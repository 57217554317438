import { includes as _includes } from 'lodash';

import { StepEnum } from './steps.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class Step {
  static readonly ADDING_INDEX: number = 0;
  static readonly VALIDATING_INDEX: number = 1;
  static readonly CREATING_INDEX: number = 2;

  protected name: StepEnum;
  protected description: string;
  protected index: number;

  constructor(name: StepEnum, description: string) {
    this.description = description;
    this.setName(name);
    this.setIndex();
  }

  getName(): StepEnum {
    return this.name;
  }

  getDescription(): string {
    return this.description;
  }

  getIndex(): number {
    return this.index;
  }

  /**
   * Indicates if the current step is adding.
   *
   * @returns boolean
   */
  isAdding(): boolean {
    return this.is(StepEnum.adding);
  }

  /**
   * Indicates if the current step is creating.
   *
   * @returns boolean
   */
  isCreating(): boolean {
    return this.is(StepEnum.creating);
  }

  /**
   * Indicates if the current step is validating.
   *
   * @returns boolean
   */
  isValidating(): boolean {
    return this.is(StepEnum.validating);
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value StepEnum
   * @returns boolean
   */
  protected is(value: StepEnum): boolean {
    const isIt: boolean = (this.name === value);
    return isIt;
  }


  /**
   * Set the current index.
   *
   * @returns void
   */
  protected setIndex(): void {
    switch (this.name) {
      case StepEnum.adding:
        this.index = Step.ADDING_INDEX;
        break;
      case StepEnum.creating:
        this.index = Step.CREATING_INDEX;
        break;
      case StepEnum.validating:
        this.index = Step.VALIDATING_INDEX;
        break;
      default:
        break;
    }
  }

  /**
   * Set the current name.
   *
   * @throws ErrorHelper
   * @returns void
   */
  protected setName(value: StepEnum): void {
    if (_includes(StepEnum, value)) {
      this.name = value;
    } else {
      throw new ErrorHelper('Invalid value given for a step name.');
    }
  }
}
