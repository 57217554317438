import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import { Subscription } from 'rxjs';

import { Configuration } from '../../models/configuration/configuration.model';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


@Component({
  selector: 'bolt-cpm-configurations-deletion-panel',
  template: require('./bolt-cpm-configurations-deletion-panel.html'),
  styles: [require('./bolt-cpm-configurations-deletion-panel.scss')]
})
export class BoltCpmConfigurationsDeletionPanelComponent implements OnChanges, OnDestroy {
  @Input() configuration: Configuration;
  @Input() open: boolean;
  @Output('cancelled') cancelEvent: EventEmitter<void>;
  @Output('deleted') deleteEvent: EventEmitter<void>;

  protected deleteSubscription: Subscription;
  protected isDeleting: boolean;
  protected isDoubleChecked: boolean;

  constructor(
    protected configurationService: ConfigurationService,
    protected notificationService: NotificationService
  ) {
    this.cancelEvent = new EventEmitter();
    this.deleteEvent = new EventEmitter();
    this.open = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.open) && this.open) {
      this.isDeleting = false;
      this.isDoubleChecked = false;

      this.cancelDeleteSubscription();
    }
  }

  ngOnDestroy() {
    this.cancelDeleteSubscription();
  }

  /**
   * Cancels the action over the configuration.
   *
   * @returns void
   */
  protected cancel(): void {
    this.cancelEvent.emit();
  }

  /**
   * Cancels the subscription for the triggered deletion.
   *
   * @returns void
   */
  protected cancelDeleteSubscription(): void {
    if (_isObject(this.deleteSubscription)) {
      this.deleteSubscription.unsubscribe();
    }
  }

  /**
   * Deletes the configuration.
   *
   * @returns void
   */
  protected delete(): void {
    this.isDeleting = true;

    this.deleteSubscription = this.configurationService.delete(this.configuration.getId()).subscribe(
      () => {
        this.notificationService.handleSuccess('Configuration was deleted successfully.');
        this.deleteEvent.emit();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed deleting the configuration', error);
        this.isDeleting = false;
      }
    );
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.isDeleting;
  }

  /**
   * Indicates if it has to block the delete button.
   *
   * @returns boolean
   */
  protected hasBlockDelete(): boolean {
    const hasIt: boolean = this.isDeleting || !this.isDoubleChecked;
    return hasIt;
  }
}
