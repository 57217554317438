import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { HotConfiguration } from '../../models/hot-configuration.model';
import { HotConfigurationTypeEnum } from '../../models/hot-configuration-type.enum';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';


@Injectable({
  providedIn: 'root',
})
export class CreditsAggregateService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Starts the aggregation process for a dubbing title
   *
   * @param titleId number
   * @param creditPositionType CreditPositionType
   * @param threshold number
   * @param locale string
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
   requestAggregationDubbingTitle(
    titleId: number,
    creditPositionType: CreditPositionType,
    threshold: number,
    locale: string,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{titleId}': titleId.toString(),
      '{creditPositionType}': creditPositionType.toString().toLowerCase(),
      '{locale}': locale,
    };

    const request: any = {
      url: this.generateUrl(
        'clone.creditsAggregateService.aggregateDubbingRequest.endpoint',
        params,
        { threshold }
      )
    };

    const subs: Subscription = this.doPatchRequest(
      request,
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: any) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Starts the aggregation process for an original version of a title
   *
   * @param title number
   * @param creditPositionType CreditPositionType
   * @param threshold number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  requestAggregationOriginalTitle(
    titleId: number,
    creditPositionType: CreditPositionType,
    threshold: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{titleId}': titleId.toString(),
      '{creditPositionType}': creditPositionType.toString().toLowerCase()
    };

    const request: any = {
      url: this.generateUrl(
        'clone.creditsAggregateService.aggregateOriginalRequest.endpoint',
        params,
        { threshold }
      )
    };

    const subs: Subscription = this.doPatchRequest(
      request,
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: any) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

   /**
    * Gets the hot configuration for a given type
    *
    * @param hotConfigurationType HotConfigurationTypeEnum
    * @returns Subscription
    */
  fetchHotConfiguration(
    hotConfigurationType: HotConfigurationTypeEnum,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction,
  ): Subscription {
    const params: any = {
      '{hotConfigurationType}': hotConfigurationType.toString()
    };

    const request: any = {
      url: this.generateUrl(
        'clone.creditsAggregateService.fetchHotConfiguration.endpoint',
        params
      )
    };

    const subs: Subscription = this.doGetRequest(
      request,
      (response: StormServiceResponseSingle) => {
        const hotConfig: HotConfiguration = new HotConfiguration(response.item);
        onSuccessDo(hotConfig);
      },
      (errorResponse: any) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }
}
