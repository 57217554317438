export class File {
  protected _id: number;
  protected _name: string;

  constructor(data: any) {
    this.initialize(data);
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  protected initialize(data: any) {
    this._id = (
      (data && data.hasOwnProperty('id'))
        ? data.id
        : undefined
    );

    this._name = (
      (data && data.hasOwnProperty('fileName'))
        ? data.fileName
        : undefined
    );
  }
}
