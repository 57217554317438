import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RoleCapabilitiesHandler } from '../../services/role-capabilities-handler/role-capabilities-handler';


@Component({
  selector: 'bolt-page-profile-import-export',
  template: require('./bolt-page-profile-import-export.html'),
  styles: [require('./bolt-page-profile-import-export.scss')]
})
export class BoltPageProfileImportExportComponent {
  protected selectedTab: string;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected router: Router,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    this.initialize();
  }

  /**
   * Activates the given tab.
   *
   * @param event any
   * @returns void
   */
  protected activateTab(event: any): void {
    this.router.navigate(
      [{ show: event.nextId }],
      { relativeTo: this.activatedRoute }
    );
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.selectedTab = params.show;
      },
      (error: any) => {
        console.log(`Error selecting tab: ${error}`);
      }
    );
  }
}
