import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';
import { LocalizedDataForm } from '../../models/form/localized-data/localized-data.form.model';

@Injectable()
export class FormFactoryService {
  protected fieldSpecs: any;

  constructor(
    protected formBuilder: FormBuilder,
    protected formConfig: FormConfigService
  ) {
    this.fieldSpecs = this.formConfig.get('role.fields');
  }

  /**
   * Builds an original role metadata form.
   *
   * @param defaultValues any
   * @returns LocalizedDataForm
   */
  buildLocalizedDataForm(defaultValues?: any): LocalizedDataForm {
    const metadataForm: LocalizedDataForm = new LocalizedDataForm(this.fieldSpecs, defaultValues);
    return metadataForm;
  }

  /**
   * Builds an original role metadata form.
   *
   * @param defaultValues any
   * @returns OriginalDataForm
   */
  buildOriginalDataForm(defaultValues?: any): OriginalDataForm {
    const metadataForm: OriginalDataForm = new OriginalDataForm(this.fieldSpecs, defaultValues);
    return metadataForm;
  }
}
