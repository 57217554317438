import { Component } from '@angular/core';
import { UserRole } from '@bolt/ui-shared/auth';
import { ActionTypeEnum, AppConfigProvider, AppConfigurationManager, Privilege } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isUndefined as _isUndefined, isObject as _isObject, isString as _isString } from 'lodash';

import { Group } from '../../models/group/group.model';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { UpcService } from '../../services/upc.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';

@Component({
  selector: 'bolt-upc-list',
  template: require('./bolt-upc-list.html'),
  styles: [require('./bolt-upc-list.scss')]
})
export class BoltUpcListComponent extends StormComponent {
  protected pager: MemoryPager;
  protected privileges: Map<string, Privilege>;
  protected focusedGroup: Group;
  protected selectedAction: ActionTypeEnum;
  protected selectedPrivilege: Privilege;
  protected selectedRoleName: string;
  protected selectedSection: string;
  protected showSidePanel: boolean;

  constructor(
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected notificationService: NotificationService,
    protected upcService: UpcService
  ) {
    super();
    this.initialize();
  }

  get groups(): Group[] {
    return this.pager.getCurrentPage();
  }

  /**
   * Applies the current filters.
   *
   * @returns void
   */
  protected applyFilters(): void {
    if (
      _isUndefined(this.selectedRoleName) &&
      _isUndefined(this.selectedAction) &&
      _isUndefined(this.selectedSection)
    ) {
      this.pager.setRecords(this.createGroups(this.privileges));
    } else {
      const matched: Map<string, Privilege> = new Map();

      this.privileges.forEach(
        (privilege: Privilege) => {
          const isMatch: boolean = this.checkFiltersFor(privilege);

          if (isMatch) {
            matched.set(privilege.name, privilege);
          }
        }
      );

      this.pager.setRecords(this.createGroups(matched));
    }
  }

  /**
   * Checks the current filter for the given privilege.
   *
   * @param privilege Privilege
   * @returns boolean
   */
  protected checkFiltersFor(privilege: Privilege): boolean {
    let isValid: boolean = true;

    if (_isString(this.selectedRoleName)) {
      isValid = privilege.roles.some(
        (role: UserRole) => role.name === this.selectedRoleName
      );
    }

    if (_isString(this.selectedAction)) {
      isValid = isValid && privilege.action === this.selectedAction;
    }

    if (_isString(this.selectedSection)) {
      isValid = isValid && Group.getSectionFrom(privilege).split('-')[0] === this.selectedSection.toLowerCase();
    }

    return isValid;
  }

  /**
   * Creates the an array of groups with the given privileges.
   *
   * @param privileges Map<string, Privilege>
   * @returns Group[]
   */
  protected createGroups(privileges: Map<string, Privilege>): Group[] {
    const groups: Group[] = new Array();

    privileges.forEach(
      (privilege: Privilege) => {
        const sectionName: string = Group.getSectionFrom(privilege);

        let matchedGroup: Group = groups.find(
          (group: Group) => group.section === sectionName
        );

        if (_isUndefined(matchedGroup)) {
          matchedGroup = new Group(sectionName);
          groups.push(matchedGroup);
        }

        matchedGroup.addPrivilege(privilege);
      }
    );

    return groups;
  }

  /**
   * Opens the side panel for editing the given privilege.
   *
   * @param privilege Privilege
   * @returns void
   */
  protected edit(privilege: Privilege): void {
    this.selectedPrivilege = privilege;
    this.toggleSidePanelVisibility(true);
  }

  /**
   * Returns the sorting criteria for groups.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (groupA: Group, groupB: Group) => {
      const nameA: string = groupA.section.trim().toLowerCase();
      const nameB: string = groupB.section.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Loads the privilege groups with the current privileges.
   *
   * @returns void
   */
  protected loadGroups(): void {
    this.privileges  = this.appConfigurationManager.getRoleCapabilities();
    const groups: Group[] = this.createGroups(this.privileges);

    this.pager.setRecords(groups);
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Indicates if it has groups.
   *
   * @returns boolean
   */
  protected hasGroups(): boolean {
    return this.pager.hasRecords();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.toggleSidePanelVisibility(false);
    this.setupPager();
    this.loadGroups();
  }

  /**
   * Indicates if the given group is the current focused group.
   *
   * @param group Group
   * @returns boolean
   */
  protected isFocusedGroup(group: Group): boolean {
    const isIt: boolean = _isObject(this.focusedGroup) && this.focusedGroup.section === group.section;
    return isIt;
  }

  /**
   * Resets the current focused group.
   *
   * @returns void
   */
  protected resetFocusedGroup(): void {
    this.focusedGroup = undefined;
  }

  /**
   * Set the given group as the focused group.
   *
   * @returns void
   */
  protected setFocusedGroup(group: Group): void {
    this.focusedGroup = group;
  }

  /**
   * Set the given action as the selected one.
   *
   * @param action ActionTypeEnum
   * @returns void
   */
  protected setSelectedAction(action: ActionTypeEnum): void {
    this.selectedAction = action;
    this.applyFilters();
  }

  /**
   * Set the given role id as the selected one.
   *
   * @param roleName string
   * @returns void
   */
  protected setSelectedRole(roleName: string): void {
    this.selectedRoleName = roleName;
    this.applyFilters();
  }

  /**
   * Set the given section as the selected one.
   *
   * @param section string
   * @returns void
   */
  protected setSelectedSection(section: string): void {
    this.selectedSection = section;
    this.applyFilters();
  }

  /**
   * Set up the memory pager.
   *
   * @returns void
   */
  protected setupPager(): void {
    this.pager = new MemoryPager(
      this.appConfig.get('ux.dataTables.pageSize'),
      this.getSortingCriteria()
    );
  }

  /**
   * Toggles the value of showSidePanel with the given value.
   *
   * @param newValue boolean
   * @returns void
   */
  protected toggleSidePanelVisibility(newValue: boolean): void {
    this.showSidePanel = newValue;
  }

  /**
   * Updates the list with the given privilege.
   *
   * @param privilege Privilege
   * @returns void
   */
  protected updatePrivilege(privilege: Privilege): void {
    this.appConfigurationManager.getRoleCapabilities().set(privilege.name, privilege);
    this.loadGroups();
    this.applyFilters();

    this.pager.setPageNumberFor(
      privilege,
      (groupA: Group, newPrivilege: Privilege) => groupA.privileges.some(
        (aPrivilege: Privilege) => aPrivilege.name === newPrivilege.name
      )
    );
  }
}
