/**
 * @see `TitleAttributes`: Common attributes for all title types.
 * @see `Feature`: Ensure that class and this enumerative are synchronized about public attributes.
 */
export enum FeatureAttributes {
  copyright = 'copyright',
  copyrightYear = 'copyrightYear',
  countryOfOriginId = 'countryOfOriginId',
  displayDubTalent = 'displayDubTalent',
  displayDubTalentOverride = 'displayDubTalentOverride',
  dmaEligible = 'dmaEligible',
  excludeKidsMode = 'excludeKidsMode',
  excludeKidsModeOverride = 'excludeKidsModeOverride',
  firstDigitalRelease = 'firstDigitalRelease',
  firstPhysicalRelease = 'firstPhysicalRelease',
  functionalMetadata = 'functionalMetadata',
  genreId = 'genreId',
  globalBoxOffice = 'globalBoxOffice',
  graphicContentDisclaimer = 'graphicContentDisclaimer',
  graphicContentDisclaimerOverride = 'graphicContentDisclaimerOverride',
  heritageDisclaimer = 'heritageDisclaimer',
  heritageDisclaimerOverride = 'heritageDisclaimerOverride',
  homeEntRatingId = 'homeEntRatingId',
  homeEntRatingSystemReasonId = 'homeEntRatingSystemReasonId',
  homeEntRatingReason = 'homeEntRatingReason',
  imdbId = 'imdbId',
  includeKidsMode = 'includeKidsMode',
  includeKidsModeOverride = 'includeKidsModeOverride',
  keywords = 'keywords',
  mpmProductId = 'mpmProductId',
  notApplicable = 'notApplicable',
  onairDateKcc = 'onairDateKcc',
  onairPlatformKcc = 'onairPlatformKcc',
  primaryProductAssociation = 'primaryProductAssociation',
  productionAspectRatioId = 'productionAspectRatioId',
  productionCompanyId = 'productionCompanyId',
  productionCompanyIds = 'productionCompanyIds',
  productMasterEarliestReleaseDate = 'productMasterEarliestReleaseDate',
  productPlacementDisclaimer = 'productPlacementDisclaimer',
  productPlacementDisclaimerOverride = 'productPlacementDisclaimerOverride',
  pseDisclaimer = 'pseDisclaimer',
  pseDisclaimerOverride = 'pseDisclaimerOverride',
  radarCopyrightYear = 'radarCopyrightYear',
  radarGenre = 'radarGenre',
  radarGroupId = 'radarGroupId',
  radarLanguage = 'radarLanguage',
  radarLanguageCode = 'radarLanguageCode',
  radarPresentedBy = 'radarPresentedBy',
  radarRating = 'radarRating',
  radarRatingSystem = 'radarRatingSystem',
  radarReleaseDate = 'radarReleaseDate',
  radarRuntime = 'radarRuntime',
  radarSynopsis = 'radarSynopsis',
  ratingFilingNumberKmrb = 'ratingFilingNumberKmrb',
  ratingId = 'ratingId',
  ratingReason = 'ratingReason',
  ratingSystemReasonId = 'ratingSystemReasonId',
  ratingType = 'ratingType',
  runtimeSeconds = 'runtimeSeconds',
  secondaryProductAssociation = 'secondaryProductAssociation',
  shortTitle = 'shortTitle',
  smokingDisclaimer = 'smokingDisclaimer',
  smokingDisclaimerOverride = 'smokingDisclaimerOverride',
  theatricalReleaseDate = 'theatricalReleaseDate',
  umid = 'umid',
  videoType = 'videoType',
}
