export class RoleResponse {
  protected _id: number;
  protected _isLocalization: boolean;
  protected _name: string;
  protected _nameLocalization: string;
  protected _language: string;
  protected _lastModified: Date;
  protected _lastModifiedBy: string;

  constructor(attributes?: { [attr: string]: any }) {
    this.initialize(attributes);
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get isLocalization(): boolean {
    return this._isLocalization;
  }

  set isLocalization(isLocalization: boolean) {
    this._isLocalization = isLocalization;
  }

  get nameLocalization(): string {
    return this._nameLocalization;
  }

  set nameLocalization(nameLocalization: string) {
    this._nameLocalization = nameLocalization;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  /**
   * Sets the values for the attributes given in the 'attributes' object
   *
   * @param attributes any
   * @returns RoleResponse
   */
  setAttributes(attributes?: { [attr: string]: any }): RoleResponse {
    if (attributes !== undefined) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }
    return this;
  }

  toString(): string {
    return this.name;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this.setAttributes(data);
  }
}
