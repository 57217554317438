import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Subscription, Observable, of } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { ItemHelper, List, MasterDataCacheService, TypeEnum } from '@bolt/ui-shared/master-data';
import { SelectionItem } from '@bolt/ui-shared/droplists';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp,
    protected cacheService: MasterDataCacheService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns an observable for creating a new item.
   *
   * @param type TypeEnum
   * @param data any
   * @returns Observable<object>
   */
  create(type: TypeEnum, data: any): Observable<object> {
    const request: any = {
      url: this.generateUrl(`newMasterdata.${type}.create.endpoint`),
      body: data
    };

    return this.doPostRequestAsObservable(request).pipe(
      map((response: any) => ItemHelper.createItem(type, response))
    );
  }

  /**
   * Fetches the master data list by the given type.
   *
   * @param listType TypeEnum
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetch(
    listType: TypeEnum,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.fetchAsObservable(listType).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (list: List) => {
        onSuccessDo(list);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      }
    );

    return subs;
  }

  /**
   * Fetches the master data list by the given type.
   *
   * @param listType TypeEnum
   * @returns Observable<List>
   */
  fetchAsObservable(listType: TypeEnum): Observable<List> {

    const obs: Observable<List> = this.fetchCached(listType).pipe(
      map(
        (response: HttpResponse<any>) => {
          const collection: any[] = this.defaultMapOn(response).item;
          const masterDataList: List = new List(listType, this.mapCollection(listType, collection));

          return masterDataList;
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          return this.defaultCatchOn(error);
        }
      )
    );

    return obs;
  }

  /**
   * Updates the item with the given type and ID, using the given data.
   *
   * @param type TypeEnum
   * @param id number
   * @param data any
   * @returns Observable<object>
   */
  update(type: TypeEnum, id: number, data: any): Observable<object> {
    const url: any = this.generateUrl(
      `newMasterdata.${type}.update.endpoint`,
      { '{id}': id }
    );

    const request: any = {
      url: url,
      body: data
    };

    return this.doPutRequestAsObservable(request).pipe(
      map(
        (response: any) => ItemHelper.createItem(type, response)
      )
    );
  }

  /**
   * Tries to fetch a Cached List, otherwise, calls the proper endpoint and caches it
   *
   * @param listType TypeEnum
   * @returns Observable<any>
   */
  protected fetchCached(listType: TypeEnum): Observable<any> {
    const cached: any[] = this.cacheService.read(listType);

    if (cached) {
      return of(cached);
    }

    const url: string = this.generateUrl(`newMasterdata.fetch.${listType}.endpoint`);

    return this.authHttp.get(url).pipe(
      tap((response: any) => this.cacheService.write(listType, response)
    ));
  }

  /**
   * Maps the given collection.
   *
   * @param listType TypeEnum
   * @param collection any[]
   * @returns SelectionItem[]
   */
  protected mapCollection(listType: TypeEnum, collection: any[]): SelectionItem[] {
    const mappedCollection: SelectionItem[] = new Array();
    let group: string = '';

    collection.forEach(
      (rawData: any) => {
        if (listType === TypeEnum.territory) {
          group = rawData.region;
        } else if (listType === TypeEnum.genre) {
          group = rawData.titleType;
        }

        const item: SelectionItem = new SelectionItem(
          rawData.name,
          rawData.id,
          ItemHelper.createItem(listType, rawData),
          group
        );

        mappedCollection.push(item);
      }
    );

    return mappedCollection;
  }
}
