import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Account, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { BoltBaseMasterDataHandlerPanelComponent } from '../../baseMasterData/handler-panel/base-handler-panel.component';

@Component({
  selector: 'bolt-master-data-production-company-handler-panel',
  template: require('./production-companies-handler-panel.html'),
})
export class BoltMasterDataProductionCompanyHandlerPanelComponent extends BoltBaseMasterDataHandlerPanelComponent {
  @Input() data: any | undefined;
  @Input() show: boolean;
  @Output('cancelled') cancelEvent: EventEmitter<void>;
  @Output('saved') saveEvent: EventEmitter<Account>;

  constructor(
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected appConfig: AppConfigProvider
  ) {
    super(masterDataService, notificationService, appConfig);
    this.cancelEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.show = false;
    this.type = MasterDataType.productionCompany;
    this.entityName = 'Production Company';

    this.setupFieldSpecs();
  }
}
