import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SelectionItem } from '@bolt/ui-shared/droplists';

import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { StormComponent } from 'app/modules/common/models/storm-component.model';

export abstract class BoltCharacterMetadataFormComponent extends StormComponent {
  @Input() form: FormGroup;
  @Input() edition: boolean;

  constructor(
    protected formConfig: ConfigService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super();
    this.edition = false;
  }

  get languages(): SelectionItem[] {
    return this.listLayoutProvider.getLanguages(true);
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength: number = this.formConfig.get(`cat.fields.${field}.maxLength`);
    return maxLength;
  }

  /**
   * Indicates if it is creating.
   *
   * @returns boolean
   */
  protected isCreating(): boolean {
    return !this.isEditing();
  }

  /**
   * Indicates if it is editing.
   *
   * @returns boolean
   */
  protected isEditing(): boolean {
    return this.edition;
  }
}
