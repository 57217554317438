import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { includes as _includes, isArray as _isArray, isObject as _isObject } from 'lodash';

import { ModeEnum } from './mode.enum';
import { Status as LockStatus } from '../../models/entity/lock/status/status.model';
import { StatusEnum as LockStatusEnum } from '../../models/entity/lock/status/status.enum';
import { Type as EntityType } from '../../models/entity/type/type.model';
import { TypeEnum as EntityTypeEnum } from '../../models/entity/type/type.enum';


@Component({
  selector: 'bolt-project-data-filter',
  template: require('./bolt-project-data-filter.html'),
  styles: [require('./bolt-project-data-filter.scss')]
})
export class BoltProjectDataFilterComponent implements OnChanges {
  @Input() description: string;
  @Input() disabled: boolean;
  @Input() mode: ModeEnum;

  @Output('changed') protected changeEvent: EventEmitter<SelectionItem[]>;

  protected readonly lockStatusEnum: typeof LockStatusEnum = LockStatusEnum;
  protected optionsIndex: Map<string, number>;
  protected optionsList: SelectionItem[];
  protected selection: string[];
  protected title: string;

  constructor() {
    this.changeEvent = new EventEmitter();
    this.description = '';
    this.disabled = false;
    this.optionsIndex = new Map();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.mode)) {
      this.setupOptions();
      this.reset();
    }

    if (_isObject(changes.description)) {
      this.setupTitle();
    }
  }

  /**
   * Reset the filter.
   *
   * @returns void
   */
  reset(): void {
    this.setupSelection();
    this.notifySelection();
  }

  /**
   * Indicates if it was altered by checking if the selection length and options length are the same.
   *
   * @returns boolean
   */
  wasAltered(): boolean {
    const wasIt: boolean =
      _isArray(this.selection) &&
      _isArray(this.optionsList) &&
      this.selection.length !== this.optionsList.length;

    return wasIt;
  }

  /**
   * Notifies the current selection.
   *
   * @returns void
   */
  protected notifySelection(): void {
    const items: SelectionItem[] = this.selection.map(
      (entry: string) => this.optionsList[this.optionsIndex.get(entry)]
    );

    this.changeEvent.emit(items);
  }

  /**
   * Set up the options using the stored mode.
   *
   * @returns void
   */
  protected setupOptions(): void {
    this.optionsList = [];

    if (!_includes(ModeEnum, this.mode)) {
      throw new Error(`Invalid mode given: ${this.mode}.`);
    } else if (this.mode === ModeEnum.entityType) {
      this.storeOption('Feature', EntityTypeEnum.Feature, EntityType);
      this.storeOption('Series', EntityTypeEnum.Series, EntityType);
      this.storeOption('Season', EntityTypeEnum.Season, EntityType);
    } else {
      switch (this.mode) {
        case ModeEnum.accountMetadata:
        case ModeEnum.territoryMetadata:
          this.storeOption('Locked', LockStatusEnum.locked, LockStatus);
          this.storeOption('Unlocked', LockStatusEnum.unlocked, LockStatus);
          this.storeOption('Not Applicable', LockStatusEnum.notApplicable, LockStatus);
        break;
        case ModeEnum.creditsMetadata:
          this.storeOption('Locked', LockStatusEnum.locked, LockStatus);
          this.storeOption('Locked (with Exemption)', LockStatusEnum.exceptionalLocked, LockStatus);
          this.storeOption('Unlocked', LockStatusEnum.unlocked, LockStatus);
          this.storeOption('No Data', LockStatusEnum.noData, LockStatus);
          this.storeOption('Not Applicable', LockStatusEnum.notApplicable, LockStatus);
        break;
        case ModeEnum.languageMetadata:
          this.storeOption('Locked (Full Data)', LockStatusEnum.locked, LockStatus);
          this.storeOption('Locked (Partial Data)', LockStatusEnum.partialLocked, LockStatus);
          this.storeOption('Unlocked (Full Data)', LockStatusEnum.unlocked, LockStatus);
          this.storeOption('Unlocked (Partial Data)', LockStatusEnum.partialUnlocked, LockStatus);
        break;
        case ModeEnum.subtitlesMetadata:
          this.storeOption('Locked', LockStatusEnum.locked, LockStatus);
          this.storeOption('Unlocked', LockStatusEnum.unlocked, LockStatus);
          this.storeOption('No Data', LockStatusEnum.noData, LockStatus);
          this.storeOption('Not Applicable', LockStatusEnum.notApplicable, LockStatus);
        break;
        default:
          this.storeOption('Locked', LockStatusEnum.locked, LockStatus);
          this.storeOption('Unlocked', LockStatusEnum.unlocked, LockStatus);
        break;
      }
    }
  }

  /**
   * Set up the selection using the stored options.
   *
   * @returns void
   */
  protected setupSelection(): void {
    this.selection = this.optionsList.map(
      (option: SelectionItem) => option.value
    );
  }

  /**
   * Set up the title using the stored description.
   *
   * @returns void
   */
  protected setupTitle(): void {
    this.description = this.description.trim();
    this.title = 'Filter by' + (this.description.length > 0 ? ` ${this.description}` : '');
  }

  /**
   * Stores a new option using the given parameters.
   *
   * @param label string
   * @param value EntityTypeEnum | LockStatusEnum
   * @param targetClass any
   * @returns void
   */
  protected storeOption(label: string, value: EntityTypeEnum | LockStatusEnum, targetClass: any): void {
    const option: SelectionItem = new SelectionItem(label, value, new targetClass(value));
    const index: number = this.optionsList.length;

    this.optionsList.push(option);
    this.optionsIndex.set(option.value, index);
  }
}
