export class Error {
  protected _code: number;
  protected _column: string;
  protected _data: string;
  protected _description: string;
  protected _name: string;
  protected _row: number;

  constructor(data: any) {
    this.initialize(data);
  }

  asString(): string {
    const value: string = `${this.description} (in file cell ${this.column}${this.row}).`;

    return value;
  }

  get code(): number {
    return this._code;
  }

  get column(): string {
    return this._column;
  }

  get data(): string {
    return this._data;
  }

  get description(): string {
    return this._description;
  }

  get name(): string {
    return this._name;
  }

  get row(): number {
    return this._row;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this._code = (
      (data && data.hasOwnProperty('code'))
        ? data.code
        : undefined
    );

    this._column = (
      (data && data.hasOwnProperty('column'))
        ? data.column
        : undefined
    );

    this._data = (
      (data && data.hasOwnProperty('data'))
        ? data.data
        : undefined
    );

    this._description = (
      (data && data.hasOwnProperty('description'))
        ? data.description
        : undefined
    );

    this._name = (
      (data && data.hasOwnProperty('name'))
        ? data.name
        : undefined
    );

    this._row = (
      (data && data.hasOwnProperty('row'))
        ? data.row
        : undefined
    );
  }
}
