import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Role } from '../../models/role.model';
import { RoleServiceFetchRoleQueryParams } from '../../services/role.service';
import { RoleManager } from '../../helpers/role-manager/role-manager.helper';
import { RoleMetadataManagerActions } from '../bolt-role-metadata-manager/bolt-role-metadata-manager.component';


@Component({
  selector: 'bolt-role-list-filters',
  template: require('./bolt-role-list-filters.html')
})
export class BoltRoleListFiltersComponent implements OnInit {
  public form: FormGroup;
  public nameValue: string = '';

  @Output('BoltRoleListFiltersFiltersUpdated')
  filtersUpdated: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();

  constructor(
    protected formBuilder: FormBuilder,
    protected activatedRoute: ActivatedRoute,
    protected roleManager: RoleManager
  ) {
    this.form = formBuilder.group({
      q:  [''],
    });
  }

  ngOnInit() {
    this.filtersUpdated.emit(this.form);

    this.activatedRoute
      .params
      .subscribe(params => {
        this.nameValue = params[RoleServiceFetchRoleQueryParams.q.toString()] || '';
        (<FormControl>this.form.get('q')).setValue(decodeURI(this.nameValue), { emitEvent: false });
      });
  }

  /**
   * Add a role.
   *
   * @return void
   */
  addRole(): void {
    this.roleManager.manageRoleMetadata(
      undefined,
      RoleMetadataManagerActions.CREATE_ROLE,
      new Role()
    );
  }
}
