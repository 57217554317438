import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MicroUiRedirectGuard } from './shared/guards/micro-ui-redirect/micro-ui-redirect.guard';

import { Bolt404Component } from './modules/layout/components/bolt-404/bolt-404.component';


const appRoutes: Routes = [
  { path: '**', component: Bolt404Component, canActivate: [MicroUiRedirectGuard] }
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes);
