import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isObject as _isObject } from 'lodash';

import { ExportValueEnum as CreditExportValue } from '../../models/credits/export-value.enum';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { Title } from 'app/modules/title/models/title.model';


@Component({
  selector: 'bolt-metadata-export-single-record',
  template: require('./bolt-metadata-export-single-record.html'),
  styles: [require('./bolt-metadata-export-single-record.scss')]
})
export class BoltMetadataExportSingleRecordComponent implements OnChanges {
  @Input() title: Title;

  locale: Locale;

  protected _creditsExport: CreditExportValue;

  constructor(
    protected layoutHandler: LayoutHandlerService
  ) { }

  get creditsExport(): CreditExportValue {
    return this._creditsExport;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.title)) {
      this.layoutHandler.reset();
    }
  }

  /**
   * Updates the Credit export wth the given option.
   *
   * @param option CreditExportValue
   * @returns void
   */
  updateCreditExport(option: CreditExportValue): void {
    this._creditsExport = option;
  }

  /**
   * Updates the locale with the given parameter.
   *
   * @param locale Locale
   * @returns void
   */
  updateLocale(locale: Locale): void {
    this.locale = locale;
  }
}
