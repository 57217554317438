import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { FileLikeObject } from 'ng2-file-upload/file-upload/file-like-object.class';
import { isUndefined as _isUndefined } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ImportService } from '../../services/import/import.service';
import { ManagerService } from '../../services/import/manager-service/manager.service';
import { Overwritters } from '../../models/import/overwritters/overwritters.model';
import { ProcessingResponse } from '../../models/import/processing-response/processing-response.model';
import { PullingActionHelper } from 'app/shared/helpers/pulling-action/pulling-action.helper';
import { ResolutionEnum } from '../../models/import/comparison/titles/title/field/resolution/resolution.enum';
import { RoleCapabilitiesHandler } from '../../services/role-capabilities-handler/role-capabilities-handler';
import { Status } from '../../models/import/processing-response/status/status.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { User } from 'app/modules/user/models/user.model';
import { UserManager } from 'app/modules/user/helpers/user-manager.helper';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-profile-import',
  template: require('./bolt-profile-import.html'),
  styles: [require('./bolt-profile-import.scss')]
})
export class BoltProfileImportComponent extends StormComponent implements OnInit {
  public status: number;

  @ViewChild('file')
  protected fileInput: ElementRef;

  @ViewChild('overwriteLockedLanguage')
  protected overwriteLockedLanguageInput: ElementRef;

  @ViewChild('overwriteLockedTerritory')
  protected overwriteLockedTerritoryInput: ElementRef;

  protected allowedFileTypes: string[];
  protected fileUploader: FileUploader;
  protected importer: User;
  protected lastUploadedFileResponse: ProcessingResponse;
  protected maxFileSizeLegend: string;
  protected overwritters: Overwritters;
  protected pullingAction: PullingActionHelper;
  protected pullingErrorList: ErrorHelper[] = new Array();
  protected selectedFile: FileItem;
  protected wasAnyResolutionButtonPressed: boolean;
  protected wasImportButtonPressed: boolean;
  protected wasResolutionProcessed: boolean;
  protected notificationsContainer = notificationsContainer;

  constructor(
    protected appConfig: AppConfigProvider,
    protected importService: ImportService,
    protected managerService: ManagerService,
    protected stormListsProvider: StormListsProvider,
    protected userManager: UserManager,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    super();
  }

  ngOnInit(): void {
    this.initialize();
  }

  /**
   * Indicates if it has block the browse button.
   *
   * @returns boolean
   */
  hasBlockBrowse(): boolean {
    const block: boolean = (
      (this.importer === undefined) ||
      !this.managerService.hasTemplates() ||
      !this.managerService.hasLanguages() ||
      !this.managerService.hasTerritories() ||
      (this.wasImportButtonPressed && !this.wasResolutionProcessed)
    );

    return block;
  }

  /**
   * Indicates if it has block the cancel button.
   *
   * @returns boolean
   */
  hasBlockCancel(): boolean {
    return this.hasBlockIngestCommands();
  }

  /**
   * Indicates if it has block import button.
   *
   * @returns boolean
   */
  hasBlockImport(): boolean {
    const block: boolean = (
      (this.fileUploader.queue.length === 0) ||
      this.wasImportButtonPressed
    );

    return block;
  }

  /**
   * Indicates if it has block the ingest commands.
   *
   * @returns boolean
   */
  hasBlockIngestCommands(): boolean {
    const block = (
      !this.wasImportButtonPressed ||
      this.wasAnyResolutionButtonPressed ||
      this.wasResolutionProcessed ||
      (this.status !== this.componentStatuses.dataFound)
    );

    return block;
  }

  /**
   * Indicates if it has block the locked language metadata overwritter.
   *
   * @returns boolean
   */
  hasBlockLockedLanguageMetadataOverwritter(): boolean {
    const block = (
      this.hasBlockIngestCommands() ||
      !this.managerService.isLanguageMetadataLocked()
    );

    return block;
  }

  /**
   * Indicates if it has block the locked language territory overwriter.
   *
   * @returns boolean
   */
  hasBlockLockedTerritoryMetadataOverwritter(): boolean {
    const block = (
      this.hasBlockIngestCommands() ||
      !this.managerService.isTerritoryMetadataLocked()
    );

    return block;
  }

  /**
   * Indicates if it has block the submit button.
   *
   * @returns boolean
   */
  hasBlockSubmit(): boolean {
    const hasIt: boolean = (
      this.hasBlockIngestCommands() ||
      this.managerService.isEnglishOnlyImportRestricted()
    );
    return hasIt;
  }

  /**
   * Indicates if it has block the templates select.
   *
   * @returns boolean
   */
  hasBlockTemplates(): boolean {
    return this.hasBlockBrowse();
  }

  /**
   * Cancels the imported data.
   *
   * @returns void
   */
  onCancel(): void {
    this.wasAnyResolutionButtonPressed = true;

    this.pullingAction.reset();
    this.pullingAction.set(
      () => {
        this.checkCancelStatus();
      },
      this.getPullingAttempts()
    );

    this.importService.cancel(
      this.managerService.comparison.uuid,
      () => {
        this.pullingAction.execute();
      },
      (error: any) => {
        this.throwCancellingError(error);
      }
    );
  }

  /**
   * Callback to manage when confirm button is triggered and change the update resolution to that status.
   *
   * @returns void
   */
  onConfirmField(): void {
    this.managerService.fieldPreviewPopupTarget.updateResolution(ResolutionEnum.CONFIRM);
    this.managerService.toggleFieldPreviewPopupVisibility(
      this.managerService.fieldPreviewPopupTarget,
      this.managerService.fieldPreviewPopupTargetExtras
    );
  }

  /**
   * Callback to manage when ignore button is triggered and change the update resolution to that status.
   *
   * @returns void
   */
  onIgnoreField(): void {
    this.managerService.fieldPreviewPopupTarget.updateResolution(ResolutionEnum.IGNORE);
    this.managerService.toggleFieldPreviewPopupVisibility(
      this.managerService.fieldPreviewPopupTarget,
      this.managerService.fieldPreviewPopupTargetExtras
    );
  }

  /**
   * Selects the file.
   *
   * @returns void
   */
  onSelectFile(): void {
    this.selectedFile = this.fileUploader.queue[0];
  }

  /**
   * Selects the template.
   *
   * @param id number
   * @returns void
   */
  onSelectTemplate(id: number): void {
    try {
      this.managerService.setCurrentTemplateById(id);
    } catch (error) {
      this.managerService.handleError('Failed salecting template.', error, notificationsContainer.pie.import.key);
    }
  }

  /**
   * Submits the imported data.
   *
   * @returns void
   */
  onSubmit(): void {
    this.wasAnyResolutionButtonPressed = true;

    // Disabled because of US141262
    // this.syncOverwrittersWithView();
    this.pullingAction.reset();

    this.pullingAction.set(
      () => {
        this.checkSubmitStatus();
      },
      this.getPullingAttempts()
    );

    this.importService.submit(
      this.managerService.comparison,
      this.overwritters,
      () => {
        this.pullingAction.execute();
      },
      (error: any) => {
        this.throwSubmittingError(error);
      }
    );
  }

  /**
   * Sends the file and gets the data.
   *
   * @returns void
   */
  onUploadFile(): void {
    this.status = this.componentStatuses.fetchingData;
    this.wasImportButtonPressed = true;
    this.wasResolutionProcessed = false;

    this.pullingAction.reset();

    this.pullingAction.set(
      () => {
        this.checkUploadedFileStatus();
      },
      this.getPullingAttempts()
    );

    if (this.lastUploadedFileResponse === undefined) {
      this.importService.uploadFile(
        this.managerService.currentTemplate,
        this.fileUploader,
        (data: ProcessingResponse) => {
          this.managerService.handleNotice(
            'The file was successfully imported and it is in comparison process.',
            notificationsContainer.pie.import.key
          );

          this.lastUploadedFileResponse = data;
          this.pullingAction.execute();
        },
        (error: ErrorHelper) => {
          this.pullingAction.reset();
          this.throwUploadingError(error);
        }
      );
    } else {
      this.pullingAction.execute();
    }
  }

  /**
   * Checks if the alert for language restriction should be visible
   *
   * @returns boolean
   */
  shouldShowLanguageRestrictedAlert(): boolean {
    const shouldShow: boolean = (
      this.managerService.hasAllNeededData() &&
      this.managerService.isLanguageRestricted() &&
      !_isUndefined(this.managerService.comparison.alerts.message)
    );

    return shouldShow;
  }

  /**
   * Cleans the last success uploading response.
   *
   * @returns void
   */
  protected cleanLastUploadedFileResponse(): void {
    this.lastUploadedFileResponse = undefined;
  }

  /**
   * Cleans the pulling errors.
   *
   * @returns void
   */
  protected cleanPullingErrorList(): void {
    this.pullingErrorList = new Array();
  }

  /**
   * Checks the declining status of the imported file.
   *
   * @returns void
   */
  protected checkCancelStatus(): void {
    const onSuccessActions: CallableFunction = (data: Status) => {
      this.lastUploadedFileResponse.updateStatus(data);

      if (this.lastUploadedFileResponse.status.isDeclined()) {
        this.reset();
        this.notifySuccessfulCancellation();
      } else if (this.lastUploadedFileResponse.status.isFailed()) {
        this.pullingAction.reset();
        this.cleanPullingErrorList();
        this.throwCancellingError(data.error);
      }
    };

    this.importService.fetchImportStatus(
      this.lastUploadedFileResponse.uuid,
      onSuccessActions,
      this.getPullingErrorActions(),
      this.getPullingFinallyActions(
        (error: any) => {
          this.throwCancellingError(error);
        }
      )
    );
  }

  /**
   * Checks the submitting status of the imported file.
   *
   * @returns void
   */
  protected checkSubmitStatus(): void {
    const onSuccessActions: CallableFunction = (data: Status) => {
      this.lastUploadedFileResponse.updateStatus(data);

      if (this.lastUploadedFileResponse.status.isDone()) {
        this.wasAnyResolutionButtonPressed = false;
        this.wasResolutionProcessed = true;

        this.pullingAction.reset();
        this.displaySummary();
        this.managerService.handleNotice('The imported file was successfully submitted.', notificationsContainer.pie.import.key);
      } else if (this.lastUploadedFileResponse.status.isFailed()) {
        this.pullingAction.reset();
        this.cleanPullingErrorList();
        this.throwSubmittingError(data.error);
      }
    };

    this.importService.fetchImportStatus(
      this.lastUploadedFileResponse.uuid,
      onSuccessActions,
      this.getPullingErrorActions(),
      this.getPullingFinallyActions(
        (error: any) => {
          this.throwSubmittingError(error);
        }
      )
    );
  }

  /**
   * Checks the uploaded file status.
   *
   * @returns void
   */
  protected checkUploadedFileStatus(): void {
    const onSuccessActions: CallableFunction = (data: Status) => {
      this.lastUploadedFileResponse.updateStatus(data);

      if (this.lastUploadedFileResponse.status.isCompared()) {
        this.pullingAction.reset();
        this.retrieveUploadedFileComparison();
      } else if (this.lastUploadedFileResponse.status.isFailed()) {
        this.pullingAction.reset();
        this.cleanLastUploadedFileResponse();
        this.cleanPullingErrorList();
        this.throwUploadingError(data.error);
      }
    };

    this.importService.fetchImportStatus(
      this.lastUploadedFileResponse.uuid,
      onSuccessActions,
      this.getPullingErrorActions(),
      this.getPullingFinallyActions(
        (error: any) => {
          this.throwUploadingError(error);
        }
      )
    );
  }

  /**
   * Displays the summary.
   *
   * @returns void
   */
  protected displaySummary(): void {
    // To be implemented.
    this.reset();
  }

  /**
   * Returns the method to be executed when a new file is selected to be uploaded.
   *
   * @param errorMessage string
   * @returns any
   */
  protected getOnAfterAddingFileMethod(errorMessage: string): any {
    const method: CallableFunction = (fileItem: FileItem) => {
      const currentExtension: string = `.${fileItem.file.name.split('.').pop()}`;

      // We can only check here the file type to work in all operating systems.
      const isValid: boolean = this.allowedFileTypes.some(
        (validExtension) => {
          const matched: boolean = (validExtension === currentExtension);
          return matched;
        }
      );

      this.cleanLastUploadedFileResponse();
      this.cleanPullingErrorList();

      if (isValid) {
        if (this.fileUploader.queue.length > 1) {
          this.fileUploader.removeFromQueue(this.fileUploader.queue[0]);
        }
      } else {
        const error: ErrorHelper = new ErrorHelper(
          `The selected file type is not one of the allowed ones (${this.allowedFileTypes.join(', ')}).`
        );

        this.fileUploader.clearQueue();
        this.managerService.handleError(errorMessage, error, notificationsContainer.pie.import.key);
      }
    };

    return method;
  }

  /**
   * Returns the method to be executed when the selected file to be uploaded is invalid.
   *
   * @param errorMessage string
   * @returns any
   */
  protected getOnWhenAddingFileFailedMethod(errorMessage: string): any {
    const method: CallableFunction = (item: FileLikeObject, filter: any, options: any) => {
      let reason: string;

      if (filter.name === 'fileSize') {
        reason = `The selected file exceeds the maximum allowed size (${this.maxFileSizeLegend}).`;
      } else {
        reason = `Unexpected ${filter.name} error: ${filter.name}`;
      }

      this.managerService.handleError(errorMessage, new ErrorHelper(reason), notificationsContainer.pie.import.key);
    };

    return method;
  }

  /**
   * Returns the number of attempts in any pulling action helper.
   *
   * @returns number
   */
  protected getPullingAttempts(): number {
    return 20;
  }

  /**
   * Returns a function for the error actions to be used in a pulling action helper.
   *
   * @returns Function
   */
  protected getPullingErrorActions(): CallableFunction {
    const actions: CallableFunction = (error: ErrorHelper) => {
      this.pullingErrorList.push(error);
    };

    return actions;
  }

  /**
   * Returns a function for the finally actions to be used in a pulling action helper.
   *
   * @param errorDisplayer Function
   * @returns Function
   */
  protected getPullingFinallyActions(errorDisplayer: CallableFunction): CallableFunction {
    const actions: CallableFunction = () => {
      try {
        if (!this.pullingAction.wasReset()) {
          this.pullingAction.execute();
        }
      } catch (error) {
        this.pullingErrorList.push(error);

        const errorToDisplay: ErrorHelper = this.pullingErrorList.shift();
        errorDisplayer(errorToDisplay);

        this.pullingErrorList.forEach(
          (extraError: ErrorHelper) => {
            console.log(extraError.toString());
          }
        );
      }
    };

    return actions;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    const size: number =
      this.appConfig.get('serviceConfig.global.request.maxBytesLimit.pie') /
      this.appConfig.get('serviceConfig.global.request.megaByteUnit');

    this.allowedFileTypes = new Array();
    this.allowedFileTypes.push('.xlsx');

    this.maxFileSizeLegend = `${size}MB`;
    this.pullingAction = new PullingActionHelper();

    this.reset();
    this.setupUserProfile();
    this.initOverwritters();
  }

  /**
   * Initializes the overwritters
   * Note: Currently values are hardcoded by client request: US141262
   * @returns void
   */
  protected initOverwritters(): void {
    this.overwritters = new Overwritters();
    this.overwritters.lockedLanguageMetadata = true;
    this.overwritters.lockedTerritoryMetadata = true;
  }

  /**
   * Returns a new file uploader.
   *
   * @returns FileUploader
   */
  protected newFileUploader(): FileUploader {
    const errorMessage: string = 'Invalid file to be imported.';

    const fileUploader = new FileUploader({
      method: 'post',
      maxFileSize: this.appConfig.get('serviceConfig.global.request.maxBytesLimit')
    });

    fileUploader.onAfterAddingFile = this.getOnAfterAddingFileMethod(errorMessage);
    fileUploader.onWhenAddingFileFailed = this.getOnWhenAddingFileFailedMethod(errorMessage);

    return fileUploader;
  }

  /**
   * Notifies about successful cancellation.
   *
   * @returns void
   */
  protected notifySuccessfulCancellation(): void {
    this.managerService.handleNotice('The imported file was successfully cancelled.', notificationsContainer.pie.import.key);
  }

  /**
   * Resets some instance attributes.
   *
   * @returns void
   */
  protected reset(): void {
    this.fileUploader = this.newFileUploader();
    this.selectedFile = undefined;
    this.status = this.componentStatuses.idle;
    this.wasAnyResolutionButtonPressed = false;
    this.wasImportButtonPressed = false;
    this.wasResolutionProcessed = false;

    this.cleanLastUploadedFileResponse();
    this.cleanPullingErrorList();

    this.managerService.reset(true);
    this.pullingAction.reset();

    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  /**
   * Retrieves the comparison for the uploaded file.
   *
   * @returns void
   */
  protected retrieveUploadedFileComparison(): void {
    this.importService.fetchComparison(
      this.lastUploadedFileResponse.uuid,
      (data: any) => {
        try {
          this.roleCapabilitiesHandler.applyDefaultResolutionIfEnglish(data);
          this.managerService.comparison = data;
          this.status = this.componentStatuses.dataFound;

          this.managerService.handleNotice('The file was processed successfully.', notificationsContainer.pie.import.key);
        } catch (error) {
          this.throwUploadingError(error);
        }
      },
      (error: any) => {
        this.throwUploadingError(error);
      }
    );
  }

  /**
   * Sets up the user profile.
   *
   * @returns void
   */
  protected setupUserProfile(): void {
    this.importer = undefined;

    this.userManager.fetchAuthenticatedUserProfile().subscribe(
      (response: any) => {
        this.importer = response.item;
      },
      (error: any) => {
        this.managerService.handleError('Failed loading user data.', error, notificationsContainer.pie.import.key);
      }
    );
  }

  /**
   * Throws an error for the cancelling process.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected throwCancellingError(error: ErrorHelper): void {
    this.reset();
    this.notifySuccessfulCancellation();
  }

  /**
   * Throws an error for the submitting process.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected throwSubmittingError(error: ErrorHelper): void {
    this.wasAnyResolutionButtonPressed = false;
    this.wasResolutionProcessed = true;

    this.managerService.handleError('Failed trying to submit the imported file. Try again.', error, notificationsContainer.pie.import.key);
  }

  /**
   * Throws an error for the uploading process.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected throwUploadingError(error: ErrorHelper): void {
    this.status = this.componentStatuses.error;
    this.wasImportButtonPressed = false;

    this.managerService.handleError('Failed importing the file.', error, notificationsContainer.pie.import.key);
  }

  /**
   * Synchronizes the overwritters with their inputs in HTML.
   *
   * @returns void
   */
  protected syncOverwrittersWithView(): void {
    if (this.overwriteLockedLanguageInput.nativeElement.disabled) {
      this.overwritters.lockedLanguageMetadata = false;
    }

    if (this.overwriteLockedTerritoryInput.nativeElement.disabled) {
      this.overwritters.lockedTerritoryMetadata = false;
    }
  }
}
