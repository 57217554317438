import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SelectionItem as ListItem } from '@bolt/ui-shared/droplists';
import { Language } from '@bolt/ui-shared/master-data';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { isObject as _isObject } from 'lodash';

import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { CreationForm } from '../../models/item/localized/creation-form/creation-form.model';
import { EditionForm } from '../../models/item/localized/edition-form/edition-form.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ItemService } from '../../services/item/item.service';
import { LayoutService as FormLayoutService } from 'app/shared/services/form/layout/layout.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { Localized } from '../../models/item/localized/localized.model';
import { Original } from '../../models/item/original/original.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-cat-item-handle-localized-metadata',
  template: require('./bolt-cat-item-handle-localized-metadata.html'),
  styles: [require('./bolt-cat-item-handle-localized-metadata.scss')]
})
export class BoltCatItemHandleLocalizedMetadataComponent implements OnChanges {
  @Input() localizedItem: Localized;
  @Input() open: boolean;
  @Input() originalItem: Original;

  @Output('closed') closeEvent: EventEmitter<undefined>;
  @Output('saved') saveEvent: EventEmitter<Localized>;

  @ViewChild('confirmOverrideLocalizationModal') protected overrideLocalizationModal: ModalDirective;
  @ViewChild('confirmCancelLocalizationModal') protected cancelLocalizationModal: ModalDirective;

  protected currentConfirmLocalizationModal: NgbModalRef;
  protected headerTitle: string;
  protected languages: ListItem[];
  protected form: FormGroup;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formBuilder: FormBuilder,
    protected formConfig: FormConfigService,
    protected formLayout: FormLayoutService,
    protected itemService: ItemService,
    protected listLayout: ListLayoutProvider,
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.open)) {
      this.open = changes.open.currentValue;

      if (this.open) {
        this.buildForm();
      } else {
        this.destroyForm();
      }
    }
  }

  get maxValuesAsString(): number {
    return this.appConfig.get('ux.multiSelect.maxValuesAsString');
  }

  get scrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Builds the form.
   *
   * @returns void
   */
  protected buildForm(): void {
    const attributes: any = this.formConfig.get('cat.fields');
    let model: any;

    if (this.isEditing()) {
      model = new EditionForm(this.originalItem, this.localizedItem, attributes);
      this.headerTitle = 'Edit Localization';
    } else {
      model = new CreationForm(this.originalItem, attributes);
      this.headerTitle = 'Add Localization';
    }

    this.form = this.formBuilder.group(model);

    this.updateUseDomesticDependencies();
  }

  /**
   * Checks if the form was touched and close it.
   *
   * @returns void
   */
  protected closeAsideContent(): void {
    if (this.form.dirty) {
      this.currentConfirmLocalizationModal = this.modalService.open(this.cancelLocalizationModal);
    } else {
      this.closeEvent.emit();
    }
  }

  /**
   * Closes the cancel confirmation modal and emit the close event.
   *
   * @returns void
   */
  protected closeCancelConfirmationModal(): void {
    this.currentConfirmLocalizationModal.close();
    this.closeEvent.emit();
  }

  /**
   * Destroys the form.
   *
   * @returns void
   */
  protected destroyForm(): void {
    this.form = undefined;
  }

  /**
   * Disables the form.
   *
   * @returns void
   */
  protected disableForm(): void {
    this.form.disable();
  }

  /**
   * Enables the form.
   *
   * @returns void
   */
  protected enableForm(): void {
    this.form.enable();
    this.updateUseDomesticDependencies();
  }

  /**
   * Ensures the given locale is not repeated in the original item.
   *
   * @returns void
   */
  protected ensureUniqueLocaleInOriginalItem(): void {
    const locale: string = this.getFormCurrentLocale();

    const existingItem: Localized = this.originalItem.localizedItems.find(
      (currentItem: Localized) => {
        const isIt: boolean = (currentItem.locale === locale);
        return isIt;
      }
    );

    if (this.isCreating() && _isObject(existingItem)) {
      this.currentConfirmLocalizationModal = this.modalService.open(this.overrideLocalizationModal);
    } else {
      this.saveItemMetadata();
    }
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength: number = this.formConfig.get(`cat.fields.${field}.maxLength`);
    return maxLength;
  }

  /**
   * Gets the locale of the current language of the form.
   *
   * @returns string
   */
  protected getFormCurrentLocale(): string {
    const language: Language = this.listLayout.getLanguageById(this.form.get('_language').value);
    const locale: string = `${language.localeLanguage}_*_*_*`;

    return locale;
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.form.disabled;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const isIt: boolean = (this.form.invalid || this.form.disabled);
    return isIt;
  }

  /**
   * Indicates if it has to display the content.
   *
   * @returns boolean
   */
  protected hasDisplay(): boolean {
    const hasIt: boolean = (_isObject(this.form) && this.open);
    return hasIt;
  }

  /**
   * Indicates if it has languages.
   *
   * @returns boolean
   */
  protected hasLanguages(): boolean {
    const hasIt: boolean = (this.languages.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has a localized item.
   *
   * @returns boolean
   */
  protected hasLocalizedItem(): boolean {
    return _isObject(this.localizedItem);
  }

  /**
   * Turns off the display.
   *
   * @returns void
   */
  protected hide(): void {
    this.open = false;
    this.closeEvent.emit();
  }

  /**
   * Indicates if it is in creation mode.
   *
   * @returns boolean
   */
  protected isCreating(): boolean {
    return !this.isEditing();
  }

  /**
   * Indicates if it is in edition mode.
   *
   * @returns boolean
   */
  protected isEditing(): boolean {
    return this.hasLocalizedItem();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.headerTitle = undefined;
    this.localizedItem = undefined;
    this.open = false;
    this.originalItem = undefined;
    this.saveEvent = new EventEmitter();

    this.loadLanguages();
  }

  /**
   * Loads the languages.
   *
   * @returns void
   */
  protected loadLanguages(): void {
    this.languages = this.listLayout.getLanguages(true).sort(
      (item1: ListItem, item2: ListItem) => {
        if (item1.label > item2.label) {
          return 1;
        } else if (item1.label < item2.label) {
          return -1;
        } else {
          return 0;
        }
      }
    );
  }

  protected obtainSavingData(): any {
    const useDomestic: boolean = (this.form.get('_useDomestic').value || false);

    // BOLTM-2919 TODO remove useDomestic condition and use the form values when fallback comes from API.
    const data: any = {
      rootId: this.form.get('_rootId').value,
      useDomestic: useDomestic,
      name: (useDomestic ? this.originalItem.name : this.form.get('_name').value),
      phonetic: (useDomestic ? null : this.form.get('_phonetic').value),
      notes: this.form.get('_notes').value
    };

    return data;
  }

  /**
   * Saves the item metadata.
   *
   * @returns void
   */
  protected saveItemMetadata(): void {
    this.disableForm();

    this.itemService.updateLocalization(
      this.originalItem.type.value,
      this.originalItem.id,
      this.getFormCurrentLocale(),
      this.obtainSavingData(),
      (item: Localized) => {
        this.saveEvent.emit(item);
        this.notificationService.handleSuccess('The item was created successfully.', undefined, notificationsContainer.cat.details.key);
        this.hide();
      },
      (error: ErrorHelper) => {
        this.enableForm();
        this.notificationService.handleError('Failed trying to create an item.', error, notificationsContainer.cat.details.key);
      }
    );
  }

  /**
   * Closes the confirmation override modal and calls the save process.
   *
   * @returns void
   */
  protected saveOverrideMetadata(): void {
    this.currentConfirmLocalizationModal.close();
    this.saveItemMetadata();
  }

  /**
   * Updates the dependencies for use-domestic field in form.
   *
   * @returns void
   */
  protected updateUseDomesticDependencies(): void {
    if (this.form.get('_useDomestic').value) {
      this.form.get('_name').disable();
      this.form.get('_phonetic').disable();
    } else {
      this.form.get('_name').enable();
      this.form.get('_phonetic').enable();
    }
  }
}
