import { Component, OnInit } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Health } from '../../models/health/health.model';
import { HealthService } from '../../services/health/health.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';

@Component({
  selector: 'bolt-cpm-health',
  template: require('./bolt-cpm-health.html'),
  styles: [require('./bolt-cpm-health.scss')]
})
export class BoltCpmHealthComponent extends StormComponent implements OnInit {
  protected health: Health;

  constructor(
    private healthService: HealthService,
    private notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Fetches the Health connection.
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();

    this.healthService.fetch().subscribe(
      (healthConnection: Health) => {
        this.health = healthConnection;
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.changeStatusToError();
        this.notificationService.handleError('Failed fetching Health connections', error);
      }
    );
  }
}
