import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { sortBy as _sortBy } from 'lodash';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { GenreFilterHelper, GenreTitleType, StormList, StormListItemInterface, StormListType } from '@bolt/ui-shared/master-data';

import { FunctionalMetadata } from 'app/modules/list/models/functional-metadata/functional-metadata.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { Title } from '../../models/title.model';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';

export abstract class BoltTitleLocalizationFormComponent {
  @Input() attributes: string[];
  @Input() localeOnReadOnlyMode: boolean;
  @Input() metadataOnReadOnlyMode: boolean;
  @Input() title: Title;
  @Input() form: FormGroup;
  @Input() isEditing: boolean;

  protected genres: SelectionItem[];
  protected filteredGenres: SelectionItem[];
  protected loadingGenres: boolean;
  protected functionalMetadata: FunctionalMetadata[] = [];
  protected functionalMetadataOptions: SelectionItem[] = [];
  protected subscriptions: Subscription;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected listsProvider: StormListsProvider
  ) {
    this.localeOnReadOnlyMode = false;
    this.metadataOnReadOnlyMode = false;
    this.attributes = [];
    this.subscriptions = new Subscription();
  }

  /**
   * Return the genre list Filtered by the genre type of the title and by officials only.
   *
   * @returns void
   */
   protected getFilteredGenresByType(): void {
    const genreTitleType: GenreTitleType = GenreTitleType[this.title.type.toString().toUpperCase()];

    this.filteredGenres = GenreFilterHelper.filterGenres(this.genres, genreTitleType);

    this.loadingGenres = false;
   }

  /**
   * Return the genre list Filtered by the genre type of the title and by its list of accounts.
   *
   * @param accountId number
   * @returns SelectionItem[]
   */
  protected getFilteredGenresByAccount(
    accountId: number
  ): SelectionItem[] {
    const genreTitleType: GenreTitleType = GenreTitleType[this.title.type.toString().toUpperCase()];
    return GenreFilterHelper.filterGenres(
      this.genres, genreTitleType, [accountId]
    );
  }

  /**
   * Return the genre list Filtered by the genre type of the title and by its list of accounts and territories.
   *
   * @param accountId number
   * @param territoryId number
   * @returns SelectionItem[]
   */
  protected getFilteredGenresByAccountTerritory(
    accountId: number,
    territoryId: number
  ): SelectionItem[] {
    const genreTitleType: GenreTitleType = GenreTitleType[this.title.type.toString().toUpperCase()];
    return GenreFilterHelper.filterGenres(
      this.genres, genreTitleType, [accountId], [territoryId]
    );
  }

  /**
   * Indicates if it has to display the given attribute.
   *
   * @param attribute string
   * @returns boolean
   */
  protected hasAttribute(attribute: string): boolean {
    return this.attributes.includes(attribute);
  }

  /**
   * Indicates if the current title is a Episode.
   *
   * @returns boolean
   */
  protected isEpisode(): boolean {
    return this.title.isEpisode();
  }

  /**
   * Indicates if the current title is a Feature.
   *
   * @returns boolean
   */
  protected isFeature(): boolean {
    return this.title.isFeature();
  }

  /**
   * Indicates if the current title is a Season.
   *
   * @returns boolean
   */
  protected isSeason(): boolean {
    return this.title.isSeason();
  }

  /**
   * Loads the functional metadata options.
   *
   * @returns void
   */
  protected loadFunctionalMetadataOptions(): void {

    if (!this.hasFunctionalMetadataEnabled()) {
      this.listsProvider.getList(StormListType.functionalMetadata).subscribe(
        (stormList: StormList) => stormList.collection.forEach(
          (functionalMetadata: StormListItemInterface) => this.functionalMetadata.push(functionalMetadata.value)
        )
      );
    }

    this.mapFunctionalMetadata();
  }

  /**
   * Loads the current selection item lists.
   *
   * @returns void
   */
  protected abstract loadLists(): void;

  /**
   * Maps the current functional metadata into selectItem.
   *
   * @returns void
   */
  protected mapFunctionalMetadata(): void {
    this.functionalMetadata.forEach(
      (functionalMetadata: FunctionalMetadata) => {
        this.functionalMetadataOptions.push(new SelectionItem(functionalMetadata.description, functionalMetadata.id));
      }
    );
  }

  /**
   * Indicates if the Functional Metadata toggle is on.
   *
   * @returns boolean
   */
  protected hasFunctionalMetadataEnabled(): boolean {
    const hasIt = this.appConfigurationManager.getToggleValue(ToggleKeyEnum.functionalMetadataAsFieldsOn);
    return hasIt;
  }
}
