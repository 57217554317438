import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EnvironmentService } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import * as fileSaver from 'file-saver';
import { map } from 'rxjs/operators';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { XsltManager } from '../../helpers/xslt-manager/xslt-manager.helper';
import { XsltService } from '../../services/xslt.service';


@Component({
  selector: 'bolt-xslt-editor',
  template: require('./bolt-xslt-editor.html'),
  styles: [require('./bolt-xslt-editor.scss')],
  providers: [
    XsltService,
    XsltManager,
  ],
  encapsulation: ViewEncapsulation.None
})
export class BoltXsltEditorComponent extends StormComponent implements OnInit {
  form: FormGroup;
  fullMetadataForm: FormGroup;
  mockTitles: object[] = new Array();
  selectedTitle: string;
  xsltConfig: object;
  xsltTemplate: string;
  output: string;
  fullMetadata: string;
  xsltEditorConfig: object = new Object();
  metadataEditorConfig: object = new Object();
  viewMode: string = 'col-xs-6';

  constructor(
    protected activatedRouteSnapshot: ActivatedRoute,
    protected appConfig: AppConfigProvider,
    protected environment: EnvironmentService,
    protected formBuilder: FormBuilder,
    protected location: Location,
    protected notificationService: NotificationService,
    protected xsltManager: XsltManager,
    protected xsltService: XsltService,
  ) {
    super();

    this.form = formBuilder.group({
      xslt: [],
    });

    this.fullMetadataForm = formBuilder.group({
      titleType: ['', Validators.required],
      titleId: ['', Validators.required],
    });

    this.setTitleTypes();
  }

  ngOnInit() {
    this.xsltEditorConfig = Object.assign(
      {
        mode: 'xml',
        placeholder: 'Xslt Code'
      },
      this.appConfig.get('ux.codemirror', new Object())
    );

    this.metadataEditorConfig = Object.assign(
      {
        mode: 'xml',
        placeholder: 'Full Metadata'
      },
      this.appConfig.get('ux.codemirror', new Object())
    );

    this.fetchXsltDetails((<any>this.activatedRouteSnapshot.snapshot.params).xsltId);
  }

  fetchXsltDetails(xsltId: number): void {
    this.xsltManager.fetchXslt(xsltId).subscribe(
      serviceResponse => this.xsltTemplate = serviceResponse.item.fileContent
    );

    this.xsltManager.fetchXsltConfig(xsltId).subscribe(
      serviceResponse => this.xsltConfig = serviceResponse.item
    );
  }

  exitEdition(): void {
    this.location.back();
  }

  protected setTitleTypes(): void {
    const titles = [
      {
        label: 'Frozen',
        value: 'feature1'
      },
      {
        label: 'How To Get Away With Murder: Season 2: [F030] Anna Mae',
        value: 'episode1'
      }
    ];

    titles.forEach(element => {
      this.mockTitles.push({
        displayName: element.label,
        displayValue: element.value
      });
    });
  }

  protected fetchMetadata(): void {
    const payload = this.appConfig.get(
      `application.metadataPayloadMocks.${this.selectedTitle}.${this.environment.getName()}`,
      { }
    );

    this.xsltManager.fetchFullMetadata(payload).subscribe(
      (serviceResponse: StormServiceResponseSingle) => {
        this.fullMetadata = serviceResponse.item.fullmetadata;
        this.notificationService.handleNotice(serviceResponse.item.message);
      }
    );
  }

  protected updateXsl(): void {
    const payload = {
      fileContent: this.xsltTemplate
    };

    this.xsltManager
      .updateXslt(payload, (<any>this.activatedRouteSnapshot.snapshot.params).xsltId)
      .subscribe(
        serviceResponse => {
          this.output = serviceResponse.item.message;
          this.notificationService.handleNotice(serviceResponse.item.message);
        }
      );
  }

  protected download(format: string): void {
    const requestBody = {
      xsl: this.xsltTemplate,
      fullmetadata: this.fullMetadata,
      type: format
    };

    this.xsltManager.transformFullMetadata(requestBody).pipe(
      map(
        (response: HttpResponse<any>) => {
          const reader = new FileReader();

          reader.readAsText(response.body);

          reader.onloadend = (): any => {
            try {
              const error: any = JSON.parse(reader.result.toString());
              this.notificationService.handleError(error.message);
            } catch (err) {
              fileSaver.saveAs(
                response.body,
                response.headers.get('content-disposition').split('=')[1]
              );
            }
          };
        }
      )
    ).subscribe();
  }
}
