import { Directive, Input, OnDestroy } from '@angular/core';
import { TemplateRef, ViewContainerRef } from '@angular/core';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { Subscription } from 'rxjs';
import { isArray as _isArray, isBoolean as _isBoolean, isObject as _isObject, includes as _includes } from 'lodash';


import { AuthHelper } from '../../helpers/auth/auth.helper';
import { CapabilitiesManager } from '../../services/role/capabilities.manager';
import { User } from 'app/modules/user/models/user.model';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';


@Directive({
  selector: '[boltAuthAware]'
})
export class BoltAuthAwareDirective implements OnDestroy {
  protected authenticatedUserSubs: Subscription;
  protected hasView: boolean = false;

  constructor(
    protected appConfiguration: AppConfigurationManager,
    protected authHelper: AuthHelper,
    protected capabilitiesManager: CapabilitiesManager,
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef
  ) { }

  @Input()
  set boltAuthAware(accessTo: any) {
    let elementId: any;
    let externalCondition: boolean;

    if (_isArray(accessTo)) {
      elementId = accessTo[0];
      externalCondition = accessTo[1];
    } else {
      elementId = accessTo;
      externalCondition = true;
    }

    if (externalCondition) {
      this.authenticatedUserSubs = this.authHelper.authenticatedUserStream.subscribe(
        (user: User) => {
          const condition: boolean = _isObject(user) && this.canPassAccessControlChecks(elementId);

          if (condition && !this.hasView) {
            this.hasView = true;
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else if (!condition && this.hasView) {
            this.hasView = false;
            this.viewContainer.clear();
          }
        }
      );
    } else {
      this.hasView = false;
      this.viewContainer.clear();
    }
  }

  ngOnDestroy() {
    if (this.authenticatedUserSubs) {
      this.authenticatedUserSubs.unsubscribe();
    }
  }

  /**
   * Indicates if the current user can access to the given element.
   *
   * @param element string
   * @returns boolean
   */
  protected canPassAccessControlChecks(element: string): boolean {
    let toggleValue: boolean;

    if (_includes(ToggleKeyEnum, element)) {
      toggleValue = this.appConfiguration.getToggleValue(element);
    }

    // If the given element is a toggle and it's turned off, we reject the access. Otherwise we check the element with the UPC manager.
    if (_isBoolean(toggleValue) && !toggleValue) {
      return false;
    } else {
      const itCan: boolean =
        this.capabilitiesManager.hasUserPrivilegeOn(element, [ActionTypeEnum.read]) ||
        this.capabilitiesManager.hasUserPrivilegeOn(element, [ActionTypeEnum.all]);

      return itCan;
    }
  }
}
