import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, ViewChild, OnDestroy } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { TitleEditHistory } from '@bolt/ui-shared/title';
import { HttpError } from '@bolt/ui-shared/common';
import { CountryInterface } from '@bolt/ui-shared/master-data/models/country/country.interface';

import { isObject as _isObject, isArray as _isArray, replace as _replace, startCase as _startCase, isArray } from 'lodash';
import { Subscription } from 'rxjs';

import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { EditHistoryService } from '../../services/edit-history.service';
import { Locale } from '../../models/locale/locale.model';
import { TitleType } from 'app/modules/title/models/title.model';
import { TypeEnum } from 'app/modules/credits/models/wizard/candidate/type/type.enum';

export interface EditHistoryMetadata {
  id: number;
  locale: string;
  type: TypeEnum | TitleType;
  imdb?: any;
  radarProductId?: any;
  name?: string ;
  language: any;
  territory: any;
  productType: any;
  account: any;
  localeObject: Locale;
  subtitles?: any[];
}

@Component({
  selector: 'bolt-edit-history',
  template: require('./bolt-edit-history.html'),
  styles: [require('./bolt-edit-history.scss')]
})
export class BoltEditHistoryComponent extends StormComponent implements OnChanges, OnDestroy {
  @Input() metadata: EditHistoryMetadata;
  @Input() isRoot: boolean = false;
  @Input() isTitle: boolean = true;
  @Input() isSubtitle: boolean = false;
  @Input() open: boolean;

  @Output('closed') closeEvent: EventEmitter<any>;

  @ViewChild('historyModalRef') protected modal: ModalDirective;

  protected readonly errorMessage: string = 'Failed trying to fetch the edit history';
  protected readonly separator: string = ', ';
  protected editHistory: TitleEditHistory[];
  protected fetchSubscription: Subscription;
  protected isArchivedHistoryActive: boolean;
  protected localeLimit: number;
  protected localeValue: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected entityMapper: EntityMapperHelper,
    protected notificationService: NotificationService,
    protected editHistoryService: EditHistoryService
  ) {
    super();
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.metadata) && this.hasChanged(changes.metadata) && !this.isRoot) {
      this.updateLocaleValue();
    }

    if (_isObject(changes.open) && this.hasChanged(changes.open)) {
      if (changes.open.currentValue) {
        this.editHistory = new Array();
        this.modal.show();
        this.fetchLocalizationHistory(false);
      } else {
        this.close();
      }
    }
  }

  ngOnDestroy() {
    this.cancelFetch();
  }

  getPropertyLabel(property: string): string {
    return _startCase(property);
  }

  /**
   * Cancels the current fetching.
   *
   * @return void
   */
  protected cancelFetch(): void {
    if (_isObject(this.fetchSubscription)) {
      this.fetchSubscription.unsubscribe();
    }
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected close(): void {
    this.open = false;

    if (_isObject(this.modal)) {
      this.modal.hide();
    }

    this.cancelFetch();
    this.closeEvent.emit();
  }

  /**
   * Fetches the edit history of the current localization.
   *
   * @returns void
   */
  protected fetchLocalizationHistory(archivedHistory: boolean): void {
    this.cancelFetch();
    this.changeStatusToFetchingData();

    this.isArchivedHistoryActive = archivedHistory;

    if (this.isRoot) {
      if (this.isSubtitle) {
        this.editHistoryService.fetchRootSubtitleEditHistory(
          this.metadata.type as TitleType,
          this.metadata.id,
          this.isArchivedHistoryActive,
          this.metadata.subtitles
        ).subscribe(
          (response: TitleEditHistory[]) => {
            this.editHistory = this.isArchivedHistoryActive && this.editHistory ? this.editHistory.concat(response) : response;
            this.changeStatusToDataFound();
          },
          (error: HttpError) => {
            if (error.is404) {
              this.editHistory = [];
              this.changeStatusToDataFound();
            } else {
              this.notificationService.handleError(this.errorMessage, error);
              this.changeStatusToError();
            }
          },
        );
      } else {
        this.editHistoryService.fetchRootEditHistory(
          this.metadata.type,
          this.metadata.id,
          this.isArchivedHistoryActive
        ).subscribe(
          (response: TitleEditHistory[]) => {
            this.editHistory = this.isArchivedHistoryActive && this.editHistory ? this.editHistory.concat(response) : response;
            this.changeStatusToDataFound();
          },
          (error: HttpError) => {
            this.notificationService.handleError(this.errorMessage, error);
            this.changeStatusToError();
          },
        );
      }
    } else {
      if (this.isSubtitle) {
        this.editHistoryService.fetchLocalizationSubtitleEditHistory(
          this.metadata.locale,
          this.metadata.type as TitleType,
          this.metadata.id,
          this.isArchivedHistoryActive,
          this.metadata.subtitles
        ).subscribe(
          (response: TitleEditHistory[]) => {
            this.editHistory = this.isArchivedHistoryActive && this.editHistory ? this.editHistory.concat(response) : response;
            this.changeStatusToDataFound();
          },
          (error: HttpError) => {
            if (error.is404) {
              this.editHistory = [];
              this.changeStatusToDataFound();
            } else {
              this.notificationService.handleError(this.errorMessage, error);
              this.changeStatusToError();
            }
          },
        );
      } else {
        this.editHistoryService.fetchLocalizationEditHistory(
          this.metadata.locale,
          this.metadata.type,
          this.metadata.id,
          this.isArchivedHistoryActive).subscribe(
          (response: TitleEditHistory[]) => {
            this.editHistory = this.isArchivedHistoryActive && this.editHistory ? this.editHistory.concat(response) : response;
            this.changeStatusToDataFound();
          },
          (error: HttpError) => {
            this.notificationService.handleError(this.errorMessage, error);
            this.changeStatusToError();
          },
        );
      }
    }
  }

  /**
   * Indicates if the given simpleChange has been changed.
   *
   * @param change SimpleChange
   * @returns boolean
   */
  protected hasChanged(change: SimpleChange): boolean {
    return change.previousValue !== change.currentValue;
  }

  /**
   * Indicates if it has edit history.
   *
   * @returns boolean
   */
  protected hasEditHistory(): boolean {
    const hasIt: boolean = _isArray(this.editHistory) && this.editHistory.length > 0;
    return hasIt;
  }

  /**
   * Initialize the instance.
   *
   * @returns void
   */
  protected initialize() {
    this.closeEvent = new EventEmitter();
    this.localeLimit = this.appConfig.get('ux.dataAccordion.viewEditHistory.localeLimit');
    this.open = false;
  }

  /**
   * Updates the locale using the stored metadata.
   *
   * @returns void
   */
  protected updateLocaleValue(): void {
    if (this.entityMapper && this.metadata && this.metadata.territory) {
      this.entityMapper.groupTerritoriesByRegion(<CountryInterface[]>this.metadata.territory).subscribe(
        (groups: any) => {
          const accounts: string = isArray(this.metadata.account) ? this.metadata.account.join(this.separator) : '';
          const productTypes: string = isArray(this.metadata.productType) ? this.metadata.productType.join(this.separator) : '';
          const territories: string = isArray(groups) ? groups.join(this.separator) : '';

          if (this.metadata.localeObject?.type?.isLanguage()) {
            this.localeValue = `${this.metadata.language} - ${territories} - ${productTypes} - ${accounts}`;
          } else if (this.metadata?.localeObject?.type?.isTerritory()) {
            this.localeValue = `${territories} - ${productTypes} - ${accounts}`;
          } else {
            this.localeValue = accounts;
          }
        }
      );
    }
  }
}
