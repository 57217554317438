import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';


export interface CollectionManagerCollectionSortByInterface {
  property: string;
  reverse: boolean;
  order: number;
}

export interface CollectionManagerPaginationInterface {
  current_page?: number;
  page_size?: number;
  total_pages?: number;
  total_results?: number;
}

export interface CollectionManagerCollectionInterface {
  name: string;
  collection: any[];
  sorting: CollectionManagerCollectionSortByInterface[];
  paginate?: boolean;
  pagination?: CollectionManagerPaginationInterface;
  event?: string;
  rawCollection?: any[];
  _collection?: any[];
  _unsortedCollection?: any[];
}

export enum CollectionManagerCollectionFilterByCompareFunction {
  STARTS_WIDTH = <any>'startsWith',
  INCLUDES = <any>'includes'
}


/**
 * @deprecated 2022-04-25 Use `MemoryPager` instead of.
 * @see MemoryPager
 */
@Injectable({
  providedIn: 'root',
})
export class CollectionManagerHelper {
  protected _collections: CollectionManagerCollectionInterface[] = [];
  protected collectionHandler: Subject<CollectionManagerCollectionInterface> = new Subject<CollectionManagerCollectionInterface>();

  constructor() { }

  /**
   * Returns the observable Collection's handler.
   *
   * @returns Observable<CollectionManagerCollectionInterface>
   */
  getCollectionHandler(): Observable<CollectionManagerCollectionInterface> {
    return this.collectionHandler.asObservable();
  }

  /**
   * Sets the collections.
   *
   * @param collections CollectionManagerCollectionInterface[]
   * @returns void
   */
  setCollections(collections: CollectionManagerCollectionInterface[]): void {
    this._collections = collections;

    this._collections.forEach(collection => {
      collection._unsortedCollection = [];
      this.setCollectionItems(collection.name, collection.collection, true);
      this.collectionHandler.next(
        Object.assign(this.getCollection(collection.name), { event: 'setCollections' })
      );
    });
  }

  /**
   * Adds new items to collection.
   *
   * @param name string
   * @param items any[]
   * @returns void
   */
  addItemsToCollection(name: string, items: any[]): void {
    const collection: CollectionManagerCollectionInterface = _.find(this._collections, { name: name });

    if (collection) {
      const _items: any[] = (
        collection.hasOwnProperty('rawCollection')
          ? _.cloneDeep(collection.rawCollection)
          : _.cloneDeep(collection.collection)
      );

      _items.push(...items);

      this.setCollectionItems(name, _items);
    } else {
      throw new Error('Collection ' + name + ' not found');
    }
  }

  /**
   * Removes the given items from collection.
   *
   * @param name string
   * @param items any[]
   * @param comparingAttributes string[]
   * @returns void
   */
  removeItemsFromCollection(name: string, items: any[], comparingAttributes: string[]): void {
    const collection: CollectionManagerCollectionInterface = _.find(this._collections, { name: name });

    if (collection) {
      const _items: any[] = (
        collection.hasOwnProperty('rawCollection')
          ? _.cloneDeep(collection.rawCollection)
          : _.cloneDeep(collection.collection)
      );

      const diff: any[] = _.differenceWith(_items, items, this.getRemovingComparingCriteria(comparingAttributes) as any);
      this.setCollectionItems(name, diff);
    } else {
      throw new Error('Collection ' + name + ' not found');
    }
  }

  /**
   * Set the collection items.
   *
   * @param name string
   * @param items any[]
   * @param resetRaw boolean
   * @param emitEvent boolean
   * @returns void
   */
  setCollectionItems(
    name: string,
    items: any[],
    resetRaw: boolean = true,
    emitEvent: boolean = true
  ): void {
    const collection: CollectionManagerCollectionInterface = _.find(this._collections, { name: name });

    if (collection) {
      collection._collection = items;

      if (resetRaw || !Array.isArray(collection.rawCollection) || (collection.rawCollection.length === 0)) {
        collection.rawCollection = items;
      }

      if (emitEvent) {
        this.collectionHandler.next(
          Object.assign(this.getCollection(name), { event: 'setCollectionItems' })
        );
      }
    } else {
      throw new Error('Collection ' + name + ' not found');
    }
  }

  /**
   * Set the pagination for the collection with the given name.
   *
   * @param name string
   * @param pagination CollectionManagerPaginationInterface
   * @returns void
   */
  setCollectionPagination(name: string, pagination: CollectionManagerPaginationInterface): void {
    const collection: CollectionManagerCollectionInterface = _.find(this._collections, { name: name });

    if (!collection) {
      throw new Error('Collection ' + name + ' not found');
    }

    collection.pagination = pagination;
  }

  /**
   * Set the sorting criteria for the collection with the given name.
   *
   * @param name string
   * @param sorting CollectionManagerCollectionSortByInterface[]
   * @returns void
   */
  setCollectionSorting(name: string, sorting: CollectionManagerCollectionSortByInterface[]): void {
    const collection: CollectionManagerCollectionInterface = _.find(this._collections, { name: name });

    if (!collection) {
      throw new Error('Collection ' + name + ' not found');
    }

    collection.sorting = sorting;
  }

  /**
   * Returns the collection with the given name.
   *
   * @param name string
   * @returns CollectionManagerCollectionInterface
   */
  getCollection(name: string): CollectionManagerCollectionInterface {
    const collection: CollectionManagerCollectionInterface = _.find(this._collections, { name: name });

    if (collection.paginate) {
      const collectionLength = Number(collection._collection ? collection._collection.length : 0);
      const totalPages = Math.ceil(collectionLength / collection.pagination.page_size);

      let page = collection.pagination.current_page || 1;

      if ((totalPages > 0) && (collection.pagination.current_page > totalPages)) {
        page = totalPages;
      }

      const offset = (page - 1) * collection.pagination.page_size;

      collection.pagination = Object.assign(collection.pagination, {
        current_page: page,
        total_results: collectionLength,
        total_pages: totalPages,
      });

      collection.collection =
        collection._collection.slice(offset, offset + collection.pagination.page_size);
    } else {
      collection.collection = collection.rawCollection;
    }

    return collection;
  }

  /**
   * Refreshes the collection with the given name.
   *
   * @param name string
   * @returns void
   */
  refreshCollection(name: string): void {
    this.collectionHandler.next(
      Object.assign(this.getCollection(name), { event: 'refreshCollection' })
    );
  }

  /**
   * Sorts the collection with the given name and criteria, and emits an event if it was defined.
   *
   * @param name string
   * @param sortBy string
   * @param singleSorting boolean
   * @param emitEvent boolean
   * @returns void
   */
  sortBy(
    name: string,
    sortBy?: string,
    singleSorting: boolean = false,
    emitEvent: boolean = true
  ): void {
    const collection: CollectionManagerCollectionInterface = this.getCollection(name);

    if (!collection) {
      throw new Error('Collection ' + name + ' not found');
    }

    if (sortBy !== undefined && sortBy !== null) {
      const foundIndex = _.findIndex(collection.sorting, { property: sortBy });

      if (foundIndex === -1) {
        collection.sorting.push({ property: sortBy, reverse: false, order: 0 });
      } else {
        if (!collection.sorting[foundIndex].reverse) {
          collection.sorting[foundIndex].reverse = !collection.sorting[foundIndex].reverse;
        } else {
          _.pullAt(collection.sorting, [foundIndex]);
        }
      }

      if (singleSorting && collection.sorting.length) {
        collection.sorting = [collection.sorting.pop()];
      }

      collection.sorting.forEach((sorting, index) => sorting.order = index + 1);

    } else if (sortBy === null) {
      collection.sorting = [];
    }

    this.setCollectionItems(
      name,
      this.sort(collection),
      false,
      false
    );

    if (emitEvent) {
      this.collectionHandler.next(
        Object.assign(this.getCollection(name), { event: 'sortBy' })
      );
    }

  }

  /**
   * Sorts the collection with the given name and criteria, and returns the result.
   *
   * @param name string
   * @param sortBy string
   * @returns CollectionManagerCollectionSortByInterface
   */
  sortedBy(name: string, sortBy: string): CollectionManagerCollectionSortByInterface {
    const collection: CollectionManagerCollectionInterface = this.getCollection(name);

    if (!collection) {
      throw new Error('Collection ' + name + ' not found');
    }

    const index = _.findIndex(collection.sorting, { property: sortBy });

    if (index === -1) {
      return <CollectionManagerCollectionSortByInterface>{ reverse: undefined, order: undefined };
    } else {
      return collection.sorting[index];
    }
  }

  /**
   * Filters a collection.
   *
   * @param name string
   * @param filters string[]
   * @param paths string[]
   * @param compareFunction CollectionManagerCollectionFilterByCompareFunction
   * @param matchAll boolean
   * @param customFilter CallableFunction
   * @returns void
   */
  filterBy(
    name: string,
    filters: string[] = [],
    paths: string[] = [],
    compareFunction: CollectionManagerCollectionFilterByCompareFunction = CollectionManagerCollectionFilterByCompareFunction.STARTS_WIDTH,
    matchAll: boolean = true,
    customFilter: CallableFunction = () => true
  ): void {
    const collection = this.getCollection(name);
    let filteredItems = [];

    if (!collection) {
      throw new Error('Collection ' + name + ' not found');
    }

    collection._unsortedCollection = [];

    if (filters.length) {
      collection.rawCollection.forEach((item) => {
        let matches: number = 0;

        filters.forEach((filter, index) => {
          const exactMatch: boolean = matchAll ? false : ((index + 1) < filters.length);

          paths.forEach(path => {
            const value = _.get(item, path);
            if (
              value &&
              this.filter(value, filter, exactMatch, compareFunction) &&
              customFilter(item)
            ) {
              matches++;
            }
          });
        });

        if (matches) {
          filteredItems.push(item);
        }
      });

      this.setCollectionItems(name, filteredItems, false);
    } else {
      filteredItems = collection.rawCollection.filter(item => customFilter(item));
      this.setCollectionItems(name, filteredItems, false);
    }

    this.sortBy(name, undefined, false, false);

    this.collectionHandler.next(
      Object.assign(this.getCollection(name), { event: 'filterBy' })
    );
  }

  /**
   * Returns a new array using the items and the sorting criteria of the given collection.
   *
   * @param collection CollectionManagerCollectionInterface
   * @returns any[]
   */
  protected sort(collection: CollectionManagerCollectionInterface): any[] {
    if (!collection._unsortedCollection.length) {
      /**
       * The first time we sort the collection, we store a backup of "_collection" into
       * "_unsortedCollection".
       */
      collection._unsortedCollection = collection._collection;
    }

    if (!collection.sorting || !collection.sorting.length) {
      /**
       * If the collection has no "sorting" data and it has items into "_unsortedCollection",
       * we use those last ones because they have the same order that API returned to the UI.
       * Otherwise we use the items into "_collection".
       */
      const items = (collection._unsortedCollection.length)
        ? collection._unsortedCollection
        : collection._collection;

      return items;
    }

    let _collection = _(collection._collection).chain();
    const _sorting = _.map(collection.sorting, _.clone);

    _sorting.reverse().forEach(
      (sort: any) => {
        _collection = _collection.sortBy(
          (o: any) => {
            if (_.isArray(o[sort.property])) {
              const all = _(o[sort.property]).filter({ id: 0 });

              const result =
                ((all.size() ? '0' : '').toString() +
                all.join('_') +
                _(o[sort.property]).reject({ id: 0 }).sortBy('value').join('_')).toUpperCase();

              return result;
            }

            let propValue = '';

            if (o[sort.property]) {
              if (Number.isInteger(o[sort.property])) {
                propValue = o[sort.property];
              } else {
                propValue = (o[sort.property].id === 0 ? '0' : '') + o[sort.property].toString();
              }
            }

            return propValue;
          }
        );

        if (sort.reverse) {
          _collection = _collection.reverse();
        }
      }
    );

    return _collection.value();
  }

  /**
   * Applies the given filter criteria and returns the result.
   *
   * @param value any
   * @param filter string
   * @param exactMatch boolean
   * @param compareFunction CollectionManagerCollectionFilterByCompareFunction
   * @returns any
   */
  protected filter(
    value: any,
    filter: string,
    exactMatch: boolean,
    compareFunction: CollectionManagerCollectionFilterByCompareFunction
  ): any {
    value = _.isArray(value) ? value : [value];

    return value.some(
      (val: any) => {
        const matched: boolean =
          (exactMatch && (val.toString().toLowerCase() === filter.toLowerCase())) ||
          (!exactMatch && (val.toString().toLowerCase()[compareFunction](filter.toLowerCase())));

        return matched;
      }
    );
  }

  /**
   * Returns the removing criteria to be used to remove items.
   *
   * @param comparingAttributes string[]
   * @returns CallableFunction
   */
  protected getRemovingComparingCriteria(comparingAttributes: string[]): CallableFunction {
    const criteria: CallableFunction = (itemA: any, itemB: any[]) => {
      let wasFound: boolean = false;
      let level: number = 0;

      while (!wasFound && (level < itemB.length)) {
        let x: number = 0;
        let matched: boolean = true;

        while (matched && (x < comparingAttributes.length)) {
          const auxA = _.get(itemA, comparingAttributes[x]);
          const auxB = _.get(itemB[level], comparingAttributes[x]);

          matched = (auxA === auxB);
          x++;
        }

        wasFound = matched;
        level++;
      }

      return wasFound;
    };

    return criteria;
  }
}
