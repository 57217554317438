import { isString as _isString } from 'lodash';
import { Privilege } from '@bolt/ui-shared/configuration';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';

export class Group {
  protected _section: string;
  protected _privileges: Privilege[];

  constructor(section: string) {
    this.initialize(section);
  }

  get section(): string {
    return this._section;
  }

  get privileges(): Privilege[] {
    return this._privileges;
  }

  /**
   * Retrieves the section name of the given privilege.
   *
   * @param privilege Privilege
   * @returns string
   */
  static getSectionFrom(privilege: Privilege): string {
    const nameParts: string[] = privilege.name.split('-');

    nameParts.splice(nameParts.length - 1, 1);
    return nameParts.join('-');
  }

  /**
   * Add the given privilege in the current map.
   *
   * @param privilege Privilege
   * @returns void
   */
  addPrivilege(privilege: Privilege): void {
    this.privileges.push(privilege);
  }

  /**
   * Initializes the instance.
   *
   * @param section string
   * @returns void
   */
  protected initialize(section: string): void {
    this.setupSection(section);
    this._privileges = new Array();
  }

  /**
   * Set up the section attribute.
   *
   * @param section string
   * @throws ErrorHelper
   * @returns void
   */
  protected setupSection(section: string): void {
    if (_isString(section)) {
      this._section = section;
    } else {
      throw new ErrorHelper('Invalid section for creating a privilege group');
    }
  }
}
