import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
  transform(value: object[], [fields, keyword]: [string[], string | string[]]) {
    if (!value || !keyword || !keyword.length) {
      return value;
    }

    let tokens: string[];

    if (Array.isArray(keyword)) {
      tokens = Array.from(keyword);
    } else {
      tokens = keyword.toString().split(' ');
    }

    tokens = tokens.filter((token) => !!token.length);

    let regexString: string = '';
    tokens.forEach((token, index) => {
      if (index + 1 < tokens.length) {
        regexString += '(?=.*\\b' + token + '\\b)';
      } else {
        regexString += '(?=.*\\b' + token + ')';
      }
    });

    return value.filter(
      (item) => fields.some(
        (field) => {
          const regex = new RegExp('^' + regexString + '.+', 'gmi');
          return regex.test(item[field]);
        }
      )
    );
  }
}
