import { GenreInterface, PrimaryProductAssociation, SecondaryProductAssociation } from '@bolt/ui-shared/master-data';

import { SeasonMetadataInterface } from './season-metadata.model';
import { TitleInterface, Title, TitleType } from './title.model';
import { SeasonAttributes } from './title/season/season-attributes.enum';
import { TitleAttributes } from './title/title-attributes.enum';


/**
 * @deprecated 2021-07-12: Use `Season` instead of this interface.
 */
export interface SeasonInterface extends TitleInterface {
  copyright?: string;
  countryOfOriginId?: number;
  mpmProductId?: string;
  numberOfEpisodes?: number;
  seasonProductionYear?: string;
  legalTitle?: string;
  productionCompanyId?: number;
  radarProductNumber?: number;
  seasonNumber?: number;
  seriesId?: number;
  keywords?: string[];
  shortSynopsis?: string;
  mediumSynopsis?: string;
  fullSynopsis?: string;
  genreId?: number[] | GenreInterface[];
  primaryProductAssociation?: number | PrimaryProductAssociation;
  secondaryProductAssociation?: number | SecondaryProductAssociation;
}

/**
 * @todo We have to re-implement the whole `Title` hierarchy class in order to not use Typescript set/get with underscore attribute. But
 * because this is part of the Bolt's core model, we need a tech debt story for doing this and move it to bolt-ui-shared project.
 * @see `SeasonAttributes`: Ensure that enumerative and this class are synchronized about public attributes.
 */
export class Season extends Title implements SeasonInterface {
  protected _copyright: string;
  protected _countryOfOriginId: number;
  protected _mpmProductId: string;
  protected _numberOfEpisodes: number;
  protected _seasonProductionYear: string;
  protected _legalTitle: string;
  protected _productionCompanyId: number;
  protected _radarProductNumber: number;
  protected _seasonNumber: number;
  protected _seasonPremiereDate: string;
  protected _seriesId: number;

  protected _keywords: string[];
  protected _shortSynopsis: string;
  protected _mediumSynopsis: string;
  protected _fullSynopsis: string;
  protected _genreId: number[] | GenreInterface[];
  protected _primaryProductAssociation: number | PrimaryProductAssociation;
  protected _secondaryProductAssociation: number | SecondaryProductAssociation;


  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
    this._type = TitleType.season;
  }

  /**
   * Returns the attributes names of a Season.
   *
   * @returns string[]
   */
  static getAttributesNames(): string[] {
    const attributes: string[] = [
      ...Object.keys(TitleAttributes),
      ...Object.keys(SeasonAttributes)
    ];

    return attributes;
  }

  get copyright(): string {
    return this._copyright;
  }

  set copyright(copyright: string) {
    this._copyright = copyright;
  }

  get countryOfOriginId(): number {
    return this._countryOfOriginId;
  }

  set countryOfOriginId(countryOfOriginId: number) {
    this._countryOfOriginId = countryOfOriginId;
  }

  get mpmProductId(): string {
    return this._mpmProductId;
  }

  set mpmProductId(mpmProductId: string) {
    this._mpmProductId = mpmProductId;
  }

  get numberOfEpisodes(): number {
    return this._numberOfEpisodes;
  }

  set numberOfEpisodes(numberOfEpisodes: number) {
    this._numberOfEpisodes = numberOfEpisodes;
  }

  get seasonProductionYear(): string {
    return this._seasonProductionYear;
  }

  set seasonProductionYear(seasonProductionYear: string) {
    this._seasonProductionYear = seasonProductionYear;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  set legalTitle(legalTitle: string) {
    this._legalTitle = legalTitle;
  }

  get productionCompanyId(): number {
    return this._productionCompanyId;
  }

  set productionCompanyId(productionCompanyId: number) {
    this._productionCompanyId = productionCompanyId;
  }

  get radarProductNumber(): number {
    return this._radarProductNumber;
  }

  set radarProductNumber(radarProductNumber: number) {
    this._radarProductNumber = radarProductNumber;
  }

  get seasonNumber(): number {
    return this._seasonNumber;
  }

  set seasonNumber(seasonNumber: number) {
    this._seasonNumber = seasonNumber;
  }

  get seasonPremiereDate(): string {
    return this._seasonPremiereDate;
  }

  set seasonPremiereDate(_seasonPremiereDate: string) {
    this._seasonPremiereDate = _seasonPremiereDate;
  }

  get seriesId(): number {
    return this._seriesId;
  }

  set seriesId(seriesId: number) {
    this._seriesId = seriesId;
  }

  get keywords(): string[] {
    return this._keywords;
  }

  set keywords(keywords: string[]) {
    this._keywords = keywords;
  }

  get shortSynopsis(): string {
    return this._shortSynopsis;
  }

  set shortSynopsis(shortSynopsis: string) {
    this._shortSynopsis = shortSynopsis;
  }

  get mediumSynopsis(): string {
    return this._mediumSynopsis;
  }

  set mediumSynopsis(mediumSynopsis: string) {
    this._mediumSynopsis = mediumSynopsis;
  }

  get fullSynopsis(): string {
    return this._fullSynopsis;
  }

  set fullSynopsis(fullSynopsis: string) {
    this._fullSynopsis = fullSynopsis;
  }

  get genreId(): number[] | GenreInterface[] {
    return this._genreId;
  }

  set genreId(genreId: number[] | GenreInterface[]) {
    this._genreId = genreId;
  }

  get localizations(): SeasonMetadataInterface[] {
    return this._localizations;
  }

  set localizations(localizations: SeasonMetadataInterface[]) {
    this._localizations = localizations;
  }

  get primaryProductAssociation(): number | PrimaryProductAssociation {
    return this._primaryProductAssociation;
  }

  set primaryProductAssociation(primaryProductAssociation: number | PrimaryProductAssociation) {
    this._primaryProductAssociation = primaryProductAssociation;
  }

  get secondaryProductAssociation(): number | SecondaryProductAssociation {
    return this._secondaryProductAssociation;
  }

  set secondaryProductAssociation(secondaryProductAssociation: number | SecondaryProductAssociation) {
    this._secondaryProductAssociation = secondaryProductAssociation;
  }

  getRawObject(): object {
    const names = Object.getOwnPropertyNames(Season.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Season.prototype, name);
      return !!result.get;
    });

    const object = new Object();
    getters.forEach(key => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return Object.assign(new Object(), super.getRawObject(), object);
  }
}
