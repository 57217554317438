export class Warnings {
  protected _deleted: number;

  constructor(data: any) {
    this.initialize(data);
  }

  get deleted(): number {
    return this._deleted;
  }

  /**
   * Returns the total quantity of warnings.
   *
   * @returns number
   */
  getTotal(): number {
    return this.deleted;
  }

  /**
   * Indicates if there are warnings.
   *
   * @returns boolean
   */
  hasWarnings(): boolean {
    const hasIt: boolean = (
      this.getTotal() > 0
    );

    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const deleted: number = (
      (data && data.hasOwnProperty('deleted'))
        ? data.deleted
        : 0
    );

    this._deleted = deleted;
  }
}
