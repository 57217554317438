import { Directive, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { WindowWrapperService } from '@bolt/ui-shared/common';


@Directive({
  selector: '[boltShortcut]'
})
export class BoltShortcutDirective implements OnInit, OnDestroy {
  @Output('boltShortcutPressed') shortcutEvent: EventEmitter<void> = new EventEmitter();
  protected keyListener: Subscription;

  constructor(protected windowWrapper: WindowWrapperService) {
    this.initialize();
  }

  ngOnDestroy(): void {
    this.keyListener.unsubscribe();
  }

  ngOnInit(): void {
    this.keyListener.add(
      fromEvent(this.windowWrapper.getWindow(), 'keydown').subscribe(
        (event: any) => {
          if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
            this.shortcutEvent.emit();
          }
        }
      )
    );
  }

  /**
   * Initialize the directive
   *
   * @returns void
   */
  protected initialize(): void {
    this.keyListener = new Subscription();
  }
}
