import * as moment from 'moment';
import { isBoolean as _isBoolean, isObject as _isObject, isString as _isString } from 'lodash';


export class LockingStatus {
  protected _locked: boolean;
  protected _lockedBy: string;
  protected _lockedDate: moment.Moment;
  protected _lockReason: string;

  constructor(data: any) {
    this.initialize(data);
  }

  static newFromItemsLockStatus(data: any): LockingStatus {
    const lockingStatus: LockingStatus = new LockingStatus({
      locked: data.itemsLocked,
      lockedBy: data.itemsLockedBy,
      lockedDate: data.itemsLockedDate
    });

    return lockingStatus;
  }

  get locked(): boolean {
    return this._locked;
  }

  set locked(locked: boolean) {
    this._locked = locked;
  }

  get lockedBy(): string {
    return this._lockedBy;
  }

  get lockedDate(): moment.Moment {
    return this._lockedDate;
  }

  get lockReason(): string {
    return this._lockReason;
  }

  /**
   * Indicates if it has a lock reason.
   *
   * @returns boolean
   */
  hasLockReason(): boolean {
    return _isString(this.lockReason);
  }

  /**
   * Indicates if it is truthy.
   *
   * @returns boolean
   */
  isTruthy(): boolean {
    return this.locked;
  }

  /**
   * Toggles the locked value.
   *
   * @returns void
   */
  toggle(): void {
    this._locked = !this._locked;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this._locked = (_isBoolean(data.locked) ? data.locked : false);
    this._lockedBy = (_isString(data.lockedBy) ? data.lockedBy : undefined);
    this._lockReason = (_isString(data.lockReason) ? data.lockReason : undefined);

    if (_isObject(data.lockedDate)) {
      this._lockedDate = data.lockedDate;
    } else if (_isString(data.lockedDate)) {
      this._lockedDate = moment(data.lockedDate);
    } else {
      this._lockedDate = undefined;
    }
  }
}
