import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { debounceTime } from 'rxjs/operators';

import { RoleInterface } from '../../models/role.model';
import { RoleManager } from '../../helpers/role-manager/role-manager.helper';
import { RoleMetadata } from '../../models/role-metadata.model';
import { RoleMetadataManagerActions } from '../bolt-role-metadata-manager/bolt-role-metadata-manager.component';

export interface BoltRoleMetadataFiltersInterface {
  filter: string[];
}


@Component({
  selector: 'bolt-role-metadata-filters',
  template: require('./bolt-role-metadata-filters.html'),
  styles: [require('./bolt-role-metadata-filters.scss')],
  encapsulation: ViewEncapsulation.None
})
export class BoltRoleMetadataFiltersComponent implements OnInit {
  @Input('BoltRoleMetadataFiltersRole') role: RoleInterface;
  @Output('BoltRoleMetadataFiltersFiltersUpdated') filtersUpdated: EventEmitter<BoltRoleMetadataFiltersInterface> =
    new EventEmitter<BoltRoleMetadataFiltersInterface>();

  public form: FormGroup;
  public filter: string;

  constructor(
    protected formBuilder: FormBuilder,
    protected appConfig: AppConfigProvider,
    protected roleManager: RoleManager,
    protected route: ActivatedRoute
  ) {
    this.form = formBuilder.group({
      filter: [],
    });
  }

  ngOnInit() {
    this.form.valueChanges.pipe(
      debounceTime(200)
    ).subscribe(
      val => {
        this.filtersUpdated.emit(<BoltRoleMetadataFiltersInterface>{
          filter: val.filter ? val.filter.trim().split(' ') : []
        });
      }
    );
  }

  /**
   * Resets the current filter.
   *
   * @returns void
   */
  resetFilter(): void {
    (<FormControl>this.form.get('filter')).setValue('');
  }

  /**
   * Prepares the create a new role process.
   *
   * @return void
   */
  addRoleMetadata(): void {
    this.roleManager.manageRoleMetadata(
      new RoleMetadata(),
      RoleMetadataManagerActions.CREATE,
      this.role
    );
  }
}
