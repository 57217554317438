import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Xslt, XsltInterface } from '../models/xslt.model';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';


@Injectable()
export class XsltService extends BoltAbstractService {
  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetches the XSLT configuration for the given ID.
   *
   * @param id number
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchXsltConfig(id: number): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.get(
      this.generateUrl('exportsService.xsltConfig.endpoint', { '{xsltId}': id })
    ).pipe(
      map(
        (response: any) => new StormServiceResponseSingle(new Xslt(response))
      )
    );

    return obs;
  }

  /**
   * Fetches the XSLT for the given ID.
   *
   * @param id number
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchXslt(id: number): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.get(
      this.generateUrl('exportsService.getXslt.endpoint', { '{xsltId}': id })
    ).pipe(
      map(
        (response: any) => new StormServiceResponseSingle(response)
      )
    );

    return obs;
  }

  /**
   * Fetches full metadata for the given payload.
   *
   * @param payload object
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchFullMetadata(payload: object): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.post(
      this.generateUrl('exportsService.fetchFullMetadata.endpoint'),
      JSON.stringify(payload)
    ).pipe(
      map(
        (response: any) => new StormServiceResponseSingle(response)
      )
    );

    return obs;
  }

  /**
   * Transaforms the full metadata for the given payload.
   *
   * @param payload object
   * @returns Observable<any>
   */
  transformFullMetadata(payload: object): Observable<any> {
    const obs: Observable<any> = this.authHttp.post(
      this.generateUrl('exportsService.transformFullMetadata.endpoint'),
      JSON.stringify(payload),
      { responseType: 'blob' }
    );

    return obs;
  }

  /**
   * Updates the XSLT content for the given ID using the given payload.
   *
   * @param id number
   * @param payload object
   * @returns Observable<StormServiceResponseSingle>
   */
  updateXsltContent(id: number, payload: object): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.post(
      this.generateUrl('exportsService.updateXslt.endpoint', { '{xsltId}': id }),
      JSON.stringify(payload)
    ).pipe(
      map(
        (response: any) => new StormServiceResponseSingle(response)
      )
    );

    return obs;
  }

  /**
   * Searches the XSLT using the given params.
   *
   * @param params \{ [propName: string]: any }
   * @returns StormServiceResponseCollection
   */
  searchXslt(params: { [propName: string]: any }): Observable<StormServiceResponseCollection> {
    const search: UrlSearchParams = new UrlSearchParams();

    Object.keys(params).forEach(
      (key: string) => {
        search.set(key, params[key]);
      }
    );

    const url: string = this.generateUrl('exportsService.searchXslt.endpoint', undefined, search);

    const obs: Observable<StormServiceResponseCollection> = this.authHttp.get(url).pipe(
      map(
        (response: any) => {
          const mappedResponse: StormServiceResponseCollection = new StormServiceResponseCollection(
            response.content,
            Number(response.number) + 1, // API paginates starting at index 0
            Number(response.size),
            Number(response.totalPages),
            Number(response.totalElements),
            response.sort.property,
            response.sort.direction
          );

          return mappedResponse;
        }
      )
    );

    return obs;
  }

  /**
   * Downloads the XSLT with the given ID.
   *
   * @param xslt Xslt
   * @returns Observable<any>
   */
  downloadXslt(xsltId: number): Observable<any> {
    const options: any = {
      responseType: 'blob',
      observe: 'response'
    };

    const obs: Observable<any> = this.authHttp.get(
      this.generateUrl('exportsService.downloadXslt.endpoint', { '{xsltId}': xsltId }),
      options
    );

    return obs;
  }

  /**
   * Set the given XSLT configuration.
   *
   * @param xslt XsltInterface
   * @returns Observable<any>
   */
  setXsltConfig(xslt: XsltInterface): Observable<any> {
    const url: string = this.generateUrl('exportsService.xsltConfig.endpoint', { '{xsltId}': xslt.id });

    const obs: Observable<any> = this.authHttp.post(
      url,
      JSON.stringify(xslt.getRawObject())
    );

    return obs;
  }

  /**
   * Deletes the given XSLT.
   *
   * @param xslt XsltInterface
   * @returns Observable<any>
   */
  deleteXslt(xslt: XsltInterface): Observable<any> {
    const obs: Observable<any> = this.authHttp.delete(
      this.generateUrl('exportsService.deleteXslt.endpoint', { '{xsltId}': xslt.id })
    );

    return obs;
  }
}
