import { isNumber as _isNumber, isObject as _isObject, isString as _isString, isUndefined as _isUndefined } from 'lodash';
import { Language } from '@bolt/ui-shared/master-data';

import { AttributeEnum as LockAttributeEnum } from './lock/attribute.enum';
import { Element } from 'app/modules/search/models/element/element.model';
import { Episode } from 'app/modules/title/models/episode.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Lock } from './lock/lock.model';
import { Title } from 'app/modules/title/models/title.model';
import { Type } from './type/type.model';
import { TypeEnum } from './type/type.enum';


export class Entity {
  protected _id: number;
  protected _mapKey: string;
  protected _name: string;
  protected _parentId: number;
  protected _parentMapKey: string;
  protected _parentType: Type;
  protected _type: Type;
  protected accountMetadataLock: Lock;
  protected languageMetadataLocks: Map<string, any>;
  protected territoryMetadataLocks: Map<string, any>;
  protected titleMetadataLock: Lock;

  constructor(data: any) {
    this.setId(data);
    this.setName(data);
    this.setType(data);
    this.setParent(data);
    this.clearLocks();
  }

  /**
   * Returns a new entity using the given Element.
   *
   * @param element Element
   * @returns Entity
   */
  static newFromElement(element: Element): Entity {
    const data: any = {
      entityId: element.id,
      entityName: element.name,
      entityType: element.type.toString()
    };

    const entity: Entity = new Entity(data);

    return entity;
  }

  /**
   * Returns a new entity using the given Title.
   *
   * @param title Title
   * @returns Entity
   */
  static newFromTitle(title: Title): Entity {
    const data: any = {
      entityId: title.id,
      entityType: title.type.toString()
    };

    if (title.isEpisode()) {
      const episode: Episode = <Episode>title;

      data.entityName = _isUndefined(episode.f0_num)
        ? title.prettifiedLegalTitle :
        `[${episode.f0_num}] ${title.prettifiedLegalTitle}`;

      data.seasonId = episode.seasonId;
    } else {
      data.entityName = title.prettifiedLegalTitle;
    }

    const entity: Entity = new Entity(data);

    return entity;
  }

  get id(): number {
    return this._id;
  }

  get mapKey(): string {
    return this._mapKey;
  }

  get name(): string {
    return this._name;
  }

  get parentId(): number {
    return this._parentId;
  }

  get parentMapKey(): string {
    return this._parentMapKey;
  }

  get parentType(): Type {
    return this._parentType;
  }

  get type(): Type {
    return this._type;
  }

  /**
   * Adds the given lock.
   *
   * @param lock Lock
   * @throws ErrorHelper
   * @returns void
   */
  addLock(lock: Lock): void {
    if (this.mapKey === lock.mapKey) {
      if (_isUndefined(this.accountMetadataLock)) {
        this.accountMetadataLock = lock;
      }

      if (_isUndefined(this.titleMetadataLock)) {
        this.titleMetadataLock = lock;
      }

      if (Language.isAll(lock.languageCode)) {
        this.territoryMetadataLocks.set(lock.territoryCode, lock);
      } else {
        this.languageMetadataLocks.set(lock.languageCode, lock);
      }
    } else {
      throw new ErrorHelper(`The given lock does not match with the entity.`);
    }
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns any
   */
  asEndpointData(): any {
    const data: any = {
      entityId: this.id,
      entityType: this.type.toString().toUpperCase()
    };

    return data;
  }

  /**
   * Removes the stored locks.
   *
   * @returns void
   */
  clearLocks(): void {
    this.accountMetadataLock = undefined;
    this.languageMetadataLocks = new Map();
    this.territoryMetadataLocks = new Map();
    this.titleMetadataLock = undefined;  }

  /**
   * Indicates if it has a parent.
   *
   * @returns boolean
   */
  hasParent(): boolean {
    return _isNumber(this.parentId);
  }

  /**
   * Indicates if it has locks.
   *
   * @returns boolean
   */
  hasLocks(): boolean {
    const hasIt: boolean =
      _isObject(this.accountMetadataLock) ||
      this.languageMetadataLocks.size > 0 ||
      this.territoryMetadataLocks.size > 0 ||
      _isObject(this.titleMetadataLock);

    return hasIt;
  }

  /**
   * Returns the lock for the given attribute and code, if the last parameter is defined.
   *
   * @param attribute AttributeEnum
   * @param code string
   * @returns Lock
   */
  getLockFor(attribute: LockAttributeEnum, code?: string): Lock {
    switch (attribute) {
      case LockAttributeEnum.account:
        return this.getLockForAccountMetadata();
      case LockAttributeEnum.dubbingCast:
      case LockAttributeEnum.dubbingCrew:
      case LockAttributeEnum.originalCast:
      case LockAttributeEnum.originalCrew:
        return this.getLockForCreditsMetadata(code);
      case LockAttributeEnum.language:
        return this.getLockForLanguageMetadata(code);
      case LockAttributeEnum.localizedSubtitles:
      case LockAttributeEnum.originalSubtitles:
        return this.getLockForSubtitlesMetadata(code);
      case LockAttributeEnum.territory:
        return this.getLockForTerritoryMetadata(code);
      case LockAttributeEnum.title:
        return this.getLockForTitleMetadata();
      default:
        throw new ErrorHelper(`Invalid attribute given for a lock: ${attribute}`);
    }
  }

  /**
   * Returns the lock for account metadata.
   *
   * @returns Lock
   */
  getLockForAccountMetadata(): Lock {
    return this.accountMetadataLock;
  }

  /**
   * Returns the lock for Cast & Crew Original/Dubbing metadata, using the given language code.
   *
   * @param languageCode string
   * @returns Lock
   */
  getLockForCreditsMetadata(languageCode: string): Lock {
    return this.findLockByLanguage(languageCode);
  }

  /**
   * Returns the lock for language metadata using the given code.
   *
   * @param languageCode string
   * @returns Lock
   */
  getLockForLanguageMetadata(languageCode: string): Lock {
    return this.findLockByLanguage(languageCode);
  }

  /**
   * Returns the lock for subtitles metadata using the given language code.
   *
   * @param languageCode string
   * @returns Lock
   */
  getLockForSubtitlesMetadata(languageCode: string): Lock {
    return this.findLockByLanguage(languageCode);
  }

  /**
   * Returns the lock for title metadata.
   *
   * @returns Lock
   */
  getLockForTitleMetadata(): Lock {
    return this.titleMetadataLock;
  }

  /**
   * Returns the lock for territory metadata using the given code.
   *
   * @param territoryCode string
   * @returns Lock
   */
  getLockForTerritoryMetadata(territoryCode: string): Lock {
    return this.findLockByTerritory(territoryCode);
  }

  /**
   * Discovers the map key.
   *
   * @returns void
   */
  protected discoverMapKey(): void {
    this._mapKey = `${this.type}-${this.id}`;
  }

  /**
   * Finds and returns the lock with the given language code.
   *
   * @param code string
   * @throws ErrorHelper
   * @returns Lock
   */
  protected findLockByLanguage(code: string): Lock {
    if (this.languageMetadataLocks.size > 0) {
      if (this.languageMetadataLocks.has(code)) {
        return this.languageMetadataLocks.get(code);
      } else {
        throw new ErrorHelper(`Given language "${code}" does not exist in locks.`);
      }
    }
  }

  /**
   * Finds and returns the lock with the given territory code.
   *
   * @param code string
   * @throws ErrorHelper
   * @returns Lock
   */
  protected findLockByTerritory(code: string): Lock {
    if (this.territoryMetadataLocks.size > 0) {
      if (this.territoryMetadataLocks.has(code)) {
        return this.territoryMetadataLocks.get(code);
      } else {
        throw new ErrorHelper(`Given territory "${code}" does not exist in locks.`);
      }
    }
  }

  /**
   * Set the ID.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setId(data: any): void {
    if (_isNumber(data.entityId)) {
      this._id = data.entityId;
    } else {
      throw new ErrorHelper('Invalid ID given for an entity.');
    }
  }

  /**
   * Set the name.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setName(data: any): void {
    if (_isString(data.entityName)) {
      const name: string = data.entityName.trim();

      if (name.length === 0) {
        throw new ErrorHelper('Empty name given for an entity.');
      } else {
        this._name = name;
      }
    } else {
      throw new ErrorHelper('Invalid name given for an entity.');
    }
  }

  /**
   * Set the parent ID.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setParent(data: any): void {
    if (this.type.isEpisode()) {
      if (_isNumber(data.seasonId)) {
        this._parentId = data.seasonId;
        this._parentType = new Type(TypeEnum.Season);
        this._parentMapKey = `${this.parentType.toString()}-${this.parentId}`;
      } else {
        throw new ErrorHelper('Invalid parent ID given for an entity.');
      }
    }
  }

  /**
   * Set the type.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setType(data: any): void {
    this._type = new Type(data.entityType);
    this.discoverMapKey();
  }

  /**
   * Returns the output text.
   *
   * @returns string
   */
  protected toString(): string {
    return this.name;
  }
}
