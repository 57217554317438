import { FormControl, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { isBoolean as _isBoolean, isString as _isString } from 'lodash';

import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';


export abstract class TalentForm extends BoltForm {
  protected firstNameField: FormControl;
  protected key: string;
  protected lastNameField: FormControl;
  protected middleNameField: FormControl;
  protected noLastNameField: FormControl;
  protected noMiddleNameField: FormControl;
  protected noPrefixField: FormControl;
  protected noSuffixField: FormControl;
  protected originalLanguageIdField: FormControl;
  protected prefixIdField: FormControl;
  protected suffixIdField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.key = this.getDefaultValue('key');
  }

  /**
   * Get the first name field.
   *
   * @returns FormControl
   */
  getFirstNameField(): FormControl {
    return this.firstNameField;
  }

  /**
   * Get the key attribute.
   *
   * @returns string
   */
  getKey(): string {
    return this.key;
  }

  /**
   * Get the last name field.
   *
   * @returns FormControl
   */
  getLastNameField(): FormControl {
    return this.lastNameField;
  }

  /**
   * Get the middle name field.
   *
   * @returns FormControl
   */
  getMiddleNameField(): FormControl {
    return this.middleNameField;
  }

  /**
   * Get the no middle name field.
   *
   * @returns FormControl
   */
  getNoMiddleNameField(): FormControl {
    return this.noMiddleNameField;
  }

  /**
   * Get the no last name field.
   *
   * @returns FormControl
   */
  getNoLastNameField(): FormControl {
    return this.noLastNameField;
  }

  /**
   * Get the no prefix field.
   *
   * @returns FormControl
   */
  getNoPrefixField(): FormControl {
    return this.noPrefixField;
  }

  /**
   * Get the no suffix field.
   *
   * @returns FormControl
   */
  getNoSuffixField(): FormControl {
    return this.noSuffixField;
  }

  /**
   * Get the original language id field.
   *
   * @returns FormControl
   */
  getOriginalLanguageIdField(): FormControl {
    return this.originalLanguageIdField;
  }

  /**
   * Get the prefix id field.
   *
   * @returns FormControl
   */
  getPrefixIdField(): FormControl {
    return this.prefixIdField;
  }

  /**
   * Get the suffix id field.
   *
   * @returns FormControl
   */
  getSuffixIdField(): FormControl {
    return this.suffixIdField;
  }

  toJson(): any {
    const noLastNameValue: boolean = _isBoolean(this.getNoLastNameField().value) ? this.getNoLastNameField().value : false;
    const noMiddleNameValue: boolean = _isBoolean(this.getNoMiddleNameField().value) ? this.getNoMiddleNameField().value : false;
    const noPrefixValue: boolean = _isBoolean(this.getNoPrefixField().value) ? this.getNoPrefixField().value : false;
    const noSuffixValue: boolean = _isBoolean(this.getNoSuffixField().value) ? this.getNoSuffixField().value : false;
    const lastNameValue: string = noLastNameValue ? null : this.getLastNameField().value;

    const data: any = {
      originalLanguageId: this.getOriginalLanguageIdField().value,
      firstName: this.getFirstNameField().value,
      middleName: noMiddleNameValue ? null : this.getMiddleNameField().value,
      lastName: lastNameValue,
      noMiddleName: noMiddleNameValue,
      noLastName: noLastNameValue,
      noPrefix: noPrefixValue,
      noSuffix: noSuffixValue,
      prefixId: noPrefixValue ? null : this.getPrefixIdField().value,
      suffixId: noSuffixValue ? null : this.getSuffixIdField().value
    };

    // TODO: This need to be removed after coordinating with API team when it's not necessary anymore.
    data.allowSingleName = noLastNameValue;

    return data;
  }

  /**
   * Returns a custom required validator function for the no-affix checkbox.
   *
   * @param noAffixControl AbstractControl
   * @returns ValidatorFn | undefined
   */
  protected abstract getNoAffixCustomRequiredValidator(noAffixControl: AbstractControl): ValidatorFn | undefined;

  /**
   * Returns a custom required validator function for the no-name checkbox.
   *
   * @param noNameControl AbstractControl
   * @returns ValidatorFn
   */
  protected getNoNameCustomRequiredValidator(noNameControl: AbstractControl): ValidatorFn {
    const validator: ValidatorFn = (control: FormControl) => {
      if (!noNameControl.value && (!_isString(control.value) || (_isString(control.value) && control.value.length === 0))) {
        return {
          required: true,
          dirty: control.dirty,
        };
      }
    };

    return validator;
  }

  protected setupFields(): void {
    this.setupNoLastName();
    this.setupNoMiddleName();
    this.setupNoPrefix();
    this.setupNoSuffix();
    this.setupFirstName();
    this.setupLastName();
    this.setupMiddleName();
    this.setupOriginalLanguageId();
    this.setupPrefixId();
    this.setupSuffixId();
  }

  /**
   * Set up the first name field.
   *
   * @returns void
   */
  protected setupFirstName(): void {
    this.firstNameField = new FormControl(this.getDefaultValue('firstName'), [
      Validators.required,
      Validators.maxLength(this.fieldSpecs.firstName.maxLength),
    ]);

    this.addControl('firstName', this.firstNameField);
  }

  /**
   * Set up the last name field.
   *
   * @returns void
   */
  protected setupLastName(): void {
    this.lastNameField = new FormControl(
      this.getDefaultValue('lastName'),
      [
        this.getNoNameCustomRequiredValidator(this.controls['noLastName']),
        Validators.maxLength(this.fieldSpecs.lastName.maxLength),
      ]
    );

    this.addControl('lastName', this.lastNameField);
  }

  /**
   * Set up the middle name field.
   *
   * @returns void
   */
  protected setupMiddleName(): void {
    this.middleNameField = new FormControl(
      this.getDefaultValue('middleName'),
      [
        this.getNoNameCustomRequiredValidator(this.controls['noMiddleName']),
        Validators.maxLength(this.fieldSpecs.middleName.maxLength)
      ]
    );

    this.addControl('middleName', this.middleNameField);
  }

  /**
   * Set up the no middle name field.
   *
   * @returns void
   */
  protected setupNoMiddleName(): void {
    this.noMiddleNameField = new FormControl(this.getDefaultValue('noMiddleName'));
    this.addControl('noMiddleName', this.noMiddleNameField);
  }

  /**
   * Set up the no last name field.
   *
   * @returns void
   */
  protected setupNoLastName(): void {
    this.noLastNameField = new FormControl(this.getDefaultValue('noLastName'));
    this.addControl('noLastName', this.noLastNameField);
  }

  /**
   * Set up the no prefix field.
   *
   * @returns void
   */
  protected setupNoPrefix(): void {
    this.noPrefixField = new FormControl(this.getDefaultValue('noPrefix'));
    this.addControl('noPrefix', this.noPrefixField);
  }

  /**
   * Set up the no suffix field.
   *
   * @returns void
   */
  protected setupNoSuffix(): void {
    this.noSuffixField = new FormControl(this.getDefaultValue('noSuffix'));
    this.addControl('noSuffix', this.noSuffixField);
  }

  /**
   * Set up the original language id field.
   *
   * @returns void
   */
  protected setupOriginalLanguageId(): void {
    this.originalLanguageIdField = new FormControl(this.getDefaultValue('originalLanguageId'), Validators.required);
    this.addControl('originalLanguageId', this.originalLanguageIdField);
  }

  /**
   * Set up the prefix id field.
   *
   * @returns void
   */
  protected setupPrefixId(): void {
    this.prefixIdField = new FormControl(
      this.getDefaultValue('prefixId'),
      this.getNoAffixCustomRequiredValidator(this.controls['noPrefix'])
    );

    this.addControl('prefixId', this.prefixIdField);
  }

  /**
   * Set up the suffix id field.
   *
   * @returns void
   */
  protected setupSuffixId(): void {
    this.suffixIdField = new FormControl(
      this.getDefaultValue('suffixId'),
      this.getNoAffixCustomRequiredValidator(this.controls['noSuffix'])
    );

    this.addControl('suffixId', this.suffixIdField);
  }
}
