import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class StringEncoderService {
  constructor() { }

  /**
   * Returns a new Blob the encoded Blob for a given content type
   *
   * @param rawData string
   * @param contentType string
   * @returns Blob
   */
  newBlobFor(rawData: string, contentType: string): Blob {
    const type: string = contentType.toLocaleLowerCase();
    const blobParts: BlobPart[] = new Array();

    if (type.includes('utf-16')) {
      blobParts.push(this.utf16leEncode(rawData));
    } else if (type.includes('iso-8859-1')) {
      blobParts.push(new Buffer(rawData, 'latin1'));
    } else {
      blobParts.push(rawData);
    }

    return new Blob(blobParts, { type: contentType });
  }

  /**
   * Gets the little Endian UTF-16 BLOB given a string of data
   *
   * @param rawData string
   * @returns Uint8Array
   */
  protected utf16leEncode(rawData: string): Uint8Array {
    const byteArray: any = new Array();
    let charCode: any = new Array();

    byteArray.push(255, 254);

    for (let i = 0; i < rawData.length; ++i) {
      charCode = rawData.charCodeAt(i);

      // tslint:disable: no-bitwise
      byteArray.push(charCode & 0xFF);
      byteArray.push(charCode / 256 >>> 0);
      // tslint:enable: no-bitwise
    }

    return new Uint8Array(byteArray);
  }
}
