import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isObject as _isObject } from 'lodash';

import { Status } from '../../models/event/status/status.model';
import { StatusEnum } from '../../models/event/status/status.enum';
import { Type } from '../../models/event/type/type.model';
import { TypeEnum } from '../../models/event/type/type.enum';


@Component({
  selector: 'bolt-cpm-events-filters',
  template: require('./bolt-cpm-events-filters.html'),
  styles: [require('./bolt-cpm-events-filters.scss')]
})
export class BoltCpmEventsFiltersComponent {
  @Input() disabled: boolean;
  @Output('statusChanged') statusChangeEvent: EventEmitter<StatusEnum>;
  @Output('typeChanged') typeChangeEvent: EventEmitter<TypeEnum>;

  protected typeItems: SelectionItem[];
  protected statusItems: SelectionItem[];

  constructor(protected appConfig: AppConfigProvider) {
    this.disabled = false;
    this.statusChangeEvent = new EventEmitter();
    this.typeChangeEvent = new EventEmitter();
    this.statusItems = Status.asSelectionItemList();
    this.typeItems = Type.asSelectionItemList();
  }

  /**
   * Returns the scrollHeight from AppConfig.
   *
   * @returns string
   */
  protected getScrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Notifies the selected type using the given event.
   *
   * @param event any
   * @returns void
   */
  protected notifyTypeChange(event: any): void {
    this.typeChangeEvent.emit(event.value);
  }

  /**
   * Notifies the selected status using the given event.
   *
   * @param event any
   * @returns void
   */
  protected notifyStatusChange(event: any): void {
    this.statusChangeEvent.emit(event.value);
  }
}
