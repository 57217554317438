import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Item } from './item/item.model';
import { Template } from '../../template/template.model';


export class RequestFileData {
  protected _items: Item[];
  protected _template: Template;

  constructor(template: Template) {
    this.initialize(template);
  }

  get items(): Item[] {
    return this._items;
  }

  get template(): Template {
    return this._template;
  }

  /**
   * Adds the given item.
   *
   * @param item Item
   * @returns void
   */
  addItem(item: Item): void {
    this._items.push(item);
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns object[]
   */
  asEndpointData(): object[] {
    const data: object[] = new Array();

    this.items.forEach(
      (item: Item) => {
        data.push(item.asEndpointData());
      }
    );

    return data;
  }

  /**
   * Indicates if there are items.
   *
   * @returns boolean
   */
  hasItems(): boolean {
    const hasIt: boolean = (this.items.length > 0);
    return hasIt;
  }

  /**
   * Reset the inner data.
   *
   * @returns void
   */
  reset(): void {
    this._items = new Array();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(template: Template): void {
    this.setTemplate(template);
    this.reset();
  }

  /**
   * Set the template.
   *
   * @param template Template
   * @throws {ErrorHelper}
   * @returns void
   */
  protected setTemplate(template: Template): void {
    if (template instanceof Template) {
      this._template = template;
    } else {
      throw new ErrorHelper('Invalid template.');
    }
  }
}
