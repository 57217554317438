import { Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { isObject as _isObject } from 'lodash';

import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Title } from 'app/modules/title/models/title.model';


export abstract class BoltCloneListItemComponent extends StormComponent implements OnDestroy {
  @Input() active: boolean;
  @Input() locale: string;
  @Input() title: Title;

  @Output('selected') selectEvent: EventEmitter<Title>;

  protected lockingStatus: LockingStatus;
  protected lockingStatusSubscription: Subscription;

  ngOnDestroy() {
    this.cancelLockingStatusSubscription();
  }

  /**
   * Cancels the locking status subscription.
   *
   * @returns void
   */
  protected cancelLockingStatusSubscription(): void {
    if (_isObject(this.lockingStatusSubscription)) {
      this.lockingStatusSubscription.unsubscribe();
    }
  }

  /**
   * Fetches the locking status for the current title.
   *
   * @returns void
   */
  protected abstract fetchLockingStatus(): void;

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.selectEvent = new EventEmitter();
  }

  /**
   * Sets the given title as the current selection.
   *
   * @param title Title
   * @returns void
   */
  protected notifySelection(title: Title): void {
    this.selectEvent.emit(title);
  }
}
