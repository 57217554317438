import { Component, Input } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { WindowWrapperService } from '@bolt/ui-shared/common';
import { Observable, of } from 'rxjs';

import { BoltMergeManagerComponent } from '../bolt-merge-manager/bolt-merge-manager.component';
import { CandidateTypeEnum } from '../../models/candidate-type.enum';
import { MergeService } from '../../services/merge.service';
import { Role } from 'app/modules/role/models/role.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Component({
  selector: 'bolt-merge-role-manager',
  template: require('./bolt-merge-role-manager.html'),
  styles: [require('./bolt-merge-role-manager.scss')]
})
export class BoltMergeRoleManagerComponent extends BoltMergeManagerComponent {
  @Input() role: Role;

  protected mergeCandidate: Role;

  constructor(
    protected mergeService: MergeService,
    protected notificationService: NotificationService,
    protected windowWrapper: WindowWrapperService
  ) {
    super(mergeService, notificationService, windowWrapper);
  }

  protected mapResponse(response: StormServiceResponseSingle): Observable<Role> {
    return of(new Role(response.item[0]));
  }

  /**
   * Merge the current merge candidate into the current role.
   *
   * @returns void
   */
  protected merge(): void {
    this.doMerge(this.mergeCandidate.id, this.role.id, CandidateTypeEnum.role);
  }
}
