import { isObject as _isObject, isString as _isString } from 'lodash';
import { Language } from '@bolt/ui-shared/master-data';

import { Category } from '../../../../models/category/category.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Unit } from 'app/modules/cat/models/unit/unit.model';


export class Filters {
  protected _category: Category;
  protected _language: Language;
  protected _name: string;

  constructor() {
    this.initialize();
  }

  /**
   * Indicates if the filters can be applied on the given unit.
   *
   * @returns boolean
   */
  canBeAppliedOn(unit: Unit): boolean {
    let isIt: boolean = true;

    if (this.isCategoryDefined()) {
      isIt = unit.original.category.isEqualsTo(this.getCategory());
    }

    if (isIt && this.isNameDefined()) {
      isIt = (isIt && unit.original.name.toLocaleLowerCase().includes(this.getName()));
    }

    return isIt;
  }

  /**
   * Returns the category.
   *
   * @returns Category
   */
  getCategory(): Category {
    return this._category;
  }

  /**
   * Returns the language.
   *
   * @returns Language
   */
  getLanguage(): Language {
    return this._language;
  }

  /**
   * Returns the name.
   *
   * @returns string
   */
  getName(): string {
    return this._name;
  }


  /**
   * Indicates if any filter is defined.
   *
   * @returns boolean
   */
  isAnyoneDefined(): boolean {
    const isIt: boolean = (this.isCategoryDefined() || this.isLanguageDefined() || this.isNameDefined());
    return isIt;
  }

  /**
   * Indicates if it has a stored category.
   *
   * @returns boolean
   */
  isCategoryDefined(): boolean {
    return _isObject(this.getCategory());
  }

  /**
   * Indicates if it has a stored language.
   *
   * @returns boolean
   */
  isLanguageDefined(): boolean {
    return _isObject(this.getLanguage());
  }

  /**
   * Indicates if it has a stored name.
   *
   * @returns boolean
   */
  isNameDefined(): boolean {
    const hasIt: boolean = (_isString(this.getName()) && (this.getName().length > 0));
    return hasIt;
  }

  /**
   * Remove the category.
   *
   * @returns void
   */
  removeCategory(): void {
    this._category = undefined;
  }

  /**
   * Remove the language.
   *
   * @returns void
   */
  removeLanguage(): void {
    this._language = undefined;
  }

  /**
   * Remove the name.
   *
   * @returns void
   */
  removeName(): void {
    this._name = undefined;
  }

  /**
   * Reset it.
   *
   * @returns void
   */
  reset(): void {
    this.removeCategory();
    this.removeLanguage();
    this.removeName();
  }

  /**
   * Set the category.
   *
   * @param category Category
   * @throws ErrorHelper
   * @returns void
   */
  setCategory(category: Category) {
    if (category instanceof Category) {
      this._category = category;
    } else {
      throw new ErrorHelper('Invalid category given');
    }
  }

  /**
   * Set the language.
   *
   * @param language Language
   * @returns void
   */
  setLanguage(language: Language) {
    if (language instanceof Language) {
      this._language = language;
    } else {
      throw new ErrorHelper('Invalid language given');
    }
  }

  /**
   * Set the name.
   *
   * @param name string
   * @returns void
   */
  setName(name: string) {
    const error: string = 'Invalid name given';

    if (_isString(name)) {
      name = name.trim().toLocaleLowerCase();

      if (name.length > 0) {
        this._name = name.trim().toLocaleLowerCase();
      } else {
        throw new ErrorHelper(error);
      }
    } else {
      throw new ErrorHelper(error);
    }
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.reset();
  }
}
