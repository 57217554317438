import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigProvider, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { pickBy as _pickBy, isObject as _isObject, isString as _isString } from 'lodash';
import { debounceTime, map } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';

import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { LocalizationSpecific } from '../../models/localization-specific.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';

import {
  StormServiceResponseCollectionInterface, StormServiceResponsePaginationInterface, StormServiceResponseSortingInterface,
  StormServiceResponsePagination, StormServiceResponseSorting
} from 'app/modules/common/services/storm-service-response-collection';

import { Title, TitleType } from '../../models/title.model';
import { TitleService, TitleServiceFetchTitlesParamsInterface } from '../../services/title.service';


@Component({
  selector: 'storm-title-list',
  template: require('./storm-title-list.html'),
  styles: [require('./storm-title-list.scss')]
})
export class StormTitleListComponent extends StormComponent implements OnInit, OnChanges, OnDestroy  {
  @Input('stormTitleListFilters') filters: FormGroup;
  @Output('stormTitleListOnSearch') onSearch: EventEmitter<DataStatusEnum>;

  listData: Observable<StormServiceResponseCollectionInterface>;
  pagination: StormServiceResponsePaginationInterface;
  sorting: StormServiceResponseSortingInterface;
  titles: Title[];

  protected localizationSpecific: typeof LocalizationSpecific;
  protected searchCriteria: any;
  protected titleSearchSubscription: Subscription;
  protected keyTypingDebouncerSubscription: Subscription;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected router: Router,
    protected titleService: TitleService
  ) {
    super();
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters.currentValue) {
      this.filters.valueChanges.pipe(
        debounceTime(
          this.appConfig.get('ux.typing.debounceTime', 0)
        ),
        map(
          filters => <TitleServiceFetchTitlesParamsInterface>filters
        )
      )
      .subscribe(
        (filters: any) => {
          if (this.filters.valid) {
            this.loadPage(1, filters);
          }
        }
      );
    }
  }

  ngOnDestroy() {
    if (_isObject(this.keyTypingDebouncerSubscription)) {
      this.keyTypingDebouncerSubscription.unsubscribe();
    }

    if (_isObject(this.titleSearchSubscription)) {
      this.titleSearchSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.searchCriteria = this.getDefaultSearchCriteria();

    this.keyTypingDebouncerSubscription = this.activatedRoute.params.pipe(
      debounceTime(
        this.appConfig.get('ux.typing.debounceTime', 0)
      )
    )
    .subscribe(
      (params: Params) => {
        if (Object.keys(params).length) {
          Object.assign(this.searchCriteria, params);
        } else {
          this.searchCriteria = this.getDefaultSearchCriteria();
        }

        this.fetchTitles();
      }
    );
  }

  /**
   * Returns the default search criteria.
   *
   * @return any
   */
  getDefaultSearchCriteria(): any {
    const criteria: any = {
      q: '',
      type: '',
      page_size: <number>this.appConfig.get('ux.dataTables.pageSize', 20),
      page: 1
    };

    return criteria;
  }

  /**
   * Navigates to the corresponding title list page, based on the provided params.
   *
   * @param page number
   * @param searchCriteria any
   * @return Promise<boolean>
   */
  loadPage(page: number, searchCriteria: any): Promise<boolean> {
    const aPromise: Promise<boolean> = this.router.navigate([
      '/titles',
      _pickBy(
        Object.assign(this.searchCriteria, searchCriteria, { page: page }),
        (param) => param
      )
    ]);

    return aPromise;
  }

  /**
   * Indicates if it can navigate to Cast & Crew.
   *
   * @param titleType TitleType
   * @returns boolean
   */
  protected canNavigateToCastAndCrew(titleType: TitleType): boolean {
    const canIt: boolean = (titleType !== TitleType.series);
    return canIt;
  }

  /**
   * Indicates if it can navigate to Insert & Subtitles.
   *
   * @param titleType TitleType
   * @returns boolean
   */
  protected canNavigateToInsertAndSubtitles(titleType: TitleType): boolean {
    const canIt: boolean = ((titleType === TitleType.feature) || (titleType === TitleType.episode));
    return canIt;
  }

  /**
   * Indicates if it can navigate to Metadata Export.
   *
   * @param titleType TitleType
   * @returns boolean
   */
  protected canNavigateToMetadataExport(titleType: TitleType): boolean {
    const canIt: boolean = ((titleType === TitleType.feature) || (titleType === TitleType.episode));
    return canIt;
  }

  /**
   * Indicates if it can navigate to Territory Localizations.
   *
   * @param titleType TitleType
   * @returns boolean
   */
  protected canNavigateToTerritoryLocalizations(titleType: TitleType): boolean {
    const canIt: boolean = ((titleType === TitleType.feature) || (titleType === TitleType.episode));
    return canIt;
  }

  /**
   * Fetch titles from the service
   *
   * @returns void
   */
  protected fetchTitles(): void {
    if (!this.hasSearchCriteria()) {
      return;
    }

    this.changeStatusToFetchingData();
    this.onSearch.emit(this.status);

    if (_isObject(this.titleSearchSubscription)) {
      this.titleSearchSubscription.unsubscribe();
    }

    this.titleSearchSubscription = this.titleService.search(this.searchCriteria).subscribe(
      (response: any) => {
        this.titles = response.collection;
        this.pagination = response.pagination;
        this.sorting = response.sorting;

        if (response.collection.length) {
          this.changeStatusToDataFound();
        } else {
          this.changeStatusToDataNotFound();
        }
      },
      (error: any) => {
        this.changeStatusToError();
      },
      () => {
        this.onSearch.emit(this.status);
      }
    );
  }

  /**
   * Indicates if the user has selected a search criteria by entering some text or selecting a type
   *
   * @returns boolean
   */
  protected hasSearchCriteria(): boolean {
    const q: string = this.searchCriteria.q;
    const type: string = this.searchCriteria.type;
    const hasQuery: boolean = _isString(q) && q.length > 0;
    const hasType: boolean = _isString(type) && type.length > 0;

    return hasQuery || hasType;
  }

  /**
   * Indicates if it has to display the message section.
   *
   * @returns boolean
   */
  protected hasDisplayMessage(): boolean {
    return !this.hasSearchCriteria();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.localizationSpecific = LocalizationSpecific;
    this.onSearch = new EventEmitter<DataStatusEnum>();
    this.pagination = new StormServiceResponsePagination();
    this.sorting = new StormServiceResponseSorting();
    this.titles = new Array();
  }
}
