import { Component, Output, EventEmitter, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { BoltProximityDropdownComponent, ProximityItem } from '@bolt/ui-shared/droplists';
import { Subscription } from 'rxjs';

import { ManagerService } from '../../services/export/manager-service/manager.service';


@Component({
  selector: 'bolt-profile-export-season-search',
  template: require('./bolt-profile-export-season-search.html'),
  styles: [require('./bolt-profile-export-season-search.scss')]
})
export class BoltProfileExportSeasonSearchComponent implements OnDestroy, AfterViewInit {
  @Output('selected') protected selectEvent: EventEmitter<undefined>;
  @ViewChild('seasonSearch') protected seasonSearch: BoltProximityDropdownComponent;

  protected options: ProximityItem[];
  protected subscriptions: Subscription;

  constructor(
    protected managerService: ManagerService
  ) {
    this.subscriptions = new Subscription();
    this.selectEvent = new EventEmitter();
    this.options = new Array();
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.managerService.secondaryTitlesObserver.subscribe(
        (list: ProximityItem[]) => {
          this.options = list;
        }
      )
    );

    this.subscriptions.add(
      this.managerService.secondaryTitleCleared.subscribe(
        (state: boolean) => {
          if (state) {
            this.seasonSearch.writeValue(undefined);
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  get maxValuesAsString(): number {
    return this.managerService.getAppConfig().get('ux.multiSelect.maxValuesAsString');
  }

  get scrollHeight(): string {
    return '150px';
  }

  /**
   * Selects the season.
   *
   * @returns void
   */
  onSelectSeason(event: any): void {
    if ((event !== undefined) && this.managerService.hasSecondaryTitles()) {
      this.managerService.setCurrentSecondaryTitle(event);
      this.selectEvent.emit();
    }
  }

  /**
   * Indicates if it has to block the select.
   *
   * @returns boolean
   */
  protected hasBlockSelector(): boolean {
    const hasIt: boolean = (
      !this.managerService.hasAllNeededData() ||
      !this.managerService.hasCurrentMainTitle() ||
      !this.managerService.hasSecondaryTitles() ||
      this.managerService.isSearching() ||
      this.managerService.isExporting()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to force reset the value on selectors.
   *
   * @returns boolean
   */
  protected hasForceResetValueOnSelector(): boolean {
    const hasIt: boolean = (
      this.hasBlockSelector() && !this.isValidSelector()
    );

    return hasIt;
  }

  /**
   * Indicates if it is searching.
   *
   * @returns boolean
   */
  protected isSearching(): boolean {
    const isIt: boolean = (
      this.managerService.hasCurrentMainTitle() &&
      !this.managerService.hasSecondaryTitles()
    );

    return isIt;
  }

  /**
   * Indicates if the select is valid.
   *
   * @returns boolean
   */
  protected isValidSelector(): boolean {
    return this.managerService.hasCurrentSecondaryTitle();
  }
}
