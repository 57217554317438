import { TypeEnum } from './type.enum';

import { includes as _includes } from 'lodash';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';

export class Type {
  private value: TypeEnum;

  constructor(value: TypeEnum) {
    this.setValue(value);
  }

  /**
   * Get the value attribute.
   *
   * @returns TypeEnum
   */
  getValue(): TypeEnum {
    return this.value;
  }

  /**
   * Indicates if the current type is the given value type.
   *
   * @param value TypeEnum
   * @returns boolean
   */
  is(value: TypeEnum): boolean {
    return this.value === value;
  }

  /**
   * Indicates if the current type is character.
   *
   * @returns boolean
   */
  isCharacter(): boolean {
    return this.is(TypeEnum.character);
  }

  /**
   * Indicates if the current type is Role.
   *
   * @returns boolean
   */
  isRole(): boolean {
    return this.is(TypeEnum.role);
  }

  /**
   * Indicates if the current type is talent.
   *
   * @returns boolean
   */
  isTalent(): boolean {
    return this.is(TypeEnum.talent);
  }

  /**
   * Set the value attribute with the given value.
   *
   * @param value TypeEnum
   * @returns void
   */
  private setValue(value: TypeEnum): void {
    const normalizedValue: string = String(value).trim().toLowerCase();

    if (_includes(TypeEnum, normalizedValue)) {
      this.value = value;
    } else {
      throw new ErrorHelper('Invalid value given for a type.');
    }
  }
}
