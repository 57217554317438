import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { Element } from '../models/element/element.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { SearchCriteria } from '../models/search-criteria/search-criteria.model';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable()
export class SearchService extends BoltAbstractService {

  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns a subscription for fetching data with the given parameters.
   *
   * @param criteria SearchCriteria
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetch(
    criteria: SearchCriteria,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const url: string = this.generateUrl(
      'consolidatedSearch.searchService.fetch.endpoint',
      undefined,
      criteria.asEndpointData()
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const searchResponse: SearchResponse = new SearchResponse(
            successResponse.item,
            (data: any) => {
              const element: any = new Element(data);
              return element;
            }
          );

          onSuccessDo(searchResponse);
        } catch (err) {
          onErrorDo(err);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }
}
