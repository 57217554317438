import { Component, Input, Output, OnChanges, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { UserRole } from '@bolt/ui-shared/auth';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CustomValidators } from 'ng2-validation';
import { isString as _isString, isNull as _isNull, sortBy as _sortBy, clone as _clone } from 'lodash';

import { ConfigService } from 'app/shared/services/form/config/config.service';
import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { User, UserInterface } from '../../models/user.model';
import { UserManager, UserManagerUserManagement } from '../../helpers/user-manager.helper';


/**
 * @todo Move this out of this file.
 */
export enum UserManagerActions {
  EDIT = <any>'EDIT',
  CREATE = <any>'CREATE',
  DELETE = <any>'DELETE',
  TOGGLE_STATE = <any>'TOGGLE_STATE',
  TOGGLE_ROLE = <any>'TOGGLE_ROLE',
}


@Component({
  selector: 'bolt-user-manager',
  template: require('./bolt-user-manager.html'),
  styles: [require('./bolt-user-manager.scss')]
})
export class BoltUserManagerComponent implements OnChanges {
  @ViewChild('preventCancelModal') preventCancelModal: ModalDirective;
  @ViewChild('confirmUserStateChangeModal') confirmUserStateChangeModal: ModalDirective;
  @ViewChild('confirmUserRoleAssignmentModal') confirmUserRoleAssignmentModal: ModalDirective;

  @Input('BoltUserManagerUserManager') userManager: UserManagerUserManagement;
  @Output('BoltUserManagerOnUserUpdated') userUpdated: EventEmitter<User> = new EventEmitter();

  protected roles: UserRole[];
  protected show: boolean = false;
  protected status: DataStatusEnum = DataStatusEnum.idle;
  protected statuses = DataStatusEnum;
  protected userManagerActions = UserManagerActions;
  protected form: FormGroup;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formBuilder: FormBuilder,
    protected formConfig: ConfigService,
    protected notificationService: NotificationService,
    protected userManagerHelper: UserManager
  ) {
    this.userManagerHelper.fetchUserRoles().subscribe(
      (serviceResponseCollection: StormServiceResponseCollection) => {
        this.roles = _sortBy(serviceResponseCollection.collection, 'name');
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.userManager &&
      (changes.userManager.currentValue !== undefined)
    ) {

      switch (true) {

        case this.userManager.action === UserManagerActions.CREATE:
        case this.userManager.action === UserManagerActions.EDIT:

          this.setupForm();

          if (UserManagerActions.EDIT === this.userManager.action) {
            this.setDefaultFormValues(this.userManager.user);
          }
          this.toggleManager(true);
          break;

        case this.userManager.action === UserManagerActions.TOGGLE_STATE:
          this.toggleUserState();
          break;

        default:
      }

    } else {
      this.toggleManager(false);
    }
  }

  protected setupForm() {
    const controlDefinitions = { };

    Object.assign(
      controlDefinitions,
      this.setupFormFieldId(),
      this.setupFormFieldActive(),
      this.setupFormFieldName(),
      this.setupFormFieldEmail(),
      this.setupFormFieldFirstName(),
      this.setupFormFieldMiddleName(),
      this.setupFormFieldLastName(),
      this.setupFormFieldTitle(),
      this.setupFormFieldOrganization(),
      this.setupFormFieldLocation(),
      this.setupFormFieldAddress(),
      this.setupFormFieldPhoneNumber(),
      this.setupFormFieldNotes(),
      this.setupFormFieldRoles()
    );

    this.form = this.formBuilder.group(controlDefinitions);
  }

  protected setupFormFieldId() {
    let validators: Validators;

    if (
      this.userManager.action === UserManagerActions.EDIT ||
      this.userManager.action === UserManagerActions.DELETE
    ) {
      validators = Validators.required;
    }

    return {
      id: [undefined, validators]
    };
  }

  protected setupFormFieldName() {
    return {
      name: [
        undefined,
        Validators.compose([
          this.customRequiredValidator,
          Validators.maxLength(this.getMaxLengthFor('name'))
        ])
      ]
    };
  }

  protected setupFormFieldEmail() {
    return {
      email: [
        undefined,
        Validators.compose([
          this.customRequiredValidator,
          CustomValidators.email,
          Validators.maxLength(this.getMaxLengthFor('email'))
        ])
      ]
    };
  }

  protected setupFormFieldFirstName() {
    return {
      firstName: [
        undefined,
        Validators.compose([
          this.customRequiredValidator,
          Validators.maxLength(this.getMaxLengthFor('firstName'))
        ])
      ]
    };
  }

  protected setupFormFieldMiddleName() {
    return {
      middleName: [undefined, Validators.maxLength(this.getMaxLengthFor('middleName'))]
    };
  }

  protected setupFormFieldLastName() {
    return {
      lastName: [
        undefined,
        Validators.compose([
          this.customRequiredValidator,
          Validators.maxLength(this.getMaxLengthFor('lastName'))
        ])
      ]
    };
  }

  protected setupFormFieldNotes() {
    return {
      notes: [undefined, Validators.maxLength(this.getMaxLengthFor('notes'))]
    };
  }

  protected setupFormFieldTitle() {
    return {
      title: [undefined, Validators.maxLength(this.getMaxLengthFor('title'))]
    };
  }

  protected setupFormFieldOrganization() {
    return {
      organization: [undefined, Validators.maxLength(this.getMaxLengthFor('organization'))]
    };
  }

  protected setupFormFieldLocation() {
    return {
      location: [undefined, Validators.maxLength(this.getMaxLengthFor('location'))]
    };
  }

  protected setupFormFieldAddress() {
    return {
      address: [undefined, Validators.maxLength(this.getMaxLengthFor('address'))]
    };
  }

  protected setupFormFieldPhoneNumber() {
    return {
      phoneNumber: [undefined, Validators.maxLength(this.getMaxLengthFor('phoneNumber'))]
    };
  }

  protected setupFormFieldActive() {
    return {
      active: [this.userManager.action === UserManagerActions.CREATE]
    };
  }

  /**
   * Set up the role field.
   *
   * @returns Object
   */
  protected setupFormFieldRoles(): object {
    return {
      roles: [new Array()]
    };
  }

  protected isAttributeValueValid(key: string): boolean {
    return !(this.form.controls[key].dirty && !this.form.controls[key].valid);
  }

  protected setDefaultFormValues(user: UserInterface) {
    const defaultValues = user.getRawObject();

    Object.keys(defaultValues).forEach(key => {
      setTimeout(() => {
        if (<FormControl>this.form.get(key)) {
          (<FormControl>this.form.get(key)).setValue(
            defaultValues[key]
          );
        }
      }, 0);
    });
  }

  /**
   * Toggle the UserManager Form visibility
   *
   * @param show boolean
   * @returns void
   */
  protected toggleManager(show: boolean): void {
    this.show = show;

    if (!show) {
      this.userManager = undefined;
    }
  }

  /**
   * Handles the Cancel flow, to toggle off the Form.
   * If unsaved changes are detected, the corresponding modal will be shown.
   *
   * @param force boolean
   * @returns void
   */
  protected cancel(force: boolean): void {
    if (force || this.form.pristine) {
      this.toggleManager(false);
      this.preventCancelModal.hide();
      return;
    }

    if (!this.form.pristine) {
      this.preventCancelModal.show();
    }
  }

  /**
   * Handles the click on Save button
   *
   * @param force boolean
   * @returns void
   */
  protected save(force: boolean = false): void {
    this.status = DataStatusEnum.fetchingData;

    if (this.userManager.action === UserManagerActions.CREATE) {
      this.createUser(new User(this.form.value));
    } else if (this.userManager.action === UserManagerActions.EDIT) {
      this.updateUser(new User(this.form.value));
    }
  }

  /**
   * Handles the create User flow
   *
   * @param user UserInterface
   * @returns void
   */
  protected createUser(user: UserInterface): void {
    this.status = DataStatusEnum.fetchingData;

    this.userManagerHelper.createUser(user).subscribe(
      serviceResponseSingle => {
        const newUser = new User(serviceResponseSingle.item);

        this.userUpdated.emit(newUser);
        this.status = DataStatusEnum.idle;

        this.toggleManager(false);
        this.notificationService.handleNotice(`User ${newUser.getFullName()} successfully added`);
      },
      error => {
        this.status = DataStatusEnum.idle;
        this.notificationService.handleError('Error while trying to create the new user', error);
      }
    );
  }

  /**
   * Checks the require status of the given field.
   *
   * @param control FormControl
   * @returns ValidationErrors
   */
  protected customRequiredValidator(control: FormControl): ValidationErrors {
    const error: ValidationErrors = { };

    if (_isNull(control.value) || (_isString(control.value) && control.value.length === 0)) {
      error.customRequired = {
        value: true,
        dirty: control.dirty
      };
    }

    return error;
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength: number = this.formConfig.get(`user.fields.${field}.maxLength`);
    return maxLength;
  }

  /**
   * Handles the update User flow
   *
   * @param user UserInterface
   * @returns void
   */
  protected updateUser(user: UserInterface): void {
    this.status = DataStatusEnum.fetchingData;

    this.userManagerHelper.updateUser(user).subscribe(
      serviceResponseSingle => {
        const updatedUser = new User(serviceResponseSingle.item);

        this.userUpdated.emit(updatedUser);
        this.status = DataStatusEnum.idle;

        this.toggleManager(false);
        this.notificationService.handleNotice(`User ${updatedUser.getFullName()} updated successfully`);
      },
      error => {
        this.status = DataStatusEnum.idle;
        this.notificationService.handleError('Error while trying to update the User', error);
      }
    );

  }

  /**
   * Handles the Activate/Deactivate User flow
   *
   * @param force boolean
   * @returns void
   */
  protected toggleUserState(force: boolean = false): void {
    if (!force) {
      this.confirmUserStateChangeModal.show();
    } else {
      this.status = DataStatusEnum.fetchingData;

      const userStateChange: User = new User({
        id: this.userManager.user.id,
        active: !this.userManager.user.active
      });

      this.userManagerHelper.updateUser(userStateChange).subscribe(
        serviceResponseSingle => {
          const updatedUser = new User(serviceResponseSingle.item);

          this.userUpdated.emit(updatedUser);
          this.status = DataStatusEnum.idle;

          this.confirmUserStateChangeModal.hide();
          this.toggleManager(false);

          const message: string =
            `User ${updatedUser.getFullName()} ${userStateChange.active ? 'activated' : 'deactivated'} successfully`;

          this.notificationService.handleNotice(message);
        },
        error => {
          this.status = DataStatusEnum.idle;
          this.notificationService.handleError('Error while trying to update the User state', error);
        }
      );
    }
  }

  /**
   * Indicates if all of the roles are activated.
   *
   * @returns boolean
   */
  protected areAllRolesActivated(): boolean {
    const theyAre: boolean = this.roles.every(
      (role: UserRole) => this.isRoleActived(role)
    );

    return theyAre;
  }

  /**
   * Indicates if the current action is create.
   *
   * @returns boolean
   */
  protected isCreating(): boolean {
    const isIt: boolean = this.userManager.action === this.userManagerActions.CREATE;
    return isIt;
  }

  /**
   * Indicates if the current action is edit.
   *
   * @returns boolean
   */
  protected isEditing(): boolean {
    const isIt: boolean = this.userManager.action === this.userManagerActions.EDIT;
    return isIt;
  }

  /**
   * Indicates if the current user has the given role.
   *
   * @param role UserRole
   * @returns boolean
   */
  protected isRoleActived(role: UserRole): boolean {
    const isIt: boolean = this.form.get('roles').value.some(
      (userRole: UserRole) => userRole.id === role.id
    );

    return isIt;
  }

  /**
   * Toggles the activated status of all roles.
   *
   * @returns void
   */
  protected toggleAllRoles(): void {
    const roleFormControl: AbstractControl = this.form.get('roles');

    if (this.areAllRolesActivated()) {
      roleFormControl.setValue(new Array());
    } else {
      roleFormControl.setValue(_clone(this.roles));
    }
  }

  /**
   * Toggles the given role status.
   *
   * @param role UserRole
   * @returns void
   */
  protected toggleRoleStatus(role: UserRole): void {
    const roleFormControl: AbstractControl = this.form.get('roles');

    const index: number = roleFormControl.value.findIndex(
      (userRole: UserRole) => userRole.id === role.id
    );

    if (index >= 0) {
      roleFormControl.value.splice(index, 1);
    } else {
      roleFormControl.value.push(role);
    }
  }
}
