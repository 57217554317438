import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Subscription } from 'rxjs';

import { ConfigurationService } from '../../services/configuration/configuration.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ServerMapping } from '../../models/configuration/server-mapping/server-mapping.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { ViewModeEnum } from './view-mode.enum';

@Component({
  selector: 'bolt-cpm-configurations-mapping',
  template: require('./bolt-cpm-configurations-mapping.html'),
  styles: [require('./bolt-cpm-configurations-mapping.scss')]
})
export class BoltCpmConfigurationsMappingComponent extends StormComponent implements OnInit, OnDestroy {
  protected fetchListener: Subscription;
  protected filteredWords: string;
  protected isJsonExpanded: boolean;
  protected selectedViewMode: string;
  protected serverMapping: ServerMapping;
  protected viewModes: SelectionItem[];

  constructor(
    protected configurationService: ConfigurationService,
    protected notificationService: NotificationService
  ) {
    super();
    this.changeStatusToIdle();

    this.viewModes = [
      new SelectionItem('JSON', ViewModeEnum.json),
      new SelectionItem('Table', ViewModeEnum.table)
    ];

    this.selectedViewMode = this.viewModes[0].value;
    this.filteredWords = '';
  }

  ngOnDestroy() {
    this.fetchListener.unsubscribe();
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Fetches the configuration mapping.
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();

    this.isJsonExpanded = true;

    this.fetchListener = this.configurationService.fetchMapping().subscribe(
      (serverMapping: ServerMapping) => {
        this.serverMapping = serverMapping;
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed fetching the Server Configuration Mapping.', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Indicates if it has to display the JSON view.
   *
   * @returns boolean
   */
  protected hasDisplayJsonView(): boolean {
    const hasIt: boolean =
      this.isDataFound() &&
      this.selectedViewMode === ViewModeEnum.json;

    return hasIt;
  }

  /**
   * Indicates if it has to display the table view.
   *
   * @returns boolean
   */
  protected hasDisplayTableView(): boolean {
    const hasIt: boolean =
      this.isDataFound() &&
      this.selectedViewMode === ViewModeEnum.table;

    return hasIt;
  }

  /**
   * Sets the filtered words.
   *
   * @param words string
   * @returns void
   */
  protected setFilteredWords(words: string): void {
    this.filteredWords = words;
  }

  /**
   * Toggles the JSON expansion status for the configuration mapping.
   *
   * @returns void
   */
  protected toggleJsonExpansion(): void {
    this.isJsonExpanded = !this.isJsonExpanded;
  }
}
