import { isArray as _isArray, isObject as _isObject } from 'lodash';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class Summary {
  protected _episodics: number[];
  protected _invalids: number[];

  constructor(data: any) {
    this.initialize(data);
  }

  get episodics(): number[] {
    return this._episodics;
  }

  get invalids(): number[] {
    return this._invalids;
  }

  /**
   * Indicates if it has episodics.
   *
   * @returns boolean
   */
  hasEpisodics(): boolean {
    const hasIt: boolean = (this.episodics.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has invalids.
   *
   * @returns boolean
   */
  hasInvalids(): boolean {
    const hasIt: boolean = (this.invalids.length > 0);
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    if (_isObject(data)) {
      this.setEpisodics(data);
      this.setInvalids(data);
    } else {
      throw new ErrorHelper('Invalid response given for a summary.');
    }
  }

  /**
   * Set the episodics from the given data.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setEpisodics(data: any): void {
    if (_isArray(data.episodic)) {
      this._episodics = data.episodic;
    } else {
      throw new ErrorHelper('Invalid episodics given for a summary.');
    }
  }

  /**
   * Set the invalids from the given data.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setInvalids(data: any): void {
    if (_isArray(data.invalid)) {
      this._invalids = data.invalid;
    } else {
      throw new ErrorHelper('Invalid invalids given for a summary.');
    }
  }
}
