import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Language, TypeEnum as MasterDataType, LanguageTypeEnum } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { isObject as _isObject, isUndefined as _isUndefined, isArray as _isArray } from 'lodash';


import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { LanguageForm } from 'app/modules/masterData/models/language/form/language-form.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { SelectionItem } from '@bolt/ui-shared/droplists';


@Component({
  selector: 'bolt-master-data-language-handler-panel',
  template: require('./bolt-master-data-language-handler-panel.html'),
  styles: [require('./bolt-master-data-language-handler-panel.scss')]
})
export class BoltMasterDataLanguageHandlerPanelComponent implements OnChanges, OnDestroy {
  @Input() language: Language | undefined;
  @Input() show: boolean;
  @Output('cancelled') cancelEvent: EventEmitter<void>;
  @Output('saved') saveEvent: EventEmitter<Language>;

  protected typesItems: SelectionItem[];
  protected languageTypeEnum: typeof LanguageTypeEnum = LanguageTypeEnum;
  protected actionSubscription: Subscription;
  protected form: LanguageForm;
  protected fieldSpecs: any;
  protected isCreating: boolean;

  constructor(
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected appConfig: AppConfigProvider
  ) {
      this.cancelEvent = new EventEmitter();
      this.saveEvent = new EventEmitter();
      this.show = false;

      this.setupFieldSpecs();
      this.loadTypes();
    }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.language)) {
      this.isCreating = _isUndefined(this.language);
    }

    if (_isObject(changes.show)) {
      if (this.show) {
        this.form = new LanguageForm(this.fieldSpecs, this.language);
      } else {
        this.form = undefined;
        this.cancelActionSubscription();
      }
    }
  }

  ngOnDestroy() {
    this.cancelActionSubscription();
  }

  /**
   * Indicates if it has types.
   *
   * @returns boolean
   */
  protected hasTypes(): boolean {
    const hasIt: boolean = _isArray(this.typesItems) && this.typesItems.length > 0;
    return hasIt;
  }

  /**
   * Loads types.
   *
   * @returns void
   */
  protected loadTypes(): void {
    this.typesItems = Object.keys(LanguageTypeEnum).map(
      (key: string) => new SelectionItem(key, key)
    );
  }

  /**
   * Cancels the action over the configuration.
   *
   * @returns void
   */
  protected cancel(): void {
    this.cancelEvent.emit();
  }

  /**
   * Cancels the subscription for the triggered action.
   *
   * @returns void
   */
  protected cancelActionSubscription(): void {
    if (_isObject(this.actionSubscription)) {
      this.actionSubscription.unsubscribe();
    }
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    return this.fieldSpecs[`${field}MaxLength`];
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.form.disabled;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const isIt: boolean = this.form.invalid || this.form.disabled;
    return isIt;
  }

  /**
   * Save the current language.
   *
   * @returns void
   */
  protected save(): void {
    this.form.disable();

    const data: any = this.form.toJson();
    const action: Observable<any> = this.isCreating
      ? this.masterDataService.create(MasterDataType.language, data)
      : this.masterDataService.update(MasterDataType.language, this.language.id, data);

    this.actionSubscription = action.subscribe(
      (newLanguage: Language) => {
        this.notificationService.handleSuccess('Language was saved successfully.');
        this.saveEvent.emit(newLanguage);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed saving the newLanguage', error);
        this.form.enable();
      }
    );
  }

  /**
   * Setup the specs for the fields
   *
   * @returns void
   */
  protected setupFieldSpecs(): void {
    this.fieldSpecs = {
      localeLanguageMaxLength: this.appConfig.get('forms.masterdata.language.fields.localeLanguage.maxLength'),
      nameMaxLength: this.appConfig.get('forms.masterdata.language.fields.name.maxLength'),
      iso6391MaxLength: this.appConfig.get('forms.masterdata.language.fields.iso6391.maxLength')
    };
  }
}
