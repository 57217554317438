import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { LanguageInterface } from '@bolt/ui-shared/master-data/models/language/language.interface';
import { findIndex, pullAt } from 'lodash';

import { BoltTalentMetadataFiltersInterface } from '../bolt-talent-metadata-filters/bolt-talent-metadata-filters.component';
import { MetadataSortByInterface } from 'app/modules/common/models/metadata.model';
import { TalentInterface, Talent } from '../../models/talent.model';
import { TalentManager } from '../../helpers/talent-manager/talent-manager.helper';
import { TalentMetadataInterface } from '../../models/talent-metadata.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-talent-metadata-list',
  template: require('./bolt-talent-metadata-list.html'),
  styles: [require('./bolt-talent-metadata-list.scss')],
})
export class BoltTalentMetadataListComponent extends StormComponent implements OnChanges {
  @Input('BoltTalentMetadataListTalent') talent: TalentInterface = new Talent();
  @Input('BoltTalentMetadataListSelectedMetadata') selectedTalentMetadata: TalentMetadataInterface;
  @Input('BoltTalentMetadataListFilters') filters: BoltTalentMetadataFiltersInterface;

  @Output('BoltTalentMetadataListOnSelectMetadata')
  onSelectMetadata: EventEmitter<TalentMetadataInterface> = new EventEmitter<TalentMetadataInterface>();

  protected talentMetadata: TalentMetadataInterface[] = [];
  protected filteredTalentMetadata: TalentMetadataInterface[] = [];
  protected matchAllSelectors = ['all', '*'];
  protected sorting = [];

  constructor(
    protected talentManager: TalentManager
  ) {
    super();
  }

  ngOnChanges(changes) {
    if (changes.talent && changes.talent.currentValue) {
      this.talentManager.mapAttributes(this.talent.localizations).subscribe(
        localizations => {
          this.talentMetadata = <TalentMetadataInterface[]>localizations;

          if (changes.talent.previousValue) {
            this.filterMetadata();
          }
        }
      );
    }

    if (changes.filters && changes.filters.currentValue) {
      this.filterMetadata();
    }
  }

  /**
   * Set the given Talent Metadata as selected.
   *
   * @param talentMetadata TalentMetadataInterface
   * @returns void
   */
  selectMetadata(talentMetadata: TalentMetadataInterface): void {
    this.selectedTalentMetadata = talentMetadata;
    this.onSelectMetadata.emit(this.selectedTalentMetadata);
  }

  /**
   * Filters the Talent Metadata with the applied filters.
   *
   * @return void
   */
  protected filterMetadata(): void {
    if (!this.filters) {
      return;
    }

    this.filteredTalentMetadata = [];

    this.talentMetadata.forEach((talentMetadata) => {
      let matches: number = 0;

      this.filters.filter.forEach((filter, index) => {
        const exactMatch: boolean = (index + 1) < this.filters.filter.length;

        if (
          !this.filters.filter.length ||
          this.filterMetadataMatchByLanguage(<LanguageInterface>talentMetadata.language, filter, exactMatch)
        ) {
          matches++;
        }
      });
      if (this.filters.filter.length === matches) {
        this.filteredTalentMetadata.push(talentMetadata);
      }
    });
    this.sortBy();
  }

  /**
   * Filters a Talent Metadata by its language name
   *
   * @param language LanguageInterface
   * @param filter string
   * @param exactMatch boolean
   * @return boolean
   */
  protected filterMetadataMatchByLanguage(language: LanguageInterface, filter: string, exactMatch: boolean): boolean {
    return (exactMatch && (language.name.toLowerCase() === filter.toLowerCase())) ||
        (!exactMatch && (language.name.toLowerCase().startsWith(filter.toLowerCase())));
  }

  /**
   * Sort the already filtered Talent Metadata.
   * If sortBy === null, the sort criteria will be removed.
   * If no sortBy criteria is given, en_*_*_* will be set as the first Talent Metadata on the collection.
   *
   * @param sortBy string
   * @return void
   */
  protected sortBy(sortBy?: string): void {

    if (sortBy !== undefined && sortBy !== null) {
      const findedIndex = findIndex(this.sorting, { property: sortBy });

      if (findedIndex === -1) {
        this.sorting.push({ property: sortBy, reverse: false });
      } else {
        if (!this.sorting[findedIndex].reverse) {
          this.sorting[findedIndex].reverse = !this.sorting[findedIndex].reverse;
        } else {
          pullAt(this.sorting, [findedIndex]);
        }
      }

      this.sorting.forEach((sorting, index) => sorting.order = index + 1);
    } else if (sortBy === null) {
      this.sorting = [];
    }

    this.filteredTalentMetadata = this.talentManager.sortTalentMetadata(
      this.filteredTalentMetadata,
      this.sorting
    );

    if (sortBy === undefined && !this.selectedTalentMetadata) {
      if (this.filteredTalentMetadata.length) {
        this.selectMetadata(this.filteredTalentMetadata[0]);
      } else {
        this.selectMetadata(undefined);
      }
    }

  }

  /**
   * Sorts the current talents by the given property.
   *
   * @param sortBy string
   * @returns MetadataSortByInterface
   */
  protected sortedBy(sortBy: string): MetadataSortByInterface {
    const index = findIndex(this.sorting, { property: sortBy });

    if (index !== -1) {
        return this.sorting[index];
    } else {
      return <MetadataSortByInterface>{ reverse: undefined, order: undefined };
    }
  }
}
