import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { isObject as _isObject, isUndefined as _isUndefined, isEmpty as _isEmpty, isBoolean as _isBoolean } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { Season } from '../../../../modules/title/models/season.model';
import { TitleType } from 'app/modules/title/models/title.model';
import { TitleService } from 'app/modules/title/services/title.service';
import { StormServiceResponseCollectionInterface } from 'app/modules/common/services/storm-service-response-collection';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-clone-season-metadata-manager',
  template: require('./bolt-clone-season-metadata-manager.html'),
  styles: [require('./bolt-clone-season-metadata-manager.scss')]
})
export class BoltCloneSeasonMetadataManagerComponent extends StormComponent implements OnChanges, OnDestroy {
  @Input() show: boolean;
  @Input() title: Season;
  @Input() locale: string;
  @Output('closed') closeEvent: EventEmitter<undefined>;
  @Output('saved') saveEvent: EventEmitter<any>;
  @ViewChild('cloneModalRef') modal: ModalDirective;

  lockingStatus: LockingStatus;
  listOfTitles: Season[];
  listOfTitlesSubscription: Subscription;
  lockingStatusSubscription: Subscription;
  selectedTitles = new Set();
  selectedDataFields = new Set();
  loading: boolean = true;
  dataFields: any[] = [];
  toggleAllInput: boolean = false;

  constructor(
    protected titleService: TitleService,
  ) {
    super();
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show)) {
      if (changes.show.currentValue) {
        this.open();
        this.fetchAllSeasonOfSeries();
      } else {
        if (!changes.show.firstChange) {
          this.reset();
        }
      }
    }
  }

  ngOnDestroy() {
    this.reset();
    this.cancelSubscriptions();
  }

  /**
   * Emits the onSelect event.
   *
   * @returns void
   */
  clone(): object {
    const payload = {
      candidates: Array.from(this.selectedTitles),
      fields: Array.from(this.selectedDataFields).map((dataField: any) => dataField.name),
    };

    this.close();

    this.saveEvent.emit(payload);
    return payload;
  }

  /**
   * Indicates if it has to display the empty message.
   *
   * @returns boolean
   */
  hasDisplayEmptyMessage(): boolean {
    const hasIt: boolean = _isEmpty(this.listOfTitles);
    return hasIt;
  }

  /**
   * Toggle titles
   *
   * @params selection Season
   * @returns void
   */
  selectTitle(selection: Season): void {
    this.toggleAllInput = this.selectedTitles.size === (this.listOfTitles.length - 1);
    if (this.isTitleSelected(selection)) {
      this.selectedTitles.delete(selection.id);
    } else {
      this.selectedTitles.add(selection.id);
    }
  }

  /**
   * Selects all the titles
   *
   * @returns void
   */
  toggleAll(): void {
    const action = this.toggleAllInput === true ? 'add' : 'delete';
    this.listOfTitles.forEach(title => {
      this.selectedTitles[action](title.id);
    });
  }

  /**
   * Cancels the current subscriptions.
   *
   * @returns void
   */
  protected cancelSubscriptions(): void {
    if (_isObject(this.listOfTitlesSubscription)) {
      this.listOfTitlesSubscription.unsubscribe();
    }
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected close(): void {
    this.closeEvent.emit();
    this.modal.hide();
    this.reset();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();

    this.dataFields = [
      {
        name: 'genreId',
        description: 'Genre'
      },
      {
        name: 'locked',
        description: 'Lock Status'
      }
    ];

    this.reset();
  }

  /**
   * Opens the modal.
   *
   * @returns void
   */
  protected open(): void {
    this?.modal?.show();
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.listOfTitles = [];
    this.selectedTitles.clear();
    this.selectedDataFields = new Set();
    this.toggleAllInput = false;
    this.loading = true;
    this.show = false;
    this.listOfTitlesSubscription = new Subscription();
    this.lockingStatusSubscription = new Subscription();
  }

  /**
   * Indicate if the clone button should be enable
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    return this.selectedTitles.size === 0 || this.selectedDataFields.size === 0;
  }

  /**
   * Fetches the seasons for the current series option.
   *
   * @returns void
   */
  protected fetchAllSeasonOfSeries(): void {
    const data: any = {
      titleType: TitleType.season,
      seriesId: this.title.seriesId,
      locale: '*_*_*_*',
      _size: 5000
    };

    this.changeStatusToFetchingData();

    this.listOfTitlesSubscription = this.titleService
      .filterTitles(data)
      .subscribe(
        (response: StormServiceResponseCollectionInterface) => {
          // Set the list of titles excluding the current one
          this.listOfTitles = response.collection
            .filter(title => title.id !== this.title.id);
          this.loading = false;
          this.changeStatusToDataFound();
        }
      , error => {
        this.changeStatusToError();
      }
    );
  }

  /**
   * Returns if the property isTitleSelected has the argument value
   *
   * @params selection Season
   * @returns boolean
   */
  protected isTitleSelected(selection: Season): boolean {
    return this.selectedTitles.has(selection.id);
  }

  /**
   * Toggle add selected data fields
   *
   * @param field any
   * @returns void
   */
  protected onDataFieldSelect(field: any): void {
    if (this.selectedDataFields.has(field)) {
      this.selectedDataFields.delete(field);
    } else {
      this.selectedDataFields.add(field);
    }
  }

  /**
   * Returns if the property selectedDataFields has the argument value
   *
   * @param field any
   * @returns boolean
   */
  protected isDataFieldSelected(field: any): boolean {
    return this.selectedDataFields.has(field);
  }
}
