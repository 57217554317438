import { isArray as _isArray, isUndefined as _isUndefined } from 'lodash';

import { ExportCandidate } from '../export-candidate/export-candidate.model';
import { TitleType } from 'app/modules/title/models/title.model';


export class ExportCounters {
  protected totalRecords:  number;
  protected uniqueEpisodes: number;
  protected uniqueFeatures: number;
  protected uniqueLocales: number;

  constructor(candidates?: ExportCandidate[]) {
    this.read(_isUndefined(candidates) ? [] : candidates);
  }

  /**
   * Returns the unique episodes counter.
   *
   * @returns number.
   */
  getUniqueEpisodes(): number {
    return this.uniqueEpisodes;
  }

  /**
   * Returns the unique features counter.
   *
   * @returns number.
   */
  getUniqueFeatures(): number {
    return this.uniqueFeatures;
  }

  /**
   * Returns the unique locales counter.
   *
   * @returns number.
   */
  getUniqueLocales(): number {
    return this.uniqueLocales;
  }

  /**
   * Returns the unique total records counter.
   *
   * @returns number.
   */
  getTotalRecords(): number {
    return this.totalRecords;
  }

  /**
   * Reads the given candidates and set the counters.
   *
   * @param candidates ExportCandidate
   * @throw Error
   * @returns void
   */
  read(candidates: ExportCandidate[]): void {
    if (_isArray(candidates)) {
      const counters: Map<string, Set<string>> = new Map();

      counters.set('episodes', new Set());
      counters.set('features', new Set());
      counters.set('locales', new Set());

      candidates.forEach(
        (candidate: ExportCandidate) => {
          const titleKey: string = `${candidate.getTitle().type}-${candidate.getTitle().id}`;

          switch (candidate.getTitle().type) {
            case TitleType.episode:
              counters.get('episodes').add(titleKey);
              break;
            case TitleType.feature:
              counters.get('features').add(titleKey);
              break;
            default:
              throw new Error(`Invalid title type given: ${candidate.getTitle().type}`);
          }

          counters.get('locales').add(candidate.getLocale().getLocale());
        }
      );

      this.totalRecords = candidates.length;
      this.uniqueEpisodes = counters.get('episodes').size;
      this.uniqueFeatures = counters.get('features').size;
      this.uniqueLocales = counters.get('locales').size;
    } else {
      throw new Error('Invalid candidates given.');
    }
  }
}
