import { Component, OnInit } from '@angular/core';

import { BooleanField } from '../../models/import/comparison/titles/title/field/boolean-field/boolean-field.model';
import { Episode } from '../../models/import/comparison/titles/title/episode/episode.model';
import { ManagerService } from '../../services/import/manager-service/manager.service';
import { profileConfig } from '../../config/profile.config';
import { TitlesEnum } from '../../models/import/comparison/titles/titles.enum';


@Component({
  selector: 'bolt-profile-import-episodes',
  template: require('./bolt-profile-import-episodes.html'),
  styles: [require('./bolt-profile-import-episodes.scss')]
})

export class BoltProfileImportEpisodesComponent implements OnInit {
  protected labels: any;
  protected titlesEnum: typeof TitlesEnum = TitlesEnum;

  constructor(protected managerService: ManagerService) {
    this.initialize();
  }

  ngOnInit() { }

  /**
   * Forces the confirm resolution.
   *
   * @param field Field
   * @returns void
   */
  protected forceConfirm(field): void {
    this.managerService.forceConfirmResolution(field);
  }

  /**
   * Initializes the Season Component.
   *
   * @returns void
   */
  protected initialize(): void {
    this.labels = profileConfig.episodesLabels;
  }

  /**
   * Ensures that all given locked fields have the same given status.
   *
   * @param Episode[] episodes
   * @param nextToggledStatus boolean
   * @returns void
   */
  protected toggleLockedFieldsToSameStatus(episodes: Episode[], nextToggledStatus: boolean): void {
    episodes.forEach(
      (episode: Episode) => {
        const lockingField: BooleanField = <BooleanField>episode.getField('locked');

        if (lockingField.isTruthy() !== nextToggledStatus) {
          lockingField.toggle();
          this.managerService.forceConfirmResolution(lockingField);
        }
      }
    );
  }
}
