import { isUndefined as _isUndefined, isString as _isString, isNull as _isNull } from 'lodash';
import { Account, Country, Language, ProductType } from '@bolt/ui-shared/master-data';

import { Type } from './type/type.model';
import { TypeEnum } from './type/type.enum';


export class Locale {
  protected _account: number[] | Account[];
  protected _language: number | Language;
  protected _productType: number[] | ProductType[];
  protected _territory: number[] | Country[];
  protected _type: Type;

  constructor(data: any) {
    this.setAccount(data);
    this.setLanguage(data);
    this.setProductType(data);
    this.setTerritory(data);
    this.setType(data);
  }

  /**
   * Returns a new locale from given value.
   *
   * @param locale string
   * @returns Locale
   */
  static from(value: string): Locale {
    const sections: string[] = value.split('_');

    const aLocale: Locale = new Locale({
      language: sections[Language.LOCALE_POSITION],
      territory: sections[Country.LOCALE_POSITION],
      productType: sections[ProductType.LOCALE_POSITION],
      account: sections[Account.LOCALE_POSITION]
    });

    return aLocale;
  }

  /**
   * Indicates if the given locale is a english root locale.
   *
   * @param locale any
   * @returns boolean
   */
  static isEnglishLanguage(locale: any): boolean {
    let isIt: boolean = false;

    if (!_isString(locale)) {
      return isIt;
    }

    const values: Array<number | string> = locale.split('_');

    if (Language.isEnglish(values[Language.LOCALE_POSITION])) {
      isIt = true;
    }

    return isIt;
  }

  /**
   * Indicates if the given locale is a english root locale.
   *
   * @param locale string
   * @returns boolean
   */
  static isEnglishRoot(locale: string): boolean {
    const values: Array<number | string> = locale.split('_');
    let isIt: boolean = false;

    if (Language.isEnglish(values[Language.LOCALE_POSITION])) {
      const territory: number | string = values[Country.LOCALE_POSITION];
      const productType: number | string = values[ProductType.LOCALE_POSITION];
      const account: number | string = values[Account.LOCALE_POSITION];

      isIt = Country.isAll(territory) && ProductType.isAll(productType) && Account.isAll(account);
    }

    return isIt;
  }

  get account(): number[] | Account[] {
    return this._account;
  }

  get language(): number | Language {
    return this._language;
  }

  get productType(): number[] | ProductType[] {
    return this._productType;
  }

  get territory(): number[] | Country[] {
    return this._territory;
  }

  get type(): Type {
    return this._type;
  }

  /**
   * Get the formatted locale
   *
   * @returns string
   */
  getLocale(): string {
    const locale: string = [
      this.language,
      this.territory.join(','),
      this.productType.join(','),
      this.account.join(','),
    ].join('_');

    return locale;
  }

  /**
   * Get the formatted locale code.
   *
   * @returns string
   */
  getLocaleCode(): string {
    const localeCode: string = [
      (<Language>this.language).localeLanguage,
      (<Country[]>this.territory).map((t: any) => t = t.iso31661).join(','),
      (<ProductType[]>this.productType).map((pt: any) => pt = pt.code).join(','),
      (<Account[]>this.account).map((acc: any) => acc = acc.code).join(','),
    ].join('_');

    return localeCode;
  }

  /**
   * Indicates if the value of account is all.
   *
   * @returns boolean
   */
  isAccountAll(): boolean {
    return Account.isAll(this.account[0]);
  }

  /**
   * Indicates if it is a account locale root.
   *
   * @returns boolean
   */
  isAccountRoot(): boolean {
    const isIt: boolean = (
      this.isComplete() &&
      this.isLanguageAll() &&
      this.isTerritoryAll() &&
      this.isProductTypeAll() &&
      !this.isAccountAll()
    );

    return isIt;
  }

  /**
   * Indicates if the current locales is complete.
   *
   * @returns boolean
   */
  isComplete(): boolean {
    const itIs: boolean =
      !_isUndefined(this.language) &&
      !_isNull(this.language) &&
      !_isUndefined(this.territory) &&
      !_isNull(this.territory) &&
      !_isUndefined(this.productType) &&
      !_isNull(this.productType) &&
      !_isUndefined(this.account) &&
      !_isNull(this.account);

    return itIs;
  }

  /**
   * Indicates if the value of language is all.
   *
   * @returns boolean
   */
  isLanguageAll(): boolean {
    return Language.isAll(this.language);
  }

  /**
   * Indicates if it is a language locale root.
   *
   * @returns boolean
   */
  isLanguageRoot(): boolean {
    const isIt: boolean = (
      this.isComplete() &&
      !this.isLanguageAll() &&
      this.isTerritoryAll() &&
      this.isProductTypeAll() &&
      this.isAccountAll()
    );

    return isIt;
  }

  /**
   * Indicates if the locale is a root locale for the current type.
   *
   * @returns boolean
   */
  isLocalizationSpecificRoot(): boolean {
    let isIt: boolean;

    if (this.hasType()) {
      switch (this.type.value) {
        case TypeEnum.account:
          isIt = this.isAccountRoot();
        break;
        case TypeEnum.language:
          isIt = this.isLanguageRoot();
        break;
        case TypeEnum.territory:
          isIt = this.isTerritoryRoot();
        break;
        default:
          isIt = false;
        break;
      }
    } else {
      isIt = false;
    }

    return isIt;
  }

  isProductTypeAll(): boolean {
    return ProductType.isAll(this.productType[0]);
  }

  /**
   * Indicates if the value of territory is all.
   *
   * @returns boolean
   */
  isTerritoryAll(): boolean {
    return Country.isAll(this.territory[0]);
  }

  /**
   * Indicates if it is a territory locale root.
   *
   * @returns boolean
   */
  isTerritoryRoot(): boolean {
    const isIt: boolean = (
      this.isComplete() &&
      this.isLanguageAll() &&
      !this.isTerritoryAll() &&
      this.isProductTypeAll() &&
      this.isAccountAll()
    );

    return isIt;
  }

  toString(): string {
    return this.getLocale();
  }

  /**
   * Indicates if it has a valid type.
   *
   * @returns boolean
   */
  protected hasType(): boolean {
    const hasIt: boolean = !_isUndefined(this.type);
    return hasIt;
  }

  /**
   * Set the account with the given data.
   *
   * @param data any
   * @returns void
   */
  protected setAccount(data: any): void {
    this._account = data.account;
  }

  /**
   * Set the language with the given data
   *
   * @param data any
   * @returns void
   */
  protected setLanguage(data: any): void {
    this._language = data.language;
  }

  /**
   * Set the product type with the given data.
   *
   * @param data any
   * @returns void
   */
  protected setProductType(data: any): void {
    this._productType = data.productType;
  }

  /**
   * Set the territory with the given data.
   *
   * @param data any
   * @returns void
   */
  protected setTerritory(data: any): void {
    this._territory = data.territory;
  }

  /**
   * Set the type with the given data.
   *
   * @param data any
   * @returns void
   */
  protected setType(data: any): void {
    if (!_isUndefined(data.localeType)) {
      this._type = new Type(data.localeType);
    }
  }
}
