import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from './../common/common.module';

import { BoltUpcEditSidePanelComponent } from './components/bolt-upc-edit-side-panel/bolt-upc-edit-side-panel.component';
import { BoltUpcFiltersComponent } from './components/bolt-upc-filters/bolt-upc-filters.component';
import { BoltUpcListComponent } from './components/bolt-upc-list/bolt-upc-list.component';
import { UpcService } from './services/upc.service';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule
  ],
  declarations: [
    BoltUpcFiltersComponent,
    BoltUpcListComponent,
    BoltUpcEditSidePanelComponent
  ],
  exports: [
    BoltUpcListComponent
  ],
  providers: [
    UpcService
  ],
})
export class UpcModule { }
