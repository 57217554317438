import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Language } from '@bolt/ui-shared/master-data';
import { findIndex as _findIndex, pullAt as _pullAt } from 'lodash';

import { BoltRoleMetadataFiltersInterface } from '../bolt-role-metadata-filters/bolt-role-metadata-filters.component';
import { MetadataSortByInterface } from 'app/modules/common/models/metadata.model';
import { Role, RoleInterface } from '../../models/role.model';
import { RoleManager } from '../../helpers/role-manager/role-manager.helper';
import { RoleMetadataInterface } from '../../models/role-metadata.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-role-metadata-list',
  template: require('./bolt-role-metadata-list.html'),
  styles: [require('./bolt-role-metadata-list.scss')],
})
export class BoltRoleMetadataListComponent extends StormComponent implements OnChanges {
  @Input('BoltRoleMetadataListRole') role: RoleInterface = new Role();
  @Input('BoltRoleMetadataListSelectedMetadata') selectedRoleMetadata: RoleMetadataInterface;
  @Input('BoltRoleMetadataListFilters') filters: BoltRoleMetadataFiltersInterface;
  @Output('BoltRoleMetadataListOnSelectMetadata')
  onSelectMetadata: EventEmitter<RoleMetadataInterface> = new EventEmitter<RoleMetadataInterface>();

  protected roleMetadata: RoleMetadataInterface[] = [];
  protected filteredRoleMetadata: RoleMetadataInterface[] = [];
  protected matchAllSelectors = ['all', '*'];
  protected sorting = [];

  constructor(
    protected roleManager: RoleManager
  ) {
    super();
  }

  ngOnChanges(changes) {
    if (changes.role && changes.role.currentValue) {
      this.roleManager.mapAttributes(this.role.localizations).subscribe(
        localizations => {
          this.roleMetadata = <RoleMetadataInterface[]>localizations;
          if (changes.role.previousValue) {
            this.filterMetadata();
          }
        }
      );
    }

    if (changes.filters && changes.filters.currentValue) {
      this.filterMetadata();
    }
  }

  /**
   * Set the given Role Metadata as selected.
   *
   * @param roleMetadata RoleMetadataInterface
   * @returns void
   */
  selectMetadata(roleMetadata: RoleMetadataInterface): void {
    this.selectedRoleMetadata = roleMetadata;
    this.onSelectMetadata.emit(this.selectedRoleMetadata);
  }

  /**
   * Filters the Role Metadata with the applied filters.
   *
   * @return void
   */
  protected filterMetadata() {
    if (!this.filters) {
      return;
    }

    this.filteredRoleMetadata = [];
    this.roleMetadata.forEach((roleMetadata) => {
      let matches: number = 0;

      this.filters.filter.forEach((filter, index) => {
        const exactMatch: boolean = (index + 1) < this.filters.filter.length;

        if (
          !this.filters.filter.length ||
          this.filterMetadataMatchByLanguage(<Language>roleMetadata.language, filter, exactMatch)
        ) {
          matches++;
        }
      });

      if (this.filters.filter.length === matches) {
        this.filteredRoleMetadata.push(roleMetadata);
      }
    });
    this.sortBy();
  }

  /**
   * Filters a Role Metadata by its language name
   *
   * @param language Language
   * @param filter string
   * @param exactMatch boolean
   * @return boolean
   */
  protected filterMetadataMatchByLanguage(language: Language, filter: string, exactMatch: boolean): boolean {
    return (exactMatch && (language.name.toLowerCase() === filter.toLowerCase())) ||
        (!exactMatch && (language.name.toLowerCase().startsWith(filter.toLowerCase())));
  }

  /**
   * Sort the already filtered Role Metadata.
   * If sortBy === null, the sort criteria will be removed.
   * If no sortBy criteria is given, en_*_*_* will be set as the first Role Metadata on the collection.
   *
   * @param sortBy string
   * @return void
   */
  protected sortBy(sortBy?: string) {
    if (sortBy !== undefined && sortBy !== null) {
      const foundIndex = _findIndex(this.sorting, { property: sortBy });

      if (foundIndex === -1) {
        this.sorting.push({ property: sortBy, reverse: false });
      } else {
        if (!this.sorting[foundIndex].reverse) {
          this.sorting[foundIndex].reverse = !this.sorting[foundIndex].reverse;
        } else {
          _pullAt(this.sorting, [foundIndex]);
        }
      }

      this.sorting.forEach((sorting, index) => sorting.order = index + 1);

    } else if (sortBy === null) {
      this.sorting = [];
    }

    this.filteredRoleMetadata = this.roleManager.sortRoleMetadata(
      this.filteredRoleMetadata,
      this.sorting
    );

    if (sortBy === undefined && !this.selectedRoleMetadata) {
      if (this.filteredRoleMetadata.length) {
        this.selectMetadata(this.filteredRoleMetadata[0]);
      } else {
        this.selectMetadata(undefined);
      }
    }

  }

  /**
   * Sorts by the given attribute name.
   *
   * @param sortBy string
   * @returns MetadataSortByInterface
   */
  protected sortedBy(sortBy: string): MetadataSortByInterface {
    const index = _findIndex(this.sorting, { property: sortBy });
    if (index !== -1) {
        return this.sorting[index];
    } else {
      return <MetadataSortByInterface>{ reverse: undefined, order: undefined };
    }
  }
}
