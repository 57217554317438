import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { Subscription } from 'rxjs';
import { isObject as _isObject } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ItemService } from '../../services/item/item.service';
import { Localized } from '../../models/item/localized/localized.model';
import { MetadataManagerService } from '../../services/item/metadata-manager/metadata-manager.service';
import { Original } from '../../models/item/original/original.model';
import { RoleCapabilitiesHandler } from '../../services/role-capabilities-handler/role-capabilities-handler';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-cat-item-localized-details',
  template: require('./bolt-cat-item-localized-details.html'),
  styles: [require('./bolt-cat-item-localized-details.scss')]
})
export class BoltCatItemLocalizedDetailsComponent extends StormComponent implements OnInit, OnDestroy {
  @Input() item: Localized;

  @Output('update') protected updateEvent: EventEmitter<Localized>;

  protected lockingFlag: boolean;
  protected openEditItemPopupFlag: boolean;
  protected originalItem: Original;
  protected subscriptions: Subscription;

  constructor(
    protected itemManager: MetadataManagerService,
    protected itemService: ItemService,
    protected notificationService: NotificationService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    super();
    this.initialize();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.listenOriginalItemChanges();
  }

  /**
   * Indicates if the current user can edit.
   *
   * @returns boolean
   */
  protected canEdit(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalized(this.item.type.value, ActionTypeEnum.write);
  }

  /**
   * Closes the popup for editing an item.
   *
   * @returns void
   */
  protected closeEditItemPopup(): void {
    this.openEditItemPopupFlag = false;
  }

  /**
   * Indicates if it has to block the edition.
   *
   * @returns boolean
   */
  protected hasBlockEdit(): boolean {
    const hasIt: boolean = (this.item.lockingStatus.isTruthy() || this.hasDisplayProcessingSpinner());
    return hasIt;
  }

  /**
   * Indicates if it has to block the locking.
   *
   * @returns boolean
   */
  protected hasBlockLocking(): boolean {
    return this.hasDisplayProcessingSpinner();
  }

  /**
   * Indicates if it has to display the edit button.
   *
   * @return boolean
   */
  protected hasDisplayEditButton(): boolean {
    const hasIt: boolean =
      this.canEdit() &&
      (
        (this.item.lockingStatus.locked && this.hasDisplayLockingStatusButton()) ||
        !this.item.lockingStatus.locked
      );

    return hasIt;
  }

  /**
   * Indicates if it has to display the locking status button.
   *
   * @returns boolean
   */
  protected hasDisplayLockingStatusButton(): boolean {
    const hasIt: boolean =
      this.roleCapabilitiesHandler.canToggleLocalizedLockingStatusOf(
        this.item.lockingStatus.locked,
        this.item.type.value
      );

    return hasIt;
  }

  /**
   * Indicates if it has to display processing spinner.
   *
   * @returns boolean
   */
  protected hasDisplayProcessingSpinner(): boolean {
    return this.lockingFlag;
  }

  /**
   * Indicates if it has an item.
   *
   * @returns boolean
   */
  protected hasItem(): boolean {
    return _isObject(this.item);
  }

  /**
   * Indicates if it has to open the popup for editing an item.
   *
   * @returns boolean
   */
  protected hasOpenEditItemPopup(): boolean {
    return this.openEditItemPopupFlag;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.item = undefined;
    this.lockingFlag = false;
    this.originalItem = undefined;
    this.subscriptions = new Subscription();
    this.updateEvent = new EventEmitter();
  }

  /**
   * Listens when the original item changes.
   *
   * @returns void
   */
  protected listenOriginalItemChanges(): void {
    const subs: Subscription = this.itemManager.originalListener.subscribe(
      () => {
        this.originalItem = this.itemManager.original;
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Opens the popup for editing an item.
   *
   * @returns void
   */
  protected openEditItemPopup(): void {
    this.openEditItemPopupFlag = true;
  }

  /**
   * Set the item.
   *
   * @param item Localized
   * @returns void
   */
  protected setItem(item: Localized): void {
    this.itemManager.discoverLanguageOn(item);

    this.item = item;
    this.updateEvent.emit(this.item);
  }

  /**
   * Changes the locking state of the item.
   *
   * @returns void
   */
  protected toggleLockingStatus(): void {
    const data: any = {
      locked: !this.item.lockingStatus.locked
    };

    this.lockingFlag = true;

    this.itemService.updateLocalization(
      this.originalItem.type.value,
      this.originalItem.id,
      this.item.locale,
      data,
      (response: Localized) => {
        const message: string = `The localization is ${(this.item.lockingStatus.isTruthy() ? 'unlocked' : 'locked')}`;

        this.setItem(response);
        this.notificationService.handleSuccess(message, undefined, notificationsContainer.cat.details.key);

        this.lockingFlag = false;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'Error while trying to update the Localization lock status',
          error,
          notificationsContainer.cat.details.key
        );

        this.lockingFlag = false;
      }
    );
  }
}
