import { Component, Input, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject, pickBy as _pickBy } from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { TalentInterface } from '../../models/talent.model';
import { TalentManager } from '../../helpers/talent-manager/talent-manager.helper';
import { TalentService, TalentServiceFetchTalentParamsInterface } from '../../services/talent.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';

import {
  StormServiceResponseCollectionInterface, StormServiceResponsePaginationInterface, StormServiceResponsePagination,
  StormServiceResponseSortingInterface, StormServiceResponseSorting, StormServiceResponseCollection
} from 'app/modules/common/services/storm-service-response-collection';


@Component({
  selector: 'bolt-talent-list',
  template: require('./bolt-talent-list.html'),
  styles: [require('./bolt-talent-list.scss')]
})
export class BoltTalentListComponent extends StormComponent implements OnInit, OnChanges, OnDestroy {
  listData: Observable<StormServiceResponseCollectionInterface>;
  talents: TalentInterface[] = [];
  pagination: StormServiceResponsePaginationInterface = new StormServiceResponsePagination();
  sorting: StormServiceResponseSortingInterface = new StormServiceResponseSorting();
  sub: any;
  searchCriteria: TalentServiceFetchTalentParamsInterface = <TalentServiceFetchTalentParamsInterface>{ };

  @Input('BoltTalentListFilters') filters: FormGroup;
  @Input('BoltTalentListRefreshList') refreshList: boolean;

  protected fetchSubscription: Subscription;

  constructor(
    protected talentService: TalentService,
    protected talentManager: TalentManager,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected appConfig: AppConfigProvider,
    protected notificationService: NotificationService
  ) {
    super();
  }

  ngOnInit() {
    this.searchCriteria = this.getDefaultSearchCriteria();

    this.sub = this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (Object.keys(params).length) {
          Object.assign(this.searchCriteria, params);
        } else {
          this.searchCriteria = this.getDefaultSearchCriteria();
        }

        this.fetch();
      }
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.cancelFetch();
  }

  /**
   * Navigates to the corresponding title list page, based on the provided params.
   *
   * @param page number
   * @param searchCriteria object
   * @return Promise<boolean>
   */
  loadPage(
    page: number,
    searchCriteria: object
  ): Promise<boolean> {
    return this.router.navigate([
      '/talent',
      _pickBy(Object.assign(this.searchCriteria, searchCriteria, { page: page }), (param) => param)
    ]);
  }

  /**
   * Returns the default search criteria.
   *
   * @return TalentServiceFetchTalentParamsInterface
   */
  getDefaultSearchCriteria(): TalentServiceFetchTalentParamsInterface {
    return {
      q: '',
      page_size: <number>this.appConfig.get('ux.dataTables.pageSize', 20),
      page: 1
    };
  }

  ngOnChanges(changes) {
    if (changes.filters && changes.filters.currentValue) {
      this.filters.valueChanges.pipe(
        debounceTime(this.appConfig.get('ux.typing.debounceTime', 0))
      )
      .subscribe(filters => {
        if (this.filters.valid) {
          this.loadPage(1, filters);
        }
      });
    }
    if (changes.refreshList && changes.refreshList.currentValue === true) {
      this.fetch();
    }
  }

  /**
   * Cancels the fetch.
   *
   * @returns void
   */
   protected cancelFetch(): void {
    if (_isObject(this.fetchSubscription)) {
      this.fetchSubscription.unsubscribe();
    }
  }

  /**
   * Fetch Talents from the service
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();
    this.cancelFetch();

    this.fetchSubscription = this.talentService.oldSearch(this.searchCriteria).subscribe(
      (response: StormServiceResponseCollection) => {
        this.talentManager.mapAttributes(response.collection).subscribe(
          (data: any) => {
            this.talents = data;
            this.pagination = response.pagination;
            this.sorting = response.sorting;

            if (data.length) {
              this.changeStatusToDataFound();
            } else {
              this.changeStatusToDataNotFound();
            }
          }
        );
      },
      (error: any) => {
        this.changeStatusToError();
        this.notificationService.handleError('Failed fetching talents.', error);
      }
    );
  }
}
