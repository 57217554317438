import { FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { isNumber as _isNumber } from 'lodash';

import { TalentForm } from '../talent-form.model';


export class OriginalDataForm extends TalentForm {
  protected appleIdField: FormControl;
  protected imbIdField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  /**
   * Get the apple id field.
   *
   * @returns FormControl
   */
  getAppleIdField(): FormControl {
    return this.appleIdField;
  }

  /**
   * Get the imb id field.
   *
   * @returns FormControl
   */
  getImbIdField(): FormControl {
    return this.imbIdField;
  }

  toJson(): any {
    const data: any = {
      ...super.toJson(),
      appleId: this.getAppleIdField().value,
      imdbId: this.getImbIdField().value
    };

    return data;
  }

  protected getNoAffixCustomRequiredValidator(noAffixControl: AbstractControl): ValidatorFn {
    const validator: ValidatorFn = (control: FormControl) => {
      if (!noAffixControl.value && !_isNumber(control.value)) {
        return {
          required: true,
          dirty: control.dirty,
        };
      }
    };

    return validator;
  }

  /**
   * Set up the apple id field.
   *
   * @returns void
   */
  protected setupAppleId(): void {
    this.appleIdField = new FormControl(this.getDefaultValue('appleId'));
    this.addControl('appleId', this.appleIdField);
  }

  protected setupFields(): void {
    super.setupFields();
    this.setupAppleId();
    this.setupImdbId();
  }

  /**
   * Set up the imdb id field.
   *
   * @returns void
   */
  protected setupImdbId(): void {
    this.imbIdField = new FormControl(this.getDefaultValue('imdbId'));
    this.addControl('imdbId', this.imbIdField);
  }
}
