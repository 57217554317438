import { includes as _includes } from 'lodash';

import { SearchTypeEnum } from './search-type.enum';
import { ErrorHelper } from '../../../helpers/http/response/error/error.helper';


export class SearchType {
  protected _value: SearchTypeEnum;

  constructor(value: SearchTypeEnum) {
    const normalizedValue: string = String(value).trim();

    if (_includes(SearchTypeEnum, normalizedValue)) {
      this._value = value;
    } else {
      throw new ErrorHelper('Invalid value given for a search type.');
    }
  }

  get value(): SearchTypeEnum {
    return this._value;
  }

  /**
   * Indicates if it is "ALL".
   *
   * @returns boolean
   */
  isAll(): boolean {
    return this.is(SearchTypeEnum.All);
  }

  /**
   * Indicates if it is "Localization".
   *
   * @returns boolean
   */
  isLocalization(): boolean {
    return this.is(SearchTypeEnum.Localization);
  }

  /**
   * Indicates if it is "Original".
   *
   * @returns boolean
   */
  isOriginal(): boolean {
    return this.is(SearchTypeEnum.Original);
  }

  /**
   * Set the stored value to "All".
   *
   * @returns void
   */
  setAll(): void {
    this._value = SearchTypeEnum.All;
  }

  /**
   * Set the stored value to "Localization".
   *
   * @returns void
   */
  setLocalization(): void {
    this._value = SearchTypeEnum.Localization;
  }

  /**
   * Set the stored value to "Original".
   *
   * @returns void
   */
  setOriginal(): void {
    this._value = SearchTypeEnum.Original;
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this._value.toString();
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value SearchTypeEnum
   * @returns boolean
   */
  protected is(value: SearchTypeEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
