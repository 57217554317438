import {
  isArray as _isArray, isBoolean as _isBoolean, isNumber as _isNumber, isObject as _isObject,
  isString as _isString, isUndefined as _isUndefined
} from 'lodash';

import { Category } from '../category/category.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Type } from '../type/type.model';
import { AssociationList } from 'app/modules/cat/models/product/association-list/association-list.model';


export class Element {
  protected _category: Category;
  protected _id: number;
  protected _isLocalization: boolean;
  protected _languageCode: string;
  protected _localizedName: string;
  protected _name: string;
  protected _products: AssociationList;
  protected _type: Type;

  constructor(data: any) {
    this.initialize(data);
  }

  get category(): Category {
    return this._category;
  }

  get id(): number {
    return this._id;
  }

  get isLocalization(): boolean {
    return this._isLocalization;
  }

  get languageCode(): string {
    return this._languageCode;
  }

  get localizedName(): string {
    return this._localizedName;
  }

  get name(): string {
    return this._name;
  }

  get products(): AssociationList {
    return this._products;
  }
  get type(): Type {
    return this._type;
  }

  /**
   * Indicates if it has a category.
   *
   * @returns boolean
   */
  hasCategory(): boolean {
    return _isObject(this._category);
  }

  /**
   * Indicates if it has products.
   *
   * @returns boolean
   */
  hasProducts(): boolean {
    return this._products.hasProductAssociations();
  }

  /**
   * Initialized the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    if (_isObject(data)) {
      this.setCategory(data);
      this.setId(data);
      this.setIsLocalization(data);
      this.setName(data);
      this.setProducts(data);
      this.setType(data);

      if (this.isLocalization) {
        this.setLanguageCode(data);
        this.setLocalizedName(data);
      }
    } else {
      throw new ErrorHelper('Invalid data for an element.');
    }
  }

  /**
   * Set the category, if it defined.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setCategory(data: any): void {
    if (data.category) {
      this._category = new Category(data.category);
    }
  }

  /**
   * Set the ID.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setId(data: any): void {
    if (_isNumber(data.id)) {
      this._id = data.id;
    } else {
      throw new ErrorHelper('Invalid ID given for an element.');
    }
  }

  /**
   * Set the ID.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setIsLocalization(data: any): void {
    if (_isUndefined(data.isLocalization)) {
      this._isLocalization = false;
    } else if (_isBoolean(data.isLocalization)) {
      this._isLocalization = data.isLocalization;
    } else {
      throw new ErrorHelper('Invalid isLocalization given for an element.');
    }
  }

  /**
   * Set the language code.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setLanguageCode(data: any): void {
    if (_isString(data.language)) {
      this._languageCode = data.language;
    } else {
      throw new ErrorHelper('Invalid language given for an element.');
    }
  }

  /**
   * Set the localized name.
   *
   * @param data any
   * @returns void
   */
  protected setLocalizedName(data: any): void {
    this._localizedName = String(data.nameLocalization).trim();
  }

  /**
   * Set the name.
   *
   * @param data any
   * @returns void
   */
  protected setName(data: any): void {
    this._name = String(data.name).trim();
  }

  /**
   * Reads the products.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setProducts(data: any): void {
    this._products = new AssociationList(data);
  }

  /**
   * Set the type.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setType(data: any): void {
    this._type = new Type(data.type);
  }
}
