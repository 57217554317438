export const notificationsContainer: any = {
  cat: {
    key: 'cat',
    details: {
      key: 'cat-details'
    }
  },
  character: {
    details: {
      key: 'character-details'
    }
  },
  pie: {
    export: {
      key: 'pie-export'
    },
    import: {
      key: 'pie-import'
    }
  },
  role: {
    key: 'role',
    details: {
      key: 'role-details'
    }
  },
  projectDashboard: {
    key: 'project-dashboard'
  },
  search: {
    key: 'search'
  },
  talent: {
    key: 'talent',
    details: {
      key: 'talent-details'
    }
  },
};
