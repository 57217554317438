import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Observable, Subject, Subscription } from 'rxjs';
import { chunk as _chunk, isBoolean as _isBoolean, isObject as _isObject } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Lock } from '../../../models/entity/lock/lock.model';
import { ManagerService } from '../../project/manager/manager.service';
import { ProjectService } from '../../project/project.service';
import { Queue } from './queue/queue.model';


@Injectable()
export class LocksRecoverService {
  protected _errorsListener: Observable<[ string[], ErrorHelper ]>;
  protected _locksListener: Observable<Lock[]>;
  protected errorsNotifier: Subject<[ string[], ErrorHelper ]>;
  protected fetchOn: boolean[];
  protected locksNotifier: Subject<Lock[]>;
  protected queues: Queue[];
  protected queueSize: number;
  protected sliceSize: number;
  protected stopOn: boolean;
  protected subscriptions: Subscription;

  constructor(
    protected appConfig: AppConfigProvider,
    protected projectManager: ManagerService,
    protected projectService: ProjectService
  ) {
    this.errorsNotifier = new Subject();
    this._errorsListener = this.errorsNotifier.asObservable();

    this.locksNotifier = new Subject();
    this._locksListener = this.locksNotifier.asObservable();

    this.queueSize = this.appConfig.get('ux.page.projectDashboard.fetchLocks.queueSize');
    this.sliceSize = this.appConfig.get('ux.page.projectDashboard.fetchLocks.sliceSize');

    this.reset();
  }

  get errorsListener(): Observable<[ string[], ErrorHelper ]> {
    return this._errorsListener;
  }

  get locksListener(): Observable<Lock[]> {
    return this._locksListener;
  }

  /**
   * Fetches the locks for the given map keys.
   *
   * @param mapKeys string[]
   * @returns void
   */
  fetch(mapKeys: string[]): void {
    const slices: string[][] = _chunk(mapKeys, this.sliceSize);

    const requests: string[][][] = _chunk(
      slices,
      Math.ceil(slices.length / this.queueSize)
    );

    this.turnOffStop();

    requests.forEach(
      (slice: string[][], queueIndex: number) => {
        this.queues[queueIndex].add(slice);

        if (!this.isFetchingFor(queueIndex)) {
          this.doFetchFor(queueIndex);
        }
      }
    );
  }

  /**
   * Indicates if it is fetching.
   *
   * @returns boolean
   */
  isFetching(): boolean {
    let isIt: boolean = false;
    let index: number = 0;

    while (!isIt && (index < this.queueSize)) {
      isIt = this.isFetchingFor(index);
      index++;
    }

    return isIt;
  }

  /**
   * Reset it.
   *
   * @returns void
   */
  reset(): void {
    this.cancelSubscriptions();
    this.turnOnStop();

    this.fetchOn = new Array();
    this.queues = new Array();

    for (let queueIndex = 0; queueIndex < this.queueSize; queueIndex++) {
      this.queues[queueIndex] = new Queue();
      this.turnOffFetchFor(queueIndex);
    }
  }

  /**
   * Cancels the subscriptions.
   *
   * @returns void
   */
  protected cancelSubscriptions(): void {
    if (_isObject(this.subscriptions)) {
      this.subscriptions.unsubscribe();
    }

    this.subscriptions = new Subscription();
  }

  /**
   * Fetches the locks for the queue with the given index.
   *
   * @param queueIndex number
   * @returns void
   */
  protected doFetchFor(queueIndex: number): void {
    const queue: Queue = this.queues[queueIndex];

    if (this.hasStop() || queue.isPositionAtEnd()) {
      this.turnOffFetchFor(queueIndex);
    } else {
      this.turnOnFetchFor(queueIndex);

      const entitiesId: number[] = queue.current().map(
        (mapKey: string) => {
          const id: number = Number(mapKey.split('-')[1]);
          return id;
        }
      );

      const subs: Subscription = this.projectService.fetchLocks(
        this.projectManager.currentProject.id,
        entitiesId
      ).subscribe(
        (locks: Lock[]) => {
          this.locksNotifier.next(locks);
          queue.next();
          this.doFetchFor(queueIndex);
        },
        (error: ErrorHelper) => {
          this.errorsNotifier.next([ queue.current(), error ]);
          queue.next();
          this.doFetchFor(queueIndex);
        }
      );

      this.subscriptions.add(subs);
    }
  }

  /**
   * Indicates if it has stop it.
   *
   * @returns boolean
   */
  protected hasStop(): boolean {
    return this.stopOn;
  }

  /**
   * Indicates if the queue for the given index is in fetching process.
   *
   * @param queueIndex number
   * @returns boolean
   */
  protected isFetchingFor(queueIndex: number): boolean {
    const isIt: boolean = (_isBoolean(this.fetchOn[queueIndex]) && this.fetchOn[queueIndex]);
    return isIt;
  }

  /**
   * Turns off the fetch for the given queue index.
   *
   * @param queueIndex number
   * @returns void
   */
  protected turnOffFetchFor(queueIndex: number): void {
    this.fetchOn[queueIndex] = false;
  }

  /**
   * Turns off the stop flag.
   *
   * @returns void
   */
  protected turnOffStop(): void {
    this.stopOn = false;
  }

  /**
   * Turns on the fetch for the given queue index.
   *
   * @param queueIndex number
   * @returns void
   */
  protected turnOnFetchFor(queueIndex: number): void {
    this.fetchOn[queueIndex] = true;
  }

  /**
   * Turns on the stop flag.
   *
   * @returns void
   */
  protected turnOnStop(): void {
    this.stopOn = true;
  }
}
