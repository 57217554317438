import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutingModule } from '@bolt/ui-shared/routing';

import { dashboardModuleRouting } from './dashboard.routing';
import { StormPageDashboardComponent } from './components/storm-page-dashboard/storm-page-dashboard.component';


@NgModule({
  imports: [
    dashboardModuleRouting,
    RoutingModule,
    CommonModule
  ],
  declarations: [
    StormPageDashboardComponent
  ],
  exports: [ ],
  providers: [ ]
})
export class DashboardModule { }
