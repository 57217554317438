import { FormControl, Validators } from '@angular/forms';

import { Localized } from '../localized.model';
import { Original } from '../../original/original.model';


export class EditionForm {
  protected _language: FormControl;
  protected _name: FormControl;
  protected _notes: FormControl;
  protected _phonetic: FormControl;
  protected _rootId: FormControl;
  protected _useDomestic: FormControl;
  protected attributes: any;
  protected localizedItem: Localized;
  protected originalItem: Original;

  constructor(original: Original, localized: Localized, attributes: any) {
    this.initialize(original, localized, attributes);
  }

  get language(): FormControl {
    return this._language;
  }

  get name(): FormControl {
    return this._name;
  }

  get notes(): FormControl {
    return this._notes;
  }

  get phonetic(): FormControl {
    return this._phonetic;
  }

  get rootId(): FormControl {
    return this._rootId;
  }

  get useDomestic(): FormControl {
    return this._useDomestic;
  }

  /**
   * Initializes the instance.
   *
   * @param original Original
   * @param localized Localized
   * @param attributes any
   * @returns void
   */
  protected initialize(original: Original, localized: Localized, attributes: any): void {
    this.attributes = attributes;
    this.localizedItem = localized;
    this.originalItem = original;

    this.setupLanguage();
    this.setupName();
    this.setupNotes();
    this.setupPhonetic();
    this.setupRootId();
    this.setupUseDomestic();
  }

  /**
   * Set ups the language field.
   *
   * @returns void
   */
  protected setupLanguage(): void {
    this._language = new FormControl(this.localizedItem.language.id, Validators.required);
  }

  /**
   * Set ups the name field.
   *
   * @returns void
   */
  protected setupName(): void {
    const validators: any = Validators.compose([
      Validators.required,
      Validators.maxLength(this.attributes.localizedName.maxLength)
    ]);

    // BOLTM-2919 TODO remove the useDomestic condition and use the localizedItem name when fallback comes from API
    const name: string = this.localizedItem.useDomestic
      ? this.originalItem.name
      : this.localizedItem.name;

    this._name = new FormControl(name, validators);
  }

  /**
   * Set ups the notes field.
   *
   * @returns void
   */
  protected setupNotes(): void {
    const validators: any = Validators.maxLength(this.attributes.notes.maxLength);
    this._notes = new FormControl(this.localizedItem.notes, validators);
  }

  /**
   * Set ups the phonetic field.
   *
   * @returns void
   */
  protected setupPhonetic(): void {
    const validators: any = Validators.maxLength(this.attributes.phonetic.maxLength);

    // BOLTM-2919 TODO remove the useDomestic condition and use the localizedItem phonetic when fallback comes from API
    const phonetic: string = this.localizedItem.useDomestic
      ? this.originalItem.phonetic
      : this.localizedItem.phonetic;

    this._phonetic = new FormControl(phonetic, validators);
  }

  /**
   * Set ups the root ID field.
   *
   * @returns void
   */
  protected setupRootId(): void {
    this._rootId = new FormControl(this.originalItem.id, Validators.required);
  }

  /**
   * Set ups the use-domestic field.
   *
   * @returns void
   */
  protected setupUseDomestic(): void {
    this._useDomestic = new FormControl(this.localizedItem.useDomestic);
  }
}
