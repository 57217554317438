import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { SearchCriteria } from '@bolt/ui-shared/searching';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from '../../common/services/bolt-abstract.service';
import { Character, CharacterInterface } from '../models/character.model';
import { CharacterMetadata, CharacterMetadataInterface } from '../models/character-metadata.model';
import { CharacterResponse } from '../models/character-response/character-response.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';

import {
  StormServiceResponseCollection, StormServiceResponseCollectionInterface
 } from 'app/modules/common/services/storm-service-response-collection';

import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { StormServicesQueryParams } from 'app/modules/common/services/storm-services-query-params';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';


export enum CharacterServiceFetchCharacterQueryParams {
  q = <any>'q',
}

export interface CharacterServiceFetchCharacterMetadataParamsInterface {
  characterId: number;
  locale?: string;
}

export interface CharacterServiceFetchCharacterParamsInterface {
  q: string;
  page_size: number;
  page: number;
  sort_by?: string;
  sort_direction?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CharacterService extends BoltAbstractService {
  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetch a single Character
   *
   * @param params CharacterServiceFetchCharacterMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchCharacter(
    params: CharacterServiceFetchCharacterMetadataParamsInterface
  ): Observable<StormServiceResponseSingle> {

    return this.authHttp
      .get(
        this.generateUrl('characterService.fetchCharacter.endpoint', { '{characterId}': params.characterId })
      ).pipe(
        map((response: any) => {
          const responsePayloadJson = response;

          responsePayloadJson.localizations.forEach((metadata, index) => {
            responsePayloadJson.localizations[index] = new CharacterMetadata(metadata);
          });

          const character = new Character(responsePayloadJson);

          return new StormServiceResponseSingle(character);

        })
      );
  }

  /**
   * Fetchs the localizations for the given character.
   *
   * @param characterId number
   * @param onSucessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  public fetchLocalization(
    characterId: number,
    onSucessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const url: string = this.generateUrl(
      'characterService.fetchCharacterLocalization.endpoint',
      { '{characterId}': characterId }
    );

    let subs: Subscription;

    subs = this.doGetRequest(
      { url: url, checkType: CheckTypeEnum.array },
      (succesResponse: StormServiceResponseSingle) => {
        try {
          const localizations: CharacterMetadata[] = new Array();
          succesResponse.item.forEach(
            (rawData: any) => {
              const character: CharacterMetadata = new CharacterMetadata(rawData);

              localizations.push(character);
            }
          );

          onSucessDo(localizations);
        } catch (error) {
          onErrorDo(error);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Creates a new Character
   *
   * @param  character CharacterInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  createCharacter(
    character: Character
  ): Observable<StormServiceResponseSingle> {
    const request: any = {
      url: this.generateUrl('characterService.createCharacter.endpoint', null),
      body: JSON.stringify(character.getRawObject())
    };

    return this.doPostRequestAsObservable(
      request
    ).pipe(
      map((response: any) => {
        return new StormServiceResponseSingle(new Character(response.item));
      })
    );
  }

  /**
   * Fetch the Character Metadata for a specific Character/locale
   *
   * @param  params CharacterServiceFetchCharacterMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchCharacterMetadata(
    params: CharacterServiceFetchCharacterMetadataParamsInterface
  ): Observable<StormServiceResponseSingle> {

    return this.authHttp
      .get(
        this.generateUrl(
          'characterService.fetchCharacterMetadata.endpoint',
          {
            '{characterId}': params.characterId,
            '{locale}': params.locale
          }
        )
      ).pipe(
        map((response: any) => {
          const responsePayloadJson = response;
          const characterMetadata = new CharacterMetadata(responsePayloadJson);
          return new StormServiceResponseSingle(characterMetadata);
        })
      );
  }

  /**
   * Fetch the computed Character Metadata for a specific Character/locale
   *
   * @param params CharacterServiceFetchCharacterMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchComputedCharacterMetadata(
    params: CharacterServiceFetchCharacterMetadataParamsInterface
  ): Observable<StormServiceResponseSingle> {

    return this.authHttp
      .get(
        this.generateUrl(
          'characterService.fetchComputedCharacterMetadata.endpoint',
          {
            '{characterId}': params.characterId,
            '{locale}': params.locale
          }
        )
      ).pipe(
        map((response: any) => {
          const responsePayloadJson = response;
          const characterMetadata = new CharacterMetadata(responsePayloadJson);
          return new StormServiceResponseSingle(characterMetadata);
        })
      );
  }

  /**
   * Updates a Character
   *
   * @param  params CharacterServiceFetchCharacterMetadataParamsInterface
   * @param  updates CharacterInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  updateCharacter(
    params: CharacterServiceFetchCharacterMetadataParamsInterface,
    updates: CharacterInterface
  ): Observable<StormServiceResponseSingle> {

    return this.authHttp
      .put(
      this.generateUrl('characterService.updateCharacter.endpoint', { '{characterId}': params.characterId }),
      JSON.stringify(updates)
      ).pipe(
        map((response: any) => {
          const responsePayloadJson = response;

          responsePayloadJson.localizations.forEach((metadata, index) => {
            responsePayloadJson.localizations[index] = new CharacterMetadata(metadata);
          });

          const character = new Character(responsePayloadJson);
          return new StormServiceResponseSingle(character);
        })
      );
  }

  /**
   * Updates Character Metadata
   *
   * @param params CharacterServiceFetchCharacterMetadataParamsInterface
   * @param fromLocale Locale
   * @param toLocale Locale
   * @param characterMetadata CharacterMetadataInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  updateCharacterMetadata(
    params: CharacterServiceFetchCharacterMetadataParamsInterface,
    fromLocale: Locale,
    toLocale: Locale,
    characterMetadata: CharacterMetadataInterface
  ): Observable<StormServiceResponseSingle> {

    return this.authHttp
      .post(
      this.generateUrl('characterService.moveMetadata.endpoint', { '{characterId}': params.characterId }),
      JSON.stringify(Object.assign({
        from: fromLocale,
        to: toLocale,
      }, characterMetadata))
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new CharacterMetadata(response));
        })
      );
  }

  /**
   * Sets the metadata for a Character
   *
   * @param  params CharacterServiceFetchCharacterMetadataParamsInterface
   * @param  locale string
   * @param  characterMetadata CharacterMetadataInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  setCharacterMetadata(
    params: CharacterServiceFetchCharacterMetadataParamsInterface,
    locale: string,
    characterMetadata: CharacterMetadata
  ): Observable<StormServiceResponseSingle> {

    return this.authHttp
      .post(
        this.generateUrl(
          'characterService.createCharacterMetadata.endpoint',
          { '{characterId}': params.characterId, '{locale}': locale }
        ),
        JSON.stringify(characterMetadata.getRawObject())
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new CharacterMetadata(response));
        })
      );
  }

  /**
   * Deletes the given Character Metadata properties (attributes)
   *
   * @param  params CharacterServiceFetchCharacterMetadataParamsInterface
   * @param  locale string
   * @param  properties string[]
   * @returns Observable<StormServiceResponseSingle>
   */
  deleteCharacterMetadataAttribute(
    params: CharacterServiceFetchCharacterMetadataParamsInterface,
    locale: string,
    properties: string[]
  ): Observable<StormServiceResponseSingle> {

    return this.authHttp
      .delete(
        this.generateUrl(
          'characterService.deleteCharacterMetadataAttribute.endpoint',
          { '{characterId}': params.characterId, '{locale}': locale }
        ),
        JSON.stringify(properties)
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new CharacterMetadata(response));
        })
      );
  }

  /**
   * Deletes the given Character Metadata properties (attributes)
   *
   * @param  params CharacterServiceFetchCharacterMetadataParamsInterface
   * @param  locale string
   * @return Observable<StormServiceResponseSingle>
   */
  deleteCharacterLocalization(
    params: CharacterServiceFetchCharacterMetadataParamsInterface,
    locale: string
  ): Observable<StormServiceResponseSingle> {
    return this.authHttp
      .delete(
        this.generateUrl(
          'characterService.deleteCharacterLocalization.endpoint',
          { '{characterId}': params.characterId, '{locale}': locale }
        )
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new CharacterMetadata(response));
        })
      );
  }

  /**
   * Returns a subscription for searching items using the given query
   *
   * @param criteria SearchCriteria
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  search(
    criteria: SearchCriteria,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.searchAsObservable(criteria).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (successResponse: SearchResponse) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      }
    );

    return subs;
  }

  /**
   * Returns an observable for searching character using the given query.
   *
   * @param criteria SearchCriteria
   * @returns Observable<SearchResponse>
   */
  searchAsObservable(criteria: SearchCriteria): Observable<SearchResponse> {
    const url: string = this.generateUrl(
      'characterService.search.endpoint',
      undefined,
      criteria.asEndpointData()
    );

    const obs: Observable<SearchResponse> = this.doGetRequestAsObservable({ url: url }).pipe(
      map((response: StormServiceResponseSingle) => {
        const mappedResponse: SearchResponse = new SearchResponse(
          response.item,
          (element: any) => new CharacterResponse(element)
        );

        return mappedResponse;
      })
    );

    return obs;
  }

  /**
   * Returns an observable for searching the given parameters.
   *
   * @param searchParams CharacterServiceFetchCharacterParamsInterface
   * @returns Observable<StormServiceResponseCollectionInterface>
   */
  oldSearch(
    searchParams: CharacterServiceFetchCharacterParamsInterface,
    excludeAdditional: boolean = false
  ): Observable<StormServiceResponseCollectionInterface> {
    const search: UrlSearchParams = new UrlSearchParams();

    search.set(CharacterServiceFetchCharacterQueryParams.q.toString(), searchParams.q);
    search.set(StormServicesQueryParams.page.toString(), (searchParams.page - 1).toString());
    search.set(StormServicesQueryParams.page_size.toString(), searchParams.page_size.toString());

    if (excludeAdditional) {
      search.set(StormServicesQueryParams.type.toString(), 'REGULAR');
    }

    if (searchParams.page_size && searchParams.sort_direction) {
      search.set(StormServicesQueryParams.sort.toString(), searchParams.sort_by + ':' + searchParams.sort_direction);
    }

    const apiUrl = this.generateUrl('characterService.oldSearch.endpoint', undefined, search);

    const obs: Observable<StormServiceResponseCollectionInterface> =
      this.authHttp.get(apiUrl).pipe(
        map(
          (response: any) => {
            const responseCollection = response.content.map(
              (item: any) => new Character(item)
            );

            return new StormServiceResponseCollection(
              responseCollection,
              Number(response.number) + 1,
              Number(response.size),
              Number(response.totalPages),
              Number(response.totalElements),
              searchParams.sort_by,
              searchParams.sort_direction
            );
          }
        )
      );

      return obs;
    }

  /**
   * Returns an observable with the result of the additional search.
   *
   * @returns Observable<any>
   */
  searchAdditional(): Observable<any> {
    const apiSearch = this.generateUrl('characterService.searchAdditional.endpoint');

    return this.authHttp
      .get(apiSearch)
      .pipe(
        map((response: HttpResponse<Character[]>) => {
          const responsePayloadJson = response;
          return responsePayloadJson;
        })
      );
  }
}
