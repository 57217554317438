import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs/internal/Observable';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { GenericWithNameForm } from 'app/modules/masterData/models/generic/name.model';


@Component({
  selector: 'bolt-base-master-data-handler-panel',
})
export class BoltBaseMasterDataHandlerPanelComponent implements OnChanges, OnDestroy {
  @Input() data: any | undefined;
  @Input() show: boolean;
  @Output('cancelled') cancelEvent: EventEmitter<void>;
  @Output('saved') saveEvent: EventEmitter<MasterDataType>;

  protected actionSubscription: Subscription;
  protected form: GenericWithNameForm;
  protected fieldSpecs: any;
  protected isCreating: boolean;
  protected entityName: string;
  protected type: MasterDataType;

  constructor(
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected appConfig: AppConfigProvider
  ) {
    this.cancelEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.show = false;

    this.setupFieldSpecs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.data)) {
      this.isCreating = _isUndefined(this.data);
    }

    if (_isObject(changes.show)) {
      if (this.show) {
        this.form = new GenericWithNameForm(this.fieldSpecs, this.data);
      } else {
        this.form = undefined;
        this.cancelActionSubscription();
      }
    }
  }

  ngOnDestroy() {
    this.cancelActionSubscription();
  }

  /**
   * Cancels the action over the data.
   *
   * @returns void
   */
  protected cancel(): void {
    this.cancelEvent.emit();
  }

  /**
   * Cancels the subscription for the triggered action.
   *
   * @returns void
   */
  protected cancelActionSubscription(): void {
    if (_isObject(this.actionSubscription)) {
      this.actionSubscription.unsubscribe();
    }
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    return this.fieldSpecs[`${field}MaxLength`];
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.form.disabled;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const isIt: boolean = this.form.invalid || this.form.disabled;
    return isIt;
  }

  /**
   * Save the current data.
   *
   * @returns void
   */
  protected save(): void {
    this.form.disable();

    const data: any = this.form.toJson();
    const action: Observable<any> = this.isCreating
      ? this.masterDataService.create(this.type, data)
      : this.masterDataService.update(this.type, this.data.id, data);

    this.actionSubscription = action.subscribe(
      (newData: MasterDataType) => {
        this.notificationService.handleSuccess(`The ${this.entityName} was saved successfully.`);
        this.saveEvent.emit(newData);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(`Failed saving the new ${this.entityName}`, error);
        this.form.enable();
      }
    );
  }

  /**
   * Setup the specs for the fields
   *
   * @returns void
   */
  protected setupFieldSpecs(): void {
    this.fieldSpecs = {
      // Set the max length of language which it is 255
      nameMaxLength: this.appConfig.get('forms.masterdata.language.fields.name.maxLength')
    };
  }
}
