import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Action } from 'app/modules/title/models/level-data/action/action.model';
import { ActionEnum } from 'app/modules/title/models/level-data/action/action.enum';


@Injectable()
export class ActionHandlerService {
  protected _action: Action;
  protected _actionListener: Observable<Action>;
  protected actionNotifier: Subject<Action>;

  constructor() {
    this.initialize();
  }

  get action(): Action {
    return this._action;
  }

  get actionListener(): Observable<Action> {
    return this._actionListener;
  }

  /**
   * Triggers a "Hide Data" action.
   *
   * @returns void.
   */
  hideData(): void {
    this.trigger(ActionEnum.hideData);
  }

  /**
   * Triggers a "Save" action.
   *
   * @returns void.
   */
  save(): void {
    this.trigger(ActionEnum.save);
  }

  /**
   * Triggers a "Save & Lock" action.
   *
   * @returns void.
   */
  saveAndLock(): void {
    this.trigger(ActionEnum.saveAndLock);
  }

  /**
   * Triggers a "Show Data" action.
   *
   * @returns void.
   */
  showData(): void {
    this.trigger(ActionEnum.showData);
  }

  /**
   * Triggers an "Unlock" action.
   *
   * @returns void.
   */
  unlock(): void {
    this.trigger(ActionEnum.unlock);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.actionNotifier = new Subject();
    this._actionListener = this.actionNotifier.asObservable();
  }

  /**
   * Changes the current action and notifies it.
   *
   * @param value ActionEnum
   * @returns void
   */
  protected trigger(value: ActionEnum): void {
    this._action = new Action(value);
    this.actionNotifier.next(this.action);
  }
}
