import { Injectable } from '@angular/core';
import { Account, Country, Language, ProductType, StormListType } from '@bolt/ui-shared/master-data';
import * as _ from 'lodash';

import { BehaviorSubject, Observable } from 'rxjs';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { StormServiceResponseSingleInterface } from 'app/modules/common/services/storm-service-response-single';
import { TalentInterface, Talent } from '../../models/talent.model';
import { TalentMetadataInterface } from '../../models/talent-metadata.model';
import { TalentMetadataManagerActions } from '../../components/bolt-talent-metadata-manager/bolt-talent-metadata-manager.component';
import { TalentService } from '../../services/talent.service';

export interface TalentManagerMetadataManagement {
  talentMetadata: TalentMetadataInterface;
  action: TalentMetadataManagerActions;
  key: string[];
  talent: TalentInterface;
  defaultValues?: object;
}

@Injectable()
export class TalentManager {

  protected singleTalentMetadata: BehaviorSubject<TalentManagerMetadataManagement> = new BehaviorSubject(undefined);

  constructor(
    protected talentService: TalentService,
    protected stormListsProvider: StormListsProvider
  ) { }

   /**
    * Allows to manage (create, edit, delete) a Talent Metadata entry
    * This method will emit a TalentManagerMetadataManagement that
    * will be handled by the BoltTalentMetadataManagerComponent
    *
    * @param metadata TalentMetadataInterface
    * @param action TalentMetadataManagerActions
    * @param talent TalentInterface
    * @param key string[]
    * @param defaultValues object
    * @returns void
    */
  manageTalentMetadata(
    metadata: TalentMetadataInterface,
    action: TalentMetadataManagerActions,
    talent: TalentInterface,
    key?: string[],
    defaultValues?: object
  ): void {

    this.singleTalentMetadata.next({
      talentMetadata: metadata,
      action: action,
      key: key,
      talent: talent,
      defaultValues: defaultValues,
    });

  }

  /**
   * Returns the observable Talent Metadata
   *
   * @returns Observable<TalentManagerMetadataManagement>
   */
  getManagedTalentMetadata(): Observable<TalentManagerMetadataManagement> {
    return this.singleTalentMetadata.asObservable();
  }

  /**
   * Takes care of creating new TalentMetadata
   *
   * @param talent TalentInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  createTalent(talent: TalentInterface) {
    return this.talentService.createTalent(talent);
  }

  /**
   * Sets the talent metadata to be managed
   *
   * @param talentId number
   * @param talentMetadata Talent
   * @param locked boolean
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  setTalentMetadata(
    talentId: number,
    locale: string,
    talentMetadata: Talent,
    locked: boolean = false
  ): Observable<StormServiceResponseSingleInterface> {

    talentMetadata['locked'] = locked;

    return this.talentService.setTalentMetadata({
        talentId: talentId
      },
      locale,
      this.parseTalentMetadata(talentMetadata)
    );

  }

  /**
   * Takes care of updating the TalentMetadata
   *
   * @param talentId number
   * @param fromLocale Locale
   * @param toLocale Locale
   * @param talentMetadata TalentMetadataInterface
   * @param locked boolean
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  updateTalentMetadata(
    talentId: number,
    fromLocale: Locale,
    toLocale: Locale,
    talentMetadata: Talent,
    locked: boolean = false
  ): Observable<StormServiceResponseSingleInterface> {

    return this.talentService.updateTalentMetadata({
        talentId: talentId
      },
      fromLocale,
      toLocale,
      this.parseTalentMetadata(talentMetadata)
    );

  }

  /**
   * Takes care of deleting the TalentMetadata
   *
   * @param talentId number
   * @param locale string
   * @param properties string[]
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  deleteTalentMetadataAttribute(
    talentId: number,
    locale: string,
    properties: string[]
  ): Observable<StormServiceResponseSingleInterface> {

    return this.talentService.deleteTalentMetadataAttribute({
        talentId: talentId
      },
      locale,
      properties
    );

  }

  /**
   * Prepares the Talent Metadata attributes to be sure the API
   * will not reject the request because of wrong data
   *
   * @param talentMetadata Talent
   * @returns Talent
   */
 parseTalentMetadata(talentMetadata: Talent): Talent {

    // locale isn't being used, removing
    talentMetadata.locale = undefined;

    return talentMetadata;
 }

 /**
  * Gets the talent metadata with the given Id and locale
  *
  * @param talentId number
  * @param locale string|Locale
  * @returns Observable<StormServiceResponseSingleInterface>
  */
 getTalentMetadataByLocale(
   talentId: number,
   locale: string | Locale
 ): Observable<StormServiceResponseSingleInterface> {

   return Observable.create(observer => {

     this.talentService.fetchTalentMetadata({
       talentId: talentId,
       locale: locale.toString()
     }).subscribe(
       singleResponse => {
         observer.next(singleResponse.item);
         observer.complete();
       },
       error => {
         observer.next(undefined);
         observer.complete();
       }
     );

   });

  }

  /**
   * Gets the talent with the given Id
   *
   * @param talentId number
   * @returns  Observable<TalentInterface>
   */
  getTalent(talentId: number): Observable<TalentInterface> {

    return Observable.create(observer => {

      this.talentService.fetchTalent({ talentId: talentId }).subscribe(
        singleResponse => {

          this.mapAttributes(singleResponse.item).subscribe(talents => {
            observer.next(talents[0]);
            observer.complete();
          });

        }
      );

    });

  }

  /**
   * Gets the computed talent metadata with the given rootId and locale
   *
   * @param talentMetadata TalentMetadataInterface
   * @returns Observable<TalentMetadataInterface>
   */
  getComputedTalentMetadata(
    talentMetadata: TalentMetadataInterface
  ): Observable<TalentMetadataInterface> {

   return Observable.create(observer => {

     this.talentService.fetchComputedTalentMetadata({
       talentId: talentMetadata.rootId,
       locale: talentMetadata.locale
     }).subscribe(singleResponse => {

       this.mapAttributes([singleResponse.item]).subscribe(
         computedTalentMetadata => {

           const finaleComputedTalentMetadata:
             TalentMetadataInterface = <TalentMetadataInterface>computedTalentMetadata[0];

           // Computed Localization API criteria for lastModified/lastModifiedBy attributes is different that STS one,
           // so we need to keep these attributes from the original (the one that comes from Fetch API)
           finaleComputedTalentMetadata.lastModifiedBy = talentMetadata.lastModifiedBy;
           finaleComputedTalentMetadata.lastModified = talentMetadata.lastModified;

           finaleComputedTalentMetadata.inheritedAttributes = Object.keys(_.pickBy(talentMetadata, _.isUndefined));

           observer.next(finaleComputedTalentMetadata);
           observer.complete();

         }
       );

     });

   });
 }

 /**
  * Sets the entities corresponding to the ids stored in the given talent(s) to get hydrated instance(s)
  *
  * @param metadataCollection TalentMetadataInterface[]
  * @returns Observable<TitleMetadataAttributeInterface>
  */
 mapAttributes(metadataCollection: TalentInterface | TalentInterface[]): Observable<TalentInterface[]> {

   return Observable.create(observer => {

     this.stormListsProvider.getLists().subscribe(lists => {

         if (!_.isArray(metadataCollection)) {
           metadataCollection = [<TalentMetadataInterface>metadataCollection];
         }

         const collection = _.cloneDeep(metadataCollection);

         (<TalentMetadataInterface[]>collection).map(talentMetadata => {

           // keep the original property values as a copy
           talentMetadata.originalData = _.cloneDeep(talentMetadata);

           try {
             talentMetadata.language =
               lists.getList(StormListType.language).getItem(<number>(talentMetadata.language)).value;
           } catch (e) { }

           try {
             talentMetadata.territory = (<[number]>talentMetadata.territory).map(territory => {
               return lists.getList(StormListType.territory).getItem(territory).value;
             });
           } catch (e) { }

           try {
             talentMetadata.productType = (<[number]>talentMetadata.productType).map(productType => {
               return lists.getList(StormListType.productType).getItem(productType).value;
             });
           } catch (e) { }

           try {
             talentMetadata.account = (<[number]>talentMetadata.account).map(account => {
               return lists.getList(StormListType.account).getItem(account).value;
             });
           } catch (e) { }

           try {
             talentMetadata.originalLanguageId =
               lists.getList(StormListType.language).getItem(<number>(talentMetadata.originalLanguageId)).value;
           } catch (e) { }

           try {
             talentMetadata.prefixId =
               lists.getList(StormListType.affix).getItem(<number>(talentMetadata.prefixId)).value;
           } catch (e) { }

           try {
             talentMetadata.suffixId =
               lists.getList(StormListType.affix).getItem(<number>(talentMetadata.suffixId)).value;
           } catch (e) { }

         });

         observer.next(collection);
         observer.complete();

     });

   });

 }

 getLocaleFromLocaleIds(metadata: TalentInterface) {

   return Observable.create(observer => {

     this.mapAttributes([_.cloneDeep(metadata)]).subscribe(

       mappedMetadataCollection => {

         const talentMetadata = mappedMetadataCollection[0];

         const territory = (<Country[]>talentMetadata.territory).map(
           mappedTerritory => {
             if (mappedTerritory.id === 0) {
               return '*';
             }
             return mappedTerritory.iso31661;
           }
         ).join(',');

         const productType = (<ProductType[]>talentMetadata.productType).map(
           mappedProductType => {
             if (mappedProductType.id === 0) {
               return '*';
             }
             return mappedProductType.code;
           }
         ).join(',');

         const account = (<Account[]>talentMetadata.account).map(
           mappedAccount => {
             if (mappedAccount.id === 0) {
               return '*';
             }
             return mappedAccount.code;
           }
         ).join(',');

         const locale = [
           (<Language>talentMetadata.language).localeLanguage,
           territory,
           productType,
           account
         ].join('_');

         observer.next(locale);
         observer.complete();
       }

     );

   });
 }

  /**
   * Sort the Talent Metadata collection with the given sortBy criteria
   *
   * @param talentMetadataCollection TalentMetadataInterface[]
   * @param sortBy \{property: string; reverse: boolean; order: number}[]
   * @returns TalentMetadataInterface[]
   */
 sortTalentMetadata(
   talentMetadataCollection: TalentMetadataInterface[],
   sortBy: Array<{ property: string; reverse: boolean; order: number }> = []
 ): TalentMetadataInterface[] {

   if (!sortBy.length) {

    const firstItemsCriteria = {
       locale: 'en_*_*_*'
     };
     const firstItems = <TalentMetadataInterface[]>_.filter(talentMetadataCollection, firstItemsCriteria);
     const res = <TalentMetadataInterface[]>[...firstItems, ..._.reject(talentMetadataCollection, firstItemsCriteria)];
     return res;
   }

   let _talentMetadataCollection = _(talentMetadataCollection).chain();
   const sorting = _.map(sortBy, _.clone);

   sorting.reverse().forEach((sort: any) => {

     _talentMetadataCollection = _talentMetadataCollection.sortBy((o) => {
       if (_.isArray(o[sort.property])) {
        const all = _(o[sort.property]).filter({ id: 0 });
         return (
           (all.size() ? '0' : '').toString() +
           all.join('_') +
           _(o[sort.property]).reject({ id: 0 }).sortBy('value').join('_')
         ).toUpperCase();
       }
       return (o[sort.property].id === 0 ? '0' : '') + o[sort.property].toString();
     });

     if (sort.reverse) {
       _talentMetadataCollection = _talentMetadataCollection.reverse();
     }

   });

   return _talentMetadataCollection.value();
 }
}
