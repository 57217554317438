import { Injectable } from '@angular/core';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { isString as _isString } from 'lodash';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { TypeEnum as ItemType } from '../../models/type/type.enum';

@Injectable({
  providedIn: 'root',
})
export class RoleCapabilitiesHandler {
  constructor(protected capabilitiesManager: CapabilitiesManager) { }

  /**
   * Indicates if the current user can toggle the given cat list locking status
   *
   * @param locked boolean
   * @returns boolean
   */
  canToggleListLockingStatusOf(locked: boolean): boolean {
    const action: ActionTypeEnum = locked ? ActionTypeEnum.unlock : ActionTypeEnum.lock;
    return this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.cat.path, [action]);
  }

  /**
   * Indicates if the current user can toggle the given localized locking status of the given item type or
   * the item list by default.
   *
   * @param locked boolean
   * @param itemType ItemType
   * @returns boolean
   */
  canToggleLocalizedLockingStatusOf(locked: boolean, itemType?: ItemType): boolean {
    const action: ActionTypeEnum = locked ? ActionTypeEnum.unlock : ActionTypeEnum.lock;

    if (_isString(itemType)) {
      return this.hasPrivilegeOnLocalized(itemType, action);
    } else {
      return this.hasPrivilegeOnLocalizedItemList(action);
    }
  }

  /**
   * Indicates if the current user can toggle the given original locking status of the given item type or
   * the item list by default.
   *
   * @param locked boolean
   * @param itemType ItemType
   * @returns boolean
   */
  canToggleOriginalLockingStatusOf(locked: boolean, itemType?: ItemType): boolean {
    const action: ActionTypeEnum = locked ? ActionTypeEnum.unlock : ActionTypeEnum.lock;

    if (_isString(itemType)) {
      return this.hasPrivilegeOnOriginal(itemType, action);
    } else {
      return this.hasPrivilegeOnOriginalItemList(action);
    }
  }

  /**
   * Indicates if the current user has the given capability on localized section for the given item type.
   *
   * @param itemType ItemType
   * @param capability ActionTypeEnum
   * @returns boolean
   */
  hasPrivilegeOnLocalized(itemType: ItemType, capability: ActionTypeEnum): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(this.retrieveLocalizedPathFor(itemType), [capability]);
  }

  /**
   * Indicates if the current user has the given capability on character original section.
   *
   * @param itemType ItemType
   * @param capability ActionTypeEnum
   * @returns boolean
   */
  hasPrivilegeOnOriginal(itemType: ItemType, capability: ActionTypeEnum): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(this.retrieveOriginalPathFor(itemType), [capability]);
  }

  /**
   * Indicates if the current user has the given capability on the item list localized section.
   *
   * @param capability ActionTypeEnum
   * @returns boolean
   */
  hasPrivilegeOnLocalizedItemList(capability: ActionTypeEnum): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.cat.localized.path, [capability]);
  }

  /**
   * Indicates if the current user has the given capability on the item list original section.
   *
   * @param capability ActionTypeEnum
   * @returns boolean
   */
  hasPrivilegeOnOriginalItemList(capability: ActionTypeEnum): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.cat.original.path, [capability]);
  }

  /**
   * Indicates if the current user has the product association privilege in details page for the given itemType.
   *
   * @param itemType ItemType
   * @param capability ActionTypeEnum
   * @returns boolean
   */
  hasPrivilegeOnProductAssociations(itemType: ItemType, capability: ActionTypeEnum): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(this.retrieveProductAssociationPathFor(itemType), [capability]);
  }

  /**
   * Retrieves the localized path for the given item type.
   *
   * @param itemType ItemType
   * @returns string
   */
  protected retrieveLocalizedPathFor(itemType: ItemType): string {
    switch (itemType) {
      case ItemType.Character:
        return modulesPath.cat.character.localized.path;
      case ItemType.Subproduct:
        return modulesPath.cat.subproduct.localized.path;
      case ItemType.Term:
        return modulesPath.cat.term.localized.path;
      default:
        break;
    }
  }

  /**
   * Retrieves the original path for the given item type.
   *
   * @param itemType ItemType
   * @returns string
   */
  protected retrieveOriginalPathFor(itemType: ItemType): string {
    switch (itemType) {
      case ItemType.Character:
        return modulesPath.cat.character.original.path;
      case ItemType.Subproduct:
        return modulesPath.cat.subproduct.original.path;
      case ItemType.Term:
        return modulesPath.cat.term.original.path;
      default:
        break;
    }
  }

  /**
   * Retrieves the product association path for the given item type.
   *
   * @param itemType ItemType
   * @returns string
   */
  protected retrieveProductAssociationPathFor(itemType: ItemType): string {
    switch (itemType) {
      case ItemType.Character:
        return modulesPath.cat.character.productAssociation.path;
      case ItemType.Subproduct:
        return modulesPath.cat.subproduct.productAssociation.path;
      case ItemType.Term:
        return modulesPath.cat.term.productAssociation.path;
      default:
        break;
    }
  }
}
