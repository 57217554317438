import { Component, Input } from '@angular/core';
import { isObject as _isObject } from 'lodash';

import { Connection } from '../../models/health/connection/connection.model';


@Component({
  selector: 'bolt-cpm-health-connection-details',
  template: require('./bolt-cpm-health-connection-details.html'),
  styles: [require('./bolt-cpm-health-connection-details.scss')]
})
export class BoltCpmHealthConnectionDetailsComponent {
  @Input() connection: Connection;
  @Input() title: string;

  constructor() { }
}
