import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { Health } from '../../models/health/health.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable({
  providedIn: 'root',
})
export class HealthService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetches the Health connection.
   *
   * @returns Observable<Health>
   */
  fetch(): Observable<Health> {
    const url: string = this.generateUrl('cpm.healthService.fetch.endpoint');

    const obs: Observable<Health> = this.doGetRequestAsObservable({ url: url }).pipe(
      map(
        (response: StormServiceResponseSingle) => new Health(response.item)
      )
    );

    return obs;
  }
}
