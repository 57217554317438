import * as moment from 'moment';
import { isArray as _isArray, isNumber as _isNumber, isObject as _isObject, isString as _isString, uniq as _uniq } from 'lodash';
import { Account, Country, Language } from '@bolt/ui-shared/master-data';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LocaleSectionsValidator } from '../../validators/locale-sections/locale-sections.validator';


export class Project {
  protected _accountCode: string;
  protected _description: string;
  protected _id: number;
  protected _languagesCodes: string[];
  protected _lastModifiedDate: moment.Moment;
  protected _lastModifiedUser: string;
  protected _locales: string[];
  protected _name: string;
  protected _territoriesCodes: string[];

  constructor(data: any) {
    if (_isObject(data)) {
      this.setId(data);
      this.setName(data);
      this.setDescription(data);
      this.setLastModifiedDate(data);
      this.setLastModifiedUser(data);
      this.setLocales(data);
    } else {
      throw new ErrorHelper('Invalid data given for a project.');
    }
  }

  get accountCode(): string {
    return this._accountCode;
  }

  get description(): string {
    return this._description;
  }

  get id(): number {
    return this._id;
  }

  get languagesCodes(): string[] {
    return this._languagesCodes;
  }

  get lastModifiedDate(): moment.Moment {
    return this._lastModifiedDate;
  }

  get lastModifiedUser(): string {
    return this._lastModifiedUser;
  }

  get locales(): string[] {
    return this._locales;
  }

  get name(): string {
    return this._name;
  }

  get territoriesCodes(): string[] {
    return this._territoriesCodes;
  }

  /**
   * Discovers the codes in the locales.
   *
   * @throws ErrorHelper
   * @returns void
   */
  protected discoverCodes(): void {
    this._accountCode = undefined;
    this._languagesCodes = undefined;
    this._territoriesCodes = undefined;

    let accountsCode: string[] = new Array();
    let languagesCodes: string[] = new Array();
    let territoriesCodes: string[] = new Array();

    this.locales.forEach(
      (locale: string) => {
        const sections: string[] = locale.split('_');

        LocaleSectionsValidator.validate(sections);

        accountsCode.push(sections[Account.LOCALE_POSITION]);

        if (Language.isAll(sections[Language.LOCALE_POSITION])) {
          territoriesCodes.push(sections[Country.LOCALE_POSITION]);
        } else {
          languagesCodes.push(sections[Language.LOCALE_POSITION]);
        }
      }
    );

    accountsCode = _uniq(accountsCode);
    languagesCodes = _uniq(languagesCodes);
    territoriesCodes = _uniq(territoriesCodes);

    if (accountsCode.length === 1) {
      this._accountCode = accountsCode[0];
    } else {
      throw new ErrorHelper('A project should only have one account at most.');
    }

    if (languagesCodes.length > 0) {
      this._languagesCodes = _uniq(languagesCodes);
    } else {
      throw new ErrorHelper('A project should have at least one language.');
    }

    if (territoriesCodes.length > 0) {
      this._territoriesCodes = _uniq(territoriesCodes);
    } else {
      throw new ErrorHelper('A project should have at least one territory.');
    }
  }

  /**
   * Set the description.
   *
   * @param data any
   * @returns void
   */
  protected setDescription(data: any): void {
    this._description = (_isString(data.description) ? data.description.trim() : undefined);
  }

  /**
   * Set the ID.
   *
   * @param data any
   * @throws ErrorHelper if the given ID is invalid.
   * @returns void
   */
  protected setId(data: any): void {
    if (_isNumber(data.id)) {
      this._id = data.id;
    } else {
      throw new ErrorHelper('Invalid ID given for a project.');
    }
  }

  /**
   * Set the last modified date.
   *
   * @param data any
   * @returns void
   */
  protected setLastModifiedDate(data: any): void {
    if (_isString(data.lastModified)) {
      this._lastModifiedDate = moment(data.lastModified);
    } else {
      throw new ErrorHelper('Invalid last-modified date given for a project.');
    }
  }

  /**
   * Set the last modified user.
   *
   * @param data any
   * @returns void
   */
  protected setLastModifiedUser(data: any): void {
    if (_isString(data.lastModifiedBy)) {
      this._lastModifiedUser = data.lastModifiedBy;
    } else {
      throw new ErrorHelper('Invalid last-modified user given for a project.');
    }
  }

  /**
   * Set the locales.
   *
   * @param data any
   * @throws ErrorHelper if the given locales are invalid.
   * @returns void
   */
  protected setLocales(data: any): void {
    if (_isArray(data.locales) && (data.locales.length > 0)) {
      this._locales = data.locales;
      this.discoverCodes();
    } else {
      throw new ErrorHelper('Invalid locales given for a project.');
    }
  }

  /**
   * Set the name.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setName(data: any): void {
    if (_isString(data.name)) {
      const name: string = data.name.trim();

      if (name.length === 0) {
        throw new ErrorHelper('Empty name given for a project.');
      } else {
        this._name = name;
      }
    } else {
      throw new ErrorHelper('Invalid name given for a project.');
    }
  }
}
