import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from './../common/common.module';
import { CatModule } from '../cat/cat.module';
import { MergeModule } from '../merge/merge.module';

import {
  BoltCharacterLocalizedDataComponent
} from './components/bolt-character-localized-data/bolt-character-localized-data.component';

import {
  BoltCharacterLocalizedDetailsComponent
} from './components/bolt-character-localized-details/bolt-character-localized-details.component';

import {
  BoltCharacterLocalizedListComponent
} from './components/bolt-character-localized-list/bolt-character-localized-list.component';

import {
  BoltCharacterMetadataManagerComponent
} from './components/bolt-character-metadata-manager/bolt-character-metadata-manager.component';

import {
  BoltCharacterOriginalDataComponent
} from './components/bolt-character-original-data/bolt-character-original-data.component';

import {
  BoltPageCharacterDetailsComponent
} from './components/bolt-page-character-details/bolt-page-character-details.component';

import { BoltCharacterListComponent } from './components/bolt-character-list/bolt-character-list.component';
import { BoltCharacterListFiltersComponent } from './components/bolt-character-list-filters/bolt-character-list-filters.component';
import { BoltPageCharacterComponent } from './components/bolt-page-character/bolt-page-character.component';
import { BoltPageCharacterListComponent } from './components/bolt-page-character-list/bolt-page-character-list.component';
import { CharacterMetadataManager } from './helpers/character-metadata-manager/character-metadata-manager';
import { characterModuleRouting } from './character.routing';
import { CharacterService } from './services/character.service';
import { FormFactoryService } from './services/form-factory/form-factory.service';
import { BoltCharacterOriginalMetadataFormComponent } from './components/bolt-character-original-metadata-form/bolt-character-original-metadata-form.component';
import { BoltCharacterLocalizedMetadataFormComponent } from './components/bolt-character-localized-metadata-form/bolt-character-localized-metadata-form.component';

@NgModule({
    imports: [
      CommonModule,
      BoltCommonModule,
      CatModule,
      MergeModule,
      characterModuleRouting
    ],
    providers: [
      CharacterMetadataManager,
      CharacterService,
      FormFactoryService
    ],
    declarations: [
      BoltCharacterLocalizedDataComponent,
      BoltCharacterLocalizedDetailsComponent,
      BoltCharacterLocalizedListComponent,
      BoltCharacterMetadataManagerComponent,
      BoltCharacterOriginalDataComponent,
      BoltPageCharacterDetailsComponent,
      BoltCharacterOriginalMetadataFormComponent,
      BoltCharacterListComponent,
      BoltCharacterLocalizedMetadataFormComponent,
      BoltCharacterListFiltersComponent,
      BoltPageCharacterComponent,
      BoltPageCharacterListComponent
    ],
    exports: [
      BoltCharacterOriginalMetadataFormComponent,
      BoltCharacterLocalizedMetadataFormComponent,
      BoltPageCharacterDetailsComponent,
      BoltCharacterMetadataManagerComponent,
      BoltPageCharacterComponent,
      BoltPageCharacterListComponent
    ]
})
export class CharacterModule { }
