import { AfterViewInit, Component, ElementRef, Input, QueryList, ViewChildren, OnDestroy } from '@angular/core';

import { HeightSynchronizerService } from '../../services/height-synchronizer/height-synchronizer.service';
import { SubtitleDetail } from '../../models/subtitle-detail.model';


@Component({
  selector: 'bolt-subtitle-localized-details',
  template: require('./bolt-subtitle-localized-details.html'),
  styles: [require('./bolt-subtitle-localized-details.scss')]
})
export class BoltSubtitleLocalizedDetailsComponent implements AfterViewInit, OnDestroy {
  @Input() entries: SubtitleDetail[];

  @ViewChildren('heightSynchronizingItem')
  protected heightSynchronizingItemList: QueryList<ElementRef>;

  constructor(
    protected heightSynchronizer: HeightSynchronizerService
  ) { }

  ngAfterViewInit() {
    this.heightSynchronizer.setLocalizedItems(this.heightSynchronizingItemList.toArray());
  }

  ngOnDestroy() {
    this.heightSynchronizer.resetLocalizedItems();
  }
}
