import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { BoltMultiFieldAutocompleteComponent } from '@bolt/ui-shared/form';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import { Subscription } from 'rxjs';

import { Element } from 'app/modules/search/models/element/element.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { SearchCriteria } from 'app/modules/search/models/search-criteria/search-criteria.model';
import { SearchManager } from 'app/shared/models/search-response/search-manager/search-manager';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { SearchService } from 'app/modules/search/services/search.service';
import { TypeEnum as SearchTypeEnum } from 'app/modules/search/models/type/type.enum';


@Component({
  selector: 'bolt-metadata-export-multiple-records-search',
  template: require('./bolt-metadata-export-multiple-records-search.html'),
  styles: [require('./bolt-metadata-export-multiple-records-search.scss')]
})
export class BoltMetadataExportMultipleRecordsSearchComponent implements OnInit, OnDestroy {
  @Output('selected') selectEvent: EventEmitter<Element | undefined>;

  searchManager: SearchManager;
  selection: Element;
  suggestionsLoading: boolean;

  @ViewChild('search', { static: true })
  protected searchInput: BoltMultiFieldAutocompleteComponent;

  protected readonly fixedSearchType: string = 'title';
  protected fetchingElementsListener: Subscription;
  protected scrollLoading: boolean;
  protected suggestionsCriteria: SearchCriteria;

  constructor(
    protected appConfig: AppConfigProvider,
    public layoutHandler: LayoutHandlerService,
    protected notificationService: NotificationService,
    public searchService: SearchService
  ) {
    this.selectEvent = new EventEmitter();
    this.searchManager = new SearchManager();

    this.reset();
    this.setupSuggestionsCriteria();
  }

  ngOnDestroy() {
    this.cancelElementsFetch();
  }

  ngOnInit() {
    this.focus();
  }

  /**
   * Clears it.
   *
   * @returns void
   */
  clear(): void {
    this.reset();
    this.focus();
    this.notifySelection(undefined);
  }

  /**
   * Focus on search input.
   *
   * @returns void
   */
  focus(): void {
    setTimeout(
      () => {
        this.searchInput.focusInput();
      }
    );
  }

  /**
   * Loads the next page for the current query.
   *
   * @returns void
   */
  loadNextSearchingPage(): void {
    if (!this.scrollLoading) {
      this.retrieveSearchSuggestions(this.searchManager.sanitizedQuery, false);
    }
  }

  /**
   * Notifies the selection.
   *
   * @param selection Element
   * @returns void
   */
  notifySelection(selection: Element): void {
    this.selection = selection;
    this.selectEvent.emit(this.selection);
  }

  /**
   * Reset it.
   *
   * @returns void
   */
  reset(): void {
    this.scrollLoading = false;
    this.selection = undefined;
    this.suggestionsLoading = false;

    this.cancelElementsFetch();
  }

  /**
   * Retrieves all suggestions for the element search.
   *
   * @param query string
   * @param shouldResetSearch boolean
   * @returns void
   */
  retrieveSearchSuggestions(query: string, shouldResetSearch: boolean): void {
    this.cancelElementsFetch();

    this.scrollLoading = true;

    if (shouldResetSearch) {
      this.suggestionsLoading = true;
      this.searchManager.sanitizedQuery = query.trim();
    }

    this.suggestionsCriteria.setPageNumber(this.searchManager.currentPage - 1);
    this.suggestionsCriteria.setQuery(this.searchManager.sanitizedQuery);

    this.fetchingElementsListener = this.searchService.fetch(
      this.suggestionsCriteria,
      (response: SearchResponse) => {
        this.searchManager.response = response;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve titles.', error);
      },
      () => {
        this.scrollLoading = false;
        this.suggestionsLoading = false;
      }
    );
  }

  /**
   * Cancel the elements fetch, if it exists a subscription for.
   *
   * @returns void
   */
  protected cancelElementsFetch(): void {
    if (_isObject(this.fetchingElementsListener)) {
      this.fetchingElementsListener.unsubscribe();
    }
  }

  /**
   * Set up the suggestions criteria.
   *
   * @returns void
   */
  protected setupSuggestionsCriteria(): void {
    const types: string[] = [
      `${this.fixedSearchType}:${SearchTypeEnum.Feature}`,
      `${this.fixedSearchType}:${SearchTypeEnum.Season}`
    ];

    this.suggestionsCriteria = new SearchCriteria();
    this.suggestionsCriteria.setPageSize(this.appConfig.get('ux.consolidatedSearch.pageSize'));
    this.suggestionsCriteria.setType(types.join(','));
  }
}
