import { Component } from '@angular/core';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';


@Component({
  selector: 'bolt-app-page',
  template: require('./bolt-app-page.html'),
  styles: [require('./bolt-app-page.scss')]
})
export class BoltAppPageComponent {
  protected readonly allCapability: ActionTypeEnum[] = [ActionTypeEnum.all];
  protected readonly readCapability: ActionTypeEnum[] = [ActionTypeEnum.read];
  protected readonly modulesPath: typeof modulesPath = modulesPath;

  constructor(
    protected capabilitiesManager: CapabilitiesManager
  ) { }

  /**
   * Indicates if it has to display the "Content Product Master" section.
   *
   * @returns boolean
   */
  protected hasDisplayContentProductMaster(): boolean {
    const hasIt: boolean =
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.cpm.configurations.path, this.allCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.cpm.eventsMonitor.path, this.readCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.cpm.healthCheck.path, this.readCapability);

    return hasIt;
  }

  /**
   * Indicates if it has to display "MasterData Management" section.
   *
   * @returns boolean
   */
  protected hasDisplayMasterDataManagement(): boolean {
    const hasIt: boolean =
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.masterData.account.path, this.allCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.masterData.languages.path, this.allCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.masterData.territory.path, this.allCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.masterData.productionCompany.path, this.allCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.masterData.dubbingStudio.path, this.allCapability);

    return hasIt;
  }

  /**
   * Indicates if it has to display the "System Management" section.
   *
   * @returns boolean
   */
  protected hasDisplaySystemManagement(): boolean {
    const hasIt: boolean =
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.users.path, this.readCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.privilege.path, this.allCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.xslt.path, this.readCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.uiConfiguration.path, this.allCapability) ||
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.admin.toggle.path, this.allCapability);

    return hasIt;
  }
}
