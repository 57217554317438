import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { Configuration } from '../../models/configuration/configuration.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { ServerMapping } from '../../models/configuration/server-mapping/server-mapping.model';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';


@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Creates a new configuration with the given data.
   *
   * @param data any
   * @returns Observable<Configuration>
   */
  create(data: any): Observable<Configuration> {
    const request: any = {
      url: this.generateUrl('cpm.configurationService.create.endpoint'),
      body: data
    };

    const obs: Observable<Configuration> = this.doPostRequestAsObservable(request).pipe(
      map(
        (response: StormServiceResponseSingle) => new Configuration(response.item)
      )
    );

    return obs;
  }

  /**
   * Deletes the configuration with the given ID.
   *
   * @param id number
   * @returns Observable<StormServiceResponseSingle>
   */
  delete(id: number): Observable<StormServiceResponseSingle> {
    const url: string = this.generateUrl(
      'cpm.configurationService.delete.endpoint',
      { '{id}': id }
    );

    return this.doDeleteRequestAsObservable({ url: url, checkType: CheckTypeEnum.null });
  }

  /**
   * Fetches the configurations list.
   *
   * @returns Observable<Configuration[]>
   */
  fetch(): Observable<Configuration[]> {
    const url: string = this.generateUrl('cpm.configurationService.fetch.endpoint');

    const obs: Observable<Configuration[]> = this.doGetRequestAsObservable({ url: url, checkType: CheckTypeEnum.array }).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          const configurations: Configuration[] = response.item.map(
            (rawData: any) => new Configuration(rawData)
          );

          return configurations;
        }
      )
    );

    return obs;
  }

  /**
   * Fetches the server mapping configuration.
   *
   * @returns Observable<ServerMapping>
   */
  fetchMapping(): Observable<ServerMapping> {
    const url: string = this.generateUrl('cpm.configurationService.fetchMapping.endpoint');

    const obs: Observable<ServerMapping> = this.doGetRequestAsObservable({ url: url }).pipe(
      map(
        (response: StormServiceResponseSingle) => new ServerMapping(response.item)
      )
    );

    return obs;
  }

  /**
   * Updates the configuration with the given ID, using the given data.
   *
   * @param id number
   * @param data any
   * @returns Observable<Configuration>
   */
  update(id: number, data: any): Observable<Configuration> {
    const url: string = this.generateUrl(
      'cpm.configurationService.update.endpoint',
      { '{id}': id }
    );

    const request: any = {
      url: url,
      body: data
    };

    const obs: Observable<Configuration> = this.doPutRequestAsObservable(request).pipe(
      map(
        (response: StormServiceResponseSingle) => new Configuration(response.item)
      )
    );

    return obs;
  }
}
