import { isNumber as _isNumber, isObject as _isObject, isString as _isString } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Type } from './type/type.model';
import { TypeEnum } from './type/type.enum';


export class Configuration {
  protected code: string;
  protected description: string;
  protected id: number;
  protected type: Type;
  protected value: string;

  constructor(data: any) {
    if (_isObject(data)) {
      this.setCode((data as any).code);
      this.setDescription((data as any).description);
      this.setId((data as any).id);
      this.setType((data as any).type);
      this.setValue((data as any).value);
    } else {
      throw new ErrorHelper('Invalid data given for a CPM configuration.');
    }
  }

  /**
   * Returns the code.
   *
   * @returns number
   */
  getCode(): string {
    return this.code;
  }

  /**
   * Returns the description.
   *
   * @returns string
   */
  getDescription(): string {
    return this.description;
  }

  /**
   * Returns the ID.
   *
   * @returns number
   */
  getId(): number {
    return this.id;
  }

  /**
   * Returns the type.
   *
   * @returns Type
   */
  getType(): Type {
    return this.type;
  }

  /**
   * Returns the value.
   *
   * @returns string
   */
  getValue(): string {
    return this.value;
  }

  /**
   * Set the code.
   *
   * @param code string
   * @returns void
   */
  protected setCode(code: string): void {
    if (_isString(code)) {
      this.code = code.trim();
    } else {
      throw new ErrorHelper(`Invalid code given for a CPM Configuration: ${code}`);
    }
  }

  /**
   * Set the description.
   *
   * @param description string
   * @returns void
   */
  protected setDescription(description: string = ''): void {
    this.description = description.trim();
  }

  /**
   * Set the ID.
   *
   * @param id number
   * @returns void
   */
  protected setId(id: number): void {
    if (_isNumber(id)) {
      this.id = id;
    } else {
      throw new ErrorHelper(`Invalid ID given for a CPM Configuration: ${id}`);
    }
  }

  /**
   * Set the type.
   *
   * @param type TypeEnum
   * @returns void
   */
  protected setType(type: TypeEnum): void {
    this.type = new Type(type);
  }

  /**
   * Set the value.
   *
   * @param value string
   * @returns void
   */
  protected setValue(value: string): void {
    this.value = value.trim();
  }
}
