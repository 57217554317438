import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { ActionTypeEnum, AppConfigProvider, ConfigurationTypeEnum } from '@bolt/ui-shared/configuration';
import { Country } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject } from 'lodash';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { CreditLocale } from '../../models/credit/credit-locale.model';
import { CollectionManagerCollectionInterface, CollectionManagerHelper } from 'app/modules/common/helpers/collection-manager.helper';
import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FeatureMetadata } from 'app/modules/title/models/feature-metadata.model';
import { HotConfigurationTypeEnum } from 'app/modules/clone/models/hot-configuration-type.enum';
import { HotConfiguration } from 'app/modules/clone/models/hot-configuration.model';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { ProductLayoutHelper } from 'app/modules/title/helpers/product-layout/product-layout.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Title } from 'app/modules/title/models/title.model';
import { TitleService } from 'app/modules/title/services/title.service';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { CreditLockingStatusInterface } from 'app/modules/credits/models/credit/credit-locking-status.interface';
import { CreditsAggregateService } from 'app/modules/clone/services/credits-aggregate/credits-aggregate.service';
import { CreditsService } from '../../services/credits.service';


@Component({
  selector: 'bolt-credits-localization-list',
  template: require('./bolt-credits-localization-list.html'),
  styles: [require('./bolt-credits-localization-list.scss')]
})
export class BoltCreditsLocalizationListComponent extends StormComponent implements OnChanges, OnInit, OnDestroy {
  @Input() protected title: Title;
  @Input() protected creditPositionType: CreditPositionType;
  @Input() protected creditType: CreditType;
  @Input() protected creditLocaleUpdated: CreditLocale[];
  @Input() protected isOriginalCreditLocked: boolean;
  @Input() protected refresh: boolean = false;

  @Output('selected') protected selectEvent: EventEmitter<CreditLocale>;
  @Output('onBulkLockingStatusUpdate') protected onBulkLockingStatusUpdate: EventEmitter<CreditLockingStatusInterface[]> =
    new EventEmitter<CreditLockingStatusInterface[]>();

  protected boltStore: any = { };
  protected bulkLockSelectionCounter = 0;
  protected bulkLockChecks:  Map<string, boolean> = new Map();
  protected collectionManagerSubscription: Subscription;
  protected confirmBulkLockUpdateModal: NgbModalRef;
  protected creditsCollection: CollectionManagerCollectionInterface;
  protected creditLocaleForm: FormGroup;
  protected filterForm: FormGroup;
  protected isBulkLockMainCheckDisabled: boolean = true;
  protected isBulkLockMainCheckSelected: boolean = false;
  protected localesLockingStatusSubscription: Subscription;
  protected localesSubscription: Subscription;
  protected lockReasons: string[];
  protected selectedCredit: CreditLocale;
  protected selectedReason: string;
  protected translatedLockingStatus: Map<string, any>;

  constructor(
    protected appConfig: AppConfigProvider,
    protected capabilitiesManager: CapabilitiesManager,
    protected creditsAggregateService: CreditsAggregateService,
    protected collectionManager: CollectionManagerHelper,
    protected formBuilder: FormBuilder,
    protected listProvider: ListLayoutProvider,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected titleService: TitleService,
    protected creditsService: CreditsService
  ) {
    super();
    this.initialize();
  }

  ngOnDestroy() {
    this.collectionManagerSubscription.unsubscribe();
    this.cancelSubscriptions();
  }

  ngOnInit() {
    if (_isObject(this.title)) {
      this.fetchLocales();
    }

    this.setupBoltStore();
    this.setupFilter();
    this.setupForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    const refreshChanged: boolean =
      _isObject(changes.refresh) &&
      !changes.refresh.isFirstChange() &&
      changes.refresh.currentValue;

    const titleChanged: boolean =
      _isObject(changes.title) &&
      !changes.title.isFirstChange() &&
      changes.title.currentValue.id !== changes.title.previousValue.id;

    const creditTypeChanged: boolean =
      _isObject(changes.creditType) &&
      !changes.creditType.isFirstChange() &&
      changes.creditType.previousValue !== changes.creditType.currentValue;

    const creditPositionTypeChanged: boolean =
      _isObject(changes.creditPositionType) &&
      !changes.creditPositionType.isFirstChange() &&
      changes.creditPositionType.previousValue !== changes.creditPositionType.currentValue;

    if (titleChanged || creditTypeChanged || creditPositionTypeChanged) {
      this.cancelSubscriptions();
      this.fetchLocales();
    }

    if (refreshChanged) {
      this.refreshLockingStatus();
    }

    if (
      changes.creditLocaleUpdated &&
      _isObject(changes.creditLocaleUpdated.currentValue)
    ) {
      this.updateCreditLocale();
    }
  }

  /**
   * Adds the credit locale of the form in the currents credit locales.
   *
   * @returns void
   */
  protected addCreditLocale(): void {
    const languageId: number = this.creditLocaleForm.get('language').value;
    const territoryId: number = this.creditLocaleForm.get('territory').value;

    if (this.isAlreadyInCreditCollection(languageId, territoryId)) {
      this.notificationService.handleError('The locale is already in the list.');
    } else {
      const data: any = {
        language: this.listProvider.getLanguageById(languageId),
        territory: [this.listProvider.getTerritoryById(territoryId)],
      };

      const newCreditLocale: CreditLocale = new CreditLocale(data);

      this.collectionManager.addItemsToCollection('credits', [newCreditLocale]);
      this.creditLocaleForm.reset();
    }
  }

  /**
   * Bulk locks the checked localizations.
   *
   * @returns void
   */
  protected bulkLock(): void {
    this.confirmBulkLockUpdateModal.close();
    const creditsToLock: CreditLocale[] = this.creditsCollection.rawCollection.filter(
      (credit: CreditLocale) => this.bulkLockChecks.get(credit.getLocale()) === true
    );

    const apiCalls: Array<Observable<CreditLockingStatusInterface>> = new Array();

    creditsToLock.forEach((credit: CreditLocale) => {
      const params: any = {
        titleType: this.title.type,
        titleId: this.title.id,
        locale: credit.getLocale(),
        creditType: this.creditType,
        creditPositionType: this.creditPositionType,
        locked: true,
        lockReason: this.selectedReason
      };
      const toggleCall: Observable<CreditLockingStatusInterface> = this.creditsService.toggleLockingStatus(params);
      apiCalls.push(toggleCall);
    });

    forkJoin(apiCalls).subscribe(
      (responses: CreditLockingStatusInterface[]) => {
        this.onBulkLockingStatusUpdate.emit(responses);
        this.bulkLockSelectionCounter = 0;
        responses.forEach((creditLocked) => {
          this.bulkLockChecks.delete(creditLocked.locale);
        });

        this.notificationService.handleSuccess(`${this.creditType} ${this.creditPositionType} locked`);
      },
      (error) => {
        const errorMessage = 'There was an error trying to bulk lock the localizations.';
        this.notificationService.handleError(errorMessage);
      }
    );
  }

  /**
   * Cancel the current subscriptions.
   *
   * @returns void
   */
  protected cancelSubscriptions(): void {
    this.localesSubscription.unsubscribe();
    this.localesLockingStatusSubscription.unsubscribe();
  }

  /**
   * Shows confirmation modal for bulk locking localizations
   *
   * @param content Reference to the modal
   * @returns void
   */
  protected openBulkLockConfirmModal(content: any): void {
    this.selectedReason = '';
    this.fetchLockReasons();
    this.confirmBulkLockUpdateModal = this.modalService.open(content);
  }

  /**
   * Fetch the list of reasons to lock the localization
   *
   * @returns void
   */
  protected fetchLockReasons(): void {
    this.creditsAggregateService.fetchHotConfiguration(
      HotConfigurationTypeEnum.LOCK_REASON,
      (hotConfig: HotConfiguration) => {
        this.lockReasons = hotConfig.value.split(',');
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Error fetching the lock reasons.', error);
      }
    );
  }

  /**
   * Fetches the locales.
   *
   * @returns void
   */
  protected fetchLocales(): void {
    if (this.creditType === CreditType.TRANSLATED) {
      this.fetchTitleLocales();
    } else {
      this.fetchCreditLocales();
    }
  }

  /**
   * Fetches the credit locales for the current title.
   *
   * @returns void
   */
  protected fetchCreditLocales(): void {
    this.changeStatusToFetchingData();

    this.localesSubscription = this.creditsService.fetchLocales(
      this.title.type,
      this.title.id,
      this.creditType,
      this.creditPositionType
    ).subscribe(
      (response: CreditLocale[]) => {
        this.collectionManager.setCollectionItems('credits', response);
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to fetch the credit locales for the current title.', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Fetches the locking statuses of the given credits.
   *
   * @param credits CreditLocale[]
   * @returns void
   */
  protected fetchCreditLockingStatuses(credits: CreditLocale[]): void {
    this.isBulkLockMainCheckDisabled = true;
    this.translatedLockingStatus = new Map();
    const observables: Array<Observable<CreditLockingStatusInterface>> = new Array();

    credits.forEach(
      (credit: CreditLocale) => {
        const observable: Observable<CreditLockingStatusInterface> = this.creditsService.fetchLockingStatus(
          this.title.type,
          this.title.id,
          this.creditType,
          this.creditPositionType,
          credit.getLocale()
        );

        observable.subscribe(
          (lockingStatus: CreditLockingStatusInterface) => {
            credit.setLockingStatus(new LockingStatus(lockingStatus));
            this.translatedLockingStatus.set(credit.getLocale(), { status: DataStatusEnum.dataFound });
          },
          (error: ErrorHelper) => {
            this.translatedLockingStatus.set(
              credit.getLocale(),
              { status: DataStatusEnum.error, error: error.message }
            );
          }
        );

        observables.push(observable);
      }
    );

    this.localesLockingStatusSubscription = forkJoin(observables).subscribe(
      (response) => {
        if (this.isOriginalCreditLocked) {
          this.creditsCollection.rawCollection.forEach((credit: CreditLocale) => {
            const locale = credit.getLocale();
            const lockingStatus = credit.getLockingStatus();
            if (!lockingStatus.locked && !this.bulkLockChecks.has(locale)) {
              this.bulkLockChecks.set(locale, false);
            }
          });
          this.isBulkLockMainCheckDisabled = this.bulkLockChecks.size === 0;
        } else {
          this.bulkLockChecks.clear();
          this.isBulkLockMainCheckSelected = false;
        }
      }
    );
  }

  /**
   * Fetches the title locales.
   *
   * @returns void
   */
  protected fetchTitleLocales(): void {
    this.changeStatusToFetchingData();

    this.localesSubscription = this.titleService.fetchProduct({
      productType: this.title.type,
      productId: this.title.id
    }).subscribe(
      serviceResponse => {
        const credits: CreditLocale[] = this.mapTitleLocales(
          (<FeatureMetadata[]>serviceResponse.item.localizations).filter(
            (localization: FeatureMetadata) => localization.localeObject.type.isLanguage()
          )
        );

        this.collectionManager.setCollectionItems('credits', credits);
        this.changeStatusToDataFound();
        this.localesLockingStatusSubscription.unsubscribe();
        this.fetchCreditLockingStatuses(credits);
      }
    );
  }

  /**
   * Get the languages options.
   *
   * @returns SelectionItem[]
   */
  protected getLanguageOptions(): SelectionItem[] {
    return this.listProvider.getLanguages(true);
  }

  /**
   * Get the territories options.
   *
   * @returns SelectionItem[]
   */
  protected getTerritoryOptions(): SelectionItem[] {
    return this.listProvider.getTerritories();
  }

  /**
   * Indicates if it has to block the add localization button.
   *
   * @returns boolean
   */
  protected hasBlockAddButton(): boolean {
    const hasIt: boolean = this.isDataFound() && this.creditLocaleForm.invalid;
    return hasIt;
  }

  /**
   * Indicates if the current user has bulk lock privilege.
   *
   * @returns boolean
   */
  protected hasBulkLockPrivilege(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.credits.localized.path, [ActionTypeEnum.delete]);
  }

  /**
   * Indicates if it should display the bulk lock button and checkboxes
   * @returns boolean
   */
  protected hasDisplayBulkLock(): boolean {
    const hasIt: boolean =
      this.creditType === CreditType.TRANSLATED &&
      this.isOriginalCreditLocked &&
      this.hasBulkLockPrivilege();
    return hasIt;
   }

  /**
   * Indicates if it has to display the credit locale form.
   *
   * @returns boolean
   */
  protected hasDisplayCreditLocaleForm(): boolean {
    const hasIt: boolean = this.creditType === CreditType.DUBBING && this.hasWritePrivilege();
    return hasIt;
  }

  /**
   * Indicates if it has to display the localization list.
   *
   * @returns boolean
   */
  protected hasDisplayData(): boolean {
    const hasIt: boolean =
      this.isDataFound() && _isObject(this.creditsCollection) && this.creditsCollection.collection.length > 0;

    return hasIt;
  }

  /**
   * Indicates if it has to display the locking status error for the given credit.
   *
   * @param credit CreditLocale
   * @returns boolean
   */
  protected hasDisplayLockingStatusErrorFor(credit: CreditLocale): boolean {
    const hasIt: boolean =
      this.creditType === CreditType.TRANSLATED &&
      this.translatedLockingStatus.has(credit.getLocale()) &&
      this.translatedLockingStatus.get(credit.getLocale()).status === DataStatusEnum.error;

    return hasIt;
  }

  /**
   * Indicates if it has to display the locking status for the given credit.
   *
   * @param credit CreditLocale
   * @returns boolean
   */
  protected hasDisplayLockingStatusFor(credit: CreditLocale): boolean {
    const hasIt: boolean =
      this.creditType === CreditType.DUBBING ||
      (
        this.translatedLockingStatus.has(credit.getLocale()) &&
        this.translatedLockingStatus.get(credit.getLocale()).status === DataStatusEnum.dataFound
      );

    return hasIt;
  }

  /**
   * Indicates if it has to display the locking status spinner for the given credit.
   *
   * @param credit CreditLocale
   * @returns boolean
   */
  protected hasDisplaySpinnerFor(credit: CreditLocale): boolean {
    const hasIt: boolean =
      this.creditType === CreditType.TRANSLATED &&
      !this.translatedLockingStatus.has(credit.getLocale());

    return hasIt;
  }

  /**
   * Indicates if it has to display the no localization message.
   *
   * @returns boolean
   */
  protected hasDisplayNoData(): boolean {
    const hasIt: boolean =
      this.isDataFound() &&
      _isObject(this.creditsCollection) &&
      this.creditsCollection.collection.length === 0;

    return hasIt;
  }

  /**
   * Indicates if the current user has write privilege.
   *
   * @returns boolean
   */
  protected hasWritePrivilege(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.credits.path, [ActionTypeEnum.write]);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.collectionManagerSubscription = new Subscription();
    this.localesLockingStatusSubscription = new Subscription();
    this.localesSubscription = new Subscription();
    this.selectEvent = new EventEmitter();
    this.translatedLockingStatus = new Map();

    this.setupCollectionManager();
  }

  /**
   * Indicates if any bulk lock checkbox is selected.
   *
   * @returns boolean
   */
  protected isAnyBulkLockCheckSelected(): boolean {
    const isIt: boolean =
      this.bulkLockSelectionCounter !== 0;

    return isIt;
  }

  /**
   * Indicates if the current values already exists in the current credit collection.
   *
   * @param languageId number
   * @param territoryId number
   * @returns boolean
   */
  protected isAlreadyInCreditCollection(languageId: number, territoryId: number): boolean {
    const isIt: boolean = this.creditsCollection.rawCollection.some(
      (item: CreditLocale) => {
        return (
          languageId === item.getLanguage().id &&
          territoryId === item.getTerritory()[0].id
        );
      }
    );

    return isIt;
  }

  /**
   * Indicates if the given credit locale is the current selection.
   *
   * @param credit CreditLocale
   * @returns boolean
   */
  protected isSelectedCredit(credit: CreditLocale): boolean {
    const isIt: boolean =
      _isObject(this.selectedCredit) &&
      credit.getLanguage().id === this.selectedCredit.getLanguage().id &&
      credit.isTerritoryEqualsTo(this.selectedCredit.getTerritory());

    return isIt;
  }

  /**
   * Toggles individual checkboxes
   * @param event Event
   * @param credit CreditLocale
   * @returns void
   */
  protected toggleBulkLockCheck(event: Event, credit: CreditLocale): void {
    event.stopPropagation();
    const locale = credit.getLocale();
    const currentValue: boolean = !this.bulkLockChecks.get(locale);

    this.bulkLockChecks.set(locale, currentValue);

    if (currentValue) {
      this.isBulkLockMainCheckSelected = Array.from(this.bulkLockChecks.values()).every((isChecked: boolean) => isChecked);
      this.bulkLockSelectionCounter++;
    } else {
      this.isBulkLockMainCheckSelected = false;
      this.bulkLockSelectionCounter--;
    }
  }

  /**
   * Maps the given localizations.
   *
   * @param localizations any[]
   * @returns CreditLocale[]
   */
  protected mapTitleLocales(localizations: any[]): CreditLocale[] {
    const titleCreditLocales: CreditLocale[] = new Array();

    localizations.forEach(
      (localization: any) => {
        const territories: Country[] = new Array();

        localization.territory.forEach(
          (id: number) => {
            territories.push(this.listProvider.getTerritoryById(id));
          }
        );

        const data: any = {
          language: this.listProvider.getLanguageById(localization.language),
          territory: territories,
          locale: localization.locale
        };

        const alreadyAdded: boolean = titleCreditLocales.some(
          (credit: CreditLocale) =>
            credit.getLanguage().id === data.language.id &&
            credit.isTerritoryEqualsTo(data.territory)
        );

        if (!alreadyAdded) {
          titleCreditLocales.push(new CreditLocale(data));
        }
      }
    );

    return titleCreditLocales;
  }

  /**
   * Refresh the locking status of the current list.
   *
   * @returns void
   */
  protected refreshLockingStatus(): void {
    const credits: CreditLocale[] = this.collectionManager.getCollection('credits').rawCollection;
    this.fetchCreditLockingStatuses(credits);
    this.bulkLockSelectionCounter = 0;
  }

  /**
   * Resets the filter input.
   *
   * @returns void
   */
  protected resetFilter(): void {
    this.filterForm.get('query').setValue('');
  }

  /**
   * Retrieves the error reason for the given credit.
   *
   * @param credit CreditLocale
   * @returns string
   */
  protected retrieveErrorReasonFor(credit: CreditLocale): string {
    let errorReason: string;

    if (this.translatedLockingStatus.has(credit.getLocale())) {
      errorReason = this.translatedLockingStatus.get(credit.getLocale()).error;
    }

    return errorReason;
  }

  /**
   * Emits the select event with the selected credit.
   *
   * @param selected CreditLocale
   * @returns void
   */
  protected selectCredit(selected: CreditLocale): void {
    this.selectedCredit = selected;
    this.selectEvent.emit(selected);
  }

  /**
   * Set up the BoltStore for form default values.
   *
   * @return void
   */
  protected setupBoltStore(): void {
    this.boltStore = {
      formDefaultValues: {
        territory: 0
      }
    };
  }

  /**
   * Set up the collection manager in order to be used with credit locales.
   *
   * @returns void
   */
  protected setupCollectionManager(): void {
    this.collectionManager.setCollections([
      {
        name: 'credits',
        collection: [],
        sorting: [],
        paginate: true,
        pagination: {
          page_size: this.appConfig.get('ux.dataTables.pageSize', 20, ConfigurationTypeEnum.number)
        },
      }
    ]);

    this.collectionManagerSubscription = this.collectionManager.getCollectionHandler().subscribe(
      (collection: CollectionManagerCollectionInterface) => {
        if (collection.name === 'credits') {
          this.creditsCollection = collection;
        }
      }
    );
  }

  /**
   * Set up the filter form.
   *
   * @returns void.
   */
  protected setupFilter(): void {
    this.filterForm = this.formBuilder.group({
      query: [undefined]
    });

    this.filterForm.valueChanges.pipe(debounceTime(200)).subscribe(
      (value: any) => {
        const words = value.query ? value.query.trim().split(' ') : [];

        this.collectionManager.filterBy('credits', words, ['language', 'territory']);
      }
    );
  }

  /**
   * Set up the form.
   *
   * @returns void
   */
  protected setupForm(): void {
    this.creditLocaleForm = this.formBuilder.group({
      language: [
        undefined,
        Validators.compose([
          Validators.required
        ])
      ],
      territory: [
        this.boltStore.formDefaultValues.territory,
        Validators.compose([
          Validators.required
        ])
      ],
    });
  }

  /**
   * Toggles the main check for bulk locking.
   *
   * @returns void
   */
  protected toggleBulkLockMainCheck(): void {
    this.isBulkLockMainCheckSelected = !this.isBulkLockMainCheckSelected;
    this.bulkLockSelectionCounter = this.isBulkLockMainCheckSelected ? this.bulkLockChecks.size : 0;

    this.bulkLockChecks.forEach(
      (value: boolean, key: string) => {
        this.bulkLockChecks.set(key, this.isBulkLockMainCheckSelected);
      }
    );
  }

  /**
   * Updates the current credit value in the current collection.
   *
   * @returns void
   */
  protected updateCreditLocale(): void {
    this.isBulkLockMainCheckSelected = false;
    this.bulkLockChecks.forEach((_, key) => {
      this.bulkLockChecks.set(key, false);
    });
    this.bulkLockSelectionCounter = 0;

    this.creditLocaleUpdated.forEach((creditLocale) => {
      const foundLocale: CreditLocale = this.creditsCollection.rawCollection.find(
        (item: CreditLocale) => {
          return creditLocale.getLanguage().id  === item.getLanguage().id &&
          item.isTerritoryEqualsTo(creditLocale.getTerritory());
        }
      );

      if (foundLocale) {
        foundLocale.setLockingStatus(creditLocale.getLockingStatus());
      }

      if (creditLocale.getLockingStatus().locked) {
        this.bulkLockChecks.delete(creditLocale.getLocale());
      } else {
        this.bulkLockChecks.set(creditLocale.getLocale(), false);
      }
    });

    this.isBulkLockMainCheckDisabled = this.bulkLockChecks.size === 0;
    this.collectionManager.setCollectionItems('credits', this.creditsCollection.rawCollection);
  }
}
