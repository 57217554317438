import { Component, OnInit } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { MasterDataCacheService, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { BoltBaseMasterDataComponent } from '../../baseMasterData/main-page/baseMasterData.component';

@Component({
  selector: 'bolt-master-data-production-companies',
  template: require('./bolt-master-data-production-companies.html'),
  styles: [ require('../../baseMasterData/main-page/baseMasterData.scss') ]
})
export class BoltMasterDataProductionCompaniesComponent extends BoltBaseMasterDataComponent implements OnInit {
  constructor(
    protected appConfig: AppConfigProvider,
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected cacheService: MasterDataCacheService
  ) {
    super(appConfig, masterDataService, notificationService, cacheService);
    this.hasOpenHandlerPanel = false;
    this.searchedWords = '';
    this.type = MasterDataType.productionCompany;
    this.setupPager();
  }

  ngOnInit(): void {
    this.fetch();
  }
}
