import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { isString as _isString, isUndefined as _isUndefined } from 'lodash';

import { AttributeEnum as LockAttributeEnum } from '../../models/entity/lock/attribute.enum';
import { BoltProjectAbstractCommonComponent } from '../bolt-project-abstract-common.component';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { Entity } from '../../models/entity/entity.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Lock } from '../../models/entity/lock/lock.model';
import { LocksRecoverService } from '../../services/entity/locks-recover/locks-recover.service';
import { ManagerService } from '../../services/project/manager/manager.service';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { ProjectService } from '../../services/project/project.service';
import { ScrollingHandlerService } from '../../services/scrolling-handler/scrolling-handler.service';
import { Status } from '../../models/entity/lock/status/status.model';
import { UrlHandlerService } from '../../services/url-handler/url-handler.service';


@Component({
  selector: 'bolt-project-details',
  template: require('./bolt-project-details.html'),
  styles: [require('./bolt-project-details.scss')]
})
export class BoltProjectDetailsComponent extends BoltProjectAbstractCommonComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('confirmProjectDeletionModal')
  protected confirmProjectDeletionModal: ModalDirective;

  // Filters related.
  protected lockStatusFilters: Map<string, [SelectionItem[], LockAttributeEnum, string?]>;

  // Locks related.
  protected lockingErrors: Map<string, ErrorHelper>;
  protected locksRecoverChangesListener: Subscription;

  protected hasDisplayIconsLegend: boolean;
  protected isDescriptionExpanded: boolean;
  protected isMetadataPopupOpen: boolean;
  protected metadataPopupParams: Array<[string, Lock, LockAttributeEnum]>;

  constructor(
    protected layoutHandler: LayoutHandlerService,
    protected locksRecover: LocksRecoverService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected projectManager: ManagerService,
    protected projectService: ProjectService,
    protected roleCapabilities: CapabilitiesManager,
    protected scrollingHandler: ScrollingHandlerService,
    protected urlHandler: UrlHandlerService
  ) {
    super(layoutHandler, notificationService, projectManager, projectService, scrollingHandler, urlHandler);

    this.hasDisplayIconsLegend = false;
    this.lockingErrors = new Map();
    this.locksRecoverChangesListener = new Subscription();
    this.lockStatusFilters = new Map();

    this.listenDataFoundInLocksRecover();
    this.listenErrorsInLocksRecover();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    this.locksRecoverChangesListener.unsubscribe();
    this.locksRecover.reset();
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  protected applyFilters(): void {
    super.applyFilters();

    this.secondEntities.forEach(
      (entry: any, parentMapKey: string) => {
        if (entry.isExpanded) {
          this.updateSecondEntitiesOrderFor(parentMapKey);
        }
      }
    );
  }

  /**
   * Starts the new project process by coping from the select project.
   *
   * @returns void
   */
  protected clone(): void {
    this.projectManager.copyDefaultsFromCurrentProject();
    this.urlHandler.clearUrl();
    this.forceCreatingStatus();
  }

  /**
   * Closes the metadata popup.
   *
   * @returns void.
   */
  protected closeMetadataPopup(): void {
    this.metadataPopupParams = undefined;
    this.turnOffIsMetadataPopupOpen();
  }

  /** Deletes the current project.
   *
   * @returns void
   */
  protected deleteCurrentProject(): void {
    this.locksRecover.reset();
    this.changeStatusToFetchingData();
    this.layoutHandler.changeToDeleting();

    this.projectManager.deleteCurrentProject(
      () => {
        this.urlHandler.clearUrl();
        this.layoutHandler.changeToReading();
        this.notificationService.handleNotice('Project deleted successfully.', undefined, notificationsContainer.projectDashboard.key);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to delete the project.', error, notificationsContainer.projectDashboard.key);

        if (error.isInvalid()) {
          this.changeStatusToError();
        } else {
          this.layoutHandler.changeToReading();
          this.changeStatusToDataFound();
        }
      }
    );
  }

  protected doAfterFetchEntities(): void {
    if (this.hasFirstEntities()) {
      const mapKeys: string[] = this.firstEntitiesOrder.map(
        (entry: [string, string]) => entry[1]
      );

      this.locksRecover.fetch(mapKeys);
    }
  }

  protected doAfterCurrentProjectChange(): void {
    this.ensureStartScrolling();
    this.fetchEntities();
  }

  protected doBeforeFetchEntities(): void {
    this.lockingErrors.clear();
    this.locksRecover.reset();
  }

  protected doesEntityPassFilters(entity: Entity): boolean {
    let passed: boolean = super.doesEntityPassFilters(entity);

    if (passed) {
      // We check here the locks filters, due to it is only in the details view.
      const iterator: IterableIterator<[SelectionItem[], LockAttributeEnum, string?]> = this.lockStatusFilters.values();
      let result: IteratorResult<[SelectionItem[], LockAttributeEnum, string?], any> = iterator.next();

      while (!result.done && passed) {
        const attribute: LockAttributeEnum = result.value[1];
        const code: string = result.value[2];
        const items: SelectionItem[] = result.value[0];
        const lock: Lock = entity.getLockFor(attribute, code);

        if (_isUndefined(lock)) {
          passed = true;
        } else {
          const status: Status = lock.status.get(attribute);
          passed = items.some((item: SelectionItem) => item.source.isEqualsTo(status));
        }

        result = iterator.next();
      }
    }

    return passed;
  }

  /**
   * Edits the project.
   *
   * @returns void
   */
  protected edit(): void {
    this.projectManager.cleanDefaults();
    this.layoutHandler.changeToUpdating();
  }

  /**
   * Filters by lock status using the given selection, attribute and code.
   *
   * @param selection SelectionItem[]
   * @param attribute LockAttributeEnum
   * @param code string
   * @returns void
   */
  protected filterByLockStatus(selection: SelectionItem[], attribute: LockAttributeEnum, code?: string): void {
    const key: string = this.retrieveLockKeyFor(attribute, code);

    this.lockStatusFilters.set(key, [selection, attribute, code]);

    this.applyFilters();
    this.discoverIfShouldResetFilters();
  }

  /**
   * Changes the layout handler status to creating.
   *
   * @returns void
   */
  protected forceCreatingStatus(): void {
    // Needed hack for refreshing the action.
    setTimeout(
      () => {
        this.layoutHandler.changeToCreating();
      }
    );
  }

  /**
   * Indicates if it has to block the clone button.
   *
   * @returns boolean
   */
  protected hasBlockClone(): boolean {
    return !this.isDataFound();
  }

  /**
   * Indicates if it has to block the delete button.
   *
   * @returns boolean
   */
  protected hasBlockDelete(): boolean {
    return !this.isDataFound();
  }

  /**
   * Indicates if it has to block the edit button.
   *
   * @returns boolean
   */
  protected hasBlockEdit(): boolean {
    return !this.isDataFound();
  }

  /**
   * Indicates if it has to block all the lock status filters.
   *
   * @returns boolean
   */
  protected hasBlockLockStatusFilters(): boolean {
    const hasIt: boolean = (!this.hasFirstEntities() || this.locksRecover.isFetching());
    return hasIt;
  }

  /**
   * Indicates if it has to block the refresh button.
   *
   * @returns boolean
   */
  protected hasBlockRefresh(): boolean {
    return this.isFetchingData();
  }

  /**
   * Indicates if it has to display the clone button.
   *
   * @returns boolean
   */
  protected hasDisplayClone(): boolean {
    const hasIt: boolean = this.roleCapabilities.hasUserPrivilegeOn(
      modulesPath.projectDashboard.path,
      [ ActionTypeEnum.write ]
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the delete button.
   *
   * @returns boolean
   */
  protected hasDisplayDelete(): boolean {
    const hasIt: boolean = this.roleCapabilities.hasUserPrivilegeOn(
      modulesPath.projectDashboard.path,
      [ ActionTypeEnum.delete ]
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the edit button.
   *
   * @returns boolean
   */
  protected hasDisplayEdit(): boolean {
    const hasIt: boolean = this.roleCapabilities.hasUserPrivilegeOn(
      modulesPath.projectDashboard.path,
      [ ActionTypeEnum.write ]
    );

    return hasIt;
  }

  /**
   * Listens when the data was found in LocksRecover.
   *
   * @returns void
   */
  protected listenDataFoundInLocksRecover(): void {
    const subs: Subscription = this.locksRecover.locksListener.subscribe(
      (locks: Lock[]) => {
        const secondEntitiesToUpdate: Set<string> = new Set();

        locks.forEach(
          (lock: Lock) => {
            if (lock.type.isEpisode()) {
              const parentMapKey: string = this.secondEntitiesReverse.get(lock.mapKey);

              secondEntitiesToUpdate.add(parentMapKey);
              this.secondEntities.get(parentMapKey).data.get(lock.mapKey).addLock(lock);
            } else {
              this.firstEntities.get(lock.mapKey).addLock(lock);
            }
          }
        );

        secondEntitiesToUpdate.forEach(
          (parentMapKey: string) => {
            this.updateSecondEntitiesOrderFor(parentMapKey);
          }
        );
      }
    );

    this.locksRecoverChangesListener.add(subs);
  }

  /**
   * Listens when any error is detected in LocksRecover.
   *
   * @returns void
   */
  protected listenErrorsInLocksRecover(): void {
    const subs: Subscription = this.locksRecover.errorsListener.subscribe(
      (data: [ string[], ErrorHelper ]) => {
        data[0].forEach(
          (mapKey: string) => {
            this.lockingErrors.set(mapKey, data[1]);
          }
        );
      }
    );

    this.locksRecoverChangesListener.add(subs);
  }

  /**
   * Opens the popup for confirming the deletion of the current project.
   *
   * @returns void
   */
  protected openConfirmDeletionPopup(): void {
    this.modalService.open(this.confirmProjectDeletionModal).result.then(
      (confirm: boolean) => {
        if (confirm) {
          this.deleteCurrentProject();
        } else {
          this.notificationService.handleNotice('Project deletion was cancelled.', undefined, notificationsContainer.projectDashboard.key);
        }
      }
    ).catch(
      (reason: any) => { }
    );
  }

  /**
   * Opens the popup for showing the metadata for the given parameters.
   *
   * @param params Array<[string, Lock, LockAttributeEnum]>
   * @returns void
   */
  protected openMetadataPopup(params: Array<[string, Lock, LockAttributeEnum]>): void {
    this.metadataPopupParams = params;
    this.turnOnIsMetadataPopupOpen();
  }

  /**
   * Refreshes it.
   *
   * @returns void
   */
  protected refresh(): void {
    this.scrollingHandler.refresh(true);
    this.fetchEntities();
  }

  /**
   * Returns the key for a lock using the given attribute and code.
   *
   * @param attribute LockAttributeEnum
   * @param code string
   * @returns string
   */
  protected retrieveLockKeyFor(attribute: LockAttributeEnum, code?: string): string {
    const key: string = `${attribute}${_isString(code) ? `-${code}` : ''}`;
    return key;
  }

  /**
   * Set if the description is expanded.
   *
   * @param value boolean
   * @returns void
   */
  protected setDescriptionExpanded(value: boolean): void {
    this.isDescriptionExpanded = value;
  }

  protected storeSecondEntities(entities: Entity[]): void {
    entities.sort(this.getSortingCriteria()).forEach(
      (entity: Entity) => {
        if (!this.secondEntities.has(entity.parentMapKey)) {
          // TODO: This should be a model.
          const entry: any = {
            data: new Map(),
            isExpanded: false,
            locksRequested: false,
            order: []
          };

          this.secondEntities.set(entity.parentMapKey, entry);
        }

        this.secondEntities.get(entity.parentMapKey).data.set(entity.mapKey, entity);
        this.secondEntities.get(entity.parentMapKey).order.push(entity.mapKey);
        this.secondEntitiesReverse.set(entity.mapKey, entity.parentMapKey);

        this.increaseAllEntitiesCounter();
      }
    );
  }

  /**
   * Toggles the value of displaying the icons legend.
   *
   * @returns void
   */
  protected toggleIconsLegend(): void {
    this.hasDisplayIconsLegend = !this.hasDisplayIconsLegend;
  }

  /**
   * Toggles the second entities for the given parent map key.
   *
   * @param parentMapKey string
   * @returns void
   */
  protected toggleSecondEntitiesFor(parentMapKey: string): void {
    this.secondEntities.get(parentMapKey).isExpanded = !this.secondEntities.get(parentMapKey).isExpanded;

    const entry: any = this.secondEntities.get(parentMapKey);

    if (entry.isExpanded) {
      if (entry.locksRequested) {
        this.updateSecondEntitiesOrderFor(parentMapKey);
      } else {
        entry.locksRequested = true;
        this.locksRecover.fetch(entry.order);
      }
    }
  }

  /**
   * Turns off "isMetadataPopupOpen" attribute.
   *
   * @returns void
   */
  protected turnOffIsMetadataPopupOpen(): void {
    this.isMetadataPopupOpen = false;
  }

  /**
   * Turns on "isMetadataPopupOpen" attribute.
   *
   * @returns void
   */
  protected turnOnIsMetadataPopupOpen(): void {
    this.isMetadataPopupOpen = true;
  }

  /**
   * Updates the order for the second entities for the given parent map key.
   *
   * @param parentMapKey string
   * @returns void
   */
  protected updateSecondEntitiesOrderFor(parentMapKey: string): void {
    const entry: any = this.secondEntities.get(parentMapKey);
    const newOrder: string[] = [];

    entry.data.forEach(
      (entity: Entity) => {
        if (this.doesEntityPassFilters(entity)) {
          newOrder.push(entity.mapKey);
        }
      }
    );

    entry.order = newOrder;
  }
}
