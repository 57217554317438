import { Language } from '@bolt/ui-shared/master-data';
import { includes as _includes } from 'lodash';

import { DataActionEnum } from './data-action.enum';
import { DataStatusEnum } from './data-status.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class StormComponent {
  componentStatuses = DataStatusEnum;
  status: DataStatusEnum;

  protected action: DataActionEnum;

  constructor() { }

  shouldShowLanguageTip(language: Language): boolean {
    return Language.isChinese(language);
  }

  getTipMessage(language: number): string {
    return Language.getChineseMessage(language);
  }

  /**
   * Changes the action to the given one.
   *
   * @param action DataStatusEnum
   * @throws ErrorHelper
   * @returns void
   */
  protected changeActionTo(action: DataActionEnum): void {
    if (_includes(DataActionEnum, <any>action)) {
      this.action = action;
    } else {
      throw new ErrorHelper(`Invalid action given for a component: ${action}`);
    }
  }

  /**
   * Changes the action to "Creating".
   *
   * @returns void
   */
  protected changeActionToCreating(): void {
    this.changeActionTo(DataActionEnum.CREATING);
  }

  /**
   * Changes the action to "Removing".
   *
   * @returns void
   */
  protected changeActionToRemoving(): void {
    this.changeActionTo(DataActionEnum.REMOVING);
  }

  /**
   * Changes the action to "Showing".
   *
   * @returns void
   */
  protected changeActionToShowing(): void {
    this.changeActionTo(DataActionEnum.SHOWING);
  }

  /**
   * Changes the action to "Updating".
   *
   * @returns void
   */
  protected changeActionToUpdating(): void {
    this.changeActionTo(DataActionEnum.UPDATING);
  }

  /**
   * Changes the status to the given one.
   *
   * @param status DataStatusEnum
   * @throws ErrorHelper
   * @returns void
   */
  protected changeStatusTo(status: DataStatusEnum): void {
    if (_includes(DataStatusEnum, <any>status)) {
      this.status = status;
    } else {
      throw new ErrorHelper(`Invalid status given for a component: ${status}`);
    }
  }

  /**
   * Changes the status to "Data Found".
   *
   * @returns void
   */
  protected changeStatusToDataFound(): void {
    this.changeStatusTo(DataStatusEnum.dataFound);
  }

  /**
   * Changes the status to "Data Not Found".
   *
   * @returns void
   */
  protected changeStatusToDataNotFound(): void {
    this.changeStatusTo(DataStatusEnum.dataNotFound);
  }

  /**
   * Changes the status to "Error".
   *
   * @returns void
   */
  protected changeStatusToError(): void {
    this.changeStatusTo(DataStatusEnum.error);
  }

  /**
   * Changes the status to "Fetching Data".
   *
   * @returns void
   */
  protected changeStatusToFetchingData(): void {
    this.changeStatusTo(DataStatusEnum.fetchingData);
  }

  /**
   * Changes the status to "Idle".
   *
   * @returns void
   */
  protected changeStatusToIdle(): void {
    this.changeStatusTo(DataStatusEnum.idle);
  }

  /**
   * Indicates if the action is "Creating".
   *
   * @returns boolean
   */
  protected isCreatingAction(): boolean {
    const isIt: boolean = (this.action === DataActionEnum.CREATING);
    return isIt;
  }

  /**
   * Indicates if the status is "Data Found".
   *
   * @returns boolean
   */
  protected isDataFound(): boolean {
    const isIt: boolean = (this.status === this.componentStatuses.dataFound);
    return isIt;
  }

  /**
   * Indicates if the status is "Data Not Found".
   *
   * @returns boolean
   */
  protected isDataNotFound(): boolean {
    const isIt: boolean = (this.status === this.componentStatuses.dataNotFound);
    return isIt;
  }

  /**
   * Indicates if the status is "Error".
   *
   * @returns boolean
   */
  protected isError(): boolean {
    const isIt: boolean = (this.status === this.componentStatuses.error);
    return isIt;
  }

  /**
   * Indicates if the status is "Fetching Data".
   *
   * @returns boolean
   */
  protected isFetchingData(): boolean {
    const isIt: boolean = (this.status === this.componentStatuses.fetchingData);
    return isIt;
  }

  /**
   * Indicates if the status is "Idle".
   *
   * @returns boolean
   */
  protected isIdle(): boolean {
    const isIt: boolean = (this.status === this.componentStatuses.idle);
    return isIt;
  }

  /**
   * Indicates if the action is "Removing".
   *
   * @returns boolean
   */
  protected isRemovingAction(): boolean {
    const isIt: boolean = (this.action === DataActionEnum.REMOVING);
    return isIt;
  }

  /**
   * Indicates if the action is "Showing".
   *
   * @returns boolean
   */
  protected isShowingAction(): boolean {
    const isIt: boolean = (this.action === DataActionEnum.SHOWING);
    return isIt;
  }

  /**
   * Indicates if the action is "Updating".
   *
   * @returns boolean
   */
  protected isUpdatingAction(): boolean {
    const isIt: boolean = (this.action === DataActionEnum.UPDATING);
    return isIt;
  }
}
