import { isArray as _isArray } from 'lodash';
import { LanguageInterface } from '@bolt/ui-shared/master-data/models/language/language.interface';

import { CharacterMetadataInterface } from './character-metadata.model';
import { CharacterType } from './character-type.enum';
import { LocalizedInterface, Localized } from 'app/modules/common/models/localized.model';
import { Product } from 'app/modules/cat/models/product/product.model';
import { Type as ItemType } from 'app/modules/cat/models/type/type.model';
import { AssociationList } from 'app/modules/cat/models/product/association-list/association-list.model';

/**
 * @todo Move this out of this file.
 */
export interface CharacterInterface extends LocalizedInterface {
  // custom Character attributes
  id?: number;
  itemType?: ItemType;
  name?: string;
  notes?: string;
  originalLanguageId?: number | LanguageInterface;
  phonetic?: string;
  requiredLocalizations?: boolean;
  type?: CharacterType;
  useDomestic?: boolean;
  products?: Product[];
}

export class Character
  extends Localized
  implements CharacterInterface {

  protected _acronym: string;
  protected _id: number;
  protected _itemType: ItemType;
  protected _name: string;
  protected _notes: string;
  protected _originalLanguageId: number | LanguageInterface;
  protected _phonetic: string;
  protected _requiredLocalizations: boolean;
  protected _type: string | CharacterType;
  protected _useDomestic: boolean;
  protected _productAssociations: AssociationList;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.initialize(attributes);
  }

  get acronym(): string {
    return this._acronym;
  }

  set acronym(acronym: string) {
    // This shouldn't be called. Use setAcronym().
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get itemType(): ItemType {
    return this._itemType;
  }

  set itemType(type: ItemType) {
    this._itemType = type;
  }

  get originalLanguageId(): number | LanguageInterface {
    return this._originalLanguageId;
  }

  set originalLanguageId(originalLanguageId: number | LanguageInterface) {
    this._originalLanguageId = originalLanguageId;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
    this.setAcronym();
  }

  get notes(): string {
    return this._notes;
  }

  set notes(notes: string) {
    this._notes = notes;
  }

  get localizations(): CharacterMetadataInterface[] {
    return this._localizations;
  }

  set localizations(localizations: CharacterMetadataInterface[]) {
    this._localizations = localizations;
  }

  get type(): CharacterType {
    return <CharacterType>this._type;
  }

  set type(type: CharacterType) {
    this._type = type;
  }

  get phonetic(): string {
    return this._phonetic;
  }

  set phonetic(phonetic: string) {
    this._phonetic = phonetic;
  }

  get requiredLocalizations(): boolean {
    return this._requiredLocalizations;
  }

  set requiredLocalizations(value: boolean) {
    this._requiredLocalizations = value;
  }

  get useDomestic(): boolean {
    return this._useDomestic;
  }

  set useDomestic(value: boolean) {
    this._useDomestic = value;
  }

  get productAssociations(): AssociationList {
    return this._productAssociations;
  }

  set productAssociations(value: AssociationList) {
    this._productAssociations = value;
  }

  getRawObject(): object {
    const names = Object.getOwnPropertyNames(Character.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Character.prototype, name);
      return !!result.get;
    });

    const object = { };

    getters.forEach(key => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return Object.assign({ }, super.getRawObject(), object);
  }

  toString(): string {
    return this.name;
  }

  /**
   * Indicates if it has product associations.
   *
   * @returns boolean
   */
  hasProductAssociations(): boolean {
    return this._productAssociations.hasProductAssociations();
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this.productAssociations = new AssociationList(data);
    this.setAttributes(data);
  }

  /**
   * Returns the first letter of the given word as an uppercase one.
   *
   * @param word string
   * @returns string
   */
  protected getUppercasedWord(word: string): string {
    const letter: string = word.charAt(0).toLocaleUpperCase();
    return letter;
  }

  /**
   * Set the acronym with the current name.
   *
   * @returns void
   */
  protected setAcronym(): void {
    if (this.name) {
      const words: string[] = this.name.trim().replace(/\s+/, ' ').split(' ');
      let acronym: string = '';

      if (words.length === 1) {
        acronym = this.getUppercasedWord(words[0]);
      } else if (words.length >= 2) {
        const firstLetter: string = this.getUppercasedWord(words[0]);
        const lastLetter: string = this.getUppercasedWord(words[words.length - 1]);

        acronym = `${firstLetter}${lastLetter}`;
      }

      this._acronym = acronym;
    }
  }
}
