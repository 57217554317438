export enum LocaleSpecificFieldEnum {
  functionalMetadata = 'functionalMetadata',
  keywords = 'keywords',
  lockStatus = 'locked',
  ratingId = 'ratingId',
  ratingReason = 'ratingReason',
  ratingSystemReasonId = 'ratingSystemReasonId',
  ratingFilingNumberKmrb = 'ratingFilingNumberKmrb',
  homeEntRatingId = 'homeEntRatingId',
  homeEntRatingReason = 'homeEntRatingReason',
  homeEntRatingSystemReasonId = 'homeEntRatingSystemReasonId',
  onairPlatformKcc = 'onairPlatformKcc',
  onairDateKcc = 'onairDateKcc'
}
