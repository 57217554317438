import { Field } from '../field.model';


export class StringField extends Field {
  constructor(data: any) {
    super(data);
  }

  isString(): boolean {
    return true;
  }

  protected parseValue(value: any): string {
    let parsedValue: string;

    if (this.isValueDefined(value)) {
      parsedValue = String(value);
    } else {
      parsedValue = undefined;
    }

    return parsedValue;
  }
}
