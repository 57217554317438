import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { isUndefined as _isUndefined } from 'lodash';

import { BoltTalentMetadataFormComponent } from '../bolt-talent-metadata-form/bolt-talent-metadata-form.component';
import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { LocalizedDataForm } from '../../models/form/localized-data/localized-data-form.model';
import { TalentLocalizedAttributesEnum } from './talent-localized-attributes.enum';


@Component({
  selector: 'bolt-talent-localized-metadata-form',
  template: require('./bolt-talent-localized-metadata-form.html'),
  styles: [require('./bolt-talent-localized-metadata-form.scss')]
})
export class BoltTalentLocalizedMetadataFormComponent extends BoltTalentMetadataFormComponent implements OnChanges {
  @Input() form: LocalizedDataForm;
  @Input() isEditing: boolean;

  protected localizedAttributes: typeof TalentLocalizedAttributesEnum = TalentLocalizedAttributesEnum;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formConfig: ConfigService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super(formConfig, listLayoutProvider);
    this.isEditing = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
