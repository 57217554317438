import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { StormPageDashboardComponent } from './components/storm-page-dashboard/storm-page-dashboard.component';


export const dashboardRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    data: {
      boltAuthAware: '*',
      title: 'Home',
    },
    component: StormPageDashboardComponent,
    canActivate: [AuthGuard],
  },
];

export const dashboardModuleRouting: ModuleWithProviders = RouterModule.forChild(dashboardRoutes);
