import { isObject as _isObject } from 'lodash';

import { Counters } from './counters/counters.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class Summary {
  protected episode: Counters;
  protected feature: Counters;
  protected featurePmx: Counters;
  protected featureLocalePmx: Counters;
  protected season: Counters;
  protected series: Counters;

  constructor(data: any) {
    if (_isObject(data)) {
      this.episode = new Counters(data.EPISODE);
      this.feature = new Counters(data.FEATURE);
      this.featurePmx = new Counters(data.FEATUREPMX);
      this.featureLocalePmx = new Counters(data.FEATURELOCALEPMX);
      this.season = new Counters(data.SEASON);
      this.series = new Counters(data.SERIES);
    } else {
      throw new ErrorHelper('Invalid data given for a Summary of CPM Event.');
    }
  }

  /**
   * Returns the "Episode" counters.
   *
   * @returns Counters
   */
  getEpisode(): Counters {
    return this.episode;
  }

  /**
   * Returns the "Feature" counters.
   *
   * @returns Counters
   */
  getFeature(): Counters {
    return this.feature;
  }

  /**
   * Returns the "Feature (PMX)" counters.
   *
   * @returns Counters
   */
  getFeaturePmx(): Counters {
    return this.featurePmx;
  }

  /**
   * Returns the "Feature (Locale PMX)" counters.
   *
   * @returns Counters
   */
  getFeatureLocalePmx(): Counters {
    return this.featureLocalePmx;
  }

  /**
   * Returns the "Season" counters.
   *
   * @returns Counters
   */
  getSeason(): Counters {
    return this.season;
  }

  /**
   * Returns the "Series" counters.
   *
   * @returns Counters
   */
  getSeries(): Counters {
    return this.series;
  }
}
