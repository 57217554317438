import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Account, Country, Language, ProductType, StormListType } from '@bolt/ui-shared/master-data';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Observer } from 'rxjs';

import { Locale } from 'app/modules/common/models/locale/locale.model';
import { RoleInterface, Role } from '../../models/role.model';
import { RoleMetadataInterface, RoleMetadata } from '../../models/role-metadata.model';
import { RoleMetadataManagerActions } from '../../components/bolt-role-metadata-manager/bolt-role-metadata-manager.component';
import { RoleService } from '../../services/role.service';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { StormServiceResponseSingleInterface } from 'app/modules/common/services/storm-service-response-single';

export interface RoleManagerMetadataManagement {
  roleMetadata: RoleMetadataInterface;
  action: RoleMetadataManagerActions;
  key: string[];
  role: RoleInterface;
  defaultValues?: object;
}


@Injectable()
export class RoleManager {
  protected singleRoleMetadata: BehaviorSubject<RoleManagerMetadataManagement> = new BehaviorSubject(undefined);

  constructor(
    protected roleService: RoleService,
    protected stormListsProvider: StormListsProvider
  ) { }

  /**
   * Allows to manage (create, edit, delete) a Role Metadata entry
   * This method will emit a RoleManagerMetadataManagement that
   * will be handled by the BoltRoleMetadataManagerComponent.
   *
   * @param metadata RoleMetadataInterface
   * @param action RoleMetadataManagerActions
   * @param role RoleInterface
   * @param key string[]
   * @param defaultValues object
   * @returns void
   */
  manageRoleMetadata(
    metadata: RoleMetadataInterface,
    action: RoleMetadataManagerActions,
    role: RoleInterface,
    key?: string[],
    defaultValues?: object
  ): void {

    this.singleRoleMetadata.next({
      roleMetadata: metadata,
      action: action,
      key: key,
      role: role,
      defaultValues: defaultValues,
    });
  }

  /**
   * Returns the observable Role Metadata
   *
   * @return Observable<RoleManagerMetadataManagement>
   */
  getManagedRoleMetadata(): Observable<RoleManagerMetadataManagement> {
    return this.singleRoleMetadata.asObservable();
  }

  /**
   * Creates a role with the given data.
   *
   * @param role Role
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  createRole(role: Role): Observable<StormServiceResponseSingleInterface> {
    return this.roleService.createRole(role);
  }

  /**
   * Takes care of creating new RoleMetadata
   *
   * @param roleId number
   * @param roleMetadata Role
   * @param locked boolean
   * @return Observable<StormServiceResponseSingleInterface>
   */
  setRoleMetadata(
    roleId: number,
    locale: string,
    roleMetadata: Role,
    locked: boolean = false
  ): Observable<StormServiceResponseSingleInterface> {

    roleMetadata['locked'] = locked;

    return this.roleService.setRoleMetadata({
        roleId: roleId
      },
      locale,
      this.parseRoleMetadata(roleMetadata)
    );

  }

  /**
   * Updates the current role for the given id with the given data.
   *
   * @param roleId number
   * @param fromLocale Locale
   * @param toLocale Locale
   * @param roleMetadata Role
   * @param locked boolean
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  updateRoleMetadata(
    roleId: number,
    fromLocale: Locale,
    toLocale: Locale,
    roleMetadata: Role,
    locked: boolean = false
  ) {

    return this.roleService.updateRoleMetadata({
        roleId: roleId
      },
      fromLocale,
      toLocale,
      this.parseRoleMetadata(roleMetadata)
    );
  }

  /**
   * Deletes the given attributes for the role with the given id.
   *
   * @param roleId number
   * @param locale string
   * @param properties string[]
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  deleteRoleMetadataAttribute(
    roleId: number,
    locale: string,
    properties: string[]
  ): Observable<StormServiceResponseSingleInterface> {

    return this.roleService.deleteRoleMetadataAttribute({
        roleId: roleId
      },
      locale,
      properties
    );
  }

  /**
   * Get the current role with the given id and locale.
   *
   * @param roleId number
   * @param locale string | Locale
   * @returns Observable<RoleMetadata>
   */
  getRoleMetadataByLocale(
    roleId: number,
    locale: string | Locale
  ): Observable<RoleMetadata> {
    return Observable.create((observer: Observer<RoleMetadata>) => {
      this.roleService.fetchRoleMetadata({
        roleId: roleId,
        locale: locale.toString()
      }).subscribe(
        (singleResponse: StormServiceResponseSingleInterface) => {
          observer.next(singleResponse.item);
          observer.complete();
        },
        (error: HttpErrorResponse) => {
          observer.next(undefined);
          observer.complete();
        }
      );
    });
  }

  /**
   * Get the computed metadata for the given role.
   *
   * @param roleMetadata RoleMetadataInterface
   * @returns Observable<RoleMetadataInterface>
   */
  getComputedRoleMetadata(
    roleMetadata: RoleMetadataInterface
  ): Observable<RoleMetadataInterface> {
    return Observable.create((observer: Observer<RoleMetadataInterface>) => {
      this.roleService.fetchComputedRoleMetadata({
        roleId: roleMetadata.rootId,
        locale: roleMetadata.locale
      }).subscribe(singleResponse => {
        this.mapAttributes([singleResponse.item]).subscribe(
          computedRoleMetadata => {
            const finaleComputedRoleMetadata: RoleMetadataInterface = <RoleMetadataInterface>computedRoleMetadata[0];

            // Computed Localization API criteria for lastModified/lastModifiedBy attributes is different that STS one,
            // so we need to keep these attributes from the original (the one that comes from Fetch API)
            finaleComputedRoleMetadata.lastModifiedBy = roleMetadata.lastModifiedBy;
            finaleComputedRoleMetadata.lastModified = roleMetadata.lastModified;
            finaleComputedRoleMetadata.inheritedAttributes = Object.keys(_.pickBy(roleMetadata, _.isUndefined));
            observer.next(finaleComputedRoleMetadata);
            observer.complete();
          }
        );
      });
    });
  }

  /**
   * Maps the attributes of the given role.
   *
   * @param metadataCollection RoleMetadataInterface[]
   * @return Observable<TitleMetadataAttributeInterface>
   */
  mapAttributes(metadataCollection: RoleInterface | RoleInterface[]): Observable<RoleInterface[]> {
    return Observable.create((observer: Observer<RoleInterface | RoleInterface[]>) => {
      this.stormListsProvider.getLists().subscribe(lists => {
          if (!_.isArray(metadataCollection)) {
            metadataCollection = [<RoleMetadataInterface>metadataCollection];
          }

          const collection = _.cloneDeep(metadataCollection);

          (<RoleMetadataInterface[]>collection).map(roleMetadata => {
            // keep the original property values as a copy
            roleMetadata.originalData = _.cloneDeep(roleMetadata);

            try {
              roleMetadata.language =
                lists.getList(StormListType.language).getItem(<number>(roleMetadata.language)).value;
            } catch (e) { }

            try {
              roleMetadata.territory = (<[number]>roleMetadata.territory).map(territory => {
                return lists.getList(StormListType.territory).getItem(territory).value;
              });
            } catch (e) { }

            try {
              roleMetadata.productType = (<[number]>roleMetadata.productType).map(productType => {
                return lists.getList(StormListType.productType).getItem(productType).value;
              });
            } catch (e) { }

            try {
              roleMetadata.account = (<[number]>roleMetadata.account).map(account => {
                return lists.getList(StormListType.account).getItem(account).value;
              });
            } catch (e) { }

            try {
              roleMetadata.originalLanguageId =
                lists.getList(StormListType.language).getItem(<number>(roleMetadata.originalLanguageId)).value;
            } catch (e) { }

          });

          observer.next(collection);
          observer.complete();
      });
    });
  }

  /**
   * Get the locale of the given role.
   *
   * @param metadata RoleInterface
   * @returns Observable<string>
   */
  getLocaleFromLocaleIds(metadata: RoleInterface): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      this.mapAttributes([_.cloneDeep(metadata)]).subscribe(
        mappedMetadataCollection => {
          const roleMetadata = mappedMetadataCollection[0];

          const territory = (<Country[]>roleMetadata.territory).map(
            mappedTerritory => {
              if (mappedTerritory.id === 0) {
                return '*';
              }
              return mappedTerritory.iso31661;
            }
          ).join(',');

          const productType = (<ProductType[]>roleMetadata.productType).map(
            mappedProductType => {
              if (mappedProductType.id === 0) {
                return '*';
              }
              return mappedProductType.code;
            }
          ).join(',');

          const account = (<Account[]>roleMetadata.account).map(
            mappedAccount => {
              if (mappedAccount.id === 0) {
                return '*';
              }
              return mappedAccount.code;
            }
          ).join(',');

          const locale = [
            (<Language>roleMetadata.language).localeLanguage,
            territory,
            productType,
            account
          ].join('_');

          observer.next(locale);
          observer.complete();
        }
      );
    });
  }

  /**
   * Sort the Role Metadata collection with the given sortBy criteria.
   *
   * @param roleMetadataCollection RoleMetadataInterface[]
   * @param sortBy Array<{ property: string; reverse: boolean; order: number }>
   * @returns RoleMetadataInterface[]
   */
  sortRoleMetadata(
    roleMetadataCollection: RoleMetadataInterface[],
    sortBy: Array<{ property: string; reverse: boolean; order: number }> = []
  ): RoleMetadataInterface[] {
    if (!sortBy.length) {
      const firstItemsCriteria = {
        locale: 'en_*_*_*'
      };

      const firstItems = _.filter(roleMetadataCollection, firstItemsCriteria);
      const res = [...firstItems, ..._.reject(roleMetadataCollection, firstItemsCriteria)];
      return res;
    }

    let _roleMetadataCollection = _(roleMetadataCollection).chain();
    const sorting = _.map(sortBy, _.clone);

    sorting.reverse().forEach((sort: any) => {
      _roleMetadataCollection = _roleMetadataCollection.sortBy((o) => {
        if (_.isArray(o[sort.property])) {
          const all = _(o[sort.property]).filter({ id: 0 });

          return (
            (all.size() ? '0' : '').toString() +
            all.join('_') +
            _(o[sort.property]).reject({ id: 0 }).sortBy('value').join('_')
          ).toUpperCase();
        }
        return (o[sort.property].id === 0 ? '0' : '') + o[sort.property].toString();
      });

      if (sort.reverse) {
        _roleMetadataCollection = _roleMetadataCollection.reverse();
      }
    });

    return _roleMetadataCollection.value();
  }

  /**
   * Prepares the Role Metadata attributes to be sure the API
   * will not reject the request because of wrong data
   *
   * @param roleMetadata Role
   * @return Role
   */
  protected parseRoleMetadata(roleMetadata: Role): Role {
    // locale isn't being used, removing
    roleMetadata.locale = undefined;

    return roleMetadata;
  }
}
