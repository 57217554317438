import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { BoltMetadataComponent } from 'app/modules/common/models/bolt-metadata-component.model';
import { Character } from '../../models/character.model';
import { CharacterMetadata } from '../../models/character-metadata.model';
import { CharacterMetadataManager } from '../../helpers/character-metadata-manager/character-metadata-manager';
import { CharacterService } from '../../services/character.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { RoleCapabilitiesHandler } from 'app/modules/cat/services/role-capabilities-handler/role-capabilities-handler';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TypeEnum as ItemType } from 'app/modules/cat/models/type/type.enum';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { TypeEnum as CreditType } from 'app/modules/credits/models/wizard/candidate/type/type.enum';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { EditHistoryMetadata } from 'app/modules/common/components/bolt-edit-history/bolt-edit-history.component';


@Component({
  selector: 'bolt-character-localized-details',
  template: require('./bolt-character-localized-details.html'),
  styles: [require('./bolt-character-localized-details.scss')]
})
export class BoltCharacterLocalizedDetailsComponent extends BoltMetadataComponent {
  @Input() localizedCharacter: CharacterMetadata;
  @Output() metadataUpdated: EventEmitter<CharacterMetadata>;
  @Output() localizationRemove: EventEmitter<undefined>;
  @Output() editLocalizationEvent: EventEmitter<undefined>;

  lockingFlag: boolean;
  showEditHistory: boolean = false;

  constructor(
    protected characterMetadataManager: CharacterMetadataManager,
    protected characterService: CharacterService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    super(modalService, notificationService);
    this.initialize();
  }

  get originalCharacter(): Character {
    return this.characterMetadataManager.character;
  }

  getMetadata(): EditHistoryMetadata {
    const localeObject: Locale = new Locale({
      account: this.localizedCharacter.account,
      language: this.localizedCharacter.language,
      productType: this.localizedCharacter.productType,
      territory: this.localizedCharacter.territory,
      localeType: this.localizedCharacter.localeType
    });

    return {
      id: this.originalCharacter?.id,
      locale: this.localizedCharacter.locale,
      type: CreditType.character,
      name: this.localizedCharacter.name,
      language: this.localizedCharacter.language,
      territory: this.localizedCharacter.territory,
      productType: this.localizedCharacter.productType,
      account: this.localizedCharacter.account,
      localeObject: localeObject
    };
  }

  /**
   * Close the edit history modal.
   *
   * @returns void
   */
  closeEditHistory(): void {
    this.showEditHistory = false;
  }

  /**
   * Open the edit history modal.
   *
   * @returns void
   */
  openEditHistory(): void {
    this.showEditHistory = true;
  }

  /**
   * Indicates if the current user can edit.
   *
   * @returns boolean
   */
  canEdit(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalized(ItemType.Character, ActionTypeEnum.write);
  }

  /**
   * Indicates if it has to display the edit button.
   *
   * @return boolean
   */
  protected hasDisplayEditButton(): boolean {
    const hasIt: boolean =
      this.canEdit() &&
      (
        (this.localizedCharacter.locked && this.hasDisplayLockingStatusButton()) ||
        !this.localizedCharacter.locked
      );

    return hasIt;
  }

  /**
   * Indicates if it has to display the locking button.
   *
   * @returns boolean
   */
  protected hasDisplayLockingStatusButton(): boolean {
    const hasIt: boolean = this.roleCapabilitiesHandler.canToggleLocalizedLockingStatusOf(
      this.localizedCharacter.locked,
      ItemType.Character
    );

    return hasIt;
  }

  /**
   * Opens the modal for edit the character.
   *
   * @return void
   */
  protected edit(): void {
    this.editLocalizationEvent.emit();
  }

  /**
   * This method removes the selected locale for the current talent
   *
   * @return void
   */
  protected deleteLocalization(): void {
    this.disableConfirmDialog();
    this.characterService.deleteCharacterLocalization(
      {
        characterId: this.localizedCharacter.rootId
      },
      this.localizedCharacter.locale
    ).subscribe(
      () => {
        this.confirmDeleteLocalizationModal.close();

        this.notificationService.handleNotice(
          'The localization was deleted successfully.',
          undefined,
          notificationsContainer.character.details.key
        );

        this.localizationRemove.emit();
      },
      (error: ErrorHelper) => {
        this.confirmDeleteLocalizationModal.close();
        this.notificationService.handleError(
          'There was an error trying to delete the location.',
          error,
          notificationsContainer.character.details.key
        );
      }
    );
  }

  /**
   * Indicates if it has to block the edition
   *
   * @returns boolean
   */
  protected hasBlockEdit(): boolean {
    const hasIt: boolean = this.localizedCharacter.locked || this.lockingFlag;
    return hasIt;
  }

  /**
   * Indicates if it has to block the delete
   *
   * @returns boolean
   */
  protected hasBlockDelete(): boolean {
    const hasIt: boolean = this.lockingFlag || this.localizedCharacter.locked;
    return hasIt;
  }

  /**
   * Indicates if it has to block the locking status button
   */
  protected hasBlockLockingStatusButton(): boolean {
    return this.lockingFlag;
  }

  /**
   * Indicates if it has to display the delete button.
   *
   * @returns boolean
   */
  protected hasDisplayDeleteButton(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalized(ItemType.Character, ActionTypeEnum.delete);
  }

  /**
   * Indicates if it has to display the spinner on the lock section
   *
   * @returns boolean
   */
  protected hasDisplayLockSpinner(): boolean {
    return this.lockingFlag;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.editLocalizationEvent = new EventEmitter();
    this.metadataUpdated = new EventEmitter();
    this.localizationRemove = new EventEmitter();
    this.lockingFlag = false;
  }

  /**
   * Retrieves the message that it has to display when there is no selected localization.
   *
   * @returns string
   */
  protected retrieveNoSelectedMessage(): string {
    const message: string =
      this.characterMetadataManager.hasLocalizations()
      ? 'Please select a localization from the list'
      : 'Please create a localization';

    return message;
  }

  /**
   * Changes the locked state of the character localization.
   *
   * @returns void
   */
  protected toggleCharacterLockedState(): void {
    this.lockingFlag = true;
    const lock: boolean = !this.localizedCharacter.locked;

    this.characterService.setCharacterMetadata(
      { characterId: this.localizedCharacter.rootId },
      this.localizedCharacter.locale,
      new CharacterMetadata({ locked: lock })
    ).subscribe(
      (serviceResponseSingle: StormServiceResponseSingle) => {
        const response: CharacterMetadata = serviceResponseSingle.item;
        this.metadataUpdated.emit(response);

        this.notificationService.handleNotice(
          `The localization is ${(lock ? 'locked' : 'unlocked')}`,
          undefined,
          notificationsContainer.character.details.key
        );

        this.lockingFlag = false;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'Error while trying to update the Localization lock status',
          error,
          notificationsContainer.character.details.key
        );

        this.lockingFlag = false;
      }
    );
  }
}
