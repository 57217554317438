import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';


@Injectable({ providedIn: 'root' })
export class MicroUiRouteChecker {
  protected routes: any;

  constructor(
    protected appConfig: AppConfigProvider
  ) {
    this.initialize();
  }

  /**
   * Verifies that a route belongs to a MicroUI. This information is
   * taken from app-config.global.json
   *
   * @param targetRoute string
   * @returns boolean
   */
  routeExists(targetRoute: string): boolean {
    const splittedTargetRoute = targetRoute.split('/');

    if (splittedTargetRoute.length > 1) {
      const microUiPath = splittedTargetRoute[1];

      for (const { path } of this.routes) {
        if (path === microUiPath) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.routes = this.appConfig.get('application.microUi.routingExceptions');
  }
}
