import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WindowWrapperService } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { clone as _clone, isNaN as _isNaN, isObject as _isObject, isString as _isString, uniq as _uniq } from 'lodash';

import { Entity } from '../../models/entity/entity.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';

import {
  FindByRidsResponse as TitleFindByRidsResponse
} from 'app/modules/title/models/title/find-by-rids-response/find-by-rids-response.model';

import { LayoutService as FormLayoutService } from 'app/shared/services/form/layout/layout.service';
import { ManagerService } from '../../services/project/manager/manager.service';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Title } from 'app/modules/title/models/title.model';
import { TitleService } from 'app/modules/title/services/title.service';


@Component({
  selector: 'bolt-project-entity-bulk-add',
  template: require('./bolt-project-entity-bulk-add.html'),
  styles: [require('./bolt-project-entity-bulk-add.scss')]
})
export class BoltProjectEntityBulkAddComponent extends StormComponent {
  @Input() disabled: boolean;
  @Input() entities: Map<string, Entity>;

  @Output('selected') protected selectEvent: EventEmitter<Entity[]>;

  @ViewChild('requestPopup')
  protected requestPopupRef: TemplateRef<any>;

  protected readonly ridsErrorMessage: string = 'Failed trying to bulk add titles.';
  protected readonly ridsInputName: string = 'rids-entry';
  protected readonly ridsListName: string = 'rids-list';
  protected readonly separator: string = ',';

  protected findTitleResponse: TitleFindByRidsResponse;
  protected form: FormGroup;
  protected formAttributes: any;
  protected newEntities: Entity[];
  protected repeatedEntities: Entity[];
  protected requestPopupModal: NgbModalRef;
  protected ridsContainer: HTMLUListElement;
  protected ridsInput: HTMLInputElement;
  protected ridsLimit: number;
  protected ridsList: number[];

  constructor(
    protected formBuilder: FormBuilder,
    protected formLayout: FormLayoutService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected projectManager: ManagerService,
    protected titleService: TitleService,
    protected windowWrapper: WindowWrapperService,
    protected appConfigProvider: AppConfigProvider
  ) {
    super();

    this.disabled = false;
    this.ridsLimit = this.appConfigProvider.get('forms.projectDashboard.bulkAdd.fields.rids.maxLength');
    this.selectEvent = new EventEmitter();
  }

  /**
   * Builds the form.
   *
   * @returns void
   */
  protected buildForm(): void {
    const controls: any = {
      rids: [
        undefined,
        Validators.compose([
          Validators.required,
          Validators.maxLength(this.ridsLimit)
        ])
      ]
    };

    this.form = this.formBuilder.group(controls);
  }

  /**
   * Closes the request popup.
   *
   * @returns void
   */
  protected closeRequestPopup(): void {
    this.requestPopupModal.close();
    this.destroyForm();
    this.destroyRidsHtmlElements();
    this.resetRidsList();
    this.resetNewEntities();
    this.resetRepeatedEntities();
    this.resetFindTitlesResponse();
  }

  /**
   * Destroys the form.
   *
   * @returns void
   */
  protected destroyForm(): void {
    this.form = undefined;
  }

  /**
   * Destroys the RIDs HTML elements.
   *
   * @returns void
   */
  protected destroyRidsHtmlElements(): void {
    this.ridsContainer = undefined;
    this.ridsInput = undefined;
  }

  /**
   * Reads the new entities from the stored find titles response.
   *
   * @returns void
   */
  protected discoverNewEntities(): void {
    this.findTitleResponse.titles.forEach(
      (title: Title) => {
        const entity: Entity = Entity.newFromTitle(title);

        if (this.entities.has(entity.mapKey)) {
          this.repeatedEntities.push(entity);
        } else {
          this.newEntities.push(entity);
        }
      }
    );
  }

  /**
   * Discovers the RIDs content in the given event.
   *
   * @returns void
   */
  protected discoverRidsContent(): void {
    const query: string = this.ridsInput.value.trim();

    if (query[query.length - 1] === this.separator) {
      const candidates: string[] = query.split(this.separator);

      this.updateRidList(candidates);
      this.scrollToRidsContainerBottom();
    }
  }

  /**
   * Discovers the RIDs HTML elements.
   *
   * @returns void
   */
  protected discoverRidsHtmlElements(): void {
    this.ridsContainer = this.windowWrapper.getDocument().querySelector(
      `.${this.ridsListName} .ui-autocomplete-multiple-container`
    );

    this.ridsInput = this.windowWrapper.getDocument().querySelector(`.${this.ridsInputName}`);
  }

  /**
   * Fetches the candidate entities.
   *
   * @returns void
   */
  protected fetchCandidateEntities(): void {
    this.changeStatusToFetchingData();
    this.resetNewEntities();
    this.resetRepeatedEntities();
    this.resetFindTitlesResponse();
    this.form.disable();

    this.titleService.findTitlesByRids(
      this.ridsList,
      (response: TitleFindByRidsResponse) => {
        this.storeFindTitleResponse(response);
        this.discoverNewEntities();
        this.changeStatusToDataFound();
        this.notifyNewEntities();

        if (!this.hasDisplaySummary()) {
          this.closeRequestPopup();
        }
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(this.ridsErrorMessage, error, notificationsContainer.projectDashboard.key);
        this.changeStatusToError();
        this.form.enable();
      }
    );
  }

  /**
   * Handles the event for keyword key up.
   *
   * @param event KeyboardEvent
   * @returns void
   */
  protected handleKeyUpEvent(event: KeyboardEvent): void {
    if ((event.key === 'Enter') && (this.ridsInput.value.length > 0)) {
      this.ridsInput.value += this.separator;
      this.discoverRidsContent();
    }
  }

  /**
   * Indicates if it has to block the add button.
   *
   * @returns boolean
   */
  protected hasBlockAdd(): boolean {
    const isIt: boolean = (this.form.invalid || this.isFetchingData());
    return isIt;
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.isFetchingData();
  }

  /**
   * Indicates if it has to display the summary.
   *
   * @returns boolean
   */
  protected hasDisplaySummary(): boolean {
    return _isObject(this.findTitleResponse);
  }

  /**
   * Notifies the new entities.
   *
   * @returns void
   */
  protected notifyNewEntities(): void {
    this.selectEvent.next(this.newEntities);
  }

  /**
   * Opens the request popup.
   *
   * @returns void
   */
  protected openRequestPopup(): void {
    this.changeStatusToIdle();
    this.resetRidsList();
    this.buildForm();

    const options: any = {
      keyboard: false,
      backdrop: 'static'
    };

    this.requestPopupModal = this.modalService.open(this.requestPopupRef, options);

    setTimeout(
      () => {
        this.discoverRidsHtmlElements();
        this.ridsInput.focus();
      }
    );
  }

  /**
   * Reset the find titles response.
   *
   * @returns void
   */
  protected resetFindTitlesResponse(): void {
    this.findTitleResponse = undefined;
  }

  /**
   * Reset the RIDs list.
   *
   * @returns void
   */
  protected resetRidsList(): void {
    this.ridsList = new Array();
  }

  /**
   * Reset the repeated entities.
   *
   * @returns void
   */
  protected resetRepeatedEntities(): void {
    this.repeatedEntities = new Array();
  }

  /**
   * Reset the new entities.
   *
   * @returns void
   */
  protected resetNewEntities(): void {
    this.newEntities = new Array();
  }

  /**
   * Scrolls to the RIDs container bottom.
   *
   * @returns void
   */
  protected scrollToRidsContainerBottom(): void {
    setTimeout(
      () => {
        this.ridsContainer.scrollTo(0, this.ridsContainer.scrollHeight);
      }
    );
  }

  /**
   * Stores the given response for finding titles.
   *
   * @param response TitleFindByRidsResponse
   * @returns void
   */
  protected storeFindTitleResponse(response: TitleFindByRidsResponse): void {
    this.findTitleResponse = response;
  }

  /**
   * Updates the RIDs list using the given candidates.
   *
   * @param candidates number[]
   * @returns void
   */
  protected updateRidList(candidates: string[]): void {
    const finalRids: number[] = _clone(this.ridsList);

    candidates.forEach(
      (candidate: string) => {
        if (_isString(candidate)) {
          const newRid: number = Number(candidate.trim() || undefined);

          if (!_isNaN(newRid)) {
            finalRids.push(newRid);
          }
        }
      }
    );

    this.ridsList = _uniq(finalRids);
    this.ridsInput.value = '';
  }
}
