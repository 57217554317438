import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';


@Component({
  selector: 'bolt-cpm-configurations-mapping-filters',
  template: require('./bolt-cpm-configurations-mapping-filters.html'),
  styles: [require('./bolt-cpm-configurations-mapping-filters.scss')]
})
export class BoltCpmConfigurationsMappingFiltersComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Output('filteredBy') filterByEvent: EventEmitter<string>;

  @ViewChild('filterBy')
  protected filterByInput: ElementRef;

  protected filterBySubject: Subject<void>;
  protected filterByListener: Subscription;
  protected words: string;

  constructor() {
    this.disabled = false;
    this.filterByEvent = new EventEmitter();
    this.filterBySubject = new Subject();
    this.words = '';
  }

  ngOnDestroy() {
    this.filterByListener.unsubscribe();
  }

  ngOnInit() {
    this.filterByListener = this.filterBySubject.asObservable().pipe(debounceTime(200)).subscribe(
      () => {
        const words: string = this.filterByInput.nativeElement.value.trim().toLowerCase();

        if (words !== this.words) {
          this.words = words;
          this.filterByEvent.emit(this.words);
        }
      }
    );
  }

  /**
   * Notifies the written words in the "Filter By" input.
   *
   * @returns void
   */
  protected notifyFilterByChange(): void {
    this.filterBySubject.next();
  }
}
