import { includes as _includes } from 'lodash';
import * as moment from 'moment';

import { ExportFormatEnum } from '../../export-format.enum';


export class ExportRecord {
  protected clientId: number;
  protected clientName: string;
  protected date: moment.Moment;
  protected fileFormat: ExportFormatEnum;
  protected fileLink: string;
  protected fileName: string;
  protected id: number;
  protected locale: string;
  protected locked: boolean;
  protected user: string;

  constructor(data: any) {
    this.clientId = data.accountId;
    this.clientName = data.accountName;
    this.date = moment(data.generationDate);
    this.fileLink = data.downloadURL;
    this.fileName = data.fileName;
    this.id = data.id;
    this.locale = data.distributionRecordLocale;
    this.locked = data.locked;
    this.user = data.userId;

    this.discoverFileFormat();
  }

  /**
   * Returns the Client ID.
   *
   * @returns number
   */
  getClientId(): number {
    return this.clientId;
  }

  /**
   * Returns the Client name.
   *
   * @returns string
   */
  getClientName(): string {
    return this.clientName;
  }

  /**
   * Returns the date.
   *
   * @returns moment.Moment
   */
  getDate(): moment.Moment {
    return this.date;
  }

  /**
   * Returns the file format.
   *
   * @returns ExportFormatEnum
   */
  getFileFormat(): ExportFormatEnum {
    return this.fileFormat;
  }

  /**
   * Returns the file link.
   *
   * @returns string
   */
  getFileLink(): string {
    return this.fileLink;
  }

  /**
   * Returns the file name.
   *
   * @returns string
   */
  getFileName(): string {
    return this.fileName;
  }

  /**
   * Returns the ID.
   *
   * @returns number
   */
  getId(): number {
    return this.id;
  }

  /**
   * Returns the locale.
   *
   * @returns string
   */
  getLocale(): string {
    return this.locale;
  }

  /**
   * Returns the user.
   *
   * @returns string
   */
  getUser(): string {
    return this.user;
  }

  /**
   * Indicates if it is locked.
   *
   * @returns boolean
   */
  isLocked(): boolean {
    return this.locked;
  }

  /**
   * Discovers the file format by checking the file name.
   *
   * @returns void
   */
  protected discoverFileFormat(): void {
    const extension: string = this.fileName.split('.').pop().toUpperCase();
    const format: ExportFormatEnum = ExportFormatEnum[extension];

    if (_includes(ExportFormatEnum, format)) {
      this.fileFormat = format;
    } else {
      throw new Error(`Invalid file format detected: ${format}`);
    }
  }
}
