import { isNumber as _isNumber } from 'lodash';

import { CharacterInterface } from 'app/modules/character/models/character.model';
import { Credit } from '../credit/credit.model';
import { CreditPositionType } from '../credit/credit-position.enum';


export class Cast extends Credit {
  protected characterId: number;
  protected position: CharacterInterface;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
    this.positionType = CreditPositionType.CAST;
  }

  getCharacterId(): number {
    return this.characterId;
  }

  setCharacterId(id: number): void {
    this.characterId = id;
  }

  getPosition(): CharacterInterface {
    return this.position;
  }

  getLocalizations(): Cast[] {
    return this._localizations;
  }

  setLocalizations(localizations: Cast[]) {
    this._localizations = localizations;
  }

  getRawObject(): object {
    const rawObject: any = super.getRawObject();
    rawObject.characterId = this.characterId;

    return rawObject;
  }

  isComplete(): boolean {
    return _isNumber(this.talentId) && _isNumber(this.characterId);
  }
}
