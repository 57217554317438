import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';

export class HotConfiguration {
  protected _id: number;
  protected _code: string;
  protected _value: string;
  protected _type: string;

  constructor(data: any) {
    this.initialize(data);
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get code(): string {
    return this._code;
  }

  set code(code: string) {
    this._code = code;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }

  get type(): string {
    return this._type;
  }

  set type(type: string) {
    this._type = type;
  }

  /**
   * Returns an Object instance of the current class
   * @returns object
   */
  getRawObject(): object {
    const names = Object.getOwnPropertyNames(HotConfiguration.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(HotConfiguration.prototype, name);
      return !!result.get;
    });

    const object = new Object();
    getters.forEach(key => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return object;
  }

  /**
   * Initializes the instance.
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    if (
      data && data.hasOwnProperty('id') && data.hasOwnProperty('code') &&
      data.hasOwnProperty('value') && data.hasOwnProperty('type')
    ) {
      this.id = data.id;
      this._code = data.code;
      this._value = data.value;
      this._type = data.type;
    } else {
      throw new ErrorHelper('Cannot get needed fields for a hot configuration.');
    }
  }
}
