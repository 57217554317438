import { isObject as _isObject } from 'lodash';

import { Locale } from 'app/modules/common/models/locale/locale.model';
import { Title } from 'app/modules/title/models/title.model';
import { Element } from 'app/modules/search/models/element/element.model';
import { Episode } from 'app/modules/title/models/episode.model';


export class ExportCandidate {
  protected title: Title;
  protected locale: Locale;

  constructor(title: Title, locale: Locale) {
    this.setTitle(title);
    this.setLocale(locale);
  }

  /**
   * Returns the key for the given parameters.
   *
   * @param item Element | Title
   * @param locale Locale
   * @return string
   */
  static getKeyFor(item: Element | Title, locale: Locale): string {
    const key = item.id + '-' + item.type + '-' + locale.getLocale().toLocaleLowerCase();
    return key;
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns object
   */
   asEndpointData(): object {
    const data: object = {
      titleId: this.title.id,
      titleType: this.title.type,
      distributionRecordLocale: this.locale?.getLocale()
    };

    if (this.title.isSeason()) {
      data['seasonId'] = (<Episode>this.title).seasonId;
    }

    return data;
  }

  /**
   * Returns the Title.
   *
   * @returns Title.
   */
  getTitle(): Title {
    return this.title;
  }

  /**
   * Returns the key.
   *
   * @returns string
   */
  getKey(): string {
    return ExportCandidate.getKeyFor(this.title, this.locale);
  }

  /**
   * Returns the Locale.
   *
   * @returns Locale
   */
  getLocale(): Locale {
    return this.locale;
  }

  /**
   * Set the Title.
   *
   * @param title Title
   * @returns void
   * @throw Error
   */
  protected setTitle(title: Title): void {
    if (_isObject(title)) {
      this.title = title;
    } else {
      throw new Error('Invalid title given for an export candidate.');
    }
  }

  /**
   * Set the Locale.
   *
   * @param locale Locale
   * @returns void
   * @throw Error
   */
  protected setLocale(locale: Locale): void {
    if (_isObject(locale) && locale.isComplete()) {
      this.locale = locale;
    }
  }
}
