import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-message',
  template: require('./bolt-message.html'),
  styles: [require('./bolt-message.scss')]
})
export class BoltMessageComponent {
  @Input() legend: string;

  constructor() {
    this.initialize();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.legend = undefined;
  }
}
