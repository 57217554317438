import { Injectable } from '@angular/core';
import { isObject as _isObject } from 'lodash';
import { Subscription, fromEvent } from 'rxjs';
import { WindowWrapperService } from '@bolt/ui-shared/common';


@Injectable()
export class ScrollingHandlerService {
  protected readonly alreadyVisitedMark: string = 'pd-hack-visited';

  protected isFirstTime: boolean;
  protected screenResizeListener: Subscription;
  protected targetStyles: Map<string, any[]>;

  constructor(protected windowWrapper: WindowWrapperService) {
    this.turnOnIsFirstTime();
    this.setupTargetStyles();
  }

  /**
   * Refreshes the scrolling hacks. If hard more is true, the refresh takes in count all affected elements by
   * previous scrolling hack. Otherwise it takes in count non-affected elements.
   *
   * @param hardMode boolean
   * @returns void
   */
  refresh(hardMode: boolean): void {
    if (hardMode) {
      this.resetSrollPosition();
    }

    this.targetStyles.forEach(
      (value: any, key: string) => {
        if (hardMode) {
          this.unfreeze(key, value);
        }

        this.freeze(key, value);
      }
    );
  }

  /**
   * Starts the scrolling hacks.
   *
   * @returns void
   */
  start(): void {
    if (this.isFirstTime) {
      this.turnOffIsFirstTime();
      this.freezeAllTargets();

      this.screenResizeListener = fromEvent(this.windowWrapper.getWindow(), 'resize').subscribe(
        () => {
          this.refresh(true);
        }
      );
    }
  }

  /**
   * Stops the scrolling hacks.
   *
   * @returns void
   */
  stop(): void {
    this.resetSrollPosition();
    this.unfreezeAllTargets();
    this.unsubscribeScreenResize();
    this.turnOnIsFirstTime();
  }

  /**
   * Freezes the element with the given selector.
   *
   * @param selector string
   * @param hack any[]
   * @returns void
   */
  protected freeze(selector: string, hack: any[]): void {
    const elements: NodeListOf<HTMLElement> = this.getTargets(selector, true);

    elements.forEach(
      (element: HTMLElement) => {
        element.classList.add(this.alreadyVisitedMark);
        element.classList.add(...hack[0]);

        if (hack[1]) {
          element.style.top = `${element.offsetTop}px`.replace('pxpx', 'px');
        }

        if (hack[2]) {
          element.style.left = `${element.offsetLeft}px`.replace('pxpx', 'px');
        }
      }
    );
  }

  /**
   * Freezes all targets.
   *
   * @returns void
   */
  protected freezeAllTargets(): void {
    this.targetStyles.forEach(
      (value: any, key: string) => {
        this.freeze(key, value);
      }
    );
  }

  /**
   * Returns the targets for the given selector.
   *
   * @param selector string
   * @param ignoreAlreadyAffected boolean
   * @returns NodeListOf<HTMLElement>
   */
  protected getTargets(selector: string, ignoreAlreadyAffected: boolean): NodeListOf<HTMLElement> {
    const finalSelector: string = `${selector}${ignoreAlreadyAffected ? `:not(.${this.alreadyVisitedMark})` : ''}`;
    const targets: NodeListOf<HTMLElement> = this.windowWrapper.getDocument().querySelectorAll(finalSelector);

    return targets;
  }

  /**
   * Reset the scroll position.
   *
   * @returns void
   */
  protected resetSrollPosition(): void {
    this.windowWrapper.getDocument().getElementById('app-container').scrollTo(0, 0);
  }

  /**
   * Set up the `targetStyles` attribute.
   *
   * @returns void
   */
  protected setupTargetStyles(): void {
    this.targetStyles = new Map();

    this.targetStyles.set('#app-container', [['pd-hack-overwrite-app-container'], false, false]);

    this.targetStyles.set(
      'bolt-header',
      [[ 'pd-hack-sticky-container', 'pd-hack-solid', 'pd-hack-deep-level-1'], true, true ]
    );

    this.targetStyles.set('bolt-header .dropdown-menu.user', [[ 'pd-hack-overwrite-user-menu'], false, false ]);
    this.targetStyles.set('bolt-footer', [[ 'pd-hack-sticky-container', 'pd-hack-deep-level-6'], false, true ]);

    this.targetStyles.set(
      '#project-list',
      [[ 'pd-hack-sticky-container', 'pd-hack-solid', 'pd-hack-deep-level-2'], true, true ]
    );

    this.targetStyles.set(
      '#project-data-header',
      [[ 'pd-hack-sticky-container', 'pd-hack-solid', 'pd-hack-deep-level-3'], true, true ]
    );

    this.targetStyles.set('#project-data-content', [[ 'pd-hack-container'], false, false ]);

    this.targetStyles.set(
      '#project-data-footer',
      [[ 'pd-hack-sticky-container', 'pd-hack-deep-level-6'], false, true ]
    );

    this.targetStyles.set('#project-data-content .field.freeze-xy', [[ 'pd-hack-deep-level-4'], true, true ]);
    this.targetStyles.set('#project-data-content .field.freeze-y', [[ 'pd-hack-deep-level-5'], true, false ]);
  }

  /**
   * Turns off the `isFirstTime` attribute.
   *
   * @returns void
   */
  protected turnOffIsFirstTime(): void {
    this.isFirstTime = true;
  }

  /**
   * Turns on the `isFirstTime` attribute.
   *
   * @returns void
   */
  protected turnOnIsFirstTime(): void {
    this.isFirstTime = true;
  }

  /**
   * Unfreezes the element with the given selector.
   *
   * @param selector string
   * @param cssClasses string
   * @param calculateTop boolean
   * @param calculateLeft boolean
   * @returns void
   */
  protected unfreeze(selector: string, hack: any[]): void {
    const elements: NodeListOf<HTMLElement> = this.getTargets(selector, false);

    elements.forEach(
      (element: HTMLElement) => {
        element.classList.remove(this.alreadyVisitedMark);
        element.classList.remove(...hack[0]);

        if (_isObject(element.style)) {
          if (hack[1]) {
            element.style.top = null;
          }

          if (hack[2]) {
            element.style.left = null;
          }
        }
      }
    );
  }

  /**
   * Unfreezes all targets.
   *
   * @returns void
   */
  protected unfreezeAllTargets(): void {
    this.targetStyles.forEach(
      (value: any, key: string) => {
        this.unfreeze(key, value);
      }
    );
  }

  /**
   * Unsubscribes from screen resize, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeScreenResize(): void {
    if (_isObject(this.screenResizeListener)) {
      this.screenResizeListener.unsubscribe();
    }
  }
}
