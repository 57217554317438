import { Component, ElementRef, ViewChild } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { capitalize as _capitalize, isObject as _isObject, isUndefined as _isUndefined, find as _find } from 'lodash';

import {
  BoltMetadataExportLocaleSelectorComponent
} from '../bolt-metadata-export-locale-selector/bolt-metadata-export-locale-selector.component';

import {
  BoltMetadataExportMultipleRecordsSearchComponent
} from '../bolt-metadata-export-multiple-records-search/bolt-metadata-export-multiple-records-search.component';

import { Element } from 'app/modules/search/models/element/element.model';
import { ExportCandidate } from '../../models/export-candidate/export-candidate.model';
import { ExportFormatEnum } from '../../models/export-format.enum';
import { GroupCriteriaEnum } from '../../models/group-criteria.enum';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServiceResponseSingleInterface } from 'app/modules/common/services/storm-service-response-single';
import { TitleService, TitleServiceFetchTitleMetadataParamsInterface } from 'app/modules/title/services/title.service';
import { TitleType, Title } from 'app/modules/title/models/title.model';


@Component({
  selector: 'bolt-metadata-export-multiple-records',
  template: require('./bolt-metadata-export-multiple-records.html'),
  styles: [require('./bolt-metadata-export-multiple-records.scss')]
})
export class BoltMetadataExportMultipleRecordsComponent {
  readonly consolidatedBundledHelp: string = 'This option requires the same account for all titles in the list.';
  readonly exportFormats: typeof ExportFormatEnum = ExportFormatEnum;
  readonly groupsCriteria: typeof GroupCriteriaEnum = GroupCriteriaEnum;

  allCandidates: ExportCandidate[];
  exportFormat: ExportFormatEnum;
  groupCriteria: GroupCriteriaEnum | undefined;
  isMainCheckSelected: boolean;
  pager: MemoryPager;
  selectedElement: Element | undefined;
  selectedLocale: Locale | undefined;
  selectionCounter: number;
  shouldOpenExportPopup: boolean;
  titleChecks: Map<string, boolean>;

  secondaryCandidates: Map<string, [ExportCandidate[], boolean]>;

  @ViewChild('add', { static: true })
  protected addButton: ElementRef;

  @ViewChild('localeSelector', { static: true })
  protected localeSelectorInput: BoltMetadataExportLocaleSelectorComponent;

  @ViewChild('titleSearch', { static: true })
  protected titleSearchInput: BoltMetadataExportMultipleRecordsSearchComponent;

  protected uniqueAccounts: Map<number, number>;

  constructor(
    protected appConfig: AppConfigProvider,
    public layoutHandler: LayoutHandlerService,
    public masterdataManager: MasterDataManager,
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) {
    this.allCandidates = [];
    this.exportFormat = undefined;
    this.groupCriteria = undefined;
    this.isMainCheckSelected = false;
    this.pager = new MemoryPager(this.appConfig.get('ux.dataTables.pageSize'));
    this.secondaryCandidates = new Map();
    this.selectedElement = undefined;
    this.selectedLocale = undefined;
    this.shouldOpenExportPopup = false;
    this.titleChecks = new Map();
    this.uniqueAccounts = new Map();

    this.layoutHandler.reset();
    this.resetSelectionCounter();
  }

  get mainCandidates(): ExportCandidate[] {
    return this.pager.records;
  }

  /**
   * Adds a record into the table.
   *
   * @returns void
   */
  addRecord(): void {
    const targetKey: string = ExportCandidate.getKeyFor(this.selectedElement, this.selectedLocale);
    const candidates: ExportCandidate[] = this.pager.records;

    const isKeyRepeated: boolean = candidates.some(
      (currentCandidate: ExportCandidate) => currentCandidate.getKey() === targetKey
    );

    if (isKeyRepeated) {
      this.notificationService.handleError('Failed trying to add the item.', new HttpError('The item already exists in the list.'));
    } else {
      const errorReason: string = 'Failed retrieving title metadata.';

      const mainRequest: TitleServiceFetchTitleMetadataParamsInterface = {
        productType: this.selectedElement.type.isFeature() ? TitleType.feature : TitleType.season,
        productId: this.selectedElement.id
      };

      this.layoutHandler.changeToFetching();

      this.titleService.fetchProduct(mainRequest, true).subscribe(
        (mainResponse: StormServiceResponseSingleInterface) => {
          const mainTitle: Title = mainResponse.item;
          const mainCandidate: ExportCandidate = new ExportCandidate(mainTitle, this.selectedLocale);

          candidates.push(mainCandidate);

          if (mainTitle.isSeason()) {
            const secondaryRequest: any = {
              titleType: TitleType.episode,
              seasonId: mainTitle.id,
              locale: '*_*_*_*',
              _size: 5000
            };

            this.titleService.filterTitles(secondaryRequest).subscribe(
              (secondaryResponse: StormServiceResponseCollection) => {
                const secondaryCandidates: ExportCandidate[] = secondaryResponse.collection.map(
                  (secondaryTitle: Title) => new ExportCandidate(secondaryTitle, this.selectedLocale)
                );

                this.secondaryCandidates.set(mainCandidate.getKey(), [secondaryCandidates, false]);
                this.storeCandidates(candidates, mainCandidate);
              },
              (secondaryError: HttpError) => {
                this.notificationService.handleError(errorReason, secondaryError);
              }
            );
          } else {
            this.storeCandidates(candidates, mainCandidate);
          }
        },
        (error: HttpError) => {
          this.notificationService.handleError(errorReason, error);
          this.layoutHandler.changeToReading();
        }
      );
    }
  }

  /**
   * Indicates if it has to disable the add button.
   *
   * @param locale Locale
   * @returns boolean
   */
  hasBlockAddRecord(): boolean {
    const showAddRecord: boolean =
      !this.layoutHandler.isReading() ||
      _isUndefined(this.selectedElement) ||
      _isUndefined(this.selectedLocale) ||
      !this.selectedLocale.isComplete();

    return showAddRecord;
  }

  /**
   * Indicates if it has to disabled the Consolidated and Bundled options.
   *
   * @returns boolean
   */
  hasBlockConsolidatedAndBundled(): boolean {
    const hasIt: boolean = this.uniqueAccounts.size > 1;
    return hasIt;
  }

  /**
   * Indicates if it has to disable the export button.
   *
   * @param locale Locale
   * @returns boolean
   */
  hasBlockExportRecords(): boolean {
    const hasIt: boolean = !this.layoutHandler.isReading() || !this.pager.hasRecords();
    return hasIt;
  }

  /**
   * Indicates if it has to disable the remove-all button.
   *
   * @returns boolean
   */
  hasBlockRemoveAll(): boolean  {
    const hastIt: boolean = !this.layoutHandler.isReading() || !this.pager.hasRecords();
    return hastIt;
  }

  /**
   * Indicates if it has to disable the remove selection button.
   *
   * @returns void
   */
  hasBlockRemoveSelection(): boolean  {
    const hastIt: boolean =
      !this.layoutHandler.isReading() ||
      (this.pager.getCurrentPage().length === 0) ||
      (this.selectionCounter === 0);

    return hastIt;
  }

  /**
   * Indicates if it has to disable the selection checkboxes.
   *
   * @returns void
   */
  hasBlockSelectionChecks(): boolean  {
    const hastIt: boolean =
      !this.layoutHandler.isReading() ||
      (this.pager.getCurrentPage().length === 0);

    return hastIt;
  }

  /**
   * Handles the close event of Export popup.
   *
   * @returns void
   */
  handleExportPopupClose(): void {
    this.shouldOpenExportPopup = false;
  }

  /**
   * Loads the given page.
   *
   * @param page number
   * @returns void
   */
  loadPage(page: number): void {
    this.resetSelectionCounter();
    this.pager.setPageNumber(page - 1);
    this.synchronizeChecks();
  }

  /**
   * Opens the "Export" popup.
   *
   * @param format ExportFormatEnum
   * @param group GroupCriteriaEnum
   * @returns void
   */
  openExport(format: ExportFormatEnum, group?: GroupCriteriaEnum): void {
    const canOpen: boolean = _isUndefined(group) || !this.hasBlockConsolidatedAndBundled();

    if (canOpen) {
      this.updateAllExportCandidates();

      this.exportFormat = format;
      this.groupCriteria = group;
      this.shouldOpenExportPopup = true;
    }
  }

  /**
   * Removes all candidates.
   *
   * @returns void
   */
  removeAll(): void {
    this.pager.reset();
    this.secondaryCandidates.clear();
    this.uniqueAccounts.clear();

    this.synchronizeChecks();
    this.resetSelectionCounter();
  }

  /**
   * Removes the selected candidates.
   *
   * @returns void
   */
  removeSelection(): void {
    const newCandidates: ExportCandidate[] = [];
    const secondaryRemoval: string[] = [];

    this.pager.records.forEach(
      (candidate: ExportCandidate) => {
        const shouldPreserve: boolean = !this.titleChecks.has(candidate.getKey()) || !this.titleChecks.get(candidate.getKey());

        if (shouldPreserve) {
          newCandidates.push(candidate);
        } else {
          this.decreaseAccountReference(<number>(candidate.getLocale().account[0]));

          if (candidate.getTitle().isSeason()) {
            secondaryRemoval.push(candidate.getKey());
          }
        }
      }
    );

    this.pager.setRecords(newCandidates);
    this.synchronizeChecks();
    this.resetSelectionCounter();

    secondaryRemoval.forEach(
      (key: string) => {
        this.secondaryCandidates.delete(key);
      }
    );

    this.discoverMainCheckValue();
  }

  /**
   * Toggles the main check.
   *
   * @returns void
   */
  toggleMainCheck(): void {
    this.isMainCheckSelected = !this.isMainCheckSelected;
    this.selectionCounter = this.isMainCheckSelected ? this.titleChecks.size : 0;

    this.titleChecks.forEach(
      (value: boolean, key: string) => {
        this.titleChecks.set(key, this.isMainCheckSelected);
      }
    );
  }

  /**
   * Toggles the title check for the given key.
   *
   * @param key string
   * @returns void
   */
  toggleTitleCheck(key: string): void {
    const currentValue: boolean = !this.titleChecks.get(key);

    this.titleChecks.set(key, currentValue);

    if (currentValue) {
      this.discoverMainCheckValue();
      this.selectionCounter++;
    } else {
      this.isMainCheckSelected = false;
      this.selectionCounter--;
    }
  }

  /**
   * Toggles the expansion of the secondary candidate for the given key.
   *
   * @param key string
   * @returns void
   */
  toggleSecondaryCandidateExpansionFor(key: string): void {
    this.secondaryCandidates.get(key)[1] = !this.secondaryCandidates.get(key)[1];
  }

  /**
   * Stores the given element.
   *
   * @param element Element
   * @returns void
   */
  storeElement(element: Element): void {
    this.selectedElement = element;

    if (_isObject(this.selectedElement)) {
      if (_isObject(this.selectedLocale) && this.selectedLocale.isComplete()) {
        setTimeout(
          () => {
            this.addButton.nativeElement.focus();
          },
          200
        );
      } else {
        this.localeSelectorInput.focus();
      }
    }
  }

  /**
   * Stores the given locale.
   *
   * @param locale Locale
   * @returns void
   */
  storeLocale(locale: Locale): void {
    this.selectedLocale = locale;
  }

  /**
   * Decreases the given account reference.
   *
   * @param account number
   * @returns void
   */
  protected decreaseAccountReference(account: number): void {
    const counter: number = this.uniqueAccounts.get(account) - 1;

    if (counter === 0) {
      this.uniqueAccounts.delete(account);
    } else {
      this.uniqueAccounts.set(account, counter);
    }
  }

  /**
   * Discovers and set the value of the main check by checking the secondary checks.
   *
   * @return void
   */
  protected discoverMainCheckValue(): void {
    this.isMainCheckSelected = Array.from(this.titleChecks.values()).every((isChecked: boolean) => isChecked);
  }

  /**
   * Increases the given account reference.
   *
   * @param account number
   * @returns void
   */
  protected increaseAccountReference(account: number): void {
    const counter: number = this.uniqueAccounts.has(account) ? this.uniqueAccounts.get(account) + 1 : 1;
    this.uniqueAccounts.set(account, counter);
  }

  /**
   * Reset the selection counter.
   *
   * @return void
   */
  protected resetSelectionCounter(): void {
    this.selectionCounter = 0;
  }

  /**
   * Synchronizes the checks with the current page.
   *
   * @return void
   */
  protected synchronizeChecks(): void {
    const candidatesPage: ExportCandidate[] = this.pager.getCurrentPage();
    const checksKeys: string[] = Array.from(this.titleChecks.keys());

    // Removes the checks not related to the current page.
    checksKeys.forEach(
      (checkKey: string) => {
        const shouldRemoveCheck: boolean = !candidatesPage.some((candidate: ExportCandidate) => candidate.getKey() === checkKey);

        if (shouldRemoveCheck) {
          this.titleChecks.delete(checkKey);
        }
      }
    );

    // Adds the missing checks from the current page.
    candidatesPage.forEach(
      (candidate: ExportCandidate) => {
        if (!this.titleChecks.has(candidate.getKey())) {
          this.titleChecks.set(candidate.getKey(), false);
        }
      }
    );

    this.discoverMainCheckValue();
  }

  /**
   * Stores the given candidates.
   *
   * @param candidates ExportCandidate[]
   * @param pageJumpCandidate ExportCandidate
   * @returns void
   */
  protected storeCandidates(candidates: ExportCandidate[], pageJumpCandidate: ExportCandidate): void {
    this.pager.setRecords(candidates);

    this.pager.setPageNumberFor(
      pageJumpCandidate,
      (candidateA: ExportCandidate, candidateB: ExportCandidate) => candidateA.getKey() === candidateB.getKey()
    );

    this.increaseAccountReference(<number>(pageJumpCandidate.getLocale().account[0]));
    this.synchronizeChecks();

    this.selectedElement = undefined;

    this.layoutHandler.changeToReading();
    this.titleSearchInput.reset();
    this.titleSearchInput.focus();
  }

  /**
   * Updates the list of all candidates (main and secondary ones).
   *
   * @returns void
   */
  protected updateAllExportCandidates(): void {
    // Only features can be exported.
    const candidates: ExportCandidate[] = this.mainCandidates.filter(
      (mainCandidate: ExportCandidate) => mainCandidate.getTitle().isFeature()
    );

    // We need all episodes to be exported (no seasons).
    this.secondaryCandidates.forEach(
      (entry: [ExportCandidate[], boolean]) => {
        candidates.push(...entry[0]);
      }
    );

    this.allCandidates = candidates;
  }
}
