import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { debounceTime } from 'rxjs/operators';

import { TalentInterface } from '../../models/talent.model';
import { TalentManager } from '../../helpers/talent-manager/talent-manager.helper';
import { TalentMetadata } from '../../models/talent-metadata.model';
import { TalentMetadataManagerActions } from '../bolt-talent-metadata-manager/bolt-talent-metadata-manager.component';


export interface BoltTalentMetadataFiltersInterface {
  filter: string[];
}

@Component({
  selector: 'bolt-talent-metadata-filters',
  template: require('./bolt-talent-metadata-filters.html'),
  styles: [require('./bolt-talent-metadata-filters.scss')],
  encapsulation: ViewEncapsulation.None
})
export class BoltTalentMetadataFiltersComponent implements OnInit {
  @Input('BoltTalentMetadataFiltersTalent') talent: TalentInterface;
  @Output('BoltTalentMetadataFiltersFiltersUpdated') filtersUpdated: EventEmitter<BoltTalentMetadataFiltersInterface> =
    new EventEmitter<BoltTalentMetadataFiltersInterface>();

  public form: FormGroup;
  public filter: string;

  constructor(
    protected formBuilder: FormBuilder,
    protected appConfig: AppConfigProvider,
    protected talentManager: TalentManager,
    protected route: ActivatedRoute
  ) {

    this.form = formBuilder.group({
      filter: [],
    });

  }

  ngOnInit() {
    this.form.valueChanges.pipe(
      debounceTime(200)
    )
    .subscribe(
      val => {
        this.filtersUpdated.emit(<BoltTalentMetadataFiltersInterface>{
          filter: val.filter ? val.filter.trim().split(' ') : []
        });
      }
    );
  }

  /**
   * Resets the current filter.
   *
   * @returns void
   */
  resetFilter(): void {
    (<FormControl>this.form.get('filter')).setValue('');
  }

  /**
   * Starts the add talent process.
   *
   * @return void
   */
  addTalentMetadata(): void {
    this.talentManager.manageTalentMetadata(
      new TalentMetadata(),
      TalentMetadataManagerActions.CREATE,
      this.talent,
      [
        // 'prefixId', @TODO localizing this field not yet supported by the API, enable when supported
        'middleName',
        // 'suffixId', @TODO localizing this field not yet supported by the API, enable when supported
        // 'appleId', @TODO localizing this field not yet supported by the API, enable when supported
        // 'imdbId' @TODO localizing this field not yet supported by the API, enable when supported
      ]
    );
  }
}
