import { isEmpty as _isEmpty } from 'lodash';
import { Country, Language } from '@bolt/ui-shared/master-data';

import { Episode } from './episode/episode.model';
import { Item } from '../item.model';
import { TitleType } from 'app/modules/title/models/title.model';


export class Season extends Item {
  protected _seriesId: number;
  protected _episodes: Episode[];
  protected _numberOfEpisodes: number;

  constructor(
    language: Language,
    territory: Country,
    id: number,
    legalTitle: string,
    seriesId: number,
    numberOfEpisodes: number
  ) {
    super();
    this.initialize(language, territory, id, legalTitle, seriesId, numberOfEpisodes);
  }

  get episodes(): Episode[] {
    return this._episodes;
  }

  get numberOfEpisodes(): number {
    return this._numberOfEpisodes;
  }

  get seriesId(): number {
    return this._seriesId;
  }

  asEndpointData(): object {
    const data: object = super.asEndpointData();

    data['seriesId'] = this.seriesId;
    data['seasonId'] = this.id;
    data['episodeIds'] = this.hasEpisodes() ? this.episodes.map(episode => episode.asEndpointData()) : new Array();

    return data;
  }

  /**
   * Indicates if it has episodes.
   *
   * @returns boolean
   */
  hasEpisodes(): boolean {
    return !_isEmpty(this._episodes);
  }

  /**
   * Set the episodes.
   *
   * @param episodesData Episode[]
   * @returns void
   */
  setEpisodes(episodesData: Episode[]): void {
    this._episodes = [...episodesData];
  }

  /**
   * Set the number of episodes.
   *
   * @param numberOfEpisodes number
   * @returns void
   */
  setNumberOfEpisodes(numberOfEpisodes: number): void {
    this._numberOfEpisodes = numberOfEpisodes;
  }

  /**
   * Initializes the instance.
   *
   * @param language Language
   * @param territory Territory
   * @param id number
   * @param legalTitle string
   * @param seriesId number
   * @param numberOfEpisodes number
   * @returns void
   */
  protected initialize(
    language: Language,
    territory: Country,
    id: number,
    legalTitle: string,
    seriesId: number,
    numberOfEpisodes: number
  ): void {
    this.setType(TitleType.season);
    this.setLanguage(language);
    this.setTerritory(territory);
    this.setId(id);
    this.setLegalTitle(legalTitle);
    this.setSeriesId(seriesId);
    this.setNumberOfEpisodes(numberOfEpisodes);
  }

  /**
   * Set the series ID.
   *
   * @param seriesId number
   * @returns void
   */
  protected setSeriesId(seriesId: number): void {
    this._seriesId = seriesId;
  }
}
