import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { isObject as _isObject, isUndefined as _isUndefined, isNumber as _isNumber, isArray as _isArray } from 'lodash';

import { Character } from '../../models/character.model';
import { CharacterMetadata } from '../../models/character-metadata.model';
import { CharacterMetadataManager } from '../../helpers/character-metadata-manager/character-metadata-manager';
import { CharacterService } from '../../services/character.service';
import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FormFactoryService } from '../../services/form-factory/form-factory.service';
import { LayoutService as FormLayoutService } from 'app/shared/services/form/layout/layout.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';
import { LocalizedDataForm } from '../../models/form/localized-data/localized-data-form.model';


@Component({
  selector: 'bolt-character-metadata-manager',
  template: require('./bolt-character-metadata-manager.html'),
  styles: [require('./bolt-character-metadata-manager.scss')],
})

export class BoltCharacterMetadataManagerComponent implements OnChanges {
  @Input() character: CharacterMetadata;
  @Input() isOriginal: boolean;
  @Input() show: boolean;
  @Output() closeEvent: EventEmitter<undefined>;
  @Output() saveEvent: EventEmitter<CharacterMetadata>;

  @ViewChild('confirmOverrideLocalizationModal') protected overrideLocalizationModal: ModalDirective;
  @ViewChild('confirmCancelModal') protected cancelModal: ModalDirective;

  protected currentConfirmLocalizationModal: NgbModalRef;
  protected form: OriginalDataForm | LocalizedDataForm;
  protected headerTitle: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected characterManager: CharacterMetadataManager,
    protected characterService: CharacterService,
    protected formConfig: FormConfigService,
    protected formLayout: FormLayoutService,
    protected formService: FormFactoryService,
    protected listLayoutProvider: ListLayoutProvider,
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show)) {
      this.show = changes.show.currentValue;

      if (this.show) {
        this.createForm();
      } else {
        this.destroyForm();
      }
    }
  }

  get languages(): SelectionItem[] {
    return this.listLayoutProvider.getLanguages(true);
  }

  get maxValuesAsString(): number {
    return this.appConfig.get('ux.multiSelect.maxValuesAsString');
  }

  get scrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Checks if the form was touched and close it.
   *
   * @returns void
   */
  protected closeAsideContent(): void {
    if (this.form.dirty) {
      this.currentConfirmLocalizationModal = this.modalService.open(this.cancelModal);
    } else {
      this.emitCloseEvent();
    }
  }

  /**
   * Closes the cancel confirmation modal and emit the close event.
   *
   * @returns void
   */
  protected closeCancelConfirmationModal(): void {
    this.currentConfirmLocalizationModal.close();
    this.emitCloseEvent();
  }

  /**
   * Creates the character metadata form.
   *
   * @returns void
   */
  protected createForm(): void {
    const defaultValues: any = new Object();
    let language: number;

    if (_isObject(this.character)) {
      if (_isArray(this.character.language)) {
        [language] = this.character.language;
      } else if (_isNumber(this.character.language)) {
        language = this.character.language;
      } else if (_isObject(this.character.language)) {
        language = this.character.language.id;
      }

      defaultValues.name = this.character.name;
      defaultValues.language = language;
    }

    if (this.isEditing()) {
      defaultValues.useDomestic = this.character.useDomestic;
      defaultValues.notes = this.character.notes;

      // BOLTM-2919 TODO remove the useDomestic condition and use character name and phonetic values when fallback comes from API.
      if (defaultValues.useDomestic) {
        defaultValues.name = this.characterManager.character.name;
        defaultValues.phonetic = this.characterManager.character.phonetic;
      } else {
        defaultValues.name = this.character.name;
        defaultValues.phonetic = this.character.phonetic;
      }

      this.form = this.formService.buildLocalizedDataForm(defaultValues);
      this.headerTitle = 'Edit Localized Character';

      if (this.form.getUseDomesticField().value) {
        this.form.getNameField().disable();
        this.form.getPhoneticField().disable();
      }
    } else {
      if (this.isOriginal) {
        defaultValues.originalLanguageId = language;
        this.form = this.formService.buildOriginalDataForm(defaultValues);
        this.headerTitle = 'Add Original Character';
      } else {
        this.form = this.formService.buildLocalizedDataForm(defaultValues);
        this.headerTitle = 'Add Localized Character';
      }
    }
  }

  /**
   * Destroys the current form.
   *
   * @returns void
   */
  protected destroyForm(): void {
    this.form = undefined;
  }

  /**
   * Emits the close event.
   *
   * @returns void
   */
  protected emitCloseEvent(): void {
    this.closeEvent.emit();
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength: number = this.formConfig.get(`cat.fields.${field}.maxLength`);
    return maxLength;
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.form.disabled;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const isIt: boolean = (this.form.invalid || this.form.disabled);
    return isIt;
  }

  /**
   * Indicates if it has to display the content.
   *
   * @returns boolean
   */
  protected hasDisplay(): boolean {
    const hasIt: boolean = (_isObject(this.form) && this.show);
    return hasIt;
  }

  /**
   * Indicates if it has to display the languages multiselect.
   *
   * @returns boolean
   */
  protected hasDisplayLanguageSelect(): boolean {
    const hasIt: boolean = this.listLayoutProvider.hasLanguages() && this.isCreating();
    return hasIt;
  }

  /**
   * Indicates if it is creating a localization.
   *
   * @returns boolean
   */
  protected isCreating(): boolean {
    const isIt: boolean = !this.isEditing();
    return isIt;
  }

  /**
   * Indicates if it is editing a given localization.
   *
   * @returns boolean
   */
  protected isEditing(): boolean {
    const isIt: boolean = _isObject(this.character) && _isNumber(this.character.id);
    return isIt;
  }

  /**
   * Indicates if it has an original character.
   *
   * @returns boolean
   */
  protected isOriginalCharacter(): boolean {
    return this.isOriginal;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.headerTitle = undefined;
    this.isOriginal = true;
    this.saveEvent = new EventEmitter();
    this.show = false;
  }

  /**
   * Indicates if the current character has a localization with the given language id.
   *
   * @param languageId number
   * @returns boolean
   */
  protected characterHasLocalization(languageId: number): boolean {
    let hasIt: boolean = false;

    if (!_isUndefined(this.characterManager.character.localizations)) {
      hasIt = this.characterManager.character.localizations.some(
        (localization: CharacterMetadata) => localization.language === languageId
      );
    }

    return hasIt;
  }

  /**
   * Makes the correct save process.
   *
   * @returns void
   */
  protected save(): void {
    if (this.isOriginalCharacter()) {
      this.saveOriginalMetadata();
    } else {
      const languageId: number = (<LocalizedDataForm>this.form).getLanguageField().value;

      if (this.isCreating() && this.characterHasLocalization(languageId)) {
        this.currentConfirmLocalizationModal = this.modalService.open(this.overrideLocalizationModal);
      } else {
        this.saveLocalizedMetadata();
      }
    }
  }

  /**
   * Saves the localized character metadata.
   *
   * @returns void
   */
  protected saveLocalizedMetadata(): void {
    const localizedForm: LocalizedDataForm = <LocalizedDataForm>this.form;
    const languageId: number = localizedForm.getLanguageField().value;
    const locale: string = `${this.listLayoutProvider.getLanguageById(languageId).localeLanguage}_*_*_*`;
    const useDomestic: boolean = localizedForm.getUseDomesticField().value;
    const originalCharacter: Character = this.characterManager.character;

    const params: any = {
      characterId: originalCharacter.id,
    };

    // BOLTM-2919 TODO remove the useDomestic condition and use localized name and phonetic when fallback comes from API.
    const data: any = {
      name: useDomestic ? originalCharacter.name : localizedForm.getNameField().value,
      phonetic: useDomestic ? originalCharacter.phonetic : localizedForm.getPhoneticField().value,
      notes: localizedForm.getNotesField().value,
      useDomestic: useDomestic
    };

    localizedForm.disable();
    this.characterService.setCharacterMetadata(
      params,
      locale,
      new CharacterMetadata(data)
    ).subscribe(
      (response: StormServiceResponseSingle) => {
        this.notificationService.handleNotice('The localized character was saved successfully.');
        this.saveEvent.emit(response.item);
        this.form.enable();
        this.emitCloseEvent();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('There was an error trying to save the localized character.', error);
        this.form.enable();
      }
    );
  }

  /**
   * Saves the character original data.
   *
   * @returns void
   */
  protected saveOriginalMetadata(): void {
    const originalForm: OriginalDataForm = <OriginalDataForm>this.form;

    const data: any = {
      name: originalForm.getOriginalNameField().value,
      originalLanguageId: originalForm.getOriginalLanguageField().value,
      notes: originalForm.getNotesField().value,
      phonetic: originalForm.getPhoneticField().value,
      requiredLocalizations: originalForm.getRequiredLocalizationsField().value
    };

    this.form.disable();

    this.characterManager.createCharacter(
      data,
      (response: CharacterMetadata) => {
        this.saveEvent.emit(response);
        this.form.enable();
        this.notificationService.handleNotice(`The original character was saved successfully.`);
        this.emitCloseEvent();
      },
      (error: ErrorHelper) => {
        this.form.enable();

        this.notificationService.handleError(
          `Failed trying to save the original character.`,
          error,
          notificationsContainer.character.details.key
        );
      }
    );
  }

  /**
   * Closes the confirmation override modal and calls the save process.
   *
   * @returns void
   */
  protected saveOverrideMetadata(): void {
    this.currentConfirmLocalizationModal.close();
    this.saveLocalizedMetadata();
  }

  /**
   * Updates the dependencies for use-domestic field in form.
   *
   * @returns void
   */
  protected updateUseDomesticDependencies(): void {
    const localizedForm: LocalizedDataForm = <LocalizedDataForm>this.form;

    const field: AbstractControl = localizedForm.getUseDomesticField();

    if (field.value) {
      localizedForm.getNameField().disable();
      localizedForm.getPhoneticField().disable();
    } else {
      localizedForm.getNameField().enable();
      localizedForm.getPhoneticField().enable();
    }
  }
}
