import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from '../common/common.module';
import { BoltCpmConfigurationsComponent } from './components/bolt-cpm-configurations/bolt-cpm-configurations.component';

import {
  BoltCpmConfigurationsDeletionPanelComponent
} from './components/bolt-cpm-configurations-deletion-panel/bolt-cpm-configurations-deletion-panel.component';

import {
  BoltCpmConfigurationsFiltersComponent
} from './components/bolt-cpm-configurations-filters/bolt-cpm-configurations-filters.component';

import {
  BoltCpmConfigurationsHandlerPanelComponent
} from './components/bolt-cpm-configurations-handler-panel/bolt-cpm-configurations-handler-panel.component';

import { BoltCpmConfigurationsListComponent } from './components/bolt-cpm-configurations-list/bolt-cpm-configurations-list.component';

import {
  BoltCpmConfigurationsMappingComponent
} from './components/bolt-cpm-configurations-mapping/bolt-cpm-configurations-mapping.component';

import {
  BoltCpmConfigurationsMappingFiltersComponent
} from './components/bolt-cpm-configurations-mapping-filters/bolt-cpm-configurations-mapping-filters.component';

import {
  BoltCpmConfigurationsMappingJsonComponent
} from './components/bolt-cpm-configurations-mapping-json/bolt-cpm-configurations-mapping-json.component';

import {
  BoltCpmConfigurationsMappingTableComponent
} from './components/bolt-cpm-configurations-mapping-table/bolt-cpm-configurations-mapping-table.component';

import { BoltCpmEventsComponent } from './components/bolt-cpm-events/bolt-cpm-events.component';
import { BoltCpmEventsDetailsComponent } from './components/bolt-cpm-events-details-panel/bolt-cpm-events-details-panel.component';
import { BoltCpmEventsFiltersComponent } from './components/bolt-cpm-events-filters/bolt-cpm-events-filters.component';
import { BoltCpmEventsListComponent } from './components/bolt-cpm-events-list/bolt-cpm-events-list.component';
import { BoltCpmEventsSummaryComponent } from './components/bolt-cpm-events-summary/bolt-cpm-events-summary.component';

import {
  BoltCpmEventsSummaryCountersComponent
} from './components/bolt-cpm-events-summary-counter/bolt-cpm-events-summary-counter.component';

import { BoltCpmHealthComponent } from './components/bolt-cpm-health/bolt-cpm-health.component';

import {
  BoltCpmHealthConnectionContainerComponent
} from './components/bolt-cpm-health-connection-container/bolt-cpm-health-connection-container.component';

import {
  BoltCpmHealthConnectionDetailsComponent
} from './components/bolt-cpm-health-connection-details/bolt-cpm-health-connection-details.component';

import { ConfigurationService } from './services/configuration/configuration.service';
import { EventService } from './services/event/event.service';
import { HealthService } from './services/health/health.service';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule
  ],
  declarations: [
    BoltCpmConfigurationsComponent,
    BoltCpmConfigurationsDeletionPanelComponent,
    BoltCpmConfigurationsHandlerPanelComponent,
    BoltCpmConfigurationsFiltersComponent,
    BoltCpmConfigurationsListComponent,
    BoltCpmConfigurationsMappingComponent,
    BoltCpmConfigurationsMappingFiltersComponent,
    BoltCpmConfigurationsMappingJsonComponent,
    BoltCpmConfigurationsMappingTableComponent,
    BoltCpmEventsComponent,
    BoltCpmEventsDetailsComponent,
    BoltCpmEventsFiltersComponent,
    BoltCpmEventsListComponent,
    BoltCpmEventsSummaryComponent,
    BoltCpmEventsSummaryCountersComponent,
    BoltCpmHealthComponent,
    BoltCpmHealthConnectionContainerComponent,
    BoltCpmHealthConnectionDetailsComponent
  ],
  exports: [ ],
  providers: [
    ConfigurationService,
    EventService,
    HealthService
  ]
})
export class CpmModule { }
