import { FormControl, Validators } from '@angular/forms';
import { indexOf as _indexOf, isObject as _isObject, isArray as _isArray, isBoolean as _isBoolean } from 'lodash';

import { Title } from '../../../title.model';
import { EpisodeAttributes } from '../../../title/episode/episode-attributes.enum';
import { FeatureAttributes } from '../../../title/feature/feature-attributes.enum';
import { TitleLocalizedForm } from '../title-localized-form.model';
import { Time } from 'app/modules/common/models/time.model';
import { Country } from '@bolt/ui-shared/master-data';

export class TerritoryLocalizationForm extends TitleLocalizedForm {
  protected includeKidsModeField: FormControl;
  protected excludeKidsModeField: FormControl;
  protected productPlacementDisclaimerField: FormControl;
  protected smokingDisclaimerField: FormControl;
  protected displayDubTalentField: FormControl;
  protected graphicContentDisclaimerField: FormControl;
  protected pseDisclaimerField: FormControl;
  protected heritageDisclaimerField: FormControl;
  protected ratingIdField: FormControl;
  protected ratingSystemReasonIdField: FormControl;
  protected ratingReasonField: FormControl;
  protected runtimeSecondsField: FormControl;
  protected ratingFilingNumberKmrbField: FormControl;
  protected onairPlatformKccField: FormControl;
  protected onairDateKccField: FormControl;
  protected firstAirReleaseDateField: FormControl;
  protected firstDigitalReleaseField: FormControl;
  protected firstPhysicalReleaseField: FormControl;
  protected localAirRunningOrderField: FormControl;
  protected theatricalReleaseDateField: FormControl;
  protected genreIdField: FormControl;
  protected includeKidsModeOverrideField: FormControl;
  protected excludeKidsModeOverrideField: FormControl;
  protected productPlacementDisclaimerOverrideField: FormControl;
  protected smokingDisclaimerOverrideField: FormControl;
  protected displayDubTalentOverrideField: FormControl;
  protected graphicContentDisclaimerOverrideField: FormControl;
  protected pseDisclaimerOverrideField: FormControl;
  protected heritageDisclaimerOverrideField: FormControl;
  protected homeEntRatingIdField: FormControl;
  protected homeEntRatingSystemReasonIdField: FormControl;
  protected homeEntRatingReasonField: FormControl;

  constructor(fieldSpecs: any, attributes: string[], title?: Title, splitFunctionalMetadataFlag?: boolean) {
    super(fieldSpecs, attributes, title, splitFunctionalMetadataFlag);
    this.setupFields();
  }

  /**
   * Returns the include kids mode field.
   *
   * @returns FormControl
   */
   getIncludeKidsModeField(): FormControl {
    return this.includeKidsModeField;
  }

  /**
   * Returns the exclude kids mode field.
   *
   * @returns FormControl
   */
   getExcludeKidsModeField(): FormControl {
    return this.excludeKidsModeField;
  }

  /**
   * Returns the product placement disclaimer field.
   *
   * @returns FormControl
   */
  getProductPlacementDisclaimerField(): FormControl {
    return this.productPlacementDisclaimerField;
  }

  /**
   * Returns the smoking disclaimer field.
   *
   * @returns FormControl
   */
  getSmokingDisclaimerField(): FormControl {
    return this.smokingDisclaimerField;
  }

  /**
   * Returns the display dub talent field.
   *
   * @returns FormControl
   */
  getDisplayDubTalentField(): FormControl {
    return this.displayDubTalentField;
  }

  /**
   * Returns the graphic content disclaimer field.
   *
   * @returns FormControl
   */
  getGraphicContentDisclaimerField(): FormControl {
    return this.graphicContentDisclaimerField;
  }

  /**
   * Returns the pse disclaimer field.
   *
   * @returns FormControl
   */
  getPseDisclaimerField(): FormControl {
    return this.pseDisclaimerField;
  }

  /**
   * Returns the heritage disclaimer field.
   *
   * @returns FormControl
   */
  getHeritageDisclaimerField(): FormControl {
    return this.heritageDisclaimerField;
  }

  /**
   * Returns the rating id field.
   *
   * @returns FormControl
   */
  getRatingIdField(): FormControl {
    return this.ratingIdField;
  }

  /**
   * Returns the rating system reason id field.
   *
   * @returns FormControl
   */
  getRatingSystemReasonIdField(): FormControl {
    return this.ratingSystemReasonIdField;
  }

  /**
   * Returns the rating reason field.
   *
   * @returns FormControl
   */
  getRatingReasonField(): FormControl {
    return this.ratingReasonField;
  }

  /**
   * Returns the runtime seconds field.
   *
   * @returns FormControl
   */
  getRuntimeSecondsField(): FormControl {
    return this.runtimeSecondsField;
  }

  /**
   * Returns the rating filing number kmrb field.
   *
   * @returns FormControl
   */
  getRatingFilingNumberKmrbField(): FormControl {
    return this.ratingFilingNumberKmrbField;
  }

  /**
   * Returns the onair platform kcc field.
   *
   * @returns FormControl
   */
  getOnairPlatformKccField(): FormControl {
    return this.onairPlatformKccField;
  }

  /**
   * Returns the onair date kcc field.
   *
   * @returns FormControl
   */
  getOnairDateKccField(): FormControl {
    return this.onairDateKccField;
  }

  /**
   * Returns the first air release date field.
   *
   * @returns FormControl
   */
  getFirstAirReleaseDateField(): FormControl {
    return this.firstAirReleaseDateField;
  }

  /**
   * Returns the first digital release field.
   *
   * @returns FormControl
   */
  getFirstDigitalReleaseField(): FormControl {
    return this.firstDigitalReleaseField;
  }

  /**
   * Returns the first physical release field.
   *
   * @returns FormControl
   */
  getFirstPhysicalReleaseField(): FormControl {
    return this.firstPhysicalReleaseField;
  }

  /**
   * Returns the local air running order field.
   *
   * @returns FormControl
   */
  getLocalAirRunningOrderField(): FormControl {
    return this.localAirRunningOrderField;
  }

  /**
   * Returns the theatrical release date field.
   *
   * @returns FormControl
   */
  getTheatricalReleaseDateField(): FormControl {
    return this.theatricalReleaseDateField;
  }

  /**
   * Returns the genre id field.
   *
   * @returns FormControl
   */
  getGenreIdField(): FormControl {
    return this.genreIdField;
  }

  getMetadataAttributesControls(): FormControl[] {
    const controls: FormControl[] = super.getMetadataAttributesControls();

    if (!this.isOnlySouthKorea()) {
      const ratingFilingIndex: number = _indexOf(controls, this.getRatingFilingNumberKmrbField());
      const onairPlatformIndex: number = _indexOf(controls, this.getOnairPlatformKccField());
      const onairDateIndex: number = _indexOf(controls, this.getOnairDateKccField());

      controls.splice(ratingFilingIndex, 1);
      controls.splice(onairPlatformIndex, 1);
      controls.splice(onairDateIndex, 1);
    }

    return controls;
  }

  /**
   * Returns the include kids mode override field.
   *
   * @returns FormControl
   */
   getIncludeKidsModeOverrideField(): FormControl {
    return this.includeKidsModeOverrideField;
  }

  /**
   * Returns the exclude kids mode override field.
   *
   * @returns FormControl
   */
  getExcludeKidsModeOverrideField(): FormControl {
    return this.excludeKidsModeOverrideField;
  }

  /**
   * Returns the product placement disclaimer override field.
   *
   * @returns FormControl
   */
  getProductPlacementDisclaimerOverrideField(): FormControl {
    return this.productPlacementDisclaimerOverrideField;
  }

  /**
   * Returns the smoking disclaimer override field.
   *
   * @returns FormControl
   */
  getSmokingDisclaimerOverrideField(): FormControl {
    return this.smokingDisclaimerOverrideField;
  }

  /**
   * Returns the display dub talent override field.
   *
   * @returns FormControl
   */
  getDisplayDubTalentOverrideField(): FormControl {
    return this.displayDubTalentOverrideField;
  }

  /**
   * Returns the graphic content disclaimer override field.
   *
   * @returns FormControl
   */
  getGraphicContentDisclaimerOverrideField(): FormControl {
    return this.graphicContentDisclaimerOverrideField;
  }

  /**
   * Returns the pse disclaimer override field.
   *
   * @returns FormControl
   */
  getPseDisclaimerOverrideField(): FormControl {
    return this.pseDisclaimerOverrideField;
  }

  /**
   * Returns the rating id field.
   *
   * @returns FormControl
   */
  getHomeEntRatingIdField(): FormControl {
    return this.homeEntRatingIdField;
  }

  /**
   * Returns the rating system reason id field.
   *
   * @returns FormControl
   */
  getHomeEntRatingSystemReasonIdField(): FormControl {
    return this.homeEntRatingSystemReasonIdField;
  }

  /**
   * Returns the rating reason field.
   *
   * @returns FormControl
   */
  getHomeEntRatingReasonField(): FormControl {
    return this.homeEntRatingReasonField;
  }

  /**
   * Returns the heritage disclaimer override field.
   *
   * @returns FormControl
   */
   getHeritageDisclaimerOverrideField(): FormControl {
    return this.heritageDisclaimerOverrideField;
  }

  toJson(): any {
    const data: any = super.toJson();

    if (_isObject(data[FeatureAttributes.runtimeSeconds])) {
      data[FeatureAttributes.runtimeSeconds] = new Time(this.getRuntimeSecondsField().value).toSeconds();
    }

    if (!this.isOnlySouthKorea()) {
      delete data[FeatureAttributes.ratingFilingNumberKmrb];
      delete data[FeatureAttributes.onairPlatformKcc];
      delete data[FeatureAttributes.onairDateKcc];
    }

    return data;
  }

  enable(): void {
    super.enable();
    this.languageField.disable();
  }

  /**
   * Indicates if the only one territory is South Korea
   *
   * @returns boolean
   */
  protected isOnlySouthKorea(): boolean {
    const isIt: boolean = _isArray(this.getTerritoryField().value) &&
      this.getTerritoryField().value.length === 1 &&
      Country.isSouthKorea(this.getTerritoryField().value[0]);

    return isIt;
  }

  protected setupFields(): void {
    super.setupFields();
    this.languageField.disable();
  }

  /**
   * Set up the product placement disclaimer field.
   *
   * @returns void
   */
  protected setupProductPlacementDisclaimerField(): void {
    this.productPlacementDisclaimerField = new FormControl(this.getDefaultValue(FeatureAttributes.productPlacementDisclaimer));
    this.addControl(FeatureAttributes.productPlacementDisclaimer, this.productPlacementDisclaimerField);
  }

  /**
   * Set up the smoking disclaimer field.
   *
   * @returns void
   */
  protected setupSmokingDisclaimerField(): void {
    const { functionalMetadataSplitFields }: any = this.title;
    this.smokingDisclaimerField = new FormControl(functionalMetadataSplitFields[FeatureAttributes.smokingDisclaimer]);
    this.addControl(FeatureAttributes.smokingDisclaimer, this.smokingDisclaimerField);
  }

  /**
   * Set up the display dub talent field.
   *
   * @returns void
   */
  protected setupDisplayDubTalentField(): void {
    this.displayDubTalentField = new FormControl(this.getDefaultValue(FeatureAttributes.displayDubTalent));
    this.addControl(FeatureAttributes.displayDubTalent, this.displayDubTalentField);
  }

  /**
   * Set up the graphic content disclaimer field.
   *
   * @returns void
   */
  protected setupGraphicContentDisclaimerField(): void {
    this.graphicContentDisclaimerField = new FormControl(this.getDefaultValue(FeatureAttributes.graphicContentDisclaimer));
    this.addControl(FeatureAttributes.graphicContentDisclaimer, this.graphicContentDisclaimerField);
  }

  /**
   * Set up the pse disclaimer field.
   *
   * @returns void
   */
  protected setupPseDisclaimerField(): void {
    this.pseDisclaimerField = new FormControl(this.getDefaultValue(FeatureAttributes.pseDisclaimer));
    this.addControl(FeatureAttributes.pseDisclaimer, this.pseDisclaimerField);
  }

  /**
   * Set up the heritage disclaimer field.
   *
   * @returns void
   */
  protected setupHeritageDisclaimerField(): void {
    const { functionalMetadataSplitFields }: any = this.title;
    this.heritageDisclaimerField = new FormControl(functionalMetadataSplitFields[FeatureAttributes.heritageDisclaimer]);
    this.addControl(FeatureAttributes.heritageDisclaimer, this.heritageDisclaimerField);
  }

  /**
   * Set up the include kids mode field.
   *
   * @returns void
   */
  protected setupIncludeKidsModeField(): void {
    this.includeKidsModeField = new FormControl(this.getDefaultValue(FeatureAttributes.includeKidsMode));
    this.addControl(FeatureAttributes.includeKidsMode, this.includeKidsModeField);
  }

  /**
   * Set up the exclude kids mode field.
   *
   * @returns void
   */
  protected setupExcludeKidsModeField(): void {
    const { functionalMetadataSplitFields }: any = this.title;
    this.excludeKidsModeField = new FormControl(functionalMetadataSplitFields[FeatureAttributes.excludeKidsMode]);
    this.addControl(FeatureAttributes.excludeKidsMode, this.excludeKidsModeField);
  }

  /**
   * Set up the rating id field.
   *
   * @returns void
   */
  protected setupRatingIdField(): void {
    this.ratingIdField = new FormControl(this.getDefaultValue(FeatureAttributes.ratingId));
    this.addControl(FeatureAttributes.ratingId, this.ratingIdField);
  }

  /**
   * Set up the rating system reason id field.
   *
   * @returns void
   */
  protected setupRatingSystemReasonIdField(): void {
    this.ratingSystemReasonIdField = new FormControl(this.getDefaultValue(FeatureAttributes.ratingSystemReasonId));
    this.addControl(FeatureAttributes.ratingSystemReasonId, this.ratingSystemReasonIdField);
  }

  /**
   * Set up the rating reason field.
   *
   * @returns void
   */
  protected setupRatingReasonField(): void {
    this.ratingReasonField = new FormControl(this.getDefaultValue(FeatureAttributes.ratingReason));
    this.addControl(FeatureAttributes.ratingReason, this.ratingReasonField);
  }

  /**
   * Set up the runtime seconds field.
   *
   * @returns void
   */
  protected setupRuntimeSecondsField(): void {
    this.runtimeSecondsField = new FormControl(this.getDefaultValue(FeatureAttributes.runtimeSeconds));
    this.addControl(FeatureAttributes.runtimeSeconds, this.runtimeSecondsField);
  }

  /**
   * Set up the rating filing number kmrb field.
   *
   * @returns void
   */
  protected setupRatingFilingNumberKmrbField(): void {
    this.ratingFilingNumberKmrbField = new FormControl(this.getDefaultValue(FeatureAttributes.ratingFilingNumberKmrb));
    this.addControl(FeatureAttributes.ratingFilingNumberKmrb, this.ratingFilingNumberKmrbField);
  }

  /**
   * Set up the onair platform kcc field.
   *
   * @returns void
   */
  protected setupOnairPlatformKccField(): void {
    this.onairPlatformKccField = new FormControl(this.getDefaultValue(FeatureAttributes.onairPlatformKcc));
    this.addControl(FeatureAttributes.onairPlatformKcc, this.onairPlatformKccField);
  }

  /**
   * Set up the onair date kcc field
   *
   * @returns void
   */
  protected setupOnairDateKccField(): void {
    this.onairDateKccField = new FormControl(this.getDefaultValue(FeatureAttributes.onairDateKcc));
    this.addControl(FeatureAttributes.onairDateKcc, this.onairDateKccField);
  }

  /**
   * Set up the first air release date field
   *
   * @returns void
   */
  protected setupFirstAirReleaseDateField(): void {
    this.firstAirReleaseDateField = new FormControl(this.getDefaultValue(EpisodeAttributes.firstAirReleaseDate));
    this.addControl(EpisodeAttributes.firstAirReleaseDate, this.firstAirReleaseDateField);
  }

  /**
   * Set up the local air running order field
   *
   * @returns void
   */
  protected setupLocalAirRunningOrderField(): void {
    this.localAirRunningOrderField = new FormControl(
      this.getDefaultValue(EpisodeAttributes.localAirRunningOrder),
      Validators.pattern(/^[+]?\d+([.]\d+)?$/)
    );

    this.addControl(EpisodeAttributes.localAirRunningOrder, this.localAirRunningOrderField);
  }

  /**
   * Set up the first digital release field
   *
   * @returns void
   */
  protected setupFirstDigitalReleaseField(): void {
    this.firstDigitalReleaseField = new FormControl(this.getDefaultValue(FeatureAttributes.firstDigitalRelease));
    this.addControl(FeatureAttributes.firstDigitalRelease, this.firstDigitalReleaseField);
  }

  /**
   * Set up the first physical release field
   *
   * @returns void
   */
  protected setupFirstPhysicalReleaseField(): void {
    this.firstPhysicalReleaseField = new FormControl(this.getDefaultValue(FeatureAttributes.firstPhysicalRelease));
    this.addControl(FeatureAttributes.firstPhysicalRelease, this.firstPhysicalReleaseField);
  }

  /**
   * Set up the theatrical release date field
   *
   * @returns void
   */
  protected setupTheatricalReleaseDateField(): void {
    this.theatricalReleaseDateField = new FormControl(this.getDefaultValue(FeatureAttributes.theatricalReleaseDate));
    this.addControl(FeatureAttributes.theatricalReleaseDate, this.theatricalReleaseDateField);
  }

  /**
   * Set up the genre id field
   *
   * @returns void
   */
  protected setupGenreIdField(): void {
    this.genreIdField = new FormControl(this.getDefaultValue(FeatureAttributes.genreId));
    this.addControl(FeatureAttributes.genreId, this.genreIdField);
  }

  /**
   * Set up the include kids mode override field.
   *
   * @returns void
   */
  protected setupIncludeKidsModeOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.includeKidsModeOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.includeKidsMode));
    } else {
      this.includeKidsModeOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.includeKidsModeOverride, this.includeKidsModeOverrideField);
  }

  /**
   * Set up the exclude kids mode override field.
   *
   * @returns void
   */
  protected setupExcludeKidsModeOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.excludeKidsModeOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.excludeKidsMode));
    } else {
      this.excludeKidsModeOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.excludeKidsModeOverride, this.excludeKidsModeOverrideField);
  }

  /**
   * Set up the product placement disclaimer override field.
   *
   * @returns void
   */
  protected setupProductPlacementDisclaimerOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.productPlacementDisclaimerOverrideField =
        new FormControl(this.title.isLocalizedField(FeatureAttributes.productPlacementDisclaimer));
    } else {
      this.productPlacementDisclaimerOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.productPlacementDisclaimerOverride, this.productPlacementDisclaimerOverrideField);
  }

  /**
   * Set up the smoking disclaimer override field.
   *
   * @returns void
   */
  protected setupSmokingDisclaimerOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.smokingDisclaimerOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.smokingDisclaimer));
    } else {
      this.smokingDisclaimerOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.smokingDisclaimerOverride, this.smokingDisclaimerOverrideField);
  }

  /**
   * Set up the display dub talent override field.
   *
   * @returns void
   */
  protected setupDisplayDubTalentOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.displayDubTalentOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.displayDubTalent));
    } else {
      this.displayDubTalentOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.displayDubTalentOverride, this.displayDubTalentOverrideField);
  }

  /**
   * Set up the graphic content disclaimer override field.
   *
   * @returns void
   */
  protected setupGraphicContentDisclaimerOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.graphicContentDisclaimerOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.graphicContentDisclaimer));
    } else {
      this.graphicContentDisclaimerOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.graphicContentDisclaimerOverride, this.graphicContentDisclaimerOverrideField);
  }

  /**
   * Set up the pse disclaimer override field.
   *
   * @returns void
   */
  protected setupPseDisclaimerOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.pseDisclaimerOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.pseDisclaimer));
    } else {
      this.pseDisclaimerOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.pseDisclaimerOverride, this.pseDisclaimerOverrideField);
  }

  /**
   * Set up the heritage disclaimer override field.
   *
   * @returns void
   */
  protected setupHeritageDisclaimerOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.heritageDisclaimerOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.heritageDisclaimer));
    } else {
      this.heritageDisclaimerOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.heritageDisclaimerOverride, this.heritageDisclaimerOverrideField);
  }

  /**
   * Set up the rating id field.
   *
   * @returns void
   */
  protected setupHomeEntRatingIdField(): void {
    this.homeEntRatingIdField = new FormControl(this.getDefaultValue(FeatureAttributes.homeEntRatingId));
    this.addControl(FeatureAttributes.homeEntRatingId, this.homeEntRatingIdField);
  }

  /**
   * Set up the rating system reason id field.
   *
   * @returns void
   */
  protected setupHomeEntRatingSystemReasonIdField(): void {
    this.homeEntRatingSystemReasonIdField = new FormControl(this.getDefaultValue(FeatureAttributes.homeEntRatingSystemReasonId));
    this.addControl(FeatureAttributes.homeEntRatingSystemReasonId, this.homeEntRatingSystemReasonIdField);
  }

  /**
   * Set up the rating reason field.
   *
   * @returns void
   */
  protected setupHomeEntRatingReasonField(): void {
    this.homeEntRatingReasonField = new FormControl(this.getDefaultValue(FeatureAttributes.homeEntRatingReason));
    this.addControl(FeatureAttributes.homeEntRatingReason, this.homeEntRatingReasonField);
  }
}
