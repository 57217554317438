import { Component } from '@angular/core';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { Attribute, FunctionalMetadataService, LevelModeEnum } from '@bolt/ui-shared/title';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { isUndefined as _isUndefined } from 'lodash';

import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { BoltTitleSpecificLocalizationDetailsComponent } from '../bolt-title-specific-localization-details/bolt-title-specific-localization-details.component';

@Component({
  selector: 'bolt-title-account-localization-details',
  template: require('./bolt-title-account-localization-details.component.html'),
  styles: [require('./bolt-title-account-localization-details.scss')]
})
export class BoltTitleAccountLocalizationDetailsComponent extends BoltTitleSpecificLocalizationDetailsComponent {

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected listProvider: StormListsProvider,
    protected functionalMetadataService: FunctionalMetadataService
  ) {
    super(appConfigurationManager, listProvider);
    this.levelMode = LevelModeEnum.account;
  }

  /**
   * Retrieves the functional metadata fields for the current level mode.
   *
   * @returns string[]
   */
  protected getFunctionalMetadataFields(): string[] {
    return this.functionalMetadataService.getAttributesByLevel(this.levelMode).map((attribute: Attribute) => attribute.getName());
  }

  protected hasUseLightGreyFor(attributeName: string): boolean {
    let hasIt: boolean;

    if (attributeName === FeatureAttributes.functionalMetadata) {
      if (this.hasFunctionalMetadataEnabled()) {
        hasIt = this.functionalMetadataService.getAttributesByLevel(this.levelMode).map(
          (attribute: Attribute) => attribute.getName()
        ).every(
          (fmAttribute: string) => super.hasUseLightGreyFor(fmAttribute)
        );
      } else {
        hasIt = super.hasUseLightGreyFor(attributeName);
      }
    } else {
      hasIt = super.hasUseLightGreyFor(attributeName);
    }

    return hasIt;
  }
}
