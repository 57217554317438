import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BoltCommonModule } from '../common/common.module';

import { BoltMetadataExportHistoryComponent } from './components/bolt-metadata-export-history/bolt-metadata-export-history.component';

import {
  BoltMetadataExportLocaleSelectorComponent
} from './components/bolt-metadata-export-locale-selector/bolt-metadata-export-locale-selector.component';


import {
  BoltMetadataExportLockIndicatorsComponent
} from './components/bolt-metadata-export-lock-indicators/bolt-metadata-export-lock-indicators.component';

import {
  BoltMetadataExportMultipleRecordsComponent
} from './components/bolt-metadata-export-multiple-records/bolt-metadata-export-multiple-records.component';

import {
  BoltMetadataExportMultipleRecordsSearchComponent
} from './components/bolt-metadata-export-multiple-records-search/bolt-metadata-export-multiple-records-search.component';

import { BoltMetadataExportPopupComponent } from './components/bolt-metadata-export-popup/bolt-metadata-export-popup.component';

import {
  BoltMetadataExportSingleRecordCommandsComponent
} from './components/bolt-metadata-export-single-record-commands/bolt-metadata-export-single-record-commands.component';

import {
  BoltMetadataExportSingleRecordComponent
} from './components/bolt-metadata-export-single-record/bolt-metadata-export-single-record.component';

import {
  BoltMetadataExportSingleRecordCreditsComponent
} from './components/bolt-metadata-export-single-record-credits/bolt-metadata-export-single-record-credits.component';

import { ExportService } from './services/export.service';
import { metadataExportModuleRouting } from './metadataExport.routing';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    metadataExportModuleRouting
  ],
  declarations: [
    BoltMetadataExportHistoryComponent,
    BoltMetadataExportLocaleSelectorComponent,
    BoltMetadataExportLockIndicatorsComponent,
    BoltMetadataExportMultipleRecordsComponent,
    BoltMetadataExportMultipleRecordsSearchComponent,
    BoltMetadataExportPopupComponent,
    BoltMetadataExportSingleRecordCommandsComponent,
    BoltMetadataExportSingleRecordComponent,
    BoltMetadataExportSingleRecordCreditsComponent
  ],
  exports: [
    BoltMetadataExportSingleRecordComponent
  ],
  providers: [
    ExportService
  ]
})
export class MetadataExportModule { }
