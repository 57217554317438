import { Field } from '../field.model';


export class NumberField extends Field {
  constructor(data: any) {
    super(data);
  }

  isNumber(): boolean {
    return true;
  }

  protected parseValue(value: any): number {
    let parsedValue: number;

    if (this.isValueNumber(value)) {
      parsedValue = Number(value);
    } else {
      parsedValue = undefined;
    }

    return parsedValue;
  }
}
