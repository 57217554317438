import { Title } from '../title.model';


export class Season extends Title {
  protected _language: string;
  protected _seasonNumber: number;
  protected _seriesId: number;

  constructor(data: any) {
    super(data);
  }

  get seasonNumber(): number {
    return this._seasonNumber;
  }

  get seriesId(): number {
    return this._seriesId;
  }

  /**
   * Returns the language.
   *
   * @returns string
   */
  getLanguage(): string {
    if (this._language === undefined) {
      this._language = this.locale.split('_')[0];
    }

    return this._language;
  }

  isSeason(): boolean {
    return true;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    super.initialize(data);

    this.populateSeriesId();
    this.populateSeasonNumber();
  }

  /**
   * Populates the season number.
   *
   * @returns void
   */
  protected populateSeasonNumber(): void {
    const field = this.getField('seasonNumber');

    if (field !== undefined) {
      this._seasonNumber = field.currentValue;
    }
  }

  /**
   * Populates the series ID.
   *
   * @returns void
   */
  protected populateSeriesId(): void {
    const field = this.getField('seriesId');

    if (field !== undefined) {
      this._seriesId = field.currentValue;
    }
  }
}
