import { Injectable } from '@angular/core';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Observable, Subscription, BehaviorSubject, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { clone as _clone, isObject as _isObject, reject as _reject, isUndefined as _isUndefined } from 'lodash';
import { Account, Country, Language, StormList, StormListType } from '@bolt/ui-shared/master-data';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ListService } from 'app/modules/list/services/list.service';
import { Project } from '../../../models/project/project.model';
import { ProjectService } from '../project.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable()
export class ManagerService {
  protected _accountItems: SelectionItem[];
  protected _currentProject: Project;
  protected _currentProjectListener: Observable<Project>;
  protected _languageItems: SelectionItem[];
  protected _projects: Project[];
  protected _projectsListener: Observable<Project[]>;
  protected _territoryItems: SelectionItem[];
  protected currentProjectNotifier: BehaviorSubject<Project>;
  protected defaults: any;
  protected fetchingDependenciesListener: Subscription;
  protected fetchingProjectsListener: Subscription;
  protected projectsNotifier: BehaviorSubject<Project[]>;

  constructor(
    protected listService: ListService,
    protected projectService: ProjectService
  ) {
    this.currentProjectNotifier = new BehaviorSubject(undefined);
    this.projectsNotifier = new BehaviorSubject([]);
    this._currentProjectListener = this.currentProjectNotifier.asObservable();
    this._projectsListener = this.projectsNotifier.asObservable();

    this.cleanDefaults();
    this.resetData();
    this.resetDependencies();
  }

  get accountItems(): SelectionItem[] {
    return this._accountItems;
  }

  get currentProject(): Project {
    return this._currentProject;
  }

  get currentProjectListener(): Observable<Project> {
    return this._currentProjectListener;
  }

  get languageItems(): SelectionItem[] {
    return this._languageItems;
  }

  get projects(): Project[] {
    return this._projects;
  }

  get projectsListener(): Observable<Project[]> {
    return this._projectsListener;
  }

  get territoryItems(): SelectionItem[] {
    return this._territoryItems;
  }

  /**
   * Cleans the defaults.
   *
   * @returns void
   */
  cleanDefaults(): void {
    this.defaults = undefined;
  }

  /**
   * Copies the defaults from current project.
   *
   * @returns void
   */
  copyDefaultsFromCurrentProject(): void {
    if (this.hasCurrentProject()) {
      this.defaults = {
        accountCode: this.currentProject.accountCode,
        languagesCodes: _clone(this.currentProject.languagesCodes),
        territoriesCodes: _clone(this.currentProject.territoriesCodes)
      };
    } else {
      throw new ErrorHelper('There is no current project from copy defaults values.');
    }
  }

  /**
   * Creates a project with the given data and adds it into the list.
   *
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  createProject(data: any, onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): void {
    this.projectService.create(
      data,
      (project: Project) => {
        this._projects.push(project);
        this.sortAndNotifyProjectsChange();
        this.setCurrentProject(project.id);

        onSuccessDo();
      },
      onErrorDo,
      finallyDo
    );
  }

  /**
   * Deletes the current project.
   *
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  deleteCurrentProject(onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): void {
    this.projectService.delete(
      this.currentProject.id,
      () => {
        this.removeCurrentProject();
        onSuccessDo();
      },
      onErrorDo,
      finallyDo
    );
  }

  /**
   * Fetches the projects.
   *
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  fetchProjects(onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): void {
    if (this.hasDependencies()) {
      this.doFetchProjects(onSuccessDo, onErrorDo, finallyDo);
    } else {
      this.fetchDependencies(
        () => {
          this.doFetchProjects(onSuccessDo, onErrorDo, finallyDo);
        },
        onErrorDo,
        finallyDo
      );
    }
  }

  /**
   * Returns the account for the given code.
   *
   * @param code string
   * @throws ErrorHelper
   * @returns Account
   */
  getAccountByCode(code: string): Account {
    const target: SelectionItem = this.accountItems.find(
      (item: SelectionItem) => ((<Account>item.source).code === code)
    );

    if (_isObject(target)) {
      return target.source;
    } else {
      throw new ErrorHelper(`Invalid code given for retrieving an account: ${code}`);
    }
  }

  /**
   * Returns the defaults values to be consumed by the project creation form.
   *
   * @returns any
   */
  getDefaults(): any {
    return this.defaults;
  }

  /**
   * Returns the language for the given code.
   *
   * @param code string
   * @throws ErrorHelper
   * @returns Language
   */
  getLanguageByCode(code: string): Language {
    const target: SelectionItem = this.languageItems.find(
      (item: SelectionItem) => ((<Language>item.source).code === code)
    );

    if (_isObject(target)) {
      return target.source;
    } else {
      throw new ErrorHelper(`Invalid code given for retrieving a language: ${code}`);
    }
  }

  /**
   * Returns the territory for the given code.
   *
   * @param code string
   * @throws ErrorHelper
   * @returns Country
   */
  getTerritoryByCode(code: string): Country {
    const target: SelectionItem = this.territoryItems.find(
      (item: SelectionItem) => ((<Country>item.source).code === code)
    );

    if (_isObject(target)) {
      return target.source;
    } else {
      throw new ErrorHelper(`Invalid code given for retrieving a territory: ${code}`);
    }
  }

  /**
   * Indicates if it has accounts.
   *
   * @returns boolean
   */
  hasAccounts(): boolean {
    const hasIt: boolean = (this.accountItems.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has a current project.
   *
   * @returns boolean
   */
  hasCurrentProject(): boolean {
    return _isObject(this._currentProject);
  }

  /**
   * Indicates if it has defaults values to be consumed by the project creation form.
   *
   * @returns boolean
   */
  hasDefaults(): boolean {
    return _isObject(this.defaults);
  }

  /**
   * Indicates if it has projects.
   *
   * @returns boolean
   */
  hasProjects(): boolean {
    const hasIt: boolean = (this.projects.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has languages.
   *
   * @returns boolean
   */
  hasLanguages(): boolean {
    const hasIt: boolean = (this.languageItems.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has territories.
   *
   * @returns boolean
   */
  hasTerritories(): boolean {
    const hasIt: boolean = (this.territoryItems.length > 0);
    return hasIt;
  }

  /**
   * Reset it.
   *
   * @returns void
   */
  reset(): void {
    this.cleanDefaults();
    this.resetData();
    this.resetDependencies();
    this.unsubscribeFetchingDependencies();
    this.unsubscribeFetchingProjects();
  }

  /**
   * Set the current project using the given ID.
   *
   * @param id number
   * @throws ErrorHelper
   * @returns void
   */
  setCurrentProject(id: number): void {
    if (_isUndefined(id)) {
      this.storeCurrentProject(undefined);
    } else {
      const target: Project = this.projects.find(
        (project: Project) => {
          const matched: boolean = (project.id === id);
          return matched;
        }
      );

      if (_isObject(target)) {
        this.storeCurrentProject(target);
      } else {
        throw new ErrorHelper('Invalid ID given for selecting a project in list.');
      }
    }
  }

  /**
   * Updates the current project with the given data.
   *
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  updateCurrentProject(data: any, onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): void {
    this.projectService.update(
      this.currentProject.id,
      data,
      (project: Project) => {
        this.overwriteCurrentProjectWith(project);
        this.sortAndNotifyProjectsChange();
        onSuccessDo();
      },
      onErrorDo,
      finallyDo
    );
  }

  /**
   * Does the fetching process for retrieving the projects.
   *
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  protected doFetchProjects(onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): void {
    this.unsubscribeFetchingProjects();
    this.resetData();

    this.fetchingProjectsListener = this.projectService.fetch(
      (projects: Project[]) => {
        this._projects = projects;

        this.sortAndNotifyProjectsChange();
        onSuccessDo();
      },
      onErrorDo,
      finallyDo
    );
  }

  /**
   * Fetches the dependencies.
   *
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  protected fetchDependencies(onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): void {
    this.unsubscribeFetchingDependencies();
    this.resetDependencies();

    this.fetchingDependenciesListener = forkJoin(
      this.listService.fetchList(StormListType.account),
      this.listService.fetchList(StormListType.language),
      this.listService.fetchList(StormListType.territory)
    ).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (responses: StormServiceResponseSingle[]) => {
        this._accountItems = this.obtainItemsFromDependency(responses[0].item, Account.ALL_ID);
        this._languageItems = this.obtainItemsFromDependency(responses[1].item, Language.ALL_ID);
        this._territoryItems = this.obtainItemsFromDependency(responses[2].item, Country.ALL_ID);

        onSuccessDo();
      },
      (error: any) => {
        onErrorDo(error);
      }
    );
  }

  /**
   * Returns the criteria for sorting names.
   *
   * @returns any
   */
  protected getNameSortingCriteria(): any {
    const criteria: any = (
      entityA: Project | Account | Country | Language,
      entityB: Project | Account | Country | Language,
    ) => {
      const nameA: string = entityA.name.trim().toLowerCase();
      const nameB: string = entityB.name.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Indicates if it has all dependencies.
   *
   * @returns boolean
   */
  protected hasDependencies(): boolean {
    const hasIt: boolean = (this.hasAccounts() && this.hasLanguages() && this.hasTerritories());
    return hasIt;
  }

  /**
   * Notifies if the projects have changed.
   *
   * @returns void
   */
  protected notifyProjectsChange(): void {
    this.projectsNotifier.next(this.projects);
  }

  /**
   * Obtains the items from the given StormList.
   *
   * @param list StormList
   * @param excludeId number
   * @throws ErrorHelper
   * @returns SelectionItem[]
   */
  protected obtainItemsFromDependency(list: StormList, excludeId: number): SelectionItem[] {
    if (list.collection.length) {
      const output: SelectionItem[] = new Array();

      list.getRawCollection().sort(this.getNameSortingCriteria()).forEach(
        (entity: any) => {
          if (entity.id !== excludeId) {
            const item: SelectionItem = new SelectionItem(entity.name, entity.code, entity);
            output.push(item);
          }
        }
      );

      return output;
    } else {
      throw new ErrorHelper(`Invalid dependency list: ${list.type}.`);
    }
  }

  /**
   * Overwrites the current project with the given one, finding it inside project list.
   *
   * @param project Project
   * @returns void
   */
  protected overwriteCurrentProjectWith(project: Project): void {
    const index: number = this.projects.findIndex(
      (xProject: Project) => (xProject.id === this.currentProject.id)
    );

    this.projects[index] = project;
    this.storeCurrentProject(project);
  }

  /**
   * Removes the current project from the list.
   *
   * @returns void
   */
  protected removeCurrentProject(): void {
    this._projects = _reject(
      this.projects,
      (xProject: Project) => (xProject.id === this.currentProject.id)
    );

    this.storeCurrentProject(undefined);
    this.notifyProjectsChange();
  }

  /**
   * Reset the current project and projects list.
   *
   * @returns void
   */
  protected resetData(): void {
    this._projects = new Array();

    this.setCurrentProject(undefined);
    this.notifyProjectsChange();
  }

  /**
   * Reset the dependency lists.
   *
   * @returns void
   */
  protected resetDependencies(): void {
    this._accountItems = new Array();
    this._languageItems = new Array();
    this._territoryItems = new Array();
  }

  /**
   * Sorts and notifies projects changes.
   *
   * @returns void
   */
  protected sortAndNotifyProjectsChange(): void {
    this.sortProjects();
    this.notifyProjectsChange();
  }

  /**
   * Sorts the projects.
   *
   * @returns void
   */
  protected sortProjects(): void {
    this._projects.sort(this.getNameSortingCriteria());
  }

  /**
   * Stores the given project.
   *
   * @param project Project
   * @returns void
   */
  protected storeCurrentProject(project: Project): void {
    this._currentProject = project;
    this.currentProjectNotifier.next(this.currentProject);
  }

  /**
   * Unsubscribes from fetching dependencies, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeFetchingDependencies(): void {
    if (_isObject(this.fetchingDependenciesListener)) {
      this.fetchingDependenciesListener.unsubscribe();
    }
  }

  /**
   * Unsubscribes from fetching projects, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeFetchingProjects(): void {
    if (_isObject(this.fetchingProjectsListener)) {
      this.fetchingProjectsListener.unsubscribe();
    }
  }
}
