export class Errors {
  protected _inFields: number;
  protected _inTitles: number;

  constructor(data: any) {
    this.initialize(data);
  }

  get inFields(): number {
    return this._inFields;
  }

  get inTitles(): number {
    return this._inTitles;
  }

  /**
   * Returns the total quantity of errors.
   *
   * @returns number
   */
  getTotal(): number {
    const total: number = (this.inFields + this.inTitles);
    return total;
  }

  /**
   * Indicates if there are errors.
   *
   * @returns boolean
   */
  hasErrors(): boolean {
    const hasIt: boolean = (
      this.getTotal() > 0
    );

    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const inFields: number = (
      (data && data.hasOwnProperty('inFields'))
        ? data.inFields
        : 0
    );

    const inTitles: number = (
      (data && data.hasOwnProperty('inTitles'))
        ? data.inTitles
        : 0
    );

    this._inFields = inFields;
    this._inTitles = inTitles;
  }
}
