import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, highlights: string | string[]) {
    if (!value || !highlights || !highlights.length) {
      return value;
    }

    let keywords: string[] = [];

    if (Array.isArray(highlights)) {
      keywords = highlights;
    } else {
      keywords = highlights.split(' ');
    }

    keywords = keywords.filter((token) => !!token.length);
    keywords = keywords.map((key) => key.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'));

    const result: string = value.toString().replace(
      new RegExp('(' + keywords.join('|') + ')', 'gmi'),
      '<mark class="highlight">$1</mark>'
    );

    return result;
  }
}
