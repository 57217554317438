import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';

import { AuthGuard } from './helpers/auth-guard/auth-guard.helper';
import { AuthHelper } from './helpers/auth/auth.helper';
import { authModuleRouting } from './auth.routing';
import { BoltAuthAccessDeniedComponent } from './components/bolt-auth-access-denied/bolt-auth-access-denied.component';
import { BoltAuthAwareDirective } from './directives/bolt-auth-aware/bolt-auth-aware.directive';
import { BoltAuthComponent } from './components/bolt-auth/bolt-auth.component';
import { BoltAuthLoginComponent } from './components/bolt-auth-login/bolt-auth-login.component';
import { BoltAuthLogoutComponent } from './components/bolt-auth-logout/bolt-auth-logout.component';
import { BoltAuthRestrictedAccessComponent } from './components/bolt-auth-restricted-access/bolt-auth-restricted-access.component';
import { BoltAuthValidateComponent } from './components/bolt-auth-validate/bolt-auth-validate.component';
import { CapabilitiesManager } from './services/role/capabilities.manager';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    authModuleRouting
  ],
  declarations: [
    BoltAuthAccessDeniedComponent,
    BoltAuthAwareDirective,
    BoltAuthComponent,
    BoltAuthLoginComponent,
    BoltAuthLogoutComponent,
    BoltAuthRestrictedAccessComponent,
    BoltAuthValidateComponent,
  ],
  exports: [
    BoltAuthAwareDirective
  ],
  providers: [
    AuthHelper,
    AuthGuard,
    CapabilitiesManager
  ],
})
export class AuthModule { }
