import { Pipe, PipeTransform } from '@angular/core';
import { isBoolean as _isBoolean } from 'lodash';


@Pipe({
  name: 'booleanToString'
})
export class BooleanToStringPipe implements PipeTransform {
  transform(value: boolean) {
    let result: string;

    if (_isBoolean(value)) {
      result = value ? 'Yes' : 'No';
    } else {
      result = '-';
    }

    return result;
  }
}
