import { LocalizationInterface } from 'app/modules/common/models/localization.model';
import { Talent, TalentInterface } from './talent.model';

export interface TalentMetadataInterface
  extends LocalizationInterface, TalentInterface { }

export class TalentMetadata
  extends Talent
  implements TalentMetadataInterface {

  // generic metadata attributes
  protected _rootId: number;
  protected _localized: boolean;

  protected _originalData: any;
  protected _inheritedAttributes: string[];

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
  }

  get rootId(): number {
    return this._rootId;
  }

  set rootId(rootId: number) {
    this._rootId = rootId;
  }

  get localized(): boolean {
    return this._localized;
  }

  set localized(localized: boolean) {
    this._localized = localized;
  }

  get originalData(): any {
    return this._originalData;
  }

  set originalData(originalData: any) {
    this._originalData = originalData;
  }

  get inheritedAttributes(): string[] {
    return this._inheritedAttributes;
  }

  set inheritedAttributes(inheritedAttributes: string[]) {
    this._inheritedAttributes = inheritedAttributes;
  }

  isInheritedAttribute(attribute: string) {
    return this._inheritedAttributes.indexOf(attribute) !== -1;
  }

}
