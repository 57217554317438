import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfigProvider, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { concat, of } from 'rxjs';

import { ENV_PROVIDERS } from './environment';
import { AppComponent } from './app.component';
import { routing, appRoutingProviders } from './app.routing';
import { AuthHttp } from './modules/auth/helpers/auth-http/auth-http.helper';
import { AuthHelper } from './modules/auth/helpers/auth/auth.helper';
import { MicroUiRedirectGuard } from './shared/guards/micro-ui-redirect/micro-ui-redirect.guard';
import { BoltCommonModule } from './modules/common/common.module';
import { XsltModule } from './modules/xslt/xslt.module';
import { ApplicationModule } from './modules/application/application.module';
import { UserModule } from './modules/user/user.module';
import { ListModule } from './modules/list/list.module';
import { AuthModule } from './modules/auth/auth.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ProfileModule } from './modules/profile/profile.module';
import { TitleModule } from './modules/title/title.module';
import { TalentModule } from './modules/talent/talent.module';
import { CharacterModule } from './modules/character/character.module';
import { RoleModule } from './modules/role/role.module';
import { SearchModule } from './modules/search/search.module';
import { ProjectModule } from './modules/project/project.module';
import { UpcModule } from './modules/upc/upc.module';
import { MasterDataModule } from './modules/masterData/master-data.module';
import { MetadataExportModule } from './modules/metadataExport/metadataExport.module';


@NgModule({
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // Bolt
    routing,
    BoltCommonModule,
    ApplicationModule,
    UserModule,
    ListModule,
    AuthModule,
    DashboardModule,
    TitleModule,
    TalentModule,
    CharacterModule,
    RoleModule,
    ProfileModule,
    XsltModule,
    SearchModule,
    ProjectModule,
    UpcModule,
    MasterDataModule,
    MetadataExportModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appRoutes: AppRoutesService) => {
        return () => of(appRoutes.loadRoutes(require('../../config/app-routes.json')));
      },
      deps: [AppRoutesService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigManager: AppConfigurationManager, appConfigProvider: AppConfigProvider) => {
        return () => concat(
          appConfigManager.loadAll(),
          of(appConfigProvider.loadConfig(
                appConfigManager,
                require('../../config/app-config.global.json'),
                require('../../config/app-config.' + ENV + '.json'),
                require('../../version.json')
              )
          )
        ).toPromise();
      },
      deps: [AppConfigurationManager, AppConfigProvider],
      multi: true
    },
    appRoutingProviders,
    ENV_PROVIDERS,
    MicroUiRedirectGuard,
    {
      provide: AuthHttp,
      useFactory: (appConfig: AppConfigProvider, authHelper: AuthHelper, http: HttpClient, router: Router) => {
        return new AuthHttp(appConfig, authHelper, http, router, [{ 'Content-Type': 'application/json' }]);
      },
      deps: [AppConfigProvider, AuthHelper, HttpClient, Router]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
