import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Language } from '@bolt/ui-shared/master-data';
import { Attribute, FunctionalMetadataService, LevelModeEnum } from '@bolt/ui-shared/title';

import {
  cloneDeep as _cloneDeep, isUndefined as _isUndefined, isNull as _isNull, isObject as _isObject, isString as _isString
} from 'lodash';

import { CapabilitiesManager } from '../../../auth/services/role/capabilities.manager';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { LocalizationSpecific } from '../../models/localization-specific.enum';
import { modulesPath } from '../../../auth/services/role/modules-path';
import { ProductLayoutHelper } from '../../helpers/product-layout/product-layout.helper';
import { Title } from '../../models/title.model';
import { Feature } from '../../models/feature.model';
import { FeatureMetadata } from '../../models/feature-metadata.model';
import { SeasonMetadata } from '../../models/season-metadata.model';
import { SeriesMetadata } from '../../models/series-metadata.model';
import { EpisodeMetadata } from '../../models/episode-metadata.model';
import { TitleService } from '../../services/title.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { LocalizationManagerAction } from '../bolt-title-localization-manager/localization-manager-action.enum';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { TypeEnum as LocaleType } from 'app/modules/common/models/locale/type/type.enum';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';


@Component({
  selector: 'bolt-title-localization-attribute-actions',
  template: require('./bolt-title-localization-attribute-actions.html'),
  styles: [require('./bolt-title-localization-attribute-actions.scss')],
})
export class BoltTitleLocalizationAttributeActionsComponent implements OnChanges, OnInit {
  @Input() localization: EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata;
  @Input() attributeLabel: string;
  @Input() attribute: string;
  @Input() allowCustomize: boolean = true;
  @Input() allowUpdate: boolean = true;
  @Input() allowDelete: boolean = true;
  @Output('updated') updatedEvent: EventEmitter<any> = new EventEmitter();

  protected readonly attributesFromPmx: string[] = Title.getPmxAttributes();
  protected currentModal: NgbModalRef;
  protected currentAction: LocalizationManagerAction;
  protected isCustomize: boolean = false;
  protected isSaving: boolean = false;
  protected isInherited: boolean;
  protected isLocalizationSpecificRoot: boolean;
  protected levelMode: LevelModeEnum;
  protected localizationPrivileges: Map<LocalizationSpecific, boolean> = new Map();
  protected isEnglishRootWriteCapable: boolean;
  protected localeType: string;
  protected showLocalizationManager: boolean = false;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected functionalMetadataService: FunctionalMetadataService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected capabilitiesManager: CapabilitiesManager,
    protected titleService: TitleService,
    protected notificationService: NotificationService,
    protected modalService: NgbModal
  ) {
    this.initializeLocalizationPrivileges();
  }

  ngOnInit() {
    this.setupLevelMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.localization && changes.localization.currentValue) {
      this.isInherited = this.localization.isComputedField(this.attribute);
      this.isLocalizationSpecificRoot = this.localization.localeObject.isLocalizationSpecificRoot();
    }

    this.localeType = this.localization.localeObject.type.toString().toUpperCase();
  }

  /**
   * Returns a boolean indicating if the Product Metadata attribute can be customized
   *
   * @returns boolean
   */
  protected canCustomize(): boolean {
    const canIt: boolean = this.allowCustomize
      && !this.localization.localeObject.isAccountRoot()
      && this.hasWritePrivileges(false);

    return canIt;
  }

  /**
   * Returns a boolean indicating if the Product Metadata attribute can be edited
   *
   * @returns boolean
   */
  protected canUpdate(): boolean {
    const canIt: boolean = this.allowUpdate
      && !this.localization.locked
      && this.canEditPmx()
      && this.hasWritePrivileges(true);

    return canIt;
  }

  /**
   * Returns a boolean indicating if the Product Metadata attribute can be deleted
   *
   * @returns boolean
   */
  protected canDelete(): boolean {
    let hasValueToDelete: boolean;

    if (this.isFunctionalMetadata()) {
      const functionalMetadataAttributes: string[] = this.hasFunctionalMetadataEnabled()
        ? this.functionalMetadataService.getAttributesByLevel(this.levelMode).map((attribute: Attribute) => attribute.getName())
        : [this.attribute];

      hasValueToDelete = functionalMetadataAttributes.some((attribute: string) => this.localization.isLocalizedField(attribute));
    } else {
      hasValueToDelete = this.localization.isLocalizedField(this.attribute);
    }

    const canIt: boolean =
      this.allowDelete &&
      !this.isLocalizationSpecificRoot &&
      !this.isInherited &&
      hasValueToDelete &&
      !this.localization.locked &&
      this.canEditPmx() &&
      this.hasWritePrivileges();

    return canIt;
  }

  /**
   * Close the current confirmation cancel modal.
   *
   * @param stopSaving boolean
   * @returns void
   */
  protected closeModal(stopSaving: boolean = false): void {
    if (stopSaving) {
      this.isSaving = false;
    }

    if (!_isUndefined(this.currentModal)) {
      this.currentModal.close();
    }
  }

  /**
   * Starts the edit process for the localization manager.
   *
   * @returns void
   */
  protected editAttribute(): void {
    this.currentAction = LocalizationManagerAction.edit;
    this.showLocalizationManager = true;
  }

  /**
   * Starts the customization process for the localization manager.
   *
   * @returns void
   */
  protected customizeFromAttribute(): void {
    this.currentAction = LocalizationManagerAction.create;
    this.isCustomize = true;
    this.showLocalizationManager = true;
  }

  /**
   * Check if the user has write privileges on current module
   *
   * @param checkEnglish boolean
   * @returns boolean
   */
  protected hasWritePrivileges(checkEnglish: boolean = true): boolean {
    if (checkEnglish && this.isEnglishLocalization()) {
      return this.isEnglishRootWriteCapable;
    }

    return this.localizationPrivileges.get(LocalizationSpecific[this.localeType]);
  }

  /**
   * Starts the delete process of the current attribute
   *
   * @returns void
   */
  protected deleteAttribute(): void {
    let attributesToDelete: string[];
    this.isSaving = false;

    if (this.isFunctionalMetadata()) {
      attributesToDelete = this.hasFunctionalMetadataEnabled()
        ? this.functionalMetadataService.getAttributesByLevel(this.levelMode).map((attribute: Attribute) => attribute.getName())
        : [FeatureAttributes.functionalMetadata];
    } else {
      attributesToDelete = [this.attribute];
    }

    this.titleService.deleteProductMetadataAttribute(
      {
        productType: this.localization.type,
        productId: this.localization.id,
      },
      this.localization.locale,
      attributesToDelete
    )
    .subscribe(
      (response: StormServiceResponseSingle) => {
        this.updatedEvent.emit(response.item);
        this.isSaving = false;
        this.closeModal();
        this.notificationService.handleNotice('Customization successfully deleted');
      },
      (error: any) => {
        this.isSaving = false;
        this.closeModal();
        this.notificationService.handleError('Error while trying to delete the Customization', error);
      }
    );
  }

  /**
   * Indicates if the user can edit the current PMX attribute.
   *
   * @returns boolean
   */
  protected canEditPmx(): boolean {
    let itCan: boolean = !this.isFromPmx();
    const synopsisAttributes: string[] = this.attributesFromPmx.filter((attribute: string) => attribute !== 'keywords');

    if (
      this.isFromPmx() &&
      this.localization.isFeature() &&
      (<Feature>this.localization).videoType === 'Bonus' &&
      this.localization.localeObject.isLanguageRoot() &&
      Language.isEnglish(<number>this.localization.language)
    ) {
      itCan = synopsisAttributes.includes(this.attribute);
    }

    return itCan;
  }

  /**
   * Indicates if the Functional Metadata toggle is on.
   *
   * @returns boolean
   */
  protected hasFunctionalMetadataEnabled(): boolean {
    const hasIt = this.appConfigurationManager.getToggleValue(ToggleKeyEnum.functionalMetadataAsFieldsOn);
    return hasIt;
  }

  /**
   * Indicates if the current attribute is from PMX.
   *
   * @returns boolean
   */
  protected isFromPmx(): boolean {
    // TODO update this logic when expect more services instead of only PMX
    const itIs: boolean =
      _isObject(this.localization.bpiField) &&
      _isString(this.localization.bpiField[this.attribute]) &&
      this.localization.bpiField[this.attribute].split('_')[0] === 'PMX';

    return itIs;
  }

  /**
   * Load the privileges on localizations for the current user
   *
   * @returns void
   */
  protected initializeLocalizationPrivileges(): void {
    const moduleInfo: any = modulesPath.titles.details;
    const allowedCapabilities: ActionTypeEnum[] = [ActionTypeEnum.write];

    this.localizationPrivileges.set(
      LocalizationSpecific.LANGUAGE,
      this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo.language.path, allowedCapabilities)
    );

    this.localizationPrivileges.set(
      LocalizationSpecific.TERRITORY,
      this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo.territory.path, allowedCapabilities)
    );

    this.localizationPrivileges.set(
      LocalizationSpecific.ACCOUNT,
      this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo.account.path, allowedCapabilities)
    );

    this.isEnglishRootWriteCapable = this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo.english.root.path, allowedCapabilities);
  }

  /**
   * Indicates if the current localization is english language
   *
   * @returns boolean
   */
  protected isEnglishLocalization(): boolean {
    return Locale.isEnglishLanguage(this.localization.locale);
  }

  /**
   * Indicates if the current attribute is functionalMetadata.
   *
   * @returns boolean
   */
  protected isFunctionalMetadata(): boolean {
    return this.attribute === FeatureAttributes.functionalMetadata;
  }

  /**
   * Opens the given modal.
   *
   * @param modal any
   * @returns void
   */
  protected openModal(modal: any): void {
    this.currentModal = this.modalService.open(modal);
  }

  /**
   * Handles the close event of the localization manager
   *
   * @returns void
   */
  protected onCloseLocalizationManager(): void {
    this.isCustomize = false;
    this.currentAction = undefined;
    this.showLocalizationManager = false;
  }

  /**
   * Handles the save event of the localization manager
   *
   * @param localization any
   */
  protected onSaveLocalization(localization: any): void {
    this.updatedEvent.emit(localization);
  }

  /**
   * Set up the level mode.
   *
   * @returns void
   */
  protected setupLevelMode(): void {
    switch (this.localization.localeObject.type.value) {
      case LocaleType.account:
        this.levelMode = LevelModeEnum.account;
        break;
      case LocaleType.territory:
        this.levelMode = LevelModeEnum.territory;
        break;
      default:
        this.levelMode = undefined;
        break;
    }
  }
}
