import { isObject as _isObject, isNumber as _isNumber, isString as _isString, isUndefined as _isUndefined } from 'lodash';
import * as moment from 'moment';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Status } from './status/status.model';
import { Type } from './type/type.model';


export class Event {
  protected createdBy: string;
  protected detail: string;
  protected finishedTime?: moment.Moment;
  protected id: number;
  protected lastModified: moment.Moment;
  protected lastModifiedBy: string;
  protected startedTime: moment.Moment;
  protected status: Status;
  protected type: Type;
  protected uuid: string;

  constructor(data: any) {
    if (_isObject(data)) {
      this.status = new Status(data.eventStatus);
      this.type = new Type(data.type);

      this.setCreatedBy(data.createdBy);
      this.setDetail(data.detail);
      this.setFinishedTime(data.finishedTime);
      this.setId(data.idEvent);
      this.setLastModified(data.lastModified);
      this.setLastModifiedBy(data.lastModifiedBy);
      this.setStartedTime(data.startedTime);
      this.setUuid(data.uuid);
    } else {
      throw new ErrorHelper('Invalid data given for a CPM Event.');
    }
  }

  /**
   * Returns the created-by username.
   *
   * @returns string
   */
  getCreatedBy(): string {
    return this.createdBy;
  }

  /**
   * Returns the detail.
   *
   * @returns string
   */
  getDetail(): string {
    return this.detail;
  }

  /**
   * Returns the finished time.
   *
   * @returns moment.Moment | undefined
   */
  getFinishedTime(): moment.Moment | undefined {
    return this.finishedTime;
  }

  /**
   * Returns the ID.
   *
   * @returns number
   */
  getId(): number {
    return this.id;
  }

  /**
   * Returns the last modified time.
   *
   * @returns moment.Moment
   */
  getLastModified(): moment.Moment {
    return this.lastModified;
  }

  /**
   * Returns the last modified user.
   *
   * @returns string
   */
  getLastModifiedBy(): string {
    return this.lastModifiedBy;
  }

  /**
   * Returns the started time.
   *
   * @returns moment.Moment
   */
  getStartedTime(): moment.Moment {
    return this.startedTime;
  }

  /**
   * Returns the status.
   *
   * @returns Status
   */
  getStatus(): Status {
    return this.status;
  }

  /**
   * Returns the type.
   *
   * @returns Type
   */
  getType(): Type {
    return this.type;
  }

  /**
   * Returns the UUID.
   *
   * @returns string
   */
  getUuid(): string {
    return this.uuid;
  }

  /**
   * Set the created-by username.
   *
   * @param createdBy string
   * @returns void
   */
  protected setCreatedBy(createdBy: string = ''): void {
    this.createdBy = createdBy.trim();
  }

  /**
   * Set the detail.
   *
   * @param detail string
   * @returns void
   */
  protected setDetail(detail: string = ''): void {
    this.detail = detail.trim();
  }

  /**
   * Set the finished time.
   *
   * @param finishedTime string
   * @returns void
   */
  protected setFinishedTime(finishedTime: string): void {
    if (_isString(finishedTime)) {
      this.finishedTime = moment(finishedTime);
    } else if (!_isUndefined(finishedTime)) {
      throw new ErrorHelper('Invalid finished time given for a CPM Event.');
    }
  }

  /**
   * Set the ID.
   *
   * @param id number
   * @throws ErrorHelper
   * @returns void
   */
  protected setId(id: number): void {
    if (_isNumber(id)) {
      this.id = id;
    } else {
      throw new ErrorHelper('Invalid ID given for a CPM Event.');
    }
  }

  /**
   * Set the last modified time.
   *
   * @param lastModified string
   * @throws ErrorHelper
   * @returns void
   */
  protected setLastModified(lastModified: string): void {
    if (_isString(lastModified)) {
      this.lastModified = moment(lastModified);
    } else {
      throw new ErrorHelper('Invalid last modifier given for a CPM Event.');
    }
  }

  /**
   * Set the last modified username.
   *
   * @param lastModifiedBy string
   * @throws ErrorHelper
   * @returns void
   */
  protected setLastModifiedBy(lastModifiedBy: string): void {
    if (_isString(lastModifiedBy)) {
      this.lastModifiedBy = lastModifiedBy.trim();
    } else {
      throw new ErrorHelper('Invalid last modified time given for a CPM Event.');
    }
  }

  /**
   * Set the started time.
   *
   * @param startedTime string
   * @throws ErrorHelper
   * @returns void
   */
  protected setStartedTime(startedTime: string): void {
    if (_isString(startedTime)) {
      this.startedTime = moment(startedTime);
    } else {
      throw new ErrorHelper('Invalid started time given for a CPM Event.');
    }
  }

  /**
   * Set the UUID.
   *
   * @param uuid string
   * @throws ErrorHelper
   * @returns void
   */
  protected setUuid(uuid: string): void {
    if (_isString(uuid)) {
      uuid = uuid.trim();

      if (uuid.length > 0) {
        this.uuid = uuid.trim();
        return;
      }
    }

    throw new ErrorHelper('Invalid UUID given for a CPM Event.');
  }
}
