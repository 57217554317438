import { Injectable } from '@angular/core';
import { startCase as _startCase } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { LanguageInterface } from '@bolt/ui-shared/master-data/models/language/language.interface';
import { ProductTypeInterface } from '@bolt/ui-shared/master-data/models/product-type/product-type.interface';
import { CountryInterface } from '@bolt/ui-shared/master-data/models/country/country.interface';
import { RatingSystemReasonInterface } from '@bolt/ui-shared/master-data/models/rating-system-reason/rating-system-reason.interface';
import { RatingInterface } from '@bolt/ui-shared/master-data/models/rating/rating.interface';
import { AccountInterface } from '@bolt/ui-shared/master-data/models/account/account.interface';
import { AspectRatioInterface } from '@bolt/ui-shared/master-data/models/aspect-ratio/aspect-ratio.interface';
import { AffixInterface } from '@bolt/ui-shared/master-data/models/affix/affix.interface';
import {
  Account,
  Affix,
  AspectRatio,
  Company,
  CompanyInterface,
  Country,
  DubbingStudio,
  Genre,
  TerritoryAccountUnitInterface,
  GenreInterface,
  Language,
  MasterDataCacheService,
  PrimaryProductAssociation,
  ProductType,
  Rating,
  RatingSystem,
  RatingSystemClientInterface,
  RatingSystemInterface,
  RatingSystemReason,
  SecondaryProductAssociation,
  StormList,
  StormListType
} from '@bolt/ui-shared/master-data';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { FunctionalMetadata } from '../models/functional-metadata/functional-metadata.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { XsltPropertyInterface, XsltProperty } from 'app/modules/xslt/models/xslt-property.model';


@Injectable({
  providedIn: 'root',
})
export class ListService {
  constructor(
    protected authHttp: AuthHttp,
    protected appConfig: AppConfigProvider,
    protected appRoutes: AppRoutesService,
    protected cacheService: MasterDataCacheService
  ) { }

  /**
   * Returns an observable for fetching the given list.
   *
   * @param listType StormListType
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchList(listType: StormListType): Observable<StormServiceResponseSingle> {
    const method: string = _startCase(listType.toString()).replace(/\s/g, '');
    const obs: Observable<StormServiceResponseSingle> = this[`fetch${method}`]();

    return obs;
  }

  /**
   * Tries to fetch a Cached List, otherwise, calls the proper endpoint and caches it
   *
   * @param list StormListType
   * @param path string
   * @returns Observable<any[]>
   */
   protected fetchCached(list: StormListType, path: string, options?: any): Observable<any[]> {
    const cached = this.cacheService.read(list);

    if (cached) {
      return of(cached);
    }

    return this.authHttp.get(this.appRoutes.get(path), options).pipe(
      tap((response: any) => this.cacheService.write(list, response)
    ));
  }

  /**
   * Returns an observable for fetching languages.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchLanguage(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.language,
      'newMasterdata.fetch.language.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: LanguageInterface) => new Language(
              item.id,
              item.name,
              item.alternateName,
              item.localeLanguage,
              item.languageType,
              item.iso6391
            )
          );

          const stormList = new StormList(StormListType.language, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching product types.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchProductType(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.productType,
      'newMasterdata.fetch.productType.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: ProductTypeInterface) => new ProductType(item.id, item.name, item.code)
          );

          const stormList = new StormList(StormListType.productType, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching territories.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchTerritory(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.territory,
      'newMasterdata.fetch.territory.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: CountryInterface) => new Country(item)
          );

          const stormList = new StormList(StormListType.territory, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching rating systems.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchRatingSystem(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.ratingSystem,
      'newMasterdata.fetch.ratingSystem.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: RatingSystemInterface) => {
              return this.parseRatingSystemObjectToModel(item);
            }
          );

          const stormList = new StormList(StormListType.ratingSystem, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching rating system reasons.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
   protected fetchRatingSystemReason(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.ratingSystemReason,
      'newMasterdata.fetch.ratingSystemReason.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: RatingSystemReasonInterface) => {
              let ratingSystems = [];

              if (item.ratingSystems) {
                ratingSystems = item.ratingSystems.map(
                  ratingSystem => this.parseRatingSystemObjectToModel(ratingSystem)
                );
              }

              return new RatingSystemReason(item.id, item.name, ratingSystems);
            }
          );

          const stormList = new StormList(StormListType.ratingSystemReason, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching ratings.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchRating(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.rating,
      'newMasterdata.fetch.rating.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: RatingInterface) => new Rating(
              item.id,
              item.name,
              item.description,
              <number>item.ratingSystemId,
              this.parseRatingSystemObjectToModel(item.ratingSystem)
            )
          );

          const stormList = new StormList(StormListType.rating, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching accounts.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchAccount(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.account,
      'newMasterdata.fetch.account.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: AccountInterface) => new Account(item.id, item.name, item.code)
          );

          const stormList = new StormList(StormListType.account, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching companies.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchCompany(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.company,
      'newMasterdata.fetch.productionCompany.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: CompanyInterface) => new Company(item.id, item.name)
          );

          const stormList = new StormList(StormListType.company, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching aspect ratios.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchAspectRatio(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.aspectRatio,
      'newMasterdata.fetch.aspectRatio.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: AspectRatioInterface) => new AspectRatio(item.id, item.name)
          );

          const stormList = new StormList(StormListType.aspectRatio, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching affixes.
   *
   * @return Observable<StormServiceResponseSingle>
   */
  protected fetchAffix(): Observable<StormServiceResponseSingle> {
    const search: UrlSearchParams = new UrlSearchParams();

    search.set('_size', this.appConfig.get('ux.page.listProvider.affix'));

    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.affix,
      'listService.fetchAffix.endpoint',
      search
    ).pipe(
      map(
        (response: any[]) => {
          const responseCollection: any = response.map(
            (item: AffixInterface) => new Affix(item.id, item.type, item.value, item.localizations)
          );

          const stormList: StormServiceResponseSingle = new StormServiceResponseSingle(
            new StormList(StormListType.affix, responseCollection)
          );

          return stormList;
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching functional metadata.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchFunctionalMetadata(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.functionalMetadata,
      'newMasterdata.fetch.functionalMetadata.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any = response.map(
            (data: any) => {
              const functional: FunctionalMetadata = new FunctionalMetadata(data);
              return functional;
            }
          );

          const stormList = new StormList(StormListType.functionalMetadata, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching genres.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchGenre(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.genre,
      'newMasterdata.fetch.genre.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any[] = response.map(
            (item: GenreInterface) => {
              const genre = new Genre(
                item.id, item.name, item.titleType, item.genreType
              );

              if (item.accounts) {
                const accounts = (<AccountInterface[]>item.accounts).map(
                  account => new Account(account.id, account.name, account.code)
                );

                genre.setApplicableAccounts(accounts);
              }

              if (item.territoryAccountUnits) {
                const territoryAccountUnits = (<TerritoryAccountUnitInterface[]>item.territoryAccountUnits).map(
                  terrAccUnit => {
                    terrAccUnit.account = new Account(
                      terrAccUnit.account.id, terrAccUnit.account.name, terrAccUnit.account.code
                    );

                    terrAccUnit.territory = new Country(terrAccUnit.territory);

                    return terrAccUnit;
                  }
                );

                genre.setApplicableTerritoryAccountUnits(territoryAccountUnits);
              }

              return genre;
            }
          );

          const stormList = new StormList(StormListType.genre, responseCollection);
          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching XSLT properties.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchXsltProperty(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.get(
      this.appRoutes.get('listService.fetchXsltProperties.endpoint')
    ).pipe(
      map(
        (response: any) => {
          const responseCollection: XsltPropertyInterface[] = [];

          Object.keys(response).forEach(xsltPropertyName => {
            (<[]>response[xsltPropertyName]).forEach(xsltProperty => {
              responseCollection.push(
                new XsltProperty(Object.assign(xsltProperty, { name: xsltPropertyName }))
              );
            });
          });

          const stormList = new StormList(StormListType.xsltProperty, responseCollection);
          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching dubbing studios.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchDubbingStudios(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.dubbingStudios,
      'newMasterdata.fetch.dubbingStudios.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any = response.map(
            (data: any) => {
              const studio: DubbingStudio = new DubbingStudio(data);
              return studio;
            }
          );

          const stormList = new StormList(StormListType.functionalMetadata, responseCollection);
          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching primary product association.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchPrimaryProductAssociation(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.primaryProductAssociation,
      'newMasterdata.fetch.primaryProductAssociation.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any = response.map(
            (data: any) => {
              const primaryAssociation: PrimaryProductAssociation = new PrimaryProductAssociation(data);
              return primaryAssociation;
            }
          );

          const stormList = new StormList(StormListType.primaryProductAssociation, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for fetching secondary product association.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  protected fetchSecondaryProductAssociation(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.fetchCached(
      StormListType.secondaryProductAssociation,
      'newMasterdata.fetch.secondaryProductAssociation.endpoint'
    )
    .pipe(
      map(
        (response: any[]) => {
          const responseCollection: any = response.map(
            (data: any) => {
              const secondaryAssociation: SecondaryProductAssociation = new SecondaryProductAssociation(data);
              return secondaryAssociation;
            }
          );

          const stormList = new StormList(StormListType.secondaryProductAssociation, responseCollection);

          return new StormServiceResponseSingle(stormList);
        }
      )
    );

    return obs;
  }

  /**
   * Returns a new RatingSystem for the given data.
   *
   * @param data any
   * @returns RatingSystem
   */
  protected parseRatingSystemObjectToModel(data: any): RatingSystem {
    const ratingSystemModel = new RatingSystem(data.id, data.name, data.type);

    if (data.ratingSystemReasons) {
      const ratingSystemReasons =
        (<RatingSystemReasonInterface[]>data.ratingSystemReasons).map(
          ratingSystemReason => {
            let ratingSystems = [];

            if (ratingSystemReason.ratingSystems) {
              ratingSystems = ratingSystemReason.ratingSystems.map(
                ratingSystem => this.parseRatingSystemObjectToModel(ratingSystem)
              );
            }

            return new RatingSystemReason(
              ratingSystemReason.id,
              ratingSystemReason.name,
              ratingSystems
            );
          }
        );

      ratingSystemModel.setRatingSystemReasons(ratingSystemReasons);
    }

    if (data.territories) {
      const territories = (<CountryInterface[]>data.territories).map(
        country => new Country(country)
      );

      ratingSystemModel.setApplicableTerritories(territories);
    }

    if (data.clients) {
      const clients = (<RatingSystemClientInterface[]>data.clients).map(
        client => {
          client.account = new Account(
            client.account.id, client.account.name, client.account.code
          );

          client.territory = new Country(client);

          return client;
        });

      ratingSystemModel.setApplicableClients(clients);
    }

    return ratingSystemModel;
  }
}
