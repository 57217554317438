import { Component } from '@angular/core';

import { RoleManager, RoleManagerMetadataManagement } from '../../helpers/role-manager/role-manager.helper';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-page-role-list',
  template: require('./bolt-page-role-list.html'),
  viewProviders: [
    RoleManager,
  ]
})
export class BoltPageRoleListComponent {

  protected refreshList: boolean = false;
  protected roleMetadataManager: RoleManagerMetadataManagement;
  protected notificationsContainer = notificationsContainer;


  constructor(
    protected roleManager: RoleManager
  ) {
    this.roleManager.getManagedRoleMetadata().subscribe(
      roleMetadataManager => this.roleMetadataManager = roleMetadataManager
    );
  }

  /**
   * Trigger the list refresh.
   *
   * @returns void
   */
  triggerListRefresh(): void {
    this.refreshList = true;
    setTimeout(() => this.refreshList = false, 100);
  }
}
