import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { BoltAppConfigurationListComponent } from './components/bolt-app-configuration-list/bolt-app-configuration-list.component';
import { BoltAppHeartbeatListComponent } from './components/bolt-app-heartbeat-list/bolt-app-heartbeat-list.component';
import { BoltAppPageComponent } from './components/bolt-app-page/bolt-app-page.component';
import { BoltAppToggleListComponent } from './components/bolt-app-toggle-list/bolt-app-toggle-list.component';
import { BoltCpmConfigurationsComponent } from '../cpm/components/bolt-cpm-configurations/bolt-cpm-configurations.component';
import { BoltCpmEventsComponent } from '../cpm/components/bolt-cpm-events/bolt-cpm-events.component';
import { BoltCpmHealthComponent } from '../cpm/components/bolt-cpm-health/bolt-cpm-health.component';
import { BoltMasterDataAccountComponent } from '../masterData/components/account/bolt-master-data-account/bolt-master-data-account.component';
import { BoltPageXsltComponent } from '../xslt/components/bolt-page-xslt/bolt-page-xslt.component';
import { BoltUpcListComponent } from '../upc/components/bolt-upc-list/bolt-upc-list.component';
import { BoltUserListComponent } from '../user/components/bolt-user-list/bolt-user-list.component';
import { BoltUserProfileComponent } from '../user/components/bolt-user-profile/bolt-user-profile.component';

// TODO: Coordinate with the Product Owner about to restore the XSLT edition.
// import { BoltXsltEditorComponent } from '../xslt/components/bolt-xslt-editor/bolt-xslt-editor.component';

import { BoltXsltListComponent } from '../xslt/components/bolt-xslt-list/bolt-xslt-list.component';
import { BoltMasterDataLanguageComponent } from '../masterData/components/language/bolt-master-data-language/bolt-master-data-language.component';
import { BoltMasterDataTerritoryComponent } from '../masterData/components/territory/bolt-master-data-territory/bolt-master-data-territory.component';
import { modulesPath } from '../auth/services/role/modules-path';
import { BoltMasterDataProductionCompaniesComponent } from '../masterData/components/production-companies/production-companies/bolt-master-data-production-companies.component';
import { BoltMasterDataDubbingStudioComponent } from '../masterData/components/dubbing-studio/dubbing-studio.component';


export const applicationRoutes: Routes = [{
  path: 'application',
  component: BoltAppPageComponent,
  children: [
    {
      path: '',
      pathMatch: 'prefix',
      redirectTo: 'heartbeat',
    },
    {
      path: 'admin/users',
      component: BoltUserListComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.users.path,
        title: 'Users',
      },
    },
    {
      path: 'admin/configurations',
      component: BoltAppConfigurationListComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.uiConfiguration.path,
        title: 'Configurations',
      },
    },
    {
      path: 'admin/toggles',
      component: BoltAppToggleListComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.toggle.path,
        title: 'Toggles',
      }
    },
    {
      path: 'admin/xslt',
      component: BoltPageXsltComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.xslt.path,
        title: 'XSLT',
      },
      children: [
        {
          path: '',
          component: BoltXsltListComponent,
        },
        // {
        //   path: 'editor/:xsltId',
        //   component: BoltXsltEditorComponent,
        // },
      ]
    },
    {
      path: 'admin/privileges',
      component: BoltUpcListComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.privilege.path,
        title: 'Privileges',
      },
    },
    {
      path: 'admin/masterdata/languages',
      component: BoltMasterDataLanguageComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.masterData.languages.path,
        title: 'Languages',
      },
    },
    {
      path: 'admin/masterdata/territory',
      component: BoltMasterDataTerritoryComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.masterData.territory.path,
        title: 'Territory',
      },
    },
    {
      path: 'admin/masterdata/account',
      component: BoltMasterDataAccountComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.masterData.account.path,
        title: 'Account',
      },
    },
    {
      path: 'admin/masterdata/production-companies',
      component: BoltMasterDataProductionCompaniesComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware:  modulesPath.admin.masterData.productionCompany.path,
        title: 'Production Companies',
      },
    },
    {
      path: 'admin/masterdata/dubbing-studio',
      component: BoltMasterDataDubbingStudioComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware:  modulesPath.admin.masterData.dubbingStudio.path,
        title: 'Dubbing Studio',
      },
    },
    {
      path: 'cpm',
      children: [
        {
          path: '',
          pathMatch: 'prefix',
          redirectTo: 'health',
        },
        {
          path: 'configurations',
          component: BoltCpmConfigurationsComponent,
          canActivate: [AuthGuard],
          data: {
            boltAuthAware: modulesPath.admin.cpm.configurations.path,
            title: 'Configurations',
          },
        },
        {
          path: 'events',
          component: BoltCpmEventsComponent,
          canActivate: [AuthGuard],
          data: {
            boltAuthAware: modulesPath.admin.cpm.eventsMonitor.path,
            title: 'Events',
          },
        },
        {
          path: 'health',
          component: BoltCpmHealthComponent,
          canActivate: [AuthGuard],
          data: {
            boltAuthAware: modulesPath.admin.cpm.healthCheck.path,
            title: 'Health',
          },
        }
      ]
    },
    {
      path: 'heartbeat',
      component: BoltAppHeartbeatListComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: modulesPath.admin.systemHeartbeat.path,
        title: 'Heartbeat',
      },
    },
    {
      path: 'user',
      component: BoltUserProfileComponent,
      canActivate: [AuthGuard],
      data: {
        boltAuthAware: '*',
        title: 'User',
      },
    },
  ]
}];

export const applicationModuleRouting: ModuleWithProviders = RouterModule.forChild(applicationRoutes);
