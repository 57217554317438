import { isArray as _isArray } from 'lodash';
import { Language } from '@bolt/ui-shared/master-data';

import { Aka } from '../aka/aka.model';
import { Character } from 'app/modules/character/models/character.model';
import { Item } from '../item.model';
import { Localized } from '../localized/localized.model';
import { AssociationList } from '../../product/association-list/association-list.model';


export class Original extends Item {
  protected _akaItems: Aka[];
  protected _localizedItems: Localized[];
  protected _originalLanguageId: number;
  protected _productAssociations: AssociationList;
  protected _requiredLocalizations: boolean;

  constructor(data: any) {
    super();
    this.initialize(data);
  }

  /**
   * Creates an Item with the given Character.
   *
   * @param character Character
   */
  static newFromCharacter(character: Character): Original {
    const data: any = character.getRawObject();
    return new Original(data);
  }

  get akaItems(): Aka[] {
    return this._akaItems;
  }

  get localizedItems(): Localized[] {
    return this._localizedItems;
  }

  get originalLanguageId(): number {
    return this._originalLanguageId;
  }

  get productAssociations(): AssociationList {
    return this._productAssociations;
  }

  set productAssociations(value: AssociationList) {
    this._productAssociations = value;
  }

  get requiredLocalizations(): boolean {
    return this._requiredLocalizations;
  }

  set requiredLocalizations(value: boolean) {
    this._requiredLocalizations = value;
  }

  /**
   * Indicates if it has localized items.
   *
   * @returns boolean
   */
  hasLocalizedItems(): boolean {
    const hasIt: boolean = (this.localizedItems.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has product associations.
   *
   * @returns boolean
   */
  hasProductAssociations(): boolean {
    return this._productAssociations.hasProductAssociations();
  }

  isOriginal(): boolean {
    return true;
  }

  protected getInvalidLanguageReason(language: Language): string {
    const reason: string = String(
      `Invalid language given (${language.id}) for stored original language ID (${this.originalLanguageId}).`
    );

    return reason;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    super.initialize(data);

    this._originalLanguageId = data.originalLanguageId;
    this._requiredLocalizations = (data.requiredLocalizations || false);
    this._productAssociations = new AssociationList(data);

    this.readAkaItems(data);
    this.readLocalizedItems(data);
  }

  protected isCorrectLanguage(language: Language): boolean {
    const isIt: boolean = (language.id === this.originalLanguageId);
    return isIt;
  }

  /**
   * Reads the aka items.
   *
   * @param data any
   * @returns void
   */
  protected readAkaItems(data: any): void {
    this._akaItems = new Array();

    if (_isArray(data.aka) && (data.aka.length > 0)) {
      data.aka.forEach(
        (akaData: any) => {
          const akaItem: Aka = new Aka(akaData);
          this.akaItems.push(akaItem);
        }
      );
    }
  }

  /**
   * Reads the localizes items.
   *
   * @param data any
   * @returns void
   */
  protected readLocalizedItems(data: any): void {
    this._localizedItems = new Array();

    if (_isArray(data.localizations) && (data.localizations.length > 0)) {
      data.localizations.forEach(
        (localizedData: any) => {
          const localizedItem: Localized = new Localized(localizedData);
          this.localizedItems.push(localizedItem);
        }
      );
    }
  }
}
