import { Account, Affix, Country, ProductType } from '@bolt/ui-shared/master-data';
import { isUndefined as _isUndefined } from 'lodash';


export class TalentResponse {
  protected _fullName: string;
  protected _fullNameLocalization: string;
  protected _id: number;
  protected _isLocalization: boolean;
  protected _language: string;
  protected _lastModified: Date;
  protected _lastModifiedBy: string;
  protected _lastName: string;
  protected _lastNameLocalization: string;
  protected _name: string;
  protected _nameLocalization: string;
  protected _middleName: string;
  protected _middleNameLocalization: string;
  protected _prefixId: number | Affix;
  protected _suffixId: number | Affix;

  constructor(attributes?: { [attr: string]: any }) {
    this.setAttributes(attributes);
    this._fullName = this.getFullName();
    this._fullNameLocalization = this.getFullNameLocalization();
  }

  get fullName(): string {
    return this._fullName;
  }

  set fullName(fullName: string) {
    this._fullName = fullName;
  }

  get fullNameLocalization(): string {
    return this._fullNameLocalization;
  }

  set fullNameLocalization(fullNameLocalization: string) {
    this._fullNameLocalization = fullNameLocalization;
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get isLocalization(): boolean {
    return this._isLocalization;
  }

  set isLocalization(isLocalization: boolean) {
    this._isLocalization = isLocalization;
  }

  get language(): string {
    return this._language;
  }

  set language(language: string) {
    this._language = language;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(lastName: string) {
    this._lastName = lastName;
  }

  get lastNameLocalization(): string {
    return this._nameLocalization;
  }

  set lastNameLocalization(lastNameLocalization: string) {
    this._lastNameLocalization = lastNameLocalization;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get nameLocalization(): string {
    return this._nameLocalization;
  }

  set nameLocalization(nameLocalization: string) {
    this._nameLocalization = nameLocalization;
  }

  get middleName(): string {
    return this._middleName;
  }

  set middleName(middleName: string) {
    this._middleName = middleName;
  }

  get middleNameLocalization(): string {
    return this._nameLocalization;
  }

  set middleNameLocalization(middleNameLocalization: string) {
    this._nameLocalization = middleNameLocalization;
  }

  set prefixId(prefixId: number | Affix) {
    this._prefixId = prefixId;
  }

  set suffixId(suffixId: number | Affix) {
    this._suffixId = suffixId;
  }

  /**
   * Gets the locale value for the TalentResponse
   *
   * @throws Error
   * @returns string
   */
  getLocalizationLocale(): string {
    if (!_isUndefined(this._language)) {
      return [this._language, Country.ALL_VALUE, ProductType.ALL_VALUE, Account.ALL_VALUE].join('_');
    } else {
      throw new Error('Language not found');
    }
  }

  /**
   * Sets the values for the attributes given in the 'attributes' object
   *
   * @param attributes any
   * @returns void
   */
  setAttributes(attributes?: { [attr: string]: any }): void {
    if (!_isUndefined(attributes)) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }
  }

  toString(): string {
    return this.name;
  }

  /**
   * Returns the full name using the given separator.
   *
   * @param separator string
   * @returns string
   */
  getFullName(separator: string = ' '): string {
    const prefix = this._prefixId instanceof Affix ? this._prefixId.value : undefined;
    const suffix = this._suffixId instanceof Affix ? this._suffixId.value : undefined;

    return [
      prefix,
      this._name,
      this._middleName,
      this._lastName,
      suffix
    ].join(separator);
  }

  /**
   * Returns the full name localization using the given separator.
   *
   * @param separator string
   * @returns string
   */
  getFullNameLocalization(separator: string = ' '): string {
    if (!this._isLocalization) {
      return;
    }

    const locale = this.getLocalizationLocale();

    const prefix = this._prefixId instanceof Affix ? this._prefixId.localizedValue(locale) : undefined;
    const suffix = this._suffixId instanceof Affix ? this._suffixId.localizedValue(locale) : undefined;

    return [
      prefix,
      this._nameLocalization,
      this._middleNameLocalization,
      this._lastNameLocalization,
      suffix
    ].join(separator);
  }
}
