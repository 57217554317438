import { Component, OnInit, OnDestroy } from '@angular/core';
import { ObservableExecutor, ObservableResponse } from '@bolt/ui-shared/common';
import { Subscription } from 'rxjs';

import { WizardService } from '../../services/wizard/wizard.service';
import { Candidate } from '../../models/wizard/candidate/candidate.model';
import { Credit } from '../../models/credit/credit.model';
import { TypeEnum } from '../../models/wizard/candidate/type/type.enum';
import { Cast } from '../../models/cast/cast.model';
import { Crew } from '../../models/crew/crew.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { CrudActionEnum } from 'app/shared/services/layout-handler/crud-action.enum';

@Component({
  selector: 'bolt-credits-wizard-summary',
  template: require('./bolt-credits-wizard-summary.html'),
  styles: [require('./bolt-credits-wizard-summary.scss')]
})
export class BoltCreditsWizardSummaryComponent implements OnInit, OnDestroy {
  protected progress: number = 0;

  private additionErrors: ObservableResponse[];
  private additionErrorMessages: Map<string, string>;
  private additionObservableExecutor: ObservableExecutor;
  private additionObservableExecutorSubs: Subscription;
  private additionObservablesLength: number;
  private creationErrors: ObservableResponse[];
  private creationErrorMessages: Map<string, string>;
  private creationObservableExecutor: ObservableExecutor;
  private creationObservableExecutorSubs: Subscription;
  private creationObservablesLength: number;
  private readonly conflictMessage: string = '. Conflict error.';
  private layoutHandlerSubscription: Subscription;
  private totalObservables: number;

  constructor(
    private layoutHandler: LayoutHandlerService,
    private wizardService: WizardService
  ) {
    this.additionErrorMessages = new Map();
    this.creationErrorMessages = new Map();
    this.additionErrors = [];
    this.creationErrors = [];
  }

  ngOnInit() {
    this.additionObservableExecutor = this.wizardService.getBulkAddObservableExecutor();
    this.additionObservablesLength = this.wizardService.getPositionNames().length;
    this.creationObservableExecutor = this.wizardService.getCreationObservableExecutor();
    this.creationObservablesLength = this.creationObservableExecutor.getQueueLength();
    this.totalObservables = this.additionObservablesLength + this.creationObservablesLength;
    this.setSubscriptions();
    this.setLayoutHandlerSubscription();
  }

  ngOnDestroy() {
    if (this.creationObservableExecutorSubs) {
      this.creationObservableExecutorSubs.unsubscribe();
    }

    if (this.additionObservableExecutorSubs) {
      this.additionObservableExecutorSubs.unsubscribe();
    }

    if (this.layoutHandlerSubscription) {
      this.layoutHandlerSubscription.unsubscribe();
    }
  }

  /**
   * Get the success message of the result from addition observable.
   *
   * @returns string
   */
   protected getAddedMessage(): string {
    const addedItems: number = this.wizardService.getAdditionObservables().size - this.additionErrors.length;
    const message: string = `${addedItems} credit${addedItems > 1 ? 's were' : ' was'} successfully added`;

    return message;
  }

  /**
   * Get the error message of the result from the given addition observable.
   *
   * @returns string
   */
   protected getAdditionErrorMessageFrom(response: ObservableResponse): string {
    return this.additionErrorMessages.get(response.key);
  }

  /**
   * Get the success message of the result from creation observable.
   *
   * @returns string
   */
   protected getCreatedMessage(): string {
    const createdCredits: number = this.creationObservablesLength - this.creationErrors.length;
    const message: string = `${createdCredits} item${createdCredits > 1 ? 's were' : ' was'} successfully created`;

    return message;
  }

  /**
   * Get the error message of the result from the given creation observable.
   *
   * @returns string
   */
   protected getCreationErrorMessageFrom(response: ObservableResponse): string {
    return this.creationErrorMessages.get(response.key);
  }

  /**
   * Indicates if it has to display the addition success message.
   *
   * @returns boolean
   */
  protected hasDisplayAdditionSuccessMessage(): boolean {
    const itHas: boolean = (this.wizardService.getAdditionObservables().size - this.additionErrors.length) > 0;
    return itHas;
  }

  /**
   * Indicates if it has to display the conflict warning.
   *
   * @returns boolean
   */
  protected hasDisplayConflictWarning(): boolean {
    const itHas: boolean =
      this.hasDisplaySummary() &&
      (
        this.additionErrors.some((error: ObservableResponse) => error.error.is409()) ||
        this.creationErrors.some((error: ObservableResponse) => error.error.is409())
      );

    return itHas;
  }

  /**
   * Indicates if it has to display the creation success message.
   *
   * @returns boolean
   */
  protected hasDisplayCreationSuccessMessage(): boolean {
    const itHas: boolean = (this.creationObservablesLength - this.creationErrors.length) > 0;
    return itHas;
  }

  /**
   * Indicates if it has to display the progress bar.
   *
   * @returns boolean
   */
  protected hasDisplayProgressBar(): boolean {
    return this.layoutHandler.isSaving();
  }

  /**
   * Indicates if it has to display the success message.
   *
   * @returns boolean
   */
  protected hasDisplaySuccessMessage(): boolean {
    const itHas: boolean =
      this.layoutHandler.isReading() &&
      (this.hasDisplayAdditionSuccessMessage() || this.hasDisplayCreationSuccessMessage());

    return itHas;
  }

  /**
   * Indicates if it has to display the summary.
   *
   * @returns boolean
   */
  protected hasDisplaySummary(): boolean {
    return this.layoutHandler.isReading();
  }

  /**
   * Indicates if it has to display the error message.
   *
   * @returns boolean
   */
  protected hasDisplayErrorMessage(): boolean {
    const itHas: boolean = this.additionErrors.length > 0 || this.creationErrors.length > 0;
    return itHas;
  }

  /**
   * Indicates if it is adding.
   *
   * @returns boolean
   */
  protected isAdding(): boolean {
    const itIs: boolean = this.layoutHandler.isSaving() && this.creationObservableExecutor.getQueueLength() === 0;
    return itIs;
  }

  /**
   * Indicates if it is creating.
   *
   * @returns boolean
   */
  protected isCreating(): boolean {
    const itIs: boolean = this.layoutHandler.isSaving() && this.creationObservableExecutor.getQueueLength() > 0;
    return itIs;
  }

  /**
   * Indicates if it is retrying.
   *
   * @returns boolean
   */
  protected isRetrying(): boolean {
    const itIs: boolean =
      this.layoutHandler.isSaving() &&
      this.wizardService.hasErrors();

    return itIs;
  }

  /**
   * Recalculates the observables length after retry.
   *
   * @returns void
   */
  private recalculateLengths(): void {
    this.creationObservablesLength = this.wizardService.getCreationErrors().length;
    this.additionObservablesLength = this.wizardService.getAdditionErrors().length;
    this.totalObservables = this.creationObservablesLength + this.additionObservablesLength;
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  private reset(): void {
    this.progress = 0;
    this.additionErrors = [];
    this.additionErrorMessages = new Map();
    this.creationErrors = [];
    this.creationErrorMessages = new Map();
    this.recalculateLengths();
  }

  /**
   * Set the layout handler subscription for handling retry process.
   *
   * @returns void
   */
  private setLayoutHandlerSubscription(): void {
    this.layoutHandlerSubscription = this.layoutHandler.actionListener.subscribe(
      (layoutStatus: CrudActionEnum) => {
        if (this.isRetrying()) {
          this.reset();
        }
      }
    );
  }

  /**
   * Set the current subscriptions.
   *
   * @returns void
   */
  private setSubscriptions(): void {
    this.setupCreationListener();
    this.setupAdditionListener();
  }

  /**
   * Set up the listener for the addition observable executor.
   *
   * @returns void
   */
  private setupAdditionListener(): void {
    this.additionObservableExecutorSubs = this.additionObservableExecutor.getExecutorListener().subscribe(
      (observableResponse: ObservableResponse) => {
        this.updateProgress();

        if (observableResponse.isError) {
          this.additionErrors.push(observableResponse);
          const talentCandidate: Candidate = this.wizardService.getTalentCandidates()[observableResponse.key];
          const positionCandidate: Candidate = this.wizardService.getPositionCandidates()[observableResponse.key];
          const creditName: string = `"${talentCandidate.getObject().fullName} ${positionCandidate.getObject().name}"`;
          const message: string = `An addition failed for credit ${creditName}${observableResponse.error.is409() ? this.conflictMessage : ''}`;
          this.additionErrorMessages.set(observableResponse.key, message);
        }

        if (this.additionObservableExecutor.getQueueLength() === 0) {
          this.wizardService.setAdditionErrors(this.additionErrors);
        }
      }
    );
  }

  /**
   * Set up the listener for the creation observable executor.
   *
   * @returns void
   */
   private setupCreationListener(): void {
    const creationQueue: Candidate[] = this.wizardService.getCreationCandidates();

    this.creationObservableExecutorSubs = this.creationObservableExecutor.getExecutorListener().subscribe(
      (observableResponse: ObservableResponse) => {
        setTimeout(() => this.updateProgress());

        if (observableResponse.isError) {
          this.creationErrors.push(observableResponse);
          const type: string = observableResponse.key.split('-')[0];

          const candidate: Candidate =
            this.wizardService.getCreationCandidates().find((elem: Candidate) => elem.getKey() === observableResponse.key);

          const message: string = `A creation failed for ${type} "${candidate.getName()}"${observableResponse.error.is409() ? this.conflictMessage : ''}`;
          this.creationErrorMessages.set(observableResponse.key, message);
        } else {
          const candidates: Candidate[] = creationQueue.filter(
            (element: Candidate) => observableResponse.key === element.getKey()
          );

          candidates.forEach(
            (candidate: Candidate) => {
              candidate.setObject(observableResponse.response.item);
              const credit: Credit = this.wizardService.getCreditsCandidates()[candidate.getIndex()];

              switch (candidate.getType().getValue()) {
                case TypeEnum.character:
                  (<Cast>credit).setCharacterId(observableResponse.response.item.id);
                  break;
                case TypeEnum.role:
                  (<Crew>credit).setRoleId(observableResponse.response.item.id);
                  break;
                case TypeEnum.talent:
                  credit.setTalentId(observableResponse.response.item.id);
                  break;
                default:
                  throw new ErrorHelper('Invalid candidate type for creating step.');
              }
            }
          );
        }

        if (this.creationObservableExecutor.getQueueLength() === 0) {
          const isRetrying: boolean = this.isRetrying();
          this.wizardService.setCreationErrors(this.creationErrors);
          this.wizardService.startBulkAdd(isRetrying);
        }
      }
    );
  }

  /**
   * Updates the current progress.
   *
   * @returns void
   */
  private updateProgress(): void {
    let pendingObservables: number;

    if (this.creationObservableExecutor.getQueueLength() > 0) {
      pendingObservables = this.additionObservablesLength + this.creationObservableExecutor.getQueueLength();
    } else {
      pendingObservables = this.additionObservableExecutor.getQueueLength();
    }

    const doneObservables: number = this.totalObservables - pendingObservables;
    this.progress = Math.floor((doneObservables * 100) / this.totalObservables);
  }
}
