import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';

import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';


@Component({
  selector: 'bolt-cpm-configurations-mapping-table',
  template: require('./bolt-cpm-configurations-mapping-table.html'),
  styles: [require('./bolt-cpm-configurations-mapping-table.scss')]
})
export class BoltCpmConfigurationsMappingTableComponent implements OnChanges {
  @Input() data: Array<[string, any]>;
  @Input() filter: string;

  protected pager: MemoryPager;

  constructor(protected appConfig: AppConfigProvider) {
    this.filter = '';
    this.pager = new MemoryPager(this.appConfig.get('ux.dataTables.pageSize'));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.filter)) {
      this.pager.setFilterCriteria(this.getFilteringCriteria());
    }

    if (_isObject(changes.data)) {
      this.pager.setRecords(this.data);
    }
  }

  /**
   * Returns the filtering criteria for server mapping.
   *
   * @returns CallableFunction
   */
  protected getFilteringCriteria(): CallableFunction {
    const criteria: CallableFunction = (entry: [string, any]) => {
      const matched: boolean = entry[0].includes(this.filter) || String(entry[1]).includes(this.filter);
      return matched;
    };

    return criteria;
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }
}
