import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';

import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { FormFactoryService } from '../../services/form-factory/form-factory.service';
import { MetadataManagerService } from '../../services/item/metadata-manager/metadata-manager.service';
import { Original as OriginalItem } from '../../models/item/original/original.model';
import { RoleCapabilitiesHandler } from '../../services/role-capabilities-handler/role-capabilities-handler';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-cat-item-original-data',
  template: require('./bolt-cat-item-original-data.html'),
  styles: [require('./bolt-cat-item-original-data.scss')]
})
export class BoltCatItemOriginalDataComponent extends StormComponent implements OnInit {
  protected editionForm: FormGroup;
  protected lockingFlag: boolean;
  protected savingFlag: boolean;
  protected showDataFlag: boolean;

  constructor(
    protected formConfig: FormConfigService,
    protected formFactory: FormFactoryService,
    protected itemManager: MetadataManagerService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.createForm();
  }

  get item(): OriginalItem {
    return this.itemManager.original;
  }

  /**
   * Indicates if the current user can edit.
   *
   * @returns boolean
   */
  protected canEdit(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnOriginal(this.item.type.value, ActionTypeEnum.write);
  }

  /**
   * Creates the form.
   *
   * @returns void
   */
  protected createForm() {
    this.editionForm = this.formFactory.buildOriginalItemEditionForm(this.item);

    if (!this.canEdit() || this.item.lockingStatus.isTruthy()) {
      this.editionForm.disable();
    } else {
      this.editionForm.enable();
    }
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength: number = this.formConfig.get(`cat.fields.${field}.maxLength`);
    return maxLength;
  }

  /**
   * Indicates if it has to disable the locking button.
   *
   * @returns boolean
   */
  protected hasBlockLocking(): boolean {
    const hasIt: boolean = (
      this.hasDisplayProcessingSpinner() ||
      this.editionForm.invalid ||
      !this.showDataFlag
    );

    return hasIt;
  }

  /**
   * Indicates if it has to disable the display data button.
   *
   * @returns boolean
   */
  protected hasBlockDisplayData(): boolean {
    return this.hasDisplayProcessingSpinner();
  }

  /**
   * Indicates if it has to disable the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const hasIt: boolean = (
      this.hasDisplayProcessingSpinner() ||
      this.editionForm.invalid ||
      this.editionForm.disabled ||
      !this.showDataFlag
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display data section.
   *
   * @returns boolean
   */
  protected hasDisplayData(): boolean {
    return this.showDataFlag;
  }

  /**
   * Indicates if it has to display the locking status button.
   *
   * @returns boolean
   */
  protected hasDisplayLockingStatusButton(): boolean {
    const hasIt: boolean = this.roleCapabilitiesHandler.canToggleOriginalLockingStatusOf(
      this.item.lockingStatus.locked,
      this.item.type.value
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display processing spinner.
   *
   * @returns boolean
   */
  protected hasDisplayProcessingSpinner(): boolean {
    const hasIt: boolean = (this.lockingFlag || this.savingFlag);
    return hasIt;
  }

  /**
   * Initialize the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.showDataFlag = true;

    this.turnOffLockingFlag();
    this.turnOffSavingFlag();
  }

  /**
   * Changes the locked state of the item.
   *
   * @returns void
   */
  protected toggleItemLockedState(): void {
    this.turnOnLockingFlag();

    this.itemManager.toggleOriginalLockedState(
      () => {
        this.toggleFormState();
        this.turnOffLockingFlag();
      },
      () => {
        this.turnOffLockingFlag();
      }
    );
  }

  /**
   * Toggles the form state.
   *
   * @returns void
   */
  protected toggleFormState(): void {
    if (this.editionForm.disabled) {
      this.editionForm.enable();
    } else {
      this.editionForm.disable();
    }
  }

  /**
   * Toggles the value of showData.
   *
   * @returns void
   */
  protected toggleShowData(): void {
    this.showDataFlag = !this.showDataFlag;
  }

  /**
   * Turns off the locking flag.
   *
   * @returns void
   */
  protected turnOffLockingFlag(): void {
    this.lockingFlag = false;
  }

  /**
   * Turns off the saving flag.
   *
   * @returns void
   */
  protected turnOffSavingFlag(): void {
    this.savingFlag = false;
  }

  /**
   * Turns on the locking flag.
   *
   * @returns void
   */
  protected turnOnLockingFlag(): void {
    this.lockingFlag = true;
  }

  /**
   * Turns on the saving flag.
   *
   * @returns void
   */
  protected turnOnSavingFlag(): void {
    this.savingFlag = true;
  }

  /**
   * Saves the item original data.
   *
   * @returns void
   */
  protected save(): void {
    const data: any = {
      name: this.editionForm.get('_originalName').value,
      originalLanguageId: this.editionForm.get('_originalLanguage').value,
      notes: this.editionForm.get('_notes').value,
      phonetic: this.editionForm.get('_phonetic').value,
      requiredLocalizations: Boolean(this.editionForm.get('_requiredLocalizations').value)
    };

    this.turnOnSavingFlag();
    this.toggleFormState();

    this.itemManager.updateOriginal(
      data,
      () => {
        this.toggleFormState();
        this.turnOffSavingFlag();
      },
      () => {
        this.toggleFormState();
        this.turnOffSavingFlag();
      }
    );
  }
}
