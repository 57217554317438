import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { Language } from '@bolt/ui-shared/master-data';
import { Subscription } from 'rxjs';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';

import { Category } from '../../../models/category/category.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Filters } from './filters/filters.model';
import { ItemService } from '../../item/item.service';
import { Localized as LocalizedItem } from '../../../models/item/localized/localized.model';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { Original as OriginalItem } from '../../../models/item/original/original.model';
import { Product } from '../../../models/product/product.model';
import { RoleCapabilitiesHandler } from '../../role-capabilities-handler/role-capabilities-handler';
import { Type } from 'app/modules/cat/models/type/type.model';
import { Unit } from '../../../models/unit/unit.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Injectable({
  providedIn: 'root',
})
export class ManagerService {
  protected _filters: Filters;
  protected _loadingLocalizations: boolean;
  protected _pager: MemoryPager;
  protected _product: Product;
  protected backupedUnits: Unit[];

  constructor(
    protected appConfig: AppConfigProvider,
    protected itemService: ItemService,
    protected notificationService: NotificationService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    this.initialize();
  }

  get filters(): Filters {
    return this._filters;
  }

  get pager(): MemoryPager {
    return this._pager;
  }

  get product(): Product {
    return this._product;
  }

  get units(): Unit[] {
    return this.pager.getCurrentPage();
  }

  /**
   * Adds a new unit for the given original and localized items.
   *
   * @param original Original
   * @param localized Localized
   * @returns void
   */
  addNewUnitFor(original: OriginalItem, localized?: LocalizedItem): void {
    if (this.isOriginalItemStored(original)) {
      throw new ErrorHelper('The item already exists in the list.');
    } else {
      this.backupedUnits.push(new Unit(original, localized));
      this.sortBackupedUnits();
      this.resolveFilters(false);
      this.trySetPageNumberFor(original);
    }
  }

  /**
   * Indicates if it can edit the localized item in the given unit.
   *
   * @param unit Unit
   * @returns boolean
   */
  canEditLocalizedItemIn(unit: Unit): boolean {
    const localized: LocalizedItem = unit.localized;
    const canCreateItem: boolean = !unit.isComplete();
    const canEditUnlockedItem: boolean = unit.isComplete() && !localized.lockingStatus.locked;

    const canEditLockedItem: boolean =
      unit.isComplete() &&
      localized.lockingStatus.locked &&
      this.roleCapabilitiesHandler.canToggleLocalizedLockingStatusOf(localized.lockingStatus.locked);

    const canIt: boolean = canCreateItem || canEditLockedItem || canEditUnlockedItem;

    return canIt;
  }

  /**
   * Indicates if the user can edit some localized item in the current page.
   *
   * @returns boolean
   */
  canEditSomeLocalizedOnCurrentPage(): boolean {
    const hasPrivilege: boolean =
      this.roleCapabilitiesHandler.hasPrivilegeOnLocalizedItemList(ActionTypeEnum.write);

    let canIt: boolean;

    if (hasPrivilege && this.filters.isLanguageDefined()) {
      canIt = this.units.some(
        (unit: Unit) => {
          return this.canEditLocalizedItemIn(unit);
        }
      );
    } else {
      canIt = false;
    }

    return canIt;
  }

  /**
   * Indicates if the current user can edit some localized locking status of the current page.
   *
   * @returns boolean
   */
  canEditSomeLocalizedLockingStatusOnCurrentPage(): boolean {
    const canIt: boolean = this.units.some(
      (unit: Unit) => {
        const localized: LocalizedItem = unit.localized;

        const canToggleLocalizedLockingStatus: boolean =
          unit.isComplete() &&
          this.filters.isLanguageDefined() &&
          this.roleCapabilitiesHandler.canToggleLocalizedLockingStatusOf(localized.lockingStatus.isTruthy());

        return canToggleLocalizedLockingStatus;
      }
    );

    return canIt;
  }

  /**
   * Indicates if the current user can edit some original locking status of the current page.
   *
   * @returns boolean
   */
  canEditSomeOriginalLockingStatusOnCurrentPage(): boolean {
    const canIt: boolean = this.units.some(
      (unit: Unit) => {
        const original: OriginalItem = unit.original;

        const canToggleOriginalLockingStatus: boolean =
          this.roleCapabilitiesHandler.canToggleOriginalLockingStatusOf(original.lockingStatus.isTruthy());

        return canToggleOriginalLockingStatus;
      }
    );

    return canIt;
  }

  /**
   * Indicates if the user can edit some original item in the current page.
   *
   * @returns boolean
   */
  canEditSomeOriginalOnCurrentPage(): boolean {
    const hasPrivilege: boolean =
      this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.write);

    let canIt: boolean;

    if (hasPrivilege) {
      canIt = this.units.some(
        (unit: Unit) => {
          return this.canEditOriginalItemIn(unit);
        }
      );
    } else {
      canIt = false;
    }

    return canIt;
  }

  /**
   * Indicates if it can edit the original item in the given unit.
   *
   * @param unit Unit
   * @returns boolean
   */
  canEditOriginalItemIn(unit: Unit): boolean {
    const original: OriginalItem = unit.original;
    const canEditUnlockedItem: boolean = !original.lockingStatus.locked;

    const canEditLockedItem: boolean =
    original.lockingStatus.locked &&
    this.roleCapabilitiesHandler.canToggleOriginalLockingStatusOf(original.lockingStatus.locked);

    const canIt: boolean = canEditUnlockedItem || canEditLockedItem;

    return canIt;
  }

  /**
   * Filters by the given category in the stored units.
   *
   * @param category Category
   * @returns void
   */
  filterByCategory(category: Category): void {
    this.filters.setCategory(category);
    this.resolveFilters(false);
  }

  /**
   * Filters by the given language in the stored units.
   *
   * @param language Language
   * @returns void
   */
  filterByLanguage(language: Language): void {
    this._loadingLocalizations = true;

    this.filters.setLanguage(language);
    this.deleteLocalizedItems();

    this.retrieveLocalizedItems(
      undefined,
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to fetch the localizations', error, notificationsContainer.cat.key);
      },
      () => {
        this._loadingLocalizations = false;
      }
    );
  }

  /**
   * Filters by the given name in the stored units.
   *
   * @param name string
   * @returns void
   */
  filterByName(name: string): void {
    this.filters.setName(name);
    this.resolveFilters(false);
  }

  /**
   * Gets the locale for the defined language in the filter.
   *
   * @returns string
   */
  getLocaleForDefinedLanguage(): string {
    if (this.filters.isLanguageDefined()) {
      const locale: string = `${this.filters.getLanguage().localeLanguage}_*_*_*`;
      return locale;
    }
  }

  /**
   * Gets an Unit with the given data.
   *
   * @param itemId number
   * @param itemType Type
   * @returns Unit
   */
  getUnit(itemId: number, itemType: Type): Unit {
    const storedUnit: Unit = this.backupedUnits.find(
      (unit: Unit) => {
        const found: boolean = ((unit.original.id === itemId) && unit.original.type.isEqualsTo(itemType));
        return found;
      }
    );

    return storedUnit;
  }

  /**
   * Indicates if there are units.
   *
   * @returns boolean
   */
  hasUnits(): boolean {
    return this.pager.hasRecords();
  }

  /**
   * Indicates if it has a stored product.
   *
   * @returns boolean
   */
  hasProduct(): boolean {
    return _isObject(this.product);
  }

  /**
   * Indicates if it is loading localizations.
   *
   * @returns boolean
   */
  isLoadingLocalizations(): boolean {
    return this._loadingLocalizations;
  }

  /**
   * Removes the empty localizations.
   *
   * @returns void
   */
  removeEmptyLocalizations(): void {
    this.backupedUnits.forEach(
      (unit: Unit) => {
        if (unit.isComplete() && _isUndefined(unit.localized.id)) {
          unit.resetLocalized();
        }
      }
    );
  }

  /**
   * Removes the category filter.
   *
   * @returns void
   */
  removeCategoryFilter(): void {
    this.filters.removeCategory();
    this.resolveFilters(false);
  }

  /**
   * Removes the category filter.
   *
   * @returns void
   */
  removeLanguageFilter(): void {
    this.filters.removeLanguage();
    this.deleteLocalizedItems();
    this.resolveFilters(true);
  }

  /**
   * Removes the category filter.
   *
   * @returns void
   */
  removeNameFilter(): void {
    this.filters.removeName();
    this.resolveFilters(false);
  }

  /**
   * Returns a subscription for retrieving the units.
   *
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  retrieveUnits(onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): Subscription {
    const subs: Subscription = this.itemService.fetch(
      this.product.type.value,
      this.product.id,
      (items: OriginalItem[]) => {
        this.storeOriginalItems(items);
        this.sortBackupedUnits();

        if (this.filters.isLanguageDefined()) {
          this.deleteLocalizedItems();
          this.retrieveLocalizedItems(onSuccessDo, onErrorDo, finallyDo);
        } else {
          this.resolveFilters(true);
          onSuccessDo();
        }
      },
      onErrorDo,
      () => {
        if (finallyDo) {
          finallyDo();
        }
      }
    );

    return subs;
  }

  /**
   * Set the product and reset itself.
   *
   * @param product Product
   * @param shouldReset boolean
   * @returns void
   */
  setProductAndReset(product: Product, shouldReset: boolean = true): void {
    this._product = product;

    if (shouldReset) {
      this.reset();
    }
  }

  /**
   * Deletes the stored localized items.
   *
   * @returns void
   */
  protected deleteLocalizedItems(): void {
    if (this.hasBackupedUnits()) {
      this.backupedUnits.forEach(
        (unit: Unit) => {
          unit.resetLocalized();
        }
      );
    }

    if (this.pager.hasRecords()) {
      this.pager.records.forEach(
        (unit: Unit) => {
          unit.resetLocalized();
        }
      );
    }
  }

  /**
   * Returns the comparison criteria for items with units.
   *
   * @returns CallableFunction
   */
  protected getComparisonCriteria(): CallableFunction {
    const criteria: CallableFunction = (unit: Unit, item: OriginalItem) => {
      return unit.original.id === item.id;
    };

    return criteria;
  }

  /**
   * Returns the criteria for the filter a unit.
   *
   * @returns any
   */
  protected getFilterCriteria(item: LocalizedItem): any {
    const criteria: CallableFunction = (unit: Unit) => {
      const matched: boolean = (unit.original.id === item.rootId && this.getLocaleForDefinedLanguage() === item.locale);
      return matched;
    };

    return criteria;
  }

  /**
   * Returns the criteria for sorting units.
   *
   * @returns any
   */
  protected getSortingCriteria(): any {
    const criteria: CallableFunction = (unitA: Unit, unitB: Unit) => {
      const typeA: string = unitA.original.type.toString();
      const typeB: string = unitB.original.type.toString();

      if (typeA > typeB) {
        return 1;
      } else if (typeA < typeB) {
        return -1;
      } else {
        const categoryA: string = unitA.original.category.toString();
        const categoryB: string = unitB.original.category.toString();

        if (categoryA > categoryB) {
          return 1;
        } else if (categoryA < categoryB) {
          return -1;
        } else {
          const nameA: string = unitA.original.name.toLowerCase();
          const nameB: string = unitB.original.name.toLowerCase();

          if (nameA > nameB) {
            return 1;
          } else if (nameA < nameB) {
            return -1;
          } else {
            return 0;
          }
        }
      }
    };

    return criteria;
  }

  /**
   * Indicates if it has backuped units.
   *
   * @returns boolean
   */
  protected hasBackupedUnits(): boolean {
    const hasIt: boolean = (this.backupedUnits.length > 0);
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this._filters = new Filters();
    this._loadingLocalizations = false;
    this._pager = new MemoryPager(this.appConfig.get('ux.page.cat.fetchPageSize'));

    this.reset();
  }

  /**
   * Indicates if the given original item is stored.
   *
   * @param item OriginalItem
   * @returns boolean
   */
  protected isOriginalItemStored(item: OriginalItem): boolean {
    const storedUnit: Unit = this.backupedUnits.find(
      (unit: Unit) => {
        const found: boolean = ((unit.original.id === item.id) && unit.original.type.isEqualsTo(item.type));
        return found;
      }
    );

    return _isObject(storedUnit);
  }

  /**
   * Reset the units.
   *
   * @returns void
   */
  protected reset(): void {
    this.backupedUnits = new Array();

    this.filters.reset();
    this.pager.reset();
  }

  /**
   * Resolves the filters.
   *
   * @param shouldPreservePage boolean
   * @returns void
   */
  protected resolveFilters(shouldPreservePage: boolean): void {
    const oldPage: number = this.pager.pageNumber;

    if (this.hasBackupedUnits() && this.filters.isAnyoneDefined()) {
      const units: Unit[] = new Array();

      this.backupedUnits.forEach(
        (unit: Unit) => {
          if (this.filters.canBeAppliedOn(unit)) {
            units.push(unit);
          }
        }
      );

      this.pager.setRecords(units);
    } else {
      this.pager.setRecords(this.backupedUnits);
    }

    if (shouldPreservePage) {
      this.trySetPageNumber(oldPage);
    }
  }

  /**
   * Retrieves the localized items for the stored language and stores them. Returns the related subscription.
   *
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  protected retrieveLocalizedItems(
    onSuccessDo?: CallableFunction,
    onErrorDo?: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.itemService.fetchLocalization(
      this.product.type.value,
      this.product.id,
      this.getLocaleForDefinedLanguage(),
      (items: LocalizedItem[]) => {
        this.storeLocalizedItems(items);
        this.resolveFilters(true);

        if (onSuccessDo) {
          onSuccessDo();
        }
      },
      (error: ErrorHelper) => {
        if (onErrorDo) {
          onErrorDo(error);
        }
      },
      () => {
        if (finallyDo) {
          finallyDo();
        }
      }
    );

    return subs;
  }

  /**
   * Sorts the backed up units.
   *
   * @returns void
   */
  protected sortBackupedUnits(): void {
    this.backupedUnits.sort(this.getSortingCriteria());
  }

  /**
   * Stores the given localized items.
   *
   * @param items Localized[]
   * @returns void
   */
  protected storeLocalizedItems(items: LocalizedItem[]): void {
    items.forEach(
      (item: LocalizedItem) => {
        const targetUnit: Unit = this.backupedUnits.find(this.getFilterCriteria(item));
        if (targetUnit) {
          targetUnit.setLocalized(item);
        }
      }
    );
  }

  /**
   * Stores the given original items.
   *
   * @param items Original[]
   * @returns void
   */
  protected storeOriginalItems(items: OriginalItem[]): void {
    this.backupedUnits = new Array();

    items.forEach(
      (item: OriginalItem) => {
        const unit: Unit = new Unit(item);
        this.backupedUnits.push(unit);
      }
    );
  }

  /**
   * Tries to set the correct page for the given number, if there is an error
   * set the first page as the current page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected trySetPageNumber(pageNumber: number): void {
    try {
      this.pager.setPageNumber(pageNumber);
    } catch (error) {
      this.pager.setPageNumber(0);
    }
  }

  /**
   * Tries to set the correct page for the given item.
   *
   * @param originalItem OriginalItem
   * @returns void
   */
  protected trySetPageNumberFor(originalItem: OriginalItem): void {
    try {
      this.pager.setPageNumberFor(originalItem, this.getComparisonCriteria());
    } catch (error) {
      this.pager.setPageNumber(0);
    }
  }
}
