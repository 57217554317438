import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';

import { AuthHelper } from '../../helpers/auth/auth.helper';
import { Status } from '../../models/status/status.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { UserManager } from 'app/modules/user/helpers/user-manager.helper';


@Component({
  selector: 'bolt-auth-login',
  template: require('./bolt-auth-login.html'),
  styles: [require('./bolt-auth-login.scss')]
})
export class BoltAuthLoginComponent implements OnInit, OnDestroy {
  protected readonly authenticatingLegend: string = 'Validating session, please wait...';
  protected readonly expiredLegend: string = 'Your BOLT session is invalid or has expired.';
  protected readonly welcomeLegend: string = 'Welcome to BOLT.';

  protected authStatus: Status;
  protected expiredSession: boolean;
  protected loginUrl: string;
  protected redirectAuthenticatedHandler: any;
  protected supportPageUrl: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected authHelper: AuthHelper,
    protected location: Location,
    protected userManager: UserManager
  ) {
    this.loginUrl = this.authHelper.getLoginUrl();
    this.supportPageUrl = this.authHelper.getSupportPageUrl();
  }

  ngOnDestroy() {
    clearTimeout(this.redirectAuthenticatedHandler);
  }

  ngOnInit() {
    this.authStatus = Status.newAuthenticating();

    this.userManager.fetchAuthenticatedUser().subscribe(
      (serviceResponse: StormServiceResponseSingle) => {
        this.authStatus = Status.newAuthenticated();
        this.authHelper.setAuthenticatedUser(serviceResponse.item);

        this.redirectAuthenticatedHandler = setTimeout(
          () => { this.location.back(); },
          this.appConfig.get('auth.redirectAuthenticatedUserIn')
        );
      },
      () => {
        this.authStatus = Status.newNotAuthenticated();
        this.expiredSession = this.authHelper.expiredSession();
      }
    );
  }
}
