import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject, isUndefined as _isUndefined, isEmpty as _isEmpty } from 'lodash';
import { Subscription } from 'rxjs';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Time } from 'app/modules/common/models/time.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { EpisodeMetadata } from '../../models/episode-metadata.model';
import { FeatureMetadata } from '../../models/feature-metadata.model';
import { SeasonMetadata } from '../../models/season-metadata.model';
import { SeriesMetadata } from '../../models/series-metadata.model';
import { TitleService } from '../../services/title.service';
import { TitleLocalizationService } from '../../services/title-localization.service';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { ProductLayoutHelper } from '../../helpers/product-layout/product-layout.helper';
import { TypeEnum as LocaleType } from 'app/modules/common/models/locale/type/type.enum';
import { MetadataCloneService } from 'app/modules/clone/services/metadata-clone/metadata-clone.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LocalizationManagerAction } from '../bolt-title-localization-manager/localization-manager-action.enum';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { LocalizationSpecific } from '../../models/localization-specific.enum';
import { EpisodeAttributes } from '../../models/title/episode/episode-attributes.enum';
import { TitleAttributes } from '../../models/title/title-attributes.enum';
import { BoltRatingService } from '../../services/bolt-rating.service';
import { Account } from '@bolt/ui-shared/master-data';


@Component({
  selector: 'bolt-title-localization-details',
  template: require('./bolt-title-localization-details.component.html'),
  styles: [require('./bolt-title-localization-details.scss')]
})
export class BoltTitleLocalizationDetailsComponent extends StormComponent implements OnChanges, OnDestroy {
  // TODO use Title model when -metadata models are removed
  @Input() localization: EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata;
  @Input() useComputed: boolean;
  @Input() showLocaleSection: boolean;
  @Input() allowLocaleActions: boolean;
  @Input() allowActions: boolean;

  @Output('localeChanged') localeChangeEvent: EventEmitter<any>;
  @Output('mouseOverMessage') mouseOverMessageEvent: EventEmitter<string>;
  @Output() localizationRemove: EventEmitter<undefined>;

  metadataOnReadOnlyMode: boolean;
  localeOnReadOnlyMode: boolean;
  attributes: string[];
  confirmDeleteLocalizationModal: NgbModalRef;
  editAction: LocalizationManagerAction = LocalizationManagerAction.edit;
  cloneMetadataManagerVisibility: boolean;
  cloneSeasonMetadataManagerVisibility: boolean;
  localeManagerVisibility: boolean;
  showEditHistory: boolean;
  isCloning: boolean;
  isCloningSeason: boolean;

  protected subscriptions: Subscription;
  protected computedLocalization: EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected metadataCloneService: MetadataCloneService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected titleService: TitleService,
    protected titleLocalizationService: TitleLocalizationService,
    protected boltRatingService: BoltRatingService
  ) {
    super();

    this.subscriptions = new Subscription();
    this.localeChangeEvent = new EventEmitter();
    this.mouseOverMessageEvent = new EventEmitter();
    this.localizationRemove = new EventEmitter();
    this.showLocaleSection = false;
    this.allowLocaleActions = true;
    this.useComputed = false;
    this.allowActions = false;
    this.cloneMetadataManagerVisibility = false;
    this.cloneSeasonMetadataManagerVisibility = false;
    this.localeManagerVisibility = false;
    this.isCloning = false;
    this.isCloningSeason = false;
    this.showEditHistory = false;
  }

  get metadata(): EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata {
    return this.useComputed ? this.computedLocalization : this.localization;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.localization &&
      !_isUndefined(changes.localization.currentValue)
    ) {
      if (this.useComputed) {
        this.fetchComputed();
        this.setAttributes();
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Sets the attributes that can be edited depending on the current locale group
   *
   * @returns void
   */
  setAttributes(): void {
    this.attributes = [];

    switch (this.localization?.localeObject?.type.value) {
      case LocaleType.language:
        this.attributes.push(TitleAttributes.title);
        this.attributes.push(TitleAttributes.altTitle);
        this.attributes.push(TitleAttributes.shortSynopsis);
        this.attributes.push(TitleAttributes.mediumSynopsis);
        this.attributes.push(TitleAttributes.fullSynopsis);

        if (!this.localization.isSeries()) {
          this.attributes.push(FeatureAttributes.keywords);
        }

        if (this.localization.isFeature()) {
          this.attributes.push(FeatureAttributes.shortTitle);
        }

      break;

      case LocaleType.territory:
        this.attributes.push(FeatureAttributes.functionalMetadata);
        this.attributes.push(FeatureAttributes.ratingId);
        this.attributes.push(FeatureAttributes.ratingSystemReasonId);
        this.attributes.push(FeatureAttributes.ratingReason);

        this.attributes.push(FeatureAttributes.runtimeSeconds);
        this.attributes.push(FeatureAttributes.ratingFilingNumberKmrb);
        this.attributes.push(FeatureAttributes.onairPlatformKcc);
        this.attributes.push(FeatureAttributes.onairDateKcc);

        if (this.localization.isEpisode() || this.localization.isFeature()) {
          this.attributes.push(FeatureAttributes.homeEntRatingId);
          this.attributes.push(FeatureAttributes.homeEntRatingSystemReasonId);
          this.attributes.push(FeatureAttributes.homeEntRatingReason);
        }

        if (this.localization.isEpisode()) {
          this.attributes.push(EpisodeAttributes.firstAirReleaseDate);
          this.attributes.push(EpisodeAttributes.localAirRunningOrder);
        }

        if (this.localization.isFeature()) {
          this.attributes.push(FeatureAttributes.firstDigitalRelease);
          this.attributes.push(FeatureAttributes.firstPhysicalRelease);
          this.attributes.push(FeatureAttributes.theatricalReleaseDate);
          this.attributes.push(FeatureAttributes.genreId);
        }

      break;

      case LocaleType.account:
        if (!this.localization.isSeason()) {
          this.attributes.push(FeatureAttributes.functionalMetadata);
        }

        if (!this.localization.isEpisode()) {
          this.attributes.push(FeatureAttributes.genreId);
          this.attributes.push(FeatureAttributes.primaryProductAssociation);
          this.attributes.push(FeatureAttributes.secondaryProductAssociation);
        }
      break;

      default:
    }
  }

  /**
   * Checks if the current locale have the 'all' value for language, product type and account
   *
   * @returns boolean
   */
  isLanguageProductTypeAndAccountAll(): boolean {
    return this.boltRatingService.isLanguageProductTypeAndAccountAll(this.localization);
  }

  /**
   * Clones the current metadata to the given data (episodes or seasons).
   *
   * @param data any
   * @returns void
   */
  cloneMetadata(data: any): void {
    this.isCloning = true;
    const message: string = 'The clone request was successfully sent to server and the cloning is being processed.';

    this.notificationService.handleNotice(message);

    this.metadataCloneService.cloneMetadataToTarget(
      this.metadata.type,
      this.metadata.id,
      this.metadata.locale,
      data.candidates,
      data.fields,
      (response: StormServiceResponseSingle) => {
        this.isCloning = false;
        this.notificationService.handleNotice('The cloning process was successfully completed.');
      },
      (error: ErrorHelper) => {
        this.isCloning = false;
        this.notificationService.handleError('Failed trying to clone metadata.', error);
      }
    );
  }

  /**
   * Close the clone metadata manager.
   *
   * @returns void
   */
  closeCloneMetadataManager(): void {
    this.cloneMetadataManagerVisibility = false;
  }

  /**
   * Close the clone season metadata manager.
   *
   * @returns void
   */
  closeCloneSeasonMetadataManager(): void {
    this.cloneSeasonMetadataManagerVisibility = false;
  }

  /**
   * Close the edit history modal.
   *
   * @returns void
   */
  closeEditHistory(): void {
    this.showEditHistory = false;
  }

  /**
   * Close the locale manager side content.
   *
   * @returns void
   */
  closeLocaleManager(): void {
    this.localeManagerVisibility = false;
  }

  /**
   * Indicates if it has to display the clone metadata for Seasons button.
   *
   * @returns boolean
   */
  hasDisplayCloneSeasonMetadataButton(): boolean {
    const hasIt: boolean =
      !this.isMoratoriumTitle() &&
      this.capabilitiesManager.hasUserPrivilegeOn(this.getCapabilityPath(), [ActionTypeEnum.write])
      && this.metadata.isSeason()
      && this.metadata.localeObject.type.isAccount()
      ;

    return hasIt;
  }

  /**
   * Indicates if it has to display the clone metadata button.
   *
   * @returns boolean
   */
  hasDisplayCloneMetadataButton(): boolean {
    const hasIt: boolean =
      !this.isMoratoriumTitle() &&
      this.capabilitiesManager.hasUserPrivilegeOn(this.getCapabilityPath(), [ActionTypeEnum.write]) &&
      this.metadata.isEpisode();

    return hasIt;
  }

  /**
   * Indicates if it has to display the delete localization button.
   *
   * @returns boolean
   */
  hasDisplayDeleteLocalization(): boolean {
    if (this.metadata.localeObject.type.isAccount()) {
      return false;
    }

    let groupName: string;
    switch (this.metadata.localeObject.type.value) {
      case LocaleType.language:
        groupName = LocalizationSpecific.LANGUAGE;
        break;
      case LocaleType.territory:
        groupName = LocalizationSpecific.TERRITORY;
        break;
      default:
        break;
    }

    const isLocalizationEmpty: boolean =
      !this.productLayoutHelper.hasGroupAttributes(this.metadata.type, this.localization, groupName, false);

    const hasIt: boolean =
      isLocalizationEmpty &&
      !this.isMoratoriumTitle() &&
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.details.locale.path, [ActionTypeEnum.delete]);

    return hasIt;
  }

  /**
   * Indicates if it has to display the edit locale button.
   *
   * @returns boolean
   */
  hasDisplayEditLocale(): boolean {
    const hasIt: boolean =
      !this.isMoratoriumTitle() &&
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.details.locale.path, [ActionTypeEnum.write]);

    return hasIt;
  }

  /**
   * Indicates if it has to display the edit metadata button.
   *
   * @returns boolean
   */
  hasDisplayEditMetadata(): boolean {
    const hasPrivileges: boolean =
      !this.isMoratoriumTitle() &&
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.details.metadata.path, [ActionTypeEnum.write]);
    const isLocaleValid: boolean = (
      (!_isEmpty(this.localization.account) && this.localization.account.length === 1) &&
      (Account.isAll(this.localization.account[0]) || Account.isDisneySvod(this.localization.account[0]))
    );

    return hasPrivileges && isLocaleValid;
  }

  /**
   * Indicates if it has to display the view edit history button.
   *
   * @returns boolean
   */
  hasDisplayEditHistory(): boolean {
    const hasIt: boolean =
      this.capabilitiesManager.hasUserPrivilegeOn(
        modulesPath.titles.details.locale.editHistory.path,
        [ActionTypeEnum.read]
      );

    return hasIt;
  }

  /**
   * Indicates if it has to display the Language attribute
   *
   * @returns boolean
   */
  hasDisplayLanguage(): boolean {
    return this.metadata.localeObject.type.isLanguage();
  }

  /**
   * Indicates if it has to display the Product type attribute
   *
   * @returns boolean
   */
  hasDisplayProductType(): boolean {
    const hasIt: boolean =
      this.metadata.localeObject.type.isLanguage() ||
      this.metadata.localeObject.type.isTerritory();

    return hasIt;
  }

  /**
   * Indicates if it has to display the Territory attribute
   *
   * @returns boolean
   */
  hasDisplayTerritory(): boolean {
    const hasIt: boolean =
      this.metadata.localeObject.type.isLanguage() ||
      this.metadata.localeObject.type.isTerritory();

    return hasIt;
  }

  /**
   * Indicates if "Moratorium" is active for the computed localization.
   *
   * @returns boolean
   */
  isMoratoriumTitle(): boolean {
    // We need computed localization for getting "moratorium" from root locale.
    const isIt: boolean = _isObject(this.computedLocalization) && this.computedLocalization.moratorium;

    return isIt;
  }

  /**
   * Open the clone season metadata manager.
   *
   * @returns void
   */
  openSeasonCloneMetadataManager(): void {
    this.cloneSeasonMetadataManagerVisibility = true;
  }

  /**
   * Open the clone metadata manager.
   *
   * @returns void
   */
  openCloneMetadataManager(): void {
    this.cloneMetadataManagerVisibility = true;
  }

  /**
   * Open the locale manager side content.
   *
   * @returns void
   */
  openLocaleManager(): void {
    this.metadataOnReadOnlyMode = true;
    this.localeManagerVisibility = true;
    this.localeOnReadOnlyMode = false;
  }

  /**
   * Open the locale manager side content.
   *
   * @returns void
   */
  openLocaleEditorManager(): void {
    this.metadataOnReadOnlyMode = false;
    this.localeManagerVisibility = true;
    this.localeOnReadOnlyMode = true;
  }

  /**
   * Open the edit history modal.
   *
   * @returns void
   */
  openEditHistory(): void {
    this.showEditHistory = true;
  }

  /**
   * Parse the needed attributes in the given computed metadata.
   *
   * @param metadata EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata
   * @returns void
   */
  protected parseComputedMetadata(metadata: EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata): void {
    if (
      (metadata.isFeature() || metadata.isEpisode()) &&
      !_isUndefined(metadata[FeatureAttributes.runtimeSeconds])
    ) {
      metadata[FeatureAttributes.runtimeSeconds] = new Time(<number>(metadata[FeatureAttributes.runtimeSeconds]));
    }

    this.computedLocalization = metadata;
  }

  /**
   * This method removes the selected localization
   *
   * @return void
   */
  protected deleteLocalization(): void {

    this.titleLocalizationService.deleteLocale(this.localization.type, this.localization.rootId, this.localization.locale).subscribe(() => {
      this.confirmDeleteLocalizationModal.close();

      this.notificationService.handleNotice(
        'The localization was deleted successfully.'
      );

      this.localizationRemove.emit();

    },
    (error: ErrorHelper) => {
      this.confirmDeleteLocalizationModal.close();
      this.notificationService.handleError(
        'There was an error trying to delete the location.',
        error
      );
    }
  );
  }

  /**
   * Allows to show the modal of deletion confirmation and handles the user answer.
   *
   * @param content Reference to the modal
   * @return void
   */
  protected openConfirmModal(content: any): void {
    this.confirmDeleteLocalizationModal = this.modalService.open(content);
  }

  /**
   * Closes the confirmation modal.
   *
   * @returns void
   */
  protected closeConfirmModal(): void {
    this.confirmDeleteLocalizationModal.close();
  }

  /**
   * Indicates if it has to display the clone metadata button.
   *
   * @returns string
   */
  protected getCapabilityPath(): string {
    let capabilityPath: string;

    switch (this.metadata.localeObject.type.value) {
      case LocaleType.account:
        capabilityPath = modulesPath.titles.details.account.path;
        break;
      case LocaleType.language:
        capabilityPath = modulesPath.titles.details.language.path;
        break;
      case LocaleType.root:
        capabilityPath = modulesPath.titles.details.english.root.path;
        break;
      case LocaleType.territory:
        capabilityPath = modulesPath.titles.details.territory.path;
        break;
      default:
        break;
    }

    return capabilityPath;
  }

  /**
   * Fetches the computed version of the current localization.
   *
   * @returns void
   */
  protected fetchComputed(): void {
    this.changeStatusToFetchingData();

    this.subscriptions.add(this.titleService.fetchComputedProductMetadata(
      {
        productType: this.localization.type,
        productId : this.localization.rootId,
        locale: this.localization.locale
      },
      this.localization
    ).subscribe(
      (computedLocalization: StormServiceResponseSingle) => {
        this.parseComputedMetadata(computedLocalization.item);
        this.changeStatusToDataFound();
      },
      () => {
        this.changeStatusToError();
      }
    ));
  }
}
