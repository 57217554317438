import { Character, CharacterInterface } from './character.model';
import { LocalizationInterface } from 'app/modules/common/models/localization.model';


/**
 * @todo Move this out of this file.
 */
export interface CharacterMetadataInterface extends LocalizationInterface, CharacterInterface { }


export class CharacterMetadata extends Character implements CharacterMetadataInterface {
  protected _localized: boolean;
  protected _rootId: number;
  protected _useDomestic: boolean;
  protected _originalData: any;
  protected _inheritedAttributes: string[];

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
  }

  get inheritedAttributes(): string[] {
    return this._inheritedAttributes;
  }

  set inheritedAttributes(inheritedAttributes: string[]) {
    this._inheritedAttributes = inheritedAttributes;
  }

  get localized(): boolean {
    return this._localized;
  }

  set localized(localized: boolean) {
    this._localized = localized;
  }

  get rootId(): number {
    return this._rootId;
  }

  set rootId(rootId: number) {
    this._rootId = rootId;
  }


  get originalData(): any {
    return this._originalData;
  }

  set originalData(originalData: any) {
    this._originalData = originalData;
  }

  get useDomestic(): boolean {
    return this._useDomestic;
  }

  set useDomestic(value: boolean) {
    this._useDomestic = value;
  }

  isInheritedAttribute(attribute: string) {
    return this._inheritedAttributes.indexOf(attribute) !== -1;
  }

}
