import { isArray as _isArray, isObject as _isObject, isString as _isString } from 'lodash';
import { RoleTypeEnum, UserRole } from '@bolt/ui-shared/auth';


export interface UserInterface {
  id: number;
  notes: string;
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  organization: string;
  title: string;
  lastModified: string;
  lastModifiedBy: string;
  created: string;
  createdBy: string;
  location: string;
  address: string;
  email: string;
  phoneNumber: string;
  active: boolean;
  roles: UserRole[];

  getFullName(): string;
  hasAdminRole(shouldCheckAdmin: boolean): boolean;
  hasCastCrewRole(shouldCheckAdmin: boolean): boolean;
  hasCatLocalizationRole(shouldCheckAdmin: boolean): boolean;
  hasCatOvRole(shouldCheckAdmin: boolean): boolean;
  hasCatRole(shouldCheckAdmin: boolean): boolean;
  hasExportRole(shouldCheckAdmin: boolean): boolean;
  hasExternalConsumerRole(shouldCheckAdmin: boolean): boolean;
  hasMetadataRole(shouldCheckAdmin: boolean): boolean;
  hasRole(userRoles: RoleTypeEnum | RoleTypeEnum[]): boolean;
  hasTaskRole(shouldCheckAdmin: boolean): boolean;
  getRawObject(): object;
  getRolesId(): number[];
}

export class User implements UserInterface {

  protected _id: number;
  protected _notes: string;
  protected _name: string;
  protected _firstName: string;
  protected _middleName: string;
  protected _lastName: string;
  protected _organization: string;
  protected _title: string;
  protected _lastModified: string;
  protected _lastModifiedBy: string;
  protected _created: string;
  protected _createdBy: string;
  protected _location: string;
  protected _address: string;
  protected _email: string;
  protected _phoneNumber: string;
  protected _active: boolean;
  protected _roles: UserRole[];

  constructor(attributes?: { [attr: string]: any }) {
    this.setAttributes(attributes);
    this.mapRoles();
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get notes(): string {
    return this._notes;
  }

  set notes(notes: string) {
    this._notes = notes;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(firstName: string) {
    this._firstName = firstName;
  }

  get middleName(): string {
    return this._middleName;
  }

  set middleName(middleName: string) {
    this._middleName = middleName;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(lastName: string) {
    this._lastName = lastName;
  }

  get organization(): string {
    return this._organization;
  }

  set organization(organization: string) {
    this._organization = organization;
  }

  get title(): string {
    return this._title;
  }

  set title(title: string) {
    this._title = title;
  }

  get lastModified(): string {
    return this._lastModified;
  }

  set lastModified(lastModified: string) {
    this._lastModified = lastModified;
  }

  get lastModifiedBy(): string {
    return this._lastModifiedBy;
  }

  set lastModifiedBy(lastModifiedBy: string) {
    this._lastModifiedBy = lastModifiedBy;
  }

  get created(): string {
    return this._created;
  }

  set created(created: string) {
    this._created = created;
  }

  get createdBy(): string {
    return this._createdBy;
  }

  set createdBy(createdBy: string) {
    this._createdBy = createdBy;
  }

  get location(): string {
    return this._location;
  }

  set location(location: string) {
    this._location = location;
  }

  get address(): string {
    return this._address;
  }

  set address(address: string) {
    this._address = address;
  }

  get email(): string {
    return this._email;
  }

  set email(email: string) {
    this._email = email;
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(phoneNumber: string) {
    this._phoneNumber = phoneNumber;
  }

  get active(): boolean {
    return this._active;
  }

  set active(active: boolean) {
    this._active = active;
  }

  get roles(): UserRole[] {
    return this._roles;
  }

  set roles(roles: UserRole[]) {
    this._roles = roles;
  }

  getFullName(): string {
    return [
      this._firstName,
      this._middleName,
      this._lastName
    ].join(' ');
  }

  toString(): string {
    return this.getFullName();
  }

  isProfileUpToDate() {
    return this._name &&
      this._name.length &&
      this._email &&
      this._email.length &&
      this._firstName &&
      this._firstName.length &&
      this._lastName &&
      this._lastName.length;
  }

  /**
   * Indicates if the current user has the ADMIN role
   *
   * @returns boolean
   */
  hasAdminRole(): boolean {
    return this.hasRole(RoleTypeEnum.ADMIN);
  }

  /**
   * Indicates if the current user has the CAST_CREW role
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasCastCrewRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.CAST_CREW, shouldCheckAdmin);
  }

  /**
   * Indicates if the current user has the CAT_LOCALIZATION role
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasCatLocalizationRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.CAT_LOCALIZATION, shouldCheckAdmin);
  }

  /**
   * Indicates if the current user has the CAT_OV role
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasCatOvRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.CAT_OV, shouldCheckAdmin);
  }

  /**
   * Indicates if the current user has the CAT role
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasCatRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.CAT, shouldCheckAdmin);
  }

  /**
   * Indicates if the current user has the EXPORT role
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasExportRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.EXPORT, shouldCheckAdmin);
  }

  /**
   * Indicates if the current user has the EXTERNAL_CONSUMER role
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasExternalConsumerRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.EXTERNAL_CONSUMER, shouldCheckAdmin);
  }

  /**
   * Indicates if the current user has the METADATA role
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasMetadataRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.METADATA, shouldCheckAdmin);
  }

  /**
   * Indicates if the current user has the PROJECT role
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasProjectRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.PROJECT, shouldCheckAdmin);
  }

  /**
   * Indicates if the current user has the given role|roles
   *
   * @param userRoles RoleTypeEnum|RoleTypeEnum[]
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasRole(userRoles: RoleTypeEnum | RoleTypeEnum[], shouldCheckAdmin: boolean = false): boolean {
    if (!Array.isArray(userRoles)) {
      userRoles = [userRoles];
    }

    if (shouldCheckAdmin) {
      userRoles.push(RoleTypeEnum.ADMIN);
    }

    const hasIt: boolean = userRoles.some(
      (roleType: RoleTypeEnum) => this.roles.some(
        (role: UserRole) => role.name === roleType.toString()
      )
    );

    return hasIt;
  }

  /**
   * Indicates if the current user has the TASKS role.
   *
   * @param shouldCheckAdmin boolean
   * @returns boolean
   */
  hasTaskRole(shouldCheckAdmin: boolean = false): boolean {
    return this.hasRole(RoleTypeEnum.TASKS, shouldCheckAdmin);
  }

  setAttributes(attributes?: { [attr: string]: any }): User {
    if (attributes !== undefined) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }

    return this;
  }

  /**
   * Returns a new raw object with the inner content.
   *
   * @returns object
   */
  getRawObject(): object {
    const output: object = new Object();
    const names: string[] = Object.getOwnPropertyNames(User.prototype);

    const getters: string[] = names.filter(
      (name: string) => {
        const result: boolean = !!Object.getOwnPropertyDescriptor(User.prototype, name).get;
        return result;
      }
    );

    getters.forEach(
      (key: string) => {
        if (this[key] !== undefined) {
          output[key] = this[key];
        }
      }
    );

    return output;
  }

  /**
   * Get the roles ids.
   *
   * @returns number[]
   */
  getRolesId(): number[] {
    if (_isArray(this.roles)) {
      const rolesIds: number[] = this.roles.map(
        (role: UserRole) => role.id
      );

      return rolesIds;
    }
  }

  /**
   * Maps the current roles.
   *
   * @returns void
   */
  protected mapRoles(): void {
    if (_isArray(this.roles)) {
      this.roles = this.roles.map(
        (roleData: any) => {
          if (_isObject(roleData)) {
            return new UserRole(roleData);
          } else if (_isString(roleData)) {
            const rawData: any = {
              name: roleData
            };

            return new UserRole(rawData);
          }
        }
      );
    }
  }
}
