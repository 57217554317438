import { FormGroup, FormControl, ValidatorFn } from '@angular/forms';
import { CustomErrorTypeEnum } from '@bolt/ui-shared/form';


export class AddingStepForm extends FormGroup {
  private configuration: any;

  constructor(talents: string[], positions: string[], configuration: any) {
    super({ });

    this.configuration = configuration;
    this.setupTalents(talents);
    this.setupPositions(positions);
  }

  /**
   * resets the form group.
   *
   * @returns void
   */
  reset(): void {
    this.get('positions').setValue('');
    this.get('talents').setValue('');
    super.reset(this.value);
  }

  /**
   * Get the empty line validator function.
   *
   * @returns ValidatorFn
   */
  protected getEmptyLineValidator(): ValidatorFn {
    const validator: any = (control: FormControl) => {
      const mappedValue: string[] = control.value.split('\n');

      if (control.dirty && mappedValue.some((elem: string) => elem.trim().length === 0)) {
        return  {
          [CustomErrorTypeEnum.emptyLine]: true
        };
      }
    };

    return validator;
  }

  /**
   * Get the maxlegth line validator function.
   *
   * @returns ValidatorFn
   */
  protected getMaxLengthLineValidator(configuration: number): ValidatorFn {
    const validator: any = (control: FormControl) => {
      const mappedValue: string[] = control.value.split('\n');

      if (mappedValue.some((elem: string) => elem.length > configuration)) {
        return  {
          [CustomErrorTypeEnum.maxLengthLine]: true
        };
      }
    };

    return validator;
  }

  /**
   * Get the maxlegth validator function.
   *
   * @returns ValidatorFn
   */
  protected getMaxLengthValidator(configuration: number): ValidatorFn {
    const validator: any = (control: FormControl) => {
      const mappedValue: string[] = control.value.split('\n').filter((value: string) => value.length > 0);

      if (mappedValue.length > configuration) {
        return {
          maxlength: {
            actualLength: mappedValue.length,
            requiredLength: configuration
          }
        };
      }
    };

    return validator;
  }

  /**
   * Set up the positions.
   *
   * @param value string[]
   * @returns void
   */
  protected setupPositions(value: string[]): void {
    const mappedPositions: string = this.mapValue(value);

    this.addControl(
      'positions',
      new FormControl(
        mappedPositions,
        [
          this.getMaxLengthValidator(this.configuration.maxLength),
          this.getEmptyLineValidator(),
          this.getMaxLengthLineValidator(this.configuration.positionMaxLength)
        ]
      )
    );
  }

  /**
   * Set up the talents.
   *
   * @param value string[]
   * @returns void
   */
  protected setupTalents(value: string[]): void {
    const mappedTalents: string = this.mapValue(value);

    this.addControl(
      'talents',
      new FormControl(mappedTalents, [
        this.getMaxLengthValidator(this.configuration.maxLength),
        this.getEmptyLineValidator()
      ])
    );
  }

  /**
   * Maps the given array to a valid string.
   *
   * @returns string
   */
  protected mapValue(value: string[]): string {
    return value.join('\n');
  }
}
