import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isObject as _isObject } from 'lodash';

@Component({
  selector: 'bolt-master-data-filter',
  template: require('./bolt-master-data-filter.html'),
  styles: [require('./bolt-master-data-filter.scss')]
})
export class BoltMasterDataFiltersComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Output('searched') searchEvent: EventEmitter<string>;

  @ViewChild('searchBy')
  protected searchByInput: ElementRef;

  protected searchByListener: Subscription;
  protected searchBySubject: Subject<void>;
  protected words: string;

  constructor() {
    this.disabled = false;
    this.searchBySubject = new Subject();
    this.searchEvent = new EventEmitter();
    this.words = '';
  }

  ngOnDestroy() {
    if (_isObject(this.searchByListener)) {
      this.searchByListener.unsubscribe();
    }
  }

  ngOnInit() {
    this.searchByListener = this.searchBySubject.asObservable().pipe(debounceTime(200)).subscribe(
      () => {
        const words: string = this.searchByInput.nativeElement.value.trim().toLowerCase();

        if (words !== this.words) {
          this.words = words;
          this.searchEvent.emit(this.words);
        }
      }
    );
  }

  /**
   * Notifies the written words in the "Search By" input.
   *
   * @returns void
   */
  protected notifySearchChange(): void {
    this.searchBySubject.next();
  }
}
