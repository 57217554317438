import { includes as _includes } from 'lodash';
import { ResolutionEnum } from './resolution.enum';


export class Resolution {
  protected _type: ResolutionEnum;

  constructor(type: ResolutionEnum) {
    this.initialize(type);
  }

  get type(): ResolutionEnum {
    return this._type;
  }

  /**
   * Indicates if its type is CONFIRM.
   *
   * @returns boolean
   */
  isConfirm(): boolean {
    const isIt: boolean = (this._type === ResolutionEnum.CONFIRM);
    return isIt;
  }

  /**
   * Indicates if its type is IGNORE.
   *
   * @returns boolean
   */
  isIgnore(): boolean {
    const isIt: boolean = (this._type === ResolutionEnum.IGNORE);
    return isIt;
  }

  /**
   * Indicates if its type is UNDETERMINED.
   *
   * @returns boolean
   */
  isUndetermined(): boolean {
    const isIt: boolean = (this._type === ResolutionEnum.UNDETERMINED);
    return isIt;
  }

  /**
   * Initializes the instance.
   *
   * @param type ResolutionEnum
   * @returns void
   */
  protected initialize(type: ResolutionEnum): void {
    this._type = (
      _includes(ResolutionEnum, <any>type)
        ? type
        : undefined
    );
  }
}
