import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Country, List as MasterDataList, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import { Observable, Subscription } from 'rxjs';

import { TerritoryForm } from 'app/modules/masterData/models/territory/territory-form.model';
import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


@Component({
  selector: 'bolt-master-data-territory-handler-panel',
  template: require('./bolt-master-data-territory-handler-panel.html'),
  styles: [require('./bolt-master-data-territory-handler-panel.scss')]
})
export class BoltMasterDataTerritoryHandlerPanelComponent implements OnChanges, OnDestroy {
  @Input() territory: Country | undefined;
  @Input() show: boolean;
  @Output('cancelled') cancelEvent: EventEmitter<void>;
  @Output('saved') saveEvent: EventEmitter<Country>;

  protected form: TerritoryForm;
  protected actionSubscription: Subscription;
  protected isCreating: boolean;
  protected fieldSpecs: any;
  protected items: any;

  constructor(
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected appConfig: AppConfigProvider
  ) {
    this.cancelEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.show = false;

    this.setupFieldSpecs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.territory)) {
      this.isCreating = _isUndefined(this.territory);
    }

    if (_isObject(changes.show)) {
      if (this.show) {
        this.form = new TerritoryForm(this.fieldSpecs, this.territory);
      } else {
        this.form = undefined;
        this.cancelActionSubscription();
      }
    }
    this.fetchRegions();
  }

  ngOnDestroy() {
    this.cancelActionSubscription();
  }

  /**
   * Loads the region.
   *
   * @returns void
   */
  protected fetchRegions(): void {
    // TODO: We need to check and change fetchAsObservable() to don't use SelectionItem.
    this.masterDataService.fetchAsObservable(MasterDataType.region).subscribe(
      (list: MasterDataList) => {
        this.items = list.items;
      },
      (error: HttpError) => {
        this.notificationService.handleError('Failed fetching the territories.', error);
      }
    );
  }

  /**
   * Cancels the action over the territory.
   *
   * @returns void
   */
  protected cancel(): void {
    this.cancelEvent.emit();
  }

  /**
   * Cancels the subscription for the triggered action.
   *
   * @returns void
   */
  protected cancelActionSubscription(): void {
    if (_isObject(this.actionSubscription)) {
      this.actionSubscription.unsubscribe();
    }
  }

  /**
   * Returns the scrollHeight from AppConfig.
   *
   * @returns string
   */
  protected getScrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    return this.fieldSpecs[`${field}MaxLength`];
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.form.disabled;
  }


  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const isIt: boolean = this.form.invalid || this.form.disabled;
    return isIt;
  }

  /**
   * Save the current territory.
   *
   * @returns void
   */
  protected save(): void {
    this.form.disable();

    const data: any = this.form.toJson();
    const action: Observable<any> = this.isCreating
      ? this.masterDataService.create(MasterDataType.territory, data)
      : this.masterDataService.update(MasterDataType.territory, this.territory.id, data);

    this.actionSubscription = action.subscribe(
      (newTerritory: Country) => {
        this.notificationService.handleSuccess('Territory was saved successfully.');
        this.saveEvent.emit(newTerritory);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed saving the new territory', error);
        this.form.enable();
      }
    );
  }

  /**
   * Setup the specs for the fields
   *
   * @returns void
   */
  protected setupFieldSpecs(): void {
    this.fieldSpecs = {
      nameMaxLength: this.appConfig.get('forms.masterdata.territory.fields.name.maxLength'),
      regionMaxLength: this.appConfig.get('forms.masterdata.territory.fields.region.maxLength'),
      iso31661MaxLength: this.appConfig.get('forms.masterdata.territory.fields.iso31661.maxLength'),
      iso31661MinLength: this.appConfig.get('forms.masterdata.territory.fields.iso31661.minLength')
    };
  }
}
