import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MasterDataCacheService } from '@bolt/ui-shared/master-data';

import { EntityMapperHelper } from './helpers/entity-mapper.helper';
import { ListLayoutProvider } from './providers/list-layout/list-layout.provider';
import { ListService } from './services/list.service';
import { StormListsProvider } from './providers/storm-lists.provider';


@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [],
  exports: [],
  providers: [
    EntityMapperHelper,
    ListLayoutProvider,
    ListService,
    StormListsProvider,
    MasterDataCacheService
  ]
})
export class ListModule { }
