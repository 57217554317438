import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { EventService } from '../../services/event/event.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Summary } from '../../models/event/summary/summary.model';


@Component({
  selector: 'bolt-cpm-events-summary',
  template: require('./bolt-cpm-events-summary.html'),
  styles: [require('./bolt-cpm-events-summary.scss')]
})
export class BoltCpmEventsSummaryComponent extends StormComponent implements OnInit, OnDestroy {
  protected fetchListener: Subscription;
  protected summary: Summary;

  constructor(
    protected eventService: EventService,
    protected notificationService: NotificationService
  ) {
    super();
    this.changeStatusToIdle();
  }

  ngOnDestroy() {
    this.cancelFetchListener();
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Cancels the fetch listener for the summary.
   *
   * @returns void
   */
  protected cancelFetchListener(): void {
    if (this.fetchListener) {
      this.fetchListener.unsubscribe();
    }
  }

  /**
   * Fetches the summary.
   *
   * @returns void
   */
  protected fetch(): void {
    this.cancelFetchListener();
    this.changeStatusToFetchingData();

    this.fetchListener = this.eventService.fetchSummary().subscribe(
      (summary: Summary) => {
        this.summary = summary;
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed fetching the Events Summary.', error);
        this.changeStatusToError();
      }
    );
  }
}
