import { Component } from '@angular/core';


@Component({
  selector: 'bolt-profile-import-summary',
  template: require('./bolt-profile-import-summary.html'),
  styles: [require('./bolt-profile-import-summary.scss')]
})
export class BoltProfileImportSummaryComponent {
  constructor() { }
}
