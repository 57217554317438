import { Status } from '../status/status.model';


export class Details {
  protected _id: number;
  protected _locale: string;
  protected _titleId: number;
  protected _legalTitle: string;
  protected _fileName: string;
  protected _jobStatus: Status;
  protected _isConsolidated: boolean;

  constructor(data: any) {
    if (data && data.hasOwnProperty('jobStatus') && data.hasOwnProperty('id')) {
      this._id = data.id;
      this._locale = (data.hasOwnProperty('locale') ? data.locale : undefined);
      this._titleId = (data.hasOwnProperty('titleId') ? data.titleId : undefined);
      this._legalTitle = (data.hasOwnProperty('legalTitle') ? data.legalTitle : undefined);
      this._fileName = (data.hasOwnProperty('fileName') ? data.fileName : undefined);
      this._isConsolidated = (data.hasOwnProperty('consolidated') ? data.consolidated : undefined);

      this.updateJobStatus(new Status(data));
    } else {
      throw new Error('Cannot get needed fields for a Metadata Export Details.');
    }
  }

  get locale(): string {
    return this._locale;
  }

  get titleId(): number {
    return this._titleId;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  get fileName(): string {
    return this._fileName;
  }

  get jobStatus(): Status {
    return this._jobStatus;
  }

  get id(): number {
    return this._id;
  }

  get isConsolidated(): boolean {
    return this._isConsolidated;
  }

  /**
   * Updates the job status.
   *
   * @param jobStatus Status
   * @returns void
   */
  public updateJobStatus(jobStatus: Status): void {
    this._jobStatus = jobStatus;
  }
}
