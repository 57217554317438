import * as moment from 'moment';
import { GenreInterface, PrimaryProductAssociation, RatingType, SecondaryProductAssociation } from '@bolt/ui-shared/master-data';
import { RatingInterface } from '@bolt/ui-shared/master-data/models/rating/rating.interface';
import { RatingSystemReasonInterface } from '@bolt/ui-shared/master-data/models/rating-system-reason/rating-system-reason.interface';

import { FeatureMetadataInterface } from './feature-metadata.model';
import { FunctionalMetadata } from 'app/modules/list/models/functional-metadata/functional-metadata.model';
import { Time, TimeInterface } from 'app/modules/common/models/time.model';
import { TitleInterface, Title, TitleType } from './title.model';
import { TitleAttributes } from './title/title-attributes.enum';
import { FeatureAttributes } from './title/feature/feature-attributes.enum';


/**
 * @deprecated 2021-07-12: Use `Feature` instead of this interface.
 */
export interface FeatureInterface extends TitleInterface {
  legalTitle?: string;
  countryOfOriginId?: number;
  productionAspectRatioId?: number;
  productionCompanyId?: number;
  productionCompanyIds?: number[];
  mpmProductId?: number;
  copyright?: string;
  copyrightYear?: number;
  umid?: string;
  imdbId?: string;
  dmaEligible?: boolean;
  globalBoxOffice?: string;
  videoType?: string;
  primaryRadarProductId?: number;
  productMasterEarliestReleaseDate?: string | moment.Moment;
  radarSynopsis?: string;
  radarGenre?: string;
  radarLanguage?: string;
  radarLanguageCode?: string;
  radarPresentedBy?: string;
  radarRatingSystem?: string;
  radarRating?: string;
  radarReleaseDate?: string | moment.Moment;
  radarRuntime?: string;
  radarGroupId?: number;
  radarCopyrightYear?: number;
  shortTitle?: string;
  keywords?: string[];
  shortSynopsis?: string;
  mediumSynopsis?: string;
  fullSynopsis?: string;
  firstDigitalRelease?: string | moment.Moment;
  firstPhysicalRelease?: string | moment.Moment;
  theatricalReleaseDate?: string | moment.Moment;
  spartaUpdatedDate?: string | moment.Moment;
  genreId?: number[] | GenreInterface[];
  functionalMetadata?: number[] | FunctionalMetadata[];
  ratingReason?: string;
  ratingId?: number[] | RatingInterface[];
  ratingSystemReasonId: number[] | RatingSystemReasonInterface[];
  runtimeSeconds?: number | TimeInterface;
  ratingType?: RatingType;
  primaryProductAssociation?: number | PrimaryProductAssociation;
  secondaryProductAssociation?: number | SecondaryProductAssociation;
  onairDateKcc?: moment.Moment;
  onairPlatformKcc?: string;
  ratingFilingNumberKmrb?: string;
  displayDubTalent?: boolean;
  graphicContentDisclaimer?: boolean;
  heritageDisclaimer?: boolean;
  excludeKidsMode?: boolean;
  includeKidsMode?: boolean;
  productPlacementDisclaimer?: boolean;
  pseDisclaimer?: boolean;
  smokingDisclaimer?: boolean;
  homeEntRatingId?: number[] | RatingInterface[];
  homeEntRatingSystemReasonId?: number[] | RatingSystemReasonInterface[];
  homeEntRatingReason?: string;
}

/**
 * @todo We have to re-implement the whole `Title` hierarchy class in order to not use Typescript set/get with underscore attribute. But
 * because this is part of the Bolt's core model, we need a tech debt story for doing this and move it to bolt-ui-shared project.
 * @see `FeatureAttributes`: Ensure that enumerative and this class are synchronized about public attributes.
 */
export class Feature extends Title implements FeatureInterface {
  protected _legalTitle: string;
  protected _notApplicable: boolean;
  protected _countryOfOriginId: number;
  protected _productionAspectRatioId: number;
  protected _productionCompanyId: number;
  protected _productionCompanyIds: number[];
  protected _mpmProductId: number;
  protected _copyright: string;
  protected _copyrightYear: number;
  protected _umid: string;
  protected _imdbId: string;
  protected _dmaEligible: boolean;
  protected _globalBoxOffice: string;
  protected _videoType: string;
  protected _productMasterEarliestReleaseDate: string | moment.Moment;
  protected _radarSynopsis: string;
  protected _radarGenre: string;
  protected _radarLanguage: string;
  protected _radarLanguageCode: string;
  protected _radarPresentedBy: string;
  protected _radarRatingSystem: string;
  protected _radarRating: string;
  protected _radarReleaseDate: string | moment.Moment;
  protected _radarRuntime: string;
  protected _radarGroupId: number;
  protected _radarCopyrightYear: number;
  protected _shortTitle: string;
  protected _keywords: string[];
  protected _shortSynopsis: string;
  protected _mediumSynopsis: string;
  protected _fullSynopsis: string;
  protected _firstDigitalRelease: string | moment.Moment;
  protected _firstPhysicalRelease: string | moment.Moment;
  protected _theatricalReleaseDate: string | moment.Moment;
  protected _spartaUpdatedDate?: string | moment.Moment;
  protected _genreId: number[] | GenreInterface[];
  protected _functionalMetadata: number[] | FunctionalMetadata[];
  protected _ratingReason: string;
  protected _ratingId: number[] | RatingInterface[];
  protected _ratingSystemReasonId: number[] | RatingSystemReasonInterface[];
  protected _runtimeSeconds: number | TimeInterface;
  protected _ratingType: RatingType;
  protected _primaryProductAssociation: number | PrimaryProductAssociation;
  protected _secondaryProductAssociation: number | SecondaryProductAssociation;
  protected _onairDateKcc?: moment.Moment;
  protected _onairPlatformKcc?: string;
  protected _ratingFilingNumberKmrb?: string;
  protected _displayDubTalent?: boolean;
  protected _graphicContentDisclaimer?: boolean;
  protected _heritageDisclaimer?: boolean;
  protected _excludeKidsMode?: boolean;
  protected _includeKidsMode?: boolean;
  protected _productPlacementDisclaimer?: boolean;
  protected _pseDisclaimer?: boolean;
  protected _smokingDisclaimer?: boolean;
  protected _homeEntRatingId?: number[] | RatingInterface[];
  protected _homeEntRatingSystemReasonId?: number[] | RatingSystemReasonInterface[];
  protected _homeEntRatingReason?: string;
  protected _primaryRadarProductId?: number;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
    this._type = TitleType.feature;
  }

  /**
   * Returns the attributes names of a Feature.
   *
   * @returns string[]
   */
  static getAttributesNames(): string[] {
    const attributes: string[] = [...Object.keys(TitleAttributes), ...Object.keys(FeatureAttributes)];

    return attributes;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  set legalTitle(legalTitle: string) {
    this._legalTitle = legalTitle;
  }

  get notApplicable(): boolean {
    return this._notApplicable;
  }

  set notApplicable(notApplicable: boolean) {
    this._notApplicable = notApplicable;
  }

  get countryOfOriginId(): number {
    return this._countryOfOriginId;
  }

  set countryOfOriginId(countryOfOriginId: number) {
    this._countryOfOriginId = countryOfOriginId;
  }

  get productionAspectRatioId(): number {
    return this._productionAspectRatioId;
  }

  set productionAspectRatioId(productionAspectRatioId: number) {
    this._productionAspectRatioId = productionAspectRatioId;
  }

  get productionCompanyId(): number {
    return this._productionCompanyId;
  }

  set productionCompanyId(productionCompanyId: number) {
    this._productionCompanyId = productionCompanyId;
  }

  get productionCompanyIds(): number[] {
    return this._productionCompanyIds;
  }

  set productionCompanyIds(productionCompanyIds: number[]) {
    this._productionCompanyIds = productionCompanyIds;
  }

  get mpmProductId(): number {
    return this._mpmProductId;
  }

  set mpmProductId(mpmProductId: number) {
    this._mpmProductId = mpmProductId;
  }

  get copyright(): string {
    return this._copyright;
  }

  set copyright(copyright: string) {
    this._copyright = copyright;
  }

  get copyrightYear(): number {
    return this._copyrightYear;
  }

  set copyrightYear(copyrightYear: number) {
    this._copyrightYear = copyrightYear;
  }

  get umid(): string {
    return this._umid;
  }

  set umid(umid: string) {
    this._umid = umid;
  }

  get imdbId(): string {
    return this._imdbId;
  }

  set imdbId(imdbId: string) {
    this._imdbId = imdbId;
  }

  get dmaEligible(): boolean {
    return this._dmaEligible;
  }

  set dmaEligible(dmaEligible: boolean) {
    this._dmaEligible = dmaEligible;
  }

  get globalBoxOffice(): string {
    return this._globalBoxOffice;
  }

  set globalBoxOffice(globalBoxOffice: string) {
    this._globalBoxOffice = globalBoxOffice;
  }

  get videoType(): string {
    return this._videoType;
  }

  set videoType(videoType: string) {
    this._videoType = videoType;
  }

  get productMasterEarliestReleaseDate(): string | moment.Moment {
    return this._productMasterEarliestReleaseDate;
  }

  set productMasterEarliestReleaseDate(value: string | moment.Moment) {
    this._productMasterEarliestReleaseDate = value;
  }

  get radarSynopsis(): string {
    return this._radarSynopsis;
  }

  set radarSynopsis(radarSynopsis: string) {
    this._radarSynopsis = radarSynopsis;
  }

  get radarGenre(): string {
    return this._radarGenre;
  }

  set radarGenre(radarGenre: string) {
    this._radarGenre = radarGenre;
  }

  get radarLanguage(): string {
    return this._radarLanguage;
  }

  set radarLanguage(radarLanguage: string) {
    this._radarLanguage = radarLanguage;
  }

  get radarLanguageCode(): string {
    return this._radarLanguageCode;
  }

  set radarLanguageCode(radarLanguageCode: string) {
    this._radarLanguageCode = radarLanguageCode;
  }

  get radarPresentedBy(): string {
    return this._radarPresentedBy;
  }

  set radarPresentedBy(radarPresentedBy: string) {
    this._radarPresentedBy = radarPresentedBy;
  }

  get radarRatingSystem(): string {
    return this._radarRatingSystem;
  }

  set radarRatingSystem(radarRatingSystem: string) {
    this._radarRatingSystem = radarRatingSystem;
  }

  get radarRating(): string {
    return this._radarRating;
  }

  set radarRating(radarRating: string) {
    this._radarRating = radarRating;
  }

  get radarReleaseDate(): string | moment.Moment {
    return this._radarReleaseDate;
  }

  set radarReleaseDate(radarReleaseDate: string | moment.Moment) {
    this._radarReleaseDate = radarReleaseDate;
  }

  get radarRuntimeForDisplay(): string {
    const time = new Time(Number(this._radarRuntime));
    return time.toString();
  }

  get radarRuntime(): string {
    return this._radarRuntime;
  }

  set radarRuntime(radarRuntime: string) {
    this._radarRuntime = radarRuntime;
  }

  get radarGroupId(): number {
    return this._radarGroupId;
  }

  set radarGroupId(radarGroupId: number) {
    this._radarGroupId = radarGroupId;
  }

  get radarCopyrightYear(): number {
    return this._radarCopyrightYear;
  }

  set radarCopyrightYear(radarCopyrightYear: number) {
    this._radarCopyrightYear = radarCopyrightYear;
  }

  get shortTitle(): string {
    return this._shortTitle;
  }

  set shortTitle(shortTitle: string) {
    this._shortTitle = shortTitle;
  }

  get keywords(): string[] {
    return this._keywords;
  }

  set keywords(keywords: string[]) {
    this._keywords = keywords;
  }

  get shortSynopsis(): string {
    return this._shortSynopsis;
  }

  set shortSynopsis(shortSynopsis: string) {
    this._shortSynopsis = shortSynopsis;
  }

  get mediumSynopsis(): string {
    return this._mediumSynopsis;
  }

  set mediumSynopsis(mediumSynopsis: string) {
    this._mediumSynopsis = mediumSynopsis;
  }

  get fullSynopsis(): string {
    return this._fullSynopsis;
  }

  set fullSynopsis(fullSynopsis: string) {
    this._fullSynopsis = fullSynopsis;
  }

  get firstDigitalRelease(): string | moment.Moment {
    return this._firstDigitalRelease;
  }

  set firstDigitalRelease(firstDigitalRelease: string | moment.Moment) {
    this._firstDigitalRelease = firstDigitalRelease;
  }

  get firstPhysicalRelease(): string | moment.Moment {
    return this._firstPhysicalRelease;
  }

  set firstPhysicalRelease(firstPhysicalRelease: string | moment.Moment) {
    this._firstPhysicalRelease = firstPhysicalRelease;
  }

  get theatricalReleaseDate(): string | moment.Moment {
    return this._theatricalReleaseDate;
  }

  set theatricalReleaseDate(theatricalReleaseDate: string | moment.Moment) {
    this._theatricalReleaseDate = theatricalReleaseDate;
  }

  get spartaUpdatedDate(): string | moment.Moment {
    return this._spartaUpdatedDate;
  }

  set spartaUpdatedDate(spartaUpdatedDate: string | moment.Moment) {
    this._spartaUpdatedDate = spartaUpdatedDate;
  }

  get genreId(): number[] | GenreInterface[] {
    return this._genreId;
  }

  set genreId(genreId: number[] | GenreInterface[]) {
    this._genreId = genreId;
  }

  get functionalMetadata(): number[] | FunctionalMetadata[] {
    return this._functionalMetadata;
  }

  set functionalMetadata(functionalMetadata: number[] | FunctionalMetadata[]) {
    this._functionalMetadata = functionalMetadata;
  }

  get ratingReason(): string {
    return this._ratingReason;
  }

  set ratingReason(ratingReason: string) {
    this._ratingReason = ratingReason;
  }

  get ratingId(): number[] | RatingInterface[] {
    return this._ratingId;
  }

  set ratingId(ratingId: number[] | RatingInterface[]) {
    this._ratingId = ratingId;
  }

  get ratingSystemReasonId(): number[] | RatingSystemReasonInterface[] {
    return this._ratingSystemReasonId;
  }

  set ratingSystemReasonId(ratingSystemReasonId: number[] | RatingSystemReasonInterface[]) {
    this._ratingSystemReasonId = ratingSystemReasonId;
  }

  get ratingType(): RatingType {
    return this._ratingType;
  }

  set ratingType(ratingType: RatingType) {
    this._ratingType = ratingType;
  }

  get runtimeSeconds(): number | TimeInterface {
    return this._runtimeSeconds;
  }

  set runtimeSeconds(runtimeSeconds: number | TimeInterface) {
    this._runtimeSeconds = runtimeSeconds;
  }

  get localizations(): FeatureMetadataInterface[] {
    return this._localizations;
  }

  set localizations(localizations: FeatureMetadataInterface[]) {
    this._localizations = localizations;
  }

  get primaryProductAssociation(): number | PrimaryProductAssociation {
    return this._primaryProductAssociation;
  }

  set primaryProductAssociation(primaryProductAssociation: number | PrimaryProductAssociation) {
    this._primaryProductAssociation = primaryProductAssociation;
  }

  get secondaryProductAssociation(): number | SecondaryProductAssociation {
    return this._secondaryProductAssociation;
  }

  set secondaryProductAssociation(secondaryProductAssociation: number | SecondaryProductAssociation) {
    this._secondaryProductAssociation = secondaryProductAssociation;
  }

  get onairDateKcc(): moment.Moment {
    return this._onairDateKcc;
  }

  set onairDateKcc(onairDateKcc: moment.Moment) {
    this._onairDateKcc = onairDateKcc;
  }

  get onairPlatformKcc(): string {
    return this._onairPlatformKcc;
  }

  set onairPlatformKcc(onairPlatformKcc: string) {
    this._onairPlatformKcc = onairPlatformKcc;
  }

  get ratingFilingNumberKmrb(): string {
    return this._ratingFilingNumberKmrb;
  }

  set ratingFilingNumberKmrb(ratingFilingNumberKmrb: string) {
    this._ratingFilingNumberKmrb = ratingFilingNumberKmrb;
  }

  get displayDubTalent(): boolean {
    return this._displayDubTalent;
  }

  set displayDubTalent(displayDubTalent: boolean) {
    this._displayDubTalent = displayDubTalent;
  }

  get graphicContentDisclaimer(): boolean {
    return this._graphicContentDisclaimer;
  }

  set graphicContentDisclaimer(graphicContentDisclaimer: boolean) {
    this._graphicContentDisclaimer = graphicContentDisclaimer;
  }

  get heritageDisclaimer(): boolean {
    return this._heritageDisclaimer;
  }

  set heritageDisclaimer(heritageDisclaimer: boolean) {
    this._heritageDisclaimer = heritageDisclaimer;
  }

  get smokingDisclaimer(): boolean {
    return this._smokingDisclaimer;
  }

  set smokingDisclaimer(smokingDisclaimer: boolean) {
    this._smokingDisclaimer = smokingDisclaimer;
  }

  get includeKidsMode(): boolean {
    return this._includeKidsMode;
  }

  set includeKidsMode(includeKidsMode: boolean) {
    this._includeKidsMode = includeKidsMode;
  }

  get excludeKidsMode(): boolean {
    return this._excludeKidsMode;
  }

  set excludeKidsMode(excludeKidsMode: boolean) {
    this._excludeKidsMode = excludeKidsMode;
  }

  get primaryRadarProductId(): number {
    return this._primaryRadarProductId;
  }

  set primaryRadarProductId(primaryRadarProductId: number) {
   this._primaryRadarProductId = primaryRadarProductId;
  }

  get productPlacementDisclaimer(): boolean {
    return this._productPlacementDisclaimer;
  }

  set productPlacementDisclaimer(productPlacementDisclaimer: boolean) {
    this._productPlacementDisclaimer = productPlacementDisclaimer;
  }

  get pseDisclaimer(): boolean {
    return this._pseDisclaimer;
  }

  set pseDisclaimer(pseDisclaimer: boolean) {
    this._pseDisclaimer = pseDisclaimer;
  }

  get homeEntRatingId(): number[] | RatingInterface[] {
    return this._homeEntRatingId;
  }

  set homeEntRatingId(ratingId: number[] | RatingInterface[]) {
    this._homeEntRatingId = ratingId;
  }

  get homeEntRatingSystemReasonId(): number[] | RatingSystemReasonInterface[] {
    return this._homeEntRatingSystemReasonId;
  }

  set homeEntRatingSystemReasonId(ratingSystemReasonId: number[] | RatingSystemReasonInterface[]) {
    this._homeEntRatingSystemReasonId = ratingSystemReasonId;
  }

  get homeEntRatingReason(): string {
    return this._homeEntRatingReason;
  }

  set homeEntRatingReason(reason: string) {
    this._homeEntRatingReason = reason;
  }

  getRawObject(): object {
    // TODO: This needs to be refactored to export individuals fields.
    const names = Object.getOwnPropertyNames(Feature.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Feature.prototype, name);
      return !!result.get;
    });

    const object = new Object();

    getters.forEach((key) => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return Object.assign(new Object(), super.getRawObject(), object);
  }
}
