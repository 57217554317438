import { Injectable } from '@angular/core';
import { EnvironmentService } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { BrowserTracker, addGlobalContexts, newTracker } from '@snowplow/browser-tracker';

import { User } from '../user/models/user.model';


@Injectable({
  providedIn: 'root'
})
export class SnowplowTrackerService {
  private tracker: BrowserTracker;

  constructor(private environmentService: EnvironmentService, private appConfigManager: AppConfigProvider) {
    this.init();
  }

  /**
   * Adds the logged user and starts tracking the events on snowplow
   *
   * @param user User
   * returns void
   */
  public trackPageView(user: User): void {
    if (!this.environmentService.isLocal() && this.tracker) {
      try {
        this.tracker.setUserId(user.name);
        this.tracker.trackPageView();
      } catch (error) {
        console.error('There was an error starting to track the page view', error);
      }
    }
  }

  protected init(): void {
    if (!this.environmentService.isLocal()) {
      try {
        this.tracker = newTracker(
          'spBoltUi',
          'https://snowplow.fpdc.dmed.technology',
          {
            appId: this.getAppId()
          }
        );

        addGlobalContexts([{
          schema: 'iglu:com.disney.cp.dmed/app_context/jsonschema/1-0-0',
          data: {
            env: this.environmentService.getName()
          }
        }]);

        this.tracker?.enableActivityTracking({
          minimumVisitLength: 10,
          heartbeatDelay: 10
        });
      } catch (error) {
        console.error('There was an error creating the snowplow tracker', error);
      }
    }
  }

  /**
   * Gets the app identifier for snowplow
   *
   * @returns string
   */
  protected getAppId(): string {
    const appName = 'bolt-ui';
    const platform = 'web';
    const environment = this.environmentService.getName();
    const { version } = this.appConfigManager.getVersionInfo() as any;

    const appId = `${appName}-${platform}-${environment}-${version}`;

    return appId;
  }
}
