import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragulaModule } from 'ng2-dragula';
import { StepsModule, ProgressBarModule } from 'primeng';

import { BoltCommonModule } from '../common/common.module';
import { BoltCreditsComponent } from './components/bolt-credits/bolt-credits.component';
import { BoltCreditsAggregateRoleListComponent } from './components/bolt-credits-aggregate-role-list/bolt-credits-aggregate-role-list.component';
import { BoltCreditsDetailsComponent } from './components/bolt-credits-details/bolt-credits-details.component';
import { BoltCreditsGroupComponent } from './components/bolt-credits-group/bolt-credits-group.component';
import { BoltCreditsManagerComponent } from './components/bolt-credits-manager/bolt-credits-manager.component';
import { SharedModule } from 'app/shared/shared.module';
import { BoltCreditsLocalizationListComponent } from './components/bolt-credits-localization-list/bolt-credits-localization-list.component';
import { CreditsService } from './services/credits.service';
import { RoleModule } from '../role/role.module';
import { TalentModule } from '../talent/talent.module';
import { CharacterModule } from '../character/character.module';
import { CloneModule } from '../clone/clone.module';
import { BoltCreditsWizardComponent } from './components/bolt-credits-wizard/bolt-credits-wizard.component';
import { BoltCreditsWizardAddingStepComponent } from './components/bolt-credits-wizard-adding-step/bolt-credits-wizard-adding-step.component';
import { WizardService } from './services/wizard/wizard.service';
import { BoltCreditsWizardValidatingStepComponent } from './components/bolt-credits-wizard-validating-step/bolt-credits-wizard-validating-step.component';
import { BoltWizardValidatingStepTalentItemComponent } from './components/bolt-wizard-validating-step-talent-item/bolt-wizard-validating-step-talent-item.component';
import { BoltWizardValidatingStepRoleItemComponent } from './components/bolt-wizard-validating-step-role-item/bolt-wizard-validating-step-role-item.component';
import { BoltWizardValidatingStepCharacterItemComponent } from './components/bolt-wizard-validating-step-character-item/bolt-wizard-validating-step-character-item.component';
import { BoltCreditsWizardCreationStepComponent } from './components/bolt-credits-wizard-creation-step/bolt-credits-wizard-creation-step.component';
import { BoltCreditsWizardSummaryComponent } from './components/bolt-credits-wizard-summary/bolt-credits-wizard-summary.component';

@NgModule({
  imports: [
    BoltCommonModule,
    CharacterModule,
    CloneModule,
    CommonModule,
    DragulaModule.forRoot(),
    RoleModule,
    SharedModule,
    TalentModule,
    StepsModule,
    ProgressBarModule
  ],
  declarations: [
    BoltCreditsComponent,
    BoltCreditsAggregateRoleListComponent,
    BoltCreditsDetailsComponent,
    BoltCreditsGroupComponent,
    BoltCreditsLocalizationListComponent,
    BoltCreditsManagerComponent,
    BoltCreditsWizardComponent,
    BoltCreditsWizardAddingStepComponent,
    BoltCreditsWizardCreationStepComponent,
    BoltCreditsWizardSummaryComponent,
    BoltCreditsWizardValidatingStepComponent,
    BoltWizardValidatingStepCharacterItemComponent,
    BoltWizardValidatingStepTalentItemComponent,
    BoltWizardValidatingStepRoleItemComponent,
  ],
  providers: [
    CreditsService,
    WizardService
  ],
  exports: [
    BoltCreditsComponent,
    BoltCreditsDetailsComponent
  ]
})
export class CreditsModule { }
