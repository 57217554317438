import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { BoltPageTalentComponent } from './components/bolt-page-talent/bolt-page-talent.component';
import { BoltPageTalentDetailsComponent } from './components/bolt-page-talent-details/bolt-page-talent-details.component';
import { BoltPageTalentListComponent } from './components/bolt-page-talent-list/bolt-page-talent-list.component';
import { modulesPath } from '../auth/services/role/modules-path';


const talentConf: any = {
  component: BoltPageTalentDetailsComponent,
  canActivate: [AuthGuard],
  data: {
    boltAuthAware: modulesPath.titles.credits.talent.path,
    title: 'Talent',
  }
};

export const talentRoutes: Routes = [
  {
    path: 'talent',
    component: BoltPageTalentComponent,
    children: [
      {
        path: '',
        component: BoltPageTalentListComponent,
        canActivate: [AuthGuard],
        data: {
          boltAuthAware: modulesPath.menu.talent.path,
          title: 'Talent List',
        }
      },
      {
        path: ':talentId',
        ...talentConf
      },
      {
        // The talentName is not necessary anymore, but we keep this entry only for backward compatibility.
        path: ':talentId/:talentName',
        ...talentConf
      }
    ]
  },
];

export const talentModuleRouting: ModuleWithProviders = RouterModule.forChild(talentRoutes);
