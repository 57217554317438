import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AuthModule } from '../auth/auth.module';

import { Bolt404Component } from './components/bolt-404/bolt-404.component';
import { BoltFooterComponent } from './components/bolt-footer/bolt-footer.component';
import { BoltHeaderComponent } from './components/bolt-header/bolt-header.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AuthModule,
  ],
  declarations: [
    Bolt404Component,
    BoltFooterComponent,
    BoltHeaderComponent
  ],
  exports: [
    Bolt404Component,
    BoltFooterComponent,
    BoltHeaderComponent
  ]
})
export class LayoutModule { }
