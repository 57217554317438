import { Injectable } from '@angular/core';
import { ObservableExecutor, ObservableResponse } from '@bolt/ui-shared/common';
import { MenuItem } from 'primeng';
import { isObject as _isObject, union as _union, isMap as _isMap, isArray as _isArray, cloneDeep as _cloneDeep } from 'lodash';

import { AddingStepForm } from '../../models/wizard/adding-step-form/adding-step-form.model';
import { Character } from 'app/modules/character/models/character.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Role } from 'app/modules/role/models/role.model';
import { Step } from '../../models/wizard/step/step.model';
import { StepEnum } from '../../models/wizard/step/steps.enum';
import { Talent } from 'app/modules/talent/models/talent.model';
import { Candidate } from '../../models/wizard/candidate/candidate.model';
import { TypeEnum } from '../../models/wizard/candidate/type/type.enum';
import { CreditPositionType } from '../../models/credit/credit-position.enum';
import { CreditType } from '../../models/credit/credit-type.enum';
import { Cast } from '../../models/cast/cast.model';
import { Crew } from '../../models/crew/crew.model';
import { Credit } from '../../models/credit/credit.model';
import { Observable } from 'rxjs';
import { CreditsService } from '../credits.service';
import { Title, TitleType } from 'app/modules/title/models/title.model';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { CharacterForm } from 'app/modules/character/models/form/character-form.model';
import { TalentForm } from 'app/modules/talent/models/form/talent-form.model';
import { RoleForm } from 'app/modules/role/models/form/role-form.model';
import { StormServiceResponseSingle, StormServiceResponseSingleInterface } from 'app/modules/common/services/storm-service-response-single';
import { CharacterService } from 'app/modules/character/services/character.service';
import { OriginalDataForm as CharacterOriginalDataForm } from 'app/modules/character/models/form/original-data/original-data-form.model';
import { OriginalDataForm as RoleOriginalDataForm } from 'app/modules/role/models/form/original-data/original-data-form.model';
import { OriginalDataForm as TalentOriginalDataForm } from 'app/modules/talent/models/form/original-data/original-data-form.model';
import { RoleService } from 'app/modules/role/services/role.service';
import { TalentService } from 'app/modules/talent/services/talent.service';


@Injectable({
  providedIn: 'root',
})
export class WizardService {
  private addingForm: AddingStepForm;
  private creditPositionType: CreditPositionType;
  private creditsCandidates: Credit[];
  private creditType: CreditType;
  private currentStep: Step;
  private invalidStepError: ErrorHelper;
  private locale: string;
  private positionCandidates: Candidate[];
  private positionNames: string[];
  private positionObservableExecutor: ObservableExecutor;
  private stepItems: MenuItem[];
  private steps: Step[];
  private talentCandidates: Candidate[];
  private talentNames: string[];
  private talentObservableExecutor: ObservableExecutor;
  private title: Title;
  private order: number;

  private additionObservables: Map<string, Observable<any>>;
  private additionErrors: ObservableResponse[];
  private additionObservableExecutor: ObservableExecutor;
  private creationErrors: ObservableResponse[];
  private creationForms: CharacterForm[] | TalentForm[] | RoleForm[];
  private creationObservables: Map<string, Observable<any>>;
  private creationObservableExecutor: ObservableExecutor;

  constructor(
    private characterService: CharacterService,
    private creditsService: CreditsService,
    private layoutHandler: LayoutHandlerService,
    private roleService: RoleService,
    private talentService: TalentService
  ) {
    this.additionObservableExecutor = new ObservableExecutor(4);
    this.creationObservableExecutor = new ObservableExecutor(4);
    this.setInitialValues();
    this.invalidStepError = new ErrorHelper('Invalid step for Cast & Crew Bulk Add Wizard.');
  }

  /**
   * Indicates if it can back to the previous step.
   *
   * @returns boolean
   */
  canBack(): boolean {
    switch (this.currentStep.getName()) {
      case StepEnum.adding:
        return false;
      case StepEnum.validating:
        return true;
      case StepEnum.creating:
        return this.layoutHandler.isCreating();
      default:
        throw this.invalidStepError;
    }
  }

  /**
   * Indicates if it can continue to the next step.
   *
   * @returns boolean
   */
  canContinue(): boolean {
    let itCan: boolean;

    switch (this.currentStep.getName()) {
      case StepEnum.adding:
        itCan =
          this.talentNames.length > 0 &&
          this.positionNames.length > 0  &&
          this.talentNames.length === this.positionNames.length &&
          this.addingForm.valid;

        return itCan;
      case StepEnum.validating:
        itCan =
          this.talentCandidates.every((item: Candidate) => item.isReady()) &&
          this.positionCandidates.every((item: Candidate) => item.isReady());

        return itCan;
      case StepEnum.creating:
        return false;
      default:
        throw this.invalidStepError;
    }
  }

  /**
   * Indicates if it can complete the bulk add process without creation step.
   *
   * @returns boolean
   */
  canCompleteFromValidationStep(): boolean {
    const itCan: boolean =
      this.currentStep.isValidating() &&
      _isArray(this.talentCandidates) &&
      _isArray(this.positionCandidates) &&
      this.talentCandidates.every(
        (candidate: Candidate) => {
          return candidate.isReady() && !candidate.hasToCreate();
      }) &&
      this.positionCandidates.every(
        (candidate: Candidate) => {
          return candidate.isReady() && !candidate.hasToCreate();
        }
      );

    return itCan;
  }

  /**
   * Indicates if it can retry.
   *
   * @returns boolean
   */
  canRetry(): boolean {
    const itCan: boolean =
      this.getCurrentStep().isCreating() &&
      this.layoutHandler.isReading() &&
      (
        this.additionErrors.some((response: ObservableResponse) => !response.error.is409()) ||
        this.creationErrors.some((response: ObservableResponse) => !response.error.is409())
      );

    return itCan;
  }

  /**
   * Get the current addition errors.
   *
   * @returns ObservableResponse[]
   */
  getAdditionErrors(): ObservableResponse[] {
    return this.additionErrors;
  }

  /**
   * Get the addition observables.
   *
   * @returns Map<string, Observable<any>>
   */
  getAdditionObservables(): Map<string, Observable<any>> {
      return this.additionObservables;
  }

  /**
   * Set the current addition errors with the given value.
   *
   * @param value ObservableResponse []
   * @returns void
   */
  setAdditionErrors(value: ObservableResponse[]): void {
      this.additionErrors = value;
  }

  /**
   * Get the candidates which must create.
   *
   * @returns Candidate[]
   */
  getCreationCandidates(): Candidate[] {
    const itemsToCreate: Candidate[] = _union(
      this.talentCandidates.filter((candidate: Candidate) => candidate.hasToCreate()),
      this.positionCandidates.filter((candidate: Candidate) => candidate.hasToCreate())
    );

    return itemsToCreate;
  }

  /**
   * Get the current creation errors.
   *
   * @returns ObservableResponse[]
   */
  getCreationErrors(): ObservableResponse[] {
    return this.creationErrors;
  }

  /**
   * Get the creation observables.
   *
   * @returns Map<string, Observable<any>>
   */
  getCreationObservables(): Map<string, Observable<any>> {
    return this.creationObservables;
  }

  /**
   * Set the current creation errors with the given value.
   *
   * @param value ObservableResponse []
   * @returns void
   */
  setCreationErrors(value: ObservableResponse[]): void {
    this.creationErrors = value;
  }

  /**
   * Set the current creation errors with the given value.
   *
   * @param forms CharacterForm[] | TalentForm[] | RoleForm[]
   * @returns void
   */
  setCreationForms(forms: CharacterForm[] | TalentForm[] | RoleForm[]): void {
    this.creationForms = forms;
  }

  /**
   * Set the credit position type attribute with the given value.
   *
   * @param value CreditPositionType
   * @returns void
   */
  setCreditPositionType(value: CreditPositionType): void {
    this.creditPositionType = value;
  }

  /**
   * Set the credit type attribute with the given value.
   *
   * @param value CreditType
   * @returns void
   */
  setCreditType(value: CreditType): void {
    this.creditType = value;
  }

  /**
   * Get the current step.
   *
   * @returns number
   */
  getCurrentStep(): Step {
    return this.currentStep;
  }

  /**
   * Get the position candidates.
   *
   * @returns Candidate[]
   */
  getPositionCandidates(): Candidate[] {
      return this.positionCandidates;
  }

  /**
   * Get the current steps.
   *
   * @returns Step[]
   */
  getSteps(): Step[] {
    return this.steps;
  }

  /**
   * Get the current steps items.
   *
   * @returns MenuItem[]
   */
  getStepsItems(): MenuItem[] {
    return this.stepItems;
  }

  /**
   * Get the talent candidates.
   *
   * @returns Candidate[]
   */
  getTalentCandidates(): Candidate[] {
    return this.talentCandidates;
  }

  /**
   * Set the given position at the given index.
   *
   * @param position Position
   * @param index number
   * @returns void
   */
  setPosition(position: Character | Role, index: number): void {
    const item: Candidate = this.positionCandidates[index];
    item.setObject(position);

    if (_isObject(position)) {
      if (this.isCast()) {
        (<Cast>this.creditsCandidates[index]).setCharacterId(position.id);
      } else {
        (<Crew>this.creditsCandidates[index]).setRoleId(position.id);
      }
      item.setAsReady();
    } else {
      item.setAsNotReady();
    }
  }

  /**
   * Set the position with the given index as ready.
   *
   * @param index number
   * @returns void
   */
  setPositionAsReady(index: number): void {
    this.positionCandidates[index].setAsReady();
  }

  /**
   * Set the position with the given index as not ready.
   *
   * @param index number
   * @returns void
   */
  setPositionAsNotReady(index: number): void {
    this.positionCandidates[index].setAsNotReady();
  }

  /**
   * Get the position names.
   *
   * @returns string[]
   */
  getPositionNames(): string[] {
    return this.positionNames;
  }

  /**
   * Set the position names.
   *
   * @param value string
   * @returns void
   */
  setPositionNames(value: string): void {
    this.positionNames = this.prettifyString(value);
  }

  /**
   * get the position observable executor.
   *
   * @returns void
   */
  getPositionObservableExecutor(): ObservableExecutor {
    return this.positionObservableExecutor;
  }

  /**
   * Set the position observable executor.
   *
   * @param value ObservableExecutor
   * @returns void
   */
  setPositionObservableExecutor(executor: ObservableExecutor): void {
    this.positionObservableExecutor = executor;
  }

  /**
   * Set the given talent at the given index.
   *
   * @param talent Talent
   * @param index number
   * @returns void
   */
  setTalent(talent: Talent, index: number): void {
    const item: Candidate = this.talentCandidates[index];
    item.setObject(talent);

    if (_isObject(talent)) {
      this.creditsCandidates[index].setTalentId(talent.id);
      item.setAsReady();
    } else {
      item.setAsNotReady();
    }
  }

  /**
   * Set the talent with the given index as ready.
   *
   * @param index number
   * @returns void
   */
  setTalentAsReady(index: number): void {
    this.talentCandidates[index].setAsReady();
  }

  /**
   * Set the talent with the given index as not ready.
   *
   * @param index number
   * @returns void
   */
  setTalentAsNotReady(index: number): void {
    this.talentCandidates[index].setAsNotReady();
  }

  /**
   * Get the talent names.
   *
   * @returns string[]
   */
  getTalentNames(): string[] {
    return this.talentNames;
  }

  /**
   * Set the talent names.
   *
   * @param value string
   * @returns void
   */
  setTalentNames(value: string): void {
    this.talentNames = this.prettifyString(value);
  }

  /**
   * get the talent observable executor.
   *
   * @returns void
   */
  getTalentObservableExecutor(): ObservableExecutor {
    return this.talentObservableExecutor;
  }

  /**
   * Set the talent observable executor.
   *
   * @param value ObservableExecutor
   * @returns void
   */
  setTalentObservableExecutor(executor: ObservableExecutor): void {
    this.talentObservableExecutor = executor;
  }

  /**
   * get the adding form.
   *
   * @returns AddingStepForm
   */
  getAddingForm(): AddingStepForm {
    return this.addingForm;
  }

  /**
   * Set the adding form.
   *
   * @param value AddingStepForm
   * @returns void
   */
  setAddingForm(value: AddingStepForm): void {
    this.addingForm = value;
  }

  /**
   * Get the credits candidates.
   *
   * @returns Credit[]
   */
  getCreditsCandidates(): Credit[] {
    return this.creditsCandidates;
  }

  /**
   * Set the is guest value of the given cast index with the given value.
   *
   * @param index number
   * @param guest boolean
   * @returns void
   */
  setCastIsGuest(index: number, guest: boolean): void {
    (<Cast>this.creditsCandidates[index]).setIsGuest(guest);
  }

  /**
   * Set the current locale with the given value.
   *
   * @param value string
   * @returns void
   */
  setLocale(value: string): void {
    this.locale = value;
  }

  /**
   * Returns the current title.
   *
   * @returns Title
   */
  getTitle(): Title {
    return this.title;
  }

  /**
   * Set the current title with the given values.
   *
   * @param id number
   * @param type TitleType
   * @returns void
   */
  setTitle(id: number, type: TitleType): void {
    this.title = new Title({ id: id, type: type });
  }

  /**
   * Set the current order with the given value.
   *
   * @param order number
   * @returns void
   */
  setOrder(order: number): void {
    this.order = order;
  }

  /**
   * Get the bulk add observable executor.
   *
   * @returns ObservableExecutor
   */
  getBulkAddObservableExecutor(): ObservableExecutor {
    return this.additionObservableExecutor;
  }

  /**
   * Get the creation observable executor.
   *
   * @returns ObservableExecutor
   */
  getCreationObservableExecutor(): ObservableExecutor {
    return this.creationObservableExecutor;
  }

  /**
   * Indicates if it has errors.
   *
   * @returns boolean
   */
  hasErrors(): boolean {
    return this.additionErrors.length > 0 || this.creationErrors.length > 0;
  }

  /**
   * Indicates if it has one position per talent.
   *
   * @returns boolean
   */
  hasOnePositionPerTalent(): boolean {
    const itHas: boolean = this.positionNames.length === this.talentNames.length;
    return itHas;
  }

  /**
   * Indicates if the bulk add process is completed.
   *
   * @returns boolean
   */
  isBulkAddComplete(): boolean {
    return _isMap(this.additionObservables) && this.additionObservableExecutor.getQueueLength() === 0;
  }

  /**
   * Indicates if the wizard is working with localized items.
   *
   * @returns boolean
   */
  isLocalized(): boolean {
    const isIt: boolean = this.creditType === CreditType.DUBBING;
    return isIt;
  }

  /**
   * Indicates if the wizard is working with original items.
   *
   * @returns boolean
   */
  isOriginal(): boolean {
    const isIt: boolean = this.creditType === CreditType.ORIGINAL;
    return isIt;
  }

  /**
   * Indicates if the position for the given index is ready.
   *
   * @param index number
   * @returns boolean
   */
  isPositionReady(index: number): boolean {
    return this.positionCandidates[index].isReady();
  }

  /**
   * Indicates if the talent for the given index is ready.
   *
   * @param index number
   * @returns boolean
   */
  isTalentReady(index: number): boolean {
    return this.talentCandidates[index].isReady();
  }

  /**
   * Continues to the next step.
   *
   * @returns void
   */
  nextStep(): void {
    switch (this.currentStep.getName()) {
      case StepEnum.adding:
        this.continueToValidationStep();
        break;
      case StepEnum.validating:
        this.layoutHandler.changeToCreating();
        this.continueToCreationStep();
        break;
      default:
        throw this.invalidStepError;
    }
  }

  /**
   * Backs to the previous step.
   *
   * @returns void
   */
  previousStep(): void {
    switch (this.currentStep.getName()) {
      case StepEnum.validating:
        this.backToAddingStep();
        break;
      case StepEnum.creating:
        this.backToValidatingStep();
        break;
      default:
        throw this.invalidStepError;
    }
  }

  /**
   * Resets the wizard to step 1.
   *
   * @returns void
   */
  reset(): void {
    this.cancelObservablesExecution();
    this.setInitialValues();
    this.addingForm.reset();
  }

  /**
   * Retries the failed observables.
   *
   * @returns void
   */
  retry(): void {
    const hasCreationErrorsToRetry: boolean = this.creationErrors.some((response: ObservableResponse) => !response.error.is409());

    if (hasCreationErrorsToRetry) {
      this.startBulkCreation(true);
    } else {
      this.startBulkAdd(true);
    }
  }

  /**
   * Starts the bulk add process.
   *
   * @param isRetry boolean
   * @returns void
   */
  startBulkAdd(isRetry: boolean = false): void {
    if (!this.layoutHandler.isSaving()) {
      this.layoutHandler.changeToSaving();
    }

    this.continueToCreationStep();
    this.setupAdditionObservables(isRetry);

    if (this.additionObservables.size > 0) {
      this.additionObservableExecutor.setQueue(_cloneDeep(this.additionObservables));
    } else {
      this.layoutHandler.changeToReading();
    }
  }

  /**
   * Starts the bulk creation process.
   *
   * @param isRetry boolean
   * @returns void
   */
  startBulkCreation(isRetry: boolean = false): void {
    this.layoutHandler.changeToSaving();
    this.setupCreationObservables(isRetry);
    this.creationObservableExecutor.setQueue(this.creationObservables);
  }

  /**
   * Backs to the adding step.
   *
   * @returns void
   */
  private backToAddingStep(): void {
    this.cancelObservablesExecution();
    this.currentStep = this.steps[Step.ADDING_INDEX];
  }

  /**
   * Backs to the adding step.
   *
   * @returns void
   */
  private backToValidatingStep(): void {
    this.currentStep = this.steps[Step.VALIDATING_INDEX];
  }

  /**
   * Cancels the current observable executors.
   *
   * @returns void
   */
  private cancelObservablesExecution(): void {
    if (_isObject(this.talentObservableExecutor)) {
      this.talentObservableExecutor.cancelExecution();
    }

    if (_isObject(this.positionObservableExecutor)) {
      this.positionObservableExecutor.cancelExecution();
    }
  }

  /**
   * Creates an observable for creating a character with the given form.
   *
   * @param form CharacterOriginalDataForm
   * @returns Observable<StormServiceResponseSingle>
   */
  private createCharacterObservable(form: CharacterOriginalDataForm): Observable<StormServiceResponseSingle> {
    return this.characterService.createCharacter(new Character(form.toJson()));
  }

  /**
   * Creates an observable for creating a talent with the given form.
   *
   * @param form TalentOriginalDataForm
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  private createTalentObservable(form: TalentOriginalDataForm): Observable<StormServiceResponseSingleInterface> {
    return this.talentService.createTalent(new Talent(form.toJson()));
  }

  /**
   * Creates an observable for creating a role with the given form.
   *
   * @param form RoleOriginalDataForm
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  private createRoleObservable(form: RoleOriginalDataForm): Observable<StormServiceResponseSingleInterface> {
    return this.roleService.createRole(new Role(form.toJson()));
  }

  /**
   * Continues to the creation step.
   *
   * @returns void
   */
  private continueToCreationStep(): void {
    this.currentStep = this.steps[Step.CREATING_INDEX];
  }

  /**
   * Continues to the validation step.
   *
   * @returns void
   */
  private continueToValidationStep(): void {
    this.setupPositionCandidates();
    this.setupTalentCandidates();
    this.setupCreditsCandidates();
    this.currentStep = this.steps[Step.VALIDATING_INDEX];
  }

  /**
   * Indicates if the current wizard is for adding Cast.
   *
   * @returns boolean
   */
  private isCast(): boolean {
    return this.creditPositionType === CreditPositionType.CAST;
  }

  /**
   * Prettifies the given value.
   *
   * @param value string
   * @returns string[]
   */
  private prettifyString(value: string): string[] {
    return value.trim().split('\n').filter(
      (name: string) => name.length > 0
    );
  }

  /**
   * Set the initial values.
   *
   * @returns void
   */
  private setInitialValues(): void {
    this.additionErrors = [];
    this.additionObservables = new Map();
    this.creationErrors = [];
    this.creationObservables = new Map();
    this.setupSteps();
    this.currentStep = this.steps[Step.ADDING_INDEX];
    this.talentNames = [];
    this.creditsCandidates = [];
    this.talentCandidates = [];
    this.positionCandidates = [];
    this.positionNames = [];
  }

  /**
   * Set up the additional observables with the current credits candidates.
   *
   * @param isRetry boolean
   * @returns void
   */
  private setupAdditionObservables(isRetry: boolean = false): void {
    this.additionObservables = new Map();

    this.creditsCandidates.forEach(
      (credit: Credit, index: number) => {
        let isValidInCaseOfRetry: boolean = true;

        if (isRetry) {
          isValidInCaseOfRetry = this.additionErrors.some(
            (response: ObservableResponse) => response.key === index.toString() && !response.error.is409()
          );
        }

        if (credit.isComplete() && isValidInCaseOfRetry) {
          if (!isRetry) {
            credit.setOrder(this.order);
            this.order += 1;
          }

          const obs: Observable<any> = this.creditsService.create(this.title, this.creditPositionType, credit);
          this.additionObservables.set(index.toString(), obs);
        } else {
          if (!credit.isComplete()) {
            let hasBlockerError: boolean = false;
            const talentCandidate: Candidate = this.talentCandidates[index];
            const positionCandidate: Candidate = this.positionCandidates[index];

            if (talentCandidate.hasToCreate() || positionCandidate.hasToCreate()) {
              const key: string = talentCandidate.hasToCreate() ? talentCandidate.getKey() : positionCandidate.getKey();

              hasBlockerError = this.creationErrors.some(
                (errorResponse: ObservableResponse) => {
                  return errorResponse.key === key && errorResponse.error.is409();
              });
            }

            const error: ErrorHelper = new ErrorHelper('Incomplete credit', hasBlockerError ? 409 : undefined);

            this.additionErrors[index] = <ObservableResponse>{
              error: error,
              isError: true,
              key: index.toString()
            };
          }
        }
      }
    );

    if (isRetry) {
      this.additionErrors = this.additionErrors.filter(
        (errorResponse: ObservableResponse) => {
          const hasToKeepError: boolean = !this.creditsCandidates[errorResponse.key].isComplete();
          return hasToKeepError;
        });
    }
  }

  /**
   * Set up the creation observables.
   *
   * @param isRetry boolean
   * @returns void
   */
  private setupCreationObservables(isRetry: boolean = false): void {
    this.creationObservables = new Map();

    if (isRetry) {
      this.creationForms = this.creationForms.filter(
        (form: CharacterForm | TalentForm | RoleForm) => {
          return this.creationErrors.some((error: ObservableResponse) => error.key === form.getKey());
        }
      );

      this.creationErrors = [];
    }

    this.creationForms.forEach(
      (form: CharacterForm | TalentForm | RoleForm) => {
        const candidate: Candidate = this.getCreationCandidates().find((elem: Candidate) => elem.getKey() === form.getKey());
        let obs: Observable<any>;

        switch (candidate.getType().getValue()) {
          case TypeEnum.character:
            obs = this.createCharacterObservable(<CharacterOriginalDataForm>form);
            break;
          case TypeEnum.role:
            obs = this.createRoleObservable(<RoleOriginalDataForm>form);
            break;
          case TypeEnum.talent:
            obs = this.createTalentObservable(<TalentOriginalDataForm>form);
            break;
          default:
            throw new ErrorHelper('Invalid candidate type for creating step.');
        }

        this.creationObservables.set(candidate.getKey(), obs);
      }
    );
  }

  /**
   * Set up the credits candidates.
   *
   * @returns void
   */
  private setupCreditsCandidates(): void {
    const data: any = {
      type: this.creditType,
      locale: this.locale
    };

    this.creditsCandidates = new Array(this.positionNames.length);

    for (let index = 0; index < this.positionNames.length; index++) {
      this.creditsCandidates[index] = this.isCast() ? new Cast(data) : new Crew(data);
    }
  }

  /**
   * Set up the current steps.
   *
   * @returns void
   */
  private setupSteps(): void {
    this.steps = [
      new Step(StepEnum.adding, 'Fill form'),
      new Step(StepEnum.validating, 'Validation'),
      new Step(StepEnum.creating, 'Creation')
    ];

    this.stepItems = [
      { label: this.steps[Step.ADDING_INDEX].getDescription() },
      { label: this.steps[Step.VALIDATING_INDEX].getDescription() },
      { label: this.steps[Step.CREATING_INDEX].getDescription() }
    ];
  }

  /**
   * Set up the position candidates.
   *
   * @returns void
   */
  private setupPositionCandidates(): void {
    const candidateType: TypeEnum = this.isCast() ? TypeEnum.character : TypeEnum.role;
    this.positionCandidates = new Array(this.positionNames.length);
    this.positionCandidates.fill(undefined);

    this.positionNames.forEach(
      (name: string, index: number) => {
        this.positionCandidates[index] = new Candidate(name, candidateType, index);
      }
    );
  }

  /**
   * Set up the talent candidates.
   *
   * @returns void
   */
  private setupTalentCandidates(): void {
    this.talentCandidates = new Array(this.talentNames.length);
    this.talentCandidates.fill(undefined);

    this.talentNames.forEach(
      (name: string, index: number) => {
        this.talentCandidates[index] = new Candidate(name, TypeEnum.talent, index);
      }
    );
  }
}
