import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from '../common/common.module';

import {
  BoltPageProfileImportExportComponent
} from './components/bolt-page-profile-import-export/bolt-page-profile-import-export.component';

import { BoltProfileExportComponent } from './components/bolt-profile-export/bolt-profile-export.component';

import {
  BoltProfileExportEpisodesListComponent
} from './components/bolt-profile-export-episodes-list/bolt-profile-export-episodes-list.component';

import {
  BoltProfileExportLocalizationsSelectorComponent
} from './components/bolt-profile-export-localizations-selector/bolt-profile-export-localizations-selector.component';

import {
  BoltProfileExportSeasonSearchComponent
} from './components/bolt-profile-export-season-search/bolt-profile-export-season-search.component';

import {
  BoltProfileExportTitlesListComponent
} from './components/bolt-profile-export-titles-list/bolt-profile-export-titles-list.component';

import { BoltProfileFieldContentComponent } from './components/bolt-profile-field-content/bolt-profile-field-content.component';
import { BoltProfileImportComponent } from './components/bolt-profile-import/bolt-profile-import.component';
import { BoltProfileImportEpisodesComponent } from './components/bolt-profile-import-episodes/bolt-profile-import-episodes.component';
import { BoltProfileImportSeasonComponent } from './components/bolt-profile-import-seasons/bolt-profile-import-seasons.component';
import { BoltProfileImportSeriesComponent } from './components/bolt-profile-import-series/bolt-profile-import-series.component';
import { BoltProfileImportSummaryComponent } from './components/bolt-profile-import-summary/bolt-profile-import-summary.component';
import { BoltProfileTemplatesComponent } from './components/bolt-profile-templates/bolt-profile-templates.component';
import { CollectionManagerHelper } from '../common/helpers/collection-manager.helper';
import { ExportService } from './services/export/export.service';
import { ImportService } from './services/import/import.service';
import { ManagerService as ExportManagerService } from './services/export/manager-service/manager.service';
import { ManagerService as ImportManagerService } from './services/import/manager-service/manager.service';
import { profileModuleRouting } from './profile.routing';
import { RoleCapabilitiesHandler } from './services/role-capabilities-handler/role-capabilities-handler';
import { TemplateService } from './services/template/template.service';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    profileModuleRouting
  ],
  declarations: [
    BoltPageProfileImportExportComponent,
    BoltProfileExportComponent,
    BoltProfileExportLocalizationsSelectorComponent,
    BoltProfileExportSeasonSearchComponent,
    BoltProfileExportTitlesListComponent,
    BoltProfileFieldContentComponent,
    BoltProfileImportComponent,
    BoltProfileImportEpisodesComponent,
    BoltProfileImportSeasonComponent,
    BoltProfileImportSeriesComponent,
    BoltProfileImportSummaryComponent,
    BoltProfileTemplatesComponent,
    BoltProfileExportEpisodesListComponent
  ],
  exports: [
    BoltPageProfileImportExportComponent
  ],
  providers: [
    CollectionManagerHelper,
    ExportManagerService,
    ExportService,
    ImportManagerService,
    ImportService,
    TemplateService,
    RoleCapabilitiesHandler
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ProfileModule { }
