import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AutoComplete } from 'primeng';
import {
  union as _union, uniq as _uniq, isObject as _isObject, isString as _isString, isNull as _isNull
} from 'lodash';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';

import { Locale } from 'app/modules/common/models/locale/locale.model';
import { LanguageLocalizationForm } from '../../models/localization/form/language/language-localization-form.model';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { TitleAttributes } from '../../models/title/title-attributes.enum';
import { BoltTitleLocalizationFormComponent } from '../bolt-title-localization-form/bolt-title-localization-form.component';
import { Language } from '@bolt/ui-shared/master-data';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { ConfigService } from 'app/shared/services/form/config/config.service';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';

@Component({
  selector: 'bolt-title-language-localization-form',
  template: require('./bolt-title-language-localization-form.component.html'),
  styles: [require('./bolt-title-language-localization-form.scss')]
})
export class BoltTitleLanguageLocalizationFormComponent extends BoltTitleLocalizationFormComponent implements OnInit {
  @Input() form: LanguageLocalizationForm;
  @Input() formSpecs: any;
  @ViewChild('keywordsInput') autocompleteCharge: AutoComplete;

  readonly previousValueMessage: string = 'This field will show the previous saved value for Proposed Update.';
  protected keywords: string[] = [];
  protected locale: Locale;
  protected titleAttributes: typeof TitleAttributes = TitleAttributes;
  protected featureAttributes: typeof FeatureAttributes = FeatureAttributes;
  protected languageFieldLimits: any;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected masterdataManager: MasterDataManager,
    protected listsProvider: StormListsProvider,
    protected configService: ConfigService
  ) {
    super(appConfigurationManager, listsProvider);
  }

  ngOnInit() {
    this.locale = new Locale(this.title.getRawObject());
    this.languageFieldLimits = this.configService.get('title.languageLocalization.fields');
  }

  /**
   * Indicates if the given attribute is from pmx.
   *
   * @param attribute string
   * @returns boolean
   */
  isFromPmx(attribute: string): boolean {
    const itIs: boolean =
      _isObject(this.title.bpiField) &&
      _isString(this.title.bpiField[attribute]) &&
      this.title.bpiField[attribute].split('_')[0] === 'PMX';

    const isLocaleValid: boolean = (
      !_isNull(this.locale.language) &&
      (Language.isEnglish(this.locale.language)  || Language.isFrenchCanadian(this.locale.language))
    );

    return itIs && isLocaleValid;
  }

  /**
   * Indicates if it has to display the computed message.
   *
   * @param fieldName string
   * @returns boolean
   */
  hasDisplayComputedMessage(fieldName: string): boolean {
    const hasIt: boolean = this.title.isNonEmptyRootField(fieldName) || this.title.isComputedField(fieldName);
    return hasIt;
  }

  /**
   * Fakes a keywords source.
   * Set's the same user's input as the suggested keyword.
   *
   * @param event any
   * @returns void
   */
  protected getKeywords(event: any): void {
    const keyword = (<string>event.query).trim();
    const separators = [',', '،'];
    let shouldMerge: boolean = false;
    this.keywords = keyword.split(new RegExp(separators.join('|'), 'g'));

    if (keyword.length > 0) {
      separators.forEach(
        (elem: string) => {
          if (keyword[keyword.length - 1] === elem) {
            shouldMerge = true;
          }
        }
      );
    }

    if (shouldMerge) {
      this.mergeKeywords();
    }
  }

  /**
   * Indicates if it has to display the alternative title field.
   *
   * @returns boolean
   */
  protected hasDisplayAltTitleField(): boolean {
    const itHas: boolean =
      Language.isJapanese(this.locale.language) &&
      this.locale.isLanguageRoot() &&
      this.hasAttribute(TitleAttributes.altTitle);

    return itHas;
  }

  /**
   * Indicates if it has to display the short title field.
   *
   * @returns boolean
   */
  protected hasDisplayShortTitleField(): boolean {
    return this.title.isFeature() && this.hasAttribute(FeatureAttributes.shortTitle);
  }

  /**
   * Indicates if it has to display the keywords field.
   *
   * @returns boolean
   */
  protected hasDisplayKeywordsField(): boolean {
    return !this.title.isSeries() && this.hasAttribute(FeatureAttributes.keywords);
  }

  /**
   * Check what key is pressed in keywords field.
   *
   * @param event any
   * @returns void
   */
  protected keyDetectKeywords(event: any): void {
    if (event.key === 'Enter') {
      this.mergeKeywords();
    }
  }

  protected loadLists(): void {
    // Do nothing. Language doesn't have lists.
  }

  /**
   * Merge keywords entry.
   *
   * @returns void
   */
  protected mergeKeywords(): void {
    let oldValue: string[] = _union(this.form.getKeywordsField().value, this.keywords);
    oldValue = this.processTags(oldValue);

    this.form.getKeywordsField().setValue(oldValue);
    this.autocompleteCharge.multiInputEL.nativeElement.value = '';
  }

  /**
   * Remove empty elements.
   *
   * @param collection string[]
   * @returns string[]
   */
  protected processTags(collection: string[]): string[] {
    let sanitizedCollection: string[] =
      collection.filter(
        (el: string) => {
          if ((el !== null) && (el !== undefined)) {
            return el.trim() !== '';
          }
        }
      )
      .map(
        (el: string) => el.trim().replace(/\s+/g, ' ')
      );

    sanitizedCollection = _uniq(sanitizedCollection);

    return sanitizedCollection;
  }

  /**
   * Updates the locale with the current form values.
   *
   * @returns void
   */
  protected updateLocale(): void {
    this.locale = new Locale(this.form.toJson());
  }
}
