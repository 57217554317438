import { includes as _includes, startCase as _startCase } from 'lodash';
import { SelectionItem } from '@bolt/ui-shared/droplists';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StatusEnum } from './status.enum';


export class Status {
  protected name: string;
  protected value: StatusEnum;

  constructor(value: StatusEnum) {
    this.setValue(value);
  }

  /**
   * Returns a list of all possible status.
   *
   * @returns Status[]
   */
  static asList(): Status[] {
    const list: Status[] = [
      Status.newHalted(),
      Status.newInProgress(),
      Status.newCompleted(),
      Status.newError()
    ];

    return list;
  }

  /**
   * Returns a list of `SelectionItem` using all possible values for a status.
   *
   * @returns SelectionItem[]
   */
  static asSelectionItemList(): SelectionItem[] {
    const items: SelectionItem[] = Status.asList().map(
      (status: Status) => new SelectionItem(status.getName(), status.getValue(), status)
    );

    return items;
  }

  /**
   * Returns a new instance for "Completed"
   *
   * @returns Status
   */
  static newCompleted(): Status {
    return new Status(StatusEnum.Completed);
  }

  /**
   * Returns a new instance for "Error"
   *
   * @returns Status
   */
  static newError(): Status {
    return new Status(StatusEnum.Error);
  }

  /**
   * Returns a new instance for "Halted"
   *
   * @returns Status
   */
  static newHalted(): Status {
    return new Status(StatusEnum.Halted);
  }

  /**
   * Returns a new instance for "InProgress"
   *
   * @returns Status
   */
  static newInProgress(): Status {
    return new Status(StatusEnum.InProgress);
  }

  /**
   * Returns the name.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Returns the value.
   *
   * @returns StatusEnum
   */
  getValue(): StatusEnum {
    return this.value;
  }

  /**
   * Indicates if is the same value.
   *
   * @param value StatusEnum
   * @returns boolean
   */
  is(value: StatusEnum): boolean {
    return this.value === value;
  }

  /**
   * Indicates if it is a "Completed".
   *
   * @returns boolean
   */
  isCompleted(): boolean {
    return this.is(StatusEnum.Completed);
  }

  /**
   * Indicates if it is an "Error".
   *
   * @returns boolean
   */
  isError(): boolean {
    return this.is(StatusEnum.Error);
  }

  /**
   * Indicates if it is a "Halted".
   *
   * @returns boolean
   */
  isHalted(): boolean {
    return this.is(StatusEnum.Halted);
  }

  /**
   * Indicates if it is an "InProgress".
   *
   * @returns boolean
   */
  isInProgress(): boolean {
    return this.is(StatusEnum.InProgress);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
   toString(): string {
    return this.name;
  }

  /**
   * Discovers the name using the stored value.
   *
   * @returns void
   */
  protected discoverName(): void {
    const targetKey: string = Object.keys(StatusEnum).find(
      (currentKey: string) => StatusEnum[currentKey] === this.getValue()
    );

    this.name = _startCase(targetKey);
  }

  /**
   * Sets the value.
   *
   * @param value StatusEnum
   * @throws ErrorHelper
   * @returns void
   */
  protected setValue(value: StatusEnum): void {
    if (_includes(StatusEnum, value)) {
      this.value = <StatusEnum>value;
      this.discoverName();
    } else {
      throw new ErrorHelper('Invalid value given for a CPM Event Status.');
    }
  }
}
