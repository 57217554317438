import { FormControl, Validators } from '@angular/forms';
import { Account } from '@bolt/ui-shared/master-data';

import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';

export class AccountForm extends BoltForm {
  protected account: Account;
  protected name: FormControl;
  protected code: FormControl;

  constructor(fieldSpecs: any, account?: Account) {
    super(fieldSpecs);
    this.account = account;
    this.setupFields();
  }

  toJson(): any {
    const output: any = {
      name: this.name.value,
      code: this.code.value
    };

    if (this.account) {
      output.id = this.account.id;
    }

    return output;
  }

  /**
   * Returns the "Name" field.
   *
   * @returns FormControl
   */
  getNameField(): FormControl {
    return this.name;
  }

  /**
   * Returns the "Code" field.
   *
   * @returns FormControl
   */
  getCodeField(): FormControl {
    return this.code;
  }

  protected setupFields(): void {
    this.setupName();
    this.setupCode();
  }

  /**
   * Set up the "Name" field.
   *
   * @returns void
   */
  protected setupName(): void {
    this.name = new FormControl(
      this.account ? this.account.name : undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.nameMaxLength)
      ])
    );
    this.addControl('name', this.name);
  }

  /**
   * Set up the "Code" field.
   *
   * @returns void
   */
  protected setupCode(): void {
    this.code = new FormControl(
      this.account ? this.account.code : undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.codeMaxLength),
        Validators.pattern(/^[A-Z0-9]+$/)
      ])
    );
    this.addControl('code', this.code);
  }
}
