import { padStart as _padStart } from 'lodash';

export interface TimeObjectInterface {
  hour: number;
  minute: number;
  second: number;
}

export interface TimeInterface {
  time: TimeObjectInterface;
  parseSeconds(seconds: number): TimeObjectInterface;
  toSeconds(): number;
}

export class Time implements TimeInterface {
    protected _time: TimeObjectInterface = {
      hour: 0,
      minute: 0,
      second: 0
    };

    constructor(time: number | TimeObjectInterface) {
      if (Number.isInteger(<any>time)) {
        time = this.parseSeconds(<number>time);
      }

      time = <TimeObjectInterface>time;
      this._time = time;
    }

    get time(): TimeObjectInterface {
      return this._time;
    }

    set time(time: TimeObjectInterface) {
      this._time = time;
    }

    parseSeconds(seconds: number): TimeObjectInterface {
      let minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;

      const hours = Math.floor(minutes / 60);
      minutes = minutes % 60;

      return {
        hour: hours,
        minute: minutes,
        second: seconds
      };
    }

    toSeconds(): number {
      return (this._time.hour * 60 * 60) +
        (this._time.minute * 60) +
        this._time.second;
    }

    toString() {
      return this._time
        ? [
          _padStart(this._time.hour.toString(), 2, '0'),
          _padStart(this._time.minute.toString(), 2, '0'),
          _padStart(this._time.second.toString(), 2, '0')
        ].join(':')
        : '';
    }
}
