import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { BoltPageTitleDetailsComponent } from './components/bolt-page-title-details/bolt-page-title-details.component';
import { modulesPath } from '../auth/services/role/modules-path';
import { StormPageTitleComponent } from './components/storm-page-title/storm-page-title.component';
import { StormPageTitleListComponent } from './components/storm-page-title-list/storm-page-title-list.component';


export const titleRoutes: Routes = [
  {
    path: 'titles',
    component: StormPageTitleComponent,
    canActivate: [AuthGuard],
    data: {
      boltAuthAware: modulesPath.titles.path
    },
    children: [
      {
        path: '',
        component: StormPageTitleListComponent,
        data: {
          title: 'Title List',
        }
      },
      {
        path: ':productType/:productId',
        component: BoltPageTitleDetailsComponent,
        data: {
          title: 'Title',
        }
      },
      {
        // The productTitle is not necessary anymore, but we keep this entry only for backward compatibility.
        path: ':productType/:productId/:productTitle',
        component: BoltPageTitleDetailsComponent,
        data: {
          title: 'Title List',
        }
      }
    ]
  }
];

export const titleModuleRouting: ModuleWithProviders = RouterModule.forChild(titleRoutes);
