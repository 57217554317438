import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Template } from '../../models/template/template.model';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';


@Injectable()
export class TemplateService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns a subscription to get the template list.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  fetch(onSuccessDo: any, onErrorDo: any, finallyDo?: any): Subscription {
    const url: string = this.generateUrl('pie.templateService.fetch.endpoint');

    const subs: Subscription = this.doGetRequest(
      { url: url, checkType: CheckTypeEnum.array },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const hasData: boolean = (
            successResponse &&
            (successResponse.item instanceof Array) &&
            (successResponse.item.length > 0)
          );

          if (hasData) {
            const items: any[] = successResponse.item;
            const templates: Template[] = new Array();

            items.forEach(
              item => {
                const template = new Template(item);
                templates.push(template);
              }
            );

            onSuccessDo(templates);
          } else {
            throw new ErrorHelper('Invalid response given');
          }
        } catch (err) {
          onErrorDo(err);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }
}
