import { FormControl, Validators } from '@angular/forms';
import { Language } from '@bolt/ui-shared/master-data';

import { isObject as _isObject, isNumber as _isNumber } from 'lodash';
import { CharacterForm } from '../character-form.model';


export class LocalizedDataForm extends CharacterForm {
  protected languageField: FormControl;
  protected nameField: FormControl;
  protected useDomesticField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  /**
   * Get the language field.
   *
   * @returns FormControl
   */
  getLanguageField(): FormControl {
    return this.languageField;
  }

  /**
   * Get the name field.
   *
   * @returns FormControl
   */
  getNameField(): FormControl {
    return this.nameField;
  }

  /**
   * Get the use domestic field.
   *
   * @returns FormControl
   */
  getUseDomesticField(): FormControl {
    return this.useDomesticField;
  }

  toJson(): any {
    const data: any = super.toJson();
    data.language = this.getLanguageField().value;
    data.name = this.getNameField().value;
    data.useDomestic = this.getUseDomesticField().value;

    return data;
  }

  protected setupFields() {
    super.setupFields();
    this.setupLanguageField();
    this.setupNameField();
    this.setupUseDomesticField();
  }

  /**
   * Set up the language field.
   *
   * @returns void
   */
  protected setupLanguageField(): void {
    const defaultLanguage: number = this.getDefaultValue('language');
    this.languageField = new FormControl(_isNumber(defaultLanguage) ? defaultLanguage : Language.ENGLISH_ID, Validators.required);
    this.addControl('language', this.languageField);
  }

  /**
   * Set up the name requested field.
   *
   * @returns void
   */
  protected setupNameField(): void {
    const validators: any = Validators.compose([
      Validators.required,
      Validators.maxLength(this.fieldSpecs.originalName.maxLength)
    ]);

    this.nameField = new FormControl(this.getDefaultValue('name'), validators);
    this.addControl('name', this.nameField);
  }

  /**
   * Set up the useDomestic field
   *
   * @returns void
   */
  protected setupUseDomesticField(): void {
    this.useDomesticField = new FormControl(this.getDefaultValue('useDomestic'));
    this.addControl('useDomestic', this.useDomesticField);
  }
}
