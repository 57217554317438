import { Component } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AppConfigProvider, AppConfigurationManager, Configuration } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isUndefined as _isUndefined, isObject as _isObject, isString as _isString, isNumber as _isNumber } from 'lodash';
import { debounceTime } from 'rxjs/operators';

import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-app-configuration-list',
  template: require('./bolt-app-configuration-list.html'),
  styles: [require('./bolt-app-configuration-list.scss')]
})
export class BoltAppConfigurationListComponent extends StormComponent {
  protected form: FormGroup;
  protected pager: MemoryPager;
  protected selectedConfiguration: Configuration;
  protected showEditSidePanel: boolean;
  protected query: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected formBuilder: FormBuilder,
    protected notificationService: NotificationService,
  ) {
    super();
    this.initialize();
  }

  get configurations(): Configuration[] {
    return this.pager.getCurrentPage();
  }

  /**
   * Search by the current query.
   *
   * @returns void
   */
  protected applySearch(): void {
    const rawConfigurations: Configuration[] = this.appConfigurationManager.getConfigurationsAsArray();

    if (this.query === '' || _isUndefined(this.query)) {
      this.pager.setRecords(rawConfigurations);
    } else {
      const filteredConfigurations: Configuration[] = rawConfigurations.filter(
        (element: Configuration) =>
          element.name.toLowerCase().includes(this.query.toLowerCase()) ||
          element.description.toLowerCase().includes(this.query.toLowerCase())
      );

      this.pager.setRecords(filteredConfigurations);
    }
  }

  /**
   * Closes the edit side panel.
   *
   * @returns void
   */
  protected closeEditSidePanel(): void {
    this.showEditSidePanel = false;
    this.selectedConfiguration = undefined;
  }

  /**
   * Open the edit side panel.
   *
   * @param selected Configuration
   * @returns void
   */
  protected edit(selected: Configuration): void {
    this.selectedConfiguration = selected;
    this.showEditSidePanel = true;
  }

  /**
   * Returns the sorting criteria for configurations.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (elemA: Configuration, elemB: Configuration) => {
      const nameA: string = elemA.name.trim().toLowerCase();
      const nameB: string = elemB.name.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Loads the current configurations.
   *
   * @returns void
   */
  protected loadConfigurations(): void {
    this.pager.setRecords(this.appConfigurationManager.getConfigurationsAsArray());
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Indicates if it has configurations.
   *
   * @returns boolean
   */
  protected hasConfigurations(): boolean {
    return this.pager.hasRecords();
  }

  /**
   * Indicates if it has to disable the edit button.
   *
   * @param configuration Configuration
   * @returns boolean
   */
  protected hasDisableEditButton(configuration: Configuration): boolean {
    return !_isNumber(configuration.id);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.setupPager();
    this.setupSearch();
    this.loadConfigurations();
    this.showEditSidePanel = false;
  }

  /**
   * Set up the memory pager.
   *
   * @returns void
   */
  protected setupPager(): void {
    this.pager = new MemoryPager(
      this.appConfig.get('ux.dataTables.pageSize'),
      this.getSortingCriteria()
    );
  }

  /**
   * Set up the search.
   *
   * @returns void
   */
  protected setupSearch(): void {
    this.form = this.formBuilder.group({ query: [''] });

    this.form.valueChanges.pipe(debounceTime(this.appConfig.get('ux.typing.debounceTime'))).subscribe(
      (value: any) => {
        this.query = value.query;
        this.applySearch();
      }
    );
  }

  /**
   * Updates the list with the given configuration.
   *
   * @param configuration configuration
   * @returns void
   */
  protected updateConfiguration(configuration: Configuration): void {
    this.appConfigurationManager.getConfigurations().set(configuration.name, configuration);
    this.applySearch();

    this.pager.setPageNumberFor(
      configuration,
      (configurationA: Configuration, newConfiguration: Configuration) => configurationA.name === newConfiguration.name
    );
  }
}
