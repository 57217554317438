import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { Company, Country, Language } from '@bolt/ui-shared/master-data';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';

import { Season } from '../../models/season.model';
import { Series } from '../../models/series.model';
import { TitleService } from '../../services/title.service';
import { TitleType } from '../../models/title.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Episode } from '../../models/episode.model';
import { Feature } from '../../models/feature.model';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-title-level-details',
  template: require('./bolt-title-level-details.html'),
  styles: [require('./bolt-title-level-details.scss')]
})
export class BoltTitleLevelDetailsComponent extends StormComponent implements OnInit {
  @Input() title: Episode | Season | Series | Feature;

  @ViewChild('feature', { static: true })
  protected featureTemplate: TemplateRef<any>;

  @ViewChild('series', { static: true })
  protected seriesTemplate: TemplateRef<any>;

  @ViewChild('season', { static: true })
  protected seasonTemplate: TemplateRef<any>;

  @ViewChild('episode', { static: true })
  protected episodeTemplate: TemplateRef<any>;

  protected extraSeasonData: Season;
  protected extraSeasonError: ErrorHelper;
  protected extraSeriesData: Series;
  protected extraSeriesError: ErrorHelper;
  protected template: TemplateRef<any>;
  protected fullGenres: SelectionItem[];
  protected countryOfOrigin: Country;
  protected spokenLanguages: Language;
  protected productionCompany: Company;

  constructor(
    protected notificationService: NotificationService,
    protected titleService: TitleService,
    protected listLayoutProvider: ListLayoutProvider,
    protected appConfigurationManager: AppConfigurationManager,
  ) {
    super();
    this.title = undefined;
    this.template = undefined;
  }

  ngOnInit() {
    this.resetExtraSeason();
    this.resetExtraSeries();

    this.discoverTemplate();
    this.retrieveExtraTitles();
  }

  /**
   * Discovers the template
   *
   * @returns void
   */
  protected discoverTemplate(): void {
    this.template = this[`${this.title.type}Template`];
  }

  /**
   * Reset the extra season.
   *
   * @returns void
   */
  protected resetExtraSeason(): void {
    this.extraSeasonData = undefined;
    this.extraSeasonError = undefined;
  }

  /**
   * Reset the extra series.
   *
   * @returns void
   */
  protected resetExtraSeries(): void {
    this.extraSeriesData = undefined;
    this.extraSeriesError = undefined;
  }

  /**
   * Retrieves the extra season.
   *
   * @returns void
   */
  protected retrieveExtraSeason(): void {
    this.resetExtraSeason();

    const params: any = {
      productId: (this.title as Episode).seasonId,
      productType: TitleType.season
    };

    this.titleService.fetchProduct(params, true).subscribe(
      (response: StormServiceResponseSingle) => {
        this.extraSeasonData = response.item;
        this.retrieveExtraSeries();
      },
      (error: any) => {
        const message: string = 'Failed retrieving additional season, so cannot retrieve additional series too.';

        this.extraSeasonError = new ErrorHelper('Failed retrieving additional season.');
        this.extraSeriesError = new ErrorHelper('Cannot retrieve additional series without additional season.');

        this.notificationService.handleError(message, error);
      }
    );
  }

  /**
   * Retrieves the extra series.
   *
   * @returns void
   */
  protected retrieveExtraSeries(): void {
    this.resetExtraSeries();

    const params: any = {
      productId: (_isObject(this.extraSeasonData) ? this.extraSeasonData.seriesId : (this.title as Season).seriesId),
      productType: TitleType.series
    };

    this.titleService.fetchProduct(params, true).subscribe(
      (response: StormServiceResponseSingle) => {
        this.extraSeriesData = response.item;
      },
      (error: any) => {
        const message: string = 'Failed retrieving additional series.';

        this.extraSeriesError = new ErrorHelper(message);
        this.notificationService.handleError(message, error);
      }
    );
  }

  /**
   * Retrieves the series and/or season when title is not a feature.
   *
   * @returns void
   */
  protected retrieveExtraTitles(): void {
    if (this.title.type === TitleType.episode) {
      this.retrieveExtraSeason();
    } else if (this.title.type === TitleType.season) {
      this.retrieveExtraSeries();
    }
  }

  /**
   * Get the selected array of genres as labels
   *
   * @returns string[]
   */
  protected getSelectedGenres(): string[] {
    this.fullGenres = this.listLayoutProvider.getGenres();
    const genresId = (this.title as Feature | Season).genreId;

    if (genresId) {
      return this.fullGenres.filter(
        (genre: any) => (this.title as Feature | Season).genreId.includes(genre.value)
      ).map(
        (genre: any): string => genre.source.name
      );
    } else {
      return undefined;
    }
  }

  /**
   * Gets the selected Country of Origin.
   *
   * @returns Country
   */
  protected getCountryOfOrigin(): Country {
    const countryId = (this.title as Season | Series | Feature).countryOfOriginId;
    return countryId ? this.countryOfOrigin = this.listLayoutProvider.getTerritoryById(countryId) : undefined;
  }

  /**
   * Gets the selected Spoken Languages.
   *
   * @returns Language
   */
  protected getSpokenLanguages(): Language {
    const spokenId = this.title.originalSpokenLanguageId;
    return spokenId ? this.spokenLanguages = this.listLayoutProvider.getLanguageById(spokenId) : undefined;
  }

  /**
   * Gets the selected Production Companies.
   * @returns string[]
   */
  protected getProductionCompanies(): string[] {
    const productionIds = (this.title as Series | Feature).productionCompanyIds;
    return productionIds ? this.listLayoutProvider.getProductionCompanyByIds(productionIds) : undefined;
  }

  /**
   * Gets the selected Production Company.
   *
   * @returns Company
   */
  protected getProductionCompany(): Company {
    const productionId = (this.title as Series | Feature).productionCompanyId;
    return productionId ? this.productionCompany = this.listLayoutProvider.getProductionCompanyById(productionId) : undefined;
  }
}
