import { includes as _includes, startCase as _startCase } from 'lodash';
import { SelectionItem } from '@bolt/ui-shared/droplists';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { TypeEnum } from './type.enum';


export class Type {
  protected name: string;
  protected value: TypeEnum;

  constructor(value: TypeEnum) {
    this.setValue(value);
  }

  /**
   * Returns a list of all possible types.
   *
   * @returns Type[]
   */
  static asList(): Type[] {
    const list: Type[] = [
      Type.newFeature(),
      Type.newFeaturePmx(),
      Type.newFeatureLocalePmx(),
      Type.newSeries(),
      Type.newSeason(),
      Type.newEpisode()
    ];

    return list;
  }

  /**
   * Returns a list of `SelectionItem` using all possible values for a type.
   *
   * @returns SelectionItem[]
   */
  static asSelectionItemList(): SelectionItem[] {
    const items: SelectionItem[] = Type.asList().map(
      (status: Type) => new SelectionItem(status.getName(), status.getValue(), status)
    );

    return items;
  }

  /**
   * Returns a new instance for "Episode"
   *
   * @returns Type
   */
  static newEpisode(): Type {
    return new Type(TypeEnum.Episode);
  }

  /**
   * Returns a new instance for "Feature"
   *
   * @returns Type
   */
  static newFeature(): Type {
    return new Type(TypeEnum.Feature);
  }

  /**
   * Returns a new instance for "FeaturePmx"
   *
   * @returns Type
   */
  static newFeaturePmx(): Type {
    return new Type(TypeEnum.FeaturePmx);
  }

  /**
   * Returns a new instance for "FeatureLocalePmx"
   *
   * @returns Type
   */
   static newFeatureLocalePmx(): Type {
    return new Type(TypeEnum.FeatureLocalePmx);
  }

  /**
   * Returns a new instance for "Season"
   *
   * @returns Type
   */
  static newSeason(): Type {
    return new Type(TypeEnum.Season);
  }

  /**
   * Returns a new instance for "Series"
   *
   * @returns Type
   */
  static newSeries(): Type {
    return new Type(TypeEnum.Series);
  }

  /**
   * Returns the name.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Returns the value.
   *
   * @returns TypeEnum
   */
  getValue(): TypeEnum {
    return this.value;
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value TypeEnum
   * @returns boolean
   */
  is(value: TypeEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }

  /**
   * Indicates if it is an "Episode".
   *
   * @returns boolean
   */
  isEpisode(): boolean {
    return this.is(TypeEnum.Episode);
  }

  /**
   * Indicates if it is an "Feature".
   *
   * @returns boolean
   */
  isFeature(): boolean {
    return this.is(TypeEnum.Feature);
  }

  /**
   * Indicates if it is a "FeaturePmx".
   *
   * @returns boolean
   */
  isFeaturePmx(): boolean {
    return this.is(TypeEnum.FeaturePmx);
  }

  /**
   * Indicates if it is a "FeatureLocalePmx".
   *
   * @returns boolean
   */
   isFeatureLocalePmx(): boolean {
    return this.is(TypeEnum.FeatureLocalePmx);
  }

  /**
   * Indicates if it is a "Season".
   *
   * @returns boolean
   */
  isSeason(): boolean {
    return this.is(TypeEnum.Season);
  }

  /**
   * Indicates if it is a "Series".
   *
   * @returns boolean
   */
  isSeries(): boolean {
    return this.is(TypeEnum.Series);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this.name;
  }

  /**
   * Discovers the name using the stored value.
   *
   * @returns void
   */
  protected discoverName(): void {
    if (this.isFeaturePmx()) {
      this.name = 'Feature (PMX)';
    } else if (this.isFeatureLocalePmx()) {
      this.name = 'Feature (Locale PMX)';
    } else {
      const targetKey: string = Object.keys(TypeEnum).find(
        (currentKey: string) => TypeEnum[currentKey] === this.getValue()
      );

      this.name = _startCase(targetKey);
    }
  }

  /**
   * Set the value.
   *
   * @param value TypeEnum
   * @returns void
   */
  protected setValue(value: TypeEnum): void {
    if (_includes(TypeEnum, value)) {
      this.value = value;
      this.discoverName();
    } else {
      throw new ErrorHelper('Invalid value given for a CPM Event Type.');
    }
  }
}
