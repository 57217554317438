import { Injectable } from '@angular/core';
import { Affix, AffixType, StormList, StormListItem, StormListType } from '@bolt/ui-shared/master-data';
import { isObject as _isObject, remove as _remove } from 'lodash';
import { StringHelper } from '@bolt/ui-shared/common';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';


@Injectable()
export class MetadataExtractorService {
  protected prefixes: Affix[];
  protected suffixes: Affix[];

  constructor(
    protected listProvider: StormListsProvider
  ) {
    this.loadAffixes();
  }

  /**
   * Extracts the metadata from the given full name and returns it.
   *
   * @param fullName string
   * @returns TalentInterface
   */
  extract(fullName: string): any {
    try {
      const words: string[] = this.obtainWords(fullName);
      const prefix: Affix = this.findAffix(this.prefixes, words);
      const suffix: Affix = this.findAffix(this.suffixes, words);
      const metadata: any = { };

      if (_isObject(prefix)) {
        _remove(words, (word: string) => word === prefix.value);
        metadata.prefixId = prefix.id;
      }

      if (_isObject(suffix)) {
        _remove(words, (word: string) => word === suffix.value);
        metadata.suffixId = suffix.id;
      }

      if (words.length === 1) {
        metadata.firstName = words[0];
      } else if (words.length === 2) {
        metadata.firstName = words[0];
        metadata.lastName = words[1];
      } else {
        metadata.firstName = words[0];
        metadata.middleName = words[1];
        metadata.lastName = words.slice(2).join(' ');
      }

      return metadata;
    } catch (error) {
      throw new ErrorHelper('Cannot extract the metadata from the given talent full name.', error);
    }
  }

  /**
   * Returns the affix in the given list using the given words.
   *
   * @param affixes Affix[]
   * @param words string
   * @returns Affix
   */
  protected findAffix(affixes: Affix[], words: string[]): Affix {
    const item: Affix = affixes.find(
      (affixItem: Affix) => words.includes(affixItem.value)
    );

    return item;
  }

  /**
   * Loads the prefixes and suffixes.
   *
   * @returns void
   */
  protected loadAffixes(): void {
    this.prefixes = new Array();
    this.suffixes = new Array();

    this.listProvider.getList(StormListType.affix).subscribe(
      (list: StormList) => {
        list.collection.forEach(
          (listItem: StormListItem) => {
            const affix: Affix = listItem.value;

            if (affix.type === AffixType.PREFIX) {
              this.prefixes.push(affix);
            } else {
              this.suffixes.push(affix);
            }
          }
        );
      }
    );
  }

  /**
   * Returns the words inside the given full name.
   *
   * @param fullName string
   * @throws ErrorHelper if no words are found in the full name.
   * @returns string[]
   */
  protected obtainWords(fullName: string): string[] {
    const words: string[] = StringHelper.toWords(fullName);

    if ((words.length === 1) && words[0] === '') {
      throw new ErrorHelper(`No words detected for: ${fullName}`);
    } else {
      return words;
    }
  }
}
