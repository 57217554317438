import { isNumber as _isNumber } from 'lodash';

import { RoleInterface } from 'app/modules/role/models/role.model';
import { Credit } from '../credit/credit.model';
import { CreditPositionType } from '../credit/credit-position.enum';


export class Crew extends Credit {
  protected roleId: number;
  protected position: RoleInterface;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
    this.positionType = CreditPositionType.CREW;
  }

  getRoleId(): number {
    return this.roleId;
  }

  setRoleId(id: number): void {
    this.roleId = id;
  }

  getPosition(): RoleInterface {
    return this.position;
  }

  getLocalizations(): Crew[] {
    return this._localizations;
  }

  setLocalizations(localizations: Crew[]) {
    this._localizations = localizations;
  }

  getRawObject(): object {
    const rawObject: any = super.getRawObject();
    rawObject.roleId = this.roleId;

    return rawObject;
  }

  isComplete(): boolean {
    return _isNumber(this.talentId) && _isNumber(this.roleId);
  }
}
