import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { Account, Country } from '@bolt/ui-shared/master-data';
import { Attribute, FunctionalMetadataEnum, FunctionalMetadataService, LevelModeEnum } from '@bolt/ui-shared/title';
import { isObject as _isObject, isUndefined as _isUndefined, isEmpty as _isEmpty, isBoolean as _isBoolean } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { Episode } from 'app/modules/title/models/episode.model';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { LocaleSpecificFieldEnum } from '../../models/locale-specific-field.enum';
import { LocaleSpecificOption } from '../../models/locale-specific-option.model';
import { TypeEnum as LocaleType } from 'app/modules/common/models/locale/type/type.enum';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';
import { BoltRatingService } from 'app/modules/title/services/bolt-rating.service';


@Component({
  selector: 'bolt-clone-metadata-manager',
  template: require('./bolt-clone-metadata-manager.html'),
  styles: [require('./bolt-clone-metadata-manager.scss')]
})
export class BoltCloneMetadataManagerComponent implements OnChanges, OnDestroy {
  @Input() show: boolean;
  @Input() title: Episode;
  @Input() locale: string;
  @Input() localeObject: Locale;
  @Output('closed') closeEvent: EventEmitter<undefined>;
  @Output('saved') saveEvent: EventEmitter<any>;

  @ViewChild('cloneModalRef') modal: ModalDirective;

  functionalMetadataSelected: boolean;
  shouldCheckNsa: boolean;
  functionalMetadataWarning: boolean;
  readonly functionalMetadataMessage = "If you want to override ALL existing Functional Metadata in the target episodes with the values that exist for this existing episode's localization, please make sure to select ALL Functional Metadata flags when copying over";

  protected cloneCandidates: Episode[];
  protected optionsFields: LocaleSpecificOption[];
  protected functionalMetadataOptions: FunctionalMetadataEnum[];
  protected selectedOptions: LocaleSpecificOption[];
  protected optionsColumns: number[];
  protected optionsColumnsNumber: number;
  protected readonly optionsByRow: number = 5;
  protected readonly functionalMetadataOption = new LocaleSpecificOption(LocaleSpecificFieldEnum.functionalMetadata, 'Functional Metadata');
  protected levelMode: LevelModeEnum;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected layoutHandler: LayoutHandlerService,
    protected functionalMetadataService: FunctionalMetadataService,
    protected boltRatingService: BoltRatingService
  ) {
    this.optionsFields = [];
    this.functionalMetadataOptions = [];
    this.optionsColumns = [];
    this.optionsColumnsNumber = 1;
    this.functionalMetadataSelected = false;
    this.shouldCheckNsa = false;

    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.localeObject.type.value !== LocaleType.language) {
      this.setLevelMode();
    }

    if (_isObject(changes.show)) {
      if (changes.show.currentValue) {
        this.setupOptions();
        this.open();
      } else {
        if (!changes.show.firstChange) {
          this.reset();
        }
      }
    }
  }

  ngOnDestroy() {
    this.reset();
  }

  /**
   * Checks if the functional metadata splitted fields should be displayed
   *
   * @returns boolean
   */
  shouldDisplayFunctionalMetadataFields(): boolean {
    return this.hasFunctionalMetadataEnabled() && !_isEmpty(this.functionalMetadataOptions);
  }

  /**
   * sets the level mode.
   *
   * @returns void
   */
  protected setLevelMode(): void {
    this.levelMode = (this.isTerritorySvod())
     ? LevelModeEnum.territoryAccount
     : LevelModeEnum[this.localeObject.type.toString()];
    }

  /**
   * Emits the onSelect event.
   *
   * @returns void
   */
  protected clone(): void {
    let selectedOptions: Array<LocaleSpecificFieldEnum  | FunctionalMetadataEnum> = this.selectedOptions.filter(
      (option: LocaleSpecificOption) => option.field !== LocaleSpecificFieldEnum.functionalMetadata
    ).map(
      (option: LocaleSpecificOption) => option.field
    );

    if (this.hasFunctionalMetadata()) {
      if (this.shouldDisplayFunctionalMetadataFields()) {
        selectedOptions = selectedOptions.concat(this.functionalMetadataOptions);
      } else {
        selectedOptions.push(LocaleSpecificFieldEnum.functionalMetadata);
      }
    }

    const data: any = {
      candidates: this.cloneCandidates.map((episode: Episode) => episode.id),
      fields: selectedOptions
    };

    this.close();
    this.saveEvent.emit(data);
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected close(): void {
    this.closeEvent.emit();
    this.modal.hide();
    this.reset();
  }

  /**
   * Indicates if the current selected options includes the given option.
   *
   * @param option LocaleSpecificFieldEnum
   * @returns boolean
   */
  protected selectedOptionsIncludes(option: LocaleSpecificFieldEnum): boolean {
    const itDoes: boolean = this.selectedOptions.some(
      (fieldOption: LocaleSpecificOption) => fieldOption.field === option
    );

    return itDoes;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    let hasIt: boolean =
      !this.layoutHandler.isReading() ||
      this.cloneCandidates.length === 0 ||
      this.selectedOptions.length === 0;

    hasIt = hasIt || !this.canCloneFunctionalMetadata();

    return hasIt;
  }

  /**
   * Checks if the exclude kids mode is selected to be cloned
   *
   * @returns boolean
   */
   protected hasFunctionalMetadata(): boolean {
    const hasFunctionalMetadata = !_isUndefined(this.selectedOptions.find(
      (value: LocaleSpecificOption) => value.field === LocaleSpecificFieldEnum.functionalMetadata
    ));

    return hasFunctionalMetadata;
  }

  /**
   * Checks if the current selection does not break the functional metadata NSA constraint
   *
   * @returns boolean
   */
  protected canCloneFunctionalMetadata(): boolean {
    let canClone = true;

    if (this.shouldCheckNsa && this.hasFunctionalMetadata()) {
      const hasNsaCandidates = this.cloneCandidates.some(
        (candidate: Episode) => candidate.heritageDisclaimer
      );

      canClone = !hasNsaCandidates;
    }

    return canClone;
  }

  /**
   * Updates the value that marks if the functional metadata requires NSA to be checked on the target
   *
   * @returns void
   */
  protected updateShouldCheckNsa(): void {
    this.shouldCheckNsa = this.hasFunctionalMetadata() && this.shouldDisplayFunctionalMetadataFields();
  }

  /**
   * Indicates if it has a title.
   *
   * @returns boolean
   */
  protected hasTitle(): boolean {
    return _isObject(this.title);
  }

  /**
   * Indicates if it has only one field options.
   *
   * @returns boolean
   */
  protected hasOnlyOneFieldOption(): boolean {
    const hasIt: boolean = this.optionsFields.length === 1 && this.functionalMetadataOptions.length === 0;
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.reset();
  }

  /**
   * Returns if the current localization is territory SVOD
   *
   * @returns boolean
   */
  protected isTerritorySvod(): boolean {
    const isDisneySvod = this.localeObject.account.length === 1 && Account.isDisneySvod(this.localeObject.account[0]);
    const isTerritory = this.localeObject.territory.length === 1 && !Country.isAll(this.localeObject.territory[0]);

    return isDisneySvod && isTerritory;
  }

  /**
   * Opens the modal.
   *
   * @returns void
   */
  protected open(): void {
    this.modal.show();
  }

  /**
   * Removes the given option from the current selected options.
   *
   * @param option LocaleSpecificOption
   * @returns void
   */
  protected removeOption(option: LocaleSpecificOption): void {
    const index: number = this.selectedOptions.findIndex(
      (fieldOption: LocaleSpecificOption) => fieldOption.field === option.field
    );

    this.selectedOptions.splice(index, 1);
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.cloneCandidates = [];
    this.optionsFields = [];
    this.selectedOptions = [];
    this.show = false;
    this.functionalMetadataSelected = false;
    this.shouldCheckNsa = false;
  }

  /**
   * Retrieves the modal title for the current title.
   *
   * @returns string
   */
  protected retrieveModalTitle(): string {
    const title: string = 'Clone Data to Episodes';
    return title;
  }

  /**
   * Retrieves the warning message for the current title.
   *
   * @returns string
   */
  protected retrieveWarningMessage(): string {
    const message: string =
      'This action will copy data to all episodes selected above. Any existing data will be overridden.';

    return message;
  }

  /**
   * Set the given title as the current selection.
   *
   * @param titles Episode[]
   * @returns void
   */
  protected setSelection(titles: Episode[]): void {
    this.cloneCandidates = titles;
  }

  /**
   * Set up the locale specific field options.
   *
   * @returns void
   */
  protected setupOptions(): void {
    const options: LocaleSpecificOption[] = new Array();

    switch (this.localeObject.type.value) {
      case LocaleType.account:
        this.fillFunctionalMetadataOn(LevelModeEnum.account);
        if (!this.hasFunctionalMetadataEnabled()) {
          this.storeOption(options, this.functionalMetadataOption.field, this.functionalMetadataOption.name);
        }
        this.storeOption(options, LocaleSpecificFieldEnum.lockStatus, 'Lock Status');
        break;
      case LocaleType.language:
        this.storeOption(options, LocaleSpecificFieldEnum.keywords, 'Keywords');
        break;
      case LocaleType.territory:
        /*
        ## The order of execution is important because it's going to be displayed in that order.
        1. Functional Metadata
        2. Ratings, and conditional Home Entertainment Ratings
        3. Not Functional Metadata
        4. South Korea
        5. Finally: Lock Status
        */

        if (this.isTerritorySvod()) {
          this.fillFunctionalMetadataOn(LevelModeEnum.territoryAccount);
        } else {
          this.functionalMetadataOptions = [];
        }

        this.storeOption(options, LocaleSpecificFieldEnum.ratingId, 'Rating');
        this.storeOption(options, LocaleSpecificFieldEnum.ratingSystemReasonId, 'Additional Rating Reason');
        this.storeOption(options, LocaleSpecificFieldEnum.ratingReason, 'Rating Reason');

        if (this.boltRatingService.isLanguageProductTypeAndAccountAll(this.title)) {
          this.storeOption(options, LocaleSpecificFieldEnum.homeEntRatingId, 'Home Entertainment Rating');
          this.storeOption(options, LocaleSpecificFieldEnum.homeEntRatingSystemReasonId, 'Home Entertainment Additional Rating Reason');
          this.storeOption(options, LocaleSpecificFieldEnum.homeEntRatingReason, 'Home Entertainment Rating Reason');
        }

        if (!this.hasFunctionalMetadataEnabled()) {
          this.storeOption(options, this.functionalMetadataOption.field, this.functionalMetadataOption.name);
        }
        this.fillSouthKoreaOn(options);
        this.storeOption(options, LocaleSpecificFieldEnum.lockStatus, 'Lock Status');
        break;
      default:
        throw new Error(`Invalid locale type given for setting up options: ${this.localeObject.type}`);
    }

    this.optionsFields = options;

    this.setOptionsColumns();

    if (this.hasOnlyOneFieldOption()) {
      this.updateSelected(this.optionsFields[0]);
    }
  }

  /**
   * Indicates if the Functional Metadata toggle is on.
   *
   * @returns boolean
   */
   protected hasFunctionalMetadataEnabled(): boolean {
    const hasIt = this.appConfigurationManager.getToggleValue(ToggleKeyEnum.functionalMetadataAsFieldsOn);

    return hasIt;
  }

  /**
   * Fills the options with functional metadata fields.
   * options param won't be necessary when toggle has been removed.
   *
   * @param mode LevelModeEnum
   * @returns void
   */
  protected fillFunctionalMetadataOn(mode: LevelModeEnum): void {
    if (this.hasFunctionalMetadataEnabled()) {

      if (mode === LevelModeEnum.account || this.isTerritorySvod()) {
        // TODO: Update the functional metadata repository to have the expected fields for territory
        this.functionalMetadataOptions = this.functionalMetadataService.getAttributesByLevel(mode).filter(
          (attribute: Attribute) => attribute.isEditable()
        ).map(
          (attribute: Attribute) => attribute.getName()
        );
      }
    }
  }

  /**
   * Adds a new LocaleSpecificOption to the given array.
   *
   * @param options LocaleSpecificOption[]
   * @param value LocaleSpecificFieldEnum
   * @param name string
   * @returns void
   */
  protected storeOption(options: LocaleSpecificOption[], value: LocaleSpecificFieldEnum, name: string): void {
    options.push(new LocaleSpecificOption(value, name));
  }

  /**
   * Fills the options for Korea territory.
   *
   * @param options LocaleSpecificOption[]
   * @returns void
   */
  protected fillSouthKoreaOn(options: LocaleSpecificOption[]): void {
    if (Country.isSouthKorea(this.localeObject.territory[0])) {
      this.storeOption(options, LocaleSpecificFieldEnum.ratingFilingNumberKmrb, 'Rating Filing Number (KMRB)');
      this.storeOption(options, LocaleSpecificFieldEnum.onairPlatformKcc, 'On-Air Platform (KCC)');
      this.storeOption(options, LocaleSpecificFieldEnum.onairDateKcc, 'On-Air Date (KCC)');
    }
  }

  /**
   * Calculates the offset to distribute the options columns.
   *
   * @param optionsColumn number
   * @returns number
   */
  protected calculateOffsetOptions(optionsColumn: number): number {
    return (optionsColumn - 1) * this.optionsByRow;
  }

  /**
   * Calculates the limit to distribute the options columns.
   *
   * @param optionsColumn number
   * @returns number
   */
  protected calculateLimitOptions(optionsColumn: number): number {
    return this.calculateOffsetOptions(optionsColumn) + this.optionsByRow;
  }

  /**
   * Sets the number of the options columns to draw.
   *
   * @returns void
   */
  protected setOptionsColumnsNumber(): void {
    this.optionsColumnsNumber = Math.ceil(this.optionsFields.length / this.optionsByRow);
  }

  /**
   * Sets the array of options columns to draw.
   *
   * @returns void
   */
  protected setOptionsColumns(): void {
    let numberOfColumns: number[] = [];

    this.setOptionsColumnsNumber();
    numberOfColumns = Array(this.optionsColumnsNumber).fill(null).map((x, i) => i + 1);

    this.optionsColumns = numberOfColumns;
  }

  /**
   * Updates the value of the given option.
   *
   * @param option LocaleSpecificOption
   * @returns void
   */
  protected updateSelected(option: LocaleSpecificOption): void {
    if (this.selectedOptionsIncludes(option.field)) {
      this.removeOption(option);
    } else {
      this.selectedOptions.push(option);
    }

    this.updateShouldCheckNsa();
  }
}
