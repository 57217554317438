import { Country, Language } from '@bolt/ui-shared/master-data';

import { Item } from '../item.model';
import { TitleType } from 'app/modules/title/models/title.model';


export class Feature extends Item {
  constructor(language: Language, territory: Country, id: number, legalTitle: string) {
    super();
    this.initialize(language, territory, id, legalTitle);
  }

  /**
   * Initializes the instance.
   *
   * @param language Language
   * @param territory Country
   * @param id number
   * @param legalTitle string
   * @returns void
   */
  protected initialize(language: Language, territory: Country, id: number, legalTitle: string): void {
    this.setType(TitleType.feature);
    this.setLanguage(language);
    this.setTerritory(territory);
    this.setId(id);
    this.setLegalTitle(legalTitle);
  }
}
