import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { NotificationService } from '@bolt/ui-shared/notification';
import { UserRole } from '@bolt/ui-shared/auth';
import { Subscription } from 'rxjs';

import {
  isObject as _isObject,
  cloneDeep as _cloneDeep,
  isNumber as _isNumber,
  isString as _isString,
  sortBy as _sortBy,
  isEmpty as _isEmpty
} from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { User } from '../../models/user.model';
import { UserManager } from '../../helpers/user-manager.helper';
import { UserManagerActions } from '../bolt-user-manager/bolt-user-manager.component';
import { UserService } from '../../services/user.service';


@Component({
  selector: 'bolt-user-list',
  template: require('./bolt-user-list.html'),
  styles: [require('./bolt-user-list.scss')]
})
export class BoltUserListComponent extends StormComponent implements OnInit, OnDestroy {
  @ViewChild('searchBy') protected searchByInput: ElementRef;

  protected fetchUsersListener: Subscription;
  protected pager: MemoryPager;
  protected rolesItems: SelectionItem[];
  protected selectedRole: string;
  protected selectedUser: User;
  protected searchedWords: string;
  private readonly noRoles: string = '(no roles)';

  constructor(
    protected appConfigProvider: AppConfigProvider,
    protected notificationService: NotificationService,
    protected userManager: UserManager,
    protected userService: UserService
  ) {
    super();

    this.searchedWords = '';
  }

  ngOnInit() {
    this.fetchUsers();
    this.loadRoles();
    this.setupUserManager();
    this.setupPager();
  }

  ngOnDestroy() {
    this.cancelFetchUserListener();
    this.userManager.reset();
  }

  /**
   * Finds the users with the given role.
   *
   * @param selectedRole string
   * @returns void
   */
  protected applyFilterBy(selectedRole: string): void {
    this.selectedRole = selectedRole;
    this.pager.setFilterCriteria(this.getFilteringCriteria());
  }

  /**
   * Finds the users with the given searchedWords.
   *
   * @returns void
   */
  protected applySearchBy(): void {
    this.searchedWords = this.searchByInput.nativeElement.value.trim().toLowerCase();
    this.pager.setFilterCriteria(this.getFilteringCriteria());
  }

  /**
   * Cancels the current fetch users subscription.
   *
   * @returns void
   */
  protected cancelFetchUserListener(): void {
    if (_isObject(this.fetchUsersListener)) {
      this.fetchUsersListener.unsubscribe();
    }
  }

  /**
   * Creates an user.
   *
   * @returns void
   */
  protected createUser(): void {
    this.userManager.manageUser(new User(), UserManagerActions.CREATE);
  }

  /**
   * Edits an user.
   *
   * @returns void
   */
  protected editUser(user: User) {
    this.userManager.manageUser(_cloneDeep(user), UserManagerActions.EDIT);
  }

  /**
   * Fetches the users.
   *
   * @returns void
   */
  protected fetchUsers(): void {
    this.cancelFetchUserListener();
    this.changeStatusToFetchingData();

    const options: any = {
      _size: this.appConfigProvider.get('ux.page.user.fetchSize')
    };

    this.fetchUsersListener = this.userService.filterUsers(options).subscribe(
      (response: StormServiceResponseCollection) => {
        this.pager.setRecords(response.collection);
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the users.', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Returns the filtering criteria for users.
   *
   * @returns CallableFunction
   */
  protected getFilteringCriteria(): CallableFunction {
    const criteria: CallableFunction = (user: User) => {
      const matchedRoleFilter: boolean =
        _isEmpty(this.selectedRole) ||
        (this.selectedRole === this.noRoles && user.roles.length === 0) ||
        user.roles.some((role: UserRole) => role.name === this.selectedRole);

      const matchedWordsFilter: boolean =
        this.searchedWords.length === 0 ||
        user.name.toLowerCase().includes(this.searchedWords) ||
        user.getFullName().toLowerCase().includes(this.searchedWords) ||
        (_isString(user.email) && user.email.toLowerCase().includes(this.searchedWords));

      return matchedWordsFilter && matchedRoleFilter;
    };

    return criteria;
  }

  /**
   * Returns the sorting criteria for users.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (elemA: User, elemB: User) => {
      const nameA: string = elemA.name.trim().toLowerCase();
      const nameB: string = elemB.name.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Returns the scrollHeight from AppConfigProvider.
   *
   * @returns string
   */
  protected getScrollHeight(): string {
    return this.appConfigProvider.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Loads the roles.
   *
   * @returns void
   */
  protected loadRoles(): void {
    this.rolesItems = new Array();

    this.userManager.fetchUserRoles().subscribe(
      (serviceResponseCollection: StormServiceResponseCollection) => {
        this.rolesItems.push(new SelectionItem(this.noRoles, this.noRoles));

        _sortBy(serviceResponseCollection.collection, 'name').forEach(
          (role: UserRole) => {
            this.rolesItems.push(new SelectionItem(role.name, role.name));
          }
        );
      }
    );
  }

  /**
   * Sets the given role as the selected one.
   *
   * @param selectedRole string
   * @returns void
   */
  protected setRole(selectedRole: string): void {
    this.applyFilterBy(selectedRole);
  }

  /**
   * Sets up the memory pager.
   *
   * @returns void
   */
  protected setupPager(): void {
    this.pager = new MemoryPager(
      this.appConfigProvider.get('ux.dataTables.pageSize'),
      this.getSortingCriteria()
    );
  }

  /**
   * Set the current user manager.
   *
   * @returns void
   */
  protected setupUserManager(): void {
    this.userManager.getManagedUser().subscribe(
      (managedUser: any) => {
        this.selectedUser = managedUser;
      }
    );
  }

  /**
   * Toggles the state for the given user.
   *
   * @param user User
   * @returns void
   */
  protected toggleUserState(user: User): void {
    this.userManager.manageUser(user, UserManagerActions.TOGGLE_STATE);
  }
}
