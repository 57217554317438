import { includes as _includes } from 'lodash';

import { ExportValueEnum } from '../export-value.enum';


export class ExportConfig {
  protected _isExpanded: boolean;
  protected _isExpansible: boolean;
  protected label: string;
  protected value: ExportValueEnum;

  constructor(value: ExportValueEnum) {
    switch (value) {
      case ExportValueEnum.DUBBING:
        this.label = 'Dubbing Cast & Crew';
        this._isExpansible = true;
        break;
      case ExportValueEnum.NONE:
        this.label = 'None';
        this._isExpansible = false;
        break;
      case ExportValueEnum.ORIGINAL:
        this.label = 'Original Cast & Crew';
        this._isExpansible = true;
        break;
      case ExportValueEnum.ORIGINAL_DUBBING:
        this.label = 'Original + Dubbing Cast & Crew';
        this._isExpansible = false;
        break;
      case ExportValueEnum.TRANSLATED:
        this.label = 'Translated Cast & Crew';
        this._isExpansible = true;
        break;
      case ExportValueEnum.TRANSLATED_DUBBING:
        this.label = 'Translated + Dubbing Cast & Crew';
        this._isExpansible = false;
        break;
      default:
        throw new Error(`Invalid value given: ${value}`);
    }

    this.value = value;
    this.resetIsExpanded();
  }

  /**
   * Returns a list with all options.
   *
   * @returns ExportConfig[]
   */
  static getList(): ExportConfig[] {
    const list: ExportConfig[] = [
      new ExportConfig(ExportValueEnum.NONE),
      new ExportConfig(ExportValueEnum.ORIGINAL),
      new ExportConfig(ExportValueEnum.TRANSLATED),
      new ExportConfig(ExportValueEnum.DUBBING),
      new ExportConfig(ExportValueEnum.ORIGINAL_DUBBING),
      new ExportConfig(ExportValueEnum.TRANSLATED_DUBBING)
    ];

    return list;
  }

  /**
   * Returns the label.
   *
   * @return string
   */
  getLabel(): string {
    return this.label;
  }

  /**
   * Returns the value.
   *
   * @returns any
   */
  getValue(): any {
    return this.value;
  }

  /**
   * Indicates if it is expanded.
   *
   * @returns boolean
   */
  isExpanded(): boolean {
    return this._isExpanded;
  }

  /**
   * Indicates if it is expansible.
   *
   * @returns boolean
   */
  isExpansible(): boolean {
    return this._isExpansible;
  }

  /**
   * Reset the is-expanded status.
   *
   * @returns void
   */
  resetIsExpanded(): void {
    this._isExpanded = false;
  }

  /**
   * Toggles the is-expanded status, if the Credit config is expansible.
   *
   * @returns void
   */
  toggleIsExpanded(): void {
    if (this.isExpansible()) {
      this._isExpanded = !this._isExpanded;
    }
  }
}
