import { Input, Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Language } from '@bolt/ui-shared/master-data';

import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { BoltCharacterMetadataFormComponent } from '../bolt-character-metadata-form/bolt-character-metadata-form.component';
import { LayoutService } from 'app/shared/services/form/layout/layout.service';
import { LocalizedDataForm } from '../../models/form/localized-data/localized-data-form.model';


@Component({
  selector: 'bolt-character-localized-metadata-form',
  template: require('./bolt-character-localized-metadata-form.html'),
  styles: [require('./bolt-character-localized-metadata-form.scss')]
})
export class BoltCharacterLocalizedMetadataFormComponent extends BoltCharacterMetadataFormComponent {
  @Input() form: LocalizedDataForm;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formConfig: ConfigService,
    protected formLayout: LayoutService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super(formConfig, listLayoutProvider);
  }

  /**
   * Get the current language name.
   *
   * @returns Language
   */
  protected getLanguage(): Language {
    const language: Language = this.languages.find(
      (value: SelectionItem) => value.value === this.form.getLanguageField().value
    ).source;

    return language;
  }

  /**
   * Updates the dependencies for use-domestic field in form.
   *
   * @returns void
   */
  protected updateUseDomesticDependencies(): void {
    const field: AbstractControl = this.form.getUseDomesticField();

    if (field.value) {
      this.form.getNameField().disable();
      this.form.getPhoneticField().disable();
    } else {
      this.form.getNameField().enable();
      this.form.getPhoneticField().enable();
    }
  }
}
