import { Component, Input } from '@angular/core';
import { DataStatusEnum } from '../../models/data-status.enum';


@Component({
  selector: 'storm-component-status',
  template: require('./storm-component-status.html'),
  styles: [require('./storm-component-status.scss')]
})
export class StormComponentStatusComponent {
  @Input() status: DataStatusEnum;

  protected componentStatuses: typeof DataStatusEnum = DataStatusEnum;

  constructor() { }

  /**
   * Indicates if the component should be hidden
   *
   * @returns boolean
   */
  protected shouldHide(): boolean {
    const shouldIt: boolean = (
      (this.status === this.componentStatuses.dataFound) ||
      (this.status === this.componentStatuses.idle)
    );

    return shouldIt;
  }
}
