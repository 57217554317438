import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BoltCommonModule } from '../common/common.module';

import { BoltCatActionsComponent } from './components/bolt-cat-actions/bolt-cat-actions.component';
import { BoltCatComponent } from './components/bolt-cat/bolt-cat.component';
import { BoltCatFiltersComponent } from './components/bolt-cat-filters/bolt-cat-filters.component';
import { BoltCatItemAkaComponent } from './components/bolt-cat-item-aka/bolt-cat-item-aka.component';
import { BoltCatItemAssociationsComponent } from './components/bolt-cat-item-associations/bolt-cat-item-associations.component';
import { BoltCatItemDetailsComponent } from './components/bolt-cat-item-details/bolt-cat-item-details.component';
import { BoltCatItemHandleLocalizedMetadataComponent } from './components/bolt-cat-item-handle-localized-metadata/bolt-cat-item-handle-localized-metadata.component';
import { BoltCatItemHandleOriginalMetadataComponent } from './components/bolt-cat-item-handle-original-metadata/bolt-cat-item-handle-original-metadata.component';
import { BoltCatItemListComponent } from './components/bolt-cat-item-list/bolt-cat-item-list.component';
import { BoltCatItemLocalizedDataComponent } from './components/bolt-cat-item-localized-data/bolt-cat-item-localized-data.component';
import { BoltCatItemLocalizedDetailsComponent } from './components/bolt-cat-item-localized-details/bolt-cat-item-localized-details.component';
import { BoltCatItemLocalizedListComponent } from './components/bolt-cat-item-localized-list/bolt-cat-item-localized-list.component';
import { BoltCatItemOriginalDataComponent } from './components/bolt-cat-item-original-data/bolt-cat-item-original-data.component';
import { BulkActionsHandlerService } from './services/unit/bulk-actions-handler/bulk-actions-handler.service';
import { catModuleRouting } from './cat.routing';
import { CharacterService } from '../character/services/character.service';
import { FormFactoryService } from './services/form-factory/form-factory.service';
import { ItemService } from './services/item/item.service';
import { ManagerService } from './services/unit/manager/manager.service';
import { MetadataManagerService } from './services/item/metadata-manager/metadata-manager.service';
import { RoleCapabilitiesHandler } from './services/role-capabilities-handler/role-capabilities-handler';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    FormsModule,
    catModuleRouting
  ],
  declarations: [
    BoltCatActionsComponent,
    BoltCatComponent,
    BoltCatFiltersComponent,
    BoltCatItemAkaComponent,
    BoltCatItemAssociationsComponent,
    BoltCatItemDetailsComponent,
    BoltCatItemHandleLocalizedMetadataComponent,
    BoltCatItemHandleOriginalMetadataComponent,
    BoltCatItemListComponent,
    BoltCatItemLocalizedDataComponent,
    BoltCatItemLocalizedDetailsComponent,
    BoltCatItemLocalizedListComponent,
    BoltCatItemOriginalDataComponent
  ],
  exports: [
    BoltCatComponent,
    BoltCatItemAkaComponent,
    BoltCatItemAssociationsComponent
  ],
  providers: [
    BulkActionsHandlerService,
    CharacterService,
    FormFactoryService,
    ItemService,
    ManagerService,
    MetadataManagerService,
    RoleCapabilitiesHandler
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CatModule { }
