import { Item } from '../item/item.model';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { Title } from 'app/modules/title/models/title.model';
import { Type } from './type/type.model';


export class Product {
  protected _id: number;
  protected _itemsLockingStatus: LockingStatus;
  protected _lockingStatus: LockingStatus;
  protected _name: string;
  protected _type: Type;

  constructor(data: any) {
    this.initialize(data);
  }

  /**
   * Returns a new product using the given subproduct.
   *
   * @param subproduct Item
   * @returns Product
   */
  static newFromSubproduct(subproduct: Item): Product {
    const product: Product = new Product({
      id: subproduct.id,
      locked: subproduct.lockingStatus.locked,
      lockedBy: subproduct.lockingStatus.lockedBy,
      lockedDate: subproduct.lockingStatus.lockedDate,
      itemsLocked: subproduct.itemsLockingStatus.locked,
      itemsLockedBy: subproduct.itemsLockingStatus.lockedBy,
      itemsLockedDate: subproduct.itemsLockingStatus.lockedDate,
      name: subproduct.name,
      productType: subproduct.type.value
    });

    return product;
  }

  /**
   * Returns a new product using the given title.
   *
   * @param title Title
   * @returns Product
   */
  static newFromTitle(title: Title): Product {
    const product: Product = new Product({
      id: title.id,
      locked: title.locked,
      lockedBy: title.lockedBy,
      lockedDate: title.lockedDate,
      itemsLocked: title.itemsLockingStatus.locked,
      itemsLockedBy: title.itemsLockingStatus.lockedBy,
      itemsLockedDate: title.itemsLockingStatus.lockedDate,
      name: title.fullTitle,
      productType: title.type
    });

    return product;
  }

  get id(): number {
    return this._id;
  }

  get itemsLockingStatus(): LockingStatus {
    return this._itemsLockingStatus;
  }

  get lockingStatus(): LockingStatus {
    return this._lockingStatus;
  }

  get name(): string {
    return this._name;
  }

  get type(): Type {
    return this._type;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this._id = data.id;
    this._itemsLockingStatus = LockingStatus.newFromItemsLockStatus(data);
    this._lockingStatus = new LockingStatus(data);
    this._name = data.name;
    this._type = new Type(data.productType);
  }
}
