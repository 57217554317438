import { AfterViewInit, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Account, Country, Language } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { isArray as _isArray, isNull as _isNull, isObject as _isObject } from 'lodash';

import { AttributeEnum as LockAttributeEnum } from '../models/entity/lock/attribute.enum';
import { BoltProjectDataFilterComponent } from './bolt-project-data-filter/bolt-project-data-filter.component';
import { Entity } from '../models/entity/entity.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { ManagerService } from '../services/project/manager/manager.service';
import { ModeEnum as FilterModeEnum } from './bolt-project-data-filter/mode.enum';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { NumberHelper } from 'app/modules/common/helpers/number/number.helper';
import { Project } from '../models/project/project.model';
import { ProjectService } from '../services/project/project.service';
import { ScrollingHandlerService } from '../services/scrolling-handler/scrolling-handler.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { UrlHandlerService } from '../services/url-handler/url-handler.service';


export abstract class BoltProjectAbstractCommonComponent extends StormComponent implements AfterViewInit, OnInit, OnDestroy {
  protected readonly filterMode: typeof FilterModeEnum = FilterModeEnum;
  protected readonly lockAttribute: typeof LockAttributeEnum = LockAttributeEnum;

  // Current Project related.
  protected currentAccount: Account;
  protected currentLanguages: Language[];
  protected currentProject: Project;
  protected currentProjectChangeListener: Subscription;
  protected currentTerritories: Country[];

  // Filters related.
  @ViewChildren('filter') protected filterElementQuery: QueryList<BoltProjectDataFilterComponent>;
  protected filterElementList: BoltProjectDataFilterComponent[];
  protected entityTypeFilter: SelectionItem[];
  protected shouldResetFilters: boolean;

  // Entities collections related.
  protected allEntitiesCounter: number;
  protected fetchingEntitiesListener: Subscription;
  protected firstEntities: Map<string, Entity>;
  protected firstEntitiesOrder: Array<[string, string]>;
  protected isAscendingOrder: boolean;
  protected secondEntities: Map<string, any>;
  protected secondEntitiesReverse: Map<string, string>;

  constructor(
    protected layoutHandler: LayoutHandlerService,
    protected notificationService: NotificationService,
    protected projectManager: ManagerService,
    protected projectService: ProjectService,
    protected scrollingHandler: ScrollingHandlerService,
    protected urlHandler: UrlHandlerService
  ) {
    super();

    this.currentProject = undefined;
    this.entityTypeFilter = [];
    this.firstEntities = new Map();
    this.secondEntities = new Map();
    this.secondEntitiesReverse = new Map();

    this.changeStatusToIdle();
    this.resetEntities();
  }

  ngAfterViewInit() {
    this.filterElementQuery.changes.pipe(delay(0)).subscribe(
      (filterElementQuery: QueryList<BoltProjectDataFilterComponent>) => {
        this.filterElementList = filterElementQuery.toArray();
      }
    );
  }

  ngOnDestroy() {
    this.scrollingHandler.stop();
    this.unsubscribeCurrentProjectChange();
    this.unsubscribeFetchingEntities();
  }

  ngOnInit() {
    this.listenCurrentProjectChange();
  }

  /**
   * Applies the stored filters over entities.
   *
   * @returns void
   */
  protected applyFilters(): void {
    const firstEntitiesOrder: Array<[string, string]> = [];

    this.firstEntities.forEach(
      (entity: Entity) => {
        if (this.doesEntityPassFilters(entity)) {
          firstEntitiesOrder.push(this.retrieveOrderEntryFor(entity));
        }
      }
    );

    this.firstEntitiesOrder = firstEntitiesOrder;

    this.refreshFirstEntitiesOrder();
  }

  /**
   * Decrease the entities count by the given quantity ensuring non negative values.
   *
   * @returns void
   */
  protected decreaseAllEntitiesCounter(quantity: number = 1): void {
    if ((this.allEntitiesCounter - quantity) >= 0) {
      this.allEntitiesCounter -= quantity;
    } else {
      this.resetAllEntitiesCounter();
    }
  }

  /**
   * Discovers if it should reset the filters.
   *
   * @returns void
   */
  protected discoverIfShouldResetFilters(): void {
    let shouldIt: boolean = false;

    if (_isArray(this.filterElementList)) {
      let x: number = 0;

      while (!shouldIt && (x < this.filterElementList.length)) {
        shouldIt = this.filterElementList[x].wasAltered();
        x++;
      }
    }

    this.shouldResetFilters = shouldIt;
  }

  /**
   * Indicates if the given entity pass the filters.
   *
   * @param entity Entity
   * @returns boolean
   */
  protected doesEntityPassFilters(entity: Entity): boolean {
    // We only check the title filter here, due to it is in both view: details and handler.
    const passed: boolean =
      entity.type.isEpisode() ||
      this.entityTypeFilter.some((item: SelectionItem) => item.source.isEqualsTo(entity.type));

    return passed;
  }

  /**
   * Actions to do after fetching entities.
   *
   * @returns void
   */
  protected doAfterFetchEntities(): void {
    // Subclasses can overwrite this.
  }

  /**
   * Actions to do after current project change.
   *
   * @returns void
   */
  protected doAfterCurrentProjectChange(): void {
    // Subclasses can overwrite this.
  }

  /**
   * Actions to do before fetching entities.
   *
   * @returns void
   */
  protected doBeforeFetchEntities(): void {
    // Subclasses can overwrite this.
  }

  /**
   * Hack for ensuring to start the scrolling service.
   *
   * @returns void
   */
  protected ensureStartScrolling(): void {
    setTimeout(
      () => {
        this.scrollingHandler.start();
      }
    );
  }


  /**
   * Fetches the project entities.
   *
   * @returns void
   */
  protected fetchEntities(): void {
    if (this.hasCurrentProject()) {
      this.changeStatusToFetchingData();
      this.unsubscribeFetchingEntities();
      this.resetEntities();
      this.doBeforeFetchEntities();

      this.fetchingEntitiesListener = this.projectService.fetchEntities(
        this.currentProject.id,
        (entities: Entity[]) => {
          this.processEntities(entities);
          this.changeStatusToDataFound();
          this.doAfterFetchEntities();
        },
        (error: ErrorHelper) => {
          this.changeStatusToError();
          this.notificationService.handleError('Failed retrieving project entities.', error, notificationsContainer.projectDashboard.key);
        }
      );
    }
  }

  /**
   * Filters by entity types using the given selection.
   *
   * @param selection SelectionItem[]
   * @returns void
   */
  protected filterByEntityTypes(selection: SelectionItem[]): void {
    this.entityTypeFilter = selection;

    this.applyFilters();
    this.discoverIfShouldResetFilters();
  }

  /**
   * Returns the sorting criteria.
   *
   * @returns any
   */
  protected getSortingCriteria(): any {
    const criteria: CallableFunction = (entryA: Entity | [string, string], entryB: Entity | [string, string]) => {
      const nameA: string = (_isArray(entryA) ? entryA[0] : entryA.name).trim().toLowerCase();
      const nameB: string = (_isArray(entryB) ? entryB[0] : entryB.name).trim().toLowerCase();
      const sortingModifier: number = (this.isAscendingOrder ? 1 : -1);

      if (nameA < nameB) {
        return (-1 * sortingModifier);
      } else if (nameA > nameB) {
        return (1 * sortingModifier);
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Indicates if it has to block the entity type filter.
   *
   * @returns boolean
   */
  protected hasBlockEntityTypeFilter(): boolean {
    return !this.hasFirstEntities();
  }

  /**
   * Indicates if it has a current project.
   *
   * @returns boolean
   */
  protected hasCurrentProject(): boolean {
    return _isObject(this.currentProject);
  }

  /**
   * Indicates if it has first entities.
   *
   * @returns boolean
   */
  protected hasFirstEntities(): boolean {
    const hasIt: boolean = (this.firstEntities.size > 0);
    return hasIt;
  }

  /**
   * Indicates if it has second entities for the given parent map key.
   *
   * @param parentMapKey string
   * @returns boolean
   */
  protected hasSecondEntitiesFor(parentMapKey: string): boolean {
    const hasIt: boolean = (
      this.secondEntities.has(parentMapKey) &&
      (this.secondEntities.get(parentMapKey).data.size > 0)
    );

    return hasIt;
  }

  /**
   * Increases the entities count by 1.
   *
   * @returns void
   */
  protected increaseAllEntitiesCounter(): void {
    this.allEntitiesCounter += 1;
  }

  /**
   * Indicates if the given value is an odd number.
   *
   * @param value number
   * @returns boolean
   */
  protected isOddNumber(value: number): boolean {
    return NumberHelper.isOdd(value);
  }

  /**
   * Subscribes any change in the ManagerService for the current project.
   *
   * @returns void
   */
  protected listenCurrentProjectChange(): void {
    this.currentProjectChangeListener = this.projectManager.currentProjectListener.subscribe(
      () => {
        this.scrollingHandler.stop();

        this.currentProject = this.projectManager.currentProject;

        if (this.hasCurrentProject()) {
          this.mapAndSetCurrentAccount(this.currentProject.accountCode);
          this.mapAndSetCurrentLanguages(this.currentProject.languagesCodes);
          this.mapAndSetCurrentTerritories(this.currentProject.territoriesCodes);
        } else if (this.projectManager.hasDefaults()) {
          const defaults: any = this.projectManager.getDefaults();

          this.mapAndSetCurrentAccount(defaults.accountCode);
          this.mapAndSetCurrentLanguages(defaults.languagesCodes);
          this.mapAndSetCurrentTerritories(defaults.territoriesCodes);
        }

        this.doAfterCurrentProjectChange();
      }
    );
  }

  /**
   * Set the current account by mapping the given code.
   * If the code is `null` or is for `All` account, the mapped value is `undefined`.
   *
   * @param code string
   * @returns void
   */
  protected mapAndSetCurrentAccount(code: string): void {
    this.currentAccount = _isNull(code) || Account.isAll(code) ? undefined : this.projectManager.getAccountByCode(code);
  }

  /**
   * Set the current languages by mapping the given codes.
   *
   * @param codes string[]
   * @returns void
   */
  protected mapAndSetCurrentLanguages(codes: string[]): void {
    this.currentLanguages = codes.map(
      (code: string) => this.projectManager.getLanguageByCode(code)
    ).sort(
      (languageA: Language, languageB: Language) => {
        if (languageA.isEnglish()) {
          return -1;
        } else if (languageB.isEnglish()) {
          return 1;
        } else {
          const nameA: string = languageA.name.toLowerCase();
          const nameB: string = languageB.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    );
  }

  /**
   * Set the current territories by mapping the given codes.
   *
   * @param codes string[]
   * @returns void
   */
  protected mapAndSetCurrentTerritories(codes: string[]): void {
    this.currentTerritories = codes.map(
      (code: string) => this.projectManager.getTerritoryByCode(code)
    ).sort(
      (territoryA: Country, territoryB: Country) => {
        if (territoryA.isUnitedStates()) {
          return -1;
        } else if (territoryB.isUnitedStates()) {
          return 1;
        } else {
          const nameA: string = territoryA.name.toLowerCase();
          const nameB: string = territoryB.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    );
  }

  /**
   * Processes the given entities.
   *
   * @param entities Entity[]
   * @returns void
   */
  protected processEntities(entities: Entity[]): void {
    const secondEntities: Entity[] = [];

    this.resetAllEntitiesCounter();

    entities.forEach(
      (entity: Entity) => {
        if (entity.hasParent()) {
          secondEntities.push(entity);
        } else {
          this.storeFirstEntity(entity);
        }
      }
    );

    this.storeSecondEntities(secondEntities);
    this.applyFilters();
  }

  /**
   * Refreshes the first entities order.
   *
   * @returns void
   */
  protected refreshFirstEntitiesOrder(): void {
    this.firstEntitiesOrder.sort(this.getSortingCriteria());
  }

  /**
   * Reloads all projects.
   *
   * @returns void
   */
  protected reload(): void {
    this.layoutHandler.changeToFetching();
  }

  /**
   * Reset the entities counter.
   *
   * @returns void
   */
  protected resetAllEntitiesCounter(): void {
    this.allEntitiesCounter = 0;
  }

  /**
   * Reset all entities.
   *
   * @returns void
   */
  protected resetEntities(): void {
    this.firstEntitiesOrder = [];
    this.isAscendingOrder = true;

    this.firstEntities.clear();
    this.secondEntities.clear();
    this.secondEntitiesReverse.clear();
    this.resetAllEntitiesCounter();
    this.resetFilters();
  }

  /**
   * Reset the filters.
   *
   * @returns void
   */
  protected resetFilters(): void {
    if (_isArray(this.filterElementList)) {
      this.filterElementList.forEach(
        (filterElement: BoltProjectDataFilterComponent) => {
          filterElement.reset();
        }
      );
    }

    this.shouldResetFilters = false;
  }

  /**
   * Retrieves an order entry for the given first entity.
   *
   * @param firstEntity Entity
   * @returns [string, string]
   */
  protected retrieveOrderEntryFor(firstEntity: Entity): [string, string] {
    const entry: [string, string] = [ firstEntity.name, firstEntity.mapKey ];
    return entry;
  }

  /**
   * Stores the given entity as a first one.
   *
   * @param entity Entity
   * @returns void
   */
  protected storeFirstEntity(entity: Entity): void {
    this.firstEntities.set(entity.mapKey, entity);
    this.increaseAllEntitiesCounter();
  }

  /**
   * Stores the given entities as second ones.
   *
   * @param entities Entity[]
   * @returns void
   */
  protected abstract storeSecondEntities(entities: Entity[]): void;

  /**
   * Toggles the sorting direction.
   *
   * @returns void
   */
  protected toggleSortingDirection(): void {
    if (this.isDataFound() && this.hasFirstEntities()) {
      this.changeStatusToFetchingData();

      this.isAscendingOrder = !this.isAscendingOrder;

      // Needed hack when there are a lot of entities.
      setTimeout(
        () => {
          this.refreshFirstEntitiesOrder();
          this.changeStatusToDataFound();
        }
      );
    }
  }

  /**
   * Unsubscribes from current project, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeCurrentProjectChange(): void {
    if (_isObject(this.currentProjectChangeListener)) {
      this.currentProjectChangeListener.unsubscribe();
    }
  }

  /**
   * Unsubscribes from fetching entities, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeFetchingEntities(): void {
    if (_isObject(this.fetchingEntitiesListener)) {
      this.fetchingEntitiesListener.unsubscribe();
    }
  }
}
