import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject } from 'lodash';
import * as fileSaver from 'file-saver';

import { CodeEnum as ExportErrorCode } from '../../models/export-error/code.enum';
import { Details as ExportJobDetails } from '../../models/export-job/details/details.model';
import { ExportCandidate } from '../../models/export-candidate/export-candidate.model';
import { ExportConfig as CreditsExportConfig } from '../../models/credits/export-config/export-config.model';
import { ExportCounters } from '../../models/export-counters/export-counters.model';
import { ExportFormatEnum } from '../../models/export-format.enum';
import { ExportService } from '../../services/export.service';
import { ExportValueEnum as CreditsExportValue } from '../../models/credits/export-value.enum';
import { GroupCriteriaEnum } from '../../models/group-criteria.enum';
import { PullingActionHelper } from 'app/shared/helpers/pulling-action/pulling-action.helper';
import { ResultReport as ExportResultReport } from '../../models/export-job/result-report/result-report.model';
import { Status as ExportJobStatus } from '../../models/export-job/status/status.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { ExportLocaleType } from '../../models/export-locale-type.enum';


@Component({
  selector: 'bolt-metadata-export-popup',
  template: require('./bolt-metadata-export-popup.html'),
  styles: [require('./bolt-metadata-export-popup.scss')]
})
export class BoltMetadataExportPopupComponent extends StormComponent implements OnChanges {
  @Input() autoExport: boolean;
  @Input() candidates: ExportCandidate[];
  @Input() creditsExport: CreditsExportValue;
  @Input() exportFormat: ExportFormatEnum;
  @Input() groupCriteria: GroupCriteriaEnum | undefined;
  @Input() includeEidrLevel2: boolean;
  @Input() open: boolean;
  @Input() overrideLocalEpisodeRunningOrder: boolean;

  @Output('closed') closeEvent: EventEmitter<void>;

  readonly errorCodes: typeof ExportErrorCode = ExportErrorCode;
  readonly exportFormats: typeof ExportFormatEnum = ExportFormatEnum;

  creditsExportOptions: CreditsExportConfig[];
  exportCounters: ExportCounters;
  exportResult: ExportResultReport;

  protected exportJobDetails: ExportJobDetails;
  protected pullingAction: PullingActionHelper;
  protected pullingAttempts: number;

  constructor(
    protected exportService: ExportService,
    protected notificationService: NotificationService
  ) {
    super();

    this.closeEvent = new EventEmitter();
    this.creditsExportOptions = CreditsExportConfig.getList();
    this.exportCounters = new ExportCounters();
    this.pullingAction = new PullingActionHelper();

    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.candidates)) {
      this.reset();
    }

    if (_isObject(changes.open)) {
      if (changes.open.currentValue) {
        this.exportCounters.read(this.candidates);

        if (this.autoExport) {
          this.startExport();
        }
      } else {
        this.reset();
      }
    }
  }

  /**
   * Downloads the file for the given parameters.
   *
   * @param jobDetails ExportJobDetails
   * @param fileFormat ExportFormatEnum
   * @returns void
   */
  downloadFile(jobId: number, fileName: string, fileFormat: ExportFormatEnum): void {
    this.exportService.downloadFile(jobId, fileFormat).subscribe(
      (data: Blob) => {
        fileSaver.saveAs(data, fileName);
        this.notificationService.handleSuccess('Download File', `${fileName} was downloaded successfully.`);
      },
      (error: HttpError) => {
        this.notificationService.handleError('Failed trying to download the file.', error);
      }
    );
  }

  /**
   * Indicates if it has to display the view of exporting records.
   *
   * @returns boolean
   */
  hasDisplayExportingView(): boolean {
    const hasIt: boolean = this.autoExport || this.isFetchingData() || this.isDataFound() || this.isError();
    return hasIt;
  }

  /**
   * Notifies the modal was closed.
   *
   * @returns void
   */
  notifyClose(): void {
    this.closeEvent.emit();
  }

  /**
   * Starts the export.
   *
   * @returns void
   */
  startExport(): void {
    this.changeStatusToFetchingData();

    this.exportResult = undefined;

    this.exportService.startExport(
      this.candidates,
      this.exportFormat,
      this.groupCriteria,
      this.creditsExport,
      this.includeEidrLevel2,
      this.overrideLocalEpisodeRunningOrder,
      ExportLocaleType.regular
    ).subscribe(
      (details: ExportJobDetails) => {
        this.exportJobDetails = details;

        this.pullingAction.set(
          () => {
            this.checkExportStatus();
          },
          this.pullingAttempts
        );

        this.pullingAction.execute();
      },
      (error: HttpError) => {
        this.failExport(error);
      }
    );
  }

  /**
   * Checks the export status.
   *
   * @returns void
   */
  protected checkExportStatus(): void {
    this.exportService.checkExportStatus(this.exportJobDetails.id).subscribe(
      (status: ExportJobStatus) => {
        this.processExportStatus(status);
      },
      (error: HttpError) => {
        this.failExport(error);
      }
    );
  }

  /**
   * Fails the export.
   *
   * @param error HttpError
   * @returns void
   */
  protected failExport(error: HttpError): void {
    this.pullingAction.reset();
    this.changeStatusToError();

    this.notificationService.handleError('Failed trying to export metadata', error);
  }

  /**
   * Processes the export status.
   *
   * @param newStatus ExportJobStatus
   * @returns void
   */
  protected processExportStatus(newStatus: ExportJobStatus): void {
    this.exportJobDetails.updateJobStatus(newStatus);

    if (newStatus.isGenerated() || newStatus.isCompleted()) {
      this.pullingAction.reset();

      this.exportService.fetchExportResultReport(this.exportJobDetails.id).subscribe(
        (result: ExportResultReport) => {
          this.exportResult = result;
          this.changeStatusToDataFound();
        },
        (error: HttpError) => {
          this.failExport(error);
        }
      );

    } else if (newStatus.isError()) {
      this.pullingAction.reset();
      this.changeStatusToError();
    }

    try {
      if (!this.pullingAction.wasReset()) {
        this.pullingAction.execute();
      }
    } catch (error) {
      this.failExport(error);
    }
  }

  /**
   * Resets it.
   *
   * @returns void
   */
  protected reset(): void {
    if (!this.autoExport) {
      this.creditsExport = CreditsExportValue.NONE;
      this.includeEidrLevel2 = false;
      this.overrideLocalEpisodeRunningOrder = false;
    }

    this.pullingAction.reset();
    this.changeStatusToIdle();
  }
}
