import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';


@Injectable()
export class LayoutService {
  constructor() { }

  /**
   * Indicates if the given field is valid.
   *
   * @param field FormControl
   * @returns boolean
   */
  isFieldValid(field: FormControl): boolean {
    const isIt: boolean = (!field.dirty || field.valid);
    return isIt;
  }
}
