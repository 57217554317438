import { Component, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';

import { BoltMergeListComponent } from '../bolt-merge-list/bolt-merge-list.component';
import { Character } from 'app/modules/character/models/character.model';
import { CharacterService } from 'app/modules/character/services/character.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';

@Component({
  selector: 'bolt-merge-character-list',
  template: require('./bolt-merge-character-list.html'),
  styles: [require('./bolt-merge-character-list.scss')]
})
export class BoltMergeCharacterListComponent extends BoltMergeListComponent {
  @Output('selected') selectEvent: EventEmitter<Character>;

  protected mergeCandidate: Character;

  constructor(
    protected characterService: CharacterService,
    protected notificationService: NotificationService
  ) {
    super(notificationService);
  }

  get items(): Character[] {
    return this.searchResponse.data;
  }

  protected doSearch(): void {
    this.fetchItemSubscription = this.characterService.search(
      this.searchCriteria,
      (response: SearchResponse) => {
        this.doDefaultOnSuccess(response);
      },
      (error: ErrorHelper) => {
        this.doDefaultOnError(error, 'characters');
      }
    );
  }
}
