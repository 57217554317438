import { Component, Input } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { SearchCriteria } from '@bolt/ui-shared/searching';
import { Observable } from 'rxjs';
import { difference as _difference, isArray as _isArray } from 'lodash';

import { BoltWizardValidatingStepItemComponent } from '../bolt-wizard-validating-step-item/bolt-wizard-validating-step-item.component';
import { TalentService } from 'app/modules/talent/services/talent.service';
import { WizardService } from '../../services/wizard/wizard.service';


@Component({
  selector: 'bolt-wizard-validating-step-talent-item',
  template: require('./bolt-wizard-validating-step-talent-item.html'),
  styles: [require('./bolt-wizard-validating-step-talent-item.scss')]
})
export class BoltWizardValidatingStepTalentItemComponent extends BoltWizardValidatingStepItemComponent {
  @Input() longer: boolean;

  protected searchMethod: (criteria: SearchCriteria) => Observable<any>;

  constructor(
    protected appConfigProvider: AppConfigProvider,
    protected wizardService: WizardService,
    protected talentService: TalentService
  ) {
    super(appConfigProvider, wizardService);
    this.longer = false;
    this.retry();
  }

  protected checkCompleteMatch(): void {
    const talentName: string[] = this.name.toLowerCase().split(' ');

    const completeMatched: SelectionItem[] = this.matches.filter(
      (match: SelectionItem) => {
        const matchName: string[] = match.source.fullName.toLowerCase().split(' ').filter(
          (value: string) => value.length > 0
        );

        const isCompleteMatch: boolean =
          talentName.length === matchName.length &&
          _difference(matchName, talentName).length === 0;

        return isCompleteMatch;
      }
    );

    if (_isArray(completeMatched) && completeMatched.length === 1) {
      this.completeMatch = true;
      this.createNew = !this.completeMatch;

      setTimeout(
        () => {
          this.wizardService.setTalent(completeMatched[0].source, this.index);
        },
        0
      );
    }
  }

  protected checkMatches(options: SelectionItem[]): void {
    if (options.length > 0) {
      this.matches = options;
      this.checkCompleteMatch();
    } else {
      this.createNew = true;
      this.wizardService.setTalentAsReady(this.index);
    }
  }

  protected onCreateNewChange(): void {
    if (this.createNew) {
      this.currentSelection = undefined;
      this.wizardService.setTalent(undefined, this.index);
      this.wizardService.setTalentAsReady(this.index);
    } else {
      this.wizardService.setTalentAsNotReady(this.index);
    }
  }

  protected retry(): void {
    this.searchMethod = this.talentService.search.bind(this.talentService);
  }

  /**
   * Sets the talent for the given id.
   *
   * @param id number
   * @returns void
   */
  protected setTalent(id: number): void {
    const talentItem: SelectionItem = this.matches.find(
      (elem: SelectionItem) => elem.value === id
    );

    this.wizardService.setTalent(talentItem?.source, this.index);
  }
}
