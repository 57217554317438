import { Input, OnDestroy, OnInit } from '@angular/core';
import { ObservableExecutor } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';

import { CastCrewSearchCriteria } from 'app/shared/models/search-criteria/character/cast-crew-search-criteria.model';
import { SearchType } from 'app/shared/models/search-criteria/search-type/search-type.model';
import { SearchTypeEnum } from 'app/shared/models/search-criteria/search-type/search-type.enum';
import { WizardService } from '../../services/wizard/wizard.service';
import { StatusEnum } from 'app/modules/common/directives/bolt-dropdown-scroll-loading/status.enum';

export abstract class BoltWizardValidatingStepItemComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() index: number;
  @Input() original: boolean;
  @Input() observableExecutor: ObservableExecutor;

  protected matches: SelectionItem[];
  protected completeMatch: boolean;
  protected createNew: boolean;
  protected currentSelection: number;
  protected searchCriteria: CastCrewSearchCriteria;
  protected statusDropdown: StatusEnum;

  constructor(
    protected appConfigProvider: AppConfigProvider,
    protected wizardService: WizardService
  ) {
    this.completeMatch = false;
    this.createNew = false;
  }

  ngOnInit() {
    this.setupSearchCriteria();
  }

  ngOnDestroy() {
    this.matches = [];
  }

  /**
   * Changes the dropdown status.
   *
   * @param status StatusEnum
   * @returns void
   */
  protected changeStatus(status: StatusEnum): void {
    this.statusDropdown = status;
  }

  /**
   * Indicates if the dropdown is loading options.
   *
   * @returns boolean
   */
  protected isLoading(): boolean {
    const isIt: boolean = (this.statusDropdown === StatusEnum.loading);
    return isIt;
  }

  /**
   * Indicates if the dropdown gets an error retrieving options.
   *
   * @returns boolean
   */
  protected isError(): boolean {
    const isIt: boolean = (this.statusDropdown === StatusEnum.error);
    return isIt;
  }

  /**
   * Indicates if the dropdown has not options to retrieve.
   *
   * @returns boolean
   */
  protected isNoMore(): boolean {
    const isIt: boolean = (this.statusDropdown === StatusEnum.noMore);
    return isIt;
  }

  /**
   * Checks if there is a complete match.
   *
   * @returns void
   */
  protected abstract checkCompleteMatch(): void;

  /**
   * Checks the matches options.
   *
   * @param options SelectionItem[]
   * @returns void
   */
  protected abstract checkMatches(options: SelectionItem[]): void;

  /**
   * Checks if there are matches.
   *
   * @returns boolean
   */
  protected hasMatches(): boolean {
    return !_isUndefined(this.matches) && this.matches.length > 0;
  }

  /**
   * Indicates if it has to display the "retry" icon.
   *
   * @returns boolean
   */
   protected hasRetry(): boolean {
    return !this.hasMatches() && this.isError();
  }

  /**
   * Indicates if it has to display the "Searching" label.
   *
   * @returns boolean
   */
  protected hasDisplaySearchingLabel(): boolean {
    return !this.hasMatches() && this.isLoading();
  }

  /**
   * Indicates if it has to display the "minus" icon.
   *
   * @returns boolean
   */
  protected hasDisplayMinusIcon(): boolean {
    return !this.hasMatches() && this.isNoMore();
  }

  /**
   * Indicates if it has to display the dropdown.
   *
   * @returns boolean
   */
  protected hasDisplayDropdown(): boolean {
    const itHas =
      !this.hasRetry() &&
      !this.hasDisplaySearchingLabel() &&
      !this.hasDisplayMinusIcon() &&
      !this.hasCompleteMatch();

    return itHas;
  }

  /**
   * Indicates if it has a complete match:
   *
   * @returns boolean
   */
  protected hasCompleteMatch(): boolean {
    return this.completeMatch;
  }

  /**
   * Indicates if it has to disable the checkbox.
   *
   * @returns boolean
   */
  protected hasDisableCheckbox(): boolean {
    const itHas: boolean = this.hasDisplayMinusIcon() || this.isReadonly() || this.hasCompleteMatch() || this.hasDisplaySearchingLabel();
    return itHas;
  }

  /**
   * Indicates if it has to disable the dropdown.
   *
   * @returns boolean
   */
  protected hasDisableDropdown(): boolean {
    return this.createNew || this.isReadonly();
  }

  /**
   * Indicates if it is the first item.
   *
   * @returns boolean
   */
  protected isFirst(): boolean {
    return this.index === 0;
  }

  /**
   * Indicates if it is in read only mode.
   *
   * @returns boolean
   */
  protected isReadonly(): boolean {
    return this.wizardService.getCurrentStep().isCreating();
  }

  /**
   * Does the necessary updates when create new checkbox change.
   *
   * @returns void
   */
  protected abstract onCreateNewChange(): void;

  /**
   * Processes the options retrieved by the dropdown.
   *
   * @param options SelectionItem[]
   * @returns void
   */
  protected processOptions(options: SelectionItem[]): void {
    if (!this.hasMatches()) {
      this.checkMatches(options);
    }
  }

  /**
   * Retries to fetch the matches.
   *
   * @returns void
   */
  protected abstract retry(): void;

  /**
   * Sets up the search criteria.
   *
   * @returns void
   */
  protected setupSearchCriteria(): void {
    const criteria: CastCrewSearchCriteria = new CastCrewSearchCriteria();
    criteria.setQuery(this.name);
    criteria.setPageSize(this.appConfigProvider.get('ux.credits.wizard.page.fetchSize'));

    const type: SearchTypeEnum = this.original ? SearchTypeEnum.Original : SearchTypeEnum.All;
    criteria.setSearchType(new SearchType(type));

    this.searchCriteria = criteria;
  }
}
