import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { Observable, Observer, Subscription } from 'rxjs';
import { FileItem, FileUploader } from 'ng2-file-upload';

import { Accept as AcceptResolution } from '../../models/import/resolution/accept/accept.model';
import { AuthHelper } from 'app/modules/auth/helpers/auth/auth.helper';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { Comparison } from '../../models/import/comparison/comparison.model';
import { Decline as DeclineResolution } from '../../models/import/resolution/decline/decline.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Overwritters } from '../../models/import/overwritters/overwritters.model';
import { ProcessingResponse } from '../../models/import/processing-response/processing-response.model';
import { Resolution } from '../../models/import/resolution/resolution.model';
import { Status } from '../../models/import/processing-response/status/status.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Template } from '../../models/template/template.model';


@Injectable()
export class ImportService extends BoltAbstractService {
  constructor(
    protected appConfig: AppConfigProvider,
    protected appRoutes: AppRoutesService,
    protected authHelper: AuthHelper,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns a subscription to cancel a comparison.
   *
   * @param uuid string
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  cancel(uuid: string, onSuccessDo: any, onErrorDo: any, finallyDo?: any): Subscription {
    const resolution: DeclineResolution = new DeclineResolution();
    const subs: Subscription = this.resolveConflicts(uuid, resolution, onSuccessDo, onErrorDo, finallyDo);

    return subs;
  }

  /**
   * Returns a subscription to ask the comparison of an uploaded file.
   *
   * @param uuid string
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  fetchComparison(
    uuid: string,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const url: string = this.generateUrl(
      'pie.importService.fetchComparison.endpoint',
      { '{uuid}': uuid }
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        const comparison: Comparison = new Comparison(successResponse.item);
        onSuccessDo(comparison);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription to ask the status of an uploaded file.
   *
   * @param uuid string
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  fetchImportStatus(
    uuid: string,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const url: string = this.generateUrl(
      'pie.importService.fetchImportStatus.endpoint',
      { '{uuid}': uuid }
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        const status: Status = new Status(successResponse.item);
        onSuccessDo(status);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription to check a resolution.
   *
   * @param uuid string
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  fetchResolution(
    uuid: string,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const url: string = this.generateUrl(
      'pie.importService.fetchResolution.endpoint',
      { '{uuid}': uuid }
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription to submit a comparison.
   *
   * @param comparison Comparison
   * @param overwritters Overwritters
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  submit(
    comparison: Comparison,
    overwritters: Overwritters,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const resolution: AcceptResolution = new AcceptResolution(comparison, overwritters);
    const subs: Subscription = this.resolveConflicts(comparison.uuid, resolution, onSuccessDo, onErrorDo, finallyDo);

    return subs;
  }

  /**
   * Returns a subscription after loads the file.
   *
   * @param template Template
   * @param fileUploader FileUploader
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  uploadFile(
    template: Template,
    fileUploader: FileUploader,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const url: string = this.generateUrl(
      'pie.importService.uploadFile.endpoint',
      { '{type}': template.id }
    );

    const authToken: string = `${this.appConfig.get('auth.oAuth2.headerPrefix')} ${this.authHelper.getToken()}`;

    fileUploader.setOptions({
      url: url,
      authToken: authToken
    });

    const obs: Observable<any> = Observable.create(
      (observer: Observer<any>) => {
        fileUploader.onErrorItem = (item: FileItem, response: string) => {
          let data: any;

          try {
            data = JSON.parse(response);
          } catch (error) {
            data = new ErrorHelper('Unknown error', undefined, response);
          }

          const myResponse: StormServiceResponseSingle = new StormServiceResponseSingle(data);

          observer.error(myResponse);
        };

        fileUploader.onSuccessItem = (item: FileItem, response: string) => {
          let data: any;
          let myResponse: StormServiceResponseSingle;

          try {
            data = JSON.parse(response);
            myResponse = new StormServiceResponseSingle(data);

            observer.next(myResponse);
            observer.complete();
          } catch (error) {
            data = new ErrorHelper('Unknown error', undefined, response);

            myResponse = new StormServiceResponseSingle(data);
            observer.error(myResponse);
          }
        };

        fileUploader.uploadItem(fileUploader.queue[0]);
      }
    );

    const subs: Subscription = obs.subscribe(
      (successResponse: StormServiceResponseSingle) => {
        const response: ProcessingResponse = new ProcessingResponse(successResponse.item);
        onSuccessDo(response);
      },
      (errorResponse: StormServiceResponseSingle) => {
        onErrorDo(errorResponse.item);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription to resolve the conflicts.
   *
   * @param uuid string
   * @param resolution Resolution
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  protected resolveConflicts(
    uuid: string,
    resolution: Resolution,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const url: string = this.generateUrl(
      'pie.importService.resolveConflicts.endpoint',
      { '{uuid}': uuid }
    );

    const request: any = {
      url: url,
      body: resolution.asEndpointData()
    };

    const subs: Subscription = this.doPostRequest(
      request,
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }
}
