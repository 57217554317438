import { Component } from '@angular/core';

import { TalentManager, TalentManagerMetadataManagement } from '../../helpers/talent-manager/talent-manager.helper';


@Component({
  selector: 'bolt-page-talent-list',
  template: require('./bolt-page-talent-list.html'),
  viewProviders: [
    TalentManager,
  ]
})
export class BoltPageTalentListComponent {
  protected refreshList: boolean = false;
  protected talentMetadataManager: TalentManagerMetadataManagement;

  constructor(
    protected talentManager: TalentManager
  ) {
    this.talentManager.getManagedTalentMetadata().subscribe(
      talentMetadataManager => this.talentMetadataManager = talentMetadataManager
    );
  }

  triggerListRefresh() {
    this.refreshList = true;
    setTimeout(() => this.refreshList = false, 100);
  }
}
