import { BooleanField } from './field/boolean-field/boolean-field.model';
import { DateField } from './field/date-field/date-field.model';
import { Error } from '../error/error.model';
import { Field } from './field/field.model';
import { NumberField } from './field/number-field/number-field.model';
import { StringField } from './field/string-field/string-field.model';
import { TypeEnum } from './field/type.enum';


export abstract class Title {
  protected _entityId: number;
  protected _errors: Error[];
  protected _fields: Field[];
  protected _id: number;
  protected _legalTitle: string;
  protected _locale: string;
  protected _radarId: number;
  protected _rowLocale: string;
  protected _type: string;

  /**
   * This is mandatory for Typescript and it doesn't have sense.
   */
  constructor(data: any) {
    this.initialize(data);
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns Object
   */
  asEndpointData(): object {
    const fieldsData: object[] = new Array();

    this.fields.forEach(
      (field: Field) => {
        if (!field.resolution.isUndetermined()) {
          const includeNewValue: boolean = (field.fieldName === 'locked');
          const fieldData: object = field.asEndpointData(includeNewValue);

          fieldsData.push(fieldData);
        }
      }
    );

    const data: object = {
      id: this.id,
      titleId: this.entityId,
      fields: fieldsData
    };

    return data;
  }

  get entityId(): number {
    return this._entityId;
  }

  get errors(): Error[] {
    return this._errors;
  }

  get fields(): Field[] {
    return this._fields;
  }

  get id(): number {
    return this._id;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  get locale(): string {
    return this._locale;
  }

  get radarId(): number {
    return this._radarId;
  }

  get rowLocale(): string {
    return this._rowLocale;
  }

  get type(): string {
    return this._type;
  }

  /**
   * Returns the field for the given name.
   *
   * @param name string
   * @returns Field
   */
  getField(name: string): Field {
    let field: Field;

    if (this.hasFields()) {
      field = this.fields.filter(
        aField => {
          const matched: boolean = (aField.fieldName === name);
          return matched;
        }
      ).shift();
    }

    return field;
  }

  /**
   * Indicates if it has fields.
   *
   * @returns boolean
   */
  hasFields(): boolean {
    const hasIt: boolean = (
      this.fields.length > 0
    );

    return hasIt;
  }

  /**
   * Indicates if it is an episode.
   *
   * @returns boolean
   */
  isEpisode(): boolean {
    return false;
  }

  /**
   * Indicates if it is locked.
   *
   * @returns void
   */
  isLocked(): boolean {
    let isIt: boolean;

    if (this.hasFields()) {
      const field: BooleanField = <BooleanField>this.getField('locked');
      isIt = field.isTruthy();
    } else {
      isIt = false;
    }

    return isIt;
  }

  /**
   * Indicates if it is a season.
   *
   * @returns boolean
   */
  isSeason(): boolean {
    return false;
  }

  /**
   * Indicates if it is a series.
   *
   * @returns boolean
   */
  isSeries(): boolean {
    return false;
  }

  /**
   * Initializes the child instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this._entityId = (
      (data && data.hasOwnProperty('entityId'))
        ? data.entityId
        : undefined
    );

    this._errors = new Array();

    this._id = (
      (data && data.hasOwnProperty('id'))
        ? data.id
        : undefined
    );

    this._legalTitle = (
      (data && data.hasOwnProperty('legalTitle'))
        ? data.legalTitle
        : undefined
    );

    this._locale = (
      (data && data.hasOwnProperty('locale'))
        ? data.locale
        : undefined
    );

    this._radarId = (
      (data && data.hasOwnProperty('radarId'))
        ? data.radarId
        : undefined
    );

    this._rowLocale = (
      (data && data.hasOwnProperty('rowLocale'))
        ? data.rowLocale
        : undefined
    );

    this._type = (
      (data && data.hasOwnProperty('titleType'))
        ? data.titleType
        : undefined
    );

    if (data && data.hasOwnProperty('errors')) {
      data.errors.forEach(
        (errorData: any) => {
          const error: Error = new Error(errorData);
          this._errors.push(error);
        }
      );
    }

    this.populateFields(data);
  }

  /**
   * Populates the fields.
   *
   * @param data any
   * @returns void
   */
  protected populateFields(data: any): void {
    this._fields = new Array();

    if (data && data.hasOwnProperty('fields')) {
      data.fields.forEach(
        (fieldData: any) => {
          let aField: Field;

          switch (fieldData.valueType) {
            case TypeEnum.BOOLEAN:
              aField = new BooleanField(fieldData);
            break;
            case TypeEnum.DATE:
              aField = new DateField(fieldData);
            break;
            case TypeEnum.NUMBER:
              aField = new NumberField(fieldData);
            break;
            default:
              aField = new StringField(fieldData);
            break;
          }

          this._fields.push(aField);
        }
      );
    }
  }
}
