import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HeartbeatService } from '../../service/heartbeat/heartbeat.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable({
  providedIn: 'root',
})
export class HeartbeatHelper {
  constructor(
    protected heartbeatService: HeartbeatService
  ) { }

  /**
   * Returns the Host for the given endpoint heartbeat key.
   *
   * @param system any
   * @returns string
   */
  getHost(system: any): string {
    return this.heartbeatService.getHost(system);
  }

  /**
   * Returns an observable for pinging the endopint of the given key.
   *
   * @param system any
   * @returns Observable<StormServiceResponseSingle>
   */
  ping(system: any): Observable<StormServiceResponseSingle> {
    return this.heartbeatService.ping(system);
  }
}
