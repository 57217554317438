import { Component, Input, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { startCase as _startCase, isObject as _isObject } from 'lodash';
import { Subscription } from 'rxjs';

import { ActionEnum } from '../../models/level-data/action/action.enum';
import { ActionHandlerService } from '../../services/level-data/action-handler/action-handler.service';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { Title } from '../../models/title.model';
import { FormHandlerService } from '../../services/level-data/form-handler/form-handler.service';
import { TitleForm } from '../../models/level-data/form/title-form.model';


@Component({
  selector: 'bolt-title-level-data-actions',
  template: require('./bolt-title-level-data-actions.html'),
  styles: [require('./bolt-title-level-data-actions.scss')]
})
export class BoltTitleLevelDataActionsComponent implements OnDestroy {
  @Input() show: boolean;
  @Input() title: Title;

  @ViewChild('confirmModal')
  protected confirmModal: TemplateRef<any>;

  protected actionName: string;
  protected form: TitleForm;
  protected formSubscription: Subscription;
  protected showEditHistory: boolean;

  constructor(
    protected actionHandler: ActionHandlerService,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected formHandler: FormHandlerService,
    protected modalService: NgbModal
  ) {
    this.listenFormChange();
    this.showEditHistory = false;
  }

  ngOnDestroy() {
    if (_isObject(this.formSubscription)) {
      this.formSubscription.unsubscribe();
    }
  }

  /**
   * Asks for confirming the action and triggers it.
   *
   * @param targetAction ActionEnum
   * @returns void
   */
  protected confirmAndTrigger(targetAction: ActionEnum): void {
    this.actionName = _startCase(targetAction);

    this.modalService.open(this.confirmModal).result.then(
      () => {
        this.actionHandler[targetAction]();
      },
      () => { }
    );
  }

  /**
   * Indicates if it has to block the "Save" button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const hasIt: boolean = this.title.locked || this.form.invalid;
    return hasIt;
  }

  /**
   * Indicates if it has to display the view edit history button.
   *
   * @returns boolean
   */
  protected hasDisplayEditHistory(): boolean {
    const hasIt: boolean =
      this.capabilitiesManager.hasUserPrivilegeOn(
        modulesPath.titles.details.locale.editHistory.path,
        [ActionTypeEnum.read]
      );

    return hasIt;
  }

  /**
   * Indicates if it has to block the "Save & Lock" button.
   *
   * @returns boolean
   */
  protected hasBlockSaveAndLock(): boolean {
    return this.hasBlockSave();
  }

  /**
   * Indicates if it has to display the "Save" button.
   *
   * @returns boolean
   */
  protected hasDisplaySave(): boolean {
    const hasIt: boolean =
      !this.isMoratoriumTitle() &&
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.details.original.path, [ActionTypeEnum.write]);

    return hasIt;
  }

  /**
   * Indicates if it has to display the "Save & Lock" button.
   *
   * @returns boolean
   */
  protected hasDisplaySaveAndLock(): boolean {
    const hasIt =
      !this.isMoratoriumTitle() &&
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.details.original.path, [ActionTypeEnum.lock, ActionTypeEnum.write]) &&
      !this.title.locked;

    return hasIt;
  }

  /**
   * Indicates if it has to display the "Unlock" button.
   *
   * @returns boolean
   */
  protected hasDisplayUnlock(): boolean {
    const hasIt =
      !this.isMoratoriumTitle() &&
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.details.original.path, [ActionTypeEnum.unlock]) &&
      this.title.locked;

    return hasIt;
  }

  /**
   * Indicates if "Moratorium" is active for the current title.
   *
   * @todo Remove this method after UAT and call `title.moratorium`.
   * @returns boolean
   */
  protected isMoratoriumTitle(): boolean {
    const isIt: boolean = this.title.moratorium;
    return isIt;
  }

  /**
   * Listens when the form changes.
   *
   * @returns void
   */
  protected listenFormChange(): void {
    this.formSubscription = this.formHandler.formListener.subscribe(
      (form: TitleForm) => {
        this.form = form;
      }
    );
  }

  /**
   * Saves the data.
   *
   * @returns void
   */
  protected save(): void {
    this.confirmAndTrigger(ActionEnum.save);
  }

  /**
   * Saves the data and locks it.
   *
   * @returns void
   */
  protected saveAndLock(): void {
    this.confirmAndTrigger(ActionEnum.saveAndLock);
  }

  /**
   * Unlocks the data.
   *
   * @returns void
   */
  protected unlock(): void {
    this.confirmAndTrigger(ActionEnum.unlock);
  }

  /**
   * Toggles the value of `show`.
   *
   * @returns void
   */
  protected toggleShow(): void {
    if (this.show) {
      this.actionHandler.hideData();
    } else {
      this.actionHandler.showData();
    }
  }

  /**
   * Close the edit history modal.
   *
   * @returns void
   */
  protected closeEditHistory(): void {
    this.showEditHistory = false;
  }

  /**
   * Open the edit history modal.
   *
   * @returns void
   */
  protected openEditHistory(): void {
    this.showEditHistory = true;
  }
}
