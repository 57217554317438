import { FormControl, Validators } from '@angular/forms';

import { RoleForm } from '../role-form.model';

export class LocalizedDataForm extends RoleForm {
  protected languageField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  /**
   * Get the language field.
   *
   * @returns FormControl
   */
  getLanguageField(): FormControl {
    return this.languageField;
  }

  toJson(): any {
    const data: any = super.toJson();
    data.language = this.getLanguageField().value;

    return data;
  }

  protected setupFields(): void {
    super.setupFields();
    this.setupLanguage();
  }

  /**
   * Set up the language field.
   *
   * @returns void
   */
  protected setupLanguage(): void {
    this.languageField = new FormControl(
      this.getDefaultValue('language'),
      Validators.required
    );

    this.addControl('language', this.languageField);
  }
}
