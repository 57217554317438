import { isBoolean as _isBoolean, isNumber as _isNumber, isString as _isString } from 'lodash';

import { Connection } from '../connection.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class KafkaConnection extends Connection {
  protected groupId: string;
  protected nodes: number;
  protected paused: boolean;
  protected running: boolean;
  protected topic: string;

  constructor(data: any) {
    super(data);
    this.setGroupId(data.groupId);
    this.setNodes(data.nodes);
    this.setPaused(data.paused);
    this.setRunning(data.running);
    this.setTopic(data.topic);
  }

  /**
   * Gets the group id.
   *
   * @returns string
   */
  getGroupId(): string {
    return this.groupId;
  }

  /**
   * Gets the nodes.
   *
   * @returns number
   */
   getNodes(): number {
    return this.nodes;
  }

  /**
   * Gets paused.
   *
   * @returns boolean
   */
   getPaused(): boolean {
    return this.paused;
  }

  /**
   * Gets running.
   *
   * @returns boolean
   */
   getRunning(): boolean {
    return this.running;
  }

  /**
   * Gets the topic.
   *
   * @returns string
   */
  getTopic(): string {
    return this.topic;
  }

  /**
   * Indicates if it is a Kafka connection.
   *
   * @returns boolean
   */
  isKafka(): boolean {
    return true;
  }

  /**
   * Sets the group id.
   *
   * @param groupId string
   * @throws ErrorHelper
   * @returns void
   */
  protected setGroupId(groupId: string): void {
    if (_isString(groupId)) {
      this.groupId = groupId;
    } else {
      throw new ErrorHelper('Invalid Group Id given for Kafka connection.');
    }
  }

  /**
   * Sets the nodes.
   *
   * @param nodes number
   * @throws ErrorHelper
   * @returns void
   */
  protected setNodes(nodes: number): void {
    if (_isNumber(nodes)) {
      this.nodes = nodes;
    } else {
      throw new ErrorHelper('Invalid Nodes given for Kafka connection.');
    }
  }

  /**
   * Sets paused.
   *
   * @param paused boolean
   * @throws ErrorHelper
   * @returns void
   */
  protected setPaused(paused: boolean): void {
    if (_isBoolean(paused)) {
      this.paused = paused;
    } else {
      throw new ErrorHelper('Invalid Paused given for Kafka connection.');
    }
  }

  /**
   * Sets running.
   *
   * @param running boolean
   * @throws ErrorHelper
   * @returns void
   */
  protected setRunning(running: boolean): void {
    if (_isBoolean(running)) {
      this.running = running;
    } else {
      throw new ErrorHelper('Invalid Running given for Kafka connection.');
    }
  }

  /**
   * Sets the topic.
   *
   * @param topic string
   * @throws ErrorHelper
   * @returns void
   */
  protected setTopic(topic: string): void {
    if (_isString(topic)) {
      this.topic = topic;
    } else {
      throw new ErrorHelper('Invalid Topic given for Kafka connection.');
    }
  }
}
