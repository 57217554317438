import { includes as _includes } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StatusEnum } from './status.enum';


export class Status {
  protected _error: ErrorHelper;
  protected _value: StatusEnum;

  constructor(data: any) {
    if (data.hasOwnProperty('jobStatus') && _includes(StatusEnum, data['jobStatus'])) {
      this._value = data['jobStatus'];

      if (data.hasOwnProperty('error') && data['error'].hasOwnProperty('description')) {
        let details: string;
        let code: number;

        if (data['error'].hasOwnProperty('code') && data['error']['code'] === '3000') {
          details = data['error']['details'];
          code = data['error']['code'];
        } else {
          details = (
            data['error'].hasOwnProperty('cause')
              ? data['error']['cause']
              : undefined
          );
        }
        this._error = new ErrorHelper(data['error']['description'], code, details);
      } else {
        this._error = undefined;
      }
    } else {
      throw new ErrorHelper('Invalid configuration detected.');
    }
  }

  get error(): ErrorHelper {
    return this._error;
  }

  get value(): StatusEnum {
    return this._value;
  }

  /**
   * Indicates if it is DOWNLOADED.
   *
   * @returns boolean
   */
  isDownloaded(): boolean {
    return this.is(StatusEnum.DOWNLOADED);
  }

  /**
   * Indicates if it is ERROR.
   *
   * @returns boolean
   */
  isError(): boolean {
    return this.is(StatusEnum.ERROR);
  }

  /**
   * Indicates if it is GENERATED.
   *
   * @returns boolean
   */
  isGenerated(): boolean {
    return this.is(StatusEnum.GENERATED);
  }

  /**
   * Indicates if it is COMPLETED.
   * @returns boolean
   */
  isCompleted(): boolean {
    return this.is(StatusEnum.COMPLETED);
  }

  /**
   * Indicates if it is PENDING.
   *
   * @returns boolean
   */
  isPending(): boolean {
    return this.is(StatusEnum.PENDING);
  }

  /**
   * Indicates if it is STARTED.
   *
   * @returns boolean
   */
  isStarted(): boolean {
    return this.is(StatusEnum.STARTED);
  }

  /**
   * Indicates if it is IN PROGRESS.
   *
   * @returns boolean
   */
  isInProgress(): boolean {
    return this.is(StatusEnum.IN_PROGRESS);
  }

  /**
   * Returns the status as a string.
   *
   * @returns string
   */
  toString(): string {
    return this._value.toString();
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected initialize(data: any): void {

  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value TypeEnum
   * @returns boolean
   */
  protected is(value: StatusEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
