import { Component } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';


@Component({
  selector: 'bolt-app-heartbeat-list',
  template: require('./bolt-app-heartbeat-list.html'),
  styles: [require('./bolt-app-heartbeat-list.scss')]
})
export class BoltAppHeartbeatListComponent {
  protected systems: any[];

  constructor(
    protected appConfig: AppConfigProvider
  ) {
    this.initialize();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.systems = this.appConfig.get('application.heartbeat.systems', []);
  }
}
