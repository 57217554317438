import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { toString as _toString } from 'lodash';

@Directive({
  selector: '[boltTimeCodeMask]'
})
export class BoltTimeCodeMaskDirective {

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    const formattedValue = this.getFormattedValue(_toString(event));
    this.ngControl.valueAccessor.writeValue(formattedValue);
  }

  /**
   * Formats the input value into the timecode format HH:MM:SS:FF
   * @param rawValue string
   * @returns string
   */
  protected getFormattedValue(rawValue: string): string {
    let newVal = rawValue.replace(/\D/g, '');
    newVal = newVal.substring(0, 8);
    const valLength = newVal.length;

    if (valLength > 6) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/, '$1:$2:$3:$4');
    } else if (valLength > 4) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})(\d{0,2})/, '$1:$2:$3');
    } else if (valLength > 2) {
      newVal = newVal.replace(/^(\d{0,2})(\d{0,2})/, '$1:$2');
    }
    return newVal;
  }
}
