import { FormControl, Validators } from '@angular/forms';

import { isObject as _isObject, isBoolean as _isBoolean } from 'lodash';
import { CharacterForm } from '../character-form.model';


export class OriginalDataForm extends CharacterForm {
  protected originalLanguageField: FormControl;
  protected originalNameField: FormControl;
  protected requiredLocalizationsField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  /**
   * Get the original language field.
   *
   * @returns FormControl
   */
  getOriginalLanguageField(): FormControl {
    return this.originalLanguageField;
  }

  /**
   * Get the original name field.
   *
   * @returns FormControl
   */
  getOriginalNameField(): FormControl {
    return this.originalNameField;
  }

  /**
   * Get the required localizations field.
   *
   * @returns FormControl
   */
  getRequiredLocalizationsField(): FormControl {
    return this.requiredLocalizationsField;
  }

  toJson(): any {
    const data: any = super.toJson();
    data.name = this.getOriginalNameField().value;
    data.originalLanguageId = this.getOriginalLanguageField().value;
    data.requiredLocalizations = this.getRequiredLocalizationsField().value;

    return data;
  }

  protected setupFields() {
    super.setupFields();
    this.setupOriginalLanguageField();
    this.setupOriginalNameField();
    this.setupRequiredLocalizationsField();
  }

  /**
   * Set up the original language field.
   *
   * @returns void
   */
  protected setupOriginalLanguageField(): void {
    this.originalLanguageField = new FormControl(
      this.getDefaultValue('originalLanguageId'),
      Validators.required
    );

    this.addControl('originalLanguageId', this.originalLanguageField);
  }

  /**
   * Set up the original name requested field.
   *
   * @returns void
   */
  protected setupOriginalNameField(): void {
    const validators: any = Validators.compose([
      Validators.required,
      Validators.maxLength(this.fieldSpecs.originalName.maxLength)
    ]);

    this.originalNameField = new FormControl(
      this.getDefaultValue('name'),
      validators
    );

    this.addControl('originalName', this.originalNameField);
  }

  /**
   * Set up the required localizations field.
   *
   * @returns void
   */
  protected setupRequiredLocalizationsField(): void {
    const defaultValue: boolean = this.getDefaultValue('requiredLocalizations');

    this.requiredLocalizationsField = new FormControl(
      _isBoolean(defaultValue) ? defaultValue : true
    );

    this.addControl('requiredLocalizations', this.requiredLocalizationsField);
  }
}
