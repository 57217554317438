import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { isArray as _isArray, isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import { Subscription } from 'rxjs';

import { Action } from '../../models/level-data/action/action.model';
import { ActionHandlerService } from '../../services/level-data/action-handler/action-handler.service';
import { Title } from '../../models/title.model';


@Component({
  selector: 'bolt-title-level-data',
  template: require('./bolt-title-level-data.html'),
  styles: [require('./bolt-title-level-data.scss')]
})
export class BoltTitleLevelDataComponent implements OnInit, OnDestroy {
  @Input() title: Title;

  @Output('updated') updateEvent: EventEmitter<Title>;

  protected actionListener: Subscription;
  protected show: boolean;

  constructor(protected actionHandler: ActionHandlerService) {
    this.show = true;
    this.updateEvent = new EventEmitter();
  }

  ngOnDestroy() {
    this.actionListener.unsubscribe();
  }

  ngOnInit() {
    this.actionListener = this.actionHandler.actionListener.subscribe(
      (action: Action) => {
        if (_isObject(action)) {
          this.handleActionChange(action);
        }
      }
    );
  }

  /**
   * Handles the given action.
   *
   * @param action Action
   * @returns void
   */
  protected handleActionChange(action: Action): void {
    if (action.isShowData()) {
      this.show = true;
    } else if (action.isHideData()) {
      this.show = false;
    }
  }

  /**
   * Handles the title update.
   *
   * @param updatedTitle Title
   * @returns void
   */
  protected handleTitleUpdate(updatedTitle: Title): void {
    this.title = updatedTitle;
    this.updateEvent.next(this.title);
  }
}
