import { Component, OnInit, Input } from '@angular/core';
import { isEmpty as _isEmpty } from 'lodash';
import { StringHelper } from '@bolt/ui-shared/common';

import { BoltAmUtcDateTimePipe } from 'app/shared/pipes/bolt-am-utc/date-time/date-time.pipe';
import { HeartbeatHelper } from '../../helpers/heartbeat/heartbeat.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Component({
  selector: 'bolt-app-heartbeat-check',
  template: require('./bolt-app-heartbeat-check.html'),
  styles: [require('./bolt-app-heartbeat-check.scss')]
})
export class BoltAppHeartbeatCheckComponent extends StormComponent implements OnInit {
  @Input('system') system: any;

  protected readonly datePattern: any = /^([0-9]{4})([0-9]{2})([0-9]{2})T([0-9]{2})([0-9]{2})([0-9]{2})$/;
  protected data: any[];
  protected rawData: any;

  constructor(protected heartbeatHelper: HeartbeatHelper) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.ping();
  }

  /**
   * Discovers the data inside the raw data.
   *
   * @returns void
   */
  protected discoverData(): void {
    if (!_isEmpty(this.rawData)) {
      const dateTimePipe: BoltAmUtcDateTimePipe = new BoltAmUtcDateTimePipe();

      this.data = new Array();

      Object.keys(this.rawData).forEach(
        (key: string) => {
          let value: any;
          let isUrl: boolean;

          if (this.datePattern.test(this.rawData[key])) {
            const sections: string[] =  this.rawData[key].match(this.datePattern).slice(1, 7);
            const isoDate: string = `${sections.slice(0, 3).join('-')}T${sections.slice(3, 6).join(':')}+0000`;

            value = `${this.rawData[key]} (${dateTimePipe.transform(isoDate)})`;
            isUrl = false;
          } else {
            value = this.rawData[key];
            isUrl = StringHelper.isUrl(value);
          }

          const entry: any = {
            name: key,
            value: value,
            isUrl: isUrl
          };

          this.data.push(entry);
        }
      );
    }
  }

  /**
   * Returns the Host for the stored endpoint heartbeat key.
   *
   * @returns string
   */
  protected getEndpointHost(): string {
    return this.heartbeatHelper.getHost(this.system);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.system = undefined;
  }

  /**
   * Pings the endpoint.
   *
   * @returns void
   */
  protected ping(): void {
    this.changeStatusToFetchingData();

    this.rawData = undefined;

    this.heartbeatHelper.ping(this.system).subscribe(
      (serviceResponseSingle: StormServiceResponseSingle) => {
        this.rawData = serviceResponseSingle.item;

        this.discoverData();
        this.changeStatusToDataFound();
      },
      () => {
        this.changeStatusToError();
      }
    );
  }
}
