import { Component, Input, OnDestroy, EventEmitter, Output, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { sortBy as _sortBy, isObject as _isObject, clone as _clone } from 'lodash';

import { Episode } from 'app/modules/title/models/episode.model';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { TitleService } from 'app/modules/title/services/title.service';
import { TitleType } from 'app/modules/title/models/title.model';


@Component({
  selector: 'bolt-clone-metadata-episode-list',
  template: require('./bolt-clone-metadata-episode-list.html'),
  styles: [require('./bolt-clone-metadata-episode-list.scss')]
})
export class BoltCloneMetadataEpisodeListComponent extends StormComponent implements OnDestroy, OnInit  {
  @Input() title: Episode;
  @Input() locale: string;
  @Input() localeObject: Locale;
  @Input() checkNsaDisclaimer: boolean;

  @Output('selected') protected selectEvent: EventEmitter<Episode[]>;

  protected selectedOptions: Episode[];
  protected optionsSubscription: Subscription;
  protected episodeOptions: Episode[];

  constructor(
    protected layoutHandler: LayoutHandlerService,
    protected titleService: TitleService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.fetchAllEpisodesOfSeason();
  }

  ngOnDestroy() {
    this.cancelSubscriptions();
    this.reset();
  }

  /**
   * Applies the filter on the current options.
   *
   * @returns void
   */
  protected applyFilter(): void {
    this.episodeOptions = _sortBy(this.episodeOptions, 'f0_num');

    this.episodeOptions = this.episodeOptions.filter(
      (episode: Episode) => episode.id !== this.title.id
    );
  }

  /**
   * Cancels the current subscriptions.
   *
   * @returns void
   */
  protected cancelSubscriptions(): void {
    if (_isObject(this.optionsSubscription)) {
      this.optionsSubscription.unsubscribe();
    }
  }

  /**
   * Fetches the episodes for the current season.
   *
   * @returns void
   */
  protected fetchAllEpisodesOfSeason(): void {
    this.layoutHandler.changeToFetching();
    this.changeStatusToFetchingData();

    const data: any = {
      titleType: TitleType.episode,
      seasonId: this.title.seasonId,
      _size: 5000
    };

    this.optionsSubscription = this.titleService.filterTitles(data).subscribe(
      (response: StormServiceResponseCollection) => {
        response.collection.forEach(
          (episode: Episode) => {
            this.episodeOptions.push(episode);
          }
        );

        this.applyFilter();
        this.layoutHandler.changeToReading();
        this.changeStatusToDataFound();
      },
      error => {
        this.layoutHandler.changeToReading();
        this.changeStatusToError();
      }
    );
  }

  /**
   * Indicates if it has to display the empty message.
   *
   * @returns boolean
   */
  protected hasDisplayEmptyMessage(): boolean {
    const hasIt: boolean = !this.hasTitleOptions();
    return hasIt;
  }

  /**
   * Indicates if it has title options.
   *
   * @returns boolean
   */
  protected hasTitleOptions(): boolean {
    const hasIt: boolean = this.episodeOptions.length > 0;
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.selectedOptions = new Array();
    this.selectEvent = new EventEmitter();
    this.episodeOptions = new Array();
  }

  /**
   * Indicates if it has all the options selected.
   *
   * @returns boolean
   */
  protected isAllSelected(): boolean {
    const isIt: boolean = this.selectedOptions.length === this.episodeOptions.length;
    return isIt;
  }

  /**
   * Indicates if the given option is in the current selections.
   *
   * @param title Episode
   * @returns boolean
   */
  protected isSelected(title: Episode): boolean {
    const isIt: boolean = this.selectedOptions.some(
      (option: Episode) => option.id === title.id
    );

    return isIt;
  }

  /**
   * Removes the given option from the current selected options.
   *
   * @param option string
   * @returns void
   */
  protected removeSelected(selected: Episode): void {
    const index: number = this.selectedOptions.findIndex(
      (option: Episode) => option.id === selected.id
    );

    this.selectedOptions.splice(index, 1);
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.selectedOptions = new Array();
    this.episodeOptions = new Array();
  }

  /**
   * Toggles all selections.
   *
   * @returns void
   */
  protected toggleAll(): void {
    if (this.isAllSelected()) {
      this.selectedOptions = new Array();
    } else {
      this.selectedOptions = _clone(this.episodeOptions);
    }

    this.selectEvent.emit(this.selectedOptions);
  }

  /**
   * Updates the value of the given option in the current selections.
   *
   * @param option Episode
   * @returns void
   */
  protected updateSelected(option: Episode): void {
    if (this.isSelected(option)) {
      this.removeSelected(option);
    } else {
      this.selectedOptions.push(option);
    }

    this.selectEvent.emit(this.selectedOptions);
  }
}
