import { isArray as _isArray, isEmpty as _isEmpty, isString as _isString } from 'lodash';
import { Account, Country, Language, ProductType } from '@bolt/ui-shared/master-data';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export abstract class LocaleSectionsValidator {
  /**
   * Validates the given locale sections and throws an error if it is invalid for a project or an entity.
   *
   * @example
   * ```
   *   Valid:
   *     - ['*', 'US', '*', '*']
   *     - ['*', 'US', '*', 'DISNEYSVOD']
   *     - ['en', '*', '*', '*']
   *     - ['en', '*', '*', 'DISNEYSVOD']
   *
   *   Invalid:
   *     - ['en', 'US', '*', 'DISNEYSVOD']
   *     - ['*', '*', '*', 'DISNEYSVOD']
   * ```
   * @param sections string[]
   * @throws ErrorHelper
   * @returns void
   */
  static validate(sections: string[]): void {
    this.validateStructure(sections);
    this.validateLanguageAndTerritory(sections[Language.LOCALE_POSITION], sections[Country.LOCALE_POSITION]);
  }

  /**
   * Indicates if any section of the given group is an invalid string.
   *
   * @param sections string[]
   * @returns boolean
   */
  protected static anyIsInvalidString(sections: string[]): boolean {
    const isIt: boolean = (
      !_isString(sections[Language.LOCALE_POSITION]) || _isEmpty(sections[Language.LOCALE_POSITION]) ||
      !_isString(sections[Country.LOCALE_POSITION]) || _isEmpty(sections[Country.LOCALE_POSITION]) ||
      !_isString(sections[ProductType.LOCALE_POSITION]) || _isEmpty(sections[ProductType.LOCALE_POSITION]) ||
      !_isString(sections[Account.LOCALE_POSITION]) || _isEmpty(sections[Account.LOCALE_POSITION])
    );

    return isIt;
  }

  /**
   * Validates the language and territory.
   *
   * @param language string
   * @param territory string
   * @throws ErrorHelper
   * @returns void
   */
  protected static validateLanguageAndTerritory(language: string, territory: string): void {
    const areLanguageAndTerritoryInvalid: boolean = (
      (Language.isAll(language) && Country.isAll(territory)) ||
      (!Language.isAll(language) && !Country.isAll(territory))
    );

    if (areLanguageAndTerritoryInvalid) {
      throw new ErrorHelper(`Invalid language and territory given for a locale: ${language} + ${territory}.`);
    }
  }

  /**
   * Validates the structure of given locale sections.
   *
   * @param sections string[]
   * @throws ErrorHelper
   * @returns void
   */
  protected static validateStructure(sections: string[]): void {
    if (!_isArray(sections) || (sections.length !== 4) || this.anyIsInvalidString(sections)) {
      throw new ErrorHelper('Invalid locale structure.');
    }
  }
}
