import { isArray as _isArray } from 'lodash';

import { TitleType } from './title.model';


export interface TitleListSearchResultInterface {
  aka?: string[];
  id: number;
  fka?: string[];
  type: TitleType;
  title: string;
  originalTitle: string;
  prettifiedLegalTitle?: string;
  lastModified: number;
  seasonId?: number;
  seasonTitle?: string;
  seriesId?: number;
  seriesTitle?: string;

  /**
   * Indicates if it has AKA.
   *
   * @returns boolean
   */
  hasAka(): boolean;

  /**
   * Indicates if it has FKA.
   *
   * @returns boolean.
   */
  hasFka(): boolean;
}

export class TitleListSearchResult implements TitleListSearchResultInterface {
  protected _aka: string[];
  protected _id: number;
  protected _fka: string[];
  protected _type: TitleType;
  protected _title: string;
  protected _originalTitle: string;
  protected _prettifiedLegalTitle: string;
  protected _lastModified: number;
  protected _seasonId?: number;
  protected _seasonTitle?: string;
  protected _seriesId?: number;
  protected _seriesTitle?: string;

  constructor(attributes?: { [attr: string]: any }) {
    this.setAttributes(attributes);
  }

  setAttributes(attributes?: { [attr: string]: any }) {
    if (attributes !== undefined) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }

    return this;
  }

  get aka(): string[] {
    return this._aka;
  }

  set aka(aka: string[]) {
    this._aka = aka;
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get fka(): string[] {
    return this._fka;
  }

  set fka(fka: string[]) {
    this._fka = fka;
  }

  get type(): TitleType {
    return this._type;
  }

  set type(type: TitleType) {
    this._type = type;
  }

  get title(): string {
    return this._title;
  }

  set title(title: string) {
    this._title = title;
  }

  get originalTitle(): string {
    return this._originalTitle;
  }

  set originalTitle(originalTitle: string) {
    this._originalTitle = originalTitle;
  }

  get prettifiedLegalTitle(): string {
    return this._prettifiedLegalTitle;
  }

  set prettifiedLegalTitle(prettifiedLegalTitle: string) {
    this._prettifiedLegalTitle = prettifiedLegalTitle;
  }

  get lastModified(): number {
    return this._lastModified;
  }

  set lastModified(lastModified: number) {
    this._lastModified = lastModified;
  }

  get seasonId(): number {
    return this._seasonId;
  }

  set seasonId(seasonId: number) {
    this._seasonId = seasonId;
  }

  get seasonTitle(): string {
    return this._seasonTitle;
  }

  set seasonTitle(seasonTitle: string) {
    this._seasonTitle = seasonTitle;
  }

  get seriesId(): number {
    return this._seriesId;
  }

  set seriesId(seriesId: number) {
    this._seriesId = seriesId;
  }

  get seriesTitle(): string {
    return this._seriesTitle;
  }

  set seriesTitle(seriesTitle: string) {
    this._seriesTitle = seriesTitle;
  }

  hasAka(): boolean {
    const hasIt: boolean = _isArray(this.aka) && this.aka.length > 0;
    return hasIt;
  }

  hasFka(): boolean {
    const hasIt: boolean = _isArray(this.fka) && this.fka.length > 0;
    return hasIt;
  }

  /**
   * Indicates if it is an episode.
   *
   * @returns boolean
   */
  isEpisode(): boolean {
    const isIt: boolean = (this.type === TitleType.episode);
    return isIt;
  }

  /**
   * Indicates if it is a feature.
   *
   * @returns boolean
   */
  isFeature(): boolean {
    const isIt: boolean = (this.type === TitleType.feature);
    return isIt;
  }

  /**
   * Indicates if it is a season.
   *
   * @returns boolean
   */
  isSeason(): boolean {
    const isIt: boolean = (this.type === TitleType.season);
    return isIt;
  }

  /**
   * Indicates if it is a series.
   *
   * @returns boolean
   */
  isSeries(): boolean {
    const isIt: boolean = (this.type === TitleType.series);
    return isIt;
  }
}
