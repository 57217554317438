import { Localized } from 'app/modules/common/models/localized.model';
import { TalentInterface } from 'app/modules/talent/models/talent.model';
import { CreditType } from './credit-type.enum';
import { CreditPositionType } from './credit-position.enum';


export abstract class Credit extends Localized {
  protected featureId: number;
  protected seasonId: number;
  protected order: number;
  protected talentId: number;
  protected talent: TalentInterface;
  protected positionType: CreditPositionType;
  protected type: CreditType;
  protected isGuest: boolean;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
  }

  getFeatureId(): number {
    return this.featureId;
  }

  getSeasonId(): number {
    return this.seasonId;
  }

  getOrder(): number {
    return this.order;
  }

  setOrder(value: number): void {
    this.order = value;
  }

  getTalentId(): number {
    return this.talentId;
  }

  setTalentId(id: number): void {
    this.talentId = id;
  }

  getTalent(): TalentInterface {
    return this.talent;
  }

  abstract getPosition(): any;

  getPositionType(): CreditPositionType {
    return this.positionType;
  }

  getType(): CreditType {
    return this.type;
  }

  getIsGuest(): boolean {
    return this.isGuest;
  }

  setIsGuest(value: boolean): void {
    this.isGuest = value;
  }

  getRawObject(): object {
    const rawObject: any = {
      featureId: this.featureId,
      seasonId: this.seasonId,
      order: this.order,
      talentId: this.talentId,
      positionType: this.positionType,
      type: this.type,
      isGuest: this.isGuest
    };

    return rawObject;
  }

  /**
   * Indicates if the credit is complete.
   *
   * @returns boolean
   */
  abstract isComplete(): boolean;
}
