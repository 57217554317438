import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from '../common/common.module';

import { BoltPageXsltComponent } from './components/bolt-page-xslt/bolt-page-xslt.component';
import { BoltXsltEditorComponent } from './components/bolt-xslt-editor/bolt-xslt-editor.component';
import { BoltXsltListComponent } from './components/bolt-xslt-list/bolt-xslt-list.component';
import { BoltXsltManagerComponent } from './components/bolt-xslt-manager/bolt-xslt-manager.component';
import { XsltManager } from './helpers/xslt-manager/xslt-manager.helper';
import { XsltService } from './services/xslt.service';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
  ],
  declarations: [
    BoltXsltListComponent,
    BoltXsltManagerComponent,
    BoltXsltEditorComponent,
    BoltPageXsltComponent,
  ],
  exports: [
    BoltPageXsltComponent,
    BoltXsltListComponent,
    BoltXsltEditorComponent,
  ],
  providers: [
    XsltManager,
    XsltService
  ]
})
export class XsltModule { }
