import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';


/**
 * @deprecated Use `LockingStatus` instead of this class (2021-11-29).
 */
export class SubtitleLockingStatus extends LockingStatus {
  constructor(data: any) {
    super(data || { });
  }
}
