import { Errors } from './errors/errors.model';
import { Warnings } from './warnings/warnings.model';


export class Alerts {
  protected _errors: Errors;
  protected _message: string;
  protected _warnings: Warnings;

  constructor(data: any) {
    this.initialize(data);
  }

  get errors(): Errors {
    return this._errors;
  }

  get message(): string {
    return this._message;
  }

  get warnings(): Warnings {
    return this._warnings;
  }

  /**
   * Returns the total quantity of alerts.
   *
   * @returns number
   */
  getTotal(): number {
    const total: number = (
      this.errors.getTotal() +
      this.warnings.getTotal()
    );

    return total;
  }

  /**
   * Indicates if there are alerts.
   *
   * @returns boolean
   */
  hasAlerts(): boolean {
    const hasIt: boolean = (
      this.errors.hasErrors() ||
      this.warnings.hasWarnings()
    );

    return hasIt;
  }

  /**
   * Updates message field
   *
   * @param message string
   * @returns void
   */
  updateMessage(message: string): void {
    this._message = message;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const errors: any = (
      (data && data.hasOwnProperty('errors'))
        ? data.errors
        : undefined
    );

    const message: any = (
      (data && data.hasOwnProperty('message'))
        ? data.message
        : undefined
    );

    const warnings: any = (
      (data && data.hasOwnProperty('warnings'))
        ? data.warnings
        : undefined
    );

    this._errors = new Errors(errors);
    this._message = message;
    this._warnings = new Warnings(warnings);
  }
}
