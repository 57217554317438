import { Injectable } from '@angular/core';
import { isString as _isString } from 'lodash';


@Injectable()
export class DataSanitizerService {
  constructor() { }

  /**
   * Trims leading and trailing whitespaces from a given string value.
   *
   * @param value string
   * @returns string
   */
  trimWhiteSpaces(value: string): string {
    if (_isString(value)) {
      return value.trim();
    }

    throw new Error(`Invalid type was used, method expects a string`);
  }
}
