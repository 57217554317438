// Angular
import { enableDebugTools, disableDebugTools } from '@angular/platform-browser';
import { enableProdMode, ApplicationRef } from '@angular/core';

// Environment Providers
let PROVIDERS: any[] = [];

// Angular debug tools in the dev console
let _decorateModuleRef = function identity<T>(value: T): T {
  return value;
};

if ('production' === ENV) {
  disableDebugTools();
  enableProdMode();

  PROVIDERS = [...PROVIDERS];
} else {
  _decorateModuleRef = (modRef: any) => {
    const appRef = modRef.injector.get(ApplicationRef);
    const cmpRef = appRef.components[0];

    const _ng = (<any>window).ng;
    enableDebugTools(cmpRef);
    (<any>window).ng.probe = _ng.probe;
    (<any>window).ng.coreTokens = _ng.coreTokens;
    return modRef;
  };

  PROVIDERS = [...PROVIDERS];
}

export const decorateModuleRef = _decorateModuleRef;
export const ENV_PROVIDERS = [...PROVIDERS];
