import { includes as _includes, startCase as _startCase } from 'lodash';
import { SelectionItem } from '@bolt/ui-shared/droplists';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { TypeEnum } from './type.enum';


export class Type {
  protected name: string;
  protected value: TypeEnum;

  constructor(value: TypeEnum) {
    this.setValue(value);
  }

  /**
   * Returns a list of `SelectionItem` using all possible values for a type.
   *
   * @returns SelectionItem[]
   */
  static asSelectionItemList(): SelectionItem[] {
    const items: SelectionItem[] = Object.keys(TypeEnum).map(
      (key: string) => new SelectionItem(_startCase(key), TypeEnum[key], new Type(TypeEnum[key]))
    );

    return items;
  }

  /**
   * Returns the name.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Returns the value.
   *
   * @returns TypeEnum
   */
  getValue(): TypeEnum {
    return this.value;
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value TypeEnum
   * @returns boolean
   */
  is(value: TypeEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }

  /**
   * Indicates if it is an "Boolean".
   *
   * @returns boolean
   */
  isBoolean(): boolean {
    return this.is(TypeEnum.Boolean);
  }

  /**
   * Indicates if it is an "BooleanList".
   *
   * @returns boolean
   */
  isBooleanList(): boolean {
    return this.is(TypeEnum.BooleanList);
  }

  /**
   * Indicates if it is a "Number".
   *
   * @returns boolean
   */
  isNumber(): boolean {
    return this.is(TypeEnum.Number);
  }

  /**
   * Indicates if it is a "NumberList".
   *
   * @returns boolean
   */
  isNumberList(): boolean {
    return this.is(TypeEnum.NumberList);
  }

  /**
   * Indicates if it is a "String".
   *
   * @returns boolean
   */
  isString(): boolean {
    return this.is(TypeEnum.String);
  }

  /**
   * Indicates if it is a "StringList".
   *
   * @returns boolean
   */
  isStringList(): boolean {
    return this.is(TypeEnum.StringList);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this.name;
  }

  /**
   * Discovers the name using the stored value.
   *
   * @returns void
   */
  protected discoverName(): void {
    const targetKey: string = Object.keys(TypeEnum).find(
      (currentKey: string) => TypeEnum[currentKey] === this.getValue()
    );

    this.name = _startCase(targetKey);
  }

  /**
   * Set the value.
   *
   * @param value TypeEnum
   * @returns void
   */
  protected setValue(value: TypeEnum): void {
    if (_includes(TypeEnum, value)) {
      this.value = value;
      this.discoverName();
    } else {
      throw new ErrorHelper('Invalid value given for a CPM Configuration Type.');
    }
  }
}
