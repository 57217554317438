import { Injectable, ElementRef } from '@angular/core';


@Injectable()
export class HeightSynchronizerService {
  protected _localizedItems: ElementRef[];
  protected _originalItems: ElementRef[];

  constructor() {
    this.initialize();
  }

  get localizedItems(): ElementRef[] {
    return this._localizedItems;
  }

  get originalItems(): ElementRef[] {
    return this._originalItems;
  }

  /**
   * Indicates if there are localized items.
   *
   * @returns boolean
   */
  hasLocalizedItems(): boolean {
    const hasIt: boolean = (this.localizedItems.length > 0);
    return hasIt;
  }

  /**
   * Indicates if there are original items.
   *
   * @returns boolean
   */
  hasOriginalItems(): boolean {
    const hasIt: boolean = (this._originalItems.length > 0);
    return hasIt;
  }

  /**
   * Reset the localized items.
   *
   * @returns void
   */
  resetLocalizedItems(): void {
    this._localizedItems = new Array();
  }

  /**
   * Reset the original items.
   *
   * @returns void
   */
  resetOriginalItems(): void {
    this._originalItems = new Array();
  }

  /**
   * Set the given localized items.
   *
   * @param localizedItems ElementRef[]
   * @returns void
   */
  setLocalizedItems(localizedItems: ElementRef[]): void {
    this._localizedItems = localizedItems;
    this.synchronize();
  }

  /**
   * Set the given original items.
   *
   * @param original ElementRef[]
   * @returns void
   */
  setOriginalItems(originalItems: ElementRef[]): void {
    this._originalItems = originalItems;
    this.synchronize();
  }

  /**
   * Fixes the localized and original items height.
   *
   * @returns void
   */
  protected fixAllItemsHeight(): void {
    this.localizedItems.forEach(
      (aLocalizedItem: ElementRef, x: number) => {
        const anOriginalItem: ElementRef = this._originalItems[x];

        this.resetItemHeight(aLocalizedItem);
        this.resetItemHeight(anOriginalItem);

        if (aLocalizedItem.nativeElement.offsetHeight > anOriginalItem.nativeElement.offsetHeight) {
          anOriginalItem.nativeElement.style.height = `${aLocalizedItem.nativeElement.offsetHeight}px`;
        } else if (aLocalizedItem.nativeElement.offsetHeight < anOriginalItem.nativeElement.offsetHeight) {
          aLocalizedItem.nativeElement.style.height = `${anOriginalItem.nativeElement.offsetHeight}px`;
        }
      }
    );
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.resetLocalizedItems();
    this.resetOriginalItems();
  }

  /**
   * Reset the height of the given item.
   *
   * @param item ElementRef
   * @returns void
   */
  protected resetItemHeight(item: ElementRef): void {
    if (item.nativeElement) {
      item.nativeElement.style.height = 'auto';
    }
  }

  /**
   * Reset the height of localized items.
   *
   * @returns void
   */
  protected resetItemHeightOverLocalizedItems(): void {
    if (this.hasLocalizedItems()) {
      this.localizedItems.forEach(
        (aLocalizedItem: ElementRef) => {
          this.resetItemHeight(aLocalizedItem);
        }
      );
    }
  }

  /**
   * Reset the height of original items.
   *
   * @returns void
   */
  protected resetItemHeightOverOriginalItems(): void {
    if (this.hasOriginalItems()) {
      this.originalItems.forEach(
        (anOriginalItem: ElementRef) => {
          this.resetItemHeight(anOriginalItem);
        }
      );
    }
  }

  /**
   * Synchronizes the localized and original items.
   *
   * @returns void
   */
  protected synchronize(): void {
    const hasSynchronize = (
      this.hasOriginalItems() &&
      this.hasLocalizedItems() &&
      this.localizedItems.length === this.originalItems.length
    );

    if (hasSynchronize) {
      this.fixAllItemsHeight();
    } else {
      this.resetItemHeightOverLocalizedItems();
      this.resetItemHeightOverOriginalItems();
    }
  }
}
