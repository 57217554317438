import { Component, Input } from '@angular/core';

@Component({
  selector: 'bolt-dynamic-table',
  template: require('./bolt-dynamic-table.component.html'),
})
export class BoltDynamicTableComponent {
  @Input() headers: Array<{ name: string; property: string }>;
  @Input() data: any[];
}
