import { Directive, ElementRef, Renderer2, Input, OnChanges, HostListener, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { fromEvent } from 'rxjs';
import { kebabCase as _kebabCase, isNull as _isNull, isObject as _isObject } from 'lodash';

import { StatusEnum } from './status.enum';


@Directive({ selector: 'p-dropdown[boltScrollLoading]' })
export class BoltDropdownScrollLoadingDirective implements OnChanges {
  @Input('boltStatus') status: StatusEnum;
  @Input('boltLoadingMessage') loadingMessage: string;
  @Input('boltNoMoreMessage') noMoreMessage: string;
  @Input('boltErrorMessage') errorMessage: string;

  @Output('boltRetryOption') retryEvent: EventEmitter<void>;
  @Output('boltScrollEnded') scrollEndedEvent: EventEmitter<void>;

  protected readonly messageClass: string = 'bolt-message-container';
  protected messageContainer: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.retryEvent = new EventEmitter();
    this.scrollEndedEvent = new EventEmitter();
    this.status = StatusEnum.idle;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.status) {
      this.handleMessage();
    }
  }

  @HostListener('onShow')
  onShow(): void {
    this.listenScrollEnding();
    this.handleMessage();
  }

  /**
   * Handles if the message needs to be shown or removed.
   *
   * @returns void
   */
  protected handleMessage(): void {
    if (this.status === StatusEnum.idle) {
      this.removeMessage();
    } else {
      this.displayMessage();
    }
  }

  /**
   * Indicates if it has the message container.
   *
   * @returns boolean
   */
  protected hasMessageContainer(): boolean {
    return _isObject(this.messageContainer);
  }

  /**
   * Displays the message at the end of the dropdown.
   *
   * @returns void
   */
  protected displayMessage(): void {
    const optionsWrapper = this.obtainScrollingWrapper();

    if (!this.hasMessageContainer()) {
      this.messageContainer = this.renderer.createElement('div');

      this.renderer.addClass(this.messageContainer, this.messageClass);
      this.renderer.appendChild(this.messageContainer, this.renderer.createElement('span'));

      this.renderer.listen(
        this.messageContainer,
        'click',
        (event: Event) => {
          event.stopPropagation();
        }
      );
    }

    this.renderer.addClass(this.messageContainer, _kebabCase(this.status));
    this.renderer.setProperty(this.messageContainer.firstChild, 'innerText', this[`${this.status}Message`]);

    if (_isObject(optionsWrapper)) {
      this.addRetryListener();

      this.renderer.appendChild(optionsWrapper, this.messageContainer);
      optionsWrapper.scrollTop = optionsWrapper.scrollHeight;
    }
  }

  /**
   * Removes the message.
   *
   * @returns void
   */
  protected removeMessage(): void {
    if (this.hasMessageContainer()) {
      this.renderer.removeChild(this.obtainScrollingWrapper(), this.messageContainer);
      this.messageContainer = undefined;
    }
  }

  /**
   *  Adds retry click listener.
   *
   * @returns void
   */
   protected addRetryListener(): void {
    if (this.status === StatusEnum.error) {
      this.renderer.listen(
        this.messageContainer,
        'click',
        () => {
          this.retryEvent.emit();
        }
      );
    }
  }

  /**
   * Listens when the scroll is at the end.
   *
   * @returns void
   */
  protected listenScrollEnding(): void {
    const optionsWrapper = this.obtainScrollingWrapper();
    const scrollEvent = fromEvent(optionsWrapper, 'scroll');

    scrollEvent.subscribe(
      (data: any) => {
        const scrolledHeight: number = data.target.scrollTop + data.target.clientHeight;
        const heightDifference: number = Math.abs(scrolledHeight - data.target.scrollHeight);

        if (heightDifference <= 5) {
          this.scrollEndedEvent.emit();
        }
      }
    );
  }

  /**
   * Obtains the options list wrapper.
   *
   * @returns HTMLElement
   */
  protected obtainScrollingWrapper(): HTMLElement {
    return this.elementRef.nativeElement.querySelector('.ui-dropdown-items-wrapper');
  }
}
