import { FormControl, Validators } from '@angular/forms';

import { Configuration } from '../configuration.model';
import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';


export class Form extends BoltForm {
  protected codeField: FormControl;
  protected configuration: Configuration;
  protected descriptionField: FormControl;
  protected typeField: FormControl;
  protected valueField: FormControl;

  constructor(fieldSpecs: any, configuration?: Configuration) {
    super(fieldSpecs);
    this.configuration = configuration;
    this.setupFields();
  }

  /**
   * Returns the "Code" field.
   *
   * @returns FormControl
   */
  getCodeField(): FormControl {
    return this.codeField;
  }

  /**
   * Returns the "Description" field.
   *
   * @returns FormControl
   */
  getDescriptionField(): FormControl {
    return this.descriptionField;
  }

  /**
   * Returns the "Type" field.
   *
   * @returns FormControl
   */
  getTypeField(): FormControl {
    return this.typeField;
  }

  /**
   * Returns the "Value" field.
   *
   * @returns FormControl
   */
  getValueField(): FormControl {
    return this.valueField;
  }

  toJson(): any {
    const output: any = {
      code: this.codeField.value,
      description: this.descriptionField.value ? this.descriptionField.value : null,
      type: this.typeField.value,
      value: this.valueField.value
    };

    return output;
  }

  protected setupFields(): void {
    this.setupCodeField();
    this.setupDescriptionField();
    this.setupTypeField();
    this.setupValueField();
  }

  /**
   * Set up the "Code" field.
   *
   * @returns void
   */
  protected setupCodeField(): void {
    this.codeField = new FormControl(
      this.configuration?.getCode(),
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.codeMaxLength)
      ])
    );

    this.addControl('code', this.codeField);
  }

  /**
   * Set up the "Description" field.
   *
   * @returns void
   */
  protected setupDescriptionField(): void {
    this.descriptionField = new FormControl(
      this.configuration?.getDescription(),
      Validators.maxLength(this.fieldSpecs.descriptionMaxLength)
    );

    this.addControl('description', this.descriptionField);
  }

  /**
   * Set up the "Type" field.
   *
   * @returns void
   */
  protected setupTypeField(): void {
    this.typeField = new FormControl(this.configuration?.getType().getValue(), Validators.required);
    this.addControl('type', this.typeField);
  }

  /**
   * Set up the "Value" field.
   *
   * @returns void
   */
  protected setupValueField(): void {
    this.valueField = new FormControl(
      this.configuration?.getValue(),
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.valueMaxLength)
      ])
    );

    this.addControl('value', this.valueField);
  }
}
