import { Product } from '../product.model';

import { isArray as _isArray, isUndefined as _isUndefined } from 'lodash';


export class AssociationList {
  protected _products: Product[];
  protected _total: number;

  constructor(data: any) {
    this.initialize(data);
  }

  get products(): Product[] {
    return this._products;
  }

  get total(): number {
    return this._total;
  }

  /**
   * Returns the product associations from the first one to the given limit as a string.
   *
   * @param limit number
   * @param include boolean
   * @returns string
   */
  getProductAssociationAsString(limit: number, includeCount: boolean = true): string {
    let output: string = '';

    if (!this.hasProductAssociations()) {
      return output;
    }

    output = this._products.slice(0, limit).map(
      (product: Product) => product.name
    ).join(', ');

    if (includeCount) {
      output += this.getProductAssociationsNotDisplayed(limit);
    }

    return output;
  }

  /**
   * Returns the product associations from the first one to the given limit as a string.
   *
   * @param limit number
   * @returns string
   */
  getProductAssociationsNotDisplayed(limit: number): string {
    let output: string = '';

    if (!_isUndefined(this._total) && this._total > limit) {
      output += `... +${this._total - limit}`;
    }

    return output;
  }

  /**
   * Indicates if it has product associations.
   *
   * @returns boolean
   */
  hasProductAssociations(): boolean {
    const hasIt: boolean = _isArray(this.products) && (this.products.length > 0);
    return hasIt;
  }

  /**
   * Initializes the product associations list.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    if (_isUndefined(data)) {
      return;
    }

    this._total = data.totalProducts;

    this._products = new Array();

    if (_isArray(data.products) && (data.products.length > 0)) {
      data.products.forEach(
        (productData: any) => {
          const product: Product = new Product(productData);
          this._products.push(product);
        }
      );
    }
  }
}
