import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Account, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { BoltBaseMasterDataHandlerPanelComponent } from '../baseMasterData/handler-panel/base-handler-panel.component';

@Component({
  selector: 'bolt-master-data-dubbing-studio-handler-panel',
  template: require('./dubbing-studio-panel.component.html'),
})
export class BoltMasterDataDubbingStudioHandlerPanelComponent extends BoltBaseMasterDataHandlerPanelComponent {
  @Input() data: any | undefined;
  @Input() show: boolean;
  @Output('cancelled') cancelEvent: EventEmitter<void>;
  @Output('saved') saveEvent: EventEmitter<Account>;

  constructor(
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected appConfig: AppConfigProvider
  ) {
    super(masterDataService, notificationService, appConfig);
    this.cancelEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.show = false;
    this.type = MasterDataType.dubbingStudios;
    this.entityName = 'Dubbing Studios';

    this.setupFieldSpecs();
  }
}
