export enum AttributeEnum {
  account = 'account',
  dubbingCast = 'dubbingCast',
  dubbingCrew = 'dubbingCrew',
  language = 'language',
  localizedSubtitles = 'localizedSubtitles',
  originalCast = 'originalCast',
  originalCrew = 'originalCrew',
  originalSubtitles = 'originalSubtitles',
  territory = 'territory',
  title = 'title'
}
