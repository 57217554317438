import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Localized } from '../item/localized/localized.model';
import { Original } from '../item/original/original.model';


export class Unit {
  protected _localized: Localized;
  protected _original: Original;

  constructor(original: Original, localized?: Localized) {
    this.initialize(original, localized);
  }

  get localized(): Localized {
    return this._localized;
  }

  get original(): Original {
    return this._original;
  }

  /**
   * Indicates if it has a items.
   *
   * @returns boolean
   */
  isComplete(): boolean {
    return _isObject(this.localized);
  }

  /**
   * Reset the localized item.
   *
   * @returns void
   */
  resetLocalized(): void {
    this._localized = undefined;
  }

  /**
   * Set the localized item.
   *
   * @param localized Localized
   * @throws ErrorHelper
   * @returns void
   */
  setLocalized(localized: Localized): void {
    if (_isObject(localized) && (this.original.id === localized.rootId)) {
      this._localized = localized;
    } else {
      throw new ErrorHelper('Invalid localized item given');
    }
  }

  /**
   * Initializes the instance.
   *
   * @param original Original
   * @param localized Localized
   * @returns void
   */
  protected initialize(original: Original, localized: Localized): void {
    this.setOriginal(original);

    if (!_isUndefined(localized)) {
      this.setLocalized(localized);
    }
  }

  /**
   * Set the original item.
   *
   * @param original Original
   * @throws ErrorHelper
   * @returns void
   */
  protected setOriginal(original: Original): void {
    if (_isObject(original)) {
      this._original = original;
    } else {
      throw new ErrorHelper('Invalid original item given');
    }
  }
}
