import { Component, Input } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { CustomErrorTypeEnum } from '@bolt/ui-shared/form';

import { AddingStepForm } from '../../models/wizard/adding-step-form/adding-step-form.model';
import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { WizardService } from '../../services/wizard/wizard.service';
import { CreditPositionType } from '../../models/credit/credit-position.enum';


@Component({
  selector: 'bolt-credits-wizard-adding-step',
  template: require('./bolt-credits-wizard-adding-step.html'),
  styles: [require('./bolt-credits-wizard-adding-step.scss')]
})
export class BoltCreditsWizardAddingStepComponent  {
  @Input() position: CreditPositionType;
  protected form: AddingStepForm;
  protected talentErrors: Map<string, string>;
  protected positionErrors: Map<string, string>;
  protected readonly emptyLineMessage: string = 'Blank lines are not allowed';

  constructor(
    protected appConfigProvider: AppConfigProvider,
    protected formConfig: FormConfigService,
    protected wizardService: WizardService
  ) {
    this.setTalentErrors();
    this.setPositionErrors();
    this.setForm();
  }

  /**
   * Get the position label.
   *
   * @returns string
   */
  protected getPositionLabel(): string {
    return this.hasDisplayCharacterLabels() ? 'Character' : 'Role';
  }

  /**
   * Indicates if it has to display the Character labels.
   *
   * @returns boolean
   */
  protected hasDisplayCharacterLabels(): boolean {
    return this.position === CreditPositionType.CAST;
  }

  /**
   * Indicates if it has to display the error message.
   *
   * @returns boolean
   */
  protected hasDisplaySharedError(): boolean {
    const itHas: boolean = !this.wizardService.hasOnePositionPerTalent();
    return itHas;
  }

  /**
   * Set the current form.
   *
   * @returns void
   */
  protected setForm(): void {
    const limit: number = this.appConfigProvider.get('forms.credits.wizard.fields.bulkLimit');

    const configuration: any = {
      maxLength: limit,
      positionMaxLength: this.position === CreditPositionType.CAST
        ? this.formConfig.get('cat.fields.originalName.maxLength')
        : this.appConfigProvider.get('forms.role.fields.name.maxLength')
    };

    this.form = new AddingStepForm(this.wizardService.getTalentNames(), this.wizardService.getPositionNames(), configuration);
    this.wizardService.setAddingForm(this.form);
  }

  /**
   * Set the position errors.
   *
   * @returns void
   */
  protected setPositionErrors(): void {
    const map: Map<string, string> = new Map();

    map.set(CustomErrorTypeEnum.emptyLine, this.emptyLineMessage);
    map.set(CustomErrorTypeEnum.maxLengthLine, `One name exceed the max length limit`);

    this.positionErrors = map;
  }

  /**
   * Set the positions with the given value.
   *
   * @returns void
   */
  protected setPositions(): void {
    this.wizardService.setPositionNames(this.form.get('positions').value);
  }

  /**
   * Set the talent errors.
   *
   * @returns void
   */
  protected setTalentErrors(): void {
    const map: Map<string, string> = new Map();
    map.set(CustomErrorTypeEnum.emptyLine, this.emptyLineMessage);
    this.talentErrors = map;
  }

  /**
   * Set the talents with the given value.
   *
   * @returns void
   */
  protected setTalents(): void {
    this.wizardService.setTalentNames(this.form.get('talents').value);
  }
}
