import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Localized as LocalizedItem } from '../../models/item/localized/localized.model';
import { MetadataManagerService } from '../../services/item/metadata-manager/metadata-manager.service';
import { TypeEnum } from '../../models/type/type.enum';


@Component({
  selector: 'bolt-cat-item-localized-data',
  template: require('./bolt-cat-item-localized-data.html'),
  styles: [require('./bolt-cat-item-localized-data.scss')]
})
export class BoltCatItemLocalizedDataComponent implements OnInit, OnDestroy {
  protected items: LocalizedItem[];
  protected itemType: TypeEnum;
  protected selectedItem: LocalizedItem;
  protected subscriptions: Subscription;

  constructor(
    protected itemManager: MetadataManagerService
  ) {
    this.initialize();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.listenOriginalItemChanges();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.selectedItem = undefined;
    this.subscriptions = new Subscription();
  }

  /**
   * Listens when the original item changes.
   *
   * @returns void
   */
  protected listenOriginalItemChanges(): void {
    const subs: Subscription = this.itemManager.originalListener.subscribe(
      () => {
        this.items = this.itemManager.original.localizedItems;
        this.itemType = this.itemManager.original.type.value;
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Set the selected item.
   *
   * @param item LocalizedItem
   * @returns void
   */
  protected setSelectedItem(item: LocalizedItem): void {
    this.selectedItem = item;
  }
}
