import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { lowerFirst as _lowerFirst } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { GroupEnum } from '../../models/heartbeat/group.enum';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable()
export class HeartbeatService {
  constructor(
    protected authHttp: AuthHttp,
    protected appConfig: AppConfigProvider,
    protected appRoutes: AppRoutesService
  ) { }

  /**
   * Returns the Host for the given endpoint heartbeat key.
   *
   * @param system any
   * @returns string
   */
  getHost(system: any): string {
    const parts: string[] = system.endpointKey.split('.');
    let host: string;
    let endpoint: string;

    switch (system.group) {
      case GroupEnum.API:
        const api: string = parts[1].replace(/Api$/, '');
        endpoint = `serviceConfig.api.${api}.host`;
      break;
      case GroupEnum.UI:
        const ui: string = _lowerFirst(parts[1].replace(/^ui/, ''));
        endpoint = `serviceConfig.ui.${ui}.host`;
      break;
      default:
        throw new ErrorHelper('Invalid system for heartbeat.');
      break;
    }

    host = this.appConfig.get(endpoint);

    return host;
  }

  /**
   * Returns an observable for pinging the endpoint of the given key.
   *
   * @param system any
   * @returns Observable<StormServiceResponseSingle>
   */
  ping(system: any): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.authHttp.get(
      this.appRoutes.get(system.endpointKey)
    ).pipe(
      map(
        (response: HttpResponse<any>) => new StormServiceResponseSingle(response)
      )
    );

    return obs;
  }
}
