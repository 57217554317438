import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';

import { AttributeEnum } from '../../models/entity/lock/attribute.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Lock } from '../../models/entity/lock/lock.model';
import { Status } from '../../models/entity/lock/status/status.model';
import { VisualOptionEnum } from './visual-option.enum';


@Component({
  selector: 'bolt-project-metadata-icon',
  template: require('./bolt-project-metadata-icon.html'),
  styles: [require('./bolt-project-metadata-icon.scss')]
})
export class BoltProjectMetadataIconComponent implements OnChanges {
  @Input() attribute: AttributeEnum;
  @Input() description: string;
  @Input() error: ErrorHelper;
  @Input() entityName: string;
  @Input() lock: Lock;

  @Output('viewDetails') protected viewDetailsEvent: EventEmitter<[string, Lock, AttributeEnum]>;

  @ViewChild('errorView', { static: true }) protected errorView: TemplateRef<any>;
  @ViewChild('lockingStatusView', { static: true }) protected lockingStatusView: TemplateRef<any>;
  @ViewChild('noDataView', { static: true }) protected noDataView: TemplateRef<any>;
  @ViewChild('notApplicableView', { static: true }) protected notApplicableView: TemplateRef<any>;
  @ViewChild('unknownView', { static: true }) protected unknownView: TemplateRef<any>;

  protected isExceptionMode: boolean;
  protected isLightMode: boolean;
  protected isLocked: boolean;
  protected title: string;
  protected view: TemplateRef<any>;

  constructor(protected appConfigurationManager: AppConfigurationManager) {
    this.attribute = undefined;
    this.description = '';
    this.entityName = undefined;
    this.error = undefined;
    this.lock = undefined;
    this.viewDetailsEvent = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.error) || _isObject(changes.lock) || _isObject(changes.attribute)) {
      this.setupView();
    }

    if (_isObject(changes.description)) {
      this.setupTitle();
    }
  }

  /**
   * Handles the click event and notifies it.
   *
   * @returns void
   */
  protected handleClick(): void {
    this.viewDetailsEvent.emit([ this.entityName, this.lock, this.attribute ]);
  }

  /**
   * Set up the title using the stored description.
   *
   * @returns void
   */
  protected setupTitle(): void {
    this.description = this.description.trim();
    this.title = 'View metadata' + (this.description.length > 0 ? `: ${this.description}` : '');
  }

  /**
   * Sets up the view mode.
   *
   * @returns void
   */
  protected setupView(): void {
    if (_isObject(this.error)) {
      this.view = this[VisualOptionEnum.error];
    } else if (_isObject(this.lock)) {
      const status: Status = this.lock.status.get(this.attribute);

      if (status.isNoData()) {
        this.view = this[VisualOptionEnum.noData];
      } else if (status.isNotApplicable()) {
        this.view = this[VisualOptionEnum.notApplicable];
      } else {
        this.isExceptionMode = status.isExceptionalLocked();
        this.isLightMode = status.isPartialLocked() || status.isPartialUnlocked();
        this.isLocked = status.isLocked() || status.isExceptionalLocked() || status.isPartialLocked();
        this.view = this[VisualOptionEnum.lockingStatus];
      }
    } else {
      this.view = this[VisualOptionEnum.unknown];
    }
  }
}
