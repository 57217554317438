import { Component, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, OnDestroy } from '@angular/core';
import { isObject as _isObject, capitalize as _capitalize } from 'lodash';

import { Episode } from 'app/modules/title/models/episode.model';
import { CreditLocale } from 'app/modules/credits/models/credit/credit-locale.model';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Season } from 'app/modules/title/models/season.model';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';


@Component({
  selector: 'bolt-clone-credits-manager',
  template: require('./bolt-clone-credits-manager.html'),
  styles: [require('./bolt-clone-credits-manager.scss')]
})
export class BoltCloneCreditsManagerComponent implements OnChanges, OnDestroy {
  @Input() creditLocale: CreditLocale;
  @Input() creditPosition: CreditPositionType;
  @Input() creditType: CreditType;
  @Input() show: boolean;
  @Input() title: Episode | Season;
  @Output('closed') closeEvent: EventEmitter<any>;
  @Output('saved') selectEvent: EventEmitter<Episode | Season>;

  @ViewChild('cloneModalRef') modal: ModalDirective;

  protected cloneCandidate: Episode | Season;

  constructor(
    protected layoutHandler: LayoutHandlerService,
  ) {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show)) {
      if (changes.show.currentValue) {
        this.open();
      } else {
        if (!changes.show.firstChange) {
          this.reset();
        }
      }
    }
  }

  ngOnDestroy() {
    this.reset();
  }

  /**
   * Emits the onSelect event.
   *
   * @returns void
   */
  protected clone(): void {
    this.selectEvent.emit(this.cloneCandidate);
    this.close();
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected close(): void {
    this.closeEvent.emit();
    this.modal.hide();
    this.reset();
  }

  /**
   * Indicates if it has to block the ok button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const hasIt: boolean =
      !this.layoutHandler.isReading() ||
      !_isObject(this.cloneCandidate);

    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.selectEvent = new EventEmitter();
    this.show = false;
  }

  /**
   * Indicates if the current title is an episode.
   *
   * @returns boolean
   */
  protected isEpisode(): boolean {
    const isIt: boolean = this.title.isEpisode();
    return isIt;
  }

  /**
   * Opens the modal.
   *
   * @returns void
   */
  protected open(): void {
    this.modal.show();
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.show = false;
  }

  /**
   * Retrieves the modal title for the current title.
   *
   * @returns string
   */
  protected retrieveModalTitle(): string {
    let title: string =
      `Clone ${_capitalize(this.creditType.toString())} ` +
      `${_capitalize(this.creditPosition.toString())} from Season`;

    if (this.title.isEpisode()) {
      title += ' or Episode';
    }

    return title;
  }

  /**
   * Retrieves the warning message for the current title.
   *
   * @returns string
   */
  protected retrieveWarningMessage(): string {
    const message: string =
    `*Cloning will remove any ${_capitalize(this.creditPosition.toString())} ` +
    `linked to this ${this.title.type.toString()}`;

    return message;
  }

  /**
   * Sets the given title as the current selection.
   *
   * @param title Episode | Season
   * @returns void
   */
  protected setSelection(title: Episode | Season): void {
    this.cloneCandidate = title;
  }
}
