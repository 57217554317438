import { FormControl, Validators } from '@angular/forms';
import { isObject as _isObject, isString as _isString } from 'lodash';

import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';


export abstract class CharacterForm extends BoltForm {
  protected notesField: FormControl;
  protected phoneticField: FormControl;
  private key: string;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.key = this.getDefaultValue('key');
  }

  /**
   * Get the notes field.
   *
   * @returns FormControl
   */
  getNotesField(): FormControl {
    return this.notesField;
  }

  /**
   * Get the phonetic field.
   *
   * @returns FormControl
   */
  getPhoneticField(): FormControl {
    return this.phoneticField;
  }

  /**
   * Get the key attribute.
   *
   * @returns string
   */
  getKey(): string {
    return this.key;
  }

  toJson(): any {
    const data: any = {
      notes: this.getNotesField().value,
      phonetic: this.getPhoneticField().value
    };

    return data;
  }

  protected setupFields() {
    this.setupNotesField();
    this.setupPhoneticField();
  }

  /**
   * Set up the notes field.
   *
   * @returns void
   */
   protected setupNotesField(): void {
    this.notesField = new FormControl(
      this.getDefaultValue('notes'),
      Validators.maxLength(this.fieldSpecs.notes.maxLength)
    );

    this.addControl('notes', this.notesField);
  }

  /**
   * Set up the phonetic field.
   *
   * @returns void
   */
   protected setupPhoneticField(): void {
    this.phoneticField = new FormControl(
      this.getDefaultValue('phonetic'),
      Validators.maxLength(this.fieldSpecs.phonetic.maxLength)
    );

    this.addControl('phonetic', this.phoneticField);
  }
}
