import { Mode } from './mode/mode.model';
import { ModeEnum } from './mode/mode.enum';
import { Title } from '../title.model';

import { isUndefined as _isUndefined } from 'lodash';


export class Episode extends Title {
  protected _language: string;
  protected _mode: Mode;
  protected _seasonId: number;
  protected _territory: string;

  constructor(data: any) {
    super(data);
  }

  get mode(): Mode {
    return this._mode;
  }

  get seasonId(): number {
    return this._seasonId;
  }

  /**
   * Returns the language.
   *
   * @returns string
   */
  getLanguage(): string {
    return this._language;
  }

  /**
   * Returns the territory.
   *
   * @returns string
   */
  getTerritory(): string {
    return this._territory;
  }

  isEpisode(): boolean {
    return true;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    super.initialize(data);
    this.populateMode();
    this.populateSeasonId();
  }

  /**
   * Populates the mode.
   *
   * @returns void
   */
  protected populateMode(): void {
    const locale: string[] = this.locale.split('_');
    let mode: ModeEnum;

    if (locale[0] === '*') {
      mode = ModeEnum.TERRITORY;

      this._language = undefined;
      this._territory = locale[1];
    } else {
      mode = ModeEnum.LANGUAGE;

      this._language = locale[0];
      this._territory = undefined;
    }

    this._mode = new Mode(mode);
  }

  /**
   * Populates the season ID.
   *
   * @returns void
   */
  protected populateSeasonId(): void {
    const field = this.getField('seasonId');

    if (!_isUndefined(field)) {
      this._seasonId = field.currentValue;
    }
  }
}
