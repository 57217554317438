import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import * as _ from 'lodash';

import { LocalizationSpecific } from '../../models/localization-specific.enum';
import { ProductLayoutHelper } from '../../helpers/product-layout/product-layout.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TitleMetadata } from '../../models/title-metadata.model';
import { Title } from '../../models/title.model';
import { TitleService } from '../../services/title.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Component({
  selector: 'bolt-title-metadata-original-version-language',
  template: require('./bolt-title-metadata-original-version-language.html'),
  styles: [require('./bolt-title-metadata-original-version-language.scss')]
})
export class BoltTitleMetadataOriginalVersionLanguageComponent extends StormComponent implements OnInit {
  @Input()
  protected title: Title;

  protected computedMetadata: any;
  protected fieldAttributes: any;
  protected fieldNames: string[];

  constructor(
    protected productLayoutHelper: ProductLayoutHelper,
    protected titleService: TitleService,
    protected notificationService: NotificationService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.loadFieldsMetadata();
    this.fetchComputedMetadata();
  }

  /**
   * Fetches the computed data for locale.
   *
   * @returns void
   */
  protected fetchComputedMetadata(): void {
    this.changeStatusToFetchingData();

    const metadada: TitleMetadata = new TitleMetadata({
      locale: this.getFixedOriginalLocale(),
      rootId: this.title.id
    });

    this.titleService.fetchComputedProductMetadata(
      {
        productType: this.title.type,
        productId: metadada.rootId,
        locale: metadada.locale
      },
      metadada
    ).subscribe(
      (response: StormServiceResponseSingle) => {
        this.computedMetadata = response.item;
        this.changeStatusToDataFound();
      },
      (error: any) => {
        this.changeStatusToError();
        this.notificationService.handleError(error);
      }
    );
  }

  /**
   * Returns the fixed original locale.
   *
   * @returns string
   */
  protected getFixedOriginalLocale(): string {
    return this.fieldAttributes['defaultSelectedLocale'];
  }

  /**
   * Indicates if it has the computed metadata for the title.
   *
   * @returns boolean
   */
  protected hasComputedMetadata(): boolean {
    return _.isObject(this.computedMetadata);
  }

  /**
   * Indicates if it has to display the keywords.
   *
   * @returns boolean
   */
  protected hasDisplayKeywords(): boolean {
    return !this.computedMetadata.isSeries();
  }

  /**
   * Indicates if it has to display the short title.
   *
   * @returns boolean
   */
  protected hasDisplayShortTitle(): boolean {
    return this.computedMetadata.isFeature();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.computedMetadata = undefined;
    this.fieldNames = undefined;
    this.title = undefined;

    this.changeStatusToIdle();
  }

  /**
   * Loads the fields metadata.
   *
   * @returns void
   */
  protected loadFieldsMetadata(): void {
    this.fieldNames = this.productLayoutHelper.getProductLayoutAttributeNames(this.title.type).metadata;

    this.fieldAttributes = this.productLayoutHelper.getProductLayoutAttributeGroup(
      this.title.type,
      LocalizationSpecific.LANGUAGE.toString()
    );
  }
}
