import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';

import {
  BoltPageProfileImportExportComponent
} from './components/bolt-page-profile-import-export/bolt-page-profile-import-export.component';

import { modulesPath } from '../auth/services/role/modules-path';


export const profileRoutes: Routes = [
  {
    path: 'profile-import-export',
    component: BoltPageProfileImportExportComponent,
    canActivate: [AuthGuard],
    data: {
      boltAuthAware: modulesPath.menu.pie.path,
      title: 'Profile Import Export',
    },
  },
];

export const profileModuleRouting: ModuleWithProviders = RouterModule.forChild(profileRoutes);
