import { isNumber as _isNumber, isObject as _isObject, isString as _isString } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class FunctionalMetadata {
  protected _id: number;
  protected _name: string;
  protected _description: string;

  constructor(data: any) {
    this.initialize(data);
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get description(): string {
    return this._description;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @throws ErrorHelper when the given data is invalid.
   * @returns void
   */
  protected initialize(data: any): void {
    this.setId(data);
    this.setName(data);
    this.setDescription(data);
  }

  /**
   * Set the ID with the given data.
   *
   * @param data any
   * @throws ErrorHelper if the given data has an invalid ID.
   * @returns void
   */
  protected setId(data: any): void {
    if (_isObject(data) && _isNumber((data as any).id)) {
      this._id = (data as any).id;
    } else {
      throw new ErrorHelper('Invalid ID given.');
    }
  }

  /**
   * Set the name with the given data.
   *
   * @param data any
   * @throws ErrorHelper if the given data has an invalid name.
   * @returns void
   */
  protected setName(data: any): void {
    if (_isObject(data) && _isString((data as any).name)) {
      this._name = (data as any).name.trim();
    } else {
      throw new ErrorHelper('Invalid name given.');
    }
  }

  /**
   * Set the description with the given data.
   *
   * @param data any
   * @throws ErrorHelper if the given data has an invalid name.
   * @returns void
   */
  protected setDescription(data: any): void {
    if (_isObject(data) && _isString((data as any).description)) {
      this._description = (data as any).description.trim();
    } else {
      throw new ErrorHelper('Invalid description given.');
    }
  }
}
