import { Params } from '@angular/router';
import { SearchCriteria } from '@bolt/ui-shared/searching';

import { SearchTypeEnum } from '../search-type/search-type.enum';
import { SearchType } from '../search-type/search-type.model';


export class CastCrewSearchCriteria extends SearchCriteria {
  protected searchType: SearchType;
  protected locales: string[];

  constructor() {
    super();
    this.reset();
  }

  /**
   * Returns the itself formatted as endpoint expects.
   *
   * @returns any
   */
  asEndpointData(): any {
    const params: any = super.asEndpointData();

    params.searchType = this.searchType.toString();

    if (this.locales && this.locales.length > 0) {
      params.locales = this.getLocalesAsString();
    }

    return params;
  }

  /**
   * Returns the itself formatted as URL expects.
   *
   * @returns any
   */
  asUrlOutput(): any {
    const params: any = super.asUrlOutput();

    params.searchType = this.searchType.toString();

    if (this.locales && this.locales.length > 0) {
      params.locales = this.getLocalesAsString();
    }

    return params;
  }

  /**
   * Returns the locales
   *
   * @returns string[]
   */
  getLocales(): string[] {
    return this.locales;
  }

  /**
   * Get the locales array as a string separated by pipes
   */
  getLocalesAsString(): string {
    return this.locales.join('|');
  }

  /**
   * Returns the search type
   *
   * @returns SearchType
   */
  getSearchType(): SearchType {
    return this.searchType;
  }

  /**
   * Reads the given URL parameters and stores the options.
   *
   * @param params Params
   * @throws ErrorHelper if the given parameters are invalid.
   * @returns void
   */
  readUrlParams(params: Params): void {
    super.readUrlParams(params);
    this.setSearchType(params.searchType);
    this.setLocales(params.locales);
  }

  /**
   * Reset the stored data.
   *
   * @returns void
   */
  reset(): void {
    super.reset();
    this.setSearchType(new SearchType(SearchTypeEnum.All));
    this.setLocales(undefined);
  }

  /**
   * Set the locale list.
   *
   * @param query string[]
   * @returns void
   */
  setLocales(locales: string[]): void {
    this.locales = locales;
  }

  /**
   * Set the search type.
   *
   * @param query SearchType
   * @returns void
   */
  setSearchType(type: SearchType): void {
    this.searchType = type;
  }
}
