import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartPluginsOptions, ChartScales } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

import { Counters } from '../../models/event/summary/counters/counters.model';
import { Status as EventStatus } from '../../models/event/status/status.model';


@Component({
  selector: 'bolt-cpm-events-summary-counter',
  template: require('./bolt-cpm-events-summary-counter.html'),
  styles: [require('./bolt-cpm-events-summary-counter.scss')]
})
export class BoltCpmEventsSummaryCountersComponent implements OnChanges {
  @Input() counters: Counters;
  @Input() title: string;

  protected datasets: ChartDataSets[];
  protected labels: string[];
  protected options: ChartOptions;
  protected plugins: any[];

  constructor() {
    this.plugins = [pluginDataLabels];

    this.setupLabels();
    this.setupOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.counters) {
      this.updateDatasets();
    }
  }

  /**
   * Sets up the labels for the chart.
   *
   * @returns void
   */
  protected setupLabels(): void {
    this.labels = EventStatus.asList().map(
      (status: EventStatus) => status.getName()
    );
  }

  /**
   * Sets up the options for the chart.
   *
   * @returns void
   */
  protected setupOptions(): void {
    const plugins: ChartPluginsOptions = {
      datalabels: {
        align: 'end',
        anchor: 'end',
        font: {
          lineHeight: 0,
          weight: 600
        }
      }
    };

    const scales: ChartScales = {
      xAxes: [{
        gridLines: {
          drawOnChartArea: false
        }
      }],
      yAxes: [{
        ticks: {
          min: 0
        }
      }]
    };

    this.options = {
      layout: {
        padding: {
          top: 20
        }
      },
      legend: {
        display: false
      },
      plugins: plugins,
      responsive: false,
      scales: scales,
      tooltips: {
        enabled: false
      }
    };
  }

  /**
   * Sets up the data for the chart.
   *
   * @returns void
   */
  protected updateDatasets(): void {
    // ChartJs doesn't allow us to use SCSS classes.
    const colors: string[] = ['#36a2eb', '#ffce56', '#41d691', '#ff6384'];

    this.datasets = [{
      backgroundColor: colors,
      barPercentage: 0.7,
      hoverBackgroundColor: colors,
      data: [
        this.counters.getHalted(),
        this.counters.getInProgress(),
        this.counters.getCompleted(),
        this.counters.getError()
      ]
    }];
  }
}
