import { includes as _includes } from 'lodash';

import { TypeEnum } from './type.enum';


export class Type {
  protected _value: TypeEnum;

  constructor(value: TypeEnum) {
    const normalizedValue: string = String(value).trim().toLocaleLowerCase();

    if (_includes(TypeEnum, normalizedValue)) {
      this._value = <TypeEnum>normalizedValue;
    } else {
      throw new Error('Invalid value given for a type.');
    }
  }

  get value(): TypeEnum {
    return this._value;
  }

  /**
   * Indicates if it is an "Episode".
   *
   * @returns boolean
   */
  isEpisode(): boolean {
    return this.is(TypeEnum.Episode);
  }

  /**
   * Indicates if it is equals to the given Type.
   *
   * @param otherType Type
   * @returns boolean
   */
  isEqualsTo(otherType: Type): boolean {
    return this.is(otherType.value);
  }

  /**
   * Indicates if it is a "Feature".
   *
   * @returns boolean
   */
  isFeature(): boolean {
    return this.is(TypeEnum.Feature);
  }

  /**
   * Indicates if it is a "Season".
   *
   * @returns boolean
   */
  isSeason(): boolean {
    return this.is(TypeEnum.Season);
  }

  /**
   * Indicates if it is a "Series".
   *
   * @returns boolean
   */
  isSeries(): boolean {
    return this.is(TypeEnum.Series);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this.value.toString();
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value TypeEnum
   * @returns boolean
   */
  protected is(value: TypeEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
