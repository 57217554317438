import { isString as _isString } from 'lodash';

import { Connection } from '../connection.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class CheckConnection extends Connection {
  protected baseUrl: string;

  constructor(data: any) {
    super(data);
    this.setBaseUrl(data.connectionBaseUrl);
  }

  /**
   * Gets the base connection url.
   *
   * @returns string
   */
   getBaseUrl(): string {
    return this.baseUrl;
  }

  /**
   * Indicates if it is a Check connection.
   *
   * @returns boolean
   */
  isCheck(): boolean {
    return true;
  }

  /**
   * Sets the base connection url.
   *
   * @param baseUrl string
   * @throws ErrorHelper
   * @returns void
   */
  protected setBaseUrl(baseUrl: string): void {
    if (_isString(baseUrl)) {
      this.baseUrl = baseUrl.trim();
    } else {
      throw new ErrorHelper('Invalid base url given for a CPM Health check connection.');
    }
  }
}
