/**
 * @see `TitleAttributes`: Common attributes for all title types.
 * @see `Episode`: Ensure that class and this enumerative are synchronized about public attributes.
 */
export enum EpisodeAttributes {
  displayDubTalent = 'displayDubTalent',
  displayDubTalentOverride = 'displayDubTalentOverride',
  episodeNumber = 'episodeNumber',
  excludeKidsMode = 'excludeKidsMode',
  excludeKidsModeOverride = 'excludeKidsModeOverride',
  f0_numb = 'f0_numb',
  firstAirReleaseDate = 'firstAirReleaseDate',
  functionalMetadata = 'functionalMetadata',
  graphicContentDisclaimer = 'graphicContentDisclaimer',
  graphicContentDisclaimerOverride = 'graphicContentDisclaimerOverride',
  heritageDisclaimer = 'heritageDisclaimer',
  heritageDisclaimerOverride = 'heritageDisclaimerOverride',
  homeEntRatingId = 'homeEntRatingId',
  homeEntRatingSystemReasonId = 'homeEntRatingSystemReasonId',
  homeEntRatingReason = 'homeEntRatingReason',
  includeKidsMode = 'includeKidsMode',
  includeKidsModeOverride = 'includeKidsModeOverride',
  keywords = 'keywords',
  localAirRunningOrder = 'localAirRunningOrder',
  notApplicable = 'notApplicable',
  onairDateKcc = 'onairDateKcc',
  onairPlatformKcc = 'onairPlatformKcc',
  originalAirRunningOrder = 'originalAirRunningOrder',
  productionNumber = 'productionNumber',
  productPlacementDisclaimer = 'productPlacementDisclaimer',
  productPlacementDisclaimerOverride = 'productPlacementDisclaimerOverride',
  pseDisclaimer = 'pseDisclaimer',
  pseDisclaimerOverride = 'pseDisclaimerOverride',
  ratingFilingNumberKmrb = 'ratingFilingNumberKmrb',
  ratingId = 'ratingId',
  ratingReason = 'ratingReason',
  ratingSystemReasonId = 'ratingSystemReasonId',
  ratingType = 'ratingType',
  runtimeSeconds = 'runtimeSeconds',
  seasonId = 'seasonId',
  smokingDisclaimer = 'smokingDisclaimer',
  smokingDisclaimerOverride = 'smokingDisclaimerOverride',
}
