export class SearchPage {
  protected _currentElements: number;
  protected _first: boolean;
  protected _index: number;
  protected _last: boolean;
  protected _pageSize: number;
  protected _totalElements: number;
  protected _totalPages: number;


  constructor(data: any) {
    if (data) {
      if (data.hasOwnProperty('first')) {
        this._first = data.first;
      }

      if (data.hasOwnProperty('numberOfElements')) {
        this._currentElements = data.numberOfElements;
      }

      if (data.hasOwnProperty('last')) {
        this._last = data.last;
      }

      if (data.hasOwnProperty('number')) {
        this._index = data.number;
      }

      if (data.hasOwnProperty('size')) {
        this._pageSize = data.size;
      }

      if (data.hasOwnProperty('totalElements')) {
        this._totalElements = data.totalElements;
      }

      if (data.hasOwnProperty('totalPages')) {
        this._totalPages = data.totalPages;
      }
    }
  }

  get currentElements(): number {
    return this._currentElements;
  }

  get currentIndex(): number {
    return this._index;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  get totalElements(): number {
    return this._totalElements;
  }

  get totalPages(): number {
    return this._totalPages;
  }

  /**
   * Indicates if the current page is the first one.
   *
   * @returns boolean
   */
  isFirstPage(): boolean {
    return this._first;
  }

  /**
   * Indicates if the current page is the last one.
   *
   * @returns boolean
   */
  isLastPage(): boolean {
    return this._last;
  }
}
