import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, catchError } from 'rxjs/operators';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { Group } from 'app/modules/group/models/group.model';
import { RequestControlEnum } from '../../models/request-control/request-control.enum';
import { TypeEnum } from '../../models/entity-type/type.enum';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


@Injectable()
export class AccessControlService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetches the entity allowed for the given data.
   *
   * @param requestControl RequestControlEnum
   * @param entityType EntityTypeEnum
   * @param entityId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetchEntityAllowed(
    requestControl: RequestControlEnum,
    entityType: TypeEnum,
    entityId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.fetchEntityAllowedAsObservable(requestControl, entityType, entityId).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (response: boolean) => {
        onSuccessDo(response);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      }
    );

    return subs;
  }

  /**
   * Fetches the entity allowed for the given data.
   *
   * @param requestControl RequestControlEnum
   * @param entityType TypeEnum
   * @param entityId number
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchEntityAllowedAsObservable(
    requestControl: RequestControlEnum,
    entityType: TypeEnum,
    entityId: number
  ): Observable<boolean> {
    const params: any = {
      '{requestControl}': requestControl.toLowerCase(),
      '{entityType}': entityType,
      '{entityId}': entityId
    };

    const url: string = this.generateUrl(`user.accessControlService.fetchEntityAllowed.endpoint`, params);

    const obs: Observable<boolean> = this.authHttp.get(url).pipe(
      map(
        (response: any) => response.allowed
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }

  /**
   * Fetches the entity groups for the given data.
   *
   * @param requestControl RequestControlEnum
   * @param entityType TypeEnum
   * @param entityId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetchEntityGroups(
    requestControl: RequestControlEnum,
    entityType: TypeEnum,
    entityId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.fetchEntityGroupsAsObservable(requestControl, entityType, entityId).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (response: Group[]) => {
        onSuccessDo(response);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      }
    );

    return subs;
  }

  /**
   * Fetches the entity groups for the given data.
   *
   * @param requestControl RequestControlEnum
   * @param entityType TypeEnum
   * @param entityId number
   * @returns Observable<Group[]>
   */
  fetchEntityGroupsAsObservable(
    requestControl: RequestControlEnum,
    entityType: TypeEnum,
    entityId: number
  ): Observable<Group[]> {
    const params: any = {
      '{requestControl}': requestControl.toLowerCase(),
      '{entityType}': entityType,
      '{entityId}': entityId
    };

    const url: string = this.generateUrl(`user.accessControlService.fetchEntityGroups.endpoint`,  params);

    const obs: Observable<Group[]> = this.authHttp.get(url).pipe(
      map(
        (response: any) => {
          const mappedResponse: StormServiceResponseSingle = this.defaultMapOn(response);
          const groups: Group[] = new Array();

          if (mappedResponse.item.isEntityInProtectedGroups) {
            mappedResponse.item.groups.forEach(
              (rawGroup: any) => {
                groups.push(new Group(rawGroup.name, rawGroup.id, rawGroup.control));
              }
            );
          }

          return groups;
        }
      ),
      catchError(
        (error: any) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }
}
