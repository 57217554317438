import { Injectable } from '@angular/core';
import { Account, Language, ProductType } from '@bolt/ui-shared/master-data';
import { get as _get, isEmpty, sortBy } from 'lodash';

import { Title } from '../models/title.model';
import { BoltRatingEnum } from '../components/bolt-rating-view/bolt-rating.enum';
import { BoltSimpleRating } from '../components/bolt-rating-view/bolt-rating.class';


@Injectable({
  providedIn: 'root',
})
export class BoltRatingService {
  ratingReasonHeaders = [
    { name: 'Source', property: 'source' },
    { name: 'Descriptor', property: 'description' },
  ];
  ratingHeaders = [
    { name: 'Source', property: 'description' },
    { name: 'Rating', property: 'name' },
    { name: 'System', property: 'ratingSystemId' },
  ];

  constructor() { }

  getRatingsInfo(localization: Title) {
    return {
      ratings: this.getRatings(localization),
      ratingReason: this.getRatingReason(localization),
      additionalRatingReason: this.getAdditionalRatings(localization),
      ratingReasonHeaders: this.ratingReasonHeaders,
      ratingHeaders: this.ratingHeaders
    };
  }

  getTheatricalSource(localization: Title): string {
    return localization.isEpisode() ? 'TV' : 'Theatrical / TV';
  }

  getRatings(localization: Title): object[] {
    const ratings = [];

    if (!isEmpty(localization['ratingId'])) {
      ratings.push(...localization['ratingId'].map(rating => ({
        name: rating?.name,
        description: this.getTheatricalSource(localization),
        ratingSystemId: rating?.ratingSystemId,
        order: 1,
      })));
    }
    // Home Entertainment
    if (!isEmpty(localization['_homeEntRatingId'])) {
      ratings.push(...localization['_homeEntRatingId'].map(rating => ({
        name: rating?.name,
        description: BoltRatingEnum.HOME_ENTERTAINMENT,
        ratingSystemId: rating?.ratingSystemId,
        order: 2,
      })));
    }

    return sortBy(ratings, 'order');
  }

  getAdditionalRatings(localization: Title): BoltSimpleRating[] {
    const allAdditionalRatings = [];
    const theatrical = _get(localization, 'ratingSystemReasonId', []).map((reason) => {
      return reason?.name;
    });
    const homeEntertainment = _get(localization, 'homeEntRatingSystemReasonId', []).map((reason) => {
      return reason?.name;
    });

    // Order: First Theatrical, then Home Entertainment
    if (theatrical.length) {
      allAdditionalRatings.push(
        new BoltSimpleRating({
          source: this.getTheatricalSource(localization),
          description: theatrical.join(', '),
          order: 1,
        })
      );
    }
    if (homeEntertainment.length) {
      allAdditionalRatings.push(
        new BoltSimpleRating({
          source: BoltRatingEnum.HOME_ENTERTAINMENT,
          description: homeEntertainment.join(', '),
          order: 2,
        })
      );
    }

    return sortBy(allAdditionalRatings, 'order');
  }

  getRatingReason(localization: Title): BoltSimpleRating[] {
    const ratingReason = [];
    // Order: First Theatrical, then Home Entertainment
    if (localization['_ratingReason']) {
      ratingReason.push(new BoltSimpleRating({
        source: this.getTheatricalSource(localization),
        description: localization['_ratingReason'],
        order: 1,
      }));
    }
    if (localization['_homeEntRatingReason']) {
      ratingReason.push(new BoltSimpleRating({
        source: BoltRatingEnum.HOME_ENTERTAINMENT,
        description: localization['_homeEntRatingReason'],
        order: 2,
      }));
    }
    return sortBy(ratingReason, 'order');
  }

  /**
   * Checks if the localization have language, product type and account with an 'ALL' value
   *
   * @param localization Title
   * @returns boolean
   */
  isLanguageProductTypeAndAccountAll(localization: Title): boolean {
    const hasIt = {
      isLanguageAll: Language.isAll(_get(localization, 'language.code', '')),
      isProductTypeAll: ProductType.isAll(_get(localization, 'productType.0.code', '')),
      isAccountAll: Account.isAll(_get(localization, 'account.0.code', '')),
    };

    return hasIt.isLanguageAll && hasIt.isProductTypeAll && hasIt.isAccountAll;
  }

  /**
   * Checks if a given title localization is from EMEA region
   *
   * @param localization Title
   * @returns boolean
   */
  isEmea(localization: Title): boolean {
    const hasIt = _get(localization, 'territory.0.region', '') === 'EMEA';
    return hasIt;
  }
}
