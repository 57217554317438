import { Directive, HostListener, ElementRef, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';
import { kebabCase as _kebabCase, isObject as _isObject } from 'lodash';

@Directive({ selector: 'p-dropdown[boltDataHeaders]' })
export class BoltDropdownDataHeadersDirective implements OnChanges {
  @Input('boltDataHeaders') headers: string[];

  private readonly headerClass: string = 'bolt-ui-dropdown-items-headers';

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.headers) {
      this.renderHeaders();
    }
  }

  @HostListener('onShow')
  onShow(): void {
    this.renderHeaders();
  }

  /**
   * Renders the headers for the dropdown.
   *
   * @returns void
   */
  protected renderHeaders(): void {
    const itemsWrapper: HTMLElement = this.elementRef.nativeElement.querySelector('.ui-dropdown-items-wrapper .ui-dropdown-items');

    if (_isObject(itemsWrapper)) {
      const previousHeaderWrapper: HTMLElement = this.elementRef.nativeElement.querySelector(`.${this.headerClass}`);

      if (previousHeaderWrapper) {
        this.renderer.removeChild(itemsWrapper, previousHeaderWrapper);
      }

      if (this.headers.length > 0) {
        const headersWrapper: HTMLElement = this.renderer.createElement('li');

        this.headers.forEach(
          (value: string) => {
            const header = this.renderer.createElement('span');

            this.renderer.addClass(header, 'field');
            this.renderer.addClass(header, _kebabCase(value));
            this.renderer.appendChild(header, this.renderer.createText(value));
            this.renderer.appendChild(headersWrapper, header);
          }
        );

        this.renderer.addClass(headersWrapper, this.headerClass);
        this.renderer.insertBefore(itemsWrapper, headersWrapper, itemsWrapper.firstChild);

        this.renderer.listen(
          headersWrapper,
          'click',
          (event: Event) => {
            event.stopPropagation();
          }
        );
      }
    }
  }
}
