import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { startCase as _startCase } from 'lodash';

import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { TitleServiceFetchTitlesQueryParams } from '../../services/title.service';
import { TitleType } from '../../models/title.model';


@Component({
  selector: 'storm-title-list-filters',
  template: require('./storm-title-list-filters.html'),
  styles: [require('./storm-title-list-filters.scss')]
})
export class StormTitleListFiltersComponent implements OnInit {
  public form: FormGroup;
  public titleTypes: SelectionItem[] = [];
  public titleValue: string = '';
  public typeValue: string = '';

  @Input('stormTitleListFiltersSearchStatus') searchStatus: DataStatusEnum;
  @Output('stormTitleListFiltersFiltersUpdated')

  filtersUpdated: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();
  protected componentStatuses = DataStatusEnum;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formBuilder: FormBuilder,
    protected activatedRoute: ActivatedRoute
  ) {
    this.form = formBuilder.group({
      q: [''],
      type: ['']
    });
  }

  ngOnInit() {
    this.setTitleTypes();
    this.filtersUpdated.emit(this.form);

    this.activatedRoute
      .params
      .subscribe((params: Params) => {

        this.titleValue = params[TitleServiceFetchTitlesQueryParams.q.toString()] || '';
        this.typeValue = params[TitleServiceFetchTitlesQueryParams.type.toString()];

        const formControlQuery = (<FormControl>this.form.get('q'));
        if (!formControlQuery.value.length) {
          formControlQuery.setValue(decodeURI(this.titleValue), { emitEvent: false });
        }

        (<FormControl>this.form.get('type')).setValue(this.typeValue, { emitEvent: false });

      });
  }

  /**
   * Filters by title type.
   *
   * @param event any
   * @returns void
   */
  protected filterByType(event: any): void {
    this.form.get('type').setValue(event.value);
  }

  /**
   * Returns the scrollHeight from AppConfig.
   *
   * @returns string
   */
  protected getScrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Set the title types (from the TitleType model),
   * used later to populate the Filter By Type dropdown
   */
  protected setTitleTypes(): void {
    for (const type in TitleType) {
      if (TitleType.hasOwnProperty(type)) {
        this.titleTypes.push(new SelectionItem(_startCase(type), type));
      }
    }
  }
}
