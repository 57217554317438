import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Status } from '../status.model';


export class Details {
  protected _locale: string;
  protected _titleId: number;
  protected _legalTitle: string;
  protected _filename: string;
  protected _downloadUrl: string;
  protected _titleType: string;
  protected _exportType: string;
  protected _jobStatus: Status;
  protected _titleSpreadSheetId: number;

  constructor(data: any) {
    this.initialize(data);
  }

  get locale(): string {
    return this._locale;
  }

  get titleId(): number {
    return this._titleId;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  get filename(): string {
    return this._filename;
  }

  get downloadUrl(): string {
    return this._downloadUrl;
  }

  get titleType(): string {
    return this._titleType;
  }

  get exportType(): string {
    return this._exportType;
  }

  get jobStatus(): Status {
    return this._jobStatus;
  }

  get titleSpreadSheetId(): number {
    return this._titleSpreadSheetId;
  }

  /**
   * Updates the job status.
   *
   * @param jobStatus Status
   * @returns void
   */
  updateJobStatus(jobStatus: Status): void {
    this._jobStatus = jobStatus;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    if (data && data.hasOwnProperty('jobStatus') && data.hasOwnProperty('titleSpreadSheetId')) {
      this._titleSpreadSheetId = data.titleSpreadSheetId;
      this._locale = (data.hasOwnProperty('locale') ? data.locale : undefined);
      this._titleId = (data.hasOwnProperty('titleId') ? data.titleId : undefined);
      this._legalTitle = (data.hasOwnProperty('legalTitle') ? data.legalTitle : undefined);
      this._filename = (data.hasOwnProperty('filename') ? data.filename : undefined);
      this._downloadUrl = (data.hasOwnProperty('downloadUrl') ? data.downloadUrl : undefined);
      this._titleType = (data.hasOwnProperty('titleType') ? data.titleType : undefined);
      this._exportType = (data.hasOwnProperty('exportType') ? data.exportType : undefined);

      this.updateJobStatus(new Status(data));
    } else {
      throw new ErrorHelper('Cannot get needed fields for a subtitle export status details.');
    }
  }
}
