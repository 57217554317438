import { Injectable } from '@angular/core';
import { ProximityItem, SelectionItem } from '@bolt/ui-shared/droplists';
import { NotificationService } from '@bolt/ui-shared/notification';
import {
  Account,
  Company,
  Country,
  Language,
  ProductType,
  StormListInterface,
  StormListItem,
  StormListType
} from '@bolt/ui-shared/master-data';
import { reject as _reject } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormListsProvider } from '../storm-lists.provider';


@Injectable({
  providedIn: 'root',
})
export class ListLayoutProvider {
  protected _accounts: any[];
  protected _genres: any[];
  protected _languages: any[];
  protected _productionCompanies: any[];
  protected _productTypes: any[];
  protected _territories: any[];

  constructor(
    protected listsProvider: StormListsProvider,
    protected notificationService: NotificationService
  ) {
    this.initialize();
  }

  /**
   * Get the accounts, remove the option all if excludeOptionAll is true.
   *
   * @param excludeOptionAll boolean
   * @returns any[]
   */
  getAccounts(excludeOptionAll: boolean = false): any[] {
    if (excludeOptionAll) {
      const allAccount: any = { value: Account.ALL_ID};
      return this.removeItem(this._accounts, allAccount);
    }
    return this._accounts;
  }

  /**
   * Returns the account for the given locale
   *
   * @param locale string
   * @returns Account
   */
  getAccountByLocale(locale: string): Account {
    const localeAccount = locale.split('_')[Account.LOCALE_POSITION];

    const target: SelectionItem = this.getAccounts().find(
      (productType: SelectionItem) => productType.source.code === localeAccount
    );

    return target.source;
  }

  /**
   * Get the languages, remove the option all if excludeOptionAll is true.
   *
   * @param excludeOptionAll boolean
   * @returns any[]
   */
  getLanguages(excludeOptionAll: boolean = false): any[] {
    if (excludeOptionAll) {
      const allLanguage: any = { value: Language.ALL_ID};
      return this.removeItem(this._languages, allLanguage);
    }

    return this._languages;
  }

  /**
   * Get the companies.
   *
   * @returns any[]
   */
  getProductionCompanies(): any[] {
    return this._productionCompanies;
  }

  /**
   * Get the product types, remove the option all if excludeOptionAll is true.
   *
   * @param excludeOptionAll boolean
   * @returns any[]
   */
  getProductTypes(excludeOptionAll: boolean = false): any[] {
    if (excludeOptionAll) {
      const allProductType: any = { value: ProductType.ALL_ID};
      return this.removeItem(this._productTypes, allProductType);
    }

    return this._productTypes;
  }

  /**
   * Get the territories, remove the option all if excludeOptionAll is true.
   *
   * @param excludeOptionAll boolean
   * @returns any[]
   */
  getTerritories(excludeOptionAll: boolean = false): any[] {
    if (excludeOptionAll) {
      const allTerritory: any = { value: Country.ALL_ID};
      return this.removeItem(this._territories, allTerritory);
    }

    return this._territories;
  }

  /**
   * Get the genres
   *
   * @returns any[]
   */
  getGenres(): any[] {
    return this._genres;
  }

  /**
   * Returns the language for the given ID.
   *
   * @param id number
   * @throws ErrorHelper
   * @returns Language
   */
  getLanguageById(id: number): Language {
    const targets: SelectionItem[] = this.getLanguages().filter(
      (item: SelectionItem) => {
        const foundIt = (item.source.id === id);
        return foundIt;
      }
    );

    if (targets.length > 0) {
      const language: Language = targets[0].source;
      return language;
    } else {
      throw new ErrorHelper('Invalid ID given for retrieving a language.');
    }
  }

  /**
   * Returns the language for the given locale.
   *
   * @param locale string
   * @returns Language
   */
  getLanguageByLocale(locale: string): Language {
    const localeLanguage = locale.split('_')[Language.LOCALE_POSITION];

    const target: SelectionItem = this.getLanguages().find(
      (language: SelectionItem) => language.source.localeLanguage === localeLanguage
    );

    return target.source;
  }

  /**
   * Returns the product type for the given locale
   *
   * @param locale string
   * @returns ProductType
   */
  getProductTypeByLocale(locale: string): ProductType {
    const localeProductType = locale.split('_')[ProductType.LOCALE_POSITION];

    const target: SelectionItem = this.getProductTypes().find(
      (productType: SelectionItem) => productType.source.code === localeProductType
    );

    return target.source;
  }

  /**
   * Returns the company for the given ID.
   *
   * @param id number
   * @throws ErrorHelper
   * @returns Company
   */
  getProductionCompanyById(id: number): Company {
    const targets: ProximityItem[] = this.getProductionCompanies().filter(
      (item: ProximityItem) => {
        const foundIt = (item.data.id === id);
        return foundIt;
      }
    );

    if (targets.length > 0) {
      const company: Company = targets[0].data.value;
      return company;
    } else {
      throw new ErrorHelper('Invalid ID given for retrieving a company.');
    }
  }

  /**
   * Returns the companies for the given IDS.
   *
   * @param ids number[]
   * @throws ErrorHelper
   * @returns string[]
   */
  getProductionCompanyByIds(ids: number[]): string[] {
    const targets = this.getProductionCompanies().filter(
      (item: ProximityItem) => ids.includes(item.data.value.id)
    ).map(
      (item: ProximityItem): string => item.data.value.name
    );

    if (targets.length > 0) {
      return targets;
    } else {
      throw new ErrorHelper('Invalid IDs given for retrieving a companies.');
    }
  }

  /**
   * Returns the territory for the given code.
   *
   * @param code string
   * @throws ErrorHelper
   * @returns Country
   */
  getTerritoryByCode(code: string): Country {
    const target: SelectionItem = this.getTerritories().find(
      (item: SelectionItem) => item.source.iso31661 === code
    );

    if (target) {
      const territory: Country = target.source;
      return territory;
    } else {
      throw new ErrorHelper('Invalid code given for retrieving a territory.');
    }
  }

  /**
   * Returns the territory for the given ID.
   *
   * @param id number
   * @throws ErrorHelper
   * @returns Country
   */
  getTerritoryById(id: number): Country {
    const targets: SelectionItem[] = this.getTerritories().filter(
      (item: SelectionItem) => {
        const foundIt = (item.source.id === id);
        return foundIt;
      }
    );

    if (targets.length > 0) {
      const territory: Country = targets[0].source;
      return territory;
    } else {
      throw new ErrorHelper('Invalid ID given for retrieving a territory.');
    }
  }

  /**
   * Returns the territory for the given locale
   *
   * @param locale string
   * @returns Country
   */
  getTerritoryByLocale(locale: string): Country {
    const localeTerritory = locale.split('_')[Country.LOCALE_POSITION];

    const target: SelectionItem = this.getTerritories().find(
      (territory: SelectionItem) => territory.source.iso31661 === localeTerritory
    );

    return target.source;
  }

  /**
   * Indicates if it has accounts.
   *
   * @returns boolean
   */
  hasAccounts(): boolean {
    const hasIt: boolean = (this.getAccounts().length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has languages.
   *
   * @returns boolean
   */
  hasLanguages(): boolean {
    const hasIt: boolean = (this.getLanguages().length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has production companies.
   *
   * @returns boolean
   */
  hasProductionCompanies(): boolean {
    const hasIt: boolean = (this.getProductionCompanies().length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has product types.
   *
   * @returns boolean
   */
  hasProductTypes(): boolean {
    const hasIt: boolean = (this.getProductTypes().length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has territories.
   *
   * @returns boolean
   */
  hasTerritories(): boolean {
    const hasIt: boolean = (this.getTerritories().length > 0);
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.loadAccounts();
    this.loadLanguages();
    this.loadTerritories();
    this.loadGenres();
    this.loadProductionCompanies();
    this.loadProductTypes();
  }

  /**
   * Loads the accounts.
   *
   * @returns void
   */
  protected loadAccounts(): void {
    this._accounts = new Array();

    this.listsProvider.getList(StormListType.account).subscribe(
      (accounts: StormListInterface) => {
        accounts.collection.forEach(
          (account: StormListItem) => {
            const item: SelectionItem = new SelectionItem(account.value.name, account.value.id, account.value);
            this._accounts.push(item);
        });
      },
      (error) => {
        this.notificationService.handleError('Failed loading accounts', error);
      }
    );
  }

  /**
   * Loads the languages.
   *
   * @returns void
   */
  protected loadLanguages(): void {
    this._languages = new Array();

    this.listsProvider.getList(StormListType.language).subscribe(
      (languages: StormListInterface) => {
        languages.collection.forEach(
          (language: StormListItem) => {
            const item: SelectionItem = new SelectionItem(language.value.name, language.value.id, language.value);
            this._languages.push(item);
        });
      },
      (error) => {
        this.notificationService.handleError('Failed loading languages', error);
      }
    );
  }

  /**
   * Loads the production companies.
   *
   * @returns void
   */
  protected loadProductionCompanies(): void {
    this._productionCompanies = new Array();

    this.listsProvider.getList(StormListType.company).subscribe(
      (companies: StormListInterface) => {
        companies.collection.forEach(
          (company: StormListItem) => {
            const item = new ProximityItem(company.value.name, company.value.id, '', company);
            this._productionCompanies.push(item);
        });
      },
      (error) => {
        this.notificationService.handleError('Failed loading production companies', error);
      }
    );
  }

  /**
   * Loads the product types.
   *
   * @returns void
   */
  protected loadProductTypes(): void {
    this._productTypes = new Array();

    this.listsProvider.getList(StormListType.productType).subscribe(
      (productTypes: StormListInterface) => {
        productTypes.collection.forEach(
          (productType: StormListItem) => {
            const item: SelectionItem = new SelectionItem(productType.value.name, productType.value.id, productType.value);
            this._productTypes.push(item);
        });
      },
      (error) => {
        this.notificationService.handleError('Failed loading territories', error);
      }
    );
  }

  /**
   * Loads the territories.
   *
   * @returns void
   */
  protected loadTerritories(): void {
    this._territories = new Array();

    this.listsProvider.getList(StormListType.territory).subscribe(
      (territories: StormListInterface) => {
        territories.collection.forEach(
          (territory: StormListItem) => {
            const item: SelectionItem = new SelectionItem(
              territory.value.name,
              territory.value.id,
              territory.value,
              territory.value.region
            );

            this._territories.push(item);
        });
      },
      (error) => {
        this.notificationService.handleError('Failed loading territories', error);
      }
    );
  }

  /**
   * Loads the genres.
   *
   * @returns void
   */
  protected loadGenres(): void {
    this.listsProvider.getList(StormListType.genre).subscribe(
      (genres: StormListInterface) => {
        this._genres = genres.collection.map(
          (genre: StormListItem) =>
            new SelectionItem(genre.value.name, genre.value.id, genre.value)
        );
      },
      (error) => {
        this.notificationService.handleError('Failed loading territories', error);
      }
    );
  }

  /**
   * Remove from the given collection the given candidate
   *
   * @param collection any[]
   * @param candidate any
   * @returns any[]
   */
  protected removeItem(collection: any[], candidate: any): any[] {
    const filteredCollection: any[] = _reject(collection, candidate);
    return filteredCollection;
  }
}
