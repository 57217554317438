import { Injectable } from '@angular/core';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';
import { Observable, Observer } from 'rxjs';

import { AccessControlService } from '../access-control.service';
import { AuthHelper } from 'app/modules/auth/helpers/auth/auth.helper';
import { RequestControlEnum } from 'app/modules/user/models/request-control/request-control.enum';
import { TypeEnum } from 'app/modules/user/models/entity-type/type.enum';
import { User } from 'app/modules/user/models/user.model';


@Injectable()
export class AccessControlManager {
  protected _user: User;

  constructor(
    protected accessControlService: AccessControlService,
    protected authHelper: AuthHelper,
    protected appConfigurationManager: AppConfigurationManager
  ) { }

  get user(): User {
    return this._user;
  }

  /**
   * Indicates if the user has access to CAT.
   *
   * @param entityType TypeEnum
   * @param entityId number
   * @returns Observable<boolean>
   */
  hasCatAccess(entityType: TypeEnum, entityId: number): Observable<boolean> {
    if (this.hasUser()) {
      return this.createCatAccessObservable(entityType, entityId);
    } else {
      return this.createCatAccessObservableWithoutUser(entityType, entityId);
    }
  }

  /**
   * Indicates if it exists a user.
   *
   * @returns boolean
   */
  hasUser(): boolean {
    return _isObject(this.user);
  }

  /**
   * Creates an observable for fetch the access of the given entity in CAT.
   *
   * @param entityType TypeEnum
   * @param entityId number
   * @returns Observable<boolean>
   */
  protected createCatAccessObservable(entityType: TypeEnum, entityId: number): Observable<boolean> {
    const obs: Observable<boolean> = Observable.create(
      (observer: Observer<boolean>) => {
        if (this.user.hasCatRole(true)) {
          this.fetchAccessFor(entityType, entityId, RequestControlEnum.cat).subscribe(
            (response: boolean) => {
              observer.next(response);
              observer.complete();
            },
            () => {
              // Any error should be considered as false until next request.
              observer.next(false);
              observer.complete();
            }
          );
        } else {
          observer.next(false);
          observer.complete();
        }
      }
    );

    return obs;
  }

  /**
   * Creates an observable for fetch the current user and the access of the given entity in CAT.
   *
   * @param entityType TypeEnum
   * @param entityId number
   * @returns Observable<boolean>
   */
  protected createCatAccessObservableWithoutUser(entityType: TypeEnum, entityId: number): Observable<boolean> {
    const obs: Observable<boolean> = Observable.create(
      (observer: Observer<boolean>) => this.authHelper.authenticatedUserStream.subscribe(
        (user: User) => {
          this._user = user;

          this.createCatAccessObservable(entityType, entityId).subscribe(
            (allowed: boolean) => {
              observer.next(allowed);
              observer.complete();
            }
          );
        },
        () => {
          // Any error should be considered as false until next request.
          observer.next(false);
          observer.complete();
        }
      )
    );

    return obs;
  }

  /**
   * Fetch the access of the given entity for the given requestControl.
   *
   * @param entityType TypeEnum
   * @param entityId number
   * @param requestControl RequestControlEnum
   * @returns Observable<boolean>
   */
  protected fetchAccessFor(
    entityType: TypeEnum,
    entityId: number,
    requestControl: RequestControlEnum
  ): Observable<boolean> {
      return this.accessControlService.fetchEntityAllowedAsObservable(requestControl, entityType, entityId);
  }
}
