import { FormControl, Validators } from '@angular/forms';
import { Original } from '../original.model';


export class EditionForm {
  protected _originalLanguage: FormControl;
  protected _notes: FormControl;
  protected _originalName: FormControl;
  protected _phonetic: FormControl;
  protected _requiredLocalizations: FormControl;
  protected attributes: any;
  protected item: Original;

  constructor(item: Original, attributes: any) {
    this.initialize(item, attributes);
  }

  get notes(): FormControl {
    return this._notes;
  }

  get originalLanguage(): FormControl {
    return this._originalLanguage;
  }

  get originalName(): FormControl {
    return this._originalName;
  }

  get phonetic(): FormControl {
    return this._phonetic;
  }

  get requiredLocalizations(): FormControl {
    return this._requiredLocalizations;
  }

  /**
   * Initializes the instance.
   *
   * @param item Original
   * @param attributes any
   * @returns void
   */
  protected initialize(item: Original, attributes: any): void {
    this.attributes = attributes;
    this.item = item;

    this.setupNotes();
    this.setupOriginalLanguage();
    this.setupOriginalName();
    this.setupPhonetic();
    this.setupRequiredLocalizations();
  }

  /**
   * Set ups the notes field.
   *
   * @returns void
   */
  protected setupNotes(): void {
    const validators: any = Validators.maxLength(this.attributes.notes.maxLength);
    this._notes = new FormControl(this.item.notes, validators);
  }

  /**
   * Set ups the original language field.
   *
   * @returns void
   */
  protected setupOriginalLanguage(): void {
    this._originalLanguage = new FormControl([ this.item.originalLanguageId ], Validators.required);
  }

  /**
   * Set ups the original name field.
   *
   * @returns void
   */
  protected setupOriginalName(): void {
    const validators: any = Validators.compose([
      Validators.required,
      Validators.maxLength(this.attributes.originalName.maxLength)
    ]);

    this._originalName = new FormControl(this.item.name, validators);
  }

  /**
   * Set ups the phonetic field.
   *
   * @returns void
   */
  protected setupPhonetic(): void {
    const validators: any = Validators.maxLength(this.attributes.phonetic.maxLength);
    this._phonetic = new FormControl(this.item.phonetic, validators);
  }

  /**
   * Set up the localization requested field.
   *
   * @returns void
   */
  protected setupRequiredLocalizations(): void {
    this._requiredLocalizations = new FormControl(this.item.requiredLocalizations);
  }
}
