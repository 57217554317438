import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpError } from '@bolt/ui-shared/common';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Account, Country, Language, ProductType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs';
import { capitalize as _capitalize, isObject as _isObject, kebabCase as _kebabCase } from 'lodash';

import { AttributeEnum } from '../../models/entity/lock/attribute.enum';
import { CreditLockingStatusInterface } from 'app/modules/credits/models/credit/credit-locking-status.interface';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditsService } from 'app/modules/credits/services/credits.service';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { Lock } from '../../models/entity/lock/lock.model';
import { LockingStatus as MetadataLockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { ManagerService as SubtitleManager } from 'app/modules/subtitle/services/manager/manager.service';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { Status as LockStatus } from '../../models/entity/lock/status/status.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleMetadata } from 'app/modules/title/models/title-metadata.model';
import { TitleService } from 'app/modules/title/services/title.service';


// TODO
// This component handles many options depending on the value of "attribute" input and it is candidate to be splitted into different
// components, according to the target metadata: Cast & Crew, Subtitles, Language, Territory and so on.
@Component({
  selector: 'bolt-project-metadata-popup',
  template: require('./bolt-project-metadata-popup.html'),
  styles: [require('./bolt-project-metadata-popup.scss')]
})
export class BoltProjectMetadataPopupComponent extends StormComponent implements OnInit, OnDestroy {
  @Input() attribute: AttributeEnum;
  @Input() entityTitle: string;
  @Input() lock: Lock;

  @Output('closed') closeEvent: EventEmitter<undefined>;

  protected readonly attributeEnum: typeof AttributeEnum = AttributeEnum;
  protected readonly creditPositions: typeof CreditPositionType = CreditPositionType;
  protected readonly creditTypes: typeof CreditType = CreditType;

  protected readonly creditsSwitcherItems: SelectionItem[] = [
    new SelectionItem('Cast', CreditPositionType.CAST),
    new SelectionItem('Crew', CreditPositionType.CREW)
  ];

  protected account: string;
  protected creditsPositionSelection: CreditPositionType;
  protected creditsTypeSelection: CreditType;
  protected headerTitle: string;
  protected isCreditsSwitcherDisabled: boolean;
  protected isCreditsSwitcherVisible: boolean;
  protected isLockExceptionModeOn: boolean;
  protected isLockLightModeOn: boolean;
  protected isMetadataLockingStatusVisible: boolean;
  protected language: string;
  protected localeSections: string[];
  protected metadata: any;
  protected metadataLockingStatus: MetadataLockingStatus;
  protected subscriptions: Subscription;
  protected subtitleAuxiliaryLanguage: string;
  protected territory: string;

  constructor(
    protected creditsService: CreditsService,
    protected masterDataManager: MasterDataManager,
    protected notificationService: NotificationService,
    protected subtitleManager: SubtitleManager,
    protected titleService: TitleService
  ) {
    super();

    this.closeEvent = new EventEmitter();
    this.subscriptions = new Subscription();

    this.changeStatusToIdle();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.subtitleManager.resetAll();
  }

  ngOnInit() {
    this.loadMetadata();
  }

  /**
   * Closes it.
   *
   * @returns void
   */
  protected close(): void {
    this.closeEvent.emit();
  }

  /**
   * Discovers the value of the auxiliary language used for Localized Subtitles metadata view.
   *
   * @todo Discuss with API team about getting the Original Spoken Language of the title inside Subtitles structure, in order to eliminate
   *       this method.
   * @returns void
   */
  protected discoverSubtitleAuxiliaryLanguage(): void {
    const params: any = {
      productType: this.lock.type,
      productId: this.lock.id
    };

    const productSubs: Subscription = this.titleService.fetchProduct(params, true).subscribe(
      (response: StormServiceResponseSingle) => {
        const languageSubs: Subscription = this.masterDataManager.getLanguageById(response.item.originalSpokenLanguageId).subscribe(
          (language: Language) => {
            this.subtitleAuxiliaryLanguage = language.name;
          }
        );

        this.subscriptions.add(languageSubs);
      }
    );

    this.subscriptions.add(productSubs);
  }

  /**
   * Returns the locale in base of locale sections.
   *
   * @returns string
   */
  protected getLocale(): string {
    const locale: string = this.localeSections.join('_');
    return locale;
  }

  /**
   * Handles the given error for retrieving locking status.
   *
   * @param error HttpError
   * @returns void
   */
  protected handleRetrievingLockingStatusError(error: any): void {
    if (error.status === 404) {
      this.metadataLockingStatus = new MetadataLockingStatus({ locked: false });
    } else {
      this.notificationService.handleError('Failed retrieving locking status details', error, notificationsContainer.projectDashboard.key);
    }
  }

  /**
   * Handles the given error for retrieving metadata.
   *
   * @param error any
   * @returns void
   */
  protected handleRetrievingMetadataError(error: any): void {
    this.notificationService.handleError('Failed retrieving metadata details', error, notificationsContainer.projectDashboard.key);
    this.changeStatusToError();
  }

  /**
   * Loads the data related to account, language, product type and territory.
   *
   * @returns void
   */
  protected loadLocaleData(): void {
    this.account = undefined;
    this.language = undefined;
    this.territory = undefined;

    this.subscriptions.add(
      this.masterDataManager.getAccountByCode(this.localeSections[Account.LOCALE_POSITION]).subscribe(
        (account: Account) => {
          this.account = account.name;
        }
      )
    );

    this.subscriptions.add(
      this.masterDataManager.getLanguageByCode(this.localeSections[Language.LOCALE_POSITION]).subscribe(
        (language: Language) => {
          this.language = language.name;
        }
      )
    );

    this.subscriptions.add(
      this.masterDataManager.getTerritoryByCode(this.localeSections[Country.LOCALE_POSITION]).subscribe(
        (territory: Country) => {
          this.territory = territory.name;
        }
      )
    );
  }

  /**
   * Loads metadata according to the stored lock and attribute.
   *
   * @returns void
   */
  protected loadMetadata(): void {
    this.setupBaseDependencies();

    switch (this.attribute) {
      case AttributeEnum.account:
        this.headerTitle = 'Account Metadata';
        this.localeSections[Account.LOCALE_POSITION] = this.lock.accountCode;
        this.loadSpecificLocalizedMetadata();
      break;
      case AttributeEnum.dubbingCast:
      case AttributeEnum.dubbingCrew:
      case AttributeEnum.originalCast:
      case AttributeEnum.originalCrew:
        const sections: string[] = _kebabCase(this.attribute).split('-');
        const position: CreditPositionType = CreditPositionType[sections[1].toUpperCase()];
        const type: CreditType = CreditType[sections[0].toUpperCase()];

        this.setupCreditsDependencies(position, type);
        this.loadCreditsMetadata();
      break;
      case AttributeEnum.language:
        this.headerTitle = 'Language Descriptive Metadata';
        this.localeSections[Language.LOCALE_POSITION] = this.lock.languageCode;
        this.localeSections[Account.LOCALE_POSITION] = this.lock.accountCode;
        this.isMetadataLockingStatusVisible = false;
        this.loadSpecificLocalizedMetadata();
      break;
      case AttributeEnum.localizedSubtitles:
        this.setupSubtitlesDependencies(false);
        this.loadSubtitlesMetadata(false);
      break;
      case AttributeEnum.originalSubtitles:
        this.setupSubtitlesDependencies(true);
        this.loadSubtitlesMetadata(true);
      break;
      case AttributeEnum.territory:
        this.headerTitle = 'Territory Metadata';
        this.localeSections[Country.LOCALE_POSITION] = this.lock.territoryCode;
        this.localeSections[Account.LOCALE_POSITION] = this.lock.accountCode;
        this.isMetadataLockingStatusVisible = false;
        this.loadSpecificLocalizedMetadata();
      break;
      case AttributeEnum.title:
        this.headerTitle = 'Title Metadata';
        this.loadTitleMetadata();
      break;
      default:
        throw new Error(`Invalid attribute given: ${this.attribute}`);
    }

    this.loadLocaleData();
  }

  /**
   * Loads metadata for Cast/Crew.
   *
   * @returns void
   */
  protected loadCreditsMetadata(): void {
    this.retrieveCreditsData();
    this.retrieveCreditsLockingStatus();
  }

  /**
   * Loads metadata for Account, Language or Territory specific.
   *
   * @returns void
   */
  protected loadSpecificLocalizedMetadata(): void {
    this.prepareSpecificLocalizedData();
    this.retrieveSpecificLockingStatus();
  }

  /**
   * Loads metadata for Insert & Subtitles.
   * According to the value on `isOriginalOnly`, it loads Original Version only or Original and Localized versions.
   *
   * @param isOriginalOnly boolean
   * @returns void
   */
  protected loadSubtitlesMetadata(isOriginalOnly: boolean): void {
    const hasOriginalLockingStatus: boolean = this.isMetadataLockingStatusVisible && isOriginalOnly;
    const hasLocalizedLockingStatus: boolean = this.isMetadataLockingStatusVisible && !isOriginalOnly;

    this.changeStatusToFetchingData();
    this.metadata = [];

    // Original Version - We always request it due to it's mandatory, no matter the value of isOriginalOnly.
    this.subtitleManager.fetchOriginalVersion(
      () => {
        this.metadata[0] = this.subtitleManager.originalVersion.subtitleDetails;

        if (isOriginalOnly) {
          this.metadataLockingStatus = this.subtitleManager.originalVersion.lockedStatus;
          this.changeStatusToDataFound();
        } else {
          this.discoverSubtitleAuxiliaryLanguage();

          // Localized Version - We request it only when isOriginalOnly is false.
          this.subtitleManager.fetchLocalizedVersion(
            () => {
              this.metadata[1] = this.subtitleManager.localizedVersion.subtitleDetails;
              this.metadataLockingStatus = this.subtitleManager.localizedVersion.lockedStatus;
              this.changeStatusToDataFound();
            },
            () => {
              this.changeStatusToError();
            },
            undefined,
            hasLocalizedLockingStatus
          );
        }
      },
      () => {
        this.changeStatusToError();
      },
      undefined,
      hasOriginalLockingStatus
    );
  }

  /**
   * Loads metadata for title level.
   *
   * @returns void
   */
  protected loadTitleMetadata(): void {
    this.changeStatusToFetchingData();

    const params: any = {
      productType: this.lock.type,
      productId: this.lock.id
    };

    const subs: Subscription = this.titleService.fetchProduct(params, true).subscribe(
      (response: StormServiceResponseSingle) => {
        this.metadata = response.item;

        if (this.isMetadataLockingStatusVisible) {
          this.metadataLockingStatus = new MetadataLockingStatus(this.metadata);
        }

        this.changeStatusToDataFound();
      },
      (error: HttpErrorResponse) => {
        this.handleRetrievingMetadataError(error);

        if (this.isMetadataLockingStatusVisible) {
          this.handleRetrievingLockingStatusError(error);
        }
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Retrieves the data for Cast/Crew.
   *
   * @returns void
   */
  protected retrieveCreditsData(): void {
    this.changeStatusToFetchingData();

    const subs: Subscription = this.creditsService.fetch(
      <any>this.lock.type.toString(),
      this.lock.id,
      this.creditsTypeSelection,
      this.creditsPositionSelection,
      this.getLocale()
    ).subscribe(
      (response: any) => {
        this.metadata = response;
        this.changeStatusToDataFound();
      },
      (error: any) => {
        this.handleRetrievingMetadataError(error);
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Retrieves the locking status for Cast/Crew.
   *
   * @returns void
   */
  protected retrieveCreditsLockingStatus(): void {
    if (this.isMetadataLockingStatusVisible) {
      const subs: Subscription = this.creditsService.fetchLockingStatus(
        <any>this.lock.type.toString(),
        this.lock.id,
        this.creditsTypeSelection,
        this.creditsPositionSelection,
        this.getLocale()
      ).subscribe(
        (response: CreditLockingStatusInterface) => {
          this.metadataLockingStatus = new MetadataLockingStatus(response);
        },
        (error: HttpErrorResponse) => {
          // TODO remove this mapping when fetchLockingStatus use the doGetRequest
          const httpError: HttpError = new HttpError(error.message, error.status, error.statusText);
          this.handleRetrievingLockingStatusError(httpError);
        }
      );

      this.subscriptions.add(subs);
    }
  }

  /**
   * Prepares the localized data for Account, Language or Territory specific.
   *
   * @returns void
   */
  protected prepareSpecificLocalizedData(): void {
    this.changeStatusToFetchingData();

    const product: TitleMetadata = new TitleMetadata({
      id: this.lock.id,
      rootId: this.lock.id,
      type: this.lock.type.value,
      locale: this.getLocale()
    });

    this.metadata = product;
    this.changeStatusToDataFound();
  }

  /**
   * Retrieves the locking status for Account, Language or Territory specific.
   *
   * @returns void
   */
  protected retrieveSpecificLockingStatus(): void {
    if (this.isMetadataLockingStatusVisible) {
      const params: any = {
        productType: this.lock.type.value,
        productId: this.lock.id,
        locale: this.getLocale()
      };

      const subs: Subscription = this.titleService.fetchProductMetadata(params).subscribe(
        (response: StormServiceResponseSingle) => {
          this.metadataLockingStatus = new MetadataLockingStatus(response.item);
        },
        (error: HttpError) => {
          this.handleRetrievingLockingStatusError(error);
        }
      );

      this.subscriptions.add(subs);
    }
  }

  /**
   * Set up the base dependencies.
   *
   * @returns void
   */
  protected setupBaseDependencies(): void {
    const status: LockStatus = this.lock.status.get(this.attribute);

    this.localeSections = [Language.ALL_VALUE, Country.ALL_VALUE, ProductType.ALL_VALUE, Account.ALL_VALUE];
    this.isCreditsSwitcherDisabled = false;
    this.isCreditsSwitcherVisible = false;
    this.isMetadataLockingStatusVisible = true;
    this.isLockExceptionModeOn = status.isExceptionalLocked();
    this.isLockLightModeOn = status.isPartialLocked() || status.isPartialUnlocked();
    this.metadata = undefined;
    this.metadataLockingStatus = undefined;
    this.subtitleAuxiliaryLanguage = undefined;
  }

  /**
   * Set up the dependencies for Cast/Crew.
   *
   * @param position CreditPositionType
   * @param type CreditType
   * @returns void
   */
  protected setupCreditsDependencies(position: CreditPositionType, type: CreditType): void {
    // We need to check the other Credits position to detect if we can allow switch between CAST/CREW tables.
    const mirrorPosition: CreditPositionType = (position === CreditPositionType.CAST ? CreditPositionType.CREW : CreditPositionType.CAST);
    const mirrorAttribute: AttributeEnum = AttributeEnum[`${type.toString().toLowerCase()}${_capitalize(mirrorPosition.toString())}`];
    const mirrorStatus: LockStatus = this.lock.status.get(mirrorAttribute);

    this.creditsPositionSelection = position;
    this.creditsTypeSelection = type;
    this.headerTitle = `${_capitalize(type.toString())} Cast & Crew`;
    this.localeSections[Language.LOCALE_POSITION] = this.lock.languageCode;
    this.isCreditsSwitcherDisabled = mirrorStatus.isNoData();
    this.isCreditsSwitcherVisible = true;
  }

  /**
   * Set up the dependencies for Insert & Subtitles.
   *
   * @param isOriginal boolean
   * @returns void
   */
  protected setupSubtitlesDependencies(isOriginal: boolean): void {
    this.subtitleManager.resetAll();

    this.localeSections[Language.LOCALE_POSITION] = this.lock.languageCode;
    this.subtitleManager.titleId = this.lock.id;
    this.subtitleManager.titleType = <any>this.lock.type.value;

    if (isOriginal) {
      this.headerTitle = 'Original Insert & Subtitles';
      this.subtitleManager.setSelectedLocalization(undefined);
    } else {
      this.headerTitle = 'Localized Insert & Subtitles';
      this.subtitleManager.setSelectedLocalization(this.getLocale());
    }
  }

  /**
   * Switches the Credits metadata using the given option.
   *
   * @param option CreditPositionType
   * @returns void
   */
  protected switchCreditsMetadata(option: CreditPositionType): void {
    if (option !== this.creditsPositionSelection) {
      const typeName: string = this.creditsTypeSelection.toString().toLowerCase();
      const positionName: string = _capitalize(option.toString());

      this.creditsPositionSelection = option;
      this.attribute = AttributeEnum[`${typeName}${positionName}`];

      this.loadMetadata();
    }
  }
}
