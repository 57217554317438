import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs';
import { isEmpty as _isEmpty } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Event } from '../../models/event/event.model';
import { EventService } from '../../services/event/event.service';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StatusEnum } from '../../models/event/status/status.enum';
import { TypeEnum } from '../../models/event/type/type.enum';


@Component({
  selector: 'bolt-cpm-events-list',
  template: require('./bolt-cpm-events-list.html'),
  styles: [require('./bolt-cpm-events-list.scss')]
})
export class BoltCpmEventsListComponent extends StormComponent implements OnInit, OnDestroy {
  protected currentEvent?: Event;
  protected fetchListener: Subscription;
  protected filteredStatus: StatusEnum;
  protected filteredType: TypeEnum;
  protected hasOpenDetailsPanel: boolean;
  protected pager: MemoryPager;

  constructor(
    protected appConfig: AppConfigProvider,
    protected eventService: EventService,
    protected notificationService: NotificationService
  ) {
    super();

    this.filteredStatus = undefined;
    this.filteredType = undefined;
    this.pager = new MemoryPager(this.appConfig.get('ux.dataTables.pageSize'), this.getSortingCriteria());

    this.changeStatusToIdle();
    this.closeDetailsPanel();
  }

  ngOnDestroy() {
    this.cancelFetchListener();
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Finds the events with the given status.
   *
   * @param status StatusEnum
   * @returns void
   */
  protected applyFilterStatus(status: StatusEnum): void {
    this.filteredStatus = status;
    this.pager.setFilterCriteria(this.getFilteringCriteria());
  }

  /**
   * Finds the events with the given type.
   *
   * @param type TypeEnum
   * @returns void
   */
  protected applyFilterType(type: TypeEnum): void {
    this.filteredType = type;
    this.pager.setFilterCriteria(this.getFilteringCriteria());
  }

  /**
   * Cancels the fetch listener for the summary.
   *
   * @returns void
   */
  protected cancelFetchListener(): void {
    if (this.fetchListener) {
      this.fetchListener.unsubscribe();
    }
  }

  /**
   * Closes the details panel.
   *
   * @returns void
   */
  protected closeDetailsPanel(): void {
    this.currentEvent = undefined;
    this.hasOpenDetailsPanel = false;
  }

  /**
   * Fetches the events.
   *
   * @returns void
   */
  protected fetch(): void {
    this.cancelFetchListener();
    this.changeStatusToFetchingData();

    this.fetchListener = this.eventService.fetchEvents().subscribe(
      (events: Event[]) => {
        this.pager.setRecords(events);
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed fetching the Events.', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Returns the list of events.
   *
   * @returns Event[]
   */
  protected getEvents(): Event[] {
    return this.pager.getCurrentPage();
  }

  /**
   * Returns the filtering criteria for events.
   *
   * @returns CallableFunction
   */
  protected getFilteringCriteria(): CallableFunction {
    const criteria: CallableFunction = (event: Event) => {
      const matched: boolean =
        (_isEmpty(this.filteredStatus) || event.getStatus().is(this.filteredStatus)) &&
        (_isEmpty(this.filteredType) || event.getType().is(this.filteredType));

      return matched;
    };

    return criteria;
  }

  /**
   * Returns the sorting criteria for events.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (eventA: Event, eventB: Event) => {
      const format: string = 'YYYY-MM-DD HH:mm:ss';
      const dateA: string = eventA.getStartedTime().format(format);
      const dateB: string = eventB.getStartedTime().format(format);

      if (dateA > dateB) {
        return -1;
      } else if (dateA < dateB) {
        return 1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Opens the details panel for the given event.
   *
   * @param event Event
   * @returns void
   */
  protected openDetailsPanel(event: Event) {
    this.currentEvent = event;
    this.hasOpenDetailsPanel = true;
  }
}
