import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { MicroUiRouteChecker } from '../../helpers/micro-ui-route-checker/micro-ui-route-checker.helper';


@Injectable()
export class MicroUiRedirectGuard implements CanActivate {
  constructor(
    protected routeChecker: MicroUiRouteChecker
  ) { }

  /**
   * Verifies that a route belongs to a MicroUI. Returns false when it does to prevent
   * a router error. True if it doesn't exists so it can be routed to the 404 page.
   *
   * @param route ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns boolean
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.routeChecker.routeExists(state.url)) {
      window.location.href = state.url;

      return false;
    } else {
      return true;
    }
  }
}
