export class Queue {
  protected position: number;
  protected slices: string[][];

  constructor() {
    this.slices = new Array();
    this.rewind();
  }

  /**
   * Adds the given slices.
   *
   * @param slices string[][]
   * @returns void
   */
  add(slices: string[][]): void {
    this.slices.push(...slices);
  }

  /**
   * Returns the current slice.
   *
   * @returns string[]
   */
  current(): string[] {
    return this.slices[this.position];
  }

  /**
   * Return the position of the current slice.
   *
   * @returns number
   */
  getPosition(): number {
    return this.position;
  }

  /**
   * Returns the slices.
   *
   * @returns string[][]
   */
  getSlices(): string[][] {
    return this.slices;
  }

  /**
   * Indicates if the position is at the end.
   *
   * @returns boolean
   */
  isPositionAtEnd(): boolean {
    const isIt: boolean = (this.position >= this.slices.length);
    return isIt;
  }

  /**
   * Moves forward to posaition to next slice.
   *
   * @returns void
   */
  next(): void {
    if (!this.isPositionAtEnd()) {
      this.position++;
    }
  }

  /**
   * Rewinds the position to the first slice.
   *
   * @returns void
   */
  rewind(): void {
    this.position = 0;
  }
}
