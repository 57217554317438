import { Comparison } from '../../comparison/comparison.model';
import { Resolution } from '../resolution.model';
import { ResolveIngestDataEnum } from '../resolve-ingest-data/resolve-ingest-data.enum';
import { Overwritters } from '../../overwritters/overwritters.model';


export class Accept extends Resolution {
  protected _comparison: Comparison;
  protected _overwritters: Overwritters;

  constructor(comparison: Comparison, overwritters: Overwritters) {
    super();
    this.initialize(comparison, overwritters);
  }

  asEndpointData(): object {
    const data: object = {
      conflictResolution: {
        resolveIngestData: this._resolveIngestData.type,
        overwriteLockedLanguageMetadata: this._overwritters.lockedLanguageMetadata,
        overwriteLockedTerritoryMetadata: this._overwritters.lockedTerritoryMetadata,
        metadata: this.comparison.titles.asEndpointData()
      }
    };

    return data;
  }

  get comparison(): Comparison {
    return this._comparison;
  }

  get overwritters(): Overwritters {
    return this._overwritters;
  }

  /**
   * Initializes the instance.
   *
   * @param comparison Comparison
   * @param overwritters Overwritters
   * @returns void
   */
  protected initialize(comparison: Comparison, overwritters: Overwritters): void {
    this._comparison = comparison;
    this._overwritters = overwritters;

    this.setupResolveIngestData(ResolveIngestDataEnum.ACCEPT);
  }
}
