import { Episode } from '../episode.model';
import { Field } from '../../field/field.model';


export class MergedData {
  protected _byLanguage: Episode;
  protected _byTerritory: Episode;

  constructor(byLanguage: Episode, byTerritory: Episode) {
    this.initialize(byLanguage, byTerritory);
  }

  get byLanguage(): Episode {
    return this._byLanguage;
  }

  get byTerritory(): Episode {
    return this._byTerritory;
  }

  get entityId(): number {
    return this.byLanguage.entityId;
  }

  get legalTitle(): string {
    return this.byLanguage.legalTitle;
  }

  get radarId(): number {
    return this.byLanguage.radarId;
  }

  get rowLocale(): string {
    return this.byLanguage.rowLocale;
  }

  get seasonId(): number {
    return this.byLanguage.seasonId;
  }

  /**
   * Returns the field for the given name in the stored episode by language.
   *
   * @param name string
   * @returns Field
   */
  getFieldByLanguage(name: string): Field {
    return this.byLanguage.getField(name);
  }

  /**
   * Returns the field for the given name in the stored episode by territory.
   *
   * @param name string
   * @returns Field
   */
  getFieldByTerritory(name: string): Field {
    return this.byTerritory.getField(name);
  }

  /**
   * Initializes the instance.
   *
   * @param byLanguage Episode
   * @param byTerritory Episode
   * @returns void
   */
  protected initialize(byLanguage: Episode, byTerritory: Episode): void {
    this._byLanguage = byLanguage;
    this._byTerritory = byTerritory;
  }
}
