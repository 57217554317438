import { Language } from '@bolt/ui-shared/master-data';

import { Character } from 'app/modules/character/models/character.model';
import { Item } from '../item.model';


export class Localized extends Item {
  protected _rootId: number;
  protected _useDomestic: boolean;

  constructor(data: any) {
    super();
    this.initialize(data);
  }

  /**
   * Creates an Item with the given Character.
   *
   * @param character Character
   */
  static newFromCharacter(character: Character): Localized {
    const data: any = character.getRawObject();
    return new Localized(data);
  }

  get rootId(): number {
    return this._rootId;
  }

  get useDomestic(): boolean {
    return this._useDomestic;
  }

  isLocalized(): boolean {
    return true;
  }

  protected getInvalidLanguageReason(language: Language): string {
    const reason: string = String(
      `Invalid language given (${language.localeLanguage}) for stored locale (${this.locale}).`
    );

    return reason;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    super.initialize(data);

    this._rootId = data.rootId;
    this._useDomestic = (data.useDomestic || false);
  }

  protected isCorrectLanguage(language: Language): boolean {
    const isIt: boolean = (language.localeLanguage === this.locale.split('_')[0]);
    return isIt;
  }
}
