import { Component, Input, OnInit } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';

import { BoltCloneListItemComponent } from '../bolt-clone-list-item/bolt-clone-list-item.component';
import { Episode } from 'app/modules/title/models/episode.model';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { StormServiceResponseSingleInterface } from 'app/modules/common/services/storm-service-response-single';
import { TitleService, TitleServiceFetchTitleMetadataParamsInterface } from 'app/modules/title/services/title.service';


@Component({
  selector: 'bolt-clone-metadata-list-episode-item',
  template: require('./bolt-clone-metadata-list-episode-item.html'),
  styles: [require('./bolt-clone-metadata-list-episode-item.scss')]
})
export class BoltCloneMetadataListEpisodeItemComponent extends BoltCloneListItemComponent implements OnInit {
  @Input() title: Episode;
  @Input() checkNsaDisclaimer: boolean;

  constructor(
    protected titleService: TitleService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.fetchLockingStatus();
  }

  protected fetchLockingStatus(): void {
    this.changeStatusToFetchingData();
    this.cancelLockingStatusSubscription();

    const params: TitleServiceFetchTitleMetadataParamsInterface = {
      productType: this.title.type,
      productId: this.title.id,
      locale: this.locale
    };

    this.lockingStatusSubscription = this.titleService.fetchProductMetadata(
      params
    ).subscribe(
      (response: StormServiceResponseSingleInterface) => {
        this.lockingStatus = new LockingStatus(response.item);
        this.changeStatusToDataFound();
      },
      (error: HttpError) => {
        if (error.is404()) {
          this.lockingStatus = new LockingStatus(new Object());
          this.changeStatusToDataFound();
        } else {
          this.changeStatusToError();
        }
      }
    );
  }
}
