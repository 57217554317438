import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Character } from '../../models/character.model';
import { CharacterMetadataManager } from '../../helpers/character-metadata-manager/character-metadata-manager';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Original as OriginalCatItem } from 'app/modules/cat/models/item/original/original.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TypeEnum as CatTypeEnum } from '../../../cat/models/type/type.enum';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-page-character-details',
  template: require('./bolt-page-character-details.html'),
  styles: [require('./bolt-page-character-details.scss')]
})
export class BoltPageCharacterDetailsComponent extends StormComponent implements OnInit, OnDestroy {
  protected catType: typeof CatTypeEnum = CatTypeEnum;
  protected routerSubs: Subscription;
  protected showCharacterLevelData: boolean = true;
  protected notificationsContainer = notificationsContainer;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected characterMetadataManager: CharacterMetadataManager,
    protected notificationService: NotificationService,
    protected router: Router
  ) {
    super();
  }

  get character(): Character {
    return this.characterMetadataManager.character;
  }

  ngOnInit() {
    this.showCharacterLevelData = (this.activatedRoute.snapshot.params['show-character-level-data'] === 'true');

    this.routerSubs = this.activatedRoute.params.subscribe(
      params => {
        const id: number = params.characterId;

        if (!this.characterMetadataManager.hasCharacter() || (this.character.id !== id)) {
          this.changeStatusToFetchingData();
          this.retrieveCharacter();
        }
      }
    );
  }

  ngOnDestroy() {
    this.routerSubs.unsubscribe();
  }

  /**
   * Gets the current character as an item.
   *
   * @returns Original
   */
  protected getCharacterAsItem(): OriginalCatItem {
    const item: OriginalCatItem = new OriginalCatItem(this.character);
    return item;
  }

  /**
   * Navigates back in the platform's history
   *
   * @returns void
   */
  protected navigateBack(): void {
    return history.back();
  }

  /**
   * Retrieves a character with the given id.
   *
   * @returns void
   */
  protected retrieveCharacter(): void {
    this.characterMetadataManager.retrieveCharacter(
      Number(this.activatedRoute.snapshot.params['characterId']),
      (response: Character) => {
        if (response) {
          this.changeStatusToDataFound();
        } else {
          this.changeStatusToDataNotFound();
        }
      },
      (error: ErrorHelper) => {
        this.changeStatusToError();
        this.notificationService.handleError('Failed fetching character', error, notificationsContainer.character.details.key);
      }
    );
  }
}
