import { Injectable } from '@angular/core';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { Language } from '@bolt/ui-shared/master-data';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { Comparison } from '../../models/import/comparison/comparison.model';
import { ConflictEnum } from '../../models/import/comparison/titles/title/field/conflict/conflict.enum';
import { Field } from '../../models/import/comparison/titles/title/field/field.model';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { ResolutionEnum } from '../../models/import/comparison/titles/title/field/resolution/resolution.enum';
import { Title } from '../../models/import/comparison/titles/title/title.model';


@Injectable()
export class RoleCapabilitiesHandler {
  protected readonly pieImportPath: string = modulesPath.pie.import.path;
  protected readonly pieImportEnglishPath: string = modulesPath.pie.import.english.path;
  protected readonly pieExportPath: string = modulesPath.pie.export.path;

  protected isImportReadCapable: boolean;
  protected isExportReadCapable: boolean;
  protected isImportWriteCapable: boolean;
  protected isImportEnglishWriteCapable: boolean;

  constructor(protected capabilitiesManager: CapabilitiesManager) {
    this.initialize();
  }

  /**
   * Checks if english language is included in Comparison and set IGNORE as resolution
   *
   * @param data Comparison
   * @returns void
   */
  applyDefaultResolutionIfEnglish(data: Comparison): void {
    if (data.languages.includes(Language.ENGLISH_VALUE) && !this.hasEnglishWritePrivilege()) {
      this.applyDefaultResolutionOnTitles(data.titles.series);
      this.applyDefaultResolutionOnTitles(data.titles.seasons);
      this.applyDefaultResolutionOnTitles(data.titles.episodes);
      data.alerts.updateMessage('English language is not editable with your current role');
    }
  }

  /**
   * Returns if the user has english write privilege
   *
   * @returns boolean
   */
  hasEnglishWritePrivilege(): boolean {
    return this.isImportEnglishWriteCapable;
  }

  /**
   * Checks if the user is able to see export tab
   *
   * @returns boolean
   */
  canExport(): boolean {
    return this.isExportReadCapable;
  }

  /**
   * Checks if the user is able to see import tab
   *
   * @returns boolean
   */
  canImport(): boolean {
    return this.isImportReadCapable;
  }

  /**
   * Changes to IGNORE the resolution for all fields in a title if it's a english root locale
   *
   * @param titles Title[]
   * @returns void
   */
  protected applyDefaultResolutionOnTitles(titles: Title[]): void {
    titles.forEach(
      (title: Title) => {
        if (Locale.isEnglishLanguage(title.locale) && !this.hasEnglishWritePrivilege()) {
          title.fields.forEach(
            (field: Field) => {
              if (field.conflict.type !== ConflictEnum.NONE) {
                field.updateResolution(ResolutionEnum.IGNORE);
              }
            }
          );
        }
      }
    );
  }

  /**
   * Initializes the instance
   *
   * @returns void
   */
  protected initialize(): void {
    this.isImportReadCapable = this.capabilitiesManager.hasUserPrivilegeOn(
      this.pieImportPath,
      [ActionTypeEnum.read]
    );

    this.isImportWriteCapable = this.capabilitiesManager.hasUserPrivilegeOn(
      this.pieImportPath,
      [ActionTypeEnum.write]
    );

    this.isExportReadCapable = this.capabilitiesManager.hasUserPrivilegeOn(
      this.pieExportPath,
      [ActionTypeEnum.read]
    );

    this.isImportEnglishWriteCapable = this.capabilitiesManager.hasUserPrivilegeOn(
      this.pieImportEnglishPath,
      [ActionTypeEnum.write]
    );
  }
}
