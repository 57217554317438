import { Input, Component } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';

import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { BoltCharacterMetadataFormComponent } from '../bolt-character-metadata-form/bolt-character-metadata-form.component';
import { LayoutService } from 'app/shared/services/form/layout/layout.service';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';


@Component({
  selector: 'bolt-character-original-metadata-form',
  template: require('./bolt-character-original-metadata-form.html'),
  styles: [require('./bolt-character-original-metadata-form.scss')]
})
export class BoltCharacterOriginalMetadataFormComponent extends BoltCharacterMetadataFormComponent {
  @Input() form: OriginalDataForm;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formConfig: ConfigService,
    protected formLayout: LayoutService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super(formConfig, listLayoutProvider);
  }
}
