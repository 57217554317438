import { includes as _includes } from 'lodash';

import { StatusEnum } from './status.enum';


export class Status {
  protected _value: StatusEnum;

  constructor(value: StatusEnum) {
    if (_includes(StatusEnum, value)) {
      this._value = value;
    } else {
      throw new Error(`Invalid value given for a lock status: ${value}`);
    }
  }

  get value(): StatusEnum {
    return this._value;
  }

  /**
   * Indicates if it is equals to the given Status.
   *
   * @param otherStatus Status
   * @returns boolean
   */
  isEqualsTo(otherStatus: Status): boolean {
    return this.is(otherStatus.value);
  }

  /**
   * Indicates if it is "exceptionalLocked".
   *
   * @returns boolean
   */
  isExceptionalLocked(): boolean {
    return this.is(StatusEnum.exceptionalLocked);
  }

  /**
   * Indicates if it is "locked".
   *
   * @returns boolean
   */
  isLocked(): boolean {
    return this.is(StatusEnum.locked);
  }

  /**
   * Indicates if it is "noData".
   *
   * @returns boolean
   */
  isNoData(): boolean {
    return this.is(StatusEnum.noData);
  }

  /**
   * Indicates if it is "notApplicable".
   *
   * @returns boolean
   */
  isNotApplicable(): boolean {
    return this.is(StatusEnum.notApplicable);
  }

  /**
   * Indicates if it is "unlocked".
   *
   * @returns boolean
   */
  isPartialLocked(): boolean {
    return this.is(StatusEnum.partialLocked);
  }

  /**
   * Indicates if it is "unlocked".
   *
   * @returns boolean
   */
   isPartialUnlocked(): boolean {
    return this.is(StatusEnum.partialUnlocked);
  }

  /**
   * Indicates if it is "unlocked".
   *
   * @returns boolean
   */
  isUnlocked(): boolean {
    return this.is(StatusEnum.unlocked);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this.value.toString();
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value StatusEnum
   * @returns boolean
   */
  protected is(value: StatusEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
