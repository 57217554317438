import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isString as _isString, isObject as _isObject } from 'lodash';
import { Country } from '@bolt/ui-shared/master-data';

import { ManagerService } from '../../services/export/manager-service/manager.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'bolt-profile-export-localizations-selector',
  template: require('./bolt-profile-export-localizations-selector.html'),
  styles: [require('./bolt-profile-export-localizations-selector.scss')],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BoltProfileExportLocalizationsSelectorComponent implements OnChanges {
  @Input() protected forceReset: boolean;
  @Input() protected forceDefaultTerritory: boolean;
  @Input() protected searchTriggered: boolean;

  protected boltStore: any = {
    translations: {
      addToListButtonLabel: `Disney's add selection to list button label`,
      selectLanguageLabel: `Disney's select a language drop-down header label`,
      selectTerritoryLabel: `Disney's select a territory drop-down header label`,
    }
  };

  protected form: FormGroup;

  constructor(
    protected managerService: ManagerService,
    protected formBuilder: FormBuilder,
    protected changeDetection: ChangeDetectorRef
  ) {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.forceReset) || _isObject(changes.forceDefaultTerritory)) {
      this.resetForm(this.forceDefaultTerritory);
    } else if (_isObject(changes.searchTriggered)) {
      this.changeDetection.detectChanges();
    }
  }

  get maxValuesAsString(): number {
    return this.managerService.getAppConfig().get('ux.multiSelect.maxValuesAsString');
  }

  get scrollHeight(): string {
    return '150px';
  }

  /**
   * Searches by localizations.
   *
   * @returns void
   */
  onSearchLocalizations(): void {
    this.managerService.searchExportableTitle();
  }

  /**
   * Selects the language.
   *
   * @param event any
   * @returns void
   */
  onSelectLanguage(event: any): void {
    if (_isString(event.value)) {
      this.managerService.setCurrentLanguage(event.value);
    } else {
      this.managerService.setCurrentLanguage(undefined);
    }

    this.changeDetection.markForCheck();
  }

  /**
   * Selects the territory.
   *
   * @param event any
   * @returns void
   */
  onSelectTerritory(event: any): void {
    if (_isString(event.value)) {
      this.managerService.setCurrentTerritory(event.value);
    } else {
      this.managerService.setCurrentTerritory(undefined);
    }

    this.changeDetection.markForCheck();
  }

  /**
   * Creates the form.
   *
   * @returns void
   */
  protected createForm(): void {
    this.form = this.formBuilder.group({
      language: [null, Validators.required],
      territory: [null, Validators.required]
    });
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.createForm();
    this.setupBoltStore();
  }

  /**
   * Set up the BoltStore for containing UI basic data like translations.
   *
   * @returns void
   */
  protected setupBoltStore(): void {
    this.populateTranslations();
  }

  /**
   * Populate translations with default language until i18n will be implemented
   *
   * @returns void
   */
  protected populateTranslations(): void {
    this.boltStore.translations.addToListButtonLabel = `Add to list`;
    this.boltStore.translations.selectLanguageLabel = `Select Language`;
    this.boltStore.translations.selectTerritoryLabel = `Select Territory`;
  }

  /**
   * Indicates if it has to block all commands.
   *
   * @returns boolean
   */
  protected hasBlockCommands(): boolean {
    const hasIt: boolean = (
      this.hasBlockSelectors() ||
      this.form.invalid
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block all selectors.
   *
   * @returns boolean
   */
  protected hasBlockSelectors(): boolean {
    const hasIt: boolean = (
      !this.managerService.hasAllNeededData() ||
      (
        this.managerService.isFeaturesSelected()
          ? !this.managerService.hasCurrentMainTitle()
          : !this.managerService.hasCurrentSecondaryTitle()
      ) ||
      this.isSearching() ||
      this.managerService.isExporting()
    );

    if (hasIt) {
      if (this.form.enabled) {
        this.form.disable();
      }
    } else {
      if (this.form.disabled) {
        this.form.enable();
      }
    }

    return hasIt;
  }

  /**
   * Indicates if it has to force reset the value on selectors.
   *
   * @returns boolean
   */
  protected hasForceResetValueOnSelectors(): boolean {
    const hasIt: boolean = (
      this.hasBlockSelectors() &&
      !this.isValidLanguage() &&
      !this.isValidTerritory()
    );

    return hasIt;
  }

  /**
   * Indicates if it is searching.
   *
   * @returns boolean
   */
  protected isSearching(): boolean {
    const isIt: boolean = (
      this.managerService.isSearching() &&
      this.managerService.hasCurrentMainTitle() &&
      !this.managerService.hasCurrentExportableTitle()
    );

    return isIt;
  }

  /**
   * Indicates if the language is valid.
   *
   * @returns boolean
   */
  protected isValidLanguage(): boolean {
    return this.managerService.hasCurrentLanguage();
  }

  /**
   * Indicates if the territory is valid.
   *
   * @returns boolean
   */
  protected isValidTerritory(): boolean {
    return this.managerService.hasCurrentTerritory();
  }

  /**
   * Resets the form.
   *
   * @returns void
   */
  protected resetForm(shouldSetDefaultTerritory: boolean): void {
    this.form.get('language').setValue(null);
    this.form.get('territory').setValue(shouldSetDefaultTerritory ? Country.ALL_VALUE : null);

    if (shouldSetDefaultTerritory) {
      this.managerService.setCurrentTerritory(Country.ALL_VALUE);
    }

    this.changeDetection.markForCheck();
  }
}
