import { Component, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserRole } from '@bolt/ui-shared/auth';
import { ActionTypeEnum, AppConfigProvider, AppConfigurationManager, Privilege } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { sortBy as _sortBy, isArray as _isArray, isNull as _isNull } from 'lodash';

import { Group } from '../../models/group/group.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { UserManager } from 'app/modules/user/helpers/user-manager.helper';

@Component({
  selector: 'bolt-upc-filters',
  template: require('./bolt-upc-filters.html'),
  styles: [require('./bolt-upc-filters.scss')]
})

export class BoltUpcFiltersComponent {
  @Output('changeAction') changeActionEvent: EventEmitter<ActionTypeEnum>;
  @Output('changeRole') changeRoleEvent: EventEmitter<string>;
  @Output('changeSection') changeSectionEvent: EventEmitter<string>;

  protected actionsItems: SelectionItem[];
  protected actionsTypeEnum: typeof ActionTypeEnum = ActionTypeEnum;
  protected form: FormGroup;
  protected privileges: Map<string, Privilege>;
  protected rolesItems: SelectionItem[];
  protected sectionItems: SelectionItem[];

  constructor(
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected formBuilder: FormBuilder,
    protected userManager: UserManager
  ) {
    this.initialize();
  }

  /**
   * Builds the form.
   *
   * @returns void
   */
  protected buildForm(): void {
    this.form = this.formBuilder.group({
      actions: [],
      roles: [],
      sections: []
    });
  }

  /**
   * Indicates if it has actions.
   *
   * @returns boolean
   */
  protected hasActions(): boolean {
    const hasIt: boolean = _isArray(this.actionsItems) && this.actionsItems.length > 0;
    return hasIt;
  }

  /**
   * Indicates if it has roles.
   *
   * @returns boolean
   */
  protected hasRoles(): boolean {
    const hasIt: boolean = _isArray(this.rolesItems) && this.rolesItems.length > 0;
    return hasIt;
  }

  /**
   * Indicates if it has sections.
   *
   * @returns boolean
   */
  protected hasSections(): boolean {
    const hasIt: boolean = _isArray(this.sectionItems) && this.sectionItems.length > 0;
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.privileges = this.appConfigurationManager.getRoleCapabilities();
    this.changeActionEvent = new EventEmitter();
    this.changeRoleEvent = new EventEmitter();
    this.changeSectionEvent = new EventEmitter();

    this.buildForm();
    this.loadActions();
    this.loadRoles();
    this.loadSections();
  }

  /**
   * Loads the actions.
   *
   * @returns void
   */
  protected loadActions(): void {
    const actionTypes: ActionTypeEnum[] = new Array(
      this.actionsTypeEnum.all, this.actionsTypeEnum.delete, this.actionsTypeEnum.lock, this.actionsTypeEnum.merge,
      this.actionsTypeEnum.read, this.actionsTypeEnum.refresh, this.actionsTypeEnum.share, this.actionsTypeEnum.unlock,
      this.actionsTypeEnum.write
    );

    this.actionsItems = new Array();

    actionTypes.forEach(
      (action: ActionTypeEnum) => {
        this.actionsItems.push(new SelectionItem(action.toUpperCase(), action));
      }
    );
  }

  /**
   * Loads the roles.
   *
   * @returns void
   */
  protected loadRoles(): void {
    this.rolesItems = new Array();

    this.userManager.fetchUserRoles().subscribe(
      (serviceResponseCollection: StormServiceResponseCollection) => {
        _sortBy(serviceResponseCollection.collection, 'name').forEach(
          (role: UserRole) => {
            this.rolesItems.push(new SelectionItem(role.name, role.name));
          }
        );
      }
    );
  }

  /**
   * Loads the sections.
   *
   * @returns void
   */
  protected loadSections(): void {
    const candidateItems: SelectionItem[] = new Array();

    this.privileges.forEach(
      (privilege: Privilege) => {
        const section: string = Group.getSectionFrom(privilege).split('-')[0].toUpperCase();

        const isAlreadyAdded: boolean = candidateItems.some(
          (item: SelectionItem) => item.label === section
        );

        if (!isAlreadyAdded) {
          candidateItems.push(new SelectionItem(section, section));
        }
      }
    );

    this.sectionItems = _sortBy(
      candidateItems,
      (item: SelectionItem) => item.label.toLowerCase()
    );
  }

  /**
   * Set and emit the given role as the selected one.
   *
   * @param action ActionTypeEnum
   * @returns void
   */
  protected setAction(action: ActionTypeEnum): void {
    this.form.get('actions').setValue(action);
    this.changeActionEvent.emit(action);
  }

  /**
   * Set and emit the given role as the selected one.
   *
   * @param roleName string
   * @returns void
   */
  protected setRole(roleName: string): void {
    this.form.get('roles').setValue(roleName);
    this.changeRoleEvent.emit(roleName);
  }

  /**
   * Set and emit the given section as the selected one.
   *
   * @param section string
   * @returns void
   */
  protected setSection(section: string): void {
    this.form.get('sections').setValue(section);
    this.changeSectionEvent.emit(section);
  }
}
