import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'bolt-page-character-list',
  template: require('./bolt-page-character-list.html'),
  styles: [require('./bolt-page-character-list.scss')]
})
export class BoltPageCharacterListComponent {
  protected filters: FormGroup;
  protected refreshList: boolean;

  constructor() {
    this.initialize();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.refreshList = false;
  }

  /**
   * Triggers a refresh for the list.
   *
   * @returns void
   */
  protected triggerListRefresh(): void {
    this.refreshList = true;

    setTimeout(
      () => this.refreshList = false,
      100
    );
  }

  /**
   * Updates the filters.
   *
   * @param filters FormGroup
   * @returns void
   */
  protected updateFilters(filters: FormGroup): void {
    this.filters = filters;
  }
}
