import { find as _find } from 'lodash';

import { Episode } from './title/episode/episode.model';
import { Season } from './title/season/season.model';
import { Series } from './title/series/series.model';
import { Title } from './title/title.model';


export class Titles {
  protected _episodes: Episode[];
  protected _seasons: Season[];
  protected _series:  Series[];

  constructor(data: any) {
    this.initialize(data);
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns object
   */
  asEndpointData(): object {
    const data: object = {
      titles: {
        series: this.retrieveEndpointFormatOf(this.series),
        seasons: this.retrieveEndpointFormatOf(this.seasons),
        episodes: this.retrieveEndpointFormatOf(this.episodes)
      }
    };

    return data;
  }

  get episodes(): Episode[] {
    return this._episodes;
  }

  get seasons(): Season[] {
    return this._seasons;
  }

  get series(): Series[] {
    return this._series;
  }

  /**
   * Returns the episodes by language list for the given language and season ID, if they are defined.
   *
   * @param language string
   * @param seasonId number
   * @returns Episode[]
   */
  getEpisodesByLanguage(language?: string, seasonId?: number): Episode[] {
    const episodes: Episode[] = this.episodes.filter(
      (anEpisode: Episode) => {
        const foundIt: boolean = (
          anEpisode.mode.isLanguage() &&
          ((language === undefined) || (anEpisode.getLanguage() === language)) &&
          ((seasonId === undefined) || (anEpisode.seasonId === seasonId))
        );

        return foundIt;
      }
    );

    return episodes;
  }

  /**
   * Returns the episodes by territory list for the given territory and season ID, if they are defined.
   *
   * @param territory string
   * @param seasonId number
   * @returns Episode[]
   */
  getEpisodesByTerritory(territory?: string, seasonId?: number): Episode[] {
    const episodes: Episode[] = this.episodes.filter(
      (anEpisode: Episode) => {
        const foundIt: boolean = (
          anEpisode.mode.isTerritory() &&
          ((territory === undefined) || (anEpisode.getTerritory() === territory)) &&
          ((seasonId === undefined) || (anEpisode.seasonId === seasonId))
        );

        return foundIt;
      }
    );

    return episodes;
  }

  /**
   * Returns the series with the given locale.
   *
   * @param locale string
   * @returns Series
   */
  getSeries(locale: string): Series {
    const language: string = locale.split('_')[0];

    const series: Series[] = this.series.filter(
      (aSeries: Series) => {
        const foundIt: boolean = (aSeries.getLanguage() === language);
        return foundIt;
      }
    );

    if (series.length > 0) {
      return series[0];
    }
  }

  /**
   * Returns the season with the given locale.
   *
   * @param locale string
   * @param seasonNumber number
   * @returns Season
   */
  getSeason(locale: string, seasonNumber: number): Season {
    const language: string = locale.split('_')[0];

    const seasons: Season[] = this.seasons.filter(
      (aSeason: Season) => {
        const foundIt: boolean = (
          (aSeason.seasonNumber === seasonNumber) &&
          (aSeason.getLanguage() === language)
        );

        return foundIt;
      }
    );

    if (seasons.length > 0) {
      return seasons[0];
    }
  }

  /**
   * Returns the list of seasons numbers.
   *
   * @param locale string
   * @returns number[]
   */
  getSeasonNumbers(locale: string): number[] {
    const language: string = locale.split('_')[0];
    const seasonNumbers: number[] = new Array();

    const seasons: Season[] = this.seasons.filter(
      (aSeason: Season) => {
        const foundIt: boolean = (
          (aSeason.getLanguage() === language)
        );

        return foundIt;
      }
    );

    seasons.forEach(
      (season: Season) => {
        if (season.seasonNumber) {
          seasonNumbers.push(season.seasonNumber);
        }
      }
    );

    seasonNumbers.sort();

    return seasonNumbers;
  }

  /**
   * Indicates if the language metadata is locked.
   *
   * @returns boolean
   */
  isLanguageMetadataLocked(): boolean {
    const isLocked: boolean = (
      this.hasAnyLock(this.series) ||
      this.hasAnyLock(this.seasons) ||
      this.hasAnyLock(this.getEpisodesByLanguage())
    );

    return isLocked;
  }

  /**
   * Indicates if the language metadata is locked.
   *
   * @returns boolean
   */
  isTerritoryMetadataLocked(): boolean {
    return this.hasAnyLock(this.getEpisodesByTerritory());
  }

  /**
   * Indicates if the given list has any field that is locked.
   *
   * @param aList Title[]
   * @returns boolean
   */
  protected hasAnyLock(aList: Title[]): boolean {
    const aItem: Title = _find(
      aList,
      (item: Title) => {
        return item.isLocked();
      }
    );

    const hasLocks: boolean = (aItem !== undefined);

    return hasLocks;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this.populateSeries(data);
    this.populateSeasons(data);
    this.populateEpisodes(data);
  }

  /**
   * Populates the episodes with the given data.
   *
   * @param data any
   * @returns void
   */
  protected populateEpisodes(data: any): void {
    this._episodes = new Array();

    if (data && data.hasOwnProperty('episodes')) {
      data.episodes.forEach(
        (episodeData: any) => {
          const episode: Episode = new Episode(episodeData);
          this._episodes.push(episode);
        }
      );
    }
  }

  /**
   * Populates the seasons with the given data.
   *
   * @param data any
   * @returns void
   */
  protected populateSeasons(data: any): void {
    this._seasons = new Array();

    if (data && data.hasOwnProperty('seasons')) {
      data.seasons.forEach(
        (seasonData: any) => {
          const season: Season = new Season(seasonData);
          this._seasons.push(season);
        }
      );
    }
  }

  /**
   * Populates the series with the given data.
   *
   * @param data any
   * @returns void
   */
  protected populateSeries(data: any): void {
    this._series = new Array();

    if (data && data.hasOwnProperty('series')) {
      data.series.forEach(
        (seriesData: any) => {
          const series: Series = new Series(seriesData);
          this._series.push(series);
        }
      );
    }
  }

  /**
   * Retrieves the endpoint format of the given title list.
   *
   * @param titles Title[]
   * @returns object[]
   */
  protected retrieveEndpointFormatOf(titles: Title[]): object[] {
    const data: object[] = new Array();

    titles.forEach(
      (title: Title) => {
        data.push(title.asEndpointData());
      }
    );

    return data;
  }
}
