import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, flatMap, retryWhen, timeout } from 'rxjs/operators';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { isUndefined as _isUndefined } from 'lodash';

import { AuthHelper } from '../auth/auth.helper';


/**
 * @todo Candidate to be removed and use interceptors. Possible tech debt.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthHttp {
  protected retries: number;

  constructor(
    protected appConfig: AppConfigProvider,
    protected authHelper: AuthHelper,
    protected http: HttpClient,
    protected router: Router,
    protected globalHeaders: any[]
  ) {
    this.initialize();
  }

  /**
   * Returns a new observable for a DELETE request.
   *
   * @param url string
   * @param body any
   * @param options any
   * @param retry boolean
   * @returns Observable<HttpResponse<any>>
   */
  delete(url: string, body?: any, options?: any, retry: boolean = true): Observable<HttpResponse<any>> {
    const response: Observable<HttpResponse<any>> = this.ensureToken().pipe(
      flatMap(
        () => this.prepareRequest('DELETE', url, body, options, retry)
      )
    );

    return response;
  }

  /**
   * Returns a new observable for a GET request.
   *
   * @param url string
   * @param options any
   * @param retry boolean
   * @returns Observable<HttpResponse<any>>
   */
  get(url: string, options?: any, retry: boolean = true): Observable<any> {
    const response: Observable<HttpResponse<any>> = this.ensureToken().pipe(
      flatMap(
        () => this.prepareRequest('GET', url, undefined, options, retry)
      )
    );

    return response;
  }

  /**
   * Returns a new observable for a HEAD request.
   *
   * @param url string
   * @param options any
   * @param retry boolean
   * @returns Observable<HttpResponse<any>>
   */
  head(url: string, options?: any, retry: boolean = true): Observable<HttpResponse<any>> {
    const response: Observable<HttpResponse<any>> = this.ensureToken().pipe(
      flatMap(
        () => this.prepareRequest('HEAD', url, undefined, options, retry)
      )
    );

    return response;
  }

  /**
   * Returns a new observable for a PATCH request.
   *
   * @param url string
   * @param body any
   * @param options any
   * @param retry boolean
   * @returns Observable<HttpResponse<any>>
   */
  patch(url: string, body: any, options?: any, retry: boolean = true): Observable<HttpResponse<any>> {
    const response: Observable<HttpResponse<any>> = this.ensureToken().pipe(
      flatMap(
        () => this.prepareRequest('PATCH', url, body, options, retry)
      )
    );

    return response;
  }

  /**
   * Returns a new observable for a POST request.
   *
   * @param url string
   * @param body any
   * @param options any
   * @param retry boolean
   * @returns Observable<HttpResponse<any>>
   */
  post(url: string, body: any, options?: any, retry: boolean = true): Observable<HttpResponse<any>> {
    const response: Observable<HttpResponse<any>> = this.ensureToken().pipe(
      flatMap(
        () => this.prepareRequest('POST', url, body, options, retry)
      )
    );

    return response;
  }

  /**
   * Returns a new observable for a PUT request.
   *
   * @param url string
   * @param body any
   * @param options any
   * @param retry boolean
   * @returns Observable<HttpResponse<any>>
   */
  put(url: string, body: any, options?: any, retry: boolean = true): Observable<HttpResponse<any>> {
    const response: Observable<HttpResponse<any>> = this.ensureToken().pipe(
      flatMap(
        () => this.prepareRequest('PUT', url, body, options, retry)
      )
    );

    return response;
  }

  /**
   * Ensures there is a token.
   *
   * @returns Observable<boolean>
   */
  protected ensureToken(): Observable<boolean> {
    const obs: Observable<boolean> = this.authHelper.getTokenObjectAsObservable().pipe(
      retryWhen(
        (errors: Observable<any>) => errors.pipe(delay(500))
      ),
      timeout(5000)
    );

    return obs;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.retries = this.appConfig.get('serviceConfig.global.request.retries', 0);
  }

  /**
   * Returns a new observable for preparing the given request.
   *
   * @param request HttpRequest<any>
   * @param url: string
   * @param body: any
   * @param options: any
   * @param retry: boolean
   * @returns Observable<HttpResponse<any>>
   */
  protected prepareRequest(method: string, url: string, body: any, options: any, retry: boolean): Observable<HttpResponse<any>> {
    const auth: string[] = this.authHelper.getAuthorizationHeader();
    let reattempt: number = 0;

    const params: any = {
      headers: { }
    };

    this.globalHeaders.forEach(
      (header: any) => {
        const key: string = Object.keys(header)[0];
        const value: string = header[key];

        params.headers[key] = value;
      }
    );

    params.headers[auth[0]] = auth[1];

    if (body) {
      params.body = body;
    }

    if (options) {
      Object.keys(options).forEach(
        (key: string) => {
          params[key] = options[key];
        }
      );
    }

    const obs: Observable<any> = this.http.request(method, url, params).pipe(
      retryWhen(
        (errors: Observable<any>) => errors.pipe(
          flatMap(
            (error: HttpErrorResponse) => {
              let retryObs: Observable<any>;

              if (retry) {
                reattempt++;

                if ((reattempt <= this.retries) && (error.status >= 500)) {
                  retryObs = of(error).pipe(delay(2000 * reattempt));
                } else {
                  retryObs = throwError(error);
                }
              } else {
                retryObs = throwError(error);
              }

              return retryObs;
            }
          )
        )
      ),
      catchError(
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.authHelper.logout(true, true);
            this.router.navigate(['/auth/login']);
          }

          return throwError(error);
        }
      )
    );

    return obs;
  }
}
