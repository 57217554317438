import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { Talent, TalentInterface } from '../../models/talent.model';
import { TypeEnum as CreditType } from 'app/modules/credits/models/wizard/candidate/type/type.enum';

import {
  FormFactoryService as  TalentFormFactoryService
} from 'app/modules/talent/services/form-factory/form-factory.service';

import { TalentMetadata } from '../../models/talent-metadata.model';
import { TalentService } from '../../services/talent.service';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { TalentManager } from '../../helpers/talent-manager/talent-manager.helper';
import { EditHistoryMetadata } from 'app/modules/common/components/bolt-edit-history/bolt-edit-history.component';


@Component({
  selector: 'bolt-talent-level-data',
  template: require('./bolt-talent-level-data.html'),
  styles: [require('./bolt-talent-level-data.scss')],
})
export class BoltTalentLevelDataComponent implements OnChanges {
  @Input('BoltTalentLevelDataTalent') talent: Talent = new Talent();
  @Input('BoltTalentLevelDataShow') showData: boolean = false;

  @Output('BoltTalentLevelDataTalentUpdate') talentUpdate: EventEmitter<TalentInterface> =
    new EventEmitter<TalentInterface>();

  protected isMergeModalOpen: boolean;
  protected notificationsContainer = notificationsContainer;
  protected talentForm: OriginalDataForm;
  protected showEditHistory: boolean = false;

  constructor(
    protected capabilitiesManager: CapabilitiesManager,
    protected talentService: TalentService,
    protected notificationService: NotificationService,
    protected talentFormFactory: TalentFormFactoryService,
    protected talentManager: TalentManager
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.talent && changes.talent.currentValue) {
      this.setupForm();
      this.setFormState();
    }
  }

  getMetadata(): EditHistoryMetadata {
    let talentName: string;

    // Map Talent attributes (prefix, suffix...)
    this.talentManager.mapAttributes(
      [new Talent(this.talent.getRawObject())]
    ).subscribe(
      mappedTalent => talentName = (<TalentInterface>mappedTalent[0]).getFullName()
    );

    return {
      id: this.talent.id,
      locale: this.talent.locale,
      type: CreditType.talent,
      imdb: this.talent.imdbId,
      name: talentName,
      language: this.talent.language,
      territory: this.talent.territory,
      productType: this.talent.productType,
      account: this.talent.account,
      localeObject: this.talent.localeObject
    };
  }

  /**
   * Close the edit history modal.
   *
   * @returns void
   */
  closeEditHistory(): void {
    this.showEditHistory = false;
  }

  /**
   * Open the edit history modal.
   *
   * @returns void
   */
  openEditHistory(): void {
    this.showEditHistory = true;
  }

  /**
   * Sets up the form.
   *
   * @return void
   */
  protected setupForm(): void {
    this.talentForm = this.talentFormFactory.buildOriginalDataForm(this.talent, false);
  }

  /**
   * Set the form's state.
   *
   * @returns void
   */
  protected setFormState(): void {
    setTimeout(() => {
      this.talentForm[this.talent.locked ? 'disable' : 'enable']();
    }, 0);
  }

  /**
   * Toggle the Talent lock state
   *
   * @return void
   */
  protected toggleLockedStateTalent(): void {
    this.talentService.updateTalent(
      { talentId: this.talent.id },
      <TalentInterface>{ locked: !this.talent.locked }
    ).subscribe(
      response => {
        this.talent = response.item;
        this.talentUpdate.emit(this.talent);

        this.notificationService.handleNotice(
          'Locked status changed',
          'The Talent has been ' + (this.talent.locked ? 'locked' : 'unlocked'),
          this.notificationsContainer.talent.details.key
        );
      },
      error => {
        this.notificationService.handleError('The locked status could not be changed',
        error,
        this.notificationsContainer.talent.details.key
        );
      }
    );

  }

  /**
   * Updates the Talent's Data
   *
   * @return void
   */
  protected updateTalentLevelData() {
    this.talentService.updateTalent(
      { talentId: this.talent.id },
      <TalentInterface>this.talentForm.toJson()
    ).subscribe(
      response => {
        this.talent = response.item;
        this.talentUpdate.emit(this.talent);

        this.notificationService.handleNotice(
          'Product updated',
          'The Talent Level Data has been saved',
          this.notificationsContainer.talent.details.key
        );
      },
      error => {
        this.notificationService.handleError(
          'The Talent Level Data could not be saved',
          error,
          this.notificationsContainer.talent.details.key
          );
      }
    );
  }

  /**
   * Closes the merge modal.
   *
   * @returns void
   */
  protected closeMergeModal(): void {
    this.isMergeModalOpen = false;
  }

  /**
   * Indicates if it has to display the merge button.
   *
   * @returns boolean
   */
  protected hasDisplayMergeButton(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(
      modulesPath.titles.credits.talent.path,
      [ActionTypeEnum.merge]
    );
  }

  /**
   * Indicates if it has to open the merge modal.
   *
   * @returns boolean
   */
  protected hasOpenMergeModal(): boolean {
    return this.isMergeModalOpen;
  }

  /**
   * Opens the merge modal.
   *
   * @returns void
   */
  protected openMergeModal(): void {
    this.isMergeModalOpen = true;
  }

  /**
   * Refreshes the current talent with the given values.
   *
   * @param talent Talent
   * @returns void
   */
  protected refreshTalent(talent: Talent): void {
    const mappedLocalizations: TalentMetadata[] = new Array();

    talent.localizations.forEach(
      (localization: any) => {
        mappedLocalizations.push(new TalentMetadata(localization));
      }
    );

    talent.localizations = mappedLocalizations;
    this.talent = talent;

    this.talentUpdate.emit(this.talent);
  }
}
