import { Component, Input, Output, EventEmitter, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { Language } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isObject as _isObject } from 'lodash';

import { CharacterMetadataInterface } from '../../models/character-metadata.model';
import { CharacterMetadataManager } from '../../helpers/character-metadata-manager/character-metadata-manager';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { RoleCapabilitiesHandler } from 'app/modules/cat/services/role-capabilities-handler/role-capabilities-handler';
import { TypeEnum as ItemType } from 'app/modules/cat/models/type/type.enum';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-character-localized-list',
  template: require('./bolt-character-localized-list.html'),
  styles: [require('./bolt-character-localized-list.scss')]
})
export class BoltCharacterLocalizedListComponent extends StormComponent implements OnInit {
  @Input() selectedLocalization: CharacterMetadataInterface;

  @Output('addLocalization') addLocalizationEvent: EventEmitter<undefined>;
  @Output('selected') selectEvent: EventEmitter<CharacterMetadataInterface>;

  @ViewChild('filter', { static: true }) protected filterInput: ElementRef;

  protected filterObserver: BehaviorSubject<undefined>;
  protected filterQuery: string;
  protected mappedLocalizations: CharacterMetadataInterface[];
  protected pager: MemoryPager;

  constructor(
    protected appConfig: AppConfigProvider,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler,
    protected characterMetadataManager: CharacterMetadataManager,
    protected notificationService: NotificationService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.setupFilter();
    this.listenCharacterChanges();
  }

  /**
   * Adds a new localization.
   *
   * @return void
   */
  protected addCharacterMetadata(): void {
    this.addLocalizationEvent.emit();
  }

  /**
   * Applies the filter.
   *
   * @returns void
   */
  protected applyFilter(): void {
    let filteredCharacters: CharacterMetadataInterface[] = new Array();

    this.filterQuery = this.filterInput.nativeElement.value.trim().toLocaleLowerCase();

    if (this.filterQuery) {
      this.mappedLocalizations.forEach(
        (character: CharacterMetadataInterface) => {
          if ((<Language>character.language).name.trim().toLocaleLowerCase().includes(this.filterQuery)) {
            filteredCharacters.push(character);
          }
        }
      );
    } else {
      filteredCharacters = this.mappedLocalizations;
    }

    this.pager.setRecords(filteredCharacters);
    this.trySetPageNumberForSelected();
  }

  /**
   * Returns the current page localizations.
   *
   * @returns CharacterMetadataInterface[]
   */
  protected getCurrentPagedLocalizations(): CharacterMetadataInterface[] {
    return this.pager.getCurrentPage();
  }

  /**
   * Returns the sorting criteria for Localizations.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (characterA: CharacterMetadataInterface, characterB: CharacterMetadataInterface) => {
      const languageA: Language = (<Language>characterA.language);
      const languageB: Language = (<Language>characterB.language);

      if (languageA.id === Language.ENGLISH_ID) {
        return -1;
      } else if (languageB.id === Language.ENGLISH_ID) {
        return 1;
      } else {
        if (languageA.name < languageB.name) {
          return -1;
        } else if (languageA.name > languageB.name) {
          return 1;
        } else {
          return 0;
        }
      }
    };

    return criteria;
  }

  /**
   * Returns the comparison criteria for localizations.
   *
   * @returns CallableFunction
   */
  protected getLocalizationComparisonCriteria(): CallableFunction {
    const criteria: CallableFunction = (characterA: CharacterMetadataInterface, characterB: CharacterMetadataInterface) => {
      return characterA.id === characterB.id;
    };

    return criteria;
  }

  /**
   * Returns the localizations.
   *
   * @returns CharacterMetadataInterface[]
   */
  protected getLocalizations(): CharacterMetadataInterface[] {
    let localizations: CharacterMetadataInterface[] = new Array();

    if (this.characterMetadataManager.hasCharacter()) {
      localizations = this.characterMetadataManager.character.localizations;
    }
    return localizations;
  }

  /**
   * Indicates if it has to block the clean filter button.
   *
   * @returns boolean
   */
  protected hasBlockCleanFilter(): boolean {
    const hasIt: boolean = (this.hasBlockFilter() || !this.filterQuery);
    return hasIt;
  }

  /**
   * Indicates if it has to block the filter input.
   *
   * @returns boolean
   */
  protected hasBlockFilter(): boolean {
    return !this.hasDisplayList();
  }

  /**
   * Indicates if it has to display the add button.
   *
   * @returns boolean
   */
  protected hasDisplayAddButton(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalized(
      ItemType.Character,
      ActionTypeEnum.write
    );
  }

  /**
   * Indicates if it has to display the paginator.
   *
   * @returns boolean
   */
  protected hasDisplayPaginator(): boolean {
    const hasIt: boolean = (this.hasDisplayList() && this.hasFilteredLocalizations());
    return hasIt;
  }

  /**
   * Indicates if it has to display the list.
   *
   * @returns boolean
   */
  protected hasDisplayList(): boolean {
    return this.characterMetadataManager.hasCharacter();
  }

  /**
   * Indicates if it has filtered localizations.
   *
   * @returns boolean
   */
  protected hasFilteredLocalizations(): boolean {
    return this.pager.hasRecords();
  }

  /**
   * Indicates if it has a selected localization.
   *
   * @returns boolean
   */
  protected hasSelected(): boolean {
    return _isObject(this.selectedLocalization);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.addLocalizationEvent = new EventEmitter();
    this.mappedLocalizations = new Array();
    this.selectEvent = new EventEmitter();

    this.pager = new MemoryPager(
      this.appConfig.get('ux.page.cat.localizationPageSize'),
      this.getSortingCriteria(),
    );
  }

  /**
   * Listens when character changes.
   *
   * @returns void
   */
  protected listenCharacterChanges(): void {
    this.characterMetadataManager.characterListener.pipe(debounceTime(0)).subscribe(
      () => {
        this.mapLocalizations();
      }
    );
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Sets the mapped localizations into mappedLocalizations using the current localizations
   *
   * @returns void
   */
  protected mapLocalizations(): void {
    this.mappedLocalizations = new Array();

    this.characterMetadataManager.mapAttributes(this.getLocalizations()).subscribe(
      (response: CharacterMetadataInterface[]) => {
        this.mappedLocalizations = response;
        this.applyFilter();
      }
    );
  }

  /**
   * Notifies that the filter changed.
   *
   * @returns void
   */
  protected notifyFilterChange(): void {
    this.filterObserver.next(undefined);
  }

  /**
   * Reset the filter.
   *
   * @returns void
   */
  protected resetFilter(): void {
    this.filterQuery = '';

    if (this.filterInput) {
      this.filterInput.nativeElement.value = this.filterQuery;
    }

    this.pager.setRecords(this.mappedLocalizations);
    this.trySetPageNumberForSelected();
  }

  /**
   * Set the given Character Metadata as selected.
   *
   * @param selected CharacterMetadataInterface
   * @returns void
   */
  protected select(selected: CharacterMetadataInterface): void {
    this.selectEvent.emit(selected);
  }

  /**
   * Set up the filter.
   *
   * @returns void
   */
  protected setupFilter(): void {
    this.filterObserver = new BehaviorSubject(undefined);
    this.filterQuery = '';

    this.filterObserver.asObservable().pipe(debounceTime(200)).subscribe(
      () => {
        this.applyFilter();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed loading localized data.', error, notificationsContainer.character.details.key);
      }
    );
  }

  /**
   * Tries to set the correct page for the current selection. If there is no selection,
   * tries to select the first element of the current page.
   *
   * @returns void
   */
  protected trySetPageNumberForSelected(): void {
    if (this.hasSelected()) {
      try {
        this.pager.setPageNumberFor(this.selectedLocalization, this.getLocalizationComparisonCriteria());
      } catch (error) {
        this.trySelectFirst();
      }
    } else {
      this.trySelectFirst();
    }
  }

  /**
   * Tries to select the first filtered item.
   *
   * @returns void
   */
  protected trySelectFirst(): void {
    if (this.hasFilteredLocalizations()) {
      this.select(this.getCurrentPagedLocalizations()[0]);
    } else {
      this.select(undefined);
    }
  }
}
