import { Conflict } from './conflict/conflict.model';
import { Error } from '../../error/error.model';
import { Resolution } from './resolution/resolution.model';
import { ResolutionEnum } from './resolution/resolution.enum';
import { Status } from './status/status.model';


export abstract class Field {
  protected _conflict: Conflict;
  protected _currentValue: any;
  protected _editable: boolean;
  protected _error: Error;
  protected _fieldName: string;
  protected _newValue: any;
  protected _readOnly: boolean;
  protected _resolution: Resolution;
  protected _status: Status;

  /**
   * This is mandatory for Typescript and it doesn't have sense.
   */
  constructor(data: any) {
    this.initialize(data);
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns Object
   */
  asEndpointData(includeNewValue: boolean = false): object {
    const data: object = {
      fieldName: this.fieldName,
      resolution: this.resolution.type
    };

    if (includeNewValue) {
      (<any>data).newValue = this.newValue;
    }

    return data;
  }

  get conflict(): Conflict {
    return this._conflict;
  }

  get currentValue(): any {
    return this._currentValue;
  }

  get editable(): boolean {
    return this._editable;
  }

  get error(): Error {
    return this._error;
  }

  get fieldName(): string {
    return this._fieldName;
  }

  get newValue(): any {
    return this._newValue;
  }

  get readOnly(): boolean {
    return this._readOnly;
  }

  get resolution(): Resolution {
    return this._resolution;
  }

  get status(): Status {
    return this._status;
  }

  /**
   * Indicates if there is any change in the value.
   *
   * @returns boolean
   */
  hasDifference(): boolean {
    return !this.conflict.isNone();
  }

  /**
   * Indicates if the value has been deleted.
   *
   * @returns boolean
   */
  hasBeenDeleted(): boolean {
    return this.conflict.isDelete();
  }

  /**
   * Checks whether the value was updated or is new.
   *
   * @returns boolean
   */
  hasBeenUpdated(): boolean {
    return this.conflict.isUpdate();
  }

  /**
   * Checks whether the field has an error
   *
   * @returns boolean
   */
  hasError(): boolean {
    const hasIt: boolean = (
      this.error !== undefined
    );

    return hasIt;
  }

  /**
   * Indicates if it is a Boolean.
   *
   * @returns boolean
   */
  isBoolean(): boolean {
    return false;
  }

  /**
   * Indicates if it is a Date.
   *
   * @returns boolean
   */
  isDate(): boolean {
    return false;
  }

  /**
   * Indicates if it is a Number.
   *
   * @returns boolean
   */
  isNumber(): boolean {
    return false;
  }

  /**
   * Indicates if it is a String.
   *
   * @returns boolean
   */
  isString(): boolean {
    return false;
  }

  /**
   * Updates the resolution.
   *
   * @returns void
   */
  updateResolution(resolution: ResolutionEnum): void {
    this._resolution = new Resolution(resolution);
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const conflict: any = (
      (data && data.hasOwnProperty('conflict'))
        ? data.conflict
        : undefined
    );

    const resolution: any = (
      (data && data.hasOwnProperty('resolution'))
        ? data.resolution
        : undefined
    );

    const status: any = (
      (data && data.hasOwnProperty('status'))
        ? data.status
        : undefined
    );

    this._currentValue = (
      (data && data.hasOwnProperty('currentValue'))
        ? this.parseValue(data.currentValue)
        : undefined
    );

    this._editable = (
      (data && data.hasOwnProperty('editable'))
        ? data.editable
        : undefined
    );

    this._error = (
      (data && data.hasOwnProperty('error'))
        ? new Error(data.error)
        : undefined
    );

    this._fieldName = (
      (data && data.hasOwnProperty('fieldName'))
        ? data.fieldName
        : undefined
    );

    this._newValue = (
      (data && data.hasOwnProperty('newValue'))
        ? this.parseValue(data.newValue)
        : undefined
    );

    this._readOnly = (
      (data && data.hasOwnProperty('readOnly'))
        ? data.readOnly
        : undefined
    );

    this._conflict = new Conflict(conflict);
    this._status = new Status(status);

    this.updateResolution(resolution);
  }

  /**
   * Indicates if the "current value" is defined.
   *
   * @returns boolean
   */
  protected isCurrentValueDefined(): boolean {
    const isIt = (
      this.currentValue !== undefined
    );

    return isIt;
  }

  /**
   * Indicates if the "new value" is defined.
   *
   * @returns boolean
   */
  protected isNewValueDefined(): boolean {
    const isIt = (
      this.newValue !== undefined
    );

    return isIt;
  }

  /**
   * Indicates if the given value is defined.
   *
   * @param value any
   * @returns boolean
   */
  protected isValueDefined(value: any): boolean {
    const isIt: boolean = (
      (value !== undefined) &&
      (value !== null)
    );

    return isIt;
  }

  /**
   * Indicates if the given value is a number.
   *
   * @param value any
   * @returns boolean
   */
  protected isValueNumber(value: any): boolean {
    const isIt: boolean = (
      !Number.isNaN(Number.parseFloat(value))
    );

    return isIt;
  }

  /**
   * Parse the given value with the expected type.
   *
   * @param value any
   * @returns any
   */
  protected abstract parseValue(value: any): any;
}
