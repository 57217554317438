import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormModule } from '@bolt/ui-shared/form';

import { BoltCommonModule } from '../common/common.module';
import { CatModule } from '../cat/cat.module';
import { CloneModule } from '../clone/clone.module';
import { GroupModule } from '../group/group.module';
import { MasterDataModule } from '../masterData/master-data.module';
import { SubtitleModule } from '../subtitle/subtitle.module';
import { titleModuleRouting } from './title.routing';
import { SharedModule } from 'app/shared/shared.module';

import { ActionHandlerService as LevelDataActionHandlerService } from './services/level-data/action-handler/action-handler.service';

import {
  BoltPageTitleDetailsComponent
} from './components/bolt-page-title-details/bolt-page-title-details.component';

import { BoltTitleLevelDataComponent } from './components/bolt-title-level-data/bolt-title-level-data.component';
import { BoltTitleLevelDataActionsComponent } from './components/bolt-title-level-data-actions/bolt-title-level-data-actions.component';

import { BoltTitleLevelDetailsComponent } from './components/bolt-title-level-details/bolt-title-level-details.component';
import { BoltTitleListSearchResultComponent } from './components/bolt-title-list-search-result/bolt-title-list-search-result.component';
import { BoltTitleLocaleManagerComponent } from './components/bolt-title-locale-manager/bolt-title-locale-manager.component';
import { BoltTitleMetadataComponent } from './components/bolt-title-metadata/bolt-title-metadata.component';
import { BoltTitleMetadataFiltersComponent } from './components/bolt-title-metadata-filters/bolt-title-metadata-filters.component';
import { BoltTitleMetadataListComponent } from './components/bolt-title-metadata-list/bolt-title-metadata-list.component';
import { BoltTitleMetadataListLanguageComponent } from './components/bolt-title-metadata-list-language/bolt-title-metadata-list-language.component';

import {
  BoltTitleMetadataOriginalVersionLanguageComponent
} from './components/bolt-title-metadata-original-version-language/bolt-title-metadata-original-version-language.component';

import { BoltTitleSeasonEpisodesListComponent } from './components/bolt-title-season-episodes-list/bolt-title-season-episodes-list.component';
import { BoltTitleSeriesSeasonsListComponent } from './components/bolt-title-series-season-list/bolt-title-series-season-list.component';
import { ProductLayoutHelper } from './helpers/product-layout/product-layout.helper';
import { StormPageTitleComponent } from './components/storm-page-title/storm-page-title.component';
import { StormPageTitleListComponent } from './components/storm-page-title-list/storm-page-title-list.component';
import { StormTitleListComponent } from './components/storm-title-list/storm-title-list.component';
import { StormTitleListFiltersComponent } from './components/storm-title-list-filters/storm-title-list-filters.component';
import { TitleService } from './services/title.service';

import {
  BoltTitleLocalizationEditHistoryComponent
} from './components/bolt-title-localization-edit-history/bolt-title-localization-edit-history.component';

import {
  BoltTitleLocalizationEditHistoryMapperComponent
} from './components/bolt-title-localization-edit-history-mapper/bolt-title-localization-edit-history-mapper.component';

import {
  BoltTitleLevelDataEpisodeFormComponent
} from './components/bolt-title-level-data-episode-form/bolt-title-level-data-episode-form.component';

import {
  BoltTitleLevelDataFeatureFormComponent
} from './components/bolt-title-level-data-feature-form/bolt-title-level-data-feature-form.component';

import {
  BoltTitleLevelDataSeasonFormComponent
} from './components/bolt-title-level-data-season-form/bolt-title-level-data-season-form.component';

import {
  BoltTitleLevelDataSeriesFormComponent
} from './components/bolt-title-level-data-series-form/bolt-title-level-data-series-form.component';

import { FormHandlerService } from './services/level-data/form-handler/form-handler.service';
import { CreditsModule } from '../credits/credits.module';
import { BoltTitleLanguageLocalizationFormComponent } from './components/bolt-title-language-localization-form/bolt-title-language-localization-form.component';
import { BoltTitleTerritoryLocalizationFormComponent } from './components/bolt-title-territory-localization-form/bolt-title-territory-localization-form.component';
import { BoltTitleAccountLocalizationFormComponent } from './components/bolt-title-account-localization-form/bolt-title-account-localization-form.component';
import { BoltTitleLocalizationManagerComponent } from './components/bolt-title-localization-manager/bolt-title-localization-manager.component';
import { BoltTitleLocalizationAttributeActionsComponent } from './components/bolt-title-localization-attribute-actions/bolt-title-localization-attribute-actions.component';
import { BoltTitleAccountLocalizationDetailsComponent } from './components/bolt-title-account-localization-details/bolt-title-account-localization-details.component';
import { BoltTitleLanguageLocalizationDetailsComponent } from './components/bolt-title-language-localization-details/bolt-title-language-localization-details.component';
import { BoltTitleTerritoryLocalizationDetailsComponent } from './components/bolt-title-territory-localization-details/bolt-title-territory-localization-details.component';
import { BoltTitleLocalizationDetailsComponent } from './components/bolt-title-localization-details/bolt-title-localization-details.component';
import { BoltTitleMetadataListAccountComponent } from './components/bolt-title-metadata-list-account/bolt-title-metadata-list-account.component';
import { BoltTitleMetadataListTerritoryComponent } from './components/bolt-title-metadata-list-territory/bolt-title-metadata-list-territory.component';
import { MetadataExportModule } from '../metadataExport/metadataExport.module';
import { BoltRatingViewComponent } from './components/bolt-rating-view/bolt-rating-view.component';
import { BoltRatingService } from './services/bolt-rating.service';
import { BoltTitleLocalizationAttributesActionsComponent } from './components/bolt-title-localization-attributes-actions/bolt-title-localization-attributes-actions.component';
import { TitleLocalizationService } from './services/title-localization.service';


@NgModule({
  imports: [
    BoltCommonModule,
    CatModule,
    CreditsModule,
    CloneModule,
    CommonModule,
    FormModule,
    FormsModule,
    SubtitleModule,
    titleModuleRouting,
    MasterDataModule,
    MetadataExportModule,
    GroupModule,
    SharedModule
  ],
  declarations: [
    BoltPageTitleDetailsComponent,
    BoltTitleLevelDataComponent,
    BoltTitleLevelDataActionsComponent,
    BoltTitleLevelDataEpisodeFormComponent,
    BoltTitleLevelDataFeatureFormComponent,
    BoltTitleLevelDataSeasonFormComponent,
    BoltTitleLevelDataSeriesFormComponent,
    BoltTitleLevelDetailsComponent,
    BoltTitleListSearchResultComponent,
    BoltTitleLocaleManagerComponent,
    BoltTitleLocalizationEditHistoryComponent,
    BoltTitleLocalizationEditHistoryMapperComponent,
    BoltTitleMetadataComponent,
    BoltTitleMetadataFiltersComponent,
    BoltTitleMetadataListComponent,
    BoltTitleMetadataListAccountComponent,
    BoltTitleMetadataListLanguageComponent,
    BoltTitleMetadataListTerritoryComponent,
    BoltTitleMetadataOriginalVersionLanguageComponent,
    BoltTitleSeasonEpisodesListComponent,
    BoltTitleSeriesSeasonsListComponent,
    BoltTitleLocalizationManagerComponent,
    BoltTitleLanguageLocalizationFormComponent,
    BoltTitleLanguageLocalizationDetailsComponent,
    BoltTitleTerritoryLocalizationFormComponent,
    BoltTitleTerritoryLocalizationDetailsComponent,
    BoltTitleAccountLocalizationFormComponent,
    BoltTitleAccountLocalizationDetailsComponent,
    BoltTitleLocalizationAttributeActionsComponent,
    BoltTitleLocalizationAttributesActionsComponent,
    BoltTitleLocalizationDetailsComponent,
    StormPageTitleComponent,
    StormPageTitleListComponent,
    StormTitleListComponent,
    StormTitleListFiltersComponent,
    BoltRatingViewComponent,
  ],
  exports: [
    BoltPageTitleDetailsComponent,
    BoltTitleLevelDetailsComponent,
    BoltTitleLocalizationDetailsComponent,
    StormPageTitleComponent,
    StormPageTitleListComponent
  ],
  providers: [
    ProductLayoutHelper,
    TitleService,
    TitleLocalizationService,
    LevelDataActionHandlerService,
    FormHandlerService,
    BoltRatingService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class TitleModule { }
