import * as moment from 'moment';


export class Episode {
  protected _id: number;
  protected _f0Num: number;
  protected _title: string;
  protected _radarProductId: number;
  protected _firstAirReleaseDate: moment.Moment;
  protected _firstAirRunningOrder: string;
  protected _seasonId: number;

  constructor(
    id: number,
    f0Num: number,
    title: string,
    radarProductId: number,
    firstAirReleaseDate: moment.Moment,
    firstAirRunningOrder: string,
    seasonId: number
  ) {
    this.initialize(id, f0Num, title, radarProductId, firstAirReleaseDate, firstAirRunningOrder, seasonId);
  }

  get id(): number {
    return this._id;
  }

  get f0Num(): number {
    return this._f0Num;
  }

  get title(): string {
    return this._title;
  }

  get radarProductId(): number {
    return this._radarProductId;
  }

  get firstAirReleaseDate(): moment.Moment {
    return this._firstAirReleaseDate;
  }

  get firstAirRunningOrder(): string {
    return this._firstAirRunningOrder;
  }

  get seasonId(): number {
    return this._seasonId;
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns number
   */
  asEndpointData(): number {
    return this.id;
  }

  /**
   * Initializes the instance.
   *
   * @param id number
   * @param f0Num number
   * @param episodeName string
   * @param radarProductId number
   * @param firstAirReleaseDate moment.Moment
   * @param firstAirRunningOrder string
   * @param seasonId number
   * @returns void
   */
  protected initialize(
    id: number,
    f0Num: number,
    episodeName: string,
    radarProductId: number,
    firstAirReleaseDate: moment.Moment,
    firstAirRunningOrder: string,
    seasonId: number
  ): void {
    this.setId(id);
    this.setF0Num(f0Num);
    this.setEpisodeName(episodeName);
    this.setRadarProductId(radarProductId);
    this.setFirstAirReleaseDate(firstAirReleaseDate);
    this.setFirstAirRunningOrder(firstAirRunningOrder);
    this.setSeasonId(seasonId);
  }

  /**
   * Set the episode ID.
   *
   * @param id number
   * @returns void
   */
  protected setId(id: number): void {
    this._id = id;
  }

  /**
   * Set the f0Num.
   *
   * @param f0Num number
   * @returns void
   */
  protected setF0Num(f0Num: number): void {
    this._f0Num = f0Num;
  }

  /**
   * Set the episode name.
   *
   * @param episodeName string
   * @returns void
   */
  protected setEpisodeName(episodeName: string): void {
    this._title = episodeName;
  }

  /**
   * Set the radar product ID.
   *
   * @param radarProductId number
   * @returns void
   */
  protected setRadarProductId(radarProductId: number): void {
    this._radarProductId = radarProductId;
  }

  /**
   * Set the first air running order.
   *
   * @param firstAirReleaseDate moment.Moment
   * @returns void
   */
  protected setFirstAirReleaseDate(firstAirReleaseDate: moment.Moment): void {
    this._firstAirReleaseDate = firstAirReleaseDate;
  }

  /**
   * Set the first air running order.
   *
   * @param firstAirRunningOrder string
   * @returns void
   */
  protected setFirstAirRunningOrder(firstAirRunningOrder: string): void {
    this._firstAirRunningOrder = firstAirRunningOrder;
  }

  /**
   * Set the season ID.
   *
   * @param seasonId number
   * @returns void
   */
  protected setSeasonId(seasonId: number): void {
    this._seasonId = seasonId;
  }
}
