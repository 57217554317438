import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isNaN as _isNaN, isObject as _isObject } from 'lodash';


@Injectable()
export class UrlHandlerService {
  protected _projectId: number;
  protected listeningUrlListener: Subscription;
  protected startedOn: boolean;

  constructor(protected router: Router) {
    this.turnStartedOnFalse();
  }

  get projectId(): number {
    return this._projectId;
  }

  /**
   * Clears the URL.
   *
   * @returns void
   */
  clearUrl(): void {
    this.setProjectId(undefined);
    this.router.navigate(['/projects']);
  }

  /**
   * Indicates if it is started.
   *
   * @returns boolean
   */
  isStarted(): boolean {
    return this.startedOn;
  }

  /**
   * Indicates if it is stopped.
   *
   * @returns boolean
   */
  isStopped(): boolean {
    return !this.isStarted();
  }

  /**
   * Starts it and runs `onUrlUpdate` when the URL changes.
   *
   * @param onUrlUpdate CallableFunction
   * @returns void
   */
  start(onUrlUpdate: CallableFunction): void {
    if (this.isStopped()) {
      this.turnStartedOnTrue();

      this.listeningUrlListener = this.router.events.subscribe(
        (event: any) => {
          if ((event instanceof NavigationEnd)) {
            const urlParts: string[] = event.url.split('/');

            if (urlParts.length === 3) {
              const candidateId: number = Number(urlParts.pop());

              if (_isNaN(candidateId)) {
                this._projectId = -1;
              } else {
                this.updateUrl(candidateId);
              }
            } else {
              this.clearUrl();
            }

            onUrlUpdate();
          }
        }
      );
    }
  }

  /**
   * Stops it.
   *
   * @returns void
   */
  stop(): void {
    if (_isObject(this.listeningUrlListener)) {
      this.listeningUrlListener.unsubscribe();
    }

    this.turnStartedOnFalse();
    this.setProjectId(undefined);
  }

  /**
   * Updates the URL with the given ID.
   *
   * @param id number
   * @returns void
   */
  updateUrl(id: number): void {
    this.setProjectId(id);
    this.router.navigate(['/projects', id]);
  }

  /**
   * Set the project ID.
   *
   * @param id number
   * @return void
   */
  protected setProjectId(id: number): void {
    this._projectId = id;
  }

  /**
   * Turns `startedOn` as false.
   *
   * @returns void
   */
  protected turnStartedOnFalse(): void {
    this.startedOn = false;
  }

  /**
   * Turns `startedOn` as true.
   *
   * @returns void
   */
  protected turnStartedOnTrue(): void {
    this.startedOn = true;
  }
}
