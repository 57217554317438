import { FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';

import { TalentForm } from '../talent-form.model';


export class LocalizedDataForm extends TalentForm {
  protected languageField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  getLanguageField(): FormControl {
    return this.languageField;
  }

  toJson(): any {
    const data: any = {
      ...super.toJson(),
      language: this.getLanguageField().value
    };

    return data;
  }

  protected getNoAffixCustomRequiredValidator(noAffixControl: AbstractControl): ValidatorFn | undefined {
    // Sending undefine because the affixes (prefix and suffix) are computed from root.
    return undefined;
  }

  protected setupFields(): void {
    super.setupFields();
    this.setupLanguage();
  }

  /**
   * Set up the language field.
   *
   * @returns void
   */
  protected setupLanguage(): void {
    this.languageField = new FormControl(
      this.getDefaultValue('language'),
      Validators.required
    );

    this.addControl('language', this.languageField);
  }
}
