import { EventEmitter, Input, Output } from '@angular/core';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { StormList, StormListItemInterface, StormListType } from '@bolt/ui-shared/master-data';
import { LevelModeEnum } from '@bolt/ui-shared/title';
import { isUndefined as _isUndefined } from 'lodash';

import { Title } from '../../models/title.model';
import { Time } from 'app/modules/common/models/time.model';
import { EpisodeAttributes } from '../../models/title/episode/episode-attributes.enum';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { TitleAttributes } from '../../models/title/title-attributes.enum';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { FunctionalMetadata } from 'app/modules/list/models/functional-metadata/functional-metadata.model';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';


export abstract class BoltTitleSpecificLocalizationDetailsComponent {
  @Input() localization: Title;
  @Input() useComputed: boolean;
  @Input() allowActions: boolean;
  @Output('mouseOverMessage') mouseOverMessageEvent: EventEmitter<string>;
  @Output('saved') saveEvent: EventEmitter<any>;

  protected functionalMetadata: FunctionalMetadata[] = [];
  protected functionalMetadataOptions: SelectionItem[] = [];
  protected readonly titleAttributes: typeof TitleAttributes = TitleAttributes;
  protected readonly featureAttributes: typeof FeatureAttributes = FeatureAttributes;
  protected readonly episodeAttributes: typeof EpisodeAttributes = EpisodeAttributes;
  protected levelMode: LevelModeEnum;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected listsProvider: StormListsProvider
  ) {
    this.useComputed = false;
    this.allowActions = false;
    this.mouseOverMessageEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
  }

  /**
   * Emits the current locale.
   *
   * @param locale string
   * @returns void
   */
  protected emitMouseOverEvent(locale: string): void {
    this.mouseOverMessageEvent.emit(locale);
  }

  /**
   * Returns the computed-from message for the field with the given name, if the field is inherited from another locale.
   *
   * @params fieldName string
   * @returns string
   */
  protected getComputedFromMessage(fieldName: string): string {
    let message: string = 'Computed From *_*_*_*';

    if (this.localization.isComputedField(fieldName)) {
      const value: any = (this.localization[fieldName] instanceof Time) ?
       this.localization[fieldName].time : this.localization[fieldName];
      if (!_isUndefined(value)) {
        const locale: string = this.localization.localizedFields[fieldName];
        message = `Computed From ${locale}`;
      }
    }

    return message;
  }

  /**
   * Indicates if it has to display the computed message.
   *
   * @param fieldName string
   * @returns boolean
   */
  protected hasDisplayComputedMessage(fieldName: string): boolean {
    const hasIt: boolean =
      this.useComputed &&
      (this.localization.isNonEmptyRootField(fieldName) || this.localization.isComputedField(fieldName));

    return hasIt;
  }

  /**
   * Indicates if it has to use the light grey style for the given attribute.
   *
   * @param fieldName string
   * @returns boolean
   */
  protected hasUseLightGreyFor(fieldName: string): boolean {
    const hasIt: boolean =
      _isUndefined(this.localization.localizedFields[fieldName]) ||
      (
        this.useComputed &&
        this.localization.isComputedField(fieldName)
      );

    return hasIt;
  }

  /**
   * Loads the functional metadata options.
   *
   * @returns void
   */
  protected loadFunctionalMetadataOptions(): void {
    if (!this.hasFunctionalMetadataEnabled()) {
      this.listsProvider.getList(StormListType.functionalMetadata).subscribe(
        (stormList: StormList) => stormList.collection.forEach(
          (functionalMetadata: StormListItemInterface) => this.functionalMetadata.push(functionalMetadata.value)
        )
      );
    }

    this.mapFunctionalMetadata();
  }

  /**
   * Maps the current functional metadata into selectItem.
   *
   * @returns void
   */
  protected mapFunctionalMetadata(): void {
    this.functionalMetadata.forEach(
      (functionalMetadata: FunctionalMetadata) => {
        this.functionalMetadataOptions.push(new SelectionItem(functionalMetadata.description, functionalMetadata.id));
      }
    );
  }

  /**
   * Indicates if the Functional Metadata toggle is on.
   *
   * @returns boolean
   */
  protected hasFunctionalMetadataEnabled(): boolean {
    const hasIt = this.appConfigurationManager.getToggleValue(ToggleKeyEnum.functionalMetadataAsFieldsOn);
    return hasIt;
  }
}
