import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { pick as _pick } from 'lodash';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { UserRole } from '@bolt/ui-shared/auth';

import { AuthHelper } from 'app/modules/auth/helpers/auth/auth.helper';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { User, UserInterface } from '../models/user.model';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';


@Injectable()
export class UserService extends BoltAbstractService {
  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService,
    protected authHelper: AuthHelper
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetches the user details
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchUserDetails(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.doGetRequestAsObservable(
      { url: this.generateUrl('user.userService.fetchUserDetails.endpoint') }
    ).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          const responsePayloadJson: any = response.item;

          responsePayloadJson.roles = new Array();

          responsePayloadJson.authorities.forEach(
            (authority: any) => {
              responsePayloadJson.roles.push(authority);
            }
          );

          const mappedResponse: StormServiceResponseSingle = new StormServiceResponseSingle(new User(responsePayloadJson));

          return mappedResponse;
        }
      )
    );

    return obs;
  }

  /**
   * Fetches the user profile
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchUserProfile(): Observable<StormServiceResponseSingle> {
    const obs: Observable<StormServiceResponseSingle> = this.doGetRequestAsObservable(
      { url: this.generateUrl('user.userService.fetchUserProfile.endpoint') }
    ).pipe(
      map(
        (response: any) => new StormServiceResponseSingle(new User(response.item))
      )
    );

    return obs;
  }

  /**
   * Filters users.
   *
   * @param filterParams \{ [propName: string]: any }
   * @returns Observable<StormServiceResponseCollection>
   */
  filterUsers(filterParams: { [propName: string]: any }): Observable<StormServiceResponseCollection> {
    const search: UrlSearchParams = new UrlSearchParams();

    Object.keys(filterParams).forEach(
      (key: string) => {
        search.set(key, filterParams[key]);
      }
    );

    const obs: Observable<StormServiceResponseCollection> = this.doGetRequestAsObservable(
      {
        url: this.generateUrl('user.userService.filterUsers.endpoint'),
        checkType: CheckTypeEnum.array,
        options: search
      }
    ).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          const responseCollection = response.item.map(
            (item: any) => new User(item)
          );

          const mappedResponse: StormServiceResponseCollection = new StormServiceResponseCollection(
            responseCollection,
            1,
            responseCollection.length,
            1,
            responseCollection.length
          );

          return mappedResponse;
        }
      )
    );

    return obs;
  }

  /**
   * Creates a new User
   *
   * @param user UserInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  createUser(user: UserInterface): Observable<StormServiceResponseSingle> {
    const userPayload: object = { ...user.getRawObject(), roles: user.getRolesId() };

    const obs: Observable<StormServiceResponseSingle>  = this.doPostRequestAsObservable(
      {
        url: this.generateUrl('user.userService.createUser.endpoint'),
        body: JSON.stringify(userPayload)
      }
    ).pipe(
      map(
        (response: any) => new StormServiceResponseSingle(new User(response.item))
      )
    );

    return obs;
  }

  /**
   * Updates a User
   *
   * @param user UserInterface
   * @returns Observable<StormServiceResponseSingle>
   */
  updateUser(user: UserInterface): Observable<StormServiceResponseSingle> {
    // Allows only these User properties to be updated
    const userPayload: object = _pick(
      user.getRawObject(),
      new Array(
        'id', 'notes', 'name', 'firstName', 'middleName', 'lastName', 'organization', 'title', 'location', 'address', 'email',
       'phoneNumber', 'active'
      )
    );

    userPayload['roles'] = user.getRolesId();

    const obs: Observable<StormServiceResponseSingle> = this.doPutRequestAsObservable(
      {
        url: this.generateUrl('user.userService.updateUser.endpoint', { '{userId}': user.id }),
        body: JSON.stringify(userPayload)
      }
    ).pipe(
      map(
        (response: any) => new StormServiceResponseSingle(new User(response.item))
      )
    );

    return obs;
  }

  /**
   * Fetches user roles
   *
   * @returns Observable<StormServiceResponseCollection>
   */
  fetchUserRoles(): Observable<StormServiceResponseCollection> {
    const obs: Observable<StormServiceResponseCollection> = this.doGetRequestAsObservable(
      { url: this.generateUrl('authService.fetchRoles.endpoint'), checkType: CheckTypeEnum.array }
    ).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          const responseCollection = response.item.map(
            (item: any) => new UserRole(item)
          );

          const mappedResponse: StormServiceResponseCollection = new StormServiceResponseCollection(
            responseCollection,
            1,
            responseCollection.length,
            1,
            responseCollection.length
          );

          return mappedResponse;
        }
      )
    );

    return obs;
  }
}
