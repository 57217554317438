import { isArray as _isArray, isObject as _isObject } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class ServerMapping {
  protected flattenStore: Array<[string, any]>;
  protected rawStore: object;

  constructor(data: any) {
    if (_isObject(data)) {
      this.rawStore = data;
      this.flattenStore = [];

      this.fillFlattenStore(data);
    } else {
      throw new ErrorHelper('Invalid data given for a Server Mapping Configuration of CPM.');
    }
  }

  /**
   * Returns the content as an array.
   *
   * @returns Array<[string, any]>
   */
  toArray(): Array<[string, any]> {
    return this.flattenStore;
  }

  /**
   * Returns the content as a JSON.
   *
   * @returns any
   */
  toJson(): any {
    return this.rawStore;
  }

  /**
   * Fills the flatten store.
   *
   * @param data any
   * @param prefix string
   * @returns void
   */
  protected fillFlattenStore(data: any, prefix: string = ''): void {
    Object.keys(data).forEach(
      (attribute: string) => {
        const key: string = (prefix.length > 0) ? `${prefix}.${attribute}` : attribute;
        const value: any = data[attribute];

        if (_isArray(value) || _isObject(value)) {
          this.fillFlattenStore(value, key);
        } else {
          this.flattenStore.push([key, value]);
        }
      }
    );
  }
}
