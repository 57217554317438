import { Component, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';

import { BoltMergeListComponent } from '../bolt-merge-list/bolt-merge-list.component';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { Talent } from 'app/modules/talent/models/talent.model';
import { TalentService } from 'app/modules/talent/services/talent.service';

@Component({
  selector: 'bolt-merge-talent-list',
  template: require('./bolt-merge-talent-list.html'),
  styles: [require('./bolt-merge-talent-list.scss')]
})
export class BoltMergeTalentListComponent extends BoltMergeListComponent {
  @Output('selected') selectEvent: EventEmitter<Talent>;

  protected mergeCandidate: Talent;

  constructor(
    protected talentService: TalentService,
    protected notificationService: NotificationService
  ) {
    super(notificationService);
  }

  get items(): Talent[] {
    return this.searchResponse.data;
  }

  protected doSearch(): void {
    this.fetchItemSubscription = this.talentService.search(
      this.searchCriteria
    ).subscribe(
      (response: SearchResponse) => {
        this.doDefaultOnSuccess(response);
      },
      (error: ErrorHelper) => {
        this.doDefaultOnError(error, 'talents');
      }
    );
  }
}
