import { FormControl, Validators } from '@angular/forms';
import { Account } from '@bolt/ui-shared/master-data';
import { isArray as _isArray, upperFirst as _upperFirst, isNull as _isNull } from 'lodash';

import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';
import { Title } from '../../title.model';
import { TitleAttributes } from '../../title/title-attributes.enum';
import { FeatureAttributes } from '../../title/feature/feature-attributes.enum';


export abstract class TitleLocalizedForm extends BoltForm {
  protected attributes: string[] = [];
  protected functionalMetadataField: FormControl;
  protected functionalMetadataAttributes: string[];
  protected accountFunctionalMetadataOverrideAttributes: string[];
  protected territoryAccountFunctionalMetadataOverrideAttributes: string[];
  protected splitFunctionalMetadataFlag: boolean;
  protected title: Title;
  protected languageField: FormControl;
  protected territoryField: FormControl;
  protected productTypeField: FormControl;
  protected accountField: FormControl;

  constructor(fieldSpecs: any, attributes: string[], title?: Title, splitFunctionalMetadataFlag?: boolean) {
    super(fieldSpecs, title);
    this.setupTitle(title);
    this.setupSplitFunctionalMetadataFlag(splitFunctionalMetadataFlag);
    this.attributes = attributes;

    this.functionalMetadataAttributes = [
      FeatureAttributes.displayDubTalent,
      FeatureAttributes.graphicContentDisclaimer,
      FeatureAttributes.heritageDisclaimer,
      FeatureAttributes.includeKidsMode,
      FeatureAttributes.excludeKidsMode,
      FeatureAttributes.productPlacementDisclaimer,
      FeatureAttributes.pseDisclaimer,
      FeatureAttributes.smokingDisclaimer
    ];

    this.accountFunctionalMetadataOverrideAttributes = [
      FeatureAttributes.heritageDisclaimer,
      FeatureAttributes.smokingDisclaimer,
      FeatureAttributes.excludeKidsMode
    ];

    this.territoryAccountFunctionalMetadataOverrideAttributes = [
      FeatureAttributes.displayDubTalent,
      FeatureAttributes.graphicContentDisclaimer,
      FeatureAttributes.includeKidsMode,
      FeatureAttributes.excludeKidsMode,
      FeatureAttributes.productPlacementDisclaimer,
      FeatureAttributes.pseDisclaimer,
      FeatureAttributes.smokingDisclaimer,
      FeatureAttributes.heritageDisclaimer
    ];
  }

  /**
   * Returns the functional metadata field.
   *
   * @returns FormControl
   */
  getFunctionalMetadataField(): FormControl {
    return this.functionalMetadataField;
  }

  /**
   * Returns the functional metadata split based on splitFunctionalMetadata flag.
   *
   * @returns boolean
   */
  getSplitFunctionalMetadataFlag(): boolean {
    return this.splitFunctionalMetadataFlag;
  }

  /**
   * Returns the language field.
   *
   * @returns FormControl
   */
  getLanguageField(): FormControl {
    return this.languageField;
  }

  /**
   * Returns the territory field.
   *
   * @returns FormControl
   */
  getTerritoryField(): FormControl {
    return this.territoryField;
  }

  /**
   * Returns the product type field.
   *
   * @returns FormControl
   */
  getProductTypeField(): FormControl {
    return this.productTypeField;
  }

  /**
   * Returns the account field.
   *
   * @returns FormControl
   */
  getAccountField(): FormControl {
    return this.accountField;
  }

  /**
   * Returns the currents metadata controls.
   *
   * @returns FormControl[]
   */
  getMetadataAttributesControls(): FormControl[] {
    const controls: FormControl[] = [];

    this.attributes.forEach(
      (attribute: string) => {
        if (attribute === FeatureAttributes.functionalMetadata) {
          if (this.getSplitFunctionalMetadataFlag()) {
            this.functionalMetadataAttributes.forEach(
              (fmAttribute: string) => {
                controls.push(this[`get${_upperFirst(fmAttribute)}Field`]());

                if (
                  this.getAccountField().value !== 0 &&
                  fmAttribute in this.accountFunctionalMetadataOverrideAttributes
                ) {
                  controls.push(this[`get${_upperFirst(fmAttribute)}OverrideField`]());
                }

                if (
                  this.getTerritoryField().value !== 0 &&
                  Account.isDisneySvod(this.getAccountField().value[0]) &&
                  fmAttribute in this.territoryAccountFunctionalMetadataOverrideAttributes
                ) {
                  controls.push(this[`get${_upperFirst(fmAttribute)}OverrideField`]());
                }
              }
            );
          } else {
            controls.push(this.getFunctionalMetadataField());
          }
        } else {
          controls.push(this[`get${_upperFirst(attribute)}Field`]());
        }
      }
    );

    return controls;
  }

  toJson(): any {
    const data: any = { };
    data[TitleAttributes.language] = this.getLanguageField().value;
    data[TitleAttributes.territory] = this.getTerritoryField().value;
    data[TitleAttributes.productType] = this.getProductTypeField().value;
    data[TitleAttributes.account] = this.getAccountField().value;

    this.attributes.forEach(
      (attribute: string) => {
        if (attribute === FeatureAttributes.functionalMetadata) {
          if (!this.getSplitFunctionalMetadataFlag()) {
            data[attribute] = this.getFunctionalMetadataField().value;
          }
        } else {
          data[attribute] = this[`get${_upperFirst(attribute)}Field`]().value;
        }
      }
    );

    return data;
  }

  protected setupFields() {
    this.setupLanguageField();
    this.setupTerritoryField();
    this.setupProductTypeField();
    this.setupAccountField();

    this.attributes.forEach(
      (attribute: string) => {
        if (attribute === FeatureAttributes.functionalMetadata) {
          if (this.getSplitFunctionalMetadataFlag()) {
            this.functionalMetadataAttributes.forEach(
              (fmAttribute: string) => {
                this[`setup${_upperFirst(fmAttribute)}Field`]();

                if (
                  !_isNull(this.getTerritoryField().value) &&
                  this.getTerritoryField().value[0] === 0 &&
                  this.accountFunctionalMetadataOverrideAttributes.includes(fmAttribute)
                ) {
                  this[`setup${_upperFirst(fmAttribute)}OverrideField`]();
                }

                if (
                  (
                    _isNull(this.getTerritoryField().value) ||
                    this.getTerritoryField().value[0] !== 0
                  ) &&
                  this.territoryAccountFunctionalMetadataOverrideAttributes.includes(fmAttribute)
                ) {
                  this[`setup${_upperFirst(fmAttribute)}OverrideField`]();
                }
              }
            );
          } else {
            this.setupFunctionalMetadataField();
          }
        } else {
          this[`setup${_upperFirst(attribute)}Field`]();
        }
      }
    );
  }

  /**
   * Set up the functional metadata field.
   *
   * @returns void
   */
  protected setupFunctionalMetadataField(): void {
    this.functionalMetadataField = new FormControl(this.getDefaultValue(FeatureAttributes.functionalMetadata));
    this.addControl(FeatureAttributes.functionalMetadata, this.functionalMetadataField);
  }

  /**
   * Set up the language field.
   *
   * @returns void
   */
  protected setupLanguageField(): void {
    this.languageField = new FormControl(this.getDefaultValue(TitleAttributes.language), Validators.required);
    this.addControl(TitleAttributes.language, this.languageField);
  }

  /**
   * Set up the territory field.
   *
   * @returns void
   */
  protected setupTerritoryField(): void {
    this.territoryField = new FormControl(this.getDefaultValue(TitleAttributes.territory), Validators.required);
    this.addControl(TitleAttributes.territory, this.territoryField);
  }

  /**
   * Set up the product type field.
   *
   * @returns void
   */
  protected setupProductTypeField(): void {
    this.productTypeField = new FormControl(this.getDefaultValue(TitleAttributes.productType), Validators.required);
    this.addControl(TitleAttributes.productType, this.productTypeField);
  }

  /**
   * Set up the account field.
   *
   * @returns void
   */
  protected setupAccountField(): void {
    this.accountField = new FormControl(this.getDefaultValue(TitleAttributes.account), Validators.required);
    this.addControl(TitleAttributes.account, this.accountField);
  }

  /**
   * Set up the functional metadata split based on splitFunctionalMetadata flag.
   *
   * @param splitFunctionalMetadataFlag boolean
   * @returns void
   */
  protected setupSplitFunctionalMetadataFlag(splitFunctionalMetadataFlag: boolean): void {
    this.splitFunctionalMetadataFlag = splitFunctionalMetadataFlag;
  }

  /**
   * Set up the current title.
   *
   * @param title Title
   * @returns void
   */
  protected setupTitle(title: Title): void {
    this.title = title;
  }
}
