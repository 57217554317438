import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';

import { Role, RoleInterface } from '../../models/role.model';
import { RoleManager, RoleManagerMetadataManagement } from '../../helpers/role-manager/role-manager.helper';
import { RoleMetadataInterface, RoleMetadata } from '../../models/role-metadata.model';
import { RoleService } from '../../services/role.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-page-role-details',
  template: require('./bolt-page-role-details.html'),
  providers: [
    RoleService,
  ],
  viewProviders: [
    RoleManager,
  ]
})

export class BoltPageRoleDetailsComponent extends StormComponent implements OnInit {
  protected role: RoleInterface = new Role();
  protected showRoleLevelData: boolean = true;
  protected selectedMetadata: RoleMetadataInterface;
  protected roleMetadataManager: RoleManagerMetadataManagement;
  protected notificationsContainer = notificationsContainer;

  constructor(
    protected roleService: RoleService,
    protected roleManager: RoleManager,
    protected route: ActivatedRoute,
    protected router: Router,
    protected appConfig: AppConfigProvider,
    protected changeDetector: ChangeDetectorRef
  ) {
    super();

    this.roleManager.getManagedRoleMetadata().subscribe(
      roleMetadataManager => this.roleMetadataManager = roleMetadataManager
    );
  }

  ngOnInit() {
    this.status = this.componentStatuses.fetchingData;
    this.setRole();

    if (this.route.snapshot.params['show-role-level-data'] === 'true') {
      this.showRoleLevelData = true;
    }
  }

  /**
   * Set the given role as the selected one.
   *
   * @param roleMetadata RoleMetadata
   * @returns void
   */
  protected setSelectedMetadata(roleMetadata?: RoleMetadata | boolean): void {
    if (roleMetadata instanceof RoleMetadata) {
      this.selectedMetadata = roleMetadata;
    } else {
      this.selectedMetadata = undefined;
    }

    this.changeDetector.detectChanges();
  }

  /**
   * Set the current role for the id from the url.
   *
   * @param refreshOrSelectMetadata boolean
   * @returns void
   */
  protected setRole(refreshOrSelectMetadata: boolean = true) {
    if (!refreshOrSelectMetadata) {
      return;
    }

    this.roleService.fetchRole({
      roleId: Number(this.route.snapshot.params['roleId'])
    }).subscribe(
      response => {
        if (response.item) {

          this.role = response.item;
          this.status = this.componentStatuses.dataFound;

          this.setSelectedMetadata(refreshOrSelectMetadata);

        } else {
          this.status = this.componentStatuses.dataNotFound;
        }

      },
      error => this.status = this.componentStatuses.error,
      () => this.roleMetadataManager = undefined
      );
  }

  /**
   * Navigates back in the platform's history
   *
   * @return void
   */
  protected navigateBack() {
    return history.back();
  }

  /**
   * Reacts to a localization removal
   *
   * @param message boolean
   * @returns void
   */
  protected onLocalizationRemove(message: boolean) {
    if (message) {
      this.setRole();
    }
  }
}
