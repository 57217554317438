import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { CountdownComponent } from 'ngx-countdown';

import { AuthHelper } from '../../helpers/auth/auth.helper';


@Component({
  selector: 'bolt-auth-logout',
  template: require('./bolt-auth-logout.html'),
  styles: [require('./bolt-auth-logout.scss')]
})
export class BoltAuthLogoutComponent implements OnInit, AfterViewInit {
  @ViewChild('countdown')
  protected countdown: CountdownComponent;

  protected readonly logoutLegend: string = 'You have successfully logged out from BOLT.';

  protected authLoginUrl: string;
  protected countdownConfig: any;

  constructor(
    protected appConfig: AppConfigProvider,
    protected authHelper: AuthHelper,
    protected router: Router
  ) {
    this.authLoginUrl = this.authHelper.getLoginUrl();

    this.countdownConfig = {
      demand: true,
      format: 's',
      leftTime: this.appConfig.get('auth.redirectLoginIn')
    };
  }

  ngAfterViewInit() {
    this.countdown.begin();
  }

  ngOnInit() {
    this.authHelper.logout();
  }

  /**
   * Redirects to login after analyzing the given event.
   *
   * @param event any
   * @returns any
   */
  protected redirectLogin(event: any): void {
    if (event.action === 'done') {
      this.router.navigate(['/auth/login']);
    }
  }
}
