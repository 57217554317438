import { Injectable } from '@angular/core';
import { Subscription, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

import { omit as _omit, isUndefined as _isUndefined } from 'lodash';
import * as _ from 'lodash';

import { TitleEditHistory } from '@bolt/ui-shared/title';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { DubbingStudio } from '@bolt/ui-shared/master-data';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { EpisodeMetadata } from '../models/episode-metadata.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FeatureMetadata } from '../models/feature-metadata.model';
import { FindByRidsResponse } from '../models/title/find-by-rids-response/find-by-rids-response.model';
import { SeasonMetadata } from '../models/season-metadata.model';
import { SeriesMetadata } from '../models/series-metadata.model';

import {
  StormServiceResponseSingle, StormServiceResponseSingleInterface
} from 'app/modules/common/services/storm-service-response-single';

import {
  StormServiceResponseCollectionInterface, StormServiceResponseCollection
} from 'app/modules/common/services/storm-service-response-collection';

import { StormServicesQueryParams } from 'app/modules/common/services/storm-services-query-params';
import { TitleListSearchResult } from '../models/title-list-search-result.model';
import { TitleMetadataFactory, TitleFactory } from '../helpers/title-factory/title-factory.helper';
import { TitleMetadataInterface, TitleMetadataLockingStatus } from '../models/title-metadata.model';
import { TitleType, TitleInterface, Title } from '../models/title.model';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';
import { ProductManagerIntegrationStatus } from '../helpers/product-manager/product-manager-integration-status.enum';
import { ProductLayoutHelper } from '../helpers/product-layout/product-layout.helper';


export enum TitleServiceFetchTitlesQueryParams {
  q = <any>'q',
  type = <any>'type'
}

export interface TitleServiceFetchTitleMetadataParamsInterface {
  productType: TitleType;
  productId: number;
  locale?: string;
}

export interface TitleServiceFetchTitlesParamsInterface {
  q: string;
  type: string;
  page_size: number;
  page: number;
  sort_by?: string;
  sort_direction?: string;
}


@Injectable()
export class TitleService extends BoltAbstractService {
  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService,
    protected productLayoutHelper: ProductLayoutHelper
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns a subscription for fetching the titles for the given RIDs list.
   *
   * @param ridsList number[]
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  findTitlesByRids(
    ridsList: number[],
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const queryParams: any = {
      rids: ridsList.join(',')
    };

    const url: string = this.generateUrl(
      'productService.findTitlesByRadarId.endpoint',
      undefined,
      queryParams
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const response: FindByRidsResponse = new FindByRidsResponse(successResponse.item);
          onSuccessDo(response);
        } catch (err) {
          onErrorDo(err);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Updates an specific locale with the given data.
   *
   * @param productType TitleType
   * @param productId number
   * @param locale string
   * @param data any
   * @returns Observable<any>
   */
  updateLocale(productType: TitleType, productId: number, locale: string, data: any): Observable<any> {
    const params: any = {
      '{productType}': productType,
      '{productId}': productId,
      '{locale}': locale
    };

    const url: string = this.generateUrl('productService.updateMetadataLocale.endpoint', params);

    const obs = this.authHttp.put(url, data).pipe(
      map(
        (response: any) => {
          let title: any;

          switch (response.titleType) {
            case TitleType.episode:
              title = new EpisodeMetadata(response);
              break;
            case TitleType.feature:
              title = new FeatureMetadata(response);
              break;
            case TitleType.season:
              title = new SeasonMetadata(response);
              break;
            case TitleType.series:
              title = new SeriesMetadata(response);
              break;
            default:
              break;
          }

          return title;
        }
      )
    );

    return obs;
  }

  /**
   * Fetch an Specific Title localized for Dubbing Studio.
   *
   * @param titleType TitleType
   * @param titleId number
   * @param locale string
   * @returns Observable<any>
   */
  public fetchTitleDubbingStudio(titleType: TitleType, titleId: number, locale: string): Observable<any> {
    const params: any = {
      '{titleType}': titleType.toString(),
      '{titleId}': titleId,
      '{locale}': locale
    };

    const url: string = this.generateUrl('productService.fetchTitleDubbingStudio.endpoint', params);

    return this.authHttp.get(url);
  }

  /**
   * Update an Specific Title localized for Dubbing Studio.
   *
   * @param dubbingStudio DubbingStudio
   * @param titleType TitleType
   * @param titleId number
   * @param locale string
   * @returns Observable<any>
   */
  public updateTitleDubbingStudio(dubbingStudio: DubbingStudio, titleType: TitleType, titleId: number, locale: string): Observable<any> {
    const body = JSON.stringify({
      dubbingStudioId: dubbingStudio ? dubbingStudio.id : null
    });

    const params: any = {
      '{titleType}': titleType.toString(),
      '{titleId}': titleId,
      '{locale}': locale
    };

    const url: string = this.generateUrl('productService.updateTitleDubbingStudio.endpoint', params);

    return this.authHttp.put(url, body);
  }

  /**
   * Sets the Dubbing Studio for a Dubbing Crew or Dubbing Cast.
   *
   * @param dubbingStudio DubbingStudio
   * @param titleType TitleType
   * @param titleId number
   * @param locale string
   * @returns Observable<any>
   */
  public createDubbingStudio(
    dubbingStudio: DubbingStudio,
    titleType: TitleType,
    titleId: number,
    locale: string
  ): Observable<any> {
    const postDubbingStudio: any = (dubbingStudio) ? dubbingStudio.id : null;

    const body = JSON.stringify({
      titleId: titleId,
      locale: locale,
      type: 'CC',
      dubbingStudioId: postDubbingStudio
    });

    return this.authHttp
      .post(
        this.generateUrl(
          'productService.createTitleDubbingStudio.endpoint',
          { '{titleType}': titleType.toString(), '{titleId}': titleId, '{locale}': locale }
        ),
        body
      );
  }

  /**
   * Fetch the Title Metadata for a single title.
   *
   * @param params TitleServiceFetchTitleMetadataParamsInterface
   * @param excludeLocalizations boolean
   * @return Observable<StormServiceResponseSingleInterface>
   */
  public fetchProduct(
    params: TitleServiceFetchTitleMetadataParamsInterface,
    excludeLocalizations: boolean = false
  ): Observable<StormServiceResponseSingleInterface> {
    const url: string = this.generateUrl(
      'productService.fetchProduct.endpoint',
      { '{productType}': params.productType, '{productId}': params.productId },
      (excludeLocalizations ? { _localizations: false } : undefined)
    );

    const obs: Observable<StormServiceResponseSingleInterface> = this.authHttp.get(url).pipe(
      map(
        (response: any) => {
          const localizations: TitleMetadataInterface[] = [];

          response.localizations.forEach(
            (localization: any, index: number) => {
              localizations[index] = TitleMetadataFactory(TitleType[params.productType], localization);
            }
          );

          response.localizations = localizations;

          return new StormServiceResponseSingle(
            TitleFactory(TitleType[params.productType], response)
          );
        }
      )
    );

    return obs;
  }

  /**
   * Gets the locked status by a given title and locale
   *
   * @param titleType TitleType
   * @param titleId number
   * @param locale string
   * @returns Observable<TitleMetadataLockingStatus>
   */
  public fetchTitleLockingStatusByLocale(
    titleType: TitleType,
    titleId: number,
    locale: string
  ): Observable<TitleMetadataLockingStatus> {

    return this.authHttp
      .get(
        this.generateUrl(
          'productService.fetchTitleTitleMetadataLockingStatus.endpoint',
          { '{productType}': titleType.toString(), '{productId}': titleId, '{locale}': locale }
        )
      ).pipe(
        map(
          (response: any[]) => {
            const data: any = response[0];

            return {
              titleType: titleType,
              titleId: titleId,
              locale: locale,
              root: data.title,
              language: data.language,
              territory: data.territory,
              originalCast: data.originalCast,
              originalCrew: data.originalCrew,
              dubbingCast: data.dubbingCast,
              dubbingCrew: data.dubbingCrew,
              translatedCast: data.translatedCast,
              translatedCrew: data.translatedCrew,
              overall: data.overall,
              error: data.error || undefined
            };
          }
        )
      );
  }

  /**
   * Triggers the integration process for a given title
   *
   * @param params TitleServiceFetchTitleMetadataParamsInterface
   * @returns Observable<ProductManagerIntegrationStatus>
   */
  public triggerIntegration(
    params: TitleServiceFetchTitleMetadataParamsInterface
  ): Observable<ProductManagerIntegrationStatus> {
    return Observable.create((observer: Observer<ProductManagerIntegrationStatus>) => {
      let statusCheckHandler;

      this.authHttp.patch(
        this.generateUrl(
          'integrationService.triggerIntegration.endpoint',
          { '{titleType}': params.productType, '{titleIdentifier}': params.productId }
        ),
        ''
      ).pipe(
        map(response => new StormServiceResponseSingle(response))
      ).subscribe(
        singleResponse => {
          const checkIntegrationStatus = () => {
            this.getIntegrationStatus(
              singleResponse.item.integrationId
            ).subscribe(response => {
              observer.next(<ProductManagerIntegrationStatus>response.item.integrationStatus);

              if (response.item.integrationStatus !== ProductManagerIntegrationStatus.IN_PROGRESS) {
                clearInterval(statusCheckHandler);
                observer.complete();
              }

            });
          };
          statusCheckHandler = setInterval(() => {
            checkIntegrationStatus();
          }, 2000);
        },
        error => {
          clearInterval(statusCheckHandler);
          observer.error(error);
        });
    });
  }

  /**
   * Gets the integration status for a given integration id
   *
   * @param integrationId string
   * @returns Observable<StormServiceResponseSingle>
   */
  public getIntegrationStatus(
    integrationId: string
  ): Observable<StormServiceResponseSingle> {
    return this.authHttp
      .get(
        this.generateUrl(
          'integrationService.checkIntegrationStatus.endpoint',
          { '{integrationId}': integrationId }
        )
      ).pipe(
        map(response => new StormServiceResponseSingle(response))
      );
  }

  /**
   * Fetch the title metadata
   *
   * @param params TitleServiceFetchTitleMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchProductMetadata(
    params: TitleServiceFetchTitleMetadataParamsInterface
  ): Observable<StormServiceResponseSingleInterface> {
    const url: string = this.generateUrl(
      'productService.fetchProductMetadata.endpoint',
      { '{productType}': params.productType, '{productId}': params.productId, '{locale}': params.locale }
    );

    return this.doGetRequestAsObservable(
      { url: url }
    ).pipe(
      map(
        (response: any) => {
          return new StormServiceResponseSingle(
            TitleMetadataFactory(TitleType[params.productType], response.item)
          );
        }
      )
    );
  }

  /**
   * Fetch the computed metadata for the required product
   *
   * @param params TitleServiceFetchTitleMetadataParamsInterface
   * @param originalMetadata any
   * @param avoidLockComputation boolean
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchComputedProductMetadata(
    params: TitleServiceFetchTitleMetadataParamsInterface,
    originalMetadata: any,
    avoidLockComputation: boolean = true,
  ): Observable<StormServiceResponseSingleInterface> {
    const obs: Observable<StormServiceResponseSingleInterface> = new Observable(
      (observer: Observer<StormServiceResponseSingleInterface>) => {
        this.authHttp.get(
          this.generateUrl(
            'productService.fetchComputedProductMetadata.endpoint',
            { '{productType}': params.productType, '{productId}': params.productId, '{locale}': params.locale }
          )
        ).pipe(
          map(
            (response: any) => {
              return new StormServiceResponseSingle(
                TitleMetadataFactory(TitleType[params.productType], response)
              );
            }
          )
        ).subscribe(
          (singleResponse: StormServiceResponseSingleInterface) => {
            if (avoidLockComputation) {
              this.productLayoutHelper.mapAttributes([singleResponse.item]).subscribe(
                ([finalComputedProductMetadata]: TitleMetadataInterface[]) => {
                  /**
                   * Computed Localization API criteria for locked/lastModified/lastModifiedBy attributes
                   * is different that we need on Bolt, currently some third party are using the API criteria
                   * so we need to override these attributes with the original values (the one that comes from Fetch Product API).
                   */
                  finalComputedProductMetadata.locked = originalMetadata.locked;
                  finalComputedProductMetadata.lastModifiedBy = originalMetadata.lastModifiedBy;
                  finalComputedProductMetadata.lastModified = originalMetadata.lastModified;
                  finalComputedProductMetadata.inheritedAttributes = Object.keys(
                    _.pickBy(originalMetadata, _isUndefined)
                  );

                  observer.next(new StormServiceResponseSingle(finalComputedProductMetadata));
                  observer.complete();
                },
                (error: any) => {
                  observer.error(error);
                  observer.complete();
                }
              );
            } else {
              observer.next(singleResponse);
              observer.complete();
            }
          },
          (error: any) => {
            observer.error(error);
            observer.complete();
          }
        );
      }
    );

    return obs;
  }

  /**
   * Updates a Product
   *
   * @param params TitleServiceFetchTitleMetadataParamsInterface
   * @param updates TitleInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public updateProduct(
    params: TitleServiceFetchTitleMetadataParamsInterface,
    updates: TitleInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .put(
        this.generateUrl(
          'productService.updateProduct.endpoint',
          { '{productType}': params.productType, '{productId}': params.productId }
        ),
        JSON.stringify(updates)
      ).pipe(
        map(
          (response: any) => {
            const localizations: TitleMetadataInterface[] = [];

            response.localizations.forEach(
              (localization: any, index: number) => {
                localizations[index] =
                  TitleMetadataFactory(TitleType[params.productType], localization);
              }
            );

            response.localizations = localizations;

            return new StormServiceResponseSingle(
              TitleFactory(TitleType[params.productType], response)
            );
          }
        )
      );
  }

  /**
   * Sets the metadata for a Product
   *
   * @param params TitleServiceFetchTitleMetadataParamsInterface
   * @param locale string
   * @param productMetadata Title
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public setProductMetadata(
    params: TitleServiceFetchTitleMetadataParamsInterface,
    locale: string,
    productMetadata: TitleMetadataInterface
  ): Observable<StormServiceResponseSingleInterface> {
    const url: string = this.generateUrl(
      'productService.createMetadata.endpoint',
      {
        '{productType}': params.productType,
        '{productId}': params.productId,
        '{locale}': locale
      }
    );

    const request: any = {
      url,
      body: JSON.stringify(productMetadata.getRawObject())
    };

    return this.doPostRequestAsObservable(request).pipe(
      map(
        (response: any) => {
          return new StormServiceResponseSingle(
            TitleMetadataFactory(TitleType[params.productType], response.item)
          );
        }
      )
    );
  }

  /**
   * Deletes the given Product Metadata properties (attributes)
   *
   * @param params TitleServiceFetchTitleMetadataParamsInterface
   * @param locale string
   * @param properties string[]
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public deleteProductMetadataAttribute(
    params: TitleServiceFetchTitleMetadataParamsInterface,
    locale: string,
    properties: string[]
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .delete(
        this.generateUrl(
          'productService.deleteMetadataAttribute.endpoint',
          { '{productType}': params.productType, '{productId}': params.productId, '{locale}': locale }
        ),
        JSON.stringify(properties)
      ).pipe(
        map(
          (response: any) => {
            return new StormServiceResponseSingle(
              TitleMetadataFactory(TitleType[params.productType], response)
            );
          }
        )
      );
  }

  /**
   * Fetch a collection of titles based on given searchParams
   *
   * @param searchParams TitleServiceFetchTitlesParamsInterface
   * @returns Observable<StormServiceResponseCollectionInterface>
   */
  public search(searchParams: TitleServiceFetchTitlesParamsInterface): Observable<StormServiceResponseCollectionInterface> {
    const search = new UrlSearchParams();

    search.set(TitleServiceFetchTitlesQueryParams.q.toString(), searchParams.q);
    search.set(TitleServiceFetchTitlesQueryParams.type.toString(), searchParams.type);
    search.set(StormServicesQueryParams.page.toString(), (searchParams.page - 1).toString()); // API paginates starting at index 0
    search.set(StormServicesQueryParams.page_size.toString(), searchParams.page_size.toString());

    if (!_isUndefined(searchParams.sort_by)) {
      search.set(StormServicesQueryParams.sort.toString(), `${searchParams.sort_by}:${searchParams.sort_direction}`);
    }

    const obs: Observable<StormServiceResponseCollectionInterface> = this.authHttp.get(
      this.generateUrl('productService.search.endpoint', undefined, search)
    ).pipe(
      map(
        (response: any) => {
          const responseCollection = response.content.map(
            (item: any) => {
              item.type = TitleType[item.type.toString().toLowerCase()];
              return new TitleListSearchResult(item);
            }
          );

          const mapped: StormServiceResponseCollection = new StormServiceResponseCollection(
            responseCollection,
            Number(response.number) + 1, // API paginates starting at index 0
            Number(response.size),
            Number(response.totalPages),
            Number(response.totalElements),
            _isUndefined(searchParams.sort_by) ? undefined : searchParams.sort_by.toString(),
            _isUndefined(searchParams.sort_direction) ? undefined : searchParams.sort_direction.toString()
          );

          return mapped;
        }
      )
    );

    return obs;
  }

  /**
   * Fetches filtered titles with the given params
   *
   * @param filterParams \{ titleType: TitleType, [propName: string]: any }
   * @returns Observable<StormServiceResponseCollectionInterface>
   */
  public filterTitles(
    filterParams: { titleType: TitleType, [propName: string]: any }
  ): Observable<StormServiceResponseCollectionInterface> {
    const search = new UrlSearchParams();
    const queryParams = _omit(filterParams, ['titleType']);

    Object.keys(queryParams).forEach(
      (key: string) => search.set(key, queryParams[key])
    );

    const url: string = this.generateUrl(
      'productService.filter.endpoint',
      { '{titleType}': filterParams.titleType },
      search
    );

    const obs: Observable<StormServiceResponseCollectionInterface> = this.authHttp.get(url).pipe(
      map(
        (response: any[]) => {
          const collection: Title[] = response.map(
            (item: any) => TitleFactory(TitleType[filterParams.titleType], item)
          );

          return new StormServiceResponseCollection(collection, 1, collection.length, 1, collection.length);
        }
      )
    );

    return obs;
  }

  /**
   * Returns a subscription for fetching the title edit history.
   *
   * @param titleType TitleType
   * @param titleId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetchTitleEditHistory(
    titleType: TitleType,
    titleId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {

    // TODO REMOVE THIS METHOD

    const params: any = {
      '{titleType}': titleType,
      '{titleId}': titleId
    };

    const url: string = this.generateUrl('productService.fetchTitleEditHistory.endpoint', params);

    const subs: Subscription = this.doGetRequest(
      { url: url, checkType: CheckTypeEnum.array },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const response: TitleEditHistory[] = new Array();
          successResponse.item.forEach((raw: any) => {
            response.push(new TitleEditHistory(raw));
          });

          onSuccessDo(response);
        } catch (err) {
          onErrorDo(err);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for fetching the title localization edit history.
   *
   * @param locale string
   * @param titleType TitleType
   * @param titleId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetchTitleLocalizationEditHistory(
    locale: string,
    titleType: TitleType,
    titleId: number,
    archivedHistory: boolean,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{locale}': locale,
      '{titleType}': titleType,
      '{titleId}': titleId
    };

    const queryParams = new UrlSearchParams();
    queryParams.set('archivedHistory', archivedHistory.toString());

    const url: string = this.generateUrl(
      'productService.fetchTitleLocalizationEditHistory.endpoint',
      params,
      queryParams
    );

    const subs: Subscription = this.doGetRequest(
      { url: url, checkType: CheckTypeEnum.array },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const response: TitleEditHistory[] = new Array();

          successResponse.item.forEach((raw: any) => {
            response.push(new TitleEditHistory(raw));
          });

          onSuccessDo(response);
        } catch (err) {
          onErrorDo(err);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a observable for fetching the title edit history with archivedHistory in false.
   *
   * @param titleType TitleType
   * @param titleId number
   * @param archivedHistory boolean
   * @returns Observable<TitleEditHistory[]>
   */
  fetchTitleRootEditHistory(titleType: TitleType, titleId: number, archivedHistory: boolean): Observable<TitleEditHistory[]> {
    const params: any = {
      '{titleType}': titleType,
      '{titleId}': titleId,
      '{archivedHistory}': archivedHistory
    };

    const queryParams = new UrlSearchParams();
    queryParams.set('archivedHistory', archivedHistory.toString());

    const url: string = this.generateUrl(
      'productService.fetchTitleEditHistory.endpoint',
      params,
      queryParams
    );

    const obs: Observable<TitleEditHistory[]> = this.doGetRequestAsObservable({ url: url, checkType: CheckTypeEnum.array }).pipe(
      map(
        (successResponse: StormServiceResponseSingle) => {
          const response: TitleEditHistory[] = new Array();
          successResponse.item.forEach((raw: any) => {
            response.push(new TitleEditHistory(raw));
          });
          return response;
        }
      )
    );

    return obs;
  }
}
