import { Component, Input, Output, OnChanges, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { isArray as _isArray, isUndefined as _isUndefined } from 'lodash';

import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { Talent, TalentInterface } from '../../models/talent.model';

import {
  FormFactoryService as  TalentFormFactoryService
} from 'app/modules/talent/services/form-factory/form-factory.service';

import { TalentManagerMetadataManagement, TalentManager } from '../../helpers/talent-manager/talent-manager.helper';
import { TalentMetadataInterface } from '../../models/talent-metadata.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormServiceResponseSingleInterface } from 'app/modules/common/services/storm-service-response-single';
import { TalentForm } from '../../models/form/talent-form.model';
import { TalentLocalizedAttributesEnum } from 'app/modules/talent/components/bolt-talent-localized-metadata-form/talent-localized-attributes.enum';


export enum TalentMetadataManagerActions {
  CREATE_TALENT = <any>'CREATE_TALENT',
  EDIT = <any>'EDIT',
  CREATE = <any>'CREATE',
  DELETE = <any>'DELETE',
  LOCK = <any>'LOCK',
  UNLOCK = <any>'UNLOCK',
}


@Component({
  selector: 'bolt-talent-metadata-manager',
  template: require('./bolt-talent-metadata-manager.html'),
  styles: [require('./bolt-talent-metadata-manager.scss')]
})
export class BoltTalentMetadataManagerComponent implements OnChanges {
  @ViewChild('confirmLockUpdateModal') confirmLockUpdateModal: ModalDirective;
  @ViewChild('notifyOverrideMetadataModal') notifyOverrideMetadataModal: ModalDirective;
  @ViewChild('confirmDeleteAttributeModal') confirmDeleteAttributeModal: ModalDirective;
  @ViewChild('preventCancelModal') preventCancelModal: ModalDirective;
  @Input('BoltTalentMetadataManagerMetadataManager') metadataManager: TalentManagerMetadataManagement;

  @Output('BoltTalentMetadataManagerOnMetadataUpdated')
  metadataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected show: boolean = false;
  protected status: DataStatusEnum = DataStatusEnum.idle;
  protected statuses = DataStatusEnum;
  protected talentMetadataManagerActions = TalentMetadataManagerActions;
  protected metadataForm: TalentForm;
  protected defaultValues: object = new Object();
  protected talentMetadata: any;
  protected metadataManagerActions = TalentMetadataManagerActions;

  constructor(
    protected appConfig: AppConfigProvider,
    protected talentManager: TalentManager,
    protected notificationService: NotificationService,
    protected talentFormFactory: TalentFormFactoryService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.metadataManager && (changes.metadataManager.currentValue !== undefined)) {
      if (
        (TalentMetadataManagerActions.CREATE_TALENT === changes.metadataManager.currentValue.action) ||
        (TalentMetadataManagerActions.CREATE === changes.metadataManager.currentValue.action) ||
        (TalentMetadataManagerActions.EDIT === changes.metadataManager.currentValue.action)
      ) {
        this.setupForm();
        this.toggleManager(true);
      } else {
        if (
          (this.metadataManager.action === TalentMetadataManagerActions.LOCK) ||
          (this.metadataManager.action === TalentMetadataManagerActions.UNLOCK)
        ) {
          this.setLockStatus();
        } else if (this.metadataManager.action === TalentMetadataManagerActions.DELETE) {
          this.deleteAttribute();
        }
      }
    } else {
      this.toggleManager(false);
    }
  }

  /**
   * Builds the form, based on different business rules.
   *
   * @returns void
   */
  protected setupForm(): void {
    this.setDefaultFormValues();

    if (this.metadataManager.action === TalentMetadataManagerActions.CREATE) {
      this.defaultValues['originalLanguageId'] = this.metadataManager.talent.originalLanguageId;
      this.defaultValues['language'] = this.metadataManager.talent.language || [];

      this.metadataForm = this.talentFormFactory.buildLocalizedDataForm(this.defaultValues);
    } else if (this.metadataManager.action === TalentMetadataManagerActions.CREATE_TALENT) {
      this.defaultValues['language'] = [];

      if (!this.metadataManager.talent.middleName) {
        this.metadataManager.talent.noMiddleName = true;
      }
      if (!this.metadataManager.talent.prefixId) {
        this.metadataManager.talent.noPrefix = true;
      }
      if (!this.metadataManager.talent.suffixId) {
        this.metadataManager.talent.noSuffix = true;
      }

      this.metadataForm = this.talentFormFactory.buildOriginalDataForm(this.metadataManager.talent);
    } else {
      this.metadataForm = this.talentFormFactory.buildLocalizedDataForm(this.metadataManager.talentMetadata.originalData, false);
    }
  }

  /**
   * Sets the default values.
   *
   * @returns void
   */
  protected setDefaultFormValues(): void {
    this.defaultValues = {
      territory: [0],
      productType: [0],
      account: [0],
      noMiddleName: true,
      noPrefix: true,
      noSuffix: true
    };
  }

  /**
   * Toggle the TalentMetadata Form visibility
   *
   * @param show boolean
   * @returns void
   */
  protected toggleManager(show: boolean): void {
    this.show = show;
    if (!show) {
      this.metadataManager = undefined;
    }
  }

  /**
   * Handles the Cancel flow, to toggle off the Form.
   * If unsaved changes are detected, the corresponding modal will be shown.
   *
   * @param force boolean
   * @returns void
   */
  protected cancel(force: boolean): void {

    if (force || this.metadataForm.pristine) {
      this.toggleManager(false);
      this.preventCancelModal.hide();
      return;
    }

    if (!this.metadataForm.pristine) {
      this.preventCancelModal.show();
    }
  }


  /**
   * Handles the click on Save button
   *
   * @param force boolean
   * @returns void
   */
  protected save(force: boolean = false): void {
    this.status = DataStatusEnum.fetchingData;

    const talentMetadata: Talent = new Talent(
      Object.assign(
        new Object(),
        this.defaultValues,
        this.obtainAttributesNotInherited()
      )
    );

    if (this.metadataManager.action === TalentMetadataManagerActions.CREATE_TALENT) {
      talentMetadata.language = talentMetadata.originalLanguageId;
      this.createTalent(talentMetadata);
      return;
    }

    // language equals to the original language
    talentMetadata.originalLanguageId = talentMetadata.language;

    this.talentManager.getLocaleFromLocaleIds(talentMetadata).subscribe(
      (localeString: string) => {
        this.talentManager.getTalentMetadataByLocale(
          this.metadataManager.talent.id,
          localeString
        ).subscribe(
          (talentMetadataByLocale: StormServiceResponseSingleInterface) => {
            if (!talentMetadataByLocale || force || this.metadataManager.action === TalentMetadataManagerActions.EDIT) {
              this.setMetadata(talentMetadata);
              return;
            }

            if (talentMetadataByLocale) {
              this.status = DataStatusEnum.idle;
              this.notifyOverrideMetadataModal.show();
            }
          },
          (error: any) => {
            this.status = DataStatusEnum.idle;
            this.notificationService.handleError('Error while trying to add the Customization', error);
          }
        );
      }
    );
  }

  /**
   * Handles the create Talent flow
   *
   * @param talent TalentInterface
   * @returns void
   */
  protected createTalent(talent: TalentInterface): void {

    this.status = DataStatusEnum.fetchingData;

    this.talentManager.createTalent(talent).subscribe(

      serviceResponseSingle => {

        const talentAuxiliary = serviceResponseSingle.item;

        this.metadataUpdated.emit(talentAuxiliary);
        this.status = DataStatusEnum.idle;
        this.toggleManager(false);

        this.notificationService.handleNotice(
          'Talent ' + [talentAuxiliary.firstName, talentAuxiliary.lastName].join() + ' successfully added'
        );
      },
      (error: ErrorHelper) => {

        this.metadataUpdated.emit(false);
        this.status = DataStatusEnum.idle;

        this.notificationService.handleError(
          'Error while trying to add the new Talent',
          error
        );
      }
    );
  }

  /**
   * Handles the create/edit TalentMetadata flow
   *
   * @param metadata Talent
   * @returns void
   */
  protected setMetadata(metadata: Talent): void {

    this.status = DataStatusEnum.fetchingData;

    this.talentManager.getLocaleFromLocaleIds(metadata).subscribe(

      locale => {

        this.talentManager.setTalentMetadata(
          this.metadataManager.talent.id,
          locale,
          metadata,
          false
        ).subscribe(
          serviceResponseSingle => {

            this.metadataUpdated.emit(serviceResponseSingle.item);
            this.status = DataStatusEnum.idle;
            this.notifyOverrideMetadataModal.hide();

            this.toggleManager(false);

            this.notificationService.handleNotice(
              'Talent successfully added',
              `Localization created for ${(<TalentMetadataInterface>serviceResponseSingle.item).locale}`
            );
          },
          (error: ErrorHelper) => {

            this.metadataUpdated.emit(false);
            this.status = DataStatusEnum.idle;

            this.notificationService.handleError('Error while trying to add the Customization', error);
          }
        );
      }
    );

  }

  /**
   * Handles the Lock/Unlock flow
   *
   * @param force boolean
   * @returns void
   */
  protected setLockStatus(force: boolean = false): void {

    if (!force) {
      this.confirmLockUpdateModal.show();
    } else {

      this.status = DataStatusEnum.fetchingData;

      let lock: boolean;
      if (this.metadataManager.action === TalentMetadataManagerActions.LOCK) {
        lock = true;
      } else if (this.metadataManager.action === TalentMetadataManagerActions.UNLOCK) {
        lock = false;
      }

      this.talentManager.getLocaleFromLocaleIds(this.metadataManager.talentMetadata.originalData).subscribe(
        locale => {

          this.talentManager.setTalentMetadata(
            this.metadataManager.talent.id,
            locale,
            new Talent(new Object()),
            lock
          ).subscribe(
            serviceResponseSingle => {

              this.metadataUpdated.emit(serviceResponseSingle.item);
              this.status = DataStatusEnum.idle;
              this.confirmLockUpdateModal.hide();

              this.notificationService.handleNotice(
                `The localization ${locale} is ${(lock ? 'locked' : 'unlocked')}`
              );
            },
            (error: ErrorHelper) => {

              this.metadataUpdated.emit(false);
              this.status = DataStatusEnum.idle;

              this.notificationService.handleError(
                'Error while trying to update the Localization lock status',
                error
              );
            }
          );
        }
      );
    }
  }

  /**
   * Handles the Lock/Unlock flow
   *
   * @param force boolean
   * @returns void
   */
  protected deleteAttribute(force: boolean = false): void {

    if (!force) {
      this.confirmDeleteAttributeModal.show();
    } else {

      this.status = DataStatusEnum.fetchingData;

      this.talentManager.getLocaleFromLocaleIds(this.metadataManager.talentMetadata.originalData).subscribe(
        locale => {

          this.talentManager.deleteTalentMetadataAttribute(
            this.metadataManager.talent.id,
            locale,
            this.metadataManager.key
          ).subscribe(
            productMetadata => {

              this.metadataUpdated.emit(true);
              this.status = DataStatusEnum.idle;
              this.confirmDeleteAttributeModal.hide();
              this.notificationService.handleNotice('Customization successfully deleted');
            },
            (error: ErrorHelper) => {
              this.metadataUpdated.emit(false);
              this.status = DataStatusEnum.idle;
              this.notificationService.handleError('Error while trying to delete the Customization', error);
            }
          );
        }
      );
    }
  }

  /**
   * Obtains the not inherited attributes to save.
   *
   * @returns any
   */
  protected obtainAttributesNotInherited(): any {
    const attributesNotInherited = this.metadataForm.toJson();

    const isEditAction: boolean = this.metadataManager.action === this.talentMetadataManagerActions.EDIT;
    const hasManagerKey: boolean = (
      !_isUndefined(this.metadataManager.key) &&
      (
        this.metadataManager.key[0] === TalentLocalizedAttributesEnum.firstName ||
        this.metadataManager.key[0] === TalentLocalizedAttributesEnum.lastName
      )
    );

    if (isEditAction && hasManagerKey) {
      delete attributesNotInherited[TalentLocalizedAttributesEnum.middleName];
    }

    return attributesNotInherited;
  }
}
