import { Component, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Language } from '@bolt/ui-shared/master-data';
import { FunctionalMetadataFormHandlerService, FunctionalMetadataService } from '@bolt/ui-shared/title';
import { isObject as _isObject } from 'lodash';
import { flatMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ActionHandlerService } from '../../services/level-data/action-handler/action-handler.service';
import { BoltTitleLevelDataBaseFormComponent } from '../bolt-title-level-data-base-form/bolt-title-level-data-base-form.component';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { Episode } from '../../models/episode.model';
import { EpisodeForm } from '../../models/level-data/form/episode/episode-form.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FormHandlerService } from '../../services/level-data/form-handler/form-handler.service';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { Season } from '../../models/season.model';
import { Series } from '../../models/series.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleService } from '../../services/title.service';
import { TitleType } from '../../models/title.model';


@Component({
  selector: 'bolt-title-level-data-episode-form',
  template: require('./bolt-title-level-data-episode-form.html'),
  styles: [require('./bolt-title-level-data-episode-form.scss')]
})
export class BoltTitleLevelDataEpisodeFormComponent extends BoltTitleLevelDataBaseFormComponent implements OnChanges, OnDestroy {
  @Input() title: Episode;

  protected form: EpisodeForm;
  protected parentSeason: Season;
  protected parentSeasonLoading: boolean;
  protected parentSeries: Series;
  protected parentSeriesLoading: boolean;
  protected spokenLanguage: Language;
  protected spokenLanguageLoading: boolean;

  constructor(
    protected actionHandler: ActionHandlerService,
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected formHandler: FormHandlerService,
    protected functionalMetadataService: FunctionalMetadataService,
    protected functionalMetadataFormHandler: FunctionalMetadataFormHandlerService,
    protected masterDataManager: MasterDataManager,
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) {
    super(
      actionHandler,
      appConfigurationManager,
      capabilitiesManager,
      formHandler,
      functionalMetadataService,
      functionalMetadataFormHandler,
      masterDataManager,
      notificationService,
      titleService
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.title) {
      this.formHandler.createEpisodeForm(this.title);
    }
  }

  protected enableForm(): void {
    super.enableForm();
    this.setFunctionalMetadataState();
  }

  /**
   * Indicates if there is a spoken language.
   *
   * @returns boolean
   */
  protected hasSpokenLanguage(): boolean {
    return _isObject(this.spokenLanguage);
  }

  protected loadParents(): void {
    this.parentSeasonLoading = true;
    this.parentSeriesLoading = true;

    const subs: Subscription = this.titleService.fetchProduct({
      productId: this.title.seasonId,
      productType: TitleType.season
    }).pipe(
      flatMap(
        (serviceResponseSingle: StormServiceResponseSingle) => {
          this.parentSeason = serviceResponseSingle.item;

          return this.titleService.fetchProduct({
            productId: this.parentSeason.seriesId,
            productType: TitleType.series
          });
        }
      )
    ).subscribe(
      (serviceResponseSingle: StormServiceResponseSingle) => {
        this.parentSeries = serviceResponseSingle.item;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the parent titles', error);
      },
      () => {
        this.parentSeasonLoading = false;
        this.parentSeriesLoading = false;
      }
    );

    this.subscriptions.add(subs);
  }

  protected hasParentData(): boolean {
    return _isObject(this.parentSeason) && _isObject(this.parentSeries);
  }

  protected loadDependencies(): void {
    this.loadFunctionalMetadata();
    this.loadLanguages();
    this.loadParents();
    this.loadSpokenLanguage();
  }

  /**
   * Loads the spoken language.
   *
   * @returns void
   */
  protected loadSpokenLanguage(): void {
    this.spokenLanguageLoading = true;

    const subs: Subscription = this.masterDataManager.getLanguageById(this.title.originalSpokenLanguageId).subscribe(
      (language: Language) => {
        this.spokenLanguage = language;
        this.spokenLanguageLoading = false;
      }
    );

    this.subscriptions.add(subs);
  }
}
