import { Injectable } from '@angular/core';
import { AppConfigProvider, ConfigurationTypeEnum } from '@bolt/ui-shared/configuration';
import { HttpError } from '@bolt/ui-shared/common';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Country, Language, StormListItemInterface, StormListsInterface, StormListType } from '@bolt/ui-shared/master-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  cloneDeep as _cloneDeep, groupBy as _groupBy, isArray as _isArray, isObject as _isObject, isNull as _isNull, isString as _isString,
  isUndefined as _isUndefined
} from 'lodash';

import { CollectionManagerHelper } from 'app/modules/common/helpers/collection-manager.helper';
import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Localization } from '../../models/localization.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { Subtitle } from '../../models/subtitle.model';
import { SubtitleDetail } from '../../models/subtitle-detail.model';
import { SubtitleLockingStatus } from '../../models/subtitle-locking-status.model';
import { SubtitleService } from '../subtitle/subtitle.service';
import { TitleMetadataInterface } from 'app/modules/title/models/title-metadata.model';
import { TitleType, Title } from 'app/modules/title/models/title.model';


@Injectable()
export class ManagerService {
  protected _isAnyVersionInEdition: boolean;
  protected _languages: any[];
  protected _localizationsCollectionName: string = 'subtitles-localizations';
  protected _localizedVersion: Subtitle;
  protected _notificationLife: number;
  protected _originalVersion: Subtitle;
  protected _originalVersionStatus: DataStatusEnum = DataStatusEnum.idle;
  protected _originalVersionStatusSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  protected _originalVersionStatusTriggered: Observable<DataStatusEnum>;
  protected _selectedLocalization: string;
  protected _selectedLocalizationChangeSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  protected _selectedLocalizationChangeTriggered: Observable<string>;
  protected _subtitlesManagerStatus: DataStatusEnum = DataStatusEnum.idle;
  protected _subtitlesManagerStatusSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  protected _subtitlesManagerStatusTriggered: Observable<DataStatusEnum>;
  protected _territories: any[];

  // TODO: Refactor below to work with only one option. Dual option for backward compatibility with old code.
  protected _title: Title;
  protected _titleId: number;
  protected _titleType: TitleType;

  constructor(
    protected appConfig: AppConfigProvider,
    protected collectionManager: CollectionManagerHelper,
    protected entityMapperHelper: EntityMapperHelper,
    protected notificationService: NotificationService,
    protected stormListsProvider: StormListsProvider,
    protected subtitleService: SubtitleService
  ) {
    this.initialize();
  }

  get languages(): any[] {
    return this._languages;
  }

  get localizedVersion(): Subtitle {
    return this._localizedVersion;
  }

  get notificationLife(): number {
    return this._notificationLife;
  }

  get originalVersion(): Subtitle {
    return this._originalVersion;
  }

  get originalVersionStatus(): DataStatusEnum {
    return this._originalVersionStatus;
  }

  get originalVersionStatusTriggered(): Observable<DataStatusEnum> {
    return this._originalVersionStatusTriggered;
  }

  get selectedLocalization(): string {
    return this._selectedLocalization;
  }

  get selectedLocalizationChangeTriggered(): Observable<any> {
    return this._selectedLocalizationChangeTriggered;
  }

  get localizationsCollectionName(): string {
    return this._localizationsCollectionName;
  }

  get subtitlesManagerStatus(): DataStatusEnum {
    return this._subtitlesManagerStatus;
  }

  get subtitlesManagerStatusTriggered(): Observable<DataStatusEnum> {
    return this._subtitlesManagerStatusTriggered;
  }

  get territories(): any[] {
    return this._territories;
  }

  get title(): Title {
    return this._title;
  }

  set title(title: Title) {
    // TODO: Refactor below to work with only one option. Dual option for backward compatibility with old code.
    this._title = title;
    this._titleId = title.id;
    this._titleType = title.type;
  }

  get titleId(): number {
    return this._titleId;
  }

  set titleId(titleId: number) {
    // TODO: Refactor below to work with only one option. Dual option for backward compatibility with old code.
    this._title = undefined;
    this._titleId = titleId;
  }

  get titleType(): TitleType {
    return this._titleType;
  }

  set titleType(titleType: TitleType) {
    // TODO: Refactor below to work with only one option. Dual option for backward compatibility with old code.
    this._title = undefined;
    this._titleType = titleType;
  }

  /**
   * Adds a new locale to collection called subtitle and inserts from the collection manager.
   *
   * @param languageId number
   * @param territoryId number
   * @returns void
   */
  addLocaleToLocalizationsCollection(languageId: number, territoryId: number): Promise<boolean> {
    return new Promise(
      (resolve, reject) => {
        try {
          const language = this.languages.find(
            aLanguage => aLanguage.entity.id === languageId
          );

          const territory = this.territories.find(
            aTerritory => aTerritory.entity.id === territoryId
          );

          const locale: string = `${language.entity.localeLanguage}_${territory.entity.iso31661}_*_*`;
          const localizationsCollection = this.collectionManager.getCollection(this.localizationsCollectionName);

          const existingLocalization: any = localizationsCollection.rawCollection.filter(
            localization => {
              return (localization.locale === locale);
            }
          ).pop();

          if (_isObject(existingLocalization)) {
            const error = new ErrorHelper('The selected locale is already added to the full list of locales');
            this.handleError('Failed adding the locale.', error);

            resolve(false);
            reject(error);
          } else {
            const newLocalization: any = {
              locale: locale,
              language: language.entity,
              territory: territory.entity,
              locked: false
            };

            this.collectionManager.addItemsToCollection(this.localizationsCollectionName, [newLocalization]);

            resolve(true);
          }
        } catch (error) {
          resolve(false);
          reject(error);
        }
      }
    );
  }

  /**
   * Adds the given localizations into their collection.
   *
   * @param localizations Localization[]
   * @returns Promise<boolean>
   */
  addRawLocalesToLocalizationsCollection(localizations: Localization[]): Promise<boolean> {
    return new Promise(
      (resolve, reject) => {
        try {
          const items: any[] = [];

          localizations.forEach(
            (localization: Localization) => {
              const locale: string[] = localization.locale.split('_');

              const language = this.languages.find(
                aLanguage => aLanguage.entity.localeLanguage === locale[0]
              );

              const territory = this.territories.find(
                aTerritory => aTerritory.entity.iso31661 === locale[1]
              );

              const item: any = {
                locale: localization.locale,
                language: language.entity,
                territory: territory.entity,
                locked: localization.isLocked()
              };

              items.push(item);
            }
          );

          this.collectionManager.addItemsToCollection(this.localizationsCollectionName, items);

          resolve(true);
        } catch (error) {
          resolve(false);
          reject(error);
        }
      }
    );
  }

  /**
   * Reset all.
   *
   * @returns void
   */
  resetAll(): void {
    this._title = undefined;
    this._titleId = undefined;
    this._titleType = undefined;

    this.resetLocalizationsCollection();
    this.resetLocalizedVersion();
    this.resetNotifications();
    this.resetOriginalVersion();
    this.resetSelectedLocalization();
    this.turnOffAnyVersionOnEdition();
  }

  /**
   * Creates an empty collection called subtitle and inserts to initialize or reset it
   * at collection manager.
   *
   * @returns void
   */
  resetLocalizationsCollection(): void {
    this.collectionManager.setCollections([
      {
        name: this.localizationsCollectionName,
        collection: [],
        sorting: [],
        paginate: true,
        pagination: {
          page_size: this.appConfig.get('ux.dataTables.pageSize', 20, ConfigurationTypeEnum.number)
        },
      }
    ]);
  }

  /**
   * Fetches the localized version for the stored title and selected localization.
   * Creates it if it not exists.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @param loadLockingStatus boolean
   * @returns void
   */
  fetchLocalizedVersion(onSuccessDo: any, onErrorDo: any, finallyDo?: any, loadLockingStatus: boolean = true): void {
    if (this.hasSelectedLocalization()) {
      this.subtitleService.fetchSubtitleLocalization(this.titleType, this.titleId, this.selectedLocalization).subscribe(
        (subtitle: Subtitle) => {
          this._localizedVersion = subtitle;

          this.synchronizeBothVersions();

          if (loadLockingStatus) {
            this.fetchLockingStatusForLocalizedVersion(onSuccessDo, onErrorDo, finallyDo);
          } else {
            this.executeOnSuccessDo(onSuccessDo, finallyDo);
          }
        },
        (error: any) => {
          if (error.status === 404) {
            this.createLocalizedVersion();
            this.executeOnSuccessDo(onSuccessDo, finallyDo);
          } else {
            this.throwError('Failed retrieving localized version', error);
            this.executeOnErrorDo(onErrorDo, finallyDo);
          }
        }
      );
    }
  }

  /**
   * Fetches the original version for the stored title.
   * Creates it if it not exists.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @param loadLockingStatus boolean
   * @returns void
   */
  fetchOriginalVersion(onSuccessDo: any, onErrorDo: any, finallyDo?: any, loadLockingStatus: boolean = true): void {
    this.changeOriginalVersionStatusTo(DataStatusEnum.fetchingData);

    this.subtitleService.fetchSubtitle(this.titleType, this.titleId).subscribe(
      (subtitle: Subtitle) => {
        this._originalVersion = subtitle;

        if (loadLockingStatus) {
          this.fetchLockingStatusForOriginalVersion(onSuccessDo, onErrorDo, finallyDo);
        } else {
          this.changeOriginalVersionStatusTo(DataStatusEnum.dataFound);
          this.executeOnSuccessDo(onSuccessDo, finallyDo);
        }
      },
      (error: HttpError) => {
        if (error.is404()) {
          this._originalVersion = new Subtitle({
            titleId: this.titleId,
            titleType: this.titleType,
            lockedStatus: false
          });

          this.changeOriginalVersionStatusTo(DataStatusEnum.dataFound);
          this.executeOnSuccessDo(onSuccessDo, finallyDo);
        } else {
          this.throwError('Failed retrieving original version', error);
          this.changeOriginalVersionStatusTo(DataStatusEnum.error);
          this.executeOnErrorDo(onErrorDo, finallyDo);
        }
      }
    );
  }

  /**
   * Returns the APP configuration provider.
   *
   * @returns AppConfigProvider
   */
  getAppConfig(): AppConfigProvider {
    return this.appConfig;
  }

  /**
   * Returns the language for the given locale.
   *
   * @param locale string
   * @returns Language
   */
  getLanguage(locale: string): Language {
    const target: string = locale.split('_')[0];

    const language: Language = this.languages.filter(
      lan => {
        return (lan.value === target);
      }
    ).pop().entity;

    return language;
  }

  /**
   * Returns the language for the given ID.
   *
   * @param id number
   * @returns Language
   */
  getLanguageById(id: number): Language {
    const _id: number = this.ensureOneId(id);

    const language: Language = this.languages.filter(
      lan => {
        return (lan.entity.id === _id);
      }
    ).pop().entity;

    return language;
  }

  /**
   * Get all the lists available at storm list provider.
   *
   * @returns Observable<StormListsInterface>
   */
  getStormLists(): Observable<StormListsInterface> {

    return this.stormListsProvider.getLists();
  }

  /**
   * Returns the territory for the given locale.
   *
   * @param subtitle Subtitle
   * @returns Language
   */
  getTerritory(locale: string): Country {
    const target: string = locale.split('_')[1];

    const territory: Country = this.territories.filter(
      ter => {
        return (ter.value === target);
      }
    ).pop().entity;

    return territory;
  }

  /**
   * Returns the territory for the given ID.
   *
   * @param id number
   * @returns Language
   */
  getTerritoryById(id: number): Country {
    const _id: number = this.ensureOneId(id);

    const territory: Country = this.territories.filter(
      ter => {
        return (ter.entity.id === _id);
      }
    ).pop().entity;

    return territory;
  }

  /**
   * (Placeholder)Group territories by region if needed to get a territory in this way.
   *
   * @param productMetadata TitleMetadataInterface
   * @returns Promise<string[]>
   */
  groupTerritoriesByRegion(productMetadata: TitleMetadataInterface): Promise<string[]> {
    return new Promise((resolve, reject) => {

      this.stormListsProvider.getList(StormListType.territory).subscribe(territoryList => {

        let result: string[] = [];
        const territoryRegions = territoryList.groupBy('value.region');
        const productTerritoryRegions = _groupBy(
          <Country[]>productMetadata.territory,
          'region'
        );

        Object.keys(productTerritoryRegions).forEach(region => {
          if (productTerritoryRegions[region].length === territoryRegions[region].length) {
            result = [region, ...result];
          } else {
            const auxiliaryProductTerritoryRegion: string[] = <string[]>productTerritoryRegions[region].map(
              product => product.toString()
            );
            result = [...result, ...auxiliaryProductTerritoryRegion];
          }
        });

        resolve(result);
      });
    });
  }

  /**
   * Adds the given message and error.
   *
   * @param message string
   * @param error ErrorHelper
   * @returns void
   */
  handleError(message: string, error: ErrorHelper): void {
    console.error(`Message: ${message}`, `Reason: ${error.toString()}`);
    this.notificationService.handleError(message, error);
  }

  /**
   * Adds the given message.
   *
   * @param message string
   * @returns void
   */
  handleNotice(message: string): void {
    this.notificationService.handleNotice(message);
  }

  /**
   * Indicates if there are languages.
   *
   * @returns boolean
   */
  hasLanguages(): boolean {
    const hasIt: boolean = (this.languages.length > 0);
    return hasIt;
  }

  /**
   * Indicates if there is an localized version with all its data.
   *
   * @returns boolean
   */
  hasLocalizedVersionByComplete(): boolean {
    const hasIt: boolean = (
      !_isUndefined(this.localizedVersion) &&
      !_isUndefined(this.localizedVersion.lockedStatus)
    );

    return hasIt;
  }

  /**
   * Indicates if there is an original version with all its data.
   *
   * @returns boolean
   */
  hasOriginalVersionByComplete(): boolean {
    const hasIt: boolean = (
      !_isUndefined(this.originalVersion) &&
      !_isUndefined(this.originalVersion.lockedStatus)
    );

    return hasIt;
  }

  /**
   * Indicates if there is a selected localization.
   *
   * @returns boolean
   */
  hasSelectedLocalization(): boolean {
    const hasIt: boolean = (
      _isString(this.selectedLocalization) &&
      (this.selectedLocalization.trim().length > 0)
    );

    return hasIt;
  }

  /**
   * Indicates if there are territories.
   *
   * @returns boolean
   */
  hasTerritories(): boolean {
    const hasIt: boolean = (this.territories.length > 0);
    return hasIt;
  }

  /**
   * Indicates if any version is in edition.
   *
   * @returns boolean
   */
  isAnyVersionInEdition(): boolean {
    return this._isAnyVersionInEdition;
  }

  /**
   * Reset the localized version.
   *
   * @returns void
   */
  resetLocalizedVersion(): void {
    this._localizedVersion = undefined;
  }

  /**
   * Reset the notifications.
   *
   * @returns void
   */
  resetNotifications(): void {
    this.notificationService.clean();
  }

  /**
   * Reset the original version.
   *
   * @returns void
   */
  resetOriginalVersion(): void {
    this._originalVersion = undefined;
  }

  /**
   * Reset the selected localization.
   *
   * @returns void
   */
  resetSelectedLocalization(): void {
    this.setSelectedLocalization(undefined);
  }

  /**
   * Saves the localized version.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns void
   */
  saveLocalizedVersion(onSuccessDo: any, onErrorDo: any, finallyDo?: any): void {
    this.subtitleService.saveSubtitleLocalization(this.localizedVersion).then(
      data => {
        this.handleNotice('Localized version was saved successfully');
        this.executeOnSuccessDo(onSuccessDo, finallyDo);
      },
      (reason: any) => {
        this.throwError('Failed saving localized version', reason);
        this.executeOnErrorDo(onErrorDo, finallyDo);
      }
    );
  }

  /**
   * Saves the original version.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns void
   */
  saveOriginalVersion(onSuccessDo: any, onErrorDo: any, finallyDo?: any): void {
    this.subtitleService.saveSubtitle(this.originalVersion).then(
      data => {
        this.handleNotice('Original version was saved successfully');
        this.executeOnSuccessDo(onSuccessDo, finallyDo);
      },
      (reason: any) => {
        this.throwError('Failed saving original version', reason);
        this.executeOnErrorDo(onErrorDo, finallyDo);
      }
    );
  }

  /**
   * Set the selected localization.
   *
   * @param string localization
   * @returns void
   */
  setSelectedLocalization(localization: string): void {
    this._selectedLocalization = localization;
    this._selectedLocalizationChangeSubject.next(this._selectedLocalization);
  }

  /**
   * Toggles the locking status for the localized version.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns void
   */
  toggleLockingStatusForLocalizedVersion(onSuccessDo: any, onErrorDo: any, finallyDo?: any): void {
    this.subtitleService.updateSubtitleLocalizationLockingStatusLockUnlock(
      this.localizedVersion,
      !this.localizedVersion.lockedStatus.locked
    ).then(
      () => {
        this.handleNotice('Locking status for localized version changed successfully');
        this.executeOnSuccessDo(onSuccessDo, finallyDo);
      },
      (reason: any) => {
        this.throwError('Failed changing the locking status for localized version', reason);
        this.executeOnErrorDo(onErrorDo, finallyDo);
      }
    );
  }

  /**
   * Toggles the locking status for the original version.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns void
   */
  toggleLockingStatusForOriginalVersion(onSuccessDo: any, onErrorDo: any, finallyDo?: any): void {
    this.subtitleService.updateSubtitleLockingStatusLockUnlock(
      this.originalVersion,
      !this.originalVersion.lockedStatus.locked
    ).then(
      () => {
        this.handleNotice('Locking status for Original version changed successfully');
        this.executeOnSuccessDo(onSuccessDo, finallyDo);
      },
      (reason: any) => {
        this.throwError('Failed changing the locking status for original version', reason);
        this.executeOnErrorDo(onErrorDo, finallyDo);
      }
    );
  }

  /**
   * Turns off the indicator about if any version is on Edition.
   *
   * @returns void
   */
  turnOffAnyVersionOnEdition(): void {
    this._isAnyVersionInEdition = false;
  }

  /**
   * Turns on the indicator about if any version is on Edition.
   *
   * @returns void
   */
  turnOnAnyVersionOnEdition(): void {
    this._isAnyVersionInEdition = true;
  }

  /**
   * Updates the subtitle details in the original version, using the given raw data.
   *
   * @param rawData any[]
   * @param untouchedSubtitles SubtitleDetail[]
   * @returns void
   */
  updateSubtitleDetailsInLocalizedVersion(rawData: any[], untouchedSubtitles: SubtitleDetail[]): void {
    if (untouchedSubtitles) {
      this.localizedVersion.subtitleDetails = untouchedSubtitles;
    } else {
      this.localizedVersion.resetSubtitleDetails();
    }

    rawData.forEach(
      (data: any) => {
        const subtitleDetail: SubtitleDetail = new SubtitleDetail(data);
        this.localizedVersion.addSubtitleDetail(subtitleDetail);
      }
    );
  }

  /**
   * Updates the subtitle details in the original version, using the given raw data.
   *
   * @param rawData any[]
   * @param untouchedSubtitles SubtitleDetail[]
   * @returns void
   */
  updateSubtitleDetailsInOriginalVersion(rawData: any[], untouchedSubtitles: SubtitleDetail[]): void {
    if (untouchedSubtitles) {
      this.originalVersion.subtitleDetails = untouchedSubtitles;
    } else {
      this.originalVersion.resetSubtitleDetails();
    }

    rawData.forEach(
      (data: any) => {
        const subtitleDetail: SubtitleDetail = new SubtitleDetail(data);
        this.originalVersion.addSubtitleDetail(subtitleDetail);
      }
    );
  }

  /**
   * Changes the stored original version status.
   *
   * @param newStatus DataStatusEnum
   * @returns void
   */
  protected changeOriginalVersionStatusTo(newStatus: DataStatusEnum): void {
    this._originalVersionStatus = newStatus;
    this._originalVersionStatusSubject.next(this.originalVersionStatus);
  }

  /**
   * Changes the stored subtitles manager status.
   *
   * @param newStatus DataStatusEnum
   * @returns void
   */
  protected changeSubtitlesManagerStatusTo(newStatus: DataStatusEnum): void {
    this._subtitlesManagerStatus = newStatus;
    this._subtitlesManagerStatusSubject.next(this.subtitlesManagerStatus);
  }

  /**
   * Creates and store a new localized version using the stored original version and selected localization.
   *
   * @returns void
   */
  protected createLocalizedVersion(): void {
    this._localizedVersion = new Subtitle({
      titleId: this.titleId,
      titleType: this.titleType,
      lockedStatus: false,
      locale: this.selectedLocalization
    });

    this.synchronizeBothVersions();
  }

  /**
   * Returns one ID if the given one is actually an array.
   * This is because we are changing the data structure from services,
   * in order to have compatibility with "p-deprecated-multiselect" component.
   *
   * @todo Check if this is actually deprecated.
   * @param id number | number[]
   * @returns number
   */
  protected ensureOneId(id: number | number[]): number {
    if (_isArray(id)) {
      const temp: number[] = _cloneDeep(id);
      return temp.pop();
    } else {
      return id;
    }
  }

  /**
   * Executes the given onErrorDo and finallyDo, if the last one was defined.
   *
   * @param onErrorDo any
   * @param finallyDo any
   * @returns void
   */
  protected executeOnErrorDo(onErrorDo: any, finallyDo: any): void {
    onErrorDo();

    if (finallyDo) {
      finallyDo();
    }
  }

  /**
   * Executes the given onSuccessDo and finallyDo, if the last one was defined.
   *
   * @param onSuccessDo any
   * @param finallyDo any
   * @returns void
   */
  protected executeOnSuccessDo(onSuccessDo: any, finallyDo: any): void {
    onSuccessDo();

    if (finallyDo) {
      finallyDo();
    }
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this._notificationLife = this.appConfig.get('ux.growl.life');

    this._originalVersion = undefined;

    this._originalVersionStatusSubject.pipe(
      filter(x => _isNull(x))
    );

    this._originalVersionStatusTriggered = this._originalVersionStatusSubject.asObservable();

    this._localizedVersion = undefined;

    this._selectedLocalizationChangeSubject.pipe(
      filter(x => _isNull(x))
    );

    this._selectedLocalizationChangeTriggered = this._selectedLocalizationChangeSubject.asObservable();

    this._subtitlesManagerStatusSubject.pipe(
      filter(x => _isNull(x))
    );

    this._subtitlesManagerStatusTriggered = this._subtitlesManagerStatusSubject.asObservable();

    this.resetNotifications();
    this.changeOriginalVersionStatusTo(DataStatusEnum.idle);
    this.changeSubtitlesManagerStatusTo(DataStatusEnum.fetchingData);
    this.turnOffAnyVersionOnEdition();

    const setupPromises: Array<Promise<boolean>> = [];
    setupPromises.push(this.setupLanguages());
    setupPromises.push(this.setupTerritories());

    Promise.all(setupPromises).then(
      (setupResponses) => {
        if (setupResponses[0] && setupResponses[1]) {
          this.changeSubtitlesManagerStatusTo(DataStatusEnum.dataFound);
        }
      }
    );
  }

  /**
   * Fetches the locking status for localized version.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns void
   */
  protected fetchLockingStatusForLocalizedVersion(onSuccessDo: any, onErrorDo: any, finallyDo: any): void {
    this.subtitleService.fetchSubtitleLocalizationLockingStatus(
      this.localizedVersion.titleType,
      this.localizedVersion.titleId,
      this.localizedVersion.locale
    ).subscribe(
      (lockingStatus: SubtitleLockingStatus) => {
        if (_isObject(this.localizedVersion)) {
          this._localizedVersion.setLockedStatus(lockingStatus);
          this.updateLockingStatusInLocalizations();
          this.executeOnSuccessDo(onSuccessDo, finallyDo);
        }
      },
      (error: any) => {
        if (_isObject(this.localizedVersion)) {
          if (error.status === 404) {
            this._localizedVersion.setLockedStatus(new SubtitleLockingStatus({ }));
            this.executeOnSuccessDo(onSuccessDo, finallyDo);
          } else {
            this.throwError('Failed retrieving locking status', error);
            this.executeOnErrorDo(onErrorDo, finallyDo);
          }
        }
      }
    );
  }

  /**
   * Fetches the locking status for original version.
   *
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns void
   */
  protected fetchLockingStatusForOriginalVersion(onSuccessDo: any, onErrorDo: any, finallyDo: any): void {
    this.subtitleService.fetchSubtitleLockingStatus(this.originalVersion.titleType, this.originalVersion.titleId).subscribe(
      (lockingStatus: SubtitleLockingStatus) => {
        if (_isObject(this.originalVersion)) {
          this._originalVersion.setLockedStatus(lockingStatus);

          this.changeOriginalVersionStatusTo(DataStatusEnum.dataFound);
          this.executeOnSuccessDo(onSuccessDo, finallyDo);
        }
      },
      (error: HttpError) => {
        if (_isObject(this.originalVersion)) {
          if (error.is404()) {
            this._originalVersion.setLockedStatus(new SubtitleLockingStatus({ }));

            this.changeOriginalVersionStatusTo(DataStatusEnum.dataFound);
            this.executeOnSuccessDo(onSuccessDo, finallyDo);
          } else {
            this.throwError('Failed retrieving locking status', error);
            this.changeOriginalVersionStatusTo(DataStatusEnum.error);
            this.executeOnErrorDo(onErrorDo, finallyDo);
          }
        }
      }
    );
  }

  /**
   * Returns the sorting criteria using the locale.
   *
   * @returns any
   */
  protected getLocaleSortingCriteria(): any {
    const criteria: CallableFunction = (itemA: StormListItemInterface, itemB: StormListItemInterface) => {
      if (itemA.value.name < itemB.value.name) {
        return -1;
      } else {
        if (itemA.value.name > itemB.value.name) {
          return 1;
        } else {
          return 0;
        }
      }
    };

    return criteria;
  }

  /**
   * Returns an data item form given language.
   *
   * @param language any
   * @returns any
   */
  protected newLanguageDataItem(language: any): any {
    const data: any = {
      label: language.name,
      value: language.localeLanguage,
      entity: <Language>language
    };

    return data;
  }

  /**
   * Returns an data item form given territory.
   *
   * @param territory any
   * @returns any
   */
  protected newTerritoryDataItem(territory: any): any {
    const data: any = {
      label: territory.name,
      value: territory.iso31661,
      group: territory.region,
      entity: <Country>territory
    };

    return data;
  }

  /**
   * Sets up the languages.
   *
   * @returns Promise<boolean>
   */
  protected setupLanguages(): Promise<boolean> {
    return new Promise(
      (resolve, reject) => {
        this._languages = new Array();

        this.stormListsProvider.getList(StormListType.language).subscribe(
          list => {
            if (list && list.collection && list.collection.length > 0) {
              const languageAll: any = list.getItem(0).value;
              const collection: StormListItemInterface[] = list.removeItem({ id: languageAll.id }).collection;

              this.languages.push(
                this.newLanguageDataItem(languageAll)
              );

              collection.sort(this.getLocaleSortingCriteria()).forEach(
                (language: StormListItemInterface) => {
                  this.languages.push(
                    this.newLanguageDataItem(language.value)
                  );
                }
              );

              resolve(true);
            } else {
              reject(false);
              throw new Error('No languages retrieved from service.');
            }
          },
          error => {
            throw error;
          }
        );
      }
    );
  }

  /**
   * Sets up the territories.
   *
   * @returns Promise<boolean>
   */
  protected setupTerritories(): Promise<boolean> {
    return new Promise(
      (resolve, reject) => {
        this._territories = new Array();

        this.stormListsProvider.getList(StormListType.territory).subscribe(
          list => {
            if (list && list.collection && list.collection.length > 0) {
              const territoryAll: any = list.getItem(0).value;
              const collection: StormListItemInterface[] = list.removeItem({ id: territoryAll.id }).collection;

              this.territories.push(
                this.newTerritoryDataItem(territoryAll)
              );

              collection.sort(this.getLocaleSortingCriteria()).forEach(
                (territory: StormListItemInterface) => {
                  this.territories.push(
                    this.newTerritoryDataItem(territory.value)
                  );
                }
              );

              resolve(true);
            } else {
              reject(false);
              throw new Error('No territories retrieved from service.');
            }
          },
          error => {
            throw error;
          }
        );
      }
    );
  }

  /**
   * Ensures subtitle details in the stored localized version.
   *
   * @returns void
   */
  protected synchronizeBothVersions(): void {
    if (this.hasLocalizedVersionByComplete() && this.hasOriginalVersionByComplete()) {
      const localizedDetails: SubtitleDetail[] = _cloneDeep(this.localizedVersion.subtitleDetails);

      this.localizedVersion.resetSubtitleDetails();

      this.originalVersion.subtitleDetails.forEach(
        (originalDetail: SubtitleDetail) => {
          let localizedDetail: SubtitleDetail = localizedDetails.filter(
            (currentDetail: SubtitleDetail) => {
              return (currentDetail.rootId === originalDetail.id);
            }
          ).pop();

          if (_isUndefined(localizedDetail)) {
            localizedDetail = new SubtitleDetail({
              rootId: originalDetail.id
            });
          }

          localizedDetail.subtitleType = originalDetail.subtitleType;

          this.localizedVersion.addSubtitleDetail(localizedDetail);
        }
      );
    }
  }

  /**
   * Throws an error with the given message and reason.
   *
   * @param message string
   * @param reason any
   * @returns void
   */
  protected throwError(message: string, reason: any): void {
    const error: ErrorHelper = new ErrorHelper(reason.statusText, reason.status);
    this.handleError(message, error);
  }

  /**
   * Updates the locking status in the localizations collection.
   *
   * @returns void
   */
  protected updateLockingStatusInLocalizations(): void {
    if (this.hasLocalizedVersionByComplete()) {
      const localizations: any[] = this.collectionManager.getCollection(this.localizationsCollectionName).collection;
      const target: any = localizations.filter((item: any) => item.locale === this.localizedVersion.locale)[0];

      if (_isObject(target)) {
        (target as any).locked = this.localizedVersion.isLocked();
        this.collectionManager.refreshCollection(this.localizationsCollectionName);
      }
    }
  }
}
