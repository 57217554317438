import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-project-icons-legend-popup',
  template: require('./bolt-project-icons-legend-popup.html'),
  styles: [require('./bolt-project-icons-legend-popup.scss')]
})
export class BoltProjectIconsLegendPopupComponent {
  @Input() show: boolean;

  constructor() {
    this.show = false;
  }
}
