import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isArray as _isArray, isEmpty as _isEmpty, isObject as _isObject } from 'lodash';


@Component({
  selector: 'bolt-cpm-configurations-mapping-json',
  template: require('./bolt-cpm-configurations-mapping-json.html'),
  styles: [require('./bolt-cpm-configurations-mapping-json.scss')]
})
export class BoltCpmConfigurationsMappingJsonComponent implements OnChanges {
  @Input() data: any;
  @Input() expanded: boolean;
  @Input() filter: string;

  protected filteredData: any;

  constructor() {
    this.filter = '';
    this.expanded = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.data) || _isObject(changes.filter)) {
      this.filteredData = (this.filter.length === 0) ? this.data : this.obtainFilteredData(this.data);
    }
  }

  /**
   * Returns the filtered data from de given source.
   *
   * @param source any
   * @returns any
   */
  protected obtainFilteredData(source: any): any {
    const output: any = { };

    Object.keys(source).forEach(
      (attribute: string) => {
        const value: any = source[attribute];

        if (attribute.includes(this.filter)) {
          output[attribute] = value;
        } else {
          if (_isArray(value) || _isObject(value)) {
            const child: any = this.obtainFilteredData(value);

            if (!_isEmpty(child)) {
              output[attribute] = child;
            }
          } else {
            if (String(value).includes(this.filter)) {
              output[attribute] = value;
            }
          }
        }
      }
    );

    return output;
  }
}
