import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { finalize, map, catchError } from 'rxjs/operators';
import { includes as _includes, isString as _isString } from 'lodash';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { Aka } from '../../models/item/aka/aka.model';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from '../../../common/services/bolt-abstract.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Localized as LocalizedItem } from '../../models/item/localized/localized.model';
import { Original as OriginalItem } from '../../models/item/original/original.model';
import { Product } from '../../models/product/product.model';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TypeEnum as ItemTypeEnum } from '../../models/type/type.enum';
import { TypeEnum as ProductTypeEnum } from '../../models/product/type/type.enum';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';


@Injectable({
  providedIn: 'root',
})
export class ItemService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns a subscription for attaching the given aka and item.
   *
   * @param itemType ItemTypeEnum
   * @param itemId number
   * @param akaId number
   * @param twoWayValue boolean
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  attachAka(
    itemType: ItemTypeEnum,
    itemId: number,
    akaId: number,
    twoWayValue: boolean,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId,
      '{akaId}': akaId
    };

    const queryParams: any = {
      enableTwoWay: twoWayValue,
    };

    const request: any = {
      url: this.generateUrl('cat.itemService.attachAka.endpoint', params, queryParams),
      body: { }
    };

    const subs: Subscription = this.doPostRequest(
      request,
      (successResponse: StormServiceResponseSingle) => {
        const item: OriginalItem = new OriginalItem(successResponse.item);
        const akaItems: Aka[] = item.akaItems;

        onSuccessDo(akaItems);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for attaching the given item and product.
   *
   * @param productType ProductTypeEnum
   * @param productId number
   * @param itemType ItemTypeEnum
   * @param itemId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  attachProduct(
    productType: ProductTypeEnum,
    productId: number,
    itemType: ItemTypeEnum,
    itemId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{productType}': productType.toLowerCase(),
      '{productId}': productId,
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId
    };

    const request: any = {
      url: this.generateUrl('cat.itemService.attachProduct.endpoint', params),
      body: { }
    };

    const subs: Subscription = this.doPostRequest(
      request,
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for creating a new item using the given data and attaching it with the given product.
   *
   * @param productType ProductTypeEnum
   * @param productId number
   * @param itemType ItemTypeEnum
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  create(
    productType: ProductTypeEnum,
    productId: number,
    itemType: ItemTypeEnum,
    data: any,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{productType}': productType.toLowerCase(),
      '{productId}': productId,
      '{itemType}': itemType.toLowerCase()
    };

    const request: any = {
      url: this.generateUrl('cat.itemService.create.endpoint', params),
      body: data
    };

    const subs: Subscription = this.doPostRequest(
      request,
      (successResponse: StormServiceResponseSingle) => {
        const item: OriginalItem = new OriginalItem(successResponse.item);
        onSuccessDo(item);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for detaching the given aka from the given item.
   *
   * @param itemType typeEnum
   * @param itemId number
   * @param akaId number
   * @returns Subscription
   */
  detachAka(
    itemType: ItemTypeEnum,
    itemId: number,
    akaId: number,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const params: any = {
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId,
      '{akaId}': akaId
    };

    const url: string = this.generateUrl('cat.itemService.detachAka.endpoint', params);

    const subs: Subscription = this.doDeleteRequest(
      { url: url, checkType: CheckTypeEnum.null },
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for detaching the given product from the given item.
   *
   * @param productType ProductTypeEnum
   * @param productId number
   * @param itemType typeEnum
   * @param itemId number
   * @returns Subscription
   */
  detachProduct(
    productType: ProductTypeEnum,
    productId: number,
    itemType: ItemTypeEnum,
    itemId: number,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const subs: Subscription = this.detachProductAsObservable(productType, productId, itemType, itemId).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    )
    .subscribe(
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      }
    );

    return subs;
  }

  /**
   * Returns an observable for detaching the given item from the given product.
   *
   * @param productType ProductTypeEnum
   * @param productId number
   * @param itemType typeEnum
   * @param itemId number
   * @returns Observable<StormServiceResponseSingle>
   */
  detachProductAsObservable(
    productType: ProductTypeEnum,
    productId: number,
    itemType: ItemTypeEnum,
    itemId: number
  ): Observable<StormServiceResponseSingle> {
    const params: any = {
      '{productType}': productType.toLowerCase(),
      '{productId}': productId,
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId
    };

    const url: string = this.generateUrl('cat.itemService.detachProduct.endpoint', params);

    const obs: Observable<StormServiceResponseSingle> = this.authHttp.delete(url, undefined).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          return this.defaultCatchOn(error);
        }
      )
    );

    return obs;
  }

  /**
   * Returns a subscription for fetching items using the given product parameters.
   *
   * @param productType ProductTypeEnum
   * @param productId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetch(
    productType: ProductTypeEnum,
    productId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{productType}': productType.toLowerCase(),
      '{productId}': productId
    };

    const url: string = this.generateUrl('cat.itemService.fetch.endpoint', params);

    return this.doFetchFor(url, OriginalItem, onSuccessDo, onErrorDo, finallyDo);
  }

  /**
   * Returns a subscription for get the Aka for an Item with the given data.
   *
   * @param itemType ItemTypeEnum
   * @param itemId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetchAka(
    itemType: ItemTypeEnum,
    itemId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId
    };

    const url: string = this.generateUrl(
      'cat.itemService.retrieve.endpoint',
      params
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        const item: OriginalItem = new OriginalItem(successResponse.item);
        const akaItems: Aka[] = item.akaItems;

        onSuccessDo(akaItems);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for fetching localized items using the given product parameters.
   *
   * @param productType ProductTypeEnum
   * @param productId number
   * @param locale string
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetchLocalization(
    productType: ProductTypeEnum,
    productId: number,
    locale: string,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{productType}': productType.toLowerCase(),
      '{productId}': productId,
      '{locale}': locale
    };

    const url: string = this.generateUrl('cat.itemService.fetchLocalization.endpoint', params);

    return this.doFetchFor(url, LocalizedItem, onSuccessDo, onErrorDo, finallyDo);
  }

  /**
   * Returns a subscription for fetch product associations using the given parameters.
   *
   * @param itemType ItemTypeEnum
   * @param itemId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetchProductAssociations(
    itemType: ItemTypeEnum,
    itemId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId
    };

    const url: string = this.generateUrl(
      'cat.itemService.fetchProductAssociations.endpoint',
      params
    );

    return this.doFetchFor(url, Product, onSuccessDo, onErrorDo, finallyDo);
  }

  /**
   * Returns a subscription for get an Item with the given data.
   *
   * @param itemType ItemTypeEnum
   * @param itemId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  retrieve(
    itemType: ItemTypeEnum,
    itemId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId
    };

    const url: string = this.generateUrl(
      'cat.itemService.retrieve.endpoint',
      params
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        const item: OriginalItem = new OriginalItem(successResponse.item);
        onSuccessDo(item);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for get a LocalizedItem Item with the given data.
   *
   * @param itemType ItemTypeEnum
   * @param itemId number
   * @param locale string
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  retrieveLocalized(
    itemType: ItemTypeEnum,
    itemId: number,
    locale: string,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId,
      '{locale}': locale
    };

    const url: string = this.generateUrl(
      'cat.itemService.retrieveLocalization.endpoint',
      params
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        const item: LocalizedItem = new LocalizedItem(successResponse.item);
        onSuccessDo(item);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for searching items using the given query
   *
   * @param query string
   * @param size number
   * @param page number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  search(
    query: string,
    size: number,
    page: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const queryParams: any = {
      q: query,
      size: size,
      page: page
    };

    const url: string = this.generateUrl('cat.itemService.search.endpoint', undefined, queryParams);

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        const response: SearchResponse = new SearchResponse(
          successResponse.item,
          (element: any) => {
            return new OriginalItem(element);
          }
        );

        onSuccessDo(response);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for searching aka items using the given query
   *
   * @param itemType ItemTypeEnum
   * @param query string
   * @param size number
   * @param page number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  searchAka(
    itemType: ItemTypeEnum,
    query: string,
    size: number,
    page: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const queryParams: any = {
      q: query,
      size: size,
      page: page,
      sort: 'name:asc',
      type: itemType
    };

    const url: string = this.generateUrl('cat.itemService.search.endpoint', undefined, queryParams);

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        const response: SearchResponse = new SearchResponse(
          successResponse.item,
          (element: any) => {
            return new Aka(element);
          }
        );

        onSuccessDo(response);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for updating the given item using the given data.
   *
   * @param itemType ItemTypeEnum
   * @param itemId number
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  update(
    itemType: ItemTypeEnum,
    itemId: number,
    data: any,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.updateAsObservable(itemType, itemId, data).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    )
    .subscribe(
      (successResponse: StormServiceResponseSingle) => {
        const item: OriginalItem = new OriginalItem(successResponse.item);
        onSuccessDo(item);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      }
    );

    return subs;
  }

  /**
   * Returns an observable for updating the given item using the given data.
   *
   * @param itemType ItemTypeEnum
   * @param itemId number
   * @param data any
   * @returns Observable<StormServiceResponseSingle>
   */
  updateAsObservable(
    itemType: ItemTypeEnum,
    itemId: number,
    data: any
  ): Observable<StormServiceResponseSingle> {
    const params: any = {
      '{itemType}': itemType.toLowerCase(),
      '{itemId}': itemId
    };

    const url: string = this.generateUrl('cat.itemService.update.endpoint', params);

    const obs: Observable<StormServiceResponseSingle> = this.authHttp.put(url, data).pipe(
      map(
        (response: any) => {
          return this.defaultMapOn(response);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          return this.defaultCatchOn(error);
        }
      )
    );

    return obs;
  }

  /**
   * Returns a subscription for updating the locking status of the items list for the given product.
   *
   * @param productType ProductTypeEnum
   * @param productId number
   * @param locked boolean
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  updateItemsListLockingStatus(
    productType: ProductTypeEnum,
    productId: number,
    locked: boolean,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.updateItemsListLockingStatusAsObservable(productType, productId, locked).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    )
    .subscribe(
      (successResponse: StormServiceResponseSingle) => {
        try {
          const rawData: any = successResponse.item;

          // We need this hack until API sends a compatible response with a product for titles.
          if (!_includes(ProductTypeEnum, rawData.productType) && _isString(rawData.titleType)) {
            rawData.productType = rawData.titleType;
          }

          onSuccessDo(new Product(rawData));
        } catch (error) {
          onErrorDo(error);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      }
    );

    return subs;
  }

  /**
   * Returns an observable for updating the locking status of the items list for the given product.
   *
   * @param productType ProductTypeEnum
   * @param productId number
   * @param locked boolean
   * @returns Observable<StormServiceResponseSingle>
   */
  updateItemsListLockingStatusAsObservable(
    productType: ProductTypeEnum,
    productId: number,
    locked: boolean
  ): Observable<StormServiceResponseSingle> {

    const params: any = {
      '{productType}': productType.toLowerCase(),
      '{productId}': productId
    };

    const url: string = this.generateUrl('cat.itemService.updateItemsListLockingStatus.endpoint', params);

    const obs: Observable<StormServiceResponseSingle> = this.authHttp.put(url, { itemsLocked: locked }).pipe(
      map(
        (response: any) => {
          return this.defaultMapOn(response);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          return this.defaultCatchOn(error);
        }
      )
    );

    return obs;
  }

  /**
   * Returns a subscription for updating the given localized item using the given data.
   *
   * @param originalType ItemTypeEnum
   * @param originalId number
   * @param locale string
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  updateLocalization(
    originalType: ItemTypeEnum,
    originalId: number,
    locale: string,
    data: any,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.updateLocalizationAsObservable(originalType, originalId, locale, data).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    )
    .subscribe(
      (successResponse: StormServiceResponseSingle) => {
        const item: LocalizedItem = new LocalizedItem(successResponse.item);
        onSuccessDo(item);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      }
    );

    return subs;
  }

  /**
   * Returns an observable for updating the given localized item using the given data.
   *
   * @param originalType ItemTypeEnum
   * @param originalId number
   * @param locale string
   * @param data any
   * @returns Observable<StormServiceResponseSingle>
   */
  updateLocalizationAsObservable(
    originalType: ItemTypeEnum,
    originalId: number,
    locale: string,
    data: any
  ): Observable<StormServiceResponseSingle> {
    const params: any = {
      '{itemType}': originalType.toLowerCase(),
      '{itemId}': originalId,
      '{locale}': locale
    };

    const url: string = this.generateUrl('cat.itemService.updateLocalization.endpoint', params);

    const obs: Observable<StormServiceResponseSingle> = this.authHttp.post(url, data).pipe(
      map(
        (response: any) => {
          return this.defaultMapOn(response);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => {
          return this.defaultCatchOn(error);
        }
      )
    );

    return obs;
  }

  /**
   * Returns a subscription for fetching data using the given URL.
   *
   * @param url string
   * @param classType typeof OriginalItem | typeof LocalizedItem | typeof Product
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  protected doFetchFor(
    url: string,
    classType: typeof OriginalItem | typeof LocalizedItem | typeof Product,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo: CallableFunction
  ): Subscription {
    const subs: Subscription = this.doGetRequest(
      { url: url, checkType: CheckTypeEnum.array },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const elements: any[] = new Array();

          successResponse.item.forEach(
            (rawData: any) => {
              const element: any = new classType(rawData);
              elements.push(element);
            }
          );

          onSuccessDo(elements);
        } catch (err) {
          onErrorDo(err);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }
}
