import { isArray as _isArray, isObject as _isObject } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Summary } from './summary/summary.model';
import { Title } from '../../title.model';


export class FindByRidsResponse {
  protected _summary: Summary;
  protected _titles: Title[];

  constructor(data: any) {
    this.initialize(data);
  }

  get summary(): Summary {
    return this._summary;
  }

  get titles(): Title[] {
    return this._titles;
  }

  /**
   * Indicates if the summary has episodics or invalids.
   *
   * @returns boolean
   */
  hasIssues(): boolean {
    const hasIt: boolean = (this.summary.hasEpisodics() || this.summary.hasInvalids());
    return hasIt;
  }

  /**
   * Indicates if it has titles.
   *
   * @returns boolean
   */
  hasTitles(): boolean {
    const hasIt: boolean = (this.titles.length > 0);
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    if (_isObject(data)) {
      this.setTitles(data);
      this.setSummary(data);
    } else {
      throw new ErrorHelper('Invalid response given for a bulk-add response.');
    }
  }

  /**
   * Set the summary.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setSummary(data: any): void {
    this._summary = new Summary(data.summary);
  }

  /**
   * Set the titles.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected setTitles(data: any): void {
    if (_isArray(data.titles)) {
      this._titles = data.titles.map(
        (rawData: any) => {
          const title: Title = new Title(rawData);

          if (title.isEpisode()) {
            throw new ErrorHelper('Episodes are not allowed in a bulk-add response.');
          }

          return title;
        }
      );
    } else {
      throw new ErrorHelper('Invalid titles given for a bulk-add response.');
    }
  }
}
