import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { kebabCase as _kebabCase } from 'lodash';
import { NotificationService } from '@bolt/ui-shared/notification';

import { TitleInterface } from '../../../title/models/title.model';
import { TitleService } from '../../../title/services/title.service';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';


@Component({
  selector: 'bolt-credits',
  template: require('./bolt-credits.html'),
  styles: [require('./bolt-credits.scss')]
})
export class BoltCreditsComponent {
  @Input('title') title: TitleInterface;

  protected boltStore: any;
  protected creditPositionTypes  = CreditPositionType;
  protected creditTypes = CreditType;
  protected selectedTab: string;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected notificationService: NotificationService,
    protected router: Router,
    protected titleService: TitleService
  ) {
    this.initialize();
  }

  /**
   * Activates the given tab.
   *
   * @param event any
   * @returns void
   */
  protected activateTab(event: any): void {
    const path: string = [
      '/titles',
      _kebabCase(this.activatedRoute.snapshot.params.productType),
      this.activatedRoute.snapshot.params.productId,
    ].join('/');

    const params: any = {
      show: this.activatedRoute.snapshot.params.show,
      details: event.nextId
    };

    this.router.navigate([path, params]);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.setupStore();
    this.setupSelectedTab();
  }

  /**
   * Set up the selected tab.
   *
   * @returns void
   */
  protected setupSelectedTab(): void {
    this.selectedTab = (this.activatedRoute.snapshot.params.details || this.boltStore.tabs.titleCast);
    this.activateTab({ nextId: this.selectedTab });
  }

  /**
   * Set up the store.
   *
   * @return void
   */
  protected setupStore(): void {
    this.boltStore = {
      tabs: {
        titleCast: 'title-cast',
        titleCrew: 'title-crew',
        dubbingCast: 'dubbing-cast',
        dubbingCrew: 'dubbing-crew'
      },
      translations: {
        talentLabel: 'Talent',
        castLabel: 'Cast',
        crewLabel: 'Crew',
        titleLabel: 'Title',
        dubbingLabel: 'Dubbing',
        titleCastTabLabel: 'Title Cast',
        titleCrewTabLabel: 'Title Crew',
        dubbingCastTabLabel: 'Dubbing Cast',
        dubbingCrewTabLabel: 'Dubbing Crew',
      }
    };
  }
}
