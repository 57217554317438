import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { NotificationService } from '@bolt/ui-shared/notification';
import { RoleTypeEnum, UserRole } from '@bolt/ui-shared/auth';
import { Privilege } from '@bolt/ui-shared/configuration';

import {
  sortBy as _sortBy, isObject as _isObject, clone as _clone, isArray as _isArray, reject as _reject
} from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { OptionTypeEnum } from './option-type.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { UpcService } from '../../services/upc.service';
import { UserManager } from 'app/modules/user/helpers/user-manager.helper';

@Component({
  selector: 'bolt-upc-edit-side-panel',
  template: require('./bolt-upc-edit-side-panel.html'),
  styles: [require('./bolt-upc-edit-side-panel.scss')]
})
export class BoltUpcEditSidePanelComponent extends StormComponent implements OnChanges {
  @Output('closed') closeEvent: EventEmitter<any>;
  @Output('saved') saveEvent: EventEmitter<any>;
  @Input() privilege: Privilege;
  @Input() show: boolean;

  protected adminRole: UserRole;
  protected form: FormGroup;
  protected option: OptionTypeEnum;
  protected roles: UserRole[];

  constructor(
    protected notificationService: NotificationService,
    protected userManager: UserManager,
    protected upcService: UpcService
  ) {
    super();
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show) && changes.show.currentValue) {
      this.createForm();
    }
  }

  /**
   * Indicates if all of the roles are activated.
   *
   * @returns boolean
   */
  protected areAllRolesActivated(): boolean {
    const theyAre: boolean = this.roles.every(
      (role: UserRole) => this.isRoleActived(role)
    );

    return theyAre;
  }

  /**
   * Creates the form with the current privilege values.
   *
   * @returns void
   */
  protected createForm(): void {
    const control: any = {
      roles: new FormControl(
        _clone(this.privilege.roles)
      )
    };

    this.form = new FormGroup(control);

    if (this.privilege.roles.length === 0) {
      this.option = OptionTypeEnum.fullAccess;
    } else {
      if (
        this.privilege.roles.length === 1 &&
        this.privilege.roles[0].name === RoleTypeEnum.ADMIN.toString()
      ) {
        this.option = OptionTypeEnum.onlyAdmin;

      } else {
        this.option = OptionTypeEnum.custom;
      }
    }
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const hasIt: boolean = this.hasDisableActions() ||
      (
        this.isCustom() &&
        this.form.get('roles').value.length === 0
      );

    return hasIt;
  }

  /**
   * Indicates if it has to disable all actions.
   *
   * @returns boolean
   */
  protected hasDisableActions(): boolean {
    return this.isUpdatingAction();
  }

  /**
   * Indicates if it has roles.
   *
   * @returns boolean
   */
  protected hasRoles(): boolean {
    const hasIt: boolean = _isArray(this.roles) && this.roles.length > 0;
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.show = false;
    this.retrieveRoles();
  }

  /**
   * Indicates if the custom option is activated.
   *
   * @returns boolean
   */
  protected isCustom(): boolean {
    return this.option === OptionTypeEnum.custom;
  }

  /**
   * Indicates if the full access option is activated.
   *
   * @returns boolean
   */
  protected isFullAccess(): boolean {
    return this.option === OptionTypeEnum.fullAccess;
  }

  /**
   * Indicates if the only admin option is activated.
   *
   * @returns boolean
   */
  protected isOnlyAdmin(): boolean {
    return this.option === OptionTypeEnum.onlyAdmin;
  }

  /**
   * Indicates if the current privilege has the given role.
   *
   * @param role RoleTypeEnum
   * @returns boolean
   */
  protected isRoleActived(role: UserRole): boolean {
    const isIt: boolean = this.form.get('roles').value.some(
      (userRole: UserRole) => userRole.name === role.name
    );

    return isIt;
  }

  /**
   * Do the needed checks when form value changes.
   *
   * @returns void
   */
  protected onRolesChanges(): void {
    if (this.isOnlyAdmin() || this.isFullAccess()) {
      this.form.get('roles').setValue(new Array());
      this.option = OptionTypeEnum.custom;
    }
  }

  /**
   * Retrieves the roles.
   *
   * @returns void
   */
  protected retrieveRoles(): void {
    this.userManager.fetchUserRoles().subscribe(
      (serviceResponseCollection: StormServiceResponseCollection) => {
        this.roles = _sortBy(serviceResponseCollection.collection, 'name');

        this.adminRole = this.roles.find(
          (role: UserRole) => role.name === RoleTypeEnum.ADMIN.toString()
        );

        this.roles = _reject(this.roles, (role: UserRole) => role.name === RoleTypeEnum.ADMIN.toString());
      }
    );
  }

  /**
   * Save the current privilege.
   *
   * @returns void
   */
  protected save(): void {
    this.changeActionToUpdating();

    this.upcService.updateUserPrivilege(
      this.privilege,
      this.form.get('roles').value.map((role: UserRole) => role.name),
      (response: Privilege) => {
        this.notificationService.handleNotice('The privilege was edited successfully.');
        this.saveEvent.emit(response);
        this.close();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to edit the privilege', error);
      },
      () => {
        this.changeActionToShowing();
      }
    );
  }

  /**
   * Set the admin role as the only one role in the form.
   *
   * @returns void
   */
  protected setCustomOption(): void {
    this.option = OptionTypeEnum.custom;
    this.form.get('roles').setValue(new Array());
  }

  /**
   * Set the admin role as the only one role in the form.
   *
   * @returns void
   */
  protected setFullAccessOption(): void {
    this.option = OptionTypeEnum.fullAccess;
    this.form.get('roles').setValue(new Array());
  }

  /**
   * Set the admin role as the only one role in the form.
   *
   * @returns void
   */
  protected setOnlyAdminOption(): void {
    this.option = OptionTypeEnum.onlyAdmin;
    this.form.get('roles').setValue([this.adminRole]);
  }

  /**
   * Toggles the activated status of all roles.
   *
   * @returns void
   */
  protected toggleAllRoles(): void {
    this.onRolesChanges();

    const roleFormControl: AbstractControl = this.form.get('roles');

    if (this.areAllRolesActivated()) {
      roleFormControl.setValue(new Array());
    } else {
      roleFormControl.setValue(_clone(this.roles));
    }
  }

  /**
   * Toggles the given role status.
   *
   * @param role UserRole
   * @returns void
   */
  protected toggleRoleStatus(role: UserRole): void {
    if (!this.hasDisableActions()) {
      this.onRolesChanges();

      const roleFormControl: AbstractControl = this.form.get('roles');

      const index: number = roleFormControl.value.findIndex(
        (userRole: UserRole) => userRole.name === role.name
      );

      if (index >= 0) {
        roleFormControl.value.splice(index, 1);
      } else {
        roleFormControl.value.push(role);
      }
    }
  }

  /**
   * Closes the edition side panel.
   *
   * @returns void
   */
  protected close(): void {
    this.show = false;
    this.closeEvent.emit();
  }
}
