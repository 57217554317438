import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './helpers/auth-guard/auth-guard.helper';
import { BoltAuthAccessDeniedComponent } from './components/bolt-auth-access-denied/bolt-auth-access-denied.component';
import { BoltAuthComponent } from './components/bolt-auth/bolt-auth.component';
import { BoltAuthLoginComponent } from './components/bolt-auth-login/bolt-auth-login.component';
import { BoltAuthLogoutComponent } from './components/bolt-auth-logout/bolt-auth-logout.component';
import { BoltAuthRestrictedAccessComponent } from './components/bolt-auth-restricted-access/bolt-auth-restricted-access.component';
import { BoltAuthValidateComponent } from './components/bolt-auth-validate/bolt-auth-validate.component';


const authRoutes: Routes = [
  {
    path: 'auth',
    component: BoltAuthComponent,
    children: [
      {
        path: '',
        component: BoltAuthLoginComponent
      },
      {
        path: 'access-denied',
        component: BoltAuthAccessDeniedComponent
      },
      {
        path: 'login',
        component: BoltAuthLoginComponent
      },
      {
        path: 'logout',
        component: BoltAuthLogoutComponent
      },
      {
        path: 'restricted-access',
        component: BoltAuthRestrictedAccessComponent,
        canActivate: [AuthGuard],
        data: {
          boltAuthAware: '*'
        }
      },
      {
        path: 'validate',
        component: BoltAuthValidateComponent
      }
    ]
  },
];

export const authModuleRouting: ModuleWithProviders = RouterModule.forChild(authRoutes);
