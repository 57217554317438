import { includes as _includes } from 'lodash';
import { ModeEnum } from './mode.enum';


export class Mode {
  protected _type: ModeEnum;

  constructor(type: ModeEnum) {
    this.initialize(type);
  }

  /**
   * Indicates if its type is LANGUAGE.
   *
   * @returns boolean
   */
  isLanguage(): boolean {
    const isIt: boolean = (this._type === ModeEnum.LANGUAGE);
    return isIt;
  }

  /**
   * Indicates if its type is TERRITORY.
   *
   * @returns boolean
   */
  isTerritory(): boolean {
    const isIt: boolean = (this._type === ModeEnum.TERRITORY);
    return isIt;
  }

  /**
   * Initializes the instance.
   *
   * @param type ConflictEnum
   * @returns void
   */
  protected initialize(type: ModeEnum): void {
    this._type = (
      _includes(ModeEnum, <any>type)
        ? type
        : undefined
    );
  }
}
