import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, OnDestroy } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Language } from '@bolt/ui-shared/master-data';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { isObject as _isObject } from 'lodash';
import { Subscription } from 'rxjs';

import { CreditPositionType } from '../../models/credit/credit-position.enum';
import { StepEnum } from '../../models/wizard/step/steps.enum';
import { WizardService } from '../../services/wizard/wizard.service';
import { CreditType } from '../../models/credit/credit-type.enum';
import { TitleType } from 'app/modules/title/models/title.model';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';


@Component({
  selector: 'bolt-credits-wizard',
  template: require('./bolt-credits-wizard.html'),
  styles: [require('./bolt-credits-wizard.scss')],
  providers: [WizardService]
})
export class BoltCreditsWizardComponent implements OnChanges, OnDestroy  {
  @Input() titleType: TitleType;
  @Input() titleId: number;
  @Input() creditType: CreditType;
  @Input() startOrderFrom: number;
  @Input() language: Language;
  @Input() locale: string;
  @Input() positionType: CreditPositionType;
  @Input() show: boolean;
  @Output() bulkAddCompleted: EventEmitter<undefined>;
  @Output() closed: EventEmitter<boolean>;
  @ViewChild('confirmCancelModal') cancelModal: ModalDirective;

  protected stepValues: any = StepEnum;
  protected currentConfirmModal: NgbModalRef;
  private bulkAddObservableSubscription: Subscription;

  constructor(
    protected wizardService: WizardService,
    private layoutHandler: LayoutHandlerService,
    private modalService: NgbModal
  ) {
    this.bulkAddCompleted = new EventEmitter();
    this.closed = new EventEmitter();
    this.show = false;

    this.bulkAddObservableSubscription = this.wizardService.getBulkAddObservableExecutor().getExecutorListener().subscribe(
      () => {
        if (this.wizardService.isBulkAddComplete()) {
          setTimeout(() => {
            this.layoutHandler.changeToReading();
            this.bulkAddCompleted.emit();
          });
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasPositionTypeChange(changes)) {
      this.wizardService.setCreditPositionType(changes.positionType.currentValue);
    }

    if (this.hasCreditTypeChange(changes)) {
      this.wizardService.setCreditType(changes.creditType.currentValue);
    }

    if (this.hasLocaleChange(changes)) {
      this.wizardService.setLocale(changes.locale.currentValue);
    }

    if (this.hasStartOrderFromChange(changes)) {
      this.wizardService.setOrder(changes.startOrderFrom.currentValue);
    }

    if (this.hasTitleIdChange(changes) || this.hasTitleTypeChange(changes)) {
      this.wizardService.setTitle(changes.titleId.currentValue, changes.titleType.currentValue);
    }
  }

  ngOnDestroy() {
    if (_isObject(this.bulkAddObservableSubscription)) {
      this.bulkAddObservableSubscription.unsubscribe();
    }
  }

  /**
   * Indicates if it can continue to the next step.
   *
   * @returns boolean
   */
  protected canContinue(): boolean {
    return this.wizardService.canContinue() && !this.hasDisableActions();
  }

  /**
   * Closes the wizard.
   *
   * @returns void
   */
  protected closeWizard(): void {
    if (_isObject(this.currentConfirmModal)) {
      this.currentConfirmModal.close();
    }

    this.show = false;
    this.closed.emit(false);
    this.wizardService.reset();
  }

  /**
   * Asks for confirmation and then close the wizard.
   *
   * @param value boolean
   * @returns void
   */
  protected confirmAndCloseWizard(): void {
    if (this.wizardService.getAddingForm().dirty && !this.hasDisplaySummary()) {
      this.currentConfirmModal = this.modalService.open(this.cancelModal);
    } else {
      this.closeWizard();
    }
  }

  /**
   * Indicates if it has to disable the actions.
   *
   * @returns boolean
   */
  protected hasDisableActions(): boolean {
    return this.layoutHandler.isSaving();
  }

  /**
   * Indicates if it has to display the back button.
   *
   * @returns boolean
   */
  protected hasDisplayBackButton(): boolean {
    return this.wizardService.canBack();
  }

  /**
   * Indicates if it has to display the continue button.
   *
   * @returns boolean
   */
  protected hasDisplayContinueButton(): boolean {
    const itHas: boolean = this.wizardService.getCurrentStep().isAdding() ||
      (
        this.wizardService.getCurrentStep().isValidating() &&
        !this.wizardService.canCompleteFromValidationStep()
      );

    return itHas;
  }

  /**
   * Indicates if it has to display the complete button.
   *
   * @returns boolean
   */
  protected hasDisplayCompleteButton(): boolean {
    return this.wizardService.canCompleteFromValidationStep();
  }

  /**
   * Indicates if it has to display the creating step.
   *
   * @returns boolean
   */
  protected hasDisplayCreatingStep(): boolean {
    const itHas: boolean = this.wizardService.getCurrentStep().isCreating() && this.layoutHandler.isCreating();
    return itHas;
  }

  /**
   * Indicates if it has to display the retry button.
   *
   * @returns boolean
   */
   protected hasDisplayRetryButton(): boolean {
    return this.wizardService.canRetry();
  }

  /**
   * Indicates if it has to display the summary.
   *
   * @returns boolean
   */
   protected hasDisplaySummary(): boolean {
    const itHas: boolean =
      this.wizardService.getCurrentStep().isCreating() &&
      (this.layoutHandler.isSaving() || this.layoutHandler.isReading());

    return itHas;
  }

  /**
   * Indicates if it has to display the validation step.
   *
   * @returns boolean
   */
  protected hasDisplayValidatingStep(): boolean {
    const itHas: boolean =
      this.wizardService.getCurrentStep().isValidating() ||
      (
        this.layoutHandler.isCreating() &&
        this.wizardService.getCurrentStep().isCreating()
      );

    return itHas;
  }

  /**
   * Continues to the next step.
   *
   * @returns void
   */
  protected nextStep(): void {
    this.wizardService.nextStep();
  }

  /**
   * Backs to the previous step.
   *
   * @returns void
   */
  protected previousStep(): void {
    this.wizardService.previousStep();
  }

  /**
   * Starts the retry process.
   *
   * @returns void
   */
  protected retry(): void {
    this.wizardService.retry();
  }

  /**
   * Starts the bulk add process.
   *
   * @returns void
   */
  protected startBulkAdd(): void {
    this.wizardService.startBulkAdd();
  }

  /**
   * Indicates if the given changes includes a credit type change.
   *
   * @param changes SimpleChanges
   * @returns boolean
   */
  private hasCreditTypeChange(changes: SimpleChanges): boolean {
    const itHas: boolean = _isObject(changes.creditType) &&
      changes.creditType.currentValue !== changes.creditType.previousValue;

    return itHas;
  }

  /**
   * Indicates if the given changes includes a locale change.
   *
   * @param changes SimpleChanges
   * @returns boolean
   */
  private hasLocaleChange(changes: SimpleChanges): boolean {
    const itHas: boolean = _isObject(changes.locale) &&
      changes.locale.currentValue !== changes.locale.previousValue;

    return itHas;
  }

  /**
   * Indicates if the given changes includes a position type change.
   *
   * @param changes SimpleChanges
   * @returns boolean
   */
  private hasPositionTypeChange(changes: SimpleChanges): boolean {
    const itHas: boolean = _isObject(changes.positionType) &&
      changes.positionType.currentValue !== changes.positionType.previousValue;

    return itHas;
  }

  /**
   * Indicates if the given changes includes a startOrderFrom change.
   *
   * @param changes SimpleChanges
   * @returns boolean
   */
  private hasStartOrderFromChange(changes: SimpleChanges): boolean {
    const itHas: boolean = _isObject(changes.startOrderFrom) &&
      changes.startOrderFrom.currentValue !== changes.startOrderFrom.previousValue;

    return itHas;
  }

  /**
   * Indicates if the given changes includes a title id change.
   *
   * @param changes SimpleChanges
   * @returns boolean
   */
  private hasTitleIdChange(changes: SimpleChanges): boolean {
    const itHas: boolean = _isObject(changes.titleId) &&
      changes.titleId.currentValue !== changes.titleId.previousValue;

    return itHas;
  }

  /**
   * Indicates if the given changes includes a title type change.
   *
   * @param changes SimpleChanges
   * @returns boolean
   */
  private hasTitleTypeChange(changes: SimpleChanges): boolean {
    const itHas: boolean = _isObject(changes.titleType) &&
      changes.titleType.currentValue !== changes.titleType.previousValue;

    return itHas;
  }
}
