import { isEmpty as _isEmpty } from 'lodash';


export interface StormServiceResponseSingleInterface {
  item: any;
}

export class StormServiceResponseSingle implements StormServiceResponseSingleInterface {
  constructor(
    public item: any = new Object()
  ) { }

  /**
   * Indicates if the payload is empty
   *
   * @returns boolean
   */
  isEmpty(): boolean {
    return _isEmpty(this.item);
  }
}
