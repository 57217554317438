import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from '../common/common.module';

import { BoltSearchComponent } from './components/bolt-search/bolt-search.component';
import { searchModuleRouting } from './search.routing';
import { SearchService } from './services/search.service';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    searchModuleRouting,
    SharedModule
  ],
  declarations: [
    BoltSearchComponent
  ],
  exports: [
    BoltSearchComponent
  ],
  providers: [
    SearchService
  ]
})
export class SearchModule { }
