import { Episode } from '../../models/episode.model';
import { EpisodeMetadata } from '../../models/episode-metadata.model';
import { Feature } from '../../models/feature.model';
import { FeatureMetadata } from '../../models/feature-metadata.model';
import { Season } from '../../models/season.model';
import { SeasonMetadata } from '../../models/season-metadata.model';
import { Series } from '../../models/series.model';
import { SeriesMetadata } from '../../models/series-metadata.model';
import { TitleType, Title } from '../../models/title.model';
import { TitleMetadataInterface, TitleMetadata } from '../../models/title-metadata.model';


export function TitleFactory(
  titleType: string,
  attributes?: { [attr: string]: any }
): Title {

  // TitleType enums are in lowercase
  titleType = titleType.toLowerCase();

  let titleEntity: Title;

  switch (true) {
    case titleType === TitleType[TitleType.feature]:
      titleEntity = new Feature(attributes);
      break;
    case titleType === TitleType[TitleType.series]:
      titleEntity = new Series(attributes);
      break;
    case titleType === TitleType[TitleType.season]:
      titleEntity = new Season(attributes);
      break;
    case titleType === TitleType[TitleType.episode]:
      titleEntity = new Episode(attributes);
      break;
    default:
      titleEntity = new Title(attributes);
  }

  return titleEntity;

}

export function TitleMetadataFactory(
  titleType: string,
  attributes?: { [attr: string]: any }
): TitleMetadataInterface {

  // TitleType enums are in lowercase
  titleType = titleType.toLowerCase();

  let titleEntity: TitleMetadataInterface;

  switch (true) {
    case titleType === TitleType[TitleType.feature]:
      titleEntity = new FeatureMetadata(attributes);
      break;
    case titleType === TitleType[TitleType.series]:
      titleEntity = new SeriesMetadata(attributes);
      break;
    case titleType === TitleType[TitleType.season]:
      titleEntity = new SeasonMetadata(attributes);
      break;
    case titleType === TitleType[TitleType.episode]:
      titleEntity = new EpisodeMetadata(attributes);
      break;
    default:
      titleEntity = new TitleMetadata(attributes);
  }

  return titleEntity;
}
