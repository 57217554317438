import { Component, OnInit } from '@angular/core';

import { BooleanField } from '../../models/import/comparison/titles/title/field/boolean-field/boolean-field.model';
import { ManagerService } from '../../services/import/manager-service/manager.service';
import { profileConfig } from '../../config/profile.config';
import { TitlesEnum } from '../../models/import/comparison/titles/titles.enum';


@Component({
  selector: 'bolt-profile-import-seasons',
  template: require('./bolt-profile-import-seasons.html'),
  styles: [require('./bolt-profile-import-seasons.scss')]
})
export class BoltProfileImportSeasonComponent implements OnInit {
  protected labels: any[];
  protected titlesEnum: typeof TitlesEnum = TitlesEnum;

  protected boltProfileImportSeasonStore: any = {
    translations: {
      lblCollapseSeasonsEpisodes: `Disney's collapse seasons episodes label`,
      lblExpandSeasonsEpisodes: `Disney's expand seasons episodes label`,
    }
  };


  constructor(protected managerService: ManagerService) {
    this.initialize();
  }

  ngOnInit() { }

  /**
   * Populate translations with default language until i18n will be implemented
   *
   * @returns void
   */
  protected populateTranslations(): void {
    this.boltProfileImportSeasonStore.translations.lblCollapseSeasonsEpisodes = `Collapse All`;
    this.boltProfileImportSeasonStore.translations.lblExpandSeasonsEpisodes = `Expand All`;
  }

  /**
   * Initializes the Season Component.
   *
   * @returns void
   */
  protected initialize(): void {
    this.labels = profileConfig.seasonsLabels.common;
    this.populateTranslations();
    this.managerService.repopulateSeasonsData();
  }

  /**
   * Toggles the given boolean field.
   *
   * @param field BooleanField
   * @returns void
   */
  protected toggleLockedField(field: BooleanField): void {
    field.toggle();
    this.managerService.forceConfirmResolution(field);
  }
}
