import * as moment from 'moment';

import { Field } from '../field.model';


export class DateField extends Field {
  constructor(data: any) {
    super(data);
  }

  isDate(): boolean {
    return true;
  }

  protected parseValue(value: any): moment.Moment {
    let parsedValue: moment.Moment;

    if (this.isValueDefined(value)) {
      parsedValue = moment(value);
    } else {
      parsedValue = undefined;
    }

    return parsedValue;
  }
}
