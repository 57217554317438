import { Component } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';

import { Character } from '../../models/character.model';
import { CharacterMetadata } from '../../models/character-metadata.model';
import { CharacterMetadataManager } from '../../helpers/character-metadata-manager/character-metadata-manager';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-character-localized-data',
  template: require('./bolt-character-localized-data.html'),
  styles: [require('./bolt-character-localized-data.scss')]
})
export class BoltCharacterLocalizedDataComponent {
  protected filters: any;
  protected selectedCharacterMetadata: CharacterMetadata;
  protected showModal: boolean = false;

  constructor(
    protected listLayoutProvider: ListLayoutProvider,
    protected notificationService: NotificationService,
    protected metadataManager: CharacterMetadataManager,
  ) { }

  get character(): Character {
    return this.metadataManager.character;
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected closeModal(): void {
    this.showModal = false;
  }

  /**
   * Indicates if it has to open the modal.
   *
   * @returns boolean
   */
  protected hasDisplayModal(): boolean {
    return this.showModal;
  }

  /**
   * Refresh the selected character.
   *
   * @param localizedCharacter CharacterMetadata
   * @returns void
   */
  protected setSavedMetadata(localizedCharacter: CharacterMetadata): void {
    localizedCharacter.language = this.listLayoutProvider.getLanguageById(<number>localizedCharacter.language);

    localizedCharacter.originalLanguageId = this.listLayoutProvider.getLanguageById(
        <number>localizedCharacter.originalLanguageId
    );

    this.refreshCharacter();
    this.setSelectedMetadata(localizedCharacter);
  }

  /**
   * Sets the selected character metadata
   *
   * @param characterMetadata CharacterMetadata
   * @returns void
   */
  protected setSelectedMetadata(characterMetadata: CharacterMetadata): void {
    if (characterMetadata) {
      this.selectedCharacterMetadata = new CharacterMetadata(characterMetadata);
    }
  }

  /**
   * Forces to refresh the character current data.
   *
   * @returns void
   */
  protected refreshCharacter(): void {
    this.metadataManager.retrieveCharacter(
      this.character.id,
      () => { },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'Failed trying to update the localizations',
          error,
          notificationsContainer.character.details.key
        );
      }
    );
  }

  /**
   * Makes the correct process when the user deletes a localization.
   *
   * @returns void
   */
  protected onLocalizationRemove(): void {
    this.selectedCharacterMetadata = undefined;
    this.refreshCharacter();
  }

  /**
   * Opens the modal for create a new localization.
   *
   * @returns void
   */
  protected openCreateModal(): void {
    this.selectedCharacterMetadata = undefined;
    this.showModal = true;
  }

  /**
   * Opens the modal for edit the current localization.
   *
   * @returns void
   */
  protected openEditModal(): void {
    this.showModal = true;
  }

  /**
   * Updates the current filters with the given data.
   *
   * @param filters any
   * @returns void
   */
  protected updateFilters(filters: any): void {
    this.filters = filters;
  }
}
