import { FormControl, Validators, ValidatorFn } from '@angular/forms';
import { CustomErrorTypeEnum } from '@bolt/ui-shared/form';

import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';

export abstract class SubtitleForm extends BoltForm {
  protected idField: FormControl;
  protected narrativeTitleField: FormControl;
  protected subtitleTypeField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  /**
   * Get the Narrative Title field.
   *
   * @returns FormControl
   */
  getNarrativeTitleField(): FormControl {
    return this.narrativeTitleField;
  }

  /**
   * Get the subtitle type field.
   *
   * @returns FormControl
   */
   getSubtitleTypeField(): FormControl {
    return this.subtitleTypeField;
  }

  toJson(): any {
    // TODO Can't use getters due to FormArray transform the current controls and we lost custom getters
    // need implementation of BoltArrayForm.
    const data: any = {
      id:  this.get('id').value,
      narrativeTitle: this.get('narrativeTitle').value,
      subtitleType: this.get('subtitleType').value
    };

    return data;
  }

  /**
   * Get the id field.
   *
   * @returns FormControl
   */
  getIdField(): FormControl {
    return this.idField;
  }

  /**
   * Get the maxlegth line validator function.
   *
   * @returns ValidatorFn
   */
   protected getNarrativeTitleCustomMaxLengthLineValidator(configuration: number): ValidatorFn {
    const validator: any = (control: FormControl) => {
      const mappedValue: string[] = control.value ? control.value.split('\n') : [];

      if (mappedValue.some((elem: string) => elem.length > configuration)) {
        return  {
          [CustomErrorTypeEnum.maxLengthLine]: true
        };
      }
    };

    return validator;
  }

  /**
   * Get the custom required validator function for the narrative title attribute.
   *
   * @returns ValidatorFn
   */
  protected abstract getNarrativeTitleCustomRequiredValidator(): ValidatorFn;

  protected setupFields(): void {
    this.setupIdField();
    this.setupSubtitleTypeField();
    this.setupNarrativeTitleField();
  }

  /**
   * Set up the id field.
   *
   * @returns void
   */
  protected setupIdField(): void {
    const defaultValue: boolean = this.getDefaultValue('id');
    this.idField = new FormControl(defaultValue);
    this.addControl('id', this.idField);
  }

  /**
   * Set up the Narrative Title field.
   *
   * @returns void
   */
   protected setupNarrativeTitleField(): void {
    this.narrativeTitleField = new FormControl(
      this.getDefaultValue('narrativeTitle'),
      [
        this.getNarrativeTitleCustomRequiredValidator(),
        this.getNarrativeTitleCustomMaxLengthLineValidator(this.fieldSpecs.narrativeTitle.maxLengthLine),
        Validators.maxLength(this.fieldSpecs.narrativeTitle.maxLength)
      ]
    );

    this.addControl('narrativeTitle', this.narrativeTitleField);
  }

  /**
   * Set up the subtitle type field.
   *
   * @returns void
   */
   protected setupSubtitleTypeField(): void {
    const defaultValue: boolean = this.getDefaultValue('subtitleType');
    const isRequired: boolean = this.fieldSpecs.subtitleType ? this.fieldSpecs.subtitleType.required : false;
    this.subtitleTypeField = new FormControl(defaultValue, isRequired ? Validators.required : undefined);
    this.addControl('subtitleType', this.subtitleTypeField);
  }
}
