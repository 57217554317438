import { includes as _includes, startCase as _startCase } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StatusEnum } from './status.enum';


export class Status {
  protected name: string;
  protected value: StatusEnum;

  constructor(value: StatusEnum) {
    this.setValue(value);
  }

  /**
   * Returns a new instance for "Authenticated".
   *
   * @returns Status
   */
  static newAuthenticated(): Status {
    return new Status(StatusEnum.Authenticated);
  }

  /**
   * Returns a new instance for "Authenticating".
   *
   * @returns Status
   */
  static newAuthenticating(): Status {
    return new Status(StatusEnum.Authenticating);
  }

  /**
   * Returns a new instance for "NotAuthenticated".
   *
   * @returns Status
   */
  static newNotAuthenticated(): Status {
    return new Status(StatusEnum.NotAuthenticated);
  }

  /**
   * Returns the name.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Returns the value.
   *
   * @returns StatusEnum
   */
  getValue(): StatusEnum {
    return this.value;
  }

  /**
   * Indicates if it is an "Authenticated".
   *
   * @returns boolean
   */
  isAuthenticated(): boolean {
    return this.is(StatusEnum.Authenticated);
  }

  /**
   * Indicates if it is an "Authenticating".
   *
   * @returns boolean
   */
  isAuthenticating(): boolean {
    return this.is(StatusEnum.Authenticating);
  }

  /**
   * Indicates if it is an "NotAuthenticated".
   *
   * @returns boolean
   */
  isNotAuthenticated(): boolean {
    return this.is(StatusEnum.NotAuthenticated);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
   toString(): string {
    return this.name;
  }

  /**
   * Discovers the name using the stored value.
   *
   * @returns void
   */
  protected discoverName(): void {
    const targetKey: string = Object.keys(StatusEnum).find(
      (currentKey: string) => StatusEnum[currentKey] === this.getValue()
    );

    this.name = _startCase(targetKey);
  }

  /**
   * Indicates if is the same value.
   *
   * @param value StatusEnum
   * @returns boolean
   */
  protected is(value: StatusEnum): boolean {
    return this.value === value;
  }

  /**
   * Sets the value.
   *
   * @param value StatusEnum
   * @throws ErrorHelper
   * @returns void
   */
  protected setValue(value: StatusEnum): void {
    if (_includes(StatusEnum, value)) {
      this.value = <StatusEnum>value;
      this.discoverName();
    } else {
      throw new ErrorHelper('Invalid value given for an Auth Status.');
    }
  }
}
