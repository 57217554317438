import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CharacterServiceFetchCharacterQueryParams } from '../../services/character.service';


@Component({
  selector: 'bolt-character-list-filters',
  template: require('./bolt-character-list-filters.html')
})
export class BoltCharacterListFiltersComponent implements OnInit {
  @Output('BoltCharacterListFiltersFiltersUpdated') filtersUpdated: EventEmitter<AbstractControl> = new EventEmitter();
  @Output('onSaveOriginalMetadata') saveEvent: EventEmitter<any> = new EventEmitter<any>();

  public form: FormGroup;
  public nameValue: string = '';

  protected showModal: boolean = false;


  constructor(
    protected activatedRoute: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected notificationService: NotificationService
  ) {
    this.form = formBuilder.group({ q: [''] });
  }

  ngOnInit() {
    this.filtersUpdated.emit(this.form);

    this.activatedRoute.params.subscribe(
      params => {
        this.nameValue = params[CharacterServiceFetchCharacterQueryParams.q.toString()] || '';
        (<FormControl>this.form.get('q')).setValue(decodeURI(this.nameValue), { emitEvent: false });
      }
    );
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected closeModal(): void {
    this.showModal = false;
  }

  /**
   * Indicates if it has to open the modal.
   *
   * @returns boolean
   */
  protected hasDisplayModal(): boolean {
    return this.showModal;
  }

  /**
   * Opens the modal.
   *
   * @returns void
   */
  protected openModal(): void {
    this.showModal = true;
  }

  /**
   * Makes the save event.
   *
   * @returns void
   */
  protected saveOriginalMetadata(): void {
    this.saveEvent.emit();
  }
}
