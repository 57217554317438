import { Component, Input, OnChanges, SimpleChanges, OnDestroy, Output, EventEmitter } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { Account, ProductType, StormListType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isArray as _isArray, isObject as _isObject } from 'lodash';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { Credit } from 'app/modules/credits/models/credit/credit.model';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditsService } from 'app/modules/credits/services/credits.service';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';
import { Episode } from 'app/modules/title/models/episode.model';
import { ExportConfig } from '../../models/credits/export-config/export-config.model';
import { ExportValueEnum } from '../../models/credits/export-value.enum';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { Title, TitleType } from 'app/modules/title/models/title.model';
import { TitleMetadataLockingStatus } from 'app/modules/title/models/title-metadata.model';
import { TitleService } from 'app/modules/title/services/title.service';


@Component({
  selector: 'bolt-metadata-export-single-record-credits',
  template: require('./bolt-metadata-export-single-record-credits.html'),
  styles: [require('./bolt-metadata-export-single-record-credits.scss')]
})
export class BoltMetadataExportSingleRecordCreditsComponent implements OnChanges, OnDestroy {
  @Input() locale: Locale;
  @Input() title: Title;

  @Output('changed') changeEvent: EventEmitter<ExportValueEnum>;

  readonly creditPosition: typeof CreditPositionType = CreditPositionType;

  creditsMetadata: Map<string, Credit[]>;
  exportOptions: ExportConfig[];
  isLoadingCredits: boolean;
  isLoadingLocks: boolean;

  locksMetadata: Map<ExportValueEnum, Array<[string, string, boolean]>>;

  selectedExportValue: ExportValueEnum;

  protected creditsListener: Subscription;
  protected locksListener: Subscription;

  constructor(
    protected creditsService: CreditsService,
    protected entityMapperHelper: EntityMapperHelper,
    protected layoutHandler: LayoutHandlerService,
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) {
    this.changeEvent = new EventEmitter();
    this.creditsMetadata = new Map();
    this.exportOptions = ExportConfig.getList();
    this.isLoadingCredits = false;
    this.isLoadingLocks = false;
    this.locksMetadata = new Map();

    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.title) || _isObject(changes.locale)) {
      this.reset();
      this.loadCreditsData();
      this.loadLocksData();
    }
  }

  ngOnDestroy() {
    this.cancelCreditsLister();
    this.cancelLocksListener();
  }

  /**
   * Returns the data key for the given Credit type and position.
   *
   * @param type CreditType
   * @param position CreditPositionType
   * @returns string
   */
  getCreditDataKey(type: CreditType, position: CreditPositionType): string {
    return `${type}_${position}`;
  }

  /**
   * Indicates if it has to block the choices buttons.
   *
   * @returns boolean
   */
  hasBlockChoices(): boolean {
    const hasIt: boolean = !this.layoutHandler.isReading() || !this.hasLocaleAndTitle();
    return hasIt;
  }

  /**
   * Indicates if it has a valid locale and a title.
   *
   * @returns boolean
   */
  hasLocaleAndTitle(): boolean {
    const hasIt: boolean = _isObject(this.title) && _isObject(this.locale) && this.locale.isComplete();
    return hasIt;
  }

  /**
   * Notifies the stored selection.
   *
   * @returns void
   */
  notifySelection(): void {
    this.changeEvent.next(this.selectedExportValue);
  }

  /**
   * Cancels the Credits listener.
   *
   * @returns void
   */
  protected cancelCreditsLister(): void {
    if (_isObject(this.creditsListener)) {
      this.creditsListener.unsubscribe();
    }
  }


  /**
   * Cancels the Locks listener.
   *
   * @returns void
   */
   protected cancelLocksListener(): void {
    if (_isObject(this.locksListener)) {
      this.locksListener.unsubscribe();
    }
  }

  /**
   * Loads the Credits data.
   *
   * @returns void
   */
  protected loadCreditsData(): void {
    this.cancelCreditsLister();
    this.creditsMetadata.clear();

    if (this.hasLocaleAndTitle()) {
      this.isLoadingCredits = true;

      const locale: string = `${this.locale.language}_${this.locale.territory}_${ProductType.ALL_VALUE}_${Account.ALL_VALUE}`;

      let titleId: number;
      let titleType: TitleType;

      // When exporting Cast & Crew metadata for an episode, that metadata will be fetched from the season which the episode belongs to.
      if (this.title.isEpisode()) {
        titleId = (<Episode>this.title).seasonId;
        titleType = TitleType.season;
      } else {
        titleId = this.title.id;
        titleType = this.title.type;
      }

      const requests: Array<Observable<Credit[]>> = [
        this.creditsService.fetch(titleType, titleId, CreditType.ORIGINAL, CreditPositionType.CAST, locale).pipe(
          flatMap((credits: Credit[]) => this.mapTalents(credits))
        ),
        this.creditsService.fetch(titleType, titleId, CreditType.ORIGINAL, CreditPositionType.CREW, locale).pipe(
          flatMap((credits: Credit[]) => this.mapTalents(credits))
        ),
        this.creditsService.fetch(titleType, titleId, CreditType.TRANSLATED, CreditPositionType.CAST, locale).pipe(
          flatMap((credits: Credit[]) => this.mapTalents(credits))
        ),
        this.creditsService.fetch(titleType, titleId, CreditType.TRANSLATED, CreditPositionType.CREW, locale).pipe(
          flatMap((credits: Credit[]) => this.mapTalents(credits))
        ),
        this.creditsService.fetch(titleType, titleId, CreditType.DUBBING, CreditPositionType.CAST, locale).pipe(
          flatMap((credits: Credit[]) => this.mapTalents(credits))
        ),
        this.creditsService.fetch(titleType, titleId, CreditType.DUBBING, CreditPositionType.CREW, locale).pipe(
          flatMap((credits: Credit[]) => this.mapTalents(credits))
        )
      ];

      this.creditsListener = forkJoin(requests).subscribe(
        (data: any) => {
          this.creditsMetadata.set(this.getCreditDataKey(CreditType.ORIGINAL, CreditPositionType.CAST), data[0]);
          this.creditsMetadata.set(this.getCreditDataKey(CreditType.ORIGINAL, CreditPositionType.CREW), data[1]);
          this.creditsMetadata.set(this.getCreditDataKey(CreditType.TRANSLATED, CreditPositionType.CAST), data[2]);
          this.creditsMetadata.set(this.getCreditDataKey(CreditType.TRANSLATED, CreditPositionType.CREW), data[3]);
          this.creditsMetadata.set(this.getCreditDataKey(CreditType.DUBBING, CreditPositionType.CAST), data[4]);
          this.creditsMetadata.set(this.getCreditDataKey(CreditType.DUBBING, CreditPositionType.CREW), data[5]);

          this.isLoadingCredits = false;
        },
        (error: HttpError) => {
          this.notificationService.handleError('Failed retrieving Cast & Crew metadata.', error);
          this.isLoadingCredits = false;
        }
      );
    }
  }

  /**
   * Loads the Locks data.
   *
   * @returns void
   */
  protected loadLocksData(): void {
    this.cancelLocksListener();
    this.locksMetadata.clear();

    if (this.hasLocaleAndTitle()) {
      this.isLoadingLocks = true;

      this.locksListener = this.titleService.fetchTitleLockingStatusByLocale(
        this.title.type,
        this.title.id,
        this.locale.getLocale()
      ).subscribe(
        (lockingStatus: TitleMetadataLockingStatus) => {
          const dubbing: Array<[string, string, boolean]> = [
            ['Dubbing Cast', 'bullhorn', lockingStatus.dubbingCast],
            ['Dubbing Crew', 'bullhorn', lockingStatus.dubbingCrew]
          ];

          const original: Array<[string, string, boolean]> = [
            ['Original Cast', 'male', lockingStatus.originalCast],
            ['Original Crew', 'male', lockingStatus.originalCrew]
          ];

          const translated: Array<[string, string, boolean]> = [
            ['Translated Cast', 'comments-o', lockingStatus.translatedCast],
            ['Translated Crew', 'comments-o', lockingStatus.translatedCrew]
          ];

          this.locksMetadata.set(ExportValueEnum.ORIGINAL, original);
          this.locksMetadata.set(ExportValueEnum.TRANSLATED, translated);
          this.locksMetadata.set(ExportValueEnum.DUBBING, dubbing);
          this.locksMetadata.set(ExportValueEnum.ORIGINAL_DUBBING, [...original, ...dubbing]);
          this.locksMetadata.set(ExportValueEnum.TRANSLATED_DUBBING, [...translated, ...dubbing]);

          this.isLoadingLocks = false;
        },
        (error: HttpError) => {
          this.notificationService.handleError('Failed loading locks metadata.', error);
          this.isLoadingLocks = false;
        }
      );
    }
  }

  /**
   * Returns a map with the talents of the given credits.
   *
   * @param credits Credit[]
   * @returns Observable<Credit[]>
   */
   protected mapTalents(credits: Credit[]): Observable<Credit[]> {
    const map: Observable<Credit[]> = this.entityMapperHelper.map(
      credits,
      [
        {
          property: 'talent.prefixId',
          mapTo: StormListType.affix,
        },
        {
          property: 'talent.suffixId',
          mapTo: StormListType.affix,
        },
      ]
    );

    return map;
  }

  /**
   * Reset it.
   *
   * @returns void
   */
  protected reset(): void {
    this.selectedExportValue = ExportValueEnum.NONE;

    if (_isArray(this.exportOptions)) {
      this.exportOptions.forEach(
        (option: ExportConfig) => {
          option.resetIsExpanded();
        }
      );
    }
  }
}
