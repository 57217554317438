import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { CrudActionEnum } from './crud-action.enum';


@Injectable()
export class LayoutHandlerService {
  protected _action: CrudActionEnum;
  protected _actionListener: Observable<CrudActionEnum>;
  protected _actionNotifier: BehaviorSubject<CrudActionEnum>;

  constructor() {
    this.initialize();
  }

  get action(): CrudActionEnum {
    return this._action;
  }

  get actionListener(): Observable<CrudActionEnum> {
    return this._actionListener;
  }

  /**
   * Changes the action to CREATING.
   *
   * @returns void
   */
  changeToCreating(): void {
    this.changeTo(CrudActionEnum.CREATING);
  }

  /**
   * Changes the action to DELETING.
   *
   * @returns void
   */
  changeToDeleting(): void {
    this.changeTo(CrudActionEnum.DELETING);
  }

  /**
   * Changes the action to FETCHING.
   *
   * @returns void
   */
  changeToFetching(): void {
    this.changeTo(CrudActionEnum.FETCHING);
  }

  /**
   * Changes the action to READING.
   *
   * @returns void
   */
  changeToReading(): void {
    this.changeTo(CrudActionEnum.READING);
  }

  /**
   * Changes the action to SAVING.
   *
   * @returns void
   */
  changeToSaving(): void {
    this.changeTo(CrudActionEnum.SAVING);
  }

  /**
   * Changes the action to UPDATING.
   *
   * @returns void
   */
  changeToUpdating(): void {
    this.changeTo(CrudActionEnum.UPDATING);
  }

  /**
   * Indicates if the action is CREATING.
   *
   * @returns boolean
   */
  isCreating(): boolean {
    return this.isAction(CrudActionEnum.CREATING);
  }

  /**
   * Indicates if the action is DELETING.
   *
   * @returns boolean
   */
  isDeleting(): boolean {
    return this.isAction(CrudActionEnum.DELETING);
  }

  /**
   * Indicates if the action is FETCHING.
   *
   * @returns boolean
   */
  isFetching(): boolean {
    return this.isAction(CrudActionEnum.FETCHING);
  }

  /**
   * Indicates if the action is READING.
   *
   * @returns boolean
   */
  isReading(): boolean {
    return this.isAction(CrudActionEnum.READING);
  }

  /**
   * Indicates if the action is SAVING.
   *
   * @returns boolean
   */
  isSaving(): boolean {
    return this.isAction(CrudActionEnum.SAVING);
  }

  /**
   * Indicates if the action is UPDATING.
   *
   * @returns boolean
   */
  isUpdating(): boolean {
    return this.isAction(CrudActionEnum.UPDATING);
  }

  /**
   * Resets the current value.
   *
   * @returns void
   */
  reset(): void {
    this.changeToReading();
  }

  /**
   * Changes the stored action to the given one.
   *
   * @param action CrudActionEnum
   * @returns void
   */
  protected changeTo(action: CrudActionEnum): void {
    this._action = action;
    this._actionNotifier.next(this._action);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this._actionNotifier = new BehaviorSubject(undefined);
    this._actionListener = this._actionNotifier.asObservable();

    this.reset();
  }

  /**
   * Indicates if the stored action is the given one.
   *
   * @returns boolean
   */
  protected isAction(action: CrudActionEnum): boolean {
    const isIt: boolean = (this.action === action);
    return isIt;
  }
}
