/**
 * @see `TitleAttributes`: Common attributes for all title types.
 * @see `Season`: Ensure that class and this enumerative are synchronized about public attributes.
 */
export enum SeasonAttributes {
  copyright = 'copyright',
  countryOfOriginId = 'countryOfOriginId',
  genreId = 'genreId',
  keywords = 'keywords',
  mpmProductId = 'mpmProductId',
  numberOfEpisodes = 'numberOfEpisodes',
  primaryProductAssociation = 'primaryProductAssociation',
  productionCompanyId = 'productionCompanyId',
  radarProductNumber = 'radarProductNumber',
  seasonNumber = 'seasonNumber',
  seasonPremiereDate = 'seasonPremiereDate',
  seasonProductionYear = 'seasonProductionYear',
  secondaryProductAssociation = 'secondaryProductAssociation',
  seriesId = 'seriesId'
}
