import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-quick-access',
  template: require('./bolt-quick-access.html'),
  styles: [require('./bolt-quick-access.scss')]
})
export class BoltQuickAccessComponent {
  @Input() disabled: boolean;
  @Input() link: string | any[];
  @Input() icon: string;
  @Input() title: string;

  constructor() {
    this.disabled = false;
    this.title = '';
  }
}
