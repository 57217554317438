import { Input, Component, OnInit } from '@angular/core';

import { RoleService } from 'app/modules/role/services/role.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';

@Component({
  selector: 'bolt-credits-aggregate-role-list',
  template: require('./bolt-credits-aggregate-role-list.html'),
  providers: [
    RoleService
  ]
})
export class BoltCreditsAggregateRoleListComponent extends StormComponent implements OnInit {
  @Input('id')
  roleId: number;
  protected role: any;

  constructor(
    protected roleService: RoleService
  ) {
    super();
  }

  ngOnInit(): void {
    this.roleService.fetchRoleNoLocation(
      this.roleId,
      (response: any) => this.role = response.item,
      this.onError
    );
  }

  /**
   * Assign the current role an error name
   */
  protected onError(): void {
    this.role = {
      id: this.roleId,
      name: 'This role name could not be retrieved'
    };
  }
}
