import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Account, Country, Language, List, ProductType, TypeEnum as ListType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isNumber as _isNumber, isUndefined as _isUndefined, upperFirst as _upperFirst, startCase as _startCase } from 'lodash';
import { Dropdown } from 'primeng';
import { Subscription } from 'rxjs';

import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';


@Component({
  selector: 'bolt-metadata-export-locale-selector',
  template: require('./bolt-metadata-export-locale-selector.html'),
  styles: [require('./bolt-metadata-export-locale-selector.scss')]
})
export class BoltMetadataExportLocaleSelectorComponent implements OnDestroy, OnInit {
  @Output('changed') changeEvent: EventEmitter<Locale>;

  readonly listType: typeof ListType = ListType;
  readonly scrollHeight: string = this.appConfig.get('ux.multiSelect.scrollHeight');

  accountList: SelectionItem[];
  languageList: SelectionItem[];
  productTypeList: SelectionItem[];
  selectedAccount: number;
  selectedLanguage: number;
  selectedProductType: number;
  selectedTerritory: number;
  territoryList: SelectionItem[];

  @ViewChild('language', { static: true })
  protected languageInput: Dropdown;

  protected accountEntity: Account | undefined;
  protected languageEntity: Language | undefined;
  protected productTypeEntity: ProductType | undefined;
  protected subscriptions: Subscription;
  protected territoryEntity: Country | undefined;

  constructor(
    protected appConfig: AppConfigProvider,
    protected layoutHandler: LayoutHandlerService,
    protected masterdataManager: MasterDataManager,
    protected notificationService: NotificationService
  ) {
    this.accountList = [];
    this.changeEvent = new EventEmitter();
    this.languageList = [];
    this.productTypeList = [];
    this.subscriptions = new Subscription();
    this.territoryList = [];

    this.reset();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.loadList(ListType.account, Account.ALL_ID);
    this.loadList(ListType.language, Language.ALL_ID);
    this.loadList(ListType.productType);
    this.loadList(ListType.territory, Country.ALL_ID);
  }

  /**
   * Focus on language list.
   *
   * @returns void
   */
  focus(): void {
    setTimeout(
      () => {
        this.languageInput.focus();
      }
    );
  }

  /**
   * Indicates if it has to block the given list.
   *
   * @param list ListType
   * @returns boolean
   */
  hasBlockList(list: ListType): boolean {
    const hasIt: boolean = !this.layoutHandler.isReading() || (this[this.getListAttributeNameFor(list)].length === 0);
    return hasIt;
  }

  /**
   * Indicates if the given selection is valid.
   *
   * @param selection number
   * @returns boolean
   */
  isSelectionValid(selection: number): boolean {
    return !_isUndefined(selection);
  }

  /**
   * Reset it.
   *
   * @returns void
   */
  reset(): void {
    this.selectedAccount = undefined;
    this.selectedLanguage = undefined;
    this.selectedProductType = ProductType.ALL_ID;
    this.selectedTerritory = undefined;

    this.discoverEntityFor(ListType.account);
    this.discoverEntityFor(ListType.language);
    this.discoverEntityFor(ListType.productType);
    this.discoverEntityFor(ListType.territory);
  }

  /**
   * Updates the selection for the given list.
   *
   * @param list ListType
   * @param selection number
   * @returns void
   */
  updateSelectionFor(list: ListType, selection: number): void {
    this[this.getSelectionAttributeNameFor(list)] = _isNumber(selection) ? selection : undefined;

    this.discoverEntityFor(list);

    const locale: Locale = new Locale({
      language: _isUndefined(this.languageEntity) ? undefined : this.languageEntity.code,
      territory: _isUndefined(this.territoryEntity) ? undefined : [this.territoryEntity.code],
      productType: _isUndefined(this.productTypeEntity) ? undefined : [this.productTypeEntity.code],
      account: _isUndefined(this.accountEntity) ? undefined : [this.accountEntity.code]
    });

    this.changeEvent.emit(locale);
  }

  /**
   * Discovers and stores the entity for the selection option into the given list.
   *
   * @param list ListType
   * @returns void
   */
  protected discoverEntityFor(list: ListType): void {
    const selection: number | undefined = this[this.getSelectionAttributeNameFor(list)];
    const attribute: string = `${list}Entity`;

    if (_isUndefined(selection)) {
      this[attribute] = undefined;
    } else {
      const item: SelectionItem = this[this.getListAttributeNameFor(list)].find(
        (current: SelectionItem) => current.value === selection
      );

      this[attribute] = _isUndefined(item) ? undefined : item.source;
    }
  }

  /**
   * Returns the list attribute name for the given list type.
   *
   * @param list ListType
   * @returns string
   */
  protected getListAttributeNameFor(list: ListType): string {
    return `${list}List`;
  }

  /**
   * Returns the selection attribute name for the given list type.
   *
   * @param list ListType
   * @returns string
   */
  protected getSelectionAttributeNameFor(list: ListType): string {
    return `selected${_upperFirst(list)}`;
  }

  /**
   * Loads the given list on the given storage.
   *
   * @param list ListType
   * @param excludedValue number
   * @returns void
   */
  protected loadList(list: ListType, excludedValue?: number): void {
    const subs: Subscription = this.masterdataManager.getListFor(
      list,
      false,
      (aList: List) => {
        this[this.getListAttributeNameFor(list)] = _isUndefined(excludedValue)
          ? aList.items
          : aList.items.filter((item: SelectionItem) => item.value !== excludedValue);

        this.discoverEntityFor(list);
      },
      (error: HttpError) => {
        this.notificationService.handleError(`Failed loading ${_startCase(list)} list.`, error);
      }
    );

    this.subscriptions.add(subs);
  }
}
