import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayFn'
})
export class ArrayFnPipe implements PipeTransform {
  transform(value: any[], fn: string, params: any[]) {
    if (!Array.isArray(value)) {
      return value;
    }

    if (typeof value[fn] === 'function') {
      return value[fn].apply(value, params);
    } else {
      return value[fn];
    }
  }
}
