import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { ProximityItem } from '@bolt/ui-shared/droplists';
import { Account, Country, Language, ProductType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { cloneDeep as _cloneDeep, isString as _isString, sortBy as _sortBy } from 'lodash';
import * as moment from 'moment';

import { AbstractManagerService } from '../../abstract-manager.service';
import { CollectionManagerHelper, CollectionManagerCollectionInterface } from 'app/modules/common/helpers/collection-manager.helper';
import { Episode } from '../../../models/export/request-file-data/item/season/episode/episode.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Feature } from '../../../models/export/request-file-data/item/feature/feature.model';
import { Item } from '../../../models/export/request-file-data/item/item.model';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { ProductLayoutHelper } from 'app/modules/title/helpers/product-layout/product-layout.helper';
import { RequestFileData } from '../../../models/export/request-file-data/request-file-data.model';
import { Season } from '../../../models/export/request-file-data/item/season/season.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { TemplateService } from '../../template/template.service';
import { Title, TitleType } from 'app/modules/title/models/title.model';
import { TitleListSearchResult } from 'app/modules/title/models/title-list-search-result.model';
import { TitleMetadataInterface } from 'app/modules/title/models/title-metadata.model';
import { TitleService } from 'app/modules/title/services/title.service';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Injectable()
export class ManagerService extends AbstractManagerService {
  protected _currentExportableTitle: Item;
  protected _currentMainTitle: TitleListSearchResult;
  protected _currentSecondaryTitle: any;
  protected _secondaryTitleCleared: BehaviorSubject<boolean>;
  protected _currentTitleType: TitleType;
  protected _expandedExportableTitles: boolean[];
  protected _exportableTitlesToRemove: any;

  protected _exportableEpisodesTitleToRemove: Array<{
    titleId: number,
    titleLanguageId: number,
    titleTerritoryId: number,
    episodeId: number
  }>;

  protected _isExporting: boolean;
  protected _isSearching: boolean;
  protected _secondaryTitles: ProximityItem[];
  protected _secondaryTitlesObserver: BehaviorSubject<ProximityItem[]>;
  protected _fetchedEpisodes: Map<number, { data: any, observable: Observable<any> }>;

  constructor(
    protected appConfig: AppConfigProvider,
    protected exportableTitles: CollectionManagerHelper,
    protected notificationService: NotificationService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected stormListsProvider: StormListsProvider,
    protected templateService: TemplateService,
    protected titleService: TitleService
  ) {
    super(appConfig, notificationService, stormListsProvider, templateService);
    this.initialize();
  }

  get currentExportableTitle(): Item {
    return this._currentExportableTitle;
  }

  get currentMainTitle(): TitleListSearchResult {
    return this._currentMainTitle;
  }

  get currentSecondaryTitle(): any {
    return this._currentSecondaryTitle;
  }

  get currentTitleType(): TitleType {
    return this._currentTitleType;
  }

  get exportableTitlesListName(): string {
    return 'pie-export-list';
  }

  get exportableEpisodesTitleToRemove(): Array<{
    titleId: number,
    titleLanguageId: number,
    titleTerritoryId: number,
    episodeId: number
  }> {
    const keys: string[] = Object.keys(this._exportableEpisodesTitleToRemove);
    const list: any[] = new Array();

    if (keys.length > 0) {
      keys.forEach(
        (key: string) => {
          list.push(this._exportableEpisodesTitleToRemove[key]);
        }
      );
    }

    return list;
  }

  get exportableTitlesToRemove(): any[] {
    const keys: string[] = Object.keys(this._exportableTitlesToRemove);
    const list: any[] = new Array();

    if (keys.length > 0) {
      keys.forEach(
        (key: string) => {
          list.push(this._exportableTitlesToRemove[key]);
        }
      );
    }

    return list;
  }

  get secondaryTitles(): ProximityItem[] {
    return this._secondaryTitles;
  }

  get secondaryTitlesObserver(): BehaviorSubject<ProximityItem[]> {
    return this._secondaryTitlesObserver;
  }

  get secondaryTitleCleared(): BehaviorSubject<boolean> {
    return this._secondaryTitleCleared;
  }

  /**
   * Cleans all titles.
   *
   * @returns void
   */
  cleanAllTitles(): void {
    this._currentExportableTitle = undefined;
    this._currentMainTitle = undefined;
    this._secondaryTitles = new Array();
    this._fetchedEpisodes = new Map();

    this.cleanCurrentSecondaryTitle();
    this.cleanExportableTitles();
    this.cleanExportableTitlesToRemove();
    this.cleanExportableEpisodesTitleToRemove();
  }

  /**
   * Returns an index to be used in Titles array.
   *
   * @param title Item
   * @returns string
   */
  generateTitleIndex(title: Item): string {
    const id: string = [title.id, title.language.id, title.territory.id].join('-');
    return id;
  }

  /**
   * Returns the exportable title list.
   *
   * @returns CollectionManagerCollectionInterface
   */
  getExportableTitles(): CollectionManagerCollectionInterface {
    return this.exportableTitles.getCollection(this.exportableTitlesListName);
  }

  /**
   * Returns a sub set of items of the exportable title list by id and locale.
   *
   * @param id number
   * @param locale Locale
   * @returns CollectionManagerCollectionInterface
   */
  getExportableTitleItemsByIdAndLocale(id: number, locale: Locale): any[] {
    const collection = this.getExportableTitles().rawCollection;
    const collectionFiltered = collection.filter(
      (storedTitle: Item) => {
        const foundIt: boolean = (
          (storedTitle.id === id) &&
          (storedTitle.language.id === (<Language>locale.language).id) &&
          (storedTitle.territory.id === (<Country[]>locale.territory)[0].id)
        );

        return foundIt;
      }
    );
    return collectionFiltered;
  }

  /**
   * Returns the exportable title list as a request file data.
   *
   * @returns RequestFileData
   */
  getExportableTitlesAsRequestFileData(): RequestFileData {
    const fileData: RequestFileData = new RequestFileData(this.currentTemplate);

    this.getExportableTitles().rawCollection.forEach(
      (title: any) => {
        fileData.addItem(title);
      }
    );

    return fileData;
  }

  /**
   * Fetches episodes from the service.
   *
   * @param season Season
   * @returns Observable<any>
   */
  fetchEpisodes(season: Season): Observable<any> {
    if (!this._fetchedEpisodes.has(season.id)) {
      const obs: Observable<any> = Observable.create(
        (observer: Observer<any>) => {
          const cachedItem: any = this._fetchedEpisodes.get(season.id);

          if (cachedItem.data) {
            observer.next(cachedItem.data);
            observer.complete();
          } else {
            const params: any = {
              titleType: TitleType.episode,
              seasonId: season.id,
              _size: this.getAppConfig().get('ux.page.filterTitles.seasonEpisodesFetchPageSize')
            };

            this.titleService.filterTitles(params).subscribe(
              (data: any) => {
                this._fetchedEpisodes.get(season.id).data = data;
                observer.next(data);
                observer.complete();
              },
              (error: any) => {
                this._fetchedEpisodes.delete(season.id);
                observer.error(error);
              }
            );
          }
        }
      );

      this._fetchedEpisodes.set(
        season.id,
        { data: undefined, observable: obs }
      );
    }

    return this._fetchedEpisodes.get(season.id).observable;
  }

  hasAllNeededData(): boolean {
    const hasIt: boolean = (
      this.hasTemplates() &&
      this.hasLanguages() &&
      this.hasTerritories()
    );

    return hasIt;
  }

  /**
   * Indicates if there is a listable title.
   *
   * @returns boolean
   */
  hasCurrentExportableTitle(): boolean {
    const hasIt: boolean = (this.currentExportableTitle !== undefined);
    return hasIt;
  }

  /**
   * Indicates if there is a current main title.
   *
   * @returns boolean
   */
  hasCurrentMainTitle(): boolean {
    const hasIt: boolean = (this.currentMainTitle !== undefined);
    return hasIt;
  }

  /**
   * Indicates if there is a current secondary title.
   *
   * @returns boolean
   */
  hasCurrentSecondaryTitle(): boolean {
    const hasIt: boolean = (this.currentSecondaryTitle !== undefined);
    return hasIt;
  }

  /**
   * Indicates if there are titles to be exported.
   *
   * @returns boolean
   */
  hasExportableTitles(): boolean {
    const hasIt: boolean = (this.getExportableTitles().collection.length > 0);
    return hasIt;
  }

  /**
   * Indicates if there are episodes to remove.
   *
   * @returns boolean
   */
  hasExportableEpisodesTitleToRemove(): boolean {
    const hasIt: boolean = (this.exportableEpisodesTitleToRemove.length > 0);
    return hasIt;
  }

  /**
   * Indicates if there are titles to remove.
   *
   * @returns boolean
   */
  hasExportableTitlesToRemove(): boolean {
    const hasIt: boolean = (this.exportableTitlesToRemove.length > 0);
    return hasIt;
  }

  /**
   * Indicates if there are secondary titles.
   *
   * @returns boolean
   */
  hasSecondaryTitles(): boolean {
    const hasIt: boolean = (this.secondaryTitles.length > 0);
    return hasIt;
  }

  /**
   * Indicates if the given exportable title is expanded.
   *
   * @returns boolean
   */
  isExportableTitleExpanded(title: any): boolean {
    const index: string = this.generateTitleIndex(title);
    return this._expandedExportableTitles[index];
  }

  /**
   * Indicates if it is exporting data.
   *
   * @returns boolean
   */
  isExporting(): boolean {
    return this._isExporting;
  }

  /**
   * Indicates if features is the selected title type.
   *
   * @returns boolean
   */
  isFeaturesSelected(): boolean {
    const isIt: boolean = (this.currentTitleType === TitleType.feature);
    return isIt;
  }

  /**
   * Indicates if the given title has all of his episodes selected to be removed.
   *
   * @param title any
   * @returns boolean
   */
  isFullTitleListOfEpisodesSelected(title: Season): boolean {
    let episodesMatch = 0;

    Object.keys(this._exportableEpisodesTitleToRemove).forEach(
      (episodeIndex: string) => {
        if (episodeIndex.indexOf(this.generateTitleIndex(title)) !== -1) {
          episodesMatch++;
        }
      }
    );

    return title.numberOfEpisodes === episodesMatch;
  }

  /**
   * Indicates if it is searching data in any API.
   *
   * @returns boolean
   */
  isSearching(): boolean {
    return this._isSearching;
  }

  /**
   * Indicates if series is the selected title type.
   *
   * @returns boolean
   */
  isSeriesSelected(): boolean {
    const isIt: boolean = (this.currentTitleType === TitleType.series);
    return isIt;
  }

  /**
   * Indicates if the given title candidate to be removed.
   *
   * @param title any
   * @returns boolean
   */
  isTitleInListToRemove(title: any): boolean {
    const index: string = this.generateTitleIndex(title);
    const isTitleInListToRemove: boolean = (this._exportableTitlesToRemove[index] !== undefined);

    return isTitleInListToRemove;
  }

  /**
   * Indicates if the given episode candidate to be removed.
   *
   * @param title any
   * @param episode any
   * @returns boolean
   */
  isEpisodeInListToRemove(title: any, episode: any): boolean {
    const index: string = this.generateTitleEpisodeIndex(title, episode);
    const isEpisodeInListToRemove: boolean = (this._exportableEpisodesTitleToRemove[index] !== undefined);

    return isEpisodeInListToRemove;
  }

  /**
   * Refreshes the exportable titles list.
   *
   * @returns void
   */
  refreshExportableTitles(): void {
    this.exportableTitles.refreshCollection(this.exportableTitlesListName);
  }

  /**
   * Removes the selected title from the exportable list.
   *
   * @returns void
   */
  removeSelectionFromExportableTitles(): void {
    this.exportableTitles.removeItemsFromCollection(
      this.exportableTitlesListName,
      [ this.exportableTitlesToRemove ],
      ['id', 'language.id', 'territory.id']
    );

    this.cleanRemovedTitlesFromExportableEpisodesTitleToRemove();
    this.cleanExportableTitlesToRemove();
    this.handleNotice('Titles removed successfully from exportable list.', notificationsContainer.pie.export.key);
  }

  /**
   * Removes the selected episodes from the exportable list.
   *
   * @returns void
   */
  removeSelectionFromExportableEpisodes(): void {
    const collection = _cloneDeep(this.exportableTitles.getCollection(this.exportableTitlesListName));
    const _items: any[] = (
      collection.hasOwnProperty('rawCollection')
        ? _cloneDeep(collection.rawCollection)
        : _cloneDeep(collection.collection)
    );

    this.exportableEpisodesTitleToRemove.forEach(
      (episodeTitle) => {
        const titleTargetIndex = _items.findIndex(
          (title: Season) => {
            const found = title.id === episodeTitle.titleId
              && title.language.id === episodeTitle.titleLanguageId
              && title.territory.id === episodeTitle.titleTerritoryId;
            return found;
          }
        );

        if (titleTargetIndex !== -1) {
          (<Season>_items[titleTargetIndex]).setEpisodes(
            (<Season>_items[titleTargetIndex]).episodes.filter(
              (episode: Episode) => {
                return episode.id !== episodeTitle.episodeId;
              }
            )
          );

          (<Season>_items[titleTargetIndex]).setNumberOfEpisodes(
            (<Season>_items[titleTargetIndex]).episodes.length
          );
        }
      }
    );

    this.exportableTitles.setCollectionItems(
      this.exportableTitlesListName,
      _items,
      true,
      false
    );

    this.cleanExportableEpisodesTitleToRemove();
    this.handleNotice('Episodes removed successfully from exportable list.', notificationsContainer.pie.export.key);
  }

  reset(fullReset: boolean): void {
    super.reset(fullReset);

    this._isExporting = false;
    this._isSearching = false;

    this.cleanNotifications();
    this.cleanAllTitles();
  }

  /**
   * Reset the current listable title.
   *
   * @returns void
   */
  resetCurrentExportableTitle(): void {
    this._currentExportableTitle = undefined;
  }

  /**
   * Add title and if has, it episodes, to remove from exportable lists.
   *
   * @param title any
   * @param index string
   * @returns void
   */
  selectTitleEpisodesToBeRemoved(title: Season, index: string): void {
    this._exportableTitlesToRemove[index] = title;

    if ((<Season>title).hasEpisodes()) {
      (<Season>title).episodes.forEach(
        (episode: Episode) => {
          const indexEpisode: string = this.generateTitleEpisodeIndex(title, episode);

          if (!this.isEpisodeInListToRemove(title, episode)) {
            this._exportableEpisodesTitleToRemove[indexEpisode] = {
              titleId: title.id,
              titleLanguageId: title.language.id,
              titleTerritoryId: title.territory.id,
              episodeId: episode.id
            };
          }
        }
      );
    }
  }

  /**
   * Searches the title to be exported.
   *
   * @returns void
   */
  searchExportableTitle(): void {
    this.resetCurrentExportableTitle();
    this._isSearching = true;

    const createdLocale = this.createLocale();
    const searchingParams: any = {
      productType: (
        this.isFeaturesSelected()
          ? this.currentTitleType
          : TitleType.season
      ),
      productId: (
        this.isFeaturesSelected()
          ? this.currentMainTitle.id
          : this.currentSecondaryTitle
      ),
      locale: createdLocale.getLocaleCode()
    };

    const repeatedTitle: any[] = this.getExportableTitleItemsByIdAndLocale(
      searchingParams.productId,
      createdLocale
    );

    if (repeatedTitle.length > 0) {
      this.handleExportableTitlesError(new ErrorHelper('Title already exists in list.'));
    } else {
      this.titleService.fetchComputedProductMetadata(searchingParams, undefined, false).subscribe(
        (data: any) => {
          this.addToExportableTitles(data.item);
        },
        (error: Response) => {
          this.handleExportableTitlesError(new ErrorHelper(`Error while fetching Computed Product Metadata ${error.statusText}`));
        }
      );
    }
  }

  /**
   * Searches the titles using the given query.
   *
   * @param query string
   * @param onSuccess any
   * @param onError any
   * @param finallyDo any
   * @returns void
   */
  searchTitleByQuery(query: string, onSuccess: any, onError: any, finallyDo?: any): void {
    this._isSearching = true;

    const request: any = {
      q: query,
      type: this.currentTitleType.toString(),
      page_size: this.getAppConfig().get('ux.dataTables.biggestPageSize'),
      page: 1,
      sort_by: 'title',
      sort_direction: 'asc',
    };

    this.titleService.search(request).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (data: any) => {
        onSuccess(data);
        this._isSearching = false;
      },
      (error: any) => {
        onError(error);
        this._isSearching = false;
      }
    );
  }

  setCurrentLanguage(language: any): void {
    super.setCurrentLanguage(language);
  }

  /**
   * Set the current main title.
   *
   * @param title TitleListSearchResult
   * @returns void
   */
  setCurrentMainTitle(title: TitleListSearchResult): void {
    this._currentMainTitle = title;

    if (this.isSeriesSelected()) {
      this.retrieveSeasons();
    }
  }

  /**
   * Set the current secondary title.
   *
   * @param secondaryTitle number
   * @returns void
   */
  setCurrentSecondaryTitle(secondaryTitle: number) {
    this._currentSecondaryTitle = (
      Number.isInteger(secondaryTitle)
        ? secondaryTitle
        : this.cleanCurrentSecondaryTitle()
    );
  }

  setCurrentTerritory(territory: any): void {
    super.setCurrentTerritory(territory);
  }

  /**
   * Set the current title type.
   *
   * @param type TitleType
   * @returns void
   */
  setCurrentTitleType(type: TitleType): void {
    this._currentTitleType = type;
  }

  /**
   * Set if it is exporting or not.
   *
   * @param isExporting boolean
   * @returns void
   */
  setIsExporting(isExporting: boolean): void {
    this._isExporting = isExporting;
  }

  /**
   * Toggles the expansion for the given exportable title.
   *
   * @param title any
   * @returns void
   */
  toggleExportableTitleExpansion(title: any): void {
    const index: string = this.generateTitleIndex(title);
    this._expandedExportableTitles[index] = !this._expandedExportableTitles[index];
  }

  /**
   * Updates the selection of titles to remove from exportable list.
   *
   * @param title any
   * @returns void
   */
  updateSelectionToRemoveFromExportableTitles(title: any): void {
    const index: string = this.generateTitleIndex(title);

    if (this.isTitleInListToRemove(title)) {
      delete this._exportableTitlesToRemove[index];

      if ((<Season>title).hasEpisodes()) {
        (<Season>title).episodes.forEach(
          (episode: Episode) => {
            const indexEpisode: string = this.generateTitleEpisodeIndex(title, episode);

            if (this.isEpisodeInListToRemove(title, episode)) {
              delete this._exportableEpisodesTitleToRemove[indexEpisode];
            }
          }
        );
      }
    } else {

      this.selectTitleEpisodesToBeRemoved(title, index);
    }
  }

  /**
   * Updates the selection of episodes by title to remove from exportable list.
   *
   * @param title any
   * @param episode any
   * @returns void
   */
  updateSelectionToRemoveFromExportableEpisodesByTitle(
    title: any,
    episode: any
  ): void {
    const index: string = this.generateTitleEpisodeIndex(title, episode);
    const indexTitle: string = this.generateTitleIndex(title);

    if (this.isEpisodeInListToRemove(title, episode)) {
      delete this._exportableEpisodesTitleToRemove[index];
      delete this._exportableTitlesToRemove[indexTitle];
    } else {
      this._exportableEpisodesTitleToRemove[index] = {
        titleId: title.id,
        titleLanguageId: title.language.id,
        titleTerritoryId: title.territory.id,
        episodeId: episode.id
      };

      if (
        this.isFullTitleListOfEpisodesSelected(<Season>title)
        && !this.isTitleInListToRemove(title)
      ) {
        this._exportableTitlesToRemove[indexTitle] = title;
      }
    }
  }

  /**
   * Adds the given title to the exportable list.
   *
   * @param title Title
   * @returns void
   */
  protected addToExportableTitles(title: Title): void {
    this.productLayoutHelper.mapAttributes([ title ]).subscribe(
      (mappedData: TitleMetadataInterface[]) => {
        const mappedTitle: any = mappedData.pop();
        let newItem: Item;

        if (this.isFeaturesSelected()) {
          newItem = new Feature(
            mappedTitle.language,
            mappedTitle.territory[0],
            mappedTitle.id,
            mappedTitle.legalTitle
          );

          this.handleExportableTitlesSuccess(newItem);
        } else {
          newItem = new Season(
            mappedTitle.language,
            mappedTitle.territory[0],
            mappedTitle.id,
            mappedTitle.legalTitle,
            mappedTitle.seriesId,
            mappedTitle.numberOfEpisodes
          );

          this.fetchEpisodes(mappedTitle).subscribe(
            (episodesRawData: StormServiceResponseCollection) => {
              const episodes: Episode[] = this.readEpisodes(episodesRawData.collection, mappedTitle);

              (<Season>newItem).setEpisodes(episodes);
              this.handleExportableTitlesSuccess(newItem);
            },
            (error: any) => {
              this.handleExportableTitlesError(error);
            }
          );
        }
      },
      (error: any) => {
        this.handleExportableTitlesError(error);
      }
    );
  }

  /**
   * Cleans the current secondary title.
   *
   * @returns void
   */
  protected cleanCurrentSecondaryTitle(): void {
    this._currentSecondaryTitle = undefined;
    this._secondaryTitleCleared.next(true);
  }

  /**
   * Cleans the exportable titles.
   *
   * @returns void
   */
  protected cleanExportableTitles(): void {
    const collection: any = {
      name: this.exportableTitlesListName,
      collection: [],
      sorting: [],
      paginate: true,
      pagination: {
        page_size: this.appConfig.get('ux.dataTables.pageSize')
      },
    };

    this.exportableTitles.setCollections([ collection ]);

    this._expandedExportableTitles = new Array();
  }

  /**
   * Cleans the exportable titles to be removed.
   *
   * @returns void
   */
  protected cleanExportableTitlesToRemove(): void {
    this._exportableTitlesToRemove = new Array();
  }

  /**
   * Cleans the titles already removed from the exportable episodes titles to be removed.
   *
   * @returns void
   */
  protected cleanRemovedTitlesFromExportableEpisodesTitleToRemove(): void {
    const exportableTitleToRemoveKeys: string[] = Object.keys(this._exportableTitlesToRemove);
    const exportableEpisodesTitleToRemoveKeys: string[] = Object.keys(this._exportableEpisodesTitleToRemove);
    let auxiliaryEpisodesTitleToRemove = new Array();

    if (exportableEpisodesTitleToRemoveKeys.length > 0) {
      exportableEpisodesTitleToRemoveKeys.forEach(
        (exportableEpisodesTitleToRemoveKey: string) => {
          auxiliaryEpisodesTitleToRemove[exportableEpisodesTitleToRemoveKey] =
          _cloneDeep(this._exportableEpisodesTitleToRemove[exportableEpisodesTitleToRemoveKey]);
        }
      );
    }

    if (exportableTitleToRemoveKeys.length > 0) {
      exportableTitleToRemoveKeys.forEach(
        (exportableTitleToRemoveKey: string) => {
          const auxCleanedEpisodesList = new Array();
          const auxEpisodesTitleToRemoveKeys: string[] = Object.keys(auxiliaryEpisodesTitleToRemove);

          if (auxEpisodesTitleToRemoveKeys.length > 0) {
            auxEpisodesTitleToRemoveKeys.forEach(
              (auxEpisodesTitleToRemoveKey: string) => {
                const match = auxEpisodesTitleToRemoveKey.indexOf(exportableTitleToRemoveKey + '-') !== -1;

                if (!match) {
                  auxCleanedEpisodesList[auxEpisodesTitleToRemoveKey] =
                    auxiliaryEpisodesTitleToRemove[auxEpisodesTitleToRemoveKey];
                }
              }
            );

            auxiliaryEpisodesTitleToRemove = auxCleanedEpisodesList;
          }
        }
      );

      this._exportableEpisodesTitleToRemove = auxiliaryEpisodesTitleToRemove;
    }
  }

  /**
   * Cleans the exportable episodes to be removed list.
   *
   * @returns void
   */
  protected cleanExportableEpisodesTitleToRemove(): void {
    this._exportableEpisodesTitleToRemove = new Array();
  }

  /**
   * Creates and returns a locale.
   *
   * @returns Locale
   */
  protected createLocale(): Locale {
    const languageEntity: Language = this.languages.filter(
      lan => {
        return (lan.value === this.currentLanguage);
      }
    ).pop().entity;

    const territoryEntity: Country = this.territories.filter(
      ter => {
        return (ter.value === this.currentTerritory);
      }
    ).pop().entity;

    const locale: Locale = new Locale({
      language: languageEntity,
      territory: [ territoryEntity ],
      productType: [ new ProductType(ProductType.ALL_ID, undefined, ProductType.ALL_VALUE) ],
      account: [ new Account(Account.ALL_ID, undefined, Account.ALL_VALUE) ]
    });

    return locale;
  }

  /**
   * Returns an index to be used in episodes to remove array.
   *
   * @param title Item
   * @param episode Episode
   * @returns string
   */
  protected generateTitleEpisodeIndex(title: Item, episode: Episode): string {
    const id: string = [title.id, title.language.id, title.territory.id, episode.id].join('-');
    return id;
  }

  /**
   * Handles the errors when user tries to add a title to be exported.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected handleExportableTitlesError(error: ErrorHelper): void {
    this.handleError(error.message, error, notificationsContainer.pie.export.key);
    this._isSearching = false;
  }

  /**
   * Handles the success path for the given exportable title.
   *
   * @param item Item
   * @returns void
   */
  protected handleExportableTitlesSuccess(item: Item): void {
    this._currentExportableTitle = item;
    this._isSearching = false;

    this.exportableTitles.addItemsToCollection(this.exportableTitlesListName, [ this.currentExportableTitle ]);
    this.handleNotice('Title was successfully added to be exported.', notificationsContainer.pie.export.key);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    super.initialize();
    this._secondaryTitleCleared = new BehaviorSubject(false);
    this._secondaryTitlesObserver = new BehaviorSubject(undefined);
    this.reset(false);
  }

  /**
   * Reads the given array and adds the episodes.
   *
   * @param episodesData any[]
   * @param season Season
   * @returns Episode[]
   */
  protected readEpisodes(episodesData: any[], season: Season): Episode[] {
    const episodes = new Array();

    episodesData.forEach(
      (episodeData) => {
        const auxiliaryFirstAirReleaseDate = (
          _isString(episodeData.firstAirReleaseDate)
            ? moment(episodeData.firstAirReleaseDate)
            : episodeData.firstAirReleaseDate
        );

        const auxiliaryFirstAirRunningOrder = (
          episodeData.firstAirRunningOrder
            ? episodeData.firstAirRunningOrder.toString()
            : ''
        );

        const episode: Episode = new Episode(
          episodeData.id,
          episodeData.f0_num,
          episodeData.title,
          episodeData.radarProductId,
          auxiliaryFirstAirReleaseDate,
          auxiliaryFirstAirRunningOrder,
          season.id
        );

        episodes.push(episode);
      }
    );

    return episodes;
  }

  /**
   * Retrieves the seasons for the current main title.
   *
   * @returns void
   */
  protected retrieveSeasons(): void {
    const errorMessage: string = 'Failed fetching seasons';

    this.titleService.filterTitles({
      titleType: TitleType.season,
      seriesId: this.currentMainTitle.id,
      _size: this.getAppConfig().get('ux.page.filterTitles.seasonEpisodesFetchPageSize')
    })
    .subscribe(
      (response: StormServiceResponseCollection) => {
        const collection: any[] = _sortBy(response.collection, 'prettifiedLegalTitle');

        if (collection.length > 0) {
          collection.forEach(
            data => {
              const item: ProximityItem = new ProximityItem(data.prettifiedLegalTitle, data.id);
              this._secondaryTitles.push(item);
            }
          );

          this._secondaryTitlesObserver.next(this._secondaryTitles);
        } else {
          this.handleError(errorMessage, new ErrorHelper('No data given.'), notificationsContainer.pie.export.key);
        }
      },
      (error: any) => {
        this.handleError(errorMessage, error, notificationsContainer.pie.export.key);
      }
    );
  }
}
