import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Language, List as MasterDataList, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { capitalize as _capitalize, isArray as _isArray, isObject as _isObject } from 'lodash';

import { Category } from '../../models/category/category.model';
import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { CreationForm as ItemCreationForm } from '../../models/item/original/creation-form/creation-form.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ItemService } from '../../services/item/item.service';
import { LayoutService as FormLayoutService } from 'app/shared/services/form/layout/layout.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { Original } from '../../models/item/original/original.model';
import { Product } from '../../models/product/product.model';
import { Type } from '../../models/type/type.model';
import { TypeEnum } from '../../models/type/type.enum';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-cat-item-handle-original-metadata',
  template: require('./bolt-cat-item-handle-original-metadata.html'),
  styles: [require('./bolt-cat-item-handle-original-metadata.scss')]
})
export class BoltCatItemHandleOriginalMetadataComponent implements OnChanges {
  @Output('closed') closeEvent: EventEmitter<undefined>;
  @Output('saved') saveEvent: EventEmitter<Original>;
  @Input() originalName: string;
  @Input() open: boolean;
  @Input() product: Product;
  @Input() language: number;

  protected categories: SelectionItem[];
  protected languages: SelectionItem[];
  protected form: FormGroup;
  protected selectedTypeCategory: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formBuilder: FormBuilder,
    protected formConfig: FormConfigService,
    protected formLayout: FormLayoutService,
    protected itemService: ItemService,
    protected listLayoutProvider: ListLayoutProvider,
    protected notificationService: NotificationService,
    protected masterDataManager: MasterDataManager
  ) {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.open)) {
      this.open = changes.open.currentValue;

      if (this.open) {
        this.buildForm();
      } else {
        this.destroyForm();
      }
    }
  }

  get maxValuesAsString(): number {
    return this.appConfig.get('ux.multiSelect.maxValuesAsString');
  }

  get scrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Builds the form.
   *
   * @returns void
   */
  protected buildForm(): void {
    const attributes: any = this.formConfig.get('cat.fields');

    this.form = this.formBuilder.group(
      new ItemCreationForm(this.originalName, this.language, attributes)
    );
  }

  /**
   * Cancels it.
   *
   * @returns void
   */
  protected cancel(): void {
    this.hide();
  }

  /**
   * Destroys the form.
   *
   * @returns void
   */
  protected destroyForm(): void {
    this.form = undefined;
  }

  /**
   * Disables the form.
   *
   * @returns void
   */
  protected disableForm(): void {
    this.form.disable();
  }

  /**
   * Enables the form.
   *
   * @returns void
   */
  protected enableForm(): void {
    this.form.enable();
  }

  /**
   * Returns the criteria for sorting languages.
   *
   * @returns any
   */
  protected getLanguagesSortingCriteria(): any {
    const criteria: CallableFunction = (itemA: SelectionItem, itemB: SelectionItem) => {
      if (itemA.label > itemB.label) {
        return 1;
      } else if (itemA.label < itemB.label) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength = this.formConfig.get(`cat.fields.${field}.maxLength`);
    return maxLength;
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.form.disabled;
  }

  /**
   * Indicates if it has to block the category list.
   *
   * @returns boolean
   */
  protected hasBlockCategories(): boolean {
    return !this.hasCategories();
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const isIt: boolean = (this.form.invalid || this.form.disabled);
    return isIt;
  }

  /**
   * Indicates if it has categories.
   *
   * @returns boolean
   */
  protected hasCategories(): boolean {
    const hasIt: boolean = (this.categories.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has to display the content.
   *
   * @returns boolean
   */
  protected hasDisplay(): boolean {
    return this.open;
  }

  /**
   * Indicates if it has to display the languages.
   *
   * @returns boolean
   */
  protected hasDisplayLanguages(): boolean {
    const hasIt: boolean = (_isArray(this.languages) && (this.languages.length > 0));
    return hasIt;
  }

  /**
   * Turns off the display.
   *
   * @returns void
   */
  protected hide(): void {
    this.open = false;
    this.closeEvent.emit();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.open = false;
    this.saveEvent = new EventEmitter();
    this.selectedTypeCategory = undefined;

    this.loadCategories();
    this.loadLanguages();
  }

  /**
   * Indicates if CHARACTER is the selected Type/Category.
   *
   * @returns boolean
   */
  protected isCharacterSelected(): boolean {
    const isIt: boolean = (this.selectedTypeCategory === TypeEnum.Character);
    return isIt;
  }

  /**
   * Loads the categories.
   *
   * @returns void
   */
  protected loadCategories(): void {
    this.categories = new Array();

    this.loadCategoriesFor(TypeEnum.Character);
    this.loadCategoriesFor(TypeEnum.Subproduct);
    this.loadCategoriesFor(TypeEnum.Term);
  }

  /**
   * Loads the languages.
   *
   * @returns void
   */
  protected loadLanguages(): void {
    this.languages = undefined;

    this.masterDataManager.getListFor(
      MasterDataType.language,
      false,
      (list: MasterDataList) => {
        this.languages = list.filter(
          (item: SelectionItem) => !Language.isAll(item.value)
        ).sort(
          this.getLanguagesSortingCriteria()
        );
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve dubbing studios', error, notificationsContainer.cat.details.key);
      }
    );
  }

  /**
   * Loads the categories for the given type.
   *
   * @param type TypeEnum
   * @returns void
   */
  protected loadCategoriesFor(type: TypeEnum): void {
    Type.getCategoryMap().get(type).forEach(
      (aCategory: Category) => {
        const value: string = aCategory.toString();
        const label: string = _capitalize(value);

        this.categories.push(
          new SelectionItem(label, value, undefined, type.toString())
        );
      }
    );
  }

  /**
   * Calls to the save process depends of the category.
   *
   * @returns void
   */
  protected save(): void {
    this.disableForm();

    const category: Category = new Category(this.form.get('_category').value);
    const language: number = (this.form.get('_language').value);
    const type: Type = Type.newFromCategory(category);
    const notes: string = this.form.get('_notes').value;
    const phonetic: string = this.form.get('_phonetic').value;

    const data: any = {
      name: this.form.get('_originalName').value,
      category: category.value,
      notes: notes ? notes : undefined,
      phonetic: phonetic ? phonetic : undefined,
      requiredLocalizations: Boolean(this.form.get('_requiredLocalizations').value),
      originalLanguageId: language
    };

    this.itemService.create(
      this.product.type.value,
      this.product.id,
      type.value,
      data,
      (item: Original) => {
        this.saveEvent.emit(item);
        this.notificationService.handleSuccess('The item was created successfully.', undefined, notificationsContainer.cat.details.key);
        this.hide();
      },
      (error: ErrorHelper) => {
        this.enableForm();
        this.notificationService.handleError('Failed trying to create an item.', error, notificationsContainer.cat.details.key);
      }
    );
  }

  /**
   * Synchronizes the notes field with the given event value.
   *
   * @param event any
   * @returns void
   */
  protected syncNotesValidators(event: any): void {
    const canSync: boolean = (
      _isObject(event) &&
      _isObject((event as any).originalEvent) &&
      _isArray((event as any).value) &&
      ((event as any).value.length > 0)
    );

    if (canSync) {
      this.selectedTypeCategory = event.value[0];

      const notes: AbstractControl = this.form.get('_notes');
      const validator: any = Validators.maxLength(this.getMaxLengthFor('notes'));

      notes.setValidators(validator);
      notes.updateValueAndValidity();
    }
  }
}
