import { Component, Input } from '@angular/core';

import { Field } from '../../models/import/comparison/titles/title/field/field.model';
import { ManagerService } from '../../services/import/manager-service/manager.service';
import { FieldExtras } from '../../models/import/comparison/titles/title/field/field-extras/field-extras.model';


@Component({
  selector: 'bolt-profile-field-content',
  template: require('./bolt-profile-field-content.html'),
  styles: [require('./bolt-profile-field-content.scss')]
})
export class BoltProfileFieldContentComponent {
  @Input('field') field: Field;
  @Input('fieldExtras') fieldExtras: FieldExtras;
  @Input('overwriteNoneConflict') overwriteNoneConflict: boolean;

  constructor(protected managerService: ManagerService) {
    this.initialize();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.overwriteNoneConflict = true;
  }
}
