import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { TitleService, TitleServiceFetchTitleMetadataParamsInterface } from 'app/modules/title/services/title.service';
import { Title } from 'app/modules/title/models/title.model';
import { StormServiceResponseSingleInterface } from 'app/modules/common/services/storm-service-response-single';
import { HttpError } from '@bolt/ui-shared/common';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-title-locking-status',
  template: require('./bolt-title-locking-status.html'),
})
export class BoltTitleLockingStatusComponent extends StormComponent implements OnInit, OnDestroy {
  @Input() title: Title;
  @Input() locale: string;

  lockingStatus: LockingStatus;
  protected lockingStatusSubscription: Subscription;

  constructor(protected titleService: TitleService) {
    super();
  }

  unsubscribe() {
    if (this.lockingStatusSubscription) {
      this.lockingStatusSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  ngOnInit() {
    this.fetchLockingStatus();
  }

  /**
   * Fetches the locking status for the current title and its locale
   *
   * @returns void
   */
  protected fetchLockingStatus(): void {
    this.unsubscribe();

    const params: TitleServiceFetchTitleMetadataParamsInterface = {
      productType: this.title.type,
      productId: this.title.id,
      locale: this.locale
    };

    this.changeStatusToFetchingData();

    this.lockingStatusSubscription = this.titleService.fetchProductMetadata(
      params
    ).subscribe(
      (response: StormServiceResponseSingleInterface) => {
        this.lockingStatus = new LockingStatus(response.item);
        this.changeStatusToDataFound();
      },
      (error: HttpError) => {
        if (error.is404()) {
          this.lockingStatus = new LockingStatus(new Object());
          this.changeStatusToDataFound();
        } else {
          this.changeStatusToError();
        }
      }
    );
  }
}
