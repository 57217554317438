import { includes as _includes } from 'lodash';
import { DecisionEnum } from './decision.enum';


export class Decision {
  protected _type: DecisionEnum;

  constructor(type: DecisionEnum) {
    this.initialize(type);
  }

  get type(): DecisionEnum {
    return this._type;
  }

  /**
   * Indicates if its type is COVERED IN DUB.
   *
   * @returns boolean
   */
  isCoveredInDub(): boolean {
    const isIt: boolean = (this._type === DecisionEnum.COVERED_IN_DUB);
    return isIt;
  }

  /**
   * Indicates if its type is NO TRANSLATION NEEDED.
   *
   * @returns boolean
   */
  isNoTranslationNeeded(): boolean {
    const isIt: boolean = (this._type === DecisionEnum.NO_TRANSLATION_NEEDED);
    return isIt;
  }

  /**
   * Indicates if its type is TRANSLATED TEXT.
   *
   * @returns boolean
   */
  isUseTranslatedText(): boolean {
    const isIt: boolean = (this._type === DecisionEnum.USE_TRANSLATED_TEXT);
    return isIt;
  }

  /**
   * Indicates if its type is VOICE OVER.
   *
   * @returns boolean
   */
  isVoiceOVer(): boolean {
    const isIt: boolean = (this._type === DecisionEnum.VOICE_OVER);
    return isIt;
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this.type.toString();
  }

  /**
   * Initializes the instance.
   *
   * @param type DecisionEnum
   * @returns void
   */
  protected initialize(type: DecisionEnum): void {
    this._type = (
      _includes(DecisionEnum, <any>type)
        ? type
        : undefined
    );
  }
}
