import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isArray as _isArray } from 'lodash';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';
import { Entity } from '../../models/entity/entity.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Lock } from '../../models/entity/lock/lock.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { Project } from '../../models/project/project.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable()
export class ProjectService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp,
    protected notificationService: NotificationService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns a subscription for creating a project.
   *
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  create(data: any, onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): Subscription {
    const request: any = {
      url: this.generateUrl('project.projectService.create.endpoint'),
      body: data
    };

    const subs: Subscription = this.doPostRequest(
      request,
      (successResponse: StormServiceResponseSingle) => {
        try {
          const project: Project = new Project(successResponse.item);
          onSuccessDo(project);
        } catch (error) {
          onErrorDo(this.createInvalidResponseError());
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for deleting a project.
   *
   * @param projectId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  delete(projectId: number, onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): Subscription {
    const url: string = this.generateUrl(
      'project.projectService.delete.endpoint',
      { '{projectId}': projectId }
    );

    const subs: Subscription = this.doDeleteRequest(
      { url: url, checkType: CheckTypeEnum.null },
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for fetching projects.
   *
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetch(onSuccessDo: CallableFunction, onErrorDo: CallableFunction, finallyDo?: CallableFunction): Subscription {
    const url: string = this.generateUrl('project.projectService.fetch.endpoint');

    const subs: Subscription = this.doGetRequest(
      { url: url, checkType: CheckTypeEnum.array },
      (successResponse: StormServiceResponseSingle) => {
        if (_isArray(successResponse.item)) {
          const correctProjects: Project[] = new Array();
          const wrongProjectsId: number[] = new Array();
          const wrongProjectsError: ErrorHelper[] = new Array();

          successResponse.item.forEach(
            (data: any) => {
              try {
                correctProjects.push(new Project(data));
              } catch (err) {
                wrongProjectsId.push(data.id);

                wrongProjectsError.push(
                  new ErrorHelper(`#${data.id}: ${err.message}`, err.statusCode, err.statusDetails)
                );
              }
            }
          );

          if (wrongProjectsId.length > 0) {
            this.handleWrongProjects(wrongProjectsId, wrongProjectsError);
          }

          onSuccessDo(correctProjects);
        } else {
          onErrorDo(this.createInvalidResponseError());
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription for fetching the entities for the given project ID.
   *
   * @param projectId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  fetchEntities(
    projectId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const url: string = this.generateUrl(
      'project.projectService.fetchEntities.endpoint',
      { '{projectId}': projectId }
    );

    const subs: Subscription = this.doGetRequest(
      { url: url, checkType: CheckTypeEnum.array },
      (successResponse: StormServiceResponseSingle) => {
        try {
          if (_isArray(successResponse.item)) {
            const entities: Entity[] = new Array();

            successResponse.item.forEach(
              (data: any) => {
                entities.push(new Entity(data));
              }
            );

            onSuccessDo(entities);
          } else {
            throw this.createInvalidResponseError();
          }
        } catch (err) {
          onErrorDo(err);
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Fetches the locks for the given project ID and entities ID.
   *
   * @param projectId number
   * @param entitiesId number[]
   * @returns Observable<Lock[]>
   */
  fetchLocks(projectId: number, entitiesId: number[]): Observable<Lock[]> {
    const url: string = this.generateUrl(
      'project.projectService.fetchLocks.endpoint',
      { '{projectId}': projectId },
      { entities: entitiesId.join(',') }
    );

    const obs: Observable<Lock[]> = this.authHttp.get(url).pipe(
      map(
        (response: any) => {
          const rawData: any =  this.defaultMapOn(response).item;

          if (_isArray(rawData)) {
            const locks: Lock[] = rawData.map(
              (data: any) => {
                return new Lock(data);
              }
            );

            return locks;
          } else {
            throw this.createInvalidResponseError();
          }
        }
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }

  /**
   * Returns a subscription for updating a project.
   *
   * @param projectId number
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  update(
    projectId: number,
    data: any, onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const url: string = this.generateUrl(
      'project.projectService.update.endpoint',
      { '{projectId}': projectId }
    );

    const subs: Subscription = this.doPutRequest(
      { url: url, body: data },
      (successResponse: StormServiceResponseSingle) => {
        try {
          const project: Project = new Project(successResponse.item);
          onSuccessDo(project);
        } catch (error) {
          onErrorDo(this.createInvalidResponseError());
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Handles the given wrong projects.
   *
   * @param idList number[]
   * @param errorList ErrorHelper[]
   * @returns void
   */
  protected handleWrongProjects(idList: number[], errorList: ErrorHelper[]): void {
    idList.sort(
      (idA: number, idB: number) => (idA - idB)
    );

    this.notificationService.handleError(
      'Invalid projects detected',
      new ErrorHelper(`Failed trying to load projects: #${idList.join(', #')}.`),
      notificationsContainer.projectDashboard.key
    );

    console.log(errorList);
  }
}
