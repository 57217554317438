import { includes as _includes } from 'lodash';
import { ConflictEnum } from './conflict.enum';


export class Conflict {
  protected _type: ConflictEnum;

  constructor(type: ConflictEnum) {
    this.initialize(type);
  }

  get type(): ConflictEnum {
    return this._type;
  }

  /**
   * Indicates if its type is NEW.
   *
   * @returns boolean
   */
  isNew(): boolean {
    const isIt: boolean = (this._type === ConflictEnum.NEW);
    return isIt;
  }

  /**
   * Indicates if its type is NONE.
   *
   * @returns boolean
   */
  isNone(): boolean {
    const isIt: boolean = (this._type === ConflictEnum.NONE);
    return isIt;
  }

  /**
   * Indicates if its type is DELETE.
   *
   * @returns boolean
   */
  isDelete(): boolean {
    const isIt: boolean = (this._type === ConflictEnum.DELETE);
    return isIt;
  }

  /**
   * Indicates if its type is UPDATE.
   *
   * @returns boolean
   */
  isUpdate(): boolean {
    const isIt: boolean = (this._type === ConflictEnum.UPDATE);
    return isIt;
  }

  /**
   * Initializes the instance.
   *
   * @param type ConflictEnum
   * @returns void
   */
  protected initialize(type: ConflictEnum): void {
    this._type = (
      _includes(ConflictEnum, <any>type)
        ? type
        : undefined
    );
  }
}
