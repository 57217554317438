import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Language } from '@bolt/ui-shared/master-data';
import { isArray as _isArray, isUndefined as _isUndefined } from 'lodash';

import { AbstractManagerService } from '../../abstract-manager.service';
import { BooleanField } from '../../../models/import/comparison/titles/title/field/boolean-field/boolean-field.model';
import { Comparison } from '../../../models/import/comparison/comparison.model';
import { Episode } from '../../../models/import/comparison/titles/title/episode/episode.model';
import { Field } from '../../../models/import/comparison/titles/title/field/field.model';
import { FieldExtras } from '../../../models/import/comparison/titles/title/field/field-extras/field-extras.model';
import { MergedData as EpisodeMergedData } from '../../../models/import/comparison/titles/title/episode/merged-data/merged-data.model';
import { ResolutionEnum } from '../../../models/import/comparison/titles/title/field/resolution/resolution.enum';
import { RoleCapabilitiesHandler } from '../../role-capabilities-handler/role-capabilities-handler';
import { Season } from '../../../models/import/comparison/titles/title/season/season.model';
import { Series } from '../../../models/import/comparison/titles/title/series/series.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { TemplateService } from '../../template/template.service';
import { TitlesEnum } from '../../../models/import/comparison/titles/titles.enum';


@Injectable()
export class ManagerService extends AbstractManagerService {
  protected _comparison: Comparison;
  protected _filteredLanguages: any[];
  protected _filteredTerritories: any[];
  protected _currentSeasonNumber: number;
  protected episodeMergedData: EpisodeMergedData[] = new Array();
  protected _isFieldPreviewPopupVisible: boolean;
  protected _fieldPreviewPopupTargetExtras: FieldExtras;
  protected _fieldPreviewPopupTarget: Field;
  protected _isSeasonCollapsed: boolean = true;
  protected _seasonsToDisplay: Array<{ season: { seasonNumber: number } }>;

  constructor(
    protected appConfig: AppConfigProvider,
    protected notificationService: NotificationService,
    protected stormListsProvider: StormListsProvider,
    protected templateService: TemplateService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    super(appConfig, notificationService, stormListsProvider, templateService);
    this.initialize();
  }

  get comparison(): Comparison {
    return this._comparison;
  }

  set comparison(comparison: Comparison) {
    this._comparison = comparison;

    this.filterLanguages();
    this.filterTerritories();
    this.ensureValidCurrentSeasonNumber();
    this.setCurrentTerritory(this.filteredTerritories[0]);
  }

  get isSeasonCollapsed(): boolean {
    return this._isSeasonCollapsed;
  }

  set isSeasonCollapsed(value: boolean) {
    this._isSeasonCollapsed = value;
  }

  get currentSeasonNumber(): number {
    return this._currentSeasonNumber;
  }

  get seasonsToDisplay(): Array<{ season: { seasonNumber: number } }> {
    return this._seasonsToDisplay;
  }

  get isFieldPreviewPopupVisible(): boolean {
    return this._isFieldPreviewPopupVisible;
  }

  set isFieldPreviewPopupVisible(value: boolean) {
    this._isFieldPreviewPopupVisible = value;
  }

  get fieldPreviewPopupTarget(): Field {
    return this._fieldPreviewPopupTarget;
  }

  set fieldPreviewPopupTarget(value: Field) {
    this._fieldPreviewPopupTarget = value;
  }

  get fieldPreviewPopupTargetExtras(): FieldExtras {
    return this._fieldPreviewPopupTargetExtras;
  }

  set fieldPreviewPopupTargetExtras(value: FieldExtras) {
    this._fieldPreviewPopupTargetExtras = value;
  }

  get filteredTerritories(): any[] {
    return this._filteredTerritories;
  }

  get filteredLanguages(): any[] {
    return this._filteredLanguages;
  }

  /**
   * Indicates if all episodes by language are locked.
   *
   * @returns boolean
   */
  areAllEpisodesByLanguageLocked(): boolean {
    const areThey: boolean = this.areAllEpisodesLocked(this.getEpisodesByLanguage());
    return areThey;
  }

  /**
   * Indicates if all episodes by territory are locked.
   *
   * @returns boolean
   */
  areAllEpisodesByTerritoryLocked(): boolean {
    const areThey: boolean = this.areAllEpisodesLocked(this.getEpisodesByTerritory());
    return areThey;
  }

  /**
   * Clear all the variables used in popup for field preview.
   *
   * @returns void
   */
  clearFieldPreviewPopupVariables(): void {
    this.fieldPreviewPopupTargetExtras = undefined;
    this.fieldPreviewPopupTarget = undefined;
    this.isFieldPreviewPopupVisible = false;
  }

  /**
   * Returns the given params from different models as an object typed as FieldExtras.
   *
   * @param selectedTitleName string
   * @param fieldType TitlesEnum
   * @param fieldLabel string
   * @param selectedTitleNumber string
   * @returns FieldExtras
   */
  fieldExtrasFromApiModel(
    selectedTitleName: string, fieldType: TitlesEnum, fieldLabel: string, selectedTitleNumber?: string
  ): FieldExtras {
    selectedTitleNumber = selectedTitleNumber || null;
    const fieldExtras: FieldExtras = new FieldExtras(
      selectedTitleName,
      fieldType,
      fieldLabel,
      selectedTitleNumber ? selectedTitleNumber : undefined
    );
    return fieldExtras;
  }

  /**
   * Forces the confirm resolution.
   *
   * @param field Field
   * @returns void
   */
  forceConfirmResolution(field): void {
    field.updateResolution(ResolutionEnum.CONFIRM);
  }

  /**
   * Returns the episodes by language for the current language and season.
   *
   * @returns Episode[]
   */
  getEpisodesByLanguage(): Episode[] {
    let filterBySeason: number;

    if (this.isSeasonCollapsed) {
      filterBySeason = this.getSeason().entityId;
    }

    const episodes: Episode[] = this.comparison.titles.getEpisodesByLanguage(
      this.currentLanguage.entity.localeLanguage,
      filterBySeason
    );

    return episodes;
  }

  /**
   * Returns the episodes by territory for the current territory and season.
   *
   * @returns Episode[]
   */
  getEpisodesByTerritory(): Episode[] {
    let filterBySeason: number;

    if (this.isSeasonCollapsed) {
      filterBySeason = this.getSeason().entityId;
    }

    const territoryCode: string = (
      this.hasCurrentTerritory()
        ? this.currentTerritory.entity.iso31661
        : undefined
    );

    const episodes: Episode[] = this.comparison.titles.getEpisodesByTerritory(
      territoryCode,
      filterBySeason
    );

    return episodes;
  }

  /**
   * Returns the merged data for all episodes (by language and territory) for the current territory and season.
   *
   * @returns EpisodeMergedData[]
   */
  getEpisodesMergedData(): EpisodeMergedData[] {
    if (this.episodeMergedData.length === 0) {
      const episodeMergedData: EpisodeMergedData[] = new Array();

      this.getEpisodesByLanguage().forEach(
        (episodeByLanguage: Episode) => {
          const territoryEpisode: Episode = this.getEpisodesByTerritory().filter(
            (targetEpisodeByTerritory: Episode) => {
              const matched: boolean = (episodeByLanguage.entityId === targetEpisodeByTerritory.entityId);
              return matched;
            }
          ).pop();

          const mergedData: EpisodeMergedData = new EpisodeMergedData(episodeByLanguage, territoryEpisode);

          episodeMergedData.push(mergedData);
        }
      );

      episodeMergedData.sort(this.getSortingCriteriaForEpisodesMergedData());
      this.episodeMergedData = episodeMergedData;
    }

    return this.episodeMergedData;
  }

  /**
   * Returns the Season for the current season.
   *
   * @returns Season
   */
  getSeason(): Season {
    return this.getSeasonFor(this._currentSeasonNumber);
  }

  /**
   * Returns the Season for the target season.
   *
   * @param seasonNumber number
   * @returns Season
   */
  getSeasonBySeasonNumber(seasonNumber: number): Season {
    return this.getSeasonFor(seasonNumber);
  }

  /**
   * Returns the season field for the given name and the target season.
   *
   * @param seasonNumber number
   * @param name string
   * @returns Field
   */
  getSeasonFieldBySeasonNumber(seasonNumber: number, name: string): Field {
    const auxiliarySeasonNumber: number = seasonNumber;
    const auxiliaryName: string = name;
    const retrievedField = this.getSeasonBySeasonNumber(auxiliarySeasonNumber).getField(auxiliaryName);
    return retrievedField;
  }

  /**
   * Returns the season field for the given name and the current season.
   *
   * @param name string
   * @returns Field
   */
  getSeasonField(name: string): Field {
    return this.getSeason().getField(name);
  }

  /**
   * Returns the locked field for the given season number.
   *
   * @param seasonNumber number
   * @returns BooleanField
   */
  getSeasonLockedField(seasonNumber: number): BooleanField {
    const field: BooleanField = <BooleanField>this.getSeasonBySeasonNumber(seasonNumber).getField('locked');
    return field;
  }

  /**
   * Returns the list of season numbers.
   *
   * @returns number[]
   */
  getSeasonNumbers(): number[] {
    const seasonNumbers: number[] = this._comparison.titles.getSeasonNumbers(
      this.currentLanguage.entity.localeLanguage
    );

    return seasonNumbers;
  }

  /**
   * Returns the series for the given locale.
   *
   * @returns Series
   */
  getSeries(): Series {
    const series: Series = this.comparison.titles.getSeries(this.currentLanguage.entity.localeLanguage);
    return series;
  }

  /**
   * Returns the series field for the given name and locale.
   *
   * @param name string
   * @returns Field
   */
  getSeriesField(name: string): Field {
    return this.getSeries().getField(name);
  }

  /**
   * Returns the locked field for the current series.
   *
   * @returns BooleanField
   */
  getSeriesLockedField(): BooleanField {
    const field: BooleanField = <BooleanField>this.getSeriesField('locked');
    return field;
  }

  hasAllNeededData(): boolean {
    const hasIt: boolean = (
      this.hasTemplates() &&
      (this.comparison !== undefined) &&
      (this.filteredLanguages !== undefined) &&
      (this.filteredLanguages.length > 0) &&
      (this.filteredTerritories !== undefined)
    );

    return hasIt;
  }

  /**
   * Indicates if the import contains only english language and the user is restricted by privileges
   *
   * @returns boolean
   */
  isEnglishOnlyImportRestricted(): boolean {
    const isIt: boolean = (
      _isArray(this.filteredLanguages) &&
      this.filteredLanguages.length === 1 &&
      Language.isEnglish(this.filteredLanguages[0].entity.localeLanguage) &&
      _isArray(this.filteredTerritories) &&
      this.filteredTerritories.length === 0 &&
      !this.roleCapabilitiesHandler.hasEnglishWritePrivilege()
    );

    return isIt;
  }

  /**
   * Indicates if the language metadata is locked.
   *
   * @returns boolean
   */
  isLanguageMetadataLocked(): boolean {
    return this.comparison.titles.isLanguageMetadataLocked();
  }

  /**
   * Indicates if the current language is restricted by user privileges
   *
   * @returns boolean
   */
  isLanguageRestricted(): boolean {
    const isRestricted: boolean = (
      Language.isEnglish(this.currentLanguage.entity.localeLanguage) &&
      !this.roleCapabilitiesHandler.hasEnglishWritePrivilege()
    );

    return isRestricted;
  }

  /**
   * Indicates if the language metadata is locked.
   *
   * @returns boolean
   */
  isTerritoryMetadataLocked(): boolean {
    return this.comparison.titles.isTerritoryMetadataLocked();
  }

  /**
   * Required method to manage popup destroy callback.
   *
   * @param ignore boolean
   * @returns void
   */
  onCloseFieldPreviewPopup(ignore: boolean): void {
    this.clearFieldPreviewPopupVariables();
  }

  reset(fullReset: boolean): void {
    super.reset(fullReset);

    this._currentLanguage = undefined;
    this._currentTerritory = undefined;
    this._filteredLanguages = undefined;
    this._filteredTerritories = undefined;
    this._comparison = undefined;
    this._currentSeasonNumber = undefined;

    this.resetEpisodesMergedData();
    this.cleanNotifications();
    this.clearFieldPreviewPopupVariables();
  }

  setCurrentLanguage(language: any): void {
    super.setCurrentLanguage(language);
    this.ensureValidCurrentSeasonNumber();
    this.repopulateSeasonsData();
    this.resetEpisodesMergedData();
  }

  /**
   * Sets the current season number.
   *
   * @param seasonNumber number
   * @returns void
   */
  setCurrentSeasonNumber(seasonNumber: number): void {
    this._currentSeasonNumber = seasonNumber;
    this.repopulateSeasonsData();
    this.resetEpisodesMergedData();
  }

  setCurrentTerritory(territory: any): void {
    super.setCurrentTerritory(territory);
    this.repopulateSeasonsData();
    this.resetEpisodesMergedData();
  }

  /**
   * Toggle visibility of the popup for field preview.
   *
   * @param field Field
   * @param fieldExtras FieldExtras
   * @returns void
   */
  toggleFieldPreviewPopupVisibility(field: Field, fieldExtras: FieldExtras): void {
    if (_isUndefined(this.fieldPreviewPopupTarget)) {
      this.fieldPreviewPopupTarget = field;
      this.isFieldPreviewPopupVisible = true;
      this.fieldPreviewPopupTargetExtras = fieldExtras;
    } else {
      if (this.fieldPreviewPopupTarget.fieldName === field.fieldName) {
        this.clearFieldPreviewPopupVariables();
      } else {
        this.fieldPreviewPopupTarget = field;
        this.isFieldPreviewPopupVisible = true;
        this.fieldPreviewPopupTargetExtras = fieldExtras;
      }
    }
  }

  /**
   * Toggles the seasons episodes expansion by changing isSeasonCollapse property and by resetting
   * the episodesMergedData variable.
   *
   * @returns void
   */
  toggleSeasonsEpisodesExpansion(): void {
    this.isSeasonCollapsed = !this.isSeasonCollapsed;
    this.repopulateSeasonsData();
    this.resetEpisodesMergedData();
  }

  /**
   * Populates again the seasons data object looking for changes at flag `isSeasonCollapsed`.
   *
   * @returns void
   */
  repopulateSeasonsData(): void {
    if (this.isSeasonCollapsed) {
      this._seasonsToDisplay = [
        {
          season: {
            seasonNumber: this.currentSeasonNumber
          }
        }
      ];
    } else {
      this._seasonsToDisplay = this.getSeasonNumbers().map(seasonNumber => {
        return {
          season: {
            seasonNumber: seasonNumber
          }
        };
      });
    }
  }

  /**
   * Indicates if all episodes by territory are locked.
   *
   * @param episodes Episode[]
   * @returns boolean
   */
  protected areAllEpisodesLocked(episodes: Episode[]): boolean {
    if (episodes.length === 0) {
      return false;
    } else {
      let current: number = 0;
      let areThey: boolean = true;

      while (areThey && (current < episodes.length)) {
        areThey = (<BooleanField>episodes[current].getField('locked')).isTruthy();
        current++;
      }

      return areThey;
    }
  }

  /**
   * Ensures that a valid current season number is selected, otherwise it reset it.
   *
   * @returns void
   */
  protected ensureValidCurrentSeasonNumber(): void {
    const seasonNumbers: number[] = this.getSeasonNumbers();

    if (seasonNumbers.length > 0) {
      const hasReset: boolean = (
        seasonNumbers
          .filter(
            (seasonNumber: number) => {
              const foundIt: boolean = (seasonNumber === this._currentSeasonNumber);
              return foundIt;
            }
          )
          .length === 0
      );

      if (hasReset) {
        this.setCurrentSeasonNumber(seasonNumbers[0]);
      }
    }
  }

  /**
   * Filters the languages using the locales in the comparison.
   *
   * @returns void
   */
  protected filterLanguages(): void {
    this._filteredLanguages = this.languages.filter(
      language => {
        const foundIt = (
          this.comparison.languages.indexOf(language.entity.localeLanguage) !== -1
        );

        return foundIt;
      }
    );

    if (this._filteredLanguages.length > 0) {
      this.setCurrentLanguage(this._filteredLanguages[0]);
    } else {
      throw new Error('No languages found');
    }
  }

  /**
   * Filters the territories using the locales in the comparison.
   *
   * @returns void
   */
  protected filterTerritories(): void {
    if (this.comparison.hasTerritories()) {
      this._filteredTerritories = this.territories.filter(
        territory => {
          const foundIt = (
            this.comparison.territories.indexOf(territory.entity.iso31661) !== -1
          );

          return foundIt;
        }
      );

      if (this._filteredTerritories.length > 0) {
        this.setCurrentTerritory(this._filteredTerritories[0]);
      } else {
        throw new Error('No territories found');
      }
    } else {
      this._filteredTerritories = new Array();
    }
  }

  /**
   * Returns the sorting criteria of the merged data for episodes.
   *
   * @returns any
   */
  protected getSortingCriteriaForEpisodesMergedData(): any {
    const criteria: CallableFunction = (mergedData1: EpisodeMergedData, mergedData2: EpisodeMergedData) => {
      const firstAirRunningOrder1Value: number = mergedData1.byLanguage.getField('firstAirRunningOrder').currentValue;
      const firstAirRunningOrder2Value: number = mergedData2.byLanguage.getField('firstAirRunningOrder').currentValue;

      if (firstAirRunningOrder1Value > firstAirRunningOrder2Value) {
        return 1;
      } else if (firstAirRunningOrder1Value < firstAirRunningOrder2Value) {
        return -1;
      } else {
        const fnum1Value: number = mergedData1.byLanguage.getField('f0_num').currentValue;
        const fnum2Value: number = mergedData2.byLanguage.getField('f0_num').currentValue;

        if (fnum1Value > fnum2Value) {
          return 1;
        } else if (fnum1Value < fnum2Value) {
          return -1;
        } else {
          return 0;
        }
      }
    };

   return criteria;
 }

  /**
   * Returns the season for the given season number.
   *
   * @param seasonNumber number
   * @returns Season
   */
  protected getSeasonFor(seasonNumber: number): Season {
    const season: Season = this._comparison.titles.getSeason(
      this.currentLanguage.entity.localeLanguage,
      seasonNumber
    );

    return season;
  }

  /**
   * Initializes the Manager.
   *
   * @returns void
   */
  protected initialize(): void {
    super.initialize();
    this.isFieldPreviewPopupVisible = false;
    this.fieldPreviewPopupTarget = undefined;
  }

  /**
   * Reset the episodes merged data.
   *
   * @returns void
   */
  protected resetEpisodesMergedData(): void {
    this.episodeMergedData = new Array();
  }
}
