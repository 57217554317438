import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';

import { isString as _isString } from 'lodash';
import { DecisionEnum } from '../../subtitle/details/decision/decision.enum';
import { SubtitleForm } from '../subtitle-form.model';


export class LocalizedDataForm extends SubtitleForm {
  protected rootIdField: FormControl;
  protected decisionField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  /**
   * Get the root id field.
   *
   * @returns FormControl
   */
  getRootIdField(): FormControl {
    return this.rootIdField;
  }

  /**
   * Get the decision field.
   *
   * @returns FormControl
   */
  getDecisionField(): FormControl {
    return this.decisionField;
  }

  toJson(): any {
    const data: any = super.toJson();
    // TODO Can't use getters due to FormArray transform the current controls and we lost custom getters
    // need implementation of BoltArrayForm.
    data.rootId = this.get('rootId').value;
    data.decision = this.get('decision').value;

    return data;
  }

  protected getNarrativeTitleCustomRequiredValidator(): ValidatorFn {
    const decisionControl: AbstractControl = this.controls['decision'];

    const validator: any = (control: FormControl) => {
      if (
        decisionControl.value === DecisionEnum.USE_TRANSLATED_TEXT.toString() &&
        (
          !_isString(control.value) ||
          (_isString(control.value) && control.value.length === 0)
        )
      ) {
        return  {
          required: true,
          dirty: control.dirty
        };
      }
    };

    return validator;
  }

  protected setupFields() {
    this.setupDecisionField();
    super.setupFields();
    this.setupRootIdField();
  }

  /**
   * Set up the root id field.
   *
   * @returns void
   */
  protected setupRootIdField(): void {
    const defaultValue: boolean = this.getDefaultValue('rootId');
    this.rootIdField = new FormControl(defaultValue, Validators.required);
    this.addControl('rootId', this.rootIdField);
  }

  /**
   * Set up the decision field.
   *
   * @returns void
   */
  protected setupDecisionField(): void {
    const defaultValue: boolean = this.getDefaultValue('decision');
    this.decisionField = new FormControl(defaultValue, Validators.required);
    this.addControl('decision', this.decisionField);
  }
}
