import { Resolution } from '../resolution.model';
import { ResolveIngestDataEnum } from '../resolve-ingest-data/resolve-ingest-data.enum';


export class Decline extends Resolution {
  constructor() {
    super();
    this.initialize();
  }

  asEndpointData(): object {
    const data: object = {
      conflictResolution: {
        resolveIngestData: this._resolveIngestData.type
      }
    };

    return data;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.setupResolveIngestData(ResolveIngestDataEnum.DECLINE);
  }
}
