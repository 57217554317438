import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { HttpError } from '@bolt/ui-shared/common';
import { Observable } from 'rxjs/internal/Observable';
import { capitalize as _capitalize, isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import * as fileSaver from 'file-saver';

import { ExportCandidate } from '../../models/export-candidate/export-candidate.model';
import { ExportFormatEnum } from '../../models/export-format.enum';
import { ExportValueEnum as CreditExportValue } from '../../models/credits/export-value.enum';
import { GroupCriteriaEnum } from '../../models/group-criteria.enum';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { Title, TitleType } from 'app/modules/title/models/title.model';
import { TitleService } from 'app/modules/title/services/title.service';
import { Episode } from 'app/modules/title/models/episode.model';
import { StormServiceResponseCollection, StormServiceResponseCollectionInterface } from 'app/modules/common/services/storm-service-response-collection';
import { ExportLocaleType } from '../../models/export-locale-type.enum';
import { ExportService } from '../../services/export.service';
import { PullingActionHelper } from 'app/shared/helpers/pulling-action/pulling-action.helper';
import { Details as ExportJobDetails } from '../../models/export-job/details/details.model';
import { ExportConfig as CreditsExportConfig } from '../../models/credits/export-config/export-config.model';
import { ExportCounters } from '../../models/export-counters/export-counters.model';
import { ResultReport as ExportResultReport } from '../../models/export-job/result-report/result-report.model';
import { Status as ExportJobStatus } from '../../models/export-job/status/status.model';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';


@Component({
  selector: 'bolt-metadata-export-single-record-commands',
  template: require('./bolt-metadata-export-single-record-commands.html'),
  styles: [require('./bolt-metadata-export-single-record-commands.scss')]
})
export class BoltMetadataExportSingleRecordCommandsComponent implements OnChanges, OnInit {
  @Input() creditsExport: CreditExportValue;
  @Input() locale: Locale;
  @Input() title: Title;
  @Input() showAllTerritories: boolean = false;

  readonly exportFormats: typeof ExportFormatEnum = ExportFormatEnum;
  readonly groupsCriteria: typeof GroupCriteriaEnum = GroupCriteriaEnum;

  candidates: ExportCandidate[];
  exportFormat: ExportFormatEnum;
  groupCriteria: GroupCriteriaEnum | undefined;
  isEidrLevel2Included: boolean;
  isFullSeasonMode: boolean;
  isLocalRunningOrderOverridden: boolean;
  shouldOpenExportHistory: boolean;
  shouldOpenExportPopup: boolean;
  creditsExportOptions: CreditsExportConfig[];
  exportCounters: ExportCounters;
  exportLocaleType = ExportLocaleType;
  territoriesExportInProgress: boolean;
  languagesExportInProgress: boolean;
  svodExportInProgress: boolean;

  protected modulesPath: typeof modulesPath = modulesPath;

  protected pullingAction: PullingActionHelper;
  protected pullingAllLanguagesAction: PullingActionHelper;
  protected pullingSvodAction: PullingActionHelper;
  protected pullingAttempts: number;

  constructor(
    protected layoutHandler: LayoutHandlerService,
    protected notificationService: NotificationService,
    protected titleService: TitleService,
    protected exportService: ExportService
  ) {
    this.reset();
  }

  ngOnInit(): void {
    this.pullingAction = new PullingActionHelper();
    this.pullingAllLanguagesAction = new PullingActionHelper();
    this.pullingSvodAction = new PullingActionHelper();
    this.territoriesExportInProgress = false;
    this.languagesExportInProgress = false;
    this.svodExportInProgress = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.title)) {
      this.reset();
    }
  }

  /**
   * Handles the close event of Export History popup.
   *
   * @returns void
   */
  handleExportHistoryClose(): void {
    this.shouldOpenExportHistory = false;
  }

  /**
   * Handles the close event of Export popup.
   *
   * @returns void
   */
  handleExportPopupClose(): void {
    this.shouldOpenExportPopup = false;
  }

  /**
   * Indicates if it has to block all territory and all language actions.
   *
   * @returns boolean
   */
  hasBlockAllLocaleActions(): boolean {
    const hasIt: boolean =
      !this.layoutHandler.isReading() ||
      _isUndefined(this.title);

    return hasIt;
  }

  hasBlockAllTerritories(): boolean {
    return this.hasBlockAllLocaleActions() || this.territoriesExportInProgress;
  }

  hasBlockAllLanguages(): boolean {
    return this.hasBlockAllLocaleActions() || this.languagesExportInProgress;
  }

  hasBlockSvod(): boolean {
    return this.hasBlockAllLocaleActions() || this.svodExportInProgress;
  }

  /**
   * Indicates if it has to block actions (buttons, checkboxes, radio-buttons and so on).
   *
   * @returns boolean
   */
  hasBlockActions(): boolean {
    const hasIt: boolean =
      !this.layoutHandler.isReading() ||
      _isUndefined(this.title) ||
      _isUndefined(this.locale) ||
      !this.locale.isComplete();

    return hasIt;
  }

  /**
   * Indicates if it has to block all territories or all languages export.
   *
   * @returns boolean
   */
  hasBlockAllOptionsExport(): boolean {
    const hasIt: boolean =
      !this.layoutHandler.isReading() ||
      _isUndefined(this.title);

    return hasIt;
  }

  /**
   * Opens the "Export" popup.
   *
   * @param format ExportFormatEnum
   * @param group GroupCriteriaEnum
   * @returns void
   */
  openExport(format: ExportFormatEnum, group?: GroupCriteriaEnum, exportLocaleType?: ExportLocaleType): void {
    const candidates: ExportCandidate[] = [
      new ExportCandidate(this.title, this.locale)
    ];

    this.exportFormat = format;
    this.groupCriteria = group;

    this.layoutHandler.changeToFetching();

    if (exportLocaleType === ExportLocaleType.regular) {
      if (this.title.isEpisode() && this.isFullSeasonMode) {
        this.fetchEpisodes().subscribe(
          (response: StormServiceResponseCollection) => {
            response.collection.forEach(
              (additionalEpisode: Title) => {
                if (additionalEpisode.id !== this.title.id) {
                  candidates.push(
                    new ExportCandidate(additionalEpisode, this.locale)
                  );
                }
              }
            );

            this.storeAndOpen(candidates);
          },
          (error: HttpError) => {
            this.notificationService.handleError('Failed recovering season episodes.', error);
            this.layoutHandler.changeToReading();
          }
        );
      } else {
        this.storeAndOpen(candidates);
      }
    } else {
      if (this.title.isEpisode() && this.isFullSeasonMode) {
        this.exportFullSeason(candidates, exportLocaleType);
      } else {
        this.export(candidates, exportLocaleType);
      }
    }
  }

  /**
   * Opens the "Export History" modal.
   *
   * @returns void
   */
  openExportHistory(): void {
    this.shouldOpenExportHistory = true;
  }

  /**
   * Resets it.
   *
   * @returns void
   */
  protected reset(): void {
    this.candidates = [];
    this.exportFormat = undefined;
    this.groupCriteria = undefined;
    this.isEidrLevel2Included = false;
    this.isFullSeasonMode = false;
    this.isLocalRunningOrderOverridden = false;
    this.shouldOpenExportHistory = false;
    this.shouldOpenExportPopup = false;
    this.territoriesExportInProgress = false;
    this.languagesExportInProgress = false;
  }

  protected fetchEpisodes(): Observable<StormServiceResponseCollectionInterface> {
    const request: any = {
      titleType: TitleType.episode,
      seasonId: (<Episode>this.title).seasonId,
      locale: '*_*_*_*',
      _size: 5000
    };

    return this.titleService.filterTitles(request);
  }

  /**
   * Stores the given candidates and opens the popup.
   *
   * @param candidates ExportCandidate[]
   * @returns void
   */
  protected storeAndOpen(candidates: ExportCandidate[]): void {
    this.candidates = candidates;
    this.shouldOpenExportPopup = true;

    this.layoutHandler.changeToReading();
  }

  /**
   * Initiates the export process
   *
   * @param candidates ExportCandidate[]
   * @param exportLocaleType ExportLocaleType
   */
  protected export(candidates: ExportCandidate[], exportLocaleType: ExportLocaleType): void {
    this.candidates = candidates;
    this.layoutHandler.changeToReading();

    if (exportLocaleType === ExportLocaleType.regular) {
      this.exportCounters.read(this.candidates);

      this.exportService.startExport(
        this.candidates,
        this.exportFormat,
        this.groupCriteria,
        this.creditsExport,
        this.isEidrLevel2Included,
        this.isLocalRunningOrderOverridden,
        exportLocaleType
      ).subscribe(
        (details: ExportJobDetails) => {
          this.manageExport(details, exportLocaleType);
        },
        (error: HttpError) => {
          this.failExport(error, exportLocaleType);
        }
      );
    } else {
      this.notificationService.handleNotice('The export process has been started.');

      if (exportLocaleType === ExportLocaleType.allLanguages) {
        this.languagesExportInProgress = true;
      } else if (exportLocaleType === ExportLocaleType.allTerritories) {
        this.territoriesExportInProgress = true;
      } else {
        this.svodExportInProgress = true;
      }

      this.exportService.startFullTitleExport(
        this.candidates,
        this.groupCriteria,
        exportLocaleType
      ).subscribe(
        (details: ExportJobDetails) => {
          this.manageExport(details, exportLocaleType);
        },
        (error: HttpError) => {
          this.failExport(error, exportLocaleType);
        }
      );
    }
  }

  /**
   * Export full season for the given parameters
   *
   * @param candidates ExportCandidate[]
   * @param exportLocaleType ExportLocaleType
   */
  protected exportFullSeason(candidates: ExportCandidate[], exportLocaleType: ExportLocaleType) {
    this.candidates = candidates;
    this.layoutHandler.changeToReading();

    this.notificationService.handleNotice('The export process has been started.');

    if (exportLocaleType === ExportLocaleType.allLanguages) {
      this.languagesExportInProgress = true;
    } else if (exportLocaleType === ExportLocaleType.allTerritories) {
      this.territoriesExportInProgress = true;
    } else {
      this.svodExportInProgress = true;
    }

    this.exportService.startFullSeasonExport(
      this.candidates,
      this.groupCriteria,
      exportLocaleType
    ).subscribe(
      (details: ExportJobDetails) => {
        this.manageExport(details, exportLocaleType);
      },
      (error: HttpError) => {
        this.failExport(error, exportLocaleType);
      }
    );
  }

  /**
   * Manages the response of an export job
   *
   * @param details ExportJobDetails
   * @param exportLocaleType ExportLocaleType
   * @returns void
   */
  protected manageExport(details: ExportJobDetails, exportLocaleType: ExportLocaleType): void {
    if (exportLocaleType === ExportLocaleType.allLanguages) {
      this.pullingAllLanguagesAction.set(
        () => this.checkExportStatus(exportLocaleType, details),
        this.pullingAttempts
      );

      this.pullingAllLanguagesAction.execute();
    } else if (exportLocaleType === ExportLocaleType.allTerritories) {
      this.pullingAction.set(
        () => this.checkExportStatus(exportLocaleType, details),
        this.pullingAttempts
      );

      this.pullingAction.execute();
    } else {
      this.pullingSvodAction.set(
        () => this.checkExportStatus(exportLocaleType, details),
        this.pullingAttempts
      );

      this.pullingSvodAction.execute();
    }
  }

  /**
   * Checks the export status.
   *
   * @param exportJobDetails ExportJobDetails
   * @param exportLocaleType ExportLocaleType
   * @returns void
   */
  protected checkExportStatus(exportLocaleType: ExportLocaleType, exportJobDetails: ExportJobDetails): void {
    this.exportService.checkExportStatus(exportJobDetails.id).subscribe(
      (status: ExportJobStatus) => {
        this.processExportStatus(status, exportLocaleType, exportJobDetails);
      },
      (error: HttpError) => {
        this.failExport(error, exportLocaleType);
      }
    );
  }

  /**
   * Fails the export.
   *
   * @param error HttpError
   * @param exportLocaleType ExportLocaleType
   * @returns void
   */
  protected failExport(error: HttpError, exportLocaleType: ExportLocaleType): void {
    if (exportLocaleType === ExportLocaleType.allLanguages) {
      this.pullingAllLanguagesAction.reset();
      this.languagesExportInProgress = false;
    } else if (exportLocaleType === ExportLocaleType.allTerritories) {
      this.pullingAction.reset();
      this.territoriesExportInProgress = false;
    } else {
      this.pullingSvodAction.reset();
      this.svodExportInProgress = false;
    }

    this.layoutHandler.reset();
    this.notificationService.handleError('Failed trying to export metadata', error);
  }

  /**
   * Processes the export status.
   *
   * @param newStatus ExportJobStatus
   * @param exportLocaleType ExportLocaleType
   * @param exportJobDetails ExportJobDetails
   * @returns void
   */
  protected processExportStatus(
    newStatus: ExportJobStatus,
    exportLocaleType: ExportLocaleType,
    exportJobDetails: ExportJobDetails
  ): void {
    exportJobDetails.updateJobStatus(newStatus);

    if (newStatus.isGenerated() || newStatus.isCompleted()) {
      if (exportLocaleType === ExportLocaleType.allLanguages) {
        this.pullingAllLanguagesAction.reset();
      } else if (exportLocaleType === ExportLocaleType.allTerritories) {
        this.pullingAction.reset();
      } else {
        this.pullingSvodAction.reset();
      }

      this.exportService.fetchExportResultReport(exportJobDetails.id).subscribe(
        (resultReport: ExportResultReport) => {
          this.downloadFile(
            resultReport.exported[0].id,
            resultReport.exported[0].fileName,
            resultReport.exportType as ExportFormatEnum,
            true,
            exportLocaleType
          );
        },
        (error: HttpError) => {
          this.failExport(error, exportLocaleType);
        }
      );

    } else if (newStatus.isError()) {
      this.failExport(newStatus.error, exportLocaleType);
    }

    try {
      if (exportLocaleType === ExportLocaleType.allLanguages) {
        if (!this.pullingAllLanguagesAction.wasReset()) {
          this.pullingAllLanguagesAction.execute();
        }
      } else if (exportLocaleType === ExportLocaleType.allTerritories) {
        if (!this.pullingAction.wasReset()) {
          this.pullingAction.execute();
        }
      } else {
        if (!this.pullingSvodAction.wasReset()) {
          this.pullingSvodAction.execute();
        }
      }
    } catch (error) {
      this.failExport(error, exportLocaleType);
    }
  }

  /**
   * Downloads the file for the given parameters.
   *
   * @param jobId number
   * @param fileName string
   * @param fileFormat ExportFormatEnum
   * @param isConsolidated boolean
   * @returns void
   */
  protected downloadFile(
    jobId: number,
    fileName: string,
    fileFormat: ExportFormatEnum,
    isConsolidated: boolean,
    exportLocaleType: ExportLocaleType
  ): void {
    this.exportService.downloadFile(jobId, fileFormat, isConsolidated).subscribe(
      (data: Blob) => {
        fileSaver.saveAs(data, fileName);
        if (exportLocaleType === ExportLocaleType.allLanguages) {
          this.languagesExportInProgress = false;
        } else if (exportLocaleType === ExportLocaleType.allTerritories) {
          this.territoriesExportInProgress = false;
        } else {
          this.svodExportInProgress = false;
        }

        this.notificationService.handleSuccess('Download File', `${fileName} was downloaded successfully.`);
      },
      (error: HttpError) => {
        this.notificationService.handleError('Failed trying to download the file.', error);
      }
    );
  }
}
