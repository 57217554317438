import { HttpError } from '@bolt/ui-shared/common';

/**
 * @deprecated 2021-05-07
 * @todo TBD the plan of use `HttpError` instead of `ErrorHelper` (shared library).
 */
export class ErrorHelper extends HttpError {
  constructor(message: string, statusCode?: number, statusDetails?: string) {
    super(message, statusCode, statusDetails);
  }
}
