import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { ProximityItem } from '@bolt/ui-shared/droplists';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isArray as _isArray, isNumber as _isNumber, isObject as _isObject } from 'lodash';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { CrudActionEnum } from 'app/shared/services/layout-handler/crud-action.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { LocksRecoverService } from '../../services/entity/locks-recover/locks-recover.service';
import { ManagerService } from '../../services/project/manager/manager.service';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { Project } from '../../models/project/project.model';
import { UrlHandlerService } from '../../services/url-handler/url-handler.service';


@Component({
  selector: 'bolt-project-list',
  template: require('./bolt-project-list.html'),
  styles: [require('./bolt-project-list.scss')]
})
export class BoltProjectListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('projects')
  protected projectsSelect: QueryList<ElementRef>;

  protected currentProject: number;
  protected errorFlag: boolean;
  protected errorReason: string;
  protected layoutChangeListener: Subscription;
  protected projectsChangeListener: Subscription;
  protected projectOptions: ProximityItem[];

  constructor(
    protected appConfig: AppConfigProvider,
    protected layoutHandler: LayoutHandlerService,
    protected locksRecover: LocksRecoverService,
    protected notificationService: NotificationService,
    protected projectManager: ManagerService,
    protected roleCapabilities: CapabilitiesManager,
    protected urlHandler: UrlHandlerService
  ) {
    this.projectOptions = new Array();
    this.urlHandler.start(
      () => {
        this.layoutHandler.reset();
        this.updateProjectSelection();
      }
    );
  }

  ngAfterViewInit() {
    this.projectsSelect.changes.pipe(debounceTime(0)).subscribe(
      () => {
        this.updateProjectSelection();
      }
    );
  }

  ngOnDestroy() {
    this.urlHandler.stop();
    this.unsubscribeLayoutChange();
    this.unsubscribeProjectsChange();
  }

  ngOnInit() {
    this.listenLayoutChange();
    this.listenProjectsChange();
    this.refresh(false);
  }

  /**
   * Fetches the projects.
   *
   * @returns void
   */
  protected fetchProjects(): void {
    this.turnOffErrorFlag();

    this.projectManager.fetchProjects(
      () => {
        this.layoutHandler.changeToReading();
      },
      (error: ErrorHelper) => {
        this.turnOnErrorFlag('Failed trying to fetch projects.');
        this.layoutHandler.changeToReading();
        this.notificationService.handleError(this.errorReason, error, notificationsContainer.projectDashboard.key);
      }
    );
  }

  /**
   * Changes the layout handler status to creating.
   *
   * @returns void
   */
  protected forceCreatingStatus(): void {
    // Needed hack for refreshing the action.
    setTimeout(
      () => {
        this.layoutHandler.changeToCreating();
      }
    );
  }

  /**
   * Maps and sets every project in project manager and gets a proximityItem instance.
   *
   * @returns void
   */
  protected setProjectsOptions(): void {
    const options: ProximityItem[] = [];

    this.projectManager.projects.map(
      (project: Project) => {
        const item = new ProximityItem(project.name, project.id);

        options.push(item);
      }
    );

    this.projectOptions = options;
  }

  /**
   * Indicates if it has to block the new project button.
   *
   * @returns boolean
   */
  protected hasBlockNewProject(): boolean {
    return !this.layoutHandler.isReading();
  }

  /**
   * Indicates if it has to block the project selection.
   *
   * @returns boolean
   */
  protected hasBlockProjectSelection(): boolean {
    const hasIt: boolean = (!this.layoutHandler.isReading() || !this.projectManager.hasProjects());
    return hasIt;
  }

  /**
   * Indicates if it has to block the refresh button.
   *
   * @returns boolean
   */
  protected hasBlockRefresh(): boolean {
    const hasIt: boolean = (this.hasDisplayLoading() || !this.layoutHandler.isReading());
    return hasIt;
  }

  /**
   * Indicates if it has to display the loading.
   *
   * @returns boolean
   */
  protected hasDisplayLoading(): boolean {
    return this.layoutHandler.isFetching();
  }

  /**
   * Indicates if it has to display the new project button.
   *
   * @returns boolean
   */
  protected hasDisplayNewProject(): boolean {
    const hasIt: boolean = this.roleCapabilities.hasUserPrivilegeOn(
      modulesPath.projectDashboard.path,
      [ActionTypeEnum.write]
    );

    return hasIt;
  }

  /**
   * Indicates if it has projects.
   *
   * @returns boolean
   */
  protected hasProjects(): boolean {
    return this.projectManager.hasProjects();
  }

  /**
   * Indicates if there is any error.
   *
   * @returns boolean
   */
  protected isThereAnyError(): boolean {
    return this.errorFlag;
  }

  /**
   * Listens any change in LayoutHandler.
   *
   * @returns void
   */
  protected listenLayoutChange(): void {
    this.layoutChangeListener = this.layoutHandler.actionListener.subscribe(
      (currentAction: CrudActionEnum) => {
        if (currentAction === CrudActionEnum.FETCHING) {
          this.fetchProjects();
        }
      }
    );
  }

  /**
   * Listens projects change in projectManager.
   *
   * @returns void
   */
  protected listenProjectsChange(): void {
    this.projectsChangeListener = this.projectManager.projectsListener.subscribe(
      () => { this.setProjectsOptions(); }
    );
  }

  /**
   * Starts the new project process.
   *
   * @returns void
   */
  protected newProject(): void {
    this.projectManager.cleanDefaults();
    this.urlHandler.clearUrl();
    this.forceCreatingStatus();
  }

  /**
   * Refreshes it.
   *
   * @returns void
   */
  protected refresh(shouldResetUrl: boolean): void {
    this.layoutHandler.changeToFetching();

    if (shouldResetUrl) {
      this.urlHandler.clearUrl();
    }
  }

  /**
   * Selects the project with the given ID.
   *
   * @param id number
   * @returns void
   */
  protected selectProject(id: number): void {
    this.locksRecover.reset();

    if (_isNumber(id)) {
      this.urlHandler.updateUrl(id);
    } else {
      this.urlHandler.clearUrl();
    }
  }

  /**
   * Turns off the error flag.
   *
   * @returns void
   */
  protected turnOffErrorFlag(): void {
    this.errorFlag = false;
    this.errorReason = undefined;
  }

  /**
   * Turns on the error flag with the given reason.
   *
   * @param reason string
   * @returns void
   */
  protected turnOnErrorFlag(reason: string): void {
    this.errorFlag = true;
    this.errorReason = reason;
  }

  /**
   * Unsubscribes from layout change, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeLayoutChange(): void {
    if (_isObject(this.layoutChangeListener.unsubscribe())) {
      this.layoutChangeListener.unsubscribe();
    }
  }

  /**
   * Unsubscribes from projects change, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeProjectsChange(): void {
    if (_isObject(this.projectsChangeListener.unsubscribe())) {
      this.projectsChangeListener.unsubscribe();
    }
  }

  /**
   * Updates the project selection, using the ID in URL.
   *
   * @returns void
   */
  protected updateProjectSelection(): void {
    if (this.hasProjects()) {
      try {
        this.projectManager.setCurrentProject(this.urlHandler.projectId);

        if (this.projectManager.hasCurrentProject()) {
          this.projectOptions.forEach(
            (item: ProximityItem) => {
              if (item.value === this.projectManager.currentProject.id) {
                this.currentProject = item.value;
              }
            }
          );

          return;
        }
      } catch (error) {
        this.notificationService.handleError('Failed selecting project.', error, notificationsContainer.projectDashboard.key);
      }
    }

    this.currentProject = undefined;
  }
}
