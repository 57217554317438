import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { TitleType } from '../models/title.model';
import { EpisodeMetadata } from '../models/episode-metadata.model';
import { FeatureMetadata } from '../models/feature-metadata.model';
import { SeasonMetadata } from '../models/season-metadata.model';
import { SeriesMetadata } from '../models/series-metadata.model';

@Injectable()
export class TitleLocalizationService extends BoltAbstractService {
  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService,
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Deletes an specific locale with the given data.
   *
   * @param productType TitleType
   * @param titleId number
   * @param locale string
   * @returns Observable<any>
   */
  deleteLocale(productType: TitleType, titleId: number, locale: string): Observable<any> {
    const params: any = {
      '{productType}': productType,
      '{titleId}': titleId,
      '{locale}': locale
    };

    const url: string = this.generateUrl('productService.deleteMetadataLocale.endpoint', params);

    const obs = this.authHttp.delete(url).pipe(map((response: any) => {
      let title: any;
      switch (response.titleType) {
        case TitleType.episode:
          title = new EpisodeMetadata(response);
          break;
        case TitleType.feature:
          title = new FeatureMetadata(response);
          break;
        case TitleType.season:
          title = new SeasonMetadata(response);
          break;
        case TitleType.series:
          title = new SeriesMetadata(response);
          break;
        default:
          break;
      }
      return title;
    }));

    return obs;

  }

}
