import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Country, StormListItemInterface, StormListsInterface, StormListType } from '@bolt/ui-shared/master-data';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isUndefined as _isUndefined, isEmpty as _isEmpty } from 'lodash';

import { CollectionManagerCollectionInterface, CollectionManagerHelper } from 'app/modules/common/helpers/collection-manager.helper';
import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { Localization } from '../../models/localization.model';
import { LocalizedInterface } from 'app/modules/common/models/localized.model';
import { ManagerService } from '../../services/manager/manager.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { SubtitleTypeEnum } from '../../models/subtitle/details/subtitle-type/subtitle-type.enum';


@Component({
  selector: 'bolt-subtitle-localizations',
  template: require('./bolt-subtitle-localizations.html'),
  styles: [ require('./bolt-subtitle-localizations.scss') ]
})
export class BoltSubtitleLocalizationsComponent extends StormComponent implements OnInit, OnDestroy {
  protected boltStore: any = {
    filterFormDefaultValues: {
      filter: '',
      language: undefined,
      territory: 0,
      subtitleType: SubtitleTypeEnum.all
    },
    translations: {
      clearFilterLabel: `Disney's clear filter button label`,
      filterByLabel: `Disney's filter by input label`,
      languageHeaderLabel: `Disney's language header label`,
      territoryHeaderLabel: `Disney's territory header label`,
    }
  };

  protected originalVersionStatusTriggeredSubscription: Subscription = null;
  protected subtitlesManagerStatusTriggeredSubscription: Subscription = null;

  protected filter: string = '';
  protected filterLocalizationForm: FormGroup;
  protected languages: SelectionItem[] = [];
  protected localizationsCollectionName: string = '';
  protected localizationsCollection: CollectionManagerCollectionInterface;
  protected territories: SelectionItem[] = [];

  constructor(
    protected appConfig: AppConfigProvider,
    protected collectionManager: CollectionManagerHelper,
    protected formBuilder: FormBuilder,
    protected subtitlesManager: ManagerService,
  ) {
    super();
    this.populateTranslations();

    this.localizationsCollectionName = this.subtitlesManager.localizationsCollectionName;
  }

  ngOnDestroy() {
    if (this.originalVersionStatusTriggeredSubscription) {
      this.originalVersionStatusTriggeredSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.initialize();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.changeStatusToIdle();
    this.setupBoltStore();
    this.initFilterForm();
    this.initLanguagesAndTerritoriesLists();
    this.initCollection();
    this.initOnFiltering();
  }

  /**
   * Set up the BoltStore for containing UI basic data like translations.
   *
   * @returns void
   */
  protected setupBoltStore(): void {
    this.populateTranslations();
  }

  /**
   * Populate translations with default language until i18n will be implemented
   *
   * @returns void
   */
  protected populateTranslations(): void {
    this.boltStore.translations.clearFilterLabel = `clear filter`;
    this.boltStore.translations.filterByLabel = `Filter by`;
    this.boltStore.translations.languageHeaderLabel = `Language`;
    this.boltStore.translations.territoryHeaderLabel = `Territory`;
  }

  /**
   * Initialize the collection for locales.
   *
   * @returns void
   */
  protected initCollection(): void {
    this.changeStatusToIdle();
    this.subtitlesManager.resetLocalizationsCollection();

    this.subtitlesManagerStatusTriggeredSubscription = this.subtitlesManager.subtitlesManagerStatusTriggered.subscribe(
      (subtitlesManagerStatus) => {
        switch (subtitlesManagerStatus) {
          case DataStatusEnum.fetchingData:
            this.changeStatusToFetchingData();
            break;
          case DataStatusEnum.dataFound:
            this.changeStatusToDataFound();
            this.subscribeToOriginalVersionStatusChanges();
            break;
          default:
            break;
        }
      }
    );

    this.collectionManager.getCollectionHandler().subscribe(
      localizations => {
        if (localizations && localizations.name === this.localizationsCollectionName) {
          this.localizationsCollection = localizations;
        }
      }
    );
  }

  /**
   * Subscribe to changes on the Original Version Status.
   *
   * @returns void
   */
  protected subscribeToOriginalVersionStatusChanges(): void {
    this.originalVersionStatusTriggeredSubscription = this.subtitlesManager.originalVersionStatusTriggered.subscribe(
      (originalVersionStatus: DataStatusEnum) => {
        if (originalVersionStatus) {
          switch (originalVersionStatus) {
            case DataStatusEnum.fetchingData:
              this.changeStatusToFetchingData();
            break;
            case DataStatusEnum.dataFound:
              this.changeStatusToDataFound();
              this.subtitlesManager.resetLocalizationsCollection();

              const localizations: Localization[] = this.subtitlesManager.originalVersion?.localizations || [];

              if (localizations.length > 0) {
                this.subtitlesManager.addRawLocalesToLocalizationsCollection(localizations).then(
                  () => this.ensureSelectedLocalizationIsStillValid()
                );
              } else {
                this.subtitlesManager.resetSelectedLocalization();
              }
            break;
            default:
            break;
          }
        }
      }
    );
  }

  /**
   * Ensure that the selected localization is still present at the localizations list.
   *
   * @returns void
   */
  protected ensureSelectedLocalizationIsStillValid(): void {
    if (this.subtitlesManager.hasOriginalVersionByComplete()) {
      const originalVersionAuxiliary = this.subtitlesManager.originalVersion;
      const localizations = originalVersionAuxiliary.localizations;
      const locales: string[] = localizations.map(localization => localization.locale) || [];
      const findResults = locales.findIndex(
        (locale) => {
          return this.subtitlesManager.selectedLocalization === locale;
        }
      );

      const shouldReset = findResults === -1;

      if (shouldReset) {
        this.subtitlesManager.resetSelectedLocalization();
      }
    }
  }

  /**
   * Initialize the filter form controller driven.
   *
   * @returns void
   */
  protected initFilterForm(): void {
    this.filterLocalizationForm = this.formBuilder.group({
      filter: [
        this.boltStore.filterFormDefaultValues.filter
      ],
      language: [
        this.boltStore.filterFormDefaultValues.language,
        Validators.compose([
          Validators.required
        ])
      ],
      territory: [
        this.boltStore.filterFormDefaultValues.territory,
        Validators.compose([
          Validators.required
        ])
      ],
    });
  }

  /**
   * Initialize the languages and territories lists for the creation of new locales
   * at locales list.
   *
   * @returns void
   */
  protected initLanguagesAndTerritoriesLists(): void {
    this.subtitlesManager.getStormLists().subscribe(
      (stormLists: StormListsInterface) => {
        // language
        if (!this.languages.length) {
          const languageList = stormLists.getList(StormListType.language);

          languageList.removeItem({ id: 0 }).collection.forEach(
            (language: StormListItemInterface, index: number) => {
              this.languages.push(new SelectionItem(language.value.name, language.id));
            }
          );
        }

        // territory
        if (!this.territories.length) {
          const territoryList = stormLists.getList(StormListType.territory);

          territoryList.setFirstItem({ id: 0 });

          territoryList.collection.forEach(
            (territory: StormListItemInterface, index: number) => {
              this.territories.push(
                new SelectionItem(
                  (<Country>territory.value).name,
                  (<Country>territory.value).id,
                  territory.value,
                  (<Country>territory.value).region
                )
              );
            }
          );
        }
      }
    );
  }

  /**
   * Initialize subscription to inputs at the filter field from the filter form.
   *
   * @returns void
   */
  protected initOnFiltering(): void {
    this.filterLocalizationForm.get('filter').valueChanges.pipe(debounceTime(200)).subscribe(
      value => {
        const filters = value ? value.trim().split(' ') : [];
        this.collectionManager.filterBy(this.localizationsCollectionName, filters, [
          'language',
          'territory',
        ]);
      }
    );
  }

  /**
   * Reset the value of the filter field from the filter form to empty it.
   *
   * @returns void
   */
  protected resetFilter(): void {
    this.filterLocalizationForm.get('filter').setValue('');
  }

  /**
   * Load all the locales again to refresh the locales list.
   *
   * @returns void
   */
  protected loadLocales(): void {
    this.collectionManager.refreshCollection(this.localizationsCollectionName);
  }

  /**
   * Add a new locale to subtitles and inserts collection using charged
   * data at language and territory fields from the filter form.
   *
   * @returns void
   */
  protected addLocale(): void {
    this.subtitlesManager.addLocaleToLocalizationsCollection(
      this.filterLocalizationForm.get('language').value,
      this.filterLocalizationForm.get('territory').value
    ).then((wasAdded) => {
      if (wasAdded) {
        this.filterLocalizationForm.reset({
          territory: this.boltStore.filterFormDefaultValues.territory
        });
      }
    });
  }

  /**
   * Select a locale from the subtitle and inserts to reflect it as active on
   * related components.
   *
   * @param localizedEntity LocalizedInterface
   * @returns void
   */
  protected selectLocalization(localizedEntity: LocalizedInterface): void {
    this.subtitlesManager.setSelectedLocalization(localizedEntity.locale);
  }

  /**
   * Check if the filter can be shown
   *
   * @param localizedEntity LocalizedInterface
   * @returns boolean
   */
  protected showFilter(): boolean {
    return !_isEmpty(this.filterLocalizationForm);
  }

  /**
   * Check if all needed preconditions to add locale are meets.
   *
   * @returns boolean
   */
  protected canAddLocale(): boolean {
    const canIt = this.filterLocalizationForm.valid
      && this.subtitlesManager.originalVersionStatus === DataStatusEnum.dataFound;
    return canIt;
  }

  /**
   * Check if all needed preconditions to show localizations list are meets.
   *
   * @returns boolean
   */
  protected canShowLocalizationsList(): boolean {
    const canIt = (
      this.isDataFound() &&
      !_isUndefined(this.localizationsCollection) &&
      !_isUndefined(this.localizationsCollection.collection) &&
      (this.localizationsCollection.collection.length > 0)
    );

    return canIt;
  }
}
