import { isString as _isString, isUndefined as _isUndefined } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Type } from './type/type.model';
import { TypeEnum } from './type/type.enum';


export class Candidate {
  private name: string;
  private index: number;
  private key: string;
  private object: any;
  private ready: boolean;
  private type: Type;

  constructor(name: string, type: TypeEnum, index: number) {
    this.setName(name);
    this.key = `${type}-${name}`;
    this.type = new Type(type);
    this.ready = false;
    this.index = index;
  }

  /**
   * Get the name attribute.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Get the object attribute.
   *
   * @returns any
   */
  getObject(): any {
    return this.object;
  }

  /**
   * Get the index attribute.
   *
   * @returns number
   */
  getIndex(): number {
    return this.index;
  }

  /**
   * Get the key attribute.
   *
   * @returns string
   */
   getKey(): string {
    return this.key;
  }

  /**
   * Set the object attribute.
   *
   * @param value any
   * @returns void
   */
  setObject(value: any): void {
    this.object = value;
  }

  /**
   * Get the type attribute.
   *
   * @returns Type
   */
  getType(): Type {
    return this.type;
  }

  /**
   * Set the current candidate as ready.
   *
   * @returns void
   */
  setAsReady(): void {
    this.ready = true;
  }

  /**
   * Set the current candidate as not ready.
   *
   * @returns void
   */
  setAsNotReady(): void {
    this.ready = false;
  }

  /**
   * Indicates if the current candidate has to create a new object.
   *
   * @returns boolean
   */
  hasToCreate(): boolean {
    const itHas: boolean = this.ready && _isUndefined(this.object);
    return itHas;
  }

  /**
   * Indicates if the current candidate is ready.
   *
   * @returns boolean
   */
  isReady(): boolean {
    return this.ready;
  }

  /**
   * Set the current name.
   *
   * @throws ErrorHelper
   * @returns void
   */
  private setName(value: string): void {
    if (_isString(value) && value.length > 0) {
      this.name = value;
    } else {
      throw new ErrorHelper('Invalid value given for a candidate name.');
    }
  }
}
