import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Attribute, FunctionalMetadataService, LevelModeEnum } from '@bolt/ui-shared/title';
import { isUndefined as _isUndefined } from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';

import { EpisodeForm } from 'app/modules/title/models/level-data/form/episode/episode-form.model';
import { FeatureForm } from 'app/modules/title/models/level-data/form/feature/feature-form.model';
import { SeasonForm } from 'app/modules/title/models/level-data/form/season/season-form.model';
import { SeriesForm } from 'app/modules/title/models/level-data/form/series/series-form.model';
import { Episode } from 'app/modules/title/models/episode.model';
import { Feature } from 'app/modules/title/models/feature.model';
import { Season } from 'app/modules/title/models/season.model';
import { Series } from 'app/modules/title/models/series.model';
import { TitleForm } from 'app/modules/title/models/level-data/form/title-form.model';

@Injectable()
export class FormHandlerService {
  formListener: Observable<TitleForm>;

  protected fieldSpecs: any;
  protected form: TitleForm;
  protected formNotifier: BehaviorSubject<TitleForm>;

  constructor(protected appConfig: AppConfigProvider, protected functionalMetadataService: FunctionalMetadataService) {
    this.fieldSpecs = {
      copyright: {
        maxLength: this.appConfig.get('forms.titleLevelData.fields.copyright.maxLength')
      },
      originalTitle: {
        maxLength: this.appConfig.get('forms.titleLevelData.fields.originalTitle.maxLength')
      }
    };

    this.formNotifier = new BehaviorSubject(undefined);
    this.formListener = this.formNotifier.asObservable();
  }

  /**
   * Creates an Episode level data form.
   *
   * @param episode Episode
   * @returns void
   */
  createEpisodeForm(episode: Episode): void {
    const defaultValues: any = episode.getRawObject();
    this.groupFunctionalMetadataSplitFields(defaultValues, LevelModeEnum.title);

    this.form = new EpisodeForm(this.fieldSpecs, defaultValues);
    this.notifyChange();
  }

  /**
   * Creates a Feature level data form.
   *
   * @param feature Feature
   * @returns void
   */
  createFeatureForm(feature: Feature): void {
    const defaultValues: any = feature.getRawObject();
    this.groupFunctionalMetadataSplitFields(defaultValues, LevelModeEnum.title);

    this.form = new FeatureForm(this.fieldSpecs, defaultValues);
    this.notifyChange();
  }

  /**
   * Creates a Season level data form.
   *
   * @param season Season
   * @returns void
   */
  createSeasonForm(season: Season): void {
    this.form = new SeasonForm(this.fieldSpecs, season.getRawObject());
    this.notifyChange();
  }

  /**
   * Creates a Series level data form.
   *
   * @param series Series
   * @returns void
   */
  createSeriesForm(series: Series): void {
    this.form = new SeriesForm(this.fieldSpecs, series.getRawObject());
    this.notifyChange();
  }

  /**
   * Groups the Functional Metadata Fields in the given raw data, using the Level Mode.
   * This method alters the given raw data.
   *
   * @param rawData any
   * @param levelMode LevelModeEnum
   * @returns void
   */
  groupFunctionalMetadataSplitFields(rawData: any, levelMode: LevelModeEnum): void {
    const functionalMetadataSplitFields = { };

    this.functionalMetadataService.getAttributesByLevel(levelMode).forEach(
      (attribute: Attribute) => {
        functionalMetadataSplitFields[attribute.getName()] = _isUndefined(rawData[attribute.getName()])
          ? null
          : rawData[attribute.getName()];
      }
    );

    rawData.functionalMetadataSplitFields = functionalMetadataSplitFields;
  }

  /**
   * Resets the current form value.
   *
   * @returns void
   */
  reset(): void {
    this.form = undefined;
    this.notifyChange();
  }

  /**
   * Notifies the current form change.
   *
   * @returns void
   */
  protected notifyChange(): void {
    this.formNotifier.next(this.form);
  }
}
