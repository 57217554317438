import { Credit } from 'app/modules/credits/models/credit/credit.model';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { Cast } from '../../models/cast/cast.model';
import { Crew } from '../../models/crew/crew.model';

export function CreditFactory(
  creditPositionType: CreditPositionType,
  attributes?: { [attr: string]: any }
): Credit {

  let creditEntity: Credit;

  switch (true) {
    case creditPositionType === CreditPositionType.CAST:
      creditEntity = new Cast(attributes);
      break;

    case creditPositionType === CreditPositionType.CREW:
      creditEntity = new Crew(attributes);
      break;

    default:
      throw new Error('CreditFactory :: invalid CreditPositionType.' + creditPositionType);
  }

  return creditEntity;
}
