import { FormControl, Validators } from '@angular/forms';
import { Account, Country, Language, ProductType } from '@bolt/ui-shared/master-data';
import { isNumber as _isNumber } from 'lodash';

import { Locale } from '../locale.model';
import { BoltForm } from '../../form/bolt-form.model';


export class LocaleForm extends BoltForm {
  protected accountField: FormControl;
  protected languageField: FormControl;
  protected productTypeField: FormControl;
  protected territoryField: FormControl;
  protected locale: Locale;

  constructor(locale: Locale) {
    super({ });
    this.locale = locale;
    this.setupFields();
  }

  getAccountField(): FormControl {
    return this.accountField;
  }

  getLanguageField(): FormControl {
    return this.languageField;
  }

  getProductTypeField(): FormControl {
    return this.productTypeField;
  }

  getTerritoryField(): FormControl {
    return this.territoryField;
  }

  toJson(): any {
    const data: any = {
      language: this.getLanguageField().value,
      territory: this.getTerritoryField().value,
      productType: this.getProductTypeField().value,
      account: this.getAccountField().value
    };

    return data;
  }

  /**
   * Gets the account ids of the current locale.
   *
   * @returns number[]
   */
  protected getAccountIds(): number[] {
    const accountIds: number[] = new Array();

    this.locale.account.forEach(
      (account: Account | number) => {
        if (_isNumber(account)) {
          accountIds.push(<number>account);
        } else {
          accountIds.push((<Account>account).id);
        }
      }
    );

    return accountIds;
  }

  /**
   * Gets the language id of the current locale.
   *
   * @returns number
   */
  protected getLanguageId(): number {
    const language: Language | number = this.locale.language;

    if (_isNumber(language)) {
      return <number>language;
    } else {
      return (<Language>language).id;
    }
  }

  /**
   * Gets the product type ids of the current locale.
   *
   * @returns number[]
   */
  protected getProductTypeIds(): number[] {
    const productTypeIds: number[] = new Array();

    this.locale.productType.forEach(
      (productType: ProductType | number) => {
        if (_isNumber(productType)) {
          productTypeIds.push(<number>productType);
        } else {
          productTypeIds.push((<ProductType>productType).id);
        }
      }
    );

    return productTypeIds;
  }

  /**
   * Gets the territory IDs of the current locale.
   *
   * @returns number[]
   */
  protected getTerritoryIds(): number[] {
    const territoryIds: number[] = new Array();

    this.locale.territory.forEach(
      (territory: Country | number) => {
        if (_isNumber(territory)) {
          territoryIds.push(<number>territory);
        } else {
          territoryIds.push((<Country>territory).id);
        }
      }
    );

    return territoryIds;
  }

  protected setupFields(): void {
    this.setupAccountField();
    this.setupLanguageField();
    this.setupProductTypeField();
    this.setupTerritoryField();
  }

  /**
   * Set up the account control.
   *
   * @returns void
   */
  protected setupAccountField(): void {
    this.accountField = new FormControl(this.getAccountIds(), Validators.required);
    this.addControl('account', this.accountField);
  }

  /**
   * Set up the language control.
   *
   * @returns void
   */
  protected setupLanguageField(): void {
    this.languageField = new FormControl(this.getLanguageId(), Validators.required);

    if (!this.locale.type.isLanguage()) {
      this.languageField.disable();
    }

    this.addControl('language', this.languageField);
  }

  /**
   * Set up the product type control.
   *
   * @returns void
   */
  protected setupProductTypeField(): void {
    this.productTypeField = new FormControl(this.getProductTypeIds(), Validators.required);

    if (this.locale.type.isAccount()) {
      this.productTypeField.disable();
    }

    this.addControl('productType', this.productTypeField);
  }

  /**
   * Set up the territory control.
   *
   * @returns void
   */
  protected setupTerritoryField(): void {
    this.territoryField = new FormControl(this.getTerritoryIds(), Validators.required);

    if (this.locale.type.isAccount()) {
      this.territoryField.disable();
    }

    this.addControl('territory', this.territoryField);
  }
}
