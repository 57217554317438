import { Component, Input, OnDestroy, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { sortBy as _sortBy, isObject as _isObject } from 'lodash';

import { CreditLocale } from 'app/modules/credits/models/credit/credit-locale.model';
import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { Episode } from 'app/modules/title/models/episode.model';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Season } from 'app/modules/title/models/season.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleService } from 'app/modules/title/services/title.service';
import { TitleType } from 'app/modules/title/models/title.model';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';


@Component({
  selector: 'bolt-clone-credits-episode-list',
  template: require('./bolt-clone-credits-episode-list.html'),
  styles: [require('./bolt-clone-credits-episode-list.scss')]
})
export class BoltCloneCreditsEpisodeListComponent extends StormComponent implements OnDestroy, OnInit  {
  @Input() creditLocale: CreditLocale;
  @Input() creditPosition: CreditPositionType;
  @Input() creditType: CreditType;
  @Input() title: Episode;

  @Output('selected') protected selectEvent: EventEmitter<Episode | Season>;

  protected cloneForm: FormGroup;
  protected errorFlag: boolean;
  protected readonly errorStatus: DataStatusEnum = DataStatusEnum.error;
  protected optionsSubscription: Subscription;
  protected seasonOption: Season;
  protected seasonSubscription: Subscription;
  protected episodeOptions: Episode[];

  constructor(
    protected formBuilder: FormBuilder,
    protected layoutHandler: LayoutHandlerService,
    protected titleService: TitleService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.fetchEpisodeOptions();
  }

  ngOnDestroy() {
    this.cancelSubscriptions();
    this.reset();
  }

  /**
   * Applies the filter on the current options.
   *
   * @returns void
   */
  protected applyFilter(): void {
    this.episodeOptions = _sortBy(this.episodeOptions, 'f0_num');

    this.episodeOptions = this.episodeOptions.filter(
      (episode: Episode) => episode.id !== this.title.id
    );
  }

  /**
   * Cancels the current subscriptions.
   *
   * @returns void
   */
  protected cancelSubscriptions(): void {
    if (_isObject(this.optionsSubscription)) {
      this.optionsSubscription.unsubscribe();
    }

    if (_isObject(this.seasonSubscription)) {
      this.seasonSubscription.unsubscribe();
    }
  }

  /**
   * Fetches the episodes for the current season option.
   *
   * @returns void
   */
  protected fetchAllEpisodesOfSeason(): void {
    if (this.seasonOption.numberOfEpisodes > 0) {
      const data: any = {
        titleType: TitleType.episode,
        seasonId: this.seasonOption.id,
        locale: '*_*_*_*',
        _size: 5000
      };

      this.optionsSubscription = this.titleService.filterTitles(data).subscribe(
        (response: StormServiceResponseCollection) => {
          response.collection.forEach(
            (episode: Episode) => {
              this.episodeOptions.push(episode);
            }
          );

          this.applyFilter();
          this.layoutHandler.changeToReading();
          this.changeStatusToDataFound();
        },
        error => {
          this.layoutHandler.changeToReading();
          this.changeStatusToDataFound();
          this.errorFlag = true;
        }
      );
    } else {
      this.layoutHandler.changeToReading();
      this.changeStatusToDataFound();
    }
  }

  /**
   * Fetches the season and episodes for the current episode.
   *
   * @returns void
   */
  protected fetchEpisodeOptions(): void {
    this.layoutHandler.changeToFetching();
    this.changeStatusToFetchingData();

    this.seasonSubscription = this.titleService.fetchProduct({
      productId: this.title.seasonId,
      productType: TitleType.season
    }).subscribe(
      (response: StormServiceResponseSingle) => {
        this.seasonOption = response.item;
        this.episodeOptions = new Array();

        this.setDefaultSelection();
        this.fetchAllEpisodesOfSeason();
      },
      () => {
        this.changeStatusToError();
      }
    );
  }

  /**
   * Indicates if it has to display the empty message.
   *
   * @returns boolean
   */
  protected hasDisplayEmptyMessage(): boolean {
    const hasIt: boolean = !this.hasTitleOptions() && !this.hasErrorTryingToFetchEpisodes();
    return hasIt;
  }

  /**
   * Indicates if it failed trying to fetch the episodes.
   *
   * @returns boolean
   */
  protected hasErrorTryingToFetchEpisodes(): boolean {
    const hasIt: boolean = !this.hasTitleOptions() && this.errorFlag;
    return hasIt;
  }

  /**
   * Indicates if it has title options.
   *
   * @returns boolean
   */
  protected hasTitleOptions(): boolean {
    const hasIt: boolean = this.episodeOptions.length > 0;
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.errorFlag = false;
    this.selectEvent = new EventEmitter();
    this.episodeOptions = new Array();
    this.setupForm();
  }

  /**
   * Indicates if the given option is the current selection.
   *
   * @param title Episode | Season
   * @returns boolean
   */
  protected isSelected(title: Episode | Season): boolean {
    const selected: Episode | Season = this.cloneForm.get('selected').value;

    const isIt: boolean =
      _isObject(selected) &&
      title.id === selected.id &&
      title.type === selected.type;

    return isIt;
  }

  /**
   * Retrieves the locking status label for the current position.
   *
   * @returns string
   */
  protected retrieveLockingStatusLabel(): string {
    const label: string = `${this.creditType.toString().slice(0, 3)} ${this.creditPosition} Lock Status`;
    return label;
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.episodeOptions = new Array();
    this.seasonOption = undefined;
  }

  /**
   * Sets the default option.
   *
   * @returns void
   */
  protected setDefaultSelection(): void {
    this.setSelected(this.seasonOption);
  }

  /**
   * Sets the given option as the current selection.
   *
   * @param option Episode | Season
   * @returns void
   */
  protected setSelected(option: Episode | Season): void {
    this.cloneForm.get('selected').setValue(option);
    this.selectEvent.emit(option);
  }

  /**
   * Set up the clone form.
   *
   * @returns void
   */
  protected setupForm(): void {
    this.cloneForm = this.formBuilder.group({
      selected: [
        undefined,
        Validators.required
      ]
    });
  }
}
