import { Component, OnInit } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Language, List as MasterDataList, MasterDataCacheService, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SelectionItem } from '@bolt/ui-shared/droplists';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { MasterDataService } from 'app/modules/masterData/services/master-data.service';


@Component({
  selector: 'bolt-master-data-language',
  template: require('./bolt-master-data-language.html'),
  styles: [require('./bolt-master-data-language.scss')]
})
export class BoltMasterDataLanguageComponent extends StormComponent implements OnInit {
  protected hasOpenHandlerPanel: boolean;
  protected language?: Language;
  protected pager: MemoryPager;
  protected searchedWords: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected cacheService: MasterDataCacheService
  ) {
    super();
    this.hasOpenHandlerPanel = false;
    this.searchedWords = '';
    this.setupPager();
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Opens the side panel for adding new language
   * @returns void
   */
  protected add(): void {
    this.openHandlerPanel(undefined);
  }

  /**
   * Finds the languages with the given words.
   *
   * @param words string
   * @returns void
   */
  protected applySearchBy(words: string): void {
    this.searchedWords = words;
    this.pager.setFilterCriteria(this.getFilteringCriteria());
  }

  /**
   * Opens the side panel for editing the given language
   * @param language Language
   * @returns void
   */
  protected edit(language: Language): void {
    this.openHandlerPanel(language);
  }

  /**
   * Loads the languages.
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();

    // TODO: We need to check and change fetchAsObservable() to don't use SelectionItem.
    this.masterDataService.fetchAsObservable(MasterDataType.language).subscribe(
      (list: MasterDataList) => {
        const records: Language[] = list.items.map((item: SelectionItem) => item.source);
        this.pager.setRecords(records);
        this.changeStatusToDataFound();
      },
      (error: HttpError) => {
        this.changeStatusToError();
        this.notificationService.handleError('Failed fetching the languages.', error);
      }
    );
  }

  /**
   * Returns the filtering criteria for languages.
   *
   * @returns CallableFunction
   */
  protected getFilteringCriteria(): CallableFunction {
    const criteria: CallableFunction = (language: Language) => {
      const matched: boolean =
        this.searchedWords.length === 0 ||
        language.localeLanguage.toLowerCase().includes(this.searchedWords) ||
        language.name.toLowerCase().includes(this.searchedWords) ||
        language.iso6391.toLowerCase().includes(this.searchedWords);

      return matched;
    };

    return criteria;
  }

  /**
   * Returns the criteria for sorting languages.
   *
   * @returns any
   */
  protected getSortingCriteria(): any {
    const criteria: CallableFunction = (languageA: Language, languageB: Language) => {
      const nameA: string = languageA.name.trim().toLowerCase();
      const nameB: string = languageB.name.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Handles the cancellation event from handler panel.
   *
   * @returns void
   */
  protected onHandlerCancel(): void {
    this.hasOpenHandlerPanel = false;
    this.language = undefined;
  }

  /**
   * Handles the saving event from handler panel.
   *
   * @returns void
   */
  protected onHandlerSave(): void {
    this.hasOpenHandlerPanel = false;
    this.language = undefined;
    this.cacheService.remove(MasterDataType.language);
    this.fetch();
  }

  /**
   * Opens the handler panel for the given languages.
   *
   * @returns void
   */
  protected openHandlerPanel(languages: Language): void {
    this.language = languages;
    this.hasOpenHandlerPanel = true;
  }

  /**
   * Set up the memory pager.
   *
   * @returns void
   */
  protected setupPager(): void {
    this.pager = new MemoryPager(
      this.appConfig.get('ux.dataTables.pageSize'),
      this.getSortingCriteria()
    );
  }
}
