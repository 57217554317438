import { isObject as _isObject, isString as _isString } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Status } from '../status/status.model';
import { StatusEnum } from '../status/status.enum';


export abstract class Connection {
  protected name: string;
  protected status: Status;

  constructor(data: any) {
    if (_isObject(data)) {
      this.setName(data.connectionName);
      this.setStatus(data.connectionStatus);
    } else {
      throw new ErrorHelper('Invalid data given for a CPM Health connection.');
    }
  }

  /**
   * Gets the connection name.
   *
   * @returns string
   */
  getName(): string {
    return this.name;
  }

  /**
   * Gets the connection status.
   *
   * @returns Status
   */
  getStatus(): Status {
    return this.status;
  }

  /**
   * Indicates if it is a Check connection.
   *
   * @returns boolean
   */
  isCheck(): boolean {
    return false;
  }

  /**
   * Indicates if it is a Kafka connection.
   *
   * @returns boolean
   */
  isKafka(): boolean {
    return false;
  }

  /**
   * Set the connection name.
   *
   * @param name string
   * @returns void
   */
  protected setName(name: string): void {
    if (_isString(name)) {
      this.name = name.trim();
    } else {
      throw new ErrorHelper('Invalid name given for a CPM Health connection.');
    }
  }

  /**
   * Set the connection status.
   *
   * @param status StatusEnum
   * @returns void
   */
  protected setStatus(status: StatusEnum): void {
    this.status = new Status(status);
  }
}
