import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Attribute, FunctionalMetadataService } from '@bolt/ui-shared/title';
import { Language } from '@bolt/ui-shared/master-data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  cloneDeep as _cloneDeep, isUndefined as _isUndefined, isNull as _isNull, isObject as _isObject, isString as _isString
} from 'lodash';

import { CapabilitiesManager } from '../../../auth/services/role/capabilities.manager';
import { ProductLayoutHelper } from '../../helpers/product-layout/product-layout.helper';
import { Feature } from '../../models/feature.model';
import { FeatureMetadata } from '../../models/feature-metadata.model';
import { SeasonMetadata } from '../../models/season-metadata.model';
import { SeriesMetadata } from '../../models/series-metadata.model';
import { EpisodeMetadata } from '../../models/episode-metadata.model';
import { TitleService } from '../../services/title.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { BoltTitleLocalizationAttributeActionsComponent } from '../bolt-title-localization-attribute-actions/bolt-title-localization-attribute-actions.component';


@Component({
  selector: 'bolt-title-localization-attributes-actions',
  template: require('./bolt-title-localization-attributes-actions.html'),
  styles: [require('./bolt-title-localization-attributes-actions.scss')],
})
export class BoltTitleLocalizationAttributesActionsComponent
  extends BoltTitleLocalizationAttributeActionsComponent implements OnChanges, OnInit {
  // TODO: Replace old models for new models
  @Input() localization: EpisodeMetadata | SeriesMetadata | SeasonMetadata | FeatureMetadata;
  @Input() attributeLabel: string;
  @Input() attributes: string[];
  @Input() allowCustomize: boolean = true;
  @Input() allowUpdate: boolean = true;
  @Input() allowDelete: boolean = true;
  @Input() showUnifiedRatings: boolean = false;
  @Output('updated') updatedEvent: EventEmitter<any> = new EventEmitter();


  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected functionalMetadataService: FunctionalMetadataService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected capabilitiesManager: CapabilitiesManager,
    protected titleService: TitleService,
    protected notificationService: NotificationService,
    protected modalService: NgbModal
  ) {
    super(
      appConfigurationManager, functionalMetadataService, productLayoutHelper,
      capabilitiesManager, titleService, notificationService, modalService
    );
    this.initializeLocalizationPrivileges();
  }

  ngOnInit() {
    this.setupLevelMode();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.localization && changes.localization.currentValue) {
      this.isInherited = this.attributes?.every((attr) => this.localization.isComputedField(attr));
      this.isLocalizationSpecificRoot = this.localization.localeObject.isLocalizationSpecificRoot();
    }

    this.localeType = this.localization.localeObject.type.toString().toUpperCase();
  }

  /**
   * Returns a boolean indicating if the Product Metadata attribute can be deleted
   *
   * @returns boolean
   */
  protected canDelete(): boolean {
    let hasValueToDelete: boolean;

    if (this.isFunctionalMetadata()) {
      const functionalMetadataAttributes: string[] = this.hasFunctionalMetadataEnabled()
        ? this.functionalMetadataService.getAttributesByLevel(this.levelMode).map((attribute: Attribute) => attribute.getName())
        : this.attributes;

      hasValueToDelete = functionalMetadataAttributes.some((attribute: string) => this.localization.isLocalizedField(attribute));
    } else {
      hasValueToDelete = this.attributes?.every((attr) => this.localization.isLocalizedField(attr));
    }

    const canIt: boolean =
      this.allowDelete &&
      !this.isLocalizationSpecificRoot &&
      !this.isInherited &&
      hasValueToDelete &&
      !this.localization.locked &&
      this.canEditPmx() &&
      this.hasWritePrivileges();

    return canIt;
  }

  /**
   * Starts the delete process of the current attribute
   *
   * @returns void
   */
  protected deleteAttribute(): void {
    let attributesToDelete: string[];
    this.isSaving = false;

    if (this.isFunctionalMetadata()) {
      attributesToDelete = this.hasFunctionalMetadataEnabled()
        ? this.functionalMetadataService.getAttributesByLevel(this.levelMode).map((attribute: Attribute) => attribute.getName())
        : [FeatureAttributes.functionalMetadata];
    } else {
      attributesToDelete = this.attributes;
    }

    this.titleService.deleteProductMetadataAttribute(
      {
        productType: this.localization.type,
        productId: this.localization.id,
      },
      this.localization.locale,
      attributesToDelete
    )
    .subscribe(
      (response: StormServiceResponseSingle) => {
        this.updatedEvent.emit(response.item);
        this.isSaving = false;
        this.closeModal();
        this.notificationService.handleNotice('Customization successfully deleted');
      },
      (error: any) => {
        this.isSaving = false;
        this.closeModal();
        this.notificationService.handleError('Error while trying to delete the Customization', error);
      }
    );
  }

  /**
   * Indicates if the user can edit the current PMX attribute.
   *
   * @returns boolean
   */
  protected canEditPmx(): boolean {
    let itCan: boolean = !this.isFromPmx();
    const synopsisAttributes: string[] = this.attributesFromPmx.filter((attribute: string) => attribute !== 'keywords');

    if (
      this.isFromPmx() &&
      this.localization.isFeature() &&
      (<Feature>this.localization).videoType === 'Bonus' &&
      this.localization.localeObject.isLanguageRoot() &&
      Language.isEnglish(<number>this.localization.language)
    ) {
      itCan =  this.attributes?.some(attr => synopsisAttributes.includes(attr));
    }

    return itCan;
  }

  /**
   * Indicates if the current attribute is from PMX.
   *
   * @returns boolean
   */
  protected isFromPmx(): boolean {
    // TODO update this logic when expect more services instead of only PMX
    const itIs: boolean =
      _isObject(this.localization.bpiField) &&
      this.attributes?.some((attr) => _isString(this.localization.bpiField[attr])) &&
      this.attributes?.some((attr) => this.localization.bpiField[attr].split('_')[0] === 'PMX');

    return itIs;
  }

  /**
   * Indicates if the current attribute is functionalMetadata.
   *
   * @returns boolean
   */
  protected isFunctionalMetadata(): boolean {
    return this.attributes?.some((attr) => attr === FeatureAttributes.functionalMetadata);
  }

  protected onSaveLocalization(localization: any): void {
    this.updatedEvent.emit(localization);
  }

}
