import { includes as _includes } from 'lodash';
import { ResolveIngestDataEnum } from './resolve-ingest-data.enum';


export class ResolveIngestData {
  protected _type: ResolveIngestDataEnum;

  constructor(type: ResolveIngestDataEnum) {
    this.initialize(type);
  }

  get type(): ResolveIngestDataEnum {
    return this._type;
  }

  /**
   * Indicates if its type is ACCEPT.
   *
   * @returns boolean
   */
  isAccept(): boolean {
    const isIt: boolean = (this._type === ResolveIngestDataEnum.ACCEPT);
    return isIt;
  }

  /**
   * Indicates if its type is DECLINE.
   *
   * @returns boolean
   */
  isDecline(): boolean {
    const isIt: boolean = (this._type === ResolveIngestDataEnum.DECLINE);
    return isIt;
  }


  /**
   * Initializes the instance.
   *
   * @param type ConflictEnum
   * @returns void
   */
  protected initialize(type: ResolveIngestDataEnum): void {
    this._type = (
      _includes(ResolveIngestDataEnum, <any>type)
        ? type
        : undefined
    );
  }
}
