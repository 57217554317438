import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { isArray as _isArray, isObject as _isObject, includes as _includes, isNumber as _isNumber } from 'lodash';
import { Subscription } from 'rxjs';

import { AccessControlService } from 'app/modules/user/services/access-control/access-control.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Group } from '../../models/group.model';
import { RequestControlEnum } from 'app/modules/user/models/request-control/request-control.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TypeEnum as EntityTypeEnum } from 'app/modules/user/models/entity-type/type.enum';


@Component({
  selector: 'bolt-group-list',
  template: require('./bolt-group-list.html'),
  styles: [require('./bolt-group-list.scss')]
})
export class BoltGroupListComponent extends StormComponent implements OnInit, OnDestroy {
  @Input() entityType: EntityTypeEnum;
  @Input() entityId: number;
  @Input() requestControl: RequestControlEnum;

  @ViewChild('groupsModalRef') modal: ModalDirective;

  protected fetchGroupsSubscription: Subscription;
  protected groups: Group[];
  protected readonly listMaxHeight: number = 4;

  constructor(
    protected accessControlService: AccessControlService,
    protected notificationService: NotificationService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.validateDependencies();
    this.fetchGroups();
  }

  ngOnDestroy() {
    this.cancelSubscription();
  }

  /**
   * Cancels the current subscription.
   *
   * @returns void
   */
  protected cancelSubscription(): void {
    if (_isObject(this.fetchGroupsSubscription)) {
      this.fetchGroupsSubscription.unsubscribe();
    }
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected closeModal(): void {
    this.modal.hide();
  }

  /**
   * Fetches the groups for the current entity.
   *
   * @returns void
   */
  protected fetchGroups(): void {
    this.changeStatusToFetchingData();

    this.fetchGroupsSubscription = this.accessControlService.fetchEntityGroupsAsObservable(
      this.requestControl,
      this.entityType,
      this.entityId
    ).subscribe(
      (groups: Group[]) => {
        this.groups = groups;

        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the groups', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Indicates if it has to display the view all.
   *
   * @returns boolean
   */
  protected hasDisplayViewAll(): boolean {
    const hasIt: boolean = this.hasDisplayList() && this.groups.length > this.listMaxHeight;
    return hasIt;
  }

  /**
   * Indicates if it has to display the empty message.
   *
   * @returns boolean
   */
  protected hasDisplayEmptyMessage(): boolean {
    const hasIt: boolean = this.isDataFound() && !this.hasGroups();
    return hasIt;
  }

  /**
   * Indicates if it has to display the list.
   *
   * @returns boolean
   */
  protected hasDisplayList(): boolean {
    const hasIt: boolean = this.isDataFound() && this.hasGroups();
    return hasIt;
  }

  /**
   * Indicates if it has groups.
   *
   * @returns boolean
   */
  protected hasGroups(): boolean {
    const hasIt: boolean =  _isArray(this.groups) && this.groups.length > 0;
    return hasIt;
  }

  /**
   * Indicates if it has a valid entity id.
   *
   * @returns boolean
   */
  protected hasValidEntityId(): boolean {
    return _isNumber(this.entityId);
  }

  /**
   * Indicates if it has a valid entity type.
   *
   * @returns boolean
   */
  protected hasValidEntityType(): boolean {
    const hasIt: boolean = _includes(EntityTypeEnum, this.entityType);
    return hasIt;
  }

  /**
   * Indicates if it has a valid request control.
   *
   * @returns boolean
   */
  protected hasValidRequestControl(): boolean {
    const hasIt: boolean = _includes(RequestControlEnum, this.requestControl);
    return hasIt;
  }

  /**
   * Initialize the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.groups = new Array();
  }

  /**
   * Opens the modal.
   *
   * @returns void
   */
  protected openModal(): void {
    this.modal.show();
  }

  /**
   * Validates the dependencies for the instance.
   *
   * @throws ErrorHelper
   * @returns void
   */
  protected validateDependencies(): void {
    if (!this.hasValidEntityId()) {
      throw new ErrorHelper('Invalid entityId given in BoltGroupListComponent');
    }

    if (!this.hasValidEntityType()) {
      throw new ErrorHelper('Invalid entityType given in BoltGroupListComponent');
    }

    if (!this.hasValidRequestControl()) {
      throw new ErrorHelper('Invalid requestControl given in BoltGroupListComponent');
    }
  }
}
