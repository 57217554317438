import { includes as _includes } from 'lodash';
import { StatusEnum } from './status.enum';


export class Status {
  protected _type: StatusEnum;

  constructor(type: StatusEnum) {
    this.initialize(type);
  }

  get type(): StatusEnum {
    return this._type;
  }

  /**
   * Indicates if its type is DONE.
   *
   * @returns boolean
   */
  isDone(): boolean {
    const isIt: boolean = (this._type === StatusEnum.DONE);
    return isIt;
  }

  /**
   * Indicates if its type is FAILED.
   *
   * @returns boolean
   */
  isFailed(): boolean {
    const isIt: boolean = (this._type === StatusEnum.FAILED);
    return isIt;
  }

  /**
   * Indicates if its type is UNDETERMINED.
   *
   * @returns boolean
   */
  isUndetermined(): boolean {
    const isIt: boolean = (this._type === StatusEnum.UNDETERMINED);
    return isIt;
  }

  /**
   * Initializes the instance.
   *
   * @param type StatusEnum
   * @returns void
   */
  protected initialize(type: StatusEnum): void {
    this._type = (
      _includes(StatusEnum, <any>type)
        ? type
        : undefined
    );
  }
}
