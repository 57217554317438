import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { EnvironmentService } from '@bolt/ui-shared/common';


@Directive({
  selector: '[boltAutomation]'
})
export class BoltAutomationDirective implements OnInit {
  @Input('boltAutomation') protected boltAutomation: string;
  protected elementName: ElementRef;

  constructor(
    protected el: ElementRef,
    protected environmentService: EnvironmentService
  ) { }

  ngOnInit() {
    if (!this.environmentService.isProd()) {
      this.elementName =
        this.el.nativeElement.id ||
        this.el.nativeElement.className ||
        this.el.nativeElement;

      this.validate();
    }
  }

  /**
   * Validates it.
   *
   * @returns void
   */
  protected validate(): void {
    if (this.boltAutomation.length === 0) {
      this.el.nativeElement.style.backgroundColor = 'red';
      console.error(`boltAutomation :: Missing ID for ${this.elementName}`);
    }
  }
}
