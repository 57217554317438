import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isObject as _isObject } from 'lodash';

import { Connection } from '../../models/health/connection/connection.model';


@Component({
  selector: 'bolt-cpm-health-connection-container',
  template: require('./bolt-cpm-health-connection-container.html'),
  styles: [require('./bolt-cpm-health-connection-container.scss')]
})
export class BoltCpmHealthConnectionContainerComponent implements OnChanges {
  @Input() connections: Connection[];
  @Input() title: string;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.connections)) {
      this.connections = changes.connections.currentValue;
    }
  }
}
