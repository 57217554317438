import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { isArray as _isArray } from 'lodash';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { StormListType } from '@bolt/ui-shared/master-data';
import { SearchCriteria } from '@bolt/ui-shared/searching';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { StormServiceResponseSingleInterface, StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { StormServiceResponseCollectionInterface, StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServicesQueryParams } from 'app/modules/common/services/storm-services-query-params';
import { Talent, TalentInterface } from '../models/talent.model';
import { TalentMetadata, TalentMetadataInterface } from '../models/talent-metadata.model';
import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { TalentResponse } from 'app/shared/models/search-response/talent/talent-response.model';


export enum TalentServiceFetchTalentQueryParams {
  q = <any>'q',
}

export interface TalentServiceFetchTalentMetadataParamsInterface {
  talentId: number;
  locale?: string;
}

export interface TalentServiceFetchTalentParamsInterface {
  q: string;
  page_size: number;
  page: number;
  sort_by?: string;
  sort_direction?: string;
}

@Injectable()
export class TalentService  extends BoltAbstractService {

  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService,
    protected entityMapperHelper: EntityMapperHelper
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetch a single Talent
   *
   * @param params TalentServiceFetchTalentMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchTalent(
    params: TalentServiceFetchTalentMetadataParamsInterface
  ): Observable<StormServiceResponseSingleInterface> {
    return this.authHttp
      .get(
        this.generateUrl('talentService.fetchTalent.endpoint', { '{talentId}': params.talentId }),
      ).pipe(
        map((response) => {
          response.localizations.forEach(
            (metadata: object, index: number) => {
              response.localizations[index] = new TalentMetadata(metadata);
            }
          );

          const talent = new Talent(response);
          return new StormServiceResponseSingle(talent);
        })
      );
  }

  /**
   * Creates a new Talent
   *
   * @param talent TalentInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public createTalent(
    talent: TalentInterface
  ): Observable<StormServiceResponseSingleInterface> {
    const request: any = {
      url: this.generateUrl('talentService.createTalent.endpoint'),
      body: JSON.stringify(talent.getRawObject())
    };

    return this.doPostRequestAsObservable(
      request
    ).pipe(
      map((response: any) => {
        return new StormServiceResponseSingle(new Talent(response.item));
      })
    );
  }

  /**
   * Fetch the Talent Metadata for a specific Talent/locale
   *
   * @param params TalentServiceFetchTalentMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchTalentMetadata(
    params: TalentServiceFetchTalentMetadataParamsInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .get(
        this.generateUrl('talentService.fetchTalentMetadata.endpoint', { '{talentId}': params.talentId, '{locale}': params.locale })
      ).pipe(
        map((response: any) => {
          const talentMetadata = new TalentMetadata(response);
          return new StormServiceResponseSingle(talentMetadata);
        })
      );
  }

  /**
   * Fetch the computed Talent Metadata for a specific Talent/locale
   *
   * @param params TalentServiceFetchTalentMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchComputedTalentMetadata(
    params: TalentServiceFetchTalentMetadataParamsInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .get(
        this.generateUrl('talentService.fetchComputedTalentMetadata.endpoint', { '{talentId}': params.talentId, '{locale}': params.locale })
      ).pipe(
        map((response: any) => {
          const talentMetadata = new TalentMetadata(response);
          return new StormServiceResponseSingle(talentMetadata);
        })
      );
  }

  /**
   * Updates a Talent
   *
   * @param params TalentServiceFetchTalentMetadataParamsInterface
   * @param updates TalentInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public updateTalent(
    params: TalentServiceFetchTalentMetadataParamsInterface,
    updates: TalentInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .put(
        this.generateUrl('talentService.updateTalent.endpoint', { '{talentId}': params.talentId }),
        JSON.stringify(updates)
      ).pipe(
        map((response: any) => {
          response.localizations.forEach((metadata: object, index: number) => {
            response.localizations[index] = new TalentMetadata(metadata);
          });

          const talent = new Talent(response);
          return new StormServiceResponseSingle(talent);
        })
      );
  }

  /**
   * Updates a talent metadata
   *
   * @param params TalentServiceFetchTalentMetadataParamsInterface
   * @param fromLocale Locale
   * @param toLocale Locale
   * @param talentMetadata TalentMetadataInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public updateTalentMetadata(
    params: TalentServiceFetchTalentMetadataParamsInterface,
    fromLocale: Locale,
    toLocale: Locale,
    talentMetadata: TalentMetadataInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .post(
        this.generateUrl('talentService.moveMetadata.endpoint', { '{talentId}': params.talentId }),
        JSON.stringify(
          Object.assign(
            { from: fromLocale, to: toLocale },
            talentMetadata
          )
        )
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new TalentMetadata(response));
        })
      );
  }

  /**
   * Sets the metadata for a Talent
   *
   * @param params TalentServiceFetchTalentMetadataParamsInterface
   * @param locale string
   * @param TalentMetadata Talent
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public setTalentMetadata(
    params: TalentServiceFetchTalentMetadataParamsInterface,
    locale: string,
    talentMetadata: Talent
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .post(
        this.generateUrl('talentService.createTalentMetadata.endpoint', { '{talentId}': params.talentId, '{locale}': locale }),
        JSON.stringify(talentMetadata.getRawObject())
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new TalentMetadata(response));
        })
      );
  }

  /**
   * Deletes the given Talent Metadata properties (attributes)
   *
   * @param params TalentServiceFetchTalentMetadataParamsInterface
   * @param locale string
   * @param properties string[]
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public deleteTalentMetadataAttribute(
    params: TalentServiceFetchTalentMetadataParamsInterface,
    locale: string,
    properties: string[]
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .delete(
        this.generateUrl(
          'talentService.deleteTalentMetadataAttribute.endpoint',
          { '{talentId}': params.talentId, '{locale}': locale }
        ),
        JSON.stringify(properties)
      ).pipe(
        map((response) => {
          return new StormServiceResponseSingle(new TalentMetadata(response));
        })
      );
  }

  /**
   * Deletes the given Talent Localization
   *
   * @param  params TalentServiceFetchTalentMetadataParamsInterface
   * @param  locale string
   * @return Observable<StormServiceResponseSingleInterface>
   */
  public deleteTalentLocalization(
    params: TalentServiceFetchTalentMetadataParamsInterface,
    locale: string
  ): Observable<StormServiceResponseSingleInterface> {
    return this.authHttp
      .delete(
        this.generateUrl('talentService.deleteTalentLocalization.endpoint', { '{talentId}': params.talentId, '{locale}': locale })
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new TalentMetadata(response));
        })
      );
  }

  /**
   * Returns an observable for searching the given parameters.
   *
   * @param searchParams TalentServiceFetchTalentParamsInterface
   * @return Observable<StormServiceResponseCollectionInterface>
   */
  public oldSearch(
    searchParams: TalentServiceFetchTalentParamsInterface
  ): Observable<StormServiceResponseCollectionInterface> {
    const search = new UrlSearchParams();

    search.set(TalentServiceFetchTalentQueryParams.q.toString(), searchParams.q);
    search.set(StormServicesQueryParams.page.toString(), (searchParams.page - 1).toString());
    search.set(StormServicesQueryParams.page_size.toString(), searchParams.page_size.toString());

    if (searchParams.sort_by && searchParams.sort_direction) {
      search.set(StormServicesQueryParams.sort.toString(), searchParams.sort_by + ':' + searchParams.sort_direction);
    }

    const obs: Observable<StormServiceResponseCollectionInterface> = this.authHttp.get(
      this.generateUrl('talentService.oldSearch.endpoint', null, search)
    ).pipe(
      map(
        response => {
          const responseCollection = response.content.map(item => new Talent(item));

          return new StormServiceResponseCollection(
            responseCollection,
            Number(response.number) + 1,
            Number(response.size),
            Number(response.totalPages),
            Number(response.totalElements),
            searchParams.sort_by,
            searchParams.sort_direction
          );
        }
      )
    );

    return obs;
  }

  /**
   * Returns an observable for the talent that match the given query
   *
   * @param criteria SearchCriteria
   * @returns Observable<SearchResponse>
   */
  search(criteria: SearchCriteria): Observable<SearchResponse> {
    const url: string = this.generateUrl(
      'talentService.search.endpoint',
      undefined,
      criteria.asEndpointData()
    );

    return this.authHttp.get(
      url
    ).pipe(
      mergeMap(
        response => {
          return this.entityMapperHelper.map(
            response.content,
            [
              {
                property: 'prefixId',
                mapTo: StormListType.affix,
              },
              {
                property: 'suffixId',
                mapTo: StormListType.affix,
              }
            ]
          ).pipe(
            map (
              mappedContentResponse => {
                response.content = mappedContentResponse;

                return new SearchResponse(
                  response,
                  (data: any) => new TalentResponse(data)
                );
              }
            )
          );
        }
      )
    );
  }
}
