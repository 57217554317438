import { Pipe, PipeTransform } from '@angular/core';
import { BoltAmUtcPipe } from '../bolt-am-utc.pipe';
import { FormatEnum } from '../format.enum';


@Pipe({
  name: 'boltAmUtcDate'
})
export class BoltAmUtcDatePipe extends BoltAmUtcPipe implements PipeTransform {
  getFormat(): FormatEnum {
    return FormatEnum.DATE;
  }
}
