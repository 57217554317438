import { Field } from '../field.model';


export class BooleanField extends Field {
  constructor(data: any) {
    super(data);
  }

  isBoolean(): boolean {
    return true;
  }

  /**
   * Checks the stored values and indicates if it is falsy.
   *
   * @returns boolean
   */
  isFalsy(): boolean {
    return !this.isTruthy();
  }

  /**
   * Checks the stored values and indicates if it is truthy.
   *
   * @returns boolean
   */
  isTruthy(): boolean {
    let isIt: boolean;

    if (this.isNewValueDefined()) {
      isIt = this.newValue;
    } else {
      if (this.isCurrentValueDefined()) {
        isIt = this.currentValue;
      } else {
        isIt = false;
      }
    }

    return isIt;
  }

  /**
   * Toggles it.
   *
   * @returns void
   */
  toggle(): void {
    this._newValue = !this.isTruthy();
  }

  protected parseValue(value: any): boolean {
    const _value: string = String(value).trim().toLowerCase();
    let parsedValue: boolean;

    if (_value === 'true') {
      parsedValue = true;
    } else if (_value === 'false') {
      parsedValue = false;
    } else {
      parsedValue = undefined;
    }

    return parsedValue;
  }
}
