import { Injectable } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { map, finalize, catchError } from 'rxjs/operators';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { CandidateTypeEnum } from '../models/candidate-type.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable({
  providedIn: 'root',
})
export class MergeService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp,
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Merge the target records into the source.
   *
   * @param type CandidateTypeEnum
   * @param sourceId number
   * @param targetId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  merge(
    type: CandidateTypeEnum,
    sourceId: number,
    targetId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.mergeAsObservable(type, sourceId, targetId).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (response: StormServiceResponseSingle) => {
        onSuccessDo(response);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      }
    );

    return subs;
  }

  /**
   * Merge the target records into the source.
   *
   * @param type CandidateTypeEnum
   * @param sourceId number
   * @param targetId number
   * @returns Observable<StormServiceResponseSingle>
   */
  mergeAsObservable(
    type: CandidateTypeEnum,
    sourceId: number,
    targetId: number
  ): Observable<StormServiceResponseSingle> {
    const params: any = {
      '{sourceId}': sourceId,
      '{targetId}': targetId
    };

    const url: string = this.generateUrl(
      `merge.mergeService.${type}.mergeToTarget.endpoint`,
      params
    );

    const obs: Observable<StormServiceResponseSingle> = this.authHttp.post(url, undefined).pipe(
      map(
        (response: HttpResponse<any>) => {
          return this.defaultMapOn(response);
        }
      ),
      catchError(
        (error: HttpErrorResponse) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }
}
