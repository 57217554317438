import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Language } from '@bolt/ui-shared/master-data';
import { isObject as _isObject } from 'lodash';

import { WizardService } from '../../services/wizard/wizard.service';
import { Candidate } from '../../models/wizard/candidate/candidate.model';
import { TypeEnum } from '../../models/wizard/candidate/type/type.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FormFactoryService as CharacterFormFactoryService } from 'app/modules/character/services/form-factory/form-factory.service';
import { FormFactoryService as RoleFormFactoryService } from 'app/modules/role/services/form-factory/form-factory.service';

import {
  FormFactoryService as  TalentFormFactoryService
} from 'app/modules/talent/services/form-factory/form-factory.service';

import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { CharacterForm } from 'app/modules/character/models/form/character-form.model';
import { TalentForm } from 'app/modules/talent/models/form/talent-form.model';
import { RoleForm } from 'app/modules/role/models/form/role-form.model';


@Component({
  selector: 'bolt-credits-wizard-creation-step',
  template: require('./bolt-credits-wizard-creation-step.html'),
  styles: [require('./bolt-credits-wizard-creation-step.scss')]
})
export class BoltCreditsWizardCreationStepComponent implements OnInit {
  @Input() language: Language;

  protected creationQueue: Candidate[];
  protected forms: CharacterForm[] | TalentForm[] | RoleForm[];
  protected index: number;
  private readonly invalidTypeMessage: string = 'Invalid candidate type for creating step.';

  constructor(
    protected wizardService: WizardService,
    private characterFormFactory: CharacterFormFactoryService,
    private layoutHandler: LayoutHandlerService,
    private roleFormFactory: RoleFormFactoryService,
    private talentFormFactory: TalentFormFactoryService
  ) {
    this.index = 0;
  }

  ngOnInit() {
    this.creationQueue = this.wizardService.getCreationCandidates();
    this.setupForms();
  }

  /**
   * Get the candidate for the current form.
   *
   * @returns Candidate
   */
  getCurrentCandidate(): Candidate {
    const candidate: Candidate = this.creationQueue.find(
      (elem: Candidate) => elem.getKey() === this.forms[this.index].getKey()
    );

    return candidate;
  }

  /**
   * Completes the creation process.
   *
   * @returns void
   */
  protected completeCreation() {
    this.wizardService.startBulkCreation();
  }

  /**
   * Indicates if it has to disable the next button.
   *
   * @returns boolean
   */
  protected hasDisableNextButton(): boolean {
    return this.forms[this.index].invalid;
  }

  /**
   * Indicates if it has to display the back button.
   *
   * @returns boolean
   */
  protected hasDisplayBackButton(): boolean {
    return this.index > 0;
  }

  /**
   * Indicates if it has to disable the next button.
   *
   * @returns boolean
   */
  protected hasDisplayNextButton(): boolean {
    return this.index + 1 < this.forms.length;
  }

  /**
   * Indicates if it has to disable the previous button.
   *
   * @returns boolean
   */
  protected hasDisablePreviousButton(): boolean {
    return this.layoutHandler.isSaving();
  }

  /**
   * Indicates if it has to disable the save button.
   *
   * @returns boolean
   */
  protected hasDisableSaveButton(): boolean {
    const itHas: boolean = this.forms.some((form: FormGroup) => form.invalid) || this.layoutHandler.isSaving();
    return itHas;
  }

  /**
   * Indicates if it has forms.
   *
   * @returns boolean
   */
  protected hasForms(): boolean {
    return this.forms.length > 0;
  }

  /**
   * Go to the next candidate.
   *
   * @returns void
   */
  protected nextCandidate(): void {
    this.index += 1;
  }

  /**
   * Go to the previous candidate.
   *
   * @returns void
   */
  protected previousCandidate(): void {
    this.index -= 1;
  }

  /**
   * Creates a character form with the given default name.
   *
   * @param candidate Candidate
   * @param localized boolean
   * @returns CharacterForm
   */
  private createCharacterForm(candidate: Candidate, localized: boolean = false): CharacterForm {
    const defaultValue: any = {
      name: candidate.getName(),
      key: candidate.getKey()
    };

    let formGroup: CharacterForm;

    if (localized) {
      defaultValue.language = this.language.id;
      formGroup = this.characterFormFactory.buildLocalizedDataForm(defaultValue);
    } else {
      defaultValue.originalLanguageId = this.language.id;
      formGroup = this.characterFormFactory.buildOriginalDataForm(defaultValue);
    }

    return formGroup;
  }

  /**
   * Creates a talent form with the given default name.
   *
   * @param candidate Candidate
   * @param localized boolean
   * @returns TalentForm
   */
  private createTalentForm(candidate: Candidate, localized: boolean = false): TalentForm {
    const defaultValue: any = {
      fullName: candidate.getName(),
      originalLanguageId: this.language.id,
      key: candidate.getKey()
    };

    let formGroup: TalentForm;

    if (localized) {
      defaultValue.language = this.language.id;

      formGroup = this.talentFormFactory.buildLocalizedDataForm(defaultValue);
    } else {
      formGroup = this.talentFormFactory.buildOriginalDataForm(defaultValue);
    }

    return formGroup;
  }

  /**
   * Creates a role form with the given default name.
   *
   * @param candidate Candidate
   * @param localized boolean
   * @returns RoleForm
   */
  private createRoleForm(candidate: Candidate, localized: boolean = false): RoleForm {
    const defaultValue: any = {
      name: candidate.getName(),
      key: candidate.getKey()
    };

    let formGroup: RoleForm;

    if (localized) {
      defaultValue.language = this.language.id;

      formGroup = this.roleFormFactory.buildLocalizedDataForm(defaultValue);
    } else {
      formGroup = this.roleFormFactory.buildOriginalDataForm(defaultValue);
    }

    return formGroup;
  }

  /**
   * Set up the forms for the current candidates.
   *
   * @returns void
   */
  private setupForms(): void {
    const isLocalized: boolean = this.wizardService.isLocalized();
    this.forms = new Array();

    this.creationQueue.forEach(
      (candidate: Candidate) => {
        let newForm: any;
        const isRepeated: boolean = this.forms.some((form: CharacterForm | TalentForm | RoleForm) => form.getKey() === candidate.getKey());

        if (!isRepeated) {
          switch (candidate.getType().getValue()) {
            case TypeEnum.character:
              newForm = this.createCharacterForm(candidate, isLocalized);
              break;
            case TypeEnum.role:
              newForm = this.createRoleForm(candidate, isLocalized);
              break;
            case TypeEnum.talent:
              newForm = this.createTalentForm(candidate, isLocalized);
              break;
            default:
              throw new ErrorHelper(this.invalidTypeMessage);
          }

          this.forms.push(newForm);
        }
      }
    );

    this.wizardService.setCreationForms(this.forms);
  }
}
