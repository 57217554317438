import { Injectable } from '@angular/core';
import { StormList, StormListInterface, StormLists, StormListType } from '@bolt/ui-shared/master-data';
import { Observable, Observer } from 'rxjs';

import { ListService } from '../services/list.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { share } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class StormListsProvider {
  protected lists: StormLists = new StormLists();
  protected observable: Observable<StormLists>;

  constructor(protected listService: ListService) { }

  /**
   * Get the current lists
   *
   * @param force boolean
   * @returns Observable<StormLists>
   */
  getLists(force: boolean = false): Observable<StormLists> {

    if (this.observable !== undefined) {
      return this.observable;
    }

    this.observable = new Observable((observer: Observer<StormLists>) => {
      if (force || this.lists.missingLists().length) {
        this.loadLists(force).subscribe(
          (list: { list: StormList, type: StormListType }) => {
            this.lists.addList(list.type, list.list);
          },
          err => {
            console.error(err);
          },
          () => {
            observer.next(this.lists);
            observer.complete();
            this.observable = undefined;
          }
        );
      } else {
        observer.next(this.lists);
        observer.complete();
        this.observable = undefined;
      }
    }).pipe(share());

    return this.observable;
  }

  /**
   * Get the current list for the given list type
   *
   * @param listType StormListType
   * @returns Observable<StormListInterface>
   */
  getList(listType: StormListType): Observable<StormListInterface> {
    return new Observable((observer: Observer<StormListInterface>) => {
      if (this.lists.hasList(listType)) {
        observer.next(this.lists.getList(listType));
        observer.complete();
      } else {
        this.getLists().subscribe(
          stormLists => {
            observer.next(stormLists.getList(listType));
            observer.complete();
          }
        );
      }
    });
  }

  /**
   * Loads the lists.
   *
   * @param force boolean
   * @returns Observable<any>
   */
  protected loadLists(force: boolean = false): Observable<any> {
    let loadedListsCount = 0;

    const listsToLoad = force
      ? Object.keys(StormListType).map(key => StormListType[key])
      : this.lists.missingLists();

    return new Observable((observer: Observer<any>) => {
      const listProcessed = () => {
        loadedListsCount++;
        if (loadedListsCount === listsToLoad.length) {
          observer.complete();
        }
      };

      listsToLoad.forEach((listType: StormListType) => {
        this.listService.fetchList(listType).subscribe(
          (response: StormServiceResponseSingle) => {
            observer.next({
              list: response.item,
              type: listType
            });
          },
          error => {
            console.warn('[ListProvider] Unable to load List: ' + listType, error);
            observer.next({
              list: new StormList(listType),
              type: listType
            });
            listProcessed();
          },
          () => {
            listProcessed();
          }
        );
      });
    }).pipe(share());
  }
}
