import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from 'app/modules/common/common.module';

import { AccessControlManager } from './services/access-control/manager/manager';
import { AccessControlService } from './services/access-control/access-control.service';
import { BoltUserListComponent } from './components/bolt-user-list/bolt-user-list.component';
import { BoltUserManagerComponent } from './components/bolt-user-manager/bolt-user-manager.component';
import { BoltUserProfileComponent } from './components/bolt-user-profile/bolt-user-profile.component';
import { UserManager } from './helpers/user-manager.helper';
import { UserService } from './services/user.service';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule
  ],
  declarations: [
    BoltUserProfileComponent,
    BoltUserListComponent,
    BoltUserManagerComponent
  ],
  exports: [
    BoltUserListComponent,
    BoltUserManagerComponent
  ],
  providers: [
    AccessControlService,
    AccessControlManager,
    UserService,
    UserManager
  ],
})
export class UserModule { }
