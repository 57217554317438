import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isObject as _isObject } from 'lodash';

import { Type } from '../../models/configuration/type/type.model';
import { TypeEnum } from '../../models/configuration/type/type.enum';


@Component({
  selector: 'bolt-cpm-configurations-filters',
  template: require('./bolt-cpm-configurations-filters.html'),
  styles: [require('./bolt-cpm-configurations-filters.scss')]
})
export class BoltCpmConfigurationsFiltersComponent implements OnInit, OnDestroy {
  @Input() disabled: boolean;

  @Output('filtered') filterEvent: EventEmitter<TypeEnum>;
  @Output('searched') searchEvent: EventEmitter<string>;

  @ViewChild('searchBy')
  protected searchByInput: ElementRef;

  protected searchByListener: Subscription;
  protected searchBySubject: Subject<void>;
  protected typeItems: SelectionItem[];
  protected words: string;

  constructor(protected appConfig: AppConfigProvider) {
    this.disabled = false;
    this.filterEvent = new EventEmitter();
    this.searchEvent = new EventEmitter();
    this.searchBySubject = new Subject();
    this.typeItems = Type.asSelectionItemList();
    this.words = '';
  }

  ngOnDestroy() {
    if (_isObject(this.searchByListener)) {
      this.searchByListener.unsubscribe();
    }
  }

  ngOnInit() {
    this.searchByListener = this.searchBySubject.asObservable().pipe(debounceTime(200)).subscribe(
      () => {
        const words: string = this.searchByInput.nativeElement.value.trim().toLowerCase();

        if (words !== this.words) {
          this.words = words;
          this.searchEvent.emit(this.words);
        }
      }
    );
  }

  /**
   * Returns the scrollHeight from AppConfig.
   *
   * @returns string
   */
  protected getScrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Notifies the selected type using the given event.
   *
   * @param event any
   * @returns void
   */
  protected notifyFilterChange(event: any): void {
    this.filterEvent.emit(event.value);
  }

  /**
   * Notifies the written words in the "Search By" input.
   *
   * @returns void
   */
  protected notifySearchChange(): void {
    this.searchBySubject.next();
  }
}
