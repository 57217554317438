// tslint:disable:variable-name

export interface StormServiceResponsePaginationInterface {
  current_page: number;
  page_size: number;
  total_pages: number;
  total_results: number;
  getPages(): number[];
}

export interface StormServiceResponseSortingInterface {
  sort_by: string;
  sort_direction: string;
  getNextSortDirectionBy(sort_by: string): string;
}

export interface StormServiceResponseCollectionInterface {
  collection: any[];
  pagination: StormServiceResponsePaginationInterface;
  sorting: StormServiceResponseSortingInterface;
}

export class StormServiceResponsePagination implements StormServiceResponsePaginationInterface {
  constructor(
    public current_page: number = 0,
    public page_size: number = 0,
    public total_pages: number = 0,
    public total_results: number = 0
  ) { }

  /**
   * Returns an array with all the page numbers.
   *
   * @returns Array
   */
  getPages() {
    return Array.from(new Array(this.total_pages), (x, i) => <number>i + 1);
  }
}

export class StormServiceResponseSorting implements StormServiceResponseSortingInterface {
  constructor(
    public sort_by: string = '',
    public sort_direction: string = ''
  ) { }

  /**
   * Returns the next sort direction for the given sort_by criteria, based on the current sort_by criteria
   * If current sort_by === given sort_by && current sort_direction === 'ASC', returns 'DESC'
   * If current sort_by === given sort_by && current sort_direction === 'DESC', returns 'ASC'
   * If current sort_by !== given sort_by, returns 'ASC'
   *
   * @param sort_by string
   * @returns string
   */
  getNextSortDirectionBy(sort_by: string): string {
    let sort_direction = 'asc';

    if (this.sort_by === sort_by && this.sort_direction === sort_direction) {
      sort_direction = 'desc';
    }

    return sort_direction;
  }
}

export class StormServiceResponseCollection implements StormServiceResponseCollectionInterface {
  pagination: StormServiceResponsePaginationInterface;
  sorting: StormServiceResponseSortingInterface;

  constructor(
    public collection: any[] = [],
    public current_page: number = 0,
    public page_size: number = 0,
    public total_pages: number = 0,
    public total_results: number = 0,
    public sort_by: string = '',
    public sort_direction: string = ''
  ) {
    this.pagination = new StormServiceResponsePagination(
      this.current_page,
      this.page_size,
      this.total_pages,
      this.total_results
    );

    this.sorting = new StormServiceResponseSorting(
      this.sort_by,
      this.sort_direction
    );
  }
}
