import { Component, Input } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';

import { BoltRoleMetadataFormComponent } from '../bolt-role-metadata-form/bolt-role-metadata-form.component';
import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { LocalizedDataForm } from '../../models/form/localized-data/localized-data.form.model';
import { RoleLocalizedAttributesEnum } from './role-localized-attributes.enum';


@Component({
  selector: 'bolt-role-localized-metadata-form',
  template: require('./bolt-role-localized-metadata-form.html'),
  styles: [require('./bolt-role-localized-metadata-form.scss')]
})
export class BoltRoleLocalizedMetadataFormComponent extends BoltRoleMetadataFormComponent {
  @Input() form: LocalizedDataForm;
  @Input() attributesToHide: string[];

  protected roleLocalizedAttributesEnum: any = RoleLocalizedAttributesEnum;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formConfig: ConfigService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super(formConfig, listLayoutProvider);
    this.attributesToHide = [];
  }

  /**
   * Returns a boolean indicating if the given attribute must be displayed in the form
   *
   * @param key string
   * @returns boolean
   */
  protected isAttributeVisible(key: string): boolean {
    return !this.attributesToHide.includes(key);
  }
}
