import { includes as _includes } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { TypeEnum } from './type.enum';


export class Type {
  protected _value: TypeEnum;

  constructor(value: TypeEnum) {
    this.initialize(value);
  }

  get value(): TypeEnum {
    return this._value;
  }

  /**
   * Indicates if it is a "Character".
   *
   * @returns boolean
   */
  isCharacter(): boolean {
    return this.is(TypeEnum.Character);
  }

  /**
   * Indicates if it is a "Episode".
   *
   * @returns boolean
   */
  isEpisode(): boolean {
    return this.is(TypeEnum.Episode);
  }

  /**
   * Indicates if it is equals to the given Type.
   *
   * @param otherType Type
   * @returns boolean
   */
  isEqualsTo(otherType: Type): boolean {
    return this.is(otherType.value);
  }

  /**
   * Indicates if it is a "Feature".
   *
   * @returns boolean
   */
  isFeature(): boolean {
    return this.is(TypeEnum.Feature);
  }

  /**
   * Indicates if it is a "Season".
   *
   * @returns boolean
   */
  isSeason(): boolean {
    return this.is(TypeEnum.Season);
  }

  /**
   * Indicates if it is a "Series".
   *
   * @returns boolean
   */
  isSeries(): boolean {
    return this.is(TypeEnum.Series);
  }

  /**
   * Indicates if it is a "Subproduct".
   *
   * @returns boolean
   */
  isSubproduct(): boolean {
    return this.is(TypeEnum.Subproduct);
  }

  /**
   * Indicates if it is a "Term".
   *
   * @returns boolean
   */
  isTerm(): boolean {
    return this.is(TypeEnum.Term);
  }

  /**
   * Calculates if it is a kind of title and returns it.
   *
   * @returns boolean
   */
  isTitle(): boolean {
    const isIt: boolean = (this.isFeature() || this.isSeries() || this.isSeason() || this.isEpisode());
    return isIt;
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this._value.toString();
  }

  /**
   * Initializes the instance.
   *
   * @param value TypeEnum
   * @returns void
   */
  protected initialize(value: TypeEnum): void {
    const normalizedValue: string = String(value).trim().toLowerCase();

    if (_includes(TypeEnum, normalizedValue)) {
      this._value = value;
    } else {
      throw new ErrorHelper(`'Invalid value given for a type: ${value}.`);
    }
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value TypeEnum
   * @returns boolean
   */
  protected is(value: TypeEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
