import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { TypeEnum } from '@bolt/ui-shared/master-data';

import { BoltMetadataComponent } from 'app/modules/common/models/bolt-metadata-component.model';
import { BoltRoleMetadataFiltersInterface } from '../bolt-role-metadata-filters/bolt-role-metadata-filters.component';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { RoleInterface } from '../../models/role.model';
import { RoleManager } from '../../helpers/role-manager/role-manager.helper';
import { RoleMetadataInterface } from '../../models/role-metadata.model';
import { RoleService } from '../../services/role.service';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { TypeEnum as CreditType } from 'app/modules/credits/models/wizard/candidate/type/type.enum';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { EditHistoryMetadata } from 'app/modules/common/components/bolt-edit-history/bolt-edit-history.component';

@Component({
  selector: 'bolt-role-metadata-details',
  template: require('./bolt-role-metadata-details.html'),
  styles: [require('./bolt-role-metadata-details.scss')],
})
export class BoltRoleMetadataDetailsComponent extends BoltMetadataComponent implements OnChanges, OnDestroy {
  @Input('BoltRoleMetadataDetailsRole') role: RoleInterface;
  @Input('BoltRoleMetadataDetailsRoleMetadata') roleMetadata: RoleMetadataInterface;
  @Input('BoltRoleMetadataDetailsFilters') filters: BoltRoleMetadataFiltersInterface;

  @Output('BoltRoleMetadataDetailsOnMetadataUpdated')
  metadataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  localizationRemove: EventEmitter<boolean> = new EventEmitter<boolean>();

  showEditHistory: boolean = false;

  protected computedRoleMetadata: RoleMetadataInterface;
  protected notificationsContainer = notificationsContainer;

  constructor(
    protected capabilitiesManager: CapabilitiesManager,
    protected notificationService: NotificationService,
    protected modalService: NgbModal,
    protected roleManager: RoleManager,
    protected roleService: RoleService
  ) {
    super(modalService, notificationService);
   }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roleMetadata) {
      if (changes.roleMetadata.currentValue) {
        this.roleManager.getComputedRoleMetadata(this.roleMetadata).subscribe(
          roleMetadata => {
            this.computedRoleMetadata = roleMetadata;
          }
        );
      } else {
        this.computedRoleMetadata = undefined;
      }
    }
  }

  ngOnDestroy(): void {
    this.notificationService.clean(this.notificationsContainer.role.details.key);
  }

  getMetadata(): EditHistoryMetadata {
    const localeObject: Locale = new Locale({
      account: this.roleMetadata.account,
      language: this.roleMetadata.language,
      productType: this.roleMetadata.productType,
      territory: this.roleMetadata.territory,
      localeType: TypeEnum.language
    });

    return {
      id: this.role.id,
      locale: this.roleMetadata.locale,
      type: CreditType.role,
      name: this.roleMetadata.name,
      language: this.roleMetadata.language,
      territory: this.roleMetadata.territory,
      productType: this.roleMetadata.productType,
      account: this.roleMetadata.account,
      localeObject: localeObject
    };
  }

  /**
   * Close the edit history modal.
   *
   * @returns void
   */
  closeEditHistory(): void {
    this.showEditHistory = false;
  }

  /**
   * Open the edit history modal.
   *
   * @returns void
   */
  openEditHistory(): void {
    this.showEditHistory = true;
  }

  /**
   * This method removes the selected locale for the current talent
   *
   * @return void
   */
  protected deleteLocalization(): void {
    this.disableConfirmDialog();
    this.roleService.deleteRoleLocalization({
        roleId: this. computedRoleMetadata.id
      },
      this. computedRoleMetadata.locale
    ).subscribe(
      () => {
        this.confirmDeleteLocalizationModal.close();
        this.notificationService.handleNotice(
          'The localization was deleted successfully.',
          '',
          this.notificationsContainer.role.details.key
        );
        this.localizationRemove.emit(true);
      },
      (error: ErrorHelper) => {
        this.confirmDeleteLocalizationModal.close();
        this.notificationService.handleError(
          'There was an error trying to delete the location.',
          error,
          this.notificationsContainer.role.details.key
        );
      }
    );
  }

  /**
   * Indicates if it has to display the delete button.
   *
   * @returns boolean
   */
  protected hasDisplayDeleteButton(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(
      modulesPath.titles.credits.role.localization.path,
      [ActionTypeEnum.delete]
    );
  }
}
