import { SearchPage } from './page/search-page.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';


export class SearchResponse {
  protected _data: any[];
  protected _pageData: SearchPage;

  constructor(data: any, constructor: CallableFunction) {
    this._pageData = new SearchPage(data);
    this.loadData(data, constructor);
  }

  /**
   * Creates a SearchResponse with the given StormServiceResponseCollection
   *
   * @param response StormServiceResponseCollection
   * @returns SearchResponse
   */
  static newFromStormServiceResponseCollection(response: StormServiceResponseCollection, constructor: CallableFunction): SearchResponse {
    const data: any = {
      first: response.current_page === 1,
      last: (response.current_page - 1) === response.total_pages,
      numberOfElements: response.total_results,
      number: response.current_page,
      size: response.page_size,
      totalElements: response.total_results,
      totalPages: response.total_pages,
      content: response.collection
    };

    const newSearchResponse: SearchResponse = new SearchResponse(data, constructor);

    return newSearchResponse;
  }

  get data(): any[] {
    return this._data;
  }

  get pageData(): SearchPage {
    return this._pageData;
  }

  /**
   * Indicates if it has data.
   *
   * @returns boolean
   */
  hasData(): boolean {
    const hasIt: boolean = (this.data.length > 0);
    return hasIt;
  }

  /**
   * Loads the array of elements using the given CallableFunction.
   *
   * @param data any
   * @param constructor CallableFunction
   * @returns void
   */
  protected loadData(data: any, constructor: CallableFunction): void {
    this._data = new Array();

    if (data && data.hasOwnProperty('content')) {
      data.content.forEach(
        (element: any) => {
          this.data.push(
            constructor(element)
          );
        }
      );
    }
  }
}
