import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { Event } from '../../models/event/event.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Summary } from '../../models/event/summary/summary.model';
import { CheckTypeEnum } from 'app/modules/common/services/check-type.enum';


@Injectable({
  providedIn: 'root',
})
export class EventService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetches the events.
   *
   * @returns Observable<Event[]>
   */
  fetchEvents(): Observable<Event[]> {
    const request: any = {
      url: this.generateUrl('cpm.eventService.fetchEvents.endpoint'),
      checkType: CheckTypeEnum.array
    };

    const obs: Observable<Event[]> = this.doGetRequestAsObservable(request).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          const events: Event[] = response.item.map((data: any) => new Event(data));
          return events;
        }
      )
    );

    return obs;
  }

  /**
   * Fetches the summary.
   *
   * @returns Observable<Summary>
   */
  fetchSummary(): Observable<Summary> {
    const url: string = this.generateUrl('cpm.eventService.fetchSummary.endpoint');

    const obs: Observable<Summary> = this.doGetRequestAsObservable({ url: url }).pipe(
      map(
        (response: StormServiceResponseSingle) => new Summary(response.item)
      )
    );

    return obs;
  }
}
