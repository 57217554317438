import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { isObject as _isObject } from 'lodash';

import { Aka } from '../../models/item/aka/aka.model';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ItemService } from '../../services/item/item.service';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { MetadataManagerService } from '../../services/item/metadata-manager/metadata-manager.service';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { Original } from '../../models/item/original/original.model';
import { SearchManager } from 'app/shared/models/search-response/search-manager/search-manager';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { Type } from '../../models/type/type.model';


@Component({
  selector: 'bolt-cat-item-aka',
  template: require('./bolt-cat-item-aka.html'),
  styles: [require('./bolt-cat-item-aka.scss')]
})
export class BoltCatItemAkaComponent extends StormComponent implements OnInit, OnDestroy {
  @Input() item: Original;

  protected confirmDetachAkaModal: NgbModalRef;
  protected editionModeFlag: boolean;
  protected fetchingAkaListerner: Subscription;
  protected pager: MemoryPager;
  protected scrollLoading: boolean;
  protected searchingAkaListerner: Subscription;
  protected searchManager: SearchManager;
  protected searchProductAssociationsLimit: number;
  protected stateConfirmDialog: boolean;
  protected suggestionsLoading: boolean;
  protected twoWayBindValue: boolean;

  constructor(
    protected appConfig: AppConfigProvider,
    protected capabilitiesManager: CapabilitiesManager,
    protected itemManager: MetadataManagerService,
    protected itemService: ItemService,
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    super();
    this.initialize();
  }

  ngOnDestroy() {
    this.unsubscribeFetchingAkas();
    this.unsubscribeSearchingAkas();
  }

  ngOnInit() {
    if (this.hasDisplay()) {
      this.fetchAkas();
    }
  }

  /**
   * Attaches the given selection.
   *
   * @param selection Original
   * @returns void
   */
  protected attachAka(selection: Original): void {
    this.changeStatusToFetchingData();

    let aka: Aka;

    this.itemService.attachAka(
      this.item.type.value,
      this.item.id,
      selection.id,
      this.twoWayBindValue,
      (akas: Aka[]) => {
        this.pager.setRecords(akas);
        aka = new Aka(selection.asEndpointData());
        this.notificationService.handleSuccess('The AKA was associated successfully.', undefined, this.retrieveNotificationsContainer());
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to associate the AKA.', error, this.retrieveNotificationsContainer());
      },
      () => {
        this.fetchAkas(
          () => {
            try {
              this.pager.setPageNumberFor(aka, this.getComparisonCriteria());
            } catch (error) {
              this.pager.setPageNumber(0);
            }
          }
        );
      }
    );
  }

  /**
   * Detaches the given selection.
   *
   * @param selection Aka
   * @returns void
   */
  protected detachAka(selection: Aka): void {
    this.disableConfirmDialog();
    this.changeStatusToFetchingData();

    this.itemService.detachAka(
      this.item.type.value,
      this.item.id,
      selection.id,
      () => {
        this.notificationService.handleSuccess('The AKA was removed successfully.', undefined, this.retrieveNotificationsContainer());
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'There was an error trying to remove the association.',
          error,
          this.retrieveNotificationsContainer()
        );
      },
      () => {
        this.confirmDetachAkaModal.close();

        const oldPager: number = this.pager.pageNumber;

        this.fetchAkas(
          () => {
            try {
              this.pager.setPageNumber(oldPager);
            } catch (error) {
              this.pager.setPageNumber(0);
            }
          }
        );
      }
    );
  }

  /**
   * Disables the confirm dialog setting its state to false.
   *
   * @return void
   */
  protected disableConfirmDialog(): void {
    this.stateConfirmDialog = false;
  }

  /**
   * Enables the confirm dialog setting its state to true.
   *
   * @return void
   */
  protected enableConfirmDialog(): void {
    this.stateConfirmDialog = true;
  }

  /**
   * Fetches the AKAs and set the correct page for the given aka.
   *
   * @param onSetRecordsDo CallableFunction
   * @returns void
   */
  protected fetchAkas(onSetRecordsDo?: CallableFunction): void {
    this.changeStatusToFetchingData();
    this.unsubscribeFetchingAkas();

    this.fetchingAkaListerner = this.itemService.fetchAka(
      this.item.type.value,
      this.item.id,
      (akas: Aka[]) => {
        this.pager.setRecords(akas);

        if (onSetRecordsDo) {
          onSetRecordsDo();
        }

        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed retrieving the AKA items.', error, this.retrieveNotificationsContainer());
        this.changeStatusToError();
      }
    );
  }

  /**
   * Returns the comparison criteria for AKAs.
   *
   * @returns CallableFunction
   */
  protected getComparisonCriteria(): CallableFunction {
    const criteria: CallableFunction = (akaA: Aka, akaB: Aka) => {
      return akaA.id === akaB.id;
    };

    return criteria;
  }

  /**
   * Returns the AKAs.
   *
   * @returns Aka[]
   */
  protected getAkas(): Aka[] {
    return this.pager.getCurrentPage();
  }

  /**
   * Returns the search suggestions.
   *
   * @returns Aka[]
   */
  protected getSearchSuggestions(): Aka[] {
    return this.searchManager.suggestions;
  }

  /**
   * Returns the sorting criteria for AKA.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (akaA: Aka, akaB: Aka) => {
      const nameA: string = akaA.name.trim().toLowerCase();
      const nameB: string = akaB.name.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Indicates if it has AKAs.
   *
   * @returns boolean
   */
  protected hasAkas(): boolean {
    return this.pager.hasRecords();
  }

  /**
   * Indicates if the current user has access to AKA section.
   *
   * @returns boolean
   */
  protected hasDisplay(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(
      modulesPath.cat.character.aka.path,
      [ActionTypeEnum.read]
    );
  }

  /**
   * Indicates if it has to display the action section.
   *
   * @returns boolean
   */
  protected hasDisplayActions(): boolean {
    const hasIt: boolean =
      this.isDataFound() &&
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.cat.character.aka.path, [ActionTypeEnum.write]);

    return hasIt;
  }

  /**
   * Indicates if it has to display the trash button.
   *
   * @returns boolean
   */
  protected hasDisplayRemoveButton(): boolean {
    return this.isEditionOn();
  }

  /**
   * Indicates if it has to display the search.
   *
   * @returns boolean
   */
  protected hasDisplaySearch(): boolean {
    return this.isEditionOn();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.editionModeFlag = false;
    this.item = undefined;
    this.scrollLoading = false;
    this.searchManager = new SearchManager();
    this.searchProductAssociationsLimit = this.appConfig.get('ux.page.cat.searchProductAssociationsQuantity');
    this.suggestionsLoading = false;
    this.twoWayBindValue = true;

    this.pager = new MemoryPager(
      this.appConfig.get('ux.page.cat.attachedPageSize'),
      this.getSortingCriteria()
    );

    this.changeStatusToIdle();
  }

  /**
   * Indicates if the current user can edit.
   *
   * @returns boolean
   */
  protected isEditionOn(): boolean {
    return this.editionModeFlag;
  }

  /**
   * Loads the next page for the current query.
   *
   * @returns void
   */
  protected loadNextSearchingPage(): void {
    if (!this.scrollLoading) {
      this.retrieveSearchSuggestions(this.searchManager.sanitizedQuery, false);
    }
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Allows to show the modal of detaching confirmation and handles the user answer.
   *
   * @param content Reference to the modal
   * @return void
   */
  protected openConfirmModal(content: any): void {
    this.enableConfirmDialog();

    this.confirmDetachAkaModal = this.modalService.open(content);
  }

  /**
   * Retrieves the notification container for the current item type.
   *
   * @returns string
   */
  protected retrieveNotificationsContainer(): string {
    const type: Type = this.item.type;

    if (type.isCharacter()) {
      return notificationsContainer.character.details.key;
    } else if (type.isSubproduct() || type.isTerm()) {
      return notificationsContainer.cat.details.key;
    }
  }

  /**
   * Retrieves the items suggestions for the given query.
   *
   * @param query string
   * @param shouldResetSearch boolean
   * @returns void
   */
  protected retrieveSearchSuggestions(query: string, shouldResetSearch: boolean): void {
    this.unsubscribeSearchingAkas();

    this.scrollLoading = true;

    if (shouldResetSearch) {
      this.searchManager.sanitizedQuery = query.toLowerCase().trim();
      this.suggestionsLoading = true;
    }

    this.searchingAkaListerner = this.itemService.searchAka(
      this.item.type.value,
      this.searchManager.sanitizedQuery,
      this.appConfig.get('ux.page.cat.searchPageSize'),
      this.searchManager.currentPage - 1,
      (response: SearchResponse) => {
        this.searchManager.response = response;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'Failed trying to retrieve the item suggestions.',
          error,
          this.retrieveNotificationsContainer()
        );
      },
      () => {
        this.scrollLoading = false;
        this.suggestionsLoading = false;
      }
    );
  }

  /**
   * Set the current two way option value with the current value.
   *
   * @param value boolean
   * @returns void
   */
  protected setTwoWayBindValue(value: boolean): void {
    this.twoWayBindValue = value;
  }

  /**
   * Toggles the current value of editionModeFlag.
   *
   * @returns void
   */
  protected toggleEditMode(): void {
    this.editionModeFlag = !this.editionModeFlag;
  }

  /**
   * Unsubscribes from fetching AKAs, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeFetchingAkas(): void {
    if (_isObject(this.fetchingAkaListerner)) {
      this.fetchingAkaListerner.unsubscribe();
    }
  }

  /**
   * Unsubscribes from searching AKA, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeSearchingAkas(): void {
    if (_isObject(this.searchingAkaListerner)) {
      this.searchingAkaListerner.unsubscribe();
    }
  }
}
