import { LocaleSpecificFieldEnum } from './locale-specific-field.enum';


export class LocaleSpecificOption {
  protected _field: LocaleSpecificFieldEnum;
  protected _name: string;

  constructor(field: LocaleSpecificFieldEnum, name: string) {
    this.initialize(field, name);
  }

  get field(): LocaleSpecificFieldEnum {
    return this._field;
  }

  get name(): string {
    return this._name;
  }

  /**
   * Initializes the instance.
   *
   * @param field LocaleSpecificFieldEnum
   * @param name string
   * @returns void
   */
  protected initialize(field: LocaleSpecificFieldEnum, name: string): void {
    this._field = field;
    this._name = name;
  }
}
