import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { isArray as _isArray, isObject as _isObject, isEqual as _isEqual, isString as _isString } from 'lodash';


@Component({
  selector: 'bolt-data-accordion',
  template: require('./bolt-data-accordion.html'),
  styles: [require('./bolt-data-accordion.scss')]
})
export class BoltDataAccordionComponent implements OnChanges {
  @Input() entry: string | any[];
  @Input() highlight: string | string[];
  @Input() horizontalValue: boolean;
  @Input() limit: number;

  @Output('collapsed') collapseEvent: EventEmitter<undefined>;
  @Output('expanded') expandEvent: EventEmitter<undefined>;

  protected readonly separator: string = ', ';
  protected data: string | any[];
  protected isCollapsed: boolean;
  protected slice: string | any[];


  constructor(
    protected appConfig: AppConfigProvider
  ) {
    this.collapseEvent = new EventEmitter();
    this.expandEvent = new EventEmitter();
    this.entry = '';
    this.horizontalValue = true;
    this.limit = this.appConfig.get('ux.dataAccordion.defaultLimit');

    this.updateData();
    this.calculateSlice(this.limit);
  }

  ngOnChanges(changes: SimpleChanges) {
    const shouldUpdateData: boolean = _isObject(changes.entry) && !_isEqual(changes.entry.previousValue, changes.entry.currentValue);

    if (shouldUpdateData) {
      this.updateData();
    }

    if (shouldUpdateData || _isObject(changes.limit)) {
      this.calculateSlice(this.limit);
    }
  }

  /**
   * Calculates the slice using the given limit.
   *
   * @params limit number
   * @returns void
   */
  protected calculateSlice(limit: number): void {
    if (this.data.length > limit) {
      this.isCollapsed = true;
      this.slice = this.data.slice(0, limit).concat(this.setupValue());
    } else {
      this.isCollapsed = false;
      this.slice = this.data;
    }
  }

  /**
   * Setups the value.
   *
   * @returns string
   */
  protected setupValue(): string {
    return this.horizontalValue ? '...' : '';
  }

  /**
   * Shows less content in the slice.
   *
   * @param $event Event
   * @returns void
   */
  protected showLess($event: Event): void {
    $event.stopPropagation();
    this.calculateSlice(this.limit);
    this.collapseEvent.emit();
  }

  /**
   * Shows more content in the slice.
   *
   * @param $event Event
   * @returns void
   */
  protected showMore($event: Event): void {
    $event.stopPropagation();
    this.calculateSlice(this.data.length);
    this.expandEvent.emit();
  }

  /**
   *  Updates data with the stored entry.
   *
   * @returns void
   */
  protected updateData(): void {
    if (_isString(this.entry)) {
      this.data = this.entry;
    } else if (_isArray(this.entry) && this.horizontalValue) {
      this.data = this.entry.join(this.separator);
    } else {
      this.data = this.entry;
    }
  }
}
