import { FormControl, Validators } from '@angular/forms';
import { Language } from '@bolt/ui-shared/master-data';
import { isUndefined as _isUndefined } from 'lodash';


export class CreationForm {
  protected _category: FormControl;
  protected _language: FormControl;
  protected _notes: FormControl;
  protected _originalName: FormControl;
  protected _phonetic: FormControl;
  protected _requiredLocalizations: FormControl;
  protected attributes: any;

  constructor(originalName: string, language: number, attributes: any) {
    this.attributes = attributes;

    this.setupCategory();
    this.setupLanguage(language);
    this.setupNotes();
    this.setupOriginalName(originalName);
    this.setupPhonetic();
    this.setupRequiredLocalizations();
  }

  get category(): FormControl {
    return this._category;
  }

  get language(): FormControl {
    return this._language;
  }

  get notes(): FormControl {
    return this._notes;
  }

  get originalName(): FormControl {
    return this._originalName;
  }

  get phonetic(): FormControl {
    return this._phonetic;
  }

  get requiredLocalizations(): FormControl {
    return this._requiredLocalizations;
  }

  /**
   * Set ups the category field.
   *
   * @returns void
   */
  protected setupCategory(): void {
    this._category = new FormControl(undefined, Validators.required);
  }

  /**
   * Set ups the language field.
   *
   * @param language number
   * @returns void
   */
  protected setupLanguage(language: number): void {
    this._language = new FormControl(
      _isUndefined(language) ? Language.ENGLISH_ID : language,
      Validators.required
    );
  }

  /**
   * Set ups the notes field.
   *
   * @returns void
   */
  protected setupNotes(): void {
    const validators: any = Validators.maxLength(this.attributes.notes.maxLength);
    this._notes = new FormControl(undefined, validators);
  }

  /**
   * Set ups the original name field.
   *
   * @param originalName string
   * @returns void
   */
  protected setupOriginalName(originalName: string): void {
    const validators: any = Validators.compose([
      Validators.required,
      Validators.maxLength(this.attributes.originalName.maxLength)
    ]);

    this._originalName = new FormControl(originalName, validators);
  }

  /**
   * Set ups the phonetic field.
   *
   * @returns void
   */
  protected setupPhonetic(): void {
    const validators: any = Validators.maxLength(this.attributes.phonetic.maxLength);
    this._phonetic = new FormControl(undefined, validators);
  }

  /**
   * Set up the localization requested field.
   *
   * @returns void
   */
  protected setupRequiredLocalizations(): void {
    this._requiredLocalizations = new FormControl(true);
  }
}
