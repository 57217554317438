export class Overwritters {
  protected _lockedLanguageMetadata: boolean;
  protected _lockedTerritoryMetadata: boolean;

  constructor() {
    this.initialize();
  }

  get lockedLanguageMetadata(): boolean {
    return this._lockedLanguageMetadata;
  }

  set lockedLanguageMetadata(newValue: boolean) {
    this._lockedLanguageMetadata = newValue;
  }

  get lockedTerritoryMetadata(): boolean {
    return this._lockedTerritoryMetadata;
  }

  set lockedTerritoryMetadata(newValue: boolean) {
    this._lockedTerritoryMetadata = newValue;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(): void {
    this.lockedLanguageMetadata = false;
    this.lockedTerritoryMetadata = false;
  }
}
