import { Component, Input, OnInit } from '@angular/core';
import { ObservableExecutor } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';

import { CreditPositionType } from '../../models/credit/credit-position.enum';
import { CreditType } from '../../models/credit/credit-type.enum';
import { WizardService } from '../../services/wizard/wizard.service';


@Component({
  selector: 'bolt-credits-wizard-validating-step',
  template: require('./bolt-credits-wizard-validating-step.html'),
  styles: [require('./bolt-credits-wizard-validating-step.scss')]
})
export class BoltCreditsWizardValidatingStepComponent implements OnInit {
  @Input() position: CreditPositionType;
  @Input() creditType: CreditType;

  protected listLimit: number;
  protected talentObservableExecutor: ObservableExecutor;
  protected positionObservableExecutor: ObservableExecutor;

  constructor(
    protected wizardService: WizardService,
    protected appConfigProvider: AppConfigProvider
  ) {
    this.listLimit = this.appConfigProvider.get('ux.credits.wizard.limitOfFetches');
  }

  ngOnInit() {
    this.talentObservableExecutor = new ObservableExecutor(this.listLimit);
    this.wizardService.setTalentObservableExecutor(this.talentObservableExecutor);

    this.positionObservableExecutor = new ObservableExecutor(this.listLimit);
    this.wizardService.setPositionObservableExecutor(this.positionObservableExecutor);
  }

  /**
   * Gets the position label.
   *
   * @returns string
   */
  protected getPositionLabel(): string {
    return this.isCast() ? 'Character' : 'Role';
  }

  /**
   * Indicates if it has to display the guest field.
   *
   * @returns boolean
   */
  protected hasDisplayGuestField(): boolean {
    return this.isCast() && this.wizardService.getTitle().isEpisode();
  }

  /**
   * Indicates if it is a Cast.
   *
   * @returns boolean
   */
  protected isCast(): boolean {
    return this.position === CreditPositionType.CAST;
  }

  /**
   * Indicates if it is a Crew.
   *
   * @returns boolean
   */
  protected isCrew(): boolean {
    return this.position === CreditPositionType.CREW;
  }

  /**
   * Indicates if the current credit is original.
   *
   * @returns boolean
   */
  protected isOriginal(): boolean {
    return this.creditType === CreditType.ORIGINAL;
  }
}
