import { Component, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';

import { BoltMergeListComponent } from '../bolt-merge-list/bolt-merge-list.component';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Role } from 'app/modules/role/models/role.model';
import { RoleService } from 'app/modules/role/services/role.service';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';

@Component({
  selector: 'bolt-merge-role-list',
  template: require('./bolt-merge-role-list.html'),
  styles: [require('./bolt-merge-role-list.scss')]
})
export class BoltMergeRoleListComponent extends BoltMergeListComponent {
  @Output('selected') selectEvent: EventEmitter<Role>;

  protected mergeCandidate: Role;

  constructor(
    protected roleService: RoleService,
    protected notificationService: NotificationService
  ) {
    super(notificationService);
  }

  get items(): Role[] {
    return this.searchResponse.data;
  }

  protected doSearch(): void {
    this.fetchItemSubscription = this.roleService.search(
      this.searchCriteria,
      (response: SearchResponse) => {
        this.doDefaultOnSuccess(response);
      },
      (error: ErrorHelper) => {
        this.doDefaultOnError(error, 'roles');
      }
    );

  }
}
