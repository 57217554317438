import { includes as _includes } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StatusEnum } from './status.enum';


export class Status {
  protected value: StatusEnum;

  constructor(value: StatusEnum) {
    this.setValue(value);
  }

  /**
   * Gets the value.
   *
   * @returns StatusEnum
   */
  getValue(): StatusEnum {
    return this.value;
  }

  /**
   * Indicates if it's an "Alert".
   *
   * @returns boolean
   */
  isAlert(): boolean {
    return this.is(StatusEnum.Alert);
  }

  /**
   * Indicates if it's an "Error".
   *
   * @returns boolean
   */
  isError(): boolean {
    return this.is(StatusEnum.Error);
  }

  /**
   * Indicates if it's an "Ok".
   *
   * @returns boolean
   */
  isOk(): boolean {
    return this.is(StatusEnum.Ok);
  }

  /**
   * Indicates if it's a "Warning".
   *
   * @returns boolean
   */
  isWarning(): boolean {
    return this.is(StatusEnum.Warning);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
   toString(): string {
    return this.value;
  }

  /**
   * Indicates if is the same value.
   *
   * @param value StatusEnum
   * @returns boolean
   */
  protected is(value: StatusEnum): boolean {
    return this.value === value;
  }

  /**
   * Sets the value.
   *
   * @param value StatusEnum
   * @throws ErrorHelper
   * @returns void
   */
  protected setValue(value: StatusEnum): void {
    if (_includes(StatusEnum, value)) {
      this.value = <StatusEnum>value;
    } else {
      throw new ErrorHelper('Invalid value given for a CPM Status.');
    }
  }
}
