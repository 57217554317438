import { isArray as _isArray } from 'lodash';

import { Character } from 'app/modules/character/models/character.model';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { AssociationList } from '../../product/association-list/association-list.model';

export class Aka {
  protected _id: number;
  protected _lockingStatus: LockingStatus;
  protected _name: string;
  protected _notes: string;
  protected _productAssociations: AssociationList;


  constructor(data: any) {
    this.initialize(data);
  }

  /**
   * Creates a Aka with the given character
   *
   * @param character Character
   * @returns Aka
   */
  static newFromCharacter(character: Character): Aka {
    const aka: Aka = new Aka(character.getRawObject());
    return aka;
  }

  get id(): number {
    return this._id;
  }

  get lockingStatus(): LockingStatus {
    return this._lockingStatus;
  }

  get name(): string {
    return this._name;
  }

  get notes(): string {
    return this._notes;
  }

  get productAssociations(): AssociationList {
    return this._productAssociations;
  }

  set productAssociations(value: AssociationList) {
    this._productAssociations = value;
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns any
   */
  asEndpointData(): any {
    const data: any = {
      id: this.id,
    };

    return data;
  }

  /**
   * Indicates if it has product associations.
   *
   * @returns boolean
   */
  hasProductAssociations(): boolean {
    return this._productAssociations.hasProductAssociations();
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this._id = data.id;
    this._lockingStatus = new LockingStatus(data);
    this._name = (data.name || '');
    this._notes = data.notes;
    this._productAssociations = new AssociationList(data);
  }
}
