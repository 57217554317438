import { FormControl, Validators } from '@angular/forms';
import { Account } from '@bolt/ui-shared/master-data';
import { isString as _isString } from 'lodash';


export class CreationForm {
  protected _account: FormControl;
  protected _description: FormControl;
  protected _languages: FormControl;
  protected _name: FormControl;
  protected _territories: FormControl;
  protected defaults: any;
  protected fieldsSpecs: any;

  constructor(fieldsSpecs: any, defaults?: any) {
    this.fieldsSpecs = fieldsSpecs;
    this.defaults = defaults;

    this.setupAccount();
    this.setupDescription();
    this.setupLanguages();
    this.setupName();
    this.setupTerritories();
  }

  get account(): FormControl {
    return this._account;
  }

  get description(): FormControl {
    return this._description;
  }

  get languages(): FormControl {
    return this._languages;
  }

  get name(): FormControl {
    return this._name;
  }

  get territories(): FormControl {
    return this._territories;
  }

  /**
   * Sets up the account field.
   *
   * @returns void
   */
  protected setupAccount(): void {
    let account: string;

    if (_isString(this.defaults?.accountCode) && !Account.isAll(this.defaults.accountCode)) {
      account = this.defaults.accountCode;
    }

    this._account = new FormControl(account);
  }

  /**
   * Sets up the description field.
   *
   * @returns void
   */
  protected setupDescription(): void {
    const validators: any = Validators.maxLength(this.fieldsSpecs.description.maxLength);
    this._description = new FormControl(this.defaults?.description, validators);
  }

  /**
   * Sets up the languages field.
   *
   * @returns void
   */
  protected setupLanguages(): void {
    this._languages = new FormControl(this.defaults?.languagesCodes || [], Validators.required);
  }

  /**
   * Sets up the name field.
   *
   * @returns void
   */
  protected setupName(): void {
    const validators: any = Validators.compose([
      Validators.required,
      Validators.maxLength(this.fieldsSpecs.name.maxLength)
    ]);

    this._name = new FormControl(this.defaults?.name, validators);
  }

  /**
   * Sets up the territories field.
   *
   * @returns void
   */
  protected setupTerritories(): void {
    this._territories = new FormControl(this.defaults?.territoriesCodes || [], Validators.required);
  }
}
