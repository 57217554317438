import { includes as _includes } from 'lodash';
import { SubtitleTypeEnum } from './subtitle-type.enum';


export class SubtitleType {
  protected _type: SubtitleTypeEnum;

  constructor(type: SubtitleTypeEnum) {
    this.initialize(type);
  }

  get type(): SubtitleTypeEnum {
    return this._type;
  }

  set type(type: SubtitleTypeEnum) {
    this._type = type;
  }

  /**
   * Indicates if it is type 'ALL'.
   *
   * @returns boolean
   */
  isAll(): boolean {
    return this.is(SubtitleTypeEnum.all);
  }

  /**
   * Indicates if it is type 'INSERT'.
   *
   * @returns boolean
   */
  isInsert(): boolean {
    return this.is(SubtitleTypeEnum.insert);
  }

  /**
   * Indicates if it is type 'LYRICS'.
   * @returns boolean
   */
  isLyrics(): boolean {
    return this.is(SubtitleTypeEnum.lyrics);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this.type.toString();
  }

  /**
   * Initializes the instance.
   *
   * @param type SubtitleTypeEnum
   * @returns void
   */
  protected initialize(type: SubtitleTypeEnum): void {
    this._type = (
      _includes(SubtitleTypeEnum, <any>type)
        ? type
        : undefined
    );
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param type SubtitleTypeEnum
   * @returns boolean
   */
  protected is(type: SubtitleTypeEnum): boolean {
    const isIt: boolean = (this.type  === type);
    return isIt;
  }

}
