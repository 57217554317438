import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isObject as _isObject } from 'lodash';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';

import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { FormFactoryService } from '../../services/form-factory/form-factory.service';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';
import { BoltTalentMetadataFormComponent } from '../bolt-talent-metadata-form/bolt-talent-metadata-form.component';


@Component({
  selector: 'bolt-talent-original-metadata-form',
  template: require('./bolt-talent-original-metadata-form.html'),
  styles: [require('./bolt-talent-original-metadata-form.scss')]
})
export class BoltTalentOriginalMetadataFormComponent extends BoltTalentMetadataFormComponent implements OnChanges {
  @Input() form: OriginalDataForm;
  @Input() showAsGrid: boolean;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formConfig: ConfigService,
    protected formFactory: FormFactoryService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super(formConfig, listLayoutProvider);
    this.showAsGrid = false;
  }

  get prefix(): SelectionItem[] {
    return this.formFactory.getPrefixList();
  }

  get suffix(): SelectionItem[] {
    return this.formFactory.getSuffixList();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }
}
