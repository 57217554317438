import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'bolt-cpm-events',
  template: require('./bolt-cpm-events.html'),
  styles: [require('./bolt-cpm-events.scss')]
})
export class BoltCpmEventsComponent implements OnInit, OnDestroy {
  protected selectedTab: string;
  protected urlChangeListener: Subscription;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected notificationService: NotificationService,
    protected router: Router
  ) { }

  ngOnDestroy() {
    this.urlChangeListener.unsubscribe();
  }

  ngOnInit() {
    this.listenUrlChanges();
  }

  /**
   * Activates the given tab.
   *
   * @param event any
   * @returns void
   */
  protected activateTab(event: any): void {
    const commands: any[] = [{ show: event.nextId }];
    const extras: any = { relativeTo: this.activatedRoute };

    this.router.navigate(commands, extras);
  }

  /**
   * Listens when URL changes.
   *
   * @returns void
   */
  protected listenUrlChanges(): void {
    this.urlChangeListener = this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.selectedTab = params.show;
      },
      (error: any) => {
        this.notificationService.handleError('Failed reading URL.', error);
      }
    );
  }
}
