import { includes as _includes, isMap as _isMap } from 'lodash';

import { Category } from '../category/category.model';
import { CategoryEnum } from '../category/category.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { TypeEnum } from './type.enum';


export class Type {
  protected static categoryMap: Map<TypeEnum, Category[]>;
  protected _value: TypeEnum;

  constructor(value: TypeEnum) {
    this.initialize(value);
  }

  get value(): TypeEnum {
    return this._value;
  }

  /**
   * Returns the map for the categories related to each type.
   *
   * @return Map<TypeEnum, Category[]>
   */
  static getCategoryMap(): Map<TypeEnum, Category[]> {
    if (!_isMap(this.categoryMap)) {
      const map: Map<TypeEnum, Category[]> = new Map();

      map.set(
        TypeEnum.Character,
        [ new Category(CategoryEnum.Character) ]
      );

      map.set(
        TypeEnum.Term,
        [
          new Category(CategoryEnum.Location),
          new Category(CategoryEnum.Phrase),
          new Category(CategoryEnum.Song),
          new Category(CategoryEnum.Other)
        ]
      );

      map.set(
        TypeEnum.Subproduct,
        [
          new Category(CategoryEnum.Game),
          new Category(CategoryEnum.Toy)
        ]
      );

      this.categoryMap = map;
    }

    return this.categoryMap;
  }


  /**
   * Returns a new type from the given category.
   *
   * @param category Category
   * @returns Type
   */
  static newFromCategory(category: Category): Type {
    let type: Type;

    switch (category.value) {
      case CategoryEnum.Character:
        type = new Type(TypeEnum.Character);
      break;
      case CategoryEnum.Game:
      case CategoryEnum.Toy:
        type = new Type(TypeEnum.Subproduct);
      break;
      case CategoryEnum.Location:
      case CategoryEnum.Other:
      case CategoryEnum.Phrase:
      case CategoryEnum.Song:
        type = new Type(TypeEnum.Term);
      break;
      default:
        throw new ErrorHelper(`Invalid category given to retrieve a type: ${category}.`);
    }

    return type;
  }

  /**
   * Indicates if it is a "Character".
   *
   * @returns boolean
   */
  isCharacter(): boolean {
    return this.is(TypeEnum.Character);
  }

  /**
   * Indicates if it is equals to the given Type.
   *
   * @param otherType Type
   * @returns boolean
   */
  isEqualsTo(otherType: Type): boolean {
    return this.is(otherType.value);
  }

  /**
   * Indicates if it is a "Subproduct".
   *
   * @returns boolean
   */
  isSubproduct(): boolean {
    return this.is(TypeEnum.Subproduct);
  }

  /**
   * Indicates if it is a "Term".
   *
   * @returns boolean
   */
  isTerm(): boolean {
    return this.is(TypeEnum.Term);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this._value.toString();
  }

  /**
   * Initializes the instance.
   *
   * @param value TypeEnum
   * @returns void
   */
  protected initialize(value: TypeEnum): void {
    const normalizedValue: string = String(value).trim().toUpperCase();

    if (_includes(TypeEnum, normalizedValue)) {
      this._value = value;
    } else {
      throw new ErrorHelper('Invalid value given for a type.');
    }
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value TypeEnum
   * @returns boolean
   */
  protected is(value: TypeEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
