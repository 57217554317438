import { Component } from '@angular/core';


@Component({
  selector: 'storm-page-dashboard',
  template: require('./storm-page-dashboard.html'),
  styles: [require('./storm-page-dashboard.scss')]
})
export class StormPageDashboardComponent {
  constructor() { }
}
