export class PullingActionHelper {
  protected _action: () => void;
  protected _attempt: number;
  protected _attemptsLimit: number;

  constructor() {
    this.initialize();
  }

  get action(): () => void {
    return this._action;
  }

  get attempt(): number {
    return this._attempt;
  }

  get attemptsLimit(): number {
    return this._attemptsLimit;
  }

  /**
   * Executes the stored action.
   *
   * @throws Error
   * @returns void
   */
  execute(): void {
    if (this.hasAction()) {
      if ((this.attemptsLimit > 0) && (this._attempt >= this.attemptsLimit)) {
        throw new Error(`All attempts were executed (${this._attemptsLimit} as limit).`);
      } else {
        const delay: number = (this.attempt * 3000);

        setTimeout(
          () => {
            this.action();
            this._attempt++;
          },
          delay
        );
      }
    } else {
      throw new Error('Cannot execute an empty action. Call prepare() first.');
    }
  }

  /**
   * Indicates if it has stored an action.
   *
   * @returns boolean
   */
  hasAction(): boolean {
    const hasIt: boolean = (this.action !== undefined);

    return hasIt;
  }

  /**
   * Deletes the stored action.
   *
   * @returns void
   */
  reset(): void {
    this._action = undefined;
    this._attempt = 0;
    this._attemptsLimit = 0;
  }

  /**
   * Sets the action.
   *
   * @param action () => void
   * @param attemptsLimit number
   * @returns void
   */
  set(action: () => void, attemptsLimit: number = 0): void {
    if (this.hasAction()) {
      throw new Error('Cannot prepare more than once. Call reset() first.');
    } else {
      this._action = action;
      this._attemptsLimit = attemptsLimit;
    }
  }

  /**
   * Indicates if it was reset.
   *
   * @returns boolean
   */
  wasReset(): boolean {
    return !this.hasAction();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.reset();
  }
}
