import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Account, Country, ProductType } from '@bolt/ui-shared/master-data';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { isObject as _isObject } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LocaleForm } from 'app/modules/common/models/locale/form/locale-form.model';
import { Title } from '../../models/title.model';
import { TitleService } from '../../services/title.service';
import { TypeEnum as LocalizationTypeEnum } from 'app/modules/common/models/locale/type/type.enum';


@Component({
  selector: 'bolt-title-locale-manager',
  template: require('./bolt-title-locale-manager.html'),
  styles: [require('./bolt-title-locale-manager.scss')]
})
export class BoltTitleLocaleManagerComponent implements OnChanges {
  @Output('closed') closeEvent: EventEmitter<any>;
  @Output('saved') saveEvent: EventEmitter<any>;
  @Input() open: boolean;
  @Input() title: Title;

  @ViewChild('confirmCancelModal') protected cancelModal: ModalDirective;

  protected currentConfirmLocaleModal: NgbModalRef;
  protected localeForm: LocaleForm;
  protected readonly territoryAllId: number = Country.ALL_ID;
  protected readonly productAllId: number = ProductType.ALL_ID;
  protected readonly accountAllId: number = Account.ALL_ID;

  constructor(
    protected notificationService: NotificationService,
    protected modalService: NgbModal,
    protected titleService: TitleService
  ) {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    const shouldCreateForm: boolean = (
      _isObject(changes.open) &&
      (changes.open.currentValue !== changes.open.previousValue) &&
      changes.open.currentValue
    );

    if (shouldCreateForm) {
      this.setupForm();
    }
  }

  /**
   * Handles the Cancel flow, to toggle off the Form.
   * If unsaved changes are detected, the corresponding modal will be shown.
   *
   * @param force boolean
   * @returns void
   */
  protected cancel(force: boolean): void {
    if (force || this.localeForm.pristine) {
      if (_isObject(this.currentConfirmLocaleModal)) {
        this.currentConfirmLocaleModal.close();
      }

      this.toggleOpen(false);
    } else {
      this.currentConfirmLocaleModal = this.modalService.open(this.cancelModal);
    }
  }

  /**
   * Indicates if it has to disable the save button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.localeForm.disabled;
  }

  /**
   * Indicates if it has to disable the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const hasIt: boolean = (this.localeForm.invalid || this.localeForm.disabled);
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.open = false;
    this.saveEvent = new EventEmitter();
  }

  /**
   * Parse the multiselect selected options to remove the ALL option.
   *
   * @param options number[]
   * @returns number[]
   */
  protected removeOptionAll(options: number[]): number[] {
    if (options.length > 1) {
      return options.filter(item => item !== 0);
    }

    return options;
  }

  /**
   * Reset the component attributes.
   *
   * @returns void
   */
  protected reset(): void {
    this.localeForm = undefined;
  }

  /**
   * Update the current locale with the given data.
   *
   * @returns void
   */
  protected save(): void {
    this.localeForm.disable();

    this.titleService.updateLocale(
      this.title.type,
      this.title.id,
      this.title.locale,
      this.localeForm.toJson()
    ).subscribe(
      (response: any) => {
        this.notificationService.handleNotice('The locale was saved successfully');
        this.toggleOpen(false);
        this.saveEvent.emit(response);
      },
      (error: Response) => {
        this.notificationService.handleError('Failed trying to update the locale.', ErrorHelper.from(error));
        this.enableForm();
      }
    );
  }

  /**
   * Set up the form.
   *
   * @returns void
   */
  protected setupForm(): void {
    this.localeForm = new LocaleForm(this.title.localeObject);
  }

  /**
   * Toggles the open value with the given data.
   *
   * @param value boolean
   * @returns void
   */
  protected toggleOpen(value: boolean): void {
    this.open = value;

    if (!this.open) {
      this.reset();
      this.closeEvent.emit();
    }
  }

  /**
   * Enables the form based on the current locale type.
   *
   * @returns void
   */
   private enableForm(): void {
    this.localeForm.getAccountField().enable();

    switch (this.title.localeObject.type.value) {
      case LocalizationTypeEnum.account:
        this.localeForm.getLanguageField().disable();
        this.localeForm.getTerritoryField().disable();
        this.localeForm.getProductTypeField().disable();
      break;
      case LocalizationTypeEnum.language:
        this.localeForm.getLanguageField().enable();
        this.localeForm.getTerritoryField().enable();
        this.localeForm.getProductTypeField().enable();
      break;
      case LocalizationTypeEnum.territory:
        this.localeForm.getLanguageField().disable();
        this.localeForm.getTerritoryField().enable();
        this.localeForm.getProductTypeField().enable();
      break;
      default:
        throw new HttpError('Invalid locale type detected.');
    }
  }
}
