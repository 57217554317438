import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { Country, Account } from '@bolt/ui-shared/master-data';
import { Attribute, FunctionalMetadataService, LevelModeEnum } from '@bolt/ui-shared/title';
import { isUndefined as _isUndefined, isNull as _isNull } from 'lodash';

import { Feature } from '../../models/feature.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { BoltTitleSpecificLocalizationDetailsComponent } from '../bolt-title-specific-localization-details/bolt-title-specific-localization-details.component';
import { BoltRatingService } from '../../services/bolt-rating.service';

@Component({
  selector: 'bolt-title-territory-localization-details',
  template: require('./bolt-title-territory-localization-details.component.html'),
  styles: [require('./bolt-title-territory-localization-details.scss')],
})
// TODO remove all references related functionalMetadataForm when the functional metadata component works without a form in readonly mode.
export class BoltTitleTerritoryLocalizationDetailsComponent extends BoltTitleSpecificLocalizationDetailsComponent implements OnChanges {
  readonly levelModes: typeof LevelModeEnum = LevelModeEnum;

  protected functionalMetadataForm: FormGroup;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected listProvider: StormListsProvider,
    protected functionalMetadataService: FunctionalMetadataService,
    protected boltRatingService: BoltRatingService
  ) {
    super(appConfigurationManager, listProvider);

    // We set territory+account as default.
    this.levelMode = LevelModeEnum.territoryAccount;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.localization && changes.localization.currentValue) {
      this.setupFormFunctionalMetadata();
    }
  }

  isLanguageProductTypeAndAccountAll() {
    return this.boltRatingService.isLanguageProductTypeAndAccountAll(this.localization);
  }

  /**
   * Indicates if it has Disney SVOD as account.
   *
   * @returns boolean
   */
  protected hasDisneySvodAccount(): boolean {
    const account: Account[] | number[] = this.localization.account;
    const hasIt: boolean = (account.length === 1) && Account.isDisneySvod(account[0]);

    return hasIt;
  }

  /**
   * Indicates if it has to display the South Korea fields.
   *
   * @returns boolean
   */
  protected hasDisplaySouthKoreaFields(): boolean {
    const territory: Country[] | number[] = this.localization.territory;

    const hasIt: boolean =
      territory.length === 1 &&
      Country.isSouthKorea(territory[0]);

    return hasIt;
  }

  /**
   * Retrieves the functional metadata fields for the current level mode.
   *
   * @returns string[]
   */
  protected getFunctionalMetadataFields(): string[] {
    const levelMode: LevelModeEnum = this.hasDisneySvodAccount() ? LevelModeEnum.territoryAccount : LevelModeEnum.territory;

    const fields: string[] = this.functionalMetadataService.getAttributesByLevel(levelMode).map(
      (attribute: Attribute) => attribute.getName()
    );

    return fields;
  }

  protected hasUseLightGreyFor(attributeName: string): boolean {
    const levelMode: LevelModeEnum = this.hasDisneySvodAccount() ? LevelModeEnum.territoryAccount : LevelModeEnum.territory;
    let hasIt: boolean;

    if (attributeName === FeatureAttributes.functionalMetadata) {
      if (this.hasFunctionalMetadataEnabled()) {
        hasIt = this.functionalMetadataService.getAttributesByLevel(levelMode).map(
          (attribute: Attribute) => attribute.getName()
        ).every(
          (fmAttribute: string) => super.hasUseLightGreyFor(fmAttribute)
        );
      } else {
        hasIt = super.hasUseLightGreyFor(attributeName);
      }
    } else {
      hasIt = super.hasUseLightGreyFor(attributeName);

      if (attributeName === this.featureAttributes.ratingId && this.isLanguageProductTypeAndAccountAll()) {
        hasIt = hasIt && super.hasUseLightGreyFor(this.featureAttributes.homeEntRatingId);
      }
    }

    return hasIt;
  }

  /**
   * Indicates if the current localization has an authoritative theatrical release date
   *
   * @returns boolean
   */
  protected isAuthoritativeSparta(): boolean {
    return !(_isUndefined((<Feature>this.localization).spartaUpdatedDate) || _isNull((<Feature>this.localization).spartaUpdatedDate));
  }

  /**
   * Set up the functional metadata form required for the @bolt/ui-shared Functional metadata component
   * with its corresponding values.
   *
   * @returns void
   */
  protected setupFormFunctionalMetadata(): void {
    const formFields = { };

    this.getFunctionalMetadataFields().forEach(
      (fieldName: string) => {
        formFields[fieldName] = new FormControl(this.localization[fieldName]);
      }
    );

    this.functionalMetadataForm = new FormGroup(formFields);
  }

  /**
   * Indicates if functional metadata customize is allowed.
   *
   * @returns boolean
   */
  protected isFunctionalMetadataCustomizeAllowed(): boolean {
    const isIt: boolean = !this.hasFunctionalMetadataEnabled() || this.hasDisneySvodAccount();
    return isIt;
  }

  /**
   * Indicates if functional metadata update is allowed.
   *
   * @returns boolean
   */
  protected isFunctionalMetadataUpdateAllowed(): boolean {
    const isIt: boolean = !this.hasFunctionalMetadataEnabled() || this.hasDisneySvodAccount();
    return isIt;
  }
}
