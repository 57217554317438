import * as moment from 'moment';

import { Status } from './status/status.model';


export class ProcessingResponse {
  protected _createdAt: moment.Moment;
  protected _createdBy: string;
  protected _id: number;
  protected _status: Status;
  protected _uuid: string;

  constructor(data: any) {
    this.initialize(data);
  }

  get createdAt(): moment.Moment {
    return this._createdAt;
  }

  get createdBy(): string {
    return this._createdBy;
  }

  get id(): number {
    return this._id;
  }

  get status(): Status {
    return this._status;
  }

  get uuid(): string {
    return this._uuid;
  }

  /**
   * Updates the status.
   *
   * @param status Status
   * @returns void
   */
  updateStatus(status: Status): void {
    this._status = status;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const status: Status = (
      (data && data.hasOwnProperty('status'))
        ? new Status({ status: data.status })
        : undefined
    );

    this._createdAt = (
      (data && data.hasOwnProperty('created'))
        ? moment(data.created)
        : undefined
    );

    this._createdBy = (
      (data && data.hasOwnProperty('createdBy'))
        ? data.createdBy
        : undefined
    );

    this._id = (
      (data && data.hasOwnProperty('id'))
        ? data.id
        : undefined
    );

    this._uuid = (
      (data && data.hasOwnProperty('processUUID'))
        ? data.processUUID
        : undefined
    );

    this.updateStatus(status);
  }
}
