import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription, Subject } from 'rxjs';
import { isObject as _isObject } from 'lodash';
import * as fileSaver from 'file-saver';

import { ExportRecord } from '../../models/history/export-record/export-record.model';
import { ExportService } from '../../services/export.service';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Title } from 'app/modules/title/models/title.model';


@Component({
  selector: 'bolt-metadata-export-history',
  template: require('./bolt-metadata-export-history.html'),
  styles: [require('./bolt-metadata-export-history.scss')]
})
export class BoltMetadataExportHistoryComponent extends StormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() open: boolean;
  @Input() title: Title;

  @Output('closed') closeEvent: EventEmitter<void>;

  searchingLocale: string;
  searchingUser: string;
  searchResponse: SearchResponse;

  @ViewChild('searchByLocale', { static: true })
  protected searchByLocaleInput: ElementRef;

  @ViewChild('searchByUser', { static: true })
  protected searchByUserInput: ElementRef;

  protected readonly pageSize: number = 12;
  protected currentPage: number;
  protected searchByLocaleSubject: Subject<void>;
  protected searchByUserSubject: Subject<void>;
  protected searchListener: Subscription;

  constructor(
    protected exportService: ExportService,
    protected notificationService: NotificationService
  ) {
    super();

    this.closeEvent = new EventEmitter();
    this.searchByLocaleSubject = new Subject();
    this.searchByUserSubject = new Subject();

    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.title)) {
      this.reset();
    }

    if (_isObject(changes.open)) {
      if (changes.open.currentValue) {
        this.focusOnLocaleSearchInput();
        this.fetchHistory();
      } else {
        this.reset();
      }
    }
  }

  ngOnDestroy() {
    this.cancelSearchListener();
  }

  ngOnInit() {
    this.searchByLocaleSubject.asObservable().subscribe(
      () => {
        this.fetchHistory();
      }
    );

    this.searchByUserSubject.asObservable().subscribe(
      () => {
        this.fetchHistory();
      }
    );
  }

  /**
   * Download the file for the given record.
   *
   * @param record ExportRecord
   * @returns void
   */
  downloadFile(record: ExportRecord): void {
    this.exportService.downloadFile(record.getId(), record.getFileFormat()).subscribe(
      (data: Blob) => {
        fileSaver.saveAs(data, record.getFileName());
        this.notificationService.handleSuccess('Download File', `${record.getFileName()} was downloaded successfully.`);
      },
      (error: HttpError) => {
        this.notificationService.handleError('Failed trying to download the file.', error);
      }
    );
  }

  /**
   * Loads the given page.
   *
   * @param page number
   * @returns void
   */
  loadPage(page: number): void {
    this.currentPage = page - 1;
    this.fetchHistory();
  }

  /**
   * Notifies the modal was closed.
   *
   * @returns void
   */
  notifyClose(): void {
    this.closeEvent.emit();
  }

  /**
   * Triggers the search by locale.
   *
   * @returns void
   */
  triggerSearchByLocale(): void {
    this.searchByLocaleSubject.next();
  }

  /**
   * Triggers the search by user.
   *
   * @returns void
   */
  triggerSearchByUser(): void {
    this.searchByUserSubject.next();
  }

  /**
   * Cancels the search listener.
   *
   * @returns void
   */
  protected cancelSearchListener(): void {
    if (_isObject(this.searchListener)) {
      this.searchListener.unsubscribe();
    }
  }

  /**
   * Searches the records.
   *
   * @returns void
   */
  protected fetchHistory(): void {
    this.cancelSearchListener();
    this.changeStatusToFetchingData();

    this.searchingLocale = this.searchByLocaleInput.nativeElement.value.trim().toLowerCase();
    this.searchingUser = this.searchByUserInput.nativeElement.value.trim().toLowerCase();

    const search: any = {
      distributionRecordLocale: this.searchingLocale,
      page: this.currentPage,
      size: this.pageSize,
      sort: 'generationDate',
      sortDir: 'desc',
      titleId: this.title.id,
      userId: this.searchingUser
    };

    this.searchResponse = undefined;

    this.searchListener = this.exportService.fetchHistory(search).subscribe(
      (response: SearchResponse) => {
        this.searchResponse = response;
        this.changeStatusToDataFound();
      },
      (error: HttpError) => {
        if (error.is404()) {
          this.changeStatusToDataNotFound();
        } else {
          this.changeStatusToError();
          this.notificationService.handleError('Failed fetching the Metadata Export History.', error);
        }
      }
    );
  }

  /**
   * Applies the focus on the locale search focus.
   *
   * @returns void
   */
  protected focusOnLocaleSearchInput(): void {
    setTimeout(
      () => {
        this.searchByLocaleInput.nativeElement.focus();
      },
      1000
    );
  }

  /**
   * Resets it.
   *
   * @returns void
   */
  protected reset(): void {
    this.currentPage = 0;
    this.open = false;
    this.searchingLocale = '';
    this.searchingUser = '';
    this.searchResponse = undefined;

    if (_isObject(this.searchByLocaleInput)) {
      this.searchByLocaleInput.nativeElement.value = '';
    }

    if (_isObject(this.searchByUserInput)) {
      this.searchByUserInput.nativeElement.value = '';
    }

    this.cancelSearchListener();
    this.changeStatusToIdle();
  }
}
