import { FormControl } from '@angular/forms';
import { upperFirst as _upperFirst, isBoolean as _isBoolean } from 'lodash';

import { Title } from '../../../title.model';
import { FeatureAttributes } from '../../../title/feature/feature-attributes.enum';
import { TitleLocalizedForm } from '../title-localized-form.model';

export class AccountLocalizationForm extends TitleLocalizedForm {
  protected genreIdField: FormControl;
  protected includeKidsModeField: FormControl;
  protected excludeKidsModeField: FormControl;
  protected productPlacementDisclaimerField: FormControl;
  protected smokingDisclaimerField: FormControl;
  protected displayDubTalentField: FormControl;
  protected graphicContentDisclaimerField: FormControl;
  protected pseDisclaimerField: FormControl;
  protected heritageDisclaimerField: FormControl;
  protected primaryProductAssociationField: FormControl;
  protected secondaryProductAssociationField: FormControl;
  protected excludeKidsModeOverrideField: FormControl;
  protected smokingDisclaimerOverrideField: FormControl;
  protected heritageDisclaimerOverrideField: FormControl;

  constructor(fieldSpecs: any, attributes: string[], title?: Title, splitFunctionalMetadataFlag?: boolean) {
    super(fieldSpecs, attributes, title, splitFunctionalMetadataFlag);
    this.setupFields();
  }

  /**
   * Returns the genre id field.
   *
   * @returns FormControl
   */
  getGenreIdField(): FormControl {
    return this.genreIdField;
  }

  /**
   * Returns the include kids mode field.
   *
   * @returns FormControl
   */
  getIncludeKidsModeField(): FormControl {
    return this.includeKidsModeField;
  }

  /**
   * Returns the exclude kids mode field.
   *
   * @returns FormControl
   */
  getExcludeKidsModeField(): FormControl {
    return this.excludeKidsModeField;
  }

  /**
   * Returns the product placement disclaimer field.
   *
   * @returns FormControl
   */
  getProductPlacementDisclaimerField(): FormControl {
    return this.productPlacementDisclaimerField;
  }

  /**
   * Returns the smoking disclaimer field.
   *
   * @returns FormControl
   */
  getSmokingDisclaimerField(): FormControl {
    return this.smokingDisclaimerField;
  }

  /**
   * Returns the display dub talent field.
   *
   * @returns FormControl
   */
  getDisplayDubTalentField(): FormControl {
    return this.displayDubTalentField;
  }

  /**
   * Returns the graphic content disclaimer field.
   *
   * @returns FormControl
   */
  getGraphicContentDisclaimerField(): FormControl {
    return this.graphicContentDisclaimerField;
  }

  /**
   * Returns the pse disclaimer field.
   *
   * @returns FormControl
   */
  getPseDisclaimerField(): FormControl {
    return this.pseDisclaimerField;
  }

  /**
   * Returns the heritage disclaimer field.
   *
   * @returns FormControl
   */
  getHeritageDisclaimerField(): FormControl {
    return this.heritageDisclaimerField;
  }

  /**
   * Returns the primary product association field.
   *
   * @returns FormControl
   */
  getPrimaryProductAssociationField(): FormControl {
    return this.primaryProductAssociationField;
  }

  /**
   * Returns the secondary product association field.
   *
   * @returns FormControl
   */
  getSecondaryProductAssociationField(): FormControl {
    return this.secondaryProductAssociationField;
  }

  /**
   * Returns the exclude kids mode override field.
   *
   * @returns FormControl
   */
  getExcludeKidsModeOverrideField(): FormControl {
    return this.excludeKidsModeOverrideField;
  }

  /**
   * Returns the smoking disclaimer override field.
   *
   * @returns FormControl
   */
  getSmokingDisclaimerOverrideField(): FormControl {
    return this.smokingDisclaimerOverrideField;
  }

  /**
   * Returns the heritage disclaimer override field.
   *
   * @returns FormControl
   */
  getHeritageDisclaimerOverrideField(): FormControl {
    return this.heritageDisclaimerOverrideField;
  }

  enable(): void {
    super.enable();
    this.languageField.disable();
    this.territoryField.disable();
    this.productTypeField.disable();
  }

  protected setupFields(): void {
    super.setupFields();
    this.languageField.disable();
    this.territoryField.disable();
    this.productTypeField.disable();
  }

  /**
   * Set up the genre id field
   *
   * @returns void
   */
  protected setupGenreIdField(): void {
    this.genreIdField = new FormControl(this.getDefaultValue(FeatureAttributes.genreId));
    this.addControl(FeatureAttributes.genreId, this.genreIdField);
  }

  /**
   * Set up the include kids mode field.
   *
   * @returns void
   */
   protected setupIncludeKidsModeField(): void {
    this.includeKidsModeField = new FormControl(this.getDefaultValue(FeatureAttributes.includeKidsMode));
    this.addControl(FeatureAttributes.includeKidsMode, this.includeKidsModeField);
  }

  /**
   * Set up the exclude kids mode field.
   *
   * @returns void
   */
   protected setupExcludeKidsModeField(): void {
    const { functionalMetadataSplitFields }: any = this.title;
    this.excludeKidsModeField = new FormControl(functionalMetadataSplitFields[FeatureAttributes.excludeKidsMode]);
    this.addControl(FeatureAttributes.excludeKidsMode, this.excludeKidsModeField);
  }

  /**
   * Set up the product placement disclaimer field.
   *
   * @returns void
   */
  protected setupProductPlacementDisclaimerField(): void {
    this.productPlacementDisclaimerField = new FormControl(this.getDefaultValue(FeatureAttributes.productPlacementDisclaimer));
    this.addControl(FeatureAttributes.productPlacementDisclaimer, this.productPlacementDisclaimerField);
  }

  /**
   * Set up the smoking disclaimer field.
   *
   * @returns void
   */
  protected setupSmokingDisclaimerField(): void {
    const { functionalMetadataSplitFields }: any = this.title;
    this.smokingDisclaimerField = new FormControl(functionalMetadataSplitFields[FeatureAttributes.smokingDisclaimer]);
    this.addControl(FeatureAttributes.smokingDisclaimer, this.smokingDisclaimerField);
  }

  /**
   * Set up the display dub talent field.
   *
   * @returns void
   */
  protected setupDisplayDubTalentField(): void {
    this.displayDubTalentField = new FormControl(this.getDefaultValue(FeatureAttributes.displayDubTalent));
    this.addControl(FeatureAttributes.displayDubTalent, this.displayDubTalentField);
  }

  /**
   * Set up the graphic content disclaimer field.
   *
   * @returns void
   */
  protected setupGraphicContentDisclaimerField(): void {
    this.graphicContentDisclaimerField = new FormControl(this.getDefaultValue(FeatureAttributes.graphicContentDisclaimer));
    this.addControl(FeatureAttributes.graphicContentDisclaimer, this.graphicContentDisclaimerField);
  }

  /**
   * Set up the pse disclaimer field.
   *
   * @returns void
   */
  protected setupPseDisclaimerField(): void {
    this.pseDisclaimerField = new FormControl(this.getDefaultValue(FeatureAttributes.pseDisclaimer));
    this.addControl(FeatureAttributes.pseDisclaimer, this.pseDisclaimerField);
  }

  /**
   * Set up the heritage disclaimer field.
   *
   * @returns void
   */
  protected setupHeritageDisclaimerField(): void {
    const { functionalMetadataSplitFields }: any = this.title;
    this.heritageDisclaimerField = new FormControl(functionalMetadataSplitFields[FeatureAttributes.heritageDisclaimer]);
    this.addControl(FeatureAttributes.heritageDisclaimer, this.heritageDisclaimerField);
  }

  /**
   * Set up the primary product association field.
   *
   * @returns void
   */
  protected setupPrimaryProductAssociationField(): void {
    this.primaryProductAssociationField = new FormControl(this.getDefaultValue(FeatureAttributes.primaryProductAssociation));
    this.addControl(FeatureAttributes.primaryProductAssociation, this.primaryProductAssociationField);
  }

  /**
   * Set up the secondary product association field.
   *
   * @returns void
   */
  protected setupSecondaryProductAssociationField(): void {
    this.secondaryProductAssociationField = new FormControl(this.getDefaultValue(FeatureAttributes.secondaryProductAssociation));
    this.addControl(FeatureAttributes.secondaryProductAssociation, this.secondaryProductAssociationField);
  }

  /**
   * Set up the exclude kids mode override field.
   *
   * @returns void
   */
  protected setupExcludeKidsModeOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.excludeKidsModeOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.excludeKidsMode));
    } else {
      this.excludeKidsModeOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.excludeKidsModeOverride, this.excludeKidsModeOverrideField);
  }

  /**
   * Set up the smoking disclaimer override field.
   *
   * @returns void
   */
  protected setupSmokingDisclaimerOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.smokingDisclaimerOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.smokingDisclaimer));
    } else {
      this.smokingDisclaimerOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.smokingDisclaimerOverride, this.smokingDisclaimerOverrideField);
  }

  /**
   * Set up the heritage disclaimer override field.
   *
   * @returns void
   */
  protected setupHeritageDisclaimerOverrideField(): void {
    if (this.title.isLocalizedField) {
      this.heritageDisclaimerOverrideField = new FormControl(this.title.isLocalizedField(FeatureAttributes.heritageDisclaimer));
    } else {
      this.heritageDisclaimerOverrideField = new FormControl();
    }
    this.addControl(FeatureAttributes.heritageDisclaimerOverride, this.heritageDisclaimerOverrideField);
  }
}
