import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Toggle } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { AppConfigurationService } from '../../service/app-configuration/app-configuration.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';

@Component({
  selector: 'bolt-app-toggle-edit-side-panel',
  template: require('./bolt-app-toggle-edit-side-panel.html'),
  styles: [require('./bolt-app-toggle-edit-side-panel.scss')]
})
export class BoltAppToggleEditSidePanelComponent extends StormComponent implements OnChanges {
  @Output('closed') closeEvent: EventEmitter<undefined>;
  @Output('saved') saveEvent: EventEmitter<Toggle>;
  @Input() toggle: Toggle;
  @Input() show: boolean;

  protected form: FormGroup;

  constructor(
    protected appConfigurationService: AppConfigurationService,
    protected notificationService: NotificationService
  ) {
    super();
    this.closeEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.show = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show) && changes.show.currentValue) {
      this.createForm();
    }
  }

  /**
   * Closes the edition side panel.
   *
   * @returns void
   */
  protected close(): void {
    this.show = false;
    this.toggle = undefined;
    this.closeEvent.emit();
  }

  /**
   * Creates the form with the current toggle values.
   *
   * @returns void
   */
  protected createForm(): void {
    const control: any = {
      value: new FormControl(this.toggle.getValue(), Validators.required)
    };

    this.form = new FormGroup(control);
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.isUpdatingAction();
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    return this.isUpdatingAction() || this.form.invalid;
  }

  /**
   * Save the current toggle.
   *
   * @returns void
   */
  protected save(): void {
    this.changeActionToUpdating();

    this.appConfigurationService.updateToggle(
      this.toggle,
      this.form.get('value').value,
      (response: Toggle) => {
        this.notificationService.handleNotice('The toggle was successfully edited.');
        this.saveEvent.emit(response);
        this.close();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to edit the toggle', error);
      },
      () => {
        this.changeActionToShowing();
      }
    );
  }

  /**
   * Toggles the toggle value.
   *
   * @returns void
   */
  protected toggleBooleanValue(): void {
    this.form.get('value').setValue(!this.form.get('value').value);
  }
}
