import { Component, Input, forwardRef, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ObservableExecutor, ObservableResponse } from '@bolt/ui-shared/common';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SearchCriteria } from '@bolt/ui-shared/searching';
import { isArray as _isArray, isFunction as _isFunction, isUndefined as _isUndefined, isObject as _isObject } from 'lodash';
import { Observable, Subscription } from 'rxjs';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { StatusEnum as ScrollLoadingStatus } from '../../directives/bolt-dropdown-scroll-loading/status.enum';


@Component({
  selector: 'bolt-paginated-dropdown',
  template: require('./bolt-paginated-dropdown.html'),
  styles: [require('./bolt-paginated-dropdown.scss')],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BoltPaginatedDropdownComponent),
      multi: true
    }
  ]
})
export class BoltPaginatedDropdownComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  /**
   * The '$' symbol denotes that the expression given contains a child object
   * The '>' symbol denotes that the expression given contains a function
   * The ':' symbol is used to separate all the params in a function
   *
   * Eg: products$getProductAssociationAsString>
   */
  @Input() dataFields: string[];
  @Input() dataHeaders: string[];
  @Input() disabled: boolean;
  @Input() errorMessage: string;
  @Input() key: string;
  @Input() loadingMessage: string;
  @Input() noMoreMessage: string;
  @Input() observableExecutor: ObservableExecutor;
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() scrollHeight: string;
  @Input() searchCriteria: SearchCriteria;
  @Input() searchMethod: (criteria: SearchCriteria) => Observable<any>;
  @Input() showClear: boolean;

  @Output('changed') changeEvent: EventEmitter<any>;
  @Output('optionsChanged') optionsChangedEvent: EventEmitter<any>;
  @Output('statusChanged') statusChangeEvent: EventEmitter<ScrollLoadingStatus>;

  protected defaultValue: any;
  protected observableExecutorListener: Subscription;
  protected onModelChange: CallableFunction;
  protected onModelTouched: CallableFunction;
  protected options: SelectionItem[];
  protected optionStatus: ScrollLoadingStatus;
  protected isRetrying: boolean;
  protected searchingKey: string;
  protected searchMethodSubscription: Subscription;

  constructor(protected notificationService: NotificationService, protected appConfigProvider: AppConfigProvider) {
    this.changeEvent = new EventEmitter();
    this.dataFields = new Array();
    this.dataHeaders = new Array();
    this.statusChangeEvent = new EventEmitter();
    this.optionsChangedEvent = new EventEmitter();

    this.loadingMessage = 'Loading...';
    this.noMoreMessage = 'No more results';
    this.errorMessage = 'Error, click to retry';
    this.isRetrying = false;
    this.options = [];
    this.scrollHeight = appConfigProvider.get('ux.multiSelect.scrollHeight');

    this.reset();
  }

  ngOnChanges(changes: SimpleChanges) {
    const shouldReset: boolean =
      (changes.searchCriteria && changes.searchCriteria.currentValue !== changes.searchCriteria.previousValue) ||
      (changes.searchMethod && changes.searchMethod.currentValue !== changes.searchMethod.previousValue);

    if (shouldReset) {
      this.searchingKey = `${this.key}-${this.searchCriteria.getQuery()}`;

      this.reset();
      this.retrieveOptions();
    }
  }

  ngOnDestroy() {
    if (!_isUndefined(this.searchMethodSubscription)) {
      this.searchMethodSubscription.unsubscribe();
    }
  }

  /**
   * Writes the value in the dropdown.
   *
   * @param value any
   * @returns void
   */
  writeValue(value: any): void {
    this.defaultValue = value;
  }

  /**
   * Set options with the selected values.
   *
   * @param event any
   * @returns void
   */
  changeValue(event: any): void {
    this.defaultValue = event.value;
    this.onModelChange(this.defaultValue);
    this.onModelTouched(this.defaultValue);
    this.changeEvent.emit(this.defaultValue);
  }

  /**
   * Registers on change event.
   *
   * @param fn CallableFunction
   * @returns void
   */
  registerOnChange(fn: CallableFunction): void {
    this.onModelChange = fn;
  }

  /**
   * Registers on touch event.
   *
   * @param fn CallableFunction
   * @returns void
   */
  registerOnTouched(fn: CallableFunction): void {
    this.onModelTouched = fn;
  }

  /**
   * Applies the params provided in the field to the object given
   *
   * @param object object
   * @param field string
   * @throws ErrorHelper
   * @returns string
   */
  protected applyFieldParams(contextObject: object, field: string): string {
    let value: string;

    if (field.includes('>')) {
      const functionName: string = field.substring(field.indexOf('$') + 1, field.indexOf('>'));
      const method: CallableFunction = contextObject[functionName];

      if (_isFunction(method)) {
        const params: string[] = field.substring(field.indexOf('>') + 1).split(':');

        if (params.length > 1 || (params.length === 1 && params[0].length > 0)) {
          value = method.apply(contextObject, params);
        } else {
          value = contextObject[functionName]();
        }
      } else {
        throw new ErrorHelper('The param before the ">" symbol should be a function.');
      }
    } else {
      value = contextObject[field.substring(field.indexOf('$') + 1)];
    }

    return value;
  }

  /**
   * Changes the status to the given one.
   *
   * @param status ScrollLoadingStatus
   * @returns void
   */
  protected changeOptionStatus(status: ScrollLoadingStatus): void {
    this.optionStatus = status;
    this.statusChangeEvent.emit(this.optionStatus);
  }

  /**
   * Gets the object that will be used to resolve the value
   *
   * @param option object
   * @param field string
   * @throws ErrorHelper
   * @returns object
   */
  protected getContextObject(option: object, field: string): object {
    if (field.includes('$')) {
      const childName: string = field.split('$')[0];
      const child: any = option[childName];

      if (_isObject(child)) {
        return child;
      } else {
        throw new ErrorHelper('The expected param before the "$" symbol should be an object.');
      }
    } else {
      return option;
    }
  }

  /**
   * Handles the error given from searchMethod when occurs.
   *
   * @param error SearchResponse
   * @returns void
   */
  protected handleError(error: ErrorHelper): void {
    this.notificationService.handleError('Failed trying to retrieve the options', error);
    this.changeOptionStatus(ScrollLoadingStatus.error);

    this.isRetrying = false;
  }

  /**
   * Loads the next page options.
   *
   * @returns void
   */
  protected loadNextPage(): void {
    if (this.optionStatus === ScrollLoadingStatus.idle && !this.isRetrying) {
      this.searchCriteria.nextPage();
      this.retrieveOptions();
    }
  }

  /**
   * Listens to the Observable Executor once.
   *
   * @returns void
   */
  protected listenObservableExecutorOnce(): void {
    if (_isUndefined(this.observableExecutorListener)) {
      this.observableExecutorListener = this.observableExecutor.getExecutorListener().subscribe(
        (observableResponse: ObservableResponse) => {
          if (observableResponse.key === this.searchingKey) {
            if (observableResponse.isError) {
              this.handleError(observableResponse.error);
            } else {
              this.processResponse(observableResponse.response);
            }
          }
        }
      );
    }
  }

  /**
   * Processes the response from searchMethod.
   *
   * @param response SearchResponse
   * @returns void
   */
  protected processResponse(response: SearchResponse): void {
    if (response.hasData()) {
      response.data.forEach(
        (elem: any) => {
          const label: string = _isArray(this.dataFields) && this.dataFields.length > 0
          ? elem[this.dataFields[0]]
          : elem.toString();

          this.options.push(new SelectionItem(label, elem.id, elem));
        }
      );

      this.changeOptionStatus(ScrollLoadingStatus.idle);
      this.optionsChangedEvent.emit(this.options);
    } else {
      this.changeOptionStatus(ScrollLoadingStatus.noMore);
      this.optionsChangedEvent.emit([]);
    }

    this.isRetrying = false;
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.options = [];
    this.changeOptionStatus(ScrollLoadingStatus.idle);
  }

  /**
   * Resolves the value for the given field.
   *
   * @param option object
   * @param field string
   * @returns string
   */
  protected resolveValue(option: object, field: string): string {
    const contextObject: object = this.getContextObject(option, field);
    const value: string = this.applyFieldParams(contextObject, field);

    return value;
  }

  /**
   * Retrieves the options using the SearchMethod input.
   *
   * @returns void
   */
  protected retrieveOptions(): void {
    this.changeOptionStatus(ScrollLoadingStatus.loading);

    const obs: Observable<any> = this.searchMethod(this.searchCriteria);

    if (this.observableExecutor) {
      this.listenObservableExecutorOnce();
      this.observableExecutor.add(this.searchingKey, obs);
    } else {
      this.searchMethodSubscription = obs.subscribe(
        (response: SearchResponse) => {
          this.processResponse(response);
        },
        (error: ErrorHelper) => {
          this.handleError(error);
        }
      );
    }
  }

  /**
   * Retries to retrieve the options.
   *
   * @returns void
   */
  protected retry(): void {
    this.isRetrying = true;

    this.changeOptionStatus(ScrollLoadingStatus.idle);
    setTimeout(() => this.retrieveOptions());
  }
}
