import { Component, OnInit } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Account, List as MasterDataList, MasterDataCacheService, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';

@Component({
  selector: 'bolt-master-data-account',
  template: require('./bolt-master-data-account.html'),
  styles: [require('./bolt-master-data-account.scss')]
})
export class BoltMasterDataAccountComponent extends StormComponent implements OnInit {
  protected hasOpenHandlerPanel: boolean;
  protected account?: Account;
  protected pager: MemoryPager;
  protected searchedWords: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected cacheService: MasterDataCacheService
  ) {
    super();
    this.hasOpenHandlerPanel = false;
    this.searchedWords = '';
    this.setupPager();
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Opens the side panel for adding new account
   * @returns void
   */
  protected add(): void {
    this.openHandlerPanel(undefined);
  }

  /**
   * Finds the accounts with the given words.
   *
   * @param words string
   * @returns void
   */
  protected applySearchBy(words: string): void {
    this.searchedWords = words;
    this.pager.setFilterCriteria(this.getFilteringCriteria());
  }

  /**
   * Opens the side panel for editting the given account
   * @param account Account
   * @returns void
   */
  protected edit(account: Account): void {
    this.openHandlerPanel(account);
  }

  /**
   * Loads the accounts.
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();

    // TODO: We need to check and change fetchAsObservable() to don't use SelectionItem.
    this.masterDataService.fetchAsObservable(MasterDataType.account).subscribe(
      (list: MasterDataList) => {
        const records: Account[] = list.items.map((item: SelectionItem) => item.source);
        this.pager.setRecords(records);
        this.changeStatusToDataFound();
      },
      (error: HttpError) => {
        this.changeStatusToError();
        this.notificationService.handleError('Failed fetching the accounts.', error);
      }
    );
  }


  /**
   * Returns the filtering criteria for accounts.
   *
   * @returns CallableFunction
   */
  protected getFilteringCriteria(): CallableFunction {
    const criteria: CallableFunction = (account: Account) => {
      const matched: boolean =
        this.searchedWords.length === 0 ||
        account.name.toLowerCase().includes(this.searchedWords) ||
        account.code.toLowerCase().includes(this.searchedWords);

      return matched;
    };

    return criteria;
  }

  /**
   * Returns the criteria for sorting accounts.
   *
   * @returns any
   */
  protected getSortingCriteria(): any {
    const criteria: CallableFunction = (accountA: Account, accountB: Account) => {
      const nameA: string = accountA.name.trim().toLowerCase();
      const nameB: string = accountB.name.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Handles the cancellation event from handler panel.
   *
   * @returns void
   */
  protected onHandlerCancel(): void {
    this.hasOpenHandlerPanel = false;
    this.account = undefined;
  }

  /**
   * Handles the saving event from handler panel.
   *
   * @returns void
   */
  protected onHandlerSave(): void {
    this.hasOpenHandlerPanel = false;
    this.account = undefined;
    this.cacheService.remove(MasterDataType.account);
    this.fetch();
  }

  /**
   * Opens the handler panel for the given accounts.
   *
   * @returns void
   */
  protected openHandlerPanel(accounts: Account): void {
    this.account = accounts;
    this.hasOpenHandlerPanel = true;
  }

  /**
   * Set up the memory pager.
   *
   * @returns void
   */
  protected setupPager(): void {
    this.pager = new MemoryPager(
      this.appConfig.get('ux.dataTables.pageSize'),
      this.getSortingCriteria()
    );
  }
}
