import { BoltSimpleRatingSourceType } from './bolt-simple-rating.type';

export class BoltSimpleRating {
  source: BoltSimpleRatingSourceType;
  description: string;
  order: number;
  constructor(opts) {
    this.source = opts.source;
    this.description = opts.description;
    this.order = opts?.order;
  }
}
