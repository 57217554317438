import { Injectable } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Language, StormList, StormListType } from '@bolt/ui-shared/master-data';
import { BehaviorSubject, Observable } from 'rxjs';
import { isArray as _isArray, isObject as _isObject } from 'lodash';
import { finalize } from 'rxjs/operators';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ItemService } from '../item.service';
import { Item } from '../../../models/item/item.model';
import { Original } from '../../../models/item/original/original.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { TypeEnum } from '../../../models/type/type.enum';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Injectable({
  providedIn: 'root',
})
export class MetadataManagerService {
  protected _languages: Language[];
  protected _original: Original;
  protected _originalListener: Observable<Original>;
  protected _originalNotifier: BehaviorSubject<Original>;

  constructor(
    protected itemService: ItemService,
    protected listProvider: StormListsProvider,
    protected notificationService: NotificationService,
  ) {
    this.initialize();
  }

  get languages(): Language[] {
    return this._languages;
  }

  get original(): Original {
    return this._original;
  }

  get originalListener(): Observable<Original> {
    return this._originalListener;
  }

  /**
   * Discovers the language for the given item and set it.
   *
   * @returns CallableFunction
   */
  discoverLanguageOn(item: Item): any {
    let language: Language;

    if (item.isOriginal()) {
      language = this.languages.find(
        (currentLanguage: Language) => {
          const foundIt: boolean = (currentLanguage.id === (<Original>item).originalLanguageId);
          return foundIt;
        }
      );
    } else {
      const localeLanguage: string = item.locale.split('_')[0];

      language = this.languages.find(
        (currentLanguage: Language) => {
          const foundIt: boolean = (currentLanguage.localeLanguage === localeLanguage);
          return foundIt;
        }
      );
    }

    item.setLanguage(language);
  }

  /**
   * Indicates if it has languages.
   *
   * @returns boolean
   */
  hasLanguages(): boolean {
    const hasIt: boolean = (_isArray(this.languages) && (this.languages.length > 0));
    return hasIt;
  }

  /**
   * Indicates if it has a original.
   *
   * @returns boolean
   */
  hasOriginal(): boolean {
    return _isObject(this._original);
  }

  /**
   * Retrieves a original with the given id.
   *
   * @param originalId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  retrieveOriginal(
    type: TypeEnum,
    id: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): void {
    this.itemService.retrieve(
      type,
      id,
      (item: Original) => {
        this.mapAndSetOriginal(item, onSuccessDo, onErrorDo, finallyDo);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed retrieving item.', error, this.retrieveNotificationsContainer(type));
        onErrorDo(error);
      },
      finallyDo
    );
  }

  /**
   * Toggles the lock status of the current original.
   *
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  toggleOriginalLockedState(
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: any
  ): void {
    const data: any = {
      locked: !this.original.lockingStatus.isTruthy()
    };

    this.itemService.update(
      this.original.type.value,
      this.original.id,
      data,
      (item: Original) => {
        this.mapAndSetOriginal(
          item,
          () => {
            this.notificationService.handleSuccess(
              `The locking status was changed to ${(this.original.lockingStatus.locked ? 'locked' : 'unlocked')}`,
              undefined,
              this.retrieveNotificationsContainer(this.original.type.value)
            );

            onSuccessDo();
          },
          onErrorDo,
          finallyDo
        );
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'Failed trying to change the locking status',
          error,
          this.retrieveNotificationsContainer(this.original.type.value)
        );

        onErrorDo(error);
      },
      finallyDo
    );
  }

  /**
   * Updates the current original with the given data.
   *
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  updateOriginal(
    data: any,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): void {
    this.itemService.update(
      this.original.type.value,
      this.original.id,
      data,
      (item: Original) => {
        this.mapAndSetOriginal(
          item,
          () => {
            this.notificationService.handleSuccess(
              `The ${this.original.type.value.toLocaleLowerCase()} was saved successfully.`,
              undefined,
              this.retrieveNotificationsContainer(this.original.type.value)
            );

            onSuccessDo();
          },
          onErrorDo,
          finallyDo
        );
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          `Failed trying to save the ${this.original.type.value.toLocaleLowerCase()}.`,
          error,
          this.retrieveNotificationsContainer(this.original.type.value)
        );

        onErrorDo(error);
      },
      finallyDo
    );
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this._languages = undefined;
    this._original = undefined;
    this._originalNotifier = new BehaviorSubject(undefined);
    this._originalListener = this._originalNotifier.asObservable();
  }

  /**
   * Sets the given original as the current original.
   *
   * @param original Original
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @returns void
   */
  protected mapAndSetOriginal(
    original: Original,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo: CallableFunction
  ): void {
    this.listProvider.getList(StormListType.language).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (languages: StormList) => {
        try {
          this._languages = languages.getRawCollection();

          this.discoverLanguageOn(original);

          this._original = original;
          this._originalNotifier.next(this._original);

          onSuccessDo();
        } catch (error) {
          onErrorDo(error);
        }
      }
    );
  }

  /**
   * Retrieves the notification container for the given item type.
   *
   * @param itemType TypeEnum
   * @returns string
   */
  protected retrieveNotificationsContainer(itemType: TypeEnum): string {
    if (itemType === TypeEnum.Character) {
      return notificationsContainer.character.details.key;
    } else if (itemType === TypeEnum.Subproduct || itemType === TypeEnum.Term) {
      return notificationsContainer.cat.details.key;
    }
  }
}
