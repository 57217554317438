import { AbstractControl } from '@angular/forms';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { Subscription } from 'rxjs';
import { isObject as _isObject } from 'lodash';

import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { CrudActionEnum } from 'app/shared/services/layout-handler/crud-action.enum';
import { EditionForm } from '../../models/unit/edition-form/edition-form.model';
import { EditionFormArray } from '../../models/unit/edition-form-array/edition-form-array.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FormFactoryService } from '../../services/form-factory/form-factory.service';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { LayoutService as FormLayoutService } from 'app/shared/services/form/layout/layout.service';
import { ManagerService as UnitManager } from '../../services/unit/manager/manager.service';
import { RoleCapabilitiesHandler } from '../../services/role-capabilities-handler/role-capabilities-handler';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Unit } from '../../models/unit/unit.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-cat-item-list',
  template: require('./bolt-cat-item-list.html'),
  styles: [require('./bolt-cat-item-list.scss')]
})
export class BoltCatItemListComponent extends StormComponent implements OnInit, OnDestroy {
  protected editionForm: EditionFormArray;
  protected layoutHandlerSubscription: Subscription;
  protected toggleFlags: any;

  constructor(
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler,
    protected formFactory: FormFactoryService,
    protected formLayout: FormLayoutService,
    protected layoutHandler: LayoutHandlerService,
    protected unitManager: UnitManager,
    protected notificationService: NotificationService,
    protected formConfigService: FormConfigService
  ) {
    super();
    this.initialize();
  }

  ngOnDestroy() {
    this.layoutHandlerSubscription.unsubscribe();
  }

  ngOnInit() {
    this.layoutHandlerSubscription = this.layoutHandler.actionListener.subscribe(
      (currentAction: CrudActionEnum) => {
        switch (currentAction) {
          case CrudActionEnum.FETCHING:
            this.fetchItems();
          break;
          case CrudActionEnum.READING:
            this.destroyEditionForm();
            this.changeStatusToDataFound();
          break;
          case CrudActionEnum.SAVING:
            this.changeStatusToFetchingData();
          break;
          case CrudActionEnum.UPDATING:
            this.createEditionForm();
            this.changeStatusToDataFound();
          break;
          default:
          break;
        }
      }
    );
  }

  /**
   * Indicates if it can edit the localized section.
   *
   * @returns boolean
   */
  protected canEditLocalized(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalizedItemList(ActionTypeEnum.write);
  }

  /**
   * Indicates if it can edit the localized locking status.
   *
   * @returns boolean
   */
  protected canEditLocalizedLockingStatus(): boolean {
    return this.canLockLocalized() || this.canUnlockLocalized();
  }

  /**
   * Indicates if the current user can edit the localized section of the given unit.
   *
   * @param unit Unit
   * @returns boolean
   */
  protected canEditLocalizedSectionOf(unit: Unit): boolean {
    const canIt: boolean =
      this.canEditLocalized() &&
      this.unitManager.canEditLocalizedItemIn(unit);

    return canIt;
  }

  /**
   * Indicates if it can edit the original section.
   *
   * @returns boolean
   */
  protected canEditOriginal(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.write);
  }

  /**
   * Indicates if it can edit the original locking status.
   *
   * @returns boolean
   */
  protected canEditOriginalLockingStatus(): boolean {
    return this.canLockOriginal() || this.canUnlockOriginal();
  }

  /**
   * Indicates if the current user can edit the original section of the given unit.
   *
   * @param unit Unit
   * @returns boolean
   */
  protected canEditOriginalSectionOf(unit: Unit): boolean {
    const canIt: boolean =
      this.canEditOriginal() &&
      this.unitManager.canEditOriginalItemIn(unit);

    return canIt;
  }

  /**
   * Indicates if the user can lock the localized metadata.
   *
   * @returns boolean
   */
  protected canLockLocalized(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalizedItemList(ActionTypeEnum.lock);
  }

  /**
   * Indicates if the user can lock the original metadata.
   *
   * @returns boolean
   */
  protected canLockOriginal(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.lock);
  }

  /**
   * Indicates if the user can toggle the localized locking status of the given unit.
   *
   * @returns boolean
   */
  protected canToggleLocalizedLockingStatusOf(unit: Unit): boolean {
    return this.roleCapabilitiesHandler.canToggleLocalizedLockingStatusOf(unit.localized.lockingStatus.locked);
  }

  /**
   * Indicates if the user can toggle the original locking status of the given unit.
   *
   * @returns boolean
   */
  protected canToggleOriginalLockingStatusOf(unit: Unit): boolean {
    return this.roleCapabilitiesHandler.canToggleOriginalLockingStatusOf(unit.original.lockingStatus.locked);
  }

  /**
   * Indicates if the user can unlock the original metadata.
   *
   * @returns boolean
   */
  protected canUnlockOriginal(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.unlock);
  }

  /**
   * Indicates if the user can unlock the localized metadata.
   *
   * @returns boolean
   */
  protected canUnlockLocalized(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalizedItemList(ActionTypeEnum.unlock);
  }

  /**
   * Creates the edition form.
   *
   * @returns void
   */
  protected createEditionForm(): void {
    const shouldAllowOriginal: boolean = this.canEditOriginal() || this.canEditOriginalLockingStatus();

    const shouldAllowLocalized: boolean = (
      this.unitManager.filters.isLanguageDefined() &&
      (this.canEditLocalized() || this.canEditLocalizedLockingStatus())
    );

    this.editionForm = this.formFactory.buildOnceUnitEditionForm(
      this.getUnits(),
      shouldAllowOriginal,
      shouldAllowLocalized
    );

    this.initEditionForm();
  }

  /**
   * Destroys the edition form.
   *
   * @returns void
   */
  protected destroyEditionForm(): void {
    this.editionForm = undefined;
    this.toggleFlags = undefined;

    this.formFactory.destroyUnitEditionForm();
  }

  /**
   * Enables the form controls for the given child form.
   *
   * @param childForm AbstractControl
   * @param unit Unit
   * @returns void
   */
  protected enableChildForm(childForm: EditionForm, unit: Unit): void {
    childForm.get('_requiredLocalizations').enable();
    childForm.get('_originalLockingStatus').enable();

    if (unit.isComplete()) {
      childForm.get('_useDomestic').enable();
      childForm.get('_localizedName').enable();
      childForm.get('_phonetic').enable();
      childForm.get('_notes').enable();
      childForm.get('_localizedLockingStatus').enable();
    }
  }

  /**
   * Fetches the items.
   *
   * @returns void
   */
  protected fetchItems(): void {
    this.changeStatusToFetchingData();

    this.unitManager.retrieveUnits(
      () => {
        this.layoutHandler.changeToReading();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed retrieving items.', error, notificationsContainer.cat.key);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Returns the units.
   *
   * @returns Unit[]
   */
  protected getUnits(): Unit[] {
    return this.unitManager.units;
  }

  /**
   * Indicates if it has to block the localized locking status switch for the given child form.
   *
   * @param childForm childForm
   * @param unit Unit
   * @returns boolean
   */
  protected hasBlockLocalizedLockingSwitch(childForm: AbstractControl): boolean {
    return this.isDetachModeOn(childForm);
  }

  /**
   * Indicates if it has to block the original locking status switch for the given child form.
   *
   * @param childForm childForm
   * @param unit Unit
   * @returns boolean
   */
  protected hasBlockOriginalLockingSwitch(childForm: AbstractControl): boolean {
    return this.isDetachModeOn(childForm);
  }

  /**
   * Indicates if it has to display the bulk actions.
   *
   * @returns boolean
   */
  protected hasDisplayBulkActions(): boolean {
    return this.isEditionOn();
  }

  /**
   * Indicates if it has to display the content.
   *
   * @returns boolean
   */
  protected hasDisplayContent(): boolean {
    let hasIt: boolean;

    if (this.isEditionOn()) {
      hasIt = (this.editionForm.length > 0);
    } else {
      hasIt = this.unitManager.hasUnits();
    }

    return hasIt;
  }

  /**
   * Indicates if it has to display the detach button.
   *
   * @returns boolean
   */
  protected hasDisplayDetach(): boolean {
    const hasIt: boolean =
      !this.unitManager.product.itemsLockingStatus.locked &&
      (this.canEditOriginal() && this.isEditionOn());

    return hasIt;
  }

  /**
   * Indicates if it has to display the localizations spinner.
   *
   * @returns boolean
   */
  protected hasDisplayLocalizationsSpinner(): boolean {
    return this.unitManager.isLoadingLocalizations();
  }

  /**
   * Indicates if it has to display the localized edition section.
   *
   * @param unit Unit
   * @returns boolean
   */
  protected hasDisplayLocalizedEditionSectionOf(unit: Unit): boolean {
    const hasIt: boolean =
      unit.isComplete() &&
      this.canEditLocalizedSectionOf(unit);

    return hasIt;
  }

  /**
   * Indicates if it has to display the lock status switch in the localized section
   *
   * @param unit Unit
   * @returns boolean
   */
  protected hasDisplayLocalizedLockSwitch(unit: Unit): boolean {
    const hasIt: boolean =
      this.canToggleLocalizedLockingStatusOf(unit) &&
      this.isEditionOn();

    return hasIt;
  }

  /**
   * Indicates if it has to display the original edition section.
   *
   * @param unit Unit
   * @returns boolean
   */
  protected hasDisplayOriginalEditionSectionOf(unit: Unit): boolean {
    const hasIt: boolean = this.canEditOriginalSectionOf(unit) || this.canToggleOriginalLockingStatusOf(unit);
    return hasIt;
  }

  /**
   * Indicates if it has to display the paginator.
   *
   * @returns boolean
   */
  protected hasDisplayPaginator(): boolean {
    return this.layoutHandler.isReading();
  }

  /**
   * Indicates if it has to display the toggle all locking status in localized section.
   *
   * @returns boolean
   */
  protected hasDisplayToggleAllLocalizedLockingStatus(): boolean {
    const hasIt: boolean =
      this.hasDisplayContent() &&
      this.unitManager.canEditSomeLocalizedLockingStatusOnCurrentPage();

    return hasIt;
  }

  /**
   * Indicates if it has to display the localized bulk actions.
   *
   * @returns boolean
   */
  protected hasDisplayToggleAllRequireLocalization(): boolean {
    const hasIt: boolean =
      this.hasDisplayContent() &&
      this.unitManager.canEditSomeOriginalOnCurrentPage();

    return hasIt;
  }

  /**
   * Indicates if it has to display the toggle all locking status in localized section.
   *
   * @returns boolean
   */
  protected hasDisplayToggleAllOriginalLockingStatus(): boolean {
    const hasIt: boolean =
      this.hasDisplayContent() &&
      this.unitManager.canEditSomeOriginalLockingStatusOnCurrentPage();

    return hasIt;
  }

  /**
   * Indicates if it has to display the toggle all use domestic checkbox
   *
   * @returns boolean
   */
  protected hasDisplayToggleAllUseDomestic(): boolean {
    const hasIt: boolean =
      this.hasDisplayContent() &&
      this.unitManager.canEditSomeLocalizedOnCurrentPage();

    return hasIt;
  }

  /**
   * Indicates if it has to expand the item table.
   *
   * @returns boolean
   */
  protected hasExpandTable(): boolean {
    const hasIt: boolean =
      (
        this.hasDisplayDetach() ||
        !this.hasDisplayToggleAllRequireLocalization()
      ) &&
      this.isEditionOn() &&
      (
        this.canEditOriginal() ||
        this.canEditOriginalLockingStatus()
      );

    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.layoutHandler.changeToFetching();
  }

  /**
   * Indicates if the detach mode is on for the given child form.
   *
   * @returns boolean
   */
  protected isDetachModeOn(childForm: AbstractControl): boolean {
    return childForm.disabled;
  }

  /**
   * Initializes the edition form.
   *
   * @returns void
   */
  protected initEditionForm(): void {
    this.toggleFlags = {
      allLocalizedLockingStatusAreChecked: true,
      allOriginalLockingStatusAreChecked: true,
      allRequiredLocalizationsAreChecked: true,
      allUseDomesticAreChecked: true
    };

    for (let x: number = 0; x < this.editionForm.length; x++) {
      const childForm: EditionForm = <EditionForm>this.editionForm.at(x);
      const unit: Unit = this.getUnits()[x];

      this.setOriginalFormStatus(unit, childForm);
      this.setLocalizedFormStatus(unit, childForm);
    }
  }

  /**
   * Indicates if the edition mode is ON.
   *
   * @returns boolean
   */
  protected isEditionOn(): boolean {
    return this.layoutHandler.isUpdating();
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.unitManager.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Toggles all localized locking status checks in the edition form.
   *
   * @returns void
   */
  protected toggleAllLocalizedLockingStatusChecks(): void {
    this.toggleFlags.allLocalizedLockingStatusAreChecked = !this.toggleFlags.allLocalizedLockingStatusAreChecked;
    this.editionForm.updateAllLocalizedLockingStatusChecks(this.toggleFlags.allLocalizedLockingStatusAreChecked);
  }

  /**
   * Toggles all original locking status checks in the edition form.
   *
   * @returns void
   */
  protected toggleAllOriginalLockingStatusChecks(): void {
    this.toggleFlags.allOriginalLockingStatusAreChecked = !this.toggleFlags.allOriginalLockingStatusAreChecked;
    this.editionForm.updateAllOriginalLockingStatusChecks(this.toggleFlags.allOriginalLockingStatusAreChecked);
  }

  /**
   * Toggles all localization-required checks in the edition form.
   *
   * @returns void
   */
  protected toggleAllRequiredLocalizationsChecks(): void {
    this.toggleFlags.allRequiredLocalizationsAreChecked = !this.toggleFlags.allRequiredLocalizationsAreChecked;
    this.editionForm.updateAllRequiredLocalizationsChecks(this.toggleFlags.allRequiredLocalizationsAreChecked);
  }

  /**
   * Toggles all use-domestic checks in the edition form.
   *
   * @returns void
   */
  protected toggleAllUseDomesticAreChecked(): void {
    this.toggleFlags.allUseDomesticAreChecked = !this.toggleFlags.allUseDomesticAreChecked;
    this.editionForm.updateAllUseDomesticAreChecked(this.toggleFlags.allUseDomesticAreChecked);
  }

  /**
   * Toggle the detach mode in the given child form.
   *
   * @param childForm AbstractControl
   * @param unit Unit
   * @returns void
   */
  protected toggleDetachModeOn(childForm: EditionForm, unit: Unit): void {
    if (this.isDetachModeOn(childForm)) {
      this.enableChildForm(childForm, unit);
    } else {
      childForm.disable();
    }

    this.synchronizeLocalizedLockingStatusOn(childForm);
    this.synchronizeOriginalLockingStatusOn(childForm);
    this.synchronizeRequiredLocalizationsOn(childForm);
    this.synchronizeUseDomesticOn(childForm);
  }

  /**
   * Set the child form status for the given localized item.
   *
   * @param unit Unit
   * @param childForm AbstractControl
   */
  protected setLocalizedFormStatus(unit: Unit, childForm: EditionForm): void {
    if (unit.isComplete()) {
      if (this.canToggleLocalizedLockingStatusOf(unit)) {
        if (childForm.get('_localizedLockingStatus').enabled) {
          this.toggleFlags.allLocalizedLockingStatusAreChecked = (
            this.toggleFlags.allLocalizedLockingStatusAreChecked &&
            childForm.get('_localizedLockingStatus').value.locked
          );
        }
      } else {
        this.toggleFlags.allLocalizedLockingStatusAreChecked = false;
      }

      if (this.canEditLocalizedSectionOf(unit)) {
        if (childForm.get('_useDomestic').enabled) {
          this.toggleFlags.allUseDomesticAreChecked = (
            this.toggleFlags.allUseDomesticAreChecked &&
            childForm.get('_useDomestic').value
          );
        }

        childForm.updateDomesticUsageDependencies();
      } else {
        this.toggleFlags.allUseDomesticAreChecked = false;
      }
    }
  }

  /**
   * Set the child form status for the given original item.
   *
   * @param unit Unit
   * @param childForm AbstractControl
   */
  protected setOriginalFormStatus(unit: Unit, childForm: EditionForm): void {
    if (this.canEditOriginalLockingStatus() && childForm.get('_originalLockingStatus').enabled) {
      this.toggleFlags.allOriginalLockingStatusAreChecked = (
        this.toggleFlags.allOriginalLockingStatusAreChecked &&
        childForm.get('_originalLockingStatus').value.locked
      );
    }

    if (this.canEditOriginalSectionOf(unit)) {
      if (childForm.get('_requiredLocalizations').enabled) {
        this.toggleFlags.allRequiredLocalizationsAreChecked = (
          this.toggleFlags.allRequiredLocalizationsAreChecked &&
          childForm.get('_requiredLocalizations').value
        );
      }
    } else {
      if (this.canToggleOriginalLockingStatusOf(unit)) {
        childForm.get('_requiredLocalizations').disable();
      }
    }
  }

  /**
   * Synchronizes the localized locking status check in the given child form.
   *
   * @param childForm AbstractControl
   * @returns void
   */
  protected synchronizeLocalizedLockingStatusOn(childForm: EditionForm): void {
    const localizedField: AbstractControl = childForm.get('_localizedLockingStatus');

    if (_isObject(localizedField)) {
      if (localizedField.value.locked) {
        let isAllChecked: boolean = true;
        let x: number = 0;

        while (isAllChecked && (x < this.editionForm.length)) {
          const xChildForm: EditionForm = <EditionForm>this.editionForm.at(x);

          if (xChildForm.get('_localizedLockingStatus').enabled) {
            isAllChecked = xChildForm.get('_localizedLockingStatus').value.locked;
          }

          x++;
        }

        this.toggleFlags.allLocalizedLockingStatusAreChecked = isAllChecked;
      } else {
        this.toggleFlags.allLocalizedLockingStatusAreChecked = false;
      }
    }

    childForm.updateValueAndValidity();
  }

  /**
   * Synchronizes the original locking status check in the given child form.
   *
   * @param childForm AbstractControl
   * @returns void
   */
  protected synchronizeOriginalLockingStatusOn(childForm: EditionForm): void {
    if (childForm.get('_originalLockingStatus').value.locked) {
      let isAllChecked: boolean = true;
      let x: number = 0;

      while (isAllChecked && (x < this.editionForm.length)) {
        const xChildForm: EditionForm = <EditionForm>this.editionForm.at(x);

        if (xChildForm.get('_originalLockingStatus').enabled) {
          isAllChecked = xChildForm.get('_originalLockingStatus').value.locked;
        }

        x++;
      }

      this.toggleFlags.allOriginalLockingStatusAreChecked = isAllChecked;
    } else {
      this.toggleFlags.allOriginalLockingStatusAreChecked = false;
    }
  }

  /**
   * Synchronizes the localization-required check in the given child form.
   *
   * @param childForm AbstractControl
   * @returns void
   */
  protected synchronizeRequiredLocalizationsOn(childForm: EditionForm): void {
    if (childForm.get('_requiredLocalizations').value) {
      let isAllChecked: boolean = true;
      let x: number = 0;

      while (isAllChecked && (x < this.editionForm.length)) {
        const xChildForm: EditionForm = <EditionForm>this.editionForm.at(x);

        if (xChildForm.enabled) {
          isAllChecked = xChildForm.get('_requiredLocalizations').value;
        }

        x++;
      }

      this.toggleFlags.allRequiredLocalizationsAreChecked = isAllChecked;
    } else {
      this.toggleFlags.allRequiredLocalizationsAreChecked = false;
    }
  }

  /**
   * Synchronizes the use-domestic check in the given child form.
   *
   * @param childForm AbstractControl
   * @returns void
   */
  protected synchronizeUseDomesticOn(childForm: EditionForm): void {
    const localizedField: AbstractControl = childForm.get('_useDomestic');

    if (_isObject(localizedField)) {
      if (!this.isDetachModeOn(childForm)) {
        childForm.updateDomesticUsageDependencies();
      }

      if (localizedField.value) {
        let isAllChecked: boolean = true;
        let x: number = 0;

        while (isAllChecked && (x < this.editionForm.length)) {
          const xChildForm: EditionForm = <EditionForm>this.editionForm.at(x);

          if (xChildForm.enabled) {
            isAllChecked = xChildForm.get('_useDomestic').value;
          }

          x++;
        }

        this.toggleFlags.allUseDomesticAreChecked = isAllChecked;
      } else {
        this.toggleFlags.allUseDomesticAreChecked = false;
      }
    }
  }
}
