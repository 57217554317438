import { TitleForm } from '../title-form.model';


export class EpisodeForm extends TitleForm {

  constructor(fieldSpecs: any, defaultValues: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
    this.setStatus();
  }

  toJson(): any {
    const data: any = {
      ...super.toJson()
    };

    return data;
  }

  protected setupFields(): void {
    super.setupFields();
    this.setupFunctionalMetadata();
    this.setupFunctionalMetadataSplitFields();
    this.setupNotApplicable();
  }
}
