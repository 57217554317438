import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';

import {
  BoltPageCharacterDetailsComponent
} from './components/bolt-page-character-details/bolt-page-character-details.component';

import { BoltPageCharacterComponent } from './components/bolt-page-character/bolt-page-character.component';
import { BoltPageCharacterListComponent } from './components/bolt-page-character-list/bolt-page-character-list.component';
import { modulesPath } from '../auth/services/role/modules-path';


const characterConf: any = {
  component: BoltPageCharacterDetailsComponent,
  canActivate: [AuthGuard],
  data: {
    boltAuthAware: modulesPath.character.details.path,
    title: 'Character',
  }
};

export const characterRoutes: Routes = [
  {
    path: 'character',
    component: BoltPageCharacterComponent,
    children: [
      {
        path: '',
        component: BoltPageCharacterListComponent,
        canActivate: [AuthGuard],
        data: {
          boltAuthAware: modulesPath.menu.character.path,
          title: 'Character List',
        }
      },
      {
        path: ':characterId',
        ...characterConf
      },
      {
        // The characterName is not necessary anymore, but we keep this entry only for backward compatibility.
        path: ':characterId/:characterName',
        ...characterConf
      }
    ]
  }
];

export const characterModuleRouting: ModuleWithProviders = RouterModule.forChild(characterRoutes);
