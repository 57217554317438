import { includes as _includes } from 'lodash';

import { CategoryEnum } from './category.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class Category {
  protected _value: CategoryEnum;

  constructor(value: CategoryEnum) {
    this.initialize(value);
  }

  get value(): CategoryEnum {
    return this._value;
  }

  /**
   * Indicates if it is a "Character".
   *
   * @returns boolean
   */
  isCharacter(): boolean {
    return this.is(CategoryEnum.Character);
  }

  /**
   * Indicates if it is equals to the given category.
   *
   * @param otherCategory Category
   * @returns boolean
   */
  isEqualsTo(otherCategory: Category): boolean {
    return this.is(otherCategory.value);
  }

  /**
   * Indicates if it is a "Game".
   *
   * @returns boolean
   */
  isGame(): boolean {
    return this.is(CategoryEnum.Game);
  }

  /**
   * Indicates if it is a "Location".
   *
   * @returns boolean
   */
  isLocation(): boolean {
    return this.is(CategoryEnum.Location);
  }

  /**
   * Indicates if it is an "Other".
   *
   * @returns boolean
   */
  isOther(): boolean {
    return this.is(CategoryEnum.Other);
  }

  /**
   * Indicates if it is a "Phrase".
   *
   * @returns boolean
   */
  isPhrase(): boolean {
    return this.is(CategoryEnum.Phrase);
  }

  /**
   * Indicates if it is a "Song".
   *
   * @returns boolean
   */
  isSong(): boolean {
    return this.is(CategoryEnum.Song);
  }

  /**
   * Indicates if it is a "Toy".
   *
   * @returns boolean
   */
  isToy(): boolean {
    return this.is(CategoryEnum.Toy);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this._value.toString();
  }

  /**
   * Initializes the instance.
   *
   * @param value CategoryEnum
   * @returns void
   */
  protected initialize(value: CategoryEnum): void {
    const normalizedValue: string = String(value).trim().toUpperCase();

    if (_includes(CategoryEnum, normalizedValue)) {
      this._value = value;
    } else {
      throw new ErrorHelper('Invalid value given for a category.');
    }
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value CategoryEnum
   * @returns boolean
   */
  protected is(value: CategoryEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
