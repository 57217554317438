import { Affix, Language } from '@bolt/ui-shared/master-data';

import { LocalizedInterface, Localized } from 'app/modules/common/models/localized.model';
import { TalentMetadataInterface } from './talent-metadata.model';

export interface TalentInterface extends LocalizedInterface {
  noMiddleName?: boolean;
  noLastName?: boolean;
  noPrefix?: boolean;
  noSuffix?: boolean;
  appleId?: string;
  firstName?: string;
  fullName?: string;
  imdbId?: string;
  lastName?: string;
  middleName?: string;
  displayName?: string;
  originalLanguageId?: number | Language;
  prefixId?: number | Affix;
  suffixId?: number | Affix;
  getFullName?(): string;
  localizedPrefixValue?(): number | string;
  localizedSuffixValue?(): number | string;
}

export class Talent extends Localized implements TalentInterface {
  protected _noMiddleName: boolean;
  protected _noLastName: boolean;
  protected _noPrefix: boolean;
  protected _noSuffix: boolean;
  protected _appleId: string;
  protected _firstName: string;
  protected _imdbId: string;
  protected _lastName: string;
  protected _middleName: string;
  protected _displayName: string;
  protected _originalLanguageId: number | Language;
  protected _prefixId: number | Affix;
  protected _suffixId: number | Affix;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
  }

  get noMiddleName(): boolean {
    return this._noMiddleName;
  }

  set noMiddleName(value: boolean) {
    this._noMiddleName = value;
  }

  get noLastName(): boolean {
    return this._noLastName;
  }

  set noLastName(value: boolean) {
    this._noLastName = value;
  }

  get noPrefix(): boolean {
    return this._noPrefix;
  }

  set noPrefix(value: boolean) {
    this._noPrefix = value;
  }

  get noSuffix(): boolean {
    return this._noSuffix;
  }

  set noSuffix(value: boolean) {
    this._noSuffix = value;
  }

  get prefixId(): number | Affix {
    return this._prefixId;
  }

  set prefixId(prefixId: number | Affix) {
    this._prefixId = prefixId;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(firstName: string) {
    this._firstName = firstName;
  }

  get middleName(): string {
    return this._middleName;
  }

  set middleName(middleName: string) {
    this._middleName = middleName;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(lastName: string) {
    this._lastName = lastName;
  }

  get displayName(): string {
    return this._displayName;
  }

  set displayName(displayName: string) {
    this._displayName = displayName;
  }

  get suffixId(): number | Affix {
    return this._suffixId;
  }

  set suffixId(suffixId: number | Affix) {
    this._suffixId = suffixId;
  }

  get appleId(): string {
    return this._appleId;
  }

  set appleId(appleId: string) {
    this._appleId = appleId;
  }

  get imdbId(): string {
    return this._imdbId;
  }

  set imdbId(imdbId: string) {
    this._imdbId = imdbId;
  }

  get originalLanguageId(): number | Language {
    return this._originalLanguageId;
  }

  set originalLanguageId(originalLanguageId: number | Language) {
    this._originalLanguageId = originalLanguageId;
  }

  get localizations(): TalentMetadataInterface[] {
    return this._localizations;
  }

  set localizations(localizations: TalentMetadataInterface[]) {
    this._localizations = localizations;
  }

  get fullName(): string {
    return this.getFullName();
  }

  set fullName(fullName: string) { }

  /**
   * Returns the prefix value using the locale.
   *
   * @returns number|string
   */
  localizedPrefixValue(): number | string {
    let prefix: number | string;

    if (typeof this._prefixId === 'number') {
      prefix = this._prefixId;
    } else if (this._prefixId instanceof Affix) {
      prefix = (this._prefixId as Affix).localizedValue(this.locale);
    }

    return prefix;
  }

  /**
   * Returns the suffix value using the locale.
   *
   * @returns number|string
   */
  localizedSuffixValue(): number | string {
    let suffix: number | string;

    if (typeof this._suffixId === 'number') {
      suffix = this._suffixId;
    } else if (this._suffixId instanceof Affix) {
      suffix = (this._suffixId as Affix).localizedValue(this.locale);
    }

    return suffix;
  }

  /**
   * Returns the full name using the given separator.
   *
   * @param separator string
   * @returns string
   */
  getFullName(separator: string = ' '): string {
    if (this._displayName) {
      return this._displayName;
    }
    return [
      this.localizedPrefixValue(),
      this._firstName,
      this._middleName,
      this._lastName,
      this.localizedSuffixValue()
    ].join(separator);
  }

  /**
   * Returns the a raw object using the inner data.
   *
   * @returns object
   */
  getRawObject(): object {
    const names = Object.getOwnPropertyNames(Talent.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Talent.prototype, name);
      return !!result.get;
    });

    const object = new Object();
    getters.forEach((key) => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return Object.assign(new Object(), super.getRawObject(), object);
  }

  /**
   * Returns is as a string.
   *
   * @returns string
   */
  toString(): string {
    return this.getFullName();
  }
}
