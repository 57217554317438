import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from './../common/common.module';
import { MergeModule } from '../merge/merge.module';

import { BoltPageTalentComponent } from './components/bolt-page-talent/bolt-page-talent.component';
import { BoltPageTalentDetailsComponent } from './components/bolt-page-talent-details/bolt-page-talent-details.component';
import { BoltPageTalentListComponent } from './components/bolt-page-talent-list/bolt-page-talent-list.component';
import { BoltTalentLevelDataComponent } from './components/bolt-talent-level-data/bolt-talent-level-data.component';
import { BoltTalentListComponent } from './components/bolt-talent-list/bolt-talent-list.component';
import { BoltTalentListFiltersComponent } from './components/bolt-talent-list-filters/bolt-talent-list-filters.component';

import {
  BoltTalentLocalizedMetadataFormComponent
} from './components/bolt-talent-localized-metadata-form/bolt-talent-localized-metadata-form.component';

import { BoltTalentMetadataDetailsComponent } from './components/bolt-talent-metadata-details/bolt-talent-metadata-details.component';
import { BoltTalentMetadataFiltersComponent } from './components/bolt-talent-metadata-filters/bolt-talent-metadata-filters.component';
import { BoltTalentMetadataListComponent } from './components/bolt-talent-metadata-list/bolt-talent-metadata-list.component';
import { BoltTalentMetadataManagerComponent } from './components/bolt-talent-metadata-manager/bolt-talent-metadata-manager.component';

import {
  BoltTalentOriginalMetadataFormComponent
} from './components/bolt-talent-original-metadata-form/bolt-talent-original-metadata-form.component';

import { FormFactoryService } from './services/form-factory/form-factory.service';
import { MetadataExtractorService } from './services/metadata-extractor/metadata-extractor.service';
import { TalentManager } from './helpers/talent-manager/talent-manager.helper';
import { talentModuleRouting } from './talent.routing';
import { TalentService } from './services/talent.service';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    MergeModule,
    talentModuleRouting,
  ],
  declarations: [
    BoltPageTalentComponent,
    BoltPageTalentListComponent,
    BoltPageTalentDetailsComponent,
    BoltTalentLevelDataComponent,
    BoltTalentListComponent,
    BoltTalentListFiltersComponent,
    BoltTalentLocalizedMetadataFormComponent,
    BoltTalentMetadataDetailsComponent,
    BoltTalentMetadataFiltersComponent,
    BoltTalentMetadataListComponent,
    BoltTalentMetadataManagerComponent,
    BoltTalentOriginalMetadataFormComponent
  ],
  exports: [
    BoltPageTalentComponent,
    BoltPageTalentListComponent,
    BoltPageTalentDetailsComponent,
    BoltTalentLocalizedMetadataFormComponent,
    BoltTalentMetadataManagerComponent,
    BoltTalentOriginalMetadataFormComponent
  ],
  providers: [
    TalentManager,
    TalentService,
    FormFactoryService,
    MetadataExtractorService
  ]
})
export class TalentModule { }
