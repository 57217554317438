import * as moment from 'moment';
import { isNumber as _isNumber } from 'lodash';

import { Localization } from './localization.model';
import { SubtitleDetail } from './subtitle-detail.model';
import { TitleType } from 'app/modules/title/models/title.model';


export class Subtitle extends Localization {
  protected _id: number;
  protected _lastModifiedBy: string;
  protected _lastModifiedDate: moment.Moment;
  protected _localizations: Localization[];
  protected _subtitleDetails: SubtitleDetail[];
  protected _titleId: number;
  protected _titleType: TitleType;

  constructor(data: any) {
    super(data);
  }

  get id(): number {
    return this._id;
  }

  get lastModifiedBy(): string {
    return this._lastModifiedBy;
  }

  get lastModifiedDate(): moment.Moment {
    return this._lastModifiedDate;
  }

  get localizations(): Localization[] {
    return this._localizations;
  }

  get subtitleDetails(): SubtitleDetail[] {
    return this._subtitleDetails;
  }

  set subtitleDetails(subtitleDetails: SubtitleDetail[]) {
    this._subtitleDetails = subtitleDetails;
  }

  get titleId(): number {
    return this._titleId;
  }

  get titleType(): TitleType {
    return this._titleType;
  }

  /**
   * Adds the given subtitle details.
   *
   * @param subtitleDetail SubtitleDetail
   * @returns void
   */
  addSubtitleDetail(subtitleDetail: SubtitleDetail): void {
    this._subtitleDetails.push(subtitleDetail);
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns object[]
   */
  asEndpointData(): object[] {
    const data: object[] = new Array();

    this.subtitleDetails.forEach(
      (subtitleDetail: SubtitleDetail) => {
        data.push(subtitleDetail.asEndpointData());
      }
    );

    return data;
  }

  /**
   * Indicates if there are localizations.
   *
   * @returns boolean
   */
  hasLocalizations(): boolean {
    const hasIt: boolean = (this.localizations.length > 0);
    return hasIt;
  }

  /**
   * Indicates if there are subtitle details.
   *
   * @returns boolean
   */
  hasSubtitleDetails(): boolean {
    const hasIt: boolean = (this.subtitleDetails.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it is persisted in database.
   *
   * @returns boolean
   */
  isPersisted(): boolean {
    const isIt: boolean = _isNumber(this.id);
    return isIt;
  }

  /**
   * Reset the subtitle details.
   *
   * @returns void
   */
  resetSubtitleDetails(): void {
    this._subtitleDetails = new Array();
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    super.initialize(data);

    this._id = (
      (data && data.hasOwnProperty('id'))
        ? data.id
        : undefined
    );

    this._lastModifiedBy = (
      (data && data.hasOwnProperty('lastModifiedBy'))
        ? data.lastModifiedBy
        : undefined
    );

    this._lastModifiedDate = (
      (data && data.hasOwnProperty('lastModified'))
        ? moment(data.lastModified)
        : undefined
    );

    this._titleId = (
      (data && data.hasOwnProperty('titleId'))
        ? data.titleId
        : undefined
    );

    this._titleType = (
      (data && data.hasOwnProperty('titleType'))
        ? data.titleType
        : undefined
    );

    this.populateLocalizations(data);
    this.populateSubtitle(data);
  }

  /**
   * Populates the localizations.
   *
   * @param data any
   * @returns void
   */
  protected populateLocalizations(data: any): void {
    if (data && data.hasOwnProperty('localizations')) {
      this._localizations = data.localizations.map(
        (rawData: any) => new Localization(rawData)
      );
    } else {
      this._localizations = new Array();
    }
  }

  /**
   * Populates the subtitles.
   *
   * @param data any
   * @returns void
   */
  protected populateSubtitle(data: any): void {
    this.resetSubtitleDetails();

    if (data && data.hasOwnProperty('subtitles')) {
      data.subtitles.forEach(
        (rawSubtitleDetail: any) => {
          const subtitleDetail: SubtitleDetail = new SubtitleDetail(rawSubtitleDetail);
          this.addSubtitleDetail(subtitleDetail);
        }
      );
    }
  }
}
