import { Component, OnDestroy } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';

import { ManagerService } from '../../services/project/manager/manager.service';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-project',
  template: require('./bolt-project.html')
})
export class BoltProjectComponent implements OnDestroy {
  protected containers: typeof notificationsContainer = notificationsContainer;

  constructor(
    protected layoutHandler: LayoutHandlerService,
    protected notificationService: NotificationService,
    protected projectManager: ManagerService
  ) {
    this.layoutHandler.changeToReading();
  }

  ngOnDestroy() {
    this.projectManager.reset();
  }
}
