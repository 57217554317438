import { Details } from '../details/details.model';
import { ExportError } from '../../export-error/export-error.model';
import { Status } from '../status/status.model';


export class ResultReport {
  protected _id: number;
  protected _exportType: string;
  protected _jobStatus: Status;
  protected _exported: Details[];
  protected _errors: ExportError[];
  protected _isArchive: boolean;

  constructor(data: any) {
    if (data && data.hasOwnProperty('jobStatus') && data.hasOwnProperty('id') && data.hasOwnProperty('exported')) {
      this._id = data.id;
      this._exportType = (data.hasOwnProperty('exportType') ? data.exportType : undefined);
      this._exported = data.exported.map((detail: any) => new Details(detail));
      this._isArchive = data.hasOwnProperty('archived') && this._exported.length > 0;
      this._errors = data.errors.map((error: any) => new ExportError(error));

      this.updateJobStatus(new Status(data));
    } else {
      throw new Error('Cannot get needed fields for a Metadata Export Result Report.');
    }
  }

  get id(): number {
    return this._id;
  }

  get exportType(): string {
    return this._exportType;
  }

  get exported(): Details[] {
    return this._exported;
  }

  get errors(): ExportError[] {
    return this._errors;
  }

  get isArchive(): boolean {
    return this._isArchive;
  }

  get jobStatus(): Status {
    return this._jobStatus;
  }

  /**
   * Updates the job status.
   *
   * @param jobStatus Status
   * @returns void
   */
  public updateJobStatus(jobStatus: Status): void {
    this._jobStatus = jobStatus;
  }
}
