import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Language } from '@bolt/ui-shared/master-data';
import { capitalize as _capitalize, isObject as _isObject, isNumber as _isNumber, isString as _isString } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Category } from '../../models/category/category.model';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { ManagerService as UnitManager } from '../../services/unit/manager/manager.service';
import { Type } from '../../models/type/type.model';
import { TypeEnum } from '../../models/type/type.enum';


@Component({
  selector: 'bolt-cat-filters',
  template: require('./bolt-cat-filters.html'),
  styles: [require('./bolt-cat-filters.scss')]
})
export class BoltCatFiltersComponent {
  @ViewChild('name')
  protected nameInput: ElementRef;

  protected categories: SelectionItem[];
  protected languages: SelectionItem[];
  protected nameObserver: BehaviorSubject<undefined>;

  constructor(
    protected appConfig: AppConfigProvider,
    protected layoutHandler: LayoutHandlerService,
    protected listLayoutProvider: ListLayoutProvider,
    protected unitManager: UnitManager,
    protected notificationService: NotificationService
  ) {
    this.initialize();
  }

  get maxValuesAsString(): number {
    return this.appConfig.get('ux.multiSelect.maxValuesAsString');
  }

  get scrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Filters using the given category or type.
   *
   * @param event any
   * @returns void
   */
  protected filterByTypeCategory(event: any): void {
    if (_isObject(event.originalEvent)) {
      if (_isString(event.value)) {
        const category: Category = new Category(event.value);
        this.unitManager.filterByCategory(category);
      } else {
        this.unitManager.removeCategoryFilter();
      }
    }
  }

  /**
   * Filters using the given language.
   *
   * @param event any
   * @returns void
   */
  protected filterByLanguage(event: any): void {
    if (_isObject(event.originalEvent)) {
      if (_isNumber(event.value)) {
        const language: Language = this.listLayoutProvider.getLanguageById(event.value);
        this.unitManager.filterByLanguage(language);
      } else {
        this.unitManager.removeLanguageFilter();
      }
    }
  }

  /**
   * Filters using the input name.
   *
   * @returns void
   */
  protected filterByName(): void {
    this.nameObserver.next(undefined);
  }

  /**
   * Indicates if it has to block the category list.
   *
   * @returns boolean
   */
  protected hasBlockCategories(): boolean {
    const hasIt: boolean = (
      !this.layoutHandler.isReading() || !this.hasCategories() || this.unitManager.isLoadingLocalizations()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the language list.
   *
   * @returns boolean
   */
  protected hasBlockLanguages(): boolean {
    const hasIt: boolean = (
      !this.layoutHandler.isReading() || !this.hasLanguages() || this.unitManager.isLoadingLocalizations()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the search input.
   *
   * @returns boolean
   */
  protected hasBlockSearch(): boolean {
    const hasIt: boolean = (!this.layoutHandler.isReading() || this.unitManager.isLoadingLocalizations());
    return hasIt;
  }

  /**
   * Indicates if it has categories.
   *
   * @returns boolean
   */
  protected hasCategories(): boolean {
    const hasIt: boolean = (this.categories.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has languages.
   *
   * @returns boolean
   */
  protected hasLanguages(): boolean {
    const hasIt: boolean = (this.languages.length > 0);
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.loadCategories();
    this.loadLanguages();
    this.setupNameObserver();
  }

  /**
   * Loads the categories.
   *
   * @returns void
   */
  protected loadCategories(): void {
    this.categories = new Array();

    this.loadCategoriesFor(TypeEnum.Character);
    this.loadCategoriesFor(TypeEnum.Subproduct);
    this.loadCategoriesFor(TypeEnum.Term);
  }

  /**
   * Loads the categories for the given type.
   *
   * @param type TypeEnum
   * @returns void
   */
  protected loadCategoriesFor(type: TypeEnum): void {
    Type.getCategoryMap().get(type).forEach(
      (aCategory: Category) => {
        const value: string = aCategory.toString();
        const label: string = _capitalize(value);

        this.categories.push(
          new SelectionItem(label, value, undefined, type.toString())
        );
      }
    );
  }

  /**
   * Loads the languages.
   *
   * @returns void
   */
  protected loadLanguages(): void {
    this.languages = this.listLayoutProvider.getLanguages(true).sort(
      (item1: SelectionItem, item2: SelectionItem) => {
        if (item1.label > item2.label) {
          return 1;
        } else if (item1.label < item2.label) {
          return -1;
        } else {
          return 0;
        }
      }
    );
  }

  /**
   * Set up an observer for any value change of the name.
   *
   * @returns void
   */
  protected setupNameObserver(): void {
    this.nameObserver = new BehaviorSubject(undefined);

    this.nameObserver.asObservable().pipe(
      debounceTime(200)
    ).subscribe(
      () => {
        const name: string = this.nameInput.nativeElement.value.trim();

        if (name) {
          this.unitManager.filterByName(name);
        } else {
          this.unitManager.removeNameFilter();
        }
      }
    );
  }
}
