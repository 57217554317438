import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from '../common/common.module';

import { BoltMergeCharacterListComponent } from './components/bolt-merge-character-list/bolt-merge-character-list.component';
import { BoltMergeCharacterManagerComponent } from './components/bolt-merge-character-manager/bolt-merge-character-manager.component';
import { BoltMergeRoleListComponent } from './components/bolt-merge-role-list/bolt-merge-role-list.component';
import { BoltMergeRoleManagerComponent } from './components/bolt-merge-role-manager/bolt-merge-role-manager.component';
import { BoltMergeTalentListComponent } from './components/bolt-merge-talent-list/bolt-merge-talent-list.component';
import { BoltMergeTalentManagerComponent } from './components/bolt-merge-talent-manager/bolt-merge-talent-manager.component';
import { MergeService } from './services/merge.service';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule
  ],
  declarations: [
    BoltMergeCharacterListComponent,
    BoltMergeCharacterManagerComponent,
    BoltMergeRoleListComponent,
    BoltMergeRoleManagerComponent,
    BoltMergeTalentListComponent,
    BoltMergeTalentManagerComponent
  ],
  providers: [
    MergeService
  ],
  exports: [
    BoltMergeCharacterManagerComponent,
    BoltMergeRoleManagerComponent,
    BoltMergeTalentManagerComponent
  ]
})
export class MergeModule { }
