import { Injectable } from '@angular/core';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isObject as _isObject, isString as _isString, remove as _remove, isUndefined as _isUndefined } from 'lodash';
import { Affix, AffixType, StormList, StormListItem, StormListType } from '@bolt/ui-shared/master-data';

import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { LocalizedDataForm } from '../../models/form/localized-data/localized-data-form.model';
import { MetadataExtractorService } from '../metadata-extractor/metadata-extractor.service';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';


@Injectable()
export class FormFactoryService {
  private fieldSpecs: any;
  private suffix: SelectionItem[];
  private prefix: SelectionItem[];

  constructor(
    private formConfig: FormConfigService,
    private stormListProvider: StormListsProvider,
    private metadataExtractor: MetadataExtractorService
  ) {
    this.fieldSpecs = this.formConfig.get('talent.fields');
    this.setupLists();
  }

  /**
   * Builds a localized data form.
   *
   * @param defaultValues any
   * @returns LocalizedDataForm
   */
  buildLocalizedDataForm(defaultValues?: any, mapDefaultValues: boolean = true): LocalizedDataForm {
    const values = mapDefaultValues ? this.mapDefaultValues(defaultValues) : defaultValues;
    const localizedDataForm: LocalizedDataForm = new LocalizedDataForm(this.fieldSpecs, values);
    return localizedDataForm;
  }

  /**
   * Builds an original data form.
   *
   * @param defaultValues any
   * @returns OriginalDataForm
   */
  buildOriginalDataForm(defaultValues?: any, mapDefaultValues: boolean = true): OriginalDataForm {
    const values = mapDefaultValues ? this.mapDefaultValues(defaultValues) : defaultValues;
    const originalDataForm: OriginalDataForm = new OriginalDataForm(this.fieldSpecs, values);
    return originalDataForm;
  }

  /**
   * Get the current prefix list.
   *
   * @returns SelectionItem[]
   */
  getPrefixList(): SelectionItem[] {
    return this.prefix;
  }

  /**
   * Get the current suffix list.
   *
   * @returns SelectionItem[]
   */
  getSuffixList(): SelectionItem[] {
    return this.suffix;
  }

  /**
   * Maps the given default values.
   *
   * @param values any
   * @returns object
   */
  private mapDefaultValues(values: any): object {
    if (!_isUndefined(values) && _isString(values.fullName) && values.fullName.trim() !== '') {
      const metadata: any = this.metadataExtractor.extract(values.fullName);
      metadata.noMiddleName = !metadata.middleName;
      metadata.noPrefix = !metadata.prefixId;
      metadata.noSuffix = !metadata.suffixId;

      Object.assign(values, metadata);
    }

    return values;
  }

  /**
   * Set up the prefix and suffix list.
   *
   * @returns void
   */
  private setupLists(): void {
    this.suffix = new Array();
    this.prefix = new Array();

    this.stormListProvider.getList(StormListType.affix).subscribe(
      (list: StormList) => {
        list.collection.forEach(
          (listItem: StormListItem) => {
            const affix: Affix = listItem.value;
            const item: SelectionItem = new SelectionItem(affix.value, affix.id, affix);

            if (affix.type === AffixType.PREFIX) {
              this.prefix.push(item);
            } else {
              this.suffix.push(item);
            }
          }
        );
      }
    );
  }
}
