import { LocalizationInterface } from 'app/modules/common/models/localization.model';
import { SeasonInterface, Season } from './season.model';


/**
 * @deprecated 2022-04-11. This interface is candidate to be removed.
 */
export interface SeasonMetadataInterface extends LocalizationInterface, SeasonInterface { }

/**
 * @deprecated 2022-04-11. This class is candidate to be removed and use `Season` instead of.
 */
export class SeasonMetadata
  extends Season
  implements SeasonMetadataInterface {

  // generic metadata attributes
  protected _rootId: number;
  protected _localized: boolean;

  protected _originalData: any;
  protected _inheritedAttributes: string[];

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
  }

  get rootId(): number {
    return this._rootId;
  }

  set rootId(rootId: number) {
    this._rootId = rootId;
  }

  get localized(): boolean {
    return this._localized;
  }

  set localized(localized: boolean) {
    this._localized = localized;
  }

  get originalData(): any {
    return this._originalData || this;
  }

  set originalData(originalData: any) {
    this._originalData = originalData;
  }

  /**
   * @deprecated 2019-11-12. Use `localizedFields`.
   */
  get inheritedAttributes(): string[] {
    return this._inheritedAttributes;
  }

  /**
   * @deprecated 2019-11-12. Use `localizedFields`.
   */
  set inheritedAttributes(inheritedAttributes: string[]) {
    this._inheritedAttributes = inheritedAttributes;
  }

  /**
   * @deprecated 2019-11-12. Use `isLocalizedField`.
   */
  isInheritedAttribute(attribute: string) {
    return this._inheritedAttributes.indexOf(attribute) !== -1;
  }
}
