import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { isObject as _isObject } from 'lodash';

import { AuthHelper } from '../../helpers/auth/auth.helper';
import { UserManager } from 'app/modules/user/helpers/user-manager.helper';


@Component({
  selector: 'bolt-auth-access-denied',
  template: require('./bolt-auth-access-denied.html'),
  styles: [require('./bolt-auth-access-denied.scss')]
})
export class BoltAuthAccessDeniedComponent implements OnDestroy {
  protected userManagerListener: Subscription;
  protected boltSupport: string;

  constructor(
    protected authHelper: AuthHelper,
    protected router: Router,
    protected userManager: UserManager
  ) {
    this.userManagerListener = this.userManager.fetchAuthenticatedUser().subscribe(
      () => this.router.navigate(['/dashboard']),
      () => this.boltSupport = this.authHelper.getSupportPageUrl()
    );
  }

  ngOnDestroy() {
    if (_isObject(this.userManagerListener)) {
      this.userManagerListener.unsubscribe();
    }
  }
}
