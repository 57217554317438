import { isArray as _isArray, clone as _clone, isFunction as _isFunction, isUndefined as _isUndefined } from 'lodash';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class MemoryPager {
  // TODO: Use getters/setters instead of Typescript get/set. Remove underscore for attributes.
  protected _backup: any[];
  protected _currentPage: any[];
  protected _filteringCriteria: CallableFunction;
  protected _pageNumber: number;
  protected _pageSize: number;
  protected _records: any[];
  protected _sortingCriteria: CallableFunction;
  protected _totalPages: number;

  constructor(pageSize: number, sortingCriteria?: CallableFunction, filteringCriteria?: CallableFunction) {
    this.doSetPageSize(pageSize);
    this.doSetFilterCriteria(filteringCriteria);
    this.doSetSortingCriteria(sortingCriteria);
    this.reset();
  }

  get filteringCriteria(): CallableFunction {
    return this._filteringCriteria;
  }

  get pageNumber(): number {
    return this._pageNumber;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  get records(): any[] {
    return this._records;
  }

  get sortingCriteria(): CallableFunction {
    return this._sortingCriteria;
  }

  get totalPages(): number {
    return this._totalPages;
  }

  /**
   * Returns the records in the current page.
   *
   * @returns any[]
   */
  getCurrentPage(): any[] {
    if (_isUndefined(this._currentPage)) {
      const start: number = (this.pageSize * this.pageNumber);
      const end: number = (start + this.pageSize);

      this._currentPage = this.records.slice(start, end);
    }

    return this._currentPage;
  }

  /**
   * Indicates if there are pages.
   *
   * @returns boolean
   */
  hasPages(): boolean {
    const hasIt: boolean = (this.totalPages > 0);
    return hasIt;
  }

  /**
   * Indicates if there are records.
   *
   * @returns boolean
   */
  hasRecords(): boolean {
    const hasIt: boolean = (_isArray(this.records) && (this.records.length > 0));
    return hasIt;
  }

  /**
   * Reset itself.
   *
   * @returns void
   */
  reset(): void {
    this.setRecords(new Array());
  }

  /**
   * Set the filtering criteria and applies it over the stored records.
   * If the given criteria is undefined, removes the current filter.
   *
   * @param filteringCriteria CallableFunction
   * @throws ErrorHelper
   * @returns void
   */
  setFilterCriteria(filteringCriteria?: CallableFunction): void {
    this.doSetFilterCriteria(filteringCriteria);
    this.paginate();
  }

  /**
   * Set the page number.
   *
   * @param pageNumber number
   * @throws ErrorHelper
   * @returns void
   */
  setPageNumber(pageNumber: number): void {
    if ((pageNumber === 0) || ((pageNumber >= 1) && (pageNumber < this.totalPages))) {
      this._pageNumber = pageNumber;
      this._currentPage = undefined;
    } else {
      throw new ErrorHelper('Invalid page number given, it is out of range.');
    }
  }

  /**
   * Set the page number for the given record.
   *
   * @param record any
   * @param comparisonCriteria CallableFunction
   * @throws ErrorHelper, if the given record does not exist in pager.
   * @returns void
   */
  setPageNumberFor(record: any, comparisonCriteria: CallableFunction): void {
    const index: number = this.records.findIndex(
      (element: any) => comparisonCriteria(element, record)
    );

    if (index >= 0) {
      this.setPageNumber(Math.trunc(index / this.pageSize));
    } else {
      throw new ErrorHelper('Failed trying to find the page for the given record.');
    }
  }

  /**
   * Set the page size and goes to the first page.
   *
   * @param pageSize number
   * @throws ErrorHelper
   * @returns void
   */
  setPageSize(pageSize: number): void {
    this.doSetPageSize(pageSize);
    this.resetPagePointers();
  }

  /**
   * Set the records making a copy of the given parameter.
   *
   * @param records any[]
   * @throws ErrorHelper
   * @returns void
   */
  setRecords(records: any[]): void {
    if (_isArray(records)) {
      this._backup = _clone(records);
      this.paginate();
    } else {
      throw new ErrorHelper('Invalid records collection given, it should be an array.');
    }
  }

  /**
   * Set the sorting criteria and applies it over the stored records.
   * If the given criteria is undefined, restores the original order.
   *
   * @param sortingCriteria CallableFunction
   * @throws ErrorHelper
   * @returns void
   */
  setSortingCriteria(sortingCriteria: CallableFunction): void {
    this.doSetSortingCriteria(sortingCriteria);
    this.paginate();
  }

  /**
   * Set the filtering criteria.
   *
   * @param filteringCriteria CallableFunction
   * @throws ErrorHelper
   * @returns void
   */
  protected doSetFilterCriteria(filteringCriteria?: CallableFunction): void {
    if (_isUndefined(filteringCriteria) || _isFunction(filteringCriteria)) {
      this._filteringCriteria = filteringCriteria;
    } else {
      throw new ErrorHelper('Invalid criteria given: it should be undefined or a filtering function.');
    }
  }

  /**
   * Set the page size.
   *
   * @param pageSize number
   * @throws ErrorHelper
   * @returns void
   */
  protected doSetPageSize(pageSize: number): void {
    if (pageSize >= 1) {
      this._pageSize = pageSize;
    } else {
      throw new ErrorHelper('Invalid page size given, it should be greater than zero.');
    }
  }

  /**
   * Set the sorting criteria.
   *
   * @param sortingCriteria CallableFunction
   * @throws ErrorHelper
   * @returns void
   */
  protected doSetSortingCriteria(sortingCriteria: CallableFunction): void {
    if (_isUndefined(sortingCriteria) || _isFunction(sortingCriteria)) {
      this._sortingCriteria = sortingCriteria;
    } else {
      throw new ErrorHelper('Invalid criteria given: it should be undefined or a sorting function.');
    }
  }

  /**
   * Filters the stored records using the stored criteria.
   *
   * @returns void
   */
  protected filterRecords(): void {
    const records: any[] = _clone(this._backup);
    this._records = _isFunction(this.filteringCriteria) ? records.filter(<any>this.filteringCriteria) : records;
  }

  /**
   * Applies filtering and sorting criteria and then goes to the first page.
   *
   * @returns void
   */
  protected paginate(): void {
    this.filterRecords();
    this.sortRecords();
    this.resetPagePointers();
  }

  /**
   * Reset the page pointers.
   *
   * @returns void
   */
  protected resetPagePointers(): void {
    this._totalPages = Math.ceil(this.records.length / this.pageSize);
    this.setPageNumber(0);
  }

  /**
   * Sorts the stored records using the stored criteria.
   *
   * @returns void
   */
  protected sortRecords(): void {
    if (this.hasRecords() && _isFunction(this.sortingCriteria)) {
      this._records.sort(<any>this.sortingCriteria);
    }
  }
}
