export abstract class NumberHelper {
  /**
   * Indicates if the given value is an even number.
   *
   * @param value number
   * @returns boolean
   */
  static isEven(value: number): boolean {
    const isIt: boolean = (value % 2 === 0);
    return isIt;
  }

  /**
   * Indicates if the given value divide by the group size is an even number.
   *
   * @param value number
   * @param groupSize number
   * @returns boolean
   */
  static isEvenGroup(value: number, groupSize: number): boolean {
    return this.isEven(Math.floor(value / groupSize));
  }

  /**
   * Indicates if the given value is an odd number.
   *
   * @param value number
   * @returns boolean
   */
  static isOdd(value: number): boolean {
    return !this.isEven(value);
  }

  /**
   * Indicates if the given value divide by the group size is an odd number.
   *
   * @param value number
   * @param groupSize number
   * @returns boolean
   */
  static isOddGroup(value: number, groupSize: number): boolean {
    return this.isOdd(Math.floor(value / groupSize));
  }
}
