import { isObject as _isObject, union as _union } from 'lodash';

import { SearchResponse } from '../search-response.model';


export class SearchManager {
  protected _currentPage: number;
  protected _sanitizedQuery: string;
  protected _response: SearchResponse;
  protected _suggestions: any[];

  constructor() {
    this._currentPage = 1;
    this._suggestions = new Array();
  }

  get currentPage(): number {
    return this._currentPage;
  }

  get sanitizedQuery(): string {
    return this._sanitizedQuery;
  }

  set sanitizedQuery(newQuery: string) {
    this._sanitizedQuery = newQuery.toLowerCase().trim();
    // TODO: Check why is setting currentPage in 1. Maybe it's due to the pager.
    this._currentPage = 1;
    this._suggestions = new Array();
  }

  get response(): SearchResponse {
    return this._response;
  }

  set response(newSearch: SearchResponse) {
    this._response = newSearch;

    if (this.response.hasData()) {
      this.addSuggestions();
      this.growsPage();
    }
  }

  get suggestions(): any[] {
    return this._suggestions;
  }

  /**
   * Indicates if it has to display the no more results.
   *
   * @returns boolean
   */
  hasDisplayNoMoreResults(): boolean {
    const hasIt: boolean =
      this.hasSearchResponse() &&
      this.response.pageData.isLastPage();

    return hasIt;
  }

  /**
   * Indicates if the search has to display the bottom message.
   *
   * @returns boolean
   */
  hasDisplaySearchBottomMessage(): boolean {
    const hasIt: boolean =
      this.hasSearchResponse() &&
      this.response.pageData.totalPages > 1;

    return hasIt;
  }

  /**
   * Indicates if it has a response
   *
   * @returns boolean
   */
  hasSearchResponse(): boolean {
    return _isObject(this.response);
  }

  /**
   * Indicates if there is no more results in the response.
   *
   * @returns void
   */
  isNothingFound(): boolean {
    const res: boolean = this.hasSearchResponse() && !this.response.hasData() && this.currentPage === 1;
    return res;
  }

  /**
   * Adds the incoming elements into the suggestions.
   *
   * @returns void
   */
  protected addSuggestions(): void {
    this._suggestions = _union(this._suggestions, this.response.data);
  }

  /**
   * Increments the current page.
   *
   * @returns void
   */
  protected growsPage(): void {
    this._currentPage++;
  }
}
