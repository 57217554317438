import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { FunctionalMetadataFormHandlerService, FunctionalMetadataService } from '@bolt/ui-shared/title';
import { AccordionItem } from '@bolt/ui-shared/common';

import { ActionHandlerService } from '../../services/level-data/action-handler/action-handler.service';
import { BoltTitleLevelDataBaseFormComponent } from '../bolt-title-level-data-base-form/bolt-title-level-data-base-form.component';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { TitleService } from '../../services/title.service';
import { Series } from '../../models/series.model';
import { SeriesForm } from '../../models/level-data/form/series/series-form.model';
import { FormHandlerService } from '../../services/level-data/form-handler/form-handler.service';


@Component({
  selector: 'bolt-title-level-data-series-form',
  template: require('./bolt-title-level-data-series-form.html'),
  styles: [require('./bolt-title-level-data-series-form.scss')]
})
export class BoltTitleLevelDataSeriesFormComponent extends BoltTitleLevelDataBaseFormComponent implements OnInit, OnDestroy {
  @Input() title: Series;
  mappedProductionCompanies: AccordionItem[];

  protected form: SeriesForm;

  constructor(
    protected actionHandler: ActionHandlerService,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected formHandler: FormHandlerService,
    protected functionalMetadataService: FunctionalMetadataService,
    protected functionalMetadataFormHandler: FunctionalMetadataFormHandlerService,
    protected masterDataManager: MasterDataManager,
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) {
    super(
      actionHandler,
      appConfigurationManager,
      capabilitiesManager,
      formHandler,
      functionalMetadataService,
      functionalMetadataFormHandler,
      masterDataManager,
      notificationService,
      titleService
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
    this.formHandler.createSeriesForm(this.title);
  }

  getMappedProductionCompanies(): AccordionItem[] {
    this.mappedProductionCompanies = this.form?.get('productionCompanyIds')?.value?.reduce((list: AccordionItem[], id: number) => {
      const label = this.productionCompanies?.find(pc => pc.value === id)?.label;
      if (label) {
        list.push(new AccordionItem(label));
      }
      return list;
    }, []);

    return this.mappedProductionCompanies;
  }

  protected loadDependencies(): void {
    this.loadLanguages();
    this.loadProductionCompanies();
    this.loadTerritories();
  }
}
