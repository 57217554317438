import { Pipe, PipeTransform } from '@angular/core';
import { BoltAmUtcPipe } from '../bolt-am-utc.pipe';
import { FormatEnum } from '../format.enum';


@Pipe({
  name: 'boltAmUtcDateTime'
})
export class BoltAmUtcDateTimePipe extends BoltAmUtcPipe implements PipeTransform {
  getFormat(): FormatEnum {
    return FormatEnum.DATETIME;
  }
}
