import { Component, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';

import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';

import {
  FormFactoryService as RoleFormFactoryService
} from 'app/modules/role/services/form-factory/form-factory.service';

import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';
import { RoleMetadata } from '../../models/role-metadata.model';
import { RoleInterface, Role } from '../../models/role.model';
import { RoleService } from '../../services/role.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { TypeEnum as CreditType } from 'app/modules/credits/models/wizard/candidate/type/type.enum';
import { EditHistoryMetadata } from 'app/modules/common/components/bolt-edit-history/bolt-edit-history.component';


@Component({
  selector: 'bolt-role-level-data',
  template: require('./bolt-role-level-data.html'),
  styles: [require('./bolt-role-level-data.scss')],
})
export class BoltRoleLevelDataComponent implements OnChanges, OnDestroy {
  @Input('BoltRoleLevelDataRole') role: RoleInterface = new Role();
  @Input('BoltRoleLevelDataShow') showData: boolean = false;
  @Output('BoltRoleLevelDataRoleUpdate') roleUpdate: EventEmitter<RoleInterface> =
    new EventEmitter<RoleInterface>();

  showEditHistory: boolean = false;

  protected form: OriginalDataForm;

  protected isMergeModalOpen: boolean;
  protected notificationsContainer = notificationsContainer;

  constructor(
    protected capabilitiesManager: CapabilitiesManager,
    protected roleService: RoleService,
    protected notificationService: NotificationService,
    protected roleFormFactory: RoleFormFactoryService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.role && changes.role.currentValue) {
      this.setupForm();
      this.setFormState();
    }
  }

  ngOnDestroy(): void {
    this.notificationService.clean(this.notificationsContainer.role.details.key);
  }

  getMetadata(): EditHistoryMetadata {
    return {
      id: this.role.id,
      locale: this.role.locale,
      type: CreditType.role,
      name: this.role.name,
      language: this.role.language,
      territory: this.role.territory,
      productType: this.role.productType,
      account: this.role.account,
      localeObject: this.role.localeObject
    };
  }

  /**
   * Close the edit history modal.
   *
   * @returns void
   */
  closeEditHistory(): void {
    this.showEditHistory = false;
  }

  /**
   * Open the edit history modal.
   *
   * @returns void
   */
  openEditHistory(): void {
    this.showEditHistory = true;
  }

  /**
   * Sets up the form.
   *
   * @returns void
   */
  protected setupForm(): void {
    this.form = this.roleFormFactory.buildOriginalDataForm(this.role);
  }

  /**
   * Sets the form's state.
   *
   * @returns void
   */
  protected setFormState(): void {
    setTimeout(() => {
      this.form[this.role.locked ? 'disable' : 'enable']();
    }, 0);
  }

  /**
   * Toggles the Role lock state.
   *
   * @return void
   */
  protected toggleLockedStateRole() {
    this.roleService.updateRole(
      { roleId: this.role.id },
      <RoleInterface>{ locked: !this.role.locked }
    ).subscribe(
      response => {
        this.role = response.item;
        this.roleUpdate.emit(this.role);
        this.notificationService.handleNotice(
          'Locked status changed',
          `The Role has been ${(this.role.locked ? 'locked' : 'unlocked')}`,
          this.notificationsContainer.role.details.key
        );
      },
      (err: ErrorHelper) => {
        this.notificationService.handleError('The locked status could not be changed',
        err,
        this.notificationsContainer.role.details.key
      );
      }
    );
  }

  /**
   * Updates the Role's Data.
   *
   * @return void
   */
  protected updateRoleLevelData() {
    this.roleService.updateRole(
      { roleId: this.role.id },
      <RoleInterface>this.form.toJson()
    ).subscribe(
      response => {
        this.role = response.item;
        this.roleUpdate.emit(this.role);

        this.notificationService.handleNotice(
          'Role updated',
          'The Role Level Data has been saved',
          this.notificationsContainer.role.details.key
        );
      },
      (err: ErrorHelper) => {
        this.notificationService.handleError(
          'The Role Level Data could not be saved',
          err,
          this.notificationsContainer.role.details.key
        );
      }
    );
  }

  /**
   * Closes the merge modal.
   *
   * @returns void
   */
  protected closeMergeModal(): void {
    this.isMergeModalOpen = false;
  }

  /**
   * Indicates if it has to display the merge button.
   *
   * @returns boolean
   */
  protected hasDisplayMergeButton(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(
      modulesPath.titles.credits.role.path,
      [ActionTypeEnum.merge]
    );
  }

  /**
   * Indicates if it has to open the merge modal.
   *
   * @returns boolean
   */
  protected hasOpenMergeModal(): boolean {
    return this.isMergeModalOpen;
  }

  /**
   * Opens the merge modal.
   *
   * @returns void
   */
  protected openMergeModal(): void {
    this.isMergeModalOpen = true;
  }

  /**
   * Refreshes the current role with the given values.
   *
   * @param role Role
   * @returns void
   */
  protected refreshRole(role: Role): void {
    const mappedLocalizations: RoleMetadata[] = new Array();

    role.localizations.forEach(
      (localization: any) => {
        mappedLocalizations.push(new RoleMetadata(localization));
      }
    );

    role.localizations = mappedLocalizations;
    this.role = role;

    this.roleUpdate.emit(this.role);
  }
}
