import { uniq as _uniq } from 'lodash';

import { Alerts } from './alerts/alerts.model';
import { Titles } from './titles/titles.model';


export class Comparison {
  protected _alerts: Alerts;
  protected _languages: string[];
  protected _territories: string[];
  protected _titles: Titles;
  protected _uuid: string;

  constructor(data: any) {
    this.initialize(data);
  }

  get alerts(): Alerts {
    return this._alerts;
  }

  get languages(): string[] {
    return this._languages;
  }

  get territories(): string[] {
    return this._territories;
  }

  get titles(): Titles {
    return this._titles;
  }

  get uuid(): string {
    return this._uuid;
  }

  /**
   * Indicates if there are languages.
   *
   * @returns boolean
   */
  hasLanguages(): boolean {
    const hasIt: boolean = (
      (this.languages !== undefined) &&
      (this.languages.length > 0)
    );

    return hasIt;
  }

  /**
   * Indicates if there are territories.
   *
   * @returns boolean
   */
  hasTerritories(): boolean {
    const hasIt: boolean = (
      (this.territories !== undefined) &&
      (this.territories.length > 0)
    );

    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const alerts: any = (
      (data && data.hasOwnProperty('metadata') && data.metadata.hasOwnProperty('alerts'))
        ? data.metadata.alerts
        : undefined
    );

    const titles: any = (
      (data && data.hasOwnProperty('metadata') && data.metadata.hasOwnProperty('titles'))
        ? data.metadata.titles
        : undefined
    );

    const uuid: string = (
      (data && data.hasOwnProperty('processUUID'))
        ? data.processUUID
        : undefined
    );

    this._alerts = new Alerts(alerts);
    this._titles = new Titles(titles);
    this._uuid = uuid;

    if (data && data.hasOwnProperty('territoriesByLanguage')) {
      this.populateLanguages(data);
      this.populateTerritories(data);
    }
  }

  /**
   * Populates languages.
   *
   * @param data any
   * @returns void
   */
  protected populateLanguages(data: any): void {
    this._languages = Object.keys(data.territoriesByLanguage);
  }

  /**
   * Populate territories.
   *
   * @param data any
   * @returns void
   */
  protected populateTerritories(data: any): void {
    const languages: string[] = Object.keys(data.territoriesByLanguage);
    const territories = new Array();

    languages.forEach(
      (language: string) => {
        data.territoriesByLanguage[language].forEach(
          (territory: string) => {
            if (territory !== '*') {
              territories.push(territory);
            }
          }
        );
      }
    );

    if (territories.length > 0) {
      this._territories = _uniq(territories);
    }
  }
}
