import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { WindowWrapperService } from '@bolt/ui-shared/common';
import { StormListType } from '@bolt/ui-shared/master-data';
import { Observable } from 'rxjs';

import { BoltMergeManagerComponent } from '../bolt-merge-manager/bolt-merge-manager.component';
import { CandidateTypeEnum } from '../../models/candidate-type.enum';
import { MergeService } from '../../services/merge.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Talent } from 'app/modules/talent/models/talent.model';
import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';


@Component({
  selector: 'bolt-merge-talent-manager',
  template: require('./bolt-merge-talent-manager.html'),
  styles: [require('./bolt-merge-talent-manager.scss')]
})
export class BoltMergeTalentManagerComponent extends BoltMergeManagerComponent implements OnDestroy, OnInit {
  @Input() talent: Talent;

  protected mergeCandidate: Talent;

  constructor(
    protected mergeService: MergeService,
    protected notificationService: NotificationService,
    protected windowWrapper: WindowWrapperService,
    protected entityMapperHelper: EntityMapperHelper
  ) {
    super(mergeService, notificationService, windowWrapper);
  }

  ngOnInit(): void {
    this.mapTalent(this.talent).subscribe(
      (mappedTalent: Talent) => this.talent = mappedTalent
    );
  }

  protected mapResponse(response: StormServiceResponseSingle): Observable<Talent> {
    return this.mapTalent(new Talent(response.item[0]));
  }

  /**
   * Maps the affix attributes in the given talent
   *
   * @param talent Talent
   * @returns Observable<Talent>
   */
  protected mapTalent(talent: Talent): Observable<Talent> {
    return this.entityMapperHelper.map(
      talent,
      [
        {
          property: '_prefixId',
          mapTo: StormListType.affix,
        },
        {
          property: '_suffixId',
          mapTo: StormListType.affix,
        }
      ]
    );
  }

  /**
   * Merge the current merge candidate into the current talent.
   *
   * @returns void
   */
  protected merge(): void {
    this.doMerge(this.mergeCandidate.id, this.talent.id, CandidateTypeEnum.talent);
  }
}
