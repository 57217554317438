import { FormControl, Validators } from '@angular/forms';
import { isUndefined as _isUndefined } from 'lodash';

import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';
import { TitleAttributes } from '../../title/title-attributes.enum';
import { FeatureAttributes } from '../../title/feature/feature-attributes.enum';

export abstract class TitleForm extends BoltForm {

  constructor(fieldSpecs: any, defaultValues: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  toJson(): any {
    const data: any = { };
    data[TitleAttributes.title] = this.get(TitleAttributes.title).value;
    return data;
  }

  /**
   * Returns the JSON of "Not Applicable" and "Functional Metadata" fields, taking in count the dependency between them.
   *
   * @returns any
   */
  getNotApplicableAndFunctionalMetadataAsJson(): any {
    const notApplicable: boolean = this.get(FeatureAttributes.notApplicable).value;
    const data: any = { };
    data[FeatureAttributes.notApplicable] = notApplicable;
    data[FeatureAttributes.functionalMetadata] = notApplicable ? null : this.get(FeatureAttributes.functionalMetadata).value;

    const functionalMetadataSplitFields = this.getDefaultValue('functionalMetadataSplitFields');

    Object.keys(functionalMetadataSplitFields).forEach(
      (fieldName: string) => {
        data[fieldName] = !this.get(fieldName).value ? null : this.get(fieldName).value;
      }
    );

    return data;
  }

  /**
   * Set up the copyright field.
   *
   * @returns void
   */
  protected setupCopyright(): void {
    const control: FormControl = new FormControl(
      this.getDefaultValue(FeatureAttributes.copyright),
      Validators.maxLength(this.fieldSpecs.copyright.maxLength)
    );

    this.addControl(FeatureAttributes.copyright, control);
  }

  /**
   * Set up the country of origin id field.
   *
   * @returns void
   */
  protected setupCountryOfOriginId(): void {
    this.addControl(FeatureAttributes.countryOfOriginId, new FormControl(this.getDefaultValue(FeatureAttributes.countryOfOriginId)));
  }

  protected setupFields(): void {
    this.setupOriginalTitle();
  }

  /**
   * Set up the functional metadata field.
   *
   * @returns void
   */
  protected setupFunctionalMetadata(): void {
    this.addControl(FeatureAttributes.functionalMetadata, new FormControl(this.getDefaultValue(FeatureAttributes.functionalMetadata)));
  }

  /**
   * Sets up the functional metadata split fields.
   *
   * @returns void
   */
  protected setupFunctionalMetadataSplitFields(): void {
    const functionalMetadataSplitFields: any = this.getDefaultValue('functionalMetadataSplitFields');

    Object.keys(functionalMetadataSplitFields).forEach(
      (fieldName: string) => {
        this.addControl(fieldName, new FormControl(functionalMetadataSplitFields[fieldName]));
      }
    );
  }

  /**
   * Set up the genre id field.
   *
   * @returns void
   */
  protected setupGenreId(): void {
    this.addControl(FeatureAttributes.genreId, new FormControl(this.getDefaultValue(FeatureAttributes.genreId)));
  }

  /**
   * Set up the not applicable field.
   *
   * @returns void
   */
  protected setupNotApplicable(): void {
    this.addControl(FeatureAttributes.notApplicable, new FormControl(this.getDefaultValue(FeatureAttributes.notApplicable)));
  }

  /**
   * Set up the original spoken language id field.
   *
   * @returns void
   */
  protected setupOriginalSpokenLanguageId(): void {
    this.addControl(
      TitleAttributes.originalSpokenLanguageId,
      new FormControl(this.getDefaultValue(TitleAttributes.originalSpokenLanguageId))
    );
  }

  /**
   * Set up the production company id field.
   *
   * @returns void
   */
  protected setupProductionCompanyId(): void {
    this.addControl(FeatureAttributes.productionCompanyId, new FormControl(this.getDefaultValue(FeatureAttributes.productionCompanyId)));
  }

  /**
   * Set up the production company ids field.
   *
   * @returns void
   */
  protected setupProductionCompanyIds(): void {
    this.addControl(FeatureAttributes.productionCompanyIds, new FormControl(this.getDefaultValue(FeatureAttributes.productionCompanyIds)));
  }

  /**
   * Set up the original title field.
   *
   * @returns void
   */
  protected setupOriginalTitle(): void {
    const control: FormControl = new FormControl(
      this.getDefaultValue('title'),
      [
        Validators.required,
        Validators.maxLength(this.fieldSpecs.originalTitle.maxLength)
      ]
    );

    this.addControl(TitleAttributes.title, control);
  }

  /**
   * Set the form status depending of the locking status.
   *
   * @returns void
   */
  protected setStatus(): void {
    if (this.defaultValues.locked) {
      setTimeout(() => this.disable());
    } else {
      setTimeout(() => this.enable());
    }
  }
}
