import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ProcessingResponse } from '../../models/export/processing-response/processing-response.model';
import { RequestFileData } from '../../models/export/request-file-data/request-file-data.model';
import { Status } from '../../models/export/processing-response/status/status.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable()
export class ExportService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns a subscription to ask the status of the file to be downloaded.
   *
   * @param uuid string
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  fetchExportStatus(
    uuid: string,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const url: string = this.generateUrl(
      'pie.exportService.fetchExportStatus.endpoint',
      { '{uuid}': uuid }
    );

    const subs: Subscription = this.doGetRequest(
      { url: url },
      (successResponse: StormServiceResponseSingle) => {
        const status: Status = new Status(successResponse.item);
        onSuccessDo(status);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription to fetch the file to be downloaded.
   *
   * @param uuid string
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  fetchFile(
    uuid: string,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const url: string = this.generateUrl(
      'pie.exportService.fetchFile.endpoint',
      { '{uuid}': uuid }
    );

    const params: any = {
      url: url,
      options: {
        responseType: 'blob'
      }
    };

    const subs: Subscription = this.doGetRequest(
      params,
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse.item);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Returns a subscription to request a file to be downloaded.
   *
   * @param data RequestFileData
   * @param onSuccessDo any
   * @param onErrorDo any
   * @param finallyDo any
   * @returns Subscription
   */
  requestFile(
    data: RequestFileData,
    onSuccessDo: any,
    onErrorDo: any,
    finallyDo?: any
  ): Subscription {
    const url: string = this.generateUrl(
      'pie.exportService.requestFile.endpoint',
      { '{type}': data.template.id }
    );

    const params: any = {
      url: url,
      body: data.asEndpointData()
    };

    const subs: Subscription = this.doPostRequest(
      params,
      (successResponse: StormServiceResponseSingle) => {
        const response: ProcessingResponse = new ProcessingResponse(successResponse.item);
        onSuccessDo(response);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }
}
