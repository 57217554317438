import { OnInit, OnDestroy, Component } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Product } from '../../models/product/product.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { MetadataManagerService } from '../../services/item/metadata-manager/metadata-manager.service';
import { Original } from '../../models/item/original/original.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


@Component({
  selector: 'bolt-cat-item-details',
  template: require('./bolt-cat-item-details.html'),
  styles: [require('./bolt-cat-item-details.scss')]
})
export class BoltCatItemDetailsComponent extends StormComponent implements OnInit, OnDestroy {
  protected product: Product;
  protected selectedTab: string;
  protected subscriptions: Subscription;
  protected notificationsContainer = notificationsContainer;

  constructor(
    protected activedRoute: ActivatedRoute,
    protected appConfig: AppConfigProvider,
    protected itemManager: MetadataManagerService,
    protected notificationService: NotificationService,
    protected router: Router
  ) {
    super();
    this.initialize();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.retrieveData();
    this.listenUrlChanges();
  }

  /**
   * Activates the given tab.
   *
   * @param event any
   * @returns void
   */
  protected activateTab(event: any): void {
    const commands: any[] = [{ show: event.nextId }];
    const extras: any = { relativeTo: this.activedRoute };

    this.router.navigate(commands, extras);
  }

  /**
   * Returns the item.
   *
   * @returns Original
   */
  protected getItem(): Original {
    return this.itemManager.original;
  }

  /**
   * Returns the name for "Details" tab.
   *
   * @returns string
   */
  protected getItemDetailsTabName(): string {
    return this.appConfig.get('ux.page.cat.tabsName.itemDetails');
  }

  /**
   * Returns the name for "Characters and Terms" tab.
   *
   * @returns string
   */
  protected getItemsListTabName(): string {
    return this.appConfig.get('ux.page.cat.tabsName.itemsList');
  }

  /**
   * Indicates if it has to display the "Characters and Terms" tab.
   *
   * @returns boolean
   */
  protected hasDisplayCharactersAndTerms(): boolean {
    const hastIt: boolean = (this.hasItem() && this.getItem().category.isGame());
    return hastIt;
  }

  /**
   * Indicates if it has an item.
   *
   * @returns boolean
   */
  protected hasItem(): boolean {
    return this.itemManager.hasOriginal();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.subscriptions = new Subscription();
    this.changeStatusToFetchingData();
  }

  /**
   * Listens when URL changes.
   *
   * @returns void
   */
  protected listenUrlChanges(): void {
    const subs: Subscription = this.activedRoute.params.subscribe(
      (params: Params) => {
        this.selectedTab = params.show;

        if (this.hasItem() && (this.getItem().id !== Number(params.id))) {
          this.retrieveData();
        }
      },
      (error: any) => {
        this.notificationService.handleError('Failed reading URL.', error, notificationsContainer.cat.details.key);
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Navigates back in the platform's history.
   *
   * @returns void
   */
  protected navigateBack(): void {
    return history.back();
  }

  /**
   * Retrieves the data.
   *
   * @returns void
   */
  protected retrieveData(): void {
    this.changeStatusToFetchingData();

    this.itemManager.retrieveOriginal(
      this.activedRoute.snapshot.data.itemType.toUpperCase(),
      Number(this.activedRoute.snapshot.params.id),
      () => {
        if (this.hasDisplayCharactersAndTerms()) {
          this.product = Product.newFromSubproduct(this.getItem());
        } else {
          this.product = undefined;
          this.selectedTab = undefined;
        }

        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed retrieving item.', error, notificationsContainer.cat.details.key);
        this.changeStatusToError();
      }
    );
  }
}
