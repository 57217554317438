import { includes as _includes } from 'lodash';

import { ActionEnum } from './action.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class Action {
  protected _value: ActionEnum;

  constructor(value: ActionEnum) {
    this.initialize(value);
  }

  get value(): ActionEnum {
    return this._value;
  }

  /**
   * Indicates if it is equals to the given action.
   *
   * @param otherAction Action
   * @returns boolean
   */
  isEqualsTo(otherAction: Action): boolean {
   return this.is(otherAction.value);
  }

  /**
   * Indicates if it is "Hide Data".
   *
   * @returns boolean
   */
  isHideData(): boolean {
    return this.is(ActionEnum.hideData);
  }

  /**
   * Indicates if it is "Save".
   *
   * @returns boolean
   */
  isSave(): boolean {
    return this.is(ActionEnum.save);
  }

  /**
   * Indicates if it is "Save & Lock".
   *
   * @returns boolean
   */
  isSaveAndLock(): boolean {
    return this.is(ActionEnum.saveAndLock);
  }

  /**
   * Indicates if it is "Show Data".
   *
   * @returns boolean
   */
  isShowData(): boolean {
    return this.is(ActionEnum.showData);
  }

  /**
   * Indicates if it is "Unlock".
   *
   * @returns boolean
   */
  isUnlock(): boolean {
    return this.is(ActionEnum.unlock);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this._value.toString();
  }

  /**
   * Initializes the instance.
   *
   * @param value ActionEnum
   * @returns void
   */
  protected initialize(value: ActionEnum): void {
    if (_includes(ActionEnum, value)) {
      this._value = value;
    } else {
      throw new ErrorHelper('Invalid value given for an action.');
    }
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value ActionEnum
   * @returns boolean
   */
  protected is(value: ActionEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
