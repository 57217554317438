import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';

import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { Language } from '@bolt/ui-shared/master-data';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { clone as _clone, isArray as _isArray, isObject as _isObject } from 'lodash';

import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Localized } from '../../models/item/localized/localized.model';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { MetadataManagerService } from '../../services/item/metadata-manager/metadata-manager.service';
import { RoleCapabilitiesHandler } from '../../services/role-capabilities-handler/role-capabilities-handler';
import { TypeEnum } from '../../models/type/type.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-cat-item-localized-list',
  template: require('./bolt-cat-item-localized-list.html'),
  styles: [require('./bolt-cat-item-localized-list.scss')]
})
export class BoltCatItemLocalizedListComponent extends StormComponent implements OnChanges, OnInit {
  @Input() items: Localized[];
  @Input() itemType: TypeEnum;
  @Input() selected: Localized;

  @ViewChild('filter') protected filterInput: ElementRef;
  @Output('selection') protected selectionEvent: EventEmitter<Localized>;

  protected dataStatus: typeof DataStatusEnum;
  protected filterObserver: BehaviorSubject<undefined>;
  protected filterQuery: string;
  protected openCreateItemPopupFlag: boolean;
  protected pager: MemoryPager;
  protected subscriptions: Subscription;

  constructor(
    protected appConfig: AppConfigProvider,
    protected itemManager: MetadataManagerService,
    protected notificationService: NotificationService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    super();
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Improve this after MVP.
    setTimeout(
      () => {
        if (changes.items && _isArray(changes.items.currentValue)) {
          this.setItems(changes.items.currentValue);
        }

        if (changes.selected && _isObject(changes.selected.currentValue)) {
          this.syncSelected(<Localized>changes.selected.currentValue);
        }
      },
      0
    );
  }

  ngOnInit() {
    this.setupFilter();
  }

  /**
   * Adds the given item.
   *
   * @returns void
   */
  protected addItem(newItem: Localized): void {
    const copiedItems: Localized[] = _clone(this.items);

    const index: number = copiedItems.findIndex(
      (element: Localized) => {
        return element.locale === newItem.locale;
      }
    );

    if (index === -1) {
      copiedItems.push(newItem);
    } else {
      copiedItems[index] = newItem;
    }

    this.select(newItem);
    this.setItems(copiedItems);
  }

  /**
   * Applies the filter.
   *
   * @returns void
   */
  protected applyFilter(): void {
    this.filterQuery = this.filterInput.nativeElement.value.trim().toLocaleLowerCase();
    let filteredItems: Localized[] = new Array();

    if (this.filterQuery) {
      this.items.forEach(
        (item: Localized) => {
          if (item.language.name.trim().toLocaleLowerCase().includes(this.filterQuery)) {
            filteredItems.push(item);
          }
        }
      );
    } else {
      filteredItems = this.items;
    }

    this.pager.setRecords(filteredItems);
    this.trySetPageNumberForSelected();
  }

  /**
   * Closes the popup for creating an item.
   *
   * @returns void
   */
  protected closeCreateItemPopup(): void {
    this.openCreateItemPopupFlag = false;
  }

  /**
   * Returns the comparison criteria for items.
   *
   * @returns CallableFunction
   */
  protected getComparisonCriteria(): CallableFunction {
    const criteria: CallableFunction = (itemA: Localized, itemB: Localized) => {
      return itemA.id === itemB.id;
    };

    return criteria;
  }

  /**
   * Returns the current page localizations.
   *
   * @returns Localized[]
   */
  protected getCurrentPagedLocalizations(): Localized[] {
    return this.pager.getCurrentPage();
  }

  /**
   * Returns the sorting criteria for languages.
   *
   * @returns CallableFunction
   */
  protected getLanguagesSortingCriteria(): any {
    const criteria: CallableFunction = (itemA: Localized, itemB: Localized) => {
      if (itemA.language.name > itemB.language.name) {
        return 1;
      } else if (itemA.language.name < itemB.language.name) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Indicates if it has to block the clean filter button.
   *
   * @returns boolean
   */
  protected hasBlockCleanFilter(): boolean {
    const hasIt: boolean = (this.hasBlockFilter() || !this.filterQuery);
    return hasIt;
  }

  /**
   * Indicates if it has to block the filter input.
   *
   * @returns boolean
   */
  protected hasBlockFilter(): boolean {
    return !this.hasDisplayList();
  }

  /**
   * Indicates if it has to display the actions section.
   *
   * @returns boolean
   */
  protected hasDisplayActions(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalized(this.itemType, ActionTypeEnum.write);
  }

  /**
   * Indicates if it has to display the paginator.
   *
   * @returns boolean
   */
  protected hasDisplayPaginator(): boolean {
    const hasIt: boolean = (this.hasDisplayList() && this.hasFilteredItems());
    return hasIt;
  }

  /**
   * Indicates if it has to display the list.
   *
   * @returns boolean
   */
  protected hasDisplayList(): boolean {
    return this.itemManager.hasLanguages();
  }

  /**
   * Indicates if it has filtered items.
   *
   * @returns boolean
   */
  protected hasFilteredItems(): boolean {
    return this.pager.hasRecords();
  }

  /**
   * Indicates if it has to open the popup for creating an item.
   *
   * @returns boolean
   */
  protected hasOpenCreateItemPopup(): boolean {
    return this.openCreateItemPopupFlag;
  }

  /**
   * Indicates if it has a selected localization.
   *
   * @returns boolean
   */
  protected hasSelected(): boolean {
    return _isObject(this.selected);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.dataStatus = DataStatusEnum;
    this.items = new Array();
    this.pager = new MemoryPager(this.appConfig.get('ux.page.cat.localizationPageSize'));
    this.selected = undefined;
    this.selectionEvent = new EventEmitter();
    this.subscriptions = new Subscription();
  }

  /**
   * Indicates if the given item is the selected one.
   *
   * @param item Localized
   * @returns boolean
   */
  protected isSelected(item: Localized): boolean {
    const isIt: boolean = (this.hasSelected() && (item.id === this.selected.id));
    return isIt;
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Maps the given items and returns them into a new collection.
   *
   * @param items Localized[]
   * @returns Localized[]
   */
  protected mapOn(items: Localized[]): Localized[] {
    const newItems: Localized[] = new Array();
    let englishItem: Localized;

    items.forEach(
      (item: Localized) => {
        this.itemManager.discoverLanguageOn(item);

        if (item.language.id === Language.ENGLISH_ID) {
          englishItem = item;
        } else {
          newItems.push(item);
        }
      }
    );

    newItems.sort(this.getLanguagesSortingCriteria());

    if (_isObject(englishItem)) {
      newItems.unshift(englishItem);
    }

    return newItems;
  }

  /**
   * Notifies that the filter changed.
   *
   * @returns void
   */
  protected notifyFilterChange(): void {
    this.filterObserver.next(undefined);
  }

  /**
   * Opens the popup for creating an item.
   *
   * @returns void
   */
  protected openCreateItemPopup(): void {
    this.openCreateItemPopupFlag = true;
  }

  /**
   * Reset the filter.
   *
   * @returns void
   */
  protected resetFilter(): void {
    this.filterQuery = '';

    if (this.filterInput) {
      this.filterInput.nativeElement.value = this.filterQuery;
    }

    this.pager.setRecords(this.items);
    this.trySetPageNumberForSelected();
  }

  /**
   * Notifies the selection.
   *
   * @param item Localized
   * @returns void
   */
  protected select(item: Localized): void {
    this.selected = item;
    this.selectionEvent.emit(this.selected);
  }

  /**
   * Set the items.
   *
   * @param items Localized[]
   * @returns void
   */
  protected setItems(items: Localized[]): void {
    this.items = this.mapOn(items);

    this.applyFilter();
  }

  /**
   * Tries to set the correct page for the current selection. If there is no selection,
   * tries to select the first element of the current page.
   *
   * @returns void
   */
  protected trySetPageNumberForSelected(): void {
    if (this.hasSelected()) {
      try {
        this.pager.setPageNumberFor(this.selected, this.getComparisonCriteria());
      } catch (error) {
        this.trySelectFirst();
      }
    } else {
      this.trySelectFirst();
    }
  }

  /**
   * Set up the filter.
   *
   * @returns void
   */
  protected setupFilter(): void {
    this.filterObserver = new BehaviorSubject(undefined);
    this.filterQuery = '';

    this.filterObserver.asObservable().pipe(
      debounceTime(200)
    ).subscribe(
      () => {
        this.applyFilter();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed loading localized data.', error);
      }
    );
  }

  /**
   * Synchronizes the given item with the selected one.
   *
   * @returns void
   */
  protected syncSelected(item: Localized): void {
    const index: number = this.items.findIndex(
      (currentItem: Localized) => {
        const foundIt: boolean = (currentItem.id === item.id);
        return foundIt;
      }
    );

    if (index === -1) {
      throw new ErrorHelper(`Invalid selected item given ${item.locale}`);
    } else {
      this.items[index] = item;

      this.select(item);
      this.applyFilter();
    }
  }

  /**
   * Tries to select the first filtered item.
   *
   * @returns void
   */
  protected trySelectFirst(): void {
    if (this.hasFilteredItems()) {
      this.select(this.getCurrentPagedLocalizations()[0]);
    } else {
      this.select(undefined);
    }
  }
}
