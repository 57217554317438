import { includes as _includes } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StatusEnum } from './status.enum';


export class Status {
  protected _error: ErrorHelper;
  protected _name: StatusEnum;

  constructor(data: any) {
    this.initialize(data);
  }

  get error(): ErrorHelper {
    return this._error;
  }

  get name(): StatusEnum {
    return this._name;
  }

  /**
   * Indicates if it is awaiting for resolution.
   *
   * @returns boolean
   */
  isAwaitingResolution(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.AWAITING_RESOLUTION
    );

    return isIt;
  }

  /**
   * Indicates if it has been compared.
   *
   * @returns boolean
   */
  isCompared(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.COMPARED
    );

    return isIt;
  }

  /**
   * Indicates if it is declined.
   *
   * @returns boolean
   */
  isDeclined(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.DECLINED
    );

    return isIt;
  }

  /**
   * Indicates if it is done.
   *
   * @returns boolean
   */
  isDone(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.DONE
    );

    return isIt;
  }

  /**
   * Indicates if it has failed.
   *
   * @returns boolean
   */
  isFailed(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.FAILED
    );

    return isIt;
  }

  /**
   * Indicates if it was accepted and its comparision has started.
   *
   * @returns boolean
   */
  isStarted(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.STARTED
    );

    return isIt;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    if (data.hasOwnProperty('error')) {
      const description: string = (data['error']['description'] || 'Unknown error');
      const cause: string = (data['error']['cause'] || undefined);

      this._error = new ErrorHelper(description, undefined, cause);
    } else {
      this._error = undefined;
    }

    if (data.hasOwnProperty('status') && _includes(StatusEnum, data['status'])) {
      this._name = data['status'];
    } else {
      this._name = undefined;
    }
  }
}
