import { EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { WindowWrapperService } from '@bolt/ui-shared/common';
import { isObject as _isObject } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { CandidateTypeEnum } from '../../models/candidate-type.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { MergeService } from '../../services/merge.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


export abstract class BoltMergeManagerComponent implements OnChanges, OnDestroy {
  @Input() show: boolean;
  @Output('closed') closeEvent: EventEmitter<undefined>;
  @Output('merged') mergeEvent: EventEmitter<object>;

  @ViewChild('mergeModalRef', { static: true }) modalTemplate: ModalDirective;

  protected isMerging: boolean;
  protected mergeCandidate: object;
  protected query: string;
  protected searchInput: HTMLInputElement;

  constructor(
    protected mergeService: MergeService,
    protected notificationService: NotificationService,
    protected windowWrapper: WindowWrapperService
  ) {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show)) {
      if (changes.show.currentValue) {
        this.open();
      } else {
        if (!changes.show.firstChange) {
          this.reset();
        }
      }
    }
  }

  ngOnDestroy() {
    this.reset();
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected close(): void {
    this.searchInput = undefined;

    this.closeEvent.emit();
    this.modalTemplate.hide();
    this.reset();
  }

  /**
   * Merges the current candidate into the current item.
   *
   * @returns void
   */
  protected doMerge(sourceId: number, targetId: number, candidateType: CandidateTypeEnum): void {
    this.isMerging = true;

    this.mergeService.merge(
      candidateType,
      sourceId,
      targetId,
      (response: StormServiceResponseSingle) => {
        this.processResponse(response);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to merge', error);
      },
      () => {
        this.isMerging = false;
      }
    );
  }

  /**
   * Processes a given response for the merging
   *
   * @param response StormServiceResponseSingle
   * @returns void
   */
  protected processResponse(response: StormServiceResponseSingle): void {
    this.mapResponse(response).subscribe(
      (data: any) => {
        this.mergeEvent.emit(data);
        this.close();
        this.notificationService.handleNotice('The merge process was successfully completed.');
      }
    );
  }

  /**
   * Hack for focusing the search input.
   *
   * @returns void
   */
  protected focusOnSearchInput(): void {
    setTimeout(
      () => {
        this.searchInput = this.windowWrapper.getDocument().querySelector('#record-search');
        this.searchInput.focus();
      },
      200
    );
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.isMerging;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    return !_isObject(this.mergeCandidate) || this.isMerging;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.isMerging = false;
    this.closeEvent = new EventEmitter();
    this.mergeEvent = new EventEmitter();
    this.reset();
  }

  /**
   * Maps the merge response.
   *
   * @param response StormServiceResponseSingle
   * @returns Observable<any>
   */
  protected abstract mapResponse(response: StormServiceResponseSingle): Observable<any>;

  /**
   * Opens the modal.
   *
   * @returns void
   */
  protected open(): void {
    this.modalTemplate.show();
    this.focusOnSearchInput();
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.query = '';
    this.mergeCandidate = undefined;
    this.show = false;
  }

  /**
   * Set the current query with the given value.
   *
   * @param value string
   * @returns void
   */
  protected setQuery(value: string): void {
    this.query = value;
  }

  /**
   * Set the given item as the current selection.
   *
   * @param selected object
   * @returns void
   */
  protected setSelection(selected: object): void {
    this.mergeCandidate = selected;
  }
}
