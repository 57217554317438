import { DateFormatPipe, UtcPipe } from 'ngx-moment';
import * as moment from 'moment';
import * as _ from 'lodash';
import { FormatEnum } from './format.enum';


export abstract class BoltAmUtcPipe {
  protected dateFormatPipe: DateFormatPipe;
  protected utcPipe: UtcPipe;

  /**
   * Returns the format to apply.
   *
   * @returns string
   */
  abstract getFormat(): FormatEnum;

  /**
   * Transforms the given value.
   *
   * @param value Date | moment.Moment | string | number
   * @returns string
   */
  transform(value: Date | moment.Moment | string | number): string {
    let newValue: string;

    if (_.isDate(value) || _.isNumber(value) || _.isString(value) || (moment.isMoment(value))) {
      newValue = this.applyAmDateFormat(this.applyAmUtc(value));
    }

    return newValue;
  }

  /**
   * Applies UtcPipe on the given value.
   *
   * @param value Date | moment.Moment | string | number
   * @returns Moment
   */
  protected applyAmUtc(value: Date | moment.Moment | string | number): moment.Moment {
    if (this.utcPipe === undefined) {
      this.utcPipe = new UtcPipe();
    }

    const newValue: any = this.utcPipe.transform(<any>value);

    return newValue;
  }

  /**
   * Applies DateFormatPipe on the given value.
   *
   * @param value moment.Moment
   * @returns string
   */
  protected applyAmDateFormat(value: moment.Moment): string {
    if (this.dateFormatPipe === undefined) {
      this.dateFormatPipe = new DateFormatPipe();
    }

    const newValue: string = this.dateFormatPipe.transform(<any>value, this.getFormat().toString());

    return newValue;
  }
}
