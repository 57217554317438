import { FormControl, Validators } from '@angular/forms';
import { Country } from '@bolt/ui-shared/master-data';
import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';
import { startCase as _startCase } from 'lodash';

export class TerritoryForm extends BoltForm {
  protected territory: Country;
  protected iso31661: FormControl;
  protected region: FormControl;
  protected name: FormControl;

  constructor(fieldSpecs: any, territory: Country) {
    super(fieldSpecs);
    this.territory = territory;
    this.setupFields();
  }

  toJson(): any {
    const output: any = {
      iso31661: this.getIso31661Field().value,
      regionId: this.getRegionField().value,
      name: this.getNameField().value
    };

    if (this.territory) {
      output.id = this.territory.id;
    }

    return output;
  }

  /**
   * Returns the "Name" field.
   *
   * @returns FormControl
   */
  getNameField(): FormControl {
    return this.name;
  }

  /**
   * Returns the "Region" field.
   *
   * @returns FormControl
   */
  getRegionField(): FormControl {
    return this.region;
  }

  /**
   * Returns the "ISO31661" field.
   *
   * @returns FormControl
   */
  getIso31661Field(): FormControl {
    return this.iso31661;
  }

  protected setupFields(): void {
    this.setupIso31661();
    this.setupRegion();
    this.setupName();
  }

  /**
   * Set up the "Name" field.
   *
   * @returns void
   */
  protected setupName(): void {
    this.name = new FormControl(
      this.territory ? this.territory.name : undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.nameMaxLength)
      ])
    );
    this.addControl('name', this.name);
  }

  /**
   * Set up the "Region" field.
   *
   * @returns void
   */
  protected setupRegion(): void {
    this.region = new FormControl(this.territory ? this.territory.region : undefined, Validators.required);
    this.addControl('region', this.region);
  }

  /**
   * Set up the "ISO31661" field.
   *
   * @returns void
   */
  protected setupIso31661(): void {
    this.iso31661 = new FormControl(
      this.territory ? this.territory.iso31661 : undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.iso31661MaxLength),
        Validators.minLength(this.fieldSpecs.iso31661MinLength),
        Validators.pattern(/^[A-Z]{2}$/)
      ])
    );
    this.addControl('iso31661', this.iso31661);
  }
}

