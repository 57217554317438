import { Component, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AutoComplete } from 'primeng';
import * as fileSaver from 'file-saver';
import * as moment from 'moment';

import { HttpError } from '@bolt/ui-shared/common';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ExportService } from '../../services/export/export.service';
import { ManagerService } from '../../services/export/manager-service/manager.service';
import { ProcessingResponse } from '../../models/export/processing-response/processing-response.model';
import { PullingActionHelper } from 'app/shared/helpers/pulling-action/pulling-action.helper';
import { Status } from '../../models/export/processing-response/status/status.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TitleListSearchResult } from 'app/modules/title/models/title-list-search-result.model';
import { TitleType } from 'app/modules/title/models/title.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';


@Component({
  selector: 'bolt-profile-export',
  template: require('./bolt-profile-export.html'),
  styles: [require('./bolt-profile-export.scss')]
})
export class BoltProfileExportComponent extends StormComponent implements AfterViewInit {
  @ViewChild('titleName', { static: true }) protected titleNameInput: AutoComplete;

  protected lastExportResponse: ProcessingResponse;
  protected pullingAction: PullingActionHelper;
  protected pullingErrorList: ErrorHelper[] = new Array();
  protected titleSearchingResults: any[];
  protected titleTypes: any = TitleType;
  protected notificationsContainer = notificationsContainer;
  protected forceResetForm: boolean;
  protected forceDefaultTerritoryValue: boolean;

  constructor(
    protected exportService: ExportService,
    protected managerService: ManagerService,
    protected changeDetector: ChangeDetectorRef
  ) {
    super();
    this.managerService.reset(true);
  }

  ngAfterViewInit(): void {
    this.initialize();
    this.changeDetector.detectChanges();
  }

  /**
   * Cancels the exportation.
   *
   * @returns void
   */
  onCancel(): void {
    this.reset(true);
    this.resetForm();
    this.managerService.handleNotice('The exportation was successfully cancelled.', notificationsContainer.pie.export.key);
  }

  /**
   * Changes the group by option.
   *
   * @param option TitleType
   * @returns void
   */
  onChangeGroupBy(option: TitleType): void {
    const canChange: boolean = (
      !this.hasBlockSearchTitle() ||
      this.managerService.isSeriesSelected() // <-- delete this line when features are able.
    );

    if (canChange) {
      this.managerService.setCurrentTitleType(option);
      this.reset(false);
    }
  }

  /**
   * Sends the export request.
   *
   * @returns void
   */
  onExport(): void {
    this.status = this.componentStatuses.fetchingData;

    this.managerService.setIsExporting(true);
    this.pullingAction.reset();

    this.pullingAction.set(
      () => {
        this.checkExportStatus();
      },
      this.getPullingAttempts()
    );

    if (this.lastExportResponse === undefined) {
      this.exportService.requestFile(
        this.managerService.getExportableTitlesAsRequestFileData(),
        (data: ProcessingResponse) => {
          this.managerService.handleNotice(
            'The titles were successfully sent to server and the Excel file is in the creation process.',
            notificationsContainer.pie.export.key
          );

          this.lastExportResponse = data;
          this.pullingAction.execute();
        },
        (error: ErrorHelper) => {
          this.pullingAction.reset();
          this.throwExportError(error);
        }
      );
    } else {
      this.pullingAction.execute();
    }
  }

  /**
   * Searches the titles.
   *
   * @param event any
   * @returns void
   */
  onSearchTitle(event: any): void {
    if (event.query) {
      this.managerService.searchTitleByQuery(
        event.query,
        (data: StormServiceResponseCollection) => {
          this.titleSearchingResults = data.collection;
        },
        (error: HttpError) => {
          this.managerService.handleError('Failed searching for titles.', error, notificationsContainer.pie.export.key);
        }
      );
    }
  }

  /**
   * Selects the template.
   *
   * @param id number
   * @returns void
   */
  onSelectTemplate(id: number): void {
    try {
      this.managerService.setCurrentTemplateById(id);
    } catch (error) {
      this.managerService.handleError('Failed selecting template.', error, notificationsContainer.pie.export.key);
    }
  }

  /**
   * Selects the title.
   *
   * @returns void
   */
  onSelectTitle(title: TitleListSearchResult): void {
    if (title) {
      try {
        this.managerService.setCurrentMainTitle(title);
      } catch (error) {
        this.managerService.handleError('Failed selecting title.', error, notificationsContainer.pie.export.key);
      }
      this.changeDetector.detectChanges();
    }
  }

  /**
   * Checks the export file status.
   *
   * @returns void
   */
  protected checkExportStatus(): void {
    const onSuccessActions: CallableFunction = (data: Status) => {
      this.lastExportResponse.updateStatus(data);

      if (this.lastExportResponse.status.isDone()) {
        this.pullingAction.reset();
        this.retrieveExportFile();
      } else if (this.lastExportResponse.status.isFailed()) {
        this.pullingAction.reset();
        this.cleanLastExportResponse();
        this.cleanPullingErrorList();
        this.throwExportError(data.error);
      }
    };

    this.exportService.fetchExportStatus(
      this.lastExportResponse.uuid,
      onSuccessActions,
      this.getPullingErrorActions(),
      this.getPullingFinallyActions(
        (error) => {
          this.throwExportError(error);
        }
      )
    );
  }

  /**
   * Cleans the last success export response.
   *
   * @returns void
   */
  protected cleanLastExportResponse(): void {
    this.lastExportResponse = undefined;
  }

  /**
   * Cleans the pulling errors.
   *
   * @returns void
   */
  protected cleanPullingErrorList(): void {
    this.pullingErrorList = new Array();
  }

  /**
   * Returns the number of attempts in any pulling action helper.
   *
   * @returns number
   */
  protected getPullingAttempts(): number {
    return 20;
  }

  /**
   * Returns a function for the error actions to be used in a pulling action helper.
   *
   * @returns CallableFunction
   */
  protected getPullingErrorActions(): CallableFunction {
    const actions: CallableFunction = (error: ErrorHelper) => {
      this.pullingErrorList.push(error);
    };

    return actions;
  }

  /**
   * Returns a function for the finally actions to be used in a pulling action helper.
   *
   * @param errorDisplayer CallableFunction
   * @returns CallableFunction
   */
  protected getPullingFinallyActions(errorDisplayer: CallableFunction): CallableFunction {
    const actions: CallableFunction = () => {
      try {
        if (!this.pullingAction.wasReset()) {
          this.pullingAction.execute();
        }
      } catch (error) {
        this.pullingErrorList.push(error);

        const errorToDisplay: ErrorHelper = this.pullingErrorList.shift();
        errorDisplayer(errorToDisplay);

        this.pullingErrorList.forEach(
          (extraError: ErrorHelper) => {
            console.log(extraError.toString());
          }
        );
      }
    };

    return actions;
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    const hasIt: boolean = (
      !this.managerService.hasTemplates() ||
      !this.managerService.hasCurrentMainTitle() ||
      this.managerService.isExporting()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the export button.
   *
   * @returns boolean
   */
  protected hasBlockExport(): boolean {
    const hasIt: boolean = (
      this.hasBlockCancel() ||
      !this.managerService.hasExportableTitles() ||
      this.managerService.isExporting()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the title search.
   *
   * @returns boolean
   */
  protected hasBlockSearchTitle(): boolean {
    return this.hasBlockTemplates();
  }

  /**
   * Indicates if it has to block the templates selection.
   *
   * @returns boolean
   */
  protected hasBlockTemplates(): boolean {
    const block: boolean = (
      !this.managerService.hasAllNeededData() ||
      this.managerService.hasCurrentMainTitle() ||
      this.managerService.isExporting()
    );

    return block;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.pullingAction = new PullingActionHelper();

    this.managerService.setCurrentTitleType(TitleType.series);
    this.reset(true);
  }

  /**
   * Indicates if it is exporting.
   *
   * @returns boolean
   */
  protected isExporting(): boolean {
    return this.managerService.isExporting();
  }

  /**
   * Indicates if it is searching.
   *
   * @returns boolean
   */
  protected isSearching(): boolean {
    const isIt: boolean = (
      this.managerService.isSearching() &&
      !this.managerService.hasCurrentMainTitle()
    );

    return isIt;
  }

  /**
   * Resets all.
   *
   * @returns void
   */
  protected reset(fullReset: boolean): void {
    this.lastExportResponse = undefined;
    this.status = this.componentStatuses.idle;

    this.titleSearchingResults = new Array();
    this.titleNameInput.selectItem(undefined);

    this.cleanLastExportResponse();
    this.cleanPullingErrorList();

    this.managerService.reset(fullReset);
    this.pullingAction.reset();

    this.changeDetector.detectChanges();
  }

  /**
   * Resets the current form.
   *
   * @returns void
   */
  protected resetForm(shouldSetDefaultTerritory: boolean = false): void {
    this.forceResetForm = true;
    this.forceDefaultTerritoryValue = shouldSetDefaultTerritory;
  }

  /**
   * Retrieves the export file.
   *
   * @returns void
   */
  protected retrieveExportFile(): void {
    this.exportService.fetchFile(
      this.lastExportResponse.uuid,
      (data: string | Blob) => {
        try {
          const date: string = moment().format('YYYYMMDD');
          const filename: string = `pie-export-${date}.xlsx`;

          fileSaver.saveAs(data, filename);

          this.reset(true);
          this.managerService.handleNotice('The file was exported successfully.', notificationsContainer.pie.export.key);
        } catch (error) {
          this.throwExportError(error);
        }
      },
      (error: HttpError) => {
        this.throwExportError(error);
      }
    );
  }

  /**
   * Throws an error for the export file process.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected throwExportError(error: ErrorHelper): void {
    this.status = this.componentStatuses.error;

    this.managerService.setIsExporting(false);
    this.managerService.handleError('Failed exporting the file.', error, notificationsContainer.pie.export.key);
  }

  /**
   * Does the current process after the season selection.
   *
   * @param event boolean
   * @returns void
   */
  protected onSeasonSelected(): void {
    this.resetForm(true);
  }
}
