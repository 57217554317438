import { Language } from '@bolt/ui-shared/master-data';
import { LocalizedInterface, Localized } from 'app/modules/common/models/localized.model';
import { RoleMetadataInterface } from './role-metadata.model';

export interface RoleInterface extends LocalizedInterface {

  // custom Role attributes
  originalLanguageId?: number | Language;
  name?: string;
  notes?: string;

}

export class Role
  extends Localized
  implements RoleInterface {

  protected _originalLanguageId: number | Language;
  protected _name: string;
  protected _notes: string;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
  }

  get originalLanguageId(): number | Language {
    return this._originalLanguageId;
  }

  set originalLanguageId(originalLanguageId: number | Language) {
    this._originalLanguageId = originalLanguageId;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get notes(): string {
    return this._notes;
  }

  set notes(notes: string) {
    this._notes = notes;
  }

  get localizations(): RoleMetadataInterface[] {
    return this._localizations;
  }

  set localizations(localizations: RoleMetadataInterface[]) {
    this._localizations = localizations;
  }

  getRawObject(): object {

    const names = Object.getOwnPropertyNames(Role.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Role.prototype, name);
      return !!result.get;
    });

    const object = new Object();
    getters.forEach(key => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return Object.assign(new Object(), super.getRawObject(), object);

  }

  toString(): string {
    return this.name;
  }
}
