import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { LocalizedDataForm } from '../../models/form/localized-data/localized-data-form.model';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';


@Injectable({
  providedIn: 'root',
})
export class FormFactoryService {
  protected attributes: any;

  constructor(
    protected formBuilder: FormBuilder,
    protected formConfig: FormConfigService
  ) {
    this.initialize();
  }

  /**
   * Builds a localized data form.
   *
   * @returns LocalizedDataForm
   */
  buildLocalizedDataForm(defaultValues?: any): LocalizedDataForm {
    return new LocalizedDataForm(this.attributes, defaultValues);
  }

  /**
   * Builds an original data form.
   *
   * @returns OriginalDataForm
   */
  buildOriginalDataForm(defaultValues?: any): OriginalDataForm {
    return new OriginalDataForm(this.attributes, defaultValues);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.attributes = this.formConfig.get('cat.fields');
  }
}
