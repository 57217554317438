import {
  isBoolean as _isBoolean, isObject as _isObject, isNumber as _isNumber, isString as _isString, isUndefined as _isUndefined
} from 'lodash';
import { Account, Country, Language } from '@bolt/ui-shared/master-data';

import { AttributeEnum } from './attribute.enum';
import { DataModeEnum } from './data-mode.enum';
import { LocaleSectionsValidator } from '../../../validators/locale-sections/locale-sections.validator';
import { Status } from './status/status.model';
import { StatusEnum } from './status/status.enum';
import { Type } from '../type/type.model';


export class Lock {
  protected _accountCode: string;
  protected _id: number;
  protected _languageCode: string;
  protected _locale: string;
  protected _mapKey: string;
  protected _status: Map<AttributeEnum, Status>;
  protected _territoryCode: string;
  protected _type: Type;

  constructor(data: any) {
    if (_isObject(data)) {
      this.setId(data);
      this.setLocale(data);
      this.setType(data);
      this.setStatus(data);
    } else {
      throw new Error('Invalid data given for a lock.');
    }
  }

  get accountCode(): string {
    return this._accountCode;
  }

  get id(): number {
    return this._id;
  }

  get languageCode(): string {
    return this._languageCode;
  }

  get locale(): string {
    return this._locale;
  }

  get mapKey(): string {
    return this._mapKey;
  }

  get status(): Map<AttributeEnum, Status> {
    return this._status;
  }

  get territoryCode(): string {
    return this._territoryCode;
  }

  get type(): Type {
    return this._type;
  }

  /**
   * Discovers the codes in the locales.
   *
   * @throws Error
   * @returns void
   */
  protected discoverLocalesCodes(): void {
    const sections: string[] = this.locale.split('_');

    LocaleSectionsValidator.validate(sections);

    this._accountCode = sections[Account.LOCALE_POSITION];
    this._languageCode = sections[Language.LOCALE_POSITION];
    this._territoryCode = sections[Country.LOCALE_POSITION];
  }

  /**
   * Discovers the map key.
   *
   * @returns void
   */
  protected discoverMapKey(): void {
    this._mapKey = `${this.type}-${this.id}`;
  }

  /**
   * Set the ID.
   *
   * @param data any
   * @throws Error
   * @returns void
   */
  protected setId(data: any): void {
    if (_isNumber(data.id)) {
      this._id = data.id;
      this.discoverMapKey();
    } else {
      throw new Error('Invalid ID given for a lock.');
    }
  }

  /**
   * Set the locale.
   *
   * @param data any
   * @throws Error
   * @returns void
   */
  protected setLocale(data: any): void {
    if (_isString(data.locale)) {
      const locale: string = data.locale.trim();

      if (locale.length === 0) {
        throw new Error('Empty locale given for a lock.');
      } else {
        this._locale = locale;
        this.discoverLocalesCodes();
      }
    } else {
      throw new Error('Invalid locale given for a lock.');
    }
  }

  /**
   * Set the status.
   *
   * @param data any
   * @throws Error
   * @returns void
   */
  protected setStatus(data: any): void {
    const isStructureInvalid: boolean =
      !_isObject(data.projectMetadata) ||
      (
        // For features, seasons and episodes both "ccLockedExceptionStatus" and "ccNoDataStatus" are mandatory.
        !this.type.isSeries() &&
        (!_isObject(data.projectMetadata.ccLockedExceptionStatus) || !_isObject(data.projectMetadata.ccNoDataStatus))
      ) ||
      (
        // For episodes the "episodeLocking" is mandatory.
        this.type.isEpisode() && !_isObject(data.episodeLocking)
      );

    if (isStructureInvalid) {
      throw new Error('Invalid project metadata structure given for a lock.');
    } else {
      const isFeatureOrEpisode: boolean = this.type.isFeature() || this.type.isEpisode();

      this._status = new Map();

      this.storeStatusFrom(AttributeEnum.title, data, true, DataModeEnum.full);
      this.storeStatusFrom(AttributeEnum.account, data, !this.type.isSeries(), DataModeEnum.full);

      this.storeStatusFrom(
        AttributeEnum.language,
        data,
        true,
        data.projectMetadata.languageFullData ? DataModeEnum.full : DataModeEnum.partial
      );

      this.storeCreditStatusFrom(AttributeEnum.originalCast, data);
      this.storeCreditStatusFrom(AttributeEnum.originalCrew, data);
      this.storeStatusFrom(AttributeEnum.originalSubtitles, data, isFeatureOrEpisode, DataModeEnum.full);
      this.storeCreditStatusFrom(AttributeEnum.dubbingCast, data);
      this.storeCreditStatusFrom(AttributeEnum.dubbingCrew, data);
      this.storeStatusFrom(AttributeEnum.localizedSubtitles, data, isFeatureOrEpisode, DataModeEnum.full);
      this.storeStatusFrom(AttributeEnum.territory, data, isFeatureOrEpisode, DataModeEnum.full);
    }
  }

  /**
   * Set the type.
   *
   * @param data any
   * @throws Error
   * @returns void
   */
  protected setType(data: any): void {
    this._type = new Type(data.entityType);
    this.discoverMapKey();
  }

  /**
   * Stores the generated Status from the given parameters for credits (Cast & Crew).
   *
   * @param attribute AttributeEnum
   * @param data any
   * @returns void
   */
  protected storeCreditStatusFrom(attribute: AttributeEnum, data: any): void {
    if (this.type.isSeries()) {
      this.storeStatusFrom(attribute, undefined, false, DataModeEnum.full);
    } else {
      const isException: boolean = data.projectMetadata.ccLockedExceptionStatus[attribute];
      const isNoData: boolean = data.projectMetadata.ccNoDataStatus[attribute];

      let source: any;

      if (isNoData && !isException) {
        source = undefined;
      } else {
        source = this.type.isEpisode() ? data.episodeLocking : data;
      }

      this.storeStatusFrom(
        attribute,
        source,
        true,
        isException ? DataModeEnum.exception : DataModeEnum.full
      );
    }
  }

  /**
   * Stores the Status of the given attribute.
   *
   * @param attribute AttributeEnum
   * @param data any
   * @param isAttributeSupported boolean
   * @param dataMode DataEnumMode
   * @throws Error
   * @returns void
   */
  protected storeStatusFrom(attribute: AttributeEnum, data: any, isAttributeSupported: boolean, dataMode: DataModeEnum): void {
    const attributeValue: boolean | undefined = _isUndefined(data) ? undefined : data[attribute];
    let statusValue: StatusEnum;

    if (_isUndefined(attributeValue)) {
      statusValue = isAttributeSupported ? StatusEnum.noData : StatusEnum.notApplicable;
    } else if (_isBoolean(attributeValue)) {
      switch (dataMode) {
        case DataModeEnum.exception:
          statusValue = attributeValue ? StatusEnum.exceptionalLocked : StatusEnum.unlocked;
        break;
        case DataModeEnum.partial:
          statusValue = attributeValue ? StatusEnum.partialLocked : StatusEnum.partialUnlocked;
        break;
        default:
          statusValue = attributeValue ? StatusEnum.locked : StatusEnum.unlocked;
        break;
      }
    } else {
      throw new Error(`Invalid lock value given for ${attribute}: ${attributeValue}`);
    }

    this.status.set(attribute, new Status(statusValue));
  }
}
