import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';

import { BulkActionsHandlerService } from '../../services/unit/bulk-actions-handler/bulk-actions-handler.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FormFactoryService } from '../../services/form-factory/form-factory.service';
import { ItemService } from '../../services/item/item.service';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { Localized as LocalizedItem } from '../../models/item/localized/localized.model';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { ManagerService as UnitManager } from '../../services/unit/manager/manager.service';
import { Original as OriginalItem } from '../../models/item/original/original.model';
import { Product } from '../../models/product/product.model';
import { RoleCapabilitiesHandler } from '../../services/role-capabilities-handler/role-capabilities-handler';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { SearchManager } from 'app/shared/models/search-response/search-manager/search-manager';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { TitleService } from 'app/modules/title/services/title.service';


@Component({
  selector: 'bolt-cat-actions',
  template: require('./bolt-cat-actions.html'),
  styles: [require('./bolt-cat-actions.scss')]
})
export class BoltCatActionsComponent {
  @Input() originalLanguage: number;

  protected displaySearchFlag: boolean;
  protected editionForm: FormArray;
  protected openCreateItemPopupFlag: boolean;
  protected scrollLoading: boolean;
  protected searchManager: SearchManager;
  protected searchProductAssociationsLimit: number;
  protected suggestionsLoading: boolean;
  protected newItemName: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected bulkActionHandler: BulkActionsHandlerService,
    protected formFactory: FormFactoryService,
    protected itemService: ItemService,
    protected layoutHandler: LayoutHandlerService,
    protected notificationService: NotificationService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler,
    protected titleService: TitleService,
    protected unitManager: UnitManager
  ) {
    this.initialize();
  }

  get listLockingStatus(): LockingStatus {
    return this.unitManager.product.itemsLockingStatus;
  }

  get sanitizedQuery(): string {
    return this.searchManager.sanitizedQuery;
  }

  get searchSuggestions(): OriginalItem[] {
    return this.searchManager.suggestions;
  }

  /**
   * Adds an Unit with the given item.
   *
   * @param originalItem Original
   * @returns void
   */
  protected addUnit(originalItem: OriginalItem): void {
    if (this.unitManager.filters.isLanguageDefined()) {
      const locale: string = this.unitManager.getLocaleForDefinedLanguage();

      this.itemService.retrieveLocalized(
        originalItem.type.value,
        originalItem.id,
        locale,
        (localizedItem: LocalizedItem) => {
          this.doAddUnit(originalItem, localizedItem);
        },
        (error: ErrorHelper) => {
          if (error.is404()) {
            this.doAddUnit(originalItem);
          } else {
            this.notificationService.handleError('Failed trying to retrieve a localized item', error, notificationsContainer.cat.key);
            this.layoutHandler.changeToReading();
          }
        }
      );
    } else {
      this.doAddUnit(originalItem);
    }
  }

  /**
   * Adds the given item into the list.
   *
   * @param item Original
   * @returns void
   */
  protected addItemFromCreation(item: OriginalItem): void {
    this.layoutHandler.changeToCreating();
    this.addUnit(item);
  }

  /**
   * Adds the given item into the list before attaching it to the current product.
   *
   * @param selectedItem OriginalItem
   * @returns void
   */
  protected addItemFromSearch(selectedItem: OriginalItem): void {
    this.layoutHandler.changeToCreating();

    this.itemService.attachProduct(
      this.unitManager.product.type.value,
      this.unitManager.product.id,
      selectedItem.type.value,
      selectedItem.id,
      () => {
        this.addUnit(selectedItem);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to add an item.', error, notificationsContainer.cat.key);
        this.layoutHandler.changeToReading();
      }
    );
  }

  /**
   * Cancels the edition mode.
   *
   * @returns void
   */
  protected cancel(): void {
    this.layoutHandler.changeToReading();
    this.reset();
  }

  /**
   * Indicates if the current user can toggle the current list locking status.
   *
   * @returns boolean
   */
  protected canToggleListLockingStatus(): boolean {
    return this.roleCapabilitiesHandler.canToggleListLockingStatusOf(this.listLockingStatus.locked);
  }

  /**
   * Closes the popup for creating an item.
   *
   * @returns void
   */
  protected closeCreateItemPopup(): void {
    this.openCreateItemPopupFlag = false;
  }

  /**
   * Inserts into the list the given unit
   *
   * @param originalItem Original
   * @param localizedItem Localized
   * @returns void
   */
  protected doAddUnit(originalItem: OriginalItem, localizedItem?: LocalizedItem): void {
    try {
      this.unitManager.addNewUnitFor(originalItem, localizedItem);
      this.notificationService.handleSuccess('The item was added successfully.', undefined, notificationsContainer.cat.key);
    } catch (error) {
      this.notificationService.handleError('Failed trying to add the item.', error, notificationsContainer.cat.key);
    } finally {
      this.layoutHandler.changeToReading();
    }
  }

  /**
   * Enables the edition mode.
   *
   * @returns void
   */
  protected edit(): void {
    this.layoutHandler.changeToUpdating();

    const shouldAllowLocalized: boolean = (
      this.unitManager.filters.isLanguageDefined() &&
      this.roleCapabilitiesHandler.hasPrivilegeOnLocalizedItemList(ActionTypeEnum.write)
    );

    const shouldAllowOriginal: boolean =
      this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.write);

    this.editionForm = this.formFactory.buildOnceUnitEditionForm(
      this.unitManager.units,
      shouldAllowOriginal,
      shouldAllowLocalized
    );
  }

  /**
   * Indicates if it has to block the add-item button.
   *
   * @returns boolean
   */
  protected hasBlockAddItem(): boolean {
    const hasIt: boolean =
      this.listLockingStatus.locked ||
      this.layoutHandler.isCreating() ||
      this.layoutHandler.isFetching() ||
      this.unitManager.isLoadingLocalizations();

    return hasIt;
  }

  /**
   * Indicates if it has to block edit button.
   *
   * @returns boolean
   */
  protected hasBlockEdit(): boolean {
    const hasIt: boolean = (
      !this.unitManager.hasUnits() ||
      this.layoutHandler.isFetching() ||
      this.layoutHandler.isCreating() ||
      this.unitManager.isLoadingLocalizations()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block request-localizations button.
   *
   * @returns boolean
   */
  protected hasBlockRequestLocalizations(): boolean {
    return true;
  }

  /**
   * Indicates if it has to block save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    return this.editionForm.invalid;
  }

  /**
   * Indicates if it has to display itself.
   *
   * @returns boolean
   */
  protected hasDisplay(): boolean {
    const hasIt: boolean = (
      this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.read) ||
      this.roleCapabilitiesHandler.hasPrivilegeOnLocalizedItemList(ActionTypeEnum.read)
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the add-item button.
   *
   * @returns boolean
   */
  protected hasDisplayAddItem(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.write);
  }

  /**
   * Indicates if it has to display the default actions.
   *
   * @returns boolean
   */
  protected hasDisplayDefaultActions(): boolean {
    const hasIt: boolean = (
      this.layoutHandler.isReading() ||
      this.layoutHandler.isCreating()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the edit button.
   *
   * @returns boolean
   */
  protected hasDisplayEditButton(): boolean {
    const hasIt: boolean =
      this.unitManager.canEditSomeLocalizedOnCurrentPage() ||
      this.unitManager.canEditSomeLocalizedLockingStatusOnCurrentPage() ||
      this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.write);

    return hasIt;
  }

  /**
   * Indicates if it has to display the edition actions.
   *
   * @returns boolean
   */
  protected hasDisplayEditionActions(): boolean {
    return this.layoutHandler.isUpdating();
  }

  /**
   * Indicates if it has to display the search input.
   *
   * @returns boolean
   */
  protected hasDisplaySearch(): boolean {
    const hasIt: boolean = (
      this.displaySearchFlag &&
      (this.layoutHandler.isReading() || this.layoutHandler.isCreating())
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the search spinner.
   *
   * @returns boolean
   */
  protected hasDisplaySuggestionsSpinner(): boolean {
    const hasIt: boolean = this.suggestionsLoading || this.layoutHandler.isCreating();
    return hasIt;
  }

  /**
   * Indicates if it has a list locking status.
   *
   * @returns boolean
   */
  protected hasListLockingStatus(): boolean {
    return _isObject(this.listLockingStatus);
  }

  /**
   * Indicates if it has to open the popup for creating an item.
   *
   * @returns boolean
   */
  protected hasOpenCreateItemPopup(): boolean {
    return this.openCreateItemPopupFlag;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.displaySearchFlag = false;
    this.editionForm = undefined;
    this.scrollLoading = false;
    this.searchManager = new SearchManager();
    this.searchProductAssociationsLimit = this.appConfig.get('ux.page.cat.searchProductAssociationsQuantity');
    this.suggestionsLoading = false;
  }

  /**
   * Loads the next page for the current query
   *
   * @returns void
   */
  protected loadNextPage(): void {
    if (!this.scrollLoading) {
      this.retrieveSearchSuggestions(this.sanitizedQuery, false);
    }
  }

  /**
   * Opens the popup for creating an item.
   *
   * @param newItemName string
   * @returns void
   */
  protected openCreateItemPopup(newItemName: string): void {
    this.newItemName = newItemName;
    this.openCreateItemPopupFlag = true;
  }

  /**
   * Reset it.
   *
   * @returns void
   */
  protected reset(): void {
    this.editionForm = undefined;
    this.formFactory.destroyUnitEditionForm();
    this.unitManager.removeEmptyLocalizations();
  }

  /**
   * Retrieves all suggestions for the item search.
   *
   * @param query string
   * @param shouldResetSearch boolean
   * @returns void
   */
  protected retrieveSearchSuggestions(query: string, shouldResetSearch: boolean): void {
    const incomingQuery: string = query.toLowerCase().trim();
    this.scrollLoading = true;

    if (shouldResetSearch) {
      this.searchManager.sanitizedQuery = incomingQuery;
      this.suggestionsLoading = true;
    }

    this.itemService.search(
      this.sanitizedQuery,
      this.appConfig.get('ux.page.cat.searchPageSize'),
      this.searchManager.currentPage - 1,
      (response: SearchResponse) => {
        this.searchManager.response = response;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the item suggestions.', error, notificationsContainer.cat.key);
      },
      () => {
        this.scrollLoading = false;
        this.suggestionsLoading = false;
      }
    );
  }

  /**
   * Save the changes.
   *
   * @returns void
   */
  protected save(): void {
    this.layoutHandler.changeToSaving();

    this.bulkActionHandler.readFrom(
      this.editionForm,
      this.unitManager.product,
      this.unitManager.getLocaleForDefinedLanguage()
    );

    this.bulkActionHandler.run(
      () => {
        if (this.bulkActionHandler.hasErrors()) {
          this.notificationService.handleWarning('Some errors detected saving changes.', undefined, notificationsContainer.cat.key);
        } else {
          this.notificationService.handleSuccess('All changes were saved.', undefined, notificationsContainer.cat.key);
        }

        this.layoutHandler.changeToFetching();
        this.reset();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Error detected saving the changes.', error, notificationsContainer.cat.key);
        this.layoutHandler.changeToUpdating();
      }
    );
  }

  /**
   * Toggles the display-search flag.
   *
   * @returns void
   */
  protected toggleDisplaySearch(): void {
    this.displaySearchFlag = !this.displaySearchFlag;
  }

  /**
   * Toggles the list locking status.
   *
   * @returns void
   */
  protected toggleListLockedState(): void {
    this.layoutHandler.changeToSaving();

    const product: Product = this.unitManager.product;

    this.itemService.updateItemsListLockingStatus(
      product.type.value,
      product.id,
      !product.itemsLockingStatus.locked,
      (newProduct: Product) => {
        this.unitManager.setProductAndReset(newProduct, false);
        this.notificationService.handleSuccess('The list locking status was updated');
        this.layoutHandler.changeToReading();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to update the list locking status', error);
        this.layoutHandler.changeToReading();
      }
    );
  }
}
