import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigProvider, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Language, List as MasterDataList , TypeEnum as MasterDataListType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Direction, DirectionEnum, Sort } from '@bolt/ui-shared/searching';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
  capitalize as _capitalize, isEmpty as _isEmpty, isObject as _isObject, isUndefined as _isUndefined, kebabCase as _kebabCase,
  reject as _reject, sortBy as _sortBy, isNumber as _isNumber
} from 'lodash';

import { CategoryEnum } from '../../models/category/category.enum';
import { Element } from '../../models/element/element.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { SearchCriteria } from '../../models/search-criteria/search-criteria.model';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { SearchService } from '../../services/search.service';
import { SearchType } from 'app/shared/models/search-criteria/search-type/search-type.model';
import { SearchTypeEnum } from 'app/shared/models/search-criteria/search-type/search-type.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TypeEnum as ProductType } from '../../models/type/type.enum';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';


@Component({
  selector: 'bolt-search',
  template: require('./bolt-search.html'),
  styles: [require('./bolt-search.scss')]
})
export class BoltSearchComponent extends StormComponent implements OnDestroy {
  @ViewChild('searchBy') protected searchByInput: ElementRef;

  protected readonly titleGroupName: string = 'title';

  protected containers: typeof notificationsContainer = notificationsContainer;
  protected dataFetchListener: Subscription;
  protected filteringOptions: SelectionItem[];
  protected languages: SelectionItem[];
  protected loadAllLanguagesListener: Subscription;
  protected loadLanguageByListener: Subscription;
  protected nameSort: Sort;
  protected searchByListener: Subscription;
  protected searchBySubject: Subject<undefined>;
  protected searchCriteria: SearchCriteria;
  protected searchProductAssociationsLimit: number;
  protected searchResponse: SearchResponse;
  protected selectedLanguage: number;
  protected selectedTypes: string[];
  protected typeEnum: typeof ProductType = ProductType;
  protected urlChangeListener: Subscription;

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected masterData: MasterDataManager,
    protected notificationService: NotificationService,
    protected router: Router,
    protected searchService: SearchService
  ) {
    super();
    this.initialize();
  }

  ngOnDestroy() {
    this.tryUnsubscribe(this.dataFetchListener);
    this.tryUnsubscribe(this.loadAllLanguagesListener);
    this.tryUnsubscribe(this.loadLanguageByListener);
    this.tryUnsubscribe(this.searchByListener);
    this.tryUnsubscribe(this.urlChangeListener);
  }

  /**
   * Fetches the data.
   *
   * @returns void
   */
  protected fetchData(): void {
    const canFetch: boolean = (
      !this.searchCriteria.isQueryEmpty() &&
      (!this.hasHandleAllLanguages() || this.searchCriteria.hasLanguage())
    );

    this.tryUnsubscribe(this.dataFetchListener);
    this.searchResponse = undefined;

    if (canFetch) {
      this.changeStatusToFetchingData();

      this.dataFetchListener = this.searchService.fetch(
        this.searchCriteria,
        (response: SearchResponse) => {
          this.searchResponse = response;

          if (this.searchResponse.hasData()) {
            this.changeStatusToDataFound();
          } else {
            this.changeStatusToDataNotFound();
          }
        },
        (error: ErrorHelper) => {
          this.changeStatusToError();
          this.notificationService.handleError('Failed retrieving data.', error, notificationsContainer.search.key);
        }
      );
    }
  }

  /**
   * Filters the language using the given event.
   *
   * @param event: any
   * @returns void.
   */
  protected filterLanguageFrom(event: any): void {
    if (_isObject(event.originalEvent)) {
      this.tryUnsubscribe(this.loadLanguageByListener);

      if (_isNumber(event.value)) {
        this.loadLanguageByListener = this.masterData.getLanguageById(event.value).subscribe(
          (language: Language) => {
            this.searchCriteria.setLanguage(language.code);
            this.updateUrl();
          },
          (error: ErrorHelper) => {
            this.notificationService.handleError('Failed selecting language.', error, notificationsContainer.search.key);
          }
        );
      } else {
        this.searchCriteria.setLanguage(undefined);
        this.updateUrl();
      }
    }
  }

  /**
   * Filters the types using the given event.
   *
   * @param event any
   * @returns void.
   */
  protected filterTypesFrom(event: any): void {
    if (_isObject(event)) {
      const type: string = this.selectedTypes.join(',');

      this.searchCriteria.setType(type);
      this.updateUrl();
    }
  }

  /**
   * Filters the cast credit using the given event.
   *
   * @param isChecked boolean
   * @returns void.
   */
  protected filterHideCastCredit(isChecked: boolean): void {
    this.searchCriteria.setHideCastCredit(isChecked);
    this.updateUrl();
  }

  /**
   * Filters results to scope by Radar Product IDs.
   *
   * @param isChecked boolean
   * @returns void.
   */
  protected filterResultsByProductId(isChecked: boolean): void {
    this.searchCriteria.setRadarProductIdCheckedStatus(isChecked);
    this.updateUrl();
  }

  /**
   * Returns the reason for the invalid search criteria.
   *
   * @returns string
   */
  protected getInvalidCriteriaReason(): string {
    let reason: string;

    if (this.hasHandleAllLanguages()) {
      reason = 'Please select a language and type something to start to search.';
    } else {
      reason = 'Please type something to start to search.';
    }

    return reason;
  }

  /**
   * Returns the MaxValuesAsString.
   *
   * @returns number
   */
  protected getMaxValuesAsString(): number {
    return this.appConfig.get('ux.consolidatedSearch.multiSelect.maxValuesAsString');
  }

  /**
   * Returns the link for routing the given element.
   *
   * @param element Element
   * @param tabName string
   * @returns Array<any>
   */
  protected getRouterLinkFor(element: Element, tabName?: string): any[] {
    const link: any[] = new Array();

    if (element.type.isTitle()) {
      link.push('/titles');
      link.push(_kebabCase(element.type.toString()));
    } else {
      link.push(`/${element.type.toString()}`);
    }

    link.push(element.id);

    if (tabName) {
      link.push({ show: tabName });
    }

    return link;
  }

  /**
   * Returns the scroll height.
   *
   * @returns string
   */
  protected getScrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Indicates if it has to block "Cast & Crew" the given element.
   *
   * @param element Element
   * @returns boolean
   */
  protected hasBlockCastAndCrewFor(element: Element): boolean {
    const hasIt: boolean = (!element.type.isTitle() || element.type.isSeries());
    return hasIt;
  }

  /**
   * Indicates if it has to block "Characters & Terms" the given element.
   *
   * @param element Element
   * @returns boolean
   */
  protected hasBlockCharactersAndTermsFor(element: Element): boolean {
    const hasIt: boolean = (
      !element.type.isTitle() &&
      (!element.type.isSubproduct() || !element.category.isGame())
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block "Insert & Subtitles" the given element.
   *
   * @param element Element
   * @returns boolean
   */
  protected hasBlockInsertAndSubtitlesFor(element: Element): boolean {
    const hasIt: boolean = (
      !element.type.isTitle() ||
      (!element.type.isFeature() && !element.type.isEpisode())
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block "Metadata Export" the given element.
   *
   * @param element Element
   * @returns boolean
   */
  protected hasBlockMetadataExportFor(element: Element): boolean {
    const hasIt: boolean = (
      !element.type.isTitle() ||
      (!element.type.isFeature() && !element.type.isEpisode())
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the counters.
   *
   * @returns boolean
   */
  protected hasDisplayCounters(): boolean {
    return this.hasSearchResponse();
  }

  /**
   * Indicates if it has to display "Details" the given element.
   *
   * @param element Element
   * @returns boolean
   */
  protected hasDisplayDetailsFor(element: Element): boolean {
    return !element.type.isTitle();
  }

  /**
   * Indicates if it has to display the message section.
   *
   * @param element Element
   * @returns boolean
   */
  protected hasDisplayMessage(): boolean {
    const hasIt: boolean = (
      this.searchCriteria.isQueryEmpty() ||
      (this.hasHandleAllLanguages() && !this.searchCriteria.hasLanguage())
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the pagination.
   *
   * @returns boolean
   */
  protected hasDisplayPagination(): boolean {
    const hasIt: boolean = (this.hasSearchResponse() && this.searchResponse.hasData());
    return hasIt;
  }

  /**
   * Indicates if it has to display "Title Level Data" the given element.
   *
   * @param element Element
   * @returns boolean
   */
  protected hasDisplayTitleLevelDataFor(element: Element): boolean {
    return element.type.isTitle();
  }

  /**
   * Indicates if it has to handle all languages (toggle)
   *
   * @todo Remove this and review its usage in code with US174824.
   * @returns boolean
   */
  protected hasHandleAllLanguages(): boolean {
    return this.appConfigurationManager.getToggleValue(ToggleKeyEnum.searchLocalizedAllLanguages);
  }

  /**
   * Indicates if it has to handle English only (toggle)
   *
   * @todo Remove this and review its usage in code with US174824.
   * @returns boolean
   */
  protected hasHandleEnglish(): boolean {
    return this.appConfigurationManager.getToggleValue(ToggleKeyEnum.searchLocalizedEnglish);
  }

  /**
   *
   * @returns boolean
   */
  protected hasLanguages(): boolean {
    const hasIt: boolean = (this.languages.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has a search response.
   *
   * @returns boolean
   */
  protected hasSearchResponse(): boolean {
    return _isObject(this.searchResponse);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.nameSort = new Sort('name', new Direction(DirectionEnum.Asc));
    this.searchCriteria = new SearchCriteria();
    this.searchProductAssociationsLimit = this.appConfig.get('ux.page.cat.searchProductAssociationsQuantity');

    this.changeStatusToIdle();
    this.setupSearchBySubject();
    this.loadFilteringTypeOptions();
    this.loadLanguagesOptions();
    this.listenUrlChanges();
  }

  /**
   * Indicates if it is sorting by name.
   *
   * @returns boolean
   */
  protected isSortingByName(): boolean {
    const hasIt: boolean = (this.searchCriteria.hasSort() && this.searchCriteria.getSort().isEqualsTo(this.nameSort));
    return hasIt;
  }

  /**
   * Listens the URL changes.
   *
   * @returns void
   */
  protected listenUrlChanges(): void {
    this.urlChangeListener = this.activatedRoute.params.pipe(debounceTime(200)).subscribe(
      (params: Params) => {
        try {
          this.readParamsOrSetDefault(params);
          this.syncSelectedLanguage();
          this.syncSelectedTypes();
          this.fetchData();
        } catch (error) {
          this.changeStatusToError();
          this.notificationService.handleError('Failed reading URL parameters.', error, notificationsContainer.search.key);
        }
      }
    );
  }

  /**
   * Loads the filtering type options.
   *
   * @returns void
   */
  protected loadFilteringTypeOptions(): void {
    this.filteringOptions = new Array();

    this.loadFilteringTypeOptionsFor(
      this.titleGroupName,
      [ProductType.Feature, ProductType.Series, ProductType.Season, ProductType.Episode],
      true
    );

    this.loadFilteringTypeOptionsFor(
      `${ProductType.Talent} & ${ProductType.Character}`,
      [ProductType.Talent, ProductType.Role, ProductType.Character],
      false
    );

    this.loadFilteringTypeOptionsFor(
      ProductType.Term,
      [CategoryEnum.Location, CategoryEnum.Phrase, CategoryEnum.Song, CategoryEnum.Other],
      true
    );

    this.loadFilteringTypeOptionsFor(ProductType.Subproduct, [CategoryEnum.Game, CategoryEnum.Toy], true);
  }

  /**
   * Loads the filtering type options using the given parameters.
   *
   * @param group string
   * @param options Array<ProductType> | Array<CategoryEnum>
   * @param shouldMergeValue boolean
   * @returns void
   */
  protected loadFilteringTypeOptionsFor(
    group: string,
    options: ProductType[] | CategoryEnum[],
    shouldMergeValue: boolean
  ): void {
    const groupName: string = group.toUpperCase();

    options.forEach(
      (option: ProductType | CategoryEnum) => {
        const label: string = _capitalize(option);
        const value: string = (shouldMergeValue ? `${group}:${option}` : option);

        this.filteringOptions.push(
          new SelectionItem(label, value, undefined, groupName)
        );
      }
    );
  }

  /**
   * Loads the languages.
   *
   * @returns void
   */
  protected loadLanguagesOptions(): void {
    this.tryUnsubscribe(this.loadAllLanguagesListener);

    this.languages = new Array();

    this.loadAllLanguagesListener = this.masterData.getListFor(
      MasterDataListType.language,
      false,
      (list: MasterDataList) => {
        const candidates: SelectionItem[] = _reject(
          list.items,
          (item: SelectionItem) => Language.isAll(item.source)
        );

        this.languages = _sortBy(
          candidates,
          (item: SelectionItem) => item.label.toLowerCase()
        );
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed loading languages.', error, notificationsContainer.search.key);
      }
    );
  }

  /**
   * Loads the given page.
   *
   * @param page number
   * @returns void
   */
  protected loadPage(page: number): void {
    this.searchCriteria.setPageNumber(page - 1);
    this.updateUrl();
  }

  /**
   * Reads the given URL parameters and stores them. If there is no parameter, stores the default ones.
   *
   * @param urlParams Params
   * @returns void
   */
  protected readParamsOrSetDefault(urlParams: Params): void {
    if (_isEmpty(urlParams)) {
      this.searchCriteria.reset();
      this.searchCriteria.setPageSize(this.appConfig.get('ux.consolidatedSearch.pageSize'));
    } else {
      this.searchCriteria.readUrlParams(urlParams);
    }

    // TODO: Remove this IF and let English as default (US174824).
    if (this.hasHandleAllLanguages()) {
      this.searchCriteria.setSearchType(new SearchType(SearchTypeEnum.All));

      if (_isUndefined(urlParams.language)) {
        this.searchCriteria.setLanguage(Language.ENGLISH_VALUE);
      }
    } else if (this.hasHandleEnglish()) {
      this.searchCriteria.setLanguage(Language.ENGLISH_VALUE);
    } else {
      this.searchCriteria.setLanguage(undefined);
    }
  }

  /**
   * Searches using the search-by entry.
   *
   * @returns void
   */
  protected searchByEntry(): void {
    this.searchBySubject.next();
  }

  /**
   * Set up a subject for any value change of search-by.
   *
   * @returns void
   */
  protected setupSearchBySubject(): void {
    this.searchBySubject = new Subject();

    this.searchByListener = this.searchBySubject.asObservable().pipe(debounceTime(200)).subscribe(
      () => {
        const value: string = this.searchByInput.nativeElement.value;

        this.searchCriteria.setQuery(value);
        this.searchCriteria.setPageNumber(0);

        this.updateUrl();
      }
    );
  }

  /**
   * Synchronizes the selected language with the stored search criteria.
   *
   * @returns void
   */
  protected syncSelectedLanguage(): void {
    this.tryUnsubscribe(this.loadLanguageByListener);

    if (this.searchCriteria.hasLanguage()) {
      this.loadLanguageByListener = this.masterData.getLanguageByCode(this.searchCriteria.getLanguage()).subscribe(
        (language: Language) => {
          this.selectedLanguage = language.id;
        },
        (error: ErrorHelper) => {
          this.notificationService.handleError('Failed synchronizing the selected language.', error, notificationsContainer.search.key);
        }
      );
    } else {
      this.selectedLanguage = undefined;
    }
  }

  /**
   * Synchronizes the selected type with the stored search criteria.
   *
   * @returns void
   */
  protected syncSelectedTypes(): void {
    this.selectedTypes = (this.searchCriteria.hasType() ? this.searchCriteria.getType().split(',') : new Array());
  }

  /**
   * Sorts by name and toggles the direction.
   * If it is actually sorting by name, toggles the direction.
   * If it is not, set name as the sorting criteria and set "Asc" as default direction.
   *
   * @returns void
   */
  protected sortByNameAndToggleDirection(): void {
    if (this.isSortingByName()) {
      this.nameSort.getDirection().toggle();
    } else {
      this.nameSort.getDirection().setAsc();
    }

    this.searchCriteria.setSort(this.nameSort);
    this.updateUrl();
  }

  /**
   * Tries to unsubscribe the given subscription.
   *
   * @param subscription Subscription
   * @returns void
   */
  protected tryUnsubscribe(subscription: Subscription): void {
    if (_isObject(subscription)) {
      subscription.unsubscribe();
    }
  }

  /**
   * Updates the URL with the stored search criteria.
   *
   * @returns void
   */
  protected updateUrl(): void {
    const params: any = this.searchCriteria.asUrlOutput();

    if (!this.hasHandleAllLanguages()) {
      delete params.language;
    }

    const commands: any[] = ['/search', params];
    const extras: any = { relativeTo: this.activatedRoute };

    this.router.navigate(commands, extras);
  }
}
