import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { isEmpty as _isEmpty } from 'lodash';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { CloningStatusDetails } from '../../models/cloning-status-details.model';
import { Title } from 'app/modules/title/models/title.model';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';


@Injectable({
  providedIn: 'root',
})
export class CreditsCloneService extends BoltAbstractService {

  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Returns an observable for requesting a cloning process.
   *
   * @param sourceTitle Title
   * @param targetTitle Title
   * @param creditType CreditType
   * @param creditPositionType CreditPositionType
   * @param locale string
   * @returns Observable<any>
   */
  public requestClone(
    sourceTitle: Title,
    targetTitle: Title,
    creditType: CreditType,
    creditPositionType: CreditPositionType,
    locale?: string
  ): Observable<any> {
    let endpoint = '';

    const endpointParams: any = {
      '{fromTitleType}': sourceTitle.type.toString(),
      '{fromTitleId}': sourceTitle.id.toString(),
      '{titleId}': targetTitle.id.toString(),
      '{creditPositionType}': creditPositionType.toString().toLowerCase(),
      '{creditType}': creditType.toString().toLowerCase(),
    };

    if (!_isEmpty(locale)) {
      endpoint = `clone.creditsCloneService.${targetTitle.type}.cloneDubbingRequest.endpoint`;
      endpointParams['{locale}'] = locale;
    } else {
      endpoint = `clone.creditsCloneService.${targetTitle.type}.cloneOriginalRequest.endpoint`;
    }

    const url = this.generateUrl(endpoint, endpointParams);

    return this.authHttp.patch(url, undefined).pipe(
      map(
        response => {
          const cloningStatus: CloningStatusDetails = new CloningStatusDetails(response);
          return cloningStatus;
        }
      ),
      catchError(
        error => {
          return this.defaultCatchOn(error);
        }
      )
    );
  }

  /**
   * Fetches the cloning event status of the given id event
   *
   * @param eventId string
   * @returns Observable<any>
   */
  public fetchCloningEventStatus(eventId: string): Observable<any> {
    const endpoint = 'clone.creditsCloneService.fetchCloningEventStatus.endpoint';
    const endpointParams: any = {
      '{eventId}': eventId
    };
    const url = this.generateUrl(endpoint, endpointParams);

    return this.authHttp.get(url).pipe(
      map(
        response => {
          const cloningStatus: CloningStatusDetails = new CloningStatusDetails(response);
          return cloningStatus;
        }
      ),
      catchError(
        error => {
          return this.defaultCatchOn(error);
        }
      )
    );
  }


  /**
   * Fetches the cloning event of the given id event
   *
   * @param eventId string
   * @returns Observable<any>
   */
  public fetchCloningEvent(eventId: string): Observable<any> {
    const endpoint = 'clone.creditsCloneService.fetchCloningEvent.endpoint';
    const endpointParams: any = {
      '{eventId}': eventId
    };
    const url = this.generateUrl(endpoint, endpointParams);

    return this.authHttp.get(url).pipe(
      map(
        response => {
          const cloningStatus: CloningStatusDetails = new CloningStatusDetails(response);
          return cloningStatus;
        }
      ),
      catchError(
        error => {
          return this.defaultCatchOn(error);
        }
      )
    );
  }
}
