import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';

import { AuthHelper, OAuth2TokenInterface } from '../../helpers/auth/auth.helper';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { UserManager } from 'app/modules/user/helpers/user-manager.helper';


@Component({
  selector: 'bolt-auth-validate',
  template: require('./bolt-auth-validate.html'),
  styles: [require('./bolt-auth-validate.scss')]
})
export class BoltAuthValidateComponent implements OnInit {
  protected readonly validatingLegend: string = 'Validating your access token, please wait...';

  constructor(
    protected appConfig: AppConfigProvider,
    protected authHelper: AuthHelper,
    protected location: Location,
    protected route: ActivatedRoute,
    protected router: Router,
    protected userManager: UserManager
  ) { }

  ngOnInit() {
    const token: OAuth2TokenInterface = {
      access_token: this.route.snapshot.params[
        this.appConfig.get('auth.tokenQueryParam', 'token').toString()
      ]
    };

    this.authHelper.storeToken(token);

    this.userManager.fetchAuthenticatedUser().subscribe(
      (serviceResponse: StormServiceResponseSingle) => {
        this.authHelper.setAuthenticatedUser(serviceResponse.item);
      },
      () => {
        this.router.navigate(['/auth/access-denied']);
      },
      () => {
        const attemptedUrl: string = this.authHelper.getAttemptedUrl();

        if (attemptedUrl) {
          this.authHelper.unsetAttemptedUrl();
          this.router.navigateByUrl(attemptedUrl);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    );
  }
}
