import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, UrlSegment } from '@angular/router';
import { AppConfigProvider, AppConfigurationManager, ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { kebabCase as _kebabCase, omit as _omit, isObject as _isObject, isString as _isString } from 'lodash';

import { AccessControlManager } from 'app/modules/user/services/access-control/manager/manager';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { Episode, EpisodeInterface } from '../../models/episode.model';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { Product as CatProduct } from 'app/modules/cat/models/product/product.model';
import { RequestControlEnum } from 'app/modules/user/models/request-control/request-control.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Season, SeasonInterface } from '../../models/season.model';
import { SeriesInterface } from '../../models/series.model';
import { TitleService } from '../../services/title.service';
import { TitleType, TitleInterface, Title } from '../../models/title.model';


@Component({
  selector: 'bolt-page-title-details',
  template: require('./bolt-page-title-details.html'),
  styles: [require('./bolt-page-title-details.scss')],
  encapsulation: ViewEncapsulation.None
})
export class BoltPageTitleDetailsComponent extends StormComponent implements OnInit, OnDestroy {
  @ViewChild('titleDetailsTab') titleDetailsTab: NgbTabset;

  fetchTitleParams: {
    productId: number,
    productType: TitleType,
    show: string
  };

  sub: any;
  product: TitleInterface = new Title();
  pageHeaderTitle: string;
  showTitleLevelData: boolean = false;

  protected readonly modulesPath: typeof modulesPath = modulesPath;

  protected boltStore: any;
  protected catAccess: boolean;
  protected catAccessSubscription: Subscription;
  protected catProduct: CatProduct;
  protected titleTypes: typeof TitleType = TitleType;
  protected uacRequestControl: RequestControlEnum;

  constructor(
    protected accessControlManager: AccessControlManager,
    protected activatedRoute: ActivatedRoute,
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected notificationService: NotificationService,
    protected router: Router,
    protected titleService: TitleService
  ) {
    super();
    this.setupStore();

    this.fetchTitleParams = this.getDefaultFetchTitleParams();
  }

  ngOnInit() {
    this.status = this.componentStatuses.fetchingData;
    this.uacRequestControl = RequestControlEnum.cat;

    this.sub = this.activatedRoute.params.subscribe(
      (params: Params) => {
        Object.assign(this.fetchTitleParams, this.getDefaultFetchTitleParams(), params);
        this.setProduct(this.fetchTitleParams.productId);
      },
      () => {
        this.notificationService.handleError('Failed reading parameters from URL.');
      }
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.cancelCatAccessSubscription();
  }

  /**
   * Indicates if it has to display the  UAC group list.
   *
   * @returns boolean
   */
  hasDisplayGroupList(): boolean {
    const hasIt: boolean =
      this.hasProduct() &&
      this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.details.userGroups.path, [ActionTypeEnum.read]);

    return hasIt;
  }

  /**
   * Cancels the current cat access subscription.
   *
   * @returns void
   */
  protected cancelCatAccessSubscription(): void {
    if (_isObject(this.catAccessSubscription)) {
      this.catAccessSubscription.unsubscribe();
    }
  }

  /**
   * Set up the store.
   *
   * @return void
   */
  protected setupStore(): void {
    this.boltStore = {
      tabs: {
        titleDetails: 'title-details',
        castAndCrew: 'cast-and-crew',
        charactersAndTerms: this.getCharactersAndTermsTabName(),
        subtitlesAndInserts: 'subtitles-and-inserts',
        metadataExport: 'metadata-export'
      },
      translations: {
        navigateBackLink: 'Back to Titles',
        titleDetailsTab: 'Title Details',
        castAndCrewTab: 'Cast & Crew',
        subtitlesAndInsertsTab: 'Insert & Subtitles',
        charactersAndTermsTab: 'Characters & Terms'
      }
    };
  }

  /**
   * Returns the default object to use as a param in the title fetch
   *
   * @returns any
   */
  protected getDefaultFetchTitleParams(): any {
    return {
      productId: undefined,
      productType: undefined,
      show: 'title-level-data',
    };
  }

  /**
   * Returns the name for CAT tab.
   *
   * @returns string
   */
  protected getCharactersAndTermsTabName(): string {
    return this.appConfig.get('ux.page.cat.tabsName.itemsList');
  }

  /**
   * Indicates if there is a product/title.
   *
   * @returns boolean
   */
  protected hasProduct(): boolean {
    return _isObject(this.product);
  }

  /**
   * Indicates if "Moratorium" is active for the current title.
   *
   * @todo Remove this method after UAT and call `product.moratorium`.
   * @returns boolean
   */
  protected isMoratoriumTitle(): boolean {
    const isIt: boolean = this.product.moratorium;
    return isIt;
  }

  /**
   * Validate if the given section is active
   *
   * @param section string
   * @returns boolean
   */
  protected isSectionActive(section: string): boolean {
    return this.fetchTitleParams.show === section;
  }

  /**
   * Activates the given tab.
   *
   * @param event any
   * @returns void
   */
  protected activateTab(event: any): void {
    const routeParams: any = this.activatedRoute.snapshot.params;
    const show: string = event.nextId;
    const path: string = ['/titles', _kebabCase(routeParams.productType), routeParams.productId].join('/');
    const excludedParams: string[] = ['productType', 'productId', 'productTitle'];

    if (show !== this.boltStore.tabs.castAndCrew) {
      excludedParams.push('details');
    }

    const params: any = Object.assign(
      _omit(routeParams, excludedParams),
      { show: show }
    );

    this.router.navigate([path, params]);
  }

  /**
   * Fetches the title with the given ID and store it.
   *
   * @param productId number
   * @returns void
   */
  protected setProduct(productId?: number): void {
    if (!productId) {
      return;
    }

    this.titleService.fetchProduct({
      productId: this.fetchTitleParams.productId,
      productType: this.fetchTitleParams.productType
    }).subscribe(
      (response: StormServiceResponseSingle) => {
        if (response.item) {
          this.product = response.item;

          this.cancelCatAccessSubscription();

          this.catAccessSubscription = this.accessControlManager.hasCatAccess(
            <any>this.product.type,
            this.product.id
          ).subscribe(
            (allowed: boolean) => {
              this.catAccess = allowed;

              this.setCatProduct();

              if (!this.catAccess && (this.activatedRoute.snapshot.params.show === this.getCharactersAndTermsTabName())) {
                let titleUrl: string = 'titles';

                this.activatedRoute.snapshot.url.forEach(
                  (urlSegment: UrlSegment) => {
                    titleUrl += `/${urlSegment.path}`;
                  }
                );

                this.router.navigate([titleUrl]);
              }

              this.changeStatusToDataFound();
            }
          );
        } else {
          this.changeStatusToDataNotFound();
        }
      },
      (error: any) => {
        this.notificationService.handleError('Failed loading title.', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Set the cat product with the current title.
   *
   * @returns void
   */
  protected setCatProduct(): void {
    if (this.catAccess && this.hasProduct()) {
      this.catProduct = CatProduct.newFromTitle(<Title>this.product);
    } else {
      this.catProduct = undefined;
    }

    this.setPageHeaderTitle();
  }

  /**
   * Sets the Page header title, based on the Title's type.
   *
   * @returns void
   */
  protected setPageHeaderTitle(): void {
    switch (true) {
      case this.product instanceof Season:
        this.titleService.fetchProduct({
          productId: (<SeasonInterface>this.product).seriesId,
          productType: TitleType.series
        }).subscribe(
          singleResponse => {
            this.pageHeaderTitle = [
              singleResponse.item.prettifiedLegalTitle,
              this.product.prettifiedLegalTitle
            ].join(': ');
          },
          () => {
            this.pageHeaderTitle = this.product.prettifiedLegalTitle;
          }
        );
        break;

      case this.product instanceof Episode:
        const episode: EpisodeInterface = <EpisodeInterface>this.product;

        this.titleService.fetchProduct({
          productId: (<EpisodeInterface>this.product).seasonId,
          productType: TitleType.season
        }).pipe(
          flatMap(
            singleResponse => {
              const season: SeasonInterface = singleResponse.item;

              return this.titleService.fetchProduct({
                productId: season.seriesId,
                productType: TitleType.series
              }).pipe(
                map(
                  _singleResponse => {
                    const series: SeriesInterface = _singleResponse.item;
                    let title: string = `${series.prettifiedLegalTitle}: Season ${season.seasonNumber}: `;

                    if (_isString(episode.f0_num)) {
                      title += `[${episode.f0_num}]`;
                    }

                    title += ` ${episode.prettifiedLegalTitle}`;

                    return title;
                  }
                )
              );
            }
          )
        )
          .subscribe(
            (pageHeader: string) => {
              this.pageHeaderTitle = pageHeader;
            },
            error => this.pageHeaderTitle = this.product.prettifiedLegalTitle
          );
        break;

      default:
        this.pageHeaderTitle = this.product.prettifiedLegalTitle;
        break;
    }
  }

  /**
   * Indicates if it can show the "Cast & Crew" tab.
   *
   * @returns boolean
   */
  protected showCastAndCrewTab(): boolean {
    const showIt: boolean = !this.isMoratoriumTitle() && !this.product.isSeries();
    return showIt;
  }

  /**
   * Indicates if it can show the "Character and Terms" tab.
   *
   * @returns boolean
   */
  protected showCharacterAndTerms(): boolean {
    return !this.isMoratoriumTitle() && this.catAccess;
  }

  /**
   * Indicates if it can show the "Metadata Export" tab.
   *
   * @returns boolean
   */
  protected showMetadataExportTab(): boolean {
    const showIt: boolean =
      !this.isMoratoriumTitle() &&
      (this.product.isFeature() || this.product.isEpisode());

    return showIt;
  }

  /**
   * Indicates if it can show the "Subtitles & Inserts" tab.
   *
   * @returns boolean
   */
  protected showSubtitlesAndInsertsTab(): boolean {
    const showIt: boolean = !this.isMoratoriumTitle() && (this.product.isFeature() || this.product.isEpisode());
    return showIt;
  }

  /**
   * Navigates to title search page.
   *
   * @returns void
   */
  protected navigateBack(): void {
    this.router.navigate(['/titles']);
  }

  /**
   * Refresh the current title.
   *
   * @returns void
   */
  protected refreshTitle(): void {
    this.setProduct(this.product.id);
  }
}
