import { Component, Input } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'bolt-locking-status-details',
  template: require('./bolt-locking-status-details.html'),
  styles: [require('./bolt-locking-status-details.scss')]
})
export class BoltLockingStatusDetailsComponent {
  @Input('BoltLockingStatusIndicatorLockStatus') lockingStatus: boolean;
  @Input('BoltLockingStatusIndicatorLockBy') lockingBy: string;
  @Input('BoltLockingStatusIndicatorLockDate') lockingDate: string | moment.Moment;
  @Input('BoltLockingStatusIndicatorDateFormat') dateFormat: string = 'YYYY-MM-DD';
  @Input('BoltLockingStatusIndicatorUpdatedBy') updatedBy: string;
  @Input('BoltLockingStatusIndicatorUpdatingDate') updatingDate: string | moment.Moment;

  constructor() { }

  /**
   * Returns the formatted date given a value.
   *
   * @param date string | moment.Moment
   * @returns string
   */
  protected getFormattedDate(date: string | moment.Moment): string {
    const formattedDate: string = date ? moment(date).format(this.dateFormat) : '';

    return formattedDate;
  }
}
