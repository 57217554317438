import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from './../common/common.module';
import { MergeModule } from '../merge/merge.module';

import { BoltPageRoleComponent } from './components/bolt-page-role/bolt-page-role.component';
import { BoltPageRoleDetailsComponent } from './components/bolt-page-role-details/bolt-page-role-details.component';
import { BoltPageRoleListComponent } from './components/bolt-page-role-list/bolt-page-role-list.component';
import { BoltRoleLevelDataComponent } from './components/bolt-role-level-data/bolt-role-level-data.component';
import { BoltRoleListComponent } from './components/bolt-role-list/bolt-role-list.component';
import { BoltRoleListFiltersComponent } from './components/bolt-role-list-filters/bolt-role-list-filters.component';

import {
  BoltRoleMetadataDetailsAttributeActionsComponent
} from './components/bolt-role-metadata-details-attribute-actions/bolt-role-metadata-details-attribute-actions.component';

import { BoltRoleMetadataDetailsComponent } from './components/bolt-role-metadata-details/bolt-role-metadata-details.component';
import { BoltRoleMetadataFiltersComponent } from './components/bolt-role-metadata-filters/bolt-role-metadata-filters.component';
import { BoltRoleMetadataListComponent } from './components/bolt-role-metadata-list/bolt-role-metadata-list.component';
import { BoltRoleMetadataManagerComponent } from './components/bolt-role-metadata-manager/bolt-role-metadata-manager.component';
import { RoleManager } from './helpers/role-manager/role-manager.helper';
import { roleModuleRouting } from './role.routing';
import { RoleService } from './services/role.service';
import { FormFactoryService } from './services/form-factory/form-factory.service';
import { BoltRoleOriginalMetadataFormComponent } from './components/bolt-role-original-metadata-form/bolt-role-original-metadata-form.component';
import { BoltRoleLocalizedMetadataFormComponent } from './components/bolt-role-localized-metadata-form/bolt-role-localized-metadata-form.component';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    MergeModule,
    roleModuleRouting,
  ],
  declarations: [
    BoltPageRoleComponent,
    BoltPageRoleListComponent,
    BoltPageRoleDetailsComponent,
    BoltRoleLevelDataComponent,
    BoltRoleListComponent,
    BoltRoleListFiltersComponent,
    BoltRoleLocalizedMetadataFormComponent,
    BoltRoleMetadataDetailsComponent,
    BoltRoleMetadataDetailsAttributeActionsComponent,
    BoltRoleMetadataFiltersComponent,
    BoltRoleMetadataListComponent,
    BoltRoleMetadataManagerComponent,
    BoltRoleOriginalMetadataFormComponent
  ],
  exports: [
    BoltPageRoleComponent,
    BoltPageRoleListComponent,
    BoltPageRoleDetailsComponent,
    BoltRoleLocalizedMetadataFormComponent,
    BoltRoleMetadataManagerComponent,
    BoltRoleOriginalMetadataFormComponent
  ],
  providers: [
    FormFactoryService,
    RoleManager,
    RoleService
  ]
})
export class RoleModule { }
