import { Pipe, PipeTransform } from '@angular/core';
import { upperFirst as _upperFirst } from 'lodash';


@Pipe({
  name: 'upperFirst'
})
export class UpperFirstPipe implements PipeTransform {
  transform(value: string) {
    if (!value || !value.length) {
      return value;
    }

    return _upperFirst(value);
  }
}
