import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { BoltCatItemDetailsComponent } from './components/bolt-cat-item-details/bolt-cat-item-details.component';
import { modulesPath } from '../auth/services/role/modules-path';


const termConf: any = {
  component: BoltCatItemDetailsComponent,
  canActivate: [AuthGuard],
  data: {
    boltAuthAware: modulesPath.cat.term.path,
    itemType: 'term',
    title: 'Term',
  }
};

const subproductConf: any = {
  component: BoltCatItemDetailsComponent,
  canActivate: [AuthGuard],
  data: {
    boltAuthAware: modulesPath.cat.subproduct.path,
    itemType: 'subproduct',
    title: 'Subproduct',
  }
};

export const catRoutes: Routes = [
  {
    path: 'term/:id',
    ...termConf
  },
  {
    // The name is not necessary anymore, but we keep this entry only for backward compatibility.
    path: 'term/:id/:name',
    ...termConf
  },
  {
    path: 'subproduct/:id',
    ...subproductConf
  },
  {
    // The name is not necessary anymore, but we keep this entry only for backward compatibility.
    path: 'subproduct/:id/:name',
    ...subproductConf
  }
];

export const catModuleRouting: ModuleWithProviders = RouterModule.forChild(catRoutes);
