import { Component } from '@angular/core';

@Component({
  selector: 'bolt-page-xslt',
  template: require('./bolt-page-xslt.html'),
  styles: [require('./bolt-page-xslt.scss')],
  providers: [],
  viewProviders: []
})
export class BoltPageXsltComponent { }
