import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { finalize, catchError, map } from 'rxjs/operators';
import { AppRoutesService } from '@bolt/ui-shared/routing';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleType } from 'app/modules/title/models/title.model';

@Injectable({
  providedIn: 'root',
})
export class MetadataCloneService extends BoltAbstractService {

  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Clones the given metadata to the given targets
   *
   * @param titleType TitleType
   * @param titleId number
   * @param locale string
   * @param targetIds number[]
   * @param fields string[]
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  cloneMetadataToTarget(
    titleType: TitleType,
    titleId: number,
    locale: string,
    targetIds: number[],
    fields: string[],
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.cloneMetadataToTargetAsObservable(
      titleType,
      titleId,
      locale,
      targetIds,
      fields
    ).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (response: StormServiceResponseSingle) => {
        onSuccessDo(response);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      }
    );

    return subs;
  }

  /**
   * Clones the given metadata to the given targets
   *
   * @param titleType TitleType
   * @param titleId number
   * @param locale string
   * @param targetIds number[]
   * @param fields string[]
   * @returns Observable<StormServiceResponseSingle>
   */
  cloneMetadataToTargetAsObservable(
    titleType: TitleType,
    titleId: number,
    locale: string,
    targetIds: number[],
    fields: string[]
  ): Observable<StormServiceResponseSingle> {
    const params: any = {
      '{titleType}': titleType,
      '{titleId}': titleId,
      '{locale}': locale
    };

    const targets: any = {
      target: targetIds
    };

    const url: string = this.generateUrl(
      `clone.metadataCloneService.localization.cloneToTargetsRequest.endpoint`,
      params,
      targets
    );

    const obs: Observable<StormServiceResponseSingle> = this.authHttp.post(url, fields).pipe(
      map(
        (response: HttpResponse<any>) => {
          const stormResponse: StormServiceResponseSingle = new StormServiceResponseSingle(response);
          return stormResponse;
        }
      ),
      catchError(
        (error: any) => this.defaultCatchOn(error)
      )
    );

    return obs;
  }
}
