import { Input } from '@angular/core';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Language } from '@bolt/ui-shared/master-data';

import { RoleForm } from '../../models/form/role-form.model';
import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { StormComponent } from 'app/modules/common/models/storm-component.model';

export abstract class BoltRoleMetadataFormComponent extends StormComponent {
  @Input() form: RoleForm;

  protected originalLanguageId: number;

  constructor(
    protected formConfig: ConfigService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super();
    this.originalLanguageId = Language.ENGLISH_ID;
  }

  get languages(): SelectionItem[] {
    return this.listLayoutProvider.getLanguages(true);
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength: number = this.formConfig.get(`role.fields.${field}.maxLength`);
    return maxLength;
  }

  /**
   * Get the current original language name.
   *
   * @returns Language
   */
  protected getOriginalLanguage(): Language {
    const language: Language = this.languages.find(
      (value: SelectionItem) => value.value === this.originalLanguageId
    ).source;

    return language;
  }
}
