import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Event } from '../../models/event/event.model';


@Component({
  selector: 'bolt-cpm-events-details-panel',
  template: require('./bolt-cpm-events-details-panel.html'),
  styles: [require('./bolt-cpm-events-details-panel.scss')]
})
export class BoltCpmEventsDetailsComponent {
  @Input() event: Event;
  @Input() open: boolean;
  @Output('closed') closeEvent: EventEmitter<void>;

  constructor() {
    this.closeEvent = new EventEmitter();
    this.open = false;
  }

  /**
   * Closes the panel.
   *
   * @returns void
   */
  protected close(): void {
    this.open = false;
    this.closeEvent.emit();
  }
}
