import { Component, OnInit } from '@angular/core';
import { ActionTypeEnum, AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { NotificationService } from '@bolt/ui-shared/notification';

import { Character } from '../../models/character.model';
import { CharacterMetadataManager } from '../../helpers/character-metadata-manager/character-metadata-manager';
import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { FormFactoryService } from '../../services/form-factory/form-factory.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { RoleCapabilitiesHandler } from 'app/modules/cat/services/role-capabilities-handler/role-capabilities-handler';
import { TypeEnum as ItemType } from 'app/modules/cat/models/type/type.enum';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';
import { TypeEnum as CreditType } from 'app/modules/credits/models/wizard/candidate/type/type.enum';
import { EditHistoryMetadata } from 'app/modules/common/components/bolt-edit-history/bolt-edit-history.component';


@Component({
  selector: 'bolt-character-original-data',
  template: require('./bolt-character-original-data.html'),
  styles: [require('./bolt-character-original-data.scss')]
})
export class BoltCharacterOriginalDataComponent implements OnInit {
  showDataFlag: boolean;
  showEditHistory: boolean = false;
  protected editionForm: OriginalDataForm;
  protected isMergeModalOpen: boolean;
  protected isProcessing: boolean;

  constructor(
    protected appConfig: AppConfigProvider,
    protected characterManager: CharacterMetadataManager,
    protected formConfig: FormConfigService,
    protected formFactory: FormFactoryService,
    protected listLayoutProvider: ListLayoutProvider,
    protected notificationService: NotificationService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler
  ) {
    this.initialize();
  }

  ngOnInit() {
    this.createForm();
  }

  get character(): Character {
    return this.characterManager.character;
  }

  get languages(): SelectionItem[] {
    return this.listLayoutProvider.getLanguages(true);
  }

  getMetadata(): EditHistoryMetadata {
    return {
      id: this.character.id,
      locale: this.character.locale,
      type: CreditType.character,
      name: this.character.name,
      language: this.character.language,
      territory: this.character.territory,
      productType: this.character.productType,
      account: this.character.account,
      localeObject: this.character.localeObject
    };
  }

  /**
   * Close the edit history modal.
   *
   * @returns void
   */
  closeEditHistory(): void {
    this.showEditHistory = false;
  }

  /**
   * Open the edit history modal.
   *
   * @returns void
   */
  openEditHistory(): void {
    this.showEditHistory = true;
  }

  /**
   * Indicates if the current user can save.
   *
   * @returns boolean
   */
  protected canSave(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnOriginal(ItemType.Character, ActionTypeEnum.write);
  }

  /**
   * Indicates if the current user can save and lock.
   *
   * @returns boolean
   */
  protected canSaveAndLock(): boolean {
    const itCan: boolean =
      this.roleCapabilitiesHandler.hasPrivilegeOnOriginal(ItemType.Character, ActionTypeEnum.write) &&
      this.roleCapabilitiesHandler.hasPrivilegeOnOriginal(ItemType.Character, ActionTypeEnum.lock) &&
      !this.character.locked;

    return itCan;
  }

  /**
   * Indicates if the current user can unlock.
   *
   * @returns boolean
   */
  protected canUnlock(): boolean {
    const itCan: boolean =
      this.roleCapabilitiesHandler.hasPrivilegeOnOriginal(ItemType.Character, ActionTypeEnum.unlock) &&
      this.character.locked;

    return itCan;
  }

  /**
   * Closes the merge modal.
   *
   * @returns void
   */
  protected closeMergeModal(): void {
    this.isMergeModalOpen = false;
  }

  /**
   * Creates the form.
   *
   * @returns void
   */
  protected createForm() {
    this.editionForm = this.formFactory.buildOriginalDataForm(this.character.getRawObject());

    if (!this.canSave() || this.character.locked) {
      this.editionForm.disable();
    } else {
      this.editionForm.enable();
    }
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength: number = this.formConfig.get(`cat.fields.${field}.maxLength`);
    return maxLength;
  }

  /**
   * Indicates if it has to disable the locking button.
   *
   * @returns boolean
   */
  protected hasBlockLocking(): boolean {
    const hasIt: boolean = (
      !this.hasDisplayLanguages() ||
      this.isProcessing ||
      this.editionForm.invalid ||
      !this.showDataFlag
    );

    return hasIt;
  }

  /**
   * Indicates if it has to disable the display data button.
   *
   * @returns boolean
   */
  protected hasBlockDisplayData(): boolean {
    return this.isProcessing;
  }

  /**
   * Indicates if it has to disable the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const hasIt: boolean = (
      !this.hasDisplayLanguages() ||
      this.isProcessing ||
      this.editionForm.invalid ||
      this.editionForm.disabled ||
      !this.showDataFlag
    );

    return hasIt;
  }

  /**
   * Indicates if it has to block the "Save & Lock" button.
   *
   * @returns boolean
   */
   protected hasBlockSaveAndLock(): boolean {
    return this.hasBlockSave();
  }

  /**
   * Indicates if it has to display data section.
   *
   * @returns boolean
   */
  protected hasDisplayData(): boolean {
    return this.showDataFlag;
  }

  /**
   * Indicates if it has to display the languages.
   *
   * @returns boolean
   */
  protected hasDisplayLanguages(): boolean {
    return this.listLayoutProvider.hasLanguages();
  }

  /**
   * Indicates if it has to display the locking status button.
   *
   * @returns boolean
   */
  protected hasDisplayLockingStatusButton(): boolean {
    return this.roleCapabilitiesHandler.canToggleOriginalLockingStatusOf(this.character.locked, ItemType.Character);
  }

  /**
   * Indicates if it has to display the merge button.
   *
   * @returns boolean
   */
  protected hasDisplayMergeButton(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnLocalized(ItemType.Character, ActionTypeEnum.merge);
  }

  /**
   * Indicates if it has to open the merge modal.
   *
   * @returns boolean
   */
  protected hasOpenMergeModal(): boolean {
    return this.isMergeModalOpen;
  }

  /**
   * Initialize the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.showDataFlag = true;
    this.isMergeModalOpen = false;
    this.isProcessing = false;
  }

  /**
   * Opens the merge modal.
   *
   * @returns void
   */
  protected openMergeModal(): void {
    this.isMergeModalOpen = true;
  }

  /**
   * Refreshes the current character with the given values.
   *
   * @param character Character
   * @returns void
   */
  protected refreshCharacter(character: Character): void {
    this.characterManager.setCharacter(character);
  }

  /**
   * Saves the character original data.
   *
   * @param hasToLock Boolean
   * @returns void
   */
  protected save(hasToLock: boolean): void {
    const data: any = {
      name: this.editionForm.getOriginalNameField().value,
      originalLanguageId: this.editionForm.getOriginalLanguageField().value,
      notes: this.editionForm.getNotesField().value,
      phonetic: this.editionForm.getPhoneticField().value,
      requiredLocalizations: this.editionForm.getRequiredLocalizationsField().value
    };

    let successMessage: string;
    let errorMessage: string;

    if (hasToLock) {
      data['locked'] = true;
      successMessage = 'The character was saved and locked successfully.';
      errorMessage = 'Failed trying to save and lock the character.';
    } else {
      successMessage = 'The character was saved successfully.';
      errorMessage = 'Failed trying to save the character.';
    }

    this.isProcessing = true;
    this.editionForm.disable();

    this.characterManager.updateCharacter(
      data,
      () => {
        this.isProcessing = false;

        if (!hasToLock) {
          this.editionForm.enable();
        }

        this.notificationService.handleSuccess(successMessage, undefined, notificationsContainer.character.details.key);
      },
      (error: ErrorHelper) => {
        this.editionForm.enable();
        this.isProcessing = false;
        this.notificationService.handleError(errorMessage, error, notificationsContainer.character.details.key);
      }
    );
  }

  /**
   * Unlocks the character.
   *
   * @returns void
   */
  protected unlock(): void {
    this.isProcessing = true;

    this.characterManager.updateCharacter(
      { locked: false },
      () => {
        this.isProcessing = false;
        this.editionForm.enable();

        this.notificationService.handleSuccess(
          'The character was unlocked successfully.',
          undefined,
          notificationsContainer.character.details.key
        );
      },
      (error: ErrorHelper) => {
        this.isProcessing = false;
        this.notificationService.handleError(
          'Failed trying to unlocked the character.',
          error,
          notificationsContainer.character.details.key
        );
      }
    );
  }

  /**
   * Toggles the value of showData.
   *
   * @returns void
   */
  protected toggleShowData(): void {
    this.showDataFlag = !this.showDataFlag;
  }
}
