import { Component, Input, OnInit } from '@angular/core';

import { BoltCloneListItemComponent } from '../bolt-clone-list-item/bolt-clone-list-item.component';
import { Episode } from 'app/modules/title/models/episode.model';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { CreditLockingStatusInterface } from 'app/modules/credits/models/credit/credit-locking-status.interface';
import { CreditsService } from 'app/modules/credits/services/credits.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


@Component({
  selector: 'bolt-clone-credits-list-episode-item',
  template: require('./bolt-clone-credits-list-episode-item.html'),
  styles: [require('./bolt-clone-credits-list-episode-item.scss')]
})
export class BoltCloneCreditsListEpisodeItemComponent extends BoltCloneListItemComponent implements OnInit {
  @Input() creditPosition: CreditPositionType;
  @Input() creditType: CreditType;
  @Input() title: Episode;

  constructor(
    protected creditsService: CreditsService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.fetchLockingStatus();
  }

  protected fetchLockingStatus(): void {
    this.changeStatusToFetchingData();
    this.cancelLockingStatusSubscription();

    this.lockingStatusSubscription = this.creditsService.fetchLockingStatus(
      this.title.type,
      this.title.id,
      this.creditType,
      this.creditPosition,
      this.locale
    ).subscribe(
      (lockingStatus: CreditLockingStatusInterface) => {
        this.lockingStatus = new LockingStatus(lockingStatus);
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.changeStatusToError();
      }
    );
  }
}
