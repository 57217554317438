export enum TypeEnum {
  Character = 'character',
  Episode = 'episode',
  Feature = 'feature',
  Season = 'season',
  Series = 'series',
  Subproduct = 'subproduct',
  Talent = 'talent',
  Term = 'term',
  Role = 'role'
}
