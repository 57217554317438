import { Pipe, PipeTransform } from '@angular/core';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isObject as _isObject } from 'lodash';

import { GroupedItem } from '../../models/grouped-item/grouped-item.model';


@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(value: SelectionItem[], criteria: string) {
    const groupedValue: GroupedItem[] = new Array();

    value.forEach(
      (element: SelectionItem) => {
        const criteriaField: any = element[criteria];

        const matchedItem: GroupedItem = groupedValue.find(
          (group: GroupedItem) => group.hasSomeItem(criteria, criteriaField)
        );

        if (_isObject(matchedItem)) {
          matchedItem.addItem(element);
        } else {
          groupedValue.push(new GroupedItem(element[criteria], [element]));
        }
      }
    );

    return groupedValue;
  }
}
