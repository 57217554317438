import { Component, Input, OnChanges } from '@angular/core';

import { RoleInterface } from '../../models/role.model';
import { RoleManager } from '../../helpers/role-manager/role-manager.helper';
import { RoleMetadataInterface } from '../../models/role-metadata.model';
import { RoleMetadataManagerActions } from '../bolt-role-metadata-manager/bolt-role-metadata-manager.component';


@Component({
  selector: 'bolt-role-metadata-details-attribute-actions',
  template: require('./bolt-role-metadata-details-attribute-actions.html'),
  styles: [require('./bolt-role-metadata-details-attribute-actions.scss')],
  providers: []
})
export class BoltRoleMetadataDetailsAttributeActionsComponent implements OnChanges {
  @Input('BoltRoleMetadataDetailsAttributeActionsRole') role: RoleInterface;
  @Input('BoltRoleMetadataDetailsAttributeActionsComputedRoleMetadata')
    computedRoleMetadata: RoleMetadataInterface;

  @Input('BoltRoleMetadataDetailsAttributeActionsAttribute') attribute: string;

  protected isInherited: boolean;

  constructor(
    protected roleManager: RoleManager
  ) { }

  ngOnChanges(changes) {
    if (changes.computedRoleMetadata && changes.computedRoleMetadata.currentValue) {
      this.isInherited = this.computedRoleMetadata.isInheritedAttribute(this.attribute);
    }
  }

  /**
   * Returns a boolean indicating if the Role Metadata attribute can be edited.
   *
   * @return boolean
   */
  canEdit(): boolean {
    return true;
  }

  /**
   * Returns a boolean indicating if the Role Metadata attribute can be deleted.
   *
   * @return boolean
   */
  canDelete(): boolean {
    return ['name'].indexOf(this.attribute) === -1 &&
    !this.isInherited &&
      this.computedRoleMetadata[this.attribute];
  }

  editRoleMetadataAttribute() {
    const defaultValues = new Object();
    defaultValues[this.attribute] = this.computedRoleMetadata.originalData[this.attribute];

    this.roleManager.manageRoleMetadata(
      this.computedRoleMetadata,
      RoleMetadataManagerActions.EDIT,
      <RoleInterface>{
        id: this.computedRoleMetadata.id
      },
      [this.attribute],
      defaultValues
    );
  }

  /**
   * Set the RoleManager to delete the current Role Metadata attribute.
   *
   * @return void
   */
  deleteRoleMetadataAttribute(): void {
    this.roleManager.manageRoleMetadata(
      this.computedRoleMetadata,
      RoleMetadataManagerActions.DELETE,
      <RoleInterface>{
        id: this.computedRoleMetadata.id
      },
      [this.attribute]
    );
  }
}
