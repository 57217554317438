import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'bolt-auth-restricted-access',
  template: require('./bolt-auth-restricted-access.html'),
  styles: [require('./bolt-auth-restricted-access.scss')]
})
export class BoltAuthRestrictedAccessComponent {
  protected attemptedResource: string;

  constructor(
    protected route: ActivatedRoute
  ) {
    this.attemptedResource = this.route.snapshot.params['attemptedResource'];
  }
}
