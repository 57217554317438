import { Injectable } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { StormListType } from '@bolt/ui-shared/master-data';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { BehaviorSubject, Observable, Observer, Subscription, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import { FileUploader } from 'ng2-file-upload';

import { AuthHelper } from 'app/modules/auth/helpers/auth/auth.helper';
import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { XsltInterface } from '../../models/xslt.model';
import { XsltManagerActions } from '../../components/bolt-xslt-manager/bolt-xslt-manager.component';
import { XsltService } from '../../services/xslt.service';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';


/**
 * @todo Move this out of this file.
 */
export interface XsltManagerXsltManagement {
  xslt: XsltInterface;
  action: XsltManagerActions;
}

@Injectable({
  providedIn: 'root',
})
export class XsltManager extends BoltAbstractService {
  protected managedXslt: BehaviorSubject<XsltManagerXsltManagement> = new BehaviorSubject(undefined);
  protected xsltUploader: FileUploader;

  constructor(
    protected appConfig: AppConfigProvider,
    protected appRoutes: AppRoutesService,
    protected authHelper: AuthHelper,
    protected authHttp: AuthHttp,
    protected entityMapperHelper: EntityMapperHelper,
    protected xsltService: XsltService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Allows to manage (create, edit, delete) a Xslt.
   * This method will emit a XsltManagerXsltManagement that will be handled by the BoltXsltManagerComponent.
   *
   * @param xslt XsltInterface
   * @param action XsltManagerActions
   * @returns void
   */
  manageXslt(
    xslt: XsltInterface,
    action: XsltManagerActions
  ) {

    this.managedXslt.next({
      xslt: xslt,
      action: action
    });

  }

  /**
   * Returns the XSLT as observable.
   *
   * @returns Observable<XsltManagerXsltManagement>
   */
  getManagedXslt(): Observable<XsltManagerXsltManagement> {
    return this.managedXslt.asObservable();
  }

  /**
   * Searches the xslt for the given params
   *
   * @param params any
   * @returns Observable<any>
   */
  searchXslt(params: { [propName: string]: any }): Observable<any> {
    const obs: Observable<any> = new Observable(
      (observer: Observer<any>) => {
        this.xsltService.searchXslt(params).subscribe(
          (serviceResponseCollection: any) => {
            this.entityMapperHelper.map(
              serviceResponseCollection.collection,
              [
                {
                  property: 'config.language',
                  mapTo: StormListType.language,
                },
                {
                  property: 'config.territory',
                  mapTo: StormListType.territory,
                },
                {
                  property: 'config.productType',
                  mapTo: StormListType.productType,
                },
                {
                  property: 'config.account',
                  mapTo: StormListType.account,
                },
                {
                  property: 'config.exportType',
                  mapTo: StormListType.xsltProperty,
                },
              ]
            ).subscribe(
              (mappedEntities: any) => {
                serviceResponseCollection.collection = mappedEntities;
                observer.next(serviceResponseCollection);
                observer.complete();
              }
            );
          }
        );
      }
    );

    return obs;
  }

  /**
   * Sets up the xslt file uploader
   *
   * @param xslt XsltInterface
   * @param action XsltManagerActions
   * @returns FileUploader
   */
  setXsltUploader(xslt: XsltInterface, action: XsltManagerActions): FileUploader {
    const apiEndpoint = `exportsService.${action.toString().toLowerCase()}Xslt.endpoint`;

    this.xsltUploader = new FileUploader({
      url: this.generateUrl(apiEndpoint, { '{xsltId}': xslt.id }),
      authToken: `${this.appConfig.get('auth.oAuth2.headerPrefix', 'BEARER')} ${this.authHelper.getToken()}`
    });

    return this.xsltUploader;
  }

  /**
   * Set up the given XSLT.
   *
   * @param xslt XsltInterface
   * @returns Observable<any>
   */
  setupXslt(xslt: XsltInterface): Observable<any> {
    if (!this.xsltUploader) {
      throwError(new Error('XsltManager.xsltUploader missing!'));
    }

    const obs: Observable<any> = new Observable(
      (observer: Observer<any>) => {
        if (this.xsltUploader.queue.length > 0) {
          this.xsltUploader.onErrorItem = (item: any, response: any, status: any, headers: any) => {
            observer.complete();
            throwError(response);
          };

          this.xsltUploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
            response = JSON.parse(response);
            xslt.id = response.id;

            this.setXsltConfig(xslt).subscribe(
              () => observer.next(xslt),
              (error: HttpErrorResponse) => observer.error(error),
              () => observer.complete()
            );
          };

          this.xsltUploader.queue[0].upload();

        } else {
          this.setXsltConfig(xslt).subscribe(
            () => observer.next(xslt),
            (error: HttpErrorResponse) => {
              observer.error(error);
            },
            () => observer.complete()
          );
        }
      }
    );

    return obs;
  }

  /**
   * Downloads the XSLT with the given ID.
   *
   * @param xslt XsltInterface
   * @returns Subscription
   */
  downloadXslt(xslt: XsltInterface) {
    const subs: Subscription = this.xsltService.downloadXslt(xslt.id).pipe(
      map(
        (response: any) => {
          fileSaver.saveAs(response.body, response.headers.get('content-disposition').split('=')[1]);
        }
      )
    ).subscribe();

    return subs;
  }

  /**
   * Set the given XSLT configuration.
   *
   * @param xslt XsltInterface
   * @returns Observable<HttpResponse<any>>
   */
  setXsltConfig(xslt: XsltInterface): Observable<HttpResponse<any>> {
    return this.xsltService.setXsltConfig(xslt);
  }

  /**
   * Deletes the given XSLT.
   *
   * @param xslt XsltInterface
   * @returns Observable<HttpResponse<any>>
   */
  deleteXslt(xslt: XsltInterface): Observable<HttpResponse<any>> {
    return this.xsltService.deleteXslt(xslt);
  }

  /**
   * Fetches the XSLT configuration for the given ID.
   *
   * @param id number
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchXsltConfig(id: number): Observable<StormServiceResponseSingle> {
    return this.xsltService.fetchXsltConfig(id);
  }

  /**
   * Fetches the XSLT for the given ID.
   *
   * @param id number
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchXslt(id: number): Observable<StormServiceResponseSingle> {
    return this.xsltService.fetchXslt(id);
  }

  /**
   * Fetches full metadata for the given payload.
   *
   * @param payload object
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchFullMetadata(payload: object): Observable<StormServiceResponseSingle> {
    return this.xsltService.fetchFullMetadata(payload);
  }

  /**
   * Transforms the full metadata for the given payload.
   *
   * @param payload object
   * @returns Observable<HttpResponse<any>>
   */
  transformFullMetadata(payload: object): Observable<HttpResponse<any>> {
    return this.xsltService.transformFullMetadata(payload);
  }

  /**
   * Updates the XSLT content for the given ID using the given payload.
   *
   * @param id number
   * @param payload object
   * @returns Observable<StormServiceResponseSingle>
   */
  updateXslt(payload: object, id: number): Observable<StormServiceResponseSingle> {
    return this.xsltService.updateXsltContent(id, payload);
  }
}
