import { Component, Input, EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'bolt-aside-content-collection',
  template: require('./bolt-aside-content-collection.html'),
  styles: [require('./bolt-aside-content-collection.scss')]
})
export class BoltAsideContentCollectionComponent {
  @Input('show') show: boolean;

  @Output('toggle') protected toggleEvent: EventEmitter<boolean>;

  constructor() {
    this.initialize();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.toggleEvent = new EventEmitter();
    this.show = false;
  }

  /**
   * Toggles the visibility and emits the event.
   *
   * @returns void
   */
  protected toggle(): void {
    this.show = !this.show;
    this.toggleEvent.emit(this.show);
  }
}
