import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { pickBy as _pickBy } from 'lodash';

import { CollectionManagerHelper, CollectionManagerCollectionInterface } from 'app/modules/common/helpers/collection-manager.helper';
import { debounceTime } from 'rxjs/operators';
import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { Xslt, XsltInterface } from '../../models/xslt.model';
import { XsltManager, XsltManagerXsltManagement } from '../../helpers/xslt-manager/xslt-manager.helper';
import { XsltManagerActions } from '../bolt-xslt-manager/bolt-xslt-manager.component';


@Component({
  selector: 'bolt-xslt-list',
  template: require('./bolt-xslt-list.html'),
  styles: [require('./bolt-xslt-list.scss')]
})
export class BoltXsltListComponent extends StormComponent implements OnInit {
  form: FormGroup;
  xsltCollection: CollectionManagerCollectionInterface;

  protected managedXslt: XsltManagerXsltManagement;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formBuilder: FormBuilder,
    protected collectionManager: CollectionManagerHelper,
    protected xsltManager: XsltManager,
    protected entityMapper: EntityMapperHelper
  ) {
    super();

    this.form = formBuilder.group({
      filter: [],
    });
  }

  ngOnInit() {
    this.collectionManager.setCollections([
      {
        name: 'xslt',
        collection: [],
        sorting: [],
      }
    ]);

    this.xsltCollection = this.collectionManager.getCollection('xslt');

    this.collectionManager.getCollectionHandler().subscribe(
      (collectionHandler: any) => {
        if (collectionHandler.event === 'sortBy') {
          this.searchXslt();
        }
      }
    );

    this.xsltManager.getManagedXslt().subscribe(
      managedXslt => this.managedXslt = managedXslt
    );

    this.form.valueChanges.pipe(debounceTime(200)).subscribe(
      () => {
        this.searchXslt();
      }
    );

    this.searchXslt();
  }

  triggerListRefresh(): void {
    this.searchXslt();
  }

  createXslt(): void {
    this.xsltManager.manageXslt(new Xslt(), XsltManagerActions.CREATE);
  }

  editXsltConfig(xslt: XsltInterface): void {
    this.xsltManager.fetchXsltConfig(xslt.id).subscribe(
      serviceResponseSingle => this.xsltManager.manageXslt(
        serviceResponseSingle.item,
        XsltManagerActions.EDIT
      )
    );
  }

  deleteXslt(xslt: XsltInterface): void {
    this.xsltManager.manageXslt(xslt, XsltManagerActions.DELETE);
  }

  protected searchXslt(): void {
    this.status = this.componentStatuses.fetchingData;

    const filters = Object.assign(
      {
        size: this.appConfig.get('ux.dataTables.pageSize', 20),
        page: this.xsltCollection.pagination ? this.xsltCollection.pagination.current_page - 1 : 0,
        sort: this.xsltCollection.sorting.length ? this.xsltCollection.sorting[0].property : 'templateName',
        sortDir: (this.xsltCollection.sorting.length ? (this.xsltCollection.sorting[0].reverse ? 'desc' : 'asc') : 'desc'),
      },
      _pickBy({ templateName: this.form.get('filter').value }, (value) => value)
    );

    this.xsltManager.searchXslt(filters).subscribe(
      (response: any) => {
        this.status = (response.collection.length ? this.componentStatuses.dataFound : this.componentStatuses.dataNotFound);

        this.collectionManager.setCollectionItems('xslt', response.collection);
        this.collectionManager.setCollectionPagination('xslt', response.pagination);

        this.collectionManager.setCollectionSorting(
          'xslt',
          [{
            property: response.sorting.sort_by,
            reverse: (response.sorting.sort_direction.toLowerCase() === 'DESC'),
            order: 0
          }]
        );
      },
      (error) => {
        this.status = (error.status === 404 ? this.componentStatuses.dataNotFound : this.componentStatuses.error);
      }
    );
  }
}
