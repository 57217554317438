import { isObject as _isObject } from 'lodash';

import { CheckConnection } from './connection/check-connection/check-connection.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { KafkaConnection } from './connection/kafka-connection/kafka-connection.model';
import { Status } from './status/status.model';


export class Health {
  protected databaseConnections: CheckConnection[];
  protected externalConnections: CheckConnection[];
  protected kafkaConnection: KafkaConnection;
  protected status: Status;

  constructor(data: any) {
    if (_isObject(data)) {
      this.kafkaConnection = new KafkaConnection(data.kafkaConnection);
      this.status = new Status(data.status);

      this.setCheckConnectionsFor('database', data.databaseConnections);
      this.setCheckConnectionsFor('external', data.externalConnections);
    } else {
      throw new ErrorHelper('Invalid data given for a CPM Health.');
    }
  }

  /**
   * Gets the database connections.
   *
   * @returns CheckConnection[]
   */
  getDatabaseConnections(): CheckConnection[] {
    return this.databaseConnections;
  }

  /**
   * Gets the external connections.
   *
   * @returns CheckConnection[]
   */
  getExternalConnections(): CheckConnection[] {
    return this.externalConnections;
  }

  /**
   * Gets kafka connection.
   *
   * @returns KafkaConnection
   */
  getKafkaConnection(): KafkaConnection {
    return this.kafkaConnection;
  }

  /**
   * Gets the current status.
   *
   * @returns Status
   */
  getStatus(): Status {
    return this.status;
  }

  /**
   * Sets the connection for.
   *
   * @param listName string
   * @param connectionData any[]
   * @throws ErrorHelper
   * @returns void
   */
  protected setCheckConnectionsFor(listName: string, connectionData: any[]): void {
    if (connectionData.length > 0) {
      const connectionAttribute: string = `${listName}Connections`;
      this[connectionAttribute] = connectionData.map((connection: any) => new CheckConnection(connection));
    } else {
      throw new ErrorHelper(`The ${listName} connections should have at least one connection for a CPM Health.`);
    }
  }
}
