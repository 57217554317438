import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { cloneDeep as _cloneDeep, isArray as _isArray, groupBy as _groupBy, set as _set, at as _at, toPath as _toPath } from 'lodash';
import { CountryInterface } from '@bolt/ui-shared/master-data/models/country/country.interface';
import { StormList, StormListType } from '@bolt/ui-shared/master-data';

import { StormListsProvider } from '../providers/storm-lists.provider';


@Injectable({
  providedIn: 'root',
})
export class EntityMapperHelper {
  constructor(
    protected stormListsProvider: StormListsProvider
  ) { }

  map(
    collection: any | any[],
    mappings: Array<{ property: string, mapTo: StormListType, mapWith?: string }>
  ): Observable<any> {

    let entities = _cloneDeep(collection);
    const isArray = _isArray(entities);

    return new Observable((observer: Observer<any[]>) => {
      this.stormListsProvider.getLists().subscribe(lists => {

          if (!isArray) {
            entities = [entities];
          }

          (<any[]>entities).forEach((entity, entityIndex) => {

            if (typeof entity === 'string') {
              const [language, territory, productType, account] = entity.split('_');
              entities[entityIndex] = {
                language: Number(language),
                territory: territory.split(',').map((terr: any) => terr = Number(terr)),
                productType: productType.split(',').map((pt: any) => pt = Number(pt)),
                account: account.split(',').map((acc: any) => acc = Number(acc)),
              };
            } else if (typeof entity === 'number') {
              entities[entityIndex] = { 0: entity };
            }

            mappings.forEach(mapping => {

              const entityPropValue = _at(entities[entityIndex], mapping.property)[0];
              if (typeof entityPropValue !== 'undefined') {

                mapping.mapWith = mapping.mapWith || 'id';

                if (!Array.isArray(entityPropValue)) {

                  const mappingEntity = lists.getList(mapping.mapTo).findItem(
                    [mapping.mapWith, entityPropValue]
                  );

                  if (mappingEntity) {
                    _set(entities[entityIndex], _toPath(mapping.property), mappingEntity.value);
                  }

                } else {
                  entityPropValue.forEach(
                    (propValue, index) => {
                      const mappingEntity = lists.getList(mapping.mapTo).findItem(
                        [mapping.mapWith, propValue]
                      );
                      if (mappingEntity) {
                        _set(
                          entities[entityIndex],
                          _toPath(mapping.property + '[' + index + ']'),
                          mappingEntity.value
                        );
                      }
                    }
                  );
                }
              }
            });
          });

          observer.next(isArray ? entities : entities[0]);
          observer.complete();
      });
    });
  }

  /**
   * Returns an observable of a strings array having the Territory Region names | Country.
   * A Territory Region name will be returned if ALL of the countries that belongs to a Region are present.
   * If not, the Country name will be returned.
   *
   * @param territories CountryInterface[]
   * @return Observable<any>
   */
  groupTerritoriesByRegion(territories: CountryInterface[]): Observable<any> {
    const obs: Observable<any> = new Observable(
      (observer: Observer<any[]>) => {
        this.stormListsProvider.getList(StormListType.territory).subscribe(
          (territoryList: StormList) => {
            const territoryRegions = territoryList.groupBy('value.region');
            const productTerritoryRegions = _groupBy(territories, 'region');
            let result: any[] = [];

            Object.keys(productTerritoryRegions).forEach(
              (region: any) => {
                if (productTerritoryRegions[region]?.length === territoryRegions[region]?.length) {
                  result = [region, ...result];
                } else {
                  result = [...result, ...productTerritoryRegions[region]];
                }
              }
            );

            observer.next(result);
            observer.complete();
          }
        );
      }
    );

    return obs;
  }
}
