import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from '../common/common.module';
import { CpmModule } from '../cpm/cpm.module';
import { UpcModule } from '../upc/upc.module';
import { UserModule } from '../user/user.module';

import { AppConfigurationService } from './service/app-configuration/app-configuration.service';
import { applicationModuleRouting } from './application.routing';

import {
  BoltAppConfigurationEditSidePanelComponent
} from './components/bolt-app-configuration-edit-side-panel/bolt-app-configuration-edit-side-panel.component';

import { BoltAppConfigurationListComponent } from './components/bolt-app-configuration-list/bolt-app-configuration-list.component';
import { BoltAppHeartbeatCheckComponent } from './components/bolt-app-heartbeat-check/bolt-app-heartbeat-check.component';
import { BoltAppHeartbeatListComponent } from './components/bolt-app-heartbeat-list/bolt-app-heartbeat-list.component';
import { BoltAppPageComponent } from './components/bolt-app-page/bolt-app-page.component';

import {
  BoltAppToggleEditSidePanelComponent
} from './components/bolt-app-toggle-edit-side-panel/bolt-app-toggle-edit-side-panel.component';

import { BoltAppToggleListComponent } from './components/bolt-app-toggle-list/bolt-app-toggle-list.component';
import { HeartbeatHelper } from './helpers/heartbeat/heartbeat.helper';
import { HeartbeatService } from './service/heartbeat/heartbeat.service';


@NgModule({
  imports: [
    CommonModule,
    BoltCommonModule,
    CpmModule,
    UpcModule,
    UserModule,
    applicationModuleRouting,
  ],
  declarations: [
    BoltAppPageComponent,
    BoltAppConfigurationListComponent,
    BoltAppConfigurationEditSidePanelComponent,
    BoltAppHeartbeatListComponent,
    BoltAppHeartbeatCheckComponent,
    BoltAppToggleListComponent,
    BoltAppToggleEditSidePanelComponent
  ],
  exports: [
    BoltAppPageComponent
  ],
  providers: [
    AppConfigurationService,
    HeartbeatService,
    HeartbeatHelper
  ]
})
export class ApplicationModule { }
