import { Injectable } from '@angular/core';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Account, Country, DubbingStudio, Language, List, ProductType, TypeEnum } from '@bolt/ui-shared/master-data';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { isUndefined as _isUndefined } from 'lodash';
import { finalize } from 'rxjs/operators';
import { isObject as _isObject } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { MasterDataService } from '../master-data.service';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';


@Injectable({
  providedIn: 'root',
})
export class MasterDataManager {
  protected lists: Map<TypeEnum, List>;

  constructor(
    protected appConfiguration: AppConfigurationManager,
    protected masterDataService: MasterDataService
  ) {
    this.initialize();
  }

  /**
   * Get the account with the given code.
   *
   * @param code string
   * @returns Observable<Account>
   */
  getAccountByCode(code: string): Observable<Account> {
    let obs: Observable<Account>;

    if (this.hasListFor(TypeEnum.account)) {
      obs = of(this.retrieveAccountByCode(code));
    } else {
      obs = Observable.create(
        (observer: Observer<Account>) => this.getListForAsObservable(TypeEnum.account).subscribe(
          () => {
            observer.next(this.retrieveAccountByCode(code));
            observer.complete();
          },
          (error: ErrorHelper) => {
            observer.error(error);
            observer.complete();
          }
        )
      );
    }

    return obs;
  }

  /**
   * Get the account with the given id.
   *
   * @param id number
   * @returns Observable<Account>
   */
  getAccountById(id: number): Observable<Account> {
    let obs: Observable<Account>;

    if (this.hasListFor(TypeEnum.account)) {
      obs = of(this.retrieveAccountById(id));
    } else {
      obs = Observable.create(
        (observer: Observer<Account>) => this.getListForAsObservable(TypeEnum.account).subscribe(
          () => {
            observer.next(this.retrieveAccountById(id));
            observer.complete();
          },
          (error: ErrorHelper) => {
            observer.error(error);
            observer.complete();
          }
        )
      );
    }

    return obs;
  }

  /**
   * Get the dubbing studio with the given id.
   *
   * @param id number
   * @returns Observable<DubbingStudio>
   */
  getDubbingStudioById(id: number): Observable<DubbingStudio> {
    let obs: Observable<DubbingStudio>;

    if (this.hasListFor(TypeEnum.dubbingStudios)) {
      obs = of(this.retrieveDubbingStudioById(id));
    } else {
      obs = Observable.create(
        (observer: Observer<DubbingStudio>) => this.getListForAsObservable(TypeEnum.dubbingStudios).subscribe(
          () => {
            observer.next(this.retrieveDubbingStudioById(id));
            observer.complete();
          },
          (error: ErrorHelper) => {
            observer.error(error);
            observer.complete();
          }
        )
      );
    }

    return obs;
  }

  /**
   * Get the language with the given code.
   *
   * @param code string
   * @returns Observable<Language>
   */
  getLanguageByCode(code: string): Observable<Language> {
    let obs: Observable<Language>;

    if (this.hasListFor(TypeEnum.language)) {
      obs = of(this.retrieveLanguageByCode(code));
    } else {
      obs = Observable.create(
        (observer: Observer<Language>) => this.getListForAsObservable(TypeEnum.language).subscribe(
          () => {
            observer.next(this.retrieveLanguageByCode(code));
            observer.complete();
          },
          (error: ErrorHelper) => {
            observer.error(error);
            observer.complete();
          }
        )
      );
    }

    return obs;
  }

  /**
   * Get the language with the given id.
   *
   * @param id number
   * @returns Observable<Language>
   */
  getLanguageById(id: number): Observable<Language> {
    let obs: Observable<Language>;

    if (this.hasListFor(TypeEnum.language)) {
      obs = of(this.retrieveLanguageById(id));
    } else {
      obs = Observable.create(
        (observer: Observer<Language>) => this.getListForAsObservable(TypeEnum.language).subscribe(
          () => {
            observer.next(this.retrieveLanguageById(id));
            observer.complete();
          },
          (error: ErrorHelper) => {
            observer.error(error);
            observer.complete();
          }
        )
      );
    }

    return obs;
  }

  /**
   * Get the product type with the given code.
   *
   * @param code string
   * @returns Observable<ProductType>
   */
  getProductTypeByCode(code: string): Observable<ProductType> {
    let obs: Observable<ProductType>;

    if (this.hasListFor(TypeEnum.productType)) {
      obs = of(this.retrieveProductTypeByCode(code));
    } else {
      obs = Observable.create(
        (observer: Observer<ProductType>) => this.getListForAsObservable(TypeEnum.productType).subscribe(
          () => {
            observer.next(this.retrieveProductTypeByCode(code));
            observer.complete();
          },
          (error: ErrorHelper) => {
            observer.error(error);
            observer.complete();
          }
        )
      );
    }

    return obs;
  }

  /**
   * Get the territory with the given code.
   *
   * @param code string
   * @returns Observable<Country>
   */
  getTerritoryByCode(code: string): Observable<Country> {
    let obs: Observable<Country>;

    if (this.hasListFor(TypeEnum.territory)) {
      obs = of(this.retrieveTerritoryByCode(code));
    } else {
      obs = Observable.create(
        (observer: Observer<Country>) => this.getListForAsObservable(TypeEnum.territory).subscribe(
          () => {
            observer.next(this.retrieveTerritoryByCode(code));
            observer.complete();
          },
          (error: ErrorHelper) => {
            observer.error(error);
            observer.complete();
          }
        )
      );
    }

    return obs;
  }

  /**
   * Get the territory with the given id.
   *
   * @param id number
   * @returns Observable<Country>
   */
  getTerritoryById(id: number): Observable<Country> {
    if (this.hasListFor(TypeEnum.territory)) {
      return of(this.retrieveTerritoryById(id));
    } else {
      const obs: Observable<Country> = Observable.create(
        (observer: Observer<Country>) => this.getListForAsObservable(TypeEnum.territory).subscribe(
          () => {
            observer.next(this.retrieveTerritoryById(id));
          },
          (error: ErrorHelper) => {
            observer.error(error);
          },
          () => {
            observer.complete();
          }
        )
      );

      return obs;
    }
  }

  /**
   * Get the current list for the given master data list type.
   *
   * @param type TypeEnum
   * @param forceRefresh boolean
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  getListFor(
    type: TypeEnum,
    forceRefresh: boolean,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.getListForAsObservable(type, forceRefresh).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (list: List) => {
        onSuccessDo(list);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      }
    );

    return subs;
  }

  /**
   * Get the current list for the given master data list type.
   *
   * @param type TypeEnum
   * @param forceRefresh boolean
   * @returns Observable<List>
   */
  getListForAsObservable(type: TypeEnum, forceRefresh: boolean = false): Observable<List> {
    let obs: Observable<List>;

    if (this.hasListFor(type) && !forceRefresh) {
      obs = of(this.lists.get(type));
    } else {
      obs = new Observable(
        (observer: Observer<List>) => {
          this.masterDataService.fetchAsObservable(type).subscribe(
            (list: List) => {
              this.lists.set(type, list);
              observer.next(list);
            },
            (error: ErrorHelper) => {
              observer.error(error);
            },
            () => {
              observer.complete();
            }
          );
        }
      );
    }

    return obs;
  }

  /**
   * Indicates if it has a list for the given master data list type.
   *
   * @param type TypeEnum
   * @returns boolean
   */
  protected hasListFor(type: TypeEnum): boolean {
    const hasIt: boolean = this.lists.has(type);
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.lists = new Map();
  }

  /**
   * Retrieves the account with the given code from the current list.
   *
   * @param code string
   * @return Account
   */
  protected retrieveAccountByCode(code: string): Account {
    const matched: SelectionItem = this.lists.get(TypeEnum.account).find(
      (item: SelectionItem) => item.source.code === code
    );

    return matched.source;
  }

  /**
   * Retrieves the account with the given id from the current list.
   *
   * @param id number
   * @return Account
   */
  protected retrieveAccountById(id: number): Account {
    const matched: SelectionItem = this.lists.get(TypeEnum.account).findByValue(id);
    return matched.source;
  }

  /**
   * Retrieves the dubbing studio with the given id from the current list.
   *
   * @param id number
   * @return DubbingStudio
   */
  protected retrieveDubbingStudioById(id: number): DubbingStudio {
    const matched: SelectionItem = this.lists.get(TypeEnum.dubbingStudios).findByValue(id);

    if (_isObject(matched)) {
      return matched.source;
    }
  }

  /**
   * Retrieves the language with the given code from the current list.
   *
   * @param code string
   * @return Language
   */
  protected retrieveLanguageByCode(code: string): Language {
    const matched: SelectionItem = this.lists.get(TypeEnum.language).find(
      (item: SelectionItem) => item.source.code === code
    );

    return matched.source;
  }

  /**
   * Retrieves the language with the given id from the current list.
   *
   * @param id number
   * @return Language
   */
  protected retrieveLanguageById(id: number): Language {
    const matched: SelectionItem = this.lists.get(TypeEnum.language).findByValue(id);
    return matched.source;
  }

  /**
   * Retrieves the product type with the given code from the current list.
   *
   * @param code string
   * @return ProductType
   */
  protected retrieveProductTypeByCode(code: string): ProductType {
    const matched: SelectionItem = this.lists.get(TypeEnum.productType).find(
      (item: SelectionItem) => item.source.code === code
    );

    return matched.source;
  }

  /**
   * Retrieves the territory with the given code from the current list.
   *
   * @param code string
   * @return Country
   */
  protected retrieveTerritoryByCode(code: string): Country {
    const matched: SelectionItem = this.lists.get(TypeEnum.territory).find(
      (item: SelectionItem) => item.source.code === code
    );

    return matched.source;
  }

  /**
   * Retrieves the territory with the given id from the current list.
   *
   * @param id number
   * @return Country
   */
  protected retrieveTerritoryById(id: number): Country {
    const matched: SelectionItem = this.lists.get(TypeEnum.territory).findByValue(id);
    return matched.source;
  }
}
