import { Injectable } from '@angular/core';
import { StormListInterface, StormLists, StormListType } from '@bolt/ui-shared/master-data';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { isArray as _isArray, cloneDeep as _cloneDeep } from 'lodash';

import { Character } from '../../models/character.model';
import { CharacterMetadataInterface } from '../../models/character-metadata.model';
import { CharacterService } from '../../services/character.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable({
  providedIn: 'root',
})
export class CharacterMetadataManager {
  protected _character: Character;
  protected _characterListener: Observable<Character>;
  protected _characterNotifier: BehaviorSubject<Character>;

  constructor(
    protected characterService: CharacterService,
    protected stormListsProvider: StormListsProvider
  ) {
    this.initialize();
  }

  get character(): Character {
    return this._character;
  }

  get characterListener(): Observable<Character> {
    return this._characterListener;
  }

  /**
   * Creates the current character with the given data.
   *
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  createCharacter(
    data: any,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): void {
    this.characterService.createCharacter(
      new Character(data),
    ).subscribe(
      (response: StormServiceResponseSingle) => {
        onSuccessDo(response.item);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      },
      () => {
        if (finallyDo) {
          finallyDo();
        }
      }
    );
  }

  /**
   * Retrieves a character with the given id.
   *
   * @param characterId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  retrieveCharacter(
    characterId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): void {
    this.characterService.fetchCharacter({ characterId: characterId }).subscribe(
      (response: StormServiceResponseSingle) => {
        this.setCharacter(response.item);
        onSuccessDo(this._character);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      },
      () => {
        if (finallyDo) {
          finallyDo();
        }
      }
    );
  }

  /**
   * Indicates if it has a character.
   *
   * @returns boolean
   */
  hasCharacter(): boolean {
    return this._character !== undefined;
  }

  /**
   * Indicates if the current character has localizations.
   *
   * @returns boolean
   */
  hasLocalizations(): boolean {
    const hasIt: boolean = this.hasCharacter() && this.character.localizations.length > 0;
    return hasIt;
  }

  /**
   * Creates an observer that maps the raw list of character metadata into a list with all the objects required.
   *
   * @param metadataCollection CharacterMetadataInterface[]
   * @returns Observable<CharacterMetadataInterface[]>
   */
  mapAttributes(metadataCollection: CharacterMetadataInterface[]): Observable<CharacterMetadataInterface[]> {
    const obs: Observable<CharacterMetadataInterface[]> = Observable.create(
      (observer: Subscriber<any>) => {
        this.stormListsProvider.getLists().subscribe(
          (lists: StormLists) => {
            const languages: StormListInterface = lists.getList(StormListType.language);
            const territories: StormListInterface = lists.getList(StormListType.territory);
            const productTypes: StormListInterface = lists.getList(StormListType.productType);
            const accounts: StormListInterface = lists.getList(StormListType.account);

            if (!_isArray(metadataCollection)) {
              metadataCollection = new Array(<any>metadataCollection);
            }

            const collection = _cloneDeep(metadataCollection);

            collection.map(
              (metadata: any) => {
                metadata.originalData = _cloneDeep(metadata);
                metadata.language = languages.getItem(metadata.language).value;
                metadata.originalLanguageId = languages.getItem(metadata.originalLanguageId).value;

                metadata.territory = metadata.territory.map(
                  (territory: number) => territories.getItem(territory).value
                );

                metadata.productType = metadata.productType.map(
                  (productType: number) => productTypes.getItem(productType).value
                );

                metadata.account = metadata.account.map(
                  (account: number) => accounts.getItem(account).value
                );
              }
            );

            observer.next(collection);
            observer.complete();
          }
        );
      }
    );

    return obs;
  }

  /**
   * Sets the given character as the current character.
   *
   * @param character Character
   * @returns void
   */
  setCharacter(character: Character): void {
    this._character = character;
    this._characterNotifier.next(this._character);
  }

  /**
   * Updates the current character with the given data.
   *
   * @param data any
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns void
   */
  updateCharacter(
    data: any,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): void {
    this.characterService.updateCharacter(
      { characterId: this.character.id },
      data,
    ).subscribe(
      response => {
        this.setCharacter(response.item);
        onSuccessDo(this._character);
      },
      (error: ErrorHelper) => {
        onErrorDo(error);
      },
      () => {
        if (finallyDo) {
          finallyDo();
        }
      }
    );
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this._character = undefined;
    this._characterNotifier = new BehaviorSubject(undefined);
    this._characterListener = this._characterNotifier.asObservable();
  }
}
