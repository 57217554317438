import { Component, Input } from '@angular/core';

import { BoltRoleMetadataFormComponent } from '../bolt-role-metadata-form/bolt-role-metadata-form.component';
import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { OriginalDataForm } from '../../models/form/original-data/original-data-form.model';


@Component({
  selector: 'bolt-role-original-metadata-form',
  template: require('./bolt-role-original-metadata-form.html'),
  styles: [require('./bolt-role-original-metadata-form.scss')]
})
export class BoltRoleOriginalMetadataFormComponent extends BoltRoleMetadataFormComponent {
  @Input() form: OriginalDataForm;
  @Input() showAsGrid: boolean;

  constructor(
    protected formConfig: ConfigService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super(formConfig, listLayoutProvider);
    this.showAsGrid = false;
  }
}
