import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-collection-counters',
  template: require('./bolt-collection-counters.html'),
  styles: [require('./bolt-collection-counters.scss')]
})
export class BoltCollectionCountersComponent {
  @Input() currentPage?: number;
  @Input() totalPages?: number;
  @Input() totalResults: number;

  constructor() { }
}
