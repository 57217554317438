import { FormArray } from '@angular/forms';

import { EditionForm } from '../edition-form/edition-form.model';


export class EditionFormArray extends FormArray {
  constructor(controls: EditionForm[]) {
    super(controls);
  }

  /**
   * Updates all localized locking status checks in the edition form.
   *
   * @param isLocked boolean
   * @returns void
   */
  updateAllLocalizedLockingStatusChecks(isLocked: boolean): void {
    this.controls.forEach(
      (formGroup: EditionForm) => {
        if (!formGroup.disabled && formGroup.get('_localizedLockingStatus').enabled) {
          formGroup.get('_localizedLockingStatus').value.locked = isLocked;
          formGroup.updateValueAndValidity();
        }
      }
    );
  }

  /**
   * Updates all original locking status checks in the edition form.
   *
   * @param isLocked boolean
   * @returns void
   */
  updateAllOriginalLockingStatusChecks(isLocked: boolean): void {
    this.controls.forEach(
      (formGroup: EditionForm) => {
        if (!formGroup.disabled && formGroup.get('_originalLockingStatus').enabled) {
          formGroup.get('_originalLockingStatus').value.locked = isLocked;
        }
      }
    );
  }


  /**
   * Updates all localization-required checks in the edition form.
   *
   * @param isRequired boolean
   * @returns void
   */
  updateAllRequiredLocalizationsChecks(isRequired: boolean): void {
    this.controls.forEach(
      (formGroup: EditionForm) => {
        if (!formGroup.disabled && formGroup.get('_requiredLocalizations').enabled) {
          formGroup.get('_requiredLocalizations').setValue(isRequired);
        }
      }
    );
  }

  /**
   * Updates all use-domestic checks in the edition form.
   *
   * @param useDomestic boolean
   * @returns void
   */
  updateAllUseDomesticAreChecked(useDomestic: boolean): void {
    this.controls.forEach(
      (formGroup: EditionForm) => {
        if (!formGroup.disabled && formGroup.get('_useDomestic').enabled) {
          formGroup.get('_useDomestic').setValue(useDomestic);
          formGroup.updateDomesticUsageDependencies();
        }
      }
    );
  }
}
