import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BoltCommonModule } from '../common/common.module';

import { BoltGroupListComponent } from './components/bolt-group-list/bolt-group-list.component';
import { UserModule } from '../user/user.module';


@NgModule({
  imports: [CommonModule, UserModule, BoltCommonModule],
  declarations: [BoltGroupListComponent],
  exports: [BoltGroupListComponent],
})
export class GroupModule { }
