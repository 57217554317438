import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppConfigProvider, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { Account, List, TypeEnum } from '@bolt/ui-shared/master-data';
import { HttpError } from '@bolt/ui-shared/common';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { NotificationService } from '@bolt/ui-shared/notification';
import { LevelModeEnum } from '@bolt/ui-shared/title';
import { Observable } from 'rxjs';
import { isArray as _isArray } from 'lodash';

import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { AccountLocalizationForm } from '../../models/localization/form/account/account-localization-form.model';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { BoltTitleLocalizationFormComponent } from '../bolt-title-localization-form/bolt-title-localization-form.component';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';

@Component({
  selector: 'bolt-title-account-localization-form',
  template: require('./bolt-title-account-localization-form.component.html'),
  styles: [require('./bolt-title-account-localization-form.scss')]
})
export class BoltTitleAccountLocalizationFormComponent extends BoltTitleLocalizationFormComponent
  implements AfterViewInit, OnInit, OnDestroy {
  @Input() creationMode: boolean;
  @Input() form: AccountLocalizationForm;

  protected readonly levelMode: LevelModeEnum = LevelModeEnum.account;
  protected loadingPrimaryAssociations: boolean;
  protected loadingSecondaryAssociations: boolean;
  protected primaryProductAssociations: SelectionItem[];
  protected secondaryProductAssociations: SelectionItem[];
  protected featureAttributes: typeof FeatureAttributes = FeatureAttributes;

  constructor(
    protected appConfigurationManager: AppConfigurationManager,
    protected appConfig: AppConfigProvider,
    protected cd: ChangeDetectorRef,
    protected listsProvider: StormListsProvider,
    protected masterDataManager: MasterDataManager,
    protected notificationService: NotificationService
  ) {
    super(appConfigurationManager, listsProvider);
    this.loadingGenres = false;
    this.loadingPrimaryAssociations = false;
    this.loadingSecondaryAssociations = false;
  }

  ngOnInit() {
    this.loadLists();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.form.getSplitFunctionalMetadataFlag()) {
      this.cd.detectChanges();
    }
  }

  /**
   * Filters the current genres.
   *
   * @param reset boolean
   * @returns void
   */
  protected filterGenres(reset: boolean = true): void {
    if (this.hasAttribute(FeatureAttributes.genreId)) {
      this.loadingGenres = true;
      this.filteredGenres = [];
      const accountId: number[] = _isArray(this.form.getAccountField().value) ? this.form.getAccountField().value : [];

      if (reset) {
        this.form.getGenreIdField().setValue(undefined);
      }

      const hasOneAccountSelected: boolean = accountId.length === 1;

      // Filter by ACCOUNT Client-Specific
      if (hasOneAccountSelected) {
        this.filterAccountGenres(accountId[0]);
      // Filter Official Genres
      } else {
        this.getFilteredGenresByType();
      }
    }
  }

  protected loadLists(): void {
    this.loadFunctionalMetadataOptions();
    this.loadGenres();
    this.loadPrimaryAssociations();
    this.loadSecondaryAssociations();
  }

  /**
   * Filters account-specific genres with the given account id.
   *
   * @param accountId number
   * @returns void
   */
  private filterAccountGenres(accountId: number): void {
    let selectedAccount: Account;
    const getAccountByIdObs: Observable<Account> = this.masterDataManager.getAccountById(accountId);
    let accountGenres: SelectionItem[] = [];

    this.subscriptions.add(
      getAccountByIdObs.subscribe(
        (accountItem: Account) => {
          selectedAccount = accountItem;

          accountGenres = this.getFilteredGenresByAccount(
            accountItem.id
          ).map(
            (genre: SelectionItem) => {
              return new SelectionItem(
                genre.label,
                genre.value,
                genre.source,
                selectedAccount.name
              );
            }
          );

          if (accountGenres.length !== 0) {
            this.filteredGenres = accountGenres;
          } else {
            this.getFilteredGenresByType();
          }

          this.loadingGenres = false;
        },
        (error: HttpError) => {
          this.getFilteredGenresByType();
          this.loadingGenres = false;
          this.notificationService.handleError('Failed trying to fetch the Account', error);
        }
      )
    );
  }

  /**
   * Loads the genre list.
   *
   * @returns void
   */
  private loadGenres(): void {
    this.loadingGenres = true;

    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(TypeEnum.genre).subscribe(
        (list: List) => {
          this.genres = list.items;
          this.filterGenres(false);
          this.loadingGenres = false;
        },
        (error: HttpError) => {
          this.notificationService.handleError(
            'Failed trying to fetch the genre list',
            error
          );

          this.loadingGenres = false;
        }
      )
    );
  }

  /**
   * Loads the primary product association list.
   *
   * @returns void
   */
  private loadPrimaryAssociations(): void {
    this.loadingPrimaryAssociations = true;

    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(TypeEnum.primaryProductAssociation).subscribe(
        (list: List) => {
          this.primaryProductAssociations = list.items;
          this.loadingPrimaryAssociations = false;
        },
        (error: HttpError) => {
          this.notificationService.handleError(
            'Failed trying to fetch the primary product associations list',
            error
          );

          this.loadingPrimaryAssociations = false;
        }
      )
    );
  }

  /**
   * Loads the secondary product associations list.
   *
   * @returns void
   */
  private loadSecondaryAssociations(): void {
    this.loadingSecondaryAssociations = true;

    this.subscriptions.add(
      this.masterDataManager.getListForAsObservable(TypeEnum.secondaryProductAssociation).subscribe(
        (list: List) => {
          this.secondaryProductAssociations = list.items;
          this.loadingSecondaryAssociations = false;
        },
        (error: HttpError) => {
          this.notificationService.handleError(
            'Failed trying to fetch the secondary product associations list',
            error
          );

          this.loadingSecondaryAssociations = false;
        }
      )
    );
  }
}
