import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';

import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { EditionForm as OriginalItemEditionForm } from '../../models/item/original/edition-form/edition-form.model';
import { EditionForm as UnitEditionForm } from '../../models/unit/edition-form/edition-form.model';
import { EditionFormArray } from '../../models/unit/edition-form-array/edition-form-array.model';
import { Localized as LocalizedItem } from '../../models/item/localized/localized.model';
import { Original as OriginalItem } from '../../models/item/original/original.model';
import { RoleCapabilitiesHandler } from '../role-capabilities-handler/role-capabilities-handler';
import { Unit } from '../../models/unit/unit.model';


@Injectable({
  providedIn: 'root',
})
export class FormFactoryService {
  protected attributes: any;
  protected editionForm: EditionFormArray;

  constructor(
    protected formBuilder: FormBuilder,
    protected formConfig: FormConfigService,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler,
  ) {
    this.initialize();
  }

  /**
   * Builds once an edition form for the given units and returns it.
   *
   * @param units Unit[]
   * @param shouldAllowOriginal boolean
   * @param shouldAllowLocalized boolean
   * @returns EditionFormArray
   */
  buildOnceUnitEditionForm(
    units: Unit[], shouldAllowOriginal: boolean, shouldAllowLocalized: boolean
  ): EditionFormArray {
    if (!this.hasUnitEditionForm() && (units.length > 0)) {
      const entries: UnitEditionForm[] = new Array();

      units.forEach(
        (unit: Unit) => {
          if (shouldAllowLocalized) {
            this.ensureLocalizedItemOn(unit);
          }

          const entry: UnitEditionForm = new UnitEditionForm(
            unit, this.attributes, shouldAllowOriginal, shouldAllowLocalized
          );

          if (shouldAllowOriginal) {
            this.setupOriginalMetadataFieldStatus(unit, entry);
            this.setupOriginalLockFieldStatus(unit, entry);
          }

          if (shouldAllowLocalized) {
            this.setupLocalizedMetadataFieldStatus(unit, entry);
            this.setupLocalizedLockFieldStatus(unit, entry);
          }

          entries.push(entry);
        }
      );

      this.editionForm = new EditionFormArray(entries);
    }

    return this.editionForm;
  }

  /**
   * Builds an edition form for the given original item and returns it.
   *
   * @param item OriginalItem
   * @returns FormGroup
   */
  buildOriginalItemEditionForm(item: OriginalItem): FormGroup {
    const form: FormGroup = this.formBuilder.group(
      new OriginalItemEditionForm(item, this.attributes)
    );

    return form;
  }

  /**
   * Destroys the cached unit edition form.
   *
   * @returns void
   */
  destroyUnitEditionForm(): void {
    this.editionForm = undefined;
  }

  /**
   * Indicates if it has a cached unit edition form.
   *
   * @returns boolean
   */
  hasUnitEditionForm(): boolean {
    const hasIt: boolean = _isObject(this.editionForm);
    return hasIt;
  }

  /**
   * Ensures that the given unit has a localized item, creating an empty one.
   * This is necessary for display the form, due to users are able to create or edit localized data.
   *
   * @param unit Unit
   * @returns void
   */
  protected ensureLocalizedItemOn(unit: Unit): void {
    if (!unit.isComplete()) {
      const localized: LocalizedItem = new LocalizedItem({
        category: unit.original.category.toString(),
        itemType: unit.original.type.toString(),
        rootId: unit.original.id
      });

      unit.setLocalized(localized);
    }
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.attributes = this.formConfig.get('cat.fields');
    this.destroyUnitEditionForm();
  }

  /**
   * Set up the status of the localized metadata fields.
   *
   * @param unit Unit
   * @param form UnitEditionForm
   * @returns void
   */
  protected setupLocalizedMetadataFieldStatus(unit: Unit, form: UnitEditionForm): void {
    if (this.roleCapabilitiesHandler.hasPrivilegeOnLocalizedItemList(ActionTypeEnum.write)) {
      const locked: boolean = unit.localized.lockingStatus.locked;

      if (locked && !this.roleCapabilitiesHandler.canToggleLocalizedLockingStatusOf(locked)) {
        form.disableLocalizedMetadataFields();
      }
    } else {
      form.disableLocalizedMetadataFields();
    }
  }

  /**
   * Set up the status of the localized lock field.
   *
   * @param unit Unit
   * @param form UnitEditionForm
   * @returns void
   */
  protected setupLocalizedLockFieldStatus(unit: Unit, form: UnitEditionForm): void {
    const locked: boolean = unit.localized.lockingStatus.locked;

    if (!this.roleCapabilitiesHandler.canToggleLocalizedLockingStatusOf(locked)) {
      form.disableLocalizedLockField();
    }
  }

  /**
   * Set up the status of the Original metadata fields.
   *
   * @param unit Unit
   * @param form UnitEditionForm
   * @returns void
   */
  protected setupOriginalMetadataFieldStatus(unit: Unit, form: UnitEditionForm): void {
    if (this.roleCapabilitiesHandler.hasPrivilegeOnOriginalItemList(ActionTypeEnum.write)) {
      const locked: boolean = unit.original.lockingStatus.locked;

      if (locked && !this.roleCapabilitiesHandler.canToggleOriginalLockingStatusOf(locked)) {
        form.disableOriginalMetadataFields();
      }
    } else {
      form.disableOriginalMetadataFields();
    }
  }

  /**
   * Set up the status of the Original lock field.
   *
   * @param unit Unit
   * @param form UnitEditionForm
   * @returns void
   */
  protected setupOriginalLockFieldStatus(unit: Unit, form: UnitEditionForm): void {
    const locked: boolean = unit.original.lockingStatus.locked;

    if (!this.roleCapabilitiesHandler.canToggleOriginalLockingStatusOf(locked)) {
      form.disableOriginalLockField();
    }
  }
}
