import { Component, Input } from '@angular/core';
import { isObject as _isObject } from 'lodash';

import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';


@Component({
  selector: 'bolt-locking-status-reason',
  template: require('./bolt-locking-status-reason.html'),
  styles: [require('./bolt-locking-status-reason.scss')]
})
export class BoltLockingStatusReasonComponent {
  @Input() lockingStatus: LockingStatus;

  constructor() {
    this.initialize();
  }

  /**
   * Indiciates if it has to display it.
   *
   * @returns boolean
   */
  protected hasDisplay(): boolean {
    const hasIt: boolean = (_isObject(this.lockingStatus) && this.lockingStatus.hasLockReason());
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.lockingStatus = undefined;
  }
}
