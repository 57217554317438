import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ManagerService as UnitManager } from '../../services/unit/manager/manager.service';
import { Product } from '../../models/product/product.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-cat',
  template: require('./bolt-cat.html'),
  styles: [require('./bolt-cat.scss')]
})
export class BoltCatComponent implements OnInit, OnDestroy {
  @Input() product: Product;
  @Input() originalLanguage: number;

  protected notificationsContainer = notificationsContainer;

  constructor(
    protected unitManager: UnitManager
  ) { }

  ngOnDestroy() {
    this.unitManager.setProductAndReset(undefined);
  }

  ngOnInit() {
    this.unitManager.setProductAndReset(this.product);
  }
}
