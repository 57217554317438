import { kebabCase as _kebabCase, isNumber as _isNumber, isObject as _isObject } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class Counters {
  protected inProgress: number;
  protected completed: number;
  protected halted: number;
  protected error: number;
  protected total: number;

  constructor(data: any) {
    if (_isObject(data)) {
      this.set('inProgress', data.IN_PROGRESS);
      this.set('completed', data.COMPLETED);
      this.set('halted', data.HALTED);
      this.set('error', data.ERROR);

      this.total = this.getCompleted() + this.getError() + this.getHalted() + this.getInProgress();
    } else {
      throw new ErrorHelper('Invalid data given for Counters of CPM Event Summary.');
    }
  }

  /**
   * Returns the "In Progress" counter.
   *
   * @returns number
   */
  getInProgress(): number {
    return this.inProgress;
  }

  /**
   * Returns the "Completed" counter.
   *
   * @returns number
   */
  getCompleted(): number {
    return this.completed;
  }

  /**
   * Returns the "Halted" counter.
   *
   * @returns number
   */
  getHalted(): number {
    return this.halted;
  }

  /**
   * Returns the "Error" counter.
   *
   * @returns number
   */
  getError(): number {
    return this.error;
  }

  /**
   * Returns the total.
   *
   * @returns number
   */
  getTotal(): number {
    return this.total;
  }

  /**
   * Set the given value for the given attribute.
   *
   * @param attribute string
   * @param value number
   * @throws ErrorHelper
   * @returns void
   */
  protected set(attribute: string, value: number): void {
    if (_isNumber(value) && (value >= 0)) {
      this[attribute] = value;
    } else {
      throw new ErrorHelper(`Invalid ${_kebabCase(attribute)} value given for a Counter of CPM Event Summary.`);
    }
  }
}
