import { Component, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

import { MappableAttributeEnum } from '@bolt/ui-shared/title';
import { NotificationService } from '@bolt/ui-shared/notification';
import { StormList, StormListInterface, StormLists, StormListType } from '@bolt/ui-shared/master-data';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';


@Component({
  selector: 'bolt-edit-history-mapper',
  template: require('./bolt-edit-history-mapper.html'),
  styles: [require('./bolt-edit-history-mapper.scss')]
})
export class BoltEditHistoryMapperComponent extends StormComponent implements OnChanges {
  @Input() attribute: MappableAttributeEnum;
  @Input() value: string;

  protected mappedValue: string;

  constructor(
    protected notificationService: NotificationService,
    protected stormListsProvider: StormListsProvider
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    const shouldMap: boolean =
      (changes.attribute.currentValue && changes.value.currentValue) &&
      (this.hasChanged(changes.attribute) || this.hasChanged(changes.value));

    if (shouldMap) {
      this.discoverMappedValue();
    } else {
      this.changeStatusToIdle();
    }
  }

  /**
   * Discovers the mapped value based on attribute mapped.
   *
   * @throws ErrorHelper
   * @returns void
   */
  protected discoverMappedValue(): void {
    this.changeStatusToFetchingData();

    const extractedIds: number[] = this.value.match(/\d+/g).map(Number);
    this.mappedValue = '';

    if (extractedIds.length > 0) {
      this.stormListsProvider.getLists().subscribe(
        (lists: StormLists) => {
          const stormList: StormList = this.retrieveMasterDataList(lists);

          const names: string[] = extractedIds?.map(
            (id: number) => this.retrieveNameFor(stormList.getItem(id).value, this.attribute)
          );

          this.mappedValue = names.join(', ');
          this.changeStatusToDataFound();
        }, (error: ErrorHelper) => {
          this.handleError('Failed trying to retrieve the master data lists', error);
        }
      );
    } else {
      this.handleError('Failed extracting IDs to be mapped.');
    }
  }

  /**
   * Handles the error.
   *
   * @param message string
   * @param error ErrorHelper
   * @returns void
   */
  protected handleError(message: string, error?: ErrorHelper): void {
    this.notificationService.handleError(message, error);
    this.changeStatusToError();
  }

  /**
   * Indicates if the given simpleChange has been changed.
   *
   * @param change SimpleChange
   * @returns boolean
   */
  protected hasChanged(change: SimpleChange): boolean {
    return change.previousValue !== change.currentValue;
  }

  /**
   * Retrieves the list name for.
   *
   * @param list any
   * @returns string
   */
  protected retrieveNameFor(list: any, attribute: MappableAttributeEnum): string {
    let name = '';

    switch (attribute) {
      case MappableAttributeEnum.ratingId:
        name = `${list.ratingSystem.name} - ${list.name}`;
        break;
      case MappableAttributeEnum.prefixId:
      case MappableAttributeEnum.suffixId:
        name = list.value;
        break;
      default:
        name = list.name;
        break;
    }

    return name;
  }

  /**
   * Retrieves the master data list to be used for mapping.
   *
   * @param masterDataList StormLists
   * @throws ErrorHelper
   * @returns StormListInterface
   */
  protected retrieveMasterDataList(masterDataList: StormLists): StormListInterface {
    switch (this.attribute) {
      case MappableAttributeEnum.countryOfOriginId:
        return masterDataList.lists[StormListType.territory];
      case MappableAttributeEnum.functionalMetadata:
        return masterDataList.lists[StormListType.functionalMetadata];
      case MappableAttributeEnum.genreId:
        return masterDataList.lists[StormListType.genre];
      case MappableAttributeEnum.originalSpokenLanguageId:
      case MappableAttributeEnum.originalLanguageId:
        return masterDataList.lists[StormListType.language];
      case MappableAttributeEnum.primaryProductAssociation:
        return masterDataList.lists[StormListType.primaryProductAssociation];
      case MappableAttributeEnum.productionCompanyId:
      case MappableAttributeEnum.productionCompanyIds:
        return masterDataList.lists[StormListType.company];
      case MappableAttributeEnum.ratingId:
        return masterDataList.lists[StormListType.rating];
      case MappableAttributeEnum.ratingSystemReasonId:
        return masterDataList.lists[StormListType.ratingSystemReason];
      case MappableAttributeEnum.secondaryProductAssociation:
        return masterDataList.lists[StormListType.secondaryProductAssociation];
      case MappableAttributeEnum.prefixId:
      case MappableAttributeEnum.suffixId:
        return masterDataList.lists[StormListType.affix];
      default:
        throw new ErrorHelper(`Invalid mappable attribute given for ${this.attribute}`);
    }
  }
}
