import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TalentInterface, Talent } from '../../models/talent.model';
import { TalentManager, TalentManagerMetadataManagement } from '../../helpers/talent-manager/talent-manager.helper';
import { TalentMetadataInterface, TalentMetadata } from '../../models/talent-metadata.model';
import { TalentService } from '../../services/talent.service';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';


@Component({
  selector: 'bolt-page-talent-details',
  template: require('./bolt-page-talent-details.html'),
  providers: [
    TalentService
  ],
  viewProviders: [
    TalentManager
  ]
})
export class BoltPageTalentDetailsComponent extends StormComponent implements OnInit {
  protected talent: TalentInterface = new Talent();
  protected pageHeaderTalentFullName: string;
  protected showTalentLevelData: boolean = true;
  protected selectedMetadata: TalentMetadataInterface;
  protected talentMetadataManager: TalentManagerMetadataManagement;
  protected notificationsContainer = notificationsContainer;

  constructor(
    protected talentService: TalentService,
    protected talentManager: TalentManager,
    protected route: ActivatedRoute,
    protected router: Router,
    protected appConfig: AppConfigProvider,
    protected changeDetector: ChangeDetectorRef
  ) {

    super();

    this.talentManager.getManagedTalentMetadata().subscribe(
      talentMetadataManager => this.talentMetadataManager = talentMetadataManager
    );

  }

  ngOnInit() {
    this.status = this.componentStatuses.fetchingData;
    this.setTalent();

    if (this.route.snapshot.params['show-talent-level-data'] === 'true') {
      this.showTalentLevelData = true;
    }
  }

  /**
   * Set the given talent as the selected one.
   *
   * @param talentMetadata TalentMetadataInterface
   * @return void
   */
  protected setSelectedMetadata(talentMetadata: TalentMetadataInterface): void {
    if (talentMetadata instanceof TalentMetadata) {
      this.selectedMetadata = talentMetadata;
    } else {
      this.selectedMetadata = undefined;
    }

    this.changeDetector.detectChanges();
  }

  /**
   * Set or refresh with the url the current talent.
   *
   * @param refreshOrSelectMetadata any
   * @return void
   */
  protected setTalent(refreshOrSelectMetadata: any = true): void {
    if (!refreshOrSelectMetadata) {
      return;
    }

    this.talentService.fetchTalent({
      talentId: Number(this.route.snapshot.params['talentId'])
    }).subscribe(
      response => {
        if (response.item) {
          this.talent = response.item;
          this.setPageHeaderTalentFullName();
          this.status = this.componentStatuses.dataFound;

          this.setSelectedMetadata(refreshOrSelectMetadata);

        } else {
          this.status = this.componentStatuses.dataNotFound;
        }
      },
      error => this.status = this.componentStatuses.error,
      () => this.talentMetadataManager = undefined
    );
  }

  /**
   * Set the Page header Talent's full name
   *
   * @return void
   */
  protected setPageHeaderTalentFullName(): void {

    const talent = new Talent(this.talent.getRawObject());

    // Map Talent attributes (prefix, suffix...)
    this.talentManager.mapAttributes([talent]).subscribe(mappedTalent => {
      this.pageHeaderTalentFullName = (<TalentInterface>mappedTalent[0]).getFullName();
    });
  }

  /**
   * Navigates back in the platform's history.
   *
   * @return void
   */
  protected navigateBack() {
    return history.back();
  }

  /**
   * Reacts to a localization removal.
   *
   * @param message boolean
   * @returns void
   */
  protected onLocalizationRemove(message: boolean): void {
    if (message) {
      this.setTalent();
    }
  }
}
