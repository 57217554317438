import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';
import { Observable, Subscription } from 'rxjs';

import { ActionEnum } from './action.enum';
import { Configuration } from '../../models/configuration/configuration.model';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Form as ConfigurationForm } from '../../models/configuration/form/form.model';
import { LayoutService as FormLayoutService } from 'app/shared/services/form/layout/layout.service';
import { Type as ConfigurationType } from '../../models/configuration/type/type.model';


@Component({
  selector: 'bolt-cpm-configurations-handler-panel',
  template: require('./bolt-cpm-configurations-handler-panel.html'),
  styles: [require('./bolt-cpm-configurations-handler-panel.scss')]
})
export class BoltCpmConfigurationsHandlerPanelComponent implements OnChanges, OnDestroy {
  @Input() configuration: Configuration | undefined;
  @Input() open: boolean;
  @Output('cancelled') cancelEvent: EventEmitter<void>;
  @Output('saved') saveEvent: EventEmitter<Configuration>;

  protected action: ActionEnum;
  protected actionSubscription: Subscription;
  protected form: ConfigurationForm;
  protected fieldSpecs: any;
  protected typeItems: SelectionItem[];

  constructor(
    protected appConfig: AppConfigProvider,
    protected configurationService: ConfigurationService,
    protected formLayout: FormLayoutService,
    protected notificationService: NotificationService
  ) {
    this.cancelEvent = new EventEmitter();
    this.open = false;
    this.saveEvent = new EventEmitter();
    this.typeItems = ConfigurationType.asSelectionItemList();

    this.setupFieldSpecs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.configuration)) {
      this.action = _isObject(this.configuration) ? ActionEnum.Edit : ActionEnum.New;
    }

    if (_isObject(changes.open)) {
      if (this.open) {
        this.form = new ConfigurationForm(this.fieldSpecs, this.configuration);
      } else {
        this.form = undefined;
        this.cancelActionSubscription();
      }
    }
  }

  ngOnDestroy() {
    this.cancelActionSubscription();
  }

  /**
   * Cancels the action over the configuration.
   *
   * @returns void
   */
  protected cancel(): void {
    this.cancelEvent.emit();
  }

  /**
   * Cancels the subscription for the triggered action.
   *
   * @returns void
   */
  protected cancelActionSubscription(): void {
    if (_isObject(this.actionSubscription)) {
      this.actionSubscription.unsubscribe();
    }
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    return this.fieldSpecs[`${field}MaxLength`];
  }

  /**
   * Returns the scrollHeight from AppConfig.
   *
   * @returns string
   */
  protected getScrollHeight(): string {
    return this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.form.disabled;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const isIt: boolean = this.form.invalid || this.form.disabled;
    return isIt;
  }

  /**
   * Indicates if it is creating a configuration.
   *
   * @returns boolean
   */
  protected isCreating(): boolean {
    const isIt: boolean = this.action === ActionEnum.New;
    return isIt;
  }

  /**
   * Saves the configuration.
   *
   * @returns void
   */
  protected save(): void {
    this.form.disable();

    const data: any = this.form.toJson();

    const action: Observable<Configuration> = this.isCreating()
      ? this.configurationService.create(data)
      : this.configurationService.update(this.configuration.getId(), data);

    this.actionSubscription = action.subscribe(
      (newConfiguration: Configuration) => {
        this.notificationService.handleSuccess('Configuration was saved successfully.');
        this.saveEvent.emit(newConfiguration);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed saving the configuration', error);
        this.form.enable();
      }
    );
  }

  /**
   * Set up the specs for the fields.
   *
   * @returns void
   */
  protected setupFieldSpecs(): void {
    this.fieldSpecs = {
      codeMaxLength: this.appConfig.get('forms.cpm.fields.code.maxLength'),
      descriptionMaxLength: this.appConfig.get('forms.cpm.fields.description.maxLength'),
      valueMaxLength: this.appConfig.get('forms.cpm.fields.value.maxLength')
    };
  }
}
