import { OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isObject as _isObject } from 'lodash';

import { ConfigService } from 'app/shared/services/form/config/config.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { TalentForm } from '../../models/form/talent-form.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


export abstract class BoltTalentMetadataFormComponent extends StormComponent implements OnChanges {
  form: TalentForm;

  constructor(
    protected formConfig: ConfigService,
    protected listLayoutProvider: ListLayoutProvider
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.form)) {
      setTimeout(
        () => {
          if (this.form.enabled) {
            this.checkLastNameState();
            this.checkMiddleNameState();
            this.checkPrefixState();
            this.checkSuffixState();
          }
        }
      );
    }
  }

  get languages(): SelectionItem[] {
    return this.listLayoutProvider.getLanguages(true);
  }

  /**
   * Checks if it has to disable or enable the middle name field.
   *
   * @returns void
   */
  protected checkMiddleNameState(): void {
    const middleNameField: FormControl = this.form.getMiddleNameField();

    if (this.form.getNoMiddleNameField().value) {
      middleNameField.setValue('');
      middleNameField.disable();
    } else {
      middleNameField.enable();
    }
  }

  /**
   * Checks if it has to disable or enable the last name field.
   *
   * @returns void
   */
  protected checkLastNameState(): void {
    const lastNameField: FormControl = this.form.getLastNameField();

    if (this.form.getNoLastNameField().value) {
      lastNameField.setValue('');
      lastNameField.disable();
    } else {
      lastNameField.enable();
    }
  }

  /**
   * Checks if it has to disable or enable the prefix field.
   *
   * @returns void
   */
  protected checkPrefixState(): void {
    const prefixField: FormControl = this.form.getPrefixIdField();

    if (this.form.getNoPrefixField().value) {
      prefixField.setValue(undefined);
      prefixField.disable();
    } else {
      prefixField.enable();
    }
  }

  /**
   * Checks if it has to disable or enable the suffix field.
   *
   * @returns void
   */
  protected checkSuffixState(): void {
    const suffixField: FormControl = this.form.getSuffixIdField();

    if (this.form.getNoSuffixField().value) {
      suffixField.setValue(undefined);
      suffixField.disable();
    } else {
      suffixField.enable();
    }
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    const maxLength: number = this.formConfig.get(`talent.fields.${field}.maxLength`);
    return maxLength;
  }
}
