import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionTypeEnum } from '@bolt/ui-shared/configuration';

import { BoltMetadataComponent } from 'app/modules/common/models/bolt-metadata-component.model';
import { BoltTalentMetadataFiltersInterface } from '../bolt-talent-metadata-filters/bolt-talent-metadata-filters.component';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { TalentInterface } from '../../models/talent.model';
import { TalentMetadataInterface } from '../../models/talent-metadata.model';
import { TalentManager } from '../../helpers/talent-manager/talent-manager.helper';
import { TalentService } from '../../services/talent.service';
import { TalentMetadataManagerActions } from '../bolt-talent-metadata-manager/bolt-talent-metadata-manager.component';
import { TypeEnum as CreditType } from 'app/modules/credits/models/wizard/candidate/type/type.enum';
import { EditHistoryMetadata } from 'app/modules/common/components/bolt-edit-history/bolt-edit-history.component';


@Component({
  selector: 'bolt-talent-metadata-details',
  template: require('./bolt-talent-metadata-details.html'),
  styles: [require('./bolt-talent-metadata-details.scss')],
})
export class BoltTalentMetadataDetailsComponent extends BoltMetadataComponent implements OnChanges {
  @Input('BoltTalentMetadataDetailsTalent') talent: TalentInterface;
  @Input('BoltTalentMetadataDetailsTalentMetadata') talentMetadata: TalentMetadataInterface;
  @Input('BoltTalentMetadataDetailsFilters') filters: BoltTalentMetadataFiltersInterface;

  @Output('BoltTalentMetadataDetailsOnMetadataUpdated')
  metadataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  localizationRemove: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected computedTalentMetadata: TalentMetadataInterface;
  protected showEditHistory: boolean = false;

  constructor(
    protected capabilitiesManager: CapabilitiesManager,
    protected notificationService: NotificationService,
    protected modalService: NgbModal,
    protected talentManager: TalentManager,
    protected talentService: TalentService
  ) {
    super(modalService, notificationService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.talentMetadata) {
      if (changes.talentMetadata.currentValue) {
        this.talentManager.getComputedTalentMetadata(this.talentMetadata).subscribe(
          talentMetadata => {
            this.computedTalentMetadata = talentMetadata;
          }
        );
      } else {
        this.computedTalentMetadata = undefined;
      }
    }
  }

  getMetadata(): EditHistoryMetadata {
    return {
      id: this.computedTalentMetadata.id,
      locale: this.computedTalentMetadata.locale,
      type: CreditType.talent,
      imdb: this.computedTalentMetadata.imdbId,
      name: this.computedTalentMetadata.fullName,
      language: this.computedTalentMetadata.language,
      territory: this.computedTalentMetadata.territory,
      productType: this.computedTalentMetadata.productType,
      account: this.computedTalentMetadata.account,
      localeObject: this.computedTalentMetadata.localeObject
    };
  }

  /**
   * Close the edit history modal.
   *
   * @returns void
   */
  closeEditHistory(): void {
    this.showEditHistory = false;
  }

  /**
   * Open the edit history modal.
   *
   * @returns void
   */
  openEditHistory(): void {
    this.showEditHistory = true;
  }

  /**
   * This method removes the selected locale for the current talent
   *
   * @return void
   */
  protected deleteLocalization(): void {
    this.disableConfirmDialog();
    this.talentService.deleteTalentLocalization({
        talentId: this.computedTalentMetadata.id
      },
      this.computedTalentMetadata.locale
    ).subscribe(
      () => {
        this.confirmDeleteLocalizationModal.close();
        this.notificationService.handleNotice('The localization was deleted successfully.');
        this.localizationRemove.emit(true);
      },
      (error: ErrorHelper) => {
        this.confirmDeleteLocalizationModal.close();
        this.notificationService.handleError('There was an error trying to delete the location.', error);
      }
    );
  }

  /**
   * Indicates if it has to display the delete button.
   *
   * @returns boolean
   */
  protected hasDisplayDeleteButton(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(
      modulesPath.titles.credits.talent.localization.path,
      [ActionTypeEnum.delete]
    );
  }

  /**
   * Opens the edition panel for the current localization.
   *
   * @returns void
   */
  protected openEditLocalizationPanel(): void {
    this.talentManager.manageTalentMetadata(
      this.computedTalentMetadata,
      TalentMetadataManagerActions.EDIT,
      this.computedTalentMetadata,
      [],
      this.computedTalentMetadata
    );
  }
}
