import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider, ConfigurationTypeEnum } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs';
import { sortBy as _sortBy, isNumber as _isNumber, isObject as _isObject, isString as _isString } from 'lodash';

import { CollectionManagerHelper, CollectionManagerCollectionInterface } from 'app/modules/common/helpers/collection-manager.helper';
import { Episode } from '../../models/episode.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleService } from '../../services/title.service';
import { TitleType } from '../../models/title.model';


@Component({
  selector: 'bolt-title-season-episodes-list',
  template: require('./bolt-title-season-episodes-list.html'),
  styles: [require('./bolt-title-season-episodes-list.scss')],
  providers: [TitleService, CollectionManagerHelper]
})
export class BoltTitleSeasonEpisodesListComponent extends StormComponent implements OnInit, OnChanges {
  public episodesCollection: CollectionManagerCollectionInterface;
  @Input('BoltTitleSeasonEpisodesListSeasonId') seasonId: number;
  @Input('excludeEpisode') episodeId: number;

  protected seasonSubscription: Subscription;
  protected episodeSubscription: Subscription;
  protected fetchingSeasonTitle: boolean;
  protected seasonTitle: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected collectionManager: CollectionManagerHelper,
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) {

    super();

    this.collectionManager.setCollections([
      {
        name: 'episodes',
        collection: [],
        sorting: [],
        paginate: true,
        pagination: {
          page_size: this.appConfig.get('ux.dataTables.pageSize', 20, ConfigurationTypeEnum.number)
        },
      }
    ]);

    this.collectionManager.getCollectionHandler().subscribe(
      collectionHandler => {
        if (collectionHandler.name === 'episodes') {
          this.episodesCollection = collectionHandler;
        }
      }
    );

    this.fetchingSeasonTitle = false;
  }

  ngOnInit() {
    this.fetchEpisodes();
    this.fetchSeasonTitle();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.seasonId && _isNumber(changes.seasonId.currentValue)) {
      this.fetchEpisodes();
      this.fetchSeasonTitle();
    } else {
      if (changes.episodeId && _isNumber(this.seasonId)) {
        this.fetchEpisodes();
      }
    }
  }

  /**
   * Loads the episodes.
   *
   * @returns void
   */
  loadEpisodes(): void {
    this.collectionManager.refreshCollection('episodes');
  }

  /**
   * Cancels the fetch episode subscription.
   *
   * @returns void
   */
  protected cancelEpisodeSubscription(): void {
    if (_isObject(this.episodeSubscription)) {
      this.episodeSubscription.unsubscribe();
    }
  }

  /**
   * Cancels the fetch season subscription.
   *
   * @returns void
   */
  protected cancelSeasonSubscription(): void {
    if (_isObject(this.seasonSubscription)) {
      this.seasonSubscription.unsubscribe();
    }
  }

  /**
   * Fetch episodes for the current season.
   *
   * @return void
   */
  protected fetchEpisodes(): void {
    this.cancelEpisodeSubscription();
    this.changeStatusToFetchingData();

    this.episodeSubscription = this.titleService.filterTitles({
      titleType: TitleType.episode,
      seasonId: this.seasonId,
      locale: '*_*_*_*',
      _size: 5000
    }).subscribe(
      (response: StormServiceResponseCollection) => {
        response.collection = _sortBy(response.collection, 'f0_num');

        if (_isNumber(this.episodeId)) {
          response.collection = response.collection.filter(
            (episode: Episode) => episode.id !== this.episodeId
          );
        }

        this.collectionManager.setCollectionItems(
          'episodes',
          response.collection
        );

        if (this.episodesCollection.collection.length > 0) {
          this.changeStatusToDataFound();
        } else {
          this.changeStatusToDataNotFound();
        }
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to fetch the episodes.', error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Fetches the season title for the current episode.
   *
   * @returns void
   */
  protected fetchSeasonTitle(): void {
    this.cancelSeasonSubscription();
    this.fetchingSeasonTitle = true;

    this.seasonSubscription = this.titleService.fetchProduct({
      productId: this.seasonId,
      productType: TitleType.season
    }).subscribe(
      (response: StormServiceResponseSingle) => {
        this.seasonTitle = response.item.title;
      },
      () => {
        this.seasonTitle = 'Season';
      },
      () => {
        this.fetchingSeasonTitle = false;
      }
    );
  }

  /**
   * Indicates if it has to display the episode list.
   *
   * @returns boolean
   */
  protected hasDisplayEpisodeList(): boolean {
    const hasIt: boolean =
      this.isDataFound() &&
      _isObject(this.episodesCollection) &&
      this.episodesCollection.collection.length > 0;

    return hasIt;
  }

  /**
   * Indicates if it has to display the info message.
   *
   * @returns boolean
   */
  protected hasDisplayInfoMessage(): boolean {
    return _isNumber(this.episodeId);
  }

  /**
   * Indicates if it has to display the season title.
   *
   * @returns boolean
   */
  protected hasDisplaySeasonTitle(): boolean {
    const hasIt: boolean = !this.fetchingSeasonTitle && _isString(this.seasonTitle);
    return hasIt;
  }
}
