import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { isString as _isString } from 'lodash';

import { SubtitleTypeEnum } from '../../subtitle/details/subtitle-type/subtitle-type.enum';
import { SubtitleForm } from '../subtitle-form.model';


export class OriginalDataForm extends SubtitleForm {
  protected timeStartField: FormControl;
  protected timeEndField: FormControl;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
  }

  /**
   * Get the time start field.
   *
   * @returns FormControl
   */
  getTimeStartField(): FormControl {
    return this.timeStartField;
  }

  /**
   * Get the time end field.
   *
   * @returns FormControl
   */
  getTimeEndField(): FormControl {
    return this.timeEndField;
  }

  toJson(): any {
    const data: any = super.toJson();
    // TODO Can't use getters due to FormArray transform the current controls and we lost custom getters
    // need implementation of BoltArrayForm.
    data.timeStart = this.get('timeStart').value;
    data.timeEnd = this.get('timeEnd').value;

    return data;
  }

  protected getNarrativeTitleCustomRequiredValidator(): ValidatorFn {
    const subtitleTypeControl: AbstractControl = this.controls['subtitleType'];

    const validator: any = (control: FormControl) => {
      if (
        subtitleTypeControl.value !== SubtitleTypeEnum.lyrics.toString() &&
        (
          !_isString(control.value) ||
          (_isString(control.value) && control.value.length === 0)
        )
      ) {
        return  {
          required: true,
          dirty: control.dirty
        };
      }
    };

    return validator;
  }

  protected setupFields() {
    super.setupFields();
    this.setupTimeStartField();
    this.setupTimeEndField();
  }

  /**
   * Set up the time start field.
   *
   * @returns void
   */
   protected setupTimeStartField(): void {
    this.timeStartField = new FormControl(
      this.getDefaultValue('timeStart'),
      [
        Validators.required,
        Validators.pattern(/^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]):([0-9]{2})$/)
      ]
    );

    this.addControl('timeStart', this.timeStartField);
  }

  /**
   * Set up the time end field.
   *
   * @returns void
   */
  protected setupTimeEndField(): void {
    this.timeEndField = new FormControl(
      this.getDefaultValue('timeEnd'),
      Validators.pattern(/^([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9]):([0-9]{2})$/)
    );

    this.addControl('timeEnd', this.timeEndField);
  }
}
