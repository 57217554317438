import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs';
import { isObject as _isObject } from 'lodash';

import { Element } from 'app/modules/search/models/element/element.model';
import { Entity } from '../../models/entity/entity.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { SearchCriteria } from 'app/modules/search/models/search-criteria/search-criteria.model';
import { SearchManager } from 'app/shared/models/search-response/search-manager/search-manager';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { SearchService as ConsolidatedSearchService } from 'app/modules/search/services/search.service';
import { TypeEnum as SearchTypeEnum } from 'app/modules/search/models/type/type.enum';


@Component({
  selector: 'bolt-project-entity-search',
  template: require('./bolt-project-entity-search.html'),
  styles: [require('./bolt-project-entity-search.scss')]
})
export class BoltProjectEntitySearchComponent {
  @Input() disabled: boolean;
  @Output('selected') protected selectEvent: EventEmitter<Entity>;

  protected readonly fixedSearchType: string = 'title';

  protected fetchingElementsListener: Subscription;
  protected pager: MemoryPager;
  protected scrollLoading: boolean;
  protected searchManager: SearchManager;
  protected stateConfirmDialog: boolean;
  protected suggestionsCriteria: SearchCriteria;
  protected suggestionsLoading: boolean;

  constructor(
    protected appConfig: AppConfigProvider,
    protected notificationService: NotificationService,
    protected searchService: ConsolidatedSearchService
  ) {
    this.disabled = false;
    this.scrollLoading = false;
    this.searchManager = new SearchManager();
    this.selectEvent = new EventEmitter();
    this.suggestionsLoading = false;

    this.pager = new MemoryPager(
      this.appConfig.get('ux.page.projectDashboard.searchPageSize'),
      this.getSortingCriteria()
    );

    this.setupSuggestionsCriteria();
  }

  /**
   * Returns the sorting criteria for elements.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (elementA: Element, elementB: Element) => {
      const nameA: string = elementA.name.trim().toLowerCase();
      const nameB: string = elementB.name.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Returns the search suggestions.
   *
   * @returns Element[]
   */
  protected getSuggestions(): Element[] {
    return this.searchManager.suggestions;
  }

  /**
   * Loads the next page for the current query
   *
   * @returns void
   */
  protected loadNextSearchingPage(): void {
    if (!this.scrollLoading) {
      this.retrieveSearchSuggestions(this.searchManager.sanitizedQuery, false);
    }
  }

  /**
   * Notifies the selection.
   *
   * @param selection Element
   * @returns void
   */
  protected notifySelection(selection: Element): void {
    if (selection instanceof Element) {
      const entity: Entity = Entity.newFromElement(selection);
      this.selectEvent.emit(entity);
    }
  }

  /**
   * Set up the suggestions criteria.
   *
   * @returns void
   */
  protected setupSuggestionsCriteria(): void {
    const types: string[] = [
      `${this.fixedSearchType}:${SearchTypeEnum.Feature}`,
      `${this.fixedSearchType}:${SearchTypeEnum.Series}`,
      `${this.fixedSearchType}:${SearchTypeEnum.Season}`
    ];

    this.suggestionsCriteria = new SearchCriteria();
    this.suggestionsCriteria.setPageSize(this.appConfig.get('ux.page.projectDashboard.searchPageSize'));
    this.suggestionsCriteria.setType(types.join(','));
  }

  /**
   * Retrieves all suggestions for the product search.
   *
   * @param query string
   * @param shouldResetSearch boolean
   * @returns void
   */
  protected retrieveSearchSuggestions(query: string, shouldResetSearch: boolean): void {
    this.scrollLoading = true;

    if (shouldResetSearch) {
      this.suggestionsLoading = true;
      this.searchManager.sanitizedQuery = query.trim();
    }

    this.suggestionsCriteria.setPageNumber(this.searchManager.currentPage - 1);
    this.suggestionsCriteria.setQuery(this.searchManager.sanitizedQuery);
    this.unsubscribeFetchingElements();

    this.fetchingElementsListener = this.searchService.fetch(
      this.suggestionsCriteria,
      (response: SearchResponse) => {
        this.searchManager.response = response;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve titles.', error, notificationsContainer.projectDashboard.key);
      },
      () => {
        this.scrollLoading = false;
        this.suggestionsLoading = false;
      }
    );
  }

  /**
   * Unsubscribes from fetching elements, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeFetchingElements(): void {
    if (_isObject(this.fetchingElementsListener)) {
      this.fetchingElementsListener.unsubscribe();
    }
  }
}
