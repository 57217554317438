// TODO: Shared is candidate to be removed when all code is moved into @bolt/ui-shared.

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule as BoltSharedAuthModule } from '@bolt/ui-shared/auth';
import { CommonModule as BoltSharedCommonModule, EnvironmentService, WindowWrapperService } from '@bolt/ui-shared/common';
import { ConfigurationModule } from '@bolt/ui-shared/configuration';
import { DataShareModule } from '@bolt/ui-shared/data-share';
import { DataTransformModule } from '@bolt/ui-shared/data-transform';
import { DroplistsModule } from '@bolt/ui-shared/droplists';
import { FormModule as BoltSharedFormModule } from '@bolt/ui-shared/form';
import { MasterDataModule } from '@bolt/ui-shared/master-data';
import { MoratoriumModule } from '@bolt/ui-shared/moratorium';
import { NotificationModule } from '@bolt/ui-shared/notification';
import { RoutingModule } from '@bolt/ui-shared/routing';
import { SearchingModule } from '@bolt/ui-shared/searching';
import { TestingModule } from '@bolt/ui-shared/testing';
import { TitleModule } from '@bolt/ui-shared/title';
import { MessageService, ToastModule, MultiSelectModule, DropdownModule, SelectButtonModule } from 'primeng';
import { ChartsModule } from 'ng2-charts';
import { CountdownModule } from 'ngx-countdown';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

import { ArrayFnPipe } from './pipes/array-fn/array-fn.pipe';
import { BoltAmUtcDatePipe } from './pipes/bolt-am-utc/date/date.pipe';
import { BoltAmUtcDateTimePipe } from './pipes/bolt-am-utc/date-time/date-time.pipe';
import { BoltAsideContentCollectionComponent } from './components/bolt-aside-content-collection/bolt-aside-content-collection.component';
import { BoltAsideContentComponent } from './components/bolt-aside-content/bolt-aside-content.component';
import { BoltAutomationDirective } from './directives/bolt-automation/bolt-automation.directive';
import { BoltDataAccordionComponent } from './components/bolt-data-accordion/bolt-data-accordion.component';
import { BoltNotImplementedComponent } from './components/bolt-not-implemented/bolt-not-implemented.component';
import { BoltShortcutDirective } from './directives/bolt-shortcut/bolt-shortcut.directive';
import { BoltTimeCodeMaskDirective } from './directives/bolt-timecode-mask/bolt-timecode-mask.directive';
import { ConfigService as FormConfigService } from './services/form/config/config.service';
import { DataSanitizerService } from './services/data-sanitizer/data-sanitizer.service';
import { HighlightPipe } from './pipes/highlight/highlight.pipe';
import { LayoutHandlerService } from './services/layout-handler/layout-handler.service';
import { LayoutService as FormLayoutService } from './services/form/layout/layout.service';
import { SearchPipe } from './pipes/search/search.pipe';
import { UpperFirstPipe } from './pipes/upper-first/upper-first.pipe';
import { BoltGreetingComponent } from './components/bolt-greeting/bolt-greeting.component';


@NgModule({
  imports: [
    // Angular
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // @bolt/ui-shared
    BoltSharedCommonModule,
    BoltSharedAuthModule,
    BoltSharedFormModule,
    ConfigurationModule,
    DataShareModule,
    DataTransformModule,
    DroplistsModule,
    MasterDataModule,
    MoratoriumModule,
    NotificationModule,
    RoutingModule,
    SearchingModule,
    TestingModule,
    TitleModule,
    // Bolt UI & Others
    ToastModule,
    MultiSelectModule,
    DropdownModule,
    ChartsModule,
    CountdownModule,
    NgxJsonViewerModule,
    SelectButtonModule
  ],
  declarations: [
    ArrayFnPipe,
    BoltAmUtcDatePipe,
    BoltAmUtcDateTimePipe,
    BoltAsideContentComponent,
    BoltAsideContentCollectionComponent,
    BoltAutomationDirective,
    BoltDataAccordionComponent,
    BoltGreetingComponent,
    BoltShortcutDirective,
    BoltTimeCodeMaskDirective,
    BoltNotImplementedComponent,
    HighlightPipe,
    SearchPipe,
    UpperFirstPipe,
  ],
  exports: [
    // @bolt/ui-shared
    BoltSharedCommonModule,
    BoltSharedAuthModule,
    BoltSharedFormModule,
    ConfigurationModule,
    DataShareModule,
    DataTransformModule,
    DroplistsModule,
    MasterDataModule,
    MoratoriumModule,
    NotificationModule,
    RoutingModule,
    SearchingModule,
    TestingModule,
    TitleModule,
    // Bolt UI & Others
    ArrayFnPipe,
    BoltAmUtcDatePipe,
    BoltAmUtcDateTimePipe,
    BoltAsideContentComponent,
    BoltAsideContentCollectionComponent,
    BoltAutomationDirective,
    BoltDataAccordionComponent,
    BoltGreetingComponent,
    BoltShortcutDirective,
    BoltTimeCodeMaskDirective,
    BoltNotImplementedComponent,
    HighlightPipe,
    SearchPipe,
    UpperFirstPipe,
    MultiSelectModule,
    DropdownModule,
    ChartsModule,
    CountdownModule,
    NgxJsonViewerModule,
    SelectButtonModule
  ],
  providers: [
    DataSanitizerService,
    EnvironmentService,
    FormConfigService,
    FormLayoutService,
    LayoutHandlerService,
    MessageService,
    WindowWrapperService
  ]
})
export class SharedModule { }
