import { Component } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { SearchCriteria } from '@bolt/ui-shared/searching';
import { Observable } from 'rxjs';
import { difference as _difference, isArray as _isArray } from 'lodash';

import { BoltWizardValidatingStepItemComponent } from '../bolt-wizard-validating-step-item/bolt-wizard-validating-step-item.component';
import { CharacterService } from 'app/modules/character/services/character.service';
import { WizardService } from '../../services/wizard/wizard.service';


@Component({
  selector: 'bolt-wizard-validating-step-character-item',
  template: require('./bolt-wizard-validating-step-character-item.html'),
  styles: [require('./bolt-wizard-validating-step-character-item.scss')]
})
export class BoltWizardValidatingStepCharacterItemComponent extends BoltWizardValidatingStepItemComponent {
  protected guest: boolean;
  protected searchMethod: (criteria: SearchCriteria) => Observable<any>;

  constructor(
    protected appConfigProvider: AppConfigProvider,
    protected wizardService: WizardService,
    protected characterService: CharacterService
  ) {
    super(appConfigProvider, wizardService);
    this.guest = false;
    this.retry();
  }

  protected checkCompleteMatch(): void {
    const characterName: string = this.name.toLowerCase();

    const completeMatched: SelectionItem[] = this.matches.filter(
      (match: SelectionItem) => {
        const matchName: string = match.source.name.trim().toLowerCase();
        return matchName === characterName;
      }
    );

    if (_isArray(completeMatched) && completeMatched.length === 1) {
      this.completeMatch = true;
      this.createNew = !this.completeMatch;

      setTimeout(
        () => {
          this.wizardService.setPosition(completeMatched[0].source, this.index);
        },
        0
      );
    }
  }

  protected checkMatches(options: SelectionItem[]): void {
    if (options.length > 0) {
      this.matches = options;
      this.checkCompleteMatch();
    } else {
      this.createNew = true;
      this.wizardService.setPositionAsReady(this.index);
    }
  }

  /**
   * Indicates if it has to display the guest field.
   *
   * @returns boolean
   */
   protected hasDisplayGuestField(): boolean {
    return this.wizardService.getTitle().isEpisode();
  }

  protected onCreateNewChange(): void {
    if (this.createNew) {
      this.currentSelection = undefined;
      this.wizardService.setPosition(undefined, this.index);
      this.wizardService.setPositionAsReady(this.index);
    } else {
      this.wizardService.setPositionAsNotReady(this.index);
    }
  }

  /**
   * Does the necessary updates when is guest checkbox change.
   *
   * @returns void
   */
  protected onGuestChanges(): void {
    this.wizardService.setCastIsGuest(this.index, this.guest);
  }

  protected retry(): void {
    this.searchMethod = this.characterService.searchAsObservable.bind(this.characterService);
  }

  /**
   * Sets the character for the given id.
   *
   * @param id number
   * @returns void
   */
  protected setCharacter(id: number): void {
    const characterItem: SelectionItem = this.matches.find(
      (elem: SelectionItem) => elem.value === id
    );

    this.wizardService.setPosition(characterItem?.source, this.index);
  }
}
