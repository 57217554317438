export class Template {
  protected _code: string;
  protected _id: number;
  protected _name: string;

  constructor(data: any) {
    this.initialize(data);
  }

  /**
   * Gets the Code.
   *
   * @returns string
   */
  get code(): string {
    return this._code;
  }

  /**
   * Gets the ID.
   *
   * @returns number
   */
  get id(): number {
    return this._id;
  }

  /**
   * Gets the name.
   *
   * @returns string
   */
  get name(): string {
    return this._name;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const code: string = (
      (data && data.hasOwnProperty('code'))
        ? data.code
        : undefined
    );

    const id: number = (
      (data && data.hasOwnProperty('id'))
        ? data.id
        : undefined
    );

    const name: string = (
      (data && data.hasOwnProperty('name'))
        ? data.name
        : undefined
    );

    this._code = code;
    this._id = id;
    this._name = name;
  }
}
