import {
  AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';

import { EnvironmentService } from '@bolt/ui-shared/common';
import { NotificationService } from '@bolt/ui-shared/notification';
import { ProximityItem, ProximityManager } from '@bolt/ui-shared/droplists';
import { BoltMultiFieldAutocompleteComponent } from '@bolt/ui-shared/form';
import { StormListType, List as MasterDataList, TypeEnum as MasterDataType, DubbingStudio } from '@bolt/ui-shared/master-data';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionTypeEnum, AppConfigProvider, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { flatMap, debounceTime, finalize } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';

import {
  capitalize as _capitalize, cloneDeep as _cloneDeep, isEmpty as _isEmpty, isObject as _isObject, isString as _isString,
  isUndefined as _isUndefined, remove as _remove, startCase as _startCase
} from 'lodash';

import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';
import { CastCrewSearchCriteria } from 'app/shared/models/search-criteria/character/cast-crew-search-criteria.model';
import { CreditLocale } from '../../models/credit/credit-locale.model';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { SearchManager } from 'app/shared/models/search-response/search-manager/search-manager';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { SearchTypeEnum } from 'app/shared/models/search-criteria/search-type/search-type.enum';
import { SearchType } from 'app/shared/models/search-criteria/search-type/search-type.model';
import { CloningStatusDetails } from 'app/modules/clone/models/cloning-status-details.model';
import { CreditsCloneService } from 'app/modules/clone/services/credits-clone/credits-clone.service';
import { HotConfigurationTypeEnum } from 'app/modules/clone/models/hot-configuration-type.enum';
import { HotConfiguration } from 'app/modules/clone/models/hot-configuration.model';
import { EventTypeEnum } from 'app/modules/clone/models/event-type.enum';
import { CloningStatusEnum } from 'app/modules/clone/models/cloning-status.enum';
import { CreditsAggregateService } from 'app/modules/clone/services/credits-aggregate/credits-aggregate.service';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { RoleManager, RoleManagerMetadataManagement } from 'app/modules/role/helpers/role-manager/role-manager.helper';
import { CharacterService } from 'app/modules/character/services/character.service';
import { TalentService } from 'app/modules/talent/services/talent.service';
import { RoleService } from 'app/modules/role/services/role.service';
import { TalentManager, TalentManagerMetadataManagement } from 'app/modules/talent/helpers/talent-manager/talent-manager.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { CharacterInterface, Character } from 'app/modules/character/models/character.model';
import { RoleInterface, Role } from 'app/modules/role/models/role.model';
import { TalentInterface, Talent } from 'app/modules/talent/models/talent.model';
import { PullingActionHelper } from 'app/shared/helpers/pulling-action/pulling-action.helper';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { CharacterMetadata } from 'app/modules/character/models/character-metadata.model';
import { CharacterMetadataManager } from 'app/modules/character/helpers/character-metadata-manager/character-metadata-manager';
import { EntityMapperHelper } from 'app/modules/list/helpers/entity-mapper.helper';

import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { RoleMetadataManagerActions } from 'app/modules/role/components/bolt-role-metadata-manager/bolt-role-metadata-manager.component';
import {
  TalentMetadataManagerActions
} from 'app/modules/talent/components/bolt-talent-metadata-manager/bolt-talent-metadata-manager.component';
import { RoleMetadata } from 'app/modules/role/models/role-metadata.model';
import { TalentMetadata } from 'app/modules/talent/models/talent-metadata.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { Credit } from 'app/modules/credits/models/credit/credit.model';
import { CreditLockingStatusInterface } from 'app/modules/credits/models/credit/credit-locking-status.interface';
import { TitleService } from 'app/modules/title/services/title.service';
import { Title, TitleType, TitleInterface } from 'app/modules/title/models/title.model';
import { ProductLayoutHelper } from 'app/modules/title/helpers/product-layout/product-layout.helper';
import { EpisodeInterface } from 'app/modules/title/models/episode.model';
import { SeasonInterface } from 'app/modules/title/models/season.model';
import { CreditsManagerActions } from './bolt-credits-manager-actions.enum';
import { CreditFactory } from '../../helpers/credit-factory/credit-factory.helper';
import { CreditsService } from '../../services/credits.service';
import { CollectionManagerHelper } from 'app/modules/common/helpers/collection-manager.helper';

import {
  MetadataExtractorService as TalentMetadataExtractorService
} from 'app/modules/talent/services/metadata-extractor/metadata-extractor.service';


@Component({
  selector: 'bolt-credits-manager',
  template: require('./bolt-credits-manager.html'),
  styles: [require('./bolt-credits-manager.scss')],
  providers: [
    CharacterService, CreditsService, RoleManager, RoleService, TalentManager,
    TalentService, TitleService
  ]
})
export class BoltCreditsManagerComponent extends StormComponent implements AfterViewInit, OnInit, OnChanges {
  @ViewChild('confirmDeleteCreditModal') confirmDeleteCreditModal: ModalDirective;
  @ViewChild('confirmLockingModal') confirmLockingModal;

  @Input('title') title: Title;
  @Input('creditType') creditType: CreditType = undefined;
  @Input('creditPositionType') creditPositionType: CreditPositionType;
  @Input('creditLocale') creditLocale: CreditLocale;
  @Input('showForm') showForm: boolean = false;
  @Input() creditTypeTab: CreditType;
  @Input() isLockedOriginalCredit: boolean;
  @Input() refresh: boolean = false;

  @Input('creditsInput') creditsInput: Credit[] = [];
  @Input('blocked') blocked: boolean = false;

  @Output('onLockingStatusUpdate') onLockingStatusUpdate: EventEmitter<CreditLockingStatusInterface[]> =
    new EventEmitter<CreditLockingStatusInterface[]>();

  @Output('onReloadLocaleList') onReloadLocaleList: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() originalLockingStatus: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onOrderChanged') onOrderChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('confirmAggregationModal') confirmAggregationModal: ElementRef;
  @ViewChild('positionMultiFieldAutocomplete') positionMultiFieldAutocomplete: BoltMultiFieldAutocompleteComponent;
  @ViewChild('talentMultiFieldAutocomplete') talentMultiFieldAutocomplete: BoltMultiFieldAutocompleteComponent;

  credits: Credit[] = [];
  creditsModified: Credit[] = [];
  disabled = false;
  notificationsContainer: any;
  positionSuggestions: RoleInterface[] | CharacterInterface[];
  creditLockingStatus: CreditLockingStatusInterface;
  creditsManagerActions = CreditsManagerActions;
  creditTypes = CreditType;
  dragulaBag: string;
  dragulaOptions: any;
  form: FormGroup;
  manager: { credits?: Credit[], action?: CreditsManagerActions } = new Object();
  options: ProximityItem[];
  roleMetadataManager: RoleManagerMetadataManagement;
  creditPositionNotFound: boolean = false;
  showAddTalent: boolean = false;
  showTalentNotFound: boolean = false;
  talentMetadataManager: TalentManagerMetadataManagement;
  talentSuggestions: TalentInterface[];
  titleCredit: any = new Object();
  itemAdditionalVoice: any = new Object();
  creditPositionTypeHeadingLabel: string;
  creditPositionTypeInputHeadingLabel: string;
  creditPositionTypeHeadingColumnLabel: string;
  creditTypeHeadingCapitalizedLabel: string;
  selectedDubbingStudio: DubbingStudio;
  selectedDubbingStudioId: number;
  dubbingStudios: MasterDataList;
  aggregateRolesId: number[] = [];
  minEpisodeFrequency = 1;
  maxEpisodeFrequency = 999;
  aggregationTitle: string;
  aggregationLabel: string;
  creditPositionTypes = CreditPositionType;
  aggregateFrequency: number;
  defaultLoaded = false;
  blockReasons: string[];
  selectedReason: string;
  showModal: boolean = false;
  isTalentLoading: boolean = false;
  isRoleLoading: boolean = false;
  showCreditsWizard: boolean = false;
  positionScrollLoading: boolean;
  positionSearchManager: SearchManager;
  talentScrollLoading: boolean;
  talentSearchManager: SearchManager;
  titleLockButton: string;
  isDoubleChecked: boolean;
  isMainCheckSelected: boolean = false;
  creditChecks: Map<number, boolean>;
  selectionCounter: number;

  boltStore: any = {
    translations: {
      talentLabel: `Disney's talent label`,
      castLabel: `Disney's cast label`,
      characterLabel: `Disney's character label`,
      crewLabel: `Disney's crew label`,
      roleLabel: `Disney's role label`,
      cloneActionLabel: `Disney's copy and paste action label`
    }
  };

  protected fetchSubscription: Subscription;
  protected fetchTitleDubbingStudioSubscription: Subscription;
  protected _hasCreditsResortingDifferences: boolean;
  protected _isResortActivated: boolean;
  protected creditsBackup: Credit[];
  protected filterValue: string[] = [];
  protected showAddCreditPosition: boolean = false;
  protected dubbingNotFound: boolean;
  protected clonePanelVisible: boolean = false;
  protected initialDetailsCache: CloningStatusDetails;
  protected pullingAction: PullingActionHelper;
  protected pullingAttempts: number = 20;
  protected pullingErrorList: ErrorHelper[];
  protected eventFlag: boolean;
  protected pathToCheckPrivileges: string;

  protected positionSearchCriteria: CastCrewSearchCriteria;
  protected positionSearchSubscription: Subscription;
  protected positionSuggestionsLoading: boolean;
  protected searchProductAssociationsLimit: number;

  protected talentSearchCriteria: CastCrewSearchCriteria;
  protected talentSearchSubscription: Subscription;
  protected talentSuggestionsLoading: boolean;
  protected newCharacter: CharacterMetadata;
  protected positionName: string;
  protected talentName: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected changeDetectorRef: ChangeDetectorRef,
    protected characterService: CharacterService,
    protected creditsAggregateService: CreditsAggregateService,
    protected creditsCloneService: CreditsCloneService,
    protected creditsService: CreditsService,
    protected dragulaService: DragulaService,
    protected entityMapperHelper: EntityMapperHelper,
    protected environmentService: EnvironmentService,
    protected formBuilder: FormBuilder,
    protected masterDataManager: MasterDataManager,
    protected modalService: NgbModal,
    protected newCharacterManager: CharacterMetadataManager,
    protected notificationService: NotificationService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected proximityManager: ProximityManager,
    protected roleManager: RoleManager,
    protected roleService: RoleService,
    protected talentManager: TalentManager,
    protected talentMetadataExtractor: TalentMetadataExtractorService,
    protected talentService: TalentService,
    protected titleService: TitleService,
    protected collectionManager: CollectionManagerHelper
  ) {
    super();
    this.initialize();
  }

  /**
   * Updates the order given an array of credits to be change
   *
   * @param resortedCredits any
   * @returns void
   */
  refreshOrder(inputCredits: any): void {
    const resortedCredits = inputCredits.credits;

    if (resortedCredits && resortedCredits.length === 0) {
      return;
    }

    this.creditsModified = inputCredits.changedCredits;
    this.credits = this.reorderCredits(resortedCredits, this.credits);
  }

  /**
   * Reorder the 'credits' list with the provided 'newOrder' list of credits
   *
   * @param newOrder Credit[]
   * @param credits Credit[]
   * @returns Credit[]
   */
  reorderCredits(newOrder: Credit[], credits: Credit[]): Credit[] {
    let newCredits = credits.map(
      (credit: Credit) => {
        const resortedCredit = newOrder.find((newCredit: Credit) => credit.id === newCredit.id);

        credit.setOrder(resortedCredit.getOrder());

        return credit;
      }
    );

    newCredits = newCredits.sort((a: Credit, b: Credit) => a.getOrder() - b.getOrder());

    return newCredits;
  }

  ngAfterViewInit() {
    setTimeout(
      () => {
        this.notificationsContainer = notificationsContainer;
      }
    );
  }

  block(): void {
    this.disabled = true;
  }

  ngOnInit() {
    this.resetTitleCredit();
    this.setupCreditPositionTypeHeadings();

    this.pullingAction = new PullingActionHelper();

    this.creditTypeHeadingCapitalizedLabel = _capitalize(this.creditType.toString().toLowerCase());

    this.characterService.searchAdditional().subscribe(
      serviceResponseCollection => {
        this.itemAdditionalVoice = serviceResponseCollection.content.pop();
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const refreshChanged: boolean =
      _isObject(changes.refresh) &&
      !changes.refresh.isFirstChange() &&
      changes.refresh.currentValue;

    const firstTitle: boolean =
      _isObject(changes.title) &&
      _isObject(changes.title.currentValue) &&
      changes.title.isFirstChange();

    const titleChanged: boolean =
      _isObject(changes.title) &&
      !changes.title.isFirstChange() &&
      changes.title.currentValue.id !== changes.title.previousValue.id;

    const firstCreditType: boolean =
      _isObject(changes.creditType) &&
      !_isUndefined(changes.creditType.currentValue) &&
      changes.creditType.isFirstChange();

    const creditTypeChanged: boolean =
      _isObject(changes.creditType) &&
      !changes.creditType.isFirstChange() &&
      changes.creditType.previousValue !== changes.creditType.currentValue;

    const firstCreditTypePosition: boolean =
      _isObject(changes.creditPositionType) &&
      !_isUndefined(changes.creditPositionType.currentValue) &&
      changes.creditPositionType.isFirstChange();

    const creditPositionTypeChanged: boolean =
      _isObject(changes.creditPositionType) &&
      !changes.creditPositionType.isFirstChange() &&
      changes.creditPositionType.previousValue !== changes.creditPositionType.currentValue;

    const creditLocaleChanged: boolean =
      _isObject(changes.creditLocale) &&
      !changes.creditLocale.isFirstChange() &&
      _isObject(changes.creditLocale.currentValue);

    if (
      refreshChanged ||
      (
        (firstTitle || titleChanged) &&
        (firstCreditType || creditTypeChanged) &&
        (firstCreditTypePosition || creditPositionTypeChanged)
      ) ||
      creditLocaleChanged
    ) {
      this.setupDragulaBag();
      this.resetCreditsBackup();
      this.fetch();
      this.setTitleDubbingStudio();
      this.setLockingStatus();

      this.pathToCheckPrivileges = this.isOriginalCredit()
        ? modulesPath.titles.credits.original.path
        : modulesPath.titles.credits.localized.path;
    }
  }

  get sanitizedQuery(): string {
    return this.positionSearchManager.sanitizedQuery;
  }

  get searchSuggestions(): any[] {
    return this.positionSearchManager.suggestions;
  }

  get talentSanitizedQuery(): string {
    return this.talentSearchManager.sanitizedQuery;
  }

  get talentSearchSuggestions(): any[] {
    return this.talentSearchManager.suggestions;
  }

  /**
   * Set current Dubbing Studio.
   *
   * @returns void
   */
  setTitleDubbingStudio(): void {
    if ((this.title.isEpisode() || this.title.isFeature()) && (this.creditType === CreditType.DUBBING)) {
      this.resetDubbingStudioSelection();
      this.setupDubbingStudioOptions();
      this.showForm = false;

      if (this.creditLocale) {
        this.fetchTitleDubbingStudioSubscription.unsubscribe();

        this.fetchTitleDubbingStudioSubscription = this.fetchTitleDubbingStudio(
          this.title.type,
          this.title.id,
          this.creditLocale.getLocale()
        ).subscribe(
          (responseTitleDubbingStudio: any) => {
            this.masterDataManager.getDubbingStudioById(responseTitleDubbingStudio.dubbingStudioId).subscribe(
              (item: DubbingStudio) => {
                this.setSelectedDubbingStudios(item);
              },
              (error: ErrorHelper) => {
                this.notificationService.handleError('Failed trying to retrieve the dubbing studio', error);
              }
            );
          },
          (error) => {
            if (error.status === 404) {
              this.setSelectedDubbingStudios(undefined);
            } else {
              this.notificationService.handleError('Failed getting Dubbing Studio.', error);
              this.changeStatusToError();
            }
          }
        );
      }
    }
  }

  /**
   * Indicates if it has to display the warning message for unlocking OV.
   *
   * @returns boolean
   */
  showWarningMessage(): boolean {
    const showIt: boolean = this.creditLockingStatus.locked && this.isOriginalCredit();
    return showIt;
  }

  resetTitleCredit() {
    this.titleCredit = {
      language: this.titleCredit.language || [0],
      territory: this.titleCredit.territory || [0]
    };
  }

  toggleFormVisibility() {
    this.showForm = !this.showForm;
  }

  /**
   * Update an Specific Title localized for Dubbing Studio.
   *
   * @param newSelectedDubbingStudio any
   * @returns void
   */
  updateDubbingStudio(newSelectedDubbingStudio: any): void {
    const shouldUpdate: boolean = (
      (
        _isObject(newSelectedDubbingStudio) &&
        !_isUndefined((newSelectedDubbingStudio as any).value) &&
        _isObject((newSelectedDubbingStudio as any).originalEvent)
      ) &&
      (
        this.dubbingNotFound ||
        ((newSelectedDubbingStudio as any).value !== this.selectedDubbingStudio.id)
      )
    );

    if (shouldUpdate && _isObject(this.title) && _isObject(this.creditLocale)) {
      const errorMessage: string = 'Failed saving Dubbing Studio.';

      this.masterDataManager.getDubbingStudioById(newSelectedDubbingStudio.value).subscribe(
        (dubbingStudio: DubbingStudio) => {
          this.titleService.updateTitleDubbingStudio(
            dubbingStudio,
            this.title.type,
            this.title.id,
            this.creditLocale.getLocale()
          ).subscribe(
            () => {
              this.setSelectedDubbingStudios(dubbingStudio);
              this.notificationService.handleSuccess('Dubbing Studio saved.');
            },
            (error: any) => {
              if (error.status === 404) {
                if (this.dubbingNotFound) {
                  this.createDubbingStudio(dubbingStudio);
                }
              } else {
                this.notificationService.handleError(errorMessage, error);
              }
            }
          );
        },
        (error: any) => {
          this.notificationService.handleError(errorMessage, error);
        }
      );
    }
  }

  /**
   * Fetch  Dubbing Studios for specific title in Dubbing Cast and Dubbing Crew.
   *
   * @param titleType TitleType
   * @param titleId number
   * @param locale string
   * @returns Observable<any>
   */
  fetchTitleDubbingStudio(
    titleType: TitleType,
    titleId: number,
    locale: string
  ): Observable<any> {
    return this.titleService
    .fetchTitleDubbingStudio(
      titleType,
      titleId,
      locale);
  }

  addCredit() {
    if (!this.form.valid) {
      return;
    }

    const rawCredit = {
      talentId: this.titleCredit.talent.id,
      characterId: this.titleCredit.position.id,
      roleId: this.titleCredit.position.id,
      type: this.creditType,
      order: this.credits.length + 1,
      locale: this.creditLocale.getLocale(),
      isGuest: this.titleCredit.guest
    };

    rawCredit[this.title.type + 'Id'] = this.title.id;

    return this.creditsService.create(
      this.title,
      this.creditPositionType,
      CreditFactory(this.creditPositionType, rawCredit)
    ).subscribe(
      (response: Credit) => {
        this.fetch();

        if (this.creditType === CreditType.ORIGINAL) {
          this.onReloadLocaleList.emit(true);
        }

        this.resetTitleCredit();

        if (this.creditType.toString() === 'DUBBING') {
          this.form.controls['position'].enable();
          this.form.controls['additionalVoice'].enable();
          this.form.controls['guest'].enable();
        }

        this.resetPositionMultiFieldAutocomplete();
        this.resetTalentMultiFieldAutocomplete();

        this.notificationService.handleSuccess(this.creditPositionType + ' member added');
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'There was an error trying to add the ' + this.creditPositionType + ' member'
        );
        console.error('Error trying to add the credit', error);
      }
    );
  }

  /**
   * Manages the given credit.
   *
   * @param credits Credit[]
   * @param action CreditsManagerActions
   * @returns void
   */
  manageCredits(credits: Credit[], action: CreditsManagerActions): void {
    switch (true) {
      case action === CreditsManagerActions.DELETE:
        this.manager = { credits: credits, action: CreditsManagerActions.DELETE };
        this.deleteCredits(credits);
        break;
      default:
        this.manager = { credits: undefined, action: undefined };
        break;
    }
  }

  /**
   * Check if Guest Checkbox should be enabled in template
   *
   * @param titleType TitleInterface
   * @param creditPosition CreditPositionType
   * @param creditType CreditType
   * @returns boolean
   */
  enableGuestCheckbox(titleType: TitleInterface, creditPosition: CreditPositionType, creditType: CreditType) {
    let enablingCheckbox: boolean = false;

    enablingCheckbox =
    (
      titleType.type === TitleType.episode &&
      creditPosition === CreditPositionType.CAST &&
      creditType !== CreditType.TRANSLATED
    );

    return enablingCheckbox;
  }

  /**
   * Deletes the given credit.
   *
   * @param credits Credit[]
   * @param force boolean
   * @returns void
   */
  deleteCredits(credits: Credit[], force: boolean = false): void {
    if (force) {
      this.confirmDeleteCreditModal.hide();

      const apiCalls: Array<Observable<StormServiceResponseSingle>> = new Array();

      credits.forEach((credit: Credit) => {
        const deletingCall: Observable<StormServiceResponseSingle> = this.creditsService.delete(
          this.title,
          this.creditPositionType,
          credit
        );

        apiCalls.push(deletingCall);
      });

      forkJoin(apiCalls).subscribe(
        response => {
          const nextActions: any = () => {
            this.fetch();

            if (this.creditType === CreditType.ORIGINAL) {
              this.onReloadLocaleList.emit(true);
            }

            this.notificationService.handleSuccess(this.creditPositionType + ' member(s) removed');
          };

          this.changeStatusToFetchingData();
          credits.forEach(credit => {
            this.removeFromCredits(credit);
          });

          if (this.credits.length > 0) {
            this.createCreditsBackup();
            this.updateCreditsOrderNumber();

            if (this.hasCreditsResortingDifferences()) {
              this.saveCreditsNewOrderNumbers(false, false, nextActions);
            } else {
              nextActions();
            }
          } else {
            nextActions();
          }

          this.resetSelectionCounter();
          this.isMainCheckSelected = false;
        },
        error => {
          this.notificationService.handleError(
            'There was an error trying to delete the ' + this.creditPositionType + ' member(s)'
          );
          console.error('Error trying to delete the credit(s)', error);
        }
      );
    } else {
      this.confirmDeleteCreditModal.show();
    }
  }

  /**
   * Fetches episodes from the service.
   *
   * @returns void
   */
  fetch(): void {
    if (this.creditType !== CreditType.ORIGINAL && !this.creditLocale) {
      return;
    }

    this.changeStatusToFetchingData();
    this.fetchSubscription.unsubscribe();

    this.fetchSubscription = this.creditsService.fetch(
      this.title.type,
      this.title.id,
      this.creditType,
      this.creditPositionType,
      this.creditLocale.getLocale()
    ).pipe(
      flatMap(
        (credits: Credit[]) => this.entityMapperHelper.map(
          credits,
          [
            {
              property: 'talent.prefixId',
              mapTo: StormListType.affix,
            },
            {
              property: 'talent.suffixId',
              mapTo: StormListType.affix,
            },
          ]
        )
      )
    )
    .subscribe(
      (credits: Credit[]) => {

        if (this.creditType === CreditType.TRANSLATED && this.creditsInput?.length > 0) {
          this.credits = this.reorderCredits(this.creditsInput, credits);
        } else {
          this.credits = credits;
        }

        this.changeStatusToDataFound();
        this.initializeChecks();
      },
      error => {
        console.log(error);
        this.changeStatusToError();
      }
    );
  }

  /**
   * Set the locking status.
   *
   * @param shouldEmit boolean
   * @returns void
   */
  setLockingStatus(shouldEmit: boolean = false): void {
    if (this.title && this.creditType && this.creditPositionType && this.creditLocale) {
      this.creditLockingStatus = undefined;

      this.creditsService.fetchLockingStatus(
        this.title.type,
        this.title.id,
        this.creditType,
        this.creditPositionType,
        this.creditLocale.getLocale()
      ).subscribe(
        lockingStatus => {
          this.creditLockingStatus = lockingStatus;

          if (this.isOriginalCredit()) {
            this.originalLockingStatus.emit(this.creditLockingStatus.locked);
          }

          if (shouldEmit) {
            this.onLockingStatusUpdate.emit([lockingStatus]);
          }
        },
        error => this.creditLockingStatus = undefined
      );
    }
  }

  /**
   * Shows the confirm dialog to lock a credit title
   *
   * @returns void
   */
  confirmToggleCreditLockingStatus(): void {
    this.isDoubleChecked = false;
    this.selectedReason = '';

    this.fetchBlockReasons();

    this.modalService.open(this.confirmLockingModal).result.then(
      (result: string) => {
        if (result === 'YES') {
          this.toggleCreditLockingStatus();
        }
      },
      () => { }
    );
  }

  /**
   * Toggles the Locking status in the current credit title
   *
   * @returns void
   */
  toggleCreditLockingStatus(): void {
    const params: any = {
      titleType: this.title.type,
      titleId: this.title.id,
      locale: this.creditLocale.getLocale(),
      creditType: this.creditType,
      creditPositionType: this.creditPositionType,
      locked: !this.creditLockingStatus.locked,
      lockReason: this.selectedReason
    };

    this.creditsService.toggleLockingStatus(params).subscribe(
      (result: CreditLockingStatusInterface) => {
        this.notificationService.handleSuccess(
          this.creditType + ' ' + this.creditPositionType +
            ' ' + (!this.creditLockingStatus.locked ? 'locked' : 'unlocked'),
        );
        this.setLockingStatus(true);
      },
      (error: ErrorHelper) => {
        const errorMessage: string = 'There was an error trying to ' +
          (!this.creditLockingStatus.locked ? 'lock' : 'unlock') +
          ' ' + this.creditType +
          ' ' + this.creditPositionType;

        this.notificationService.handleError(errorMessage);
      }
    );
  }

  /**
   * Returns a boolean depending if the given entity is localized.
   *
   * @param entity CharacterInterface | RoleInterface
   * @returns boolean
   */
  isLocalized(entity: CharacterInterface | RoleInterface): boolean {
    return !!Object.keys(entity.localizedFields).length;
  }

  /**
   * Returns a boolean depending if the given credit is an additional voice.
   *
   * @param credit any
   * @returns boolean
   */
  isAdditionalVoice(credit: any): boolean {
    return this.itemAdditionalVoice.id === credit.characterId && this.creditType.toString() === 'DUBBING';
  }

  /**
   * Retrieves the talent suggestions.
   *
   * @param event any
   * @returns void
   */
  retrieveTalentSuggestions(event: any): void {
    this.talentStartLoading();
    this.filterValue = event.query.trim().toLocaleLowerCase().replace(/\s+/, ' ').split(' ');
    this.talentName = event.query.trim();

    this.talentSearchSubscription.unsubscribe();
    this.talentSearchSubscription = this.talentService.oldSearch({
      q: (<string>event.query).trim(),
      page_size: 1000,
      page: 1,
    }).subscribe(
      serviceResponseCollection => {
        this.talentManager.mapAttributes(serviceResponseCollection.collection).subscribe(
          (talents: TalentInterface[]) => {
            const mappedItems: ProximityItem[] = talents.map(
              (talent: TalentInterface) => {
                return new ProximityItem(talent.fullName, talent.id, undefined, talent);
              }
            );

            const key: string = `${this.creditType}-talent-search`;
            this.proximityManager.setCollection(key, mappedItems);
            this.proximityManager.addQuery(key, event.query);

            this.talentSuggestions = this.proximityManager.getList(key).map(
              (proximityItem: ProximityItem) => {
                return proximityItem.data;
              }
            );

            this.talentStopLoading();
            this.showTalentNotFound = this.form.get('talent').value && talents.length === 0;
          }
        );
      }
    );
  }

  /**
   * Updates the states of the inputs depend of the checkboxes values, if
   * resetValue is true, cleans the credit position input.
   *
   * @param resetValue boolean
   * @returns void
   */
  updateChanges(resetValue: boolean = false): void {
    if (this.titleCredit.guest) {
      this.form.controls['additionalVoice'].disable();
    } else {
      this.form.controls['additionalVoice'].enable();
      if (this.titleCredit.additionalVoice) {
        const additionalCharacter: Character = new Character({
          id: this.itemAdditionalVoice.id,
          name: this.itemAdditionalVoice.name
        });

        this.form.controls['position'].patchValue(additionalCharacter);
        this.titleCredit.position = additionalCharacter;
        this.form.controls['position'].disable();
        this.form.controls['guest'].disable();
        this.addCredit();
      } else {
        if (resetValue) {
          this.form.controls['position'].reset();
        }

        this.form.controls['position'].enable();
        this.form.controls['guest'].enable();
        this.creditPositionNotFound = false;
      }
    }
  }

  /**
   * Triggers an add-credit position action.
   *
   * @param position Character | Role
   * @returns void
   */
  triggerAddCreditPosition(position: Character | Role, action?: RoleMetadataManagerActions): void {
    if (!this.creditLockingStatus || this.creditLockingStatus.locked) {
      return;
    }

    if (this.creditPositionType === CreditPositionType.CREW) {
      this.addNewRole(<Role>position, <RoleMetadataManagerActions>action);
    } else {
      this.addNewCharacter(<Character>position);
    }
  }

  /**
   * Retrieves the position suggestions.
   *
   * @param event any
   * @returns void
   */
  retrievePositionSuggestions(event: any): void {
    const q: string = event.query.trim();
    this.positionName = event.query.trim();

    const query: any = {
      q: q,
      page: 1,
      page_size: 99999
    };

    const searchService: Observable<any> = (
      (this.creditPositionType === CreditPositionType.CAST)
        ? this.characterService.oldSearch(query, true)
        : this.roleService.oldSearch(query)
    );

    this.filterValue = q.toLocaleLowerCase().split(' ');
    this.isRoleLoading = true;
    this.positionSearchSubscription.unsubscribe();

    if (q) {
      this.positionSearchSubscription = searchService.subscribe(
        (response: StormServiceResponseCollection) => {
          const mappedItems: ProximityItem[] = response.collection.map(
            (position: any) => {
              return new ProximityItem(position.name, position.id, undefined, position);
            }
          );

          const key: string = `${this.creditType}-position-search`;
          this.proximityManager.setCollection(key, mappedItems);
          this.proximityManager.addQuery(key, event.query);

          this.positionSuggestions = this.proximityManager.getList(key).map(
            (proximityItem: ProximityItem) => proximityItem.data
          );

          this.creditPositionNotFound = this.form.get('position').value && this.positionSuggestions.length === 0;
          this.isRoleLoading = false;
        },
        error => {
          this.isRoleLoading = false;
        }
      );
    } else {
      this.isRoleLoading = false;
    }
  }

  /**
   * Sets up the form to add cast/crew
   *
   * @returns void
   */
  setupForm(): void {
    this.form = this.formBuilder.group({
      talent: [
        '',
        Validators.compose([
          Validators.required,
          // This is necessary for making the auto-complete invalid, if no coincidence is selected
          Validators.minLength(0),
          Validators.maxLength(0)
        ])
      ],
      guest: [
        false,
        Validators.compose([
        ])
      ],
      additionalVoice: [
        false,
        Validators.compose([
        ])
      ]
    });

    const crewPositionFormControl = new FormControl('', Validators.compose([
      Validators.required,
      // This is necessary for making the auto-complete invalid, if no coincidence is selected
      Validators.minLength(0),
      Validators.maxLength(0)
    ]));

    const castPositionFormControl = new FormControl('', Validators.compose([
      Validators.required
    ]));

    if (this.hasDisplayPositionMultiFieldAutocomplete()) {
      this.form.setControl('position', castPositionFormControl);
    } else {
      this.form.setControl('position', crewPositionFormControl);

      this.form.get('position').valueChanges.pipe(debounceTime(200)).subscribe(
        (value: string) => {
          this.showAddCreditPosition = this.isInvalidOption(value);
        }
      );
    }

    this.form.get('talent').valueChanges.pipe(debounceTime(200)).subscribe(
      (value: string) => {
        this.showAddTalent = this.isInvalidOption(value);
      }
    );
  }

  /**
   * Add a new talent according to the action sent but for Dubbing list will be overwritten by
   * action type Create_Talent
   *
   * @param talent Talent
   * @param action TalentMetadataManagerActions
   * @returns void
   */
  triggerAddTalent(
    talent: Talent,
    action: TalentMetadataManagerActions = TalentMetadataManagerActions.CREATE_TALENT
  ): void {
  if (!this.creditLockingStatus || this.creditLockingStatus.locked) {
    return;
  }

  const languageId: number = this.creditLocale.getLanguage().id;
  let talentMetadata: any;

  if (action === TalentMetadataManagerActions.CREATE) {
    talentMetadata = {
      language: languageId,
      originalLanguageId: languageId,
    };
  } else if (action === TalentMetadataManagerActions.CREATE_TALENT) {
    talentMetadata = this.talentMetadataExtractor.extract(this.talentName);

    talentMetadata.language = languageId;
    talentMetadata.originalLanguageId = languageId;
  }

  this.talentManager.manageTalentMetadata(
    new Object(),
    action,
    talent ? talent : talentMetadata,
    undefined,
    talentMetadata
  );
}

  /**
   * Updates the position.
   *
   * @param credit Role | RoleMetadata | Character | CharacterMetadata
   * @returns void
   */
  onCreditUpdate(credit: Role | RoleMetadata | Character | CharacterMetadata): void {
    if (credit) {
      if (credit instanceof RoleMetadata || credit instanceof CharacterMetadata) {
        this.fetch();
      } else {
        this.setFormPosition(credit);
        this.tryAddCreditForPosition();
      }
    }
  }

  /**
   * Updates the talent.
   *
   * @param talent Talent | TalentMetadata
   * @returns void
   */
  onTalentUpdate(talent: Talent | TalentMetadata): void {
    if (talent) {
      if (talent instanceof TalentMetadata) {
        this.fetch();
      } else {
        this.setFormTalent(talent);
        this.tryAddCreditForTalent();
      }
    }
  }

  /**
   * Indicates if it is locked.
   *
   * @returns boolean
   */
  isLocked(): boolean {
    return !this.isUnlocked();
  }

  /**
   * Indicates if resort is activated.
   *
   * @returns boolean
   */
  isResortActivated(): boolean {
    return this._isResortActivated;
  }

  /**
   * Indicates if it is unlocked.
   *
   * @returns boolean
   */
  isUnlocked(): boolean {
    const isIt: boolean = this.creditLockingStatus !== undefined && !this.creditLockingStatus.locked;

    return isIt;
  }

  /**
   * Opens the lateral modal to add a new character with the current language
   * and the query (as the name) string as default values
   *
   * @param character Character
   * @returns void
   */
  protected addNewCharacter(character: Character): void {
    const originalLanguageId: number[] = Array.of(this.creditLocale.getLanguage().id);

    this.addCharacter(character, originalLanguageId);
  }

  /**
   * Adds a new character.
   *
   * @param character Character
   * @param language number[]
   * @returns void
   */
  protected addCharacter(
    character: Character,
    language: number[]
  ): void {
    if (_isUndefined(character)) {
      this.newCharacter = new CharacterMetadata({
        name: this.positionName,
        language: language
      });
    } else {
      this.newCharacterManager.setCharacter(character);
      this.newCharacter = new CharacterMetadata({
        language: language
      });
    }

    this.showModal = true;
  }

  /**
   * Opens the lateral modal to add a new role with the current language
   * and the query (as the name) string as default values
   *
   * @returns void
   */
  protected addNewRole(role: Role, action: RoleMetadataManagerActions): void {
    if (action !== RoleMetadataManagerActions.CREATE) {
      action = RoleMetadataManagerActions.CREATE_ROLE;
    }

    const roleMetadata: any = {
      name: action !== RoleMetadataManagerActions.CREATE ? this.positionName : undefined,
      language: action === RoleMetadataManagerActions.CREATE ? this.creditLocale.getLanguage().id : undefined
    };

    this.roleManager.manageRoleMetadata(
      new Object(),
      <RoleMetadataManagerActions>action,
      role || roleMetadata,
      undefined,
      roleMetadata
    );
  }

  /**
   * Initializes the data required for the Bolt MultiFieldAutocomplete used to provide the position
   *
   * @returns void
   */
  protected initializePositionMultiFieldAutocomplete(): void {
    this.positionSearchManager = new SearchManager();
    this.positionScrollLoading = false;
    this.positionSuggestionsLoading = false;
    this.searchProductAssociationsLimit = this.appConfig.get('ux.page.character.searchProductAssociationsQuantity');
    this.setupPositionSearchCriteria();
  }

  /**
   * Initializes the data required for the Bolt MultiFieldAutocomplete used to provide the talent
   *
   * @returns void
   */
  protected initializeTalentMultiFieldAutocomplete(): void {
    this.talentSearchManager = new SearchManager();
    this.talentScrollLoading = false;
    this.talentSuggestionsLoading = false;
    this.setupTalentSearchCriteria();
  }

  /**
   * Reset the value displayed for the Bolt MultiFieldAutocomplete used to provide the position
   *
   * @returns void
   */
  protected resetPositionMultiFieldAutocomplete(): void {
    if (this.positionMultiFieldAutocomplete) {
      this.positionMultiFieldAutocomplete.inputEL.nativeElement.value = '';
      this.form.get('position').reset();
    }
  }

  /**
   * Reset the value displayed for the Bolt MultiFieldAutocomplete used to provide the talent
   *
   * @returns void
   */
  protected resetTalentMultiFieldAutocomplete(): void {
    if (this.talentMultiFieldAutocomplete) {
      this.talentMultiFieldAutocomplete.inputEL.nativeElement.value = '';
      this.form.get('talent').reset();
    }
  }

  /**
   * Setup the translated header of credit position type for some specific scenarios.
   *
   * @returns void
   */
  protected setupCreditPositionTypeHeadings(): void {
    this.creditPositionTypeHeadingLabel = this.creditPositionType.toString();
    this.creditPositionTypeInputHeadingLabel = this.creditPositionType.toString();
    this.creditPositionTypeHeadingColumnLabel = this.creditPositionType.toString();
    const isTypeWithCustomText = (
      this.title.type === TitleType.episode
      || this.title.type === TitleType.season
      || this.title.type === TitleType.feature
    );

    if (isTypeWithCustomText) {
      switch (this.creditPositionType) {
        case CreditPositionType.CAST:
          this.creditPositionTypeHeadingLabel = this.boltStore.translations.castLabel;
          this.creditPositionTypeInputHeadingLabel = this.boltStore.translations.characterLabel;
          this.creditPositionTypeHeadingColumnLabel = this.boltStore.translations.characterLabel;
          this.aggregationLabel = 'Aggregate Cast';
          break;
        case CreditPositionType.CREW:
          this.creditPositionTypeHeadingLabel = this.boltStore.translations.crewLabel;
          this.creditPositionTypeInputHeadingLabel = this.boltStore.translations.roleLabel;
          this.creditPositionTypeHeadingColumnLabel = this.boltStore.translations.roleLabel;
          this.aggregationLabel = 'Aggregate Crew';
          break;
        default:
          break;
      }
    }
  }

  /**
   * Sets up the dubbing studio options.
   *
   * @returns void
   */
  protected setupDubbingStudioOptions(): void {
    this.masterDataManager.getListFor(
      MasterDataType.dubbingStudios,
      false,
      (list: MasterDataList) => {
        this.dubbingStudios = list;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve dubbing studios', error);
      }
    );
  }

  /**
   * Activates resort.
   *
   * @returns void
   */
  protected activateResort(): void {
    if (this.canResort()) {
      this.createCreditsBackup();
      this.setIsResortActivated(true);
    }
  }

  /**
   * Applies the resort.
   *
   * @returns void
   */
  protected applyResort(): void {
    const nextActions: any = () => {
      this.resetCreditsBackup();
      this.fetch();
      this.onOrderChanged.emit({ credits: this.credits, changedCredits: [] });
    };

    this.setIsResortActivated(false);
    this.changeStatusToFetchingData();
    this.saveCreditsNewOrderNumbers(true, true, nextActions);
  }

  /**
   * Populate translations with default language until i18n will be implemented
   *
   * @returns void
   */
  protected populateTranslations(): void {
    this.boltStore = {
      translations: {
        talentLabel: `Talent`,
        castLabel: `Cast`,
        crewLabel: `Crew`,
        characterLabel: `Character`,
        roleLabel: `Role`,
        cloneActionLabel: `Clone`,
      }
    };
  }

  /**
   * Indicates if should has clone dialog opened.
   *
   * @returns boolean
   */
  protected hasOpen(): boolean {
    const hasIt = this.clonePanelVisible;
    return hasIt;
  }

  /**
   * Indicates if should has clone actions.
   *
   * @returns boolean
   */
  protected canShowCloneActions(): boolean {
    const canIt: boolean = (
      this.hasWritePrivilege() &&
      (this.title.isEpisode() || this.title.isSeason()) &&
      this.creditLocale &&
      this.creditType !== this.creditTypes.TRANSLATED &&
      !this.isDubbingTab() &&
      this.isUnlocked() &&
      !this.showForm
    );

    return canIt;
  }

  /**
   * Indicates if should has aggregate actions.
   *
   * @returns boolean
   */
  protected canShowAggregateActions(): boolean {
    const canIt: boolean = (
      this.hasWritePrivilege() &&
      this.title.isSeason() &&
      this.creditType !== this.creditTypes.TRANSLATED &&
      !this.isDubbingTab() &&
      this.isUnlocked() &&
      !this.showForm
    );

    return canIt;
  }

  /**
   * Indicates if event actions are disabled.
   *
   * @returns boolean
   */
  protected isEventActionDisabled(): boolean {
    const isIt =
      !this.isDataFound() ||
      (
        _isObject(this.creditLocale) &&
        (this.isResortActivated() || this.isEventInProgress()
      )
    );

    return isIt;
  }

  /**
   * Opens the dialog for copy and paste procedure.
   *
   * @returns void
   */
  protected openClonePanel(): void {
    this.clonePanelVisible = true;
  }

  /**
   * Closes the dialog for copy and paste procedure.
   *
   * @returns void
   */
  protected closeClonePanel(): void {
    this.clonePanelVisible = false;
  }

  /**
   * Indicates if both given credits are equals for reordering.
   *
   * @param creditA Credit
   * @param creditB Credit
   * @returns boolean
   */
  protected areCreditsEqualsForReordering(creditA: Credit, creditB: Credit): boolean {
    const areThem = (
      (creditA.id === creditB.id) &&
      (creditA.getOrder() === creditB.getOrder())
    );

    return areThem;
  }

  /**
   * Cancels resort.
   *
   * @returns void
   */
  protected cancelResort(): void {
    if (this.hasCreditsResortingDifferences()) {
      this.credits = _cloneDeep(this.creditsBackup);
    }

    this.onOrderChanged.emit({ credits: this.credits, changedCredits: [] });

    this.resetCreditsBackup();
    this.setIsResortActivated(false);
  }

  /**
   * Indicates if it can resort.
   *
   * @returns boolean
   */
  protected canResort(): boolean {
    const canIt: boolean = (
      this.isUnlocked() &&
      (
        (this.creditType === this.creditTypes.ORIGINAL) ||
        (this.creditType === this.creditTypes.DUBBING)
      )
    );

    return canIt;
  }

  /**
   * Indicates if it has to display the lock/unlock button.
   *
   * @returns boolean
   */
  protected hasDisplayLockButton(): boolean {
    const hasPrivilege =  this.isUnlocked() ? this.hasLockPrivilege() : this.hasUnlockPrivilege();
    return hasPrivilege && !this.isDubbingTab();
  }

  /**
   * Indicates if the current user has the privilege for changing the lock status.
   *
   * @returns boolean
   */
  protected canToggleLockingStatus(): boolean {
    if (this.creditLockingStatus.locked) {
      return this.hasUnlockPrivilege();
    }

    return this.hasLockPrivilege();
  }

  /**
   * Creates the creditsBackup from credits.
   *
   * @returns void
   */
  protected createCreditsBackup(): void {
    this.creditsBackup = _cloneDeep(this.credits);
    this.setHasCreditsResortingDifferences(false);
  }

  /**
   * Creates the given dubbing studio in the service.
   *
   * @param dubbingStudio DubbingStudio
   * @returns void
   */
  protected createDubbingStudio(dubbingStudio: DubbingStudio): void {
    this.titleService.createDubbingStudio(
      dubbingStudio,
      this.title.type,
      this.title.id,
      this.creditLocale.getLocale()
    ).subscribe(
      () => {
        this.setSelectedDubbingStudios(dubbingStudio);
        this.notificationService.handleSuccess('Dubbing Studio saved.');
      },
      (error) => {
        this.notificationService.handleError('Failed saving Dubbing Studio.', error);
      }
    );
  }

  /**
   * Detects if there is any difference related to resort credits.
   *
   * @returns void
   */
  protected detectCreditsResortingDifferences(): void {
    const canDetect: boolean = (
      (this.credits.length > 0) &&
      (this.creditsBackup.length > 0)
    );

    if (canDetect) {
      const limit: number = this.credits.length;
      let x: number = 0;
      let noChangesDetected: boolean = true;

      while (noChangesDetected && (x < limit)) {
        noChangesDetected = this.areCreditsEqualsForReordering(this.credits[x], this.creditsBackup[x]);
        x++;
      }

      this.setHasCreditsResortingDifferences(!noChangesDetected);
    }
  }

  /**
   * Returns the resorted credits.
   *
   * @returns Credit[]
   */
  protected getResortedCredits(): Credit[] {
    const credits: Credit[] = new Array();

    this.credits.forEach(
      (credit: Credit, index: number) => {
        if (!this.areCreditsEqualsForReordering(credit, this.creditsBackup[index])) {
          credits.push(credit);
        }
      }
    );

    return credits;
  }

  /**
   * Indicates if it has to block resort commands.
   *
   * @returns boolean
   */
  protected hasBlockResortCommands(): boolean {
    const hasIt: boolean = (
      !this.canResort() ||
      this.isEventInProgress() ||
      (this.status !== this.componentStatuses.dataFound) ||
      (this.credits.length < 2)
    );

    return hasIt;
  }

  /**
   * Indicates if it has a credit locale.
   *
   * @returns boolean
   */
  protected hasCreditLocale(): boolean {
    const hasIt: boolean = _isObject(this.creditLocale);
    return hasIt;
  }

  /**
   * Indicates if credits has resorting differences.
   *
   * @returns boolean
   */
  protected hasCreditsResortingDifferences(): boolean {
    return this._hasCreditsResortingDifferences;
  }

  /**
   * Indicates if it has to disable the guest checkbox.
   *
   * @param credit RoleInterface | TalentInterface | CharacterInterface
   * @returns boolean
   */
  protected hasDisableGuestCheckboxFor(credit: RoleInterface | TalentInterface | CharacterInterface): boolean {
    const hasIt: boolean =
      !this.hasWritePrivilege() ||
      (
        this.creditType !== this.creditTypes.TRANSLATED &&
        _isObject(this.creditLockingStatus) &&
        this.creditLockingStatus.locked
      ) ||
      this.isResortActivated() ||
      this.isAdditionalVoice(credit);

    return hasIt;
  }

  /**
   * Indicates if the translated credit can be locked.
   *
   * @returns boolean
   */
  protected canLockTranslatedCredit(): boolean {
    if (this.creditType === this.creditTypes.TRANSLATED && !this.isLockedOriginalCredit) {
      this.setTitleLockButton('You can\'t lock it due to the Original is unlocked');

      return false;
    }

    return true;
  }

  /**
   * Sets title to lock button
   *
   * @returns void
   */
  protected setTitleLockButton(title: string = ''): void {
    this.titleLockButton = title;
  }

  /**
   * Indicates if it has to disable the lock button.
   *
   * @returns boolean
   */
  protected hasDisableLockButton(): boolean {
    this.setTitleLockButton();

    const hasIt: boolean =
      !_isObject(this.creditLockingStatus) ||
      !this.canLockTranslatedCredit() ||
      this.isEventActionDisabled() ||
      !this.canToggleLockingStatus();

    return hasIt;
  }

  /**
   * Indicates if it has to display the add some link.
   *
   * @returns boolean
   */
  protected hasDisplayAddSomeLink(): boolean {
    const hasIt: boolean =
      this.hasWritePrivilege() &&
      this.creditType !== this.creditTypes.TRANSLATED &&
      _isObject(this.creditLockingStatus) &&
      !this.creditLockingStatus.locked;

    return hasIt;
  }

  /**
   * Indicates if it has to display the delete button.
   *
   * @returns boolean
   */
  protected hasDisplayDeleteButton(): boolean {
    const hasIt: boolean =
      this.hasWritePrivilege() &&
      this.creditType !== this.creditTypes.TRANSLATED &&
      _isObject(this.creditLockingStatus) &&
      !this.isDubbingTab() &&
      !this.creditLockingStatus.locked;

    return hasIt;
  }

  /**
   * Indicates if it has to display the bulk delete button and checkboxes.
   *
   * @returns boolean
   */
  protected hasDisplayBulkDelete(): boolean {
    const hasIt: boolean =
      this.hasBulkDeletePrivilege() &&
      this.creditType !== this.creditTypes.TRANSLATED &&
      _isObject(this.creditLockingStatus) &&
      !this.isDubbingTab() &&
      !this.creditLockingStatus.locked;

    return hasIt;
  }

  /**
   * Indicates if it has to display the dubbing studios spinner
   *
   * @returns boolean
   */
  protected hasDisplayDubbingStudioSpinner(): boolean {
    const hasIt: boolean =
      !this.hasShowDubbingStudioList() &&
      _isUndefined(this.selectedDubbingStudio) &&
      !this.dubbingNotFound;

    return hasIt;
  }

  /**
   * Indicates if it has to display the link for creating a localization of the given credit.
   *
   * @returns boolean
   */
  protected hasDisplayLinkFor(credit: RoleInterface | TalentInterface | CharacterInterface): boolean {
    const hasIt: boolean = (
      this.canEdit(credit) &&
      !this.blocked
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the link for creating a localization of the given credit.
   *
   * @returns boolean
   */
  protected canEdit(credit: RoleInterface | TalentInterface | CharacterInterface): boolean {
    const hasIt: boolean = (
      this.hasWritePrivilege() &&
      this.creditType === this.creditTypes.TRANSLATED &&
      !this.isLocalized(credit)
    );

    return hasIt;
  }

  /**
   * Indicates if it has to expand the header.
   *
   * @returns boolean
   */
  protected hasExpandHeader(): boolean {
    return this.hasWritePrivilege();
  }

  /**
   * Indicates if the current user has lock privilege.
   *
   * @returns boolean
   */
  protected hasLockPrivilege(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(this.pathToCheckPrivileges, [ActionTypeEnum.lock]);
  }

  /**
   * Indicates if it has to show commands.
   *
   * @returns boolean
   */
  protected hasShowCommands(): boolean {
    const hasIt: boolean = (
      this.hasWritePrivilege() &&
      (this.creditType !== this.creditTypes.TRANSLATED) &&
      !this.isDubbingTab() &&
      this.isUnlocked()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to show dubbing studio.
   *
   * @returns void
   */
  protected hasShowDubbingStudio(): boolean {
    const canIt: boolean = (
      !this.showForm &&
      !this.isResortActivated() &&
      (this.creditType === this.creditTypes.DUBBING) &&
      this.creditLocale &&
      this.creditLocale.getLanguage() &&
      _isObject(this.dubbingStudios) &&
      (this.dubbingStudios.hasItems() &&
      ((this.title.type === TitleType.episode) || (this.title.type === TitleType.feature))
      )
    );

    return canIt;
  }

  /**
   * Indicates if it has to show dubbing studio list.
   *
   * @returns void
   */
  protected hasShowDubbingStudioList(): boolean {
    const hasIt: boolean = (
      this.hasWritePrivilege() &&
      !this.isEventInProgress() &&
      !this.isResortActivated() &&
      this.creditLockingStatus &&
      !this.creditLockingStatus.locked &&
      _isObject(this.dubbingStudios) &&
      this.dubbingStudios.hasItems()
    );

    return hasIt;
  }

  /**
   * Indicates if the current user has unlock privilege.
   *
   * @returns boolean
   */
  protected hasUnlockPrivilege(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(this.pathToCheckPrivileges, [ActionTypeEnum.unlock]);
  }

  /**
   * Indicates if the current user has write privilege.
   *
   * @returns boolean
   */
  protected hasWritePrivilege(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(this.pathToCheckPrivileges, [ActionTypeEnum.write]);
  }

  /**
   * Indicates if the current user has bulk delete privilege.
   *
   * @returns boolean
   */
  protected hasBulkDeletePrivilege(): boolean {
    return this.capabilitiesManager.hasUserPrivilegeOn(this.pathToCheckPrivileges, [ActionTypeEnum.delete]);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.selectedDubbingStudioId = undefined;
    this.fetchSubscription = new Subscription();
    this.fetchTitleDubbingStudioSubscription = new Subscription();
    this.positionSearchSubscription = new Subscription();
    this.talentSearchSubscription = new Subscription();
    this.creditChecks = new Map();

    this.populateTranslations();

    this.talentManager.getManagedTalentMetadata().subscribe(
      talentMetadataManager => this.talentMetadataManager = talentMetadataManager
    );

    this.roleManager.getManagedRoleMetadata().subscribe(
      roleMetadataManager => this.roleMetadataManager = roleMetadataManager
    );

    this.initializePositionMultiFieldAutocomplete();
    this.initializeTalentMultiFieldAutocomplete();
    this.turnOffEventFlag();
    this.setupForm();
    this.resetSelectionCounter();
  }

  /**
   * Indicates if the the form field value of the given key is a valid.
   *
   * @param key string
   * @returns boolean
   */
  protected isAttributeValueValid(key: string): boolean {
    return !(this.form.get(key).dirty && !this.form.get(key).valid);
  }

  /**
   * Indicates if an event is in progress.
   *
   * @returns boolean
   */
  protected isEventInProgress(): boolean {
    return this.eventFlag;
  }

  /**
   * Indicates if the given value is invalid.
   *
   * @param value any
   * @returns boolean
   */
  protected isInvalidOption(value: any): boolean {
    const isIt: boolean = _isString(value) && value.trim().length > 0 ? true : false;
    return isIt;
  }

  /**
   * Returns a new observable for resorting update credits.
   *
   * @returns Observable<any>
   */
  protected newAsynchronousCreditsResortingUpdate(): Observable<any> {
    const apiCalls: Array<Observable<Credit>> = new Array();

    this.getResortedCredits().forEach(
      (credit: Credit) => {
        const updatingCall: Observable<Credit> = this.creditsService.update(
          this.title,
          this.creditPositionType,
          credit
        );

        apiCalls.push(updatingCall);
      }
    );

    const obs: Observable<any> = forkJoin(apiCalls);

    return obs;
  }

  /**
   * Removes the given credit from credits.
   *
   * @param credit Credit
   * @returns void
   */
  protected removeFromCredits(credit: Credit): void {
    const removingCriteria: any = (aCredit: Credit) => {
      const shouldRemoved: boolean = (aCredit.id === credit.id);
      return shouldRemoved;
    };

    _remove(this.credits, removingCriteria);
  }

  /**
   * Reset the credits backup.
   *
   * @returns void
   */
  protected resetCreditsBackup(): void {
    this.creditsBackup = new Array();

    this.setHasCreditsResortingDifferences(false);
    this.setIsResortActivated(false);
  }

  /**
   * Saves the new orders for credits.
   *
   * @param displayMessage boolean
   * @param triggerUpdate boolean
   * @param onSuccessDo any
   * @returns void
   */
  protected saveCreditsNewOrderNumbers(displayMessage: boolean, triggerUpdate: boolean, onSuccessDo: any): void {
    if (this.hasCreditsResortingDifferences()) {
      this.newAsynchronousCreditsResortingUpdate().subscribe(
        (successResponse: any) => {
          if (displayMessage) {
            this.notificationService.handleSuccess(`${this.creditPositionType} members updated.`);
          }

          if (triggerUpdate) {
            this.onReloadLocaleList.emit(true);
          }

          if (onSuccessDo) {
            onSuccessDo();
          }
        },
        (error: any) => {
          this.notificationService.handleError(`Error updating ${this.creditPositionType} members updated.`, error);
        }
      );
    }
  }

  /**
   * Set the selected value as the current dubbing studio.
   *
   * @returns void
   */
  protected setSelectedDubbingStudios(selected: DubbingStudio): void {
    if (_isObject(selected)) {
      this.selectedDubbingStudio = selected;
      this.selectedDubbingStudioId = selected.id;
      this.dubbingNotFound = false;
    } else {
      this.selectedDubbingStudio = undefined;
      this.selectedDubbingStudioId = undefined;
      this.dubbingNotFound = true;
    }
  }

  /**
   * Set if credits has resorting differences.
   *
   * @param isResortActivated boolean
   * @returns void
   */
  protected setHasCreditsResortingDifferences(hasCreditsResortingDifferences: boolean): void {
    this._hasCreditsResortingDifferences = hasCreditsResortingDifferences;
  }

  /**
   * Set if resort is activated.
   *
   * @param isResortActivated boolean
   * @returns void
   */
  protected setIsResortActivated(isResortActivated: boolean): void {
    this._isResortActivated = isResortActivated;
  }

  /**
   * Set up the dragula bag.
   *
   * @returns void
   */
  protected setupDragulaBag(): void {
    const creditType: string = this.creditType.toString().toLocaleLowerCase();
    const locale: string = this.creditLocale ? this.creditLocale.getLocale() : undefined;
    const random: number = Math.floor(Math.random() * 100);

    this.dragulaBag = `cast&crew-${creditType}-${this.title.id}-${locale}-${random}`;
    this.dragulaService.destroy(this.dragulaBag);
    this.setupDragulaGroup();
  }

  /**
   * Set up the dragula group for the current bag.
   *
   * @returns void
   */
  protected setupDragulaGroup(): void {
    this.dragulaService.createGroup(this.dragulaBag, {
      moves: () => {
        return this.isResortActivated();
      }
    });

    this.dragulaService.drop(this.dragulaBag).subscribe(
      (droppedItem: any) => {
        this.updateCreditsOrderNumber();
        this.onOrderChanged.emit({ credits: this.credits, changedCredits: this.getResortedCredits()});
      }
    );
  }

  /**
   * Updates the credits order numbers.
   *
   * @returns void
   */
  protected updateCreditsOrderNumber(): void {
    this.credits.forEach(
      (credit: Credit, index: number) => {
        const newOrder = (index + 1);
        credit.setOrder(newOrder);
      }
    );

    this.detectCreditsResortingDifferences();
  }

  /**
   * Updates the guest of given credit.
   *
   * @param credit Credit
   * @returns void
   */
  protected updateGuestCredit(credit: Credit): void {
    credit.setIsGuest(!credit.getIsGuest());

    this.creditsService.update(
      this.title,
      this.creditPositionType,
      credit
    ).subscribe(
      response => {
        this.notificationService.handleSuccess(`${this.creditPositionType} updated.`);
      },
      error => {
        this.notificationService.handleError(`There was an error trying to update the ${this.creditPositionType}.`);
        console.error('There was an error trying to update the credit', error);
      }
    );
  }

  /**
   * Indicates if the given credit was changed by resort.
   *
   * @param credit Credit
   * @returns boolean
   */
  protected wasCreditChangedByResort(credit: Credit): boolean {
    let wasIt: boolean = false;

    if (this.isResortActivated()) {
      const index: number = this.credits.findIndex(
        (originalCredit: Credit) => (originalCredit.id === credit.id)
      );

      wasIt = !this.areCreditsEqualsForReordering(credit, this.creditsBackup[index]);
    } else if (!this.isOriginalCredit()) {
      wasIt = this.creditsModified?.some(
        (originalCredit: Credit) => (originalCredit.id === credit.id)
      );
    }

    return wasIt;
  }

  /**
   * Receives the selected by user episode or season to clone.
   *
   * @param selectedEpisodeSeasonToClone EpisodeInterface | SeasonInterface
   * @returns void
   */
  protected cloneSelectSync(selectedEpisodeSeasonToClone: EpisodeInterface | SeasonInterface): void {
    this.closeClonePanel();
    this.initialDetailsCache = new CloningStatusDetails({
      uuid: '-1',
      eventType: EventTypeEnum.CLONNING.toString(),
      status: CloningStatusEnum.IN_PROGRESS
    });

    if (selectedEpisodeSeasonToClone !== undefined) {
      this.changeStatusToFetchingData();

      if (this.pullingAction) {
        this.pullingAction.reset();
      }

      const sourceTitle = <Title>selectedEpisodeSeasonToClone;

      if (this.creditType === CreditType.DUBBING) {
        this.doCloneRequest(sourceTitle, this.creditLocale.getLocale());
      } else {
        this.doCloneRequest(sourceTitle);
      }
    }
  }

  /**
   * Make call to requestClone method of title clone service
   * with locale param as optional.
   *
   * @param sourceTitle Title
   * @param locale string
   * @returns void
   */
  protected doCloneRequest(sourceTitle: Title, locale?: string): void {
    this.turnOnEventFlag();
    this.resetPullingErrorList();

    this.creditsCloneService.requestClone(
      sourceTitle,
      this.title,
      this.creditType,
      this.creditPositionType,
      locale
    ).subscribe(
      (response: CloningStatusDetails) => this.requestEventSuccessCallback(response),
      (err: ErrorHelper) => this.requestEventErrorCallback(err)
    );
  }

  /**
   * Resets the dubbing studios selection.
   *
   * @returns void
   */
  protected resetDubbingStudioSelection(): void {
    this.selectedDubbingStudio = undefined;
    this.selectedDubbingStudioId = undefined;
  }

  /**
   * Sync when request clone or aggregation event fails and throw an error.
   *
   * @param cachedThis any
   * @param error ErrorHelper
   * @returns void
   */
  protected requestEventErrorCallback(error: ErrorHelper): void {
    if (this.pullingAction) {
      this.pullingAction.reset();
    }

    this.throwEventErrorAndRefresh(error);
  }

  /**
   * Sync when request clone or aggregation event has been successful, cache the current status
   * and start the pulling action.
   *
   * @param cachedThis any
   * @param initialDetails CloningStatusDetails
   * @returns void
   */
  protected requestEventSuccessCallback(initialDetails: CloningStatusDetails): void {
    let message: string;

    if (initialDetails.eventType === EventTypeEnum.AGGREGATION.toString()) {
      message = 'The aggregation request was successfully sent to server and the aggregation is being processed.';
    } else {
      message = 'The clone request was successfully sent to server and the cloning is being processed.';
    }

    this.initialDetailsCache = initialDetails;
    this.notificationService.handleNotice(message);
    this.pullingAction.set(
      () => {
        this.checkCloningStatus();
      },
      this.pullingAttempts
    );
    this.pullingAction.execute();
  }

  /**
   * Sync when cloning is completed to refresh the episodes list.
   *
   * @returns void
   */
  protected checkCloningStatus(): void {
    this.creditsCloneService.fetchCloningEventStatus(
      this.initialDetailsCache.uuid
    ).pipe(
      finalize(
        () => this.finallyFetchingCurrentEventStatus()
      )
    )
    .subscribe(
      (eventStatusDetails: CloningStatusDetails) => {
        this.initialDetailsCache.updateEventStatus(eventStatusDetails.status);
        this.successfullyFetchingCurrentEventStatus(eventStatusDetails);
      },
      (error: ErrorHelper) => this.failedFetchingCurrentEventStatus(error)
    );
  }

  /**
   * Adds the error to errorsList on fetching cloning status fails.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected failedFetchingCurrentEventStatus(error: ErrorHelper): void {
    if (this.pullingAction) {
      this.pullingAction.reset();
    }

    this.pullingErrorList.push(error);
    this.throwEventErrorAndRefresh(error);
  }

  /**
   * Throws an error for the cloning process and refresh the episodes list.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected throwEventErrorAndRefresh(error: ErrorHelper): void {
    let message: string;

    if (this.initialDetailsCache && this.initialDetailsCache.eventType === EventTypeEnum.AGGREGATION.toString()) {
      message = 'The aggregation process failed.';
    } else {
      message = 'Failed cloning selected episode or season.';
    }

    this.notificationService.handleError(message, error);
    this.turnOffEventFlag();
    this.fetch();
  }

  /**
   * After fetching the cloning status, with him, checks current status and based on that
   * refresh the episodes list if ready or throw an error if couldn't be cloned.
   *
   * @param currentStatus Status
   * @returns void
   */
  protected successfullyFetchingCurrentEventStatus(currentStatus: CloningStatusDetails): void {
    if (currentStatus.isCompleted()) {
      let message: string;
      if (currentStatus.eventType === EventTypeEnum.AGGREGATION.toString()) {
        message = 'The aggregation process was successfully completed.';
      } else {
        message = 'The cloning process was successfully completed.';
      }

      this.fetch();

      if (this.creditType === CreditType.ORIGINAL) {
        this.onReloadLocaleList.emit(true);
      }

      this.notificationService.handleSuccess(message);
      this.pullingAction.reset();
      this.turnOffEventFlag();
    } else if (currentStatus.isError()) {
      this.pullingAction.reset();

      const errorToDisplay: ErrorHelper = new ErrorHelper(currentStatus.errorMessage);

      this.throwEventErrorAndRefresh(errorToDisplay);
    }
  }

  /**
   * Checks if cloning status checks flow needs to continue or not.
   *
   * @returns void
   */
  protected finallyFetchingCurrentEventStatus(): void {
    try {
      if (!this.pullingAction.wasReset()) {
        this.pullingAction.execute();
      }
    } catch (error) {
      this.pullingErrorList.push(error);

      const errorToDisplay: ErrorHelper = this.pullingErrorList.shift();
      this.throwEventErrorAndRefresh(errorToDisplay);

      this.pullingErrorList.forEach(
        (extraError: ErrorHelper) => {
          console.error(extraError.toString());
        }
      );
    }
  }

  /**
   * Indicates if 'Nothing found.' have to be displayed for credit position
   *
   * @returns boolean
   */
  protected hasDisplayCreditPositionNotFound(): boolean {
    const hasIt: boolean = this.creditPositionNotFound && !this.titleCredit.additionalVoice;
    return hasIt;
  }

  /**
   * Indicates if 'Add new Cast/Crew.' have to be displayed for credit position
   *
   * @returns boolean
   */
  protected hasDisplayAddCreditPosition(): boolean {
    const hasIt: boolean = this.showAddCreditPosition && !this.titleCredit.additionalVoice;
    return hasIt;
  }

  /**
   * Indicates if MultiField autocomplete have to be displayed
   *
   * @returns boolean
   */
  protected hasDisplayPositionMultiFieldAutocomplete(): boolean {
    const hasIt: boolean = (
      this.appConfigurationManager.getToggleValue(ToggleKeyEnum.positionMultifieldSearchOn) &&
      (
        this.hasDisplayCharacterMultiFieldAutocomplete() ||
        this.hasDisplayRoleMultiFieldAutocomplete()
      )
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the role search.
   *
   * @returns boolean
   */
  protected hasDisplayRoleMultiFieldAutocomplete(): boolean {
    const hasIt: boolean = this.creditPositionType === CreditPositionType.CREW;

    return hasIt;
  }

  /**
   * Indicates if it has to display the character search.
   *
   * @returns boolean
   */
  protected hasDisplayCharacterMultiFieldAutocomplete(): boolean {
    const hasIt: boolean = this.creditPositionType === CreditPositionType.CAST;

    return hasIt;
  }

  /**
   * Indicates if MultiField autocomplete for talent have to be displayed
   *
   * @returns boolean
   */
  protected hasDisplayTalentMultiFieldAutocomplete(): boolean {
    return this.appConfigurationManager.getToggleValue(ToggleKeyEnum.talentMultifieldSearchOn);
  }

  /**
   * Indicates if it has to display the search spinner.
   *
   * @returns boolean
   */
  protected hasDisplaySuggestionsSpinner(): boolean {
    return this.positionSuggestionsLoading;
  }

  /**
   * Indicates if it has to display the search spinner.
   *
   * @returns boolean
   */
  protected hasDisplayTalentSuggestionsSpinner(): boolean {
    return this.talentSuggestionsLoading;
  }

  /**
   * Retrieves all suggestions for the position (character or role) search.
   *
   * @param query string
   * @param shouldResetSearch boolean
   * @returns void
   */
  protected retrieveSearchSuggestions(query: string, shouldResetSearch: boolean): void {
    const incomingQuery: string = query.toLowerCase().trim();

    this.positionName = query.trim();
    this.positionScrollLoading = true;

    if (shouldResetSearch) {
      this.positionSearchManager.sanitizedQuery = incomingQuery;
      this.positionSuggestionsLoading = true;
    }

    this.positionSearchCriteria.setPageNumber(this.positionSearchManager.currentPage - 1);
    this.positionSearchCriteria.setQuery(this.sanitizedQuery);
    this.positionSearchCriteria.setSearchType(this.getSearchType());

    if (this.creditType === this.creditTypes.DUBBING) {
      this.positionSearchCriteria.setLocales(['*_*_*_*', this.creditLocale.getLocale()]);
    }

    if (this.creditPositionType === CreditPositionType.CAST) {
      this.searchCharacters();
    } else {
      this.searchRoles();
    }
  }

  /**
   * Search the roles that matches a given criteria
   *
   * @returns void
   */
  protected searchRoles(): void {
    this.positionSearchSubscription.unsubscribe();

    this.positionSearchSubscription = this.roleService.search(
      this.positionSearchCriteria,
      (response: SearchResponse) => {
        this.positionSearchManager.response = response;
        this.positionScrollLoading = false;
        this.positionSuggestionsLoading = false;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the item suggestions.', error);
        this.positionScrollLoading = false;
        this.positionSuggestionsLoading = false;
      }
    );
  }

  /**
   * Search the characters that matches a given criteria
   *
   * @returns void
   */
  protected searchCharacters(): void {
    this.positionSearchSubscription.unsubscribe();

    this.positionSearchSubscription = this.characterService.search(
      this.positionSearchCriteria,
      (response: SearchResponse) => {
        this.positionSearchManager.response = response;
        this.positionScrollLoading = false;
        this.positionSuggestionsLoading = false;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the item suggestions.', error);
        this.positionScrollLoading = false;
        this.positionSuggestionsLoading = false;
      }
    );
  }

  /**
   * Retrieves all suggestions for the talent search.
   *
   * @param query string
   * @param shouldResetSearch boolean
   * @returns void
   */
  protected retrieveTalentSearchSuggestions(query: string, shouldResetSearch: boolean): void {
    const incomingQuery: string = query.toLowerCase().trim();

    this.talentName = query.trim();
    this.talentScrollLoading = true;

    if (shouldResetSearch) {
      this.talentSearchManager.sanitizedQuery = incomingQuery;
      this.talentSuggestionsLoading = true;
    }

    this.talentSearchCriteria.setPageNumber(this.talentSearchManager.currentPage - 1);
    this.talentSearchCriteria.setQuery(this.talentSanitizedQuery);
    this.talentSearchCriteria.setSearchType(this.getSearchType());

    if (this.creditType === this.creditTypes.DUBBING) {
      this.talentSearchCriteria.setLocales(['*_*_*_*', this.creditLocale.getLocale()]);
    }

    this.talentSearchSubscription.unsubscribe();

    this.talentSearchSubscription = this.talentService.search(
      this.talentSearchCriteria
    ).subscribe(
      (response: SearchResponse) => {
        this.talentSearchManager.response = response;
        this.talentScrollLoading = false;
        this.talentSuggestionsLoading = false;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the item suggestions.', error);
        this.talentScrollLoading = false;
        this.talentSuggestionsLoading = false;
      }
    );
  }

  /**
   * Set up the search criteria for position.
   *
   * @returns void
   */
  protected setupPositionSearchCriteria(): void {
    this.positionSearchCriteria = new CastCrewSearchCriteria();

    if (this.creditPositionType === CreditPositionType.CAST) {
      this.positionSearchCriteria.setPageSize(this.appConfig.get('ux.page.character.searchPageSize'));
    } else {
      this.positionSearchCriteria.setPageSize(this.appConfig.get('ux.page.role.searchPageSize'));
    }
  }

  /**
   * Set up the search criteria for talent.
   *
   * @returns void
   */
  protected setupTalentSearchCriteria(): void {
    this.talentSearchCriteria = new CastCrewSearchCriteria();
    this.talentSearchCriteria.setPageSize(this.appConfig.get('ux.page.talent.searchPageSize'));
    this.talentSearchCriteria.setSearchType(this.getSearchType());
  }

  /**
   * Gets the search type for the current credit type
   *
   * @returns SearchType
   */
  protected getSearchType(): SearchType {
    let type: SearchType;

    if (this.creditType === this.creditTypes.DUBBING) {
      type = new SearchType(SearchTypeEnum.All);
    } else {
      type = new SearchType(SearchTypeEnum.Original);
    }

    return type;
  }

  /**
   * Sets the selected character.
   *
   * @param selectedCharacter Character | Role
   * @returns void
   */
  protected selectPosition(selectedPosition: Character | Role): void {
    this.setFormPosition(selectedPosition);
    this.tryAddCreditForPosition();
  }

  /**
   * Sets the selected role.
   *
   * @param selectedRole Role
   * @returns void
   */
  protected selectTalent(selectedTalent: Talent): void {
    this.setFormTalent(selectedTalent);
    this.tryAddCreditForPosition();
  }

  /**
   * Loads the next page for the current query
   *
   * @returns void
   */
  protected loadNextPage(): void {
    if (!this.positionScrollLoading) {
      this.retrieveSearchSuggestions(this.sanitizedQuery, false);
    }
  }

  /**
   * Loads the next page for talent with the current query
   *
   * @returns void
   */
  protected loadTalentNextPage(): void {
    if (!this.talentScrollLoading) {
      this.retrieveTalentSearchSuggestions(this.talentSanitizedQuery, false);
    }
  }

  /**
   * Gets the fields for the position multi-field autocomplete
   *
   * @returns string[]
   */
  protected getPositionFields(): string[] {
    let fields: string[];

    if (this.creditPositionType === CreditPositionType.CAST) {
      fields = ['name', 'products$getProductAssociationAsString>' + this.searchProductAssociationsLimit];
    } else {
      fields = ['name'];
    }

    return fields;
  }

  /**
   * Gets the headers for the position multi-field autocomplete
   *
   * @returns string[]
   */
  protected getPositionHeaders(): string[] {
    let headers: string[];

    if (this.creditPositionType === CreditPositionType.CAST) {
      if (this.creditType === this.creditTypes.DUBBING) {
        headers = ['Original name /\nLocalized Name', 'Product Association'];
      } else {
        headers = ['Original name', 'Product Association'];
      }
    } else {
      if (this.creditType === this.creditTypes.DUBBING) {
        headers = ['Original name /\nLocalized Name'];
      } else {
        headers = [];
      }
    }

    return headers;
  }

  /**
   * Gets the headers for the talent multi-field autocomplete
   *
   * @returns string[]
   */
  protected getTalentHeaders(): string[] {
    let headers: string[];

    if (this.creditType === this.creditTypes.DUBBING) {
      headers = ['Original name /\nLocalized Name'];
    } else {
      headers = [];
    }

    return headers;
  }

  /**
   * Reset the pulling errors list.
   *
   * @returns void
   */
  protected resetPullingErrorList(): void {
    this.pullingErrorList = new Array();
  }

  /**
   * Sets and update the talent field
   *
   * @param talent Talent
   * @returns void
   */
  protected setFormTalent(talent: Talent): void {
    this.talentManager.mapAttributes(talent).subscribe(
      (mappedTalents: Talent[]) => {
        this.titleCredit.talent = mappedTalents.pop();
        this.form.get('talent').setValue(this.titleCredit.talent);
      }
    );
  }

  /**
   * Sets and update the position field
   *
   * @param position Role | Character
   * @returns void
   */
  protected setFormPosition(position: Role | Character): void {
    this.titleCredit.position = position;
    this.form.get('position').setValue(position);
  }

  /**
   * Clear the position input messages and try to add the credit.
   *
   * @returns void
   */
  protected tryAddCreditForPosition(): void {
    this.showAddCreditPosition = false;
    this.creditPositionNotFound = false;
    this.addCredit();
  }

  /**
   * Clear the talent input messages and try to add the credit.
   *
   * @returns void
   */
  protected tryAddCreditForTalent(): void {
    this.showAddTalent = false;
    this.showTalentNotFound = false;
    this.addCredit();
  }

  /**
   * Turns on the event flag.
   *
   * @returns void
   */
  protected turnOffEventFlag(): void {
    this.eventFlag = false;
  }

  /**
   * Turns on the event flag.
   *
   * @returns void
   */
  protected turnOnEventFlag(): void {
    this.eventFlag = true;
  }

  /**
   * This shows the confirmation modal for aggregation
   *
   * @returns void
   */
  protected aggregateConfirmation() {
    this.resetHotConfig();
    this.initializeHotConfiguration();

    this.modalService.open(this.confirmAggregationModal).result
      .then(
        (confirmToggle: boolean) => {
          if (confirmToggle) {
            this.aggregate();
          }
        }
      )
      .catch(
        (reason: any) => {
          console.error('Aggregate error', reason);
        }
      );
  }

  /**
   * This starts the aggregation process
   *
   * @returns void
   */
  protected aggregate(): void {
    this.changeStatusToFetchingData();
    this.turnOnEventFlag();
    this.resetPullingErrorList();

    if (this.pullingAction) {
      this.pullingAction.reset();
    }

    this.initialDetailsCache = new CloningStatusDetails({
      uuid: '-1',
      eventType: EventTypeEnum.AGGREGATION.toString(),
      status: CloningStatusEnum.IN_PROGRESS
    });

    if (this.creditType === CreditType.DUBBING) {
      this.creditsAggregateService.requestAggregationDubbingTitle(
        this.title.id,
        this.creditPositionType,
        this.aggregateFrequency,
        this.creditLocale.getLocale(),
        (response: any) => {
          const aggregationStatus: CloningStatusDetails = new CloningStatusDetails(response.item);
          this.requestEventSuccessCallback(aggregationStatus);
        },
        (err: ErrorHelper) => this.requestEventErrorCallback(err)
      );
    } else {
      this.creditsAggregateService.requestAggregationOriginalTitle(
        this.title.id,
        this.creditPositionType,
        this.aggregateFrequency,
        (response: StormServiceResponseSingle) => {
          const aggregationStatus: CloningStatusDetails = new CloningStatusDetails(response.item);
          this.requestEventSuccessCallback(aggregationStatus);
        },
        (err: ErrorHelper) => this.requestEventErrorCallback(err)
      );
    }
  }

  /**
   * Initialize the defaults valued configured for aggregation
   *
   * @returns void
   */
  protected initializeHotConfiguration(): void {
    let thresholdType: HotConfigurationTypeEnum;

    if (this.creditPositionType === CreditPositionType.CAST) {
      this.aggregationTitle = 'AGGREGATE CAST';
      thresholdType = HotConfigurationTypeEnum.CAST_AGGREGATION_THRESHOLD;
    } else {
      this.aggregationTitle = 'AGGREGATE CREW';
      thresholdType = HotConfigurationTypeEnum.CREW_AGGREGATION_THRESHOLD;
      this.fetchAggregationRoles();
    }

    this.creditsAggregateService.fetchHotConfiguration(
      thresholdType,
      (hotConfig: HotConfiguration) => {
        this.aggregateFrequency = +hotConfig.value;
        this.defaultLoaded = true;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Error loading the default threshold.', error);
        this.defaultLoaded = true;
      }
    );
  }

  /**
   * Resets the hot config values
   *
   * @returns void
   */
  protected resetHotConfig(): void {
    this.aggregateFrequency = undefined;
    this.aggregateRolesId = undefined;
    this.defaultLoaded = false;
  }

  /**
   * Fetch the default roles for aggregation and its names
   *
   * @returns void
   */
  protected fetchAggregationRoles(): void {
    this.creditsAggregateService.fetchHotConfiguration(
      HotConfigurationTypeEnum.CREW_AGGREGATION_ROLES,
      (hotConfig: HotConfiguration) => {
        this.aggregateRolesId = hotConfig.value.split(',').map(Number);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Error fetching the default configured roles to add.', error);
      }
    );
  }

  /**
   * Validates if the roles are valid for the current credit position type
   *
   * @returns boolean
   */
  protected hasValidRoles(): boolean {
    const isValid = (
      this.creditPositionType === CreditPositionType.CAST ||
      this.aggregateRolesId && this.aggregateRolesId.length > 0
    );
    return isValid;
  }

  /**
   * Fetch the list of reasons to block the talent
   *
   * @returns void
   */
  protected fetchBlockReasons(): void {
    this.creditsAggregateService.fetchHotConfiguration(
      HotConfigurationTypeEnum.LOCK_REASON,
      (hotConfig: HotConfiguration) => {
        this.blockReasons = hotConfig.value.split(',');
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Error fetching the block reasons.', error);
      }
    );
  }

  /**
   * Checks if the Locking status can display its lock reason
   *
   * @returns boolean
   */
  protected hasLockReason(): boolean {
    const hasIt =
      this.creditLockingStatus &&
      this.creditLockingStatus.locked &&
      !_isEmpty(this.creditLockingStatus.lockReason);

    return hasIt;
  }

  /**
   * Closes the modal.
   *
   * @returns void
   */
  protected closeModal(): void {
    this.showModal = false;
    this.newCharacterManager.setCharacter(undefined);
  }

  /**
   * Indicates if it has to open the modal.
   *
   * @returns boolean
   */
  protected hasDisplayModal(): boolean {
    return this.showModal;
  }

  /**
   * Sets the isTalentLoading flag on
   *
   * @returns void
   */
  protected talentStartLoading(): void {
    this.isTalentLoading = true;
  }

  /**
   * Sets the isTalentLoading flag off
   *
   * @returns void
   */
  protected talentStopLoading(): void {
    this.isTalentLoading = false;
  }

  /**
   * Indicates if it is creating an original character.
   *
   * @returns boolean
   */
  protected isCreatingOriginalCharacter(): boolean {
    const isIt: boolean = !this.newCharacterManager.hasCharacter();
    return isIt;
  }

  /**
   * Indicates if the current credit type is Dubbing
   *
   * @returns boolean
   */
  protected isDubbingCredit(): boolean {
    const isIt: boolean = this.creditType === CreditType.DUBBING;
    return isIt;
  }

  /**
   * Indicates if the current credit type is Original
   *
   * @returns boolean
   */
  protected isOriginalCredit(): boolean {
    const isIt: boolean = this.creditType === CreditType.ORIGINAL;
    return isIt;
  }

  /**
   * Indicates if the current credit type tab is Dubbing
   *
   * @returns boolean
   */
  protected isDubbingTab(): boolean {
    const isIt: boolean = this.creditTypeTab === CreditType.DUBBING;
    return isIt;
  }

  /**
   * Toggles the wizard visibility.
   *
   * @returns void
   */
  protected toggleWizardVisibility(): void {
    this.showCreditsWizard = !this.showCreditsWizard;
  }

  /**
   * Update the show value when the wizard closes.
   *
   * @returns void
   */
  protected onCloseWizard(): void {
    this.showCreditsWizard = false;
  }

  /**
   * Indicates if it has to display the new button.
   *
   * @returns boolean
   */
  protected hasDisplayBulkAdd(): boolean {
    if (this.isOriginalCredit()) {
      return true;
    } else if (this.isDubbingCredit()) {
      return this.appConfigurationManager.getToggleValue(ToggleKeyEnum.creditsDubbingBulkEntryWizardOn);
    }
  }

  /**
   * Returns the seconds for the countdown button.
   *
   * @returns number
   */
  protected getCountdownSeconds(): number {
    if (this.showWarningMessage()) {
      if (this.isDoubleChecked) {
        return 3;
      } else {
        return -1;
      }
    } else {
      return 0;
    }
  }

  /**
   * Initializes the credit checks
   *
   * @return void
   */
  protected initializeChecks(): void {

    this.credits.forEach(
      (credit: Credit) => {
        const creditId: number = credit.id;
        if (!this.creditChecks.has(creditId)) {
          this.creditChecks.set(creditId, false);
        }
      }
    );
  }

  /**
   * Toggles the main check for bulk deletes.
   *
   * @returns void
   */
  protected toggleMainCheck(): void {
    this.isMainCheckSelected = !this.isMainCheckSelected;
    this.selectionCounter = this.isMainCheckSelected ? this.creditChecks.size : 0;

    this.creditChecks.forEach(
      (value: boolean, key: number) => {
        this.creditChecks.set(key, this.isMainCheckSelected);
      }
    );
  }

  /**
   * Toggles the credit check for the given key.
   *
   * @param key string
   * @returns void
   */
  protected toggleCreditCheck(key: number): void {
    const currentValue: boolean = !this.creditChecks.get(key);

    this.creditChecks.set(key, currentValue);

    if (currentValue) {
      this.isMainCheckSelected = Array.from(this.creditChecks.values()).every((isChecked: boolean) => isChecked);
      this.selectionCounter++;
    } else {
      this.isMainCheckSelected = false;
      this.selectionCounter--;
    }
  }

  /**
   * Reset the selection counter.
   *
   * @return void
   */
  protected resetSelectionCounter(): void {
    this.selectionCounter = 0;
  }

  /**
   * Indicates if it has to disable the remove selection button.
   *
   * @returns void
   */
  protected isAnyCreditCheckSelected(): boolean  {
    return this.selectionCounter !== 0;
  }

  /**
   * Returns an array of the checked credits.
   *
   * @returns Credit[]
   */
  protected getCheckedCredits(): Credit[] {
    return this.credits.filter((credit: Credit) => this.creditChecks.get(credit.id));
  }
}
