import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { Observable, Observer } from 'rxjs';
import { isBoolean as _isBoolean, includes as _includes } from 'lodash';

import { AuthHelper } from '../auth/auth.helper';
import { CapabilitiesManager } from '../../services/role/capabilities.manager';
import { UserManager } from 'app/modules/user/helpers/user-manager.helper';
import { UserService } from 'app/modules/user/services/user.service';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    protected appConfiguration: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected userService: UserService,
    protected userManager: UserManager,
    protected authHelper: AuthHelper,
    protected router: Router
  ) { }

  /**
   * Determines if the attempted route can be accessed or not.
   *
   * @param route ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns Observable<any>
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const obs: Observable<any> = new Observable(
      (observer: Observer<any>) => {
        if (this.authHelper.authenticatedUserStream.getValue()) {
          this.validateUserRoleAccess(observer, (<any>route.data).boltAuthAware, state.url);
          observer.complete();
          return;
        }

        this.userManager.fetchAuthenticatedUser().subscribe(
          (response: any) => {
            this.authHelper.setAuthenticatedUser(response.item);
            this.validateUserRoleAccess(observer, (<any>route.data).boltAuthAware, state.url);
          },
          (error: any) => {
            // Store the attempted URL for redirecting
            if (state.url.indexOf('/auth/') !== 0) {
              this.authHelper.setAttemptedUrl(state.url);
            }

            // Navigate to the login page
            observer.next(false);
            this.router.navigate(['/auth/login']);
          },
          () => {
            observer.complete();
          }
        );
      }
    );

    return obs;
  }

  /**
   * Indicates if the current user can access to the given element.
   *
   * @param element string
   * @returns boolean
   */
   protected canPassAccessControlChecks(element: string): boolean {
    let toggleValue: boolean;

    if (_includes(ToggleKeyEnum, element)) {
      toggleValue = this.appConfiguration.getToggleValue(element);
    }

    // If the given element is a toggle and it's turned off, we reject the access. Otherwise we check the element with the UPC manager.
    if (_isBoolean(toggleValue) && !toggleValue) {
      return false;
    } else {
      const itCan: boolean =
        this.capabilitiesManager.hasUserPrivilegeOn(element, [ActionTypeEnum.read]) ||
        this.capabilitiesManager.hasUserPrivilegeOn(element, [ActionTypeEnum.all]);

      return itCan;
    }
  }

  /**
   * Validates the user role access.
   *
   * @param observer Observer<any>
   * @param elementId string
   * @param attemptedResource string
   * @returns void
   */
  protected validateUserRoleAccess(observer: Observer<any>, elementId: string, attemptedResource: string): void {
    if ((elementId === '*') || this.canPassAccessControlChecks(elementId)) {
      observer.next(true);
    } else {
      observer.next(false);
      this.router.navigate(['/auth/restricted-access', { attemptedResource: attemptedResource }]);
    }
  }
}
