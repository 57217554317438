import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ManagerService } from '../../services/export/manager-service/manager.service';
import { Season } from '../../models/export/request-file-data/item/season/season.model';


@Component({
  selector: 'bolt-profile-export-episodes-list',
  template: require('./bolt-profile-export-episodes-list.html'),
  styles: [require('./bolt-profile-export-episodes-list.scss')]
})
export class BoltProfileExportEpisodesListComponent implements OnDestroy {
  @Input() season: Season;

  protected episodesSubscription: Subscription;

  constructor(protected managerService: ManagerService) {
    this.initialize();
  }

  ngOnDestroy() {
    if (this.episodesSubscription) {
      this.episodesSubscription.unsubscribe();
    }
  }

  /**
   * Updates the status of the selected episode to be deleted.
   *
   * @param title any
   * @param episode any
   * @returns void
   */
  onUpdateDeletableSelection(title: any, episode: any): void {
    this.managerService.updateSelectionToRemoveFromExportableEpisodesByTitle(title, episode);
  }

  /**
   * Initializes the Component.
   *
   * @returns void
   */
  protected initialize(): void {
    this.season = undefined;
  }

  /**
   * Indicates if the given episode is selected.
   *
   * @param title any
   * @param episode any
   * @returns boolean
   */
  protected isChecked(title: any, episode: any): boolean {
    return this.managerService.isEpisodeInListToRemove(title, episode);
  }
}
