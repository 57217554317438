import { Component, Input, OnChanges, ChangeDetectorRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { find as _find, isObject as _isObject } from 'lodash';

import { TitleMetadataInterface } from '../../models/title-metadata.model';
import { TitleInterface, Title, TitleType } from '../../models/title.model';
import { FeatureMetadata } from '../../models/feature-metadata.model';
import { SeriesMetadata } from '../../models/series-metadata.model';
import { SeasonMetadata } from '../../models/season-metadata.model';
import { EpisodeMetadata } from '../../models/episode-metadata.model';
import { CollectionManagerCollectionInterface } from 'app/modules/common/helpers/collection-manager.helper';
import { BoltTitleMetadataFiltersInterface } from '../bolt-title-metadata-filters/bolt-title-metadata-filters.component';

@Component({
  selector: 'bolt-title-metadata',
  template: require('./bolt-title-metadata.html'),
  styles: [require('./bolt-title-metadata.scss')]
})
export class BoltTitleMetadataComponent implements OnChanges {
  selectedMetadata: TitleMetadataInterface;

  @Input('BoltTitleMetadataTitle') title: TitleInterface = new Title();
  @Output('LocalizationChanged') localizationChangeEvent: EventEmitter<any> = new EventEmitter();

  protected filters: BoltTitleMetadataFiltersInterface;
  protected revealLocale: string;
  protected titleTypes = TitleType;
  protected localizationsUpdate: boolean = false;
  protected metadataCollection: CollectionManagerCollectionInterface;

  constructor(
    protected changeDetector: ChangeDetectorRef
  ) { }

  setSelectedMetadata(titleMetadata: any) {
    if (
      titleMetadata instanceof FeatureMetadata ||
      titleMetadata instanceof SeriesMetadata ||
      titleMetadata instanceof SeasonMetadata ||
      titleMetadata instanceof EpisodeMetadata
    ) {
      this.selectedMetadata = titleMetadata;
    } else {
      this.selectedMetadata = undefined;
      this.forceUpdateFromLocalizationChange();
    }

    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title && changes.title.currentValue && this.selectedMetadata) {
      this.setSelectedMetadata(_find(this.title.localizations, { id: this.selectedMetadata.id }));
    }
  }

  /**
   * Indicates if it has a selected metadata.
   *
   * @returns boolean
   */
  protected hasSelectedMetadata(): boolean {
    return _isObject(this.selectedMetadata);
  }

  /**
   * Indicates if it has to display the episode list of the current season.
   *
   * @returns boolean
   */
  protected hasDisplaySeasonEpisodeList(): boolean {
    const hasIt: boolean = this.title.isEpisode() || this.title.isSeason();
    return hasIt;
  }

  /**
   * Retrieves the current episode id.
   *
   * @returns number
   */
  protected retrieveEpisodeId(): number {
    if (this.title.isEpisode()) {
      return this.title.id;
    }
  }

  /**
   * Retrieves the season id.
   *
   * @returns number
   */
  protected retrieveSeasonId(): number {
    if (this.title.isSeason()) {
      return this.title.id;
    } else if (this.title.isEpisode()) {
      return (<EpisodeMetadata>this.title).seasonId;
    }
  }

  /**
   * Set the created localization as the selected one.
   *
   * @param localization Title
   * @returns void
   */
  protected selectNewLocalization(localization: Title): void {
    this.selectedMetadata = localization;
    this.forceUpdateFromLocalizationChange();
  }

  /**
   * Forces an update in the current localization.
   *
   * @returns void
   */
  protected forceUpdateFromLocalizationChange(): void {
    this.localizationsUpdate = true;
    this.localizationChangeEvent.emit();
  }
}
