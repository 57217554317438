import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'bolt-aside-content',
  template: require('./bolt-aside-content.html'),
  styles: [require('./bolt-aside-content.scss')]
})
export class BoltAsideContentComponent {
  @Input('show')
  show: boolean = false;

  @Output('toggle')
  toggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Show/Hide the Aside Content panel and emits the event.
   *
   * @return void
   */
  toggle(): void {
    this.show = !this.show;
    this.toggleEvent.emit(this.show);
  }
}
