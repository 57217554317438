import { Component, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Configuration } from '@bolt/ui-shared/configuration';
import { isObject as _isObject } from 'lodash';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { AppConfigurationService } from '../../service/app-configuration/app-configuration.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';

@Component({
  selector: 'bolt-app-configuration-edit-side-panel',
  template: require('./bolt-app-configuration-edit-side-panel.html'),
  styles: [require('./bolt-app-configuration-edit-side-panel.scss')]
})
export class BoltAppConfigurationEditSidePanelComponent extends StormComponent implements OnChanges {
  @Output('closed') closeEvent: EventEmitter<undefined>;
  @Output('saved') saveEvent: EventEmitter<Configuration>;
  @Input() configuration: Configuration;
  @Input() show: boolean;

  protected form: FormGroup;

  constructor(
    protected appConfigurationService: AppConfigurationService,
    protected notificationService: NotificationService,
  ) {
    super();
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.show) && changes.show.currentValue) {
      this.createForm();
    }
  }

  /**
   * Closes the edition side panel.
   *
   * @returns void
   */
  protected close(): void {
    this.show = false;
    this.configuration = undefined;
    this.closeEvent.emit();
  }

  /**
   * Creates the form with the current configuration values.
   *
   * @returns void
   */
  protected createForm(): void {
    const control: any = {
      value: new FormControl(
        this.configuration.value,
        [
          Validators.required,
          Validators.maxLength(255)
        ]
      )
    };

    this.form = new FormGroup(control);
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.isUpdatingAction();
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    return this.isUpdatingAction() || this.form.invalid;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.closeEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.show = false;
  }

  /**
   * Save the current configuration.
   *
   * @returns void
   */
  protected save(): void {
    this.changeActionToUpdating();

    this.appConfigurationService.updateConfiguration(
      this.configuration,
      this.form.get('value').value.toString(),
      (response: Configuration) => {
        this.notificationService.handleNotice('The configuration was successfully edited.');
        this.saveEvent.emit(response);
        this.close();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to edit the configuration', error);
      },
      () => {
        this.changeActionToShowing();
      }
    );
  }

  /**
   * Toggles the configuration value.
   *
   * @returns void
   */
  protected toggleBooleanConfiguration(): void {
    this.form.get('value').setValue(!this.form.get('value').value);
  }
}
