import { Component } from '@angular/core';
import { AuthHelper } from 'app/modules/auth/helpers/auth/auth.helper';


@Component({
  selector: 'bolt-footer',
  template: require('./bolt-footer.html'),
  styles: [require('./bolt-footer.scss')]
})
export class BoltFooterComponent {
  protected supportPageUrl: string;

  constructor(protected authHelper: AuthHelper) {
    this.supportPageUrl = this.authHelper.getSupportPageUrl();
  }
}
