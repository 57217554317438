import { Component, Input } from '@angular/core';
import { isUndefined as _isUndefined } from 'lodash';
import { AccordionItem } from '@bolt/ui-shared/common';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';


import { TitleListSearchResultInterface } from '../../models/title-list-search-result.model';
import { TitleType } from 'app/modules/title/models/title.model';


@Component({
  selector: 'bolt-title-list-search-result',
  template: require('./bolt-title-list-search-result.html'),
  styles: [require('./bolt-title-list-search-result.scss')],
})
export class BoltTitleListSearchResultComponent  {
  @Input('BoltTitleListSearchResultTitle') title: TitleListSearchResultInterface;
  @Input('BoltTitleListSearchResultQuery') query: string;

  readonly limit: number = 5;

  protected titleTypes = TitleType;

  constructor(
    protected appConfigurationManager: AppConfigurationManager
  ) { }

  /**
   * Retrieves the accordion items list from the stored title.
   *
   * @returns AccordionItems[]
   */
  getAccordionItems(): AccordionItem[] {
    const list: AccordionItem[] = [];

    if (this.title.hasAka()) {
      this.title.aka.forEach(
        (item: string) => {
         list.push(new AccordionItem(item, 'aka'));
        }
      );
    }

    if (this.title.hasFka()) {
      this.title.fka.forEach(
        (item: string) => {
         list.push(new AccordionItem(item, 'fka'));
        }
      );
    }

    return list;
  }

  showLegalTitle(): boolean {
    return this.title.title &&
      this.title.originalTitle &&
      this.title.title.toLowerCase() !== this.title.originalTitle.toLowerCase();
  }
}

