import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { UserRole } from '@bolt/ui-shared/auth';
import { Privilege } from '@bolt/ui-shared/configuration';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';


@Injectable()
export class UpcService extends BoltAbstractService {

  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Updates a privilege with the given data.
   *
   * @param privilege Privilege
   * @param roles string[]
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  updateUserPrivilege(
    privilege: Privilege,
    roles: string[],
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const url: string = this.generateUrl('appConfigurationManager.roleCapability.endpoint');

    const body: any = {
      name: privilege.name,
      description: privilege.description,
      value: roles
    };

    const sub: Subscription = this.doPutRequest(
      { url: url + `/${privilege.id}`, body: body },
      (successResponse: StormServiceResponseSingle) => {
        try {
          successResponse.item.roles = successResponse.item.value.map(
            (element: string) => new UserRole({ name: element })
          );

          const mappedRes: Privilege = new Privilege(successResponse.item);
          onSuccessDo(mappedRes);
        } catch (error) {
          onErrorDo(this.createInvalidResponseError());
        }
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return sub;
  }
}
