import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BoltCommonModule } from '../common/common.module';

import { BoltSubtitleExportComponent } from './components/bolt-subtitle-export/bolt-subtitle-export.component';
import { BoltSubtitleComponent } from './components/bolt-subtitle/bolt-subtitle.component';
import { BoltSubtitleLocalizationsComponent } from './components/bolt-subtitle-localizations/bolt-subtitle-localizations.component';
import { BoltSubtitleLocalizedDetailsComponent } from './components/bolt-subtitle-localized-details/bolt-subtitle-localized-details.component';
import { BoltSubtitleLocalizedPanelComponent } from './components/bolt-subtitle-localized-panel/bolt-subtitle-localized-panel.component';
import { BoltSubtitleOriginalDetailsComponent } from './components/bolt-subtitle-original-details/bolt-subtitle-original-details.component';
import { BoltSubtitleOriginalPanelComponent } from './components/bolt-subtitle-original-panel/bolt-subtitle-original-panel.component';
import { HeightSynchronizerService } from './services/height-synchronizer/height-synchronizer.service';
import { ManagerService } from './services/manager/manager.service';
import { SubtitleService } from './services/subtitle/subtitle.service';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BoltCommonModule
  ],
  declarations: [
    BoltSubtitleComponent,
    BoltSubtitleExportComponent,
    BoltSubtitleLocalizationsComponent,
    BoltSubtitleLocalizedDetailsComponent,
    BoltSubtitleLocalizedPanelComponent,
    BoltSubtitleOriginalDetailsComponent,
    BoltSubtitleOriginalPanelComponent
  ],
  exports: [
    BoltSubtitleComponent,
    BoltSubtitleLocalizedDetailsComponent,
    BoltSubtitleOriginalDetailsComponent
  ],
  providers: [
    HeightSynchronizerService,
    ManagerService,
    SubtitleService
  ]
})
export class SubtitleModule { }
