import { Component } from '@angular/core';
import { ConfigurationTypeEnum } from '@bolt/ui-shared/configuration';

import { ManagerService } from '../../services/export/manager-service/manager.service';
import { Season } from '../../models/export/request-file-data/item/season/season.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


@Component({
  selector: 'bolt-profile-export-titles-list',
  template: require('./bolt-profile-export-titles-list.html'),
  styles: [require('./bolt-profile-export-titles-list.scss')]
})
export class BoltProfileExportTitlesListComponent extends StormComponent {
  constructor(protected managerService: ManagerService) {
    super();
  }

  /**
   * Refreshes pages.
   *
   * @returns void
   */
  onRefreshPages(): void {
    this.managerService.refreshExportableTitles();
  }

  /**
   * Updates the status of the selected title to be deleted.
   *
   * @param title any
   * @returns void
   */
  onUpdateDeletableSelection(title: any): void {
    this.managerService.updateSelectionToRemoveFromExportableTitles(title);
  }

  /**
   * Deletes the selection.
   *
   * @returns void
   */
  onDeleteSelection(): void {
    if (this.managerService.hasExportableTitlesToRemove()) {
      this.managerService.removeSelectionFromExportableTitles();
    }
    if (this.managerService.hasExportableEpisodesTitleToRemove()) {
      this.managerService.removeSelectionFromExportableEpisodes();
    }
  }

  /**
   * Returns the boundary link of pagination.
   *
   * @returns boolean
   */
  protected getPaginationBoundaryLinks(): boolean {
    return this.managerService.getAppConfig().get('ux.pagination.boundaryLinks', undefined, ConfigurationTypeEnum.boolean);
  }

  /**
   * Returns the max size of pagination.
   *
   * @returns number
   */
  protected getPaginationMaxSize(): number {
    return this.managerService.getAppConfig().get('ux.pagination.maxSize');
  }

  /**
   * Returns the rotate of pagination.
   *
   * @returns boolean
   */
  protected getPaginationRotate(): boolean {
    return this.managerService.getAppConfig().get('ux.pagination.rotate');
  }

  /**
   * Indicates if the given title is selected.
   *
   * @param title any
   * @returns boolean
   */
  protected isChecked(title: any): boolean {
    return this.managerService.isTitleInListToRemove(title);
  }

  /**
   * Indicates if the given title is expanded.
   *
   * @param title any
   * @returns boolean
   */
  protected isExpanded(title: any): boolean {
    const isExpanded = this.managerService.isExportableTitleExpanded(title);

    if (isExpanded) {
      if (!this.managerService.isFeaturesSelected()) {
        if (this.managerService.isTitleInListToRemove(title)) {
          this.managerService.selectTitleEpisodesToBeRemoved(
            <Season>title,
            this.managerService.generateTitleIndex(title)
          );
        }
      }
    }

    return isExpanded;
  }

 /**
  * Toggles the expansion for the given title.
  *
  * @param title any
  * @returns void
  */
 protected toggleExpansion(title: any): void {
   this.managerService.toggleExportableTitleExpansion(title);
 }
}
