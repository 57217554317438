import { Component, Input, Output, EventEmitter } from '@angular/core';
import { isString as _isString } from 'lodash';


@Component({
  selector: 'bolt-metadata-attribute-info-message',
  template: require('./bolt-metadata-attribute-info-message.html'),
  styles: [require('./bolt-metadata-attribute-info-message.scss')]
})
export class BoltMetadataAttributeInfoMessageComponent {
  @Input() message: string;
  @Input() light: boolean;
  @Output() mouseoutEmitted: EventEmitter<undefined>;
  @Output() mouseoverEmitted: EventEmitter<undefined>;

  constructor() {
    this.light = false;
    this.mouseoutEmitted = new EventEmitter();
    this.mouseoverEmitted = new EventEmitter();
  }

  /**
   * Emits the mouse out event.
   *
   * @returns void
   */
  protected emitMouseOut(): void {
    this.mouseoutEmitted.emit();
  }

  /**
   * Emits the mouse over event.
   *
   * @returns void
   */
  protected emitMouseOver(): void {
    this.mouseoverEmitted.emit();
  }

  /**
   * Indicates if it has a message.
   *
   * @returns boolean
   */
  protected hasMessage(): boolean {
    return _isString(this.message) && this.message.length > 0;
  }

  /**
   * Indicates if it has to use the light style for the message.
   *
   * @returns boolean
   */
  protected hasToUseLightStyle(): boolean {
    return this.light;
  }
}
