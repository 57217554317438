import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AbstractManagerService } from '../../services/abstract-manager.service';


@Component({
  selector: 'bolt-profile-templates',
  template: require('./bolt-profile-templates.html'),
  styles: [require('./bolt-profile-templates.scss')]
})
export class BoltProfileTemplatesComponent {
  @Input() disabled: boolean;
  @Input() managerService: AbstractManagerService;

  @Output('changed') change: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    this.initialize();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.disabled = true;
    this.managerService = undefined;
  }

  protected onChangeTemplate(event: any): void {
    const _id: number = Number(event.srcElement.value);

    event.stopPropagation();
    this.change.emit(_id);
  }
}
