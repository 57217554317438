import { SeriesMetadataInterface } from './series-metadata.model';
import { TitleInterface, Title, TitleType } from './title.model';
import { SeriesAttributes } from './title/series/series-attributes.enum';
import { TitleAttributes } from './title/title-attributes.enum';


/**
 * @deprecated 2021-07-12: Use `Series` instead of this interface.
 */
export interface SeriesInterface extends TitleInterface {
  countryOfOriginId?: number;
  mpmFamilyId?: string;
  originalSpokenLocale?: string;
  productionCompanyId?: number;
  productionCompanyIds?: number[];
  radarGroupId?: number;
  legalTitle?: string;
  videoType?: string;
  shortSynopsis?: string;
  mediumSynopsis?: string;
  fullSynopsis?: string;
}

/**
 * @todo We have to re-implement the whole `Title` hierarchy class in order to not use Typescript set/get with underscore attribute. But
 * because this is part of the Bolt's core model, we need a tech debt story for doing this and move it to bolt-ui-shared project.
 * @see `SeriesAttributes`: Ensure that enumerative and this class are synchronized about public attributes.
 */
export class Series extends Title implements SeriesInterface {
  protected _countryOfOriginId: number;
  protected _mpmFamilyId: string;
  protected _originalSpokenLocale: string;
  protected _productionCompanyId: number;
  protected _productionCompanyIds: number[];
  protected _radarGroupId: number;
  protected _legalTitle: string;
  protected _videoType: string;
  protected _shortSynopsis: string;
  protected _mediumSynopsis: string;
  protected _fullSynopsis: string;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
    this._type = TitleType.series;
  }

  /**
   * Returns the attributes names of a Series.
   *
   * @returns string[]
   */
  static getAttributesNames(): string[] {
    const attributes: string[] = [
      ...Object.keys(TitleAttributes),
      ...Object.keys(SeriesAttributes)
    ];

    return attributes;
  }

  get countryOfOriginId(): number {
    return this._countryOfOriginId;
  }

  set countryOfOriginId(countryOfOriginId: number) {
    this._countryOfOriginId = countryOfOriginId;
  }

  get mpmFamilyId(): string {
    return this._mpmFamilyId;
  }

  set mpmFamilyId(mpmFamilyId: string) {
    this._mpmFamilyId = mpmFamilyId;
  }

  get originalSpokenLocale(): string {
    return this._originalSpokenLocale;
  }

  set originalSpokenLocale(originalSpokenLocale: string) {
    this._originalSpokenLocale = originalSpokenLocale;
  }

  get productionCompanyId(): number {
    return this._productionCompanyId;
  }

  set productionCompanyId(productionCompanyId: number) {
    this._productionCompanyId = productionCompanyId;
  }

  get productionCompanyIds(): number[] {
    return this._productionCompanyIds;
  }

  set productionCompanyIds(productionCompanyIds: number[]) {
    this._productionCompanyIds = productionCompanyIds;
  }

  get radarGroupId(): number {
    return this._radarGroupId;
  }

  set radarGroupId(radarGroupId: number) {
    this._radarGroupId = radarGroupId;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  set legalTitle(legalTitle: string) {
    this._legalTitle = legalTitle;
  }

  get videoType(): string {
    return this._videoType;
  }

  set videoType(videoType: string) {
    this._videoType = videoType;
  }

  get shortSynopsis(): string {
    return this._shortSynopsis;
  }

  set shortSynopsis(shortSynopsis: string) {
    this._shortSynopsis = shortSynopsis;
  }

  get mediumSynopsis(): string {
    return this._mediumSynopsis;
  }

  set mediumSynopsis(mediumSynopsis: string) {
    this._mediumSynopsis = mediumSynopsis;
  }

  get fullSynopsis(): string {
    return this._fullSynopsis;
  }

  set fullSynopsis(fullSynopsis: string) {
    this._fullSynopsis = fullSynopsis;
  }

  get localizations(): SeriesMetadataInterface[] {
    return this._localizations;
  }

  set localizations(localizations: SeriesMetadataInterface[]) {
    this._localizations = localizations;
  }

  getRawObject(): object {
    const names = Object.getOwnPropertyNames(Series.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Series.prototype, name);
      return !!result.get;
    });

    const object = new Object();
    getters.forEach(key => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return Object.assign(new Object(), super.getRawObject(), object);
  }
}
