import { FeatureAttributes } from '../../../title/feature/feature-attributes.enum';
import { TitleAttributes } from '../../../title/title-attributes.enum';
import { TitleForm } from '../title-form.model';


export class FeatureForm extends TitleForm {

  constructor(fieldSpecs: any, defaultValues: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
    this.setStatus();
  }

  toJson(): any {
    const data: any = {
      ...super.toJson()
    };

    data[FeatureAttributes.copyright] = this.get(FeatureAttributes.copyright).value;
    data[FeatureAttributes.countryOfOriginId] = this.get(FeatureAttributes.countryOfOriginId).value;
    data[FeatureAttributes.genreId] = this.get(FeatureAttributes.genreId).value;
    data[TitleAttributes.originalSpokenLanguageId] = this.get(TitleAttributes.originalSpokenLanguageId).value;
    data[FeatureAttributes.productionCompanyId] = this.get(FeatureAttributes.productionCompanyId).value;
    data[FeatureAttributes.productionCompanyIds] = this.get(FeatureAttributes.productionCompanyIds).value;

    return data;
  }

  protected setupFields(): void {
    super.setupFields();
    this.setupCopyright();
    this.setupCountryOfOriginId();
    this.setupFunctionalMetadata();
    this.setupFunctionalMetadataSplitFields();
    this.setupGenreId();
    this.setupNotApplicable();
    this.setupOriginalSpokenLanguageId();
    this.setupProductionCompanyId();
    this.setupProductionCompanyIds();
  }
}
