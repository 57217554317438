import { EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';

import { BoltTitleMetadataFiltersInterface } from './bolt-title-metadata-filters/bolt-title-metadata-filters.component';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { Title } from '../models/title.model';


export abstract class BoltTitleMetadataListBaseComponent extends StormComponent implements OnChanges {
  @Input('Title') product: Title = new Title();
  @Input('SelectedMetadata') selectedProductMetadata: Title;
  @Input('LocalizationChanged') localizationChanged: boolean;
  @Input('RevealLocale') revealLocale: string;
  @Input('Filters') filters: BoltTitleMetadataFiltersInterface;

  @Output('OnSelectMetadata')
  onSelectMetadata: EventEmitter<Title> = new EventEmitter<Title>();

  protected pager: MemoryPager;
  protected updateList: boolean;
  protected unsortedRecords: any[];

  constructor(
    protected appConfig: AppConfigProvider
  ) {
    super();
    this.updateList = true;
    this.setupPager();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.updateList || this.localizationChanged) {
      this.localizationChanged = false;
      this.loadList();
    }

    if (changes.selectedProductMetadata) {
      try {
        this.pager.setPageNumberFor(this.selectedProductMetadata, (localeA: Title, localeB: Title) => localeA.id === localeB.id);
      } catch (error) {
        this.pager.setPageNumber(0);
      }
    }
  }

  loadList(): void {
    this.ensureFiltersExists();
    this.applyFilters();
    this.mapLocalizations();
  }

  /**
   * Applies the filters.
   *
   * @returns void
   */
  protected applyFilters(): void {
    if (this.filters.filter.length > 0) {
      this.pager.setFilterCriteria(this.getFilterCriteria());
    } else {
      this.pager.setFilterCriteria();
    }
  }

  /**
   * Change the order of the list
   *
   * @param iterate string
   */
  protected abstract sortBy(iterate: string);

  /**
   * Return filter criteria function
   *
   * @returns CallableFunction
   */
  protected abstract getFilterCriteria(): CallableFunction;

  /**
   * Ensures that filters always exists.
   *
   * @returns void
   */
  protected abstract ensureFiltersExists(): void;

  /**
   * Maps all localizations in the product.
   *
   * @returns void
   */
  protected abstract mapLocalizations(): void;

  /**
   * Set up the memory pager.
   *
   * @returns void
   */
  protected setupPager(): void {
    this.pager = new MemoryPager(
      this.appConfig.get('ux.dataTables.pageSize'),
    );
  }
}
