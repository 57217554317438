import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { HttpError } from '@bolt/ui-shared/common';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Subscription } from 'rxjs';

import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TitleMetadataLockingStatus } from '../../../title/models/title-metadata.model';
import { TitleService } from '../../../title/services/title.service';
import { TitleType } from '../../../title/models/title.model';


@Component({
  selector: 'bolt-metadata-export-lock-indicators',
  template: require('./bolt-metadata-export-lock-indicators.html'),
  styles: [require('./bolt-metadata-export-lock-indicators.scss')],
})
export class BoltMetadataExportLockIndicatorsComponent extends StormComponent implements OnInit, OnDestroy {
  @Input() locale: string;
  @Input() titleId: number;
  @Input() titleType: TitleType;

  readonly errorReason: string = 'Failed loading the title locking status.';
  lockingStatus: TitleMetadataLockingStatus;

  protected subscription: Subscription;

  constructor(
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) {
    super();

    this.subscription = new Subscription();
    this.changeStatusToIdle();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.changeStatusToFetchingData();

    const subs: Subscription = this.titleService.fetchTitleLockingStatusByLocale(this.titleType, this.titleId, this.locale).subscribe(
      (lockingStatus: TitleMetadataLockingStatus) => {
        this.lockingStatus = lockingStatus;
        this.changeStatusToDataFound();
      },
      (error: HttpError) => {
        this.notificationService.handleError(this.errorReason, error);
        this.changeStatusToError();
      }
    );

    this.subscription.add(subs);
  }
}
