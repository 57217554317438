import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { isObject as _isObject } from 'lodash';

import { ManagerService } from '../../services/manager/manager.service';
import { SubtitleType } from '../../models/subtitle/details/subtitle-type/subtitle-type.model';
import { SubtitleTypeEnum } from '../../models/subtitle/details/subtitle-type/subtitle-type.enum';
import { Title } from 'app/modules/title/models/title.model';


@Component({
  selector: 'bolt-subtitle',
  template: require('./bolt-subtitle.html'),
  styles: [require('./bolt-subtitle.scss')],
})
export class BoltSubtitleComponent implements OnInit, OnDestroy {
  @Input() title: Title;

  protected subtitleType: SubtitleType;

  constructor(protected managerService: ManagerService) {
    this.title = undefined;
    this.updateSelectType(new SubtitleType(SubtitleTypeEnum.all));
  }

  ngOnDestroy() {
    // DO NOT call managerService.resetAll() here due to it affects Credits due too it's using the deprecated CollectionManagerHelper.
    this.managerService.resetNotifications();
    this.managerService.resetSelectedLocalization();
    this.managerService.turnOffAnyVersionOnEdition();
  }

  ngOnInit() {
    this.managerService.title = this.title;
  }

  /**
   * Updates the selected subtitle type.
   *
   * @param type SubtitleType
   * @returns void
   */
   updateSelectType(type: SubtitleType): void {
    this.subtitleType = type;
  }
}
