import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { FunctionalMetadataFormHandlerService, FunctionalMetadataService } from '@bolt/ui-shared/title';
import { isObject as _isObject } from 'lodash';

import { ActionHandlerService } from '../../services/level-data/action-handler/action-handler.service';
import { BoltTitleLevelDataBaseFormComponent } from '../bolt-title-level-data-base-form/bolt-title-level-data-base-form.component';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { TitleService } from '../../services/title.service';
import { Series } from '../../models/series.model';
import { SeasonForm } from '../../models/level-data/form/season/season-form.model';
import { Season } from '../../models/season.model';
import { FormHandlerService } from '../../services/level-data/form-handler/form-handler.service';
import { TitleType } from '../../models/title.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Subscription } from 'rxjs';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


@Component({
  selector: 'bolt-title-level-data-season-form',
  template: require('./bolt-title-level-data-season-form.html'),
  styles: [require('./bolt-title-level-data-season-form.scss')]
})
export class BoltTitleLevelDataSeasonFormComponent extends BoltTitleLevelDataBaseFormComponent implements OnInit, OnDestroy {
  @Input() title: Season;

  protected form: SeasonForm;
  protected parentSeries: Series;
  protected parentSeriesLoading: boolean;

  constructor(
    protected actionHandler: ActionHandlerService,
    protected appConfigurationManager: AppConfigurationManager,
    protected formHandler: FormHandlerService,
    protected capabilitiesManager: CapabilitiesManager,
    protected functionalMetadataService: FunctionalMetadataService,
    protected functionalMetadataFormHandler: FunctionalMetadataFormHandlerService,
    protected masterDataManager: MasterDataManager,
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) {
    super(
      actionHandler,
      appConfigurationManager,
      capabilitiesManager,
      formHandler,
      functionalMetadataService,
      functionalMetadataFormHandler,
      masterDataManager,
      notificationService,
      titleService
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
    this.formHandler.createSeasonForm(this.title);
  }

  protected loadParents(): void {
    this.parentSeriesLoading = true;

    const subs: Subscription = this.titleService.fetchProduct({
      productId: this.title.seriesId,
      productType: TitleType.series
    }).subscribe(
      (serviceResponseSingle: StormServiceResponseSingle) => {
        this.parentSeries = serviceResponseSingle.item;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to retrieve the parent Series', error);
      },
      () => {
        this.parentSeriesLoading = false;
      }
    );

    this.subscriptions.add(subs);
  }

  protected hasParentData(): boolean {
    return _isObject(this.parentSeries);
  }

  protected loadDependencies(): void {
    this.loadGenres();
    this.loadLanguages();
    this.loadTerritories();
    this.loadParents();
  }
}
