import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AppConfigProvider, ActionTypeEnum } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isObject as _isObject } from 'lodash';
import { Subscription } from 'rxjs';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ItemService } from '../../services/item/item.service';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { Original } from '../../models/item/original/original.model';
import { Product } from '../../models/product/product.model';
import { RoleCapabilitiesHandler } from '../../services/role-capabilities-handler/role-capabilities-handler';
import { SearchCriteria } from 'app/modules/search/models/search-criteria/search-criteria.model';
import { SearchManager } from 'app/shared/models/search-response/search-manager/search-manager';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { SearchService as ConsolidatedSearchService } from 'app/modules/search/services/search.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TypeEnum } from 'app/modules/search/models/type/type.enum';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { Type } from '../../models/type/type.model';


@Component({
  selector: 'bolt-cat-item-associations',
  template: require('./bolt-cat-item-associations.html'),
  styles: [require('./bolt-cat-item-associations.scss')]
})
export class BoltCatItemAssociationsComponent extends StormComponent implements OnInit, OnDestroy {
  @Input() item: Original;

  protected readonly titleEnum: string = 'title';

  protected confirmDetachModal: NgbModalRef;
  protected editionModeFlag: boolean;
  protected fetchingAssociationsListener: Subscription;
  protected pager: MemoryPager;
  protected scrollLoading: boolean;
  protected searchManager: SearchManager;
  protected stateConfirmDialog: boolean;
  protected searchingAssociationsListener: Subscription;
  protected suggestionsCriteria: SearchCriteria;
  protected suggestionsLoading: boolean;

  constructor(
    protected appConfig: AppConfigProvider,
    protected itemService: ItemService,
    protected notificationService: NotificationService,
    protected modalService: NgbModal,
    protected roleCapabilitiesHandler: RoleCapabilitiesHandler,
    protected searchService: ConsolidatedSearchService
  ) {
    super();
    this.initialize();
  }

  ngOnDestroy() {
    this.unsubscribeFetchingAssociations();
    this.unsubscribeSearchingAssociations();
  }

  ngOnInit() {
    if (this.hasDisplay()) {
      this.fetchAssociations();
    }
  }

  /**
   * Attaches the given selection.
   *
   * @param selection Product
   * @returns void
   */
  protected attachProduct(selection: Product): void {
    this.changeStatusToFetchingData();

    let data: any;

    this.itemService.attachProduct(
      selection.type.value,
      selection.id,
      this.item.type.value,
      this.item.id,
      (response: StormServiceResponseSingle) => {
        data = {
          id: response.item.productId
        };

        this.notificationService.handleSuccess(
          'The product was associated successfully.',
          undefined,
          this.retrieveNotificationsContainer()
        );
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed trying to associate the product.', error, this.retrieveNotificationsContainer());
      },
      () => {
        this.fetchAssociations(
          () => {
            try {
              this.pager.setPageNumberFor(data, this.getComparisonCriteria());
            } catch (error) {
              this.pager.setPageNumber(0);
            }
          }
        );
      }
    );
  }

  /**
   * Detaches the given selection.
   *
   * @param selection Product
   * @returns void
   */
  protected detachAssociation(selection: Product): void {
    this.disableConfirmDialog();
    this.changeStatusToFetchingData();

    this.itemService.detachProduct(
      selection.type.value,
      selection.id,
      this.item.type.value,
      this.item.id,
      () => {
        this.notificationService.handleSuccess(
          'The association was removed successfully',
          undefined,
          this.retrieveNotificationsContainer()
        );
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'There was an error trying to remove the association.',
          error,
          this.retrieveNotificationsContainer()
        );
      },
      () => {
        this.confirmDetachModal.close();

        const oldPager: number = this.pager.pageNumber;

        this.fetchAssociations(
          () => {
            try {
              this.pager.setPageNumber(oldPager);
            } catch (error) {
              this.pager.setPageNumber(0);
            }
          }
        );
      }
    );
  }

  /**
   * Disables the confirm dialog setting its state to false.
   *
   * @return void
   */
  protected disableConfirmDialog(): void {
    this.stateConfirmDialog = false;
  }

  /**
   * Enables the confirm dialog setting its state to true.
   *
   * @return void
   */
  protected enableConfirmDialog(): void {
    this.stateConfirmDialog = true;
  }

  /**
   * Fetches the associations and set the correct page for the given product.
   *
   * @param onSetRecordsDo CallableFunction
   * @returns void
   */
  protected fetchAssociations(onSetRecordsDo?: CallableFunction): void {
    this.changeStatusToFetchingData();
    this.unsubscribeFetchingAssociations();

    this.fetchingAssociationsListener = this.itemService.fetchProductAssociations(
      this.item.type.value,
      this.item.id,
      (products: Product[]) => {
        this.pager.setRecords(products);

        if (onSetRecordsDo) {
          onSetRecordsDo();
        }

        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed retrieving the product associations.', error, this.retrieveNotificationsContainer());
        this.changeStatusToError();
      }
    );
  }

  /**
   * Returns the associations.
   *
   * @returns Product[]
   */
  protected getAssociations(): Product[] {
    return this.pager.getCurrentPage();
  }

  /**
   * Returns the comparison criteria for products.
   *
   * @returns CallableFunction
   */
  protected getComparisonCriteria(): CallableFunction {
    const criteria: CallableFunction = (productA: Product, productB: Product) => {
      return productA.id === productB.id;
    };

    return criteria;
  }

  /**
   * Returns the search suggestions.
   *
   * @returns Product[]
   */
  protected getSearchSuggestions(): Product[] {
    return this.searchManager.suggestions;
  }

  /**
   * Returns the sorting criteria for associations.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (productA: Product, productB: Product) => {
      const nameA: string = productA.name.trim().toLowerCase();
      const nameB: string = productB.name.trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Indicates if it has associations.
   *
   * @returns boolean
   */
  protected hasAssociations(): boolean {
    return this.pager.hasRecords();
  }

  /**
   * Indicates if it has to display it.
   *
   * @returns boolean
   */
  protected hasDisplay(): boolean {
    return this.roleCapabilitiesHandler.hasPrivilegeOnProductAssociations(
      this.item.type.value,
      ActionTypeEnum.read
    );
  }

  /**
   * Indicates if it has to display the action section.
   *
   * @returns boolean
   */
  protected hasDisplayActions(): boolean {
    const hasIt: boolean = (
      this.roleCapabilitiesHandler.hasPrivilegeOnProductAssociations(
        this.item.type.value,
        ActionTypeEnum.write
      ) &&
      this.isDataFound()
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display the trash button.
   *
   * @returns boolean
   */
  protected hasDisplayRemoveButton(): boolean {
    return this.isEditionOn();
  }

  /**
   * Indicates if it has to display the search.
   *
   * @returns boolean
   */
  protected hasDisplaySearch(): boolean {
    return this.isEditionOn();
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.editionModeFlag = false;
    this.item = undefined;
    this.scrollLoading = false;
    this.searchManager = new SearchManager();
    this.suggestionsLoading = false;

    this.pager = new MemoryPager(
      this.appConfig.get('ux.page.cat.attachedPageSize'),
      this.getSortingCriteria()
    );

    this.changeStatusToIdle();
    this.setupSuggestionsCriteria();
  }

  /**
   * Indicates if the edition is ON.
   *
   * @returns boolean
   */
  protected isEditionOn(): boolean {
    return this.editionModeFlag;
  }

  /**
   * Loads the next page for the current query
   *
   * @returns void
   */
  protected loadNextSearchingPage(): void {
    if (!this.scrollLoading) {
      this.retrieveSearchSuggestions(this.searchManager.sanitizedQuery, false);
    }
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Allows to show the modal of detaching confirmation and handles the user answer.
   *
   * @param content Reference to the modal
   * @return void
   */
  protected openConfirmModal(content: any): void {
    this.enableConfirmDialog();

    this.confirmDetachModal = this.modalService.open(content);
  }

  /**
   * Retrieves the notification container for the current item type.
   *
   * @returns string
   */
  protected retrieveNotificationsContainer(): string {
    const type: Type = this.item.type;

    if (type.isCharacter()) {
      return notificationsContainer.character.details.key;
    } else if (type.isSubproduct() || type.isTerm()) {
      return notificationsContainer.cat.details.key;
    }
  }

  /**
   * Retrieves all suggestions for the product search.
   *
   * @param query string
   * @param shouldResetSearch boolean
   * @returns void
   */
  protected retrieveSearchSuggestions(query: string, shouldResetSearch: boolean): void {
    this.unsubscribeSearchingAssociations();

    this.scrollLoading = true;

    if (shouldResetSearch) {
      this.suggestionsLoading = true;
      this.searchManager.sanitizedQuery = query.trim();
    }

    this.suggestionsCriteria.setPageNumber(this.searchManager.currentPage - 1);
    this.suggestionsCriteria.setQuery(this.searchManager.sanitizedQuery);

    this.searchingAssociationsListener = this.searchService.fetch(
      this.suggestionsCriteria,
      (response: SearchResponse) => {
        this.searchManager.response = response;
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError(
          'Failed trying to retrieve the item suggestions.',
          error,
          this.retrieveNotificationsContainer()
        );
      },
      () => {
        this.scrollLoading = false;
        this.suggestionsLoading = false;
      }
    );
  }

  /**
   * Set up the suggestions criteria.
   *
   * @returns void
   */
  protected setupSuggestionsCriteria(): void {
    const type: string = `${this.titleEnum},${TypeEnum.Subproduct}`;

    this.suggestionsCriteria = new SearchCriteria();
    this.suggestionsCriteria.setPageSize(this.appConfig.get('ux.page.cat.searchPageSize'));
    this.suggestionsCriteria.setType(type);
  }

  /**
   * Toggles the current value of editionModeFlag.
   *
   * @returns void
   */
  protected toggleEditMode(): void {
    this.editionModeFlag = !this.editionModeFlag;
  }

  /**
   * Unsubscribes from fetching associations, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeFetchingAssociations(): void {
    if (_isObject(this.fetchingAssociationsListener)) {
      this.fetchingAssociationsListener.unsubscribe();
    }
  }

  /**
   * Unsubscribes from searching associations, if it exists a subscription for.
   *
   * @returns void
   */
  protected unsubscribeSearchingAssociations(): void {
    if (_isObject(this.searchingAssociationsListener)) {
      this.searchingAssociationsListener.unsubscribe();
    }
  }
}
