import { Component, Input, Output, OnChanges, EventEmitter, ViewChild, SimpleChanges, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { Account, Country, Language, ProductType, StormListItemInterface, StormListsInterface, StormListType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { groupBy as _groupBy, omit as _omit, pick as _pick, zipObject as _zipObject } from 'lodash';

import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { Xslt, XsltInterface } from '../../models/xslt.model';
import { XsltManagerXsltManagement, XsltManager } from '../../helpers/xslt-manager/xslt-manager.helper';


/**
 * @todo Move this out of this file.
 */
export enum XsltManagerActions {
  EDIT = <any>'EDIT',
  CREATE = <any>'CREATE',
  DELETE = <any>'DELETE',
}

@Component({
  selector: 'bolt-xslt-manager',
  template: require('./bolt-xslt-manager.html'),
})
export class BoltXsltManagerComponent extends StormComponent implements OnChanges, OnInit {
  @ViewChild('preventUnsavedChangesModal') preventUnsavedChangesModal: any;
  @ViewChild('confirmDeleteXsltModal') confirmDeleteXsltModal: any;

  @Input('BoltXsltManagerManager') manager: XsltManagerXsltManagement;
  @Output('BoltXsltManagerOnUpdate') onUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  accounts: SelectionItem[] = [];
  form: FormGroup;
  managerActions = XsltManagerActions;
  languages: SelectionItem[] = [];
  productTypes: SelectionItem[] = [];
  show: boolean = false;
  territories: SelectionItem[] = [];
  xsltProperties: { [propName: string]: any } = { };
  xsltUploader: FileUploader;
  dynamicProperties: string[];
  dynamicPropertiesLoaded: boolean = false;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formBuilder: FormBuilder,
    protected managerHelper: XsltManager,
    protected notificationService: NotificationService,
    protected listsProvider: StormListsProvider,
    protected modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    this.toggleManager(false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.manager &&
      (changes.manager.currentValue !== undefined)
    ) {

      switch (this.manager.action) {
        case XsltManagerActions.CREATE:
        case XsltManagerActions.EDIT:
          this.xsltUploader = this.managerHelper.setXsltUploader(
            this.manager.xslt,
            this.manager.action
          );

          this.setupForm();
          this.toggleManager(true);

          break;
        case XsltManagerActions.DELETE:
          this.deleteXslt(this.manager.xslt);
          break;
        default:
          break;
      }

    } else {
      this.toggleManager(false);
    }
  }

  isAttributeValueValid(key: string): boolean {
    return !(this.form.controls[key].dirty && !this.form.controls[key].valid);
  }

  /**
   *  Sets the XSLT properties object
   *
   * @param stormLists StormListsInterface
   * @returns void
   */
  setXsltPropertyValues(stormLists: StormListsInterface) {
    const properties = _groupBy(
      stormLists.getList(StormListType.xsltProperty).getRawCollection(),
      'name'
    );

    this.dynamicProperties = Object.keys(properties);

    this.dynamicProperties.forEach(
      (key: string) => {
        this.xsltProperties[key] = {
          options: properties[key].map(
            (xsltProp: any) => {
              const label: string = xsltProp.value;
              return new SelectionItem(label, xsltProp.id);
            }
          ),
          label: key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
        };
      }
    );
  }

  /**
   * Toggles the Manager Form visibility
   *
   * @param show boolean
   * @return void
   */
  toggleManager(show: boolean): void {
    this.show = show;
    if (!show) {
      this.manager = undefined;
    }
  }

  /**
   * Handles the Cancel flow, to toggle off the Form.
   * If unsaved changes are detected, the corresponding modal will be shown.
   *
   * @return void
   */
  cancel(): void {
    if (this.form.touched) {
      this.modalService.open(this.preventUnsavedChangesModal).result.then(
        (result: any) => {
          if (result === 'YES') {
            this.toggleManager(false);
          }
        },
        () => { }
      );
    } else {
      this.toggleManager(false);
    }
  }

  /**
   * Handles the click on Save button
   *
   * @return void
   */
  save(): void {
    this.status = DataStatusEnum.fetchingData;

    const rootXsltProperties = Object.getOwnPropertyNames(Xslt.prototype);

    const xsltConfig = Object.assign(
      { config: _omit(this.form.value, rootXsltProperties) },
      _pick(this.form.value, rootXsltProperties)
    );

    try {
      this.managerHelper.setupXslt(new Xslt(xsltConfig)).subscribe(
        (response: any) => {
          this.onUpdate.emit();
          this.status = DataStatusEnum.idle;

          this.toggleManager(false);

          this.notificationService.handleNotice('XSLT Configuration successfully added');
        },
        (error: any) => {
          this.notificationService.handleError('Error trying to set XSLT Configuration', error.error);
          this.status = DataStatusEnum.idle;
          console.error(error);
        }
      );
    } catch (error) {
      this.notificationService.handleError('Error trying to set XSLT Configuration', error);
      this.status = DataStatusEnum.idle;
      console.error(error);
    }
  }

  /**
   * Removes the given xslt
   *
   * @param xslt XsltInterface
   */
  deleteXslt(xslt: XsltInterface): void {
    this.confirmDeleteXsltModal.xslt = xslt;

    this.modalService.open(this.confirmDeleteXsltModal).result.then(
      (result: any) => {
        if (result === 'YES') {
          this.managerHelper.deleteXslt(xslt).subscribe(
            () => {
              this.onUpdate.emit();

              this.notificationService.handleNotice('XSLT Configuration was removed');
            },
            (error: any) => {
              this.notificationService.handleError('Error trying to remove XSLT Configuration', error);
            }
          );
        }
      },
      () => { }
    );
  }

  /**
   * Indicates if the for is valid
   *
   * @returns boolean
   */
  isFormValid(): boolean {
    const isIt: boolean = (
      this.form.valid &&
      (
        (this.manager.action === XsltManagerActions.CREATE && !!this.xsltUploader.queue.length) ||
        this.manager.action !== XsltManagerActions.CREATE
      )
    );

    return isIt;
  }

  protected setupForm(): void {
    this.listsProvider.getLists().subscribe(
      (stormLists: StormListsInterface) => {
        if (!this.languages.length) {
          stormLists.getList(StormListType.language).collection.forEach(
            (language: StormListItemInterface) => {
              if (language.id !== Language.ALL_ID) {
                this.languages.push(new SelectionItem(language.value.name, language.id));
              }
            }
          );
        }

        if (!this.territories.length) {
          stormLists.getList(StormListType.territory).collection.forEach(
            (territory: StormListItemInterface) => {
              if (territory.id !== Country.ALL_ID) {
                this.territories.push(
                  new SelectionItem(
                    (<Country>territory.value).name,
                    (<Country>territory.value).id,
                    territory.value,
                    (<Country>territory.value).region
                  )
                );
              }
            }
          );
        }

        if (!this.productTypes.length) {
          stormLists.getList(StormListType.productType).collection.forEach(
            (productType: StormListItemInterface) => {
              if (productType.id !== ProductType.ALL_ID) {
                this.productTypes.push(new SelectionItem(productType.value.name, productType.id));
              }
            }
          );
        }

        if (!this.accounts.length) {
          stormLists.getList(StormListType.account).collection.forEach(
            (account: StormListItemInterface) => {
              if (account.id !== Account.ALL_ID) {
                this.accounts.push(new SelectionItem(account.value.name, account.id));
              }
            }
          );
        }

        this.setXsltPropertyValues(stormLists);

        this.setupControls();
      }
    );
  }

  protected setupControls(): void {
    const controlDefinitions: any = {
      id: [
        this.manager.xslt.id ? this.manager.xslt.id : ''
      ],
      templateName: [
        this.manager.xslt.templateName ? this.manager.xslt.templateName : '',
        Validators.required
      ],
      isActive: [
        this.manager.xslt.isActive ? this.manager.xslt.isActive : false,
      ],
      isDefault: [
        this.manager.xslt.isDefault ? this.manager.xslt.isDefault : false,
      ],
      language: [
        this.manager.xslt.config.language ? this.manager.xslt.config.language : [],
      ],
      territory: [
        this.manager.xslt.config.territory ? this.manager.xslt.config.territory : [],
      ],
      productType: [
        this.manager.xslt.config.productType ? this.manager.xslt.config.productType : [],
      ],
      account: [
        this.manager.xslt.config.account ? this.manager.xslt.config.account : [],
      ],
    };

    this.dynamicProperties.forEach(
      (xsltProperty) => {
        controlDefinitions[xsltProperty] = [
          this.manager.xslt.config[xsltProperty] ? this.manager.xslt.config[xsltProperty] : [],
        ];
      }
    );

    this.dynamicPropertiesLoaded = true;

    this.form = this.formBuilder.group(controlDefinitions);
  }
}
