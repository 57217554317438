import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { Talent } from '../../models/talent.model';
import { TalentServiceFetchTalentQueryParams } from '../../services/talent.service';
import { TalentManager } from '../../helpers/talent-manager/talent-manager.helper';
import { TalentMetadataManagerActions } from '../bolt-talent-metadata-manager/bolt-talent-metadata-manager.component';


@Component({
  selector: 'bolt-talent-list-filters',
  template: require('./bolt-talent-list-filters.html')
})

export class BoltTalentListFiltersComponent implements OnInit {
  public form: FormGroup;
  public nameValue: string = '';

  @Output('BoltTalentListFiltersFiltersUpdated')
  filtersUpdated: EventEmitter<AbstractControl> = new EventEmitter<AbstractControl>();

  constructor(
    protected formBuilder: FormBuilder,
    protected activatedRoute: ActivatedRoute,
    protected talentManager: TalentManager
  ) {
    this.form = formBuilder.group({
      q:  [''],
    });
  }

  ngOnInit() {
    this.filtersUpdated.emit(this.form);

    this.activatedRoute
      .params
      .subscribe(params => {
        this.nameValue = params[TalentServiceFetchTalentQueryParams.q.toString()] || '';
        (<FormControl>this.form.get('q')).setValue(decodeURI(this.nameValue), { emitEvent: false });
      });
  }

  /**
   * Starts the add talent process.
   *
   * @return void
   */
  addTalent(): void {
    this.talentManager.manageTalentMetadata(
      undefined,
      TalentMetadataManagerActions.CREATE_TALENT,
      new Talent()
    );
  }
}
