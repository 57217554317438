import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { BoltProjectComponent } from './components/bolt-project/bolt-project.component';
import { BoltProjectContainerComponent } from './components/bolt-project-container/bolt-project-container.component';
import { modulesPath } from '../auth/services/role/modules-path';


export const projectRoutes: Routes = [
  {
    path: 'projects',
    component: BoltProjectComponent,
    canActivate: [AuthGuard],
    data: {
      boltAuthAware: modulesPath.projectDashboard.path,
      title: 'Projects',
    },
    children: [
      {
        path: '',
        component: BoltProjectContainerComponent,
      },
      {
        path: ':id',
        component: BoltProjectContainerComponent
      }
    ]
  }
];

export const projectModuleRouting: ModuleWithProviders = RouterModule.forChild(projectRoutes);
