import { FormControl, Validators, FormGroup, ValidationErrors } from '@angular/forms';
import { cloneDeep as _cloneDeep, isNull as _isNull, isObject as _isObject } from 'lodash';
import { StringHelper } from '@bolt/ui-shared/common';

import { Unit } from '../unit.model';


export class EditionForm extends FormGroup {
  protected attributes: any;
  protected unit: Unit;

  constructor(unit: Unit, attributes: any, shouldAllowOriginal: boolean, shouldAllowLocalized: boolean) {
    super({ });
    this.initialize(unit, attributes, shouldAllowOriginal, shouldAllowLocalized);
  }

  /**
   * Disables the localized lock field.
   *
   * @returns void
   */
  disableLocalizedLockField(): void {
    this.get('_localizedLockingStatus').disable();
  }

  /**
   * Disables the localized metadata fields.
   *
   * @returns void
   */
  disableLocalizedMetadataFields(): void {
    this.get('_useDomestic').disable();
    this.get('_localizedName').disable();
    this.get('_phonetic').disable();
    this.get('_notes').disable();
  }

  /**
   * Disables the Original lock field.
   *
   * @returns void
   */
  disableOriginalLockField(): void {
    this.get('_originalLockingStatus').disable();
  }

  /**
   * Disables the Original metadata fields.
   *
   * @returns void
   */
  disableOriginalMetadataFields(): void {
    this.get('_requiredLocalizations').disable();
  }

  /**
   * Updates the availability of the fields that depends on the domestic-use
   *
   * @returns void
   */
  public updateDomesticUsageDependencies(): void {
    if (this.get('_useDomestic').value) {
      this.get('_localizedName').disable();
      this.get('_phonetic').disable();
    } else {
      this.get('_localizedName').enable();
      this.get('_phonetic').enable();
    }
  }

  /**
   * Initializes the instance.
   *
   * @param unit Unit
   * @param attributes any
   * @param shouldAllowOriginal boolean
   * @param shouldAllowLocalized boolean
   * @returns void
   */
  protected initialize(unit: Unit, attributes: any, shouldAllowOriginal: boolean, shouldAllowLocalized: boolean): void {
    this.attributes = attributes;
    this.unit = unit;

    this.setupOriginalId();
    this.setupType();
    this.setupOriginalName();

    if (shouldAllowOriginal) {
      this.setupOriginalFields();
    }

    if (shouldAllowLocalized && this.unit.isComplete()) {
      this.setupLocalizedFields();
    }
  }

  /**
   * Sets up the localized fields and the validators
   *
   * @returns void
   */
  protected setupLocalizedFields(): void {
    this.setupRootId();
    this.setupLocalizedId();
    this.setupUseDomestic();
    this.setupPhonetic();
    this.setupNotes();
    this.setupLocalizedLockingStatus();
    this.setupLocalizedName();

    this.setValidators(this.validateGroup);
  }

  /**
   * Sets up the original fields
   *
   * @returns void
   */
  protected setupOriginalFields(): void {
    this.setupOriginalLockingStatus();
    this.setupRequiredLocalizations();
  }

  /**
   * Set up the localized ID field.
   *
   * @returns void
   */
  protected setupLocalizedId(): void {
    this.addControl('_localizedId',  new FormControl(this.unit.localized.id));
  }

  /**
   * Set up the localized locking status field.
   *
   * @returns void
   */
  protected setupLocalizedLockingStatus(): void {
    this.addControl('_localizedLockingStatus',  new FormControl(_cloneDeep(this.unit.localized.lockingStatus)));
  }

  /**
   * Set up the localized name field.
   *
   * @returns void
   */
  protected setupLocalizedName(): void {
    // BOLTM-2919 TODO remove the useDomestic condition and use the localized name when fallback comes from API
    const name: string = this.unit.localized.useDomestic
      ? this.unit.original.name
      : this.unit.localized.name;

    this.addControl(
      '_localizedName',
      new FormControl(name, Validators.maxLength(this.attributes.originalName.maxLength))
    );
  }

  /**
   * Set up the notes field.
   *
   * @returns void
   */
  protected setupNotes(): void {
    this.addControl(
      '_notes',
      new FormControl(this.unit.localized.notes, Validators.maxLength(this.attributes.notes.maxLength))
    );
  }

  /**
   * Set up the original ID field.
   *
   * @returns void
   */
  protected setupOriginalId(): void {
    this.addControl('_originalId', new FormControl(this.unit.original.id, Validators.required));
  }

  /**
   * Set up the original locking status field.
   *
   * @returns void
   */
  protected setupOriginalLockingStatus(): void {
    this.addControl('_originalLockingStatus', new FormControl(_cloneDeep(this.unit.original.lockingStatus)));
  }

  /**
   * Set up the original name field.
   *
   * @returns void
   */
  protected setupOriginalName(): void {
    const validators: any = Validators.compose([
      Validators.required,
      Validators.maxLength(this.attributes.originalName.maxLength)
    ]);

    this.addControl('_originalName', new FormControl(this.unit.original.name, validators));
  }

  /**
   * Set up the phonetic field.
   *
   * @returns void
   */
  protected setupPhonetic(): void {
    const validators: any = Validators.maxLength(this.attributes.phonetic.maxLength);

    // BOLTM-2919 TODO remove the useDomestic condition and use the localized phonetic when fallback comes from API
    const phonetic: string = this.unit.localized.useDomestic
      ? this.unit.original.phonetic
      : this.unit.localized.phonetic;

    this.addControl('_phonetic', new FormControl(phonetic, validators));
  }

  /**
   * Set up the localization requested field.
   *
   * @returns void
   */
  protected setupRequiredLocalizations(): void {
    this.addControl('_requiredLocalizations', new FormControl(this.unit.original.requiredLocalizations));
  }

  /**
   * Set up the root ID field.
   *
   * @returns void
   */
  protected setupRootId(): void {
    this.addControl('_rootId', new FormControl(this.unit.localized.rootId));
  }

  /**
   * Set up the type field.
   *
   * @returns void
   */
  protected setupType(): void {
    this.addControl('_type', new FormControl(this.unit.original.type.value));
  }

  /**
   * Set up the phonetic field.
   *
   * @returns void
   */
  protected setupUseDomestic(): void {
    this.addControl('_useDomestic', new FormControl(this.unit.localized.useDomestic));
  }

  /**
   * Validates the current form
   *
   * @param group EditionForm
   * @returns ValidationErrors
   */
  protected validateGroup(group: EditionForm): ValidationErrors {
    if (!this.unit.isComplete()) {
      return null;
    }

    if (StringHelper.isNonEmptyText(group.get('_localizedName').value)) {
      group.get('_localizedName').setErrors(null);
      return null;
    }

    if (group.get('_useDomestic').value) {
      group.get('_localizedName').setErrors(null);
      return null;
    }

    if (_isNull(group.get('_localizedId').value)) {
      const hasLock: boolean = (
        _isObject(group.get('_localizedLockingStatus').value) &&
        group.get('_localizedLockingStatus').value.locked
      );

      if (
        !StringHelper.isNonEmptyText(group.get('_phonetic').value) &&
        !StringHelper.isNonEmptyText(group.get('_notes').value) &&
        !hasLock
      ) {
        group.get('_localizedName').setErrors(null);
        return null;
      }
    }

    const error = { error: 'The Localized name is required.' };

    group.get('_localizedName').setErrors(error);

    return error;
  }
}
