import { Injectable } from '@angular/core';
import { UserRole } from '@bolt/ui-shared/auth';
import { BehaviorSubject, Observable } from 'rxjs';

import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { UserInterface } from '../models/user.model';
import { UserManagerActions } from '../components/bolt-user-manager/bolt-user-manager.component';
import { UserService } from '../services/user.service';


/**
 * @todo Move this out of this file.
 */
export interface UserManagerUserManagement {
  user: UserInterface;
  action: UserManagerActions;
  role?: UserRole;
}

@Injectable()
export class UserManager {
  protected managedUser: BehaviorSubject<UserManagerUserManagement> = new BehaviorSubject(undefined);

  constructor(
    protected userService: UserService,
  ) { }

  /**
   * Allows to manage (create, edit, activate/deactivate) a User. This method will emit a UserManagerUserManagement that will be handled by
   * the BoltUserManagerComponent.
   *
   * @param user UserInterface
   * @param action UserManagerActions
   * @param role UserRole
   * @returns void
   */
  manageUser(user: UserInterface, action: UserManagerActions, role?: UserRole): void {
    this.managedUser.next({
      user: user,
      action: action,
      role: role
    });
  }

  /**
   * Returns the the user management as observable.
   *
   * @return Observable<UserManagerUserManagement>
   */
  getManagedUser(): Observable<UserManagerUserManagement> {
    return this.managedUser.asObservable();
  }

  /**
   * Fetches the user details.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchAuthenticatedUser(): Observable<StormServiceResponseSingle> {
    return this.userService.fetchUserDetails();
  }

  /**
   * Fetches the user profile.
   *
   * @returns Observable<StormServiceResponseSingle>
   */
  fetchAuthenticatedUserProfile(): Observable<StormServiceResponseSingle> {
    return this.userService.fetchUserProfile();
  }

  /**
   * Filters users.
   *
   * @param filterParams \{ [propName: string]: any }
   * @returns Observable<StormServiceResponseCollection>
   */
  filterUsers(filterParams: { [propName: string]: any } = new Object()): Observable<StormServiceResponseCollection> {
    return this.userService.filterUsers(filterParams);
  }

  /**
   * Creates a user.
   *
   * @param user UserInterface
   * @returns Observable<StormServiceResponseCollection>
   */
  createUser(user: UserInterface): Observable<StormServiceResponseSingle> {
    return this.userService.createUser(user);
  }

  /**
   * Updates a user.
   *
   * @param user UserInterface
   * @returns Observable<StormServiceResponseCollection>
   */
  updateUser(user: UserInterface): Observable<StormServiceResponseSingle> {
    return this.userService.updateUser(user);
  }

  /**
   * Fetches user roles.
   *
   * @returns Observable<StormServiceResponseCollection>
   */
  fetchUserRoles(): Observable<StormServiceResponseCollection> {
    return this.userService.fetchUserRoles();
  }

  /**
   * Resets the current managedUser.
   *
   * @returns void
   */
  reset(): void {
    this.managedUser.next(undefined);
  }
}
