import { Injectable } from '@angular/core';
import { get as _get } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


@Injectable()
export class ConfigService {
  protected config: any;

  constructor() {
    this.initialize();
  }

  /**
   * Retrieves the value for the given key.
   *
   * @param key string
   * @returns any
   */
  get(key: string): any {
    const value = _get(this.config, key);
    return value;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.loadConfig();
  }

  /**
   * Loads the config file.
   *
   * @throws ErrorHelper
   * @returns void
   */
  protected loadConfig(): void {
    try {
      this.config = require('./../../../../../../config/app-forms.config.json');
    } catch (err) {
      throw new ErrorHelper('Failed loading the form configuration file.', undefined, err);
    }
  }
}
