import { Component, OnDestroy } from '@angular/core';

import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { ManagerService } from '../../services/project/manager/manager.service';
import { ScrollingHandlerService } from '../../services/scrolling-handler/scrolling-handler.service';


@Component({
  selector: 'bolt-project-container',
  template: require('./bolt-project-container.html'),
  styles: [require('./bolt-project-container.scss')]
})
export class BoltProjectContainerComponent implements OnDestroy {
  constructor(
    protected layoutHandler: LayoutHandlerService,
    protected projectManager: ManagerService,
    protected scrollingHandler: ScrollingHandlerService
  ) { }

  ngOnDestroy() {
    this.scrollingHandler.stop();
  }

  /**
   * Indicates if it has to display details section.
   *
   * @returns boolean
   */
  protected hasDisplayDetails(): boolean {
    const hasIt: boolean = (
      this.layoutHandler.isDeleting() ||
      (this.layoutHandler.isReading() && this.projectManager.hasCurrentProject())
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display handler section.
   *
   * @returns boolean
   */
  protected hasDisplayHandler(): boolean {
    const hasIt: boolean = (
      this.layoutHandler.isSaving() ||
      this.layoutHandler.isCreating() ||
      (this.layoutHandler.isUpdating() && this.projectManager.hasCurrentProject())
    );

    return hasIt;
  }

  /**
   * Indicates if it has to display message section.
   *
   * @returns boolean
   */
  protected hasDisplayMessage(): boolean {
    const hasIt: boolean = (!this.hasDisplayDetails() && !this.hasDisplayHandler());
    return hasIt;
  }
}
