import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoltCommonModule } from '../common/common.module';

import {
  BoltCloneCreditsEpisodeListComponent
} from './components/bolt-clone-credits-episode-list/bolt-clone-credits-episode-list.component';

import {
  BoltCloneCreditsListEpisodeItemComponent
} from './components/bolt-clone-credits-list-episode-item/bolt-clone-credits-list-episode-item.component';

import {
  BoltCloneCreditsListSeasonItemComponent
} from './components/bolt-clone-credits-list-season-item/bolt-clone-credits-list-season-item.component';

import {
  BoltCloneCreditsManagerComponent
} from './components/bolt-clone-credits-manager/bolt-clone-credits-manager.component';

import {
  BoltCloneCreditsSeasonListComponent
} from './components/bolt-clone-credits-season-list/bolt-clone-credits-season-list.component';

import {
  BoltCloneMetadataManagerComponent
} from './components/bolt-clone-metadata-manager/bolt-clone-metadata-manager.component';

import { BoltCloneSeasonMetadataManagerComponent } from './components/bolt-clone-season-metadata-manager/bolt-clone-season-metadata-manager.component';
import { BoltTitleLockingStatusComponent } from './components/bolt-title-locking-status/bolt-title-locking-status.component';
import {
  BoltCloneMetadataEpisodeListComponent
} from './components/bolt-clone-metadata-episode-list/bolt-clone-metadata-episode-list.component';

import {
  BoltCloneMetadataListEpisodeItemComponent
} from './components/bolt-clone-metadata-list-episode-item/bolt-clone-metadata-list-episode-item.component';

import { CreditsAggregateService } from './services/credits-aggregate/credits-aggregate.service';
import { CreditsCloneService } from './services/credits-clone/credits-clone.service';
import { MetadataCloneService } from './services/metadata-clone/metadata-clone.service';

@NgModule({
  imports: [
    BoltCommonModule,
    CommonModule
  ],
  declarations: [
    BoltCloneCreditsEpisodeListComponent,
    BoltCloneCreditsListEpisodeItemComponent,
    BoltCloneCreditsListSeasonItemComponent,
    BoltCloneCreditsManagerComponent,
    BoltCloneCreditsSeasonListComponent,
    BoltCloneMetadataManagerComponent,
    BoltCloneSeasonMetadataManagerComponent,
    BoltCloneMetadataEpisodeListComponent,
    BoltCloneMetadataListEpisodeItemComponent,
    BoltTitleLockingStatusComponent,
  ],
  providers: [CreditsCloneService, CreditsAggregateService, MetadataCloneService],
  exports: [
    BoltCloneCreditsManagerComponent,
    BoltCloneMetadataManagerComponent,
    BoltCloneSeasonMetadataManagerComponent,
    BoltTitleLockingStatusComponent,
  ]
})
export class CloneModule { }
