import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { reject as _reject, isObject as _isObject } from 'lodash';

import { CapabilitiesManager } from '../../../../modules/auth/services/role/capabilities.manager';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { LocalizationSpecific } from '../../models/localization-specific.enum';
import { modulesPath } from '../../../../modules/auth/services/role/modules-path';
import { ProductLayoutHelper } from '../../helpers/product-layout/product-layout.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { BoltTitleMetadataFiltersInterface } from '../bolt-title-metadata-filters/bolt-title-metadata-filters.component';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleAttributes } from '../../models/title/title-attributes.enum';
import { TitleFactory } from '../../helpers/title-factory/title-factory.helper';
import { Title, TitleType } from '../../models/title.model';
import { TitleService } from '../../services/title.service';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';

@Component({
  selector: 'bolt-title-metadata-list',
  template: require('./bolt-title-metadata-list.html'),
  styles: [require('./bolt-title-metadata-list.scss')]
})
export class BoltTitleMetadataListComponent extends StormComponent implements OnChanges {
  @Input('Title') product: Title = new Title();
  @Input('SelectedMetadata') selectedProductMetadata: Title;
  @Input('RevealLocale') revealLocale: string;
  @Input('Filters') filters: BoltTitleMetadataFiltersInterface;
  @Input('LocalizationChanged') localizationChanged: boolean;
  @Output('LockStatusChanged') lockStatusChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output('OnSelectMetadata')
  onSelectMetadata: EventEmitter<Title> = new EventEmitter<Title>();

  protected readonly emptyLocalizationMessage: string = 'This localization contains no data.';

  protected confirmLockUpdateModal: NgbModalRef;
  protected fixRootMetadata: Title;
  protected isEnglishLockCapable: boolean;
  protected localeLimit: number;
  protected localizationSpecific = LocalizationSpecific;
  protected lockPrivileges: Map<string, boolean> = new Map();
  protected mappingFlags: any;
  protected mappingInProgress: boolean;
  protected unlockPrivileges: Map<string, boolean> = new Map();
  protected updatingLockingStatus: boolean;
  protected pager: MemoryPager;

  constructor(
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected modalService: NgbModal,
    protected notificationService: NotificationService,
    protected productLayoutHelper: ProductLayoutHelper,
    protected titleService: TitleService,
  ) {
    super();
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ensureFiltersExists();
    this.applyFilters(changes);
  }

  /**
   * Sets the given title metadata as selected.
   *
   * @param productMetadata TitleMetadataInterface
   * @returns void
   */
  selectMetadata(productMetadata: Title): void {
    this.selectedProductMetadata = productMetadata;
    this.onSelectMetadata.emit(this.selectedProductMetadata);
  }

  /**
   * Returns the current lock action.
   *
   * @returns string
   */
  protected getLockAction(): string {
    const action: string = this.selectedProductMetadata.locked ? 'unlock' : 'lock';
    return action;
  }

  /**
   * Indicates if the current user can toggle the lock status on the active group
   *
   * @returns boolean
   */
  protected canToggleLockStatusOnGroup(): boolean {
    if (this.isMoratoriumTitle()) {
      return false;
    } else if (Locale.isEnglishLanguage(this.selectedProductMetadata.locale)) {
      return this.isEnglishLockCapable;
    } else {
      const groupName: string = this.filters.groupBy.split('Specific')[0].toUpperCase();

      if (this.selectedProductMetadata.locked) {
        return this.unlockPrivileges.get(groupName);
      } else {
        return this.lockPrivileges.get(groupName);
      }
    }
  }

  /**
   * Indicates if the Language column should be displayed.
   *
   * @returns boolean
   */
  protected shouldDisplayLanguage(): boolean {
    const hasIt = (
      !this.filters ||
      (
        (this.filters.groupBy !== this.localizationSpecific.TERRITORY.toString()) &&
        (this.filters.groupBy !== this.localizationSpecific.ACCOUNT.toString())
      )
    );

    return hasIt;
  }

  /**
   * Indicates if the Territory column should be displayed.
   *
   * @returns boolean
   */
  protected shouldDisplayProductType(): boolean {
    const hasIt = (!this.filters || this.filters.groupBy !==  this.localizationSpecific.ACCOUNT.toString());
    return hasIt;
  }

  /**
   * Indicates if the Territory column should be displayed.
   *
   * @returns boolean
   */
  protected shouldDisplayTerritory(): boolean {
    const hasIt = (!this.filters || this.filters.groupBy !== this.localizationSpecific.ACCOUNT.toString());
    return hasIt;
  }

  /**
   * Applies the filters.
   *
   * @param changes any
   * @returns void
   */
  protected applyFilters(changes: any): void {
    if (changes.filters && changes.filters.currentValue) {
      if (
        changes.filters.previousValue &&
        (changes.filters.previousValue.groupBy !== changes.filters.currentValue.groupBy)
      ) {
        // When Group By criteria changes, selected metadata and sorting needs to be reset
        this.selectMetadata(undefined);
      }
    }
  }

  /**
   * Ensures that filters always exists.
   *
   * @returns void
   */
  protected ensureFiltersExists(): void {
    if (!this.filters) {
      this.filters = {
        filter: [],
        groupBy: this.localizationSpecific.LANGUAGE.toString()
      };
    }
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.localeLimit = this.appConfig.get('ux.dataAccordion.lockingPopup.localeLimit');
    this.updatingLockingStatus = false;
    this.mappingInProgress = false;

    this.mappingFlags = {
      languageSpecific: false,
      territorySpecific: false,
      accountSpecific: false
    };

    const moduleInfo: any = modulesPath.titles.details;

    Object.keys(LocalizationSpecific).forEach(
      (section: string) => {
        this.lockPrivileges.set(
          section,
          this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo[section.toLowerCase()].path, [ActionTypeEnum.lock])
        );

        this.unlockPrivileges.set(
          section,
          this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo[section.toLowerCase()].path, [ActionTypeEnum.unlock])
        );
      }
    );

    this.isEnglishLockCapable = this.capabilitiesManager.hasUserPrivilegeOn(
      moduleInfo.english.root.path, [ActionTypeEnum.lock]
    );
  }

  /**
   * Indicates if "Moratorium" is active for the current title.
   *
   * @todo Remove this method after UAT and call `product.moratorium`.
   * @returns boolean
   */
  protected isMoratoriumTitle(): boolean {
    const isIt: boolean = this.product.moratorium;
    return isIt;
  }

  /**
   * Check if the current user is able to see the locking button
   *
   * @returns boolean
   */
  protected hasDisplayLockingStatusButton(): boolean {
    const hasIt: boolean = _isObject(this.selectedProductMetadata) && this.canToggleLockStatusOnGroup();
    return hasIt;
  }

  /**
   * Indicates if the given metadata title is an empty localization, according to current group in filters.
   *
   * @param metadata Title
   * @return boolean
   */
  protected isLocalizationEmpty(metadata: Title): boolean {
    return !this.productLayoutHelper.hasGroupAttributes(this.product.type, metadata, this.filters.groupBy, false);
  }

  /**
   * Updates the locking status of the current localization.
   *
   * @returns void
   */
  protected updateLockingStatus(): void {
    this.updatingLockingStatus = true;
    const lock: boolean = !this.selectedProductMetadata.locked;
    const metadata: Title = TitleFactory(TitleType[this.selectedProductMetadata.type], { });
    metadata[TitleAttributes.locked] = lock;

    this.titleService.setProductMetadata(
      {
        productType: this.product.type,
        productId: this.product.id
      },
      this.selectedProductMetadata.locale,
      metadata
    ).pipe(
      finalize(() => {
        this.updatingLockingStatus = false;
        this.closeConfirmModal();
      })
    ).subscribe(
      (serviceResponseSingle: StormServiceResponseSingle) => {
        this.lockStatusChangeEvent.emit();

        this.notificationService.handleNotice(
          `The localization ${this.selectedProductMetadata.locale} is ${lock ? 'locked' : 'unlocked'}`
        );
      },
      (error: any) => {
        this.notificationService.handleError('Error while trying to update the Localization lock status', error);
      }
    );
  }

  /**
   * Allows to show the modal of locking confirmation and handles the user answer.
   *
   * @param content Reference to the modal
   * @return void
   */
  protected openConfirmModal(content: any): void {
    this.confirmLockUpdateModal = this.modalService.open(content);
  }

  /**
   * Closes the confirm modal.
   *
   * @returns void
   */
  protected closeConfirmModal(): void {
    this.confirmLockUpdateModal.close();
  }
}
