import { isString as _isString, isNumber as _isNumber, includes as _includes } from 'lodash';

import { RequestControlEnum } from 'app/modules/user/models/request-control/request-control.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class Group {
  protected _name: string;
  protected _id: number;
  protected _requestControl: RequestControlEnum;

  get name(): string {
    return this._name;
  }

  get id(): number {
    return this._id;
  }

  get requestControl(): RequestControlEnum {
    return this._requestControl;
  }

  constructor(name: string, id: number, requestControl: RequestControlEnum) {
    this.initialize(name, id, requestControl);
  }

  /**
   * Initializes the instance.
   *
   * @param name string
   * @param id number
   * @param requestControl RequestControlEnum
   * @returns void
   */
  protected initialize(name: string, id: number, requestControl: RequestControlEnum): void {
    this.setName(name);
    this.setId(id);
    this.setRequestControl(requestControl);
  }

  /**
   * Set the current name with the given value.
   *
   * @param name string
   * @throws ErrorHelper
   * @returns void
   */
  protected setName(name: string): void {
    if (_isString(name) && name.length > 0) {
      this._name = name;
    } else {
      throw new ErrorHelper('Invalid name given for creating a group');
    }
  }

  /**
   * Set the current id with the given value.
   *
   * @param id number
   * @throws ErrorHelper
   * @returns void
   */
  protected setId(id: number): void {
    if (_isNumber(id)) {
      this._id = id;
    } else {
      throw new ErrorHelper('Invalid id given for creating a group');
    }
  }

  /**
   * Set the current request control with the given value.
   *
   * @param requestControl RequestControlEnum
   * @throws ErrorHelper
   * @returns void
   */
  protected setRequestControl(requestControl: RequestControlEnum): void {
    if (_includes(RequestControlEnum, requestControl)) {
      this._requestControl = requestControl;
    } else {
      throw new ErrorHelper('Invalid request control given for creating a group');
    }
  }
}
