import { XsltPropertyInterface } from './xslt-property.model';


/**
 * @todo Move this out of this file.
 */
export interface XsltInterface {
  id: number;
  fileName: string;
  templateName: string;
  config: { [propName: string]: XsltPropertyInterface };
  downloadUrl: string;
  isDefault: boolean;
  isActive: boolean;
  getRawObject(): object;
}


export class Xslt implements XsltInterface {
  _id: number;
  _fileName: string;
  _templateName: string;
  _config: { [propName: string]: XsltPropertyInterface };
  _downloadUrl: string;
  _isDefault: boolean;
  _isActive: boolean;

  constructor(attributes?: { [attr: string]: any }) {
    this.setAttributes(Object.assign({ config: [] }, attributes));
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get fileName(): string {
    return this._fileName;
  }

  set fileName(fileName: string) {
    this._fileName = fileName;
  }

  get templateName(): string {
    return this._templateName;
  }

  set templateName(templateName: string) {
    this._templateName = templateName;
  }

  get downloadUrl(): string {
    return this._downloadUrl;
  }

  set downloadUrl(downloadUrl: string) {
    this._downloadUrl = downloadUrl;
  }

  get isDefault(): boolean {
    return this._isDefault;
  }

  set isDefault(isDefault: boolean) {
    this._isDefault = isDefault;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(isActive: boolean) {
    this._isActive = isActive;
  }

  get config(): { [propName: string]: XsltPropertyInterface } {
    return this._config;
  }

  set config(config: { [propName: string]: XsltPropertyInterface }) {
    this._config = config;
  }

  /**
   * Set the attributes.
   *
   * @param attributes \{ [attr: string]: any }
   * @returns Xslt
   */
  setAttributes(attributes?: { [attr: string]: any }): Xslt {
    if (attributes !== undefined) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }

    return this;
  }

  /**
   * Returns a new raw object with the inner content.
   *
   * @returns object
   */
  getRawObject(): object {
    const output: object = new Object();
    const names: string[] = Object.getOwnPropertyNames(Xslt.prototype);

    const getters: string[] = names.filter(
      (name: string) => {
        const result: boolean = !!Object.getOwnPropertyDescriptor(Xslt.prototype, name).get;
        return result;
      }
    );

    getters.forEach(
      (key: string) => {
        if (this[key] !== undefined) {
          output[key] = this[key];
        }
      }
    );

    return output;
  }
}
