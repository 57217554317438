// tslint:disable: variable-name
import * as moment from 'moment';
import { RatingInterface } from '@bolt/ui-shared/master-data/models/rating/rating.interface';
import { RatingSystemReasonInterface } from '@bolt/ui-shared/master-data/models/rating-system-reason/rating-system-reason.interface';
import { RatingType } from '@bolt/ui-shared/master-data';

import { EpisodeMetadataInterface } from './episode-metadata.model';
import { FunctionalMetadata } from 'app/modules/list/models/functional-metadata/functional-metadata.model';
import { TimeInterface } from 'app/modules/common/models/time.model';
import { TitleInterface, Title, TitleType } from './title.model';
import { TitleAttributes } from './title/title-attributes.enum';
import { EpisodeAttributes } from './title/episode/episode-attributes.enum';


/**
 * @deprecated 2021-07-12: Use `Episode` instead of this interface.
 */
export interface EpisodeInterface extends TitleInterface {
  episodeNumber?: number;
  productionNumber?: string;
  f0_num?: string;
  firstAirReleaseDate?: string | moment.Moment;
  originalAirRunningOrder?: number;
  localAirRunningOrder?: number;
  legalTitle?: string;
  seasonId?: number;
  keywords?: string[];
  shortSynopsis?: string;
  mediumSynopsis?: string;
  fullSynopsis?: string;
  radarProductId?: number;
  ratingReason?: string;
  ratingId?: number[] | RatingInterface[];
  ratingSystemReasonId: number[] | RatingSystemReasonInterface[];
  runtimeSeconds?: number | TimeInterface;
  ratingType?: RatingType;
  functionalMetadata?: number[] | FunctionalMetadata[];
  onairDateKcc?: moment.Moment;
  onairPlatformKcc?: string;
  ratingFilingNumberKmrb?: string;
  displayDubTalent?: boolean;
  graphicContentDisclaimer?: boolean;
  heritageDisclaimer?: boolean;
  excludeKidsMode?: boolean;
  includeKidsMode?: boolean;
  productPlacementDisclaimer?: boolean;
  pseDisclaimer?: boolean;
  smokingDisclaimer?: boolean;
  homeEntRatingId?: number[] | RatingInterface[];
  homeEntRatingSystemReasonId: number[] | RatingSystemReasonInterface[];
  homeEntRatingReason?: string;
}

/**
 * @todo We have to re-implement the whole `Title` hierarchy class in order to not use Typescript set/get with underscore attribute. But
 * because this is part of the Bolt's core model, we need a tech debt story for doing this and move it to bolt-ui-shared project.
 * @see `EpisodeAttributes`: Ensure that enumerative and this class are synchronized about public attributes.
 */
export class Episode extends Title implements EpisodeInterface {
  protected _episodeNumber: number;
  protected _productionNumber: string;
  protected _f0_num: string;
  protected _firstAirReleaseDate: string | moment.Moment;
  protected _originalAirRunningOrder: number;
  protected _localAirRunningOrder: number;
  protected _legalTitle: string;
  protected _seasonId: number;
  protected _keywords: string[];
  protected _shortSynopsis: string;
  protected _mediumSynopsis: string;
  protected _fullSynopsis: string;
  protected _radarProductId: number;
  protected _ratingReason: string;
  protected _ratingId: number[] | RatingInterface[];
  protected _ratingSystemReasonId: number[] | RatingSystemReasonInterface[];
  protected _runtimeSeconds: number | TimeInterface;
  protected _ratingType: RatingType;
  protected _functionalMetadata: number[] | FunctionalMetadata[];
  protected _notApplicable: boolean;
  protected _onairDateKcc?: moment.Moment;
  protected _onairPlatformKcc?: string;
  protected _ratingFilingNumberKmrb?: string;
  protected _displayDubTalent?: boolean;
  protected _graphicContentDisclaimer?: boolean;
  protected _heritageDisclaimer?: boolean;
  protected _excludeKidsMode?: boolean;
  protected _includeKidsMode?: boolean;
  protected _productPlacementDisclaimer?: boolean;
  protected _pseDisclaimer?: boolean;
  protected _smokingDisclaimer?: boolean;
  protected _homeEntRatingId?: number[] | RatingInterface[];
  protected _homeEntRatingSystemReasonId?: number[] | RatingSystemReasonInterface[];
  protected _homeEntRatingReason?: string;

  constructor(attributes?: { [attr: string]: any }) {
    super(attributes);
    this.setAttributes(attributes);
    this._type = TitleType.episode;
  }

  /**
   * Returns the attributes names of a Episode.
   *
   * @returns string[]
   */
  static getAttributesNames(): string[] {
    const attributes: string[] = [
      ...Object.keys(TitleAttributes),
      ...Object.keys(EpisodeAttributes)
    ];

    return attributes;
  }

  get episodeNumber(): number {
    return this._episodeNumber;
  }

  set episodeNumber(episodeNumber: number) {
    this._episodeNumber = episodeNumber;
  }

  get productionNumber(): string {
    return this._productionNumber;
  }

  set productionNumber(productionNumber: string) {
    this._productionNumber = productionNumber;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  set legalTitle(legalTitle: string) {
    this._legalTitle = legalTitle;
  }

  get firstAirReleaseDate(): string | moment.Moment {
    return this._firstAirReleaseDate;
  }

  set firstAirReleaseDate(firstAirReleaseDate: string | moment.Moment) {
    this._firstAirReleaseDate = firstAirReleaseDate;
  }

  get originalAirRunningOrder(): number {
    return this._originalAirRunningOrder;
  }

  set originalAirRunningOrder(originalAirRunningOrder: number) {
    this._originalAirRunningOrder = originalAirRunningOrder;
  }

  get localAirRunningOrder(): number {
    return this._localAirRunningOrder;
  }

  set localAirRunningOrder(localAirRunningOrder: number) {
    this._localAirRunningOrder = localAirRunningOrder;
  }

  get f0_num(): string {
    return this._f0_num;
  }

  set f0_num(f0_num: string) {
    this._f0_num = f0_num;
  }

  get seasonId(): number {
    return this._seasonId;
  }

  set seasonId(seasonId: number) {
    this._seasonId = seasonId;
  }

  get keywords(): string[] {
    return this._keywords;
  }

  set keywords(keywords: string[]) {
    this._keywords = keywords;
  }

  get shortSynopsis(): string {
    return this._shortSynopsis;
  }

  set shortSynopsis(shortSynopsis: string) {
    this._shortSynopsis = shortSynopsis;
  }

  get mediumSynopsis(): string {
    return this._mediumSynopsis;
  }

  set mediumSynopsis(mediumSynopsis: string) {
    this._mediumSynopsis = mediumSynopsis;
  }

  get fullSynopsis(): string {
    return this._fullSynopsis;
  }

  set fullSynopsis(fullSynopsis: string) {
    this._fullSynopsis = fullSynopsis;
  }

  get radarProductId(): number {
    return this._radarProductId;
  }

  set radarProductId(radarProductId: number) {
    this._radarProductId = radarProductId;
  }

  get ratingReason(): string {
    return this._ratingReason;
  }

  set ratingReason(ratingReason: string) {
    this._ratingReason = ratingReason;
  }

  get ratingId(): number[] | RatingInterface[] {
    return this._ratingId;
  }

  set ratingId(ratingId: number[] | RatingInterface[]) {
    this._ratingId = ratingId;
  }

  get ratingSystemReasonId(): number[] | RatingSystemReasonInterface[] {
    return this._ratingSystemReasonId;
  }

  set ratingSystemReasonId(ratingSystemReasonId: number[] | RatingSystemReasonInterface[]) {
    this._ratingSystemReasonId = ratingSystemReasonId;
  }

  get ratingType(): RatingType {
    return this._ratingType;
  }

  set ratingType(ratingType: RatingType) {
    this._ratingType = ratingType;
  }

  get runtimeSeconds(): number | TimeInterface {
    return this._runtimeSeconds;
  }

  set runtimeSeconds(runtimeSeconds: number | TimeInterface) {
    this._runtimeSeconds = runtimeSeconds;
  }

  get localizations(): EpisodeMetadataInterface[] {
    return this._localizations;
  }

  set localizations(localizations: EpisodeMetadataInterface[]) {
    this._localizations = localizations;
  }

  get functionalMetadata(): number[] | FunctionalMetadata[] {
    return this._functionalMetadata;
  }

  set functionalMetadata(functionalMetadata: number[] | FunctionalMetadata[]) {
    this._functionalMetadata = functionalMetadata;
  }

  get notApplicable(): boolean {
    return this._notApplicable;
  }

  set notApplicable(notApplicable: boolean) {
    this._notApplicable = notApplicable;
  }

  get onairDateKcc(): moment.Moment {
    return this._onairDateKcc;
  }

  set onairDateKcc(onairDateKcc: moment.Moment) {
    this._onairDateKcc = onairDateKcc;
  }

  get onairPlatformKcc(): string {
    return this._onairPlatformKcc;
  }

  set onairPlatformKcc(onairPlatformKcc: string) {
    this._onairPlatformKcc = onairPlatformKcc;
  }

  get ratingFilingNumberKmrb(): string {
    return this._ratingFilingNumberKmrb;
  }

  set ratingFilingNumberKmrb(ratingFilingNumberKmrb: string) {
    this._ratingFilingNumberKmrb = ratingFilingNumberKmrb;
  }

  get displayDubTalent(): boolean {
    return this._displayDubTalent;
  }

  set displayDubTalent(displayDubTalent: boolean) {
    this._displayDubTalent = displayDubTalent;
  }

  get graphicContentDisclaimer(): boolean {
    return this._graphicContentDisclaimer;
  }

  set graphicContentDisclaimer(graphicContentDisclaimer: boolean) {
    this._graphicContentDisclaimer = graphicContentDisclaimer;
  }

  get heritageDisclaimer(): boolean {
    return this._heritageDisclaimer;
  }

  set heritageDisclaimer(heritageDisclaimer: boolean) {
    this._heritageDisclaimer = heritageDisclaimer;
  }

  get smokingDisclaimer(): boolean {
    return this._smokingDisclaimer;
  }

  set smokingDisclaimer(smokingDisclaimer: boolean) {
    this._smokingDisclaimer = smokingDisclaimer;
  }

  get includeKidsMode(): boolean {
    return this._includeKidsMode;
  }

  set includeKidsMode(includeKidsMode: boolean) {
    this._includeKidsMode = includeKidsMode;
  }

  get excludeKidsMode(): boolean {
    return this._excludeKidsMode;
  }

  set excludeKidsMode(excludeKidsMode: boolean) {
    this._excludeKidsMode = excludeKidsMode;
  }

  get productPlacementDisclaimer(): boolean {
    return this._productPlacementDisclaimer;
  }

  set productPlacementDisclaimer(productPlacementDisclaimer: boolean) {
    this._productPlacementDisclaimer = productPlacementDisclaimer;
  }

  get pseDisclaimer(): boolean {
    return this._pseDisclaimer;
  }

  set pseDisclaimer(pseDisclaimer: boolean) {
    this._pseDisclaimer = pseDisclaimer;
  }

  get homeEntRatingId(): number[] | RatingInterface[] {
    return this._homeEntRatingId;
  }

  set homeEntRatingId(ratingId: number[] | RatingInterface[]) {
    this._homeEntRatingId = ratingId;
  }

  get homeEntRatingSystemReasonId(): number[] | RatingSystemReasonInterface[] {
    return this._homeEntRatingSystemReasonId;
  }

  set homeEntRatingSystemReasonId(ratingSystemReasonId: number[] | RatingSystemReasonInterface[]) {
    this._homeEntRatingSystemReasonId = ratingSystemReasonId;
  }

  get homeEntRatingReason(): string {
    return this._homeEntRatingReason;
  }

  set homeEntRatingReason(reason: string) {
    this._homeEntRatingReason = reason;
  }

  getRawObject(): object {
    // TODO: This needs to be refactored to export individuals fields.
    const names = Object.getOwnPropertyNames(Episode.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Episode.prototype, name);
      return !!result.get;
    });

    const object = new Object();
    getters.forEach(key => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return Object.assign(new Object(), super.getRawObject(), object);
  }
}
