import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { BoltSearchComponent } from './components/bolt-search/bolt-search.component';
import { modulesPath } from '../auth/services/role/modules-path';


export const searchRoutes: Routes = [
  {
    path: 'search',
    component: BoltSearchComponent,
    canActivate: [AuthGuard],
    data: {
      boltAuthAware: modulesPath.menu.search.path,
      title: 'Search',
    }
  }
];

export const searchModuleRouting: ModuleWithProviders = RouterModule.forChild(searchRoutes);
