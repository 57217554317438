import { Component, Input } from '@angular/core';


@Component({
  selector: 'bolt-greeting',
  template: require('./bolt-greeting.html'),
  styles: [require('./bolt-greeting.scss')]
})
export class BoltGreetingComponent {
  @Input() showLegend: boolean;

  constructor() {
    this.showLegend = true;
  }
}
