import * as moment from 'moment';

import { Decision } from './subtitle/details/decision/decision.model';
import { SubtitleType } from './subtitle/details/subtitle-type/subtitle-type.model';


export class SubtitleDetail {
  protected _decision: Decision;
  protected _id: number;
  protected _lastModifiedBy: string;
  protected _lastModifiedDate: moment.Moment;
  protected _narrativeTitle: string;
  protected _rootId: number;
  protected _timeEnd: string;
  protected _timeStart: string;
  protected _translation: string;
  protected _subtitleType: SubtitleType;

  constructor(data: any) {
    this.initialize(data);
  }

  get decision(): Decision {
    return this._decision;
  }

  set decision(decision: Decision) {
    this._decision = decision;
  }

  get id(): number {
    return this._id;
  }

  get lastModifiedBy(): string {
    return this._lastModifiedBy;
  }

  get lastModifiedDate(): moment.Moment {
    return this._lastModifiedDate;
  }

  get narrativeTitle(): string {
    return this._narrativeTitle;
  }

  set narrativeTitle(narrativeTitle: string) {
    this._narrativeTitle = narrativeTitle;
  }

  get rootId(): number {
    return this._rootId;
  }

  get subtitleType(): SubtitleType {
    return this._subtitleType;
  }

  set subtitleType(subtitleType: SubtitleType) {
    this._subtitleType = subtitleType;
  }

  get timeEnd(): string {
    return this._timeEnd;
  }

  set timeEnd(timeEnd: string) {
    this._timeEnd = timeEnd;
  }

  get timeStart(): string {
    return this._timeStart;
  }

  set timeStart(timeStart: string) {
    this._timeStart = timeStart;
  }

  get translation(): string {
    return this._translation;
  }

  set translation(translation: string) {
    this._translation = translation;
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns object
   */
  asEndpointData(): object {
    const data: any = new Object();

    if (this.id) {
      data.id = this.id;
    }

    if (this.timeStart) {
      data.timeStart = this.timeStart;
    }

    if (this.timeEnd) {
      data.timeEnd = this.timeEnd;
    }

    if (this.narrativeTitle) {
      data.narrativeTitle = this.narrativeTitle;
    }

    if (this.rootId) {
      data.rootId = this.rootId;
    }

    if (this.translation) {
      data.translation = this.translation;
    }

    if (this.decision) {
      data.decision = this.decision.toString();
    }

    if (this.subtitleType) {
      data.subtitleType = this.subtitleType.toString();
    }

    return data;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this._decision = (
      (data && data.hasOwnProperty('decision'))
        ? new Decision(data.decision)
        : undefined
    );

    this._id = (
      (data && data.hasOwnProperty('id'))
        ? data.id
        : false
    );

    this._lastModifiedBy = (
      (data && data.hasOwnProperty('lastModifiedBy'))
        ? data.lastModifiedBy
        : undefined
    );

    this._lastModifiedDate = (
      (data && data.hasOwnProperty('lastModified'))
        ? moment(data.lastModified)
        : undefined
    );

    this._narrativeTitle = (
      (data && data.hasOwnProperty('narrativeTitle'))
        ? data.narrativeTitle
        : undefined
    );

    this._rootId = (
      (data && data.hasOwnProperty('rootId'))
        ? data.rootId
        : undefined
    );

    this._timeEnd = (
      (data && data.hasOwnProperty('timeEnd'))
        ? data.timeEnd
        : undefined
    );

    this._timeStart = (
      (data && data.hasOwnProperty('timeStart'))
        ? data.timeStart
        : undefined
    );

    this._translation = (
      (data && data.hasOwnProperty('translation'))
        ? data.translation
        : undefined
    );

    this._subtitleType = (
      (data && data.hasOwnProperty('subtitleType'))
        ? new SubtitleType(data.subtitleType)
        : undefined
    );
  }
}
