import { Component, Input, OnDestroy, EventEmitter, Output, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { sortBy as _sortBy, isObject as _isObject } from 'lodash';

import { CreditLocale } from 'app/modules/credits/models/credit/credit-locale.model';
import { Season } from 'app/modules/title/models/season.model';
import { Series } from 'app/modules/title/models/series.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TitleService } from 'app/modules/title/services/title.service';
import { TitleType } from 'app/modules/title/models/title.model';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';


@Component({
  selector: 'bolt-clone-credits-season-list',
  template: require('./bolt-clone-credits-season-list.html'),
  styles: [require('./bolt-clone-credits-season-list.scss')]
})
export class BoltCloneCreditsSeasonListComponent extends StormComponent implements OnDestroy, OnInit  {
  @Input() creditLocale: CreditLocale;
  @Input() creditPosition: CreditPositionType;
  @Input() creditType: CreditType;
  @Input() title: Season;

  @Output('selected') protected selectEvent: EventEmitter<Season>;

  protected cloneForm: FormGroup;
  protected optionsSubscription: Subscription;
  protected series: Series;
  protected serieSubscription: Subscription;
  protected titleOptions: Season[];

  constructor(
    protected formBuilder: FormBuilder,
    protected titleService: TitleService
  ) {
    super();
    this.initialize();
  }

  ngOnInit() {
    this.fetchSeasonOptions();
  }

  ngOnDestroy() {
    this.cancelSubscriptions();
  }

  /**
   * Applies the filter on the current options.
   *
   * @returns void
   */
  protected applyFilter(): void {
    this.titleOptions = _sortBy(this.titleOptions, 'prettifiedLegalTitle');

    this.titleOptions = this.titleOptions.filter(
      (option: Season) => option.id !== this.title.id
    );
  }

  /**
   * Cancels the current subscriptions.
   *
   * @returns void
   */
  protected cancelSubscriptions(): void {
    if (_isObject(this.optionsSubscription)) {
      this.optionsSubscription.unsubscribe();
    }

    if (_isObject(this.serieSubscription)) {
      this.serieSubscription.unsubscribe();
    }
  }

  /**
   * Fetches the seasons for the current serie option.
   *
   * @returns void
   */
  protected fetchAllSeasonOfSerie(): void {
    const data: any = {
      titleType: TitleType.season,
      seriesId: this.series.id,
      locale: '*_*_*_*',
      _size: 5000
    };

    this.optionsSubscription = this.titleService.filterTitles(data).subscribe(
      (response: StormServiceResponseCollection) => {
        response.collection.forEach(
          (season: Season) => {
            this.titleOptions.push(season);
          }
        );

        this.applyFilter();
        this.setDefaultSelection();
        this.changeStatusToDataFound();
      },
      error => {
        this.changeStatusToDataFound();
      }
    );
  }

  /**
   * Fetches the season for the current series.
   *
   * @returns void
   */
  protected fetchSeasonOptions(): void {
    this.changeStatusToFetchingData();

    this.serieSubscription = this.titleService.fetchProduct({
      productId: this.title.seriesId,
      productType: TitleType.series
    }).subscribe(
      (response: StormServiceResponseSingle) => {
        this.series = response.item;
        this.titleOptions = new Array();

        this.fetchAllSeasonOfSerie();
      },
      () => {
        this.changeStatusToError();
      }
    );
  }

  /**
   * Indicates if it has to display the empty message.
   *
   * @returns boolean
   */
  protected hasDisplayEmptyMessage(): boolean {
    const hasIt: boolean = !this.hasTitleOptions();
    return hasIt;
  }


  /**
   * Indicates if it has title options.
   *
   * @returns boolean
   */
  protected hasTitleOptions(): boolean {
    const hasIt: boolean = this.titleOptions.length > 0;
    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.optionsSubscription = new Subscription();
    this.selectEvent = new EventEmitter();
    this.serieSubscription = new Subscription();
    this.titleOptions = new Array();
    this.setupForm();
  }

  /**
   * Indicates if the given option is the current selection.
   *
   * @param option Season
   * @returns boolean
   */
  protected isSelected(option: Season): boolean {
    const selected: Season = this.cloneForm.get('selected').value;

    const isIt: boolean =
      _isObject(selected) &&
      option.id === selected.id &&
      option.type === selected.type;

    return isIt;
  }

  /**
   * Retrieves the locking status label for the current position.
   *
   * @returns string
   */
  protected retrieveLockingStatusLabel(): string {
    const label: string = `${this.creditType.toString().slice(0, 3)} ${this.creditPosition} Lock Status`;
    return label;
  }

  /**
   * Resets the current values.
   *
   * @returns void
   */
  protected reset(): void {
    this.titleOptions = new Array();
    this.series = undefined;
  }

  /**
   * Sets the default option.
   *
   * @returns void
   */
  protected setDefaultSelection(): void {
    this.setSelected(this.titleOptions[0]);
  }

  /**
   * Sets the given option as the current selection.
   *
   * @param option Season
   * @returns void
   */
  protected setSelected(option: Season): void {
    this.cloneForm.get('selected').setValue(option);
    this.selectEvent.emit(option);
  }

  /**
   * Set up the clone form.
   *
   * @returns void
   */
  protected setupForm(): void {
    this.cloneForm = this.formBuilder.group({
      selected: [
        undefined,
        Validators.required
      ]
    });
  }
}
