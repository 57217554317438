import { FormGroup } from '@angular/forms';
import { isArray as _isArray, isObject as _isObject, isUndefined as _isUndefined } from 'lodash';


export abstract class BoltForm extends FormGroup {
  protected fieldSpecs: any;
  protected defaultValues: any;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super({ });
    this.fieldSpecs = fieldSpecs;
    this.defaultValues = defaultValues;
  }

  /**
   * Returns a JSON representation to send to API endpoints.
   *
   * @returns any
   */
  abstract toJson(): any;

  /**
   * Get the default value for the given attribute.
   *
   * @param attribute string
   * @returns any
   */
  protected getDefaultValue(attribute: string): any {
    let returningValue: any;

    if (_isObject(this.defaultValues)) {
      const defaultValue: any = this.defaultValues[attribute];

      if (_isArray(defaultValue)) {
        returningValue = defaultValue.map(
          (current: any) => _isObject(<any>current) && !_isUndefined(current.id) ? current.id : current
        );
      } else if (_isObject(<any>defaultValue) && !_isUndefined(defaultValue.id)) {
        returningValue = defaultValue.id;
      } else {
        returningValue = defaultValue;
      }
    }

    return returningValue;
  }

  /**
   * Set up the fields.
   *
   * @returns void
   */
  protected abstract setupFields(): void;
}
