import { ResolveIngestData } from './resolve-ingest-data/resolve-ingest-data.model';
import { ResolveIngestDataEnum } from './resolve-ingest-data/resolve-ingest-data.enum';


export abstract class Resolution {
  protected _resolveIngestData: ResolveIngestData;

  /**
   * This is mandatory for Typescript and it doesn't have sense.
   */
  constructor() { }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns Object
   */
  abstract asEndpointData(): object;

  get resolveIngestData(): ResolveIngestData {
    return this._resolveIngestData;
  }

  /**
   * Sets up the resolve ingest data with the given value.
   *
   * @param value ResolveIngestDataEnum
   * @returns void
   */
  protected setupResolveIngestData(value: ResolveIngestDataEnum): void {
    this._resolveIngestData = new ResolveIngestData(value);
  }
}
