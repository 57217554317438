import { Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Direction, DirectionEnum, Sort } from '@bolt/ui-shared/searching';
import { Subscription } from 'rxjs';
import { isObject as _isObject, isString as _isString } from 'lodash';

import { CastCrewSearchCriteria } from 'app/shared/models/search-criteria/character/cast-crew-search-criteria.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { SearchType } from 'app/shared/models/search-criteria/search-type/search-type.model';
import { SearchTypeEnum } from 'app/shared/models/search-criteria/search-type/search-type.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


export abstract class BoltMergeListComponent extends StormComponent implements OnChanges, OnDestroy {
  @Input() primaryRecord: any;
  @Input() query: string;

  @Output('selected') selectEvent: EventEmitter<object>;

  protected fetchItemSubscription: Subscription;
  protected mergeCandidate: any;
  protected searchCriteria: CastCrewSearchCriteria;
  protected searchResponse: SearchResponse;

  constructor(protected notificationService: NotificationService) {
    super();
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isValidQueryChange(changes.query)) {
      this.unselect();
      this.updateQuery();
      this.fetchItems();
    }
  }

  ngOnDestroy(): void {
    this.cancelSearchSubscription();
    this.reset();
  }

  abstract get items(): any[];

  /**
   * Cancels the current search subscription.
   *
   * @returns void
   */
  protected cancelSearchSubscription(): void {
    if (_isObject(this.fetchItemSubscription)) {
      this.fetchItemSubscription.unsubscribe();
    }
  }

  /**
   * Default error path for fetch items.
   *
   * @param error ErrorHelper
   * @param itemType string
   */
  protected doDefaultOnError(error: ErrorHelper, itemType?: string): void {
    this.changeStatusToError();
    this.notificationService.handleError(`Failed trying to fetch ${itemType ? itemType : 'items'}`, error);
  }

  /**
   * Default success path for fetch items.
   *
   * @param response SearchResponse
   * @returns void
   */
  protected doDefaultOnSuccess(response: SearchResponse): void {
    this.searchResponse = response;
    this.changeStatusToDataFound();
  }

  /**
   * Calls the search service.
   *
   * @returns void
   */
  protected abstract doSearch(): void;

  /**
   * Fetches the items with the current search criteria.
   *
   * @returns void
   */
  protected fetchItems(): void {
    this.changeStatusToFetchingData();
    this.cancelSearchSubscription();

    // Restriction in order to reset the list if the user delete the search input
    if (this.query === '') {
      this.reset();
      this.changeStatusToDataFound();
    } else {
      this.doSearch();
    }
  }

  /**
   * Indicates if it has items.
   *
   * @returns boolean
   */
  protected hasItems(): boolean {
    const hasIt: boolean = _isObject(this.searchResponse) && this.items.length > 0;
    return hasIt;
  }

  /**
   * Indicates if it has to display the empty message.
   *
   * @returns boolean
   */
  protected hasDisplayEmptyMessage(): boolean {
    const hasIt: boolean = this.isDataFound() && !this.hasItems();
    return hasIt;
  }

  /**
   * Indicates if it has to display the items list.
   *
   * @returns boolean
   */
  protected hasDisplayList(): boolean {
    const hasIt: boolean = this.isDataFound() && this.hasItems();
    return hasIt;
  }

  /**
   * Indicates if it has to display the paginator.
   *
   * @returns boolean
   */
  protected hasDisplayPaginator(): boolean {
    const hasIt: boolean =
      this.isDataFound() &&
      _isObject(this.searchResponse) &&
      (this.searchResponse.pageData.totalPages > 1);

    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.selectEvent = new EventEmitter();

    this.setupSearchCriteria();
    this.changeStatusToDataFound();
  }

  /**
   * Indicates if the given item is the primary one.
   *
   * @param item any
   * @returns boolean
   */
  protected isPrimary(item: any): boolean {
    const isIt: boolean =
      _isObject(this.primaryRecord) &&
      (this.primaryRecord.id === item.id);

    return isIt;
  }

  /**
   * Indicates if the given item is the selected one.
   *
   * @param item any
   * @returns boolean
   */
  protected isSelected(item: any): boolean {
    const isIt: boolean =
      _isObject(this.mergeCandidate) &&
      (this.mergeCandidate.id === item.id);

    return isIt;
  }

  /**
   * Indicates if the given change is valid.
   *
   * @param change SimpleChange
   * @returns boolean
   */
  protected isValidQueryChange(change: SimpleChange): boolean {
    const isIt: boolean =
      _isObject(change) &&
      _isString(change.currentValue) &&
      change.currentValue !== change.previousValue;

    return isIt;
  }

  /**
   * Loads the given page.
   *
   * @param page number
   * @returns void
   */
  protected loadPage(page: number): void {
    this.unselect();
    this.searchCriteria.setPageNumber(page - 1);
    this.fetchItems();
  }

  /**
   * Notifies the current item selection.
   *
   * @param item any
   * @returns void
   */
  protected notifySelection(item: any): void {
    if (!this.isPrimary(item)) {
      this.mergeCandidate = item;
      this.selectEvent.emit(this.mergeCandidate);
    }
  }

  /**
   * Resets to the default values.
   *
   * @returns void
   */
  protected reset(): void {
    this.searchResponse = undefined;
    this.unselect();
  }

  /**
   * Set the current search criteria.
   *
   * @returns void
   */
  protected setupSearchCriteria(): void {
    this.searchCriteria = new CastCrewSearchCriteria();
    this.searchCriteria.setPageSize(14);
    this.searchCriteria.setSort(new Sort('name', new Direction(DirectionEnum.Asc)));
    this.searchCriteria.setSearchType(new SearchType(SearchTypeEnum.Original));
  }

  /**
   * Updates the current query.
   *
   * @returns void
   */
  protected updateQuery(): void {
    this.searchCriteria.setQuery(this.query);
  }

  /**
   * Unselects the current character.
   *
   * @returns void
   */
  protected unselect(): void {
    this.mergeCandidate = undefined;
    this.selectEvent.emit(this.mergeCandidate);
  }
}
