import { Component, OnInit } from '@angular/core';

import { AuthHelper, OAuth2TokenInterface } from 'app/modules/auth/helpers/auth/auth.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { UserInterface, User } from '../../models/user.model';
import { UserManager } from '../../helpers/user-manager.helper';


@Component({
  selector: 'bolt-user-profile',
  template: require('./bolt-user-profile.html'),
  styles: [require('./bolt-user-profile.scss')]
})
export class BoltUserProfileComponent extends StormComponent implements OnInit {
  user: UserInterface = new User();
  accessToken: OAuth2TokenInterface;

  constructor(
    protected userManager: UserManager,
    protected authHelper: AuthHelper
  ) {
    super();
  }

  ngOnInit() {
    this.fetchUser();
    this.getToken();
  }

  /**
   * Get the token.
   *
   * @returns void
   */
  getToken(): void {
    this.accessToken = this.authHelper.getTokenObject();
  }

  /**
   * Fetches the user profile.
   *
   * @returns void
   */
  fetchUser(): void {
    this.status = this.componentStatuses.fetchingData;

    this.userManager.fetchAuthenticatedUserProfile().subscribe(
      (response: StormServiceResponseSingle) => {
        this.user = response.item;
        this.status = this.componentStatuses.dataFound;
      },
      () => {
        this.status = this.componentStatuses.error;
      }
    );
  }
}
