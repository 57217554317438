import { Title } from '../title.model';


export class Series extends Title {
  protected _language: string;

  constructor(data: any) {
    super(data);
  }

  /**
   * Returns the language.
   *
   * @returns string
   */
  getLanguage(): string {
    if (this._language === undefined) {
      this._language = this.locale.split('_')[0];
    }

    return this._language;
  }

  isSeries(): boolean {
    return true;
  }
}
