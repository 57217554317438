/**
 * @see `TitleAttributes`: Common attributes for all title types.
 * @see `Series`: Ensure that class and this enumerative are synchronized about public attributes.
 */
export enum SeriesAttributes {
  countryOfOriginId = 'countryOfOriginId',
  mpmFamilyId = 'mpmFamilyId',
  originalSpokenLocale = 'originalSpokenLocale',
  productionCompanyId = 'productionCompanyId',
  productionCompanyIds = 'productionCompanyIds',
  radarGroupId = 'radarGroupId',
  videoType = 'videoType'
}
