/**
 * @todo Move this out of this file.
 */
export interface XsltPropertyInterface {
  id: number;
  name: string;
  value: any;
  toString(): string;
}

export class XsltProperty implements XsltPropertyInterface {
  _id: number;
  _name: string;
  _value: any;

  constructor(attributes?: { [attr: string]: any }) {
    this.setAttributes(attributes);
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
  }

  toString(): string {
    return <string>this._value;
  }

  /**
   * Set the attributes.
   *
   * @param attributes \{ [attr: string]: any }
   * @returns Xslt
   */
  setAttributes(attributes?: { [attr: string]: any }): XsltProperty {
    if (attributes !== undefined) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }

    return this;
  }

  /**
   * Returns a new raw object with the inner content.
   *
   * @returns object
   */
  getRawObject(): object {
    const output: object = new Object();
    const names: string[] = Object.getOwnPropertyNames(XsltProperty.prototype);

    const getters: string[] = names.filter(
      (name: string) => {
        const result: boolean = !!Object.getOwnPropertyDescriptor(XsltProperty.prototype, name).get;
        return result;
      }
    );

    getters.forEach(
      (key: string) => {
        if (this[key] !== undefined) {
          output[key] = this[key];
        }
      }
    );

    return output;
  }
}
