import { isArray as _isArray } from 'lodash';

export class LocalizationListHelper {

  /**
   * Given a list of selected values and a new selected value gets the new selection list.
   * If the unique value is selected it should be the only one item selected.
   *
   * @param list number[]
   * @param selectedValue any
   * @param uniqueValue number
   * @returns number[]
   */
  static validateUniqueValues(list: number[], selectedValue: number, uniqueValue: number): number[] {
    if (selectedValue === uniqueValue && list.includes(uniqueValue)) {
      return [uniqueValue];
    } else {
      return list.filter((item: number) => item !== uniqueValue);
    }
  }

  /**
   * Given a list of selected values and a new selected value or group, gets the new selection list.
   * If the unique value is selected it should be the only one item selected.
   *
   * @param list number[]
   * @param event any
   * @param uniqueValue number
   * @returns number[]
   */
  static validateUniqueValuesWithGroups(list: number[], event: any, uniqueValue: number): number[] {
    if (
      event.value === uniqueValue ||
      (
        _isArray(event.value) &&
        event.value.some((item: any) => item.value === uniqueValue) &&
        event.group !== 'selectAll'
      )
    ) {
      return list.length === 0 ? [] : [uniqueValue];
    } else {
      return list.filter((item: number) => item !== uniqueValue);
    }
  }
}
