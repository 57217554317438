import { includes as _includes } from 'lodash';

import { StatusEnum } from './status.enum';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class Status {
  protected _value: StatusEnum;

  constructor(value: StatusEnum) {
    this.initialize(value);
  }

  get value(): StatusEnum {
    return this._value;
  }

  /**
   * Indicates if it is equals to the given status.
   *
   * @param otherStatus Status
   * @returns boolean
   */
  isEqualsTo(otherStatus: Status): boolean {
   return this.is(otherStatus.value);
  }

  /**
   * Indicates if it is finished.
   *
   * @returns boolean
   */
  isFinished(): boolean {
    return this.is(StatusEnum.Finished);
  }

  /**
   * Indicates if it is iddle.
   *
   * @returns boolean
   */
  isIddle(): boolean {
    return this.is(StatusEnum.Iddle);
  }

  /**
   * Indicates if it is running.
   *
   * @returns boolean
   */
  isRunning(): boolean {
    return this.is(StatusEnum.Running);
  }

  /**
   * Returns it as string.
   *
   * @returns string
   */
  toString(): string {
    return this._value.toString();
  }

  /**
   * Initializes the instance.
   *
   * @param value StatusEnum
   * @returns void
   */
  protected initialize(value: StatusEnum): void {
    const normalizedValue: string = String(value).trim().toLowerCase();

    if (_includes(StatusEnum, normalizedValue)) {
      this._value = value;
    } else {
      throw new ErrorHelper('Invalid value given for a status.');
    }
  }

  /**
   * Indicates if the given value is the stored one.
   *
   * @param value StatusEnum
   * @returns boolean
   */
  protected is(value: StatusEnum): boolean {
    const isIt: boolean = (this.value === value);
    return isIt;
  }
}
