import { FormControl, Validators } from '@angular/forms';
import { Language } from '@bolt/ui-shared/master-data';
import { isObject as _isObject } from 'lodash';

import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';


export abstract class RoleForm extends BoltForm {
  protected nameField: FormControl;
  protected notesField: FormControl;
  private key: string;

  constructor(fieldSpecs: any, defaultValues?: any) {
    super(fieldSpecs, defaultValues);
    this.key = this.getDefaultValue('key');
  }

  /**
   * Get the name field.
   *
   * @returns FormControl
   */
  getNameField(): FormControl {
    return this.nameField;
  }

  /**
   * Get the notes field.
   *
   * @returns FormControl
   */
  getNotesField(): FormControl {
    return this.notesField;
  }

  /**
   * Get the key attribute.
   *
   * @returns string
   */
  getKey(): string {
    return this.key;
  }

  toJson(): any {
    const data: any = {
      name: this.getNameField().value,
      notes: this.getNotesField().value,
      originalLanguageId: Language.ENGLISH_ID
    };

    return data;
  }

  protected setupFields(): void {
    this.setupName();
    this.setupNotes();
  }

  /**
   * Set up the name field.
   *
   * @returns void
   */
  protected setupName(): void {
    this.nameField = new FormControl(
      this.getDefaultValue('name'),
      [
        Validators.required,
        Validators.maxLength(this.fieldSpecs.name.maxLength)
      ]
    );

    this.addControl('name', this.nameField);
  }

  /**
   * Set up the notes field.
   *
   * @returns void
   */
  protected setupNotes(): void {
    this.notesField = new FormControl(
      this.getDefaultValue('notes'),
      Validators.maxLength(this.fieldSpecs.notes.maxLength)
    );

    this.addControl('notes', this.notesField);
  }
}
