import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Country, Language, StormList, StormListItemInterface, StormListType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isString as _isString } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Template } from '../models/template/template.model';
import { TemplateService } from './template/template.service';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';


export abstract class AbstractManagerService {
  protected _currentLanguage: any;
  protected _currentTemplate: Template;
  protected _currentTerritory: any;
  protected _notificationLife: number;
  protected _templates: Template[];
  protected _languages: any[];
  protected _territories: any[];
  protected templatesFailed: boolean;

  constructor(
    protected appConfig: AppConfigProvider,
    protected notificationService: NotificationService,
    protected stormListsProvider: StormListsProvider,
    protected templateService: TemplateService
  ) {
    // The initialize method is called from subclasses.
  }

  get currentLanguage(): any {
    return this._currentLanguage;
  }

  get currentTemplate(): Template {
    return this._currentTemplate;
  }

  get currentTerritory(): any {
    return this._currentTerritory;
  }

  get languages(): any[] {
    return this._languages;
  }

  get notificationLife(): number {
    return this._notificationLife;
  }

  get templates(): Template[] {
    return this._templates;
  }

  get territories(): any[] {
    return this._territories;
  }

  /**
   * Returns the APP configuration provider.
   *
   * @returns AppConfigProvider
   */
  getAppConfig(): AppConfigProvider {
    return this.appConfig;
  }

  /**
   * Retrieves the template for the given ID.
   *
   * @param id number
   * @throws {Error}
   * @returns Template
   */
  getTemplate(id: number): Template {
    const templates: Template[] = this.templates.filter(
      (template: Template) => {
        const found: boolean = (template.id === id);
        return found;
      }
    );

    if (templates.length > 0) {
      return templates[0];
    } else {
      throw new Error('Invalid given template ID');
    }
  }

  /**
   * Adds the given message and error.
   *
   * @param message string
   * @param error ErrorHelper
   * @param container string
   * @returns void
   */
  handleError(message: string, error: ErrorHelper, container: string): void {
    this.notificationService.handleError(message, error, container);
  }

  /**
   * Adds the given message.
   *
   * @param message string
   * @param container string
   * @returns void
   */
  handleNotice(message: string, container: string): void {
    this.notificationService.handleNotice(message, undefined, container);
  }

  /**
   * Indicates if all need data to use the manager exists.
   *
   * @returns boolean
   */
  abstract hasAllNeededData(): boolean;

  /**
   * Indicates if it has a current language.
   *
   * @returns boolean
   */
  hasCurrentLanguage(): boolean {
    return _isString(this.currentLanguage);
  }

  /**
   * Indicates if it has a current territory.
   *
   * @returns boolean
   */
  hasCurrentTerritory(): boolean {
    return _isString(this.currentTerritory);
  }

  /**
   * Indicates if there are languages.
   *
   * @returns boolean
   */
  hasLanguages(): boolean {
    const hasIt: boolean = (this.languages.length > 0);
    return hasIt;
  }

  /**
   * Indicates if there are templates.
   *
   * @returns boolean
   */
  hasTemplates(): boolean {
    const hasIt: boolean = (
      (this.templates !== undefined) &&
      (this.templates.length > 0)
    );

    return hasIt;
  }

  /**
   * Indicates if there was any error fetching templates.
   *
   * @returns boolean
   */
  hasTemplatesFailed(): boolean {
    return this.templatesFailed;
  }

  /**
   * Indicates if there are territories.
   *
   * @returns boolean
   */
  hasTerritories(): boolean {
    const hasIt: boolean = (this.territories.length > 0);
    return hasIt;
  }

  /**
   * Resets all.
   *
   * @param fullReset boolean
   * @returns void
   */
  reset(fullReset: boolean): void {
    this.resetCurrentLanguage();
    this.resetCurrentTerritory();

    if (fullReset) {
      this.resetCurrentTemplate();
    }
  }

  /**
   * Set the current language.
   *
   * @param language any
   * @returns void
   */
  setCurrentLanguage(language: any): void {
    this._currentLanguage = language;
  }

  /**
   * Set the given template as current.
   *
   * @param template Template
   * @returns void
   */
  setCurrentTemplate(template: Template): void {
    this._currentTemplate = template;
  }

  /**
   * Set the given template ID as current.
   *
   * @param id number
   * @returns void
   */
  setCurrentTemplateById(id: number): void {
    const template: Template = this.getTemplate(id);
    this.setCurrentTemplate(template);
  }

  /**
   * Set the current territory.
   *
   * @param territory any
   * @returns void
   */
  setCurrentTerritory(territory: any): void {
    this._currentTerritory = territory;
  }

  /**
   * Cleans the notifications.
   *
   * @returns void
   */
  protected cleanNotifications(): void {
    this.notificationService.clean();
  }

  /**
   * Returns the sorting criteria for locales.
   *
   * @returns any
   */
  protected getLocaleSortingCriteria(): any {
    const criteria: CallableFunction = (itemA: StormListItemInterface, itemB: StormListItemInterface) => {
      if (itemA.value.name < itemB.value.name) {
        return -1;
      } else {
        if (itemA.value.name > itemB.value.name) {
          return 1;
        } else {
          return 0;
        }
      }
    };

    return criteria;
  }

  /**
   * Initializes the Manager.
   *
   * @returns void
   */
  protected initialize(): void {
    this._notificationLife = this.getAppConfig().get('ux.growl.life');

    this.cleanNotifications();
    this.setupLanguages();
    this.setupTemplates();
    this.setupTerritories();
  }

  /**
   * Returns an data item form given territory.
   *
   * @param territory any
   * @returns any
   */
  protected newTerritoryDataItem(territory: any): any {
    const data: any = {
      label: territory.name,
      value: territory.iso31661,
      group: territory.region,
      entity: <Country>territory
    };

    return data;
  }

  /**
   * Reset the current language.
   *
   * @returns void
   */
  protected resetCurrentLanguage(): void {
    this._currentLanguage = undefined;
  }

  /**
   * Reset the current template.
   *
   * @returns void
   */
  protected resetCurrentTemplate(): void {
    if (this.hasTemplates()) {
      this.setCurrentTemplate(this.templates[0]);
    }
  }

  /**
   * Reset the current territory.
   *
   * @returns void
   */
  protected resetCurrentTerritory(): void {
    this._currentTerritory = undefined;
  }

  /**
   * Sets up the languages.
   *
   * @returns void
   */
  protected setupLanguages(): void {
    this._languages = new Array();
    this.resetCurrentLanguage();

    this.stormListsProvider.getList(StormListType.language).subscribe(
      (list: StormList) => {
        if (list && list.collection && list.collection.length > 0) {
          const collection: StormListItemInterface[] = list.removeItem({ id: 0 }).collection;

          collection.sort(this.getLocaleSortingCriteria()).forEach(
            (language: StormListItemInterface) => {
              const item: any = {
                label: language.value.name,
                value: language.value.localeLanguage,
                entity: <Language>language.value
              };

              this.languages.push(item);
            }
          );
        } else {
          throw new Error('No languages retrieved from service.');
        }
      },
      (error: any) => {
        throw error;
      }
    );
  }

  /**
   * Set up the templates.
   *
   * @returns void
   */
  protected setupTemplates(): void {
    this.templatesFailed = false;

    this.templateService.fetch(
      (data: Template[]) => {
        this._templates = data;
        this.resetCurrentTemplate();
      },
      (error: ErrorHelper) => {
        this._templates = new Array();
        this.templatesFailed = true;

        this.handleError('Failed fetching templates.', error, 'global');
      }
    );
  }

  /**
   * Sets up the territories.
   *
   * @returns void
   */
  protected setupTerritories(): void {
    this._territories = new Array();
    this.resetCurrentTerritory();

    this.stormListsProvider.getList(StormListType.territory).subscribe(
      (list: StormList) => {
        if (list && list.collection && list.collection.length > 0) {
          const territoryAll: any = list.getItem(0).value;
          const collection: StormListItemInterface[] = list.removeItem({ id: territoryAll.id }).collection;

          this.territories.push(
            this.newTerritoryDataItem(territoryAll)
          );

          collection.sort(this.getLocaleSortingCriteria()).forEach(
            (territory: StormListItemInterface) => {
              this.territories.push(
                this.newTerritoryDataItem(territory.value)
              );
            }
          );
        } else {
          throw new Error('No territories retrieved from service.');
        }
      },
      (error: any) => {
        throw error;
      }
    );
  }
}
