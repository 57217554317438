import { Component, OnInit } from '@angular/core';

import { BooleanField } from '../../models/import/comparison/titles/title/field/boolean-field/boolean-field.model';
import { ManagerService } from '../../services/import/manager-service/manager.service';
import { profileConfig } from '../../config/profile.config';
import { TitlesEnum } from '../../models/import/comparison/titles/titles.enum';


@Component({
  selector: 'bolt-profile-import-series',
  template: require('./bolt-profile-import-series.html'),
  styles: [require('./bolt-profile-import-series.scss')]
})
export class BoltProfileImportSeriesComponent implements OnInit {
  protected labels: any[];
  protected titlesEnum: typeof TitlesEnum = TitlesEnum;

  constructor(protected managerService: ManagerService) {
    this.initialize();
  }

  ngOnInit() { }

  /**
   * Initializes Component
   *
   * @returns void
   */
  protected initialize(): void {
    this.labels = profileConfig.seriesLabels.common;
  }

  /**
   * Toggles the given boolean field.
   *
   * @param field BooleanField
   * @returns void
   */
  protected toggleLockedField(field: BooleanField): void {
    field.toggle();
    this.managerService.forceConfirmResolution(field);
  }
}
