import { Component, Input, Output, OnChanges, EventEmitter, ViewChild, SimpleChanges, OnDestroy } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Language, StormListItemInterface, StormListsInterface, StormListType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { SelectItem } from 'primeng';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { isArray as _isArray, isUndefined as _isUndefined } from 'lodash';

import { DataStatusEnum } from 'app/modules/common/models/data-status.enum';
import { Role } from '../../models/role.model';
import { RoleMetadataInterface } from '../../models/role-metadata.model';
import { FormFactoryService as RoleFormFactoryService } from 'app/modules/role/services/form-factory/form-factory.service';
import { RoleManagerMetadataManagement, RoleManager } from '../../helpers/role-manager/role-manager.helper';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { RoleForm } from '../../models/form/role-form.model';
import { RoleLocalizedAttributesEnum } from 'app/modules/role/components/bolt-role-localized-metadata-form/role-localized-attributes.enum';


export enum RoleMetadataManagerActions {
  CREATE_ROLE = <any>'CREATE_ROLE',
  EDIT = <any>'EDIT',
  CREATE = <any>'CREATE',
  DELETE = <any>'DELETE',
  LOCK = <any>'LOCK',
  UNLOCK = <any>'UNLOCK',
}

@Component({
  selector: 'bolt-role-metadata-manager',
  template: require('./bolt-role-metadata-manager.html'),
  styles: [require('./bolt-role-metadata-manager.scss')],
})
export class BoltRoleMetadataManagerComponent implements OnChanges, OnDestroy {
  @ViewChild('confirmLockUpdateModal') confirmLockUpdateModal: ModalDirective;
  @ViewChild('notifyOverrideMetadataModal') notifyOverrideMetadataModal: ModalDirective;
  @ViewChild('confirmDeleteAttributeModal') confirmDeleteAttributeModal: ModalDirective;
  @ViewChild('preventCancelModal') preventCancelModal: ModalDirective;

  @Input('BoltRoleMetadataManagerMetadataManager')
    metadataManager: RoleManagerMetadataManagement;

  @Output('BoltRoleMetadataManagerOnMetadataUpdated')
    metadataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  protected show: boolean = false;
  protected status: DataStatusEnum = DataStatusEnum.idle;
  protected statuses = DataStatusEnum;
  protected roleMetadataManagerActions = RoleMetadataManagerActions;
  protected metadataForm: RoleForm;
  protected defaultValues: object = new Object();
  protected roleMetadata: any;
  protected languages: SelectItem[] = [];
  protected originalLanguage: Language;
  protected stormLists: StormListsInterface;
  protected metadataManagerActions = RoleMetadataManagerActions;
  protected notificationsContainer = notificationsContainer;

  constructor(
    protected appConfig: AppConfigProvider,
    protected stormListsProvider: StormListsProvider,
    protected roleManager: RoleManager,
    protected notificationService: NotificationService,
    private roleFormFactory: RoleFormFactoryService
  ) {
    this.setupLanguages();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.metadataManager &&
      (changes.metadataManager.currentValue !== undefined)
    ) {
      if (
        RoleMetadataManagerActions.CREATE_ROLE === changes.metadataManager.currentValue.action ||
        RoleMetadataManagerActions.CREATE === changes.metadataManager.currentValue.action ||
        RoleMetadataManagerActions.EDIT === changes.metadataManager.currentValue.action
      ) {
        this.setupForm();
        this.toggleManager(true);
      } else {
        if (
          this.metadataManager.action === RoleMetadataManagerActions.LOCK ||
          this.metadataManager.action === RoleMetadataManagerActions.UNLOCK
        ) {

          this.setLockStatus();
        } else if (this.metadataManager.action === RoleMetadataManagerActions.DELETE) {
          this.deleteAttribute();
        }
      }
    } else {
      this.toggleManager(false);
    }
  }

  ngOnDestroy() {
    this.notificationService.clean(this.notificationsContainer.role.details.key);
    this.notificationService.clean(this.notificationsContainer.role.key);

  }

  /**
   * Builds the form, based on different business rules
   *
   * @return void
   */
  protected setupForm(): void {
    this.setDefaultFormValues();

    if (this.metadataManager.action === RoleMetadataManagerActions.CREATE) {
      this.defaultValues['language'] = this.metadataManager.role.language || [];

      this.metadataForm = this.roleFormFactory.buildLocalizedDataForm(this.defaultValues);
    } else if (this.metadataManager.action === RoleMetadataManagerActions.CREATE_ROLE) {
      this.defaultValues['language'] = [];

      this.metadataForm = this.roleFormFactory.buildOriginalDataForm(this.metadataManager.role);
    } else {
      this.metadataForm = this.roleFormFactory.buildLocalizedDataForm(this.metadataManager.roleMetadata.originalData);
    }
  }

  /**
   * Sets the defaults values.
   *
   * @returns void
   */
  protected setDefaultFormValues(): void {
    this.defaultValues = { territory: [0], productType: [0], account: [0] };
  }

  /**
   * Populates the dropdown components.
   *
   * @return void
   */
  protected setupLanguages(): void {

    this.stormListsProvider.getLists().subscribe((stormLists: StormListsInterface) => {

      if (!this.languages.length) {

        const languageList = stormLists.getList(StormListType.language).removeItem({ id: 0 });

        // Role's original Language must be always 'English'
        this.originalLanguage =
          <Language>languageList.findItem(['value.iso6391', 'en']).value;

        languageList.collection.forEach((listItem: StormListItemInterface, index: number) => {
          this.languages.push({
            label: (<Language>listItem.value).name,
            value: (<Language>listItem.value).id
          });
        });

      }

    });
  }

  /**
   * Toggle the RoleMetadata Form visibility
   *
   * @param show boolean
   * @return void
   */
  protected toggleManager(show: boolean): void {
    this.show = show;
    if (!show) {
      this.metadataManager = undefined;
    }
  }

  /**
   * Handles the Cancel flow, to toggle off the Form.
   * If unsaved changes are detected, the corresponding modal will be shown.
   *
   * @param force boolean
   * @return void
   */
  protected cancel(force: boolean): void {
    if (force || this.metadataForm.pristine) {
      this.toggleManager(false);
      this.preventCancelModal.hide();
      return;
    }

    if (!this.metadataForm.pristine) {
      this.preventCancelModal.show();
    }
  }

  /**
   * Handles the click on Save button
   *
   * @param force boolean
   * @return void
   */
  protected save(force: boolean = false): void {
    this.status = DataStatusEnum.fetchingData;

    const roleMetadata = new Role(
      Object.assign(
        new Object(),
        this.defaultValues,
        this.obtainAttributesNotInherited()
      )
    );

    if (this.metadataManager.action === RoleMetadataManagerActions.CREATE_ROLE) {
      roleMetadata.language = roleMetadata.originalLanguageId;
      this.createRole(roleMetadata);
      return;
    }

    // language equals to the original language
    roleMetadata.originalLanguageId = roleMetadata.language;
    this.roleManager.getLocaleFromLocaleIds(roleMetadata).subscribe(
      localeString => {
        // verify if given locale already exists
        this.roleManager.getRoleMetadataByLocale(
          this.metadataManager.role.id,
          localeString
        ).subscribe(

          roleMetadataByLocale => {

            if (!roleMetadataByLocale || force || this.metadataManager.action === RoleMetadataManagerActions.EDIT) {
              this.setMetadata(roleMetadata);
              return;
            }

            if (roleMetadataByLocale) {
              this.status = DataStatusEnum.idle;
              this.notifyOverrideMetadataModal.show();
            }

          },
          error => {
            this.status = DataStatusEnum.idle;
            this.notificationService.handleError('Error while trying to add the Customization', error);
          }
        );
      }
    );
  }

  /**
   * Handles the create Role flow
   *
   * @param role Role
   * @return void
   */
  protected createRole(role: Role): void {
    this.status = DataStatusEnum.fetchingData;

    this.roleManager.createRole(role).subscribe(
      serviceResponseSingle => {
        const singleRole = serviceResponseSingle.item;

        this.metadataUpdated.emit(singleRole);
        this.status = DataStatusEnum.idle;
        this.toggleManager(false);
        this.notificationService.handleNotice(
          'Role ' + singleRole.name + ' successfully added',
          '',
          this.notificationsContainer.role.key
        );
      },
      (error: ErrorHelper) => {
        this.metadataUpdated.emit(false);
        this.status = DataStatusEnum.idle;
        this.notificationService.handleError(
          'Error while trying to add the new Role',
          error,
          this.notificationsContainer.role.key
        );
      }
    );
  }

  /**
   * Handles the create/edit RoleMetadata flow
   *
   * @param metadata Role
   * @return void
   */
  protected setMetadata(metadata: Role): void {
    this.status = DataStatusEnum.fetchingData;

    this.roleManager.getLocaleFromLocaleIds(metadata).subscribe(
      locale => {

        this.roleManager.setRoleMetadata(
          this.metadataManager.role.id,
          locale,
          metadata,
          false
        ).subscribe(
          serviceResponseSingle => {
            this.metadataUpdated.emit(serviceResponseSingle.item);
            this.status = DataStatusEnum.idle;
            this.notifyOverrideMetadataModal.hide();
            this.toggleManager(false);

            this.notificationService.handleNotice(
              'Role successfully added', 'Localization created for ' +
              (<RoleMetadataInterface>serviceResponseSingle.item).locale
            );
          },
          (error: ErrorHelper) => {
            this.metadataUpdated.emit(false);
            this.status = DataStatusEnum.idle;
            this.notificationService.handleError('Error while trying to add the Customization', error);
          }
        );
      }
    );
  }

  /**
   * Handles the Lock/Unlock flow
   *
   * @param force boolean
   * @return void
   */
  protected setLockStatus(force: boolean = false): void {
    if (!force) {
      this.confirmLockUpdateModal.show();
    } else {
      this.status = DataStatusEnum.fetchingData;

      let lock: boolean;
      if (this.metadataManager.action === RoleMetadataManagerActions.LOCK) {
        lock = true;
      } else if (this.metadataManager.action === RoleMetadataManagerActions.UNLOCK) {
        lock = false;
      }

      this.roleManager.getLocaleFromLocaleIds(this.metadataManager.roleMetadata.originalData).subscribe(
        locale => {

          this.roleManager.setRoleMetadata(
            this.metadataManager.role.id,
            locale,
            new Role(new Object()),
            lock
          ).subscribe(
            serviceResponseSingle => {
              this.metadataUpdated.emit(serviceResponseSingle.item);
              this.status = DataStatusEnum.idle;
              this.confirmLockUpdateModal.hide();
              this.notificationService.handleNotice(
                'The localization ' + locale + ' is ' + (lock ? 'locked' : 'unlocked'),
                '',
                this.notificationsContainer.role.details.key
              );
            },
            (error: ErrorHelper) => {
              this.metadataUpdated.emit(false);
              this.status = DataStatusEnum.idle;
              this.notificationService.handleError(
                'Error while trying to update the Localization lock status',
                error,
                this.notificationsContainer.role.details.key
              );
            }
          );
        }
      );
    }
  }

  /**
   * Handles the Lock/Unlock flow
   *
   * @param force boolean
   * @return void
   */
  protected deleteAttribute(force: boolean = false): void {
    if (!force) {
      this.confirmDeleteAttributeModal.show();
    } else {
      this.status = DataStatusEnum.fetchingData;

      this.roleManager.getLocaleFromLocaleIds(this.metadataManager.roleMetadata.originalData).subscribe(
        locale => {
          this.roleManager.deleteRoleMetadataAttribute(
            this.metadataManager.role.id,
            locale,
            this.metadataManager.key
          ).subscribe(
            productMetadata => {
              this.metadataUpdated.emit(true);
              this.status = DataStatusEnum.idle;
              this.confirmDeleteAttributeModal.hide();
              this.notificationService.handleNotice(
                'Customization successfully deleted',
                '',
                this.notificationsContainer.role.details.key
              );
            },
            error => {
              this.metadataUpdated.emit(false);
              this.status = DataStatusEnum.idle;
              this.notificationService.handleError(
                'Error while trying to delete the Customization',
                error,
                this.notificationsContainer.role.details.key
              );
            }
          );
        }
      );
    }
  }

  /**
   * Retrieves the attributes to hide in role localized form
   *
   * @returns string[]
   */
  protected retrieveAttributesToHide(): string[] {
    const attributes = [];

    if (this.metadataManager.action === this.roleMetadataManagerActions.EDIT && !_isUndefined(this.metadataManager.key)) {
      attributes.push(RoleLocalizedAttributesEnum.language);

      if (this.metadataManager.key[0] === RoleLocalizedAttributesEnum.name) {
        attributes.push(RoleLocalizedAttributesEnum.notes);
      }
    }

    return attributes;
  }

  /**
   * Obtains the not inherited attributes to save.
   *
   * @returns any
   */
  protected obtainAttributesNotInherited(): any {
    const attributesNotInherited = this.metadataForm.toJson();

    const isEditAction: boolean = this.metadataManager.action === this.roleMetadataManagerActions.EDIT;
    const hasManagerKey: boolean = (
      !_isUndefined(this.metadataManager.key) &&
      this.metadataManager.key[0] === RoleLocalizedAttributesEnum.name
    );

    if (isEditAction && hasManagerKey) {
      delete attributesNotInherited[RoleLocalizedAttributesEnum.notes];
    }

    return attributesNotInherited;
  }
}
