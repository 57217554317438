import { includes as _includes } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { StatusEnum } from './status.enum';


export class Status {
  protected _error: ErrorHelper;
  protected _name: StatusEnum;

  constructor(data: any) {
    this.initialize(data);
  }

  get error(): ErrorHelper {
    return this._error;
  }

  get name(): StatusEnum {
    return this._name;
  }

  /**
   * Indicates if it is DOWNLOADED.
   *
   * @returns boolean
   */
  isDownloaded(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.DOWNLOADED
    );

    return isIt;
  }

  /**
   * Indicates if it is ERROR.
   *
   * @returns boolean
   */
  isError(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.ERROR
    );

    return isIt;
  }

  /**
   * Indicates if it is GENERATED.
   *
   * @returns boolean
   */
  isGenerated(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.GENERATED
    );

    return isIt;
  }

  /**
   * Indicates if it is COMPLETED.
   * @returns boolean
   */
  isCompleted(): boolean {
    return this.name === StatusEnum.COMPLETED;
  }

  /**
   * Indicates if it is PENDING.
   *
   * @returns boolean
   */
  isPending(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.PENDING
    );

    return isIt;
  }

  /**
   * Indicates if it is STARTED.
   *
   * @returns boolean
   */
  isStarted(): boolean {
    const isIt: boolean = (
      this.name === StatusEnum.STARTED
    );

    return isIt;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @throws ErrorHelper
   * @returns void
   */
  protected initialize(data: any): void {
    if (data.hasOwnProperty('jobStatus') && _includes(StatusEnum, data['jobStatus'])) {
      this._name = data['jobStatus'];

      if (data.hasOwnProperty('error') && data['error'].hasOwnProperty('description')) {
        let details: string;
        let code: number;

        if (data['error'].hasOwnProperty('code') && data['error']['code'] === '3000') {
          details = data['error']['details'];
          code = data['error']['code'];
        } else {
          details = (data['error'].hasOwnProperty('cause') ? data['error']['cause'] : undefined);
        }

        this._error = new ErrorHelper(data['error']['description'], code, details);
      } else {
        this._error = undefined;
      }
    } else {
      throw new ErrorHelper('Invalid configuration detected.');
    }
  }
}
