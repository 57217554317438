import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { AppConfigProvider, ConfigurationTypeEnum } from '@bolt/ui-shared/configuration';
import { sortBy as _sortBy } from 'lodash';

import { CollectionManagerHelper, CollectionManagerCollectionInterface } from 'app/modules/common/helpers/collection-manager.helper';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TitleService } from '../../services/title.service';
import { TitleType } from '../../models/title.model';


@Component({
  selector: 'bolt-title-series-season-list',
  template: require('./bolt-title-series-season-list.html'),
  styles: [require('./bolt-title-series-season-list.scss')],
  providers: [CollectionManagerHelper]
})
export class BoltTitleSeriesSeasonsListComponent extends StormComponent implements OnInit, OnChanges {
  seasonsCollection: CollectionManagerCollectionInterface;

  @Input('BoltTitleSeriesSeasonsListSerieId') protected serieId: number;
  @Input('BoltTitleSeasonEpisodesListSerieTitle') protected serieTitle: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected titleService: TitleService,
    protected collectionManager: CollectionManagerHelper,
  ) {
    super();
    this.initialize();
  }

  /**
   * Loads the seasons.
   *
   * @returns void
   */
  loadSeasons(): void {
    this.collectionManager.refreshCollection('seasons');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.seriesId && changes.seriesId.currentValue) {
      this.fetch();
    }
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Fetches episodes from the service.
   *
   * @return void
   */
  protected fetch(): void {
    this.status = this.componentStatuses.fetchingData;

    this.titleService.filterTitles({
      titleType: TitleType.season,
      seriesId: this.serieId,
      _size: this.appConfig.get('ux.page.filterTitles.seasonEpisodesFetchPageSize')
    })
    .subscribe(
      response => {
        response.collection = _sortBy(response.collection, 'seasonNumber');

        this.collectionManager.setCollectionItems('seasons', response.collection);

        this.status = (
          (response.collection.length > 0)
            ? this.componentStatuses.dataFound
            : this.componentStatuses.dataNotFound
        );
      },
      error => {
        this.status = this.componentStatuses.error;
      }
    );
  }

  /**
   * Returns the collection.
   *
   * @returns object
   */
  protected getCollection(): object {
    const collection: object = {
      name: 'seasons',
      collection: [],
      sorting: [],
      paginate: true,
      pagination: {
        page_size: this.appConfig.get('ux.dataTables.pageSize', 20, ConfigurationTypeEnum.number)
      },
    };

    return collection;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.collectionManager.setCollections([
      <CollectionManagerCollectionInterface>this.getCollection()
    ]);

    this.collectionManager.getCollectionHandler().subscribe(
      collectionHandler => {
        if (collectionHandler.name === 'seasons') {
          this.seasonsCollection = collectionHandler;
        }
      }
    );
  }
}
