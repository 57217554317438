import { FormControl, Validators } from '@angular/forms';
import { Language } from '@bolt/ui-shared/master-data';
import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';

export class LanguageForm extends BoltForm {
  protected language: Language;
  protected localeLanguage: FormControl;
  protected name: FormControl;
  protected iso6391: FormControl;
  protected languageType: FormControl;

  constructor(fieldSpecs: any, language?: Language) {
    super(fieldSpecs);
    this.language = language;
    this.setupFields();
  }

  toJson(): any {
    const output: any = {
      localeLanguage: this.localeLanguage.value,
      name: this.name.value,
      iso6391: this.iso6391.value,
      languageType: this.languageType.value
    };

    if (this.language) {
      output.id = this.language.id;
    }

    return output;
  }

  /**
   * Returns the "Locale Language" field.
   *
   * @returns FormControl
   */
  getLocaleLanguageField(): FormControl {
    return this.localeLanguage;
  }

  /**
   * Returns the "Name" field.
   *
   * @returns FormControl
   */
  getNameField(): FormControl {
    return this.name;
  }

  /**
   * Returns the "ISO6391" field.
   *
   * @returns FormControl
   */
  getIso6391Field(): FormControl {
    return this.iso6391;
  }

  /**
   * Returns the "languageType" field.
   *
   * @returns FormControl
   */
  getLanguageTypeField(): FormControl {
    return this.languageType;
  }

  protected setupFields(): void {
    this.setupLocaleLanguage();
    this.setupName();
    this.setupIso6391();
    this.setupLanguageType();
  }

  /**
   * Set up the "Locale Language" field.
   *
   * @returns void
   */
  protected setupLocaleLanguage(): void {
    this.localeLanguage = new FormControl(
      this.language ? this.language.localeLanguage : undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.localeLanguageMaxLength),
        Validators.pattern(/^[a-z]{1,32}(-[a-z]{1,32})?$/)
      ])
    );
    this.addControl('localeLanguage', this.localeLanguage);
  }

  /**
   * Set up the "Name" field.
   *
   * @returns void
   */
  protected setupName(): void {
    this.name = new FormControl(
      this.language ? this.language.name : undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.nameMaxLength),
        Validators.pattern(/^[A-Z]{1}([a-z]{1,255})(-[A-Z]{1}([a-z]{1,255}))?$/)

      ])
    );
    this.addControl('name', this.name);
  }

  /**
   * Set up the "ISO6391" field.
   *
   * @returns void
   */
  protected setupIso6391(): void {
    this.iso6391 = new FormControl(
      this.language ? this.language.iso6391 : undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.iso6391MaxLength),
        Validators.minLength(this.fieldSpecs.iso6391MinLength),
        Validators.pattern(/^[a-z]{1,255}$/)
      ])
    );
    this.addControl('iso6391', this.iso6391);
  }

  /**
   * Set up the "languageType" field.
   *
   * @returns void
   */
  protected setupLanguageType(): void {
    this.languageType = new FormControl(
      this.language ? this.language.languageType : undefined,
      Validators.required
    );

    this.addControl('languageType', this.languageType);
  }
}
