import { Injectable } from '@angular/core';
import { UserRole } from '@bolt/ui-shared/auth';
import { ActionTypeEnum, AppConfigurationManager, Privilege } from '@bolt/ui-shared/configuration';
import { Subscription } from 'rxjs';

import {
  isObject as _isObject, isString as _isString, isArray as _isArray, includes as _includes,
  isUndefined as _isUndefined
} from 'lodash';

import { AuthHelper } from '../../helpers/auth/auth.helper';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { User } from 'app/modules/user/models/user.model';


@Injectable({
  providedIn: 'root',
})
export class CapabilitiesManager {
  protected roleCapabilities: Map<string, Privilege>;
  protected user: User;
  protected userSubscription: Subscription;

  constructor(
    protected authHelper: AuthHelper,
    protected appConfigurationManager: AppConfigurationManager
  ) {
    this.initialize();
  }

  /**
   * Indicates if the current user has the privilege for the given module path and every capabilities.
   *
   * @param modulePath string
   * @param capabilities ActionTypeEnum[]
   * @returns boolean
   */
  hasUserPrivilegeOn(modulePath: string, capabilities: ActionTypeEnum[]): boolean {
    if (this.user.hasAdminRole()) {
      return true;
    }

    let hasPrivilege: boolean = _isArray(capabilities) && capabilities.length > 0;

    if (hasPrivilege) {
      hasPrivilege = capabilities.every(
        (capability: ActionTypeEnum) =>
          _includes(ActionTypeEnum, capability) &&
          this.checkPrivilege(`${modulePath}-${capability}`)
      );
    }

    return hasPrivilege;
  }

  /**
   * Returns all capabilities of the user on a module path.
   *
   * @param modulePath string
   * @returns privileges ActionTypeEnum[]
   */
  getUserCapabilitiesOn(modulePath: string): ActionTypeEnum[] {
    const allCapabilities: ActionTypeEnum[] = Object.values(ActionTypeEnum);

    if (this.user.hasAdminRole()) {
      return allCapabilities;
    }

    const capabilities: ActionTypeEnum[] = new Array();

    allCapabilities.forEach((capability: ActionTypeEnum) => {
      if (this.checkPrivilege(`${modulePath}-${capability}`)) {
        capabilities.push(capability);
      }
    });

    return capabilities;
  }

  /**
   * Indicates if the current user has privilege on the given module path.
   *
   * @param modulePath string
   * @throws ErrorHelper
   * @returns boolean
   */
  protected checkPrivilege(modulePath: string): boolean {
    let roles: any = this.getAllowedRolesFor(modulePath);

    if (_isUndefined(roles)) {
      return false;
    } else if (roles.length === 0) {
      return true;
    } else {
      roles = roles.map(
        (role: UserRole) => role.name
      );

      return this.hasUser() && this.user.hasRole(roles);
    }
  }

  /**
   * Get the roles for the given module path.
   *
   * @param modulePath string
   * @returns UserRole[]
   */
  protected getRoles(modulePath: string): UserRole[] {
    if (this.roleCapabilities.has(modulePath)) {
      return this.roleCapabilities.get(modulePath).roles;
    }
  }

  /**
   * Get the allowed roles for the given module path.
   *
   * @param modulePath string
   * @throws ErrorHelper
   * @returns UserRole[]
   */
  protected getAllowedRolesFor(modulePath: string): UserRole[] {
    if (_isString(modulePath)) {
      return this.getRoles(modulePath);
    } else {
      throw new ErrorHelper('Invalid given module');
    }
  }

  /**
   * Indicates if it has an user.
   *
   * @returns boolean
   */
  protected hasUser(): boolean {
    return _isObject(this.user);
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.userSubscription = this.authHelper.authenticatedUserStream.subscribe(
      (user: User) => {
        this.user = user;
      }
    );

    this.loadRoleCapabilities();
  }

  /**
   * Loads the current role capabilities.
   *
   * @returns void
   */
  protected loadRoleCapabilities(): void {
    this.roleCapabilities = this.appConfigurationManager.getRoleCapabilities();
  }
}
