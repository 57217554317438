import { EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { HttpError, AccordionItem } from '@bolt/ui-shared/common';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { MetadataSanitizer } from '@bolt/ui-shared/data-transform';
import { ProximityItem, SelectionItem } from '@bolt/ui-shared/droplists';
import { Country, GenreFilterHelper, GenreTitleType, Language, List, TypeEnum as ListType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Attribute, FunctionalMetadataFormHandlerService, FunctionalMetadataService, LevelModeEnum } from '@bolt/ui-shared/title';
import { Subscription } from 'rxjs';
import { isObject as _isObject, startCase as _startCase, isArray as _isArray, sortBy as _sortBy, isEmpty as _isEmpty, union as _union } from 'lodash';

import { FunctionalMetadata } from 'app/modules/list/models/functional-metadata/functional-metadata.model';
import { Action } from '../../models/level-data/action/action.model';
import { ActionEnum } from '../../models/level-data/action/action.enum';
import { ActionHandlerService } from '../../services/level-data/action-handler/action-handler.service';
import { CapabilitiesManager } from 'app/modules/auth/services/role/capabilities.manager';
import { MasterDataManager } from 'app/modules/masterData/services/manager/manager';
import { modulesPath } from 'app/modules/auth/services/role/modules-path';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { Title } from '../../models/title.model';
import { TitleService } from '../../services/title.service';
import { FormHandlerService } from '../../services/level-data/form-handler/form-handler.service';
import { TitleForm } from '../../models/level-data/form/title-form.model';
import { Episode } from '../../models/episode.model';
import { Feature } from '../../models/feature.model';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { ToggleKeyEnum } from 'app/modules/common/models/toggle-key.enum';
import { StormComponent } from 'app/modules/common/models/storm-component.model';


export abstract class BoltTitleLevelDataBaseFormComponent extends StormComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() title: Title;
  @Input() show: boolean;

  @Output('updated') updateEvent: EventEmitter<Title>;

  readonly levelMode: LevelModeEnum = LevelModeEnum.title;
  readonly limit: number = 5;

  protected form: TitleForm;
  protected functionalMetadata: SelectionItem[];
  protected genre: SelectionItem[];
  protected language: SelectionItem[];
  protected alternateLanguages: SelectionItem[];
  protected productionCompany: ProximityItem[];
  protected productionCompanies: SelectionItem[];
  protected subscriptions: Subscription;
  protected territory: SelectionItem[];

  constructor(
    protected actionHandler: ActionHandlerService,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected formHandler: FormHandlerService,
    protected functionalMetadataService: FunctionalMetadataService,
    protected functionalMetadataFormHandler: FunctionalMetadataFormHandlerService,
    protected masterDataManager: MasterDataManager,
    protected notificationService: NotificationService,
    protected titleService: TitleService
  ) {
    super();
    this.updateEvent = new EventEmitter();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this.formHandler.reset();
  }

  ngOnInit() {
    this.subscriptions = new Subscription();

    this.loadDependencies();
    this.listenActionChange();
    this.listenFormChange();
  }

  ngAfterViewInit() {
    setTimeout(() => this.disableFormIfMust());
  }

  /**
   * Retrieves an accordion items list for the given string list.
   *
   * @param items string[] | undefined
   * @returns AccordionItem[]
   */
  getAccordionItems(items: string[] | undefined): AccordionItem[] {
    let parsedItems: AccordionItem[];

    if (_isArray(items)) {
      parsedItems = items.map((item: string) => new AccordionItem(item));
    } else {
      parsedItems = [];
    }

    return parsedItems;
  }

  /**
   * Disables the form.
   *
   * @returns void
   */
  protected disableForm(): void {
    this.form.disable();
  }

  /**
   * Enables the form.
   *
   * @returns void
   */
  protected enableForm(): void {
    this.form.enable();
  }

  /**
   * Loads the given list.
   *
   * @param type ListType
   * @returns void
   */
  protected doLoadList(type: ListType): void {
    const genreTitleType: GenreTitleType = GenreTitleType[this.title.type.toString().toUpperCase()];
    const subs: Subscription = this.masterDataManager.getListFor(
      type,
      false,
      (list: List) => {
        if (type === ListType.productionCompany) {
          const items: SelectionItem[] = list.items;
          this.productionCompanies = _sortBy(items, 'label');
          this[type] = list.items.map(
            (item: SelectionItem) => new ProximityItem(item.label, item.value, item.group, item.source)
          );
        } else if (type === ListType.genre) {
          this[type] = GenreFilterHelper.filterGenres(list.items, genreTitleType);
        } else {
          let items: SelectionItem[] = list.items;

          if (type === ListType.language) {
            items = items.filter((item: SelectionItem) => item.value !== Language.ALL_ID);

            this.alternateLanguages = items.map(
              (item: SelectionItem) => {
                const name = item.source.alternateName ? item.source.alternateName : item.label;

                return new SelectionItem(name, item.value, item.source);
              }
            );
          } else if (type === ListType.territory) {
            items = _sortBy(items.filter((item: SelectionItem) => item.value !== Country.ALL_ID), 'label');
          }

          this[type] = items;
        }
      },
      (error: HttpError) => {
        this.notificationService.handleError(`Failed loading "${_startCase(type)}" list.`, error);
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Gets the array of Functional metadata for the current title
   *
   * @returns FunctionalMetadata[]
   */
  protected getTitleFunctionalMetadata(): FunctionalMetadata[] {
    let functionalMetadata: SelectionItem[] = [];
    const title = this.title as Episode | Feature;

    if (_isArray(title.functionalMetadata) && _isArray(this.functionalMetadata)) {
      functionalMetadata = this.functionalMetadata.filter(
        (metadata) => title.functionalMetadata.includes(metadata.value)
      );
    }

    return functionalMetadata.map((item: SelectionItem) => item.source);
  }

  /**
   * Disables the form based on the capability.
   *
   * @returns void
   */
  protected disableFormIfMust(): void {
    const hasIt: boolean =
      this.isMoratoriumTitle() ||
      !this.capabilitiesManager.hasUserPrivilegeOn(modulesPath.titles.details.original.path, [ActionTypeEnum.write]);

    if (hasIt) {
      this.disableForm();
    }
  }

  /**
   * Indicates if it has the needed parent data.
   *
   * @returns boolean
   */
  protected hasParentData(): boolean {
    return false;
  }

  /**
   * Handles the given action.
   *
   * @param action Action
   * @returns void
   */
  protected handleActionChange(action: Action): void {
    switch (action.value) {
      case ActionEnum.unlock:
        this.unlock();
        break;
      case ActionEnum.save:
        this.save(false);
        break;
      case ActionEnum.saveAndLock:
        this.save(true);
        break;
      default:
        // Do nothing.
        break;
    }
  }

  /**
   * Handles the change of "Not Applicable" over the old "functionalMetadata" field.
   *
   * @param isChecked boolean
   * @returns void
   */
  protected handleOldNotApplicableChange(isChecked: boolean): void {
    if ((this.title.isFeature() || this.title.isEpisode())) {
      const control: AbstractControl = this.form.get('functionalMetadata');

      if (isChecked) {
        control.reset();
        control.disable();
      } else {
        control.enable();
      }
    }
  }

  /**
   * Indicates if "Moratorium" is active for the current title.
   *
   * @todo Remove this method after UAT and call `title.moratorium`.
   * @returns boolean
   */
  protected isMoratoriumTitle(): boolean {
    const isIt: boolean = this.title.moratorium;
    return isIt;
  }

  /**
   * Indicates if it has to use the Functional Metadata as split fields.
   *
   * @returns boolean
   */
  protected shouldUseFunctionalMetadataAsFields(): boolean {
    const isIt: boolean = this.title.isFeature() || this.title.isEpisode()
      ? this.appConfigurationManager.getToggleValue(ToggleKeyEnum.functionalMetadataAsFieldsOn)
      : false;

    return isIt;
  }

  /**
   * Listens when the action changes.
   *
   * @returns void
   */
  protected listenActionChange(): void {
    const subs: Subscription = this.actionHandler.actionListener.subscribe(
      (action: Action) => {
        if (_isObject(action)) {
          this.handleActionChange(action);
        }
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Listens when the form changes.
   *
   * @returns void
   */
  protected listenFormChange(): void {
    const subs: Subscription = this.formHandler.formListener.subscribe(
      (form: TitleForm) => {
        this.form = form;
      }
    );

    this.subscriptions.add(subs);
  }

  /**
   * Loads the dependencies needed for the form.
   * By default no dependencies are loaded.
   *
   * @returns void
   */
  protected loadDependencies(): void {
    // Do nothing.
  }

  /**
   * Load the functional metadata.
   *
   * @returns void
   */
  protected loadFunctionalMetadata(): void {
    this.doLoadList(ListType.functionalMetadata);
  }

  /**
   * Load the genres.
   *
   * @returns void
   */
  protected loadGenres(): void {
    this.doLoadList(ListType.genre);
  }

  /**
   * Load the languages.
   *
   * @returns void
   */
  protected loadLanguages(): void {
    this.doLoadList(ListType.language);
  }

  /**
   * Load the parent titles.
   *
   * @returns void
   */
  protected loadParents(): void {
    // Do nothing.
  }

  /**
   * Load the production companies.
   *
   * @returns void
   */
  protected loadProductionCompanies(): void {
    this.doLoadList(ListType.productionCompany);
  }

  /**
   * Load the territories.
   *
   * @returns void
   */
  protected loadTerritories(): void {
    this.doLoadList(ListType.territory);
  }

  /**
   * Handles the saving action for the title.
   *
   * @returns void
   */
  protected save(shouldLock: boolean): void {
    this.disableForm();

    const metadata: any = this.form.toJson();
    let successAction: string;
    let errorAction: string;

    if (this.shouldUseFunctionalMetadataAsFields()) {
      const functionalMetadataObj = this.functionalMetadataFormHandler.getFormValuesByLevel(this.form, this.levelMode);

      Object.assign(metadata, functionalMetadataObj);
    } else if (this.title.isFeature() || this.title.isEpisode()) {
      Object.assign(metadata, this.form.getNotApplicableAndFunctionalMetadataAsJson());
    }

    if (shouldLock) {
      metadata.locked = true;
      successAction = 'saved and locked';
      errorAction = 'save and lock';
    } else {
      successAction = 'saved';
      errorAction = 'save';
    }

    const sanitizedMetadata = MetadataSanitizer.sanitizeEmptyLists(
      metadata, [ FeatureAttributes.genreId, FeatureAttributes.functionalMetadata ]
    );

    this.updateTitle(sanitizedMetadata, successAction, errorAction);
  }

  /**
   * Sets the functional metadata state.
   *
   * @returns void.
   */
  protected setFunctionalMetadataState(): void {
    if (this.title.isFeature() || this.title.isEpisode()) {
      const heritageDisclaimerControl: boolean = this.form.get(FeatureAttributes.heritageDisclaimer).value;

      this.functionalMetadataService.getAttributesByLevel(LevelModeEnum.title).forEach(
        (attribute: Attribute) => {
          if (<any>attribute.getName() === FeatureAttributes.excludeKidsMode && heritageDisclaimerControl) {
            this.form.get(FeatureAttributes.excludeKidsMode).disable();
          } else {
            this.form.get(attribute.getName()).enable();
          }
        }
      );
    }
  }

  /**
   * Handles the unlocking action for the title.
   *
   * @returns void
   */
  protected unlock(): void {
    this.updateTitle({ locked: false }, 'unlocked', 'unlock');
  }

  /**
   * Updates the title using the given metadata.
   *
   * @param metadata any
   * @param successAction string
   * @param errorAction string
   * @returns void
   */
  protected updateTitle(metadata: any, successAction: string, errorAction: string): void {
    const params: any = {
      productType: this.title.type,
      productId: this.title.id
    };

    this.titleService.updateProduct(params, metadata).subscribe(
      (response: StormServiceResponseSingle) => {
        this.updateEvent.emit(response.item);

        this.notificationService.handleSuccess('Title updated', `The Title Level Data has been ${successAction}.`);

        if (!response.item.locked) {
          this.enableForm();
        }
      },
      (error: any) => {
        this.notificationService.handleError(`Failed trying to ${errorAction} the Title Level Data`, error);
        this.enableForm();
      }
    );
  }
}
