import { FormControl, Validators } from '@angular/forms';
import { Language } from '@bolt/ui-shared/master-data';
import { indexOf as _indexOf } from 'lodash';

import { Title } from '../../../title.model';
import { FeatureAttributes } from '../../../title/feature/feature-attributes.enum';
import { TitleAttributes } from '../../../title/title-attributes.enum';
import { TitleLocalizedForm } from '../title-localized-form.model';

export class LanguageLocalizationForm extends TitleLocalizedForm {
  protected titleField: FormControl;
  protected altTitleField: FormControl;
  protected shortTitleField: FormControl;
  protected shortSynopsisField: FormControl;
  protected mediumSynopsisField: FormControl;
  protected fullSynopsisField: FormControl;
  protected keywordsField: FormControl;

  constructor(fieldSpecs: any, attributes: string[], title?: Title) {
    super(fieldSpecs, attributes, title);
    this.setupFields();
  }

  /**
   * Returns the title field.
   *
   * @returns FormControl
   */
  getTitleField(): FormControl {
    return this.titleField;
  }

  /**
   * Returns the alt title field.
   *
   * @returns FormControl
   */
  getAltTitleField(): FormControl {
    return this.altTitleField;
  }

  /**
   * Returns the short title field.
   *
   * @returns FormControl
   */
   getShortTitleField(): FormControl {
    return this.shortTitleField;
  }

  /**
   * Returns the short synopsis field.
   *
   * @returns FormControl
   */
  getShortSynopsisField(): FormControl {
    return this.shortSynopsisField;
  }

  /**
   * Returns the medium synopsis field.
   *
   * @returns FormControl
   */
  getMediumSynopsisField(): FormControl {
    return this.mediumSynopsisField;
  }

  /**
   * Returns the full synopsis field.
   *
   * @returns FormControl
   */
  getFullSynopsisField(): FormControl {
    return this.fullSynopsisField;
  }

  /**
   * Returns the keywords field.
   *
   * @returns FormControl
   */
  getKeywordsField(): FormControl {
    return this.keywordsField;
  }

  getMetadataAttributesControls(): FormControl[] {
    const controls: FormControl[] = super.getMetadataAttributesControls();

    if (!Language.isJapanese(this.getLanguageField().value)) {
      const index: number = _indexOf(controls, this.getAltTitleField());
      controls.splice(index, 1);
    }

    return controls;
  }

  toJson(): any {
    const data: any = super.toJson();

    if (!Language.isJapanese(this.getLanguageField().value)) {
      delete data[TitleAttributes.altTitle];
    }

    return data;
  }

  /**
   * Set up the title field.
   *
   * @returns void
   */
  protected setupTitleField(): void {
    this.titleField = new FormControl(
      this.getDefaultValue(TitleAttributes.title), Validators.maxLength(this.fieldSpecs[TitleAttributes.title].maxLength)
    );

    this.addControl(TitleAttributes.title, this.titleField);
  }

  /**
   * Set up the alt title field.
   *
   * @returns void
   */
  protected setupAltTitleField(): void {
    this.altTitleField = new FormControl(
      this.getDefaultValue(TitleAttributes.altTitle), Validators.maxLength(this.fieldSpecs[TitleAttributes.altTitle].maxLength)
    );

    this.addControl(TitleAttributes.altTitle, this.altTitleField);
  }

  /**
   * Set up the short title field.
   *
   * @returns void
   */
   protected setupShortTitleField(): void {
    this.shortTitleField = new FormControl(
      this.getDefaultValue(FeatureAttributes.shortTitle), Validators.maxLength(this.fieldSpecs[FeatureAttributes.shortTitle].maxLength)
    );

    this.addControl(FeatureAttributes.shortTitle, this.shortTitleField);
  }

  /**
   * Set up the short synopsis field.
   *
   * @returns void
   */
  protected setupShortSynopsisField(): void {
    this.shortSynopsisField = new FormControl(
      this.getDefaultValue(TitleAttributes.shortSynopsis), Validators.maxLength(this.fieldSpecs[TitleAttributes.shortSynopsis].maxLength)
    );

    this.addControl(TitleAttributes.shortSynopsis, this.shortSynopsisField);
  }

  /**
   * Set up the medium synopsis field.
   *
   * @returns void
   */
  protected setupMediumSynopsisField(): void {
    this.mediumSynopsisField = new FormControl(
      this.getDefaultValue(TitleAttributes.mediumSynopsis), Validators.maxLength(this.fieldSpecs[TitleAttributes.mediumSynopsis].maxLength)
    );

    this.addControl(TitleAttributes.mediumSynopsis, this.mediumSynopsisField);
  }

  /**
   * Set up the full synopsis field.
   *
   * @returns void
   */
  protected setupFullSynopsisField(): void {
    this.fullSynopsisField = new FormControl(
      this.getDefaultValue(TitleAttributes.fullSynopsis), Validators.maxLength(this.fieldSpecs[TitleAttributes.fullSynopsis].maxLength)
    );

    this.addControl(TitleAttributes.fullSynopsis, this.fullSynopsisField);
  }

  /**
   * Set up the keywords field.
   *
   * @returns void
   */
  protected setupKeywordsField(): void {
    this.keywordsField = new FormControl(this.getDefaultValue(FeatureAttributes.keywords));
    this.addControl(FeatureAttributes.keywords, this.keywordsField);
  }
}
