import { isObject as _isObject } from 'lodash';

import { SubtitleLockingStatus } from './subtitle-locking-status.model';


export class Localization {
  protected _locale: string;
  protected _lockedStatus: SubtitleLockingStatus;

  constructor(data: any) {
    this.initialize(data);
  }

  get locale(): string {
    return this._locale;
  }

  get lockedStatus(): SubtitleLockingStatus {
    return this._lockedStatus;
  }

  /**
   * Indicates if it is locked.
   *
   * @returns boolean
   */
  isLocked(): boolean {
    const isIt: boolean = (
      _isObject(this.lockedStatus) &&
      this.lockedStatus.locked
    );

    return isIt;
  }

  /**
   * Set the locked status.
   *
   * @param lockedStatus SubtitleLockingStatus
   * @returns void
   */
  setLockedStatus(lockedStatus: SubtitleLockingStatus): void {
    this._lockedStatus = lockedStatus;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const lockedStatus = (
      (data && data.hasOwnProperty('lockedStatus'))
        ? data.lockedStatus
        : undefined
    );

    this._locale = (
      (data && data.hasOwnProperty('locale'))
        ? data.locale
        : undefined
    );

    this.setLockedStatus(new SubtitleLockingStatus(lockedStatus));
  }
}
