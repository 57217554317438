import { isObject as _isObject, isNumber as _isNumber } from 'lodash';
import { Language } from '@bolt/ui-shared/master-data';

import { Category } from '../category/category.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';
import { Type } from '../type/type.model';


export abstract class Item {
  protected _acronym: string;
  protected _category: Category;
  protected _id: number;
  protected _itemsLockingStatus: LockingStatus;
  protected _language: Language;
  protected _locale: string;
  protected _lockingStatus: LockingStatus;
  protected _name: string;
  protected _notes: string;
  protected _phonetic: string;
  protected _type: Type;

  get acronym(): string {
    return this._acronym;
  }

  get category(): Category {
    return this._category;
  }

  get id(): number {
    return this._id;
  }

  get itemsLockingStatus(): LockingStatus {
    return this._itemsLockingStatus;
  }

  get language(): Language {
    return this._language;
  }

  get locale(): string {
    return this._locale;
  }

  get lockingStatus(): LockingStatus {
    return this._lockingStatus;
  }

  get name(): string {
    return this._name;
  }

  get notes(): string {
    return this._notes;
  }

  get phonetic(): string {
    return this._phonetic;
  }

  get type(): Type {
    return this._type;
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns any
   */
  asEndpointData(): any {
    const data: any = {
      id: this.id,
      locale: this.locale
    };

    return data;
  }

  /**
   * Indicates if it has a language.
   *
   * @returns boolean
   */
  hasLanguage(): boolean {
    return _isObject(this.language);
  }

  /**
   * Indicates if it's a localized item
   *
   * @return boolean
   */
  isLocalized(): boolean {
    return false;
  }

  /**
   * Indicates if it's an original item
   *
   * @return boolean
   */
  isOriginal(): boolean {
    return false;
  }

  /**
   * Indicates if the current item is persisted.
   *
   * @returns boolean
   */
  isPersisted(): boolean {
    return _isNumber(this.id);
  }

  /**
   * Set the language.
   *
   * @param language Language
   * @throws ErrorHelper
   * @returns void
   */
  setLanguage(language: Language): void {
    if ((language instanceof Language) && this.isCorrectLanguage(language)) {
      this._language = language;
    } else {
      throw new ErrorHelper(this.getInvalidLanguageReason(language));
    }
  }

  /**
   * Returns the first letter of the given word as an uppercase one.
   *
   * @param word string
   * @returns string
   */
  protected firstUppercaseLetter(word: string): string {
    const letter: string = word.charAt(0).toLocaleUpperCase();
    return letter;
  }

  /**
   * Returns the invalidation reason for the given language.
   *
   * @param language Language
   * @returns string
   */
  protected abstract getInvalidLanguageReason(language: Language): string;

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    const category: Category = new Category(data.category);
    const type: Type = new Type(data.itemType);

    this._category = category;
    this._id = data.id;
    this._itemsLockingStatus = LockingStatus.newFromItemsLockStatus(data);
    this._locale = data.locale;
    this._lockingStatus = new LockingStatus(data);
    this._name = (data.name || '');
    this._notes = data.notes;
    this._phonetic = data.phonetic;
    this._type = type;

    this.setAcronym();
  }

  /**
   * Indicates if it is the correct language for the item.
   *
   * @param language Language
   * @returns boolean
   */
  protected abstract isCorrectLanguage(language: Language): boolean;

  /**
   * Set the acronym.
   *
   * @returns void
   */
  protected setAcronym(): void {
    const words: string[] = this.name.trim().replace(/\s+/, ' ').split(' ');

    if (words.length === 1) {
      this._acronym = this.firstUppercaseLetter(words[0]);
    } else if (words.length >= 2) {
      const firstLetter: string = this.firstUppercaseLetter(words[0]);
      const lastLetter: string = this.firstUppercaseLetter(words[words.length - 1]);

      this._acronym = `${firstLetter}${lastLetter}`;
    }
  }
}
