import { Account, Country, Language, ProductType } from '@bolt/ui-shared/master-data';
import { isArray as _isArray, isEqual as _isEqual, sortBy as _sortBy } from 'lodash';

import { LockingStatus } from 'app/shared/models/locking-status/locking-status.model';

export class CreditLocale {
  protected language: Language;
  protected lockingStatus: LockingStatus;
  protected territory: Country[];
  protected sortedTerritory: Country[];

  constructor(data: any) {
    this.language = data.language;
    this.lockingStatus = new LockingStatus(data);
    this.territory = data.territory;
    this.sortedTerritory = _sortBy(this.territory, (territory: Country) => territory.id);
  }

  getLanguage(): Language {
    return this.language;
  }

  getTerritory(): Country[] {
    return this.territory;
  }

  getLockingStatus(): LockingStatus {
    return this.lockingStatus;
  }

  setLockingStatus(value: LockingStatus): void {
    this.lockingStatus = value;
  }

  /**
   * Get the locale for the current values.
   *
   * @returns string
   */
  getLocale(): string {
    const territories: string = this.territory.map((territory: Country) => territory.iso31661).join(',');

    const locale: string = [
      this.language.localeLanguage,
      territories,
      [ProductType.ALL_VALUE],
      [Account.ALL_VALUE],
    ].join('_');

    return locale;
  }

  /**
   * Get the locale in numbers for the current values.
   *
   * @returns string
   */
  getLocaleInNumbers(): string {
    const territories: string = this.territory.map((territory: Country) => territory.id).join(',');

    const locale: string = [
      this.language.id,
      territories,
      [ProductType.ALL_ID],
      [Account.ALL_ID],
    ].join('_');

    return locale;
  }

  /**
   * Indicates if the stored territory list is equals to the given territory list.
   *
   * @param territories Country[]
   * @throws Error
   * @returns boolean
   */
  isTerritoryEqualsTo(territories: Country[]): boolean {
    if (_isArray(territories)) {
      const isIt: boolean = _isEqual(
        _sortBy(territories, (territory: Country) => territory.id),
        this.sortedTerritory
      );

      return isIt;
    } else {
      throw new Error('Invalid territories given.');
    }
  }
}
