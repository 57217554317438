import { Injectable } from '@angular/core';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { isUndefined as _isUndefined } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { Details as ExportJobDetails } from '../models/export-job/details/details.model';
import { ExportCandidate } from '../models/export-candidate/export-candidate.model';
import { ExportFormatEnum } from '../models/export-format.enum';
import { ExportRecord } from '../models/history/export-record/export-record.model';
import { ExportValueEnum as CreditsExportValue } from '../models/credits/export-value.enum';
import { GroupCriteriaEnum } from '../models/group-criteria.enum';
import { ResultReport as ExportResultReport } from '../models/export-job/result-report/result-report.model';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { Status as ExportJobStatus } from '../models/export-job/status/status.model';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { StringEncoderService } from 'app/modules/common/services/string-encoder/string-encoder.service';
import { ExportLocaleType } from '../models/export-locale-type.enum';
import { Episode } from 'app/modules/title/models/episode.model';
import { TitleType } from 'app/modules/title/models/title.model';


@Injectable({
  providedIn: 'root'
})
export class ExportService extends BoltAbstractService {
  constructor(
    protected appRoutes: AppRoutesService,
    protected authHttp: AuthHttp,
    protected stringEncoderService: StringEncoderService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Checks the status of an export job.
   *
   * @param jobId number
   * @returns Observable<ExportJobStatus>
   */
  checkExportStatus(jobId: number): Observable<ExportJobStatus> {
    const url: string =  this.generateUrl(
      'metadataExport.exportService.checkExportStatus.endpoint',
      { '{jobId}': jobId }
    );

    const obs: Observable<ExportJobStatus> = this.doGetRequestAsObservable({ url: url }).pipe(
      map(
        (response: StormServiceResponseSingle) => new ExportJobStatus({ jobStatus: response.item.status })
      )
    );

    return obs;
  }

  /**
   * Downloads the file with the given parameters.
   *
   * @param id number
   * @param format ExportFormatEnum
   * @returns Observable<Blob>
   */
  downloadFile(id: number, format: ExportFormatEnum, consolidated: boolean = false): Observable<Blob> {
    const params: any = {
      '{jobId}': id
    };

    let endpoint: string;
    let responseType: string;

    if (format === ExportFormatEnum.ZIP) {
      endpoint = 'metadataExport.exportService.downloadFile.archive.endpoint';
      responseType = 'blob';
    } else if (consolidated) {
      endpoint = 'metadataExport.exportService.downloadFile.endpoint';
      params['{format}'] = 'consolidated';
      responseType = 'text';
    } else {
      endpoint = 'metadataExport.exportService.downloadFile.endpoint';
      params['{format}'] = format;
      responseType = 'text';
    }

    const request: any = {
      url: this.generateUrl(endpoint, params),
      options: {
        responseType: responseType,
        observe: 'response'
      }
    };

    const obs: Observable<Blob> = this.doGetRequestAsObservable(request).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          const rawBody = response.item.body;
          const contentType = response.item.headers.get('content-type');
          const data = this.stringEncoderService.newBlobFor(rawBody, contentType);

          return data;
        }
      )
    );

    return obs;
  }

  fetchExportResultReport(jobId: number): Observable<ExportResultReport> {
    const url: string = this.generateUrl(
      'metadataExport.exportService.fetchExportResultReport.endpoint',
      { '{jobId}': jobId }
    );

    const obs: Observable<ExportResultReport> = this.doGetRequestAsObservable({ url: url }).pipe(
      map(
        (response: StormServiceResponseSingle) => new ExportResultReport(response.item)
      )
    );

    return obs;
  }

  /**
   * Fetches the export history.
   *
   * @param criteria any
   * @returns Observable<SearchResponse>
   */
  fetchHistory(criteria: any): Observable<SearchResponse> {
    const url: string = this.generateUrl(
      'metadataExport.exportService.fetchHistory.endpoint',
      undefined,
      criteria
    );

    const obs: Observable<SearchResponse> = this.doGetRequestAsObservable({ url: url }).pipe(
      map(
        (response: StormServiceResponseSingle) => {
          const searchResponse: SearchResponse = new SearchResponse(
            response.item,
            (data: any) => new ExportRecord(data)
          );

          return searchResponse;
        }
      )
    );

    return obs;
  }

  /**
   * Start the process to generate an export file with the given criteria
   *
   * @param candidates ExportCandidate[]
   * @param format ExportFormatEnum
   * @param groupCriteria GroupCriteriaEnum
   * @param creditsExport CreditsExportValue
   * @param includeEidrLevel2 boolean
   * @param overrideLocalEpisodeRunningOrder boolean
   * @param exportLocaleType ExportLocaleType
   * @returns Observable<ExportJobDetails>
   */
  startExport(
    candidates: ExportCandidate[],
    format: ExportFormatEnum,
    groupCriteria: GroupCriteriaEnum,
    creditsExport: CreditsExportValue,
    includeEidrLevel2: boolean,
    overrideLocalEpisodeRunningOrder: boolean,
    exportLocaleType: ExportLocaleType
  ): Observable<ExportJobDetails> {

    const url: string = this.generateUrl(
      this.getStartingExportEndpoint(groupCriteria, exportLocaleType),
      { '{format}': format.toLowerCase() },
    );

    const request: any = {
      url: url,
      body: candidates.map(
        (candidate: ExportCandidate) => {
          const entry: object = candidate.asEndpointData();

          entry['includeEidrLv2'] = includeEidrLevel2;

          if (creditsExport !== CreditsExportValue.NONE) {
            entry['castCrewType'] = creditsExport;
          }

          if (candidate.getTitle().isEpisode()) {
            entry['isCompleteSeason'] = true;
            entry['useLocalAirRunningOrder'] = overrideLocalEpisodeRunningOrder;
          }

          return entry;
        }
      )
    };

    const obs: Observable<ExportJobDetails> = this.doPostRequestAsObservable(request).pipe(
      map(
        (response: StormServiceResponseSingle) => new ExportJobDetails(response.item)
      )
    );

    return obs;
  }

  /**
   * Start the process to generate an export file with the given criteria
   *
   * @param candidates ExportCandidate[]
   * @param groupCriteria GroupCriteriaEnum
   * @param exportLocaleType ExportLocaleType
   * @returns Observable<ExportJobDetails>
   */
  startFullTitleExport(
    candidates: ExportCandidate[],
    groupCriteria: GroupCriteriaEnum,
    exportLocaleType: ExportLocaleType
  ): Observable<ExportJobDetails> {

    const queryParams: any = {
      titleId: candidates[0].getTitle().id,
      titleType: candidates[0].getTitle().type.toString().toLocaleUpperCase(),
      localeType: exportLocaleType
    };

    if (exportLocaleType === ExportLocaleType.disney_svod) {
      queryParams.localeType = ExportLocaleType.allTerritories;
      queryParams.account = 'DISNEYSVOD';
    }

    const url: string = this.generateUrl(
      this.getStartingExportEndpoint(groupCriteria, exportLocaleType),
      null,
      queryParams
    );

    const request: any = {
      url: url,
      body: candidates.map(
        (candidate: ExportCandidate) => {
          const entry: object = candidate.asEndpointData();

          if (candidate.getTitle().isEpisode()) {
            entry['isCompleteSeason'] = true;
          }

          return entry;
        }
      )
    };

    const obs: Observable<ExportJobDetails> = this.doGetRequestAsObservable(request).pipe(
      map(
        (response: StormServiceResponseSingle) => new ExportJobDetails(response.item)
      )
    );

    return obs;
  }

  /**
   * Start the process to generate an export file with the given criteria
   *
   * @param candidates ExportCandidate[]
   * @param groupCriteria GroupCriteriaEnum
   * @param exportLocaleType ExportLocaleType
   * @returns Observable<ExportJobDetails>
   */
  startFullSeasonExport(
    candidates: ExportCandidate[],
    groupCriteria: GroupCriteriaEnum,
    exportLocaleType: ExportLocaleType
  ): Observable<ExportJobDetails> {

      const queryParams: any = {
        titleId: (candidates[0].getTitle() as Episode).seasonId,
        titleType: TitleType.season.toString().toUpperCase(),
        localeType: exportLocaleType
      };

      if (exportLocaleType === ExportLocaleType.disney_svod) {
        queryParams.localeType = ExportLocaleType.allTerritories;
        queryParams.account = 'DISNEYSVOD';
      }

      const url: string = this.generateUrl(
        this.getStartingExportEndpoint(groupCriteria, exportLocaleType),
        null,
        queryParams
      );

      const request: any = {
        url: url,
        body: candidates.map(
          (candidate: ExportCandidate) => {
            const entry: object = candidate.asEndpointData();

            if (candidate.getTitle().isEpisode()) {
              entry['isCompleteSeason'] = true;
            }

            return entry;
          }
        )
      };

      const obs: Observable<ExportJobDetails> = this.doGetRequestAsObservable(request).pipe(
        map(
          (response: StormServiceResponseSingle) => new ExportJobDetails(response.item)
        )
      );

      return obs;
    }

  /**
   * Returns the endpoint for starting the export by using the given criteria.
   *
   * @param groupCriteria GroupCriteriaEnum
   * @returns string
   */
  protected getStartingExportEndpoint(groupCriteria: GroupCriteriaEnum, exportLocaleType: ExportLocaleType): string {

    let section: string;
    let endpoint: string;

    if (exportLocaleType === ExportLocaleType.regular) {
      section = _isUndefined(groupCriteria) || groupCriteria === null ? 'job' : `${groupCriteria}Job`;
      endpoint = `metadataExport.exportService.startExport.${section}.endpoint`;
    } else {
      endpoint = `metadataExport.exportService.startExport.fullTitle.endpoint`;
    }

    return endpoint;
  }
}
