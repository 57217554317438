import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isObject as _isObject } from 'lodash';

import { StormComponent } from './storm-component.model';


/**
 * This class implements the common functionality in the Role, Talent and Character metadata
 * and defines the common behavior
 */
export abstract class BoltMetadataComponent extends StormComponent {
  protected confirmDeleteLocalizationModal: NgbModalRef;
  protected stateConfirmDialog: boolean;

  constructor(
    protected modalService: NgbModal,
    protected notificationService: NotificationService
  ) {
    super();
  }

  /**
   * This method allows to show the modal of deletion confirmation and handles the user answer
   *
   * @param content Reference to the modal
   * @returns void
   */
  protected openConfirmModal(content: any): void {
    this.enableConfirmDialog();
    this.confirmDeleteLocalizationModal = this.modalService.open(content);
    this.confirmDeleteLocalizationModal.result.then(
      (result: any) => {
        if (result) {
          this.deleteLocalization();
        }
      }
    );
  }

  /**
   * This method removes the selected locale for the current talent
   *
   * @returns void
   */
  protected abstract deleteLocalization(): void;

  /**
   * This method disables the confirm dialog setting its state to false
   *
   * @returns void
   */
  protected disableConfirmDialog(): void {
    this.stateConfirmDialog = false;
  }

  /**
   * This method enables the confirm dialog setting its state to true
   *
   * @returns void
   */
  protected enableConfirmDialog(): void {
    this.stateConfirmDialog = true;
  }

  /**
   * Return the current state of the confirmation dialog
   *
   * @returns boolean
   */
  protected isConfirmDialogEnabled(): boolean {
    return this.stateConfirmDialog;
  }
}
