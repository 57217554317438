import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Account, TypeEnum as MasterDataType } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Observable } from 'rxjs/internal/Observable';
import { isObject as _isObject, isUndefined as _isUndefined } from 'lodash';

import { MasterDataService } from 'app/modules/masterData/services/master-data.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { AccountForm } from 'app/modules/masterData/models/account/account-form.model';


@Component({
  selector: 'bolt-master-data-account-handler-panel',
  template: require('./bolt-master-data-account-handler-panel.html'),
  styles: [require('./bolt-master-data-account-handler-panel.scss')]
})
export class BoltMasterDataAccountHandlerPanelComponent implements OnChanges, OnDestroy {
  @Input() account: Account | undefined;
  @Input() show: boolean;
  @Output('cancelled') cancelEvent: EventEmitter<void>;
  @Output('saved') saveEvent: EventEmitter<Account>;

  protected actionSubscription: Subscription;
  protected form: AccountForm;
  protected fieldSpecs: any;
  protected isCreating: boolean;

  constructor(
    protected masterDataService: MasterDataService,
    protected notificationService: NotificationService,
    protected appConfig: AppConfigProvider
  ) {
    this.cancelEvent = new EventEmitter();
    this.saveEvent = new EventEmitter();
    this.show = false;

    this.setupFieldSpecs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_isObject(changes.account)) {
      this.isCreating = _isUndefined(this.account);
    }

    if (_isObject(changes.show)) {
      if (this.show) {
        this.form = new AccountForm(this.fieldSpecs, this.account);
      } else {
        this.form = undefined;
        this.cancelActionSubscription();
      }
    }
  }

  ngOnDestroy() {
    this.cancelActionSubscription();
  }

  /**
   * Cancels the action over the account.
   *
   * @returns void
   */
  protected cancel(): void {
    this.cancelEvent.emit();
  }

  /**
   * Cancels the subscription for the triggered action.
   *
   * @returns void
   */
  protected cancelActionSubscription(): void {
    if (_isObject(this.actionSubscription)) {
      this.actionSubscription.unsubscribe();
    }
  }

  /**
   * Returns the max length for the given field.
   *
   * @param field string
   * @returns number
   */
  protected getMaxLengthFor(field: string): number {
    return this.fieldSpecs[`${field}MaxLength`];
  }

  /**
   * Indicates if it has to block the cancel button.
   *
   * @returns boolean
   */
  protected hasBlockCancel(): boolean {
    return this.form.disabled;
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const isIt: boolean = this.form.invalid || this.form.disabled;
    return isIt;
  }

  /**
   * Save the current account.
   *
   * @returns void
   */
  protected save(): void {
    this.form.disable();

    const data: any = this.form.toJson();
    const action: Observable<any> = this.isCreating
      ? this.masterDataService.create(MasterDataType.account, data)
      : this.masterDataService.update(MasterDataType.account, this.account.id, data);

    this.actionSubscription = action.subscribe(
      (newAccount: Account) => {
        this.notificationService.handleSuccess('The Account was saved successfully.');
        this.saveEvent.emit(newAccount);
      },
      (error: ErrorHelper) => {
        this.notificationService.handleError('Failed saving the new account', error);
        this.form.enable();
      }
    );
  }

  /**
   * Setup the specs for the fields
   *
   * @returns void
   */
  protected setupFieldSpecs(): void {
    this.fieldSpecs = {
      nameMaxLength: this.appConfig.get('forms.masterdata.account.fields.name.maxLength'),
      codeMaxLength: this.appConfig.get('forms.masterdata.account.fields.code.maxLength')
    };
  }
}
