import * as moment from 'moment';
import { isObject as _isObject, isUndefined as _isUndefined, isString as _isString } from 'lodash';
import { Account, Country, Language, ProductType } from '@bolt/ui-shared/master-data';

import { Locale } from './locale/locale.model';
import { TypeEnum as LocaleTypeEnum } from './locale/type/type.enum';
import { Title } from 'app/modules/title/models/title.model';


/**
 * Base interface for all localized Bolt entities
 */
export interface LocalizedInterface  {

  // Localized entities have these common attributes
  id?: number;
  language?: number | Language;
  territory?: number[] | Country[];
  productType?: number[] | ProductType[];
  account?: number[] | Account[];
  locale?: string;
  localeObject?: Locale;
  locked?: boolean;
  localized?: boolean;

  localizations?: any[] | Title[];
  localizedFields?: { [attr: string]: any };

  // optional attributes
  lastModified?: string | moment.Moment;
  lastModifiedBy?: string;
  lockedDate?: string | moment.Moment;
  lockedBy?: string;

  setAttributes?(attributes: { [attr: string]: any }): LocalizedInterface;
  getEntityName?(): string;
  getRawObject?(): object;
  toString?(): string;
  hasLocaleObject?(): boolean;
}

export class Localized implements LocalizedInterface {

  // Localized entities have these common attributes
  protected _account: number[] | Account[];
  protected _id: number;
  protected _language: number | Language;
  protected _lastModified: string | moment.Moment;
  protected _lastModifiedBy: string;
  protected _locale: string;
  protected _localeType: LocaleTypeEnum;
  protected _localeObject: Locale;
  protected _localizations: any[];
  protected _localized: boolean;
  protected _localizedFields?: { [attr: string]: any };
  protected _locked: boolean;
  protected _lockedBy: string;
  protected _lockedDate: string | moment.Moment;
  protected _productType: number[] | ProductType[];
  protected _territory: number[] | Country[];

  constructor(attributes?: { [attr: string]: any }) {
    this.initialize(attributes);
  }

  setAttributes(attributes?: { [attr: string]: any }): LocalizedInterface {
    if (attributes !== undefined) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }
    return this;
  }

  get account(): number[] | Account[] {
    return this._account;
  }

  set account(account: number[] | Account[]) {
    this._account = account;
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get lastModified(): string | moment.Moment {
    if (!_isUndefined(this._lastModified)) {
      this._lastModified = moment(this._lastModified);
    }

    return this._lastModified;
  }

  set lastModified(lastModified: string | moment.Moment) {
    this._lastModified = lastModified;
  }

  get lastModifiedBy(): string {
    return this._lastModifiedBy;
  }

  set lastModifiedBy(lastModifiedBy: string) {
    this._lastModifiedBy = lastModifiedBy;
  }

  get language(): number | Language {
    return this._language;
  }

  set language(language: number | Language) {
    this._language = language;
  }

  get territory(): number[] | Country[] {
    return this._territory;
  }

  set territory(territory: number[] | Country[]) {
    this._territory = territory;
  }

  get productType(): number[] | ProductType[] {
    return this._productType;
  }

  set productType(productType: number[] | ProductType[]) {
    this._productType = productType;
  }

  get locale(): string {
    return this._locale;
  }

  set locale(locale: string) {
    this._locale = locale;
  }

  get localeType(): LocaleTypeEnum {
    return this._localeType;
  }

  set localeType(type: LocaleTypeEnum) {
    this._localeType = type;
  }

  get localeObject(): Locale {
    return this._localeObject;
  }

  set localeObject(locale: Locale) {
    this._localeObject = locale;
  }

  get localizations(): any[] {
    throw new Error(this.getEntityName() + ' :: needs to implement localizations getter');
  }

  set localizations(localizations: any[]) {
    throw new Error(this.getEntityName() + ' :: needs to implement localizations setter');
  }

  get localized(): boolean {
    return this._localized;
  }

  set localized(localized: boolean) {
    this._localized = localized;
  }

  get localizedFields(): { [attr: string]: any } {
    return this._localizedFields;
  }

  set localizedFields(localizedFields: { [attr: string]: any }) {
    this._localizedFields = localizedFields;
  }

  get locked(): boolean {
    return this._locked;
  }

  set locked(locked: boolean) {
    this._locked = locked;
  }

  get lockedBy(): string {
    return this._lockedBy;
  }

  set lockedBy(lockedBy: string) {
    this._lockedBy = lockedBy;
  }

  get lockedDate(): string | moment.Moment {
    if (this._lockedDate !== undefined) {
      this._lockedDate = moment(this._lockedDate);
    }
    return this._lockedDate;
  }

  set lockedDate(lockedDate: string | moment.Moment) {
    this._lockedDate = lockedDate;
  }

  getEntityName(): string {
    return this.constructor.name;
  }

  getRawObject(): object {

    const names = Object.getOwnPropertyNames(Localized.prototype);

    const getters = names.filter((name) => {
      const result = Object.getOwnPropertyDescriptor(Localized.prototype, name);
      return !!result.get;
    });

    const object = { };
    getters.forEach(key => {
      if (this[key] !== undefined) {
        object[key] = this[key];
      }
    });

    return object;

  }

  /**
   * Indicates if it has a locale object.
   *
   * @returns boolean
   */
  hasLocaleObject(): boolean {
    const hasIt: boolean = _isObject(this.localeObject);
    return hasIt;
  }

  /**
   * Indicates if the given field is a localized one.
   *
   * @param field string
   * @returns boolean
   */
  isLocalizedField(field: string): boolean {
    const isIt: boolean = (
      _isObject(this.localizedFields) &&
      _isString(this.localizedFields[field]) &&
      this.localizedFields[field] === this.locale
    );

    return isIt;
  }

  /**
   * Indicates if the given field is a computed one.
   *
   * @param field string
   * @returns boolean
   */
  isComputedField(field: string): boolean {
    const isIt: boolean = (
      _isObject(this.localizedFields) &&
      _isString(this.localizedFields[field]) &&
      this.localizedFields[field] !== this.locale
    );

    return isIt;
  }

  toString(): string {
    throw new Error(this.getEntityName() + ' :: needs to implement toString() method');
  }

  /**
   * Initializes the instance.
   *
   * @param attributes any
   */
  protected initialize(attributes: { [attr: string]: any }): void {
    this.setAttributes(attributes);
    this.setLocaleObject(attributes);
  }

  /**
   * Set the locale object.
   *
   * @param attributes any
   */
  protected setLocaleObject(attributes: { [attr: string]: any }): void {
    if (!_isUndefined(attributes)) {
      this._localeObject = new Locale(attributes);
    }
  }
}
