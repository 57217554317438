import { Country, Language } from '@bolt/ui-shared/master-data';

import { TitleType } from 'app/modules/title/models/title.model';


export abstract class Item {
  protected _id: number;
  protected _language: Language;
  protected _legalTitle: string;
  protected _territory: Country;
  protected _type: TitleType;

  /**
   * This is mandatory for Typescript and it doesn't have sense.
   */
  constructor() { }

  get id(): number {
    return this._id;
  }

  get language(): Language {
    return this._language;
  }

  get legalTitle(): string {
    return this._legalTitle;
  }

  get territory(): Country {
    return this._territory;
  }

  get type(): TitleType {
    return this._type;
  }

  /**
   * Returns the inner data formatted as endpoint expects.
   *
   * @returns object
   */
  asEndpointData(): object {
    const data: object = {
      language: this.language.name,
      territory: this.territory.name,
      type: this.type.toString()
    };

    return data;
  }

  /**
   * Set the ID.
   *
   * @param id number
   * @returns void
   */
  protected setId(id: number): void {
    this._id = id;
  }

  /**
   * Set the language.
   *
   * @param language Language
   * @returns void
   */
  protected setLanguage(language: Language): void {
    this._language = language;
  }

  /**
   * Set the legal title.
   *
   * @param legalTitle string
   * @returns void
   */
  protected setLegalTitle(legalTitle: string): void {
    this._legalTitle = legalTitle;
  }

  /**
   * Set the territory.
   *
   * @param territory Country
   * @returns void
   */
  protected setTerritory(territory: Country): void {
    this._territory = territory;
  }

  /**
   * Set the type.
   *
   * @param type TitleType
   * @returns void
   */
  protected setType(type: TitleType): void {
    this._type = type;
  }
}
