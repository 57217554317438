import { Component, Input } from '@angular/core';
import { NotificationService } from '@bolt/ui-shared/notification';
import { WindowWrapperService } from '@bolt/ui-shared/common';
import { Observable, of } from 'rxjs';

import { BoltMergeManagerComponent } from '../bolt-merge-manager/bolt-merge-manager.component';
import { CandidateTypeEnum } from '../../models/candidate-type.enum';
import { Character } from 'app/modules/character/models/character.model';
import { MergeService } from '../../services/merge.service';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';

@Component({
  selector: 'bolt-merge-character-manager',
  template: require('./bolt-merge-character-manager.html'),
  styles: [require('./bolt-merge-character-manager.scss')]
})
export class BoltMergeCharacterManagerComponent extends BoltMergeManagerComponent {
  @Input() character: Character;

  protected mergeCandidate: Character;

  constructor(
    protected mergeService: MergeService,
    protected notificationService: NotificationService,
    protected windowWrapper: WindowWrapperService
  ) {
    super(mergeService, notificationService, windowWrapper);
  }

  protected mapResponse(response: StormServiceResponseSingle): Observable<Character> {
    return of(new Character(response.item[0]));
  }

  /**
   * Merge the current merge candidate into the current character.
   *
   * @returns void
   */
  protected merge(): void {
    this.doMerge(this.mergeCandidate.id, this.character.id, CandidateTypeEnum.character);
  }
}
