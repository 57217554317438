import { Params } from '@angular/router';
import { isBoolean as _isBoolean, isObject as _isObject, isString as _isString, isUndefined as _isUndefined } from 'lodash';
import { SearchCriteria as BaseSearchCriteria } from '@bolt/ui-shared/searching';
import { Account, Country, ProductType } from '@bolt/ui-shared/master-data';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { SearchType } from 'app/shared/models/search-criteria/search-type/search-type.model';


export class SearchCriteria extends BaseSearchCriteria {
  protected language: string;
  protected searchType: SearchType;
  protected type: string;
  protected hideCastCredit: boolean;
  protected radarProductIdCheckedStatus: boolean;

  constructor() {
    super();
  }

  asEndpointData(): any {
    const params: any = super.asEndpointData();

    if (this.hasLanguage()) {
      params.locale = this.obtainLocaleForEndpoint();
    }

    if (this.hasSearchType()) {
      params.searchType = this.getSearchType().value;
    }

    if (this.hasType()) {
      params.type = this.obtainTypeForEndpoint();
    }

    params.hideCastCredit = this.getHideCastCredit();

    if (this.radarProductIdCheckedStatus) {
      params.q = params.q.split(',').map((rid: string) => {
        return `rid${rid.trim()}`;
      }).join(',');
    }

    return params;
  }

  asUrlOutput(): any {
    // Do NOT send searchType in URL.
    const params: any = {
      ...super.asUrlOutput(),
      language: this.getLanguage() || '',
      type: this.getType() || '',
      'hide-cast-credit': this.getHideCastCredit(),
      'filter-by-rid': this.getRadarProductIdCheckedStatus()
    };

    return params;
  }

  /**
   * Returns the language.
   *
   * @returns string
   */
  getLanguage(): string {
    return this.language;
  }

  /**
   * Returns the search type.
   *
   * @returns SearchType
   */
  getSearchType(): SearchType {
    return this.searchType;
  }

  /**
   * Returns the type.
   *
   * @returns string
   */
  getType(): string {
    return this.type;
  }

  /**
   * Returns the Hide Cast Credit.
   *
   * @returns boolean
   */
  getHideCastCredit(): boolean {
    return this.hideCastCredit;
  }

  /**
   * Returns the Radar Product ID checked status.
   *
   * @returns boolean
   */
  getRadarProductIdCheckedStatus(): boolean {
    return this.radarProductIdCheckedStatus;
  }

  /**
   * Indicates if it has a language.
   *
   * @returns boolean
   */
  hasLanguage(): boolean {
    const hasIt: boolean = (_isString(this.getLanguage()) && (this.getLanguage().length > 0));
    return hasIt;
  }

  /**
   * Indicates if it has a type.
   *
   * @returns boolean
   */
  hasSearchType(): boolean {
    const hasIt: boolean = _isObject(this.getSearchType());
    return hasIt;
  }

  /**
   * Indicates if it has a type.
   *
   * @returns boolean
   */
  hasType(): boolean {
    const hasIt: boolean = (_isString(this.getType()) && (this.getType().length > 0));
    return hasIt;
  }

  readUrlParams(params: Params): void {
    super.readUrlParams(params);
    this.setLanguage(params.language);
    this.setType(params.type);

    const hideCastCredit: boolean | undefined = this.readParamAsBoolean(params, 'hide-cast-credit');
    this.setHideCastCredit(hideCastCredit);

    const radarProductIdCheckedStatus: boolean | undefined = this.readParamAsBoolean(params, 'filter-by-rid');
    this.setRadarProductIdCheckedStatus(radarProductIdCheckedStatus);

    // The searchType cannot be read from URL.
    this.setSearchType(params.searchType);
  }

  reset(): void {
    super.reset();
    this.setLanguage(undefined);
    this.setSearchType(undefined);
    this.setType(undefined);
    this.setHideCastCredit(true);
    this.setRadarProductIdCheckedStatus(false);
  }

  /**
   * Set the language.
   *
   * @param language string
   * @throws ErrorHelper if the given language is invalid.
   * @returns void
   */
  setLanguage(language: string): void {
    if (_isUndefined(language)) {
      this.language = undefined;
    } else if (_isString(language)) {
      this.language = language.trim();
    } else {
      throw new ErrorHelper('Invalid language given.');
    }
  }

  /**
   * Set the search type.
   *
   * @param searchType SearchType
   * @throws ErrorHelper if the given search type is invalid.
   * @returns void
   */
  setSearchType(searchType: SearchType): void {
    if (_isUndefined(searchType)) {
      this.searchType = undefined;
    } else if (searchType instanceof SearchType) {
      this.searchType = searchType;
    } else {
      throw new ErrorHelper('Invalid search type given.');
    }
  }

  /**
   * Set the type.
   *
   * @param type string
   * @throws ErrorHelper if the given type is invalid.
   * @returns void
   */
  setType(type: string): void {
    if (_isUndefined(type)) {
      this.type = undefined;
    } else if (_isString(type)) {
      this.type = type.trim();
    } else {
      throw new ErrorHelper('Invalid type given.');
    }
  }

  /**
   * Set the Hide Cast Credit
   *
   * @param hideCastCredit boolean
   * @returns void
   */
  setHideCastCredit(hideCastCredit: boolean): void {
    if (_isBoolean(hideCastCredit)) {
      this.hideCastCredit = hideCastCredit;
    } else {
      throw new ErrorHelper('Invalid Hide Cast Credits given.');
    }
  }

  /**
   * Set the Radar Product ID checked status
   *
   * @param isChecked boolean
   * @returns void
   */
  setRadarProductIdCheckedStatus(isChecked: boolean): void {
    if (_isBoolean(isChecked)) {
      this.radarProductIdCheckedStatus = isChecked;
    } else {
      throw new ErrorHelper('Invalid Radar Product ID checked status given.');
    }
  }

  /**
   * Returns the locale for endpoint.
   *
   * @returns string
   */
  protected obtainLocaleForEndpoint(): string {
    const sections: string[] = new Array(
      this.getLanguage(),
      Country.ALL_VALUE,
      ProductType.ALL_VALUE,
      Account.ALL_VALUE
    );

    const output: string = sections.join('_');

    return output;
  }

  /**
   * Returns the type for endpoint.
   *
   * @returns string
   */
  protected obtainTypeForEndpoint(): string {
    const oldTypes: string[] = this.getType().split(',');
    const newTypes: string[] = new Array();

    if (oldTypes.length > 0) {
      const groups: Map<string, string[]> = new Map();

      oldTypes.forEach(
        (option: string) => {
          const keys: string[] = option.split(':');

          if (!groups.has(keys[0])) {
            groups.set(keys[0], new Array());
          }

          if (keys.length > 1) {
            groups.get(keys[0]).push(keys[1]);
          }
        }
      );

      groups.forEach(
        (values: string[], key: string) => {
          const entries: string = ((values.length > 0) ? `:${values.join(',')}` : '');
          newTypes.push(`${key}${entries}`);
        }
      );
    }

    const output: string = newTypes.join(';');

    return output;
  }
}
