import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { BoltPageRoleComponent } from './components/bolt-page-role/bolt-page-role.component';
import { BoltPageRoleDetailsComponent } from './components/bolt-page-role-details/bolt-page-role-details.component';
import { BoltPageRoleListComponent } from './components/bolt-page-role-list/bolt-page-role-list.component';
import { modulesPath } from '../auth/services/role/modules-path';


const roleConf: any = {
  component: BoltPageRoleDetailsComponent,
  canActivate: [AuthGuard],
  data: {
    boltAuthAware: modulesPath.titles.credits.role.path,
    title: 'Role',
  }
};

export const roleRoutes: Routes = [
  {
    path: 'role',
    component: BoltPageRoleComponent,
    children: [
      {
        path: '',
        component: BoltPageRoleListComponent,
        canActivate: [AuthGuard],
        data: {
          boltAuthAware: modulesPath.menu.role.path,
          title: 'Role List',
        }
      },
      {
        path: ':roleId',
        ...roleConf
      },
      {
        // The roleName is not necessary anymore, but we keep this entry only for backward compatibility.
        path: ':roleId/:roleName',
        ...roleConf
      }
    ]
  }
];

export const roleModuleRouting: ModuleWithProviders = RouterModule.forChild(roleRoutes);
