import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HttpError } from '@bolt/ui-shared/common';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { ActionTypeEnum, AppConfigurationManager } from '@bolt/ui-shared/configuration';
import { Account, Country, Language, ProductType } from '@bolt/ui-shared/master-data';
import { find as _find, cloneDeep as _cloneDeep, isEmpty as _isEmpty } from 'lodash';
import { debounceTime } from 'rxjs/operators';

import { CapabilitiesManager } from '../../../../modules/auth/services/role/capabilities.manager';
import { Episode } from '../../models/episode.model';
import { EpisodeAttributes } from '../../models/title/episode/episode-attributes.enum';
import { FeatureAttributes } from '../../models/title/feature/feature-attributes.enum';
import { LocalizationManagerAction } from '../bolt-title-localization-manager/localization-manager-action.enum';
import { LocalizationSpecific } from '../../models/localization-specific.enum';
import { Title, TitleType } from '../../models/title.model';
import { StormListsProvider } from 'app/modules/list/providers/storm-lists.provider';
import { ProductLayoutHelper } from '../../helpers/product-layout/product-layout.helper';
import { Season } from '../../models/season.model';
import { modulesPath } from '../../../../modules/auth/services/role/modules-path';
import { TitleAttributes } from '../../models/title/title-attributes.enum';
import { TitleMetadataFactory } from '../../helpers/title-factory/title-factory.helper';
import { TypeEnum } from 'app/modules/common/models/locale/type/type.enum';
import { BoltRatingService } from '../../services/bolt-rating.service';


export interface BoltTitleMetadataFiltersInterface {
  filter: string[];
  groupBy: string;
}

@Component({
  selector: 'bolt-title-metadata-filters',
  template: require('./bolt-title-metadata-filters.html'),
  styles: [require('./bolt-title-metadata-filters.scss')],
  encapsulation: ViewEncapsulation.None
})
export class BoltTitleMetadataFiltersComponent implements OnInit, OnChanges {
  @Input('BoltTitleMetadataFiltersProduct') product: Title;

  @Output('BoltTitleMetadataFiltersFiltersUpdated')
  filtersUpdated: EventEmitter<BoltTitleMetadataFiltersInterface> = new EventEmitter<BoltTitleMetadataFiltersInterface>();

  @Output('localizationCreated') localizationCreatedEvent: EventEmitter<Title> = new EventEmitter();

  protected readonly createAction: LocalizationManagerAction = LocalizationManagerAction.create;

  protected activeGroup: any;
  protected activeGroupKey: string;
  protected attributes: string[];
  protected creationTitle: any;
  protected filter: string;
  protected form: FormGroup;
  protected groups: any[] = [];
  protected localizationPrivileges: Map<LocalizationSpecific, boolean> = new Map();
  protected prevGroupBy: string;
  protected showLocalizationManager: boolean = false;

  constructor(
    protected appConfig: AppConfigProvider,
    protected appConfigurationManager: AppConfigurationManager,
    protected capabilitiesManager: CapabilitiesManager,
    protected formBuilder: FormBuilder,
    protected productLayoutHelper: ProductLayoutHelper,
    protected route: ActivatedRoute,
    protected stormListsProvider: StormListsProvider,
    protected boltRatingService: BoltRatingService
  ) {
    this.form = formBuilder.group({
      filter: [],
      groupBy:  [],
    });

    this.initializeLocalizationPrivileges();
  }

  ngOnInit() {
    const activeGroup = _find(this.groups, { key: this.route.snapshot.params['group-by'] });

    if (this.route.snapshot.params['group-by'] && (activeGroup !== undefined)) {
      this.activeGroup = activeGroup;
      this.activeGroupKey = activeGroup.key;
    }

    this.form.valueChanges.pipe(debounceTime(200)).subscribe(
      val => {
        this.filtersUpdated.emit(
          <BoltTitleMetadataFiltersInterface>{
            filter: val.filter ? val.filter.trim().split(' ') : [],
            groupBy: val.groupBy
          }
        );
      }
    );
  }

  ngOnChanges(changes) {
    const haveSetGroup: boolean = (
      !_isEmpty(changes.product.currentValue) &&
      (
        _isEmpty(changes.product.previousValue) ||
        (changes.product.currentValue.id !== changes.product.previousValue.id)
      )
    );

    if (haveSetGroup) {
      this.setGroupByOptions();
    }
  }

  /**
   * Set the group.
   *
   * @returns void
   */
  setGroupByOptions(): void {
    const attributeGroups =
      this.productLayoutHelper.getProductLayoutAttributeGroups(this.product.type);

    this.groups = [];

    Object.keys(attributeGroups).map(
      (groupName: any) => {
        if (attributeGroups[groupName].attributes.length && this.hasReadPrivilegeOn(attributeGroups[groupName])) {
          this.groups.push(attributeGroups[groupName]);
        }
      }
    );

    this.activeGroup = this.groups[0];
    this.activeGroupKey = this.groups[0].key;
  }

  /**
   * Set as active group the one with the given value.
   *
   * @param groupKey string
   * @returns void
   */
  setActiveGroup(groupKey: string): void {
    this.activeGroupKey = groupKey;
    this.activeGroup = _find(this.groups, { key: groupKey });
    this.resetFilter();
  }

  /**
   * Resets the current filter.
   *
   * @returns void
   */
  resetFilter(): void {
    (<FormControl>this.form.get('filter')).setValue('');
  }

  /**
   * Load localization privileges for the current user
   *
   * @returns void
   */
  protected initializeLocalizationPrivileges(): void {
    const moduleInfo: any = modulesPath.titles.details;
    const allowedCapabilities: ActionTypeEnum[] = [ActionTypeEnum.write];

    this.localizationPrivileges.set(
      LocalizationSpecific.LANGUAGE,
      this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo.language.path, allowedCapabilities)
    );

    this.localizationPrivileges.set(
      LocalizationSpecific.TERRITORY,
      this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo.territory.path, allowedCapabilities)
    );

    this.localizationPrivileges.set(
      LocalizationSpecific.ACCOUNT,
      this.capabilitiesManager.hasUserPrivilegeOn(moduleInfo.account.path, allowedCapabilities)
    );
  }

  /**
   * Check if the current user has write access on the active group
   *
   * @returns boolean
   */
  protected hasWritePrivilegeOnGroup(): boolean {
    const groupName: string = this.activeGroupKey.split('Specific')[0].toUpperCase();

    return this.localizationPrivileges.get(LocalizationSpecific[groupName]);
  }

  /**
   * Check if the current user has read access on the given group
   *
   * @returns boolean
   */
  protected hasReadPrivilegeOn(group: any): boolean {
    let hasIt: boolean = true;

    if (group.key === LocalizationSpecific.ACCOUNT) {
      hasIt = this.capabilitiesManager.hasUserPrivilegeOn(
        modulesPath.titles.details.account.path, [ActionTypeEnum.read]
      );
    }

    return hasIt;
  }

  /**
   * Check if the current user is able to see the add localization button
   *
   * @return boolean
   */
  protected hasDisplayAddLocalizationButton(): boolean {
    const hasIt: boolean = !this.isMoratoriumTitle() && this.activeGroup.newAttributeLabel && this.hasWritePrivilegeOnGroup();
    return hasIt;
  }

  /**
   * Indicates if "Moratorium" is active for the current title.
   *
   * @todo Remove this method after UAT and call `product.moratorium`.
   * @returns boolean
   */
  protected isMoratoriumTitle(): boolean {
    const isIt: boolean = this.product.moratorium;
    return isIt;
  }

  /**
   * Opens the localization manager side panel.
   *
   * @returns void
   */
  protected openLocalizationManager(): void {
    const languageAll: number = Language.ALL_ID;
    const territoryAll: number[] = [Country.ALL_ID];
    const productTypeAll: number[] = [ProductType.ALL_ID];
    const accountAll: number[] = [Account.ALL_ID];

    const data: any = {
      type: TitleType[<string>(this.route.snapshot.params['productType'])],
      id: Number(this.route.snapshot.params['productId'])
    };

    if (this.product.isSeason()) {
      data.seriesId = (<Season>this.product).seriesId;
    } else if (this.product.isEpisode()) {
      data.seasonId = (<Episode>this.product).seasonId;
    }

    this.attributes = [];

    switch (this.activeGroupKey) {
      case LocalizationSpecific.LANGUAGE:
        this.attributes.push(TitleAttributes.title);
        this.attributes.push(TitleAttributes.altTitle);
        this.attributes.push(TitleAttributes.shortSynopsis);
        this.attributes.push(TitleAttributes.mediumSynopsis);
        this.attributes.push(TitleAttributes.fullSynopsis);

        if (!this.product.isSeries()) {
          this.attributes.push(FeatureAttributes.keywords);
        }

        if (this.product.isFeature()) {
          this.attributes.push(FeatureAttributes.shortTitle);
        }

        data.localeType = TypeEnum.language;
        data.territory = territoryAll;
        data.productType = productTypeAll;
        data.account = accountAll;
      break;

      case LocalizationSpecific.TERRITORY:
        this.attributes.push(FeatureAttributes.functionalMetadata);
        this.attributes.push(FeatureAttributes.ratingId);
        this.attributes.push(FeatureAttributes.ratingSystemReasonId);
        this.attributes.push(FeatureAttributes.ratingReason);

        this.attributes.push(FeatureAttributes.runtimeSeconds);
        this.attributes.push(FeatureAttributes.ratingFilingNumberKmrb);
        this.attributes.push(FeatureAttributes.onairPlatformKcc);
        this.attributes.push(FeatureAttributes.onairDateKcc);

        if (this.product.isEpisode() || this.product.isFeature()) {
          this.attributes.push(FeatureAttributes.homeEntRatingId);
          this.attributes.push(FeatureAttributes.homeEntRatingSystemReasonId);
          this.attributes.push(FeatureAttributes.homeEntRatingReason);
        }

        if (this.product.isEpisode()) {
          this.attributes.push(EpisodeAttributes.firstAirReleaseDate);
          this.attributes.push(EpisodeAttributes.localAirRunningOrder);
          this.setControls(data);

        }

        if (this.product.isFeature()) {
          this.attributes.push(FeatureAttributes.firstDigitalRelease);
          this.attributes.push(FeatureAttributes.firstPhysicalRelease);
          this.attributes.push(FeatureAttributes.theatricalReleaseDate);
          this.attributes.push(FeatureAttributes.genreId);
          this.setControls(data);
        }

        data.localeType = TypeEnum.territory;
        data.language = languageAll;
        data.productType = productTypeAll;
        data.account = accountAll;
      break;

      case LocalizationSpecific.ACCOUNT:
        if (!this.product.isSeason()) {
          this.attributes.push(FeatureAttributes.functionalMetadata);
        }

        if (!this.product.isEpisode()) {
          this.attributes.push(FeatureAttributes.genreId);
          this.attributes.push(FeatureAttributes.primaryProductAssociation);
          this.attributes.push(FeatureAttributes.secondaryProductAssociation);
        }

        if (this.product.isFeature() || this.product.isEpisode()) {
          this.setControls(data);
        }

        data.localeType = TypeEnum.account,
        data.language = languageAll;
        data.territory = territoryAll;
        data.productType = productTypeAll;
      break;

      default:
        throw new HttpError('Invalid specific localization given for creating a locale');
    }

    data[TitleAttributes.originalData] = _cloneDeep(data);
    this.creationTitle = TitleMetadataFactory(TitleType[this.product.type], data);
    this.showLocalizationManager = true;
  }

  /**
   * Handles the close event of the localization manager
   *
   * @returns void
   */
  protected onCloseLocalizationManager(): void {
    this.showLocalizationManager = false;
  }

  /**
   * Handles the saved event from localization manager.
   *
   * @param title Title
   * @returns void
   */
  protected onLocalizationSaved(title: Title): void {
    this.localizationCreatedEvent.emit(title);
  }

  /**
   * Sets the current controls for heritage disclaimer and smoking disclaimer.
   *
   * @param data any
   * @returns void
   */
  protected setControls(data: any): void {
    data[FeatureAttributes.heritageDisclaimer] = this.product[FeatureAttributes.heritageDisclaimer];
    data[FeatureAttributes.smokingDisclaimer] = this.product[FeatureAttributes.smokingDisclaimer];
  }
}
