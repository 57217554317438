import { isArray as _isArray } from 'lodash';

import { AssociationList } from 'app/modules/cat/models/product/association-list/association-list.model';
import { CharacterType } from '../character-type.enum';


export class CharacterResponse {
  protected _id: number;
  protected _isLocalization: boolean;
  protected _language: string;
  protected _lastModified: Date;
  protected _lastModifiedBy: string;
  protected _name: string;
  protected _nameLocalization: string;
  protected _originalLanguageId: number;
  protected _products: AssociationList;
  protected _type: CharacterType;

  constructor(attributes?: { [attr: string]: any }) {
    this.initialize(attributes);
  }

  get id(): number {
    return this._id;
  }

  set id(id: number) {
    this._id = id;
  }

  get isLocalization(): boolean {
    return this._isLocalization;
  }

  set isLocalization(isLocalization: boolean) {
    this._isLocalization = isLocalization;
  }

  get nameLocalization(): string {
    return this._nameLocalization;
  }

  set nameLocalization(nameLocalization: string) {
    this._nameLocalization = nameLocalization;
  }

  get originalLanguageId(): number {
    return this._originalLanguageId;
  }

  set originalLanguageId(originalLanguageId: number) {
    this._originalLanguageId = originalLanguageId;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  get type(): CharacterType {
    return <CharacterType>this._type;
  }

  set type(type: CharacterType) {
    this._type = type;
  }

  get products(): AssociationList {
    return this._products;
  }

  set products(value: AssociationList) {
    this._products = value;
  }

  /**
   * Indicates if it has product associations.
   *
   * @returns boolean
   */
  hasProducts(): boolean {
    return this._products.hasProductAssociations();
  }

  /**
   * Sets the values for the attributes given in the 'attributes' object
   *
   * @param attributes any
   * @returns CharacterResponse
   */
  setAttributes(attributes?: { [attr: string]: any }): CharacterResponse {
    if (attributes !== undefined) {
      Object.keys(attributes).forEach(attr => this[attr] = attributes[attr]);
    }
    return this;
  }

  toString(): string {
    return this.name;
  }

  /**
   * Initializes the instance.
   *
   * @param data any
   * @returns void
   */
  protected initialize(data: any): void {
    this.setAttributes(data);
    this._products = new AssociationList(data);
  }
}
