import { CodeEnum } from './code.enum';


export class ExportError {
  protected code: CodeEnum;
  protected id: number;
  protected message: string;
  protected titleId: number;
  protected titleName: string;
  protected titleType: string;

  constructor(data: any) {
    if (data) {
      this.code = (data.hasOwnProperty('errorCode') ? data.errorCode : undefined);
      this.id = data.id;
      this.message = (data.hasOwnProperty('message') ? data.message : undefined);
      this.titleId = data.titleId;
      this.titleName = (data.hasOwnProperty('legalTitle') ? data.legalTitle : undefined);
      this.titleType = (data.hasOwnProperty('titleType') ? data.titleType : undefined);
    } else {
      throw new Error('Invalid data given for an error.');
    }
  }

  /**
   * Gets the error code.
   *
   * @returns CodeEnum
   */
  getCode(): CodeEnum {
    return this.code;
  }

  /**
   * Gets the ID.
   *
   * @returns number
   */
  getId(): number {
    return this.id;
  }

  /**
   * Gets the error message.
   *
   * @return string
   */
  getMessage(): string {
    return this.message;
  }

  /**
   * Gets the Title ID.
   *
   * @returns number
   */
  getTitleId(): number {
    return this.titleId;
  }

  /**
   * Gets the Title Name.
   *
   * @return string
   */
  getTitleName(): string {
    return this.titleName;
  }

  /**
   * Gets the Title Type.
   *
   * @returns string
   */
  getTitleType(): string {
    return this.titleType;
  }
}
