import { Injectable } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { isNumber as _isNumber } from 'lodash';
import { StringHelper } from '@bolt/ui-shared/common';

import { EditionForm } from 'app/modules/cat/models/unit/edition-form/edition-form.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ItemService } from '../../item/item.service';
import { ManagerService as UnitManager } from '../manager/manager.service';
import { Product } from '../../../models/product/product.model';
import { Status } from './status/status.model';
import { StatusEnum } from './status/status.enum';
import { StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { TypeEnum as ItemType } from '../../../models/type/type.enum';


@Injectable({
  providedIn: 'root',
})
export class BulkActionsHandlerService {
  protected actions: Array<Observable<StormServiceResponseSingle>>;
  protected statusNotifier: BehaviorSubject<Status>;
  protected _errors: any[];
  protected _status: Status;
  protected _statusListener: Observable<Status>;

  constructor(protected itemService: ItemService, protected unitManager: UnitManager) {
    this.initialize();
  }

  get errors(): any[] {
    return this._errors;
  }

  get status(): Status {
    return this._status;
  }

  get statusListener(): Observable<Status> {
    return this._statusListener;
  }

  /**
   * Indicates if it has actions.
   *
   * @returns boolean
   */
  hasActions(): boolean {
    const hasIt: boolean = (this.actions.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has errors after applying the run process.
   *
   * @returns boolean
   */
  hasErrors(): boolean {
    const hasIt: boolean = (this.errors.length > 0);
    return hasIt;
  }

  /**
   * Reads the actions from the given edition form, product and locale.
   *
   * @param unitEditionForm FormArray
   * @param product Product
   * @param locale string
   * @throws ErrorHelper
   * @returns void
   */
  readFrom(unitEditionForm: FormArray, product: Product, locale: string): void {
    if ((unitEditionForm.valid || unitEditionForm.disabled) && (unitEditionForm.length > 0)) {
      this.reset();

      for (let x = 0; x < unitEditionForm.length; x++) {
        const childForm: EditionForm = <EditionForm>unitEditionForm.at(x);

        if (childForm.disabled) {
          this.addDetachAction(childForm, product);
        } else {
          if (this.shouldAddOriginalUpdateAction()) {
            this.addOriginalUpdateAction(childForm);
          }
          if (this.shouldAddLocalizedUpdateAction(locale)) {
            if (_isNumber(this.unitManager.units[x].localized.id) || this.isValidChildForm(childForm)) {
              this.addLocalizedUpdateAction(childForm, locale);
            }
          }
        }
      }
    } else {
      throw new ErrorHelper('Cannot read a form array that is invalid or empty.');
    }
  }

  /**
   * Reset the actions.
   *
   * @returns void
   */
  reset(): void {
    this.actions = new Array();
    this._errors = new Array();

    this.changeStatusTo(StatusEnum.Iddle);
  }

  /**
   * Runs the actions.
   *
   * @param onFinishDo CallableFunction
   * @param onAbortDo CallableFunction
   * @returns any
   */
  run(onFinishDo: CallableFunction, onAbortDo: CallableFunction): void {
    try {
      if (this.hasActions()) {
        this.changeStatusTo(StatusEnum.Running);

        const buffer: Observable<any> = forkJoin(this.actions);

        buffer.subscribe(
          response => {
            onFinishDo();
          },
          error => {
            this._errors.push(error);
            onFinishDo();
          }
        );
      } else {
        throw new ErrorHelper('Cannot run without actions.');
      }
    } catch (error) {
      onAbortDo(error);
    }
  }

  /**
   * Adds the given child form as a detach action.
   *
   * @param childForm AbstractControl
   * @param product Product
   * @returns void
   */
  protected addDetachAction(childForm: EditionForm, product: Product): void {
    const action: Observable<any> = this.itemService.detachProductAsObservable(
      product.type.value,
      product.id,
      childForm.get('_type').value,
      childForm.get('_originalId').value
    );

    this.actions.push(action);
  }

  /**
   * Adds the given child form as a localized update action.
   *
   * @param product Product
   * @param childForm AbstractControl
   * @param locale string
   * @returns void
   */
  protected addLocalizedUpdateAction(childForm: EditionForm, locale: string): void {
    const useDomestic: boolean = (childForm.get('_useDomestic').value || false);

    const data: any = {
      rootId: childForm.get('_rootId').value,
      useDomestic: useDomestic,
      phonetic: (useDomestic ? null : childForm.get('_phonetic').value),
      notes: childForm.get('_notes').value,
      locked: childForm.get('_localizedLockingStatus').value.locked
    };

    if ((childForm.get('_type').value === ItemType.Character)) {
      // BOLTM-2919 TODO remove the useDomestic condition and use the localized name when fallback comes from API
      if (useDomestic) {
        data.name = childForm.get('_originalName').value;
      } else {
        data.name = childForm.get('_localizedName').value;
      }
    } else {
      if (useDomestic) {
        // BOLTM-2919 TODO remove the useDomestic condition and use the localized name when fallback comes from API
        // TODO: The name should be null for terms (waiting for API support).
        data.name = childForm.get('_originalName').value;
      } else {
        data.name = childForm.get('_localizedName').value;
      }
    }

    const action: Observable<any> = this.itemService.updateLocalizationAsObservable(
      childForm.get('_type').value,
      childForm.get('_originalId').value,
      locale,
      data
    );

    this.actions.push(action);
  }

  /**
   * Adds the given child form as an original update action.
   *
   * @param childForm AbstractControl
   * @returns void
   */
  protected addOriginalUpdateAction(childForm: EditionForm): void {
    const data: any = {
      locked: childForm.get('_originalLockingStatus').value.locked,
      requiredLocalizations: childForm.get('_requiredLocalizations').value
    };

    const action: Observable<any> = this.itemService.updateAsObservable(
      childForm.get('_type').value,
      childForm.get('_originalId').value,
      data
    );

    this.actions.push(action);
  }

  /**
   * Checks if the required fields to save a new item are present in a given form
   *
   * @param childForm AbstractControl
   * @returns boolean
   */
  protected isValidChildForm(childForm: EditionForm): boolean {
    const isValid: boolean = (
      StringHelper.isNonEmptyText(childForm.get('_localizedName').value) || childForm.get('_useDomestic').value
    );

    return isValid;
  }

  /**
   * Changes the stored action to the given one.
   *
   * @param action CrudActionEnum
   * @returns void
   */
  protected changeStatusTo(status: StatusEnum): void {
    this._status = new Status(status);
    this.statusNotifier.next(this.status);
  }

  /**
   * Indicates if it has to add the localized actions.
   *
   * @param locale string
   * @returns boolean
   */
  protected shouldAddLocalizedUpdateAction(locale: string): boolean {
    const shouldIt: boolean =
      locale &&
      (
        this.unitManager.canEditSomeLocalizedOnCurrentPage() ||
        this.unitManager.canEditSomeLocalizedLockingStatusOnCurrentPage()
      );

    return shouldIt;
  }

  /**
   * Indicates if it has to add the original actions.
   *
   * @returns boolean
   */
  protected shouldAddOriginalUpdateAction(): boolean {
    const shouldIt: boolean =
      this.unitManager.canEditSomeOriginalOnCurrentPage() ||
      this.unitManager.canEditSomeOriginalLockingStatusOnCurrentPage();

    return shouldIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.statusNotifier = new BehaviorSubject(undefined);
    this._statusListener = this.statusNotifier.asObservable();

    this.reset();
  }
}
