export const profileConfig = {
  seriesLabels: {
    common: [
      {
        field: 'title',
        value: 'Localized Series Title'
      },
      {
        field: 'shortSynopsis',
        value: 'Localized Series Short Synopsis'
      },
      {
        field: 'mediumSynopsis',
        value: 'Localized Series Medium Synopsis'
      },
      {
        field: 'fullSynopsis',
        value: 'Localized Series Long Synopsis'
      }
    ]
  },
  seasonsLabels: {
    common: [
      {
        field: 'title',
        value: 'Localized Season Title',
      },
      {
        field: 'keywords',
        value: 'Localized Season Keywords',
      },
      {
        field: 'shortSynopsis',
        value: 'Localized Season Short Synopsis',
      },
      {
        field: 'mediumSynopsis',
        value: 'Localized Season Medium Synopsis',
      },
      {
        field: 'fullSynopsis',
        value: 'Localized Season Full Synopsis',
      }
    ]
  },
  episodesLabels: {
    common: [
      {
        field: 'f0_num',
        value: 'F#'
      },
      {
        field: 'legalTitle',
        value: 'Episode Title'
      },
      {
        field: 'firstAirRunningOrder',
        value: 'First Air Running Order Position'
      },
      {
        field: 'firstAirReleaseDate',
        value: 'First Air Release Date'
      }
    ],
    language: [
      {
        field: 'title',
        value: 'Localized Episode Title'
      },
      {
        field: 'keywords',
        value: 'Localized Episode Keywords'
      },
      {
        field: 'shortSynopsis',
        value: 'Localized Short Synopsis'
      },
      {
        field: 'mediumSynopsis',
        value: 'Localized Medium Synopsis'
      },
      {
        field: 'fullSynopsis',
        value: 'Localized Full Synopsis'
      }
    ],
    territory: [
      {
        field: 'localAirDate',
        value: 'Local Air Date'
      },
      {
        field: 'localAirRunningOrder',
        value: 'Local Broadcast Order'
      },
      {
        field: 'ratingId',
        value: 'Rating'
      },
      {
        field: 'ratingReason',
        value: 'Rating Reason'
      }
    ]
  }
};
