import { Component, OnInit } from '@angular/core';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isEmpty as _isEmpty } from 'lodash';

import { Configuration } from '../../models/configuration/configuration.model';
import { ConfigurationService } from '../../services/configuration/configuration.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { MemoryPager } from 'app/shared/models/memory-pager/memory-pager.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { TypeEnum } from '../../models/configuration/type/type.enum';


@Component({
  selector: 'bolt-cpm-configurations-list',
  template: require('./bolt-cpm-configurations-list.html'),
  styles: [require('./bolt-cpm-configurations-list.scss')]
})
export class BoltCpmConfigurationsListComponent extends StormComponent implements OnInit {
  protected configuration?: Configuration;
  protected filteredType: TypeEnum;
  protected hasOpenDeletionPanel: boolean;
  protected hasOpenHandlerPanel: boolean;
  protected pager: MemoryPager;
  protected searchedWords: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected configurationService: ConfigurationService,
    protected notificationService: NotificationService
  ) {
    super();

    this.filteredType = undefined;
    this.hasOpenDeletionPanel = false;
    this.hasOpenHandlerPanel = false;
    this.searchedWords = '';

    this.pager = new MemoryPager(this.appConfig.get('ux.dataTables.pageSize'), this.getSortingCriteria());
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Opens the side panel for adding a new configuration.
   *
   * @returns void
   */
  protected add(): void {
    this.openHandlerPanel(undefined);
  }

  /**
   * Finds the configurations with the given type.
   *
   * @param type TypeEnum
   * @returns void
   */
  protected applyFilterBy(type: TypeEnum): void {
    this.filteredType = type;
    this.pager.setFilterCriteria(this.getFilteringCriteria());
  }

  /**
   * Finds the configurations with the given words.
   *
   * @param words string
   * @returns void
   */
  protected applySearchBy(words: string): void {
    this.searchedWords = words;
    this.pager.setFilterCriteria(this.getFilteringCriteria());
  }

  /**
   * Opens the side panel for deleting the given configuration.
   *
   * @param configuration Configuration
   * @returns void
   */
  protected delete(configuration: Configuration): void {
    this.configuration = configuration;
    this.hasOpenDeletionPanel = true;
  }

  /**
   * Opens the side panel for editing the given configuration.
   *
   * @param configuration Configuration
   * @returns void
   */
  protected edit(configuration: Configuration): void {
    this.openHandlerPanel(configuration);
  }

  /**
   * Fetches the configurations.
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();

    this.configurationService.fetch().subscribe(
      (configurations: Configuration[]) => {
        this.pager.setRecords(configurations);
        this.changeStatusToDataFound();
      },
      (error: ErrorHelper) => {
        this.changeStatusToError();
        this.notificationService.handleError('Failed fetching the configurations.', error);
      }
    );
  }

  /**
   * Returns the filtering criteria for configurations.
   *
   * @returns CallableFunction
   */
  protected getFilteringCriteria(): CallableFunction {
    const criteria: CallableFunction = (configuration: Configuration) => {
      const matched: boolean =
        (_isEmpty(this.filteredType) || configuration.getType().is(this.filteredType)) &&
        (
          this.searchedWords.length === 0 ||
          configuration.getCode().toLowerCase().includes(this.searchedWords) ||
          configuration.getDescription().toLowerCase().includes(this.searchedWords)
        );

      return matched;
    };

    return criteria;
  }

  /**
   * Returns the sorting criteria for configurations.
   *
   * @returns CallableFunction
   */
  protected getSortingCriteria(): CallableFunction {
    const criteria: CallableFunction = (configA: Configuration, configB: Configuration) => {
      const nameA: string = configA.getCode().trim().toLowerCase();
      const nameB: string = configB.getCode().trim().toLowerCase();

      if (nameA > nameB) {
        return 1;
      } else if (nameA < nameB) {
        return -1;
      } else {
        return 0;
      }
    };

    return criteria;
  }

  /**
   * Loads the given page.
   *
   * @param pageNumber number
   * @returns void
   */
  protected loadPage(pageNumber: number): void {
    this.pager.setPageNumber(pageNumber - 1);
  }

  /**
   * Handles the cancel event from deletion panel.
   *
   * @returns void
   */
  protected onDeletionCancel(): void {
    this.hasOpenDeletionPanel = false;
  }

  /**
   * Handles the confirm event from handler panel.
   *
   * @returns void
   */
  protected onDeletionConfirm(): void {
    this.hasOpenDeletionPanel = false;
    this.configuration = undefined;

    this.fetch();
  }

  /**
   * Handles the cancellation event from handler panel.
   *
   * @returns void
   */
  protected onHandlerCancel(): void {
    this.hasOpenHandlerPanel = false;
  }

  /**
   * Handles the saving event from handler panel.
   *
   * @returns void
   */
  protected onHandlerSave(): void {
    this.hasOpenHandlerPanel = false;
    this.configuration = undefined;

    this.fetch();
  }

  /**
   * Opens the handler panel for the given configuration.
   *
   * @param configuration Configuration
   * @returns void
   */
  protected openHandlerPanel(configuration: Configuration): void {
    this.configuration = configuration;
    this.hasOpenHandlerPanel = true;
  }
}
