import { Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectionItem } from '@bolt/ui-shared/droplists';
import { finalize } from 'rxjs/operators';
import * as fileSaver from 'file-saver';

import { Details } from '../../models/export/status/details/details.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { ManagerService } from '../../services/manager/manager.service';
import { PullingActionHelper } from 'app/shared/helpers/pulling-action/pulling-action.helper';
import { Status } from '../../models/export/status/status.model';
import { StormComponent } from 'app/modules/common/models/storm-component.model';
import { SubtitleExportType } from '../../models/subtitle-export-type.enum';
import { SubtitleService } from '../../services/subtitle/subtitle.service';
import { SubtitleType } from '../../models/subtitle/details/subtitle-type/subtitle-type.model';
import { SubtitleTypeEnum } from '../../models/subtitle/details/subtitle-type/subtitle-type.enum';


@Component({
  selector: 'bolt-subtitle-export',
  template: require('./bolt-subtitle-export.html'),
  styles: [require('./bolt-subtitle-export.scss')]
})
export class BoltSubtitleExportComponent extends StormComponent {
  @Output() selectType = new EventEmitter<SubtitleType>();
  @ViewChild('confirmExportModal')
  protected confirmExportModal: ElementRef;
  @ViewChild('notificationExportModal')
  protected notificationExportModal: ElementRef;

  protected boltStore: any = { };
  protected initialDetailsCache: Details;
  protected pullingAction: PullingActionHelper;
  protected pullingAttempts: number = 20;
  protected pullingErrorList: ErrorHelper[];
  protected errorList: string[] = [];
  protected subtitleExportType = SubtitleExportType.EPISODE_ONLY;
  protected subtitleExportTypes = SubtitleExportType;
  protected subtitleType = new SubtitleType(SubtitleTypeEnum.all);
  protected subtitleTypes: SelectionItem[];

  constructor(
    protected modalService: NgbModal,
    protected subtitleService: SubtitleService,
    protected subtitlesManager: ManagerService
  ) {
    super();
    this.initialize();
  }

  /**
   * Displays the confirmation modal related to export.
   *
   * @returns void
   */
  onExport(): void {
    this.modalService.open(this.confirmExportModal).result
      .then(
        (confirmToggle: boolean) => {
          if (confirmToggle) {
            this.startExport();
          }
        }
      )
      .catch(
        (reason: any) => { }
      );
  }

  /**
   * Emits the selected subtitle type every time it changes
   *
   * @param type SubtitleTypeEnum
   * @returns void
   */
  protected onSelectType(type: SubtitleType): void {
    this.selectType.emit(type);
  }

  /**
   * This start the exporting procedure for subtitles and inserts that
   * will take care is there is or not a localization selected
   *
   * @returns void
   */
  protected startExport(): void {
    this.changeStatusToFetchingData();
    this.pullingAction.reset();
    this.errorList = [];

    if (this.subtitleExportType === SubtitleExportType.ALL_SEASON) {
      this.subtitleService.requestExportableBulkFile(
        this.subtitlesManager.title,
        this.subtitleType.type,
        this.subtitlesManager.selectedLocalization
      )
      .subscribe(
        (initialDetails: Details) => {
          this.checkStatus(initialDetails);
        },
        (error: ErrorHelper) => {
          this.pullingAction.reset();
          this.throwExportError(error);
        }
      );
    } else {
      this.subtitleService.requestExportableFile(
        this.subtitlesManager.title,
        this.subtitleType.type,
        this.subtitlesManager.selectedLocalization
      )
      .subscribe(
        (initialDetails: Details) => {
          this.checkStatus(initialDetails);
        },
        (error: ErrorHelper) => {
          this.pullingAction.reset();
          this.throwExportError(error);
        }
      );
    }
  }

  /**
   * Determines how to check the status of the export process
   *
   * @param initialDetails Details
   * @returns void
   */
  protected checkStatus(initialDetails: Details): void {
    this.initialDetailsCache = initialDetails;
    this.subtitlesManager.handleNotice(
      'The subtitles were successfully sent to server and the Excel file is in the creation process.'
    );
    this.pullingAction.set(
      () => {
        if (this.subtitleExportType === SubtitleExportType.ALL_SEASON) {
          this.checkBulkExportingStatus();
        } else {
          this.checkExportingStatus();
        }
      },
      this.pullingAttempts
    );
    this.pullingAction.execute();
  }

  /**
   * Retrieves the already generated file for export.
   *
   * @returns void
   */
  protected checkBulkExportingStatus(): void {
    this.subtitleService.fetchExportableBulkFileStatus(
      this.initialDetailsCache.titleSpreadSheetId.toString()
    ).pipe(
      finalize(
        () => this.finallyFetchingCurrentExportStatus()
      )
    )
    .subscribe(
      (status: Status) => {
        this.initialDetailsCache.updateJobStatus(status);
        this.successfullyFetchingCurrentExportStatus(status);
        if (status.error && status.error.statusDetails) {
          this.errorList = new Array(status.error.statusDetails);
          this.modalService.open(this.notificationExportModal);
        }
      },
      (error: ErrorHelper) => this.failedFetchingCurrentExportStatus(error)
    );
  }

  /**
   * Retrieves the already generated file for export.
   *
   * @returns void
   */
  protected checkExportingStatus(): void {
    this.subtitleService.fetchExportableFileStatus(
      this.initialDetailsCache.titleSpreadSheetId.toString()
    ).pipe(
      finalize(
        () => this.finallyFetchingCurrentExportStatus()
      )
    ).subscribe(
      (status: Status) => {
        this.initialDetailsCache.updateJobStatus(status);
        this.successfullyFetchingCurrentExportStatus(status);
      },
      (error: ErrorHelper) => this.failedFetchingCurrentExportStatus(error)
    );
  }

  /**
   * Adds the error to errorsList on fetching exporting status fails.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected failedFetchingCurrentExportStatus(error: ErrorHelper): void {
    this.pullingAction.reset();
    this.resetPullingErrorList();
    this.pullingErrorList.push(error);
    this.throwExportError(error);
  }

  /**
   * After fetching the exporting status, with him, checks current status and based on that
   * fetches the generated file if ready or throw an error if couldn't be generated.
   *
   * @param currentStatus Status
   * @returns void
   */
  protected successfullyFetchingCurrentExportStatus(currentStatus: Status): void {
    if (currentStatus.isGenerated() || currentStatus.isCompleted()) {
      this.pullingAction.reset();
      this.retrieveExportFile();
    } else if (this.isError()) {
      this.pullingAction.reset();
      this.throwExportError(currentStatus.error);
    }
  }

  /**
   * Checks if exporting status checks flow needs to continue or not.
   *
   * @returns void
   */
  protected finallyFetchingCurrentExportStatus(): void {
    try {
      if (!this.pullingAction.wasReset()) {
        this.pullingAction.execute();
      }
    } catch (error) {
      this.pullingErrorList.push(error);

      const errorToDisplay: ErrorHelper = this.pullingErrorList.shift();
      this.throwExportError(errorToDisplay);

      this.pullingErrorList.forEach(
        (extraError: ErrorHelper) => {
          console.log(extraError.toString());
        }
      );
    }
  }

  /**
   * Retrieves the already generated file for export.
   *
   * @returns void
   */
  protected retrieveExportFile(): void {
    if (this.subtitleExportType === SubtitleExportType.ALL_SEASON) {
      this.subtitleService.fetchExportableBulkFile(this.initialDetailsCache.titleSpreadSheetId.toString())
      .subscribe(
        (generatedFile: any) => {
          this.saveFile(generatedFile);
        },
        (error: ErrorHelper) => {
          this.throwExportError(error);
        }
      );
    } else {
      this.subtitleService.fetchExportableFile(this.initialDetailsCache.titleSpreadSheetId.toString())
      .subscribe(
        (generatedFile: any) => {
          this.saveFile(generatedFile);
        },
        (error: ErrorHelper) => {
          this.throwExportError(error);
        }
      );
    }
  }

  /**
   * Once an exportable file has been fetched this method saves the file.
   *
   * @param generatedFile any
   * @returns void
   */
  protected saveFile(generatedFile: any): void {
    try {
      const auxStatusDetailsCache: Details = this.initialDetailsCache;
      const filename: string = <string>auxStatusDetailsCache.filename;
      fileSaver.saveAs(generatedFile, filename);
      setTimeout(
        () => {
          this.resetPullingErrorList();
          this.changeStatusToDataFound();
          this.subtitlesManager.handleNotice('The file was exported successfully.');
        },
        0
      );
    } catch (e) {
      this.throwExportError(e);
    }
  }

  /**
   * Indicates if it has to block the export.
   *
   * @returns boolean
   */
  protected hasBlockExport(): boolean {
    const hasIt: boolean = (
      this.subtitlesManager.isAnyVersionInEdition() ||
      !this.subtitlesManager.hasOriginalVersionByComplete() ||
      this.isFetchingData()
    );

    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.pullingAction = new PullingActionHelper();

    this.setupSubtitleTypeList();
    this.setupBoltStore();
    this.changeStatusToIdle();
    this.resetPullingErrorList();
  }

  /**
   * Populate translations with default language until i18n will be implemented.
   *
   * @return void
   */
  protected populateTranslations(): void {
    this.boltStore.translations = {
      exportContentLabel: 'Export',
      confirmExportRequestingInputLabel: 'Please confirm',
      confirmExportOriginalVersionOnly: 'Are you sure you want to export only',
      confirmExportLocalizationAndOriginalVersion: 'Are you sure you want to export',
      confirmExportLocalizationAndOriginalVersionConnector: 'and',
      confirmExportOriginalVersionTitle: 'Original Version',
      confirmExportConfirmationLabel: 'Yes',
      confirmExportCancellationLabel: 'No'
    };
  }

  /**
   * Set up the subtitle type list.
   *
   * @returns void
   */
  protected setupSubtitleTypeList(): void {
    this.subtitleTypes = new Array();

    this.subtitleTypes.push(
      new SelectionItem(SubtitleTypeEnum.all.toString(), new SubtitleType(SubtitleTypeEnum.all))
    );

    this.subtitleTypes.push(
      new SelectionItem(SubtitleTypeEnum.insert.toString(), new SubtitleType(SubtitleTypeEnum.insert))
    );

    this.subtitleTypes.push(
      new SelectionItem(SubtitleTypeEnum.lyrics.toString(), new SubtitleType(SubtitleTypeEnum.lyrics))
    );
  }

  /**
   * Reset the pulling errors list.
   *
   * @returns void
   */
  protected resetPullingErrorList(): void {
    this.pullingErrorList = new Array();
  }

  /**
   * Set up the BoltStore for containing UI basic data like translations.
   *
   * @return void
   */
  protected setupBoltStore(): void {
    this.boltStore = {
      translations: { }
    };

    this.populateTranslations();
  }

  /**
   * Throws an error for the export file process.
   *
   * @param error ErrorHelper
   * @returns void
   */
  protected throwExportError(error: ErrorHelper): void {
    this.subtitlesManager.handleError('Failed exporting the file.', error);
    this.changeStatusToError();
  }
}
