import { TitlesEnum } from '../../../titles.enum';

export class FieldExtras {
  protected _selectedTitleName: string;
  protected _fieldType: TitlesEnum;
  protected _fieldLabel: string;
  protected _selectedTitleNumber: string;

  constructor(
    selectedTitleName: string,
    fieldType: TitlesEnum,
    fieldLabel: string,
    selectedTitleNumber?: string
  ) {
    this.initiliaze(selectedTitleName, fieldType, fieldLabel, selectedTitleNumber);
  }

  get selectedTitleName(): string {
    return this._selectedTitleName;
  }

  get fieldType(): TitlesEnum {
    return this._fieldType;
  }

  get fieldLabel(): string {
    return this._fieldLabel;
  }

  get selectedTitleNumber(): string {
    return this._selectedTitleNumber;
  }

  initiliaze(
    selectedTitleName: string,
    fieldType: TitlesEnum,
    fieldLabel: string,
    selectedTitleNumber?: string
  ) {
    this._selectedTitleName = selectedTitleName;
    this._fieldType = fieldType;
    this._fieldLabel = fieldLabel;
    this._selectedTitleNumber = selectedTitleNumber;
  }
}
