import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { Account } from '@bolt/ui-shared/master-data';
import { NotificationService } from '@bolt/ui-shared/notification';
import { isArray as _isArray, isObject as _isObject, isString as _isString, isNull as _isNull } from 'lodash';

import { BoltProjectAbstractCommonComponent } from '../bolt-project-abstract-common.component';
import { ConfigService as FormConfigService } from 'app/shared/services/form/config/config.service';
import { CreationForm } from '../../models/project/creation-form/creation-form.model';
import { EditionForm } from '../../models/project/edition-form/edition-form.model';
import { Entity } from '../../models/entity/entity.model';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { LayoutHandlerService } from 'app/shared/services/layout-handler/layout-handler.service';
import { LayoutService as FormLayoutService } from 'app/shared/services/form/layout/layout.service';
import { ListLayoutProvider } from 'app/modules/list/providers/list-layout/list-layout.provider';
import { ManagerService } from '../../services/project/manager/manager.service';
import { notificationsContainer } from 'app/modules/common/models/notifications-container';
import { ProjectService } from '../../services/project/project.service';
import { ScrollingHandlerService } from '../../services/scrolling-handler/scrolling-handler.service';
import { UrlHandlerService } from '../../services/url-handler/url-handler.service';


@Component({
  selector: 'bolt-project-handler',
  template: require('./bolt-project-handler.html'),
  styles: [require('./bolt-project-handler.scss')]
})
export class BoltProjectHandlerComponent extends BoltProjectAbstractCommonComponent implements AfterViewInit, OnInit, OnDestroy {
  protected readonly noEpisodesReason: string = 'Episodes will be displayed after saving the project.';
  protected readonly noLockingStatusReason: string = 'Locking status will be displayed after saving the project.';
  protected readonly mainWarningMessage: string = 'Locking status, episodes and titles counter will be displayed after saving the project.';

  protected areAllEntitiesChecked: boolean;
  protected fieldsSpec: any;
  protected form: FormGroup;
  protected isEditionOn: boolean;
  protected maxValuesAsString: string;
  protected newCandidates: Set<string>;
  protected removingCandidates: Set<string>;
  protected scrollHeight: string;

  constructor(
    protected appConfig: AppConfigProvider,
    protected formBuilder: FormBuilder,
    protected formConfig: FormConfigService,
    protected formLayout: FormLayoutService,
    protected layoutHandler: LayoutHandlerService,
    protected listLayout: ListLayoutProvider,
    protected notificationService: NotificationService,
    protected projectManager: ManagerService,
    protected projectService: ProjectService,
    protected scrollingHandler: ScrollingHandlerService,
    protected urlHandler: UrlHandlerService
  ) {
    super(layoutHandler, notificationService, projectManager, projectService, scrollingHandler, urlHandler);

    this.areAllEntitiesChecked = false;
    this.fieldsSpec = this.formConfig.get('projectDashboard.project.fields');
    this.maxValuesAsString = this.appConfig.get('ux.multiSelect.maxValuesAsString');
    this.newCandidates = new Set();
    this.removingCandidates = new Set();
    this.scrollHeight = this.appConfig.get('ux.multiSelect.scrollHeight');
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    this.destroyForm();
    super.ngOnDestroy();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * Adds the given entity.
   *
   * @param entity Entity
   * @returns void
   */
  protected addEntity(entity: Entity): void {
    if (entity instanceof Entity) {
      if (entity.type.isEpisode()) {
        this.notificationService.handleError(
          'Only Features, Series and Seasons are allowed to be added to project.',
          undefined,
          notificationsContainer.projectDashboard.key
        );
      } else {
        if (this.firstEntities.has(entity.mapKey)) {
          this.notificationService.handleError(
            'The given title is already in the project.',
            undefined,
            notificationsContainer.projectDashboard.key
          );
        } else {
          this.storeFirstEntity(entity);
          this.newCandidates.add(entity.mapKey);

          this.areAllEntitiesChecked = false;

          if (this.doesEntityPassFilters(entity)) {
            this.firstEntitiesOrder.push(this.retrieveOrderEntryFor(entity));
          }
        }
      }
    }
  }

  /**
   * Adds the given entities.
   *
   * @param entities Entity[]
   * @returns void
   */
  protected addEntities(entities: Entity[]): void {
    entities.forEach(
      (entity: Entity) => {
        this.addEntity(entity);
      }
    );
  }

  protected doAfterCurrentProjectChange(): void {
    this.isEditionOn = this.hasCurrentProject();
    this.buildForm();
  }

  /**
   * Builds the form.
   *
   * @returns void
   */
  protected buildForm(): void {
    this.removingCandidates.clear();

    if (this.isEditionOn) {
      // Form is created in doAfterFetchEntities() in order to have all entities for edition.
      this.fetchEntities();
    } else {
      this.form = this.formBuilder.group(
        new CreationForm(this.fieldsSpec, this.projectManager.getDefaults())
      );

      this.ensureStartScrolling();
      this.changeStatusToDataFound();
    }
  }

  /**
   * Cancels the project.
   *
   * @returns void
   */
  protected cancel(): void {
    const message: string = `Project ${this.isEditionOn ? 'edition' : 'creation'} was cancelled.`;

    this.layoutHandler.changeToReading();
    this.notificationService.handleNotice(message, undefined, notificationsContainer.projectDashboard.key);
  }

  /**
   * Destroys the form.
   *
   * @returns void
   */
  protected destroyForm(): void {
    this.form = undefined;
  }

  protected doAfterFetchEntities(): void {
    this.form = this.formBuilder.group(
      new EditionForm(this.fieldsSpec, this.currentProject)
    );

    this.ensureStartScrolling();
  }

  /**
   * Handles the given error and common actions after saving the project.
   *
   * @param message string
   * @param error ErrorHelper
   * @returns void
   */
  protected handleSavingError(message: string, error: ErrorHelper): void {
    this.notificationService.handleError(message, error, notificationsContainer.projectDashboard.key);

    if (this.isEditionOn) {
      this.layoutHandler.changeToUpdating();
    } else {
      this.layoutHandler.changeToCreating();
    }

    if (error.isInvalid()) {
      this.changeStatusToError();
    } else {
      this.changeStatusToDataFound();
    }
  }

  /**
   * Handles the common successful actions after saving the project.
   *
   * @param message string
   * @returns void
   */
  protected handleSavingSuccess(message: string): void {
    this.notificationService.handleNotice(message, undefined, notificationsContainer.projectDashboard.key);
    this.layoutHandler.changeToReading();
    this.changeStatusToDataFound();
  }

  /**
   * Indicates if it has to block the save button.
   *
   * @returns boolean
   */
  protected hasBlockSave(): boolean {
    const hasIt: boolean = (!this.isDataFound() || this.form.invalid);
    return hasIt;
  }

  /**
   * Indicates if it has to display cancel.
   *
   * @returns boolean
   */
  protected hasDisplayCancel(): boolean {
    return this.isDataFound();
  }

  /**
   * Indicates if it has to display save.
   *
   * @returns boolean
   */
  protected hasDisplaySave(): boolean {
    return this.isDataFound();
  }

  /**
   * Handles the change of current account.
   *
   * @param code string
   * @returns void
   */
  protected handleCurrentAccountChange(code: string): void {
    this.mapAndSetCurrentAccount(code);
    this.refreshScrollingHandler();
  }

  /**
   * Handles the change of current languages.
   *
   * @param codes string[]
   * @returns void
   */
  protected handleCurrentLanguagesChange(codes: string[]): void {
    this.mapAndSetCurrentLanguages(codes);
    this.refreshScrollingHandler();
  }

  /**
   * Handles the change of territories.
   *
   * @param codes string[]
   * @returns void
   */
  protected handleCurrentTerritoriesChange(codes: string[]): void {
    this.mapAndSetCurrentTerritories(codes);
    this.refreshScrollingHandler();
  }

  /**
   * Indicates if it has languages and territories.
   *
   * @returns boolean
   */
  protected hasLanguagesAndTerritories(): boolean {
    const hasIt: boolean = (
      (_isObject(this.form.get('_languages').value) && this.form.get('_languages').value.length > 0) &&
      (_isObject(this.form.get('_territories').value) && this.form.get('_territories').value.length > 0)
    );

    return hasIt;
  }

  /**
   * Reads the data stored in form and returns it as API expects it.
   *
   * @returns any
   */
  protected readDataFromForm(): any {
    const account: string = (this.form.get('_account').value || Account.ALL_VALUE);
    const description: string = (this.form.get('_description').value || '').trim();
    const languages: string[] = this.form.get('_languages').value;
    const territories: string[] = this.form.get('_territories').value;

    const data: any = {
      name: this.form.get('_name').value.trim(),
      locales: [],
      entities: []
    };

    if (description.length > 0) {
      data.description = description;
    }

    languages.forEach(
      (language: string) => {
        const locale: string = `${language}_*_*_${account}`;
        data.locales.push(locale);
      }
    );

    territories.forEach(
      (territory: string) => {
        const locale: string = `*_${territory}_*_${account}`;
        data.locales.push(locale);
      }
    );

    this.firstEntities.forEach(
      (entity: Entity) => {
        if (!this.removingCandidates.has(entity.mapKey)) {
          data.entities.push(entity.asEndpointData());
        }
      }
    );

    return data;
  }

  /**
   * Refreshes the scrolling handler.
   *
   * @returns void
   */
  protected refreshScrollingHandler(): void {
    setTimeout(
      () => {
        // Hack for ensuring the scrolling refresh.
        this.scrollingHandler.refresh(false);
      }
    );
  }

  /**
   * Saves the project.
   *
   * @returns void
   */
  protected save(): void {
    this.changeStatusToFetchingData();
    this.layoutHandler.changeToSaving();

    const data: any = this.readDataFromForm();

    if (this.isEditionOn) {
      this.projectManager.updateCurrentProject(
        data,
        () => {
          this.handleSavingSuccess('Project updated successfully.');
        },
        (error: ErrorHelper) => {
          this.handleSavingError('Failed trying to update the project.', error);
        }
      );
    } else {
      this.projectManager.createProject(
        data,
        () => {
          this.urlHandler.updateUrl(this.projectManager.currentProject.id);
          this.handleSavingSuccess('Project created successfully.');
        },
        (error: ErrorHelper) => {
          this.handleSavingError('Failed trying to create the project.', error);
        }
      );
    }
  }

  protected storeSecondEntities(entities: Entity[]): void {
    // Episodes are ignored during creation/edition.
  }

  /**
   * Toggles all the checks for removing entities.
   *
   * @returns void
   */
  protected toggleAllEntitiesChecks(): void {
    this.areAllEntitiesChecked = !this.areAllEntitiesChecked;

    if (this.areAllEntitiesChecked) {
      this.firstEntitiesOrder.forEach(
        (entry: [string, string]) => {
          this.removingCandidates.add(entry[1]);
        }
      );
    } else {
      this.removingCandidates.clear();
    }
  }

  /**
   * Toggles the entity with the given map key for its removal.
   *
   * @param mapKey string
   * @returns void
   */
  protected toggleRemovingCandidateFor(mapKey: string): void {
    if (this.removingCandidates.has(mapKey)) {
      this.removingCandidates.delete(mapKey);
      this.areAllEntitiesChecked = false;
    } else {
      this.removingCandidates.add(mapKey);
      this.areAllEntitiesChecked = this.firstEntities.size === this.removingCandidates.size;
    }
  }
}
