import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { decorateModuleRef } from './app/environment';
import { AppModule } from './app';

export function main(): Promise<any> {
  const promise: Promise<any> = platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(decorateModuleRef)
    .catch(
      (error: any) => console.error(error)
    );

  return promise;
}

document.addEventListener('DOMContentLoaded', () => main());
