import { SeasonAttributes } from '../../../title/season/season-attributes.enum';
import { TitleAttributes } from '../../../title/title-attributes.enum';
import { TitleForm } from '../title-form.model';


export class SeasonForm extends TitleForm {

  constructor(fieldSpecs: any, defaultValues: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
    this.setStatus();
  }

  toJson(): any {
    const data: any = super.toJson();
    data[SeasonAttributes.copyright] = this.get(SeasonAttributes.copyright).value;
    data[SeasonAttributes.countryOfOriginId] = this.get(SeasonAttributes.countryOfOriginId).value;
    data[SeasonAttributes.genreId] = this.get(SeasonAttributes.genreId).value;
    data[TitleAttributes.originalSpokenLanguageId] = this.get(TitleAttributes.originalSpokenLanguageId).value;
    return data;
  }

  protected setupFields(): void {
    super.setupFields();
    this.setupCopyright();
    this.setupCountryOfOriginId();
    this.setupGenreId();
    this.setupOriginalSpokenLanguageId();
  }
}
