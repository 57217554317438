import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppConfigProvider } from '@bolt/ui-shared/configuration';
import { NotificationService } from '@bolt/ui-shared/notification';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { isObject as _isObject, pickBy as _pickBy } from 'lodash';

import { CharacterInterface } from '../../models/character.model';
import { CharacterService, CharacterServiceFetchCharacterParamsInterface } from '../../services/character.service';
import { StormComponent } from 'app/modules/common/models/storm-component.model';

import {
  StormServiceResponseCollection, StormServiceResponseCollectionInterface, StormServiceResponsePagination,
  StormServiceResponsePaginationInterface, StormServiceResponseSorting, StormServiceResponseSortingInterface
} from 'app/modules/common/services/storm-service-response-collection';


@Component({
  selector: 'bolt-character-list',
  template: require('./bolt-character-list.html'),
  styles: [require('./bolt-character-list.scss')]
})
export class BoltCharacterListComponent extends StormComponent implements OnInit, OnChanges, OnDestroy {
  listData: Observable<StormServiceResponseCollectionInterface>;
  characters: CharacterInterface[] = [];
  pagination: StormServiceResponsePaginationInterface = new StormServiceResponsePagination();
  sorting: StormServiceResponseSortingInterface = new StormServiceResponseSorting();
  sub: any;
  searchCriteria: CharacterServiceFetchCharacterParamsInterface = <CharacterServiceFetchCharacterParamsInterface>{ };

  @Input('BoltCharacterListFilters') filters: FormGroup;
  @Input('BoltCharacterListRefreshList') refreshList: boolean;

  protected fetchSubscription: Subscription;

  constructor(
    protected characterService: CharacterService,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected appConfig: AppConfigProvider,
    protected notificationService: NotificationService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && changes.filters.currentValue) {

      this.filters.valueChanges.pipe(
        debounceTime(this.appConfig.get('ux.typing.debounceTime', 0))
      ).subscribe(
        filters => {
          if (this.filters.valid) {
            this.loadPage(1, filters);
          }
        }
      );
    }

    if (changes.refreshList && changes.refreshList.currentValue === true) {
      this.fetch();
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.cancelFetch();
  }

  ngOnInit() {
    this.searchCriteria = this.getDefaultSearchCriteria();

    this.sub = this.activatedRoute.params.subscribe(
      (params: Params) => {
        if (Object.keys(params).length) {
          Object.assign(this.searchCriteria, params);
        } else {
          this.searchCriteria = this.getDefaultSearchCriteria();
        }

        this.fetch();
      }
    );
  }

  /**
   * Navigates to the corresponding title list page, based on the provided params
   *
   * @param page: number
   * @param searchCriteria object
   * @returns : Promise<boolean>
   */
  loadPage(page: number, searchCriteria: object): Promise<boolean> {
    return this.router.navigate([
      '/character',
      _pickBy(Object.assign(this.searchCriteria, searchCriteria, { page: page }), (param) => param)
    ]);
  }

  /**
   * Returns the default search criteria
   *
   * @returns CharacterServiceFetchCharacterParamsInterface
   */
  getDefaultSearchCriteria(): CharacterServiceFetchCharacterParamsInterface {
    return {
      q: '',
      page_size: <number>this.appConfig.get('ux.dataTables.pageSize', 20),
      page: 1
    };
  }

  /**
   * Cancels the fetch.
   *
   * @returns void
   */
   protected cancelFetch(): void {
    if (_isObject(this.fetchSubscription)) {
      this.fetchSubscription.unsubscribe();
    }
  }

  /**
   * Fetch Characters from the service
   *
   * @returns void
   */
  protected fetch(): void {
    this.changeStatusToFetchingData();
    this.cancelFetch();

    this.fetchSubscription = this.characterService.oldSearch(this.searchCriteria).subscribe(
      (response: StormServiceResponseCollection) => {
        this.characters = response.collection;
        this.pagination = response.pagination;
        this.sorting = response.sorting;

        if (response.collection.length) {
          this.changeStatusToDataFound();
        } else {
          this.changeStatusToDataNotFound();
        }
      },
      (error: any) => {
        this.changeStatusToError();
        this.notificationService.handleError('Failed fetching characters.', error);
      }
    );
  }
}
