import { Component, Input } from '@angular/core';

import { TitleType } from '../../../title/models/title.model';
import { CreditPositionType } from 'app/modules/credits/models/credit/credit-position.enum';
import { CreditType } from 'app/modules/credits/models/credit/credit-type.enum';
import { Credit } from 'app/modules/credits/models/credit/credit.model';


@Component({
  selector: 'bolt-credits-details',
  template: require('./bolt-credits-details.html'),
  styles: [require('./bolt-credits-details.scss')]
})
export class BoltCreditsDetailsComponent {
  @Input() credits: Credit[];
  @Input() position: CreditPositionType;
  @Input() titleType: TitleType;
  @Input() type: CreditType;

  constructor() {
    this.initialize();
  }

  /**
   * Indicates if it has credits.
   *
   * @returns boolean
   */
  protected hasCredits(): boolean {
    const hasIt: boolean = (this.credits.length > 0);
    return hasIt;
  }

  /**
   * Indicates if it has to display guest.
   *
   * @returns boolean
   */
  protected hasDisplayGuestSelection(): boolean {
    const hasIt: boolean = (
      (this.titleType === TitleType.episode) &&
      (this.position === CreditPositionType.CAST) &&
      (this.type !== CreditType.TRANSLATED)
    );

    return hasIt;
  }

  /**
   * Initializes the instance.
   *
   * @returns void
   */
  protected initialize(): void {
    this.credits = new Array();
  }

  /**
   * Indicates if it is CAST.
   *
   * @returns boolean
   */
  protected isCast(): boolean {
    return !this.isCrew();
  }

  /**
   * Indicates if it is CREW.
   *
   * @returns boolean
   */
  protected isCrew(): boolean {
    const isIt: boolean = (this.position === CreditPositionType.CREW);
    return isIt;
  }

  /**
   * Indicates if it is ORIGINAL.
   *
   * @returns boolean
   */
  protected isOriginal(): boolean {
    const isIt: boolean = (this.type === CreditType.ORIGINAL);
    return isIt;
  }
}
