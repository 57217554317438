import { SelectionItem } from '@bolt/ui-shared/droplists';
import { isString as _isString, isArray as _isArray } from 'lodash';

import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';


export class GroupedItem {
  protected _label: string;
  protected _items: SelectionItem[];

  constructor(label: string, items: SelectionItem[]) {
    this.setLabel(label);
    this.setItems(items);  }

  get label(): string {
    return this._label;
  }

  get value(): string {
    return this._label;
  }

  get items(): SelectionItem[] {
    return this._items;
  }

  /**
   * Add the given item into the current value.
   *
   * @param item SelectionItem
   * @returns void
   */
  addItem(item: SelectionItem): void {
    this.items.push(item);
  }

  /**
   * Indicates if the current group has an Item with the given value by the given criteria.
   *
   * @param criteria string
   * @param value any
   * @returns boolean
   */
  hasSomeItem(criteria: string, value: any): boolean {
    return this.items.some((item: SelectionItem) => item[criteria] === value);
  }

  /**
   * Set the current label with the given value.
   *
   * @param label string
   * @throws ErrorHelper
   * @returns void
   */
  protected setLabel(label: string): void {
    if (_isString(label) && label.length > 0) {
      this._label = label;
    } else {
      throw new ErrorHelper('Invalid label for creating a GroupedItem');
    }
  }

  /**
   * Set the current items with the given value.
   *
   * @param items SelectionItem[]
   * @throws ErrorHelper
   * @returns void
   */
  protected setItems(items: SelectionItem[]): void {
    if (_isArray(items) && items.length > 0) {
      this._items = items;
    } else {
      throw new ErrorHelper('Invalid items for creating a GroupedItem');
    }
  }
}
