import { SeriesAttributes } from '../../../title/series/series-attributes.enum';
import { TitleAttributes } from '../../../title/title-attributes.enum';
import { TitleForm } from '../title-form.model';


export class SeriesForm extends TitleForm {

  constructor(fieldSpecs: any, defaultValues: any) {
    super(fieldSpecs, defaultValues);
    this.setupFields();
    this.setStatus();
  }

  toJson(): any {
    const data: any = super.toJson();
    data[SeriesAttributes.countryOfOriginId] = this.get(SeriesAttributes.countryOfOriginId).value;
    data[TitleAttributes.originalSpokenLanguageId] = this.get(TitleAttributes.originalSpokenLanguageId).value;
    data[SeriesAttributes.productionCompanyId] = this.get(SeriesAttributes.productionCompanyId).value;
    data[SeriesAttributes.productionCompanyIds] = this.get(SeriesAttributes.productionCompanyIds).value;
    return data;
  }

  protected setupFields(): void {
    super.setupFields();
    this.setupCountryOfOriginId();
    this.setupOriginalSpokenLanguageId();
    this.setupProductionCompanyId();
    this.setupProductionCompanyIds();
  }
}
