import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/helpers/auth-guard/auth-guard.helper';
import { modulesPath } from '../auth/services/role/modules-path';
import { BoltMetadataExportMultipleRecordsComponent } from './components/bolt-metadata-export-multiple-records/bolt-metadata-export-multiple-records.component';


export const metadataExportRoutes: Routes = [
  {
    path: 'metadata-export',
    component: BoltMetadataExportMultipleRecordsComponent,
    canActivate: [AuthGuard],
    data: {
      boltAuthAware: modulesPath.menu.metadataExport.path,
      title: 'Metadata Export',
    }
  }
];

export const metadataExportModuleRouting: ModuleWithProviders = RouterModule.forChild(metadataExportRoutes);
