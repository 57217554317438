import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng';

import { BoltCommonModule } from '../common/common.module';
import { CreditsModule } from '../credits/credits.module';
import { SubtitleModule } from '../subtitle/subtitle.module';
import { TitleModule } from '../title/title.module';

import { projectModuleRouting } from './project.routing';

import { BoltProjectComponent } from './components/bolt-project/bolt-project.component';
import { BoltProjectContainerComponent } from './components/bolt-project-container/bolt-project-container.component';
import { BoltProjectDataFilterComponent } from './components/bolt-project-data-filter/bolt-project-data-filter.component';
import { BoltProjectDetailsComponent } from './components/bolt-project-details/bolt-project-details.component';
import { BoltProjectEntityBulkAddComponent } from './components/bolt-project-entity-bulk-add/bolt-project-entity-bulk-add.component';
import { BoltProjectEntitySearchComponent } from './components/bolt-project-entity-search/bolt-project-entity-search.component';
import { BoltProjectHandlerComponent } from './components/bolt-project-handler/bolt-project-handler.component';
import { BoltProjectIconsLegendPopupComponent } from './components/bolt-project-icons-legend-popup/bolt-project-icons-legend-popup.component';
import { BoltProjectListComponent } from './components/bolt-project-list/bolt-project-list.component';
import { BoltProjectMetadataIconComponent } from './components/bolt-project-metadata-icon/bolt-project-metadata-icon.component';
import { BoltProjectMetadataPopupComponent } from './components/bolt-project-metadata-popup/bolt-project-metadata-popup.component';
import { LocksRecoverService } from './services/entity/locks-recover/locks-recover.service';
import { ManagerService } from './services/project/manager/manager.service';
import { ProjectService } from './services/project/project.service';
import { ScrollingHandlerService } from './services/scrolling-handler/scrolling-handler.service';
import { UrlHandlerService } from './services/url-handler/url-handler.service';


@NgModule({
  imports: [
    CommonModule,
    InputSwitchModule,
    BoltCommonModule,
    CreditsModule,
    SubtitleModule,
    TitleModule,
    projectModuleRouting
  ],
  declarations: [
    BoltProjectComponent,
    BoltProjectContainerComponent,
    BoltProjectDataFilterComponent,
    BoltProjectDetailsComponent,
    BoltProjectEntityBulkAddComponent,
    BoltProjectEntitySearchComponent,
    BoltProjectHandlerComponent,
    BoltProjectIconsLegendPopupComponent,
    BoltProjectListComponent,
    BoltProjectMetadataIconComponent,
    BoltProjectMetadataPopupComponent
  ],
  exports: [
    BoltProjectComponent
  ],
  providers: [
    LocksRecoverService,
    ManagerService,
    ProjectService,
    ScrollingHandlerService,
    UrlHandlerService
  ]
})
export class ProjectModule { }
