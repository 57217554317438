import { FormControl, Validators } from '@angular/forms';

import { BoltForm } from 'app/modules/common/models/form/bolt-form.model';

export class GenericWithNameForm extends BoltForm {
  // TODO: Set Type
  protected entity;
  protected name: FormControl;

  // TODO: Set Type
  constructor(fieldSpecs: any, data?) {
    super(fieldSpecs);
    this.entity = data;
    this.setupFields();
  }

  toJson(): any {
    const output: any = {
      name: this.name.value
    };

    if (this.entity) {
      output.id = this.entity.id;
    }

    return output;
  }

  /**
   * Returns the "Name" field.
   *
   * @returns FormControl
   */
  getNameField(): FormControl {
    return this.name;
  }

  protected setupFields(): void {
    this.setupName();
  }

  /**
   * Set up the "Name" field.
   *
   * @returns void
   */
  protected setupName(): void {
    this.name = new FormControl(
      this.entity ? this.entity.name : undefined,
      Validators.compose([
        Validators.required,
        Validators.maxLength(this.fieldSpecs.nameMaxLength)
      ])
    );
    this.addControl('name', this.name);
  }
}
