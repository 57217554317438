import { Component } from '@angular/core';


@Component({
  selector: 'bolt-404',
  template: require('./bolt-404.html'),
  styles: [require('./bolt-404.scss')]
})
export class Bolt404Component {
  constructor() { }
}
