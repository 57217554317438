import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { UrlSearchParams } from '@bolt/ui-shared/common';
import { AppRoutesService } from '@bolt/ui-shared/routing';
import { SearchCriteria } from '@bolt/ui-shared/searching';

import { AuthHttp } from 'app/modules/auth/helpers/auth-http/auth-http.helper';
import { BoltAbstractService } from 'app/modules/common/services/bolt-abstract.service';
import { ErrorHelper } from 'app/shared/helpers/http/response/error/error.helper';
import { Locale } from 'app/modules/common/models/locale/locale.model';
import { SearchResponse } from 'app/shared/models/search-response/search-response.model';
import { StormServiceResponseSingleInterface, StormServiceResponseSingle } from 'app/modules/common/services/storm-service-response-single';
import { StormServiceResponseCollectionInterface, StormServiceResponseCollection } from 'app/modules/common/services/storm-service-response-collection';
import { StormServicesQueryParams } from 'app/modules/common/services/storm-services-query-params';
import { Role, RoleInterface } from '../models/role.model';
import { RoleResponse } from '../models/role-response/role-response.model';
import { RoleMetadata, RoleMetadataInterface } from '../models/role-metadata.model';

export enum RoleServiceFetchRoleQueryParams {
  q = <any>'q',
}

export interface RoleServiceFetchRoleMetadataParamsInterface {
  roleId: number;
  locale?: string;
}

export interface RoleServiceFetchRoleParamsInterface {
  q: string;
  page_size: number;
  page: number;
  sort_by?: string;
  sort_direction?: string;
}


@Injectable()
export class RoleService extends BoltAbstractService {
  constructor(
    protected authHttp: AuthHttp,
    protected appRoutes: AppRoutesService
  ) {
    super(appRoutes, authHttp);
  }

  /**
   * Fetch a single Role
   *
   * @param params RoleServiceFetchRoleMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchRole(
    params: RoleServiceFetchRoleMetadataParamsInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .get(
        this.generateUrl('roleService.fetchRole.endpoint', { '{roleId}': params.roleId })
      ).pipe(
        map((response: any) => {
          response.localizations.forEach((metadata, index) => {
            response.localizations[index] = new RoleMetadata(metadata);
          });

          const role = new Role(response);
          return new StormServiceResponseSingle(role);
        })
      );
  }

  /**
   * Fetch a single Role with no location info
   *
   * @param roleId number
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchRoleNoLocation(
    roleId: number,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const params: any = {
      '{roleId}': roleId
    };

    const request: any = {
      url: this.generateUrl(
        'roleService.fetchRole.endpoint',
        params,
        { _localizations: false }
      )
    };

    const subs: Subscription = this.doGetRequest(
      request,
      (successResponse: StormServiceResponseSingle) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: any) => {
        onErrorDo(errorResponse);
      },
      finallyDo
    );

    return subs;
  }

  /**
   * Creates a new Role
   *
   * @param role RoleInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public createRole(
    role: Role
  ): Observable<StormServiceResponseSingleInterface> {
    const request: any = {
      url: this.generateUrl('roleService.createRole.endpoint', null),
      body: JSON.stringify(role.getRawObject())
    };

    return this.doPostRequestAsObservable(
      request
    ).pipe(
      map((response: any) => {
        return new StormServiceResponseSingle(new Role(response.item));
      })
    );
  }

  /**
   * Fetch the Role Metadata for a specific Role/locale
   *
   * @param params RoleServiceFetchRoleMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchRoleMetadata(
    params: RoleServiceFetchRoleMetadataParamsInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .get(
        this.generateUrl('roleService.fetchRoleMetadata.endpoint', { '{roleId}': params.roleId, '{locale}': params.locale })
      ).pipe(
        map((response: any) => {
          const roleMetadata = new RoleMetadata(response);
          return new StormServiceResponseSingle(roleMetadata);
        })
      );
  }

  /**
   * Fetch the computed Role Metadata for a specific Role/locale
   *
   * @param params RoleServiceFetchRoleMetadataParamsInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public fetchComputedRoleMetadata(
    params: RoleServiceFetchRoleMetadataParamsInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .get(
        this.generateUrl('roleService.fetchComputedRoleMetadata.endpoint', { '{roleId}': params.roleId, '{locale}': params.locale })
      ).pipe(
        map((response: any) => {
          const roleMetadata = new RoleMetadata(response);
          return new StormServiceResponseSingle(roleMetadata);
        })
      );
  }

  /**
   * Updates a Role
   *
   * @param params RoleServiceFetchRoleMetadataParamsInterface
   * @param updates RoleInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public updateRole(
    params: RoleServiceFetchRoleMetadataParamsInterface,
    updates: RoleInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .put(
        this.generateUrl('roleService.updateRole.endpoint', { '{roleId}': params.roleId }),
        JSON.stringify(updates)
      ).pipe(
        map((response: any) => {
          response.localizations.forEach((metadata: object, index: number) => {
            response.localizations[index] = new RoleMetadata(metadata);
          });

          const role = new Role(response);
          return new StormServiceResponseSingle(role);
        })
      );
  }

  /**
   * Updates the role metadata
   *
   * @param params RoleServiceFetchRoleMetadataParamsInterface
   * @param fromLocale Locale
   * @param toLocale Locale
   * @param roleMetadata RoleMetadataInterface
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public updateRoleMetadata(
    params: RoleServiceFetchRoleMetadataParamsInterface,
    fromLocale: Locale,
    toLocale: Locale,
    roleMetadata: RoleMetadataInterface
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .post(
        this.generateUrl('roleService.moveMetadata.endpoint', { '{roleId}': params.roleId }),
        JSON.stringify(
          Object.assign(
            { from: fromLocale, to: toLocale },
            roleMetadata
          )
        )
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new RoleMetadata(response));
        })
      );
  }

  /**
   * Sets the metadata for a Role
   *
   * @param params RoleServiceFetchRoleMetadataParamsInterface
   * @param locale string
   * @param roleMetadada Role
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public setRoleMetadata(
    params: RoleServiceFetchRoleMetadataParamsInterface,
    locale: string,
    roleMetadata: Role
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .post(
        this.generateUrl('roleService.createRoleMetadata.endpoint', { '{roleId}': params.roleId, '{locale}': locale }),
        JSON.stringify(roleMetadata.getRawObject())
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new RoleMetadata(response));
        })
      );
  }

  /**
   * Deletes the given Role Metadata properties (attributes)
   *
   * @param params RoleServiceFetchRoleMetadataParamsInterface
   * @param locale string
   * @param properties string[]
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public deleteRoleMetadataAttribute(
    params: RoleServiceFetchRoleMetadataParamsInterface,
    locale: string,
    properties: string[]
  ): Observable<StormServiceResponseSingleInterface> {

    return this.authHttp
      .delete(
        this.generateUrl('roleService.deleteRoleMetadataAttribute.endpoint', { '{roleId}': params.roleId, '{locale}': locale }),
        JSON.stringify(properties)
      ).pipe(
        map((response) => {
          return new StormServiceResponseSingle(new RoleMetadata(response));
        })
      );
  }

  /**
   * Deletes the given Role Localization
   *
   * @param params RoleServiceFetchRoleMetadataParamsInterface
   * @param locale string
   * @returns Observable<StormServiceResponseSingleInterface>
   */
  public deleteRoleLocalization(
    params: RoleServiceFetchRoleMetadataParamsInterface,
    locale: string,
  ): Observable<StormServiceResponseSingleInterface> {
    return this.authHttp
      .delete(
        this.generateUrl('roleService.deleteRoleLocalization.endpoint', { '{roleId}': params.roleId, '{locale}': locale })
      ).pipe(
        map((response: any) => {
          return new StormServiceResponseSingle(new RoleMetadata(response));
        })
      );
  }

  /**
   * Returns an observable for searching the given parameters.
   *
   * @param searchParams RoleServiceFetchRoleParamsInterface
   * @returns Observable<StormServiceResponseCollectionInterface>
   */
  public oldSearch(
    searchParams: RoleServiceFetchRoleParamsInterface
  ): Observable<StormServiceResponseCollectionInterface> {
    const search = new UrlSearchParams();

    search.set(RoleServiceFetchRoleQueryParams.q.toString(), searchParams.q);
    search.set(StormServicesQueryParams.page.toString(), (searchParams.page - 1).toString());
    search.set(StormServicesQueryParams.page_size.toString(), searchParams.page_size.toString());

    if (searchParams.sort_by && searchParams.sort_direction) {
      search.set(StormServicesQueryParams.sort.toString(), searchParams.sort_by + ':' + searchParams.sort_direction);
    }

    const apiUrl = this.generateUrl('roleService.oldSearch.endpoint', undefined, search);

    const obs: Observable<StormServiceResponseCollectionInterface> = this.authHttp.get(apiUrl).pipe(
      map(
        response => {
          const responseCollection = response.content.map((item: object) => new Role(item));

          return new StormServiceResponseCollection(
            responseCollection,
            Number(response.number) + 1,
            Number(response.size),
            Number(response.totalPages),
            Number(response.totalElements),
            searchParams.sort_by,
            searchParams.sort_direction
          );
        }
      )
    );

    return obs;
  }

  /**
   * Returns a subscription for searching roles using the given query
   *
   * @param criteria SearchCriteria
   * @param onSuccessDo CallableFunction
   * @param onErrorDo CallableFunction
   * @param finallyDo CallableFunction
   * @returns Subscription
   */
  search(
    criteria: SearchCriteria,
    onSuccessDo: CallableFunction,
    onErrorDo: CallableFunction,
    finallyDo?: CallableFunction
  ): Subscription {
    const subs: Subscription = this.searchAsObservable(criteria).pipe(
      finalize(
        () => {
          if (finallyDo) {
            finallyDo();
          }
        }
      )
    ).subscribe(
      (successResponse: SearchResponse) => {
        onSuccessDo(successResponse);
      },
      (errorResponse: ErrorHelper) => {
        onErrorDo(errorResponse);
      }
    );

    return subs;
  }

  /**
   * Returns an observable for searching role using the given query.
   *
   * @param criteria SearchCriteria
   * @returns Observable<SearchResponse>
   */
  searchAsObservable(criteria: SearchCriteria): Observable<SearchResponse> {
    const url: string = this.generateUrl(
      'roleService.search.endpoint',
      undefined,
      criteria.asEndpointData()
    );

    const obs: Observable<SearchResponse> = this.doGetRequestAsObservable({ url: url }).pipe(
      map((response: StormServiceResponseSingle) => {
        const mappedResponse: SearchResponse = new SearchResponse(
          response.item,
          (element: any) => new RoleResponse(element)
        );

        return mappedResponse;
      })
    );

    return obs;
  }
}
